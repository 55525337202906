import React, { useState, useEffect } from 'react';
import { commonServices } from '../../common/helper/utils';
import { Button, Form, Row, Col } from 'react-bootstrap';
import * as styled from './style';
import {
    PASSWORD_PATTERN,
    PASSWORD_BAD,
    PASSWORD_GOOD,
    OTP_PATTERN
} from '../../common/helper/pattern';
import RecaptchaComponent from './recaptcha-component';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as Config from './../../config/app.config';

function ChangePasswordRecoveryForm(props) {

    const [data, setData] = useState({
        otp: '',
        newPassword: '',
        confirmdPassword: '',
        email: props.email
    })
    const [disabled, setDisabled] = useState(true);
    const [validated, setValidated] = useState(false);
    const [indicator, setIndicator] = useState('')
    const [indicatorValue, setIndicatorValue] = useState(0);
    const [newPasswordError, setNewPasswordError] = useState('')

    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    useEffect(() => {
        if (data.otp !== '' && data.newPassword !== '' && data.confirmdPassword !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [data])

    const onChange = (event) => {
        let password = event.target.value;
        setConfirmPasswordError('')
        setData({
            ...data,
            [event.target.name]: password
        })

        if (event.target.name === 'newPassword') {
            if (password == '') {
                setIndicatorValue({ 'value': '0%', 'color': '', 'text': '' })
                setIndicator('')
            } else {
                setNewPasswordError('')
                if (new RegExp(PASSWORD_PATTERN).test(password) !== true ) {
                    setNewPasswordError("The password doesn't match the rules specified.");
                    setDisabled(true);
                }else{
                  setDisabled(false);
                  setNewPasswordError('');
                }
                if (new RegExp(PASSWORD_PATTERN).test(password) !== true ||  data.confirmdPassword === '') {
                 
                  setDisabled(true);
              }else{
                setDisabled(false);
                setNewPasswordError('');
              }
                if (new RegExp(PASSWORD_PATTERN).test(password) === true) {
                    setIndicator('Strong')
                    setIndicatorValue({ 'value': '100%', 'color': '#147412', 'text': '#FFFFFF' })
                } else if (new RegExp(PASSWORD_GOOD).test(password) === true) {
                    setIndicator('Normal')
                    setIndicatorValue({ 'value': '60%', 'color': '#E8C766', 'text': '#FFFFFF' })

                } else if (new RegExp(PASSWORD_BAD).test(password) === true) {
                    setIndicator('Weak')
                    setIndicatorValue({ 'value': '40%', 'color': '#832533', 'text': '#FFFFFF' })
                } else {
                    setIndicator('Too short')
                    setIndicatorValue({ 'value': '10%', 'color': '#FF0000', 'text': '#333333' })
                }
            }
        } else {
            setIndicator('')
        }

        setValidated(true);
        props.ressetChangePassword();
    }

    const onSubmit = (event) => {
        let form = event.currentTarget;

        if (form.checkValidity() === false) {

        } else {
            // if (new RegExp(PASSWORD_PATTERN).test(data.newPassword) !== true) {
            //     // setNewPasswordError('New password must have at least 8 characters and atleast 1 uppercase, 1 lowercase, 1 number, 1 special symbol')
            // }
            if (data.confirmdPassword !== data.newPassword) {
                setConfirmPasswordError('Passwords must match')
            } else {
                // setConfirmPasswordError('')
                props.submitChangePassword(data);
            }
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }

    return (
        <styled.RecoveryPassword>
            <Form onSubmit={onSubmit}
                validated={validated}
                noValidate
                id="change-password-form"
                autocomplete="off">
                <Form.Group>
                    <Form.Label>One-time code</Form.Label>

                    <div className="text-secondary">Enter the code we sent to your email address</div>
                    <Row><Col xs={6}>
                    <Form.Control
                       
                        type="text"
                        name="otp"
                        maxLength="6"
                        required
                        pattern={OTP_PATTERN}
                        value={data.otp}
                        autoComplete="nope"
                        onChange={onChange} />
                    <Form.Control.Feedback type="invalid">
                        {
                            data.otp === '' && 'Please enter a code'
                        }
                        {
                            data.otp !== '' && 'Please enter a valid code'
                        }

                    </Form.Control.Feedback>
                    </Col></Row>
                    <Row className="loginError">
                        <Col md="auto"> {props.changePasswordError.map((datum, index) => (
                            <styled.Errors>{datum.includes("otp") ? commonServices.filterErrors(datum) : ''}</styled.Errors>
                        ))}</Col>
                    </Row>
                </Form.Group>
                <Row></Row>
                <h5><Form.Text className="mb-4 font-weight-bold">CHOOSE A NEW PASSWORD</Form.Text></h5>
                <Row><Col xs={6}>
                <Form.Group>
                    <Form.Label>New password</Form.Label>
                    
                    <Form.Control
                         
                        type="password"
                        name="newPassword"
                        minLength="8"
                        maxLength="20"
                        autoComplete="new-password"
                        required
                        value={data.password}
                        pattern={PASSWORD_PATTERN}
                        onChange={onChange} />
                    {
                        indicator !== '' && indicator !== null &&
                        <>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={indicatorValue.value}
                                    aria-valuemin="0" aria-valuemax="100" style={{ width: indicatorValue.value, backgroundColor: indicatorValue.color, color: indicatorValue.text }}>
                                </div>
                            </div>
                            {/* {indicator} */}
                        </>
                    }
                    {
                        newPasswordError !== '' ?
                            <span className="text-danger">
                                {newPasswordError}
                            </span> :
                            <Form.Control.Feedback type="invalid">
                                {
                                    data.newPassword === '' && 'Please enter a password'
                                }
                                {
                                    data.newPassword !== '' && 'Please enter a valid password'
                                }
                            </Form.Control.Feedback>
                    }

                    <Row className="loginError">
                        <Col md="auto"> {props.changePasswordError.map((datum, index) => (
                            <styled.Errors>{datum.includes("newPassword") ? commonServices.filterErrors(datum) : ''}</styled.Errors>
                        ))}</Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Confirm new password</Form.Label>
                    <Form.Control
                         
                        type="password"
                        name="confirmdPassword"
                        maxLength="20"
                        required
                        autoComplete="confirm-password"
                        // pattern={PASSWORD_PATTERN}
                        value={data.confirmdPassword}
                        onChange={onChange} />
                    {
                        validated === true && data.confirmdPassword === '' &&
                        <span className="text-danger">Please enter a confirmed password</span>
                    }
                    {
                        validated === true && data.confirmdPassword !== '' && data.confirmdPassword !== data.newPassword &&
                        <span className="text-danger">{confirmPasswordError}</span>
                    }
                    <Row className="loginError">
                        <Col md="auto">
                            {
                                props.changePasswordError.map((datum, index) => (
                                    <styled.Errors>{datum.includes("confirmdPassword") ? commonServices.filterErrors(datum) : ''}</styled.Errors>
                                ))
                            }
                        </Col>
                    </Row>
                </Form.Group>
                </Col>
                <Col><div className="info-container">
                 <Row><Col className="mt-2">
                 New password must be:
                 </Col></Row>
                 
                 <Row><Col className="info-data  mt-2 mb-2">
                 <ol class="info-list">
                 <li>  <span> 8 characters </span></li>
                 <li> <span> Contain one uppercase </span></li>
                 <li> <span> Contain one lowercase  </span></li>
                 <li> <span> Contain one number  </span></li>  <li><span> Contain one(!_@#$%&) special symbol  </span></li>

               
                
              </ol>
                 </Col></Row>
                </div></Col></Row>
              
              
                <Form.Group>
                    <GoogleReCaptchaProvider reCaptchaKey={Config.CAPTCHA_SECURITY_TOKEN}>
                        {
                            props.verified === false &&
                            <RecaptchaComponent setVerified={props.setVerified}
                                setShowCaptchaError={props.setShowCaptchaError} />
                        }
                    </GoogleReCaptchaProvider>
                    {
                        props.showCaptchaError &&
                        <div className="text-danger">Please verify yourself as human</div>
                    }
                </Form.Group>
            </Form>
            <div className="modal-button-wrapper d-flex justify-content-between">
                <div onClick={props.backToLogin}
                    className="back-login-btn d-flex align-items-center cursor-pointer">
                    <img alt='' src="/images/icons/ic_chevron_right.svg" className="mr-2 checkIcon" /> Back to login
                </div>
                <Button
                    form="change-password-form"
                    type="submit"
                    disabled={disabled}
                    className="btn-snacktool btn-snacktool-golden">
                    Save new password
                </Button>
            </div>
        </styled.RecoveryPassword>
    )
}

export default ChangePasswordRecoveryForm
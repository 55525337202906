import React, { useState } from 'react';
import {  Row, Col } from 'react-bootstrap';
import * as styled from './style';
import RequestAccountForm from './../../components/login/request-account-form';
import RequestAccountSuccess from './../../components/login/request-account-success';
import { connect } from 'react-redux';
import * as actions from './../../actions/request-account-actions';
import * as Pattern from './../../common/helper/pattern';

function RequestAccount(props) {
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);
    const [disabledState, setDisabledState] = useState(true);
    const [verified, setVerified] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [emailError, setEamilError] = useState('');

    const onChange = (event) => {
        let email = event.target.value;

        setEmail(email);
        setEamilError('');

        if (email !== '') {
            setDisabledState(false);
            if (new RegExp(Pattern.EMAIL_PATTERN_ACCOUNT).test(email) !== true) {
                setEamilError('Please enter valid email address');
            }
        } else {
            setDisabledState(true);
        }
        props.resetRequesetAccount();
        setValidated(true);
    }

    const onSubmit = (event) => {
        let form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            if (verified === false) {
                setShowCaptchaError(true);
            } else {
                setShowCaptchaError(false);
                props.sendRequestAccount(email);
            }
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }

    const backToLogin = () => {
        props.history.push(`/login`);
    }

    return (
    <styled.RequestAccount>
        
            {
                <Row>
                    <Col className="login-wrapper col-md-4 offset-md-4 d-flex align-items-center justify-content-center">
                        
                            {
                                (props.requestAccount === null || props.requestAccount === false) &&
                                <styled.LoginForm>
                                    <styled.LoginTitle>Request a Write Fictif account</styled.LoginTitle>
                                    <RequestAccountForm 
                                        email = {email}
                                        validated = {validated}
                                        disabledState = {disabledState} 
                                        onChange = {onChange}
                                        onSubmit = {onSubmit}
                                        backToLogin = {backToLogin}
                                        requestAccount = {props.requestAccount}
                                        verified = {verified}
                                        showCaptchaError = {showCaptchaError}
                                        setShowCaptchaError = {setShowCaptchaError}
                                        setVerified = {setVerified}
                                        requestAccountError = {props.requestAccountError}
                                        emailError = {emailError} />
                                </styled.LoginForm>
                            }
                            {
                                props.requestAccount &&
                                <RequestAccountSuccess backToLogin = {backToLogin}/>
                            }
                        
                    </Col>
                </Row>
            }
  </styled.RequestAccount>
  )
}

const mapStateToProps = (state) => {
    return {
        requestAccount: state.AccountReducer.requestAccount,
        requestAccountError: state.AccountReducer.requestAccountError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendRequestAccount: (email) => dispatch(actions.requestAccount(email)),
        resetRequesetAccount: () => dispatch(actions.resetRequestAccount())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccount)
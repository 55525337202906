import * as ActionTypes from './action-types';

export function updateMyAccountDetails(authToken, username, email) {
  return {
    type: ActionTypes.MY_ACCOUNT_UPDATE_DETAILS,
    authToken: authToken,
    username: username,
    email: email
  };
}

export function updateMyAccountDetailsFailMessage(errorFail) {
  return {
    type: ActionTypes.MY_ACCOUNT_UPDATE_DETAILS_FAIL,
    errorFail: errorFail
  };
}

export function updateMyAccountDetailsFail() {
  return {
    type: ActionTypes.MY_ACCOUNT_UPDATE_DETAILS_FAILED
  };
}

export function updateMyAccountDetailsSuccess(payload) {
  return {
    type: ActionTypes.MY_ACCOUNT_UPDATE_DETAILS_SUCCESS,
    payload: payload
  };
}

export function setSelectedAccountMenu(menuName) {
  return {
    type: ActionTypes.SET_SELECTED_ACCOUNT_MENU,
    menuName: menuName
  };
}

export function changePasswordMyAccount(
  authToken,
  oldPassword,
  newPassword,
  confirmPassword
) {
  return {
    type: ActionTypes.SET_MY_CHANGE_PASSWORD,
    authToken: authToken,
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirmPassword: confirmPassword
  };
}

export function changePasswordMyAccountSuccess() {
  return {
    type: ActionTypes.SET_MY_CHANGE_PASSWORD_SUCCESS
  };
}

export function changePasswordMyAccountFail(errorFail) {
  return {
    type: ActionTypes.SET_MY_CHANGE_PASSWORD_FAIL,
    errorFail: errorFail
  };
}

export function deleteMyAccount(authToken, deleteStory) {
  return {
    type: ActionTypes.MY_ACCOUNT_DELETE,
    deleteStory: deleteStory,
    authToken: authToken
  };
}

export function deleteMyAccountSuccess() {
  return {
    type: ActionTypes.MY_ACCOUNT_DELETE_SUCCESS
  };
}

export function deleteMyAccountFail(errorFail) {
  return {
    type: ActionTypes.MY_ACCOUNT_DELETE_FAIL
  };
}

export function setToggleCharacterValue(authToken, toggleCharacter) {
  return {
    type: ActionTypes.SET_TOGGLE_CHARACTER,
    toggleCharacter: toggleCharacter,
    authToken: authToken
  };
}

export function setSuccessFalse() {
  return {
    type: ActionTypes.SET_SUCCESS_FALSE
  };
}

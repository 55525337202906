import React, { useEffect, useState } from 'react';
import * as CollaboratorStyle from './../viewer/style/collaborator-style';
// import * as CollaboratorStyle from './/style/collaborator-style';
import { Button, Form, Modal } from 'react-bootstrap';
import * as translations from './../viewer/style/translations';

const StoryCredit = props => {
  const [storyCreditValue, setStoryCreditValue] = useState(props.creditValue);
  const [storyCreditButton, setStoryCreditButton] = useState(
    props.stroyCredit ? true : false
  );

  const handleChangeValueCredit = event => {
    setStoryCreditValue(event.target.value);
    if (event.target.value === '') {
      setStoryCreditButton(true);
    } else {
      setStoryCreditButton(false);
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showCreditModal}
      // onHide={props.setshowCollaboratorModal(false)}
      backdrop="static"
    >
      <CollaboratorStyle.modalCollaborator>
        <Modal.Body>
          <Modal.Title id="example-custom-modal-styling-title">
            {props.creditValue && props.creditValue !== ''
              ? translations.EDIT_STORY_CREDITS
              : translations.ADD_STORY_CREDITS}
          </Modal.Title>

          <Form method="POST">
            <Form.Group>
              <Form.Label>{translations.STORY_CREDITS}</Form.Label>
              <div className="">
                <div className="input-wrapper">
                  <Form.Control
                    required
                    as="textarea"
                    name="storyCredits"
                    key="creditName"
                    defaultValue={props.defaultcollaboratorName}
                    onChange={event => handleChangeValueCredit(event)}
                    value={storyCreditValue}
                    placeholder="Add credit"
                    autoComplete="off"
                    rows={8}
                  />
                  <Form.Control.Feedback type="invalid">
                    Field is required
                  </Form.Control.Feedback>
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter valid and unique viewer name
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <Button
                id="createSceneBtn"
                type="button"
                className="btn-snacktool btn-snacktool-brown btn btn-primary"
                onClick={e => props.setStoryCreditModal(false)}
              >
                {translations.CANCEL}
              </Button>
              <Button
                id="createSceneBtn"
                type="button"
                className="btn-snacktool btn-snacktool-golden btn btn-primary"
                disabled={storyCreditButton}
                onClick={e => props.handleAddCredits(e, storyCreditValue)}
              >
                {props.creditValue && props.creditValue !== ''
                  ? translations.EDIT_CREDITS
                  : translations.ADD_CREDITS}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </CollaboratorStyle.modalCollaborator>
    </Modal>
  );
};

export default StoryCredit;

export const INTRO_OPTIONS = {
  skipLabel: 'x',
  prevLabel: 'Previous',
  nextLabel: 'Next',
  exitOnEsc: true,
  exitOnOverlayClick: true,
  showStepNumbers: false,
  hidePrev: true,
  hideNext: true,
  overlayOpacity: 0,

  doneLabel: 'Finish'
};

export const stepViewConstant = [
  {
    element: '.step-views',
    intro:
      '<h3>VIEWS</h3><p>These are the backgrounds that you will use for your story.</p>',
    position: 'top',
    tooltipClass: 'writetool-if_else_3'
  },
  {
    element: '.step-background-view',
    intro:
      '<h3>BACKGROUND IMAGE</h3><p>Views use background images you have uploaded via the Library section.</p>',
    position: 'bottom',
    tooltipClass: 'writetool_library_3'
  },
  {
    element: '.step-object-view',
    intro:
      '<h3>OBJECTS</h3><p>Additionally, you can layer objects on top of a background image, and even make those objects clickable.</p>',
    position: 'left',
    tooltipClass: 'writetool_library_3'
  },
  {
    element: '.step-tab-view',
    intro:
      '<h3>USE THEM NOW OR LATER</h3><p>You can create all the views you want. They will be stored here and you can use them later.</p>',
    position: 'bottom',
    tooltipClass: 'writetool_library_3'
  }
];

export const TITLE_APPROVE = 'Approve this story?';
export const SUBTITLE_APPROVE = 'The Story Creator will receive a notification with your feedback. The creator may then submit the story for publishing or make more changes as desired.';
export const BTN_APPROVE = 'Yes, approve it';
export const TITLE_SUCCESS_APPROVE = 'Story has been approved.';
export const SUBTITLE_SUCCESS_APPROVE = 'The Story Creator will be notified of this change.';

export const TITLE_REJECT = 'Reject this story?';
export const SUBTITLE_REJECT = 'The Story Creator will receive a notification with your feedback. The creator may then make changes to the story as desired.';
export const BTN_REJECT = 'Yes, reject it';
export const TITLE_SUCCESS_REJECT = 'Story has been rejected.';
export const SUBTITLE_SUCCESS_REJECT = 'The Story Creator will be notified of this change.';

export const TITLE_PUBLISH = 'Publish this story?';
export const SUBTITLE_PUBLISH = 'The story will be converted into a game and the Story Creator will receive a notification.';
export const BTN_PUBLISH = 'Yes, publish it';
export const TITLE_SUCCESS_PUBLISH = 'Story has been published.';
export const SUBTITLE_SUCCESS_PUBLISH = 'The Story Creator will be notified of this change.';

export const TITLE_UNPUBLISH = 'Unpublish this story?';
export const SUBTITLE_UNPUBLISH = 'The story will become editable again.';
export const BTN_UNPUBLISH = 'Yes, unpublish it';
export const TITLE_SUCCESS_UNPUBLISH = 'The story has been unpublished.';
export const SUBTITLE_SUCCESS_UNPUBLISH = 'The Story Creators will be notified of this action.';
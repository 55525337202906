import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as ViewsActions from './../../actions/place-background-action';

import * as ActionTypes from '../../actions/action-types';
import * as PlayerActions from './../../actions/player-action';
import * as LibraryActions from './../../actions/library-action';
import {
  getCommandsLevel1,
  getCommandsLevel2,
  getCharacterColor,
  setCommandLevel,
  getCommands,
  setAutoSaveCommands,
  setCommands,
  setCommandsPanel,
  getAvailableBlocks,
  getCommandsFail,
  setCursorPostion,
  getPreviewAsset,
  getPreviewAssetFail,
  getCommandsEdit,
  getCommandsEditFail,
  realeseLock,
  showModal,
  changeLogScript,
  saveFlowchartChanges
} from '../../actions/story-action';
import * as CommentAction from '../../actions/comment-action';
import * as StoryAction from '../../actions/story-action';

import * as WriteStyle from './style-write-story.js';
import CommandsPanel from '../../components/story/commands-panel';
import { StoryService } from './../../components/story/service';
import * as Constant from './../../common/constants';

import TextEditor from './../script-writer/text-editor';
import SceneList from '../../components/scene/scenes-list';
import CreateChapter from '../../components/scene/create-chapter/CreateChapter';
import CreateRoute from '../../components/scene/create-chapter/CreateRoute';

import SubmitRoute from '../../components/scene/create-chapter/SubmitRoute';
import Success from '../../components/scene/create-chapter/Success';
import Fail from '../../components/scene/create-chapter/Fail';

import PlaceBackground from './../../components/story/place-background/PlaceBackground';

import EditViewCommand from './../../components/story/edit-view-command/EditViewCommand';

import PlaceMoveBackground from './../../components/story/move-background/PlaceBackground';

import CancelPlaceBackground from './../../components/story/place-background/CancelPlaceBackground';
import CancelMovePlaceBackground from './../../components/story/move-background/CancelPlaceBackground';

import * as PlaceBackgroundActions from './../../actions/place-background-action';
import StoryRightPanel from './../../components/story/story-right-panel';

// Imports for preview player
import { PlayerService } from './../../components/player/service/player-service';
import { PlaceBackgroundService } from './../../components/story/place-background-service';
import AddObjectsModal from './../../components/story/place-background/AddObjectsModal';
import CreateViewModal from './../../components/story/place-background/CreateViewModal';
import CommentsModal from './../../components/story/comments/CommentsModal';

import CommentsSuccess from './../../components/story/comments/CommentSuccess';
import CommentsFail from './../../components/story/comments/CommentFail';

import SuccessDeleteRoute from '../../components/common/Modals/Success';
import FailDeleteRoute from '../../components/common/Modals/Fail';
import ConfirmDeleteRoute from '../../components/common/Modals/Confirm';
import ConfirmAutoSavePopup from '../../components/common/Modals/WarnigToSave';

import ChangeLogModal from './../../components/changelog/changelogModal';

import { ImageMapper } from './../../common/helper/image-mapper';
import * as AspectRatioConfig from './../../config/aspectRatio.config';

import * as GameInterfaceActions from './../../actions/game-interface-action';
import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

import RouteInfo from './../../components/story/route/RouteInfo';
import LibraryChangeLog from './../../components/library/changelog/library-change-log';
import FlowchartPanel from '../../components/story/flowchart-panel';
import LargeFlowchart from '../../components/flowchart/large-flowchart';
import Warning from '../../components/views/warning';
import LockedAssets from './../../components/library/list-assets/delete-assets/LockedAssets';
import _ from 'lodash';
import story from '.';
import { postStoryProgressData } from '../../saga/api/story-api';
const WriteStory = props => {
  const commandConstant = {
    commandId: null,
    cmdText: '',

    blockCommand: false,

    deleted: false,
    modified: false
  };

  const commentConstant = {
    commentText: '',
    lineNo: null
  };

  const routeConstant = {
    characterId: '',
    description: '',
    specs: '',
    chapterLastName: '',
    removeImage: false
  };

  // intor.js states
  const [stepsScriptEnabled, setStepsScriptEnabled] = useState(
    props.tutorials.firstTextEditor === false ? true : false
  );

  const [initialScriptStep, setInitialScriptStep] = useState(0);
  const [stepsScript, setStepsScript] = useState(Constant.stepScriptsConstant);

  const [stepsViewEnabled, setStepsViewEnabled] = useState(false);

  const [stepsCommandEnabled, setStepsCommandEnabled] = useState(false);
  const [initialCommandStep, setInitialCommandStep] = useState(0);
  const [stepsCommand, setStepsCommand] = useState();

  let flowchartNode = props.flowchartNode;
  let flowchartConnector = props.flowchartConnector;

  const [files, setFiles] = useState(null);
  const [route, setRoute] = useState(routeConstant);
  const [routeValidated, setRouteValidated] = useState(false);
  const [editRouteId, setEditRouteId] = useState(null);

  const [editCharRoute, setEditCharRoute] = useState(false);

  const [editRouteCharId, setEditRouteCharId] = useState(null);

  const [routeCharacterList, setRouteCharacterList] = useState(
    props.characterList
  );

  // const [routeCharList, setRouteCharList] = useState(props.routeCharaList);

  const [refreshRouteList, setRefreshRouteList] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [routeButtonState, setRouteButtonState] = useState(true);

  const [showClearFile, setShowClearFile] = useState(false);

  const [flowchartToggle, setflowchartToggle] = useState('inline-block');
  const [sceneId, setSceneId] = useState(null);
  const [newSceneName, setNewSceneName] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('#FFFFFF');
  const [commandPanelToggle, setCommandPanelToggle] = useState('block');

  const [commandPanelShow, setCommandPanelShow] = useState('fa fa-angle-left');
  const [validated, setValidated] = useState(false);

  const [commandId, setCommandId] = useState(null);
  const [showShortCut, setshowShortCut] = useState(false);
  const [comamndSavePanel, setComamndSavePanel] = useState(null);

  const [flowchartPopup, setFlowchartPopup] = useState(false);
  const [showFlowchart, setShowFlowchart] = useState(false);

  const [collaboratorModal, setCollaboratorModal] = useState(false);

  //commandPannel
  // const [commandAllFlags, setcommandAllFlags] = useState({
  //     commandEnterTheSceneFlag: 0,
  //     commandPlayMusicFlag: false,
  //     commandBackgroundFlag: false,
  //     commandOffScreenFlag: false
  // });

  // new command panel
  const [comments, setComments] = useState(commentConstant);
  const [commandObj, setcommandObj] = useState(commandConstant);
  const [commandData, setcommandData] = useState(props.commandObj);

  // new command panel
  // State for place backgrounds pages
  const [showPlaceBackground, setShowPlaceBackground] = useState(false);
  const [showEditViewCommand, setShowEditViewCommand] = useState(false);
  const [showMovePlaceBackground, setMoveShowPlaceBackground] = useState(false);
  const [tabButtonClicked, setTabButtonClicked] = useState('placeView');

  //HideIfVisited
  const [hideIfVisitedArray, setHideIfVisitedArray] = useState([]);

  const [
    showCancelPlaceBackgroundWindow,
    setShowCancelPlaceBackgroundWindow
  ] = useState(false);
  const [
    showCancelMovePlaceBackgroundWindow,
    setShowCancelMovePlaceBackgroundWindow
  ] = useState(false);

  const [totalFormInput, setBlockTotalFormInput] = useState();

  // State for preview player
  const [commandsTimeoutIds, setCommandsTimeoutIds] = useState([]);
  const [play, setPlay] = useState(false);
  const [firstPlay, setFirstPlay] = useState(false);
  const [lastPlay, setLastPlay] = useState(false);
  const [prevPlay, setPrevPlay] = useState(false);
  const [nextPlay, setNextPlay] = useState(false);
  const [gotoPlay, setGotoPlay] = useState(null);
  const [currentCommand, setCurrentCommand] = useState(0);
  const [currentScene, setCurrentScene] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [fastPreview, setFastPreview] = useState(false);
  const [checkCommandState, setCheckCommandState] = useState(false);
  const [showDecision, setShowDecision] = useState(false);
  const [decisionParameters, setDecisionParameters] = useState(null);
  const [ifElseDecisionParameters, setIfElseDecisionParameters] = useState([]);

  const [showEnd, setShowEnd] = useState(false);
  const [showCharModal, setshowCharModal] = useState(false);
  const [choiceTimeout, setChoiceTimeout] = useState([]);
  const [pageX, setpageX] = useState(null);
  const [pageY, setpageY] = useState(null);
  const [lastView, setLastView] = useState(null);
  const [showDialogue, setShowDialogue] = useState(false);
  const [playFromStart, setPlayFromStart] = useState(true);
  const [showNameDialogue, setShowNameDialogue] = useState(false);
  const [externalPlayPauseHandler, setExternalPlayPauseHandler] = useState(
    false
  );
  const [aspectRatio, setAspectRatio] = useState(
    AspectRatioConfig.aspectRatioNineByNineteen
  );
  const [consoleMessage, setConsoleMessage] = useState([]);
  const [executedCommand, setExecutedCommand] = useState([]);
  const [showScriptError, setShowScriptError] = useState(false);
  const [command, setCommand] = useState({});
  const [startFromHighlightBlock, setStartFromHighlightBlock] = useState(false);

  // Place background state initialization
  const [clickedEventCheck, setClickedEventCheck] = useState(true);
  const [duplicateName, setDuplicateName] = useState(props.duplicateName);
  const [searchText, setSearchText] = useState('');
  const [showAddObjectForm, setShowAddObjectForm] = useState(false);
  const [editObjectIndex, setEditObjectIndex] = useState(null);
  const [createViewModal, setCreateViewModal] = useState(false);

  const [commentsModal, setCommentsModal] = useState(false);

  const [commentLineNo, setCommentLineNo] = useState(null);
  const [commentCmdText, setCommentCmdText] = useState('');
  const [commentBlockText, setCommentBlockText] = useState('');
  const [browseViewId, setBrowseViewId] = useState(null);
  const [editView, setEditView] = useState(false);

  //Create Variable Window
  const [variableWindoOnOffStatus, setVariableWindoOnOffStatus] = useState(
    false
  );

  //Right Panel Flowchart/Preview
  const [activeTabSelected, setActiveTabSelected] = useState('Flowchart');
  const [selectBackground, setSelectBackground] = useState(true);
  const [addObject, setAddObject] = useState(false);
  const [blockClickedEvent, setBlockClickedEvent] = useState(false);
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [editChapter, setEditChapter] = useState(0);

  const [highlightBlock, setHighlightBlock] = useState(false);
  const [previewScroll, setPreviewScroll] = useState(true);

  const [showRouteModal, setShowRouteModal] = useState(false);

  const [confirmRouteDelete, setConfirmRouteDelete] = useState(false);
  const [showSuccessDeleteRoute, setShowSuccessDeleteRoute] = useState(false);
  const [showFailureDeleteRoute, setShowFailureDeleteRoute] = useState(false);
  const [deleteCharacterRouteId, setDeleteCharacterRouteId] = useState(null);
  const [characterRouteIdText, setCharacterRouteId] = useState(null);
  const [showRouteInfo, setShowRouteInfo] = useState(false);

  const [showLibraryChangeLog, setShowLibraryChangeLog] = useState(false);

  const [compareFlag, setCompareFlag] = useState(false);
  const [textEditorValidate, setTextEditorValidate] = useState(false);
  const [indentButtonClick, setIndentButtonClick] = useState(false);

  const [autoSaveFlagCheckBack, setAutoSaveFlagCheckBack] = useState(false);
  const [releaseLockCheck, setReleaseLockCheck] = useState(null);

  const [editWarningTextEditor, setEditWarningTextEditor] = useState(false);
  const [editErrorTextEditor, setEditErrorTextEditor] = useState(false);
  const [viewErrorTextEditor, setViewErrorTextEditor] = useState(false);
  const [viewWarningTextEditor, setViewWarningTextEditor] = useState(false);

  const [refreshList, setRefreshList] = useState(false);

  //Block name on edit
  const [editBlockName, setEditBlockName] = useState(null);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  //Highlight block
  const [highlightBlockIndex, setHighlightBlockIndex] = useState(0);

  let isCommandEditVariable = props.isEditCommand;
  let fadeIntoSecondsValue = null;

  const [scriptChanged, setScriptChanged] = useState(false);
  const [expandEditor, setExpandEditor] = useState(false);

  useEffect(() => {
    if (props.currentSceneId !== null) {
      props.fetchBlockchart(
        props.authToken,
        props.storyId,
        props.currentSceneId
      );
      props.fetchCommandsObject(
        props.authToken,
        props.storyId,
        props.currentSceneId
      );
    } else {
      // props.fetchFlowchart(props.authToken, props.storyId);
      setHideIfVisitedArray([]);
      handleSetLevel();
      setSceneId(null);
      setNewSceneName('');
      props.getStoryDetails(props.authToken, props.storyId);
    }
    props.getCommandLevelOne(props.authToken);
    props.getCharacters(props.storyId, props.authToken);
    //props.getRouteCharacters(props.storyId, props.authToken);
    props.fetchAssets(props.authToken, props.storyId);
    props.getViews(props.storyId, props.authToken);

    props.getNewCharacters(props.storyId, props.authToken);
    props.getBackgroundImages(props.storyId, props.authToken);

    props.getListScene(props.storyId, props.authToken);
    props.getCharacterRoutes(props.authToken, props.storyId);
    setPlayFromStart(true);
    setConsoleMessage([]);
    resetPlayerState();
    props.fetchUIElements(props.storyId, props.authToken);
    props.fetchGameFonts(props.authToken, props.storyId);
    setShowFlowchart(false);
    if (
      props.tokenDataUser &&
      props.tokenDataUser.roles !== Constant.SNACK_USER_WRITER
    ) {
      props.fetchLibraryChangeLog(props.authToken, props.storyId);
    }
  }, []);

  useEffect(
    () => {
      if (props.storyDetails) {
        if (props.storyDetails.createdBy) {
          if (
            props.storyDetails.createdBy &&
            (props.storyDetails.createdBy ===
              props.tokenDataUser.userId.toString() ||
              props.storyDetails.storyOwnerOrCollab === true)
          ) {
            props.setPermission();
            setRefreshList(!refreshList);
          } else if (
            props.tokenDataUser &&
            (props.tokenDataUser.roles === Constant.SNACK_USER_EDITOR ||
              props.tokenDataUser.roles === Constant.SNACK_USER_ADMIN)
          ) {
            props.resetPermission();
            setRefreshList(!refreshList);
          }
        }
      }
    },
    [props.storyDetails],
    []
  );

  useEffect(
    () => {
      if (props.currentSceneId !== null && props.currentSceneId !== undefined) {
        props.fetchCommandsObject(
          props.authToken,
          props.storyId,
          props.currentSceneId
        );
        props.getAvailableBlocks(
          props.authToken,
          props.currentSceneId,
          props.storyId
        );
        props.getCharacterRoutes(props.authToken, props.storyId);
      }
      if (props.currentSceneId === null) {
        flowchartNode = null;
        flowchartConnector = null;
        setShowFlowchart(false);
        setExpandEditor(false);
      }

      if (props.commandObj !== null) {
        setcommandData(props.commandObj);
      }
    },
    [props.currentSceneId],
    props.blockData,
    props.commandObj,
    props.avialableBlocks,
    []
  );

  // Place background effects

  useEffect(() => {
    if (props.showModal) {
      props.getBackgroundImages(props.storyId, props.authToken);
      props.getViews(props.storyId, props.authToken);
    }

    if (props.viewId !== null && props.isEditCommand === true) {
      let view = StoryService.getView(props.views, props.viewId);
      setPlaceBackgroundProps(view);
      props.setPlaceBackgroundProperties('backgroundCopy', view.id);
      props.setPlaceBackgroundProperties('name', view.name);
      props.setPlaceBackgroundProperties('characterRoute', view.characterRoute);
      props.setPlaceBackgroundProperties(
        'exportToGallary',
        view.exportToGallary
      );
      props.setPlaceBackgroundProperties('foundInJuicy', view.foundInJuicy);
      props.setPlaceBackgroundProperties('captionText', view.captionText);
    }

    setStepsScriptEnabled(
      props.tutorials.firstTextEditor === false ? true : false
    );

    if (props.activeKey === 'view') {
      setStepsViewEnabled(props.tutorials.firstView === false ? true : false);
    } else {
      setStepsViewEnabled(false);
    }
  }, [
    props.showModal,
    props.viewId,
    props.commandId,
    props.tutorials,
    props.activeKey
  ]);

  // useEffect(() => {
  //   setStepsScriptEnabled(props.tutorials.firstTextEditor === fCreate viewalse ? true : false);
  //   setStepsViewEnabled(props.tutorials.firstView === false ? true : false);
  // }, []);

  useEffect(() => {
    if (props.isRouteEdit === false && editCharRoute === false) {
      let routeList = StoryService.setRouteCharacterLists(
        props.characterList,
        props.storyRoutes,
        props.isRouteEdit,
        props.isRouteEdit == true ? editRouteCharId : null
      );

      setRouteCharacterList(routeList);
      setProgress(0);
      clearInterval(intervalId);
    }
  }, [props.storyRoutes, props.characterList]);

  useEffect(() => {
    let editFile = null;
    let editfileNew = [];
    if (props.isRouteEdit === true && props.routeData) {
      let routeList = StoryService.setRouteCharacterLists(
        props.characterList,
        props.storyRoutes,
        props.isRouteEdit,
        props.isRouteEdit == true ? editRouteCharId : null
      );

      setRouteCharacterList(routeList);
      setShowRouteModal(true);
      routeConstant.characterId = props.routeData.characterId;
      routeConstant.description = props.routeData.description;
      routeConstant.specs = props.routeData.specs;
      routeConstant.chapterLastName = props.routeData.chapterLastName;
      routeConstant.imagePath = props.routeData.imagePath;

      setShowClearFile(props.routeData.imagePath === null ? false : true);
      if (props.routeData.imagePath === null) {
        editFile = props.routeData.imagePath;
        setFiles(editFile);
      } else {
        editfileNew.push(props.routeData.imagePath);
        setFiles(editfileNew);
      }

      setRoute(routeConstant);
      setProgress(0);
      clearInterval(intervalId);
    }
  }, [props.isRouteEdit, props.characterList]);

  const clickedFlowchartPopup = () => {
    setFlowchartPopup(!flowchartPopup);
  };

  const variableWindowClicked = variableWindoOnOff => {
    setVariableWindoOnOffStatus(variableWindoOnOff);
    if (variableWindoOnOff === true) {
      if (props.flowchartHasErrors !== true) {
        setActiveTabSelected('Preview');
        setPreviewScroll(true);
      } else {
        setActiveTabSelected('Flowchart');
      }
    } else {
      props.updateVariableWindowCheck([], []);
      props.fetchResetVariableWindow(
        props.authToken,
        props.storyId,
        props.currentSceneId
      );
    }
  };

  const updateVariableWithId = (variableId, variableUpdatedValue, clicked) => {
    let localVariable = props.localVariableListDTO;
    let localChangedVariable = props.changeVariableIds;
    if (localVariable && localVariable.length > 0) {
      let variableIndex = localVariable.findIndex(
        variable => parseInt(variable.id) === parseInt(variableId)
      );
      if (variableIndex >= 0) {
        if (
          clicked === 'reset' ||
          props.localVariableListDTO[variableIndex].defaultValue ===
            variableUpdatedValue
        ) {
          localChangedVariable = props.changeVariableIds.filter(
            ids => ids !== variableId
          );
        } else {
          localChangedVariable.push(variableId);
        }
        localVariable[variableIndex].value = variableUpdatedValue;
        props.updateVariableWindowCheck(localVariable, localChangedVariable);
      }
    }
  };

  const handleCommnadShortCutClick = () => {
    setshowShortCut(!showShortCut);
  };

  const handleCreateScene = data => {
    props.createScene(props.authToken, props.storyId, data);
    setShowChapterModal(false);
  };

  const handleSaveList = chapterList => {
    props.saveListScene(props.authToken, props.storyId, chapterList);
    setEditChapter(0);
    setShowChapterModal(false);
  };

  const handleClickEvent = (clickedEvent, tabofView = 'placeView') => {
    setClickedEventCheck(clickedEvent);
    setTabButtonClicked(tabofView);
    setCreateViewModal(true);
  };

  const handleCommnadMenuClick = commandId => {
    props.resetCommandValue();
    props.getCommandLevelTwo(
      props.authToken,
      props.storyId,
      commandId,
      props.currentSceneId
    );
    setCommandId(commandId);
    props.setLevel(Constant.COMMAND_LEVEL_TWO);
    setValidated(false);
    props.setIsValidText('');
    setEditBlockName(null);
    if (commandId === Constant.COMMAND_GO_TO_BLOCK) {
      props.getAvailableBlocks(
        props.authToken,
        props.currentSceneId,
        props.storyId
      );
    } else if (commandId === Constant.COMMAND_PLACE_VIEW) {
      props.setPlaceBackgroundProperties('characterRoute', '');
      props.setPlaceBackgroundProperties('exportToGallary', false);
      props.setPlaceBackgroundProperties('captionText', '');
    } else if (
      commandId === Constant.COMMAND_ADD_IF ||
      commandId === Constant.COMMAND_ADD_END ||
      commandId === Constant.COMMAND_ADD_ELSE ||
      commandId === Constant.COMMAND_ADD_ELSE_IF
    ) {
      setInitialCommandStep(0);
      setStepsCommandEnabled(
        props.tutorials.firstConditionalCommand === false ? true : false
      );
      setStepsCommand(Constant.stepIfCommand);
    }
    //choice command
    else if (commandId === Constant.COMMAND_PAID_ADD_DECISION) {
      setInitialCommandStep(0);
      setStepsCommandEnabled(
        props.tutorials.firstChoiceCommand === false ? true : false
      );
      setStepsCommand(Constant.stepChoiceCommand);
    }
  };

  const editCommand = (lineNo, cmdId) => {
    if (cmdId === null) {
      cmdId = props.commandObj.commands[lineNo].commandId;
    }
    if (
      props.commandObj &&
      ((props.commandObj.commands[lineNo].errors &&
        props.commandObj.commands[lineNo].errors.length == 0) ||
        props.commandObj.commands[lineNo].errors == undefined) &&
      props.autoSaveFlag !== true
    ) {
      if (
        cmdId !== Constant.COMMAND_PLACE_VIEW &&
        cmdId !== Constant.COMMAND_MOVE_BACKGROUND
      ) {
        if (cmdId === Constant.COMMAND_ADD_BLOCK) {
          setEditBlockName(
            props.commandObj.commands[lineNo].cmdText
              .replace('block ', '')
              .trim()
          );
        } else {
          setEditBlockName(null);
        }
        props.setLevel(Constant.COMMAND_LEVEL_ONE);
        props.resetCommandValue();
        props.fetcEditCommandObject(
          props.authToken,
          props.storyId,
          props.currentSceneId,
          lineNo + 1
        );
        props.setLevel(Constant.COMMAND_LEVEL_TWO);
        setValidated(false);
        props.setIsValidText('');
        props.getAvailableBlocks(
          props.authToken,
          props.currentSceneId,
          props.storyId
        );
      } else if (cmdId === Constant.COMMAND_PLACE_VIEW) {
        props.fetcEditCommandObject(
          props.authToken,
          props.storyId,
          props.currentSceneId,
          lineNo + 1
        );
        setShowEditViewCommand(true);
        props.setLevel(Constant.COMMAND_LEVEL_ONE);
      } else if (cmdId === Constant.COMMAND_MOVE_BACKGROUND) {
        props.fetcEditCommandObject(
          props.authToken,
          props.storyId,
          props.currentSceneId,
          lineNo + 1
        );
        props.resetPlaceBackground();
        setMoveShowPlaceBackground(true);
        props.setLevel(Constant.COMMAND_LEVEL_ONE);
        props.setPlaceBackgroundProperties('activeKey', 'view');
      }
    }

    if (props.autoSaveFlag === true) {
      setEditWarningTextEditor(true);
    } else if (
      props.commandObj &&
      props.commandObj.commands[lineNo].errors &&
      props.commandObj.commands[lineNo].errors.length > 0
    ) {
      setEditWarningTextEditor(true);
      setEditErrorTextEditor(true);
    }
  };

  const handleSetLevel = () => {
    props.setLevel(Constant.COMMAND_LEVEL_ONE);
    isCommandEditVariable = false;
  };

  const handleNodeClickforBlock = (
    blockId,
    routeId = null,
    changeLogScript = false,
    isCompareFlagPass = false
  ) => {
    props.resetCommandEditData();
    props.resetPreviewData();
    setActiveTabSelected('Flowchart');
    setCharacterRouteId(routeId);
    setPreviewScroll(false);
    setHighlightBlock(false);
    setTextEditorValidate(false);
    setIndentButtonClick(false);
    setCommentLineNo(null);
    setComamndSavePanel('start');

    props.fetchBlockchart(props.authToken, props.storyId, blockId);
    setCompareFlag(isCompareFlagPass);
  };

  const handleChangeLogScript = blockId => {
    setShowFlowchart(false);
    props.fetchChangeLogScript(props.authToken, props.storyId, blockId);
  };

  const changeLogModalClose = () => {
    props.modalShowChangeLog(false);
  };

  const handleNodeClickforFlowchartNode = blockId => {
    // setShowFlowchart(false);
    const indexFound = props.flowchartNode.findIndex(
      block => block.id === blockId
    );
    if (indexFound >= 0) {
      props.setCursorPosition(props.flowchartNode[indexFound].index);
      setBlockClickedEvent(true);
      props.clickOnFlowchartNode(blockId);
    }
  };

  const handleCommandPanel = () => {
    if ('block' === commandPanelToggle) {
      setCommandPanelShow('fa fa-angle-right');
      setTimeout(function() {
        setCommandPanelToggle('none');
      }, 100);
    } else {
      setCommandPanelShow('fa fa-angle-left');
      setTimeout(function() {
        setCommandPanelToggle('block');
      }, 100);
    }
  };

  const scrollToBottom = () => {
    let scrollingElement = document.scrollingElement || document.body;
    scrollingElement.scrollTop = scrollingElement.scrollHeight + 100;
  };

  const handleSaveCommandInfo = (event, syntax, color, setTotalFormInput) => {
    const form = event.currentTarget;

    const olderLength = props.commandObj.commands.length;
    let commandDataBkup = props.commandObj;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
    } else {
      let cmdId = props.isEditCommand ? props.commandId : commandId;
      let newBlock = null;

      if (cmdId === Constant.COMMAND_ADD_BLOCK && props.isEditCommand) {
        newBlock = form['blockNameTextBox'].value;
      }
      switch (cmdId) {
        default:
          commandDataBkup = StoryService.saveCommand(
            setTotalFormInput,
            commandObj,
            commandDataBkup,
            props.isEditCommand ? props.commandId : commandId,
            color,
            form,
            props.cursorPostion,
            props.isEditCommand,
            syntax,
            props.characterListForScript
          );

          if (props.isEditCommand && editBlockName !== null) {
            props.setCommandsData(
              commandDataBkup,
              props.authToken,
              props.storyId,
              props.currentSceneId,
              false,
              editBlockName,
              newBlock
            );
          }
          //else {
          //
          // props.setCommandsDataPanel(
          //   commandDataBkup,
          //   props.authToken,
          //   props.storyId,
          //   props.currentSceneId,
          //   false,
          //   editBlockName,
          //   newBlock
          // );
          // }
          props.setLevel(Constant.COMMAND_LEVEL_ONE);
          // props.fetchBlockchart(props.authToken,props.storyId, props.currentSceneId)
          scrollToBottom();
          break;
      }
      event.preventDefault();
      if (props.isEditCommand) {
        setComamndSavePanel(null);
      } else {
        if (props.cursorPostion === null) {
          setTimeout(() => setComamndSavePanel(true), 100);
        } else if (
          props.commandObj &&
          parseInt(props.commandObj.commands.length) -
            parseInt(props.cursorPostion === null ? 0 : props.cursorPostion) ==
            2
        ) {
          setTimeout(() => setComamndSavePanel(true), 100);
        } else if (
          props.commandObj &&
          parseInt(props.commandObj.commands.length) - parseInt(olderLength) ==
            0
        ) {
          setTimeout(() => setComamndSavePanel(null), 100);
        } else if (
          props.commandObj &&
          parseInt(props.commandObj.commands.length) - parseInt(olderLength) ===
            1
        ) {
          setTimeout(() => setComamndSavePanel(false), 100);
        } else {
          setTimeout(() => setComamndSavePanel(true), 100);
        }
      }
      setTextEditorValidate(false);
      setIndentButtonClick(false);
    }
    setValidated(true);

    event.stopPropagation();
    resetPlayerState();
    setScriptChanged(new Date().getTime());
  };

  const handleFlowchartView = () => {
    if ('inline-block' === flowchartToggle) {
      setflowchartToggle('none');
      setbackgroundColor('#F5F5FA');
    } else {
      setflowchartToggle('inline-block');
      setbackgroundColor('#FFFFFF');
    }
  };

  let sceneColor = '#530ACD';
  if (props.showingSceneFlag === false) {
    sceneColor = '#ACACAC';
  }

  if (
    props.isValidText === true &&
    props.blockData.hasOwnProperty('commands')
  ) {
    props.setIsValidText(false);
    props.setIsValidText('');
    props.setLevel(Constant.COMMAND_LEVEL_ONE);
    let updatedFlowchartData = StoryService.saveCommand(
      totalFormInput,
      commandObj,
      props.commandObj,
      commandId,
      '',
      '',
      props.cursorPostion
    );
    // props.setCommandsData(updatedFlowchartData, props.authToken, props.storyId, props.currentSceneId);
  }

  const handleSaveReducerForHeader = commandArrayValues => {
    if (commandArrayValues.length <= 0) {
      commandArrayValues = null;
    }
    if (commandArrayValues !== null) {
      props.getCommandObjUpdated(commandArrayValues);
      setActiveTabSelected('Flowchart');
      setHighlightBlock(false);
    }

    resetPlayerState();
  };

  const handleTextFieldChange = () => {
    props.setIsValidText('');
  };

  const onHidePlaceBackgroundModal = () => {
    setShowPlaceBackground(false);
    //setEditView(false);
    setMoveShowPlaceBackground(false);
    if (props.background) {
      setShowCancelPlaceBackgroundWindow(true);
    }

    //dont delete this required for edit view
    props.resetCommandEditData();
    props.resetPreviewData();
    props.setActiveKey('browse');
  };

  const onHideMovePlaceBackgroundModal = () => {
    setMoveShowPlaceBackground(false);
    setShowCancelMovePlaceBackgroundWindow(true);
  };

  const handlePlaceViewMenuClick = () => {
    props.resetPlaceBackground();
    setShowPlaceBackground(true);
    setSelectBackground(true);
    setAddObject(false);
    props.setPlaceBackgroundProperties('activeKey', 'browse');
  };

  const handlePlaceMoveViewMenuClick = () => {
    props.resetPlaceBackground();
    setMoveShowPlaceBackground(true);
    props.setPlaceBackgroundProperties('activeKey', 'view');
  };

  const hideCancelPlaceBackgroundWindow = () => {
    setShowCancelPlaceBackgroundWindow(false);
    setShowPlaceBackground(true);
    setMoveShowPlaceBackground(false);
    //dont delete this required for edit view
    props.resetCommandEditData();
    props.resetPreviewData();
  };

  const onLeavePlaceBackground = () => {
    setShowCancelPlaceBackgroundWindow(false);
    setShowPlaceBackground(false);
    setMoveShowPlaceBackground(false);
  };

  const hideCancelMovePlaceBackgroundWindow = () => {
    setShowCancelMovePlaceBackgroundWindow(false);
    setMoveShowPlaceBackground(true);
  };

  const onLeaveMovePlaceBackground = () => {
    setShowCancelMovePlaceBackgroundWindow(false);
    setMoveShowPlaceBackground(false);
    props.resetCommandEditData();
  };

  const handleDialogueDisplayEvent = () => {
    setPlay(!play);
  };

  const resetFlowchart = isReleaseLock => {
    props.setLevel(Constant.COMMAND_LEVEL_ONE);
    if (isReleaseLock) {
      props.releseLock(props.authToken, props.storyId, props.currentSceneId);
    }
    props.resetCommandObject();
    props.resetFlowChartData();
    setConsoleMessage([]);
  };

  const releaseLockChapter = (currentChapterId, isReleaseLock) => {
    props.releseLock(props.authToken, props.storyId, currentChapterId);
  };

  const preventScriptCall = isReleaseLock => {
    setReleaseLockCheck(isReleaseLock);
    if (props.autoSaveFlag === true) {
      setAutoSaveFlagCheckBack(true);
    } else {
      resetFlowchart(isReleaseLock);
    }
    props.getCharacterRoutes(props.authToken, props.storyId);
  };

  const closeAutoSaveFlagCheck = () => {
    props.autopopupSave(false);
    setAutoSaveFlagCheckBack(false);
    resetFlowchart(releaseLockCheck);
  };

  const closeAutoSaveFlagCheckClose = () => {
    setAutoSaveFlagCheckBack(false)
  };

  const editWarningCommandOkay = () => {
    setEditWarningTextEditor(false);
    setEditErrorTextEditor(false);
    setViewErrorTextEditor(false);
    setViewWarningTextEditor(false);
  };

  const onClickYes = () => {
    props.setCommandsData(
      props.commandObj,
      props.authToken,
      props.storyId,
      props.currentSceneId,
      true
    );
    props.autopopupSave(false);
    setAutoSaveFlagCheckBack(false);
    resetFlowchart(releaseLockCheck);
  };

  const handlePlayerButtonsClick = (
    e,
    action,
    clickedOnExternalButton = false,
    indexes = {}
  ) => {
    let isChapterEnd = PlayerService.isChapterEnd(
      props.flowchartPreviewData,
      currentBlock,
      currentCommand
    );
    if (clickedOnExternalButton === false && isChapterEnd) {
      return false;
    }
    PlayerService.clearAllTimeout(commandsTimeoutIds);
    switch (action) {
      case Constant.EVENT_FIRST:
        if (props.flowchartHasErrors === true || textEditorValidate === false) {
          setShowScriptError(true);
          return;
        }
        PlayerService.clearPreviewDocument();
        resetPlayerState();
        setCurrentScene(0);
        setCurrentBlock(0);
        setCurrentCommand(0);
        setFastPreview(false);
        setCheckCommandState(false);
        setPlay(false);
        setShowEnd(false);
        setTimeout(function() {
          setPlay(true);
          setExternalPlayPauseHandler(true);
        }, 1000);
        break;

      case Constant.EVENT_PLAY:
        PlayerService.clearPreviewDocument();
        if (props.flowchartHasErrors === true || textEditorValidate === false) {
          setShowScriptError(true);
          return;
        }
        if (playFromStart === true) {
          props.handlePreviewModal(
            props.flowchartPreviewData,
            aspectRatio,
            props.interfaceList
          );
          setPlay(true);
          setShowEnd(false);
          setExternalPlayPauseHandler(true);
          setPlayFromStart(false);
          setCurrentScene(0);
          setCurrentBlock(0);
          setCurrentCommand(0);
        } else {
          let nextIndexes = PlayerService.getCurrentStateOfCommandExecution(
            props.flowchartPreviewData,
            {
              currentScene: currentScene,
              currentBlock: currentBlock,
              currentCommand: currentCommand
            },
            props.playOn,
            command,
            externalPlayPauseHandler,
            indexes
          );

          setCurrentScene(nextIndexes.scene);
          setCurrentBlock(nextIndexes.block);
          setCurrentCommand(nextIndexes.command);
          setFastPreview(true);
          setPlay(true);
          setCheckCommandState(true);
          setShowEnd(false);
          setExternalPlayPauseHandler(true);
          setConsoleMessage([]);
          if (
            (command && command.commandId === Constant.COMMAND_SAY_GENDERED) ||
            command.commandId === Constant.COMMAND_ADD_DIALOGUE
          ) {
            setShowDialogue(false);
          }
        }
        break;

      case Constant.EVENT_PAUSE:
        setPlay(false);
        setExternalPlayPauseHandler(false);
        break;
      default:
        break;
    }
  };

  const handleDecisionClick = (
    value,
    affinityType = null,
    affinityWeight = null,
    hideIfVisited = null,
    lineNumber = null,
    blockName = null
  ) => {
    PlayerService.clearAllTimeout(commandsTimeoutIds);
    PlayerService.clearAllTimeout(choiceTimeout);
    PlayerService.clearPreviewDocument();
    let dataHide = hideIfVisitedArray;
    let flowchartData = null;
    if (props.playOn === 'scenes') {
      flowchartData = props.flowchartPreviewData;
    } else {
      flowchartData = props.flowchartPreviewData;
    }

    // Set upright value
    if (affinityType !== null && affinityWeight !== null) {
      calculateAffinity(affinityType, affinityWeight);
    }

    if (hideIfVisited == 'true') {
      const hideChoiceData = {
        destination: value,
        lineNumber: lineNumber,
        hideIfVisited: hideIfVisited,
        blockName: blockName
      };

      dataHide.push(hideChoiceData);
      setHideIfVisitedArray(dataHide);
    }

    let indexes = PlayerService.collectIndexes(flowchartData, value);
    setCurrentBlock(indexes[1]);
    setCurrentScene(indexes[0]);
    setCurrentCommand(0);
    setFastPreview(true);
    setIfElseDecisionParameters([]);

    setCheckCommandState(false);
    setGotoPlay(!gotoPlay);
    setShowDecision(false);
  };

  const handlePlaceViewScript = (viewName, fadeintoseconds = 1) => {
    if (!viewName) {
      let view = PlaceBackgroundService.getView(
        props.backgroundCopy,
        props.views
      );
      if (view && view.length > 0) {
        viewName = view[0].name;
      }
    }

    let commandObjPlaceView = {
      commandId: Constant.COMMAND_PLACE_VIEW,
      cmdText: `view ${viewName} ${fadeintoseconds}`,
      blockCommand: false,
      deleted: false,
      modified: true
    };

    let commandObjCopy = props.commandObj;
    if (props.isEditCommand) {
      setComamndSavePanel(null);
      commandObjCopy.commands.splice(
        props.cursorPostion,
        1,
        commandObjPlaceView
      );
    } else {
      if (props.cursorPostion >= 0) {
        if (
          commandObjCopy.commands[0].cmdText.trim() === '' &&
          (commandObjCopy.commands.length === 1 || props.cursorPostion === 0) &&
          !props.isCommandEdit
        ) {
          commandObjCopy.commands[0] = commandObjPlaceView;
          setComamndSavePanel(null);
        } else if (
          commandObjCopy.commands[props.cursorPostion] &&
          commandObjCopy.commands[props.cursorPostion].cmdText.trim() === ''
        ) {
          commandObjCopy.commands[props.cursorPostion] = commandObjPlaceView;
          setComamndSavePanel(null);
        } else {
          setComamndSavePanel(false);
          commandObjCopy.commands.splice(
            props.cursorPostion + 1,
            0,
            commandObjPlaceView
          );
        }
      } else {
        setComamndSavePanel(true);
        commandObjCopy.commands.push(commandObjPlaceView);
      }
    }
    setShowPlaceBackground(false);
    setShowEditViewCommand(false);
    // setTimeout(
    //   () =>
    //     props.setCommandsDataPanel(
    //       commandObjCopy,
    //       props.authToken,
    //       props.storyId,
    //       props.currentSceneId
    //     ),
    //   1000
    // );
    setScriptChanged(new Date().getTime());
    props.resetStoryView();
  };

  const handleMovePlaceViewScript = (destinantioName, transition) => {
    let finalstring = `${destinantioName} ${
      transition === '1' ? '' : transition
    }`;
    let commandObjPlaceView = {
      commandId: Constant.COMMAND_MOVE_BACKGROUND,
      cmdText: `moveto ${finalstring.trim()}`,
      blockCommand: false,
      deleted: false,
      modified: true
    };

    let commandObjCopy = props.commandObj;

    if (props.isEditCommand) {
      setComamndSavePanel(null);
      commandObjCopy.commands.splice(
        props.cursorPostion,
        1,
        commandObjPlaceView
      );
    } else {
      if (props.cursorPostion >= 0) {
        if (
          commandObjCopy.commands[0].cmdText.trim() === '' &&
          (commandObjCopy.commands.length === 1 || props.cursorPostion === 0) &&
          !props.isCommandEdit
        ) {
          commandObjCopy.commands[0] = commandObjPlaceView;
          setComamndSavePanel(null);
        } else if (
          commandObjCopy.commands[props.cursorPostion] &&
          commandObjCopy.commands[props.cursorPostion].cmdText.trim() === ''
        ) {
          commandObjCopy.commands[props.cursorPostion] = commandObjPlaceView;
          setComamndSavePanel(null);
        } else {
          setComamndSavePanel(false);
          commandObjCopy.commands.splice(
            props.cursorPostion + 1,
            0,
            commandObjPlaceView
          );
        }
      } else {
        setComamndSavePanel(true);
        commandObjCopy.commands.push(commandObjPlaceView);
      }
    }
    setMoveShowPlaceBackground(false);
    // props.setCommandsDataPanel(
    //   commandObjCopy,
    //   props.authToken,
    //   props.storyId,
    //   props.currentSceneId
    // );
    setScriptChanged(new Date().getTime());
    props.resetCommandEditData();
  };

  const changeCursorPosition = index => {
    props.setCursorPosition(index);
    setCommentLineNo(index);
  };

  const handleAutoSaveStory = event => {
    props.setCommandsDataAutoSave(
      props.commandObj,
      props.authToken,
      props.storyId,
      props.currentSceneId
    );
  };

  const showEditorModal = (lineNo, pageX, pageY) => {
    if (
      props.commandObj &&
      ((props.commandObj.commands[lineNo].errors &&
        props.commandObj.commands[lineNo].errors.length == 0) ||
        props.commandObj.commands[lineNo].errors == undefined) &&
      props.autoSaveFlag !== true
    ) {
      props.fetchPreviewAssetObject(
        props.authToken,
        props.storyId,
        props.currentSceneId,
        lineNo + 1
      );
      setpageX(pageX);
      setpageY(pageY);
      setshowCharModal(true);
    }

    if (props.autoSaveFlag === true) {
      setViewWarningTextEditor(true);
    } else if (
      props.commandObj &&
      props.commandObj.commands[lineNo].errors &&
      props.commandObj.commands[lineNo].errors.length > 0
    ) {
      setViewWarningTextEditor(true);
      setViewErrorTextEditor(true);
    }
  };

  const hideCharModal = () => {
    props.resetPreviewData();
    setshowCharModal(false);
  };

  // Place backgrounds methods
  const handleSaveForLaterClickEvent = clickedEvent => {
    setClickedEventCheck(true);
    setCreateViewModal(true);
    setTabButtonClicked('placeView');
  };

  const handleChange = event => {
    props.setPlaceBackgroundProperties([event.target.name], event.target.value);
    if (event.target.name === 'background') {
      props.getOtherViewOfCurrentBackground(
        props.authToken,
        props.storyId,
        event.target.value
      );
      props.setPlaceBackgroundProperties('x', 0);
      props.setPlaceBackgroundProperties('y', 0);
      props.setPlaceBackgroundProperties('height', 0);
      props.setPlaceBackgroundProperties('width', 0);
    }
    if (event.target.name === 'name') {
      setDuplicateName(false);
    }
  };

  const saveForLater = event => {
    const form = event.currentTarget;
    const checkDuplicate = checkViewNameDuplicate(props.name.trim());
    const originalView = props.views.filter(
      item => item.id === props.backgroundCopy
    );

    if (form.checkValidity() === false || checkDuplicate === true) {
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();

      let viewObject = {
        asset: { id: props.background },
        height: Math.round(props.height),
        width: Math.round(props.width),
        xcoordinate: props.xcoordinate,
        ycoordinate: props.ycoordinate,
        name: props.name,
        viewObjects: props.viewObjects,
        exportToGallary: false,
        foundInJuicy: false,
        characterRoute: '',
        captionText: ''
      };

      let newObjects = ImageMapper.prepareCoordinatesByViewPort(
        props.viewObjects,
        viewObject.width,
        viewObject.height,
        AspectRatioConfig.cropPanelWidth,
        AspectRatioConfig.cropPanelHeight
      );

      viewObject['viewObjects'] = newObjects;

      if (props.backgroundCopy != null) {
        viewObject.id = props.backgroundCopy;
      }

      if (clickedEventCheck === false) {
        if (form['exporttogallery'].value !== 'false') {
          viewObject['exportToGallary'] = true;
          viewObject['characterRoute'] = form['addtogallery'].value;
          viewObject['captionText'] = form['caption'].value;
          viewObject['foundInJuicy'] = form['foundInJuicy'].value;
        }

        if (browseViewId !== null) {
          viewObject['asset'] = { id: browseViewId };
        }
      }

      if (clickedEventCheck === false) {
        fadeIntoSecondsValue = form['fadeintoseconds'].value;
      }

      if (originalView.length > 0 && originalView[0].name !== props.name) {
        setShowSaveConfirmation(true);
        props.saveViewObjectFunction(viewObject, fadeIntoSecondsValue);
      } else {
        saveView(viewObject);

        if (clickedEventCheck === false) {
          const fadeintoseconds = form['fadeintoseconds'].value;
          setTimeout(function() {
            handlePlaceViewScript(props.name, fadeintoseconds);
          }, 2000);
        }
      }

      setCreateViewModal(false);
      props.setActiveKey('browse');
      setClickedEventCheck(true);
      setBrowseViewId(null);
    }
    event.preventDefault();
    setDuplicateName(checkDuplicate);
    props.setPlaceBackgroundProperties('validated', true);
  };

  const saveView = (viewObject, isEdit = false) => {
    let saveViewObject = isEdit === false ? viewObject : props.saveViewObject;
    props.saveForLater(saveViewObject, props.storyId, props.authToken);
    if (props.fadeIntoSeconds !== null) {
      setTimeout(function() {
        handlePlaceViewScript(props.name, props.fadeIntoSeconds);
      }, 2000);
    }

    setSearchText('');
    setSelectBackground(true);
    setAddObject(false);
    setCreateViewModal(false);
    setClickedEventCheck(true);
    setBrowseViewId(null);
    setShowSaveConfirmation(false);

    props.setActiveKey('browse');
    props.resetViews();
    props.resetSaveViewObject();
    // props.setCommandsData(props.commandObj, props.authToken, props.storyId, props.currentSceneId);
  };

  const checkViewNameDuplicate = name => {
    if (tabButtonClicked === 'placeView') {
      return StoryService.checkDuplicateName(
        props.views,
        name,
        props.backgroundCopy
      );
    } else {
      return false;
    }
  };

  const handleReorderObjectList = objectLists => {
    props.reOrderObject(objectLists);
  };

  const handlePreviewDataClick = () => {
    resetPlayerState();
    PlayerService.clearPlayer();
    props.fetchPreviewDataOnClick(
      props.authToken,
      props.storyId,
      props.currentSceneId
    );

    let nombreId = PlayerService.getVariableId(
      props.localVariableListDTO,
      Constant.reservedVariables[0]
    );
    let pronounsId = PlayerService.getVariableId(
      props.localVariableListDTO,
      Constant.reservedVariables[1]
    );
    let uprightId = PlayerService.getVariableId(
      props.localVariableListDTO,
      Constant.reservedVariables[2]
    );
    if (variableWindoOnOffStatus === false) {
      updateVariableWithId(nombreId, 'Player', 'reset');
      updateVariableWithId(pronounsId, '', 'reset');
      updateVariableWithId(uprightId, 0, 'reset');
      setConsoleMessage([]);
    }
  };

  const setCrop = crop => {
    props.setPlaceBackgroundProperties('x', Math.round(crop.detail.x));
    props.setPlaceBackgroundProperties('y', Math.round(crop.detail.y));
    props.setPlaceBackgroundProperties(
      'height',
      Math.round(crop.detail.height)
    );
    props.setPlaceBackgroundProperties('width', Math.round(crop.detail.width));
  };

  const handleBrowseSelection = id => {
    setEditView(true);
    if (id === props.backgroundCopy) {
      props.setPlaceBackgroundProperties('backgroundCopy', null);
      props.setPlaceBackgroundProperties('name', '');
      props.setPlaceBackgroundProperties('exportToGallary', false);
      props.setPlaceBackgroundProperties('characterRoute', '');
      props.setPlaceBackgroundProperties('captionText', '');

      props.setPlaceBackgroundProperties('background', null);
    } else {
      props.setPlaceBackgroundProperties('backgroundCopy', id);
      const viewToSet = StoryService.getView(props.views, id);
      //commented the line which set name when browse view
      props.setPlaceBackgroundProperties('name', viewToSet.name);
      props.setPlaceBackgroundProperties(
        'exportToGallary',
        viewToSet.exportToGallary
      );
      props.setPlaceBackgroundProperties('captionText', viewToSet.captionText);
      props.setPlaceBackgroundProperties(
        'foundInJuicy',
        viewToSet.foundInJuicy
      );
      props.setPlaceBackgroundProperties(
        'characterRoute',
        viewToSet.characterRoute
      );
      setPlaceBackgroundProps(viewToSet, 'browse');
      props.getViewsEditable(props.storyId, id, props.authToken);

      //props.setPlaceBackgroundProperties('background', viewToSet.asset.id);
    }
  };

  const handleDuplicateClick = () => {
    setEditView(true);
    props.resetViews();
    let view = StoryService.getView(props.views, props.backgroundCopy);
    props.getOtherViewOfCurrentBackground(
      props.authToken,
      props.storyId,
      view.asset.id
    );
    setPlaceBackgroundProps(view);
  };

  const handleViewSearch = event => {
    let searchViews = StoryService.searchText(props.views, event.target.value);
    props.setPlaceBackgroundProperties('searchViews', searchViews);
    setSearchText(event.target.value);
  };

  const onHideAddObjectsForm = () => {
    setShowAddObjectForm(false);
  };

  const onCancelClickAddObject = () => {
    setShowAddObjectForm(false);
  };

  const handleObjectDeleteClick = id => {
    let objectList = props.viewObjects.filter((item, index) => index !== id);
    props.setObject(objectList);
  };

  const handleEditObjectClick = id => {
    setShowAddObjectForm(true);
    setEditObjectIndex(id);
  };

  const handleAddObjectClick = () => {
    setShowAddObjectForm(true);
    setEditObjectIndex(null);
  };

  const hideCreateViewModal = () => {
    setCreateViewModal(false);
  };

  const handleViewEditClick = () => {
    if (!props.editableFlag) {
      setCollaboratorModal(false);
      let view = StoryService.getView(props.views, props.backgroundCopy);
      props.getOtherViewOfCurrentBackground(
        props.authToken,
        props.storyId,
        view.asset.id
      );
      setPlaceBackgroundProps(view);
      props.setPlaceBackgroundProperties('name', view.name);
      props.setPlaceBackgroundProperties(
        'exportToGallary',
        view.exportToGallary
      );
      props.setPlaceBackgroundProperties('foundInJuicy', view.foundInJuicy);
      props.setPlaceBackgroundProperties('characterRoute', view.characterRoute);
    } else {
      setCollaboratorModal(true);
    }
  };

  const setPlaceBackgroundProps = (view, fromTab = 'view') => {
    let viewPortWidth = AspectRatioConfig.cropPanelWidth;
    let viewPortHeight = AspectRatioConfig.cropPanelHeight;
    let newObjects = ImageMapper.prepareCoordinatesByViewPort(
      view.viewObjects,
      view.width,
      view.height,
      viewPortWidth,
      viewPortHeight,
      false
    );

    if (fromTab !== 'browse') {
      props.setPlaceBackgroundProperties('background', view.asset.id);
      setBrowseViewId(view.asset.id);
      props.setPlaceBackgroundProperties('activeKey', 'view');
    } else {
      setBrowseViewId(view.asset.id);
    }
    props.setPlaceBackgroundProperties('x', view.xcoordinate);
    props.setPlaceBackgroundProperties('y', view.ycoordinate);
    props.setPlaceBackgroundProperties('height', view.height);
    props.setPlaceBackgroundProperties('width', view.width);
    props.setPlaceBackgroundProperties('exportToGallary', view.exportToGallary);
    props.setPlaceBackgroundProperties('captionText', view.captionText);
    props.setPlaceBackgroundProperties('foundInJuicy', view.foundInJuicy);
    props.setPlaceBackgroundProperties('characterRoute', view.characterRoute);
    props.setPlaceBackgroundProperties('viewObjects', newObjects);
  };

  ///Comment Section Starts Here
  const onAddCommentClick = (commentLine, commands, cursorPostion) => {
    if (commentLineNo !== null) {
      let commandlength = cursorPostion;
      let blockCommentName = '';
      for (let i = 0; i <= commandlength; i++) {
        if (commands[i].blockCommand === true) {
          blockCommentName = commands[i].cmdText.replace('block', '');
        }
      }
      setCommentLineNo(commentLine);
      setCommentCmdText(commands[cursorPostion].cmdText); // commentCmdText,commentBlockText
      setCommentBlockText(blockCommentName);
      setComments({
        ...comments,
        lineNo: commentLine
      });
    }
    setCommentsModal(true);
  };

  const changeCommentPoup = () => {
    setComments({
      ...comments,
      lineNo: null
    });
    setCommentLineNo(null);
  };

  const hideCommentsModal = () => {
    setCommentLineNo(null);
    setCommentCmdText('');
    setCommentBlockText('');
    setCommentsModal(false);
  };

  const onHideCommentStatusModal = () => {
    props.hideCommentStatus();
  };

  const handleSaveInfo = event => {
    let formData = new FormData();

    props.setCommentsData(
      comments,
      props.authToken,
      props.storyId,
      props.currentSceneId
    );
    // setHideIfVisitedArray([])
    setCommentsModal(false);
    event.preventDefault();
  };

  const calculateAffinity = (type, weight) => {
    if (type !== '') {
      let uprightVariable = PlayerService.getVariable(
        props.localVariableListDTO,
        Constant.reservedVariables[2]
      );
      let upright = 0;
      if (typeof uprightVariable.value === 'string') {
        upright = uprightVariable.value === 'true' ? 1 : 0;
      } else {
        upright = parseInt(uprightVariable.value);
      }
      upright = upright + Constant.affinityWeight[type][weight];
      updateVariableWithId(uprightVariable.id, upright, 'normal');
    }
  };

  const handleSaveStory = event => {
    setHideIfVisitedArray([]);
    props.setCommandsData(
      props.commandObj,
      props.authToken,
      props.storyId,
      props.currentSceneId
    );
    
  };

  // const handleAutoSaveStory = event => {

  //   props.setCommandsDataAutoSave(
  //     props.commandObj,
  //     props.authToken,
  //     props.storyId,
  //     props.currentSceneId
  //   );
  // };

  const setDialogueVariables = (name, pronouns) => {
    let nombreId = PlayerService.getVariableId(
      props.localVariableListDTO,
      Constant.reservedVariables[0]
    );
    let pronounsId = PlayerService.getVariableId(
      props.localVariableListDTO,
      Constant.reservedVariables[1]
    );
    updateVariableWithId(nombreId, name, 'normal');
    updateVariableWithId(pronounsId, pronouns, 'normal');
  };

  ///// Create new chapter Functions
  const handleShowChapterModal = () => {
    setShowChapterModal(true);
    setEditChapter(0);
  };

  const handleHideChapterModal = () => {
    setShowChapterModal(false);
  };

  const handleShowRouteModal = () => {
    setFiles(null);
    setRoute(routeConstant);
    setShowClearFile(false);
    setShowRouteModal(true);
    setRouteValidated(false);
    setRouteCharacterList([]);
    props.getCharacters(props.storyId, props.authToken);
    setRouteButtonState(false);

    // let routeCharList = StoryService.setRouteCharacterLists(routeCharacterList, props.storyRoutes, props.isRouteEdit, null)

    // setRouteCharacterList(routeCharList)
  };
  const handleHideRouteModal = () => {
    setFiles(null);
    setRoute(routeConstant);
    setShowClearFile(false);
    setShowRouteModal(false);
    setEditRouteId(null);
    setProgress(0);
    setEditCharRoute(false);
    setEditRouteCharId(null);
    setRouteCharacterList([]);
    props.resetRouteModal();
    props.getCharacters(props.storyId, props.authToken);
    props.getCharacterRoutes(props.authToken, props.storyId);
    clearInterval(intervalId);
    setRouteButtonState(true);
  };

  const handleCreateRoute = event => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      setRouteButtonState(true);
      setEditCharRoute(false);
      setShowRouteModal(false);
      setRouteCharacterList([]);
      const intervalId = interval();
      setIntervalId(intervalId);
      props.saveRoute(
        StoryService.getrouteFormData(route, files, props.isRouteEdit),
        props.authToken,
        props.storyId,
        props.isRouteEdit,
        editRouteId
      );
    }
    setShowRouteModal(false);
    event.preventDefault();
    event.stopPropagation();
    setRouteValidated(true);
  };

  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 25);
    }, 800);
  };
  const handleEditRoute = (routeId, characterId) => {
    setRouteCharacterList([]);
    props.getCharacters(props.storyId, props.authToken);
    props.getRouteById(props.storyId, routeId, props.authToken);

    // let routeEditCharList = StoryService.setRouteCharacterLists(props.characterList, props.storyRoutes, true, characterId)
    // setRouteCharacterList(routeEditCharList)
    setRouteButtonState(false);
    setEditCharRoute(true);
    setEditRouteId(routeId);
    setEditRouteCharId(characterId);
  };

  const handleHideRouteResult = () => {
    props.resetRouteModal();
    props.resetRouteResultModal();
  };

  const onCancelRouteDeleteClick = () => {
    setConfirmRouteDelete(false);
    setShowFailureDeleteRoute(false);
    // setShowSuccessDeleteRoute(false)
    // setDeleteCharacterRouteId(null)
  };

  const onCancelRouteDeleteClickSuccess = () => {
    // setConfirmRouteDelete(false)
    setShowFailureDeleteRoute(false);
    setShowSuccessDeleteRoute(false);
    setDeleteCharacterRouteId(null);
  };

  const handleRouteDeleteClick = routeId => {
    setDeleteCharacterRouteId(routeId);
    let characterRouteData = props.storyRoutes;
    const chapterIndex = characterRouteData.findIndex(
      chapter => parseInt(chapter.id) === parseInt(routeId)
    );

    if (characterRouteData[chapterIndex].scenes.length > 0) {
      const deleteFalse = characterRouteData[chapterIndex].scenes.findIndex(
        aScene =>
          aScene.lockAcquired === true &&
          props.tokenDataUser.userId !== aScene.lockAcquiredBy
      );
      if (deleteFalse >= 0) {
        setShowFailureDeleteRoute(true);
      } else {
        setConfirmRouteDelete(true);
      }
    } else {
      setRouteButtonState(true);
      props.deleteRoute(props.storyId, routeId, props.authToken);
      // deleteCharacterRoute(routeId)
      props.getCharacters(props.storyId, props.authToken);
      setConfirmRouteDelete(false);
      setShowSuccessDeleteRoute(true);
      setRouteCharacterList([]);
    }
  };

  const deleteCharacterRoute = routeId => {
    setConfirmRouteDelete(false);
    props.deleteRoute(props.storyId, deleteCharacterRouteId, props.authToken);
    setShowSuccessDeleteRoute(true);
  };

  const resetPlayerState = _.debounce(() => {
    PlayerService.clearPlayer();
    PlayerService.clearAllTimeout(commandsTimeoutIds);
    PlayerService.clearPreviewDocument();
    setCommandsTimeoutIds([]);
    setPlay(false);
    setFirstPlay(false);
    setLastPlay(false);
    setPrevPlay(false);
    setNextPlay(false);
    setGotoPlay(null);
    setCurrentCommand(0);
    setCurrentScene(0);
    setCurrentBlock(0);
    setShowSpinner(false);
    setFastPreview(false);
    setCheckCommandState(false);
    setShowDecision(false);
    setDecisionParameters(null);
    setIfElseDecisionParameters([]);

    setShowEnd(false);
    setshowCharModal(false);
    setChoiceTimeout([]);
    setpageX(null);
    setpageY(null);
    setLastView(null);
    setShowDialogue(false);
    setPlayFromStart(true);
    setShowNameDialogue(false);
    setExternalPlayPauseHandler(false);
    setConsoleMessage([]);
    setExecutedCommand([]);
    setShowScriptError(false);
  }, 1000);

  //let checkCommandExists = StoryService.checkCommandExists(props.flowchartPreviewData);
  function onExit() {
    setStepsScriptEnabled(false);
    props.setTutorials(props.tutorials, props.authToken, 'firstTextEditor');
  }

  function onViewExit() {
    setStepsScriptEnabled(false);
    props.setTutorials(props.tutorials, props.authToken, 'firstView');
  }

  function onVarTutorialExit() {
    props.setTutorials(props.tutorials, props.authToken, 'firstVarWindow');
  }

  const handleHideAssetLockModal = () => {
    setCollaboratorModal(false);
  };

  function onCommandExit() {
    setStepsCommandEnabled(false);
    if (
      commandId === Constant.COMMAND_ADD_IF ||
      commandId === Constant.COMMAND_ADD_END ||
      commandId === Constant.COMMAND_ADD_ELSE ||
      commandId === Constant.COMMAND_ADD_ELSE_IF
    ) {
      props.setTutorials(
        props.tutorials,
        props.authToken,
        'firstConditionalCommand'
      );
    }
    if (commandId === Constant.COMMAND_PAID_ADD_DECISION) {
      props.setTutorials(
        props.tutorials,
        props.authToken,
        'firstChoiceCommand'
      );
    }
  }

  const handleShowRouteInfoModal = () => {
    setShowRouteInfo(true);
  };

  const handleHideRouteInfoModal = () => {
    setShowRouteInfo(false);
  };

  const updateRouteInfo = routeInfo => {
    props.updateRouteInfo(props.authToken, props.storyId, routeInfo);
  };

  const onCharacterRouteExit = routeInfo => {
    props.setTutorials(props.tutorials, props.authToken, 'firstCharacterRoute');
  };

  const handleShowLibraryChangeLogClick = () => {
    setShowLibraryChangeLog(true);
  };

  const handleLibraryChangeLogClose = () => {
    setShowLibraryChangeLog(false);
  };

  const onEditViewCommandModal = () => {
    setShowEditViewCommand(false);
    props.resetStoryView();
  };

  const setExtendFlowchart = () => {
    if (flowchartPopup === false) {
      setTimeout(() => {
        setFlowchartPopup(!flowchartPopup);
      }, 800);
    } else {
      setFlowchartPopup(!flowchartPopup);
      props.saveFlowchartJson(
        props.authToken,
        props.storyId,
        props.currentSceneId,
        props.flowchartJson
      );
    }
  };

  const changeCheckBoxHighlightBlock = event => {
    if (highlightBlock === true) {
      setHighlightBlockIndex(0);
      resetPlayerState();
    } else {
      highlightBlockIndexPreviewSet(props.cursorPostion);
    }
    setHighlightBlock(!highlightBlock);
  };

  const highlightBlockIndexPreviewSet = cursor => {
    if (props.flowchartHasErrors === true || textEditorValidate === false) {
      setShowScriptError(true);
      return;
    }
    resetPlayerState();
    props.fetchResetVariableWindow(
      props.authToken,
      props.storyId,
      props.currentSceneId
    );
    const nextIndexes = PlayerService.getCurrentStateOfCommandExecutionHighlightBlock(
      props.flowchartPreviewData,
      {
        currentScene: currentScene,
        currentBlock: currentBlock
      },
      props.playOn,
      command,
      externalPlayPauseHandler,
      {},
      cursor
    );
    if (nextIndexes && nextIndexes.block) {
      setHighlightBlockIndex(nextIndexes.block);
    }
    setFastPreview(false);
    setPlay(false);
    setCheckCommandState(true);
    setShowEnd(false);
    setExternalPlayPauseHandler(false);
  };

  const handleMarkStoryRoute = (currentValue, routeId) => {
    props.markCompleteStoryRoute(
      props.authToken,
      props.storyId,
      routeId,
      currentValue
    );
  };

  const autopopupSaveFlag = flag => {
    props.autopopupSave(flag);
    if (activeTabSelected !== 'Flowchart') {
      setActiveTabSelected('Flowchart');
      setHighlightBlock(false);
      resetPlayerState();
    }
  };

  const handleDraftStory = (event, sceneId) => {
    event.preventDefault();
    event.stopPropagation();
    props.markDraftStatus(
      props.authToken,
      props.storyId,
      sceneId,
      event.target.checked
    );
  };

  const fontChangeSave = (fontSize, font) => {
    props.fontChange(props.authToken, fontSize, font);
  };

  const spellCheckerCall = () => {
    props.spellChecker(
      props.authToken,
      props.storyId,
      props.currentSceneId,
      props.commandObj
    );
  };

  const addToIgnoreCall = word => {
    props.addToIgnoreCallPost(
      props.authToken,
      props.storyId,
      props.currentSceneId,
      props.commandObj,
      word
    );
  };

  const expandEditorClick = () => {
    setTimeout(() => {
      setExpandEditor(!expandEditor);
    }, 100);
  };

  return (
    <WriteStyle.writeStoryWrapper>
      {props.currentSceneId && (
        <Steps
          enabled={stepsScriptEnabled}
          steps={stepsScript ? stepsScript : ''}
          initialStep={initialScriptStep}
          onExit={onExit}
          options={Constant.INTRO_OPTIONS}
        />
      )}
      {props.currentSceneId && (
        <Steps
          enabled={stepsCommandEnabled}
          steps={stepsCommand ? stepsCommand : ''}
          initialStep={initialCommandStep}
          onExit={onCommandExit}
          options={Constant.INTRO_OPTIONS}
        />
      )}

      <Row>
        {expandEditor !== true && (
          <Col
            className={`${
              props.currentSceneId && props.currentSceneId !== null
                ? 'col-2 backgroundChange'
                : 'col-2 left-rail'
            }`}
          >
            <WriteStyle.writeStoryCommandPanel
              className="step-command2 step-command"
              commandPanelToggle={commandPanelToggle}
            >
              {props.showPanels && props.currentSceneId && (
                <CommandsPanel
                  commandsList={props.commandsLevel1}
                  loading={props.commandsLevel1loading}
                  commandsList2={props.commandsLevel2}
                  loading2={props.commandsLevel2loading}
                  handleCommnadMenuClick={handleCommnadMenuClick}
                  handleCommnadShortCutClick={handleCommnadShortCutClick}
                  showShortCut={showShortCut}
                  level={props.commandLevel}
                  handleSetLevel={handleSetLevel}
                  handleSaveCommandInfo={handleSaveCommandInfo}
                  validated={validated}
                  isValidText={props.isValidText}
                  handleTextFieldChange={handleTextFieldChange}
                  flowchartNode={props.avialableBlocks}
                  commandId={props.commandId ? props.commandId : commandId}
                  characterList={props.characterListForScript}
                  handlePlaceViewMenuClick={handlePlaceViewMenuClick}
                  handlePlaceMoveViewMenuClick={handlePlaceMoveViewMenuClick}
                  backgroundImages={props.backgroundImages}
                  isCommandEdit={isCommandEditVariable}
                  isViewStory={props.isViewStory}
                  isViewRoute={props.isViewRoute}
                  variableListDTO={props.variableListDTO}
                  variableWindoOnOffStatus={variableWindoOnOffStatus}
                  tokenDataUser={
                    props.commandObj &&
                    props.commandObj.lockAcquired === true &&
                    props.commandObj.lockAcquiredBy !==
                      props.tokenDataUser.userId
                      ? true
                      : false
                  }
                  full_name_toggle={props.full_name_toggle}
                />
              )}
            </WriteStyle.writeStoryCommandPanel>
            <WriteStyle.writerStoryAngleLeft>
              {props.showPanels === true &&
                props.currentSceneId !== null &&
                props.commandLevel !== 2 && (
                  <i
                    onClick={() => handleCommandPanel()}
                    className={commandPanelShow}
                  ></i>
                )}
            </WriteStyle.writerStoryAngleLeft>
          </Col>
        )}
        <Col
          className={`${
            expandEditor === true ? `col-12` : `col-8`
          } script-panel-container`}
        >
          <WriteStyle.writeStoryShow>
            {props.currentSceneId && props.isEditor ? (
              <TextEditor
                commandList={props.commandObj}
                autopopupSave={autopopupSaveFlag}
                blockList={props.avialableBlocks}
                getCommandObjUpdated={props.getCommandObjUpdated}
                storyTitle={props.storyTitleTextEditor}
                storySubTitle={props.storySubTitleTextEditor}
                characterListForScript={props.characterListForScript}
                handleSaveReducerForHeader={handleSaveReducerForHeader}
                avialableBlocks={props.avialableBlocks}
                // backToScene={resetFlowchart}
                backToScene={preventScriptCall}
                views={props.views}
                changeCursorPosition={changeCursorPosition}
                showHideModal={showEditorModal}
                hideCharModal={hideCharModal}
                showModal={showCharModal}
                previewAssetObj={props.previewAssetObj}
                pageX={pageX}
                pageY={pageY}
                cursorPostion={props.cursorPostion}
                editCommand={editCommand}
                assetsAll={props.assetsAll}
                isViewStory={props.isViewStory}
                isViewRoute={props.isViewRoute}
                showPanels={props.showPanels}
                onAddCommentClick={onAddCommentClick}
                setComments={setComments}
                isCommentStory={props.isCommentStory}
                commentLineNo={commentLineNo}
                changeCommentPoup={changeCommentPoup}
                currentStoryStatus={props.currentStoryStatus}
                variableListDTO={props.variableListDTO}
                tokenDataUser={props.tokenDataUser}
                localVariableListDTO={props.localVariableListDTO}
                variableWindowClicked={variableWindowClicked}
                activeTabSelected={activeTabSelected}
                updateVariableWithId={updateVariableWithId}
                updateValueArrayIds={props.changeVariableIds}
                handleSaveStory={handleSaveStory}
                storyStatusObj={props.storyStatusObj}
                play={play}
                blockClickedEvent={blockClickedEvent}
                setBlockClickedEvent={setBlockClickedEvent}
                allStoryRoutes={props.storyRoutes}
                currentCharacterRouteId={characterRouteIdText}
                currentSceneId={props.currentSceneId}
                storyId={props.storyId}
                previewScroll={previewScroll}
                setPreviewScroll={setPreviewScroll}
                tutorials={props.tutorials}
                onVarTutorialExit={onVarTutorialExit}
                stepsScriptEnabled={stepsScriptEnabled}
                showChangeLogModal={props.showChangeLogModal}
                compareFlag={compareFlag}
                handleChangeLogScript={handleChangeLogScript}
                comamndSavePanel={comamndSavePanel}
                setComamndSavePanel={setComamndSavePanel}
                setScriptChanged={setScriptChanged}
                scriptChanged={scriptChanged}
                textEditorValidate={textEditorValidate}
                setTextEditorValidate={setTextEditorValidate}
                setShowScriptError={setShowScriptError}
                flowchartHasErrors={props.flowchartHasErrors}
                externalPlayPauseHandler={externalPlayPauseHandler}
                commandObjBkup={props.commandObjBkup}
                indentButtonClick={indentButtonClick}
                setIndentButtonClick={setIndentButtonClick}
                textEditorloading={props.textEditorloading}
                changeCheckBoxHighlightBlock={highlightBlockIndexPreviewSet}
                highlightBlock={highlightBlock}
                lastSavedTime={props.lastSavedTime}
                handleAutoSaveStory={handleAutoSaveStory}
                full_name_toggle={props.full_name_toggle}
                fontSize={props.fontSize}
                fontFamily={props.fontFamily}
                fontChangeSave={fontChangeSave}
                spellGrammar={props.spellGrammar}
                spellCheckerCall={spellCheckerCall}
                addToIgnoreCall={addToIgnoreCall}
                expandEditorClick={expandEditorClick}
                expandEditor={expandEditor}
                loadGrammar={props.loadGrammar}
              />
            ) : (
              <SceneList
                flowchartData={props.storyRoutes}
                storyTitle={props.storyTitle}
                newSceneName={newSceneName}
                setNewSceneName={setNewSceneName}
                handleCreateScene={handleCreateScene}
                handleNodeClickforBlock={handleNodeClickforBlock}
                storyScenesErrors={props.storyScenesErrors}
                handleSaveList={handleSaveList}
                isViewStory={props.isViewStory}
                isViewRoute={props.isViewRoute}
                showPanels={props.showPanels}
                tokenDataUser={props.tokenDataUser}
                isCommentStory={props.isCommentStory}
                handleShowChapterModal={handleShowChapterModal}
                handleShowRouteModal={handleShowRouteModal}
                handleEditRoute={handleEditRoute}
                setShowChapterModal={setShowChapterModal}
                setEditChapter={setEditChapter}
                handleRouteDeleteClick={handleRouteDeleteClick}
                storyRouteListLoading={props.storyRouteListLoading}
                handleMarkStoryRoute={handleMarkStoryRoute}
                characterList={routeCharacterList}
                routesData={props.storyRoutes}
                routeCharaList={props.routeCharaList}
                refreshRouteList={refreshRouteList}
                routeButtonState={routeButtonState}
                handleShowRouteInfoModal={handleShowRouteInfoModal}
                currentStoryStatus={props.currentStoryStatus}
                handleChangeLogScript={handleChangeLogScript}
                libraryChangeLog={props.libraryChangeLog}
                handleShowLibraryChangeLogClick={
                  handleShowLibraryChangeLogClick
                }
                currentStoryStatus={props.currentStoryStatus}
                backPage={props.backPage}
                handleDraftStory={handleDraftStory}
                storyStatus={props.storyDetails.status}
                storyDetails={props.storyDetails}
                viewOnly={props.storyDetails.viewOnly}
                refreshList={refreshList}
                releaseLockChapter={releaseLockChapter}
              />
            )}
          </WriteStyle.writeStoryShow>
        </Col>
        {expandEditor === false && (
          <Col
            className={`${
              props.currentSceneId && props.currentSceneId !== null
                ? 'col-2'
                : 'col-2 right-rail'
            }`}
          >
            {props.currentSceneId && props.currentSceneId !== null && (
              <StoryRightPanel
                backgroundColor={backgroundColor}
                flowchartToggle={flowchartToggle}
                sceneColor={sceneColor}
                authToken={props.authToken}
                storyId={props.storyId}
                showingSceneFlag={props.showingSceneFlag}
                flowchartNode={flowchartNode}
                flowchartConnector={flowchartConnector}
                currentSceneId={props.currentSceneId}
                fetchFlowchart={resetFlowchart}
                handleFlowchartView={handleFlowchartView}
                // Parameters for priview player
                handlePlayerButtonsClick={handlePlayerButtonsClick}
                SceneId={props.SceneId}
                blocks={props.flowchartPreviewData}
                sceneId={props.sceneId}
                commandsTimeoutIds={commandsTimeoutIds}
                setCommandsTimeoutIds={setCommandsTimeoutIds}
                play={play}
                setPlay={setPlay}
                firstPlay={firstPlay}
                lastPlay={lastPlay}
                prevPlay={prevPlay}
                nextPlay={nextPlay}
                gotoPlay={gotoPlay}
                setGotoPlay={setGotoPlay}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
                currentCommand={currentCommand}
                setCurrentCommand={setCurrentCommand}
                currentScene={currentScene}
                setCurrentScene={setCurrentScene}
                handleDialogueDisplayEvent={handleDialogueDisplayEvent}
                previewTitle={props.previewTitle}
                showSpinner={showSpinner}
                fastPreview={fastPreview}
                setFastPreview={setFastPreview}
                checkCommandState={checkCommandState}
                setCheckCommandState={setCheckCommandState}
                handleDecisionClick={handleDecisionClick}
                setShowDecision={setShowDecision}
                showDecision={showDecision}
                decisionParameters={decisionParameters}
                setDecisionParameters={setDecisionParameters}
                ifElseDecisionParameters={ifElseDecisionParameters}
                setIfElseDecisionParameters={setIfElseDecisionParameters}
                showEnd={showEnd}
                setShowEnd={setShowEnd}
                loading={props.loading}
                blobImages={props.blobImages}
                loadImages={() =>
                  props.handlePreviewModal(
                    props.flowchartPreviewData,
                    aspectRatio,
                    props.interfaceList
                  )
                }
                choiceTimeout={choiceTimeout}
                setChoiceTimeout={setChoiceTimeout}
                fetchPreviewDataOnClickPreview={handlePreviewDataClick}
                lastView={lastView}
                setLastView={setLastView}
                showDialogue={showDialogue}
                setShowDialogue={setShowDialogue}
                views={props.views}
                isViewStory={props.isViewStory}
                isViewRoute={props.isViewRoute}
                setPlayFromStart={setPlayFromStart}
                showNameDialogue={showNameDialogue}
                setShowNameDialogue={setShowNameDialogue}
                setDialogueVariables={setDialogueVariables}
                externalPlayPauseHandler={externalPlayPauseHandler}
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                variableListDTO={props.variableListDTO}
                interfaceList={props.interfaceList}
                preloadMessage={props.loadingMessage}
                variableWindoOnOffStatus={variableWindoOnOffStatus}
                activeTabSelected={activeTabSelected}
                setActiveTabSelected={setActiveTabSelected}
                localVariableListDTO={props.localVariableListDTO}
                consoleMessage={consoleMessage}
                setConsoleMessage={setConsoleMessage}
                updateVariableWithId={updateVariableWithId}
                changeCursorPosition={changeCursorPosition}
                handleNodeClickforFlowchartNode={
                  handleNodeClickforFlowchartNode
                }
                gameFonts={props.gameFonts}
                previewScroll={previewScroll}
                setPreviewScroll={setPreviewScroll}
                setHighlightBlock={setHighlightBlock}
                highlightBlock={highlightBlock}
                setExternalPlayPauseHandler={setExternalPlayPauseHandler}
                resetPlayerState={resetPlayerState}
                setExecutedCommand={setExecutedCommand}
                executedCommand={executedCommand}
                flowchartHasErrors={props.flowchartHasErrors}
                showScriptError={showScriptError}
                textEditorValidate={textEditorValidate}
                setShowScriptError={setShowScriptError}
                commandListForScriptEditor={props.commandObj}
                tokenDataUser={props.tokenDataUser}
                setCommand={setCommand}
                setExtendFlowchart={setExtendFlowchart}
                levelFlowchart={props.levelFlowchart}
                fetchBlockchart={props.fetchBlockchart}
                resetFlowchart={props.resetOnlyFlowchart}
                flowchartJson={props.flowchartJson}
                storeFlowchartJson={props.storeFlowchartJson}
                changeCheckBoxHighlightBlock={changeCheckBoxHighlightBlock}
                highlightBlockIndex={highlightBlockIndex}
                setStartFromHighlightBlock={setStartFromHighlightBlock}
                startFromHighlightBlock={startFromHighlightBlock}
                setUpdatedFlowchartJson={props.setUpdatedFlowchartJson}
                showFlowchart={showFlowchart}
                setShowFlowchart={setShowFlowchart}
                hideIfVisitedArray={hideIfVisitedArray}
                loadingPreview={props.loadingPreview}
              />
            )}
          </Col>
        )}
      </Row>
      {showChapterModal && (
        <CreateChapter
          showModal={showChapterModal}
          handleHideChapterModal={handleHideChapterModal}
          storyRoutes={props.storyRoutes}
          handleCreateScene={handleCreateScene}
          sceneList={props.storyScenesList}
          chapterData={StoryService.getChapterData(
            props.storyRoutes,
            editChapter
          )}
          storyDetails={props.storyDetails}
          handleSaveList={handleSaveList}
        />
      )}
      {setShowRouteModal && (
        <CreateRoute
          showModal={showRouteModal}
          handleHideRouteModal={handleHideRouteModal}
          setFiles={data => setFiles(data)}
          files={files}
          setRoute={data => setRoute(data)}
          route={route}
          isRouteEdit={props.isRouteEdit}
          characterList={routeCharacterList}
          setShowClearFile={setShowClearFile}
          showClearFile={showClearFile}
          handleCreateRoute={handleCreateRoute}
          routesData={props.storyRoutes}
          validated={routeValidated}
          loading={props.loadingRouteData}
          editRouteId={editRouteCharId}
          setRouteValidated={setRouteValidated}
          charLoading={props.charLoading}
          refreshRouteList={refreshRouteList}
          tutorials={props.tutorials}
          onCharacterRouteExit={onCharacterRouteExit}
        />
      )}

      {props.progressRoute === true && (
        <SubmitRoute
          showModal={props.progressRoute}
          isRouteEdit={props.isRouteEdit}
          progress={progress}
        />
      )}
      {props.successRouteModal === true && (
        <Success
          handleHideRouteResult={handleHideRouteResult}
          showModal={props.successRouteModal}
          isRouteEditSuccess={props.isRouteEditSuccess}
          isRouteEdit={props.isRouteEdit}
        />
      )}
      {props.failRouteModal === true && (
        <Fail
          handleHideRouteResult={handleHideRouteResult}
          showModal={props.failRouteModal}
          isRouteEdit={props.isRouteEdit}
        />
      )}

      {showPlaceBackground && (
        <PlaceBackground
          showModal={showPlaceBackground}
          onHideModal={onHidePlaceBackgroundModal}
          handlePlaceViewScript={handlePlaceViewScript}
          handleChange={handleChange}
          setCrop={setCrop}
          saveForLater={saveForLater}
          clickedEventCheck={clickedEventCheck}
          handleClickEvent={handleClickEvent}
          handleSaveForLaterClickEvent={handleSaveForLaterClickEvent}
          duplicateName={duplicateName}
          handleBrowseSelection={handleBrowseSelection}
          handleDuplicateClick={handleDuplicateClick}
          handleViewSearch={handleViewSearch}
          searchText={searchText}
          backgroundImages={props.backgroundImages}
          views={props.views}
          searchViews={props.searchViews}
          setActiveKey={props.setActiveKey}
          background={props.background}
          setShowAddObjectForm={setShowAddObjectForm}
          viewObjects={props.viewObjects}
          handleObjectDeleteClick={handleObjectDeleteClick}
          handleEditObjectClick={handleEditObjectClick}
          editObjectIndex={editObjectIndex}
          handleAddObjectClick={handleAddObjectClick}
          handleReorderList={handleReorderObjectList}
          activeKey={props.activeKey}
          assetList={props.assetsAll}
          setObject={props.setObject}
          xcoordinate={props.xcoordinate}
          ycoordinate={props.ycoordinate}
          height={props.height}
          width={props.width}
          backgroundCopy={props.backgroundCopy}
          handleViewEditClick={handleViewEditClick}
          selectBackground={selectBackground}
          setSelectBackground={setSelectBackground}
          addObject={addObject}
          setAddObject={setAddObject}
          tutorials={props.tutorials}
          onExit={onViewExit}
          stepsViewEnabled={stepsViewEnabled}
          editView={editView}
          setEditView={setEditView}
          libraryChange={false}
          characterRoutesList={props.storyRoutes}
          clickedEventCheck={clickedEventCheck}
          setClickedEventCheck={setClickedEventCheck}
          otherView={props.otherView}
        />
      )}

      {showMovePlaceBackground && (
        <PlaceMoveBackground
          showModal={setMoveShowPlaceBackground}
          onHideModal={onHideMovePlaceBackgroundModal}
          views={props.views}
          backgroundImages={props.backgroundImages}
          fadeinView={props.fadeinView}
          viewId={props.viewId}
          isEditCommand={props.isEditCommand}
          handleMovePlaceViewScript={handleMovePlaceViewScript}
        />
      )}
      {showCancelMovePlaceBackgroundWindow && (
        <CancelMovePlaceBackground
          show={showCancelMovePlaceBackgroundWindow}
          onCancelClick={hideCancelMovePlaceBackgroundWindow}
          onLeaveClick={onLeaveMovePlaceBackground}
        />
      )}

      {showCancelPlaceBackgroundWindow && (
        <CancelPlaceBackground
          show={showCancelPlaceBackgroundWindow}
          onCancelClick={hideCancelPlaceBackgroundWindow}
          onLeaveClick={onLeavePlaceBackground}
          duplicateName={false}
        />
      )}
      {showAddObjectForm === true && (
        <AddObjectsModal
          showModal={showAddObjectForm}
          onHideModal={onHideAddObjectsForm}
          assetsList={props.assetsAll}
          onCancelClickAddObject={onCancelClickAddObject}
          addObject={props.addObject}
          setShowAddObjectForm={setShowAddObjectForm}
          editObjectIndex={editObjectIndex}
          viewObjects={props.viewObjects}
          setObject={props.setObject}
        />
      )}

      {createViewModal === true && (
        <CreateViewModal
          showModal={createViewModal}
          onHideModal={hideCreateViewModal}
          validated={props.validated}
          duplicateName={duplicateName}
          saveForLater={saveForLater}
          handleChange={handleChange}
          clickedEventCheck={clickedEventCheck}
          fadeinView={props.fadeinView}
          isEditCommand={props.isEditCommand}
          tabButtonClicked={tabButtonClicked}
          characterRoutesList={props.storyRoutes}
          backgroundCopy={props.backgroundCopy}
          characterRoute={props.viewcharacterRoute}
          exportToGallary={props.viewexportogallery}
          name={props.name}
          captionText={props.captionText}
          foundInJuicy={props.foundInJuicy}
        />
      )}

      {commentsModal === true && (
        <CommentsModal
          showModal={commentsModal}
          onHideModal={hideCommentsModal}
          handleSaveInfo={handleSaveInfo}
          comments={comments}
          commentCmdText={commentCmdText}
          commentBlockText={commentBlockText}
          setComments={data => setComments(data)}
          commentLineNo={commentLineNo}
        />
      )}
      {props.commentStatus && props.commentStatus === 'success' && (
        <CommentsSuccess
          show={true}
          onHideCommentStatusModal={onHideCommentStatusModal}
        />
      )}
      {props.commentStatus && props.commentStatus === 'fail' && (
        <CommentsFail
          show={true}
          onHideCommentStatusModal={onHideCommentStatusModal}
        />
      )}

      {confirmRouteDelete === true && (
        <ConfirmDeleteRoute
          firstLineLabel="Deleting this route will delete all the chapters in this route. This action cannot be undone. Are you sure you want to proceed?"
          secondLineLabel="&nbsp;"
          onClose={onCancelRouteDeleteClick}
          cancel="Cancel"
          confirm="Yes, delete it"
          show={confirmRouteDelete}
          handleClearCharacter={deleteCharacterRoute}
        />
      )}
      {showFailureDeleteRoute === true && (
        <FailDeleteRoute
          label="In character route having some locked chapter so not able to delete chapter"
          handleClick={onCancelRouteDeleteClickSuccess}
          mutedText="&nbsp;"
        />
      )}
      {showSuccessDeleteRoute === true && (
        <FailDeleteRoute
          label="The route has been deleted"
          handleClick={onCancelRouteDeleteClickSuccess}
          show={showSuccessDeleteRoute}
          mutedText="&nbsp;"
        />
      )}
      {showRouteInfo && (
        <RouteInfo
          showModal={showRouteInfo}
          handleHideRouteInfoModal={handleHideRouteInfoModal}
          routeInfo={props.routeInfo}
          updateRouteInfo={updateRouteInfo}
          showPanels={props.showPanels}
        />
      )}
      {props.showChangeLogModal && (
        <ChangeLogModal
          label="Modal Success"
          handleClick={onCancelRouteDeleteClickSuccess}
          mutedText="&nbsp;"
          commandList={props.commandObj}
          olderVersion={props.oldScript}
          newerVersion={props.newScript}
          lastVersionDate={props.lastVersionDate}
          storyTitle={props.storyTitleTextEditor}
          storySubTitle={props.storySubTitleTextEditor}
          changeLogModalClose={changeLogModalClose}
          showModal={props.showChangeLogModal}
        />
      )}
      {showLibraryChangeLog && (
        <LibraryChangeLog
          libraryChangeLog={props.libraryChangeLog}
          showModal={true}
          onHide={handleLibraryChangeLogClose}
        />
      )}
      {showEditViewCommand && (
        <EditViewCommand
          showModal={true}
          onHideModal={onEditViewCommandModal}
          handlePlaceViewScript={handlePlaceViewScript}
          handleViewSearch={handleViewSearch}
          searchText={searchText}
          backgroundImages={props.backgroundImages}
          views={props.views}
          searchViews={props.searchViews}
          background={props.background}
          activeKey={props.activeKey}
          assetList={props.assetsAll}
          backgroundCopy={props.backgroundCopy}
          selectBackground={selectBackground}
          setSelectBackground={setSelectBackground}
          fadeinView={props.fadeinView}
        />
      )}
      {autoSaveFlagCheckBack === true && (
        <ConfirmAutoSavePopup
          firstLineLabel="You have unsaved work in the script. Do you wish to save your changes before leaving?"
          secondLineLabel="&nbsp;"
          onClose={closeAutoSaveFlagCheck}
          cancel="Leave without saving"
          confirm="Yes, Save"
          show={autoSaveFlagCheckBack}
          cancelMain={closeAutoSaveFlagCheckClose}
          handleClearCharacter={onClickYes}
        />
      )}
      {editWarningTextEditor === true && (
        <ConfirmAutoSavePopup
          firstLineLabel={
            editErrorTextEditor
              ? 'Unable to edit this command as it may have errors. Please validate script and try again.'
              : 'Unable to edit unsaved commands. Please save your work and try again.'
          }
          secondLineLabel="&nbsp;"
          onClose={editWarningCommandOkay}
          confirm="Okay"
          show={editWarningCommandOkay}
          handleClearCharacter={editWarningCommandOkay}
        />
      )}
      {viewWarningTextEditor === true && (
        <ConfirmAutoSavePopup
          firstLineLabel={
            viewErrorTextEditor
              ? 'Unable to preview this command as it may have errors. Please validate script and try again.'
              : 'Unable to preview unsaved commands. Please save your work and try again.'
          }
          secondLineLabel="&nbsp;"
          onClose={editWarningCommandOkay}
          confirm="Okay"
          show={editWarningCommandOkay}
          handleClearCharacter={editWarningCommandOkay}
        />
      )}
      {showSaveConfirmation && (
        <Warning showModal={true} handleWarningClick={saveView} />
      )}

      {flowchartPopup === true &&
        props.currentSceneId !== null &&
        props.flowchartHasErrors !== true && (
          <LargeFlowchart
            close={setExtendFlowchart}
            flowchartNode={flowchartNode}
            flowchartConnector={flowchartConnector}
            flowchartHasErrors={props.flowchartHasErrors}
            show={flowchartPopup}
            storyId={props.storyId}
            chapterId={props.chapterId}
            flowchartJson={props.flowchartJson}
            setUpdatedFlowchartJson={props.setUpdatedFlowchartJson}
          />
        )}

      {collaboratorModal == true && (
        <LockedAssets
          showModal={collaboratorModal}
          lockedAssetData={props.editableData}
          handleHideAssetLockModal={handleHideAssetLockModal}
          type="view"
        />
      )}
    </WriteStyle.writeStoryWrapper>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    tokenDataUser: state.LoginReducer.tokenDataUser,
    showPanels: state.LoginReducer.showPanels,
    flowchartData: state.FlowchartReducer.flowchartData,
    flowchartNode: state.FlowchartReducer.flowchartNode,
    flowchartConnector: state.FlowchartReducer.flowchartConnector,
    flowchartAllScenes: state.FlowchartReducer.flowchartAllScenes,
    storyId: state.StoryReducer.storyId,
    showingSceneFlag: state.FlowchartReducer.showingSceneFlag,
    commandsLevel1: state.StoryReducer.commandsLevel1,
    commandsLevel1loading: state.StoryReducer.commandsLevel1loading,
    commandsLevel2: state.StoryReducer.commandsLevel2,
    commandsLevel2loading: state.StoryReducer.commandsLevel2loading,
    characterVariations: state.StoryReducer.characterVariations,
    characterVariationsLoading: state.StoryReducer.characterVariationsLoading,
    characterExpressions: state.StoryReducer.characterExpressions,
    characterExpressionsLoading: state.StoryReducer.characterExpressionsLoading,
    characterList: state.CharacterReducer.characterList,
    routeCharaList: state.CharacterReducer.routeCharaList,
    charLoading: state.CharacterReducer.charLoading,

    currentSceneId: state.FlowchartReducer.currentSceneId,

    successRouteModal: state.StoryReducer.successRouteModal,
    progressRoute: state.StoryReducer.progressRoute,
    failRouteModal: state.StoryReducer.failRouteModal,

    isValidText: state.FlowchartReducer.isValidText,
    script: state.FlowchartReducer.script,
    blockData: state.FlowchartReducer.blockData,
    storyTitle: state.StoryReducer.storyTitle,
    storyTitleTextEditor: state.StoryReducer.storyTitleTextEditor,
    storySubTitleTextEditor: state.StoryReducer.storySubTitleTextEditor,
    storySubTitle: state.FlowchartReducer.storySubTitle,
    commandLevel: state.StoryReducer.commandLevel,
    commandBackground: state.CommandReducer.commandBackground,
    commandCharacter: state.CommandReducer.commandCharacter,
    commandMedia: state.CommandReducer.commandMedia,
    commandPlayMusic: state.CommandReducer.commandPlayMusic,
    commandData: state.FlowchartReducer.commandData,
    flowchartPreviewData: state.FlowchartReducer.flowchartPreviewData,
    storyScenesErrors: state.StoryReducer.storyScenesErrors,
    commandObj: state.StoryReducer.commandObj,
    commandObjBkup: state.StoryReducer.commandObjBkup,
    avialableBlocks: state.StoryReducer.avialableBlocks,
    characterListForScript: state.CharacterReducer.characterListForScript,
    backgroundImages: state.PlaceBackgroundReducer.backgroundImageList,
    storyScenesList: state.FlowchartReducer.storyScenesList,
    variableListDTO: state.StoryReducer.variableListDTO,
    localVariableListDTO: state.StoryReducer.localVariableListDTO,
    originalVariableLocalDTO: state.StoryReducer.originalVariableLocalDTO,
    changeVariableIds: state.StoryReducer.changeVariableIds,

    // state for preview player
    loading: state.PlayerReducer.loading,
    blobImages: state.PlayerReducer.blobImages,
    views: state.PlaceBackgroundReducer.views,
    assetsAll: state.LibraryReducer.assetList,
    backgroundCopy: state.PlaceBackgroundReducer.backgroundCopy,
    cursorPostion: state.StoryReducer.cursorPostion,
    isEditor: state.StoryReducer.isEditor,

    // Place backgrounds states
    activeKey: state.PlaceBackgroundReducer.activeKey,
    background: state.PlaceBackgroundReducer.background,
    fadeIn: state.PlaceBackgroundReducer.fadeIn,
    name: state.PlaceBackgroundReducer.name,
    captionText: state.PlaceBackgroundReducer.captionText,
    xcoordinate: state.PlaceBackgroundReducer.x,
    ycoordinate: state.PlaceBackgroundReducer.y,
    viewexportogallery: state.PlaceBackgroundReducer.exportToGallary,
    viewcharacterRoute: state.PlaceBackgroundReducer.characterRoute,
    foundInJuicy: state.PlaceBackgroundReducer.foundInJuicy,
    height: state.PlaceBackgroundReducer.height,
    width: state.PlaceBackgroundReducer.width,
    zoom: state.PlaceBackgroundReducer.zoom,
    aspect: state.PlaceBackgroundReducer.aspect,
    validated: state.PlaceBackgroundReducer.validated,
    searchViews: state.PlaceBackgroundReducer.searchViews,
    viewObjects: state.PlaceBackgroundReducer.viewObjects,

    previewAssetObj: state.StoryReducer.previewAssetObj,
    isEditCommand: state.StoryReducer.isEditCommand,
    commandId: state.StoryReducer.commandId,
    viewId: state.StoryReducer.viewId,

    isViewStory: state.StoryReducer.isViewStory,
    isViewRoute: state.StoryReducer.isViewRoute,
    fadeinView: state.StoryReducer.fadeinView,
    isCommentStory: state.StoryReducer.isCommentStory,
    currentStoryStatus: state.StoryReducer.currentStoryStatus,

    commentStatus: state.CommentReducer.commentStatus,
    interfaceList: state.GameInterfaceReducer.interfaceList,

    loadingMessage: state.PlayerReducer.loadingMessage,
    storyStatusObj: state.StoryReducer.storyStatusObj,
    storyRoutes: state.StoryReducer.storyRoutes,
    isRouteEdit: state.StoryReducer.isRouteEdit,
    isRouteEditSuccess: state.StoryReducer.isRouteEditSuccess,
    showRouteDeleteConfirm: state.StoryReducer.showRouteDeleteConfirm,

    routeData: state.StoryReducer.routeData,
    loadingRouteData: state.StoryReducer.loadingRouteData,
    storyRouteListLoading: state.StoryReducer.storyRouteListLoading,
    tutorials: state.LoginReducer.tutorials,
    gameFonts: state.GameInterfaceReducer.gameFonts,
    currentCharacterRouteId: state.FlowchartReducer.currentCharacterRouteId,
    routeInfo: state.StoryReducer.routeInfo,

    showChangeLogModal: state.StoryReducer.showChangeLogModal,
    oldScript: state.StoryReducer.oldScript,
    newScript: state.StoryReducer.newScript,
    lastVersionDate: state.StoryReducer.lastVersionDate,
    libraryChangeLog: state.LibraryReducer.changeLog,

    flowchartHasErrors: state.FlowchartReducer.flowchartHasErrors,
    autoSaveFlag: state.StoryReducer.autoSaveFlag,
    levelFlowchart: state.FlowchartReducer.levelFlowchart,
    flowchartJson: state.FlowchartReducer.flowchartJson,

    saveViewObject: state.PlaceBackgroundReducer.saveViewObject,
    fadeIntoSeconds: state.PlaceBackgroundReducer.fadeIntoSeconds,
    textEditorloading: state.StoryReducer.loading,
    storyDetails: state.StoryReducer.storyDetails,
    backPage: state.StoryReducer.backPage,
    lastSavedTime: state.StoryReducer.lastSavedTime,
    full_name_toggle: state.LoginReducer.full_name_toggle,
    editableFlag: state.PlaceBackgroundReducer.editableFlag,
    editableData: state.PlaceBackgroundReducer.editableData,
    otherView: state.PlaceBackgroundReducer.otherView,
    fontSize: state.LoginReducer.fontSize,
    fontFamily: state.LoginReducer.fontFamily,
    spellGrammar: state.StoryReducer.spellGrammar,
    loadGrammar: state.StoryReducer.loadGrammar,
    loadingPreview: state.FlowchartReducer.loadingPreview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFlowchart: (authToken, storyId) =>
      dispatch({
        type: ActionTypes.GET_FLOWCHART_DETAILS,
        authToken: authToken,
        storyId: storyId
      }),
    setUpdatedFlowchartJson: flowchartJson =>
      dispatch({
        type: ActionTypes.SAVE_FLOWCHART,
        flowchartJson: flowchartJson
      }),
    saveFlowchartJson: (authToken, storyId, sceneId, flowchartJson) =>
      dispatch(
        saveFlowchartChanges(authToken, storyId, sceneId, flowchartJson)
      ),
    fetchBlockchart: (authToken, storyId, blockId) =>
      dispatch({
        type: ActionTypes.GET_BLOCK_FLOWCHART_DETAILS,
        authToken: authToken,
        storyId: storyId,
        blockId: blockId
      }),
    fetchCommandsObject: (authToken, storyId, sceneId) =>
      dispatch(getCommands(authToken, storyId, sceneId)),
    modalShowChangeLog: viewModal => dispatch(showModal(viewModal)),
    fetchChangeLogScript: (authToken, storyId, chapterId) =>
      dispatch(changeLogScript(authToken, storyId, chapterId)),
    //get command object for preview in popup
    fetchPreviewAssetObject: (authToken, storyId, sceneId, lineNo) =>
      dispatch(getPreviewAsset(authToken, storyId, sceneId, lineNo)),
    //get command object for Edit
    fetcEditCommandObject: (authToken, storyId, sceneId, lineNo) =>
      dispatch(getCommandsEdit(authToken, storyId, sceneId, lineNo)),

    resetCommandEditData: () => dispatch(getCommandsEditFail()),

    setTutorials: (data, authToken, flag) =>
      dispatch({
        type: ActionTypes.SET_TUTORIAL_DATA,
        payload: data,
        authToken: authToken,
        flag: flag
      }),

    resetPreviewData: () => dispatch(getPreviewAssetFail()),
    resetCommandObject: () => dispatch(getCommandsFail()),
    resetFlowChartData: () =>
      dispatch({ type: ActionTypes.GET_RESET_FLOWCHART_DETAILS }),
    resetOnlyFlowchart: () =>
      dispatch({ type: ActionTypes.GET_RESET_ONLY_FLOWCHART }),
    setCursorPosition: index => dispatch(setCursorPostion(index)),

    setCommandsData: (
      commandData,
      authToken,
      storyId,
      sceneId,
      savefromMenu = false,
      changeBlockName = null,
      newBlock = null
    ) =>
      dispatch(
        setCommands(
          commandData,
          authToken,
          storyId,
          sceneId,
          savefromMenu,
          changeBlockName,
          newBlock
        )
      ),
    setCommandsDataPanel: (
      commandData,
      authToken,
      storyId,
      sceneId,
      savefromMenu = false,
      changeBlockName = null,
      newBlock = null
    ) =>
      dispatch(
        setCommandsPanel(
          commandData,
          authToken,
          storyId,
          sceneId,
          savefromMenu,
          changeBlockName,
          newBlock
        )
      ),
    setCommandsDataAutoSave: (
      commandData,
      authToken,
      storyId,
      sceneId,
      savefromMenu = false,
      changeBlockName = null,
      newBlock = null
    ) =>
      dispatch(
        setAutoSaveCommands(
          commandData,
          authToken,
          storyId,
          sceneId,
          savefromMenu,
          changeBlockName,
          newBlock
        )
      ),
    getAvailableBlocks: (authToken, sceneId, storyId) =>
      dispatch(getAvailableBlocks(authToken, sceneId, storyId)),
    getNewCharacters: (storyId, authToken) =>
      dispatch(LibraryActions.getCharactersForScript(storyId, authToken)),
    getListScene: (storyId, authToken) =>
      dispatch(StoryAction.listScene(authToken, storyId)),
    saveListScene: (authToken, storyId, updatedList) =>
      dispatch(StoryAction.saveSceneList(authToken, storyId, updatedList)),
    getCommandLevelOne: authToken => dispatch(getCommandsLevel1(authToken)),
    getCommandLevelTwo: (authToken, storyId, commandId, sceneId) =>
      dispatch(getCommandsLevel2(authToken, storyId, commandId, sceneId)),
    // getCharacterVariations: (authToken, storyId, characterId) => dispatch(getCharacterVariations(authToken, storyId, characterId)),
    // getCharacterExpressions: (authToken, storyId, characterId, variationId) => dispatch(getCharacterExpressions(authToken, storyId, characterId, variationId)),

    setIsValidText: isValid =>
      dispatch({
        type: ActionTypes.SET_BLOCK_NAME_VALID,
        isValid: isValid
      }),

    setLevel: commandLevel => dispatch(setCommandLevel(commandLevel)),
    handlePreviewModal: (
      flowchartPreviewData,
      aspectRatio,
      gameInterfaceList
    ) =>
      dispatch(
        PlayerActions.setPlayerModalTrue(
          flowchartPreviewData,
          aspectRatio,
          gameInterfaceList
        )
      ),
    resetCommandValue: () => dispatch(StoryAction.resetCommandValue()),
    getCharacterColor: (authToken, storyId, characterId) =>
      dispatch(getCharacterColor(authToken, storyId, characterId)),
    getCharacters: (storyId, authToken) =>
      dispatch(LibraryActions.getCharacters(storyId, authToken)),
    getRouteCharacters: (storyId, authToken) =>
      dispatch(LibraryActions.getRouteCharacters(storyId, authToken)),

    createScene: (authToken, storyId, sceneData) =>
      dispatch(StoryAction.createScene(authToken, storyId, sceneData)),
    resetPlaceBackground: () =>
      dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    getBackgroundImages: (storyId, authToken) =>
      dispatch(PlaceBackgroundActions.getBackgroundImages(storyId, authToken)),
    getCommandObjUpdated: commandArrayValues =>
      dispatch(StoryAction.updateCurrentCommandObj(commandArrayValues)),

    fetchAssets: (authToken, storyId) =>
      dispatch(LibraryActions.setAssetList(authToken, storyId)),
    setPlaceBackgroundProperties: (key, value) =>
      dispatch(PlaceBackgroundActions.setBackgroundProperties(key, value)),
    getViews: (storyId, authToken) =>
      dispatch(ViewsActions.getViews(storyId, authToken)),

    releseLock: (commandData, authToken, storyId, sceneId) =>
      dispatch(realeseLock(commandData, authToken, storyId, sceneId)),

    // Place backgrounds actions
    saveForLater: (data, storyId, authToken) =>
      dispatch(PlaceBackgroundActions.saveForLater(data, storyId, authToken)),
    saveRoute: (data, authToken, storyId, IsRouteEdit, editRouteId) =>
      dispatch(
        StoryAction.createRoute(
          data,
          authToken,
          storyId,
          IsRouteEdit,
          editRouteId
        )
      ),
    resetRouteModal: () => dispatch(StoryAction.resetRouteModal()),

    resetRouteResultModal: () => dispatch(StoryAction.resetRouteModal()),

    getCharacterRoutes: (authToken, storyId) =>
      dispatch(StoryAction.listRoute(authToken, storyId)),
    getRouteById: (storyId, routeId, authToken) =>
      dispatch(StoryAction.getRouteById(storyId, routeId, authToken)),
    deleteRoute: (storyId, routeId, authToken) =>
      dispatch(StoryAction.deleteRoute(storyId, routeId, authToken)),

    setActiveKey: activeKey =>
      dispatch(PlaceBackgroundActions.setActiveKey(activeKey)),
    resetViews: () => dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    addObject: object => dispatch(PlaceBackgroundActions.addObject(object)),
    reOrderObject: objects =>
      dispatch(PlaceBackgroundActions.reOrderObject(objects)),
    setObject: objectList =>
      dispatch(PlaceBackgroundActions.setObject(objectList)),
    fetchPreviewDataOnClick: (authToken, storyId, blockId) =>
      dispatch({
        type: ActionTypes.GET_PREVIEW_DATA_ON_CLICK,
        authToken: authToken,
        storyId: storyId,
        blockId: blockId
      }),
    clickOnFlowchartNode: blockId =>
      dispatch(StoryAction.flowchartBlockClick(blockId)),
    //  setCommandsData: (commandData, authToken, storyId, sceneId) => dispatch(setCommands(commandData, authToken, storyId, sceneId)),
    setCommentsData: (commentData, authToken, storyId, sceneId) =>
      dispatch(
        CommentAction.addComments(commentData, authToken, storyId, sceneId)
      ),
    hideCommentStatus: () => dispatch(CommentAction.hideCommentStatus()),
    fetchUIElements: (storyId, authToken) =>
      dispatch(GameInterfaceActions.getGameInterface(storyId, authToken)),
    fetchResetVariableWindow: (authToken, storyId, sceneId) =>
      dispatch(
        StoryAction.resetAllLocalVariableWindow(authToken, storyId, sceneId)
      ),
    updateVariableWindowCheck: (localVariableList, changedVariableList) =>
      dispatch(
        StoryAction.updateLocalVariableWindow(
          localVariableList,
          changedVariableList
        )
      ),
    markCompleteStoryRoute: (authToken, storyId, routeId, valueMark) =>
      dispatch(
        StoryAction.markCompleteStoryRoute(
          authToken,
          storyId,
          routeId,
          valueMark
        )
      ),

    markDraftStatus: (authToken, storyId, sceneId, value) =>
      dispatch(StoryAction.markDraftStatus(authToken, storyId, sceneId, value)),

    fetchGameFonts: (authToken, storyId) =>
      dispatch(GameInterfaceActions.getGameFonts(authToken, storyId)),
    updateRouteInfo: (authToken, storyId, routeInfo) =>
      dispatch(StoryAction.updateRouteInfo(authToken, storyId, routeInfo)),
    fetchLibraryChangeLog: (authToken, storyId) =>
      dispatch(LibraryActions.setLibraryChangeLog(authToken, storyId)),

    getStoryDetails: (authToken, storyId) =>
      dispatch({
        type: ActionTypes.GET_STORY_DETAILS,
        authToken: authToken,
        storyId: storyId
      }),

    autopopupSave: flag =>
      dispatch({
        type: ActionTypes.EDITOR_AUTO_SAVE_FLAG,
        flag: flag
      }),

    setPermission: flag =>
      dispatch({
        type: ActionTypes.SET_PANEL_PERMISSION_TRUE
      }),

    resetPermission: flag =>
      dispatch({
        type: ActionTypes.SET_PANEL_PERMISSION_FALSE
      }),

    resetStoryView: () =>
      dispatch({
        type: ActionTypes.RESET_DATA,
        key: 'viewId',
        value: null
      }),
    saveViewObjectFunction: (viewObject, fadeIntoSeconds) =>
      dispatch(
        PlaceBackgroundActions.setSaveViewObject(viewObject, fadeIntoSeconds)
      ),
    resetSaveViewObject: () =>
      dispatch(PlaceBackgroundActions.resetSaveViewObject()),
    getViewsEditable: (storyId, viewId, authToken) =>
      dispatch(
        PlaceBackgroundActions.getViewsEditable(storyId, viewId, authToken)
      ),
    getOtherViewOfCurrentBackground: (authToken, storyId, backgroundId) =>
      dispatch(
        PlaceBackgroundActions.getOtherViewsFromId(
          authToken,
          storyId,
          backgroundId
        )
      ),
    fontChange: (authToken, fontSize, font) =>
      dispatch(StoryAction.fontChange(authToken, fontSize, font)),
    spellChecker: (authToken, storyId, chapterId, commandObj) =>
      dispatch(
        StoryAction.spellCheckerCall(authToken, storyId, chapterId, commandObj)
      ),
    addToIgnoreCallPost: (authToken, storyId, chapterId, commandObj, word) =>
      dispatch(
        StoryAction.addToIgnoreCallPost(
          authToken,
          storyId,
          chapterId,
          commandObj,
          word
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriteStory);

import React from 'react';
import * as translations from './../../translations';
import { Button, Row, Col } from 'react-bootstrap';
import './../../style/style.css';
import * as Constant from './../../constant';

function AddCharacterModalBottom(props) {
  return (
    <Row lg={12}>
      <Col lg={6} className="footer-left-button p-0">
        {props.activeTabKey === Constant.FIRST_TAB_KEY && props.showClearFile && (
          <>
            <span className="mt-20" onClick={props.handleClearFile}>
              <img
                alt=""
                src="/images/icons/ic_chevron_right.svg"
                className="mr-1 checkIcon"
              />{' '}
              {translations.REMOVE_FILE}
            </span>
          </>
        )}
        {props.activeTabKey === Constant.SECOND_TAB_KEY && (
          <>
            <span
              className="mt-20"
              onClick={() => props.handleActiveKey(Constant.FIRST_TAB_KEY)}
            >
              <img
                alt=""
                src="/images/icons/ic_chevron_right.svg"
                className="mr-1 checkIcon"
              />{' '}
              {translations.BACK_TO_UPLOAD_FILE}
            </span>
          </>
        )}
      </Col>
      <Col lg={6} className="mt-20 text-right footer-right-button p-0">
        {props.activeTabKey === Constant.FIRST_TAB_KEY && (
          <Button
            disabled={props.continueToAddInfoClass ? false : true}
            className={`${props.continueToAddInfoClass} btn-snacktool btn-snacktool-golden`}
            onClick={() => props.handleActiveKey(Constant.SECOND_TAB_KEY)}
          >
            {props.isEdit
              ? translations.CONTINUE_TO_ADD_INFO
              : translations.CONTINUE_TO_ADD_INFO}
          </Button>
        )}
        {props.activeTabKey === Constant.SECOND_TAB_KEY && (
          <Button
            disabled={props.addInfoClass}
            type="submit"
            className="btn-snacktool btn-snacktool-golden"
            form="add-info-form"
          >
            {props.isEdit
              ? translations.UPDATE_INFO
              : translations.ADD_CHARACTER_ASSET}
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default AddCharacterModalBottom;

import React from 'react';
import { Style } from './style';
import Head from './head';
import PlaceBackgroundTabs from './../story/place-background/PlaceBackgroundTabs';

function ViewsContent(props) {
  return (
    <Style.contentPanel>
      <Head storyTitle={props.storyTitle} />
      <PlaceBackgroundTabs
        activeKey={props.activeKey}
        setActiveKey={props.setActiveKey}
        backgroundImages={props.backgroundImages}
        handleChange={props.handleChange}
        setCrop={props.setCrop}
        onHideModal={props.onHideModal}
        saveForLater={props.saveForLater}
        background={props.background}
        xcoordinate={props.xcoordinate}
        ycoordinate={props.ycoordinate}
        height={props.height}
        width={props.width}
        aspect={props.aspect}
        name={props.name}
        zoom={props.zoom}
        clickedEventCheck={props.clickedEventCheck}
        handleClickEvent={props.handleClickEvent}
        handleSaveForLaterClickEvent={props.handleSaveForLaterClickEvent}
        fadeIn={props.fadeIn}
        validated={props.validated}
        views={props.views}
        duplicateName={props.duplicateName}
        handleBrowseSelection={props.handleBrowseSelection}
        handleDuplicateClick={props.handleDuplicateClick}
        handleViewSearch={props.handleViewSearch}
        searchViews={props.searchViews}
        backgroundCopy={props.backgroundCopy}
        searchText={props.searchText}
        handlePlaceViewScript={props.handlePlaceViewScript}
        setShowAddObjectForm={props.setShowAddObjectForm}
        viewObjects={props.viewObjects}
        handleObjectDeleteClick={props.handleObjectDeleteClick}
        handleEditObjectClick={props.handleEditObjectClick}
        handleAddObjectClick={props.handleAddObjectClick}
        handleReorderList={props.handleReorderList}
        assetList={props.assetList}
        setObject={props.setObject}
        handleViewEditClick={props.handleViewEditClick}
        selectBackground={props.selectBackground}
        setSelectBackground={props.setSelectBackground}
        addObject={props.addObject}
        setAddObject={props.setAddObject}
        setEditView={props.setEditView}
        editView={props.editView}
        libraryChange={props.libraryChange}
        editViewFinal={props.editViewFinal}
        setAutoSaveFlag={props.setAutoSaveFlag}
        characterRoutesList={props.characterRoutesList}
        exportToGallary={props.exportToGallary}
        characterRoute={props.characterRoute}
        captionText={props.captionText}
        foundInJuicy={props.foundInJuicy}
        otherView={props.otherView}
      />
    </Style.contentPanel>
  );
}

export default ViewsContent;

import styled from "styled-components";

export const FlowchartWrapper = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
`;

export const actionButtonLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 22px;
    height: 18px;
    float: right;
    margin : 17px 15px 0px 0px;
  
  input[type='checkbox'] {
    display:none;
  }


  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #D6D6D6 0% 0% no-repeat padding-box;;
    border-radius:4px;
    -webkit-transition: .4s;
    transition: .4s;
    float:right;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    margin: 1px 0px 0px 0px;
    height: 16px;
    width: 5px;
    right: 2px;
    background: #FFF 0% 0% no-repeat padding-box;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius : 2px;
  }
  
  input:checked + .slider {
    background: #530ACD 0% 0% no-repeat padding-box;;
  }
  
  input:focus + .slider {
  }
  
  input:checked + .slider:before {
    -webkit-transform: translate(2px, 0px);;
    -ms-transform: translate(2px, 0px);;
    transform: translate(2px, 0px);
    border-radius: 1px;
    margin-right: 1px;
    width: 4px;
    height: 4px;
  }
`;

export const header = styled.div`
    height:51px;
    border-bottom : 1px solid #F5F5FA;
    display: ${props => props.currentSceneId}
`;

export const Label = styled.label`
    margin : 17px 0px 17px 16px;
    text-align: left;
    
    letter-spacing: 0;
    color: ${props => props.flowchartScenceLabelColor || '#530ACD'}
    display: ${props => props.flowchartToggle || 'none'}
    opacity: 1;
`;

export const actionButton = styled.button`
    height: 18px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    background: #D6D6D6 0% 0% no-repeat padding-box;
    opacity: 1;
    margin : 17px 12px 17px 0px;
`

export const showHide = styled.div``;

export const arrowIcon = styled.span`
  color:#ACACAC;
  .fa-angle-right:before {
    color:#ACACAC !important;
    padding-left : 13px;
    padding-right: 9px;
  }

  fa-angle-right {
    color:#ACACAC !important;
    padding-left : 13px;
    padding-right: 9px;
  }
`

export const choicesLabel = styled.span`
  display: ${props => props.flowchartToggle || 'none'};
  color:#530ACD;  
  
`;

export const GraphCss = styled.div`
margin-top:10px;
.vis-network:focus {
  outline: 0;
}
.blankData {
  display:none;
}
`

export const writeStoryFlowchart = styled.div`
    width: 100%;
    background: ${props => props.backgroundColor || props.backgroundColor} 0% 0% no-repeat padding-box;
    opacity: 1;
    
    padding-right:0px;
    padding-left:0px;
    .showErrorFlowchart{
      // background: #F5F5FA 0% 0% no-repeat padding-box;
      background: #8452E8 0% 0% no-repeat padding-box;

      border-radius: 4px;
      text-align: left;
      font: normal normal normal 14px/19px PT Sans;
      margin-top: 50%;
      padding: 15px 15px 15px 40px;
      margin-left: 14px;
      margin-right: 14px;
      background-image:url('/images/icons/warning_icon.svg') !important;
      background-size: 24px 24px;
      background-position: 10px 15px;
    }
  }
`;


import styled from "styled-components";
export const libraryChangeLogModal = styled.div`

.nav{
    display: inline-block;
}
.nav-tabs{
    border-bottom:2px solid #F0EEEE;
    .nav-link{
        font-family:PT Sans;
        font-weight: bold;
        font-size:14px;
        letter-spacing: 0px;
        color: #ACACAC;
        float:left;
        padding-left:30px;
        padding-right:30px;
    }
    .nav-link:hover{
        border-color: transparent;
        
    }
    .nav-link.active{
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom:4px solid #530ACD;
        color:#530ACD;
    }
}
.tab-content{
    padding:40px 0 0;
}
`;
export const libraryChangeLogModalContant = styled.div`
    padding: 0 0;
    .bg-light{
        background:#F5F5F5;
        th{
            border:none;
            font-size:11px;
            letter-spacing: 1.1px;
            color: #606060;
            text-transform: uppercase;
            .fa{
                position:relative;
            }
            .fa-sort-up:before, .fa-sort-asc:before, .fa-sort-down:before, .fa-sort-desc:before{
                font-size:16px;
                position:absolute;
                top: -16px;
                left: 5px;
            }
            .fa-sort-up:before, .fa-sort-asc:before{
                top: -8px;
            }
        }
    }
    .table-body{
        tr:first-child{
            td{
                border-top:none; 
            }
        }
        td{
            font-family: PT Sans;
            font-size:14px;
            letter-spacing: 0px;
            color: #303030;
            .badges{
                background:#DFDFDF;
                font-size: 12px;
                letter-spacing: 0.22px;
                color: #000000;
                padding:5px 14px;
            }
        }
        td:first-child{
            position:relative;
            &:before{
                content: '';
                width: 20px;
                height: 1px;
                background: #fff;
                position: absolute;
                bottom: -1px;
                left: 0;    
            }
        }
        td:last-child{
            position:relative;
            &:before{
                content: '';
                width: 20px;
                height: 1px;
                background: #fff;
                position: absolute;
                bottom: -1px;
                right: 0;    
            }
        }
        td.blur-text{
            font-size:12px;
            letter-spacing: 0px;
            color: #ACACAC;
        }
    }
    .table td, .table th {
        padding:14px 31px;
    }
`;

export const libraryChangeLog = {
    libraryChangeLogModal,
    libraryChangeLogModalContant
}

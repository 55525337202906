import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function CheckBox({component, enableDisableUsingCheckBox, enableDisableCheckBox}) {
    const [checkbox, setCheckbox] = useState(false);
    const handleChange = () => {
        setCheckbox(!enableDisableCheckBox);
        enableDisableUsingCheckBox(!enableDisableCheckBox);
    }

    return(
        <Form.Group controlId = {component.widgetName} className="commandLabelSuffix custom-checkbox">
            <input type="checkbox" 
                name={component.widgetName} 
                className='styled-checkbox' 
                id='styled-checkbox1'
                label={component.label}
                onChange={handleChange}
                value = {checkbox}/>
            <label for='styled-checkbox1' className="commandLabel">{component.label} </label>
        </Form.Group>

    )
}

export default CheckBox
import React from 'react';
import { LibraryStyle} from './../../style/style';
import { Row, Col} from 'react-bootstrap';

function  Fail() {
    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <Row>
                    <Col className="fail-icon">
                    <img src="/images/icons/ic_clear_24px.svg" className="mediumIcon"/>

                    </Col>
                </Row>
                <Row>
                    <Col className="submit-asset-label">
                        Asset can't be saved. Something went wrong!
                    </Col>
                </Row>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Fail
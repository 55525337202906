import React from 'react';
import { NavLink } from 'react-router-dom';
import { AdminStyle } from './style/admin-style';
import * as Translations from './translations';
import * as Config from './../../config/app.config';

function AdminMenu({ selectedMenu, setSelectedMenu, roleAdmin }) {
  const handleMenuSelection = menuName => {
    setSelectedMenu(menuName);
  };

  return (
    <AdminStyle.libraryMenu>
      <AdminStyle.libraryMenuList>
        {roleAdmin === '[ROLE_ADMIN]' && (
          <AdminStyle.libraryMenuListItem
            selectedMenu={selectedMenu === 'users' ? true : false}
          >
            <NavLink
              variant="link"
              exact
              onClick={() => handleMenuSelection('users')}
              to="users"
              className={
                selectedMenu === 'users' ? 'menu-enabled' : 'menu-disabled'
              }
            >
              {selectedMenu === 'users' && (
                <img
                  alt=""
                  className="svg-icon-image"
                  src={Config.IMAGE_ICON_PATH + 'user_circle_active.svg'}
                />
              )}
              {selectedMenu !== 'users' && (
                <img
                  alt=""
                  className="svg-icon-image"
                  src={Config.IMAGE_ICON_PATH + 'user_circle.svg'}
                />
              )}
              &nbsp;&nbsp;{Translations.ACCOUNTS_LIST}
            </NavLink>
          </AdminStyle.libraryMenuListItem>
        )}
        <AdminStyle.libraryMenuListItem
          selectedMenu={selectedMenu === 'stories' ? true : false}
        >
          <NavLink
            to="stories"
            onClick={() => handleMenuSelection('stories')}
            className={
              selectedMenu === 'stories' ? 'menu-enabled' : 'menu-disabled'
            }
          >
            {selectedMenu === 'stories' && (
              <img
                alt=""
                className="menu-icon svg-icon-image"
                src={Config.IMAGE_ICON_PATH + 'ic-chat.svg'}
              />
            )}
            {selectedMenu !== 'stories' && (
              <img
                alt=""
                className="menu-icon svg-icon-image"
                src={Config.IMAGE_ICON_PATH + 'stories-inactive.svg'}
              />
            )}

            {Translations.STORY_LIST}
          </NavLink>
        </AdminStyle.libraryMenuListItem>
      </AdminStyle.libraryMenuList>
    </AdminStyle.libraryMenu>
  );
}

export default AdminMenu;

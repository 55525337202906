import * as Constant from './../../../common/constants';
import { ImageMapper } from './../../../common/helper/image-mapper';
import * as Config from './../../../config/aspectRatio.config';
import mergeImages from 'merge-images';

const setBackGround = (command, blobImages, lastView, aspectRatio, skipTimeoutTemp, currentView) => {
    let previewWidth = Config.aspectRatioParameters[aspectRatio].previewWidth;
    let previewHeight = Config.aspectRatioParameters[aspectRatio].previewHeight;
    let commandParameters = command.view;
    let previewContentOriginal = document.getElementById('preview-content');
    let oldBackgroundView = document.getElementById('preview-content-background-' + currentView);
    
    if (oldBackgroundView) {
        oldBackgroundView.remove();
    }

    let emptyBackground = document.getElementById('preview-content-background');
    if (emptyBackground) {
        emptyBackground.remove();
    }

    oldBackgroundView = document.getElementById('preview-content-background-0');
    
    if (oldBackgroundView) {
        oldBackgroundView.remove();
    }

    let previewContent = document.getElementById('preview-content-background-' + commandParameters.id);

    if (previewContent == null) {
        previewContent = document.createElement('div');
        previewContent.id = 'preview-content-background-'+commandParameters.id;
        previewContent.className = 'preview-content-background';
        previewContentOriginal.insertBefore(previewContent, previewContentOriginal.childNodes[0]);
    }

    if (Object.keys(commandParameters).length !== 0
        && commandParameters) {
        clearLastView(lastView);
        let url = `url(${blobImages[command.view.asset.awsPath]})`;

        previewContent.style.backgroundImage = url;
        previewContent.style.height = previewHeight + 'px';
        previewContent.style.width = previewWidth + 'px';

        setBackgroundView(command, previewContent, previewWidth, previewHeight, aspectRatio);
        
        previewContent.style.backgroundRepeat = 'no-repeat';
        if (commandParameters.hasOwnProperty(Constant.BACKGROUND_POSITION)) {
            previewContent.style.backgroundPosition = command.parameters[Constant.BACKGROUND_POSITION].toLowerCase();
        }
        
        if (skipTimeoutTemp === false) {
            // Fade in effect on play in without skip mode
            if (command.parameters[Constant.BACKGROUND_FADE]) {
                previewContent.style.animation = `fadeIn ${command.parameters[Constant.BACKGROUND_FADE]}s`;
            } else {
                previewContent.style.animation = `fadeIn 0.5s`
            }
        } else {
            // Remove added animation in skip mode
            previewContent.style.removeProperty('animation');
        }
    }

    let fadeIn = skipTimeoutTemp === true ? 0 : command.parameters[Constant.BACKGROUND_FADE];

    setObjectsImagesOnViews(command, 
        blobImages, 
        previewContent, 
        fadeIn, 
        aspectRatio);
}

const changeBackground = (command) => {
    setBackGround(command);
}

const moveBackGround = (command, blobImages, aspectRatio, skipTimeoutTemp, previousCommand, currentView, lastHideObj) => {
    let commandParameters = command.view;
    let previewWidth = Config.aspectRatioParameters[aspectRatio].previewWidth;
    let previewHeight = Config.aspectRatioParameters[aspectRatio].previewHeight;
    
    let previewContent = document.getElementById('preview-content-background-' + currentView);
    if (!previewContent) {
        return false;
    }

    // add objects in background
    if (skipTimeoutTemp === false) {
        prepareExistingBackgroundForMove(previousCommand, 
            command,
            blobImages,
            previewContent,
            aspectRatio,
            previewWidth, 
            previewHeight,
            skipTimeoutTemp,
            lastHideObj)
    }
    

    //moveExistingBackground(command, previewContent, previewHeight, previewWidth, aspectRatio);

    if (skipTimeoutTemp === true && Object.keys(commandParameters).length !== 0
        && commandParameters) {

        previewContent.style.height = previewHeight + 'px';
        previewContent.style.width = previewWidth + 'px';
        
        setBackgroundView(command, previewContent, previewWidth, previewHeight, aspectRatio);

        if (commandParameters.hasOwnProperty(Constant.BACKGROUND_POSITION_TO)) {
            previewContent.style.backgroundPosition = commandParameters[Constant.BACKGROUND_POSITION_TO].toLowerCase();
        }

        previewContent.style.transition = ''

        if (skipTimeoutTemp === false && command.parameters['Move Duration']) {
            previewContent.style.transition = `background ${commandParameters['Move Duration']}s ease-in-out`;
            previewContent.style.opacity = 1;
        }

        let moveDuration = skipTimeoutTemp ? 0 : command.parameters['Move Duration'];
        setObjectsImagesOnViews(command, 
            blobImages, 
            previewContent, 
            0,
            aspectRatio);
    }
    
    // let moveDuration = skipTimeoutTemp ? 0 : command.parameters['Move Duration'];
    // setObjectsImagesOnViews(command, 
    //     blobImages, 
    //     previewContent, 
    //     moveDuration,
    //     aspectRatio);

    return true;
}

const setObjectsImagesOnViews = (command, blobImages, previewContent, fadeIn, aspectRatio) => {
    var elements = document.querySelectorAll('.viewObjectsImages')

    for (let i = 0; i < elements.length; i++) {
        elements[i].remove()
    }

    let pWidth = Config.aspectRatioParameters[aspectRatio].previewWidth;
    let pHeight = Config.aspectRatioParameters[aspectRatio].previewHeight;
    if (aspectRatio === Config.aspectRatioNineByNineteen) {
        pWidth = Config.aspectRatioParameters[aspectRatio].objectViewWidth;
        pHeight = Config.aspectRatioParameters[aspectRatio].objectViewHeight;
    }
    let objects = ImageMapper.mapDefaultObjectsOnView(command.view.viewObjects.slice().reverse(), 
        command.view.width, 
        command.view.height, 
        pWidth,
        pHeight, 
        fadeIn, 
        aspectRatio,
        blobImages);

    previewContent.insertAdjacentHTML('beforeend', objects);
}

const prepareExistingBackgroundForMove = (command, nextCommand, blobImages, previewContent, aspectRatio, previewWidth, previewHeight, skipTimeoutTemp, lastHideObj) => {
    var elements = document.querySelectorAll('.viewObjectsImages')
    let originalImageSpecs = ImageMapper.getOriginalSpecsOfImage(command.view.asset.specs);

    // Map current background object
    let previousObjects = ImageMapper.mapDefaultObjectsOnBackground(command.view.viewObjects, 
        originalImageSpecs[0], 
        originalImageSpecs[1], 
        originalImageSpecs[0],
        originalImageSpecs[1], 
        aspectRatio,
        blobImages,
        false,
        lastHideObj);

        // Map next background objects
    let nextObjects = ImageMapper.mapDefaultObjectsOnBackground(nextCommand.view.viewObjects, 
        originalImageSpecs[0], 
        originalImageSpecs[1], 
        originalImageSpecs[0],
        originalImageSpecs[1], 
        aspectRatio,
        blobImages);

        let mergeFiles = [];
        mergeFiles.push({
            src: blobImages[command.view.asset.awsPath],
            x: 0,
            y: 0
        });

        for (let i = 0; i < previousObjects.length; i++) {
            mergeFiles.push({
                src: blobImages[command.view.viewObjects[i].asset.awsPath],
                x: previousObjects[i][0] + command.view.xcoordinate,
                y: previousObjects[i][1] + command.view.ycoordinate,
                width: previousObjects[i][2],
                height: previousObjects[i][3]
            })
        }
        for (let i = 0; i < nextObjects.length; i++) {
            mergeFiles.push({
                src: blobImages[nextCommand.view.viewObjects[i].asset.awsPath],
                x: nextObjects[i][0] + nextCommand.view.xcoordinate,
                y: nextObjects[i][1] + nextCommand.view.ycoordinate,
                width: nextObjects[i][2],
                height: nextObjects[i][3]
            })
        }
        
    mergeImages(mergeFiles).then(b64 => {
        previewContent.style.backgroundImage = `url(${b64})`;
        for (let i = 0; i < elements.length; i++) {
            elements[i].remove()
        }
    
        if (!command.view) {
            return false;
        }
        moveExistingBackground(nextCommand, previewContent, previewWidth, previewHeight, aspectRatio, blobImages, skipTimeoutTemp)
    })
}

const moveExistingBackground = (command, previewContent, previewWidth, previewHeight, aspectRatio, blobImages, skipTimeoutTemp) => {
    let commandParameters = command.parameters;
    if (Object.keys(commandParameters).length !== 0
        && commandParameters) {

        previewContent.style.height = previewHeight + 'px';
        previewContent.style.width = previewWidth + 'px';
        if (skipTimeoutTemp === false) {
            previewContent.style.transition = `background ${command.parameters['Move Duration']}s`;
        }
        
        setTimeout(() => {
            setNewBackgroundAfterMove(command, previewContent, previewWidth, previewHeight, aspectRatio, blobImages, skipTimeoutTemp);
            let moveDuration = skipTimeoutTemp ? 0 : command.parameters['Move Duration'];
            setObjectsImagesOnViews(command, 
                blobImages, 
                previewContent, 
                0,
                aspectRatio);
        }, command.parameters['Move Duration'] * 1000);
        setBackgroundView(command, previewContent, previewWidth, previewHeight, aspectRatio);

        // if (commandParameters.hasOwnProperty(Constant.BACKGROUND_POSITION_TO)) {
        //     previewContent.style.backgroundPosition = commandParameters[Constant.BACKGROUND_POSITION_TO].toLowerCase();
        // }

        // previewContent.style.transition = ''

        // if (skipTimeoutTemp === false && command.parameters['Move Duration']) {
        //     previewContent.style.transition = `background ${commandParameters['Move Duration']}s ease-in-out`;
        //     previewContent.style.opacity = 1;
        // }
    }
}

const setNewBackgroundAfterMove = (command, previewContent, previewWidth, previewHeight, aspectRatio, blobImages, skipTimeoutTemp) => {
    let commandParameters = command.parameters
    if (Object.keys(commandParameters).length !== 0
        && commandParameters) {
        previewContent.style.backgroundImage = `url(${blobImages[command.view.asset.awsPath]})`;
        // previewContent.style.height = previewHeight + 'px';
        // previewContent.style.width = previewWidth + 'px';
        
        //setBackgroundView(command, previewContent, previewWidth, previewHeight, aspectRatio);

        // if (commandParameters.hasOwnProperty(Constant.BACKGROUND_POSITION_TO)) {
        //     previewContent.style.backgroundPosition = commandParameters[Constant.BACKGROUND_POSITION_TO].toLowerCase();
        // }

        // previewContent.style.transition = ''

        // if (skipTimeoutTemp === false && command.parameters['Move Duration']) {
        //     previewContent.style.transition = `background ${commandParameters['Move Duration']}s ease-in-out`;
        //     previewContent.style.opacity = 1;
        // }
    }
}

const clearLastView = (lastView) => {
    if (null === lastView || undefined === lastView) {
        return;
    }

    let previewContent = document.getElementById('preview-content');
    //previewContent.style.backgroundImage = '';
    lastView.view.viewObjects.map(object => {
        let removeChild = document.getElementById(`object-${object.asset.id}`);

        if (removeChild === null) {
            return;
        }

        removeChild.parentNode.removeChild(removeChild);
    });
}

const setBackgroundView = (command, previewContent, previewWidth, previewHeight, aspectRatio) => {
    // Find original specs (height, width) of original image
    let originalSpecs = ImageMapper.getOriginalSpecsOfImage(command.view.asset.specs);
    const realImageHeight = originalSpecs[1];
    const realImageWidth = originalSpecs[0];

    // Get height and width of current view for 3/4 aspect ratio
    let currentWidth = parseInt(command.view.width)
    let currentHeight = command.view.height

    // Find zoom level of view
    let zoomW = (currentWidth / previewWidth);
    let zoomH = (currentHeight / previewHeight);


    // For ratio 9/19
    let widthDiff = 0;
    if (aspectRatio === Config.aspectRatioNineByNineteen) {
         let width = Math.round(((Config.aspectRatioCalcNineByNineteen) * currentHeight));
        // if (currentWidth < width) {
        //     widthDiff = currentWidth - width;
        // } else {
        //     let divident = 1.2;
        //     if (currentWidth > previewWidth) {
        //         divident = 2
        //     }
        //     if (width > Config.aspectRatioParameters[aspectRatio].previewWidth) {
        //         divident = 4;
        //     }
        //     widthDiff = (currentWidth - width) / divident;
        // }
        // let divident = 2;
        // if (width > Config.aspectRatioParameters[aspectRatio].previewWidth) {
        //     divident = 4;
        // }
        // if (currentWidth > previewWidth) {
        //     widthDiff = Math.round((currentWidth - width) * 0.42) / 1.5;
        // } else {
           widthDiff = (currentWidth - width);
           zoomW = width / previewWidth;
        //}
        
        //currentWidth = width;
        //widthDiff = (currentWidth - width);
    }

    // Calculate new coordinate respective to view port
    // Find X
    let newCoordinateX = ImageMapper.prepareBackgroundCoordinate(command.view, realImageWidth, realImageWidth / zoomW, aspectRatio, widthDiff);
    // Find Y
    let newCoordinateY = ImageMapper.prepareBackgroundYCoordinate(command.view, realImageHeight, realImageHeight / zoomH);

    // Set x,y to background
    previewContent.style.backgroundPositionX = `-${newCoordinateX[0]}px`;
    previewContent.style.backgroundPositionY = `-${newCoordinateY[1]}px`;
    previewContent.style.backgroundSize = `${realImageWidth / (zoomW)}px ${realImageHeight / zoomH}px`;
}

export const BackGroundCommands = {
    setBackGround,
    changeBackground,
    moveBackGround
}
import React from 'react';
import { Modal,Button } from 'react-bootstrap';
import { DeleteStyle} from './style';
 

function Success(props) {
  return (
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={props.showModal}
    onHide={props.handleHideDeleteModal}
    >
      <Modal.Body  >
        
      <DeleteStyle.addAssetMessageBox>
            <DeleteStyle.successAssetContent>
              <div className="success-icon mb-3">
                <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
              </div>
              <div  data-testid="success-label" className="submit-asset-label">Story has been deleted.</div>
              {/* <div  data-testid="success-note" className="text-muted submit-asset-subtitle ">You will be notified after our Editors have reviewed it.</div> */}
              <div className="mt-4">
                <Button data-testid="success-btn"  onClick={props.handleHideDeleteModal}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
              </div>
            </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}
export default Success;
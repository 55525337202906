import * as ActionTypes from './story-action-types';

// List Scene ::: Begin
export function listScene(token, storyId) {
  return {
    type: ActionTypes.LIST_SCENE,
    authToken: token,
    storyId: storyId
  };
}

// List Scene
export function saveSceneList(token, storyId, updatedList) {
  return {
    type: ActionTypes.UPDATE_LIST_SCENE,
    authToken: token,
    storyId: storyId,
    updatedList: updatedList
  };
}

export function resetCast() {
  return {
    type: ActionTypes.RESET_CAST
  };
}

export function saveListSceneSuccess(token, storyId) {
  return {
    type: ActionTypes.UPDATE_LIST_SCENE_SUCCESS,
    authToken: token,
    storyId: storyId
  };
}

export function saveListSceneFail(token, storyId) {
  return {
    type: ActionTypes.UPDATE_LIST_SCENE_FAILURE,
    authToken: token,
    storyId: storyId
  };
}

export function listSceneSuccess(storyScenes) {
  return {
    type: ActionTypes.LIST_SCENE_SUCCESS,
    storyScenes: storyScenes
  };
}

export function listSceneFail(errors) {
  return {
    type: ActionTypes.LIST_SCENE_FAILURE,
    errors: errors
  };
}
// List Scene ::: End

// Create Scene ::: Begin
export function createScene(token, storyId, sceneData) {
  return {
    type: ActionTypes.CREATE_SCENE,
    authToken: token,
    storyId: storyId,
    sceneData: sceneData
  };
}

export function createSceneSuccess(storyScenes) {
  return {
    type: ActionTypes.CREATE_SCENE_SUCCESS,
    storyScenes: storyScenes
  };
}

export function createSceneFail(errors) {
  return {
    type: ActionTypes.CREATE_SCENE_FAILURE,
    errors: errors
  };
}
// Create Scene ::: End

//Add Cost

export function createCast(
  payload,
  token,
  storyId,
  isCastEdit,
  editRouteId,
  characterId
) {
  return {
    type: ActionTypes.CREATE_CAST,
    payload: payload,
    authToken: token,
    storyId: storyId,
    isCastEdit: isCastEdit,
    castId: editRouteId,
    characterId: characterId
  };
}

export function createCastSuccess(storyScenes) {
  return {
    type: ActionTypes.CREATE_CAST_SUCCESS,
    storyScenes: storyScenes
  };
}

export function createCastFail(errors) {
  return {
    type: ActionTypes.CREATE_CAST_FAILURE,
    errors: errors
  };
}

export function resetCastModal() {
  return {
    type: ActionTypes.RESET_CAST_MODALS
  };
}

export function getCast(storyId, castId, authToken) {
  return {
    type: ActionTypes.GET_CAST,
    storyId: storyId,
    characterId: castId,
    authToken: authToken
  };
}

export function getCastSuccess(castData) {
  return {
    type: ActionTypes.GET_CAST_SUCCESS,
    castData: castData,
    isCastEdit: castData
  };
}

export function getCastFail(payload) {
  return {
    type: ActionTypes.GET_CAST_FAILURE,
    payload: payload
  };
}

export function deleteCast(storyId, authToken, characterId) {
  return {
    type: ActionTypes.DELETE_CAST,
    storyId: storyId,
    characterId: characterId,
    authToken: authToken
  };
}

export function deleteCastSuccess() {
  return {
    type: ActionTypes.DELETE_CAST_SUCCESS,
    castData: []
  };
}

export function deleteCastFail(payload) {
  return {
    type: ActionTypes.DELETE_CAST_FAILURE,
    payload: payload
  };
}
//End Add Cost

//Create Route ::: Begin

export function createRoute(payload, token, storyId, IsRouteEdit, editRouteId) {
  return {
    type: ActionTypes.CREATE_ROUTE,
    payload: payload,
    authToken: token,
    storyId: storyId,
    IsRouteEdit: IsRouteEdit,
    routeId: editRouteId
  };
}

export function createRouteSuccess(storyScenes) {
  return {
    type: ActionTypes.CREATE_ROUTE_SUCCESS,
    storyScenes: storyScenes
  };
}

export function createRouteFail(errors) {
  return {
    type: ActionTypes.CREATE_ROUTE_FAILURE,
    errors: errors
  };
}

export function resetRouteModal() {
  return {
    type: ActionTypes.RESET_ROUTE_MODALS
  };
}

export function resetRouteResultModal() {
  return {
    type: ActionTypes.RESET_ROUTE_MODALS
  };
}

export function listRoute(token, storyId) {
  return {
    type: ActionTypes.GET_ROUTE,
    authToken: token,
    storyId: storyId
  };
}

export function listRouteSuccess(storyRoutes) {
  return {
    type: ActionTypes.GET_ROUTE_SUCCESS,
    storyRoutes: storyRoutes
  };
}

export function listRouteFail(errors) {
  return {
    type: ActionTypes.GET_ROUTE_FAILURE,
    errors: errors
  };
}

export function markCompleteStoryRoute(authToken, storyId, routeId, markFlag) {
  return {
    type: ActionTypes.MARK_STORY_ROUTE,
    storyId: storyId,
    routeId: routeId,
    authToken: authToken,
    flag: markFlag
  };
}

export function markCompleteStoryRouteSuccess(payload) {
  return {
    type: ActionTypes.MARK_STORY_ROUTE_SUCCESS,
    payload: payload
  };
}

export function markCompleteStoryRouteFail(payload) {
  return {
    type: ActionTypes.MARK_STORY_ROUTE_FAIL,
    payload: payload
  };
}

export function markDraftStatus(authToken, storyId, sceneId, flag) {
  return {
    type: ActionTypes.MARK_STORY_DRAFT_STATUS,
    storyId: storyId,
    sceneId: sceneId,
    authToken: authToken,
    flag: flag
  };
}

export function markDraftStatusSuccess(payload) {
  return {
    type: ActionTypes.MARK_STORY_DRAFT_STATUS_SUCCESS,
    payload: payload
  };
}

export function markDraftStatusFail(payload) {
  return {
    type: ActionTypes.MARK_STORY_DRAFT_STATUS_FAIL,
    payload: payload
  };
}

export function getRouteById(storyId, routeId, authToken) {
  return {
    type: ActionTypes.GET_ROUTE_BY_ID,
    storyId: storyId,
    routeId: routeId,
    authToken: authToken
  };
}

export function getRouteByIdSuccess(routeData) {
  return {
    type: ActionTypes.GET_ROUTE_BY_ID_SUCCESS,
    routeData: routeData
  };
}

export function getRouteByIdFail(payload) {
  return {
    type: ActionTypes.GET_ROUTE_BY_ID_FAIL,
    payload: payload
  };
}

export function deleteRoute(storyId, routeId, authToken) {
  return {
    type: ActionTypes.DELETE_ROUTE,
    storyId: storyId,
    routeId: routeId,
    authToken: authToken
  };
}

export function deleteRouteSuccess(routeData) {
  return {
    type: ActionTypes.DELETE_ROUTE_SUCCESS,
    routeData: routeData
  };
}

export function deleteRouteFail(payload) {
  return {
    type: ActionTypes.DELETE_ROUTE_FAILURE,
    payload: payload
  };
}
//Create Route ::: End

//Commands: Level1
export function getCommandsLevel1(token) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL1,
    authToken: token
  };
}

export function getCommandsLevel1Success(commandsLevel1) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL1_SUCCESS,
    commandsLevel1: commandsLevel1
  };
}

export function getCommandsLevel1Fail(errors) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL1_FAIL,
    errors: errors
  };
}

// Commands:Level2
export function getCommandsLevel2(token, storyId, commandId, sceneId) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL2,
    authToken: token,
    storyId: storyId,
    commandId: commandId,
    sceneId: sceneId
  };
}

export function getCommandsLevel2Success(commandsLevel2, commandId) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL2_SUCCESS,
    commandsLevel2: commandsLevel2,
    commandId: commandId
  };
}

export function getCommandsLevel2Fail(errors) {
  return {
    type: ActionTypes.GET_COMMAND_LEVEL2_FAIL,
    errors: errors
  };
}

export function getCommandsLevel2Script(token, storyId, commandId) {
  return {
    type: ActionTypes.GET_SCRIPT_COMMAND_LEVEL2,
    authToken: token,
    storyId: storyId,
    commandId: commandId
  };
}

export function getCommandsLevel2ScriptSuccess(commandsLevel2, commandId) {
  return {
    type: ActionTypes.GET_SCRIPT_COMMAND_LEVEL2_SUCCESS,
    commandsLevel2Scripts: commandsLevel2,
    commandId: commandId
  };
}

export function getCommandsLevel2ScriptFail(errors) {
  return {
    type: ActionTypes.GET_SCRIPT_COMMAND_LEVEL2_FAIL,
    errors: errors
  };
}

// Commands:Level2: Character Variation OPTIONS
// export function getCharacterVariations(token, storyId, characterId, isEditScript) {
//   return {
//     type: ActionTypes.GET_CHAR_VARIATIONS,
//     authToken: token,
//     storyId: storyId,
//     characterId: characterId,
//     isEdit: isEditScript
//   }
// }

// export function getCharacterVariationsSuccess(charVariations, isEdit) {
//   return {
//     type: ActionTypes.GET_CHAR_VARIATIONS_SUCCESS,
//     characterVariations: charVariations,
//     isEdit: isEdit

//   }
// }

// export function getCharacterVariationsFail(errors) {
//   return {
//     type: ActionTypes.GET_CHAR_VARIATIONS_FAIL,
//     errors: errors
//   }

// }

// Commands Level2 : Character color get fetched
export function getCharacterColor(token, storyId, characterId) {
  return {
    type: ActionTypes.GET_CHAR_COLOR,
    authToken: token,
    storyId: storyId,
    characterId: characterId
  };
}

export function getCharacterColorSuccess(charColor) {
  return {
    type: ActionTypes.GET_CHAR_COLOR_SUCCESS,
    characterColor: charColor
  };
}

export function getCharacterColorFail(errors) {
  return {
    type: ActionTypes.GET_CHAR_COLOR_FAIL,
    errors: errors
  };
}

// Commands:Level2: Character Expression OPTIONS
// export function getCharacterExpressions(token, storyId, characterId, variationId, isEditScript) {
//   return {
//     type: ActionTypes.GET_CHAR_EXPRESSIONS,
//     authToken: token,
//     storyId: storyId,
//     characterId: characterId,
//     variationId: variationId,
//     isEditScript: isEditScript

//   }
// }

// export function getCharacterExpressionsSuccess(charExpressions, isEdit) {
//   return {
//     type: ActionTypes.GET_CHAR_EXPRESSIONS_SUCCESS,
//     characterExpressions: charExpressions,
//     isEdit: isEdit
//   }
// }

// export function getCharacterExpressionsFail(errors) {
//   return {
//     type: ActionTypes.GET_CHAR_EXPRESSIONS_FAIL,
//     errors: errors
//   }
// }

export function setStory(payload, authToken, editStory, storyId) {
  return {
    type: ActionTypes.SET_STORY,
    payload: payload,
    authToken: authToken,
    editStory: editStory,
    storyId: storyId
  };
}

export function setStorySuccess(payload) {
  return {
    type: ActionTypes.SET_STORY_SUCCESS,
    payload: payload
  };
}

export function setStoryFail(payload) {
  return {
    type: ActionTypes.SET_STORY_FAIL,
    payload: payload
  };
}

export function setStoryProgress(payload, authToken, storyId, sceneId) {
  return {
    type: ActionTypes.SET_STORY_SAVE_PROGRESS,
    payload: payload,
    storyId: storyId,
    authToken: authToken,
    sceneId: sceneId
  };
}

export function setStoryProgressSuccess(payload) {
  return {
    type: ActionTypes.SET_STORY_SAVE_PROGRESS_SUCCESS,
    payload: payload
  };
}

export function setStoryProgressFail(payload) {
  return {
    type: ActionTypes.SET_STORY_SAVE_PROGRESS_FAIL,
    payload: payload
  };
}

export function getStoryGenere(payload) {
  return {
    type: ActionTypes.GET_STORY_GENERE,
    payload: payload
  };
}

export function getStoryGenereSuccess(payload) {
  return {
    type: ActionTypes.GET_STORY_GENERE_SUCCESS,
    payload: payload
  };
}

export function getStoryGenereFail() {
  return {
    type: ActionTypes.GET_STORY_GENERE_FAIL
  };
}

export function setCursorPostion(index) {
  return {
    type: ActionTypes.SET_CURSOR_POSITION,
    index: index
  };
}

export function setShowModalTrue(editStory) {
  return {
    type: ActionTypes.SET_SHOW_MODAL_TRUE,
    editStory: editStory
  };
}

export function setStoryViewStatus(flag) {
  return {
    type: ActionTypes.SET_VIEW_STORY_STATUS,
    flag: flag
  };
}

export function setRouteInfoViewStatus(paramShow) {
  return {
    type: ActionTypes.SET_VIEW_ROUTE_STATUS,
    viewRoute: paramShow
  };
}

export function setStoryAddComment(status, storyStatus) {
  return {
    type: ActionTypes.SET_COMMENT_STORY_STATUS,
    status: status,
    storyStatus: storyStatus
  };
}

export function setShowModalFalse(editStory) {
  return {
    type: ActionTypes.SET_SHOW_MODAL_FALSE,
    editStory: editStory
  };
}

export function setStoryNameValidity(storyName, authToken) {
  return {
    type: ActionTypes.SET_STORY_NAME_VALIDITY,
    storyName: storyName,
    authToken: authToken
  };
}

export function setStoryNameValidityTrue(payload) {
  return {
    type: ActionTypes.SET_STORY_NAME_VALIDITY_TRUE,
    payload: payload
  };
}

export function setStoryNameValidityFalse() {
  return {
    type: ActionTypes.SET_STORY_NAME_VALIDITY_FALSE
  };
}

export function setCommandLevel(commandLevel) {
  return {
    type: ActionTypes.SET_COMMAND_LEVEL,
    commandLevel: commandLevel
  };
}

export function resetCommandValue() {
  return {
    type: ActionTypes.RESET_COMMAND_VALUE
  };
}

export function getStoryAdmin(authToken, authorNameAdded) {
  return {
    type: ActionTypes.GET_STORY_ADMIN,
    authToken: authToken,
    authorNameAdded: authorNameAdded
  };
}

export function getStoryAdminSuccess(payload) {
  return {
    type: ActionTypes.GET_STORY_ADMIN_SUCCESS,
    payload: payload
  };
}

export function getStoryAdminSuccessWithSearch(payload, authorNameAdded) {
  return {
    type: ActionTypes.GET_STORY_ADMIN_SUCCESS_WITH_AUTHOR,
    payload: payload,
    authorNameAdded: authorNameAdded
  };
}

export function getStoryAdminFail() {
  return {
    type: ActionTypes.GET_STORY_ADMIN_FAIL
  };
}

export function setShowReviewModalTrue() {
  return {
    type: ActionTypes.SHOW_REVIEW_MODAL_TRUE
  };
}
export function setShowReviewModalFalse() {
  return {
    type: ActionTypes.SHOW_REVIEW_MODAL_FALSE
  };
}
export function setStoryReview(token, storyId, storyStatus) {
  return {
    type: ActionTypes.SET_STORY_REVIEW,
    authToken: token,
    storyId: storyId,
    storyStatus: storyStatus
  };
}
export function setStoryReviewSuccess() {
  return {
    type: ActionTypes.SET_STORY_REVIEW_SUCCESS
  };
}

export function setStoryReviewFail() {
  return {
    type: ActionTypes.SET_STORY_REVIEW_FAIL
  };
}

export function checkStoryLocked(token, storyId) {
  return {
    type: ActionTypes.CHECK_STORY_LOCKED,
    authToken: token,
    storyId: storyId
  };
}
export function checkStoryLockedSuccess(payload) {
  return {
    type: ActionTypes.CHECK_STORY_LOCKED_SUCCESS,
    payload: payload
  };
}

export function checkStoryLockedFail() {
  return {
    type: ActionTypes.CHECK_STORY_LOCKED_FAIL
  };
}

export function setShowDeleteModalTrue() {
  return {
    type: ActionTypes.SHOW_DELETE_MODAL_TRUE
  };
}
export function setShowDeleteModalFalse() {
  return {
    type: ActionTypes.SHOW_DELETE_MODAL_FALSE
  };
}
export function setStoryDelete(token, storyId) {
  return {
    type: ActionTypes.SET_STORY_DELETE,
    authToken: token,
    storyId: storyId
  };
}
export function setStoryDeleteSuccess() {
  return {
    type: ActionTypes.SET_STORY_DELETE_SUCCESS
  };
}

export function setStoryDeleteFail() {
  return {
    type: ActionTypes.SET_STORY_DELETE_FAIL
  };
}

export function setShowAdminReviewModalTrue() {
  return {
    type: ActionTypes.SHOW_ADMIN_REVIEW_MODAL_TRUE
  };
}

export function setShowAdminReviewModalFalse() {
  return {
    type: ActionTypes.SHOW_ADMIN_REVIEW_MODAL_FALSE
  };
}

export function setStoryAdminReview(token, storyId, status) {
  return {
    type: ActionTypes.SET_STORY_ADMIN_REVIEW,
    authToken: token,
    storyId: storyId,
    status: status
  };
}
export function setStoryAdminReviewSuccess() {
  return {
    type: ActionTypes.SET_STORY_ADMIN_REVIEW_SUCCESS
  };
}

export function setStoryAdminReviewFail() {
  return {
    type: ActionTypes.SET_STORY_ADMIN_REVIEW_FAIL
  };
}

export function setStoryDuplicate(token, storyId) {
  return {
    type: ActionTypes.SET_STORY_DUPLICATE,
    authToken: token,
    storyId: storyId
  };
}
export function setStoryDuplicateSuccess() {
  return {
    type: ActionTypes.SET_STORY_DUPLICATE_SUCCESS
  };
}

export function setStoryDuplicateFail() {
  return {
    type: ActionTypes.SET_STORY_DUPLICATE_FAIL
  };
}

export function resetChapterStoryError() {
  return {
    type: ActionTypes.RESET_CHAPTER_STORY_ERROR
  };
}

///ALL NEW COMMAND PANEL LOGIC HERE

// export function setAutoSaveCommands(
//   payload,
//   authToken,
//   storyId,
//   sceneId,
//   savefromMenu,
//   changeBlockName = null,
//   newBlock = null
// ) {
//   return {
//     type: ActionTypes.SET_AUTOSAVE_COMMANNDS,
//     payload: payload,
//     authToken: authToken,
//     storyId: storyId,
//     sceneId: sceneId,
//     savefromMenu: savefromMenu,
//     changeBlockName: changeBlockName,
//     newBlock: newBlock
//   };
// }

export function setCommands(
  payload,
  authToken,
  storyId,
  sceneId,
  savefromMenu,
  changeBlockName = null,
  newBlock = null
) {
  return {
    type: ActionTypes.SET_COMMANNDS,
    payload: payload,
    authToken: authToken,
    storyId: storyId,
    sceneId: sceneId,
    savefromMenu: savefromMenu,
    changeBlockName: changeBlockName,
    newBlock: newBlock
  };
}

export function setCommandsPanel(
  payload,
  authToken,
  storyId,
  sceneId,
  savefromMenu,
  changeBlockName = null,
  newBlock = null
) {
  return {
    type: ActionTypes.SET_COMMANNDS_PANEL,
    payload: payload,
    authToken: authToken,
    storyId: storyId,
    sceneId: sceneId,
    savefromMenu: savefromMenu,
    changeBlockName: changeBlockName,
    newBlock: newBlock
  };
}

export function setCommandsPanelSuccess(payload) {
  return {
    type: ActionTypes.SET_COMMANNDS_SUCCESS_PANEL,
    payload: payload
  };
}

export function setAutoSaveCommands(
  payload,
  authToken,
  storyId,
  sceneId,
  savefromMenu,
  changeBlockName = null,
  newBlock = null
) {
  return {
    type: ActionTypes.SET_AUTOSAVE_COMMANNDS,
    payload: payload,
    authToken: authToken,
    storyId: storyId,
    sceneId: sceneId,
    savefromMenu: savefromMenu,
    changeBlockName: changeBlockName,
    newBlock: newBlock
  };
}

export function setAutoSaveCommandsSuccess(payload) {
  return {
    type: ActionTypes.SET_AUTOSAVE_COMMANNDS_SUCCESS,
    payload: payload
  };
}

export function setCommandsSuccess(payload) {
  return {
    type: ActionTypes.SET_COMMANNDS_SUCCESS,
    payload: payload
  };
}

export function setCommandsFail(payload) {
  return {
    type: ActionTypes.SET_COMMANNDS_FAIL,
    payload: payload
  };
}

export function getCommands(authToken, storyId, sceneId) {
  return {
    type: ActionTypes.GET_COMMANNDS,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId
  };
}

export function getCommandsSuccess(payload) {
  return {
    type: ActionTypes.GET_COMMANNDS_SUCCESS,
    payload: payload
  };
}

export function getCommandsFail(payload) {
  return {
    type: ActionTypes.GET_COMMANNDS_FAIL,
    payload: payload
  };
}

export function realeseLock(authToken, storyId, sceneId) {
  return {
    type: ActionTypes.SET_RELEASE_LOCK,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId
  };
}

export function realeseLockSuccess(payload) {
  return {
    type: ActionTypes.SET_RELEASE_LOCK_SUCCESS,
    payload: payload
  };
}

export function realeseLockFail(payload) {
  return {
    type: ActionTypes.SET_RELEASE_LOCK_FAIL,
    payload: payload
  };
}

export function getCommandsEdit(authToken, storyId, sceneId, lineNo) {
  return {
    type: ActionTypes.GET_COMMANNDS_EDIT,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId,
    lineNo: lineNo
  };
}

export function getCommandsEditSuccess(commandsLevel2, commandId) {
  return {
    type: ActionTypes.GET_COMMANNDS_EDIT_SUCCESS,
    commandsLevel2: commandsLevel2,
    commandId: commandId
  };
}

export function getCommandsEditFail(payload) {
  return {
    type: ActionTypes.GET_COMMANNDS_EDIT_FAIL,
    payload: payload
  };
}

export function getPreviewAsset(authToken, storyId, sceneId, lineNo) {
  return {
    type: ActionTypes.GET_PREVIEW_ASSET,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId,
    lineNo: lineNo
  };
}

export function getPreviewAssetSuccess(payload) {
  return {
    type: ActionTypes.GET_PREVIEW_ASSET_SUCCESS,
    payload: payload
  };
}

export function getPreviewAssetFail(payload) {
  return {
    type: ActionTypes.GET_PREVIEW_ASSET_FAIL,
    payload: payload
  };
}

export function getAvailableBlocks(authToken, sceneId, storyId) {
  return {
    type: ActionTypes.GET_AVAILABLE_BLOCKS,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId
  };
}

export function getAvailableBlocksSuccess(payload) {
  return {
    type: ActionTypes.GET_AVAILABLE_BLOCKS_SUCCESS,
    payload: payload
  };
}

export function getAvailableBlocksFail(payload) {
  return {
    type: ActionTypes.GET_AVAILABLE_BLOCKS_FAIL,
    payload: payload
  };
}

export function updateCurrentCommandObj(payload) {
  return {
    type: ActionTypes.UPDATE_COMMAND_SCRIPT_EDITOR,
    payload: payload
  };
}

export function getCollaboratorList(authToken, storyId) {
  return {
    type: ActionTypes.GET_COLLABORATOR_LIST,
    authToken: authToken,
    storyId: storyId
  };
}

export function getCollaboratorListSuccess(payload) {
  return {
    type: ActionTypes.GET_COLLABORATOR_LIST_SUCCESS,
    payload: payload
  };
}

export function getCollaboratorListFail(errors) {
  return {
    type: ActionTypes.GET_COLLABORATOR_LIST_FAIL,
    errors: errors
  };
}

export function getViewerList(authToken, storyId) {
  return {
    type: ActionTypes.GET_VIEWER_LIST,
    authToken: authToken,
    storyId: storyId
  };
}

export function getViewerListSuccess(payload) {
  return {
    type: ActionTypes.GET_VIEWER_LIST_SUCCESS,
    payload: payload
  };
}

export function getViewerListFail(errors) {
  return {
    type: ActionTypes.GET_VIEWER_LIST_FAIL,
    errors: errors
  };
}

export function addCollaborator(authToken, storyId, collaboratorName) {
  return {
    type: ActionTypes.ADD_COLLABORATOR_TO_STORY,
    authToken: authToken,
    storyId: storyId,
    name: collaboratorName
  };
}

export function addCollaboratorSuccess(payload) {
  return {
    type: ActionTypes.ADD_COLLABORATOR_TO_STORY_SUCCESS,
    payload: payload
  };
}

export function addCollaboratorFail(errors) {
  return {
    type: ActionTypes.ADD_COLLABORATOR_TO_STORY_FAIL,
    errors: errors
  };
}

export function addViewer(authToken, storyId, collaboratorName) {
  return {
    type: ActionTypes.ADD_VIEWER_TO_STORY,
    authToken: authToken,
    storyId: storyId,
    name: collaboratorName
  };
}

export function addViewerSuccess(payload) {
  return {
    type: ActionTypes.ADD_VIEWER_TO_STORY_SUCCESS,
    payload: payload
  };
}

export function addViewerFail(errors) {
  return {
    type: ActionTypes.ADD_VIEWER_TO_STORY_FAIL,
    errors: errors
  };
}

export function revokeCollaborator(
  authToken,
  storyId,
  collaboratorName,
  isRevoke
) {
  return {
    type: ActionTypes.REVOKE_COLLABORATOR_TO_STORY,
    authToken: authToken,
    storyId: storyId,
    name: collaboratorName,
    isRevoke: isRevoke
  };
}

export function revokeCollaboratorSuccess(payload) {
  return {
    type: ActionTypes.REVOKE_COLLABORATOR_TO_STORY_SUCCESS,
    payload: payload
  };
}

export function revokeCollaboratorFail(errors) {
  return {
    type: ActionTypes.REVOKE_COLLABORATOR_TO_STORY_FAIL,
    errors: errors
  };
}

export function revokeViewer(authToken, storyId, viewerName, isRevoke) {
  return {
    type: ActionTypes.REVOKE_VIEWER_TO_STORY,
    authToken: authToken,
    storyId: storyId,
    name: viewerName,
    isRevoke: isRevoke
  };
}

export function revokeViewerSuccess(payload) {
  return {
    type: ActionTypes.REVOKE_VIEWER_TO_STORY_SUCCESS,
    payload: payload
  };
}

export function revokeViewerFail(errors) {
  return {
    type: ActionTypes.REVOKE_VIEWER_TO_STORY_FAIL,
    errors: errors
  };
}

export function fetchWritersList(authToken) {
  return {
    type: ActionTypes.GET_WRITER_LIST,
    authToken: authToken
  };
}

export function fetchWritersListSuccess(payload) {
  return {
    type: ActionTypes.GET_WRITER_LIST_SUCCESS,
    payload: payload
  };
}

export function fetchWritersListFail(errors) {
  return {
    type: ActionTypes.GET_WRITER_LIST_FAIL,
    errors: errors
  };
}

export function resetAllLocalVariableWindow(authToken, storyId, sceneId) {
  return {
    type: ActionTypes.RESET_ALL_VARIABLE_WINDOW,
    authToken: authToken,
    sceneId: sceneId,
    storyId: storyId
  };
}

export function resetAllLocalVariableWindowSuccess(payload) {
  return {
    type: ActionTypes.RESET_ALL_VARIABLE_WINDOW_SUCCESS,
    payload: payload
  };
}

export function updateLocalVariableWindow(updatedValue, changedVariableList) {
  return {
    type: ActionTypes.UPDATE_VARIABLE_WINDOW,
    updatedValue: updatedValue,
    changedVariableList: changedVariableList
  };
}

export function updateStoryWindowSuccess(payloads) {
  return {
    type: ActionTypes.UPDATE_VARIABLE_WINDOW_SUCCESS,
    payload: payloads.updatedValue,
    changedVariableList: payloads.changedVariableList
  };
}

export function checkStoryValid(token, storyId) {
  return {
    type: ActionTypes.CHECK_STORY_VALID,
    authToken: token,
    storyId: storyId
  };
}
export function checkStoryValidSuccess(payload) {
  return {
    type: ActionTypes.CHECK_STORY_VALID_SUCCESS,
    payload: payload
  };
}

export function checkStoryValidFail() {
  return {
    type: ActionTypes.CHECK_STORY_VALID_FAIL
  };
}

export function resetStoryReviewError() {
  return {
    type: ActionTypes.RESET_STORY_REVIEW_ERROR
  };
}

export function flowchartBlockClick(blockId) {
  return {
    type: ActionTypes.CLICK_FLOWCHART,
    blockId: blockId
  };
}

export function updateRouteInfo(authToken, storyId, routeInfo) {
  return {
    type: ActionTypes.UPDATE_ROUTE_INFO,
    authToken: authToken,
    storyId: storyId,
    routeInfo: routeInfo
  };
}

export function updateRouteInfoSuccess() {
  return {
    type: ActionTypes.UPDATE_ROUTE_INFO_SUCCESS
  };
}

export function updateRouteInfoFail() {
  return {
    type: ActionTypes.UPDATE_ROUTE_INFO_FAIL
  };
}

export function showModal(viewModal) {
  return {
    type: ActionTypes.CHANGE_LOG_MODAL_VIEW,
    viewModal: viewModal
  };
}

export function changeLogScript(authToken, storyId, chapterId) {
  return {
    type: ActionTypes.GET_CHANGE_LOG_SCRIPT,
    authToken: authToken,
    storyId: storyId,
    chapterId: chapterId
  };
}

export function changeLogScriptSuccess(payload) {
  return {
    type: ActionTypes.GET_CHANGE_LOG_SCRIPT_SUCCESS,
    payload: payload
  };
}

export function changeLogScriptFail(payload) {
  return {
    type: ActionTypes.GET_CHANGE_LOG_SCRIPT_FAIL
  };
}
export function saveFlowchartChanges(
  authToken,
  storyId,
  chapterId,
  flowchartJson
) {
  return {
    type: ActionTypes.SAVE_FLOWCHART_CHANGES,
    authToken: authToken,
    storyId: storyId,
    chapterId: chapterId,
    flowchartJson: flowchartJson
  };
}

export function saveFlowchartChangesSuccess(payload) {
  return {
    type: ActionTypes.SUCCESS_SAVE_FLOWCHART_CHANGES,
    payload: payload
  };
}

export function saveFlowchartChangesFail() {
  return {
    type: ActionTypes.FAIL_SAVE_FLOWCHART_CHANGES
  };
}

export function setAuthorName(authorName) {
  return {
    type: ActionTypes.SET_AUTHOR_NAME,
    authorName: authorName
  };
}

export function setSearchBy(searchBy) {
  return {
    type: ActionTypes.SET_SEARCH_BY,
    searchBy: searchBy
  };
}

export function updateStoryId(unityStoryId, storyId, authToken) {
  return {
    type: ActionTypes.UPDATE_STORY_ID,
    unityStoryId: unityStoryId,
    storyId: storyId,
    authToken: authToken
  };
}

export function updateStoryIdSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_STORY_ID_SUCCESS,
    payload: payload
  };
}

export function updateSuccessHide() {
  return {
    type: ActionTypes.UPDATE_SUCCESS_HIDE
  };
}

export function addStoryCredit(authToken, storyId, storyCredits) {
  return {
    type: ActionTypes.ADD_CREDITS,
    storyCredits: storyCredits,
    storyId: storyId,
    authToken: authToken
  };
}

export function fontChange(authToken, fontSize, font) {
  return {
    type: ActionTypes.FONT_CHANGE,
    fontSize: `${fontSize}px`,
    font: font,
    authToken,
    authToken
  };
}

export function spellCheckerCall(authToken, storyId, chapterId, commandObj) {
  return {
    type: ActionTypes.SET_GRAMMAR_SPELL,
    storyId: storyId,
    chapterId: chapterId,
    authToken: authToken,
    commandObj: commandObj
  };
}

export function addToIgnoreCallPost(authToken, storyId, chapterId, commandObj, word) {
  return {
    type: ActionTypes.SET_GRAMMAR_IGNORE,
    storyId: storyId,
    chapterId: chapterId,
    authToken: authToken,
    commandObj: commandObj,
    word: word
  };
}

export function spellCheckSuccess(payload) {
  return {
    type: ActionTypes.SET_GRAMMAR_SPELL_SUCCESS,
    payload: payload
  };
}

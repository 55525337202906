import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormGroup } from 'react-bootstrap';
import * as translations from './translations';
import { ChapterStyle } from './style';
import { RouteServices } from './service';
// import * as commandPanelStyle from '../../../pages/script-writer/style';

function CreateChapter(props) {
  const [data, setData] = useState({
    name: '',
    authorNotes: '',
    characterRouteId: '',
    description: '',
    startEnding: false,
    caption: '',
    endingDesc: '',
    cost: 50
  });
  const [validated, setValidated] = useState(false);
  const [disabledState, setDisabledState] = useState(
    props.chapterData !== null ? false : 'disabled'
  );
  const [route, setRoute] = useState(null);
  const [showChapterError, setShowChapterError] = useState(false);

  useEffect(() => {
    if (props.chapterData !== null) {
      setData({
        name: props.chapterData.name,
        authorNotes: props.chapterData.authorNotes,
        characterRouteId: props.chapterData.characterRouteId,
        description: props.chapterData.description,
        startEnding: props.chapterData.startEnding,
        caption: props.chapterData.caption,
        endingDesc: props.chapterData.endingDesc,
        cost: props.chapterData.cost
      });

      let route = RouteServices.getRoute(
        props.storyRoutes,
        props.chapterData.characterRouteId
      );
      setRoute(route);
    }
  }, [props.chapterData]);

  const handleFormChange = event => {
    let eventValue = event.target.value;
    if (event.target.name === 'startEnding') {
      eventValue = !data.startEnding;
    } else if (event.target.name === 'cost') {
      eventValue = parseInt(eventValue);
    }
    let newData = {
      ...data,
      [event.target.name]: eventValue
    };

    if (event.target.name === 'characterRouteId') {
      const routeCheck = RouteServices.getRoute(
        props.storyRoutes,
        parseInt(event.target.value)
      );
      if (routeCheck && routeCheck.name === 'Prologue') {
        newData = {
          ...data,
          [event.target.name]: eventValue,
          startEnding: false,
          caption: '',
          endingDesc: '',
          cost: 50
        };
      }
      setRoute(routeCheck);
    }

    setData(newData);

    if (newData.name.trim() !== '' && newData.description !== '') {
      setDisabledState('');
    } else {
      setDisabledState('disabled');
    }

    if (props.storyRoutes.length > 1 && newData.characterRouteId == '') {
      setDisabledState('disabled');
    }
    if (route && route.name !== 'Prologue' && newData.startEnding == true) {
      if (
        newData.caption.trim() !== '' &&
        newData.endingDesc.trim() !== '' &&
        newData.name.trim() !== '' &&
        newData.description.trim() !== ''
      ) {
        setDisabledState('');
      } else {
        setDisabledState('disabled');
      }
    } else if (
      newData.startEnding == true &&
      props.storyDetails.storyType == 'ShortStory'
    ) {
      if (
        newData.caption.trim() !== '' &&
        newData.endingDesc.trim() !== '' &&
        newData.name.trim() !== '' &&
        newData.description.trim() !== ''
      ) {
        setDisabledState('');
      } else {
        setDisabledState('disabled');
      }
    }
    setValidated(true);
  };

  const createStoryRoutesOptions = () => {
    let options = props.storyRoutes.map(route => {
      return (
        <option value={route.id} key={`route-${route.id}`}>
          {route.name}
        </option>
      );
    });

    return options;
  };

  const handleCreateChapter = event => {
    const form = event.currentTarget;
    let editChapterName =
      props.chapterData !== null ? props.chapterData.name : '';
    let isChapterValid = false;
    if (data.name != editChapterName) {
      isChapterValid = RouteServices.isChapterNameExists(
        props.storyRoutes,
        data.name
      );
    }
    event.preventDefault();
    if (form.checkValidity() === false || isChapterValid) {
      if (isChapterValid) {
        setShowChapterError(true);
      }
      event.stopPropagation();
      event.preventDefault();
    } else {
      // Edit chapter
      if (props.chapterData !== null) {
        let editedChapters = RouteServices.editChapterWithParticularId(
          props.storyRoutes,
          props.chapterData.id,
          data
        );
        props.handleSaveList(editedChapters);
      } else {
        props.handleCreateScene(data);
      }
      event.stopPropagation();
      event.preventDefault();
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleHideChapterModal}
      backdrop="static"
    >
      <ChapterStyle.addInfoContainer>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
            {props.chapterData !== null
              ? translations.EDIT_CHAPTER
              : translations.CREATE_NEW_CHAPTER}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="add-chapter-form"
            noValidate
            validated={validated}
            onSubmit={e => handleCreateChapter(e)}
          >
            <Form.Group>
              <Form.Label>Chapter name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                onChange={handleFormChange}
                maxLength="35"
                value={data.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a chapter name.
              </Form.Control.Feedback>
              {showChapterError && (
                <Form.Text className="text-danger">
                  Chapter name already exists
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Author's notes (optional)
                <br />
                <Form.Text className="text-muted">
                  This is for internal use. The game player won't read this.
                </Form.Text>
              </Form.Label>

              <Form.Control
                type="text"
                name="authorNotes"
                autoComplete="off"
                onChange={handleFormChange}
                maxLength="50"
                value={data.authorNotes}
              />
              <Form.Control.Feedback type="invalid">
                Invalid author's note
              </Form.Control.Feedback>
            </Form.Group>

            {(props.storyRoutes.length > 1 ||
              props.storyDetails.storyType === 'ShortStory') &&
              props.chapterData === null && (
                <Form.Group>
                  <div className="row justify-content-between">
                    <div className="col col-lg-6 mr-3">
                      <Form.Label>
                        {props.storyDetails.storyType === 'ShortStory'
                          ? `Prologue route`
                          : `Prologue or Character Route`}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="characterRouteId"
                        onChange={handleFormChange}
                        required
                        value={data.characterRouteId}
                      >
                        {props.storyDetails.storyType !== 'ShortStory' && (
                          <option value="">Select route</option>
                        )}
                        {createStoryRoutesOptions()}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {props.storyDetails.storyType === 'ShortStory'
                          ? `Please enter valid prologue route`
                          : `Please enter valid Prologue or Character Route`}
                        {/* Please enter valid Prologue or Character Route */}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              )}

            {props.chapterData !== null && (
              <FormGroup>
                <div className="row justify-content-between">
                  <div className="col col-lg-6 mr-3">
                    <Form.Label>Prologue/Route</Form.Label>
                    <p className="text-muted font-14 mb-0">
                      {data.characterRouteId && route.name}
                    </p>
                  </div>
                </div>
              </FormGroup>
            )}

            <Form.Group>
              <Form.Label>
                Summary
                <Form.Text className="text-muted">
                  Limit: 300 characters
                </Form.Text>
              </Form.Label>
              <Form.Control
                as="textarea"
                type="textarea"
                className="textAreaResize"
                rows="5"
                required
                maxLength="300"
                autoComplete="off"
                name="description"
                value={data.description}
                onChange={handleFormChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a description.
              </Form.Control.Feedback>
            </Form.Group>
            {((route &&
              route.name !== 'Prologue' &&
              props.storyDetails.storyType === 'RouteStory') ||
              props.storyDetails.storyType === 'ShortStory') && (
              <div className="d-flex">
                <label className="switch-flip">
                  <input
                    type="checkbox"
                    name="startEnding"
                    key="selecwisd"
                    className="checkbox-flip"
                    label="Reversed chapter."
                    // defaultValue={data.startEnding}
                    checked={data.startEnding == true ? 'checked' : ''}
                    onChange={handleFormChange}
                    value={data.startEnding}
                  />
                  <span className="slider-flip round"></span>
                </label>
                <span class="label-text font-14">Ending chapter</span>
              </div>
            )}
            {data.startEnding == true &&
              ((route &&
                route.name !== 'Prologue' &&
                props.storyDetails.storyType === 'RouteStory') ||
                props.storyDetails.storyType === 'ShortStory') && (
                <>
                  <Form.Group>
                    <Form.Label>Caption</Form.Label>
                    <Form.Control
                      type="text"
                      name="caption"
                      key="caption"
                      autoComplete="off"
                      onChange={handleFormChange}
                      maxLength="15"
                      value={data.caption}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a caption.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Description
                      <Form.Text className="text-muted">
                        Limit: 150 characters
                      </Form.Text>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      type="textarea"
                      className="textAreaResize"
                      rows="5"
                      required
                      maxLength="150"
                      autoComplete="off"
                      key="endingDesc"
                      name="endingDesc"
                      value={data.endingDesc}
                      onChange={handleFormChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a ending description.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{translations.ENDING_COST}</Form.Label>
                    <Form.Control
                      type="number"
                      name="cost"
                      key="cost"
                      onChange={handleFormChange}
                      defaultValue="0"
                      min="0"
                      max="2147483647"
                      value={data.cost}
                      pattern={'0-9]*'}
                      step={1}
                      required
                      placeholder="Ending cost"
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter valid number between 0 to 2147483647.
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
          </Form>
          <div className="modal-button-wrapper justify-content-between">
            <Button
              onClick={props.handleHideChapterModal}
              className="btn-snacktool btn-snacktool-golden"
            >
              Cancel
            </Button>
            <Button
              form="add-chapter-form"
              type="submit"
              disabled={disabledState}
              className="btn-snacktool btn-snacktool-golden"
            >
              {props.chapterData !== null ? 'Update chapter' : 'Add chapter'}
            </Button>
          </div>
        </Modal.Body>
      </ChapterStyle.addInfoContainer>
    </Modal>
  );
}

export default CreateChapter;

import React  from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalStyled } from './style/modal-style';

const DeleteMyAccount = ({
    handleLogout
}) => {



    return <Modal show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        dialogClassName="delete-account-modal">
        <Modal.Body>
            <ModalStyled.addAssetMessageBox>
                <ModalStyled.successAssetContent>
                    <div className="submit-asset-label"
                        data-testid="success-label">
                        Your Write Fictif account will be deleted after 7 days
                    </div>
                    <div className="text-muted text-note"
                        data-testid="success-note">
                        If you change your mind, please write to <a href="mailto:writefictif@nixhydragames.com">writefictif@nixhydragames.com</a>
                    </div>
                    <div className="mt-5 d-flex align-items-center justify-content-center">

                        <Button className="btn-snacktool btn-snacktool-golden"
                            onClick={() => handleLogout()} >
                            Okay
                            </Button>
                    </div>
                </ModalStyled.successAssetContent>
            </ModalStyled.addAssetMessageBox>
        </Modal.Body>
    </Modal>
}

export default DeleteMyAccount
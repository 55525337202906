import React, { useState } from 'react';
import { LibraryStyle } from './../../style/style';

import * as Translations from './../../translations';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Confirm from './../../../common/Modals/Confirm';

function CreateCharacterHead(props) {
  const [confirmYes, setConfirmYes] = useState(false);
  const handleClearCharacter = () => {
    props.initializeCreateCharacter();
    setConfirmYes(true);
  };

  const confirmProcess = () => {
    confirmAlert({
      title: Translations.WARNING,
      message: Translations.CANCEL_CREATE_CHARACTER_PROCESS_WARNING,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleClearCharacter()
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ],
      customUI: ({ onClose }) => {
        return (
          <Confirm
            firstLineLabel="Leave the process?"
            secondLineLabel="Changes will not be saved"
            cancel="Cancel"
            onClose={onClose}
            handleClearCharacter={() => handleClearCharacter()}
          />
        );
      }
    });
  };

  return (
    <div>
      {confirmYes === true && <Redirect to="/characters" />}
      <LibraryStyle.listHeading>
        <div className="panel-header">
          <div className="back-arrow" onClick={confirmProcess}>
            <i className="fa fa-angle-left" />
          </div>
          <div className="story-heading">
            <div className="sceneName">
              {props.IsCharacterEdit === true
                ? Translations.UPDATE_CHARACTER
                : Translations.CREATE_NEW_CHARACTER}
            </div>
          </div>
        </div>
      </LibraryStyle.listHeading>
    </div>
  );
}

export default CreateCharacterHead;

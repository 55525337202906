import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DeleteStyle } from './style';

function Success(props) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
      className="modalbg"
      size="md"
      show={props.showModal}
      onHide={props.handleHideDeleteModal}
    >
      <Modal.Body>
        <DeleteStyle.addAssetMessageBox>
          <DeleteStyle.successAssetContent>
            <div className="fail-icon">
              <img
                alt=""
                className="mediumIcon"
                src="/images/icons/ic_clear_24px.svg"
              />
            </div>
            <div data-testid="success-label" className="submit-asset-label">
              The character has been deleted
            </div>
            {/* <Row><Col  data-testid="success-note" className="text-muted submit-asset-subtitle ">Character deleted succesfuly.</Col></Row> */}
            <div className="mt-3">
              <Button
                data-testid="success-btn"
                onClick={props.handleHideDeleteModal}
                className="btn-snacktool btn-snacktool-golden"
              >
                Okay
              </Button>
            </div>
          </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}
export default Success;

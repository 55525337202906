import React from 'react';
import { ChapterStyle} from './style';
import { Row,Modal,Button, Col} from 'react-bootstrap';

function  Fail(props) {

  return (

    <Modal 
    aria-labelledby="contained-modal-title-vcenter"
    dialogClassName="confirmModal"
   className="modalbg" 
   size="md" 
   show={props.showModal}
   onHide={props.handleHideRouteResult}
   >
     <Modal.Body  >
       
     
     <ChapterStyle.addAssetMessageBox>
            <ChapterStyle.successAssetContent>
                <Row>
                <Col className="fail-icon">
                <img src="/images/icons/ic_clear_24px.svg" className="mediumIcon"/>

                    </Col>
                </Row>
                <Row>
                <Col className="submit-asset-label">
                     {props.isRouteEdit===true?"Route can't be updated. Something went wrong!":"Route can't be saved. Something went wrong!"}   
                    </Col>
                    </Row>
                 
                <Row>
                    <Col className="submit-asset-cancel-label ">
                    <br/>
                   <br/>
                   <br/>
                    <Button  onClick={props.handleHideRouteResult}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
                    </Col>
                </Row> 
            </ChapterStyle.successAssetContent>
        </ChapterStyle.addAssetMessageBox>
     </Modal.Body>
   </Modal>
 );
  


     
}

export default Fail
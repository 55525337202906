import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const Header = styled.div`
  background: ${colors.color_white};
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid ${colors['command-header']};

  .footer-pannel {
    justify-content: space-between;
  }
  .panel-header {
    display: flex;
    align-items: center;
    height: 60px;
    position: relative;

    .margin-top-minus {
      margin-top: -10px !important;
      position: absolute;
      right: 0;
    }

    .back-arrow {
      border-right: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      width: 76px;
      justify-content: center;
      height: 60px;
      margin-right: 16px;

      cursor:pointer .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
    }
    .lock-block {
      text-align: left;

      border: 1px solid grey;
      border-radius: 16px;
      background-color: #707070;
      color: #ffffffde;
      padding: 7px;
      font-size: 12px;
    }
    .lock-container {
      width: 335px;
    }
    .edit-block {
      padding: 0 10px;
      text-align: right;
      width: 17%;
      .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
      .selected-line-number {
        font-size: 11px;
        white-space: nowrap;
        color: #acacac;
        letter-spacing: 1.1px;
        text-transform: uppercase;
      }
    }

    .footer-left {
      padding: 5px 12px 12px 12px !important;
    }

    .footer-right {
      padding: 5px 12px 12px 12px;
    }
    .story-heading {
      width: 20%;
    }
    .story-heading-long {
      width: 33%;
    }
    .storyName {
      text-align: left;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 1.1px;
      color: ${colors.scorpion_gray};
      text-transform: uppercase;
      opacity: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }

    .sceneName {
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0;
      color: ${colors.color_black};
      opacity: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }
    .add-comment-line {
      position: absolute;
      right: 123px;
      bottom: -15px;
      z-index: 1;
      button {
        border-radius: 20px;
      }
    }
    .add-save-line {
      position: absolute;
      right: 15px;
      bottom: -15px;
      button {
        border-radius: 20px;
      }
    }
    .btn-div {
      right: 190px;
    }
    .btn-validate-inactive {
      right: 200px;
    }
    .btn-indent-div {
      right: 345px;
    }
    .btn-validate {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #45c7ad;
      border-radius: 40px;
      opacity: 1;
      padding: 6px 25px !important;
      // color: #45c7ad !important;
    }
  }

  .panel-header-dark {
    background: #303030 0% 0% no-repeat padding-box !important;

    .storyName {
      text-align: left;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 1.1px;
      color: #bfbfbf !important;
      text-transform: uppercase;
      opacity: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }

    .sceneName {
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0;
      color: #ffffff !important;
      opacity: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }
  }

  .info-icon {
    background: url('/images/icons/info.svg') no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  .info-icon:hover {
    background: url('/images/icons/info-active.svg') no-repeat;
  }

  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 45px;
    margin-bottom: 0;
  }

  .switch-flip input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 40px;
  }

  .slider-flip:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider-flip {
    background-color: #2196f3;
  }

  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }

  .slider-flip.round:before {
    border-radius: 50%;
  }

  .bold-text {
    font-size: 14px;
    font-weight: bold;
  }

  .selected {
    background: #f6e0f0;
    select,
    input {
      background: #f6e0f0;
    }
  }
`;

export const VariableWindow = styled.div`
  //background: #f0f0f0;
  background: #3a2c61;
  .save-btn {
    background: #bc45ad;
    font-family: Roboto !important;
    font-weight: bold !important;
    font-size: 14px !important;
    min-width: 90px !important;
    // color: #fff !important;
    border: none;
    position: absolute;
    right: 85px;
    top: -22px;
    z-index: 1;
  }

  .variables-btn-wrapper {
    // background: #fff;
    // border-left: 60px solid #f0f0f0;
    // background: #6428e1;
    border-left: 60px solid #3a2c61;
    position: relative;
    height: 30px;
    .variables-btn {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0;
      color: #530acd;
      box-shadow: 0px 0px 4px #0000001a;
      margin: 5px 0 !important;
      border: none;
      position: absolute;
      left: -45px;
      top: -23px;

      .variable-icon-open,
      .variable-icon-hide {
        background: url(/images/icons/call_to_action.svg) no-repeat !important;
        width: 18px;
        height: 22px;
        margin-right: 8px;
        transform: rotate(90deg);
        display: inline-block;
      }
      .variable-icon-hide {
        background: url(/images/icons/call-to-action-hide.svg) no-repeat !important;
      }
    }
    .error-btn {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0;
      color: #ff0000;
      box-shadow: 0px 0px 4px #0000001a;
      margin: 5px 0 !important;
      border: none;
      position: absolute;
      left: 95px;
      top: -23px;
      
    }

    .expand-btn {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0;
      color: #ff0000;
      box-shadow: 0px 0px 4px #0000001a;
      margin: 5px 0 !important;
      border: none;
      position: absolute;
      left: 250px;
      top: -23px;
      height:37px !important;
      min-width: 40px !important;
    }

    .btn-indent {
      min-width: 24px;
      min-height: 24px;
      position: absolute;
      left: 100px;
      top: -18px;
      color: #9a9a9a;
    }
  }
  .backgroundTransparent {
    background: transparent !important;
  }

  .font-family {
    margin-top: -170px;
    padding-bottom: 15px;
    position: relative;
    float: right;
    margin-right: 118px;
    background: #fff;
    padding: 20px 10px 0px 10px;
    border-radius: 10px;
    border: 1px solid #000;
    width: 500px;
    .form-label {
      font-weight: bold;
    }
    .space-around {
      justify-content: space-around;
    }
  }

  .find-replace {
    margin-top: -170px;
    padding-bottom: 15px;
    position: relative;
    float: right;
    margin-right: 118px;

    .backgroundFindReplace {
      width: 480px !important;
      box-shadow: 0px 4px 10px #0000004d;
      border-radius: 4px;
      opacity: 1;
      margin-left: 20%;
      background-color: #fff;
      margin: 10px 10px 0px 20%;
      padding: 10px;
    }
    .space-between {
      justify-content: flex-start;
    }
    .closeButton {
      color: #acacac;
      font-size: 24px;
    }
    .checkboxLabel {
      font: normal bold 14px/19px PT Sans;
    }
    .find-button {
      min-width: 86px !important;
      max-width: 88px !important;
      height: 32px !important;
      border-radius: 40px !important;
      opacity: 1 !important;
      font: normal normal bold 14px/16px Roboto !important;
      padding: 0px !important;
    }
    .input-width {
      width: 200px !important;
      height: 32px !important;
      border: 2px solid #d6d6d6;
      border-radius: 4px;
      opacity: 1;
      padding-left: 16px !important;
    }
    .padding-top {
      padding-top: 5px !important;
    }
    .replace-button {
      min-width: 90px !important;
      max-width: 92px !important;
      height: 32px !important;
      border-radius: 40px !important;
      opacity: 1 !important;
      font: normal normal bold 14px/16px Roboto !important;
      padding: 0px !important;
    }
    .replace-all {
      min-width: 108px;
      max-width: 110px !important;
      height: 32px;
      border-radius: 40px !important;
      opacity: 1 !important;
      font: normal normal bold 14px/16px Roboto !important;
      padding: 0px !important;
    }
    .search-count {
      text-align: left !important;
      font: normal normal normal 14px/19px PT Sans !important;
      letter-spacing: 0px !important;
      color: #707070 !important;
      opacity: 1 !important;
      width: 170px !important;
    }
  }
`;

export const FooterArea = styled.div`
height:50px;
// background:#F0F0F0 0% 0% no-repeat padding-box;

background:#3A2C61 0% 0% no-repeat padding-box;
.panel-header{
  display: flex;
    align-items: center;   
    position:relative;
    
  .edit-block{
    padding: 0 10px;
    text-align:right;
    width: 17%;
    .fa-angle-left{
      font-size:30px;
      color:#D6D6D6;
    }
  }

  .selected-line-number {
      font-size: 11px;
      white-space: nowrap;
color: #FFFFFF;
// color: #606060;
      letter-spacing: 1.1px;
      text-transform: uppercase;
    }

    .script-error {
      font-size: 11px;
      white-space: nowrap;
      color: #FF6565
      letter-spacing: 1.1px;
      text-transform: uppercase;
    }

  .footer-left {
    padding: 5px 12px 12px 30px !important;
  }
  .footer-right {
    padding: 5px 12px 12px 12px;
  }
  .footer-left-text{

    width:225px !important;
  }
  .add-comment-line{
    position: absolute;
    right: 123px;
    bottom: -15px;
    z-index: 1;
    button{
      border-radius:20px;
    }
  }
  .add-save-line{
    position: absolute;
    right: 85px;
    bottom: -15px;
    button{
      border-radius:20px;
    }
  }
  .btn-div{
    right:190px;
  }
  .btn-validate-inactive{
    right:200px
  }
  .btn-indent-div{
    right: 345px;
  }
  .btn-validate{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #45C7AD;
    border-radius: 40px;
    opacity: 1;
    padding: 6px 25px !important;
    color: #45C7AD !important;
  }
`;

export const Body = styled.div`
  padding: 0 !important;
  background: #fff;
  opacity: 1;
  display: inline-block;
  height: calc(100vh - 150px);
  width: 100%;
  overflow: auto;
  position:relative;
.checkRoundBlue{
  background-color: #cbe6ef !important;
}
  .scene-name{
    cursor: pointer;
    font-weight: bold;
    }
    .scene-name:hover{
      text-decoration: underline;
    }
    .bgTr{
      background-color: #EBEBEB  !important;
    }
    .width-20{
      width:20% !important;
    }
  
  .emptyInput{
    background-color:yellow;
  }
  
}
.scenes-list-wrapper{
  margin-bottom:30px;

  .right-col{
    font-weight: bold;
    margin-top: 1.7% !important;
  }
  .col-8{
    box-shadow:none;
  }
  
  h3{
    font-size: 24px;
    letter-spacing: 0px;
    color: #303030;
  }
  .btn{
    padding:0 10px;
  }
  .bold-text{
    font-size:14px;
    font-weight:bold;
  }
  .info-icon {
    background:url('/images/icons/info.svg') no-repeat;
    width:14px;
    height : 14px;
    display:inline-block;
    
  }
  .info-icon:hover{
    background:url('/images/icons/info-active.svg') no-repeat;
  }
  .dropdown-menu {
    padding: 5px 0;
  }
  .dropDownItemCss {
      font-size: 14px;
  }
  .dropdown-item:hover{
    background:#d6d6d6;
  }
}

.switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 40px;
    margin-bottom: 0;
  }
  
  .switch-flip input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 40px;
  }
  
  .slider-flip:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider-flip {
    background-color: #2196F3;
  }
  
  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }
  
  .slider-flip.round:before {
    border-radius: 50%;
  }
 


  .selected{
    background:#F6E0F0;
    select,input{
        background:#F6E0F0;;
    }
  }

  select{
    border: 0;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
  }

  input{
    border:0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
    &.backgroundBorder{
      width:30px;
    }
  }

  .characterBorder,.mediaBorder,.backgroundBorder{
    margin: 0 4px;
  }

.square {
  min-width: 20px;
  text-align: right;
  display: inline-block;
}

.decisionText{
  text-align:left;
}
.bodyAddText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#F6E0F0;
  font-weight:bold;
  display:flex;
  align-items:center;
  select,input{
        background:#F6E0F0;;
    }
   
}
.blockAddSquare{
  margin: 0px 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: #F6E0F0;
  
}

.bodyText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  align-items:center;
  // &:hover{
  //   .characterSquare{
  //     color:${colors.color_white};
  //   }
  //   background:${colors.very_soft_pink};
  //   color:${colors.color_white};
  //     select,input{
  //       background:${colors.very_soft_pink};
  //       color:${colors.color_white};
  //   }
  // }
}
.bodyTextBlock{
  margin-top:30px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  // &:hover{
  //   .blockSquare{
  //     color:${colors.color_white};
  //   }
  //   background:${colors.primary_purple};
  //   color:${colors.color_white};
  //   .blockBorder{
  //       background:${colors.primary_purple};
  //       color:${colors.color_white};
  //   }
  // }

}
.bodyTextBlock,.bodyText{
  ${'' /* &:focus{
    outline:0;
  } */}
  &:hover{
    background:#F6E0F0;;
    select,input{
        background:#F6E0F0;;
    }
  }
}
.enterScene{
  input{
    width: 4%;
  }
  select{
    width: 8%;
  }
}

.sceneError {
  margin-bottom:30px;
  color : red;
}
.folder-shared-icon, .route-info-icon{
  background:url(/images/icons/folder-shared.svg) no-repeat !important;
  width:17px;
  height:14px;
  margin-right:8px;
  display: block;
  float: left;
  margin-top: 3px;
}
.route-info-icon{
  background:url(/images/icons/ic-chat.svg) no-repeat !important;
  width:14px;
  height:14px;
}
.library-changes-icon {
  background:url(/images/icons/ic_history_24px.png) no-repeat !important;
  width: 18px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}
button:disabled{
  .route-info-icon{
    background:url(/images/icons/ic-chat-disabled.svg) no-repeat !important;
    width:14px;
    height:14px;
  }
}

//create-scene styling
.create-scene-container{
  margin: 0 25px;

    form{
      width:75%;
      display:flex;
      justify-content:flex-start;
      align-items:flex-end;
      margin-bottom:10px;
      width:75%;
      .form-group{
        margin-right:16px;
        margin-bottom:0;
        .form-label{
          color: #303030;
          font-family:PT Sans !important;
          font-size:14px;
          font-family:bold;
          margin:0;
        }
        .form-control{
          background: #FFFFFF;
          border: 2px solid #D6D6D6;
          border-radius: 4px;
          height:40px;
          margin:0;
          width:340px;
        }
      }
    }
    .empty-row{
      height:120px;
      width:100%;
    background:#F5F5F5;
    }
    .empty-icon{
      padding-top:2%;
    }
    .empty-text{
      
      color: #303030;
          font-family:PT Sans !important;
          font-size:18px;
          font-family:bold;
    }
  .drag-drop-table{
    width:100%;
    background:#F5F5F5;
    tr{

      th{
        color: #606060;
        font-family:PT Sans;
        font-size:14px;
        font-weight: normal;
        padding:16px 0 8px 0;

      
      }
      td{
        color: #303030;
        font-family:PT Sans;
        font-size:14px;
        padding:8px 0;

        .fa-bars{
          color: #ACACAC;
          font-size: 12px;
          cursor:pointer;
        }
        img{
          cursor:pointer;
        }
        .checkIcon{
          cursor: auto;
          heighT:24px;
        }
      }
    }
  }
}

`;

export const Body1 = styled.div`
  padding: 0 !important;
  background: #fff;
  opacity: 1;
  /* display: inline-block; */
  // height: ${props =>
    props.conditionalHeight === true || props.conditionalFindReplace
      ? props.conditionalFindReplace === true
        ? 'calc(100vh - 445px)'
        : 'calc(100vh - 532px)'
      : 'calc(100vh - 252px)'};
  height: ${props =>
    props.conditionalHeight === true
      ? 'calc(100vh - 595px)'
      : 'calc(100vh - 267px)'};

  width: 100%;
  overflow: scroll;
  position:relative;
 
  .scene-namec{
      cursor: pointer;
      font-weight: bold;
  }
  
  .scene-name:hover{
    text-decoration: underline;
  }
  
  .bgTr{
    background-color: #F5F5FA !important;
  }
  
  .empty-gutter{
    background:#f0f0f0;
    width:60px;
    height:25px;
  }

  // .ace_content{
  //   background-color:rgb(132, 82, 232);
  //   color: #FFFFFF;
  // }
  
  // .ace_gutter{
  //       z-index: 0 !important;
  //       background-color:#6428E1;
  //       color: #FFFFFF;
  // }

  .ace_gutter-layer, .ace_gutter{
    width:60px !important;
  }
  .ace_gutter-cell.ace_error{
    background:url(/images/icons/error_icon.svg) no-repeat !important;
    left:10px;
  }
  .ace_gutter-cell.ace_warning{
    background:url(/images/icons/warning_icon.svg) no-repeat !important;
    left:10px;
  }
  .ace_scroller{
    left:59.8027px!important;
  }
  // .ace_layer{
  //   word-break:null
  // }
  
  .ace_editor{
    width:100% !important;
    height: inherit !important;
  }

  .ace_gutter-cell.ace_info{
    background:url(/images/icons/preview.svg) no-repeat !important;
    background-size: 30% !important;
  }

  .emptyInput{
    background-color:yellow;
  }

  .myMarker {
    position: absolute;
    border-bottom: 1px solid red;
    z-index: 20
  }

  .spellMarker{
    border-bottom: 3px solid #EC5757;
    z-index: 20;
    position: absolute;
    background-color:#FDEEEF
  }

  .grammarMarker{
    border-bottom: 3px solid #8F7FFF;
    z-index: 20;
    position: absolute;
    background-color:#F3F2FF;
  }
  

  .ace_block {
    color: ${colors.primary_purple}
  }
}
  .selected{
    background:#F6E0F0;
    select,input{
        background:#F6E0F0;;
    }
  }

  select{
    border: 0;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
  }

  input{
    border:0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
    &.backgroundBorder{
      width:30px;
    }
  }


  .characterBorder,.mediaBorder,.backgroundBorder{
    margin: 0 4px;
  }

.square {
  min-width: 20px;
  text-align: right;
  display: inline-block;
}

.decisionText{
  text-align:left;
}
.bodyAddText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#F6E0F0;
  font-weight:bold;
  display:flex;
  align-items:center;
  select,input{
        background:#F6E0F0;;
    }
   
}
.blockAddSquare{
  margin: 0px 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: #F6E0F0;
  
}

.ace_blockSquare{
    color: ${colors.primary_purple}
}

.ace_spellMarker{
  border-bottom: 1px solid red;
  background-color:#FDEEEF;
}

.ace_commentSquare{
  color: ${colors.medium_light_gray}
  // color: #FFFFFF;
}

.ace_effectSquare{
  color: ${colors.light_blue_effect}
  // color: #FFFFFF;

}


.ace_marker-layer .ace_active-line {
  background-color: #FFD489;
  animation: 1s ease 0s 1 normal none running fadeIn;
  color: #FFFFFF !important;
}

// .ace_gutter-active-line{
//   background-color: #8452E8;
// }

// .ace-editor .ace_content {
//   background-color: #8452E8 !important;
// }
 
.dialogueBorder{
  position: absolute;
    border-bottom: 1px solid #F9A602;
    color: yellow;
    z-index: 20
}

.commandTextBorder{
  position: absolute;
  border-bottom: 1px solid  ${colors.shades_shade300};
  z-index: 20
}


.commandSquare{
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
    color: ${colors.dark_yellow};
}

.decisionSquare {
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: ${colors.primary_purple};
  
}

.backgroundSquare {
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: ${colors.very_dark_cyan};
}

.ace_viewSquare{
  color: #157ffb;
}

.ace_tooltip{
  background: #43425D;
  box-shadow: 0px 0px 1px #000029 !important;
  opacity: 1;
  color:#fff;
  font-size:12px;
  padding:10px;
  border-radius:5px;
  font-family:Roboto !important;
  letter-spacing: 0.4px;
  border:none;
}

// .ace_tooltip:before{
//   content:'';
//   display:block;
//   width:0;
//   height:0;
//   position:absolute;
//   border-top: 6px solid transparent;
//   border-bottom: 6px solid transparent;
//   border-right:6px solid #43425D;
//   left:-6px;
//   bottom:7px;
// }

.ace_dialogSqaure{
  color:#157ffb;
  }

.ace_mediaSquare {
  color:${colors.dark_lime_green};
}

.ace_characterSquare {
  color: ${colors.very_dark_pink};
}

.bodyText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  align-items:center;
  // &:hover{
  //   .characterSquare{
  //     color:${colors.color_white};
  //   }
  //   background:${colors.very_soft_pink};
  //   color:${colors.color_white};
  //     select,input{
  //       background:${colors.very_soft_pink};
  //       color:${colors.color_white};
  //   }
  // }
}
.bodyTextBlock{
  margin-top:30px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  // &:hover{
  //   .blockSquare{
  //     color:${colors.color_white};
  //   }
  //   background:${colors.primary_purple};
  //   color:${colors.color_white};
  //   .blockBorder{
  //       background:${colors.primary_purple};
  //       color:${colors.color_white};
  //   }
  // }

}
.bodyTextBlock,.bodyText{
  ${'' /* &:focus{
    outline:0;
  } */}
  &:hover{
    background:#F6E0F0;;
    select,input{
        background:#F6E0F0;;
    }
  }
}
.bodyBlockContent{

}
.blockBorder{
  border-bottom: 2px solid ${colors.primary_purple};
  border-top:none;
  border-left:none;
  border-right:none;
  text-align:center;
  min-width: 20px;
  max-width: 100px;
}
.commandBg{
  // background-color:${colors.primary_yellow}
}
.commandBorder{
  border-bottom: 2px solid ${colors.primary_yellow};
  border-top:none;
  border-left:none;
  border-right:none;
}
.decisionBorder {
  border-bottom: 2px solid ${colors.primary_purple};
}
.backgroundBorder {
  border-bottom: 2px solid ${colors.very_soft_cyan};
  text-align:center;
}
.mediaBorder {
  border-bottom: 2px solid ${colors.moderate_lime_green};
  
}
.characterBorder {
  border-bottom: 2px solid ${colors.very_soft_pink};
  text-align: center;
  
    
}

.enterScene{
  input{
    width: 4%;
  }
  select{
    width: 8%;
  }
}

.sceneError {
  margin-bottom:30px;
  color : red;
}

//create-scene styling
.create-scene-container{
  margin: 0 25px;

    form{
      width:75%;
      display:flex;
      justify-content:flex-start;
      align-items:flex-end;
      margin-bottom:10px;
      width:75%;
      .form-group{
        margin-right:16px;
        margin-bottom:0;
        .form-label{
          color: #303030;
          font-family:PT Sans !important;
          font-size:14px;
          font-family:bold;
          margin:0;
        }
        .form-control{
          background: #FFFFFF;
          border: 2px solid #D6D6D6;
          border-radius: 4px;
          height:40px;
          margin:0;
          width:340px;
        }
      }
    }
  .drag-drop-table{
    width:100%;
    background:#F5F5F5;
    tr{

      th{
        color: #606060;
        font-family:PT Sans;
        font-size:11px;
        font-weight: normal;
        padding:16px 0 8px 0;

      
      }
      td{
        color: #303030;
        font-family:PT Sans;
        font-size:14px;
        padding:8px 0;

        .fa-bars{
          color: #ACACAC;
          font-size: 12px;
          cursor:pointer;
        }
        img{
          cursor:pointer;
        }
      }
    }
  }
}



  
`;

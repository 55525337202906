import styled from 'styled-components'
import * as Config from './../../config/app.config';

const editIcon = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + ('active-edit.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 20px;
`;

const uploadIcon = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + ( 'activate-upload.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 20px;
`;

const settingsIconDisabled = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + ( 'ic_settings_24px-disabled_purple.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 20px;
`;

const settingsIconActive = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + ( 'ic_settings_24px.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px !important;
    width: 24px !important;
`;

const defaultIcon = styled.img.attrs({
  src: props => Config.IMAGE_ICON_PATH + ( 'ic_star.svg')
})`
  font-size: 20px !important;
  padding-right: 0px;
  height: 24px;
  width: 24px;
`;

const deleteIcon = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + ('active-deactivate.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 20px;
    cursor:pointer;
`;

// const sharedIcon = styled.img.attrs({
//     src: props => Config.IMAGE_ICON_PATH + ( 'active-deactivate.svg')
// })`
//     font-size: 20px !important;
//     padding-right: 0px;
//     height: 26px;
//     width: 20px;
// `;

const previewIcon = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + (props.isOpen ? 'up-caret.svg' : 'caret.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 20px;
`;

const restoreIcon = styled.img.attrs({
    src: props => Config.IMAGE_ICON_PATH + (props.isShared ? '' : 'revert.svg')
})`
    font-size: 20px !important;
    padding-right: 0px;
    height: 24px;
    // width: 24px;
    visibility: ${props => props.showIcon ? '' : 'hidden'};
`;

export const styledIcons = {
    editIcon,
    uploadIcon,
    deleteIcon,
    defaultIcon,
    previewIcon,
    restoreIcon,
    settingsIconActive,
    settingsIconDisabled
}
import styled from "styled-components";
 

export const ListRow = styled.div`
   
   
.switch-flip {
  position: relative;
  display: inline-block;
  height: 20px;
  margin-right: 25px;
}

.switch-flip input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-flip {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 40px;
}

.slider-flip:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-flip {
  background-color: #2196F3;
}



input:focus + .slider-flip {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-flip:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider-flip.round {
  border-radius: 34px;
}

.slider-flip.round:before {
  border-radius: 50%;
}

 .bgDisable{
  background-color: #B0E2FF !important;
  cursor: unset;
 }
 .bgUncheckDisable{
  background-color: grey !important;
  cursor: unset;
 }

 .bgCHar{
      background:#E6E6E6 !important;
      cursor: auto;
    }
  

`;



export const ListStyle = {
  ListRow,
   
}
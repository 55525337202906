import React, { useEffect } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  STORY_PENDING_APPROVAL,
  SNACK_USER_EDITOR,
  SNACK_USER_ADMIN
} from './../../../common/constants';
import { Row, Col } from 'react-bootstrap';

const TextEditorFooter = props => {
  let isLocked = true;
  let saveLocked = true;
  if (props.commandList && props.commandList.lockAcquired === true) {
    if (props.commandList.lockAcquiredBy === props.tokenDataUser.userId) {
      isLocked = true;
      saveLocked = true;
    } else {
      isLocked = false;
      saveLocked = false;
    }
  }

  if (props.commandList && props.commandList.lockAcquired === false) {
    // if (props.commandList.lockAcquiredBy == props.tokenDataUser.userId) {
    //   isLocked = false;
    // }
    // else {
    isLocked = false;
    saveLocked = true;

    // }
  }

  useEffect(
    () => {
      const backdropElement = document.getElementById('story-heding');
      if (backdropElement)
        backdropElement.addEventListener('click', hideCommentPoup);
    },
    props.editIconState,
    []
  );

  const hideCommentPoup = () => {
    props.changeCommentPoup();
  };

  return (
    <div className="panel-header footer-pannel">
      <Row className="footer-left mt-2">
        <Col>
          <div className="selected-line-number mb-1">
            {props.highlightHint.command ? (
              <span>
                <b>Command:</b>{' '}
                <i style={{ textTransform: 'capitalize' }}>
                  {props.highlightHint.command}{' '}
                </i>
              </span>
            ) : (
              ''
            )}

            {props.highlightHint.parameter ? (
              <span>
                | <b> Parameter:</b>{' '}
                <i style={{ textTransform: 'capitalize' }}>
                  {props.highlightHint.parameter}
                </i>
                <OverlayTrigger
                  key={`opt-text`}
                  placement="top"
                  overlay={
                    <Tooltip id={`opt-text`}>
                      The parameters enclosed within Square brackets [] are Optional. If no parameter is added, the default value will be used.
                    </Tooltip>
                  }
                >
                  <span className="info-icon ml-2"></span>
                </OverlayTrigger>
              </span>
            ) : (
              ''
            )}
          </div>
          <div className="script-error">
            {props.flowchartHasErrors === true &&
              props.textEditorValidate === true &&
              props.value !== null &&
              props.value.trim() !== '' && (
                <span style={{ color: '#FF6565' }}>Script Has Errors</span>
              )}
            {props.flowchartHasErrors === false &&
              props.textEditorValidate === true &&
              props.value !== null &&
              props.value.trim() !== '' && (
                <span style={{ color: '#45C745' }}>
                  Script correctly validated
                </span>
              )}
          </div>
        </Col>
        <div className="mt-1 ml-2 ">
          <div className="footer-left-text">
            <span className="selected-line-number mb-1">
              ACTIONS ON LINE{' '}
              {props.cursorPostion ? parseInt(props.cursorPostion) + 1 : 1}
            </span>
            {props.previewIconState &&
              props.scriptValue !== '' &&
              props.externalPlayPauseHandler === false ? (
              <img
                onClick={() => props.onPreviewClick()}
                src="/images/icons/preview.svg"
                alt=""
                className="ml-4 cursor-pointer checkIcon"
              />
            ) : (
              ''
            )}{' '}
            {!props.isViewStory &&
              props.editIconState &&
              saveLocked &&
              props.scriptValue !== '' && (
                <img
                  disabled={props.isViewStory}
                  onClick={() =>
                    props.isViewStory === true ? null : props.onEditClick()
                  }
                  className="cursor-pointer checkIcon"
                  src="/images/icons/edit.svg"
                  alt=""
                />
              )}
          </div>
        </div>
      </Row>

    </div>
  );
};

export default TextEditorFooter;


export const INTRO_OPTIONS = {skipLabel:"x",
prevLabel:"Previous",
nextLabel:"Next",
exitOnEsc:true,
exitOnOverlayClick:true,
showStepNumbers:false,
hidePrev:true,
hideNext:true,
doneLabel:'Finish' };

export const stepConstant = [
  {
    element: '.steps-library',
    intro: "<h3>LIBRARY SECTION</h3> <p>Here is where you can upload the files to create the characters and backgrounds that fill your story.</p>",
    position: 'top',
    tooltipClass: 'writetool_library_1'
    
  },
  {
    element: '.step-character',
    intro: '<h3>CREATE CHARACTERS</h3><p>Characters are anyone or anything that has a line in the script. Start here to use them in your story.</p>',
    position: 'right',
    tooltipClass: 'writetool_library_2'
  },
  {
    element: '.step-views',
    intro: '<h3>CREATE VIEWS</h3><p>Views are the backgrounds and illustrations that fill the screen. Here you can create and edit them to use in your story.</p>',
    position: 'right',
    tooltipClass: 'writetool_library_3'
  },
  {
    element: '.step-assets',
    intro: '<h3>UPLOAD BACKGROUND, OBJECT AND SOUND FILES</h3><p>Upload files to your story’s library. All collaborators on the story will be able to access these background, object, and sound files.</p>',
    position: 'right',
    tooltipClass: 'writetool_library_4'
  },
  {
    element: '.step-cover',
    intro: '<h3>COVER IMAGE</h3><p>The album cover appears in the main list of stories. The series background and story title appear once the player has opened the story page and is viewing the contents. Each item has a specific pixel size in order to work within the game.</p>',
    position: 'right',
    tooltipClass: 'writetool_library_4'
  },
  {
    element: '.step-interface',
    intro: '<h3>CUSTOMIZE THE GAME UI</h3><p>You can upload your own images for buttons, name plates, and other interface elements.</p>',
    position: 'right',
    tooltipClass: 'writetool_library_5'
  }
 
];

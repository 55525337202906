import {endpoint} from "../../config/app.config";


export const checkCharacterNameValidity = (payload) => {
let charId=payload.IsCharacterEdit===true?'&character_id='+payload.editCharaterId:'';
 
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/exists?character_name=${payload.characterName}${charId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}

export const saveAsset = (payload) => {
 
   if(payload.IsCharacterEdit===true){
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/${payload.charId}`, {
      method: 'PUT',
      body: payload.character,
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
         
      }
  })
  .then(res => res.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  })

   }
   else{
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters`, {
        method: 'POST',
        body: payload.character,
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
           
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    })
  }
}

export const checkVariationExists = (payload) => {
    let queryString = `character_name=${payload.characterName}&expression_name=${payload.expression}&variation_name=${payload.variationName}`;
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/variation/exists?${queryString}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    })
}

export const getCharacters = (payload) => {
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    })
}

export const getRouteCharacters = (payload) => {
  //routes/characters
  return fetch(`${endpoint}/api/stories/${payload.storyId}/routes/characters`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          'Content-Type': 'application/json',
      }
  })
  .then(res => res.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  })
}

export const getCharactersById = (payload) => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/${payload.characterId}`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          'Content-Type': 'application/json',
      }
  })
  .then(res => res.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  })
}
/////api/stories/{story_id}/characters/{id}/editable
export const getLockedCharactersById = (payload) => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/${payload.characterId}/editable`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          'Content-Type': 'application/json',
      }
  })
  .then(res => res.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  })
}





export const getCharactersScript = (payload) => {
    return fetch(`${endpoint}/api/stories/${payload.storyId}/characters`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    })
}

export const getDeleteCharacters = (payload) => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/${payload.charId}/usage`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          'Content-Type': 'application/json',
      }
  })
  .then(res => res.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  })
}

export const deleteCharacter = (payload) => {
  
  return fetch(`${endpoint}/api/stories/${payload.storyId}/characters/${payload.charId}`,
  {
      method: 'DELETE',
      headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          'Content-Type': 'application/json',
      }
  })
  .then(resp => resp.json())
  .then(resp => resp)
  .catch((err) => {
      throw err;
  });
}

//deleteCharacter
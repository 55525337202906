import React from 'react';
import { Modal,  Button } from 'react-bootstrap';
import { ModalStyled } from './Style';

function Success ({
    label,
    note,
    handleClick
}) {
    return <Modal show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'>
            <Modal.Body>
                <ModalStyled.addAssetMessageBox> 
                    <ModalStyled.successAssetContent>
                            <div className="success-icon mb-3">
                                <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
                            </div>
                            <div className="submit-asset-label" 
                                data-testid="success-label">
                                    {label}
                            </div>
                            <div className="text-muted text-note" 
                                data-testid="success-note">
                                {note}
                            </div>
                            <div className="mt-3">
                                <Button  
                                    onClick={handleClick}
                                    data-testid="success-btn"
                                    className="btn-snacktool btn-snacktool-golden">
                                    Okay
                                </Button>
                            </div>
                </ModalStyled.successAssetContent>
            </ModalStyled.addAssetMessageBox>
        </Modal.Body>
    </Modal>
}

export default Success
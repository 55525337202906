import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Card, Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';

import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { LibraryStyle } from './style/style';
import * as Constant from './../../common/constants';

// let setEnableFlag = 'disabled';

let inActiveArray = []



const CommandsLevel1 = (
  {
    loading,
    commandsList,
    setLevel,
    showShortCut,
    handleCommnadMenuClick,
    handleCommnadShortCutClick,

    handlePlaceViewMenuClick,
    handlePlaceMoveViewMenuClick,
    isViewStory,
    tokenDataUser,
    variableWindoOnOffStatus
  }) => {

  const [inActiveArray, setInActiveArray] = useState([])

  useEffect(() => {
  }, [inActiveArray])
  return (
    <LibraryStyle.commandPanel>
      <div className="command-panel-head-row d-flex align-items-center">
        <div className="text-left float-left head-title">Commands</div>
        <div className="head-icon">
          <OverlayTrigger
            key={`command-key`}
            placement="right"
            overlay={
              <Tooltip id={`command-key`}>
                Commands can be typed directly rather than selected from the menu. When entering a command, Ctrl+Space (Option+Space on Mac) will show a list of all parameters available. "Tab" autocompletes the first item in the list that shows up.
              </Tooltip>
            }
          >
            <img alt="" className={`command-key`} src="/images/icons/ic_keyboard.svg" />
          </OverlayTrigger>
        </div>
      </div>
      <div className="commands">
        <Categories
          loading={loading}
          catList={commandsList}
          setLevel={setLevel}
          showShortCut={showShortCut}
          handleCommnadMenuClick={handleCommnadMenuClick}
          handlePlaceMoveViewMenuClick={handlePlaceMoveViewMenuClick}
          handlePlaceViewMenuClick={handlePlaceViewMenuClick}
          isViewStory={isViewStory}
          tokenDataUser={tokenDataUser}
          variableWindoOnOffStatus={variableWindoOnOffStatus}
          inActiveArray={inActiveArray}
          setInActiveArray={setInActiveArray}
        />
      </div>
    </LibraryStyle.commandPanel>
  );
};

const Categories = (
  {
    loading,
    catList,
    setLevel,
    showShortCut,
    handleCommnadMenuClick,

    handlePlaceViewMenuClick,
    handlePlaceMoveViewMenuClick,
    isViewStory,
    tokenDataUser,
    variableWindoOnOffStatus,
    inActiveArray,
    setInActiveArray
  }) => {
  if (loading) {
    return '';
  } else {
    return catList.map(cat => (
      <CommandPanelListShow
        key={cat.name}
        cat={cat}
        catList={catList}
        setLevel={setLevel}
        showShortCut={showShortCut}
        handleCommnadMenuClick={handleCommnadMenuClick}
        handlePlaceViewMenuClick={handlePlaceViewMenuClick}
        handlePlaceMoveViewMenuClick={handlePlaceMoveViewMenuClick}
        isViewStory={isViewStory}
        tokenDataUser={tokenDataUser}
        variableWindoOnOffStatus={variableWindoOnOffStatus}
        inActiveArray={inActiveArray}
        setInActiveArray={setInActiveArray}
      />
    ));
  }
};

const CommandsInCategory = (
  {
    cmdList,
    handleCommnadMenuClick,
    flowchartNode,

    handlePlaceViewMenuClick,
    handlePlaceMoveViewMenuClick,
    isViewStory,
    tokenDataUser,
    variableWindoOnOffStatus
  }) => {

  return cmdList.map(cmd => (
    <CommandsInCategoryDisplayConditional
      cmd={cmd}
      key={cmd.name}
      handleCommnadMenuClick={handleCommnadMenuClick}

      handlePlaceViewMenuClick={handlePlaceViewMenuClick}
      handlePlaceMoveViewMenuClick={handlePlaceMoveViewMenuClick}
      isViewStory={isViewStory}
      tokenDataUser={tokenDataUser}
      variableWindoOnOffStatus={variableWindoOnOffStatus}
    />
  ));
};

const CommandDisplaySubList = (cmd, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handleMovePlaceViewMenuClick) => {
  return <React.Fragment>
    {
      (cmd.id !== Constant.COMMAND_PLACE_VIEW && cmd.id !== Constant.COMMAND_MOVE_BACKGROUND) &&
      <React.Fragment key={cmd.id}>
        <Button
          variant="link"
          onClick={() => handleCommnadMenuClick(cmd.id)}
          className="command-btn text-left"
          disabled={isViewStory === true || tokenDataUser === true || variableWindoOnOffStatus === true ? true : false}
        >
          {cmd.displayName}
        </Button>
      </React.Fragment>
    }
    {
      cmd.id === Constant.COMMAND_PLACE_VIEW &&
      <React.Fragment key={cmd.id}>
        <Button
          variant="link"
          onClick={() => handlePlaceViewMenuClick()}
          className="command-btn text-left"
          disabled={isViewStory === true || tokenDataUser === true || variableWindoOnOffStatus === true ? true : false}
        >
          {cmd.displayName} >
        </Button>
      </React.Fragment>
    }
    {
      cmd.id === Constant.COMMAND_MOVE_BACKGROUND &&
      <React.Fragment key={cmd.id}>
        <Button
          variant="link"
          onClick={() => handleMovePlaceViewMenuClick()}
          className="command-btn text-left"
          disabled={isViewStory === true || tokenDataUser === true || variableWindoOnOffStatus === true ? true : false}
        >
          {cmd.displayName} >
        </Button>
      </React.Fragment>
    }

  </React.Fragment>
}

const CommandsInCategoryDisplayConditional = (
  {
    cmd,
    handleCommnadMenuClick,
    flowchartNode,

    handlePlaceViewMenuClick,
    handlePlaceMoveViewMenuClick,
    isViewStory,
    tokenDataUser,
    variableWindoOnOffStatus
  }) => {

  switch (cmd.id) {

    case Constant.COMMAND_ADD_BLOCK:
      return CommandDisplaySubList(cmd, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus);

    default:
      return CommandDisplaySubList(cmd, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick);
  }
}

const CommandDisplayHeadList = (cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray) => {

  const eventKeyPressed = (eventKey) => {

    if (document.getElementById(`icon_right_${eventKey}`).className === 'fa fa-caret-right pr-2') {
      document.getElementById(`icon_right_${eventKey}`).className = 'fa fa-caret-down pr-2'
    } else {
      document.getElementById(`icon_right_${eventKey}`).className = 'fa fa-caret-right pr-2'
    }
  }

  return (
    <React.Fragment key={cat.id}>
      <Accordion defaultActiveKey={`#${cat.id}`}>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={`#${cat.id}`}
            onClick={() => eventKeyPressed(cat.id, inActiveArray, setInActiveArray)}>
            <div
              className="command-title text-left"
            >
              <i id={`icon_right_${cat.id}`} className={`fa fa-caret-down pr-2`} />
              {cat.name}
            </div>
            {/* <div className="command-shortcut">
              {showShortCut && <span className="badge badge-dark">{cat.shortcut}</span>}
            </div> */}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`#${cat.id}`}>
            <Card.Body>
              <ButtonGroup vertical>
                <CommandsInCategory
                  cmdList={cat.commands}
                  handleCommnadMenuClick={handleCommnadMenuClick}

                  handlePlaceViewMenuClick={handlePlaceViewMenuClick}
                  handlePlaceMoveViewMenuClick={handlePlaceMoveViewMenuClick}

                  isViewStory={isViewStory}
                  tokenDataUser={tokenDataUser}
                  variableWindoOnOffStatus={variableWindoOnOffStatus}
                />
              </ButtonGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  )
};

const CommandPanelListShow = (
  {
    cat,
    showShortCut,
    handleCommnadMenuClick,
    handlePlaceViewMenuClick,
    handlePlaceMoveViewMenuClick,
    isViewStory,
    tokenDataUser,
    variableWindoOnOffStatus,
    inActiveArray,
    setInActiveArray
  }) => {
  // setEnableFlag = 'disabled';
  switch (cat.name) {
    case "STORY FLOW":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    case "CHARACTER":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    case "STORY TEXT":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    case "VIEWS & OBJECTS":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    case "MEDIA & EFFECTS":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    case "VARIABLES":
      return CommandDisplayHeadList(cat, showShortCut, handleCommnadMenuClick, isViewStory, tokenDataUser, variableWindoOnOffStatus, handlePlaceViewMenuClick, handlePlaceMoveViewMenuClick, inActiveArray, setInActiveArray);

    default:
      return null;
  }
}

export default CommandsLevel1;
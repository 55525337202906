import styled from 'styled-components';

const addInfoContainer = styled.div`
  .modal-header {
    padding: 20px 60px;
  }
  .modal-body {
    padding: 0 60px 30px 60px;
    .form-group {
      .form-label {
        font-weight: bold;
        font-size: 14px;
        color: #303030;
        margin-bottom: 0.2rem;
        .text-muted {
          color: #707070 !important;
          font-size: 14px;
          margin-top: 0 !important;
        }
      }
      .secondary-text {
        font-weight: normal;
        font-size: 14px;
        color: #707070;
      }
      .disabled-text {
        width: 100%;
        padding: 0.3rem;
        border-radius: 0.25rem;
        box-shadow: none !important;
        border: 1px solid #d6d6d6 !important;
        background-image: none !important;
      }
      .lable-bottom-text {
        color: #707070;
        font-size: 14px;
        margin-bottom: 0.2rem;
      }
      .upload-file {
        width: 140px;
        ${'' /* .card-text{
           display:none;
         } */}
        .container {
          div:first-child {
            &:focus {
              outline: 0;
              box-shadow: none;
            }
          }
        }
        .card-content {
          height: 84px;
          width: 84px !important;
          border: 1px solid #bfbfbf;
          width: 100px;
          background: #f5f5fa;
          padding: 0;
          border-radius: 2px;

          .thumbs-container {
            width: 100px;
            height: 84px;
            div {
              width: 100px;
              height: 84px;
            }
          }
          .fa-cloud-upload:before {
            content: '';
            background: url(/images/icons/backup.svg) no-repeat !important;
            width: 54px;
            height: 36px;
            display: block;
          }
          .card-margin-15 {
            margin-right: 46px;
          }

          .text-secondary-file {
            p {
              color: #6c757d !important;
              width: 100px!importan;
            }
          }
          .cloud-upload-icon {
            opacity: 1;
            font-size: 90px !important;
          }
          @supports (-moz-appearance: none) {
            .cloud-upload-icon {
              padding-top: 0 !important;
            }
          }
        }
      }
    }
    .info-icon {
      background: url('/images/icons/info.svg') no-repeat;
      width: 14px;
      height: 14px;
      display: inline-block;
    }
    .info-icon:hover {
      background: url('/images/icons/info-active.svg') no-repeat;
    }
    
  }

  .label-text{
    margin-left: 25px;
    font-weight: bold;
  }

  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 25px;
  }

  .switch-flip input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 40px;
  }

  .slider-flip:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider-flip {
    background-color: #2196f3;
  }

  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }

  .slider-flip.round:before {
    border-radius: 50%;
  }
  .card-text,
  .left-button {
    position: absolute;
    bottom: -24px;
    line-height: normal;
    width: 380px;
    right: 0;
    text-align: right;
    display: block;
  }
  .left-button {
    font-family: Roboto;
    font-size: 14px;
    cursor: pointer;
    color: #E6A554 !important;
    width: 128px;
    text-align: left;
  }
  .font-14 {
    font-size: 14px;
  }
`;
const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
`;

const submitAssetContent = styled.div`
  width: 100%;
  margin-top: 20%;
  text-align: center;
`;
const successAssetContent = styled.div`
  width: 100%;
  margin-top: 10%;
  text-align: center;
`;

export const ToolTip = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 25px;
  padding: 20px;
  //margin-top: 50px;
  background-color: #00ced1;
`;

export const ChapterStyle = {
  addInfoContainer,
  addAssetMessageBox,
  submitAssetContent,
  successAssetContent,
  ToolTip
};

import React from 'react';
import { Button, Modal  } from 'react-bootstrap';
 
 

function DeleteStoryModal(props) {
   
  return (
    <Modal
      size="md"
       
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
     
      backdrop="static"
    >
      
      <Modal.Body className="mt-5">
       <div className="modal-content-wrapper">
           <div  className="modal-icon">
              <img alt="" className="checkIcon" src="/images/icons/ic_warning_24px.svg"/>
           </div>
           <div  className="modal-text">
              <label className="heading">Deleting this story will remove all its content and cannot be undone. Are you sure you want to proceed?</label>
              {/* <label className="sub-heading">
              </label> */}
           </div>
         
       </div>
       <div className="modal-button-wrapper justify-content-between">
       <Button onClick={props.handleHideDeleteModal} className="btn-snacktool btn-snacktool-golden">Cancel</Button>
          <Button onClick={props.handleStoryDelete} className="btn-snacktool btn-snacktool-golden">Yes, delete it</Button>
       </div>

      </Modal.Body>
    </Modal>
  );
}
export default DeleteStoryModal;

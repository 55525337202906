import React from 'react';
import { LibraryStyle } from './../style/style';
import ListHead from './ListHead';
import ListContent from './ListContent';

function ListAsset(props) {
  return (
    <LibraryStyle.listContentPanel>
      <ListHead
        storyTitle={props.storyTitle}
        handleFilterClick={props.handleFilterClick}
        filters={props.filters}
      />
      <ListContent
        handleUploadClick={props.handleUploadClick}
        assetList={props.assetList}
        loading={props.loading}
        handleSorting={props.handleSorting}
        sorting={props.sorting}
        showDeleteAssetModal={props.showDeleteAssetModal}
        hideDeleteAssetModal={props.hideDeleteAssetModal}
        characterInfoList={props.characterInfoList}
        showModal={props.showModal}
        handleShowDeleteModal={props.handleShowDeleteModal}
        handleHideDeleteModal={props.handleHideDeleteModal}
        handleDeleteAsset={props.handleDeleteAsset}
        deleteConfirm={props.deleteConfirm}
        assetInfoList={props.assetInfoList}
        deleteAsset={props.deleteAsset}
        IsAssetEdit={props.IsAssetEdit}
        showLockModal={props.showLockModal}
        handleEditAssetClick={props.handleEditAssetClick}
        setFileNames={props.setFileNames}
        maxFileError={props.maxFileError}
        setMaxFileError={props.setMaxFileError}
        setShowUploadPopupEnableUpload={props.setShowUploadPopupEnableUpload}
        handleChangeActiveCover={props.handleChangeActiveCover}
        filters={props.filters}
      />
    </LibraryStyle.listContentPanel>
  );
}

export default ListAsset;

import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from '../actions/action-types';
import * as StoryAction from '../actions/story-action-types'
import jwt from 'jsonwebtoken';
import {
  postLoginData,
  postInvite,
  postRefreshTokenData,
  fetchTutorialData,
  postTutorialData,
  getTutorialData,
  getToggleUpdated,
  logoutApi,
  setFontSetting
} from './api/accounts-api';
import { fontChange } from '../actions/story-action';

export function* postLoginDataWorker({ payload, isLogOut, logoutAuthToken }) {
  try {
    if (isLogOut == true) {
      const data = yield call(logoutApi, logoutAuthToken);
    }
    const data = yield call(postLoginData, payload);

    let storyId = getParameterByName('share');

    if (storyId !== null) {
      const inviteData = yield call(postInvite, storyId, data.body.accessToken);
      if (inviteData.result === 'Success') {
        yield put({
          type: ActionTypes.SET_SUCCESS_INVITE_ACCEPT,
          payload: storyId,
          acceptMsg: inviteData.body
        });
      }
      //"Only view operation is supported for this story"
      if (inviteData.result === 'Failure') {
        yield put({
          type: ActionTypes.SET_FAIL_INVITE_ACCEPT,
          expired:
            inviteData.message[0] === 'Sorry, this invitation has expired.' ||
            inviteData.message[0] ===
              'Only view operation is supported for this story'
              ? true
              : false,
          canceled:
            inviteData.message[0] ===
            'Sorry, your invitation to access this story has been cancelled.'
              ? true
              : false
        });
      }
    }
    
    if (data.result !== 'Failure') {
      let tokenDataUser = jwt.decode(
        data.body.accessToken ? data.body.accessToken : []
      );
      //if (tokenDataUser.roles === '[ROLE_WRITER]') {
      const tutorialData = yield call(fetchTutorialData, data.body.accessToken);

      if (tutorialData.result === 'Success') {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_SUCCESS,
          result: tutorialData
        });
      } else {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_FAIL,
          payload: {
            result: data
          }
        });
      }
      //}

      yield put({
        type: ActionTypes.SET_SUCCESS_LOGIN_DETAILS,
        payload: {
          result: data
        }
      });
    } else {
      yield put({
        type: ActionTypes.SET_FAIL_LOGIN_DETAILS,
        payload: {
          result: data
        }
      });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_FAIL_LOGIN_DETAILS });
  }
}

export function* postRefreshTokenWorker(payload) {
  try {
    const data = yield call(postRefreshTokenData, payload);

    if (data.result === 'Success') {
      yield put({
        type: ActionTypes.SET_TOKEN_REFRESH_SUCCESS,
        payload: {
          result: data
        }
      });
      let tokenDataUser = jwt.decode(data.body.accessToken);

      const tutorialData = yield call(fetchTutorialData, data.body.accessToken);

      if (tutorialData.result === 'Success') {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_SUCCESS,
          result: tutorialData
        });
      } else {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_FAIL,
          payload: {
            result: data
          }
        });
      }
    } else {
      yield put({ type: ActionTypes.SET_TOKEN_REFRESH_FAIL });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_TOKEN_REFRESH_FAIL });
  }
}

export function* postTutorialDataWorker(payload) {
  try {
    const data = yield call(postTutorialData, payload);
    if (data.result !== 'Failure') {
      yield put({
        type: ActionTypes.SET_TUTORIAL_DATA_SUCCESS,
        result: data
      });
      let tokenDataUser = jwt.decode(payload.authToken);

      const tutorialData = yield call(fetchTutorialData, payload.authToken);

      if (tutorialData.result === 'Success') {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_SUCCESS,
          result: tutorialData
        });
      } else {
        yield put({
          type: ActionTypes.GET_TUTORIAL_DATA_FAIL,
          payload: {
            result: data
          }
        });
      }
    } else {
      yield put({
        type: ActionTypes.SET_TUTORIAL_DATA_FAIL,
        payload: {
          result: data
        }
      });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_TUTORIAL_DATA_FAIL });
  }
}

export function* postTutorialDataWatcher() {
  yield takeLatest(ActionTypes.SET_TUTORIAL_DATA, postTutorialDataWorker);
}

export function* getTutorialDataWorker(payload) {
  try {
    let tokenDataUser = jwt.decode(payload);

    const data = yield call(getTutorialData, payload);

    if (data.result !== 'Failure') {
      yield put({
        type: ActionTypes.GET_TUTORIAL_DATA_SUCCESS,
        payload: {
          result: data
        }
      });
    } else {
      yield put({
        type: ActionTypes.GET_TUTORIAL_DATA_FAIL,
        payload: {
          result: data
        }
      });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_TUTORIAL_DATA_FAIL });
  }
}

export function* toggleCharacter(payload) {
  try {
    const data = yield call(getToggleUpdated, payload);

    if (data.result !== 'Failure') {
      yield put({
        type: ActionTypes.SET_TOGGLE_CHARACTER_SUCCESS,
        payload: {
          result: data
        }
      });
    } else {
      yield put({
        type: ActionTypes.SET_TOGGLE_CHARACTER_FAILURE,
        payload: {
          result: data
        }
      });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_TOGGLE_CHARACTER_FAILURE });
  }
}

export function* fontChangeSetting(payload) {
  try {
    const data = yield call(setFontSetting, payload);

    if (data.result !== 'Failure') {
      yield put({
        type: StoryAction.SET_FONT_SUCCESS,
        payload: {
          result: data
        }
      });
    } else {
      yield put({
        type: StoryAction.SET_FONT_FAILURE,
        payload: {
          result: data
        }
      });
    }
  } catch (e) {
    yield put({ type: StoryAction.SET_FONT_FAILURE });
  }
}

export function* getTutorialDataWatcher() {
  yield takeLatest(ActionTypes.GET_TUTORIAL_DATA, getTutorialDataWorker);
}

export function* postLoginDataWatcher() {
  yield takeLatest(ActionTypes.SET_LOGIN_DETAILS, postLoginDataWorker);
}

export function* postRefreshTokenWatcher() {
  yield takeLatest(ActionTypes.SET_TOKEN_REFRESH, postRefreshTokenWorker);
}

export function* toggleCharacterFullName() {
  yield takeLatest(ActionTypes.SET_TOGGLE_CHARACTER, toggleCharacter);
}

export function* fontChangeSettingWatcher() {
  yield takeLatest(StoryAction.FONT_CHANGE, fontChangeSetting)
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export default {
  postLoginDataWatcher,
  postRefreshTokenWatcher,
  postTutorialDataWatcher,
  getTutorialDataWatcher,
  toggleCharacterFullName,
  fontChangeSettingWatcher
};

import styled from 'styled-components';
import { colors } from '../../common/styles/variables';
import * as AspectRatioConfig from './../../config/aspectRatio.config';

export const uielementpreviewtitle = styled.div`
  font-family: PT Sans;
  color: #ffffff;
  p {
    font-size: 11px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  h3 {
    font-size: 24px;
  }
`;
export const uielementpreviewwrapper = styled.div`
  font-family: PT Sans;
  color: #ffffff;
  .mobile-bottom-text {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.1px;
  }
  .bottom-text {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
  }
`;

export const uielementpreview = styled.div`
  position: relative;
  width: 241px;
  height: 498px;
  /*background: #2a2a2a;*/
  background: url(images/icons/Phone.svg) no-repeat;
  overflow: hidden;
  margin-top: 50px;
  border-radius: 35px;
  box-shadow: 0px 2px 0px 3px rgba(26, 26, 26, 0.4);
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 5px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000000;
    border-radius: 10px;
  }
  .screen-wrapper-left {
    background: url('./images/static-preview-left.png') no-repeat;
  }
  .screen-wrapper-right {
    background: url('./images/static-preview-right-new.png') no-repeat;
  }
  .screen-wrapper {
    width: 223px;
    height: 397px;
    background-size: 100% 100%;
    position: relative;
    .normal-decision-disabled-btn {
      background: url(${props =>
        props.Normaldecisionbuttondisabled !== null
          ? props.Normaldecisionbuttondisabled + '?' + new Date().getTime()
          : './images/game_interface/btn_option_disabled.png'})
        no-repeat;
      width: 203px;
      height: 68px;
      background-size: 100%;
      margin: 101px auto 0;
      font-family: ${props =>
        props.fonts.buttonLabel.toLowerCase()}, Slabo !important;
      color: #ffffff;
      font-size: 18px;
      padding: 10px 20px 10px 20px;
    }
    .paid-decision-btn {
      background: url(${props =>
        props.Paiddecisionbtn !== null
          ? props.Paiddecisionbtn + '?' + new Date().getTime()
          : './images/game_interface/btn_option_juicy.png'})
        no-repeat;
      width: 203px;
      height: 68px;
      background-size: 100%;
      margin: 0 auto;
      font-family: ${props =>
        props.fonts.buttonLabel.toLowerCase()}, Slabo !important;
      font-size: 18px;
      letter-spacing: 0;
      color: #000000;
      padding: 10px 20px 10px 20px;
    }
    .currency-coin {
      background: url(./images/game_interface/currencycoins.png)
        no-repeat;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
      margin-right: 4px;
      margin-left: 20px;
    }
    .normal-decision-btn {
      background: url(${props =>
        props.Normaldecisionbuttonnormal !== null
          ? props.Normaldecisionbuttonnormal + '?' + new Date().getTime()
          : './images/game_interface/btn_option.png'})
        no-repeat;
      background-size: 100%;
      width: 200px;
      height: 68px;
      font-family: ${props =>
        props.fonts.buttonLabel.toLowerCase()}, Slabo !important;
      color: #ffffff;
      font-size: 18px;
      margin: 0 auto;
      padding: 10px 20px 10px 20px;
    }
    /*.dialogueBackgroundImage {
      background: url(${props =>
        props.Dialoguebackground + '?' + new Date().getTime()})
        no-repeat;
      width: 203px;
      height: 73px;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    
    .text-default-bg{
      background:rgba(0,0,0,0.8);
    }*/

    .hideText {
      overflow: hidden;
    }
    #dialogBackground {
      border: 5px solid transparent;
      border-image: url(${props => props.Dialoguebackground});
      border-image-slice: 49% fill;
      margin: 0px 7px 9px 7px;
      height: 74px;
      padding: 5px;
      position: absolute;
      bottom: 0;
      width: 95%;
      opacity: 0.69;
      font-size:11px;
    }

    .title-default-bg{
      min-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80%;
      overflow: hidden;
      width: 100%;
    }

    #namePlate {
      border: 5px solid transparent;
      border-image: url(${props => props.NamePlate});
      border-image-slice: 49% fill;
      margin: 0px 7px 9px 7px;
      width: 112px;
      height: 23px;
      padding: 0;
      margin-left: 14px;
      line-height: 14px;
    }

    .slider-wrapper {
      position: relative;
    }
    .slider-border {
      background: url('./images/slider-border.png') no-repeat;
      width: 148px;
      height: 16px;
      background-size: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    .slider-fill-timer {
      background: url(${props => props.timeBar})
        no-repeat;
      width: 190px;
      height: 8px;
      background-size: 100% 100%;
      position: absolute;
      margin-left: 15px;
      margin-top: 10px;
    }
    .right-mobile-title {
      font-family: ${props =>
        props.fonts.characterNamePlate.toLowerCase()}, Slabo !important;
      color: #FFFFFF;
      font-size: 14px;
      padding: 0 10px;
      position: absolute;
      bottom: 63px;
      left: 0;
      z-index: 1;
    }
    .right-mobile-text {
      font-family: ${props =>
        props.fonts.dialogueText.toLowerCase()}, Slabo !important;
      color: #ffffff;
      font-size: 12px;
      letter-spacing: 0;
      padding: 30px 50px 0 10px;
      line-height: normal;
    }
    .continue-icon {
      background: url(${props =>
        props.ContinueIcon + '?' + new Date().getTime()})
        no-repeat;
      width: 10px;
      height: 18px;
      background-size: 100%;
      position: absolute;
      right: 10px;
      bottom: 0;
    }
    .right-mobile-top {
      background: #000;
      width: 100%;
      height: 25px;
      .continue-icon {
        position: relative;
        right: 0;
        top: 0;
      }
    }
  }
`;
export const smartplayer = styled.div`
  position: relative;
  height: ${props => AspectRatioConfig.preview[props.aspectRatio].height}px;
  width: ${props => AspectRatioConfig.preview[props.aspectRatio].width}px;
  margin: auto;
  background: #43425d;
  overflow: hidden;
  margin-top: 25px;

  .previewScriptError {
    color: #ffffff;
    margin-top: ${props =>
      props.aspectRatio === AspectRatioConfig.aspectRatioNineByNineteen
        ? '90%'
        : '53%'};
    padding: 0 10px 0 10px;
    font-size: 13px;
  }

  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  &:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }

  .spinner-class {
    text-align: center !important;
    vertical-align: middle !important;
    margin: 60% 42% !important;
    color: #ffffff !important;
  }

  .spinner-class-9-19 {
    text-align: center !important;
    vertical-align: middle !important;
    margin: 95% 40% !important;
    color: #ffffff !important;
  }

  .shake {
    animation: shakeEffect !important;
    animation-iteration-count: infinite !important;
    -webkit-animation-iteration-count: infinite !important;
    animation-duration: 0.2s !important;
  }

  .preloadMessage {
    position: absolute;
    top: 177px;
    left: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .preloadMessage-9-19 {
    position: absolute;
    top: 230px;
    left: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .btn-img-label.endMsg {
    color: #ffffff !important;
    font-size: xx-large;
  }
  .blur-background {
    opacity: 0.2;
  }

  .preview {
    width: 100%;
    height: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @keyframes shakeEffect {
    0% {
      transform: translate(${props => props.shakeX * 5}px, 0px);
    }
    100% {
      transform: translate(0px, ${props => props.shakeY * 5}px);
    }
  }

  @keyframes charAnimationLeftToRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes charAnimationRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes charAnimationMiddleToLeft {
    0% {
      transform: translateX(20%);
    }
    20% {
      transform: translateX(0);
    }
  }
  @keyframes charAnimationMiddleToRight {
    0% {
      transform: translateX(20%);
    }
    20% {
      transform: translateX(-20%);
    }
  }

  @keyframes charAnimationRightToMiddle {
    0% {
      transform: translateX(40%);
    }
    20% {
      transform: translateX(20%);
    }
  }

  @keyframes charAnimationLeftToMiddle {
    0% {
      transform: translateX(-20%);
    }
    20% {
      transform: translateX(0);
    }
  }

  @keyframes charAnimationRightToLeft {
    0% {
      transform: translateX(40%);
    }
    40% {
      transform: translateX(0);
    }
  }
  @keyframes charAnimationLeftToRight {
    0% {
      transform: translateX(-40%);
    }
    40% {
      transform: translateX(0);
    }
  }

  @keyframes charAnimationLeftToRightWithFlip {
    0% {
      transform: translateX(-100%) scaleX(-1);
    }
    100% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationRightToLeftWithFlip {
    0% {
      transform: translateX(100%) scaleX(-1);
    }
    100% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationMiddleToLeftWithFlip {
    0% {
      transform: translateX(20%) scaleX(-1);
    }
    20% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationLeftToMiddleWithFlip {
    0% {
      transform: translateX(-20%) scaleX(-1);
    }
    20% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationRightToMiddleWithFlip {
    0% {
      transform: translateX(20%) scaleX(-1);
    }
    20% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationMiddleToRightWithFlip {
    0% {
      transform: translateX(-20%) scaleX(-1);
    }
    20% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes charAnimationLeftToRightWithNoneFlip {
    0% {
      transform: translateX(-40%) scaleX(-1);
    }
    40% {
      transform: translateX(0) scaleX(-1);
    }
  }

  @keyframes fadeInObj {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const content = styled.div`
  width: 100%;
  border-top: 1px solid #e3e3e3;
`;
export const playerButtons = styled.div`
  width: fit-content;
  width: ${props => props.buttonPanelWidth}px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  background: white;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  align-items: center;
  padding: 7px 0;
  img {
    cursor: pointer;
  }
`;

export const quickDialogue = styled.div`
  .typewriter h6 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0px solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    color: ${props => props.parameters};
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;

export const customDialogue = styled.div`
  .typewriter div {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0px solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;
// // font-size: ${props => props.parameters.Size}px;
// color: ${props => props.parameters.Color};
// animation:
// typing ${props => props.parameters['Writing speed']}s steps(40, end),
// blink-caret .75s step-end infinite;
export const dialogueHeader = styled.div`
  position: absolute;
  top: -15px;
  left: 10%;
  background: #854f88;
  padding: 4px 10px;
  min-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  border-radius: 6px;
  color: ${props => props.parameters};
`;

export const showNameDialogue = styled.div`
  background: #332d48;
  height: 100%;
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 100%;
  .showNameDialogue {
    background: #332d48;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 6px;
    width: 196px;
    padding: 22px 0;
    box-sizing: content-box;
    color: #f2f2f5;
    font-family: PT Sans;
    font-size: 13px;
    .title {
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 0;
      color: #fff;
      margin-bottom: 17px;
      line-height: normal;
    }

    .radioContainer {
      padding: 0;
      font-size: 13px;
      color: #f2f2f5;
      margin-bottom: 12px;
    }
    .radioContainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: relative;
      display: block;
      float: left;
      margin-right: 8px;
      height: 17px;
      width: 17px;
      background-color: transparent;
      border: 3px solid #bdbdbd;
      border-radius: 50%;
    }
    .radioContainer:hover input ~ .checkmark {
      border-color: #b5add1;
    }

    .radioContainer input:checked ~ .checkmark {
      background-color: #fff;
      border-color: #b5add1;
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 1px;
      top: 1px;
      width: 9px;
      height: 9px;
      background: #b5add1;
      border-radius: 50%;
    }
    .radioContainer input:checked ~ .checkmark:after {
      display: block;
    }
    .form-label {
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0;
      color: #d4cbf5;
      text-transform: uppercase;
      margin-bottom: 13px !important;
    }
    .preview-btn-wrapper {
      .confirm-btn {
        width: 162px;
        height: 30px;
        background: #eed9ff;
        color: #3a3352 !important;
        letter-spacing: 0px;
        border: none !important;
        font-weight: bold !important;
        font-size: 13px !important;
        border-radius: 5px !important;
        text-transform: uppercase;
      }
      .confirm-btn:disabled {
        background: #716183 !important;
        color: #3a3352 !important;
      }
    }
    .form-control {
      border: 2px solid #dad1f7;
      color: #f2f2f5;
      font-size: 13px !important;
      background: #6c6586;
    }
    .form-control::-webkit-input-placeholder {
      color: #f2f2f5;
      font-size: 13px !important;
      text-align: center;
    }
    .form-control::-moz-placeholder {
      color: #f2f2f5;
      font-size: 13px !important;
      text-align: center;
    }
    .form-control:-ms-input-placeholder {
      color: #f2f2f5;
      font-size: 13px !important;
      text-align: center;
    }
    .form-control::placeholder {
      color: #f2f2f5;
      font-size: 13px !important;
      text-align: center;
    }
  }
`;

export const previewHeader = styled.div`
  .active {
    fill: #530acd;
  }
  .tablet {
  }
  .phone-icon-wrapper {
    margin: 20px auto 0;
    .tablet-col {
      cursor: pointer;
      width: 20px;
      text-align: center;
    }
    .mobile-col {
      cursor: pointer;
      width: 20px;
      text-align: center;
      margin-right: 20px;
    }
  }
`;

export const characterDialogue = styled.div`
  padding: 25px 10px 10px;
  background: rgba(0, 0, 0, 0.4);
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
  color: #fffdfd;
`;

// export const characterDialogue = styled.div`
//   background: url(https://snack-assets-bucket.s3.amazonaws.com/2/42/Assets/UI%20Assets/BoilerPlate/BoilerPlate_DialogueBackground.png?1585655140406) no-repeat; */
//   width: 203px;
//   height: 85px;
//   background-size: 100% 100%;
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// `;

export const dialogueBox = styled.div`
  .right-mobile-title {
    font-family: ${props => props.gameFonts.characterNamePlate.toLowerCase()},
      Slabo !important;
    color: ${props => props.color};
    font-size: 14px;
    padding: 0 10px;
    position: absolute;
    bottom: 63px;
    left: 0;
    z-index: 1;
  }
  .right-mobile-text {
    font-family: ${props => props.gameFonts.dialogueText.toLowerCase()}, Slabo !important;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    padding: 12px 12px 12px 12px;
    line-height: normal;
    overflow: hidden;
  }
  .dialogueBackgroundImage {
    background: url(${props => props.Dialoguebackground}) no-repeat;
    width: 203px;
    height: 85px;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  #namePlate {
    border: 5px solid transparent;
    border-image: url(${props => props.NameImage});
    border-image-slice: 49% fill;
    margin: 0px 7px 9px 7px;
    width: 112px;
    height: 23px;
    padding: 0;
    margin-left: 14px;
    line-height: 14px;
  }
  #dialogBackground {
    border: 5px solid transparent;
    border-image: url(${props => props.Dialoguebackground});
    border-image-slice: 49% fill;
    margin: 0px 7px 9px 7px;
    height: 74px;
    padding: 5px;
    opacity: 0.69;
    word-wrap: anywhere;
    font-size: 11px;
  }

  .typewriter h6 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0px solid orange; /* The typwriter cursor */
    /*white-space: nowrap;*/ /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
    color: #ffffff;
    font-size: 14px;
  }

  .continue-icon {
    background: url(${props => props.Continueicon}) no-repeat;
    width: 10px;
    height: 18px;
    background-size: 100%;
    position: absolute;
    right: 16px;
    bottom: 14px;
  }

  #typedtext {
    height: 55px;
    overflow: hidden;
  }
`;

export const dialogueBoxDefault = styled.div`
  .right-mobile-title {
    font-family: ${props => props.gameFonts.characterNamePlate.toLowerCase()},
      Slabo !important;
    color: ${props => props.color};
    font-size: 14px;
    padding: 0 6px 3px 6px;
    position: absolute;
    bottom: 75px;
    left: 0;
    z-index: 1;
    background: #554a8c;

    min-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    overflow: hidden;
    border-radius: 6px;
    width: 112px;
    height: 23px;
    margin-left: 14px;
  }
  .right-mobile-text {
    font-family: ${props => props.gameFonts.dialogueText.toLowerCase()}, Slabo !important;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    padding: 12px;
    line-height: normal;
  }
  .dialogueBackgroundImage {
    margin: 0px 7px 9px 7px;
    background: rgba(0, 0, 0, 0.8);
    width: 203px;
    height: 75px;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .typewriter h6 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0px solid orange; /* The typwriter cursor */
    /*white-space: nowrap;*/ /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
    color: #ffffff;
    font-size: 14px;
  }

  .continue-icon {
    background: url(${props => props.Continueicon}) no-repeat;
    width: 7px;
    height: 10px;
    background-size: 100%;
    position: absolute;
    right: 6px;
    bottom: 6px;
  }
  #typedtext {
    height: 55px;
    overflow: hidden;
  }
`;

export const choicePanel = styled.div`
  .btn-img-wrapper {
    background: url(${props => props.normalBackground});
    display: flex;
    justify-content: center;
    align-items: center;
    width: 203px;
    height: 68px;
    background-size: 100%;
    margin: 0 auto;
    color: ${props => props.color};
  }

  /*.btn-img-wrapper:hover{
    cursor: pointer;
    background: url(${props => props.highlightedBackground}) no-repeat;
    width: 203px;
    height: 68px;
    background-size: 100%;
  }*/

  .btn-img-wrapper:active{
    background: url(${props => props.pressedBackground}) no-repeat;
    width: 203px;
    height: 68px;
    background-size: 100%;
  }

  .btn-img-label{
    margin: 0 auto;
    font-family: ${props =>
      props.gameFonts.buttonLabel.toLowerCase()}, Slabo !important;
    font-size: 14px;
  }
  .currencyIcon {
    background: url(${props => props.currencyBackground}) no-repeat;
    background-size: 100%;
    height: 20px;
    width: 20px;
    margin: 0 5px;
  }
  .btn-img-label.endMsg {
    color: #FFFFFF !important;
    font-size: xx-large;
  }
  .buttonLabel {
    padding: 10px 0px 10px 20px;
    font-size: 14px;
  }
  .buttonPrice {
    padding-right: 20px;
  }
`;

export const choiceTimer = styled.div`
  .slider-wrapper {
    position: relative;
    width: 190px;
    margin: 16px auto;
  }
  .slider-border {
    background: url('../images/slider-border.png') no-repeat;
    width: 148px;
    height: 16px;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .slider-fill-timer {
    background: url(${props => props.timeBar}) no-repeat;
    width: 100%;
    height: 4px;
    background-size: 100% 100%;
    position: absolute;
    transition: all 0.5s linear;
  }
  .timerShadow {
    width: 0%;
    height: 4px;
    background-size: 100% 100%;
    position: absolute;
    transition: all 0.5s ease;
    background: #000;
    opacity: 0.9;
    right: 0;
    animation: choiceTimer ${props => parseInt(props.waitTime)}s linear;
    animation-fill-mode: forwards;
  }
  @keyframes choiceTimer {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
`;

export const console = styled.div`
  .console-panel {
    width: 75%;
    height: auto;
    margin: 0 auto;
  }
  .console-panel-title {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 15px;
    cursor: pointer;
  }
`;

export const scrollHighlight = styled.div`
  .commandLabelSuffix {
    margin-left: 25px;
  }
  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 40px;
  }

  .switch-flip input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 40px;
  }

  .slider-flip:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider-flip {
    background-color: #2196f3;
  }

  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }

  .slider-flip.round:before {
    border-radius: 50%;
  }
  .label-text {
    font-weight: bold;
    vertical-align: text-bottom;
    margin-left: 10px !important;
    font: normal normal bold 12px/15px PT Sans;
    color: #3A2C61;
  }
`;

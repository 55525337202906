import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import * as styled from './../../player/style';

import { FONT_FAMILY, FONT_SIZE } from './../../../common/constants';

const FontFamily = props => {
  return (
    <Form>
      <Row className="">
        <Form.Group as={Col} controlId="formFontFamily">
          <Form.Label>Font family</Form.Label>
          <select
            className="form-control"
            defaultValue="Choose..."
            name="fontFamily"
            onChange={event => props.fontChange(event, 'fontFamily')}
            defaultValue={props.font}
          >
            {FONT_FAMILY.map(fontSize => {
              return <option key={fontSize}>{fontSize}</option>;
            })}
          </select>
        </Form.Group>

        <Form.Group as={Col} controlId="formFontSize">
          <Form.Label>Font size</Form.Label>
          <select
            className="form-control"
            defaultValue="Choose..."
            name="fontSize"
            onChange={event => props.fontChange(event, 'fontSize')}
            defaultValue={props.fontSize.replace('px', '')}
          >
            {FONT_SIZE.map(fontSize => {
              return <option key={fontSize}>{fontSize}</option>;
            })}
          </select>
        </Form.Group>
      </Row>
      <Row className="d-flex space-around mb-2">
      <button
          key="fontFamilyButton"
          className="btn-snacktool flex-item mb-3 btn-snacktool-brown replace-all"
          onClick={()=>props.fontChangeCancel()}
          type="button"
        >
          Cancel
        </button>
        <button
          key="fontFamilyButtonChange"
          className="btn-snacktool flex-item mb-3 btn-snacktool-brown replace-all"
          onClick={()=>props.fontChangeSave()}
          type="button"
        >
          Change
        </button>
        
      </Row>
    </Form>
  );
};

export default FontFamily;

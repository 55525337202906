import React from 'react';

import { Button, Modal, Row, Col } from 'react-bootstrap';
import * as styled from './style';

const ConfirmationWindow = ({
  acct,
  userDeactivate,
  onHideClcik,
  accStatus
}) => {
  return (
    <styled.ConfirmModal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="confirmModal"
        className="modalbg"
        size="md"
        show={userDeactivate}
      >
        <Modal.Body>
          {userDeactivate && (
            <styled.AccountDeleted>
              <styled.AccountIcon>
                <Row>
                  <Col>
                    <br />
                    <br />

                    <div className="success-icon mb-3">
                      <img
                        alt=""
                        className="mediumIcon"
                        src={
                          accStatus === 'deactivated'
                            ? '/images/icons/ic_do_not_disturb_off_24px.svg'
                            : '/images/icons/ic_check_circle_24px.svg'
                        }
                      />
                    </div>
                    <br />
                  </Col>
                </Row>
              </styled.AccountIcon>
              {`Account ${acct.email} has been ${accStatus}`}
              <br />
              <br />
              <Row className="justify-content-md-center">
                <Col md="auto">
                  <Button
                    className="btn-snacktool btn-snacktool-golden btn btn-primary"
                    onClick={onHideClcik}
                  >
                    Okay
                  </Button>
                </Col>
              </Row>
            </styled.AccountDeleted>
          )}
        </Modal.Body>
      </Modal>
    </styled.ConfirmModal>
  );
};
export default ConfirmationWindow;

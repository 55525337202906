import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LibraryStyle } from './../../story/style/style';

function ResetSuccess({ handleSuccesClick, show, textSuccess }) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
      className="modalbg"
      size="md"
      show={show}
    >
      <Modal.Body>
        <LibraryStyle.addAssetMessageBox>
          <LibraryStyle.successAssetContent>
            <div className="success-icon mb-3">
              <img
                alt=""
                className="mediumIcon"
                src="/images/icons/ic_done_24px.svg"
              />
            </div>
            <div data-testid="success-label" className="submit-asset-label">
              {textSuccess}
            </div>
            <div>
              <Button
                data-testid="success-btn"
                onClick={handleSuccesClick}
                className="btn-snacktool btn-snacktool-golden btn btn-primary"
              >
                Okay
              </Button>
            </div>
          </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}
export default ResetSuccess;

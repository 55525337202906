import React from 'react';
import { ChapterStyle} from './style';
import { Row,Modal, Col, ProgressBar } from 'react-bootstrap';
import * as translations from './translations';

function SubmitRoute(props) {
 
    return (

      <Modal 
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
     className="modalbg" 
     size="md" 
     show={props.showModal}
      
     >
       <Modal.Body  >
         
       
       <ChapterStyle.addAssetMessageBox>
            <ChapterStyle.submitAssetContent>
                <Row className="pb-5">
                    <Col className="submit-asset-label">
                        {props.isRouteEdit? translations.UPDATING_ROUTE :translations.SAVING_NEW_ROUTE}
                    </Col>
                </Row>
                <Row>
                    <Col className="progress-bar-col pb-4">
                        <ProgressBar	style={{ width: `${props.progress<100?props.progress:100}%` }} aria-valuenow={props.progress} aria-valuemin='0' aria-valuemax='100' className="progress-bar-custom"   >
                         
                        </ProgressBar>
                    </Col>
                </Row>
                
            </ChapterStyle.submitAssetContent>
        </ChapterStyle.addAssetMessageBox>
       </Modal.Body>
     </Modal>
   );
    
}

export default SubmitRoute
import React from 'react';
import * as Constants from './../../common/constants';
import * as LibraryConstant from './constant';
import * as translations from './translations';
import * as mmb from 'music-metadata-browser';
import ReactAudioPlayer from 'react-audio-player';

const createPreviewUIThumb = files => {
  let thumbs = files.map(file => (
    <div key={file.name}>
      {getFileType(file) === 'image' && (
        <img
          src={file.preview}
          key={file.name}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      )}
      {getFileType(file) === 'audio' && (
        <audio
          autoPlay={true}
          src={file.preview}
          controls
          className="audio-play"
          key={file.name}
          alt="previewAudio"
        />
      )}
    </div>
  ));

  return thumbs;
};

const createPreviewThumb = files => {
  let thumbs = files.map(file => (
    <div key={file.name}>
      {getFileType(file) === 'image' && (
        <img
          src={file.preview}
          key={file.name}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      )}
      {getFileType(file) === 'audio' && (
        <audio
          autoPlay={true}
          src={file.preview}
          controls
          className="audio-play"
          key={file.name}
          alt="previewAudio"
        />
      )}
    </div>
  ));

  return thumbs;
};

const createUploadPreviewThumb = file => {
  if (file === undefined) {
    return;
  }
  if (
    file.name.split('.')[1].toUpperCase() === 'OGG' ||
    file.name.split('.')[1].toUpperCase() === 'WAV'
  ) {
    let oThumb = (
      <div key={file.name}>
        {
          <audio
            src={URL.createObjectURL(file)}
            type={`audio/${file.name.split('.')[1].toLowerCase()}`}
            controls
            autoPlay={true}
            className="audio-play"
            controlsList="nodownload"
            style={{ width: '240px', marginTop: '10px' }}
            key={file ? file.name : ''}
            alt="previewAudio"
          />
        }
      </div>
    );

    return oThumb;
  } else {
    let thumbs = (
      <div key={file.name}>
        {getFileType(file) === 'image' && (
          <img
            src={URL.createObjectURL(file)}
            key={file ? file.name : ''}
            height="100%"
            width="100%"
            alt="previewImage"
          />
        )}
        {getFileType(file) === 'audio' && (
          <audio
            autoPlay={true}
            src={URL.createObjectURL(file)}
            controls
            className="audio-play"
            controlsList="nodownload"
            style={{ width: '240px', marginTop: '10px' }}
            key={file ? file.name : ''}
            alt="previewAudio"
          />
        )}
        {getFileType(file) === 'video' && (
          <audio
            autoPlay={true}
            src={URL.createObjectURL(file)}
            controls
            className="audio-play"
            controlsList="nodownload"
            style={{ width: '240px', marginTop: '10px' }}
            key={file ? file.name : ''}
            alt="previewAudio"
          />
        )}
      </div>
    );

    return thumbs;
  }
};

const createEditPreviewThumb = (files, asset) => {
  let thumbs = files.map(file => (
    <div>
      {(asset.type === 'Background_Image' || asset.type === 'Object_Image') && (
        <img
          src={files[0] + '?v=' + new Date().getTime()}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      )}
      {asset.type === 'Background_Music' && (
        <audio
          autoPlay={true}
          src={files[0]}
          controls
          className="audio-play"
          alt="previewAudio"
        />
      )}
      {asset.type === 'Incidental_Sound' && (
        <audio
          autoPlay={true}
          src={files[0]}
          controls
          className="audio-play"
          alt="previewAudio"
        />
      )}
    </div>
  ));

  return thumbs;
};

const createCoverEditPreviewThumb = (files, asset) => {
  let thumbs = files.map(file => (
    <div>
      {
        <img
          src={files[0] + '?v=' + new Date().getTime()}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      }
    </div>
  ));

  return thumbs;
};

const checkFileSize = (rejectedFiles, maxSize) => {
  return rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
};

const createPreviewUrl = acceptedFiles => {
  let newFiles = acceptedFiles[0];
  return [newFiles].map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
  );
};

const createUploadPreviewUrl = acceptedFiles => {
  let newFiles = acceptedFiles;
  return [newFiles].map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
  );
};

const getAssetTypeList = files => {
  if (files.length === 0) {
    return Constants.ASSET_TYPES_IMAGES;
  }
  let fileType = getFileType(files[0]);
  if (fileType === 'image') {
    return Constants.ASSET_TYPES_IMAGES;
  }

  return Constants.ASSET_TYPES_AUDIO;
};

const getAssetFileTypeList = (fileType, extension = '') => {
  if (fileType === 'image') {
    switch (extension) {
      case 'PNG':
        return Constants.ASSET_TYPES_IMAGES;
        break;

      case 'JPG':
      case 'JPEG':
        return Constants.ASSET_TYPES_IMAGES_JPG;
        break;

      default:
        return Constants.ASSET_TYPES_IMAGES;
        break;
    }
  } else if (fileType === 'audio' || fileType === 'video') {
    return Constants.ASSET_TYPES_AUDIO;
  } else {
    return [];
  }
};

const getStoryFileTypeList = (fileType, extension = '') => {
  switch (extension) {
    case 'PNG':
      if (fileType.width == '512' && fileType.height == '819') {
        return Constants.STORY_TYPES_ALBUM_IMAGES;
      } else if (fileType.width == '1024' && fileType.height == '649') {
        return Constants.STORY_TYPES_SERIES_IMAGES;
      } else if (fileType.width <= 390 && fileType.height <= 100) {
        return Constants.STORY_TYPES_TITLE_IMAGES;
      } else {
        return [];
      }
      break;

    default:
      return [];
      break;
  }
};

const checkImageTypeorNot = files => {
  if (files.length === 0) {
    return Constants.ASSET_TYPES_IMAGES;
  }
  let fileType = getFileType(files[0]);
  if (fileType === 'image') {
    return true;
  }

  return false;
};

const getEditAssetTypeList = (files, type) => {
  if (type === 'Background_Image' || type === 'Object_Image') {
    return Constants.ASSET_TYPES_IMAGES;
  } else {
    return Constants.ASSET_TYPES_AUDIO;
  }
};

const getFileType = file => {
  if (file !== null) {
    if (file.type !== undefined) {
      return file.type.split('/')[0];
    }
  }

  return null;
};

const getAssetFileType = file => {
  if (file !== null) {
    return file.type.split('/')[0];
  }

  return null;
};

const getFileExtension = file => {
  if (file !== null) {
    return file.type
      .split('/')[1]
      .split('+')[0]
      .toUpperCase();
  }
  return null;
};

const getActiveTabKey = (
  assetStatus,
  activeTabKey,
  hideModal,
  intervalId,
  actionFrom = ''
) => {
  let newActiveTab = activeTabKey;
  if (assetStatus === 'success') {
    newActiveTab = LibraryConstant.FOURTH_TAB_KEY;
  }

  if (assetStatus === 'fail') {
    newActiveTab = LibraryConstant.FIFTH_TAB_KEY;
  }

  if (assetStatus === 'cancel') {
    newActiveTab = LibraryConstant.SIXTH_TAB_KEY;
  }

  if (
    newActiveTab === LibraryConstant.FOURTH_TAB_KEY ||
    newActiveTab === LibraryConstant.FIFTH_TAB_KEY ||
    // newActiveTab === LibraryConstant.THIRD_TAB_KEY ||
    newActiveTab === LibraryConstant.SIXTH_TAB_KEY
  ) {
    if (actionFrom === 'uiInterface' && assetStatus === 'success') {
      setTimeout(hideModal, 5000);
      clearInterval(intervalId);
    } else {
      if (actionFrom !== 'uiInterface') {
        setTimeout(hideModal, 5000);
        clearInterval(intervalId);
      }
    }
  }

  return newActiveTab;
};

const getAssetActiveTabKey = (
  assetStatus,
  activeTabKey,
  hideModal,
  intervalId
) => {
  let newActiveTab = activeTabKey;

  if (assetStatus === 'success') {
    newActiveTab = LibraryConstant.FOURTH_TAB_KEY;
    setTimeout(hideModal, 2000);
    clearInterval(intervalId);
  }

  if (assetStatus === 'fail') {
    newActiveTab = LibraryConstant.FIFTH_TAB_KEY;
    setTimeout(hideModal, 5000);
    clearInterval(intervalId);
  }

  if (assetStatus === 'cancel') {
    newActiveTab = LibraryConstant.SIXTH_TAB_KEY;
    setTimeout(hideModal, 2000);
    clearInterval(intervalId);
  }

  return newActiveTab;
};

async function getFileWidth(type, file) {
  if (type === 'image') {
    let imageSpecs = await readAsDataURL(file);
    return imageSpecs.width;
  } else {
    return '';
  }
}

async function getFileHeight(type, file) {
  if (type === 'image') {
    let imageSpecs = await readAsDataURL(file);
    return imageSpecs.height;
  } else {
    return '';
  }
}

async function setAsssetFileData(file, asset, assetData, typeMain = 'asset') {
  let assetNames = [];
  for (let j = 0; j < assetData.length; j++) {
    assetNames.push(assetData[j].name);
  }

  let assetList = [];

  for (let i = 0; i < file.length; i++) {
    let temp = {};
    //.replace(/[`~!@#$%^&*()|+\=\s?;:'",.<>\{\}\[\]\\\/]/gi, '_')

    let assetName = generateUniqueName(
      assetNames,
      file[i].name.split('.')[0].replace(/\s+/g, '_')
    );

    temp['fileIndex'] = i;
    temp['name'] = assetName;
    temp['type'] = '';
    if (
      file[i].name.split('.')[1].toUpperCase() === 'OGG' ||
      file[i].name.split('.')[1].toUpperCase() === 'WAV'
    ) {
      temp['extension'] = file[i].name.split('.')[1].toUpperCase();
    } else {
      temp['extension'] =
        file[i].type === '' ? 'invalid' : getFileExtension(file[i]);
    }

    if (
      file[i].name.split('.')[1].toUpperCase() === 'OGG' ||
      file[i].name.split('.')[1].toUpperCase() === 'WAV'
    ) {
      temp['assetType'] = 'audio';
    } else {
      temp['assetType'] =
        file[i].type === '' ? 'invalid' : getAssetFileType(file[i]);
    }

    temp['specs'] =
      file[i].type === '' ||
      file[i].type === 'audio/ogg' ||
      file[i].type === 'video/ogg'
        ? '1m 53s'
        : await addSpec(getAssetFileType(file[i]), file[i]);
    temp['size'] = file[i].size;
    if (typeMain === 'cover') {
      temp['height'] =
        file[i].type === '' ||
        file[i].type === 'audio/ogg' ||
        file[i].type === 'video/ogg'
          ? ''
          : await getFileHeight(getAssetFileType(file[i]), file[i]);

      temp['width'] =
        file[i].type === '' ||
        file[i].type === 'audio/ogg' ||
        file[i].type === 'video/ogg'
          ? ''
          : await getFileWidth(getAssetFileType(file[i]), file[i]);
    }
    if (
      file[i].name
        .split('.')
        [file[i].name.split('.').length - 1].toUpperCase() === 'OGG' ||
      file[i].name
        .split('.')
        [file[i].name.split('.').length - 1].toUpperCase() === 'WAV'
    ) {
      if (
        file[i].name
          .split('.')
          [file[i].name.split('.').length - 1].toUpperCase() === 'OGG'
      ) {
        let checkSafari = checkBrowser();

        if (checkSafari === true) {
          temp['isUpload'] = false;
          temp['extension'] = 'invalid';
        } else {
          temp['isUpload'] = true;
        }

        // if( /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'])===true)
        // {
        //   temp['isUpload']=false;
        //   temp['extension']='invalid'
        // }
      } else {
        temp['extension'] = 'WAV';
        temp['isUpload'] = true;
      }
    } else {
      temp['isUpload'] =
        file[i].type === ''
          ? false
          : isUploadFile(file[i].size, getFileExtension(file[i]));
    }

    if (new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(assetName) !== true) {
      temp['isValid'] = false;
    } else {
      temp['isValid'] = true;
    }

    if (file[i].size > 15728640) {
      temp['isUpload'] = false;
    }

    assetNames.push(assetName);

    assetList.push(temp);
  }

  return assetList;
}

function checkBrowser() {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;

  // Detect Internet Explorer
  let IExplorerAgent =
    userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf('OP') > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;
  return safariAgent;
}

async function setCharacterFileData(file, asset, assetData) {
  let assetNames = [];
  for (let j = 0; j < assetData.length; j++) {
    assetNames.push(assetData[j].assetName);
  }

  let assetList = [];

  for (let i = 0; i < file.length; i++) {
    let temp = {};

    //.replace(/[`~!@#$%^&*()|+\=\s?;:'",.<>\{\}\[\]\\\/]/gi, '_')
    let assetName = generateUniqueName(
      assetNames,
      file[i].name.split('.')[0].replace(/\s+/g, '_')
    );

    temp['fileIndex'] = i;
    temp['assetName'] = assetName;
    temp['assetType'] = '';

    temp['extension'] =
      file[i].type === '' ? 'invalid' : getFileExtension(file[i]);
    temp['fileType'] =
      file[i].type === '' ? 'invalid' : getAssetFileType(file[i]);
    temp['specs'] =
      file[i].type === ''
        ? ''
        : await addSpec(getAssetFileType(file[i]), file[i]);
    temp['size'] = file[i].size;
    temp['defaultAsset'] = false;
    temp['isUpload'] =
      file[i].type === ''
        ? false
        : isUploadCharacterFile(file[i].size, getFileExtension(file[i]));

    if (new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(assetName) !== true) {
      temp['isValid'] = false;
    } else {
      temp['isValid'] = true;
    }

    assetNames.push(assetName);

    assetList.push(temp);
  }

  return assetList;
}

const isUploadFile = (size, extension) => {
  let isUpload = true;

  if (
    size < 15728640 &&
    (extension === 'PNG' ||
      extension === 'JPG' ||
      extension === 'JPEG' ||
      extension === 'MP3' ||
      extension === 'WAV' ||
      extension === 'OGG' ||
      extension === 'MPEG')
  ) {
    isUpload = true;
  } else {
    isUpload = false;
  }
  return isUpload;
};

const isUploadCharacterFile = (size, extension) => {
  let isUpload = true;

  if (
    size < 15728640 &&
    (extension === 'PNG' ||
      extension === 'MP3' ||
      extension === 'WAV' ||
      extension === 'OGG' ||
      extension === 'MPEG')
  ) {
    isUpload = true;
  } else {
    isUpload = false;
  }
  return isUpload;
};

function generateUniqueName(eNames, newName) {
  const nameParts = function(_fileName) {
    const lastDotIndex = _fileName.lastIndexOf('.');
    let fName, extn;

    if (lastDotIndex === -1) {
      extn = '';
      fName = _fileName;
    } else {
      extn = _fileName.split('.').pop();
      fName = _fileName.substring(0, lastDotIndex);
    }
    return { onlyName: fName, extension: extn };
  };

  const origName = nameParts(newName).onlyName;
  let i = 0;

  while (true) {
    if (eNames.find(aName => aName === newName) == undefined) {
      //Unique
      return newName;
    } else {
      const extension = nameParts(newName).extension;
      newName = extension
        ? `${origName}_${++i}.${extension}`
        : `${origName}_${++i}`;
    }
  }
}

const setUploadEnabled = assets => {
  let isEnabled = false;
  let validUpload = 0;
  for (let i = 0; i < assets.length; i++) {
    if (assets[i].isUpload === false) {
      validUpload++;
    }

    if (assets[i] !== undefined) {
      if (assets[i].isUpload === true) {
        if (
          assets[i].type === '' ||
          assets[i].name === '' ||
          assets[i].isUnique === false ||
          assets[i].isValid === false ||
          (assets[i].extension !== 'PNG' && assets[i].type === 'Object_Image')
        ) {
          isEnabled = true;
          return isEnabled;
        }
      }
    }
  }

  if (validUpload === assets.length) {
    isEnabled = true;
  }
  return isEnabled;
};

const setCharacterUploadEnabled = assets => {
  let isEnabled = false;
  let validUpload = 0;
  for (let i = 0; i < assets.length; i++) {
    if (assets[i].isUpload === false) {
      validUpload++;
    }

    if (assets[i] !== undefined) {
      if (assets[i].isUpload === true) {
        if (
          assets[i].assetType === '' ||
          assets[i].assetName === '' ||
          assets[i].isUnique === false ||
          assets[i].isValid === false
        ) {
          isEnabled = true;
          return isEnabled;
        }
      }
    }
  }

  if (validUpload === assets.length) {
    isEnabled = true;
  }
  return isEnabled;
};

// addSpec(LibraryServices.getFileType(files[0]));

async function addSpec(type, file) {
  if (type === 'image') {
    let imageSpecs = await readAsDataURL(file);
    return `${imageSpecs.width}x${imageSpecs.height} px`;
  }
  if (type === 'audio') {
    let audioSpecs = await readAudioFile(file);
    return audioSpecs;
  }
}

function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader(); // CREATE AN NEW INSTANCE.
    reader.onload = function(e) {
      var img = new Image();
      img.src = e.target.result;
      img.onload = function() {
        return resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
    // let fileReader = new FileReader();
    // let img = new Image()
    // img.src=file;
    // debugger
    // img.onload = function(){
    //   return resolve({ width:file.width, height: file.height});
    // }
    // fileReader.readAsDataURL(file);
  });
}

function readAudioFile(file) {
  try {
    return new Promise((resolve, reject) => {
      return mmb.parseBlob(file, { native: true }).then(metadata => {
        return resolve(
          parseInt(metadata.format.duration / 60, 10).toString() +
            'm ' +
            parseInt(metadata.format.duration % 60).toString() +
            's'
        );
      });
    });
  } catch (err) {
    console.log('Parsing error in audio file');
  }
}

function parseFile(file) {}

const getFormData = (asset, files) => {
  let editFile = [];
  var formData = new FormData();
  if (typeof files[0] !== 'string') {
    formData.append('file', files[0]);
  } else {
    formData.append('file', JSON.stringify(editFile));
  }

  //  for (let n = 0;n<characterStore.Files.length; n++) {

  //   let isExist=false;

  // for (let m = 0;m<finalVarObject.length; m++) {
  //   if(n===finalVarObject[m].fileIndex){
  //    isExist=true;
  //   }
  // }

  // if(isExist===false){
  //  characterStore.Files.splice(n,1,fileEmptyObject)
  // }

  // }

  delete asset.fileIndex;
  delete asset.assetType;
  delete asset.size;
  delete asset.isUpload;
  delete asset.isUnique;
  delete asset.isValid;
  formData.append('asset', JSON.stringify(asset));

  return formData;
};

const getFormDataCover = (asset, files) => {
  let editFile = [];
  var formData = new FormData();
  if (typeof files[0] !== 'string') {
    formData.append('file', files[0]);
  } else {
    formData.append('file', JSON.stringify(editFile));
  }

  //  for (let n = 0;n<characterStore.Files.length; n++) {

  //   let isExist=false;

  // for (let m = 0;m<finalVarObject.length; m++) {
  //   if(n===finalVarObject[m].fileIndex){
  //    isExist=true;
  //   }
  // }

  // if(isExist===false){
  //  characterStore.Files.splice(n,1,fileEmptyObject)
  // }

  // }

  delete asset.fileIndex;
  delete asset.assetType;
  delete asset.size;
  delete asset.isUpload;
  delete asset.isUnique;
  delete asset.isValid;
  formData.append('coverimage', JSON.stringify(asset));

  return formData;
};

const checkUniqueAssetName = (assetData, filesData, name, key) => {
  let isUnique = true;

  for (let j = 0; j < assetData.length; j++) {
    if (assetData[j].name.toLowerCase() === name.toLowerCase()) {
      isUnique = false;
    }
  }

  for (let i = 0; i < filesData.length; i++) {
    if (i !== key) {
      if (filesData[i].name.toLowerCase() === name.toLowerCase()) {
        isUnique = false;
      }
    }
  }

  return isUnique;
};

const checkCharacterUniqueAssetName = (assetData, filesData, name, key) => {
  let isUnique = true;

  for (let j = 0; j < assetData.length; j++) {
    if (assetData[j].assetName.toLowerCase() === name.toLowerCase()) {
      isUnique = false;
    }
  }

  for (let i = 0; i < filesData.length; i++) {
    if (i !== key) {
      if (filesData[i].assetName.toLowerCase() === name.toLowerCase()) {
        isUnique = false;
      }
    }
  }

  return isUnique;
};

const getUploadFormData = (asset, files) => {
  let finalAsset = [];

  var fileEmptyObject = new Blob([''], { type: 'image/png' });

  var formData = new FormData();

  for (let n = 0; n < files.length; n++) {
    let isExist = false;

    for (let m = 0; m < asset.length; m++) {
      if (n === asset[m].fileIndex) {
        if (asset[m].isUpload === true) {
          isExist = true;
        }
      }
    }

    if (isExist === false) {
      files.splice(n, 1, fileEmptyObject);
    }
  }
  let finalUploadFiles = files;

  for (let i = 0; i < finalUploadFiles.length; i++) {
    if (asset[i].isUpload === false) {
      formData.append('files', fileEmptyObject);
    } else {
      formData.append('files', finalUploadFiles[i]);
    }
  }

  for (let j = 0; j < asset.length; j++) {
    if (asset[j].isUpload === true) {
      delete asset[j].assetType;
      delete asset[j].size;
      delete asset[j].isUpload;
      delete asset[j].isUnique;
      delete asset[j].isValid;

      finalAsset.push(asset[j]);
      //finalUploadFiles.push(files[j])
    }
  }

  formData.append('assets', JSON.stringify(finalAsset));

  return formData;
};

const getUploadFormDataCover = (asset, files) => {
  let finalAsset = [];

  var fileEmptyObject = new Blob([''], { type: 'image/png' });

  var formData = new FormData();

  for (let n = 0; n < files.length; n++) {
    let isExist = false;

    for (let m = 0; m < asset.length; m++) {
      if (n === asset[m].fileIndex) {
        if (asset[m].isUpload === true) {
          isExist = true;
        }
      }
    }

    if (isExist === false) {
      files.splice(n, 1, fileEmptyObject);
    }
  }
  let finalUploadFiles = files;

  for (let i = 0; i < finalUploadFiles.length; i++) {
    if (asset[i].isUpload === false) {
      formData.append('files', fileEmptyObject);
    } else {
      formData.append('files', finalUploadFiles[i]);
    }
  }

  for (let j = 0; j < asset.length; j++) {
    if (asset[j].isUpload === true) {
      delete asset[j].assetType;
      delete asset[j].size;
      delete asset[j].isUpload;
      delete asset[j].isUnique;
      delete asset[j].isValid;

      finalAsset.push(asset[j]);
      //finalUploadFiles.push(files[j])
    }
  }

  formData.append('coverimages', JSON.stringify(finalAsset));

  return formData;
};

const setUploadVariationsDefaultData = (variationData, asset, type, key) => {
  let isExist = false;

  if (variationData.length > 0) {
    for (let i = 0; i < variationData.length; i++) {
      if (variationData[i].assetType === type) {
        if (variationData[i].defaultAsset === true) {
          isExist = true;
        }
      }
    }
  }

  for (let j = 0; j < asset.length; j++) {
    if (j !== key) {
      if (asset[j].assetType === type) {
        if (asset[j].defaultAsset === true) {
          isExist = true;
        }
      }
    }
  }

  return isExist;
};

const checkUploadVariationsDefaultData = (variationData, asset) => {
  if (asset) {
    let defaultType = asset.assetType;
    let defaultAsset = asset.defaultAsset;

    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          if (defaultAsset === true) {
            variationData[i]['defaultAsset'] = false;
          }
        }
      }
    }
  }

  return variationData;
};

const checkVariationsDefaultData = (variationData, asset) => {
  if (asset) {
    let defaultType = asset.assetType;
    let defaultAsset = asset.defaultAsset;

    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          if (defaultAsset === true) {
            variationData[i]['defaultAsset'] = false;
          }
        }
      }
    }
  }

  return variationData;
};

const checkUploadPreviewVariationsDefaultData = (variationData, asset) => {
  if (asset) {
    let defaultType = asset.assetType;
    let defaultAsset = asset.defaultAsset;

    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          if (defaultAsset === true) {
            variationData[i]['defaultAsset'] = false;
            variationData[i]['preview'] = false;
          }
        }
      }
    }
  }

  return variationData;
};

const checkPreviewVariationsDefaultData = (variationData, asset) => {
  if (asset) {
    let defaultType = asset.assetType;
    let defaultAsset = asset.defaultAsset;

    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          if (defaultAsset === true) {
            variationData[i]['defaultAsset'] = false;
            variationData[i]['preview'] = false;
          }
        }
      }
    }
  }

  return variationData;
};

const loadImageWithoutCanvas = imagePath => {
  return new Promise(resolve => {
    // Simulate a call to Dropbox or other service that can
    // return an image as an ArrayBuffer.
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    // Use JSFiddle logo as a sample image to avoid complicating
    // this example with cross-domain issues.
    xhr.open('GET', imagePath, true);

    // Ask for the result as an ArrayBuffer.
    xhr.responseType = 'arraybuffer';
    let blobImgUrl = '';

    xhr.onload = function(e) {
      // Obtain a blob: URL for the image data.
      var arrayBufferView = new Uint8Array(this.response);
      var blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
      var urlCreator = window.URL || window.webkitURL;
      blobImgUrl = urlCreator.createObjectURL(blob);
      resolve(blobImgUrl);
    };

    xhr.send();
  });
};

const checkAssetsCount = asset => {
  let isEnabled = false;
  let outCnt = 0;
  let ASSET_TYPES_CHARACTER = ['Outfit', 'Expression', 'Hair'];
  if (asset.length > 0) {
    for (let i = 0; i < ASSET_TYPES_CHARACTER.length; i++) {
      for (let j = 0; j < asset.length; j++) {
        if (ASSET_TYPES_CHARACTER[i] === 'Outfit') {
          if (asset[j].assetType === ASSET_TYPES_CHARACTER[i]) {
            outCnt++;
          }
        }
        //   if(ASSET_TYPES_CHARACTER[i]==='Expression'){
        //     if(asset[j].assetType===ASSET_TYPES_CHARACTER[i]){
        //       expCnt++

        //   }
        // }
        //   if(ASSET_TYPES_CHARACTER[i]==='Hair'){
        //     if(asset[j].assetType===ASSET_TYPES_CHARACTER[i]){
        //       hairCnt++

        //   }
        // }
      }
    }
  }
  // if(expCnt>0 && outCnt>0 && hairCnt>0){
  //   isEnabled=true;
  // }
  if (outCnt > 0) {
    isEnabled = true;
  }

  return isEnabled;
};

const getVariationsData = (variationData, asset, files, isEdit) => {
  let defaultType = asset.assetType;
  let deafaultCnt = 0;
  let accesories = [];

  let finalVariationData = [];

  if (isEdit) {
    for (let i = 0; i < variationData.length; i++) {
      if (variationData[i].fileIndex === asset.fileIndex) {
        variationData.splice(i, 1, asset);
      }
    }
    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          if (variationData[i].defaultAsset === true) {
            deafaultCnt++;
          }
        }
      }
    }
    if (deafaultCnt === 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].fileIndex === asset.fileIndex) {
          variationData[i]['defaultAsset'] = true;
        }
      }
    }
  } else {
    let index = files.length;
    asset['fileIndex'] = index;
    if (asset) {
      if (variationData.length > 0) {
        for (let i = 0; i < variationData.length; i++) {
          if (variationData[i].assetType === defaultType) {
            deafaultCnt++;
          }
        }
      }
      if (deafaultCnt === 0) {
        asset['defaultAsset'] = true;
      }
    }
    variationData.push(asset);
  }

  for (let a = 0; a < variationData.length; a++) {
    if (variationData[a].assetType === 'Accessory') {
      finalVariationData.push(variationData[a]);
    }
  }
  for (let h = 0; h < variationData.length; h++) {
    if (variationData[h].assetType === 'Hair') {
      finalVariationData.push(variationData[h]);
    }
  }
  for (let e = 0; e < variationData.length; e++) {
    if (variationData[e].assetType === 'Expression') {
      finalVariationData.push(variationData[e]);
    }
  }

  for (let o = 0; o < variationData.length; o++) {
    if (variationData[o].assetType === 'Outfit') {
      finalVariationData.push(variationData[o]);
    }
  }

  return finalVariationData;
};

const getUploadVariationsData = (variationData, asset, files, isEdit) => {
  let defaultType = asset.assetType;
  let deafaultCnt = 0;
  let accesories = [];

  let finalVariationData = [];

  let index = files.length;
  asset['fileIndex'] = index;
  if (asset) {
    if (variationData.length > 0) {
      for (let i = 0; i < variationData.length; i++) {
        if (variationData[i].assetType === defaultType) {
          deafaultCnt++;
        }
      }
    }
    if (deafaultCnt === 0) {
      asset['defaultAsset'] = true;
    }
  }
  variationData.push(asset);

  for (let a = 0; a < variationData.length; a++) {
    if (variationData[a].assetType === 'Accessory') {
      finalVariationData.push(variationData[a]);
    }
  }
  for (let h = 0; h < variationData.length; h++) {
    if (variationData[h].assetType === 'Hair') {
      finalVariationData.push(variationData[h]);
    }
  }
  for (let e = 0; e < variationData.length; e++) {
    if (variationData[e].assetType === 'Expression') {
      finalVariationData.push(variationData[e]);
    }
  }

  for (let o = 0; o < variationData.length; o++) {
    if (variationData[o].assetType === 'Outfit') {
      finalVariationData.push(variationData[o]);
    }
  }

  return finalVariationData;
};

const getUploadVariationsPreviewData = (
  variationData,
  asset,
  files,
  isEdit
) => {
  let isExistCnt = 0;
  let finalVariationData = [];
  if (isEdit) {
    for (let i = 0; i < variationData.length; i++) {
      if (variationData[i].fileIndex === asset.fileIndex) {
        variationData.splice(i, 1, asset);
      }
    }
  } else {
    if (asset) {
      if (variationData.length > 0) {
        for (let i = 0; i < variationData.length; i++) {
          if (variationData[i].fileIndex === asset.fileIndex) {
            isExistCnt++;
          }
        }
      }
    }
    if (isExistCnt === 0) variationData.push(asset);
  }

  for (let a = 0; a < variationData.length; a++) {
    if (variationData[a].assetType === 'Accessory') {
      finalVariationData.push(variationData[a]);
    }
  }
  for (let h = 0; h < variationData.length; h++) {
    if (variationData[h].assetType === 'Hair') {
      finalVariationData.push(variationData[h]);
    }
  }
  for (let e = 0; e < variationData.length; e++) {
    if (variationData[e].assetType === 'Expression') {
      finalVariationData.push(variationData[e]);
    }
  }
  for (let o = 0; o < variationData.length; o++) {
    if (variationData[o].assetType === 'Outfit') {
      finalVariationData.push(variationData[o]);
    }
  }

  return finalVariationData;
};

const getVariationsPreviewData = (variationData, asset, files, isEdit) => {
  let isExistCnt = 0;
  let finalVariationData = [];
  if (isEdit) {
    for (let i = 0; i < variationData.length; i++) {
      if (variationData[i].fileIndex === asset.fileIndex) {
        variationData.splice(i, 1, asset);
      }
    }
  } else {
    if (asset) {
      if (variationData.length > 0) {
        for (let i = 0; i < variationData.length; i++) {
          if (variationData[i].fileIndex === asset.fileIndex) {
            isExistCnt++;
          }
        }
      }
    }
    if (isExistCnt === 0) variationData.push(asset);
  }

  for (let a = 0; a < variationData.length; a++) {
    if (variationData[a].assetType === 'Accessory') {
      finalVariationData.push(variationData[a]);
    }
  }
  for (let h = 0; h < variationData.length; h++) {
    if (variationData[h].assetType === 'Hair') {
      finalVariationData.push(variationData[h]);
    }
  }
  for (let e = 0; e < variationData.length; e++) {
    if (variationData[e].assetType === 'Expression') {
      finalVariationData.push(variationData[e]);
    }
  }
  for (let o = 0; o < variationData.length; o++) {
    if (variationData[o].assetType === 'Outfit') {
      finalVariationData.push(variationData[o]);
    }
  }

  return finalVariationData;
};

const getUplodFilesData = (FileData, files, isEdit, asset) => {
  if (isEdit) {
    FileData.splice(asset.fileIndex, 1, files[0]);
  } else {
    FileData.push(files);
  }

  return FileData;
};

const getFilesData = (FileData, files, isEdit, asset) => {
  if (isEdit) {
    FileData.splice(asset.fileIndex, 1, files[0]);
  } else {
    FileData.push(files[0]);
  }

  return FileData;
};

const deleteVariationsData = (variationData, index) => {
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i].fileIndex === index) variationData.splice(i, 1);
  }

  return variationData;
};

const setEditAssetData = (variationData, index) => {
  let editAsset = [];
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i].fileIndex === index) editAsset = variationData[i];
  }

  return editAsset;
};
const setEditAssetName = (variationData, index) => {
  let editAssetName = [];
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i].fileIndex === index)
      editAssetName = variationData[i].assetName;
  }

  return editAssetName;
};

const setEditAssetFileData = (files, index) => {
  let editAssetFile = [];
  for (let i = 0; i < files.length; i++) {
    if (i === index) editAssetFile.push(files[i]);
  }

  return editAssetFile;
};

const filterAssetList = (assets, filters, order) => {
  let filterList = [];
  filterList = assets.filter(asset => {
    if (filters.image === true) {
      return Constants.ASSET_TYPES_IMAGES.indexOf(asset.type) > -1;
    }

    if (filters.audio === true) {
      if (
        asset.type === 'Background_Music' ||
        asset.type === 'Incidental_Sound'
      ) {
        return true;
      }
      return Constants.ASSET_TYPES_AUDIO.indexOf(asset.type) > -1;
    }

    return asset;
  });

  let newList = sortList(filterList, order);

  return newList;
};

const sortList = (assetList, order) => {
  if (order === 'desc') {
    return assetList
      .sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      })
      .reverse();
  }

  return assetList
    .sort((a, b) => {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    })
    .reverse();
};

const getAssetTypeLabel = assetTypeName => {
  let explodeName = assetTypeName.split('_');
  if (explodeName[1] === undefined) {
    return `${explodeName[0]}`;
  } else {
    return `${explodeName[0]} ${explodeName[1]}`;
  }
};

const getCharacterTypeLabel = assetTypeName => {
  let explodeName = assetTypeName;
  return `${explodeName}`;
};

const showError = (rejectedFiles, isEdit = false, asset = []) => {
  let file = rejectedFiles[0];
  let fileType = getFileType(file);
  let error = '';
  if (isEdit === false) {
    switch (fileType) {
      case 'image':
        error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
        break;
      case 'audio':
        error = translations.ASSET_AUDIO_TYPE_ERROR_MESSAGE;
        break;
      default:
        error = translations.FILES_TYPE_NOT_ACCEPTED;
    }
  } else {
    error = translations.FILES_TYPE_NOT_ACCEPTED;
    switch (asset.type) {
      case 'Background_Image':
        if (fileType === 'image') {
          error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
        }
        break;

      case 'Object_Image':
        if (fileType === 'image') {
          error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE_PNG;
        }
        break;
      case 'Incidental_Sound':
      case 'Background_Music':
        if (fileType === 'audio') {
          error = translations.ASSET_AUDIO_TYPE_ERROR_MESSAGE;
        }
        break;
      default:
        error = translations.FILES_TYPE_NOT_ACCEPTED;
    }
  }

  return error;
};

const filterAssetListByCharacter = (assetList, searchAsset) => {
  let filterList = assetList.filter(asset => {
    return (
      asset.name.toLowerCase().includes(searchAsset.toLowerCase()) ||
      asset.tags.indexOf(searchAsset) > -1
    );
  });

  return filterList;
};

const getSelectedAssetIds = (selectedAsset, characterAssetId, selectAll) => {
  let selectedAssetIds = [];
  let remove = false;
  if (selectedAsset.indexOf(characterAssetId) > -1 && selectAll === false) {
    selectedAssetIds = selectedAsset.filter(x => x !== characterAssetId);
    remove = true;
  } else {
    selectedAsset.push(characterAssetId);
    selectedAssetIds = [...new Set(selectedAsset)];
  }

  return [selectedAssetIds, remove];
};

const getElementFormData = (file, id, specs, storyId) => {
  var formData = new FormData();
  formData.append('file', file);
  formData.append('story_id', storyId);
  formData.append('id', id);
  formData.append('specs', specs);

  return formData;
};

const mapInterfaceElements = interfaceList => {
  let list = {};
  Constants.interfaceList.map(item => {
    let interfaceItem = getInterfaceItem(item, interfaceList);
    if (interfaceItem[0]) {
      list[item] = interfaceItem[0].awsPath;
    }
  });

  return list;
};

const getInterfaceItem = (item, interfaceList) => {
  return interfaceList.filter(interfaceItem => item === interfaceItem.element);
};

const prepareFontName = font => {
  let newFont = font.split('_').join(' ');
  return newFont.charAt(0).toUpperCase() + newFont.slice(1);
};

const getSortedList = list => {
  return list.sort(function(a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
};

const makeCamelCaseString = string => {
  return (
    string.charAt(0).toUpperCase() +
    string.slice(1, string.length).toLowerCase()
  );
};

const getDefaultActiveTabInLibraryChangeLog = data => {
  if (data.characters.length > 0) {
    return 'characters';
  }

  if (data.assets.length > 0) {
    return 'assets';
  }

  if (data.gameInterfaces.length > 0) {
    return 'gameInterface';
  }

  if (data.views.length > 0) {
    return 'views';
  }
};

export const LibraryServices = {
  createPreviewThumb,
  checkFileSize,
  createPreviewUrl,
  getAssetTypeList,
  getFileType,
  getFileExtension,
  getActiveTabKey,
  getAssetActiveTabKey,
  getFormData,
  filterAssetList,
  getAssetTypeLabel,
  showError,
  filterAssetListByCharacter,
  getSelectedAssetIds,
  getElementFormData,
  getVariationsData,
  getFilesData,
  deleteVariationsData,
  checkVariationsDefaultData,
  checkAssetsCount,
  checkPreviewVariationsDefaultData,
  getVariationsPreviewData,
  setEditAssetFileData,
  setEditAssetData,
  loadImageWithoutCanvas,
  mapInterfaceElements,
  createEditPreviewThumb,
  getEditAssetTypeList,
  createPreviewUIThumb,
  prepareFontName,
  getSortedList,
  makeCamelCaseString,
  getDefaultActiveTabInLibraryChangeLog,
  checkImageTypeorNot,
  setEditAssetName,
  setAsssetFileData,
  getAssetFileTypeList,
  getUploadFormData,
  createUploadPreviewThumb,
  setUploadEnabled,
  checkUniqueAssetName,
  getCharacterTypeLabel,
  getStoryFileTypeList,
  checkUploadVariationsDefaultData,
  setUploadVariationsDefaultData,
  setCharacterFileData,
  checkUploadPreviewVariationsDefaultData,
  getUploadVariationsData,
  getUploadVariationsPreviewData,
  getUplodFilesData,
  checkCharacterUniqueAssetName,
  setCharacterUploadEnabled,
  getUploadFormDataCover,
  createCoverEditPreviewThumb,
  getFormDataCover
};

import React, { useState } from 'react'
import {  Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';


const VariableTextBox = (props) => {
    const [stringBoxValue, setstringBoxValue] = useState(props.value)
    const [errorMessage, setErrorMessage] = useState(null)

    const changeStringBox = (event, variableId, variableValue, clicked) => {
        switch (clicked) {
            case 'reset':
                const variableValueReset = variableValue == null ? '' : variableValue
                props.onChangeTextBox(variableId, variableValueReset, clicked);
                setstringBoxValue(variableValueReset);
                setErrorMessage('')
                break;

            case 'normal':
                const variableValueChanged = event.target.value;
                if (variableValueChanged == '' || variableValueChanged == null) {
                    setErrorMessage('Variable value should not be blanked')
                    setstringBoxValue(variableValueChanged);
                    props.onChangeTextBox(variableId, variableValueChanged, 'normal');

                } else {
                    setstringBoxValue(variableValueChanged);
                    setErrorMessage('')
                    props.onChangeTextBox(variableId, variableValueChanged, 'normal');
                }
                break;

            case 'focuseout':
                if (event.target.value == null || event.target.value == '') {
                    setstringBoxValue(variableValue);
                    props.onChangeTextBox(variableId, variableValue == null ? '' : variableValue, 'reset');
                    setErrorMessage('')
                }
                break;
                default:break;
        }
    }

    return (
        <Row>
            <Col md="9" className="p-0">
                <Form.Group>
                    <Form.Control
                        required
                        type="text"
                        name={`textbox_${props.id}`}
                        id={props.id}
                        key={props.id}
                        // defaultValue={props.value}
                        value={props.value}
                        className="ml-0"
                        placeholder="Variable Value"
                        maxLength="20"
                        onChange={(event) => changeStringBox(event, props.id, stringBoxValue, 'normal')}
                        disabled={props.defaultDisabledView}
                        onBlur={(event) => changeStringBox(event, props.id, stringBoxValue == '' ? props.defaultValueTextbox : stringBoxValue, 'focuseout')}

                    />
                    <Form.Text className="text-danger">
                        {errorMessage}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col>
                <div className="ml-3 mt-2">
                    {
                        props.updateValueArrayIds.includes(props.id) === true &&
                        <OverlayTrigger
                            key={`tooltip-reset-${props.id}`}
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-${props.id}`} >
                                    Reset to default
                            </Tooltip>
                            }
                        >
                            <img
                            alt=''
                                src={`/images/icons/refresh.svg`}
                                className="refresh-icon"
                                onClick={props.defaultDisabledView !== 'disabled' ? (event) => changeStringBox(event, props.id, props.defaultValueTextbox, 'reset') : ''}
                            />
                        </OverlayTrigger>


                    }
                </div>
            </Col>
        </Row>
    )
}

export default VariableTextBox;
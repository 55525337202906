import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as Constant from './../../../common/constants';

const setMedia = (command) => {
    let commandParameters = command.parameters;
    let previewContent = document.getElementById('media-sound-preview');
    if (Object.keys(commandParameters).length !== 0) {
        let mediaSound = sound(command.sprites[0].url);
        previewContent.innerHTML = ReactDOMServer.renderToString(mediaSound);
    }
}


const addVolume = (command, skipTimeout = false) => {
    let commandParameters = command.parameters;
    let previewContent = document.getElementById('media-preview');
    if (skipTimeout === false && Object.keys(commandParameters).length !== 0) {
        let volume  = parseFloat(commandParameters['Volume'])/10;
        let initialVolume = parseFloat(commandParameters['Old volume'])/10;
        if(document.getElementById('playSound')) {
            let playSoundVolume = document.getElementById('playSound')
            playSoundVolume.volume = volume
        }
        if(document.getElementById('playMusic')) {
            let playMusicVolume = document.getElementById('playMusic');
            var interval = parseInt(commandParameters['Duration']) * 1000;
            var fadeout = setInterval(
                function() {
                    playMusicVolume.volume = initialVolume;
                  // Reduce volume by 0.05 as long as it is above 0
                  // This works as long as you start with a multiple of 0.05!
                  if (initialVolume < volume) {
                        initialVolume = parseFloat((initialVolume + 0.1).toFixed(1));
                        playMusicVolume.volume = initialVolume;
                  } else if (volume < initialVolume) {
                        initialVolume = parseFloat((initialVolume - 0.1).toFixed(1));
                        playMusicVolume.volume = initialVolume;
                } if (volume == initialVolume) {
                    // Stop the setInterval when 0 is reached
                    clearInterval(fadeout);
                  }
                }, interval);
        }
        // let mediaSound = sound(command.sprites[0]);
        // previewContent.innerHTML = ReactDOMServer.renderToString(mediaSound);
    } else {
        let volume  = parseFloat(commandParameters['Old volume'])/10;
        if(document.getElementById('playMusic')) {
            let playMusicVolume = document.getElementById('playMusic');
            //playMusicVolume.volume = volume;
        }
        if(document.getElementById('playSound')) {
            let playSoundVolume = document.getElementById('playSound')
            //playSoundVolume.volume = volume
        }
    }
}

const playMusicInterval = (duration, initialVolume, volume, type = 'fadeIn') => {
    let playMusicVolume = document.getElementById('playMusic');
    
    var fadeout = setInterval(
        function() {
            playMusicVolume.volume = initialVolume;
            // Reduce volume by 0.05 as long as it is above 0
            // This works as long as you start with a multiple of 0.05!
            if (initialVolume <= volume) {
                initialVolume = parseFloat((initialVolume + 0.1).toFixed(1));
                playMusicVolume.volume = initialVolume;
            } else if (volume < initialVolume) {
                initialVolume = parseFloat((initialVolume - 0.1).toFixed(1));
                playMusicVolume.volume = initialVolume;
                if (initialVolume === 0) {
                    playMusicVolume.remove();
                }
            } if (volume == initialVolume) {
                // Stop the setInterval when 0 is reached
                clearInterval(fadeout);
            }
        }, ((duration /10) * 1000));
}


function sound(src) {
    return <audio autoPlay={true} src={src} id="playSound" className="audio-play" autoPlay key="audio" alt="previewAudio"/>
} 

const playMusic = (command) => {
    let commandParameters = command.parameters;
    let previewContent = document.getElementById('media-preview');
    let musicContent = document.getElementById('playMusic');
    let oldSrc = '';
    if (musicContent) {
        oldSrc = musicContent.src;
    }

    // Check existing music file is playing
    if (oldSrc != '' && oldSrc === command.sprites[0].url) {
        return;
    }
    
    // If not existing file then create new instant
    if (Object.keys(commandParameters).length !== 0) {
        let mediaSound = '';
        if (commandParameters['Loop'] === 'true') {
            mediaSound = repeatMusic(command.sprites[0].url);
        } else {
            mediaSound = singleTimeMusic(command.sprites[0].url);
        }
        previewContent.innerHTML = ReactDOMServer.renderToString(mediaSound);
    }
    
    if (commandParameters.hasOwnProperty('Fade in') && commandParameters['Fade in'] > 0) {
        let playMusicVolume = document.getElementById('playMusic');
        playMusicVolume.volume = 0;
        playMusicInterval(commandParameters['Fade in'], 0 , 1, 'fadeIn')
    }
}

const repeatMusic = (src) => {
    return <audio src={src} 
        id="playMusic" 
        autoPlay={true}
        className="audio-play" 
        loop 
        autoPlay 
        key="audio" 
        alt="playMusic" 
        volume="0.0"/>
}

const singleTimeMusic = (src) => {
    return <audio src={src} 
        id="playMusic" 
        autoPlay={true}
        className="audio-play" 
        autoPlay 
        key="audio" 
        alt="playMusic"
        volume="0.0" />
}

const stopMusic = (command) => {
    let playMusic = document.getElementById('playMusic');

    if (playMusic) {
        if (command.parameters.hasOwnProperty('Fade out') && command.parameters['Fade out'] > 0) {
            playMusicInterval(command.parameters['Fade out'], 1 , 0, 'fadeOut')
        }
        if (command.parameters.hasOwnProperty('Fade out') && command.parameters['Fade out'] === '0') {
            playMusic.remove();
        }
        
        return true;
    }

    return false;
}

const shakeCamera = (command, viewId) => {
    if (viewId == null) {
        return;
    }

    let commandParameters = command.parameters;
    let previewElement = document.getElementById('preview-content-background-' + viewId);

    if (!previewElement) {
        return;
    }
    
    previewElement.style.removeProperty('animation');
    previewElement.classList.add('shake');

    setTimeout(() => {
        previewElement.classList.remove('shake');
    }, commandParameters[Constant.DURATION] * 1000);
}

export const MediaCommands = {
    setMedia,
    playMusic,
    stopMusic,
    addVolume,
    shakeCamera
}
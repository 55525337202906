import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import * as StyleStoryList from './style-story';
import './story.css';

const StyleListing = props => {
  let storyButtonLabelName = '';
  let storyActionLink = 'home';
  const editToCast = `characters/${props.storyData.id}`;
  let imageButton = '';
  switch (props.storyData.status) {
    case 'New':
      storyButtonLabelName = 'Start Writing';
      storyActionLink = props.storyData.viewOnly
        ? `write-story/${props.storyData.id}`
        : `characters/${props.storyData.id}`;
      break;

    case 'In Progress':
    case 'In Revision':
      storyButtonLabelName = 'Continue Writing';
      storyActionLink = `write-story/${props.storyData.id}`;

      break;

    default:
      storyButtonLabelName = 'View';
      storyActionLink = `write-story/${props.storyData.id}`;
  }

  if (props.storyData.readOnly === true || props.storyData.viewOnly) {
    storyButtonLabelName = 'View';
  }

  return (
    <div className="col-lg-4 col-xlg-3 col-xxl-2 d-flex justify-content-center main_story">
      <StyleStoryList.StoryList>
        <div className="margin-story">
          <div>
            <div className="storyblock-header">
              <OverlayTrigger
                key={`tooltip-${props.storyData.id}`}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${props.storyData.id}`}>
                    {props.storyData.title}
                  </Tooltip>
                }
              >
                <StyleStoryList.StoryTitle>
                  {props.storyData.title}
                </StyleStoryList.StoryTitle>
              </OverlayTrigger>
              {props.storyData.readOnly !== true &&
                props.storyData.status !== 'Submitted' &&
                props.storyData.viewOnly !== true && (
                  <Dropdown direction="right">
                    <Dropdown.Toggle className="btnMainStory">
                      <i className="fa fa-ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropDownMenuCss">
                      {props.storyData.status !== 'Published' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleEditClick(
                              props.storyData.id,
                              props.storyData.status
                            )
                          }
                          className="dropDownItemCss"
                        >
                          Edit Info
                        </Dropdown.Item>
                      )}

                      {(props.storyData.status === 'In Progress' ||
                        props.storyData.status === 'New' ||
                        props.storyData.status === 'Revision' ||
                        props.storyData.status === 'In Revision') &&
                        props.storyData.id !== 1 && (
                          <Dropdown.Item
                            onClick={() =>
                              props.handleCreditClick(
                                props.storyData.id,
                                props.storyData.storyCredits
                                  ? props.storyData.storyCredits
                                  : ''
                              )
                            }
                            className="dropDownItemCss"
                          >
                            {props.storyData.storyCredits &&
                            props.storyData.storyCredits != ''
                              ? `Edit Credits`
                              : `Add Credits`}
                          </Dropdown.Item>
                        )}

                      {(props.storyData.status === 'In Progress' ||
                        props.storyData.status === 'New' ||
                        props.storyData.status === 'In Revision') && (
                        <Dropdown.Item
                          className="dropDownItemCss"
                          onClick={() =>
                            props.handleShowCollaborator(
                              props.storyData.id,
                              props.storyData.createdBy
                            )
                          }
                        >
                          Add Collaborator
                        </Dropdown.Item>
                      )}

                      {(props.storyData.status === 'In Progress' ||
                        props.storyData.status === 'New' ||
                        props.storyData.status === 'In Revision') &&
                        props.storyData.storyType === 'ShortStory' && (
                          <Dropdown.Item
                            className="dropDownItemCss"
                            onClick={() =>
                              props.setStoryIdAction(
                                props.storyData.id,
                                props.storyData.status,
                                props.storyData.readOnly,
                                props.storyData.title,
                                props.storyData.viewOnly
                              )
                            }
                          >
                            <NavLink
                              to={editToCast}
                              className="dropDownItemCssNav"
                              // className="btn-snacktool-secondary btn-snacktool"
                            >
                              Edit Cast List
                            </NavLink>
                          </Dropdown.Item>
                        )}

                      <Dropdown.Item
                        className="dropDownItemCss"
                        onClick={() =>
                          props.handleShowViewer(
                            props.storyData.id,
                            props.storyData.createdBy
                          )
                        }
                      >
                        Add Viewer
                      </Dropdown.Item>

                      {/* <Dropdown
                  className="dropDownItemCss"
                  onClick={() =>
                    props.handleShowCollaborator(
                      props.storyData.id,
                      props.storyData.createdBy
                    )
                  }
                >
                  Add Collaborator
                </Dropdown> */}

                      {props.storyData.status === 'In Progress' && (
                        <Dropdown.Item
                          disabled={
                            props.reviewLoading &&
                            props.reviewstoryId === props.storyData.id
                              ? true
                              : false
                          }
                          onClick={() =>
                            props.handleReviewClick(
                              props.storyData.id,
                              props.storyData.status
                            )
                          }
                          className="dropDownItemCss"
                        >
                          Submit for Review
                        </Dropdown.Item>
                      )}
                      {props.storyData.status === 'In Revision' && (
                        <Dropdown.Item
                          disabled={
                            props.reviewLoading &&
                            props.reviewstoryId === props.storyData.id
                              ? true
                              : false
                          }
                          onClick={() =>
                            props.handleReviewClick(
                              props.storyData.id,
                              props.storyData.status
                            )
                          }
                          className="dropDownItemCss"
                        >
                          Submit for Review
                        </Dropdown.Item>
                      )}

                      {(props.storyData.status === 'In Progress' ||
                        props.storyData.status === 'New' ||
                        props.storyData.status === 'Revision' ||
                        props.storyData.status === 'In Revision') &&
                        props.storyData.id !== 1 && (
                          <Dropdown.Item
                            onClick={() =>
                              props.handleDeleteClick(props.storyData.id)
                            }
                            className="dropDownItemCss"
                            style={{ borderTop: '1px solid #D6D6D6' }}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      {props.storyData.status === 'Pending Approval' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleUndoReviewClick(
                              props.storyData.id,
                              props.storyData.canUndoReview,
                              'undoReview'
                            )
                          }
                          className="dropDownItemCss dropDownItemSubmit"
                        >
                          Undo request Review
                        </Dropdown.Item>
                      )}
                      {props.storyData.status === 'Approved' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleSubmitPublishClick(
                              props.storyData.id,
                              props.storyData.status
                            )
                          }
                          className="dropDownItemCss dropDownItemSubmit"
                        >
                          Submit for Publishing
                        </Dropdown.Item>
                      )}
                      {props.storyData.status === 'Approved' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleSubmitPublishClick(
                              props.storyData.id,
                              'UpdateApproved'
                            )
                          }
                          className="dropDownItemCss dropDownItemSubmit"
                        >
                          Make Changes
                        </Dropdown.Item>
                      )}

                      {props.storyData.status === 'Published' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleSubmitPublishClick(
                              props.storyData.id,
                              'UpdatePublished'
                            )
                          }
                          className="dropDownItemCss dropDownItemSubmit"
                        >
                          Edit Story
                        </Dropdown.Item>
                      )}

                      {props.storyData.status === 'Rejected' && (
                        <Dropdown.Item
                          onClick={() =>
                            props.handleSubmitPublishClick(
                              props.storyData.id,
                              'UpdateRejected'
                            )
                          }
                          className="dropDownItemCss dropDownItemSubmit"
                        >
                          Update
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </div>
            <StyleStoryList.StoryCreator>
              Created by {props.storyData.createdBy}
            </StyleStoryList.StoryCreator>

            <StyleStoryList.StoryCover
              storyCoverImage={props.storyData.seriesBackground}
            >
              <StyleStoryList.StoryStatusSpan>
                <StyleStoryList.StoryStatus>
                  {props.storyData.status}
                </StyleStoryList.StoryStatus>
              </StyleStoryList.StoryStatusSpan>
              <StyleStoryList.StoryStatusSpan>
                <StyleStoryList.StoryStatus>
                  {props.storyData.storyType === 'ShortStory'
                    ? 'Short story'
                    : 'Route story'}
                </StyleStoryList.StoryStatus>
              </StyleStoryList.StoryStatusSpan>
            </StyleStoryList.StoryCover>
            <div className="genersList">
              {props.storyData.genres.map(
                genres =>
                  // <StyleStoryList.StoryGeners key={genres.id}>
                  `${genres.name}  `
                // </StyleStoryList.StoryGeners>
              )}
            </div>
            <StyleStoryList.StoryActionButton
              onClick={() =>
                props.setStoryIdAction(
                  props.storyData.id,
                  props.storyData.status,
                  props.storyData.readOnly,
                  props.storyData.title,
                  props.storyData.viewOnly,
                  props.storyData.unityStoryId
                )
              }
            >
              <NavLink
                to={storyActionLink}
                className={`btn-snacktool-brown btn-snacktool`}
              >
                {storyButtonLabelName}
              </NavLink>
            </StyleStoryList.StoryActionButton>
          </div>
        </div>
      </StyleStoryList.StoryList>
    </div>
  );
};

export default StyleListing;

import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
  Overlay
} from 'react-bootstrap';
import * as AccoundDetail from './style/myaccount';
import {
  EMAIL_PATTERN_ACCOUNT,
  USER_NAME_PATTERN_MY_ACCOUNT
} from '../../common/helper/pattern';

const MyAccountSettings = props => {
  const handleSaveToggle = event => {
    props.toggleCharacterChange(props.authToken, props.toggleCheckbox);
    event.preventDefault();
  };

  const [validated, setValidated] = useState(true);
  return (
    <AccoundDetail.MyAccountDetail>
      <Form onSubmit={handleSaveToggle} validated={true} autocomplete="off">
        <Form.Row>
          <Form.Group controlId="formGridusername" className="col-md-5">
            <div className="d-flex mt-4">
              <label className="switch-flip">
                <input
                  type="checkbox"
                  name="reverseChapter"
                  key={`key_flip`}
                  className="checkbox-flip"
                  label="Show full name."
                  onChange={event =>
                    props.handleToggleCharacterSettings(props.toggleCheckbox)
                  }
                  // value={props.toggleCheckbox == 'true' ? true : false}
                  checked={props.toggleCheckbox == 'true' ? 'checked' : ''}
                />
                <span className="slider-flip round"></span>
              </label>
              <span className="label-text">
                Show full name
                <OverlayTrigger
                  key={`full-name-text`}
                  placement="top"
                  overlay={
                    <Tooltip id={`full-name-text`}>
                      Turn this setting on to show full names of characters for
                      all character commands in the command panel.
                    </Tooltip>
                  }
                >
                  <span className="info-icon ml-2"></span>
                </OverlayTrigger>
              </span>
            </div>
          </Form.Group>
        </Form.Row>
        <Button
          variant="primary"
          className="btn-snacktool btn-snacktool-golden btn btn-primary"
          type="submit"
          // onClick={event => props.handleSaveToggle(event)}
          //   disabled={disable}
        >
          Save
        </Button>
      </Form>
    </AccoundDetail.MyAccountDetail>
  );
};

export default MyAccountSettings;

export const FIRST_TAB_KEY = 'first';
export const SECOND_TAB_KEY = 'second';
export const THIRD_TAB_KEY = 'third';
export const FOURTH_TAB_KEY = 'fourth';
export const FIFTH_TAB_KEY = 'fifth';
export const SIXTH_TAB_KEY = 'sixth';
export const SEVENTH_TAB_KEY = 'seventh';



export const TAGS_PATTERN_MAX_5 = '^[0-9a-zA-Z]+(?:, [0-9a-zA-Z]+){0,4}$';

export const STORY_NAME_PATTERN = '[a-zA-Z0-9_- ]{3,50}';
export const STORY_DESCRIPTION_PATTERN = '[a-zA-Z0-9_-]{3,250}';
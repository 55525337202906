export const GET_BACKGROUND_IMAGES = 'GET_BACKGROUND_IMAGES';
export const GET_BACKGROUND_IMAGES_SUCCESS = 'GET_BACKGROUND_IMAGES_SUCCESS';
export const GET_BACKGROUND_IMAGES_FAIL = 'GET_BACKGROUND_IMAGES_FAIL';

export const SAVE_VIEW_LATER = 'SAVE_VIEW_LATER';
export const SAVE_VIEW_LATER_SUCCESS = 'SAVE_VIEW_LATER_SUCCESS';
export const SAVE_VIEW_LATER_FAIL = 'SAVE_VIEW_LATER_FAIL';

export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY';
export const SET_BACKGROUND_PROPERTIES = 'SET_BACKGROUND_PROPERTIES';
export const SET_RESET_BACKGROUND_PROPERTIES =
  'SET_RESET_BACKGROUND_PROPERTIES';

export const GET_VIEWS = 'GET_VIEWS';
export const GET_VIEWS_SUCCESS = 'GET_VIEWS_SUCCESS';
export const GET_VIEWS_FAIL = 'GET_VIEWS_FAIL';

export const GET_VIEWS_EDITABLE = 'GET_VIEWS_EDITABLE';
export const GET_VIEWS_EDITABLE_SUCCESS = 'GET_VIEWS_EDITABLE_SUCCESS';
export const GET_VIEWS_EDITABLE_FAIL = 'GET_VIEWS_EDITABLE_FAIL';
export const GET_VIEWS_COLLABORATOR_MODULE_FLAG =
  'GET_VIEWS_COLLABORATOR_MODULE_FLAG';

export const ADD_OBJECT = 'ADD_OBJECT';
export const SET_OBJECT = 'SET_OBJECT';
export const REORDER_OBJECT = 'REORDER_OBJECT';

export const SET_SAVE_VIEW_OBJECT = 'SET_SAVE_VIEW_OBJECT';
export const RESET_SAVE_VIEW_OBJECT = 'RESET_SAVE_VIEW_OBJECT';

export const SET_AUTOSAVE_FLAG = 'SET_AUTOSAVE_FLAG';
export const RESET_AUTOSAVE_FLAG = 'RESET_AUTOSAVE_FLAG';

export const GET_OTHER_CREATED_VIEWS = 'GET_OTHER_CREATED_VIEWS';
export const GET_OTHER_CREATED_VIEWS_SUCCESS =
  'GET_OTHER_CREATED_VIEWS_SUCCESS';
export const GET_OTHER_CREATED_VIEWS_FAIL = 'GET_OTHER_CREATED_VIEWS_FAIL';

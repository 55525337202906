import { endpoint } from '../../config/app.config';

export const postUserData = payloads => {
  if (payloads.isEditAccount === true) {
    return fetch(endpoint + `/api/users/${payloads.userId}`, {
      method: 'PUT',
      body: JSON.stringify(payloads.payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.token}`
      }
    })
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(`${endpoint}/api/users/register`, {
      method: 'POST',
      body: JSON.stringify(payloads.payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payloads.token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resp => {
        return resp;
      })
      .catch(err => {
        throw err;
      });
  }
};

export const deletetUserData = payloads => {
  return fetch(
    payloads.status === 'deactivated'
      ? `${endpoint}/api/users/${payloads.payload}/deactivate`
      : `${endpoint}/api/users/${payloads.payload}/reactivate`,
    {
      method: 'PATCH',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payloads.token
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const deletetAccountData = payloads => {
  return fetch(`${endpoint}/api/users/${payloads.payload}`, {
    method: 'DELETE',

    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + payloads.token
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const postLoginData = userInput => {
  return fetch(`${endpoint}/api/auth/token`, {
    method: 'POST',
    body: JSON.stringify(userInput),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getToggleUpdated = payload => {
  return fetch(`${endpoint}/api/settings`, {
    method: 'POST',
    body: JSON.stringify({ FULL_NAME_TOGGLE: payload.toggleCharacter }),
    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const setFontSetting = payload => {
  return fetch(`${endpoint}/api/settings`, {
    method: 'POST',
    body: JSON.stringify({
      FONT_SIZE: payload.fontSize,
      FONT_FAMILY: payload.font
    }),
    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const postRefreshTokenData = payload => {
  return fetch(`${endpoint}/api/token/refresh`, {
    method: 'POST',

    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const postInvite = (storyId, authToken) => {
  return fetch(`${endpoint}/api/stories/${storyId}/accept_invite`, {
    method: 'POST',
    body: JSON.stringify(storyId),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const logoutApi = logOutAuthToken => {
  return fetch(`${endpoint}/api/logout`, {
    method: 'POST',
    // body: JSON.stringify(storyId),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + logOutAuthToken
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const fetchTutorialData = payloads => {
  return fetch(`${endpoint}/api/onboarding`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads}`
    }
  })
    .then(resp => resp.json())
    .then(jsonresp => {
      return jsonresp;
    })
    .catch(err => {
      throw err;
    });
};

export const getTutorialData = payloads => {
  return fetch(`${endpoint}/api/onboarding`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.payloads}`
    }
  })
    .then(resp => resp.json())
    .then(jsonresp => {
      return jsonresp;
    })
    .catch(err => {
      throw err;
    });
};

export const postTutorialData = payload => {
  switch (payload.flag) {
    case 'firstLogin':
      payload.payload.firstLogin = true;
      break;
    case 'firstCharacterRoute':
      payload.payload.firstCharacterRoute = true;
      break;
    case 'firstChoiceCommand':
      payload.payload.firstChoiceCommand = true;
      break;
    case 'firstConditionalCommand':
      payload.payload.firstConditionalCommand = true;
      break;
    case 'firstVarWindow':
      payload.payload.firstVarWindow = true;
      break;
    case 'firstLibrary':
      payload.payload.firstLibrary = true;
      break;
    case 'firstTextEditor':
      payload.payload.firstTextEditor = true;
      break;
    case 'firstView':
      payload.payload.firstView = true;
      break;
    case 'All':
      payload.payload.firstChoiceCommand = false;
      payload.payload.firstConditionalCommand = false;
      payload.payload.firstLibrary = false;
      payload.payload.firstTextEditor = false;
      payload.payload.firstVarWindow = false;
      payload.payload.firstView = false;
      payload.payload.firstCharacterRoute = false;
      break;
    default:
      break;
  }

  ///api/onboarding/{id}
  return fetch(`${endpoint}/api/onboarding/${payload.payload.id}`, {
    method: 'PUT',
    body: JSON.stringify(payload.payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const updateMyAccountApi = payloads => {
  return fetch(`${endpoint}/api/users/user_details`, {
    method: 'PUT',
    body: JSON.stringify({
      username: payloads.username,
      email: payloads.email
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + payloads.authToken
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const changePasswordAccountApi = payloads => {
  return fetch(`${endpoint}/api/users/change_password`, {
    method: 'PUT',
    body: JSON.stringify({
      oldPassword: payloads.oldPassword,
      newPassword: payloads.newPassword,
      confirmdPassword: payloads.confirmPassword
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + payloads.authToken
    }
  })
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const fetchUserAccounts = payloads => {
  return fetch(`${endpoint}/api/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(resp => resp.json())
    .then(jsonresp => {
      return jsonresp.body;
    })
    .catch(err => {
      throw err;
    });
};

export const requestAccount = payload => {
  return fetch(`${endpoint}/api/account/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ emailId: payload.email })
  })
    .then(resp => resp.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const passwordRecovery = payload => {
  return fetch(`${endpoint}/api/account/send_otp`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ emailId: payload.email })
  })
    .then(resp => resp.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const deleteMyAccount = payload => {
  return fetch(
    `${endpoint}/api/users/delete_request?deleteStories=${payload.deleteStory}`,
    {
      method: 'PATCH',
      // body: JSON.stringify({ 'deleteStories': payload.deleteStory }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const changePassword = payload => {
  return fetch(
    `${endpoint}/api/account/recover_password?email=${encodeURIComponent(
      payload.email
    )}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
  )
    .then(resp => resp.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

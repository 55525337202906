import React, { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import * as Constant from './../../../common/constants';

function CheckBox({ component, commandId, handleChangeAddGenderedText, isCommandEdit, setGotoChapterRequired }) {
    let defaultDisabled = ''

    const [checkbox, setCheckbox] = useState(component.properties.defaultValue);
    const [changeGenderedText, setChangeGenderedText] = useState(false);

    switch (commandId) {
        case Constant.COMMAND_ADD_DIALOGUE:
            if (component.widgetName === 'genderedCheckbox'
                && changeGenderedText === false) {
                handleChangeAddGenderedText(component.properties.defaultValue);
            }
            break;
        case Constant.COMMAND_SET_VAR:
            if (component.properties.defaultValue === checkbox && isCommandEdit && component.widgetName === 'opCheckbox') {
                handleChangeAddGenderedText(component.properties.defaultValue);
            } else {
                defaultDisabled = ''
            }
            break;
        case Constant.COMMAND_GOTO_CHAPTER:
            if (changeGenderedText === false) {
                setGotoChapterRequired(component.properties.defaultValue);
            }
            break;
        default:
            break;
    }


    const handleChange = () => {
        let checkBoxVal = checkbox === 'false' ? 'true' : 'false';
        setCheckbox(checkBoxVal);

        switch (commandId) {
            case Constant.COMMAND_ADD_DIALOGUE:
                setChangeGenderedText(true);
                handleChangeAddGenderedText(checkBoxVal);
                break;
            case Constant.COMMAND_SET_VAR:
                if (component.widgetName === 'opCheckbox') {
                    handleChangeAddGenderedText(checkBoxVal);
                }
                break;
            case Constant.COMMAND_GOTO_CHAPTER:
                setGotoChapterRequired(checkBoxVal);
                setChangeGenderedText(true);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Form.Group controlId={component.widgetName} className="d-flex mb-0">

                <label className="switch-flip flex-item">

                    <input
                        type="checkbox"
                        key={component.widgetName}
                        name={component.widgetName}
                        className='checkbox-flip'
                        label={component.label}
                        onChange={handleChange}
                        value={checkbox}
                        disabled={defaultDisabled}
                        checked={(checkbox == true || checkbox == 'true') ? 'checked' : null}
                    />
                    <span className="slider-flip round"></span>
                </label>
                <label className="commandLabelSuffix flex-item">{component.label}</label>

            </Form.Group>
            {
                commandId === Constant.COMMAND_SET_VAR && component.widgetName === 'opCrossCheckbox' &&
                <div>
                    <label className='ml-5 commandSuffixSetVar mb-2'>{component.placeHolder}</label>
                </div>
            }
            {
                commandId === Constant.COMMAND_GOTO_CHAPTER && component.widgetName === 'opCheckbox' &&
                <div>
                    <label className='ml-5 commandNote mb-2'>{component.note}</label>
                </div>
            }
        </>
    )
}

export default CheckBox
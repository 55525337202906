import React from 'react';
import { CommentStyle } from './styles';
import { Row, Col } from 'react-bootstrap';

function CommentHead(props) {
   
    return (
        <CommentStyle.listHeading>
            <div className="mt-4 comment-header pl-4 pr-4">
            <Row>
                <Col xs={6}  className="comment-title">
                     {props.commentFilter===true && props.filterList.length>0 ?`Comment ${props.commentNumber+1} of ${props.filterList.length}`:''}
                     {props.commentFilter===false?'All comments in this chapter':''}
                </Col>
                <Col xs={6} className="text-right filter-wrapper">
                 
                    {/* <button className={`filter-button  ${props.filters.image ? `filter-button-active` : null}`}
                        onClick = {(event) => props.handleFilterClick(event)} name="image">Unresolved</button>
                    <button className={`filter-button  ${props.filters.audio ? `filter-button-active` : null}`}
                        onClick = {props.handleFilterClick} name="audio">All Comments</button>
                     */}
                     
                     <div className="btn-wrapper ">
                     <button className={`filter-button  ${props.commentFilter==true ? `filter-button-active` : null}`}
                        onClick = {() => props.handleFilterClick(true)} name="image">Unresolved</button>
                    <button className={`filter-button  ${props.commentFilter==false ? `filter-button-active` : null}`}
                          onClick = {() => props.handleFilterClick(false)} name="audio">All Comments</button>
                    </div>
                </Col>
            </Row>
            </div>
        </CommentStyle.listHeading>
    );
}

export default CommentHead
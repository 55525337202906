import React from 'react';
import { DeleteStyle} from './styles/modalstyles';
import { Row,Button,Modal, Col} from 'react-bootstrap';

function  InviteExpireModal(props) {
  

  return (
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={props.show}
    onHide={props.onHideExpiredModal}
    >
      <Modal.Body  >
        
      <DeleteStyle.addAssetMessageBox>
            <DeleteStyle.successAssetContent>
                <Row>
                    <Col className="fail-icon">
                    <img src="/images/icons/ic_clear_24px.svg" className="mediumIcon"/>

                    </Col>
                </Row>
               
                <Row>
                    <Col className="submit-asset-label">
                    {props.invitationExpired===true && `Sorry, this invitation has expired.`}
                    {props.invitationCancel===true && `Sorry, your invitation to access this story has been canceled`}
                    </Col>
                </Row>
                <Row>
                    <Col className="submit-asset-label">
                    <Button data-testid="success-btn"  onClick={props.onHideExpiredModal}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
                    </Col>
                </Row>
            </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );

  } 

export default InviteExpireModal
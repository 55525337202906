import styled from 'styled-components';
import { colors } from './../../../common/styles/variables';

export const TabLabel = styled.label`
  letter-spacing: 0;
  color: ${colors.Very_dark_grayish_blue};
  opacity: 1;
`;

const addInfoContainer = styled.div`
.label-title, .bold-text{
    font-family: PT Sans;
    font-size:14px;
    font-weight:bold;
}
.normal-text{
    font-family: PT Sans;
    font-size:14px;
    font-weight:regular;
    color:#707070;
}

.commandlabelsuffixDiv{
        align-items: center;
        display: flex;
        margin-left: 8px;
      }
      .commandLabelSuffix {
        font-weight:normal;
        font-size : 14px;
        line-height: 19px
        letter-spacing: 0;
        color: ${colors.shades_shade500};
        // color: #FFF;
        opacity: 1;
        padding-left : 5px;
      }
      
      .switch-flip {
        position: relative;
        display: inline-block;
        height: 20px;
        margin-right: 25px;
      }
      
      .switch-flip input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider-flip {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 40px;
      }
      
      .slider-flip:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider-flip {
        background-color: #2196F3;
      }
      
     

      input:focus + .slider-flip {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider-flip:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
      
      /* Rounded sliders */
      .slider-flip.round {
        border-radius: 34px;
      }
      
      .slider-flip.round:before {
        border-radius: 50%;
      }
      
       .bgDisable{
        background-color: #B0E2FF !important;
        cursor: unset;
       }
       .bgUncheckDisable{
        background-color: grey !important;
        cursor: unset;
       }
    .add-info-head-title {
        font-size: 20px;
        color: ${colors.very_dark_gray};
        margin:30px 0 15px 0;
    }

    .add-info-label {
        font-size: 14px;
        color: ${colors.very_dark_gray};
        font-weight: bold;
    }

    .add-info-note {
        font-size: 12px;
        color: ${colors.gray44};
    }
    .container{
        div:first-child{
            &:focus {
            outline: 0;
            box-shadow: none;
            border:2px solid #157FFB !important;
            border-radius:4px;
            }
        }
        
    }
    .form-control:disabled{
        background-color:#e9ecef !important;
        cursor:pointer;
        opacity: 1;
    }
`;

const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
`;

const submitAssetContent = styled.div`
  width: 100%;
  margin-top: 20%;
  text-align: center;
`;

const successAssetContent = styled.div`
  width: 100%;
  margin-top: 25%;
  text-align: center;

  .submit-asset-label-sucess {
    font-size: 24px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
    text-align: center !important;
  }

  .submit-asset-label {
    font-size: 24px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
    text-align: left !important;
  }

  .submit-asset-label-text-center {
    text-align: center !important;
  }

  .text-note {
    font-size: 14px;
    color: #707070;
  }
`;

const tabLayout = styled.div`
  display: FLEX;
  width: 100%;
  justify-content: center;
`;

const leftTabLabelLayout = styled.div`
  width: 85%;
  display: flex;
  position: relative;
`;

const rightTabLabelLayout = styled.div`
  width: 45%;
  display: flex;
  position: relative;
  &:last-child {
    width: 10%;
  }
`;

const leftTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rightTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
`;

const leftTabLine = styled.div`
  width: 93%;
  height: 3px;
  background: #157ffb;
  position: absolute;
  left: 48px;
  top: 15px;
  z-index: 0;
`;

const rightTabLine = styled.div``;

const firstCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #4d4f5c;
  text-align: center;
  margin-top: 5px;
`;

const secondCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  color: #4d4f5c;
  margin-top: 5px;
`;
const listContentPanel = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 160px);
  margin-left: -6px;
  // margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  background: ${colors.color_white};
  box-shadow: 0px 0px 10px ${colors.dark_red};
  margin-bottom: 5px;
  height: calc(100vh - 90px);
  .add-info-modal-body {
    padding-right: 56px;
    padding-left: 56px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

// left: 234px;
//     width: 100%;
//     opacity: 1;
//     overflow-y: auto;
//     overflow-x: hidden;
//     max-height: 800px;
//     background: #fff;
//     opacity: 1;
//     display: inline-block;
//     width: 100%;

const listContentPanelInterface = styled.div`
  height: calc(100vh - 160px);
  margin-left: -6px;
  // margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  background: ${colors.color_white};
  box-shadow: 0px 0px 10px ${colors.dark_red};
  margin-bottom: 5px;
  height: calc(100vh - 90px);
  .add-info-modal-body {
    padding-right: 56px;
    padding-left: 56px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

const listHeading = styled.div`
  padding: 0px 30px 0px 25px;
  font-size: 24px;
  width: 100%;
  text-align: left;
  letter-spacing: 0;
  color: ${colors.very_dark_gray};
  border-bottom: 1px solid ${colors.secondary_gray100};
  line-height: 60px;
  border-radius: 6px 6px 0px 0px;
  background-color: ${colors.color_white};

  .filter-button {
    background: ${colors.shades_shade200} 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    text-align: center;
    font: Roboto;
    font-size: 12px;
    letter-spacing: 0.22px;
    color: ${colors.very_pale_yellow};
    width: auto;
    float: right;
    cursor: pointer;
    line-height: 20px;
    border: ${colors.shades_shade200};
    margin-right: 6px;
  }

  .filter-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .filter-label {
    color: #606060;
    text-transform: uppercase;
    opacity: 1;
    font-size: 11px;
    letter-spacing: 1.1px;
    font-size: 11px;
    margin-bottom: 0;
    margin-right: 8px;
  }

  .filter-button-active {
    background-color: ${colors.strong_violet};
    border: ${colors.strong_violet};
  }

  .cancel-create-character-icon {
    color: ${colors.medium_light_gray};
    opacity: 1;
    float: left;
    cursor: pointer;
    border-right: 1px solid ${colors.secondary_gray100};
  }

  .panel-header {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0 -20px;
    .back-arrow {
      border-right: 1px solid #e3e3e3;
      display: flex;
      height: 100%;
      align-items: center;
      height: 60px;
      padding: 0 24px;
      margin-right: 16px;

      cursor:pointer .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
    }
    .story-heading {
      width: 100%;
    }
    .sceneName {
      text-align: left;
      font-size: 32px;
      line-height: 27px;
      letter-spacing: 0;
      color: ${colors.color_black};
      opacity: 1;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const listMainSection = styled.div`
  left: 234px;
  width: 100%;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 800px;

  .small-container {
    height: calc(100vh - 480px);
  }
  .big-container {
    height: calc(100vh - 160px);
  }

  .library_list {
    width: 790px;
    background-color: white;
  }

  .button {
    background: ${colors.color_white};
    border: 2px solid ${colors.primary_green};
    border-radius: 40px;
    opacity: 1;
    color: ${colors.primary_green};
    font-family: Robot;
    font-size: 16px;
    text-align: center;
    margin-right: 20px;
  }

  .button-de-active {
    background: ${colors.shades_shade200};
    border-radius: 40px;
    opacity: 1;
    border: ${colors.shades_shade200};
    padding: 12px 30px 12px 30px;
    text-align: center;
    font: Bold 14px/16px Roboto;
    letter-spacing: 0;
    color: ${colors.color_white};
    opacity: 1;
    margin-right: 20px;
  }

  .content-head-row {
    padding: 40px 15px 25px 15px !important;
    .btn-snacktool {
      margin-right: 15px;
    }
  }

  .right-text {
    text-align: right;
    font-size: 14px/19px;
    letter-spacing: 0;
    color: ${colors.gray44};
    opacity: 0.5;
    padding-top: 6px;
  }
`;

const listTable = styled.div`
    
    text-align: center;
    margin-left: auto;
    background: white !important;
    width: 100%;
    .table_library{
        background-color:white;
        margin-left: -27%;
        width: 790px;
        margin-top: 5% !important;
    }

    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 12px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.sprite-layers-rows {
        height: 235px;
        overflow-y: auto;
    }

    .sprite-row{
        padding: 12px  12px  12px 0px;
        cursor:pointer
        display:flex;
        justify-content:space-between;
        align-items: center;
        .asset-details{
            display: flex;
            align-items: center;
            .fa-bars{
                color:#ACACAC;
                font-size:13px;
            }
            .asset-name{
                margin-left:16px;
                color:#303030;
                font-family:PT Sans;
                font-size:14px
            }
        }
        .sprite-row-name{
            color:#303030;
            font-size:13px;
        }
    }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 50px;
height: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

    .table-head {
        background-color: ${colors.color_white};
        text-align: center;
        font-size: 11px/14px;
        letter-spacing: 1.1px;
        color: ${colors.scorpion_gray};
        text-transform: uppercase;
        opacity: 1;
        top: 0;
    }

    .table-head-row{
        background-color: ${colors.color_white};      
    }

    .table-head-row th {
        border-top: ${colors.secondary_gray100};
        border-bottom: ${colors.secondary_gray100};
        font-size: 14px;
        color: ${colors.scorpion_gray};
        text-transform: uppercase;
        letter-spacing: 1.1px;
        opacity: 1;
        font-weight: normal;
        vertical-align: unset;
        margin: 0;
        width:auto;
    }


    .padding-left-40{
        padding-left:40px;
    }

    input[type="checkbox"] {
        display: none;
    }
    
    /* The checkboxContainer */
    .checkboxContainer {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      
    }
    
    /* Hide the browser's default checkbox */
    .checkboxContainer input {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background: ${colors.color_white} ;
        border: 2px solid ${colors.shades_shade200};
        border-radius: 4px;
    }
    
    span.disable {
        background: ${colors.shades_shade200};
        border: ${colors.shades_shade200};
    }
    
    /* On mouse-over, add a grey background color */
    .checkmark .disable:hover ~ .checkmark {
        background-color: ${colors.shades_shade200} !important;
    }
    .enabled:hover {
        border: 2px solid ${colors.light_blue};
    }
    
    /* When the checkbox is checked, add a blue background */
    .checkboxContainer input:checked ~ .checkmark {
      background-color: ${colors.vivid_blue};
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    .checkboxContainer input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .checkboxContainer .checkmark:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .table-head-col {
        width: 4%;
    }

    .table-body-row td {
        font-size: 14px;
        letter-spacing: 0;
        color: ${colors.very_dark_gray};
        opacity: 1;
        font-weight: normal; 
        vertical-align: middle;
        margin: 0;
    }

    .variation-name{
        word-break: break-all;
    }

    .shared-asset-active {
        color: ${colors.gray44};
    }

    .shared-asset-in-active {
        color: ${colors.shades_shade200};
    }

    .badges{
        background-color: ${colors.shades_shade200};
        border-radius: 46px;
        opacity: 1;
        text-align: center;
        font: Regular 12px/14px Roboto;
        letter-spacing: 0.22px;
        opacity: 1;
        font-weight: normal;
        font-size: 14px;
    }
    .light-badge{
        background: #DFDFDF !important;
        border-radius: 45px !important;
        padding: 7px 13px !important;
        color: rgba(0, 0, 0, 0.67) !important;
        font-size: 11px !important;
        font-family: Roboto !important;
      }

    .text {
        color: ${colors.medium_light_gray} !important;
        opacity: 1;
        font-size: 15px;
        text-align: center;
        font-size: 10px;
    }

    .action-icons {
        font-size: 20px !important;
        padding-right: 15px;
    }

    .action-icon-active {
        color: ${colors.strong_violet};
    }

    .action-icon-in-active {
        color: ${colors.gray44};
    }

    .asset-name-col {
        width: 30%;
        text-align: left;
        word-break: break-all;
    }

    .asset-upload-name-col {
        width: 40%;
        text-align: left;
    }
    .margin-28{
      margin-top:28px;
    }
    .margin-50{
      margin-top:50px;
    }

    .asset-upload-name-char-col {
        width: 37%;
        text-align: left;
    }
    .variations-action-icons {
        height: 16px;
        width: 16px;
    }
    .no-result {
        background:#F5F5F5;
        margin-top: 154px;
        h3 {
            font-size:24px;
            letter-spacing: 0px;
            color: #303030;
            padding:48px 0;
        }
    }
`;

const libraryMenu = styled.div`
  display: flex;
  width: 232px;
  float: left;
  // margin-top: 60px;
`;

const libraryMenuList = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0px;
`;

const libraryMenuListItem = styled.li`
  height: 60px;
  padding-top: 12px;
  v-align: center;
  background-color: ${props => (props.selectedMenu === true ? '#E6E4E4' : '')};
  padding-left: 15px;
  ${props =>
    props.selectedMenu === true
      ? `border-right: 5px solid ${colors.strong_violet}`
      : null}

  .menu-icon {
    padding-right: 15px;
  }

  .menu-disabled {
    color: ${colors.gray44} !important;
    text-decoration: none !important;
    img {
      height: 24px;
    }
  }

  .menu-enabled {
    color: ${colors.strong_violet} !important;
    text-decoration: none !important;
    img {
      height: 24px;
    }
  }
`;

const characterContent = styled.div`
  .content-head {
    padding: 27px 0 22px 0 !important;
    border-bottom: 1px solid ${colors.secondary_gray100};
    font-size: 15px;
  }
  .left-title {
    font-size: 24px;
  }

  .btn-primary.disabled {
    background-color: ${colors.shades_shade200} !important;
    border: ${colors.shades_shade200} !important;
    pointer-events: none !important;
  }

  .character_list {
    // margin-left: -35% !important;
    width: 790px !important;
    background-color: white;
  }

  .col-title {
    v-align: center;
    font: 16px/20px;
    font-weight: bold;
  }

  .input-error {
    border: 1px solid red !important;
  }

  .padding-left-3 {
    padding-left: 15px !important;
  }

  .step-three-col-title {
    font-size: 13px;
    font-weight: bold;
    color: #212529;
    text-transform: uppercase;
  }

  .menu-nav {
    text-decoration: none;
    padding-right: 20px;
  }

  .search-text-field {
    float: left;
    margin-right: 11px !important;
    padding: 10px !important;
    width: 250px !important;
    height: 40px !important;
    background: ${colors.color_white};
    border: 2px solid ${colors.light_grey};
    border-radius: 4px;
  }

  .search-text-field::placeholder {
    font-size: 13px;
    color: ${colors.shades_shade200};
  }

  .row-count {
    height: 80px;
    v-align: center;
    line-height: 80px !important;
    font-size: 14px;
    letter-spacing: 0;
    color: ${colors.gray44};
  }

  .list-head-row {
    height: 40px;
    line-height: 40px !important;
    border-bottom: 1px solid ${colors.light_grey};
    opacity: 1 !important;
  }

  .list-col {
    font-size: 14px;
    color: ${colors.scorpion_gray};
    letter-spacing: 1.1px;
  }

  .file-name {
    color: ${colors.very_dark_gray};
  }

  .file-info {
    text-align: right;
    font-size: 10px;
    letter-spacing: 0;
    color: ${colors.shades_shade200};
  }

  .list-asset-table {
    height: 650px;
    overflow-x: auto;
  }

  .fixed-row {
    position: sticky;
    top: 0;
    background: ${colors.secondary_gray100};
  }

  .finish-button {
    font-size: 15px;
  }

  .info-label {
    font-size: 14px;
  }
  .back-to-step {
    // color: #45c7ad;
    color: #E6A554;

    font-weight: bold;
    font-size: 14px;
    font-family: Roboto;
    letter-spacing: 0px;
  }
  input[type='text']::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  input[type='text']::-moz-placeholder {
    color: #bfbfbf;
  }
  input[type='text']:-ms-input-placeholder {
    color: #bfbfbf;
  }
  input[type='text']::placeholder {
    color: #bfbfbf;
  }

  .scroll-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 160px);
  }
`;

const characterComposer = styled.div`
    width: 350px;
    text-align: center;

    .character-composer-block {
        text-align: center !important;
        background-color: ${colors.secondary_gray100} !important;
        padding:20px;
    }

    .character-composer-block-custom{
      text-align: center !important;
    background: url(/images/transparent-pattern-bg.svg) repeat;
    background-size: 5%;
    border-radius: 3px;
    border: 1px solid #000 !important;
    padding: 20px 20px 0 20px !important;

    }
    .character-composer-frame {
        height: 295px;
        width: 295px;
        margin:0px;
        border: 1px solid black;
        position: relative;
        overflow: hidden;
    }

    .character-composer-frame-custom {
        height: 295px;
        width: 295px;
        margin:0px;
        
        position: relative;
    }

    .empty-row {
        height: 20px;
    }

    .frame-image {
        position: absolute;
        left: 0;
    }

    .sprite-row{
        padding: 12px  12px  12px 0px;
        cursor:pointer
        display:flex;
        justify-content:space-between;
        align-items: center;
        .asset-details{
            display: flex;
            align-items: center;
            .fa-bars{
                color:#ACACAC;
                font-size:13px;
            }
            .asset-name{
                margin-left:16px;
                color:#303030;
                font-family:PT Sans;
                font-size:14px
            }
        }
        .sprite-row-name{
            color:#303030;
            font-size:13px;
        }
    }

    .btn-save-variation {
        position: relative;
        bottom: 21px;
        left: 15px;
    }
`;

const spriteLayer = styled.div`
  .enabled {
    opacity: 1;
  }
  .disabled {
    opacity: 0.15;
  }
  .bars {
    color: ${colors.medium_light_gray};
  }
  .sprite-layer-text {
    font-size: 11px;
    text-transform: uppercase;
    color: #606060;
    padding-bottom: 10px;
  }
  .sprite-row-name {
    font-size: 14px;
  }
  .sprite-layers-rows {
    height: 235px;
    overflow-y: auto;
  }
`;

const listPanel = styled.div`
  background: #fff;
  opacity: 1;
  display: inline-block;
  width: 100%;

  left: 234px;

  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;

  max-height: 800px;
  background: #fff;
  opacity: 1;

  .scroll-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 160px);
  }
`;
const listHeader = styled.div`
  padding: 15px 0 40px 0 !important;
`;
const listContainer = styled.div`
  margin: 0 9px;
  font-family: PT Sans;
  h3 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #303030;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-left: 5px;
  }
  .moreInfoLink {
    color: #157ffb;
    cursor: pointer;
  }

  .font-15 {
    font-size: 15px !important;
  }
`;
const tableContainer = styled.table`
    width: 100%;
    margin-bottom:20px;
    .checkbox-box{
        padding:10px;
        .checkboxContainer{
            margin:0;
        }
    }
    .icon-box{

    }
    .show {
        display: block;
    }
    .hide {
        display: none;
    }
    thead{
        background: #F5F5FA 0% 0% no-repeat padding-box;
        border-radius: 4px 4px 0px 0px;
        th{
            text-align: center;
            font-family:PT Sans;
            font-size:11px
            color: #606060;
            text-transform: uppercase;
            padding: .75rem;
            font-weight:normal;
            .fa{
                vertical-align: top;
                color:#707070;
                margin-left:6px;
                font-size:12px;
            }
        }
       

    }

    tbody{
        tr{
            border-bottom:1px solid #D6D6D6;
            td{
                text-align: center;
                font-family:PT Sans;
                font-size:14px
                letter-spacing: 0;
                color: #707070;
                padding: .75rem;
                .fa-angle-down{
                    color:#707070;
                    font-size:20px;
                    cursor:pointer;
                }
                .create-details{
                    font-size:10px;
                    color:#BFBFBF;
                    span{
                        line-height:10px;
                        margin-top:4px;
                    }
                }
                .fa-users{
                    font-size:14px;
                    color:#707070;
                    margin-left: 5px;
                }
            
            }
            .text-bold-dark{
                color:#303030;
                font-weight: bold;
            }
            .text-dark{
                color:#303030;
            }
            .width-30{
                width:30% !important;
            }
            .width-20{
                width:20% !important;
            }
            .over-text{
              max-width: 200px;
    /* max-width: 100px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
            }
            .text-small-light{
                color:#ACACAC;
                font-size:12px;
            }
            .disabled{
                color:#BFBFBF !important;
            }
            .actions{
                img{
                    margin: 0 5px;
                }
            }
            .shared-asset-active {
                color: ${colors.gray44} !important;
            }
        
            .shared-asset-in-active {
                color: ${colors.shades_shade200} !important;
            }
        }
        .exanded-row-wrapper{
            .exanded-row-block{
                display: flex;
                align-items: flex-start;
                .choose-expression-block{
                    position:relative;
                    display: flex;
                    flex-direction: column;
                    width: 150px;
                    border: 1px solid #707070;
                    background:#F5F5FA;
                    color:#707070;
                    padding: 0 25px;
                    justify-content: center;
                    height: 150px;
                    font-size:12px;
                    padding-bottom: 2px;
                    .fa-eye{
                        font-size: 23px;
                        color: #BFBFBF;
                    }
                }
                .list-preview-img {
                    position: absolute;
                    left: 0;
                }
                .select-block{
                    display: flex;
                    margin-left:60px;
                    width: 50%;
                    .custom-select-dropdown{
                        margin-right:30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                        select{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;

const addAssetConfirmBox = styled.div`
  display: flex;
  justify-content: center;

  .btn-okay {
    margin-top: 20%;
    width: 120px;
    height: 40px;
  }

  .submit-asset-label {
    font-size: 24px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
  }

  .delete-asset-label {
    font-size: 21px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
  }

  .text-note {
    font-size: 14px;
    color: #707070;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const confirmAssetContent = styled.div`
  width: 100%;
  margin-top: 8%;
  text-align: center;
`;

const gameFonts = styled.div`
  margin: 20px 0 50px;
  .card {
    background: #f5f5f5;
    margin-right: 48px;
    height: 176px;
    overflow: scroll;
    &:last-child {
      margin-right: 0px;
    }
    &:first-child {
      margin-left: 5px;
    }
    .custom-select-dropdown {
      margin: 16px 16px 24px;
    }
    .card-text {
      bottom: 0;
      left: 0;
      width: 100%;
      .dialogue-text {
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 14px;
        padding: 23px 20px 17px 20px;
        position: relative;

        .continue-img {
          background: url(/images/game_interface/continue.png) no-repeat;
          background-size: 100% 100%;
          width: 10px;
          height: 18px;
          position: absolute;
          bottom: 11px;
          right: 9px;
        }
      }

      .badge {
        width: 70px;
        height: 22px;
        background: #bfbfbf;
        margin-top: -11px;
        margin-left: 125px;
        position: absolute;
        z-index: 1;
        color: #303030;
        font: Regular 12px/14px Roboto;
        text-align: center;
        padding-top: 3px;
        font-size: 12px;
      }

      .single-line-text {
        padding-bottom: 30px;
      }
      .button-label {
        background: url(/images/game_interface/btn_option_new.png) center center
          no-repeat;
        height: 50px;
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .asra-img {
        background: url(/images/game_interface/bx_dialog.png) no-repeat;
        background-size: 100% 100%;
        height: 72px;
        padding-left: 6px;
      }
    }
  }
  .dialogue-text {
    font-family: ${props => props.fonts.dialogueText.toLowerCase()}, PT Sans !important;
  }
  .character-name-plate-text {
    font-family: ${props => props.fonts.characterNamePlate.toLowerCase()},
      PT Sans !important;
    font-size: 16px !important;
  }
  .button-label-text {
    font-family: ${props => props.fonts.buttonLabel.toLowerCase()}, PT Sans !important;
    font-size: 20px !important;
  }
`;

export const LibraryStyle = {
  addInfoContainer,
  addAssetMessageBox,
  submitAssetContent,
  successAssetContent,
  tabLayout,
  leftTabLabelLayout,
  rightTabLabelLayout,
  leftTabLabelContent,
  rightTabLabelContent,
  leftTabLine,
  rightTabLine,
  firstCircleContent,
  secondCircleContent,
  listContentPanel,
  listContentPanelInterface,
  listHeading,
  listMainSection,
  listTable,
  libraryMenu,
  libraryMenuList,
  libraryMenuListItem,
  characterContent,
  characterComposer,
  spriteLayer,
  listPanel,
  listHeader,
  listContainer,
  tableContainer,
  gameFonts,
  addAssetConfirmBox,
  confirmAssetContent
};

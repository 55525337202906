import React from 'react';
import { Modal,Row,Col,Button } from 'react-bootstrap';
import { DeleteStyle} from './style';

function DeleteConfirm(props) {
  return (
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={props.showModal}
    onHide={props.handleHideDeleteModal}
    >
      <Modal.Body  >
        
        <DeleteStyle.ConfirmBox> 
                    <DeleteStyle.confirmContent>
        <Row>
          <Col className="fail-icon">
          <img alt="" className="mediumIcon" src="/images/icons/ic_warning_24px.svg"/>
          </Col>
        </Row>
        <Row>
          <Col className="submit-asset-label" data-testid="first-label">
            Are you sure want to delete this
          </Col>
        </Row>
        <Row>
          <Col className="submit-asset-label" data-testid="second-label">
          cover image?
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
          <Button
              className="btn-snacktool btn-snacktool-golden"
              onClick={props.handleHideDeleteModal}
            >
              Cancel
              </Button>
          </Col>
          <Col>
            <Button
              onClick={props.handleDeleteAsset}
              className="btn-snacktool btn-snacktool-golden btn btn-primary"
            >
             Yes,delete it
            </Button>
          </Col>
        </Row>
        </DeleteStyle.confirmContent></DeleteStyle.ConfirmBox>
      </Modal.Body>
    </Modal>
  );
}
export default DeleteConfirm;
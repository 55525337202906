import React from 'react';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import FlowchartPanel from './flowchart-panel';
import PreviewPanel from './preview-panel';
import * as TabStyle from './style-story';

function StoryRightPanel({
  backgroundColor,
  flowchartToggle,
  sceneColor,
  authToken,
  storyId,
  showingSceneFlag,
  flowchartNode,
  flowchartConnector,
  currentSceneId,
  fetchFlowchart,
  handleFlowchartView,

  // parameters for preview player
  commandsTimeoutIds,
  setCommandsTimeoutIds,
  handlePlayerButtonsClick,
  blocks,
  sceneId,
  play,
  setPlay,
  firstPlay,
  lastPlay,
  nextPlay,
  prevPlay,
  gotoPlay,
  setGotoPlay,
  currentBlock,
  setCurrentBlock,
  currentCommand,
  setCurrentCommand,
  currentScene,
  setCurrentScene,
  handleDialogueDisplayEvent,
  previewTitle,
  showSpinner,
  fastPreview,
  setFastPreview,
  checkCommandState,
  setCheckCommandState,
  handleDecisionClick,
  setShowDecision,
  showDecision,
  decisionParameters,
  setDecisionParameters,
  ifElseDecisionParameters,
  setIfElseDecisionParameters,
  showEnd,
  setShowEnd,
  loading,
  blobImages,
  loadImages,
  choiceTimeout,
  setChoiceTimeout,
  fetchPreviewDataOnClickPreview,
  lastView,
  setLastView,
  showDialogue,
  setShowDialogue,
  views,
  setPlayFromStart,
  showNameDialogue,
  setShowNameDialogue,
  setDialogueVariables,
  externalPlayPauseHandler,
  aspectRatio,
  setAspectRatio,
  variableListDTO,
  interfaceList,
  preloadMessage,
  activeTabSelected,
  setActiveTabSelected,
  localVariableListDTO,
  variableWindoOnOffStatus,
  consoleMessage,
  setConsoleMessage,
  updateVariableWithId,
  changeCursorPosition,
  handleNodeClickforFlowchartNode,
  gameFonts,
  previewScroll,
  setPreviewScroll,
  setExternalPlayPauseHandler,
  resetPlayerState,
  setExecutedCommand,
  executedCommand,
  flowchartHasErrors,
  showScriptError,
  textEditorValidate,
  setShowScriptError,
  isViewStory,
  commandListForScriptEditor,
  tokenDataUser,
  setCommand,
  setExtendFlowchart,
  levelFlowchart,
  fetchBlockchart,
  resetFlowchart,
  flowchartJson,
  storeFlowchartJson,
  setHighlightBlock,
  highlightBlock,
  changeCheckBoxHighlightBlock,
  highlightBlockIndex,
  setStartFromHighlightBlock,
  startFromHighlightBlock,
  setUpdatedFlowchartJson,
  showFlowchart,
  setShowFlowchart,
  hideIfVisitedArray,
  loadingPreview
}) {
  let disabledTab =
    blocks === null && variableWindoOnOffStatus == false ? 'disabled' : '';
  const onClickTab = onclickEvent => {
    resetPlayerState();
    if (onclickEvent.target.text === 'Preview') {
      fetchPreviewDataOnClickPreview();
      setPreviewScroll(true);
      setActiveTabSelected('Preview');
    } else {
      if (
        activeTabSelected === 'Preview' &&
        onclickEvent.target.text !== 'Preview'
      ) {
        resetFlowchart();
        setPreviewScroll(false);
        fetchBlockchart(authToken, storyId, currentSceneId);
      }
      setActiveTabSelected('Flowchart');
    }
  };

  return (
    <div>
      <TabStyle.StoryRightPanelTab className="step-flowchart">
        <Tabs
          className="right-panel-tabs step-flowchart"
          activeKey={activeTabSelected}
          onClick={event => onClickTab(event)}
        >
          <Tab
            eventKey="Flowchart"
            title={
              <div>
                <span className="mr-3 vertical-align-middle">Flowchart</span>
                <img
                  src="/images/icons/open_external.svg"
                  className="checkIcon"
                  onClick={() =>
                    activeTabSelected !== 'Preview'
                      ? setExtendFlowchart(true)
                      : ''
                  }
                />
              </div>
            }
          >
            {showFlowchart === true ? (
              <>
                <div>
                  <button
                    onClick={() => setShowFlowchart(false)}
                    className="btn-snacktool-golden btn-snacktool"
                    style={{ marginTop: '5%', marginLeft: '25%' }}
                  >
                    Hide Flowchart
                  </button>
                  {/* <img
                    src="../../images/new_image/Hide_Flowchart_2.png"
                    onClick={() => setShowFlowchart(false)}
                    style={{ marginTop: '5%', marginLeft: '25%' }}
                  /> */}
                </div>
                <FlowchartPanel
                  backgroundColor={backgroundColor}
                  flowchartToggle={flowchartToggle}
                  sceneColor={sceneColor}
                  authToken={authToken}
                  storyId={storyId}
                  showingSceneFlag={showingSceneFlag}
                  flowchartNode={flowchartNode}
                  flowchartConnector={flowchartConnector}
                  currentSceneId={currentSceneId}
                  fetchFlowchart={fetchFlowchart}
                  handleFlowchartView={handleFlowchartView}
                  handleNodeClickforFlowchartNode={
                    handleNodeClickforFlowchartNode
                  }
                  levelFlowchart={levelFlowchart}
                  flowchartHasErrors={flowchartHasErrors}
                  commandListForScriptEditor={commandListForScriptEditor}
                  flowchartJson={flowchartJson}
                  storeFlowchartJson={storeFlowchartJson}
                  setUpdatedFlowchartJson={setUpdatedFlowchartJson}
                  showFlowchart={showFlowchart}
                  setShowFlowchart={setShowFlowchart}
                />
              </>
            ) : (
              <div>
                <button
                  onClick={() => setShowFlowchart(true)}
                  className="btn-snacktool-golden btn-snacktool"
                  style={{ marginTop: '50%', marginLeft: '25%' }}
                >
                  Show Flowchart
                </button>
              </div>
            )}
          </Tab>
          <Tab eventKey="Preview" title="Preview">
            {loadingPreview === false ? (
              <PreviewPanel
                blocks={blocks}
                commandsTimeoutIds={commandsTimeoutIds}
                setCommandsTimeoutIds={setCommandsTimeoutIds}
                play={play}
                firstPlay={firstPlay}
                lastPlay={lastPlay}
                prevPlay={prevPlay}
                nextPlay={nextPlay}
                gotoPlay={gotoPlay}
                setGotoPlay={setGotoPlay}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
                currentCommand={currentCommand}
                setCurrentCommand={setCurrentCommand}
                currentScene={currentScene}
                setCurrentScene={setCurrentScene}
                handleDialogueDisplayEvent={handleDialogueDisplayEvent}
                previewTitle={previewTitle}
                fastPreview={fastPreview}
                setFastPreview={setFastPreview}
                checkCommandState={checkCommandState}
                setCheckCommandState={setCheckCommandState}
                setPlay={setPlay}
                handlePlayerButtonsClick={handlePlayerButtonsClick}
                handleDecisionClick={handleDecisionClick}
                setShowDecision={setShowDecision}
                showDecision={showDecision}
                decisionParameters={decisionParameters}
                setDecisionParameters={setDecisionParameters}
                ifElseDecisionParameters={ifElseDecisionParameters}
                setIfElseDecisionParameters={setIfElseDecisionParameters}
                showEnd={showEnd}
                setShowEnd={setShowEnd}
                blobImages={blobImages}
                loading={loading}
                sceneId={currentSceneId}
                choiceTimeout={choiceTimeout}
                setChoiceTimeout={setChoiceTimeout}
                lastView={lastView}
                setLastView={setLastView}
                showDialogue={showDialogue}
                setShowDialogue={setShowDialogue}
                views={views}
                setPlayFromStart={setPlayFromStart}
                showNameDialogue={showNameDialogue}
                setShowNameDialogue={setShowNameDialogue}
                setDialogueVariables={setDialogueVariables}
                externalPlayPauseHandler={externalPlayPauseHandler}
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                fetchPreviewDataOnClickPreview={fetchPreviewDataOnClickPreview}
                variableListDTO={variableListDTO}
                interfaceList={interfaceList}
                preloadMessage={preloadMessage}
                localVariableListDTO={localVariableListDTO}
                consoleMessage={consoleMessage}
                setConsoleMessage={setConsoleMessage}
                updateVariableWithId={updateVariableWithId}
                changeCursorPosition={changeCursorPosition}
                gameFonts={gameFonts}
                previewScroll={previewScroll}
                setPreviewScroll={setPreviewScroll}
                setExternalPlayPauseHandler={setExternalPlayPauseHandler}
                resetPlayerState={resetPlayerState}
                setExecutedCommand={setExecutedCommand}
                executedCommand={executedCommand}
                flowchartHasErrors={flowchartHasErrors}
                showScriptError={showScriptError}
                textEditorValidate={textEditorValidate}
                setShowScriptError={setShowScriptError}
                isViewStory={isViewStory}
                commandListForScriptEditor={commandListForScriptEditor}
                tokenDataUser={tokenDataUser}
                setCommand={setCommand}
                setHighlightBlock={setHighlightBlock}
                highlightBlock={highlightBlock}
                changeCheckBoxHighlightBlock={changeCheckBoxHighlightBlock}
                highlightBlockIndex={highlightBlockIndex}
                setStartFromHighlightBlock={setStartFromHighlightBlock}
                startFromHighlightBlock={startFromHighlightBlock}
                hideIfVisitedArray={hideIfVisitedArray}
              />
            ) : (
              <Spinner
                animation="border"
                variant="dark"
                style={{ marginTop: '50%', marginLeft: '50%' }}
              />
            )}
          </Tab>
        </Tabs>
      </TabStyle.StoryRightPanelTab>
    </div>
  );
}

export default StoryRightPanel;

import React from 'react';
import moment from 'moment';
import { styledIcons } from './../../../common/styles/icons';
import * as Translations from './../translations';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import story from '../../../pages/story';

function ListRow({
  character,
  index,
  previewStatus,
  handlePreviewClick,
  characterIndex,
  expressionList,
  outfitList,
  hairsList,
  accesoryList,
  listPreviewSprites,
  handleExpressionChange,
  handleOutfitChange,
  handleHairChange,
  handleAccesoryChange,
  handleEditCast,
  handleShowDeleteModal,
  handleEditClick,
  handleAddCastModal,
  storyDetails
}) {
  return (
    <React.Fragment>
      <tr className={`${previewStatus[index] ? 'border-0' : ''}`}>
        <td className="text-left text-bold-dark">
          {character.characterLongName}{' '}
        </td>
        <td>{character.variationCount}</td>
        <td>{character.createdBy}</td>

        <td className="text-small-light">
          {moment(new Date(character.createdAt)).format('MM-DD-YYYY')}
        </td>
        <td className="actions">
          <OverlayTrigger
            key={`edit-character`}
            placement="top"
            overlay={<Tooltip id={`edit-character`}>Edit character</Tooltip>}
          >
            <styledIcons.editIcon
              title="edit"
              className="cursor-pointer"
              onClick={() => handleEditClick(character.id)}
            />
          </OverlayTrigger>
          <OverlayTrigger
            key={`delete-character`}
            placement="top"
            overlay={
              <Tooltip id={`delete-character`}>Delete character</Tooltip>
            }
          >
            <styledIcons.deleteIcon
              title="delete"
              className="cursor-pointer"
              onClick={() => handleShowDeleteModal(character.id)}
            />
          </OverlayTrigger>
          {character.characterCast === true &&
            storyDetails &&
            storyDetails.storyType === 'ShortStory' &&
            character.isDefaultPlayer !== true && (
              <OverlayTrigger
                key={`short-story-character`}
                placement="top"
                overlay={
                  <Tooltip id={`short-story-character`}>
                    Update Cast Information
                  </Tooltip>
                }
              >
                <styledIcons.settingsIconDisabled
                  title="Update cast"
                  className="cursor-pointer"
                  onClick={() => handleEditCast(character.id)}
                />
              </OverlayTrigger>
            )}
          {character.characterCast === false &&
            storyDetails &&
            storyDetails.storyType === 'ShortStory' &&
            character.isDefaultPlayer !== true && (
              <OverlayTrigger
                key={`short-story-character`}
                placement="top"
                overlay={
                  <Tooltip id={`short-story-character`}>
                    Add to Story Cast
                  </Tooltip>
                }
              >
                <styledIcons.settingsIconActive
                  title="Add cast"
                  className="cursor-pointer"
                  onClick={() => handleAddCastModal(character.id)}
                />
              </OverlayTrigger>
            )}
        </td>
        <td className="icon-box">
          <styledIcons.previewIcon
            isOpen={previewStatus != undefined && previewStatus[index]}
            onClick={handlePreviewClick}
            id={index}
          />
        </td>
      </tr>
      <tr
        className={`exanded-row-wrapper ${previewStatus[index] ? '' : 'hide'}`}
      >
        <td colspan="8">
          <div className="exanded-row-block">
            <div className="choose-expression-block">
              {listPreviewSprites.length == 0 && (
                <React.Fragment>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                  <span>{Translations.CHARACTER_LIST_PREVIEW_NOTE}</span>
                </React.Fragment>
              )}
              {listPreviewSprites.length > 0 &&
                listPreviewSprites.map(sprite => {
                  return (
                    <img
                      src={sprite}
                      height="150px"
                      width="150px"
                      className="list-preview-img"
                    />
                  );
                })}
            </div>
            <div className="w-50">
              <div className="row">
                <div className="select-block w-100">
                  <div className="custom-select-dropdown">
                    <label className="select-label">
                      {Translations.OUTFIT_TITLE}
                    </label>
                    <select
                      onChange={event => handleOutfitChange(event, index)}
                      value={characterIndex.outfit}
                    >
                      {/* <option
                        // selected={previewStatus[index] ? false : true}
                        value="-1"
                      >
                        {Translations.SELECT_ONE}
                      </option> */}
                      {outfitList.map((outfit, index) => {
                        return (
                          <option value={index}>{outfit.assetName}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="custom-select-dropdown">
                    <label className="select-label">
                      {Translations.EXPRESSION_TITLE}
                    </label>
                    <select
                      onChange={event => handleExpressionChange(event, index)}
                      value={characterIndex.expression}
                    >
                      {/* <option
                        // selected={previewStatus[index] ? false : true}
                        value="-1"
                      >
                        {Translations.SELECT_ONE}
                      </option> */}
                      {expressionList.map((expression, index) => {
                        return (
                          <option value={index}>{expression.assetName}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="select-block w-100  mt-4">
                  <div className="custom-select-dropdown">
                    <label className="select-label">
                      {Translations.HAIRS_TITLE}
                    </label>
                    <select
                      onChange={event => handleHairChange(event, index)}
                      value={characterIndex.hair}
                    >
                      {/* <option
                        // selected={previewStatus[index] ? false : true}
                        value="-1"
                      >
                        {Translations.SELECT_ONE}
                      </option> */}
                      {hairsList.map((hair, index) => {
                        return <option value={index}>{hair.assetName}</option>;
                      })}
                    </select>
                  </div>
                  <div className="custom-select-dropdown">
                    <label className="select-label">
                      {Translations.ACCESORY_TITLE}
                    </label>
                    <select
                      onChange={event => handleAccesoryChange(event, index)}
                      value={characterIndex.acc}
                    >
                      {/* <option
                        // selected={previewStatus[index] ? false : true}
                        value="-1"
                      >
                        {Translations.SELECT_ONE}
                      </option> */}
                      {accesoryList.map((acc, index) => {
                        return <option value={index}>{acc.assetName}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default ListRow;

import { prop } from 'lodash/fp';
import React from 'react';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { BACKGROUND_IMAGE } from '../../../common/constants';
import { PlaceBackgroundService } from './../place-background-service';
import ShowList from './show-list';

function CheckViews(props) {
  // let transform = '';
  // if (document.getElementsByClassName('cropper-canvas')) {
  //   // transform = document.querySelector('.cropper-canvas');
  //   // transform = window.getComputedStyle(transform).transform;
  // }
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleModalViews}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="header-text">
          All Views of the Background
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="modal-button-wrapper justify-content-between"
          style={{ height: '460px' }}
        >
          <div
            id={'main-show-div'}
            style={{
              maxHeight: '423px',
              width: '317.33px',
              position: 'relative',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${props.backgroundImage})`,
              backgroundSize: '317px',
              //   backgroundPosition: 'center',
              // transform: `${transform}`
            }}
          >
            {props.otherView &&
              props.otherView.map(views => {
                const resultDimension = PlaceBackgroundService.getEditParameterValuesShow(
                  props.selectedBackground.specs,
                  views.left,
                  views.top,
                  views.width,
                  views.height
                );
                return (
                  <ShowList
                    height={resultDimension.height}
                    width={resultDimension.width}
                    top={resultDimension.y}
                    left={resultDimension.x}
                    name={views.name}
                  />
                );
              })}
           </div>
        </div>
       
      </Modal.Body>
    </Modal>
  );
}

export default CheckViews;

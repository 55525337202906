import React from 'react';
import { Modal } from 'react-bootstrap';
import PlaceBackgroundTabs from './PlaceBackgroundTabs';

function PlaceBackground(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.onHideModal}
      backdrop="static"
    >
      <Modal.Header closeButton style={{ alignItems: 'start' }}>
        <Modal.Title id="contained-modal-title-vcenter" className="header-text">
          <p className="mb-0">Move View</p>
          <p style={{ fontSize: '14px', color: '#707070' }}>
            A transition will occur only between two views using the same
            background image.
          </p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="modal-content-wrapper">
          <PlaceBackgroundTabs
            backgroundImages={props.backgroundImages}
            background={props.background}
            handleMovePlaceViewScript={props.handleMovePlaceViewScript}
            views={props.views}
            onHideModal={props.onHideModal}
            viewId={props.viewId}
            fadeinView={props.fadeinView}
            isEditCommand={props.isEditCommand}
            characterRoutesList={props.characterRoutesList}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PlaceBackground;

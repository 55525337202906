import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalStyled } from './style/modal-style';

function Success({
    label,
    note,
    handleClick,
    label_next,
    imageChange
}) {
    return <Modal show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        dialogClassName="my-account-success">
        <Modal.Body>
            <ModalStyled.addAssetMessageBox>
                <ModalStyled.successAssetContent>
                    <div className="success-icon mb-3">
                        {imageChange === true ? <img className="mediumIcon" alt="" src="/images/icons/ic_check_circle_24px.svg" /> : <img className="mediumIcon" alt="" src="/images/icons/ic_done_24px.svg" />}

                    </div>
                    <div className="submit-asset-label"
                        data-testid="success-label">
                        {label} 
                        <div>{label_next}</div>
                    </div>
                    <div className="text-muted text-note"
                        data-testid="success-note">
                        {note}
                    </div>
                    <div>
                        <Button
                            onClick={handleClick}
                            data-testid="success-btn"
                            className="btn-snacktool btn-snacktool-golden">
                            Okay
                                </Button>
                    </div>
                </ModalStyled.successAssetContent>
            </ModalStyled.addAssetMessageBox>
        </Modal.Body>
    </Modal>
}

export default Success
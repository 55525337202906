import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Row
} from 'react-bootstrap';

import { ChapterStyle } from './style';
import UploadFile from './uploadCast';
import { RouteServices } from './cast-services';
// import ItemListLoader from '../../common/spin-loader';
// import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

function AddCastModal(props) {
  const {
    cast,
    setCast,
    characterList,
    setShowClearFile,
    showClearFile,
    handleCreateCast,
    isCastEdit,
    routesData,
    files,
    setFiles,
    validated,
    loading,
    editRouteId,
    setRouteValidated,
    charLoading,
    refreshRouteList,
    handleAddCastModalHide
  } = props;
  let routeTypeOptions = [];

  const [addInfoClass, setAddInfoClass] = useState(true);
  const [disableCharacterList, setDisableCharacterList] = useState(false);
  const [showRouteError, setShowRouteError] = useState(false);
  const [showFileError, setShowFileError] = useState(false);

  const [initialRouteStep, setInitialRouteStep] = useState(0);
  const [stepsRoute, setStepsRoute] = useState({
    element: 'true Element',
    intro: 'intro'
  });

  const [stepsRouteEnabled, setStepsRouteEnabled] = useState(false);

  useEffect(() => {
    setAddInfoClass(isCastEdit === true ? false : true);

    // if (isCastEdit === true) {
    //   let checkChapters = RouteServices.checkChapterExist(
    //     props.routesData,
    //     props.characterId
    //   );
    //   setDisableCharacterList(checkChapters === true ? true : false);
    // } else {
    //   setDisableCharacterList(false);
    // }

    setTimeout(function() {
      // setStepsRouteEnabled(
      //   props.tutorials.firstCharacterRoute === false ? true : false
      // );
    }, 1000);
  }, [isCastEdit, props.characterList, refreshRouteList, props.tutorials]);

  const handleHideTutorial = () => {
    // setStepsRouteEnabled(false);
    props.onCharacterRouteExit();
  };
  const handleClearFile = () => {
    props.setFiles(null);
    setShowClearFile(false);
    //   setShowFileError(true);
    //  setAddInfoClass(true);
  };

  const handleFormChange = event => {
    if (event.target.name === 'characterId') {
      if (
        isCastEdit === true &&
        editRouteId.toString() !== event.target.value.toString()
      ) {
        let checkRoute = RouteServices.checkRouteExist(
          props.routesData,
          event.target.value
        );
        if (checkRoute === true) {
          setAddInfoClass(true);
          setShowRouteError(true);
        } else {
          setShowRouteError(false);
        }
      }
    }
    setCast({
      ...cast,
      [event.target.name]: event.target.value
    });

    if (
      event.currentTarget.form.checkValidity() === false ||
      (event.target.name === 'characterId' && event.target.value === '')
    ) {
      setAddInfoClass(true);
    } else {
      setAddInfoClass(false);
    }

    props.setRouteValidated(true);
  };

  if (characterList && characterList.length > 0) {
    routeTypeOptions = characterList.map((char, key) => {
      if (char.isDefaultPlayer === null || char.isDefaultPlayer === false) {
        return (
          <option key={key} value={char.id}>
            {RouteServices.getRouteTypeLabel(
              char.characterName,
              char.displayName
            )}
          </option>
        );
      }
    });
  }
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={() => props.handleAddCastModalHide()}
      backdrop="static"
    >
      <ChapterStyle.addInfoContainer className="step-route">
        <Modal.Header closeButton>
          {/* {loading === false && ( */}
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
            {isCastEdit === true ? 'Update cast to story' : 'Add Cast to story'}
          </Modal.Title>
          {/* )} */}
        </Modal.Header>

        <Modal.Body>
          {/* <Steps
            enabled={stepsRouteEnabled}
            steps={stepsRoute}
            initialStep={initialRouteStep}
            onExit={handleHideTutorial}
            options={translations.INTRO_OPTIONS}
          /> */}

          {/* {loading === true && (
            <Row className="row-100">
              <ItemListLoader />
            </Row>
          )} */}

          {/* {loading === false && ( */}
          <div>
            <Form
              id="add-route-form"
              noValidate
              validated={validated}
              onSubmit={e => props.handleCreateCast(e)}
            >
              <Form.Group>
                <div className="row">
                  <div className="upload-file mb-1">
                    <Form.Label>
                      <div className="d-flex align-items-center">
                        Character portrait
                        <OverlayTrigger
                          key={`tooltip-avtar`}
                          placement="top"
                          overlay={
                            <Tooltip
                              id={`tooltip-avtar`}
                              className="tooltip-320"
                            >
                              Supported file format: PNG. <br /> File
                              dimensions: 1024x1024 pixels. <br />
                              File size limit: 15 Mb.
                            </Tooltip>
                          }
                        >
                          <span className=" ml-2">
                            <img
                              alt=""
                              className="checkIcon"
                              src="/images/icons/ic_info_24px.svg"
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Form.Label>
                    <Form.Label>
                      <span className="secondary-text"> 1024x1024 pixels</span>
                    </Form.Label>

                    <UploadFile
                      setFiles={setFiles}
                      files={files}
                      setShowClearFile={setShowClearFile}
                      showRouteError={showRouteError}
                      showClearFile={showClearFile}
                      handleClearFile={handleClearFile}
                      cast={cast}
                      setCast={setCast}
                      setAddInfoClass={setAddInfoClass}
                      isCastEdit={isCastEdit}
                      setShowFileError={setShowFileError}
                      showFileError={showFileError}
                      isCharacterRoute={true}
                      // setContinueToAddInfoClass={setContinueToAddInfoClass}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Character's last name (optional)</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  autocomplete="off"
                  onChange={handleFormChange}
                  onKeyUp={handleFormChange}
                  maxlength="12"
                  defaultValue={cast.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {/* {translations.DESCRIPTION_ERROR_MESSAGE} */}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Summary
                  <Form.Text className="text-muted">
                    Limit: 355 characters
                  </Form.Text>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="textarea"
                  className="textAreaResize"
                  rows="5"
                  maxLength="355"
                  minLength="1"
                  required
                  autocomplete="off"
                  name="description"
                  value={cast.description}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a summary.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
            <div className="modal-button-wrapper justify-content-between">
              {isCastEdit === true ? (
                <Button
                  onClick={() => props.deleteCast()}
                  className="btn-snacktool btn-snacktool-brown"
                >
                  Delete cast
                </Button>
              ) : (
                <Button
                  onClick={() => props.handleAddCastModal()}
                  className="btn-snacktool btn-snacktool-golden"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={e => props.handleCreateCast(e)}
                disabled={cast.description == '' ? 'disabled' : ''}
                form="add-chapter-form"
                className="btn-snacktool btn-snacktool-golden"
              >
                {isCastEdit === true ? 'Update cast' : 'Add cast'}
              </Button>
            </div>
          </div>
          {/* )} */}
        </Modal.Body>
      </ChapterStyle.addInfoContainer>
    </Modal>
  );
}
export default AddCastModal;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SuccessDeleteAccount = props => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
      className="modalbg"
      size="md"
      show={props.show}
      onHide={() => props.onCancelAccountDeleteClick()}
    >
      <Modal.Body className="mt-5">
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon svg-icon-image"
              src="/images/icons/ic_clear_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">{`Account ${props.acct.email} has been deleted`}</label>
          </div>
        </div>
        <div className="modal-button-wrapper justify-content-center">
          <Button
            onClick={event => props.onCloseDeleteSucces(event)}
            className="btn-snacktool btn-snacktool-golden btn btn-primary"
          >
            Okay
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SuccessDeleteAccount;

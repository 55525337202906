export const FIRST_TAB_KEY = 'first';
export const SECOND_TAB_KEY = 'second';
export const THIRD_TAB_KEY = 'third';
export const FOURTH_TAB_KEY = 'fourth';
export const FIFTH_TAB_KEY = 'fifth';
export const SIXTH_TAB_KEY = 'sixth';


export const TAGS_PATTERN_MAX_5 = '^[0-9a-zA-Z]+(?:, [0-9a-zA-Z]+){0,4}$';


export const ASSET_NAME_PATTERN = '[a-zA-Z0-9_-]{1,12}';
export const NEW_LINE_CHECK_PATTERN = '/(\r\n|\n|\r)/g';

// export const CHAPTER_NAME_PATTERN = '^[^-\s][a-zA-Z0-9_\s-]+$';

export const CHAPTER_NAME_PATTERN = '^[^\s].+[^\s]$';
export const INTRO_OPTIONS = {skipLabel:"x",
prevLabel:"Previous",
nextLabel:"Next",
exitOnEsc:true,
exitOnOverlayClick:true,
showStepNumbers:false,
hidePrev:true,
hideNext:true,
doneLabel:'Finish' };

export const stepConstant = [
  {
    element: '.steps-library',
    intro: "<h3>LIBRARY SECTION</h3> <p>Here is where you can create characters and views.</p>",
    position: 'top',
  },
  {
    element: '.step-views',
    intro: '<h3>CREATE VIEWS</h3><p></p>',
    position: 'right',
  },
  {
    element: '.step-character',
    intro: '<h3>CREATE CHARACTERS</h3><p>Here you can create, edit and manage the characters in your story.</p>',
    position: 'right',
  },
  {
    element: '.step-assets',
    intro: '<h3>UPLOAD IMAGES</h3><p>Those images will be used to create characters in this section or views directly in the script.</p>',
    position: 'right',
  },
  
  {
    element: '.step-interface',
    intro: '<h3>CUSTOMIZE THE GAME UI</h3><p>You can upload your own images for buttons, name plates and other interface elements.</p>',
    position: 'right',
  },
 
];
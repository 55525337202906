import styled from "styled-components";
import {colors} from "./../../common/styles/variables"

export const StoryBlankContainer = styled.div`
    background-color: #F5F5FA 0% 0% no-repeat padding-box;
    width:100%
`;

export const StoryCreateArrow = styled.div`
    width: 82px;
    height: 174px;
    transform: matrix(0.99, 0.12, 0.12, 0.99, 0, 0);
    background: transparent url('./images/Story/storycreatearrow.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-left: 45.24%;
    margin-top: -89.5px !important;
`;

export const StartLabel = styled.label`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.shades_shade200};
    
`;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  Badge,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import './../../style/style.css';
import ListContent from './ListContent';
import { LibraryServices } from './../../../../components/library/service';

function ListFile(props) {
  const {
    fileNames,
    assetFiles,
    handleAssetFileDelete,
    handleTypeChange,
    handleNameChange,
    handleUploadInfo,
    enableUpload,
    setEnableUpload,
    handleDefaultTypeChange,
    refreshList,
    focusElement,
    dummyAsset,
    setDummyAsset
  } = props;

  const [outfitCount, setOutfitCount] = useState(0);
  const [hairCount, setHairCount] = useState(0);
  const [expressionCount, setExpressionCOunt] = useState(0);
  const [accessoryCount, setAccesoryCount] = useState(0);

  useEffect(() => {
    setDeleteDefaultData();
  }, [refreshList]);

  const setDeleteDefaultData = () => {
    let variations = assetFiles;
    let otCount = 0,
      hrCount = 0,
      expCount = 0,
      accCount = 0;
    for (let i = 0; i < variations.length; i++) {
      if (variations[i].assetType === 'Outfit') {
        otCount++;
      }
      if (variations[i].assetType === 'Expression') {
        expCount++;
      }
      if (variations[i].assetType === 'Hair') {
        hrCount++;
      }
      if (variations[i].assetType === 'Accessory') {
        accCount++;
      }
    }
    setOutfitCount(otCount);
    setHairCount(hrCount);
    setExpressionCOunt(expCount);
    setAccesoryCount(accCount);
  };

  setEnableUpload(LibraryServices.setCharacterUploadEnabled(assetFiles));
  return (
    <Modal
      dialogClassName="upload-modal-dialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={fileNames.length > 0}
      onHide={props.onHide}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="header-text">
          Upload multiple files
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: '0 70px 30px 15px !important' }}>
        <ListContent
          fileNames={fileNames}
          assetFiles={assetFiles}
          handleAssetFileDelete={handleAssetFileDelete}
          handleTypeChange={handleTypeChange}
          handleNameChange={handleNameChange}
          handleUploadInfo={handleUploadInfo}
          handleDefaultTypeChange={handleDefaultTypeChange}
          refreshList={refreshList}
          focusElement={focusElement}
          outfitCount={outfitCount}
          hairCount={hairCount}
          expressionCount={expressionCount}
          accessoryCount={accessoryCount}
          dummyAsset={dummyAsset}
          setDummyAsset={setDummyAsset}
        />

        <Row>
          <Col>
            <Button
              className="btn-snacktool btn-snacktool-golden"
              onClick={props.onHide}
            >
              Cancel
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              onClick={e => handleUploadInfo(e)}
              disabled={enableUpload}
              className="btn-snacktool btn-snacktool-golden btn btn-primary"
            >
              Upload
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ListFile;

import React, { useEffect } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PlaceBgStyle } from './style';
import { StoryService } from './../service';

const callbackFunction = (x, id) => {
  let element = document.getElementById('view-' + id);
  if (element != null) {
    element.style.backgroundImage = `url(${x})`;
    element.style.backgroundRepeat = 'no-repeat';
    element.style.backgroundPosition = 'center';
    element.style.backgroundSize = '100% 100%';
  }
};

function BrowseViewLibraryTab(props) {
  let filterList =
    props.searchViews.length === 0 ? props.views : props.searchViews;
  useEffect(() => {
    filterList.map(view => {
      let url = view.asset.awsPath;
      StoryService.resizeImage(
        url,
        view.width,
        view.height,
        view.xcoordinate,
        view.ycoordinate,
        callbackFunction,
        view.id
      );
    });
  }, [filterList]);

  return (
    <React.Fragment>
      <div className="browse-tab-content">
        <div className="d-flex justify-content-between">
          <div className="tab-header-content">
            <Button
              className={`btn-snacktool btn-snacktool-brown mr-3 `}
              disabled={`${props.backgroundCopy === null ? 'disabled' : ''}`}
              onClick={props.handleDuplicateClick}
            >
              Duplicate <i className="fa fa-clone ml-1" aria-hidden="true"></i>
            </Button>
            <Button
              className={`btn-snacktool btn-snacktool-brown mr-3 `}
              disabled={`${props.backgroundCopy === null ? 'disabled' : ''}`}
              onClick={props.handleViewEditClick}
            >
              Edit
            </Button>
          </div>
          <div>
            <Form.Group className="form-search-box">
              <Form.Control
                type="text"
                name="scenename"
                id="scenename"
                placeholder="Search views"
                onChange={props.handleViewSearch}
                value={props.searchText}
              />
              <i className="fa fa-search" aria-hidden="true"></i>
            </Form.Group>
          </div>
        </div>
        <div className="tab-list-content row">
          {props.searchText != '' &&
            props.searchViews.length == 0 &&
            'No views found'}
          {(props.searchText == '' || props.searchViews.length > 0) &&
            filterList.map(view => {
              let objectCountLabel =
                view.viewObjects.length === 1 ? 'object' : 'objects';
              return (
                <div
                  className={`list-col-library ${
                    view.id === props.backgroundCopy ? 'active' : null
                  }`}
                  onClick={() => props.handleBrowseSelection(view.id)}
                  key={'view' + view.id}
                >
                  <PlaceBgStyle.coverBg
                    background={view.asset.awsPath}
                    id={`view-${view.id}`}
                  >
                    <div className="border-div"></div>
                  </PlaceBgStyle.coverBg>
                  <div className="browse-img-details">
                    <div className="browse-img-name">
                      <OverlayTrigger
                        key={`title${view.id}`}
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={`tooltip-top`}
                            className="preview-mobile-tooltip"
                          >
                            {view.name}
                          </Tooltip>
                        }
                      >
                        <span>{StoryService.prepareViewName(view.name)}</span>
                      </OverlayTrigger>
                    </div>
                    <div className="browse-img-size">
                      {view.asset.specs} |{' '}
                      {`${view.viewObjects.length} ${objectCountLabel}`}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default BrowseViewLibraryTab;

import React, { useCallback, useState, useEffect } from 'react';
import { LibraryStyle } from './../../style/style';
import { Row, Col, Container } from 'react-bootstrap';

import ListTable from './ListTable';

function ListContent(props) {
  return (
    <LibraryStyle.listMainSection>
      <Container>
        <Row>
          <Col>
            <ListTable
              fileNames={props.fileNames}
              assetFiles={props.assetFiles}
              handleAssetFileDelete={props.handleAssetFileDelete}
              handleTypeChange={props.handleTypeChange}
              handleNameChange={props.handleNameChange}
              handleUploadInfo={props.handleUploadInfo}
              handleDefaultTypeChange={props.handleDefaultTypeChange}
              refreshList={props.refreshList}
              focusElement={props.focusElement}
              outfitCount={props.outfitCount}
              hairCount={props.hairCount}
              expressionCount={props.expressionCount}
              accessoryCount={props.accessoryCount}
              dummyAsset={props.dummyAsset}
              setDummyAsset={props.setDummyAsset}
            />
          </Col>
        </Row>
      </Container>
    </LibraryStyle.listMainSection>
  );
}

export default ListContent;

import React from 'react';
import * as CollaboratorStyle from '../story/collaborator/style/collaborator-style';
import * as translations from '../story/collaborator/style/translations';
import { Button, Form, Modal } from 'react-bootstrap';

const UpdateStoryId = props => {
  return (
    <Modal
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      backdrop="static"
    >
      <CollaboratorStyle.modalCollaborator>
        <Modal.Body>
          <Modal.Title id="example-custom-modal-styling-title">
            {translations.STORYID}
          </Modal.Title>
          <p>{translations.STORYIDSUB}</p>
          <p>{translations.STORYIDSUBSECOND}</p>
          <Form
            method="POST"
            onSubmit={evt => props.updateStoryId(evt, props.storyInfoId)}
          >
            <Form.Group>
              {/* <div className="input-wrapper"> */}
              <Form.Control
                required
                type="text"
                name="unityStoryId"
                key="unityStoryId"
                onChange={props.hanldeStoryIdChange}
                placeholder="Update story ID"
                maxLength="9"
                minLength="4"
                value={props.storyInfoIdValue}
              />
              <Form.Control.Feedback type="invalid">
                {'error'}
              </Form.Control.Feedback>
              {props.storyIdError !== '' && (
                <Form.Text className="text-danger">
                  {props.storyIdError}
                </Form.Text>
              )}
              {/* </div> */}
            </Form.Group>
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <div>
                <Button
                  id="closeBtn"
                  type="button"
                  className="btn-snacktool btn-snacktool-golden btn btn-primary"
                  onClick={() => props.handleHideUpdateStoryId()}
                >
                  {translations.CANCEL}
                </Button>
              </div>
              <div>
                <Button
                  id="createSceneBtn"
                  type="submit"
                  className="btn-snacktool btn-snacktool-golden btn btn-primary"
                  disabled={props.updateButtonEnable}
                >
                  {translations.UPDATESTORYID}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </CollaboratorStyle.modalCollaborator>
    </Modal>
  );
};

export default UpdateStoryId;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, withRouter, NavLink } from 'react-router-dom';
import * as ActionTypes from '../../actions/action-types';
import * as StoryAction from '../../actions/story-action';
import * as Constant from './../../config/app.config';
import * as HeaderConstant from './Constant';
import moment from 'moment';
import * as styled from './styles';
import * as LibraryActions from './../../actions/library-action';
import * as CommentAction from './../../actions/comment-action';
import * as MyAccountAction from './../../actions/myaccount-action';
import * as PlaceBackgroundAction from './../../actions/place-background-action';
import { CommentServices } from './comments/service';
import ConfirmAutoSavePopup from '../../components/common/Modals/WarnigToSave';

import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

import { setCommands } from '../../actions/story-action';

import Comments from './comments/comments';

import Error from './Error/error';

const Header = props => {
  const replyConstant = {
    reply: ''
  };
  const commentConstant = {
    commentText: ''
  };

  const [reply, setReply] = useState(replyConstant);
  const [comment, setComment] = useState(commentConstant);

  const [showComment, setShowComment] = useState(false);
  const [commentFilter, setCommentFilter] = useState(true);
  const [commentNumber, setCommentNumber] = useState(0);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);

  // intor.js states
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [showRepteatTutorial, setShowRepteatTutorial] = useState([]);
  const [autoSaveFlagCheck, setAutoSaveFlagCheck] = useState(false);
  const [menuClicked, setMenuClicked] = useState(null);
  const [viewAutoSaveFlagCheck, setViewAutoSaveFlagCheck] = useState(false);
  const [characterAutoSaveFlagCheck, setCharacterAutoSaveFlagCheck] = useState(
    false
  );

  let isLibraryMenu =
    props.history.location.pathname.includes('/library') ||
    props.history.location.pathname.includes('/characters') ||
    props.history.location.pathname.includes('/interface') ||
    props.history.location.pathname.includes('/views');

  useEffect(() => {
    if (
      props.history.location.pathname &&
      props.history.location.pathname.includes('/write-story')
    ) {
      if (props.storyId && props.currentSceneId)
        props.getComments(props.authToken, props.storyId, props.currentSceneId);
    }

    if (props.history.location.pathname && isLibraryMenu) {
      setSteps(HeaderConstant.stepConstant);
      setStepsEnabled(props.tutorials.firstLibrary === false ? true : false);
    }
    setShowRepteatTutorial(
      CommentServices.getRepeatTutorialState(props.tutorials)
    );
  }, [props.currentSceneId, props.tutorials]);

  let tokenData = props.tokenData ? props.tokenData : '';

  if (tokenData === '') {
    props.addLogin(null, false);
    props.history.push('/login');
  }

  const autoSavePopupCall = from => {
    setMenuClicked(from);
    if (props.autoSaveFlag === true) {
      setAutoSaveFlagCheck(true);
    } else if (props.viewAutoSaveFlag === true) {
      setViewAutoSaveFlagCheck(true);
    } else if (props.characterAutoSaveFlag === true) {
      setCharacterAutoSaveFlagCheck(true);
    } else {
      props.resetViews();
      afterCheckAutoSave(from);
    }

    if (
      props.commandObj &&
      props.commandObj.lockAcquired === true &&
      props.tokenData.userId === props.commandObj.lockAcquiredBy &&
      props.autoSaveFlag !== true
    ) {
      if (props.currentSceneId !== null) {
        props.releseLock(props.authToken, props.storyId, props.currentSceneId);
        props.resetFlowChartData();
      }
    }
  };

  const handleLogout = event => {
    props.addLogin(null, true, props.authToken);
    props.history.push('/login');
  };

  const handleMyStories = event => {
    props.setSelectedMenuAccount('');
    props.history.push('/home');
  };

  const handleMyAccount = event => {
    props.setSelectedMenuAccount('myaccount');
    props.history.push('/myaccount');
  };

  const handleManage = event => {
    if (tokenData.roles === '[ROLE_ADMIN]') {
      props.history.push('/users');
    } else if (tokenData.roles === '[ROLE_EDITOR]') {
      props.setAuthorName('');
      props.history.push('/stories');
    }
  };

  const handleNavigation = event => {
    props.setSelectedMenuAccount('');
    if (tokenData.roles === '[ROLE_ADMIN]') {
      props.history.push('/users');
    } else if (tokenData.roles === '[ROLE_EDITOR]') {
      props.history.push('/stories');
      props.setAuthorName('');
    } else {
      props.history.push('/home');
    }
  };

  const handleRepeatTour = event => {
    props.setTutorials(props.tutorials, props.authToken, 'All');
  };

  const handleMenuClick = menu => {
    props.setSelectedMenu(menu);
  };

  const handleStoryClick = () => {
    let authorName = '';
    props.fetchStoryforAdmin(props.authToken, authorName);
    props.setAuthorName('');
    props.setSelectedMenu('stories');
  };

  const handlePrevClick = () => {
    let prevComment = commentNumber - 1;
    setCommentNumber(prevComment);
    setShowReplyForm(false);
    setShowCommentForm(false);
  };

  const handleNextClick = () => {
    let nextComment = commentNumber + 1;
    setCommentNumber(nextComment);
    setShowReplyForm(false);
    setShowCommentForm(false);
  };

  const handleFilterClick = filter => {
    setCommentFilter(filter);
    setCommentNumber(0);
    setShowReplyForm(false);
    setShowCommentForm(false);
  };

  const hidePopup = () => {
    setShowComment(false);
  };

  const handleShowComment = event => {
    if (document.getElementById('backdropElementComment'))
      document.getElementById('backdropElementComment').style.display = 'block';
    if (document.getElementById('commentPopup'))
      document.getElementById('commentPopup').style.display = 'block';
    setShowComment(true);
    setCommentFilter(true);
    setCommentNumber(0);
  };

  const handleResolveComment = (commentNo, status) => {
    let updateCommentList = CommentServices.getResolvedComment(
      status === 'resolved' ? props.filterCommnetList : props.commentLists,
      commentNo,
      status
    );
    props.updateComments(
      updateCommentList,
      props.authToken,
      props.storyId,
      props.currentSceneId,
      status === 'resolved'
        ? props.filterCommnetList[commentNo].id
        : props.commentLists[commentNo].id,
      false
    );
    if (status === 'resolved') {
      if (commentNumber === props.filterCommnetList.length - 1) {
        setCommentNumber(props.filterCommnetList.length - 2);
      }
    }
  };

  const handleEditComment = (event, commentNo) => {
    let updateCommentList = CommentServices.getEditComment(
      props.filterCommnetList,
      commentNo,
      comment.commentText
    );
    props.updateComments(
      updateCommentList,
      props.authToken,
      props.storyId,
      props.currentSceneId,
      props.filterCommnetList[commentNo].id,
      false
    );

    if (commentNumber !== 0) {
      if (commentNumber === props.filterCommnetList.length - 1) {
        setCommentNumber(props.filterCommnetList.length - 1);
      }
    } else {
      setCommentNumber(0);
    }
    setShowCommentForm(false);
    event.preventDefault();
  };

  const handleReplyComment = (event, commentNo) => {
    let updateCommentList = CommentServices.getReplyComment(
      props.filterCommnetList,
      commentNo,
      reply.reply
    );
    props.updateComments(
      updateCommentList,
      props.authToken,
      props.storyId,
      props.currentSceneId,
      props.filterCommnetList[commentNo].id,
      false
    );

    if (commentNumber !== 0) {
      if (commentNumber === props.filterCommnetList.length - 1) {
        setCommentNumber(props.filterCommnetList.length - 2);
      }
    } else {
      setCommentNumber(0);
    }
    setShowReplyForm(false);
    event.preventDefault();
  };

  const handleDeleteComment = (event, commentNo, isComment) => {
    if (isComment === true) {
      props.updateComments(
        props.filterCommnetList,
        props.authToken,
        props.storyId,
        props.currentSceneId,
        props.filterCommnetList[commentNo].id,
        isComment,
        isComment
      );
    } else {
      let updateCommentList = CommentServices.getReplyComment(
        props.filterCommnetList,
        commentNo,
        null
      );
      props.updateComments(
        updateCommentList,
        props.authToken,
        props.storyId,
        props.currentSceneId,
        props.filterCommnetList[commentNo].id,
        isComment
      );
    }
    if (commentNumber !== 0) {
      if (commentNumber === props.filterCommnetList.length - 1) {
        setCommentNumber(props.filterCommnetList.length - 2);
      }
    } else {
      setCommentNumber(0);
    }
    setShowReplyForm(false);
    event.preventDefault();
  };

  const onLibraryExit = () => {
    if (stepsEnabled === true) {
      setStepsEnabled(false);
      props.setTutorials(props.tutorials, props.authToken, 'firstLibrary');
    }
  };
  const handleHideErrorModal = () => {
    props.hideErrorModal();
  };

  const closeAutoSaveFlagCheck = () => {
    afterCheckAutoSave(menuClicked);
    props.autopopupSave(false);
    setAutoSaveFlagCheck(false);
    props.resetAllTextEditor();
  };

  const onClickYes = () => {
    props.setCommandsData(
      props.commandObj,
      props.authToken,
      props.storyId,
      props.currentSceneId
    );
    props.autopopupSave(false);
    afterCheckAutoSave(menuClicked);
    setAutoSaveFlagCheck(false);
  };

  const afterCheckAutoSave = from => {
    switch (from) {
      case 'WRITE':
        setMenuClicked(null);
        props.resetFlowChartData();
        props.history.push('/write-story');
        break;

      case 'characters':
        setMenuClicked(null);
        handleMenuClick('characters');
        props.history.push('/characters');
        break;

      case 'navigation':
        setMenuClicked(null);
        handleNavigation();
        break;

      case 'signOut':
        setMenuClicked(null);
        handleLogout();
        break;

      case 'mystory':
        setMenuClicked(null);
        handleMyStories();
        // props.history.push('/home');
        break;

      case 'myAccount':
        setMenuClicked(null);
        handleMyAccount();
        break;
      case 'manage':
        setMenuClicked(null);
        handleManage();
        break;

      default:
        break;
    }
  };

  const leaveViewAutoSavePopup = () => {
    afterCheckAutoSave(menuClicked);
    setViewAutoSaveFlagCheck(false);
    props.resetViewAutoSaveFlag();
    props.resetViews();
  };

  const cancelViewAutoSavePopup = () => {
    setViewAutoSaveFlagCheck(false);
  };

  const leaveCharacterAutoSavePopup = () => {
    afterCheckAutoSave(menuClicked);
    setCharacterAutoSaveFlagCheck(false);
    props.resetCharacterAutoSaveFlag();
  };

  const cancelCharacterAutoSavePopup = () => {
    setCharacterAutoSaveFlagCheck(false);
  };
  return (
    <styled.Header>
      <div
        className={`${
          props.history.location.pathname &&
          props.history.location.pathname.includes('/home')
            ? 'header-container header-container-small'
            : 'header-container header-container-big'
        }`}
      >
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onLibraryExit}
          options={HeaderConstant.INTRO_OPTIONS}
        />
        <styled.HeaderMenu>
          <Row className="header-left">
            <Col xs="4">
              <NavLink
                className="logoStyle"
                onClick={() => autoSavePopupCall('navigation')}
              >
                <img
                  alt=""
                  className="login-bg"
                  src="/images/new_image/logo.png"
                  width="132"
                  height="24"
                />
              </NavLink>
            </Col>
            <Col xs="8">
              <Navbar variant="dark">
                <Nav className="mr-auto">
                  {props.headerValue === true && props.showPanels === true && (
                    <NavLink
                      className="step-write  nav-link"
                      activeClassName={`${
                        props.history.location.pathname.includes('/write-story')
                          ? 'activeNav'
                          : ''
                      } `}
                      onClick={() => autoSavePopupCall('WRITE')}
                    >
                      WRITE
                    </NavLink>
                  )}

                  {props.headerValue === true &&
                    props.showPanels === true &&
                    !props.isViewStory && (
                      <NavLink
                        className={`${
                          isLibraryMenu
                            ? 'activeNav step-library-menu navbar-dark navbar-nav nav-link'
                            : 'step-library-menu navbar-dark navbar-nav nav-link'
                        } `}
                        activeClassName={`${isLibraryMenu ? 'activeNav' : ''} `}
                        disabled={props.isViewStory ? true : false}
                        onClick={() =>
                          props.isViewStory === true
                            ? null
                            : autoSavePopupCall('characters')
                        }
                      >
                        LIBRARY
                      </NavLink>
                    )}
                  {tokenData.roles === '[ROLE_ADMIN]' &&
                    props.showPanels === false &&
                    props.headerValue === true && (
                      <NavLink
                        className="nav-link"
                        activeClassName="activeNav"
                        to="/users"
                      >
                        Accounts
                      </NavLink>
                    )}
                  {(tokenData.roles === '[ROLE_ADMIN]' ||
                    tokenData.roles === '[ROLE_EDITOR]') &&
                    props.showPanels === false &&
                    props.headerValue === true && (
                      <NavLink
                        className="nav-link"
                        activeClassName="activeNav"
                        to="/stories"
                        onClick={() => handleStoryClick()}
                      >
                        Stories
                      </NavLink>
                    )}
                </Nav>
              </Navbar>
            </Col>
          </Row>
          <Row className="header-right">
            {props.history.location.pathname &&
              props.history.location.pathname.includes('/write-story') &&
              !props.isViewStory &&
              props.currentSceneId !== null && (
                <Col xs={`${!props.isViewStory ? 8 : 2}`}>
                  <Row className="saveContainer">
                    {props.history.location.pathname &&
                      props.history.location.pathname.includes(
                        '/write-story'
                      ) &&
                      !props.isViewStory && (
                        <Col xs="2">
                          <Navbar variant="dark">
                            <Nav className="mr-auto"></Nav>
                          </Navbar>
                        </Col>
                      )}
                  </Row>
                </Col>
              )}
            <Col xs={`${!props.isViewStory ? 2 : 2}`}>
              <Row className="saveContainer">
                {props.history.location.pathname &&
                  props.history.location.pathname.includes('/write-story') &&
                  props.currentSceneId !== null &&
                  (props.commentLists.length > 0 ||
                    props.filterCommnetList.length > 0) &&
                  props.isCommentStory === false && (
                    <Col xs="2">
                      {props.commandObj &&
                        (props.commandObj.lockAcquired === false ||
                          props.tokenData.userId ===
                            props.commandObj.lockAcquiredBy ||
                          props.showPanels === false) && (
                          <Navbar variant="dark">
                            {' '}
                            <Nav className="mr-auto">
                              <a
                                href="#"
                                data-testid="save-story"
                                className="saveLink position-relative"
                                onClick={
                                  props.commentLists.length > 0 ||
                                  props.filterCommnetList.length > 0
                                    ? handleShowComment
                                    : null
                                }
                              >
                                {props.commentCount !== null &&
                                  props.commentCount > 0 && (
                                    <div className="notification">
                                      {props.commentCount}
                                    </div>
                                  )}
                                <img
                                  alt=""
                                  className="checkIcon"
                                  src="/images/icons/notifications.svg"
                                />
                              </a>
                            </Nav>
                          </Navbar>
                        )}
                    </Col>
                  )}
              </Row>
            </Col>
            <Col xs="2">
              <Navbar variant="dark">
                <Nav>
                  <Dropdown
                    direction="bottom"
                    className="user-account-dropdown"
                  >
                    <Dropdown.Toggle className="btnMainStory ">
                      <styled.userTitle>{tokenData.username} </styled.userTitle>
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                      <img
                        alt=""
                        src="/images/icons/face.svg"
                        className="ml-2 svg-icon-image"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="dropDownItemCss"
                        // onClick={handleMyStories}
                        onClick={() => autoSavePopupCall('mystory')}
                      >
                        <Link>My Stories</Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropDownItemCss"
                        onClick={() => autoSavePopupCall('myAccount')}
                        disabled={
                          props.selectedMenuAccount === 'myaccount' ||
                          props.selectedMenuAccount === 'changepassword'
                            ? 'disabled'
                            : ''
                        }
                      >
                        My Account
                      </Dropdown.Item>

                      {(tokenData.roles === '[ROLE_ADMIN]' ||
                        tokenData.roles === '[ROLE_EDITOR]') && (
                        <Dropdown.Item
                          className="dropDownItemCss"
                          onClick={() => autoSavePopupCall('manage')}
                        >
                          Manage
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        disabled={!showRepteatTutorial}
                        className="dropDownItemCss"
                        onClick={handleRepeatTour}
                      >
                        Reset Tutorials
                      </Dropdown.Item>

                      <Dropdown.Item
                        className="dropDownItemCss"
                        // onClick={handleLogout}
                        onClick={() => autoSavePopupCall('signOut')}
                        style={{ borderTop: '1px solid #D6D6D6' }}
                      >
                        Sign Out
                      </Dropdown.Item>
                      <Dropdown.Item className="dropDownItemCss">
                        <span className="version">
                          Version : {Constant.APP_VERSION}
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </styled.HeaderMenu>
        <Row>
          <div className="col-md-2">
            {props.currentSceneId !== null &&
              props.commandObj &&
              props.commandObj.lockAcquired === true &&
              props.tokenData.userId === props.commandObj.lockAcquiredBy &&
              props.history.location.pathname &&
              props.history.location.pathname.includes('/write-story') && (
                <div className="tooltip-head">
                  <div className="tooltip-content">
                    Allow other writers to edit this chapter by going back to
                    the chapter list.
                  </div>
                </div>
              )}
            &nbsp;
          </div>
          <div
            className={`${
              props.commandObj &&
              props.commandObj.lockAcquired === true &&
              props.tokenData.userId === props.commandObj.lockAcquiredBy
                ? 'col-md-6 p-0 offset-md-2 text-right saving_ellipses'
                : 'col-md-6 p-0 offset-md-2 text-right saving_ellipses'
            }`}
          >
            {props.history.location.pathname &&
              props.history.location.pathname.includes('/write-story') &&
              !props.isViewStory &&
              props.currentSceneId !== null && (
                <>
                  {(props.loading || props.autosaveFlagLoading) && (
                    <div className="storySaveLabel">
                      Saving
                      <span className="ellipsis-anim">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </span>{' '}
                    </div>
                  )}
                  {(!props.loading && !props.autosaveFlagLoading) && (
                    <div className="storySaveLabel">
                      Saved {moment(new Date(props.lastSavedTime)).fromNow()}{' '}
                    </div>
                  )}
                </>
              )}
          </div>
        </Row>
        {(props.commentLists.length > 0 ||
          props.filterCommnetList.length > 0) &&
          showComment && (
            <Comments
              commentFilter={commentFilter}
              commentLists={props.commentLists}
              filterCommnetList={props.filterCommnetList}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
              handleFilterClick={handleFilterClick}
              commentNumber={commentNumber}
              showPanels={props.showPanels}
              isOwnerOrCollab={props.isOwnerOrCollab}
              isViewStory={props.isViewStory}
              hidePopup={hidePopup}
              handleResolveComment={handleResolveComment}
              reply={reply}
              setReply={setReply}
              setComment={setComment}
              handleReplyComment={handleReplyComment}
              showReplyForm={showReplyForm}
              setShowReplyForm={setShowReplyForm}
              handleDeleteComment={handleDeleteComment}
              isCommentStory={props.isCommentStory}
              currentStoryStatus={props.currentStoryStatus}
              setShowCommentForm={setShowCommentForm}
              showCommentForm={showCommentForm}
              comment={comment}
              handleEditComment={handleEditComment}
              tokenData={props.tokenData}
            />
          )}

        {props.showApiError && (
          <Error
            show={props.showApiError}
            handleHideErrorModal={handleHideErrorModal}
            errormsg={props.errormsg}
          />
        )}

        {autoSaveFlagCheck === true && (
          <ConfirmAutoSavePopup
            firstLineLabel="You have unsaved work in the script. Do you wish to save your changes before leaving?"
            secondLineLabel="&nbsp;"
            onClose={closeAutoSaveFlagCheck}
            cancel="Leave without saving"
            confirm="Yes, Save"
            show={autoSaveFlagCheck}
            handleClearCharacter={onClickYes}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            autoSaveFlag={props.autoSaveFlag}
            currentSceneId={props.currentSceneId}
          />
        )}
        {viewAutoSaveFlagCheck === true && (
          <ConfirmAutoSavePopup
            firstLineLabel="You have unsaved work in the view. Do you wish to leave anyways?"
            secondLineLabel="&nbsp;"
            onClose={cancelViewAutoSavePopup}
            cancel="Cancel"
            confirm="Yes, leave"
            show={autoSaveFlagCheck}
            handleClearCharacter={leaveViewAutoSavePopup}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            autoSaveFlag={props.autoSaveFlag}
          />
        )}
        {characterAutoSaveFlagCheck === true && (
          <ConfirmAutoSavePopup
            firstLineLabel="You have unsaved work in the character. Do you wish to leave anyways?"
            secondLineLabel="&nbsp;"
            onClose={cancelCharacterAutoSavePopup}
            cancel="Cancel"
            confirm="Yes, leave"
            show={autoSaveFlagCheck}
            handleClearCharacter={leaveCharacterAutoSavePopup}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            autoSaveFlag={props.autoSaveFlag}
          />
        )}
      </div>
    </styled.Header>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    tokenData: state.LoginReducer.tokenData,
    storyId: state.StoryReducer.storyId,
    currentSceneId: state.FlowchartReducer.currentSceneId,
    lastSavedTime: state.StoryReducer.lastSavedTime,
    loading: state.StoryReducer.loading,
    autosaveFlagLoading:state.StoryReducer.autosaveFlagLoading,
    flowchartData: state.FlowchartReducer.flowchartData,

    commandObj: state.StoryReducer.commandObj,
    isViewStory: state.StoryReducer.isViewStory,

    commentLists: state.CommentReducer.commentLists,
    filterCommnetList: state.CommentReducer.filterCommnetList,
    commentCount: state.CommentReducer.commentCount,
    isCommentStory: state.StoryReducer.isCommentStory,
    currentStoryStatus: state.StoryReducer.currentStoryStatus,
    showPanels: state.LoginReducer.showPanels,
    isOwnerOrCollab: state.LoginReducer.isOwnerOrCollab,

    tutorials: state.LoginReducer.tutorials,
    selectedMenuAccount: state.MyAccountReducer.selectedMenu,
    showApiError: state.AccountReducer.showApiError,
    errormsg: state.AccountReducer.errormsg,
    autoSaveFlag: state.StoryReducer.autoSaveFlag,
    viewAutoSaveFlag: state.PlaceBackgroundReducer.autoSaveFlag,
    characterAutoSaveFlag: state.CharacterReducer.autoSaveFlag
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addLogin: (login, isLogOut, authToken = null) =>
      dispatch({
        type: ActionTypes.SET_LOGIN_DETAILS,
        payload: login,
        isLogOut: isLogOut,
        logoutAuthToken: authToken
      }),

    restoreSession: storage =>
      dispatch({
        type: ActionTypes.SET_LOCAL_STORAGE_LOGIN_DETAILS,
        payload: storage
      }),

    setTutorials: (data, authToken, flag) =>
      dispatch({
        type: ActionTypes.SET_TUTORIAL_DATA,
        payload: data,
        authToken: authToken,
        flag: flag
      }),
    hideErrorModal: () =>
      dispatch({
        type: ActionTypes.RESET_API_ERROR
      }),
    resetFlowChartData: isresetSceneId =>
      dispatch({
        type: ActionTypes.GET_RESET_FLOWCHART_DETAILS,
        isresetSceneId
      }),
    addStoryProgress: (story, authToken, storyId, sceneId) =>
      dispatch(
        StoryAction.setStoryProgress(story, authToken, storyId, sceneId)
      ),
    setCommandsData: (
      commandData,
      authToken,
      storyId,
      sceneId,
      savefromMenu = true,
      changeBlockName = null
    ) =>
      dispatch(
        setCommands(
          commandData,
          authToken,
          storyId,
          sceneId,
          savefromMenu,
          changeBlockName
        )
      ),
    fetchBlockchart: (authToken, storyId, blockId) =>
      dispatch({
        type: ActionTypes.GET_BLOCK_FLOWCHART_DETAILS,
        authToken: authToken,
        storyId: storyId,
        blockId: blockId
      }),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    getComments: (authToken, storyId, sceneId) =>
      dispatch(CommentAction.getComments(authToken, storyId, sceneId)),
    updateComments: (
      updateCommentList,
      authToken,
      storyId,
      sceneId,
      commentId,
      isComment
    ) =>
      dispatch(
        CommentAction.updateComments(
          updateCommentList,
          authToken,
          storyId,
          sceneId,
          commentId,
          isComment
        )
      ),
    setSelectedMenuAccount: menuName =>
      dispatch(MyAccountAction.setSelectedAccountMenu(menuName)),
    fetchStoryforAdmin: (authToken, authorNameAdded) =>
      dispatch(StoryAction.getStoryAdmin(authToken, authorNameAdded)),
    releseLock: (commandData, authToken, storyId, sceneId) =>
      dispatch(
        StoryAction.realeseLock(commandData, authToken, storyId, sceneId)
      ),
    autopopupSave: flag =>
      dispatch({
        type: ActionTypes.EDITOR_AUTO_SAVE_FLAG,
        flag: flag
      }),
    resetAllTextEditor: () =>
      dispatch({
        type: ActionTypes.RESET_COMMAND_DETAILS
      }),
    resetViewAutoSaveFlag: () =>
      dispatch(PlaceBackgroundAction.resetAutoSaveFlag()),
    resetViews: () => dispatch(PlaceBackgroundAction.resetPlaceBackground()),
    resetCharacterAutoSaveFlag: () =>
      dispatch(LibraryActions.resetAutoSaveFlag()),
    setAuthorName: authorName => dispatch(StoryAction.setAuthorName(authorName))
  };
};

const Headers = withRouter(Header);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Headers);

import React from 'react'
import { Modal } from 'react-bootstrap'
import ChangeLogModalScript from './changeLogPopup'
import moment from 'moment';

const ChangeLogModal = (props) => {
    return (
        <Modal
            show={props.showModal}
            dialogClassName="changeLogScriptView"
            aria-labelledby="example-custom-modal-styling-title"
            onHide={props.changeLogModalClose}
        >
            <Modal.Header closeButton={props.changeLogModalClose}>
                <div>
                    <div className="sub-title">{props.storyTitle}</div>
                    <div className="title">{props.storySubTitle}</div>
                </div>
            </Modal.Header>
            <Modal.Body style={{ width: "100%" }}>
                <div className='row'>
                    <div className="col-md-6 p-0 section-title">{moment(new Date(props.lastVersionDate)).format('MMM DD YYYY')} | Previous version submitted</div>
                    <div className="col-md-6 p-0 section-title">{moment().format('MMM DD YYYY')} | Current version</div>
                </div>
                <p>
                    <ChangeLogModalScript
                        data={props.commandList}
                        olderVersion={props.olderVersion}
                        newerVersion={props.newerVersion}
                        lastVersionDate={props.lastVersionDate}
                        storyTitle={props.storyTitle}
                        storySubTitle={props.storySubTitle}
                    />
                </p>

            </Modal.Body>
        </Modal>

    )
}

export default ChangeLogModal
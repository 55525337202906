import React, { useState, useEffect } from 'react'
import * as AccoundDetail from './style/myaccount'
import { Form, Button,Row, Col } from 'react-bootstrap';
import { PASSWORD_PATTERN, PASSWORD_BAD, PASSWORD_GOOD } from '../../common/helper/pattern';


const ChangePassword = (props) => {

    const [disable, setDisable] = useState('disabled')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [oldPasswordError, setOldPasswordError] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')
    const [validated, setValidated] = useState(false);

    const [changePasswordError, setChangePasswordError] = useState(null)
    const [indicator, setIndicator] = useState('')
    const [indicatorValue, setIndicatorValue] = useState(0)



    useEffect(() => {
        setChangePasswordError(props.errorPassword)
    }, props.errorPassword);

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setValidated(true);

            if (newPassword !== confirmPassword) {
                setConfirmPasswordError('Passwords must match')
            }
            else if (new RegExp(PASSWORD_PATTERN).test(newPassword) !== true) {
                setNewPasswordError('New password must have at least 8 characters with at least 1 uppercase, 1 lowercase, 1 number and 1 special symbol');
                setDisable('disabled');
            } else {
                props.changePasswordMyAccount(props.authToken, oldPassword, newPassword, confirmPassword)
            }
        }
        event.preventDefault();
    };

    const oldPasswordChange = (event) => {
        const oldPwd = event.target.value
        setOldPassword(oldPwd)
        setChangePasswordError('');
        setIndicator('')
        if (oldPwd === '') {
            setDisable('disabled')
            setOldPasswordError('Please enter a current password')
            // ('')
        } else {
            // setErrorUser('')
            if (oldPwd !== '' && newPassword !== '' && confirmPassword !== '') {
                setDisable('')
            }
            setOldPasswordError('')
        }
    }

    const newPasswordChange = (event) => {
        const newPwd = event.target.value
        setNewPassword(newPwd)
        if (newPwd === '') {
            setDisable('disabled')
            setNewPasswordError('Please enter a new password')
            setIndicatorValue({ 'value': '0%', 'color': '', 'text': '' })
            setIndicator('')
        } else {
            setNewPasswordError('')
            if (oldPassword !== '' && newPwd !== '' && confirmPassword !== '') {
                setDisable('')
            }
            if (new RegExp(PASSWORD_PATTERN).test(newPwd) !== true) {
                setNewPasswordError("The password doesn't match the rules specified.");
                setDisable('disabled');
            }
            if (new RegExp(PASSWORD_PATTERN).test(newPwd) === true) {
                setIndicator('Strong')
                setIndicatorValue({ 'value': '100%', 'color': '#147412', 'text': '#FFFFFF' })
                if (oldPassword !== '' && newPwd !== '' && confirmPassword !== '' &&  newPwd === confirmPassword ) {
                  setDisable('')
              }else{
                setDisable('disabled')
              }
                
            } else if (new RegExp(PASSWORD_GOOD).test(newPwd) === true) {
                setIndicator('Normal')
                setIndicatorValue({ 'value': '60%', 'color': '#E8C766', 'text': '#FFFFFF' })

            } else if (new RegExp(PASSWORD_BAD).test(newPwd) === true) {
                setIndicator('Weak')
                setIndicatorValue({ 'value': '40%', 'color': '#832533', 'text': '#FFFFFF' })
            } else {
                setIndicator('Too short')
                setIndicatorValue({ 'value': '10%', 'color': '#FF0000', 'text': '#333333' })
            }
        }
    }

    const confirmPasswordChange = (event) => {
        const confirmPwd = event.target.value
        
        setConfirmPassword(confirmPwd)
        setIndicatorValue(null)
        setIndicator('')
        if (confirmPwd === '') {
            setDisable('disabled')
            setConfirmPasswordError('Please enter a confirm password')
        } else {
           setConfirmPasswordError('')
            if (confirmPwd !== '' && newPassword !== '' && oldPassword !== '' &&  newPassword === confirmPwd) {
                setDisable('')
            }else{
              if(newPassword !== confirmPwd){
                setConfirmPasswordError("The password doesn't match")
              }
              setDisable('disabled')
            }
           
        }
    }

    return (
        <AccoundDetail.MyAccountDetail>
            <Form
                
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autocomplete="off"
            >
            <Row><Col xs={5}> 
                <Form.Group controlId="formGroupcurrent">
                    <h3 className="title-label">CHANGE YOUR PASSWORD</h3>
                    <Form.Label>Current password</Form.Label>
                    <input id="password" style={{display:'none'}} type="password" name="fakepasswordcurrent"/>
                    <Form.Control
                        type="password"
                        className={oldPasswordError !== '' || changePasswordError !== null ? 'input-error form-input' : 'form-input'}
                        value={oldPassword}
                        onChange={(event) => oldPasswordChange(event)}
                        onBlur={(event) => oldPasswordChange(event)}
                        maxLength="20"
                        autoComplete="current-password"
                        required
                        id="real-password"
                    />
                    {
                        oldPasswordError !== '' ?
                            <Form.Text className="text-danger">
                                {oldPasswordError}
                            </Form.Text> :
                            <Form.Control.Feedback type="invalid">
                                Please enter a current password
                                </Form.Control.Feedback>
                    }
                    {
                        changePasswordError !== '' &&
                        <Form.Text className="text-danger">
                            {changePasswordError}
                        </Form.Text>
                    }
                </Form.Group>
                </Col></Row>
<Row><Col xs={5}>
                <Form.Group controlId="formGroupNewPassword">
                    <Form.Label>New password</Form.Label>
                    <input id="Newpassword" style={{display:'none'}} type="password" name="fakepasswordnew"/>
                    <Form.Control
                        type="password"
                        className={newPasswordError !== '' ? 'input-error form-input' : 'form-input'}
                        value={newPassword}
                        onChange={(event) => newPasswordChange(event)}
                        onBlur={(event) => newPasswordChange(event)}
                        maxLength="20"
                        autoComplete="new-password"
                        required
                        autoComplete="false"
                        id="new-password"
                        

                    />
                    {
                        indicator !== '' && indicator !== null &&

                        < div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={indicatorValue.value}
                                aria-valuemin="0" aria-valuemax="100" style={{ width: indicatorValue.value, backgroundColor: indicatorValue.color, color: indicatorValue.text }}>
                                {/* {indicator} */}
                            </div>
                        </div>
                    }
                    {
                        newPasswordError !== '' ?
                            <Form.Text className="text-danger">
                                {newPasswordError}
                            </Form.Text> :
                            <Form.Control.Feedback type="invalid">
                                Please enter a new password
                                </Form.Control.Feedback>
                    }
                </Form.Group>

                <Form.Group controlId="formGroupconfirmPassword">
                    <Form.Label>Confirm new password</Form.Label>
                    <input id="confirmpassword" style={{display:'none'}} type="password" name="fakepasswordconfirm"/>
                    <Form.Control
                        type="password"
                        className={confirmPasswordError !== '' ? 'input-error form-input' : 'form-input'}
                        value={confirmPassword}
                        onChange={(event) => confirmPasswordChange(event)}
                       
                        onInput={(event) => confirmPasswordChange(event)}
                        maxLength="20"
                        autoComplete="confirm-password"
                        required
                        id="confirm-password"
                    />
                    {
                        confirmPasswordError !== '' ?
                            <Form.Text className="text-danger">
                                {confirmPasswordError}
                            </Form.Text> :
                            <Form.Control.Feedback type="invalid">
                                Please enter a confirm password
                                </Form.Control.Feedback>
                    }
                </Form.Group>
                </Col>
                <Col><div className="info-container">
                 <Row><Col className="mt-2">
                 New password must be:
                 </Col></Row>
                 
                 <Row><Col className="info-data  mt-2 mb-2">
                 <ol class="info-list">
                 <li>  <span> 8 characters </span></li>
                 <li> <span> Contain one uppercase </span></li>
                 <li> <span> Contain one lowercase  </span></li>
                 <li> <span> Contain one number  </span></li>  <li><span> Contain one(!_@#$%&) special symbol  </span></li>

               
                
              </ol>

                
                 </Col></Row>
                </div></Col></Row>
                <Button
                    variant="primary"
                    className='btn-snacktool btn-snacktool-golden btn btn-primary'
                    type="submit"
                    disabled={disable}
                >
                    Save
                </Button>
            </Form>
        </AccoundDetail.MyAccountDetail >
    )
}

export default ChangePassword
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { LibraryStyle } from './style/style';
import CreateWidgets from './create-widgets';
import { StoryService } from './service';
import { COMMAND_STOP_MUSIC } from './../../common/constants';

let updateTotalFormInput = [];
let uniquefilterArray = null;

const CommandsLevel2 = ({
  loading,
  commandsList,
  handleSetLevel,
  handleSaveCommandInfo,
  validated,
  isValidText,
  handleTextFieldChange,
  flowchartNode,

  commandId,
  full_name_toggle,

  characterList,

  isCommandEdit,
  variableListDTO
}) => {
  const [currentIndexOriginal] = useState(2);
  const [filterCharacter, setFilterCharacter] = useState(undefined);

  const commandLevel2Wrapper = useRef(null);
  updateTotalFormInput = [];

  let allCommands = commandsList ? commandsList.commands : [];
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleSetLevel(1);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('click', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside);
      };
    });
  }

  useOutsideAlerter(commandLevel2Wrapper);

  if (!loading) {
    let uniqueData = StoryService.getFilterArray(allCommands);
    uniquefilterArray = uniqueData[0];
    updateTotalFormInput = uniqueData[1];
    return (
      <LibraryStyle.commandPanel
        id="command-level-2-lib"
        ref={commandLevel2Wrapper}
      >
        <div className="command-head-panel">
          <div onClick={() => handleSetLevel(1)}>
            <i className="fa fa-angle-left  head-icon" />
          </div>
          <div className="title-block">
            <span className="title">{commandsList.title}</span>
            <span className="sub-title d-flex align-items-center">
              {commandsList.subTitle}{' '}
              {commandsList.hints !== '' && commandsList.hints !== null ? (
                <OverlayTrigger
                  key={`tooltip-${commandsList.subTitle}`}
                  placement="top"
                  overlay={
                    <Tooltip
                      id={`tooltip-${commandsList.title}`}
                      className="tooltip-320"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${commandsList.hints}`
                        }}
                      ></div>
                    </Tooltip>
                  }
                >
                  <span className="info-icon ml-1"></span>
                </OverlayTrigger>
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
        <div className="commands-body">
          <Form
            onSubmit={event =>
              handleSaveCommandInfo(
                event,
                commandsList.commandSyntax,
                commandsList.color,
                uniquefilterArray
              )
            }
            noValidate
            validated={validated}
            key={`form-${commandId}`}
          >
            <div className="commands-2">
              <CreateWidgets
                components={allCommands}
                key={commandId}
                isValidText={isValidText}
                handleTextFieldChange={handleTextFieldChange}
                updateTotalFormInput={updateTotalFormInput}
                uniquefilterArray={uniquefilterArray}
                flowchartNode={flowchartNode}
                commandId={commandId}
                currentIndexOriginal={currentIndexOriginal}
                characterList={characterList}
                isCommandEdit={isCommandEdit}
                filterCharacter={filterCharacter}
                setFilterCharacter={setFilterCharacter}
                variableListDTO={variableListDTO}
                full_name_toggle={full_name_toggle}
              />
            </div>
            <div>
              <SubmitButton
                key={`submitButton`}
                submit={commandsList.submit}
                commandId={commandId}
              />
            </div>
          </Form>
        </div>
      </LibraryStyle.commandPanel>
    );
  } else {
    return (
      <LibraryStyle.commandPanel>
        <Spinner
          className="spinner-class"
          animation="border"
          variant="primary"
        />
      </LibraryStyle.commandPanel>
    );
  }
};

const SubmitButton = ({ submit, commandId }) => {
  return (
    <>
      {/* {
                commandId === COMMAND_STOP_MUSIC &&
                <span className="commandLabelSuffix">
                    Current background music will stop playing
                </span>
            } */}
      <Button
        type="submit"
        className="btn-snacktool btn-snacktool-golden btn btn-primary submit-btn mt-3"
        variant="info"
      >
        {submit.label}
      </Button>
    </>
  );
};

export default CommandsLevel2;

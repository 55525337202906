import React, {useEffect, useState, useRef} from 'react';
import { Button, Form } from 'react-bootstrap';

const FormEditChapter = (props) => {
    const [editChapterName, setEditChapterName] =  useState(props.chapterName);
    const [disabledButton, setDisabledButton] =  useState('')
    const [storyScenesErrorsDisplay, setStoryScenesErrorsDisplay] =  useState(null)
    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                props.handleEditChapterProgress(props.chapterName)
            }
        }

        useEffect(() => {
            // Bind the event listener
            document.addEventListener("click", handleClickOutside);
            return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
            };
        });
    }

    useOutsideAlerter(wrapperRef);

    const setNewEditChapterNameValidation = (value) => {
        const nameValueValid = value.trim()
        if (nameValueValid === '') {
            setEditChapterName('')
            setStoryScenesErrorsDisplay('Please enter valid chapter name for edit')
            setDisabledButton('disabled')
        } else {
            setDisabledButton('')
            setEditChapterName(value)
        }
    }

    const setEditChapterNameCheck = (event, value) => {
        const nameValue = value.trim()
        if (nameValue === '') {
            setEditChapterName('')
            setStoryScenesErrorsDisplay('Please enter valid chapter name for edit')
        } else {
            if(props.chapterName === nameValue) {
                props.handleEditChapterProgress(value)
            }else if(props.allChapterList.every((chapter) => chapter.name !== nameValue)) {
                props.handleEditChapterProgress(value)
                setStoryScenesErrorsDisplay(null)
            } else {
                setStoryScenesErrorsDisplay('Chapter name already exists')
            }
        }
        event.preventDefault()
    }

    return(
        <div>
            <Form method="POST" onSubmit={(evt) => setEditChapterNameCheck(evt, editChapterName)} style={{}} ref={wrapperRef}>
                <Form.Group>
                    <Form.Control
                        required
                        type = "text"
                        name = "scenename"
                        id = "scenename"
                        // value = {editChapterName}
                        placeholder = "Chapter Name"
                        maxLength = "100"
                        value={editChapterName}
                        onChange = {(evt) => setNewEditChapterNameValidation(evt.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Button
                        id="createSceneBtn" 
                        type="submit" 
                        className="btn-snacktool btn-snacktool-brown"
                        disabled = {disabledButton}
                    >
                        Save
                    </Button>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                        Please enter valid and unique chapter name
                </Form.Control.Feedback> 
            </Form>
            {
                storyScenesErrorsDisplay !== null &&
                    <div className= "sceneError mb-0">{storyScenesErrorsDisplay}</div>
            }
        </div>
    )
}

export default FormEditChapter
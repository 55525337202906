import { takeLatest, put, call } from 'redux-saga/effects';
import * as Actions from './../actions/place-background-action';
import * as ActionTypes from './../actions/place-background-action-types';
import {
  getBackgroundImagesApi,
  saveView,
  getViews,
  editable_flags,
  otherViewsAPI
} from './api/place-background-api';
import * as ActionTypesError from '../actions/action-types';

export function* getBackgroundImages(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(getBackgroundImagesApi, payload);
      if (data.result === 'Success') {
        yield put(Actions.getBackgroundImagesSuccess(data.body));
      } else {
        yield put(Actions.getBackgroundImagesFail());

        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypesError.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypesError.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(Actions.getBackgroundImagesFail());
      yield put({
        type: ActionTypesError.SET_API_ERROR
      });
    }
  }
}

export function* saveViewLaterWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(saveView, payload);
      if (data.result === 'Success') {
        yield put(Actions.getViews(payload.storyId, payload.authToken));
        yield put(Actions.setActiveKey('browse'));
        yield put(Actions.resetPlaceBackground());
      } else {
        yield put(Actions.setActiveKey('view'));
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypesError.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypesError.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(Actions.setActiveKey('view'));
      yield put({
        type: ActionTypesError.SET_API_ERROR
      });
    }
  }
}

export function* getViewsWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(getViews, payload);
      if (data.result === 'Success') {
        yield put(Actions.getViewsSuccess(data.body));
      } else {
        yield put(Actions.getViewsFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypesError.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypesError.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(Actions.getViewsFail());
      yield put({
        type: ActionTypesError.SET_API_ERROR
      });
    }
  }
}

export function* getViewsEditableWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(editable_flags, payload);
      if (data.result === 'Success') {
        yield put(Actions.getViewsEditableSuccess(data.body));
      } else {
        yield put(Actions.getViewsEditableFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypesError.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypesError.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(Actions.getViewsFail());
      yield put({
        type: ActionTypesError.SET_API_ERROR
      });
    }
  }
}

export function* getViewsOtherFromId(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(otherViewsAPI, payload);
      if (data.result === 'Success') {
        yield put(Actions.getOtherViewsFromIdSuccess(data.body));
      } else {
        yield put(Actions.getOtherViewsFromIdFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypesError.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypesError.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(Actions.getViewsFail());
      yield put({
        type: ActionTypesError.SET_API_ERROR
      });
    }
  }
}

export function* placeBackgroundDataWatcher() {
  yield takeLatest(ActionTypes.GET_BACKGROUND_IMAGES, getBackgroundImages);
}

export function* saveViewLaterWatcher() {
  yield takeLatest(ActionTypes.SAVE_VIEW_LATER, saveViewLaterWorker);
}

export function* getViewsWatcher() {
  yield takeLatest(ActionTypes.GET_VIEWS, getViewsWorker);
}

export function* getEditableViewFlagWatcher() {
  yield takeLatest(ActionTypes.GET_VIEWS_EDITABLE, getViewsEditableWorker);
}

export function* getOtherViewFromIdWatcher() {
  yield takeLatest(ActionTypes.GET_OTHER_CREATED_VIEWS, getViewsOtherFromId);
}

export default {
  placeBackgroundDataWatcher,
  saveViewLaterWatcher,
  getViewsWatcher,
  getEditableViewFlagWatcher,
  getOtherViewFromIdWatcher
};

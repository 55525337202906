import React from 'react';
import * as Constant from './../../../common/constants';

function DropdownOptions({
  component,
  flowchartNode,
  commandId,
  currentSelected,
  characterList,
  filterCharacter,
  defaultValue,
  variableFields,
  variableListDTO,
  checkbox,
  checkboxisChange,
  fullNameToggle
}) {
  // const dependsOn = component.properties.dependsOn;
  let optionsValueArray = [];
  let defaultOption = (
    <option key={component.placeHolder} value="">
      {component.placeHolder}
    </option>
  );
  let options = component.properties.values;

  if (
    component.widgetName === 'positionDropdown' ||
    component.widgetName === 'appearDropdown' ||
    component.widgetName === 'transitionTypeDropdown'
  ) {
    defaultOption = '';
  }

  switch (commandId) {
    case Constant.COMMAND_SET_VAR:
      switch (component.widgetName) {
        case 'operatorDropdown':
          switch (variableFields) {
            case 'Boolean':
              options = Constant.OPERATOR_FOR_SETVAR_BOOLEAN;
              break;
            case 'String':
              options = Constant.OPERATOR_FOR_SETVAR_STRING;
              break;
            default:
              options = Constant.OPERATOR_FOR_INTEGER_FLOAT;
              break;
          }
          optionsValueArray = options.map(opt => (
            <option
              key={opt.id ? opt.id : opt.name}
              value={opt.id ? opt.id : opt.name}
            >
              {opt.displayName ? opt.displayName : opt.name}
            </option>
          ));
          break;

        case 'variableDropdownSelect':
          options = variableListDTO.filter(
            variable =>
              variable.datatype === variableFields &&
              variable.name.search('-') === -1
          );
          if (options.length > 0) {
            optionsValueArray = options.map(opt => (
              <option key={opt.name} value={opt.name}>
                {opt.name}
              </option>
            ));
          }
          break;

        default:
          optionsValueArray = options.map(opt => (
            <option
              key={opt.id ? opt.id : opt.name}
              value={opt.id ? opt.id : opt.name}
            >
              {opt.displayName ? opt.displayName : opt.name}
            </option>
          ));
          break;
      }
      break;

    case Constant.COMMAND_ADD_IF:
    case Constant.COMMAND_ADD_ELSE_IF:
      if (component.widgetName === 'operatorDropdown') {
        options = Constant.OPERATOR_FOR_OTHER;
        const listSelectedVar = variableListDTO.filter(
          varialeList => varialeList.name === variableFields
        );
        if (listSelectedVar.length > 0) {
          if (
            listSelectedVar[0].datatype === 'Boolean' ||
            listSelectedVar[0].datatype === 'String'
          )
            options = Constant.OPERATOR_FOR_BOOLEAN;
        }
        optionsValueArray = options.map(opt => (
          <option
            key={opt.id ? opt.id : opt.name}
            value={opt.id ? opt.id : opt.name}
          >
            {opt.displayName ? opt.displayName : opt.name}
          </option>
        ));
      } else {
        optionsValueArray = options.map(opt => (
          <option
            key={opt.displayName ? opt.displayName : opt.name}
            value={opt.id ? opt.id : opt.name}
          >
            {opt.displayName ? opt.displayName : opt.name}
          </option>
        ));
      }
      break;

    case Constant.COMMAND_CHOICE_TIMER:
    case Constant.COMMAND_PAID_ADD_DECISION:
    case Constant.COMMAND_GO_TO_BLOCK:
      if (
        'destination' === component.label.toLowerCase() ||
        'destination if time is up' === component.label.toLowerCase()
      ) {
        optionsValueArray = flowchartNode.map(opt => (
          <option key={opt.id} value={opt.id}>
            {opt.displayName}
          </option>
        ));
      } else {
        optionsValueArray = options.map(opt => (
          <option
            key={opt.displayName ? opt.displayName : opt.name}
            value={opt.id ? opt.id : opt.name}
          >
            {opt.displayName ? opt.displayName : opt.name}
          </option>
        ));
      }
      break;

    case Constant.COMMAND_ENTER_THE_SCENE:
    case Constant.COMMAND_CHANGE_EXPRESSION:
    case Constant.COMMAND_LEAVES_THE_SCENE:
    case Constant.COMMAND_REPLACE_CHARACTER:
    case Constant.COMMAND_REPLACE_OUTFIT:
    case Constant.COMMAND_SET_OUTFIT:
    case Constant.COMMAND_SHOW_OUTFIT:
      switch (component.widgetName) {
        case 'outFitDropdown':
          if (filterCharacter) {
            optionsValueArray = filterCharacter.outfits.map(opt => (
              <option
                key={opt.id}
                value={opt.id}
                selected={
                  parseInt(opt.id) === parseInt(defaultValue) ? 'selected' : ''
                }
              >
                {opt.assetName}
              </option>
            ));
          }
          break;

        case 'expressionDropdown':
          if (filterCharacter) {
            optionsValueArray = filterCharacter.expressions.map(opt => (
              <option
                key={opt.id}
                value={opt.id}
                selected={
                  parseInt(opt.id) == parseInt(defaultValue) ? 'selected' : ''
                }
              >
                {opt.assetName}
              </option>
            ));
          }
          break;

        case 'hairDropdown':
          if (filterCharacter) {
            optionsValueArray = filterCharacter.hairs.map(opt => (
              <option
                key={opt.id}
                value={opt.id}
                selected={opt.id == defaultValue ? 'selected' : ''}
              >
                {opt.assetName}
              </option>
            ));
          }
          break;

        case 'accessoryDropdown':
          if (filterCharacter) {
            optionsValueArray = filterCharacter.accessories.map(opt => (
              <option
                key={opt.id}
                value={opt.id}
                selected={opt.id == defaultValue ? 'selected' : ''}
              >
                {opt.assetName}
              </option>
            ));
          }
          break;

        case 'characterDropdown':
        case 'originalCharacterDropdown':
          optionsValueArray = characterList.map(opt => (
            <option key={opt.id} value={opt.id}>
              {(checkbox == true && checkboxisChange == 'change') ||
              (fullNameToggle == 'true' && checkboxisChange == '')
                ? opt.characterLongName
                : opt.characterName}
            </option>
          ));
          break;

        case 'newCharacterDropdown':
          options = characterList.filter(
            characterId =>
              parseInt(characterId.id) !== parseInt(currentSelected)
          );
          optionsValueArray = options.map(opt => (
            <option key={opt.displayName} value={opt.id}>
              {(checkbox == true && checkboxisChange == 'change') ||
              (fullNameToggle == 'true' && checkboxisChange == '')
                ? opt.characterLongName
                : opt.characterName}
            </option>
          ));
          break;

        default:
          optionsValueArray = options.map(opt => (
            <option key={opt.displayName} value={opt.id}>
              {opt.displayName}
            </option>
          ));
          break;
      }
      break;
    default:
      if (component.widgetName === 'CharacterDropdown') {
        optionsValueArray = options.map(opt => (
          <option
            key={opt.displayName ? opt.displayName : opt.name}
            value={opt.id ? opt.id : opt.name}
          >
            {checkbox ? opt.characterLongName : opt.characterShortName}
          </option>
        ));
      } else {
        optionsValueArray = options.map(opt => (
          <option
            key={opt.displayName ? opt.displayName : opt.name}
            value={opt.id ? opt.id : opt.name}
          >
            {opt.displayName ? opt.displayName : opt.name}
          </option>
        ));
      }
  }

  if (
    commandId === Constant.COMMAND_ENTER_THE_SCENE ||
    commandId === Constant.COMMAND_GO_TO_BLOCK ||
    commandId === Constant.COMMAND_CHANGE_EXPRESSION ||
    commandId === Constant.COMMAND_LEAVES_THE_SCENE ||
    commandId === Constant.COMMAND_REPLACE_CHARACTER ||
    commandId === Constant.COMMAND_ADD_DIALOGUE ||
    commandId === Constant.COMMAND_PAID_ADD_DECISION ||
    commandId === Constant.COMMAND_ADD_IF ||
    commandId === Constant.COMMAND_ADD_ELSE_IF ||
    commandId === Constant.COMMAND_SHOW_OBJECT ||
    commandId === Constant.COMMAND_HIDE_OBJECT ||
    commandId === Constant.COMMAND_TAP_OBJECT ||
    commandId === Constant.COMMAND_PLAY_MUSIC ||
    commandId === Constant.COMMAND_PLAY_SOUND ||
    commandId === Constant.COMMAND_GOTO_CHAPTER ||
    commandId === Constant.COMMAND_SAY_GENDERED ||
    commandId === Constant.COMMAND_SHOW_OUTFIT ||
    commandId === Constant.COMMAND_SET_OUTFIT ||
    commandId === Constant.COMMAND_REPLACE_OUTFIT ||
    commandId === Constant.COMMAND_SHAKE_CAMERA
  ) {
    optionsValueArray.unshift(defaultOption);
  }

  return optionsValueArray;
}

export default DropdownOptions;

import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { ModalStyled } from './Style';

function Fail(props) {
    return <Modal show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'>
        <Modal.Body>
            <ModalStyled.addAssetMessageBox>
                <ModalStyled.successAssetContent>
                    <Row>
                        <Col className="">
                            <img src="/images/icons/clear.svg" className="mediumIcon mb-2"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="submit-asset-label"
                            data-testid="fail-label">
                            {props.label}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-muted text-note"
                            data-testid="fail-note">
                            {props.mutedText}
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Button
                                onClick={() => { props.handleClick() }}
                                data-testid="fail-btn"
                                className="btn-snacktool btn-snacktool-golden">
                                Okay
                                </Button>
                        </Col>
                    </Row>
                </ModalStyled.successAssetContent>
            </ModalStyled.addAssetMessageBox>
        </Modal.Body>
    </Modal>
}

export default Fail

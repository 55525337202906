export const GET_GAME_INTERFACE = 'GET_GAME_INTERFACE';
export const GET_GAME_INTERFACE_SUCCESS = 'GET_GAME_INTERFACE_SUCCESS';
export const GET_GAME_INTERFACE_FAIL = 'GET_GAME_INTERFACE_FAIL';

export const SET_SHOW_MODAL_TRUE = 'SET_SHOW_MODAL_TRUE';
export const SET_SHOW_MODAL_FALSE = 'SET_SHOW_MODAL_FALSE';

export const SAVE_ELEMENT = 'SAVE_ELEMENT';
export const SAVE_ELEMENT_SUCCESS = 'SAVE_ELEMENT_SUCCESS';
export const SAVE_ELEMENT_FAIL = 'SAVE_ELEMENT_FAIL';

export const SET_ACTIVE_TAB_KEY = 'SET_ACTIVE_TAB_KEY';

export const SET_RESET_ELEMENT = 'SET_RESET_ELEMENT';
export const SET_RESET_ELEMENT_SUCCESS = 'SET_RESET_ELEMENT_SUCCESS';
export const SET_RESET_ELEMENT_FAIL = 'SET_RESET_ELEMENT_FAIL';

export const HIDE_RESET_SUCCESS_MODAL = 'HIDE_RESET_SUCCESS_MODAL';

export const SET_GAME_FONTS = 'SET_GAME_FONTS';
export const SET_GAME_FONTS_SUCCESS = 'SET_GAME_FONTS_SUCCESS';
export const SET_GAME_FONTS_FAIL = 'SET_GAME_FONTS_FAIL';

export const GET_GAME_FONTS = 'GET_GAME_FONTS';
export const GET_GAME_FONTS_SUCCESS = 'GET_GAME_FONTS_SUCCESS';
export const GET_GAME_FONTS_FAIL = 'GET_GAME_FONTS_FAIL';
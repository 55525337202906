import React from 'react';
import { Row, Col} from 'react-bootstrap';
import './../style/style.css';
import UploadFile from './UploadFile';
import UploadTitle from './UploadTitle';
import UploadFilesAlbum from './UploadFilesAlbum';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const AddFiles = props => {
  let thumbs = [];

  if (
    props.editStory &&
    props.storyDetails.titleCover === null &&
    props.titleCoverFiles &&
    props.titleCoverFiles.length === 0
  ) {
    thumbs = [];
  } else {
    if (props.editStory && props.clearfile === false) {
      thumbs = [];
    } else {
      thumbs = `<img class='epmtyBorder' src=${props.storyDetails.titleCover} key=${props.storyDetails.titleCover} height="100px" alt='' />`;
    }
  }

  let borderthumbs = [];

  if (
    props.editStory &&
    props.storyDetails.titleCover === null &&
    props.titleCoverFiles &&
    props.titleCoverFiles.length === 0
  ) {
    borderthumbs = [];
  } else {
    if (props.editStory && props.clearfile === false) {
      borderthumbs = [];
    } else {
      borderthumbs = `<img src=${props.storyDetails.titleCover} class='fileBorder' key=${props.storyDetails.titleCover} height="100px" alt='' />`
    }
  }

  // showSeriesIamge StoryCoverBorder

  const StoryCover = styled.div`
    box-sizing: border-box;
    width: 100%;

    height: 140px;
    background: url(${props.storyDetails.seriesBackground === null ? './images/Series_background_placeholder.svg' : props.storyDetails.seriesBackground})
      no-repeat;
    opacity: 1;
    background-size: cover;
  `;

  const StoryCoverBorder = styled.div`
    box-sizing: border-box;
    width: 100%;

    height: 140px;
    background: url(${props.storyDetails.seriesBackground === null ? './images/Series_background_placeholder.svg' : props.storyDetails.seriesBackground})
      no-repeat;
    opacity: 1;
    background-size: cover;
  `;

  const StoryTitle = styled.div`
    box-sizing: border-box;
    margin: 1% 7% 0 16%;
    width: 70%;
    height: 34px;
    background: url(${props.storyDetails.artTitle === null ? './images/Story_title_placeholder.svg' : props.storyDetails.artTitle}) no-repeat;
    opacity: 1;
    background-size: cover;
    border: 4px solid transparent;
  `;

  const StoryTitleBorder = styled.div`
    box-sizing: border-box;

    margin: 1% 7% 0 16%;
    width: 70%;
    height: 34px;
    background: url(${props.storyDetails.artTitle === null ? './images/Story_title_placeholder.svg' : props.storyDetails.artTitle}) no-repeat;
    opacity: 1;
    background-size: cover;
    border: 4px solid transparent;
  `;
  return (
    <div className="upload-files-wrapper d-flex mt-4">
      <Row className="row-100">
        <Col className="padding-0" md={4}>
          <UploadFilesAlbum
            storyDetails={props.storyDetails}
            titleCoverFiles={[]}
            highlightALbum={props.highlightALbum}
            resetAlbumBorder={props.resetAlbumBorder}
          />
          <UploadFile
            storyDetails={props.storyDetails}
            highlightSeries={props.highlightSeries}
            resetSeriesBorder={props.resetSeriesBorder}
          />
          <UploadTitle
            // setShowClearFile={props.setShowClearFile}
            // setContinueToAddInfoClass={props.setContinueToAddInfoClass}
            // editStory={props.editStory}
            storyDetails={props.storyDetails}
            // handleResetArtFile={props.handleResetArtFile}
            // activeTabKey={props.activeTabKey}
            // setAsset={props.setAsset}
            // asset={props.asset}
            // setArtTitleFiles={props.setArtTitleFiles}
            artTitleFiles={props.artTitleFiles}
            // artClearfile={props.artClearfile}
            // setArtClearFile={props.setArtClearFile}
            highlighttitle={props.highlighttitle}
            resetTitleBorder={props.resetTitleBorder}
          />
          <div className="series-background-upload">
            <Row className="margin-20">
              <Col className="subtitle-text-uplaod">
                Supported file format: PNG. File size limit: 15 Mb.
              </Col>
            </Row>
            <Row className="margin-16">
              <Col className="subtitle-text-uplaod">
                You can edit this story info and add these images later.
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={4}>
          <div className="cover-heading mb-2">Fictif app stories preview</div>
          <Row>
            <Col className="mid-col-wrapper">
              <Row>
                <Col>
                  <img src="/images/Fictif_logo.png" className="ml-1" />
                </Col>
              </Row>
              <Row className="margin-footer">
                <Col>
                  <Row>
                    <Col className="ml-4 mr-3">
                      {props.storyDetails.titleCover !== null && (
                        <div>
                          <div className="border-box">
                            <div>
                              {props.showAlbumIamge === true && (
                                <aside className="thumbs-container">
                                  {props.storyDetails.titleCover !== null && (
                                      <div>{ReactHtmlParser(borderthumbs)}</div>
                                    )}
                                </aside>
                              )}
                              {props.showAlbumIamge === false && (
                                <aside className="thumbs-container">
                                  {props.storyDetails.titleCover !== null && (
                                  <div>{ReactHtmlParser(thumbs)}</div>
                                  )}
                                </aside>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {props.storyDetails.titleCover === null && (
                        <img
                          className={
                            props.showAlbumIamge === true
                              ? 'fileBorder ml-1'
                              : 'epmtyBorder ml-1'
                          }
                          src="/images/cover.svg"
                        />
                      )}

                      <div className="mt-1 cover-title-main">{`${
                        props.storyDetails.title !== ''
                          ? props.storyDetails.title
                          : 'Another Story'
                      }`}</div>
                    </Col>
                    <Col className="ml-3 mr-3 epmtyBorder ">
                      <div className="cover-grey  "></div>
                      <div className="mt-2 cover-title">Another Story </div>
                    </Col>
                  </Row>
                  <Row className="margin-footer">
                    <Col className="ml-4 mr-3 epmtyBorder">
                      <div className="cover-grey "></div>
                      <div className="mt-1 cover-title"> Another Story </div>
                    </Col>
                    <Col className="ml-4 mr-3 epmtyBorder ">
                      <div className="cover-grey "></div>
                      <div className="mt-1 cover-title"> Another Story </div>
                    </Col>
                  </Row>
                  <Row className="margin-footer">
                    <Col>
                      <img src="/images/Nav_bar.svg" className="ml-1" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col md={4}>
          <div className="cover-heading mb-2">
            Fictif app story page preview
          </div>
          <Row>
            <Col className="mid-col-wrapper">
              <Row>
                <Col className="margin-5" id="seriesFile">
                  <div>
                    <div>
                      <div className="thumbs-container-cover">
                        {props.showSeriesIamge === false && (
                          <StoryCover style={{ border: '4px solid' }}>
                            <div className="title-wrapper"></div>
                            <div className="title-caption">
                              <div className="mngl" id="titleFile">
                                <div>
                                  <div>
                                    <div>
                                      {' '}
                                      {props.showTitleIamge === false && (
                                        <StoryTitle></StoryTitle>
                                      )}
                                      {props.showTitleIamge && (
                                        <StoryTitleBorder
                                          style={{
                                            border: '4px solid #6CC4AE'
                                          }}
                                        ></StoryTitleBorder>
                                      )}
                                    </div>

                                    <div className="subtitle-caption">
                                      {props.generes.length > 0 &&
                                        props.generes.map(gener => {
                                          return (
                                            <span className="subtitle-spacing">
                                              {gener.name.toUpperCase()} &nbsp;
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </StoryCover>
                        )}

                        {props.showSeriesIamge === true && (
                          <StoryCoverBorder
                            style={{ border: '4px solid #6CC4AE' }}
                          >
                            <div className="title-wrapper"></div>
                            <div
                              className="title-caption-border"
                              style={{ background: 'none !important' }}
                            >
                              <div className="mngl" id="titleFile">
                                <div>
                                  <div>
                                    <div>
                                      {props.showTitleIamge === false && (
                                        <StoryTitle></StoryTitle>
                                      )}
                                      {props.showTitleIamge && (
                                        <StoryTitleBorder></StoryTitleBorder>
                                      )}
                                    </div>
                                    <div className="subtitle-caption">
                                      {props.generes.length > 0 &&
                                        props.generes.map(gener => {
                                          return (
                                            <span className="subtitle-spacing">
                                              {gener.name.toUpperCase()} &nbsp;
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </StoryCoverBorder>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 cover-title"></div>
                </Col>
              </Row>

              <Row className="border-row">
                <Col>
                  <div className="play-button">
                    <div style={{ paddingTop: '4%' }} className="mt-3">
                      <span style={{ paddingRight: '3%' }}>
                        {' '}
                        <img src="/images/ic_play_arrow_24px.svg" />
                      </span>
                      Play
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="border-row border-row-bg">
                <Col className="cover-content">{props.description}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddFiles;

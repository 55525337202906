import { endpoint } from '../../config/app.config';

//CommandLevel1
export const getCommandLevel1FromApi = payloads => {
  return fetch(`${endpoint}/api/commands`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};
//CommandLevel2
export const getCommandLevel2FromApi = payloads => {
  return fetch(
    `${endpoint}/api/commands/${payloads.commandId}?story_id=${payloads.storyId}&scene_id=${payloads.sceneId}`,
    // return fetch('http://localhost:3001/media/',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//getCharacterVariationsFromApi
export const getCharacterVariationsFromApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/characters/${payloads.characterId}/variations`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => {
      return jsonresp;
    })
    .catch(err => {
      throw err;
    });
};

//getCharacterExpressionsFromApi
export const getCharacterExpressionsFromApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/characters/${payloads.characterId}/variations/${payloads.variationId}/expressions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => {
      return jsonresp;
    })
    .catch(err => {
      throw err;
    });
};

//Get Character Color
export const getCharacterColorFromApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/characters/${payloads.characterId}/color`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => {
      return jsonresp;
    })
    .catch(err => {
      throw err;
    });
};

export const getStoryListFromApi = authtoken => {
  return fetch(`${endpoint}/api/stories`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authtoken}`
    }
  })
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getStoryDetail = payload => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getStoryGenereList = payload => {
  return fetch(`${endpoint}/api/genres`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload}`
    }
  })
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const postStory = payloads => {
  if (payloads.editStory) {
    return fetch(endpoint + `/api/stories/${payloads.storyId}`, {
      method: 'PUT',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    })
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(endpoint + '/api/stories', {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    })
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  }
};

export const duplicateStory = payloads => {
  return fetch(
    endpoint + `/api/stories/${payloads.storyId}/copy?copyCollaborators=true`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const reviewStory = payloads => {
  switch (payloads.storyStatus) {
    case 'Approved':
      return fetch(endpoint + `/api/stories/${payloads.storyId}/submit`, {
        method: 'PATCH',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      })
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });

    case 'UpdateApproved':
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/update_approved_story`,
        {
          method: 'PATCH',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });
    case 'UpdateRejected':
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/update_rejected_story`,
        {
          method: 'PATCH',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });
    case 'UpdatePublished':
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/update_published_story`,
        {
          method: 'PATCH',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });

    case 'undoReview':
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/undo_request_review`,
        {
          method: 'PATCH',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });

    default:
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/send_for_review`,
        {
          method: 'PUT',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });
  }
};

export const reviewAdminStory = payloads => {
  switch (payloads.status) {
    case 'APPROVED':
    case 'REJECTED':
      return fetch(
        endpoint + `/api/stories/${payloads.storyId}/review/${payloads.status}`,
        {
          method: 'PATCH',
          body: payloads.payload,
          headers: {
            Authorization: `Bearer ${payloads.authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });

    case 'PUBLISH':
      return fetch(endpoint + `/api/stories/${payloads.storyId}/publish`, {
        method: 'PATCH',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      })
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });
    case 'UNPUBLISH':
      return fetch(endpoint + `/api/stories/${payloads.storyId}/unpublish`, {
        method: 'PATCH',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      })
        .then(res => res.json())
        .then(resp => resp)
        .catch(err => {
          throw err;
        });

    default:
      break;
  }
};

export const deleteStory = payloads => {
  return fetch(endpoint + `/api/stories/${payloads.storyId}`, {
    method: 'DELETE',
    body: payloads.payload,
    headers: {
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const postStoryProgressData = payload => {
  payload.payload.storyId = payload.storyId;
  let flowchartData = '';
  if (payload.sceneId !== null && payload.sceneId !== undefined) {
    flowchartData = JSON.stringify([payload.payload]);
  } else {
    flowchartData = JSON.stringify(payload.payload);
  }

  return fetch(`${endpoint}/api/stories/${payload.storyId}/details`, {
    method: 'POST',
    body: flowchartData,
    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const checkStoryNameValidity = payload => {
  return fetch(`${endpoint}/api/stories/exists?title=${payload.storyName}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

//Story Admin View

export const getStoryAdminApi = payloads => {
  return fetch(`${endpoint}/api/stories/view`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payloads.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

///NEw api for command panel
export const postCommands = payloads => {
  let apiForPostCommands =
    endpoint +
    `/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/story_text`;
  if (
    payloads.changeBlockName !== null &&
    payloads.changeBlockName !== payloads.newBlock
  ) {
    apiForPostCommands =
      endpoint +
      `/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/block/rename?old_name=${payloads.changeBlockName}&new_name=${payloads.newBlock}`;
  }
  return fetch(apiForPostCommands, {
    method: 'POST',
    body: JSON.stringify(payloads.payload),
    headers: {
      Authorization: `Bearer ${payloads.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const postAutoSaveApi = payloads => {
  let apiForPostCommands =
    endpoint +
    `/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/autosave`;
  // if (
  //   payloads.changeBlockName !== null &&
  //   payloads.changeBlockName !== payloads.newBlock
  // ) {
  //   apiForPostCommands =
  //     endpoint +
  //     `/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/block/rename?old_name=${payloads.changeBlockName}&new_name=${payloads.newBlock}`;
  // }

  return fetch(apiForPostCommands, {
    method: 'POST',
    body: JSON.stringify(payloads.payload),
    headers: {
      Authorization: `Bearer ${payloads.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getCommandList = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/story_text`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payloads.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const getCharacterRoutesApi = payloads => {
  return fetch(`${endpoint}/api/stories/46/routes`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payloads.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const getCommandEditList = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/commands/${payloads.lineNo}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payloads.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const getPreviewAssetList = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/recipe/${payloads.lineNo}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payloads.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//getAvialableBlocks

export const getAvialableBlocks = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/blocks`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payloads.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//collaborator
export const getCollaboratorApi = payloads => {
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/collaborator`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const addCollaboratorApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${
      payloads.storyId
    }/collaborator?name=${encodeURIComponent(payloads.name)}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const addViewerApi = payloads => {
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/viewers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    },
    body: JSON.stringify({ emailOrUsername: payloads.name })
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const revokeCollaboratorApi = payloads => {
  if (payloads.isRevoke === true) {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/collaborator/${payloads.name}/revoke_access`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/collaborator/${payloads.name}/resend_invite`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  }
};

export const revokeViewerApi = payloads => {
  if (payloads.isRevoke === true) {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/viewers/${payloads.name}/revoke_access`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/viewers/${payloads.name}/resend_invite`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  }
};

//collaborator
export const getViewerApi = payloads => {
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/viewers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const getAllWriterApi = payloads => {
  ///api/users/role/writer
  return fetch(`${endpoint}/api/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const releaseLock = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/release_lock`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const lockedStory = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/locked_or_error`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const validateStory = payloads => {
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/validate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payloads.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const updateRouteInfoApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/routes/info?routePromptText=${payloads.routeInfo.promptText}&routeSynopsis=${payloads.routeInfo.synopsis}&story_id=${payloads.storyId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const changeLogApi = payloads => {
  return fetch(
    `${endpoint}/api/changelog/${payloads.storyId}/scene/${payloads.chapterId}/script_version`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const updateStoryIdApi = payloads => {
  return fetch(
    // api/stories/369/storyid/mynew
    `${endpoint}/api/stories/${payloads.storyId}/storyid/${payloads.unityStoryId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const addStoryCreditAPI = payloads => {
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/credits/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${payloads.authToken}`
    },
    body: payloads.storyCredits
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const spellCheckApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.chapterId}/spellcheck`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      },
      body: JSON.stringify(payloads.commandObj)
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const spellIgnoreApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/wordtoignore`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payloads.authToken}`
      },
      body: `${payloads.word}`
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

import * as ActionTypes from '../actions/action-types';
import * as StoryActionTypes from '../actions/story-action-types';

import { flowchartServices } from '../common/helper/flowchart';

export const initialState = {
  story: '',
  storyStatus: '',
  storyLists: [],
  genreList: [],
  errors: [],
  storyId: null,
  commandId: null,
  loading: false,
  storyNameValidity: false,
  commandLevel: 1,
  commandsLevel1: [],
  commandsLevel1loading: true,
  commandsLevel1Errors: [],
  storyStatusObj: null,
  commandsLevel2: [],
  commandsLevel2loading: true,
  commandsLevel2Errors: [],

  commandsLevel2Scripts: [],
  commandsLevel2Scriptloading: true,
  commandsLevel2ScriptErrors: [],
  variableListDTO: [],
  localVariableListDTO: [],
  changeVariableIds: [],
  originalVariableLocalDTO: [],

  commandLeve2Update: 0,
  characterVariations: [],
  characterVariationsLoading: true,
  characterVariationsErrors: [],

  characterExpressions: [],
  characterExpressionsLoading: true,
  characterExpressionsErrors: [],

  characterColor: null,
  characterCharacterLoading: true,
  characterCharacterErrors: [],
  autosaveFlagLoading: false,
  storyScenes: [],
  storyScenesLoading: true,
  storyScenesErrors: [],
  editStory: false,
  storyDetails: [],

  //For Admin below
  storyListAdminReviewed: [],
  storyListAdminOthers: [],
  storyAdminLoading: true,

  showReviewModal: false,
  reviewConfirm: false,
  reviewLoading: false,
  showDeleteModal: false,
  deleteConfirm: false,

  showAdminReviewModal: false,
  adminReviewConfirm: false,
  sceneListStoryTitle: null,
  storyTitleTextEditor: null,
  storySubTitleTextEditor: null,
  //For new logic to add commands
  commandObj: null,
  spellGrammar: [],
  avialableBlocks: [],
  updateCommandObj: null,
  cursorPostion: null,
  isEditor: null,
  previewAssetObj: null,
  // for Edit Command
  isEditCommand: false,
  viewId: null,
  fadeinView: 0.5,
  isViewStory: false,
  isCommentStory: false,
  currentStoryStatus: '',

  //collaborator
  collaboratorList: [],
  viewerList: [],
  writerListAll: [],
  showLockedModal: false,
  lockedObject: [],
  lastSavedTime: null,
  storyRouteLoading: false,
  storyRouteErrors: [],
  successRouteModal: false,
  failRouteModal: false,
  progressRoute: false,
  storyRouteListLoading: false,
  storyRoutes: [],
  isRouteEdit: false,
  isCastEdit: false,
  isRouteEditSuccess: false,
  routeData: [],
  showRouteDeleteConfirm: false,
  loadingRouteData: false,
  storyReviewErrorModal: false,
  storyReviewTitle: '',
  storyReviewSubTitle: '',
  storyReviewScenes: [],
  storyReviewWarning: '',
  showReviewModalChapterNotAvailable: false,
  showChangeLogModal: false,
  lastVersionDate: null,
  storyTitle: '',
  routeInfo: {
    promptText: '',
    synopsis: ''
  },
  isViewRoute: true,
  autoSaveFlag: false,
  authorName: '',
  searchBy: 'Creator name',
  castData: [],
  successUpdate: false,
  loadGrammar: false,
  unityStoryId: ''
};

let routeInfoPromptText =
  'You have unlocked character paths! Head over to the series page to keep playing.';
let routeInfoSynopsis =
  'Learn more about the journeys and love interests you can choose.';

export const StoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoryActionTypes.SET_VIEW_STORY_STATUS:
      return {
        ...state,
        isViewStory: action.flag
      };
    case StoryActionTypes.SET_COMMENT_STORY_STATUS:
      return {
        ...state,
        isCommentStory: action.status,
        currentStoryStatus: action.storyStatus
      };
    case StoryActionTypes.SET_VIEW_ROUTE_STATUS:
      return {
        ...state,
        isViewRoute: action.viewRoute
      };

    case StoryActionTypes.CREATE_SCENE:
      return {
        ...state,
        storyScenesLoading: true,
        storyScenesErrors: []
      };

    case StoryActionTypes.CREATE_SCENE_SUCCESS:
      return {
        ...state,
        storyScenes: action.storyScenes,
        storyScenesLoading: false,
        storyScenesErrors: []
      };

    case StoryActionTypes.CREATE_SCENE_FAILURE:
      return {
        ...state,
        storyScenesLoading: false,
        storyScenesErrors: action.errors
      };

    case StoryActionTypes.CREATE_CAST:
      return {
        ...state,
        storyCastLoading: true,
        successCastModal: false,
        failCastModal: false,
        progressCast: true,
        storyCastErrors: []
      };

    case StoryActionTypes.CREATE_CAST_SUCCESS:
      return {
        ...state,
        // storyScenes: action.storyScenes,
        storyCastLoading: false,
        successCastModal: true,
        isCastEditSuccess: state.isCastEdit === true ? true : false,
        isCastEdit: false,
        failCastModal: false,
        progressCast: false,
        storyCastErrors: [],
        castData: []
      };

    case StoryActionTypes.CREATE_CAST_FAILURE:
      return {
        ...state,
        storyCastLoading: false,
        successCastModal: false,
        isCastEdit: false,
        progressCast: false,
        failCastModal: true,
        storyCastErrors: action.errors,
        castData: []
      };

    case StoryActionTypes.CREATE_ROUTE:
      return {
        ...state,
        storyRouteLoading: true,
        successRouteModal: false,
        failRouteModal: false,
        progressRoute: true,
        storyRouteErrors: []
      };

    case StoryActionTypes.CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        // storyScenes: action.storyScenes,
        storyRouteLoading: false,
        successRouteModal: true,
        isRouteEditSuccess: state.isRouteEdit === true ? true : false,
        isRouteEdit: false,

        failRouteModal: false,
        progressRoute: false,
        storyRouteErrors: [],

        routeData: []
      };

    case StoryActionTypes.CREATE_ROUTE_FAILURE:
      return {
        ...state,
        storyRouteLoading: false,
        successRouteModal: false,
        isRouteEdit: false,
        progressRoute: false,
        failRouteModal: true,
        storyRouteErrors: action.errors,

        routeData: []
      };

    case StoryActionTypes.RESET_CAST:
      return {
        ...state,

        successRouteModal: false,
        progressRoute: false,
        failRouteModal: false,
        isCastEdit: false,
        castData: []
      };

    case StoryActionTypes.RESET_ROUTE_MODALS:
      return {
        ...state,

        successRouteModal: false,
        progressRoute: false,
        failRouteModal: false,
        isRouteEdit: false
      };
    case StoryActionTypes.RESET_ROUTE_RESULT_MODALS:
      return {
        ...state,

        isRouteEditSuccess: false
      };

    case StoryActionTypes.GET_ROUTE:
      return {
        ...state,
        storyRouteListLoading: true,
        isRouteEdit: false,
        storyRoutes: []
      };
    case StoryActionTypes.GET_ROUTE_SUCCESS:
      return {
        ...state,
        storyRouteListLoading: false,
        isRouteEdit: false,
        routeData: [],
        storyRoutes: action.storyRoutes
      };
    case StoryActionTypes.GET_ROUTE_FAILURE:
      return {
        ...state,
        storyRouteListLoading: false,
        isRouteEdit: false,
        routeData: [],
        storyRoutes: action.storyRoutes
      };

    case StoryActionTypes.GET_CAST:
      return {
        ...state,
        isCastEdit: false,
        loadingCastData: true,
        castData: []
      };
    case StoryActionTypes.GET_CAST_SUCCESS:
      return {
        ...state,
        isCastEdit: true,
        loadingRouteData: false,
        castData: action.castData
      };
    case StoryActionTypes.GET_CAST_FAILURE:
      return {
        ...state,
        isCastEdit: false,
        loadingRouteData: false,
        castData: []
      };

    case StoryActionTypes.GET_ROUTE_BY_ID:
      return {
        ...state,
        isRouteEdit: false,
        loadingRouteData: true,
        routeData: []
      };
    case StoryActionTypes.GET_ROUTE_BY_ID_SUCCESS:
      return {
        ...state,
        isRouteEdit: true,
        loadingRouteData: false,
        routeData: action.routeData
      };
    case StoryActionTypes.GET_ROUTE_BY_ID_FAIL:
      return {
        ...state,
        isRouteEdit: false,
        loadingRouteData: false,
        routeData: []
      };

    case StoryActionTypes.DELETE_ROUTE:
      return {
        ...state,
        showRouteDeleteConfirm: false,
        storyRouteListLoading: true,
        isRouteEdit: false
      };
    case StoryActionTypes.DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        showRouteDeleteConfirm: true,
        isRouteEdit: false
        // storyRouteListLoading: false,
      };
    case StoryActionTypes.DELETE_ROUTE_FAILURE:
      return {
        ...state,
        showRouteDeleteConfirm: false,
        isRouteEdit: false
      };

    case StoryActionTypes.MARK_STORY_ROUTE:
      return {
        ...state
        //	storyRouteListLoading: true,
      };

    case StoryActionTypes.MARK_STORY_ROUTE_SUCCESS:
      return {
        ...state
      };

    case StoryActionTypes.MARK_STORY_ROUTE_FAIL:
      return {
        ...state
      };

    case StoryActionTypes.MARK_STORY_DRAFT_STATUS:
      return {
        ...state,
        storyRouteListLoading: true
      };

    case StoryActionTypes.MARK_STORY_DRAFT_STATUS_SUCCESS:
      return {
        ...state
      };

    case StoryActionTypes.MARK_STORY_DRAFT_STATUS_FAIL:
      return {
        ...state
      };

    case StoryActionTypes.GET_COMMAND_LEVEL1:
      return {
        ...state,
        commandsLevel1loading: true,
        commandsLevel1Errors: [],
        storyScenesErrors: []
      };

    case StoryActionTypes.GET_COMMAND_LEVEL1_SUCCESS:
      return {
        ...state,
        commandsLevel1: action.commandsLevel1,
        commandsLevel1loading: false
      };

    case StoryActionTypes.GET_COMMAND_LEVEL1_FAIL:
      return {
        ...state,
        commandsLevel1loading: false,
        commandsLevel1Errors: action.errors
      };

    case StoryActionTypes.GET_COMMAND_LEVEL2:
      return {
        ...state,
        commandsLevel2loading: true,
        commandsLevel2Errors: []
      };

    case StoryActionTypes.GET_COMMAND_LEVEL2_SUCCESS:
      return {
        ...state,
        commandsLevel2: action.commandsLevel2,
        commandId: action.commandId,
        commandsLevel2loading: false
      };

    case StoryActionTypes.GET_COMMAND_LEVEL2_FAIL:
      return {
        ...state,
        commandsLevel2loading: false,
        commandsLevel2Errors: action.errors
      };

    //FOR SCRIPT EDITOR
    case StoryActionTypes.GET_SCRIPT_COMMAND_LEVEL2:
      return {
        ...state,
        commandsLevel2loading: true
      };

    case StoryActionTypes.GET_SCRIPT_COMMAND_LEVEL2_SUCCESS:
      return {
        ...state,
        commandsLevel2Scripts: action.commandsLevel2Scripts,
        commandId: action.commandId,
        commandsLevel2Scriptloading: false
      };

    case StoryActionTypes.GET_SCRIPT_COMMAND_LEVEL2_FAIL:
      return {
        ...state,
        commandsLevel2Scriptloading: false,
        commandsLevel2ScriptErrors: action.errors
      };

    case StoryActionTypes.GET_CHAR_VARIATIONS:
      return {
        ...state,
        characterVariationsLoading: true,
        characterVariationsErrors: [],
        commandLeve2Update: action.isEditScript ? new Date() : 0
      };

    case StoryActionTypes.GET_CHAR_VARIATIONS_SUCCESS:
      return {
        ...state,
        characterVariations: action.characterVariations,
        characterVariationsLoading: false,
        commandLeve2Update: action.isEdit ? new Date() : 0
      };

    case StoryActionTypes.GET_CHAR_VARIATIONS_FAIL:
      return {
        ...state,
        characterVariationsLoading: false,
        characterVariationsErrors: action.errors
      };

    case ActionTypes.GET_STORY_LIST_DETAILS:
      return {
        ...state,
        storyLists: [],
        isViewStory: false,
        isViewRoute: true,
        showReviewModal: false,
        isCastEdit: false
      };

    case ActionTypes.RESET_COMMAND_DETAILS:
      return {
        ...state,
        commandObj: null
      };

    case ActionTypes.GET_SUCCESS_STORY_LIST_DETAILS:
      return {
        ...state,
        storyLists: action.payload.body,
        isViewStory: false,
        isViewRoute: true
      };

    case ActionTypes.GET_FAIL_STORY_LIST_DETAILS:
      return {
        ...state,
        isViewStory: false,
        isViewRoute: true
      };

    case ActionTypes.SET_STORY_ID:
      return {
        ...state,
        storyId: action.storyId,
        storyTitle: action.storyTitle,
        backPage: action.backPage,
        isCastEdit: false,
        unityStoryId: action.unityStoryId,
        castData: []
      };

    case StoryActionTypes.GET_STORY_GENERE:
      return {
        ...state
      };

    case StoryActionTypes.GET_STORY_GENERE_SUCCESS:
      return {
        ...state,
        genreList: action.payload
      };

    case StoryActionTypes.GET_STORY_GENERE_FAIL:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_NAME_VALIDITY:
      return {
        ...state,
        storyNameValidity: false
      };

    case StoryActionTypes.SET_STORY_NAME_VALIDITY_TRUE:
      return {
        ...state,
        storyNameValidity: action.payload
      };

    case StoryActionTypes.SET_STORY_NAME_VALIDITY_FALSE:
      return {
        ...state,
        storyNameValidity: false
      };

    case StoryActionTypes.SET_STORY:
      return {
        ...state,
        story: action.payload,
        errors: [],
        loading: true
      };

    case StoryActionTypes.SET_STORY_SUCCESS:
      return {
        ...state,
        storyStatus: 'success',
        errors: [],
        story: action.payload,
        loading: false,
        // editStory: false,
        storyScenesErrors: [],
        storyDetails: []
      };

    case StoryActionTypes.SET_STORY_FAIL:
      return {
        ...state,
        storyStatus: 'fail',
        errors: action.payload.message ? action.payload.message : [],
        story: '',
        loading: false,
        editStory: false,
        storyDetails: []
      };

    case StoryActionTypes.SET_SHOW_MODAL_TRUE:
      return {
        ...state,
        showModal: true,
        editStory: action.editStory ? action.editStory : false
      };

    case StoryActionTypes.SET_CURSOR_POSITION:
      return {
        ...state,
        cursorPostion: action.index
      };

    case StoryActionTypes.SET_SHOW_MODAL_FALSE:
      return {
        ...state,
        story: '',
        storyStatus: '',
        showModal: false,
        editStory: false,
        storyDetails: []
      };

    case StoryActionTypes.RESET_COMMAND_VALUE:
      return {
        ...state,
        characterExpressions: [],
        characterVariations: []
      };

    ////Story details for EDIT
    case ActionTypes.GET_STORY_DETAILS:
      return {
        ...state,
        storyId: action.storyId,
        authToken: action.authToken
      };

    case ActionTypes.GET_STORY_DETAILS_SUCCESS:
      return {
        ...state,
        storyDetails: action.payload,
        routeInfo: {
          promptText:
            action.payload.routePromptText !== null
              ? action.payload.routePromptText
              : routeInfoPromptText,
          synopsis:
            action.payload.routeSynopsis !== null
              ? action.payload.routeSynopsis
              : routeInfoSynopsis
        }
      };

    case ActionTypes.GET_STORY_DETAILS_FAIL:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_REVIEW:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_REVIEW_SUCCESS:
      return {
        ...state,
        showReviewModal: true,
        reviewConfirm: true
      };
    case StoryActionTypes.SET_STORY_REVIEW_FAIL:
      return {
        ...state,
        showReviewModal: false,
        reviewConfirm: false
      };

    case StoryActionTypes.CHECK_STORY_LOCKED:
      return {
        ...state
      };

    case StoryActionTypes.CHECK_STORY_LOCKED_SUCCESS:
      return {
        ...state,
        showReviewModal: action.payload.body.length > 0 ? false : true,
        reviewConfirm: false,
        showLockedModal: action.payload.body.length > 0 ? true : false,
        lockedObject: action.payload.body,
        reviewLoading: false
      };

    case StoryActionTypes.CHECK_STORY_LOCKED_FAIL:
      return {
        ...state,

        showReviewModal: false,
        reviewConfirm: false,
        showLockedModal: false,
        reviewLoading: false,
        lockedObject: []
      };

    case StoryActionTypes.SHOW_REVIEW_MODAL_TRUE:
      return {
        ...state,
        showReviewModal: true,
        reviewConfirm: false
      };

    case StoryActionTypes.SHOW_REVIEW_MODAL_FALSE:
      return {
        ...state,
        showReviewModal: false,
        reviewConfirm: false
      };

    case StoryActionTypes.SET_STORY_ADMIN_REVIEW:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_ADMIN_REVIEW_SUCCESS:
      return {
        ...state,
        showAdminReviewModal: true,
        adminReviewConfirm: true
      };

    case StoryActionTypes.SET_STORY_ADMIN_REVIEW_FAIL:
      return {
        ...state,

        showAdminReviewModal: false,
        adminReviewConfirm: false
      };

    case StoryActionTypes.SET_STORY_DUPLICATE:
      return {
        ...state,
        storyAdminLoading: true
      };

    case StoryActionTypes.SET_STORY_DUPLICATE_SUCCESS:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_DUPLICATE_FAIL:
      return {
        ...state,
        storyAdminLoading: false
      };

    case StoryActionTypes.SHOW_ADMIN_REVIEW_MODAL_TRUE:
      return {
        ...state,
        showAdminReviewModal: true,
        adminReviewConfirm: false
      };

    case StoryActionTypes.SHOW_ADMIN_REVIEW_MODAL_FALSE:
      return {
        ...state,
        showAdminReviewModal: false,
        adminReviewConfirm: false
      };

    case StoryActionTypes.SET_STORY_DELETE:
      return {
        ...state
      };

    case StoryActionTypes.SET_STORY_DELETE_SUCCESS:
      return {
        ...state,
        showDeleteModal: true,
        deleteConfirm: true
      };

    case StoryActionTypes.SET_STORY_DELETE_FAIL:
      return {
        ...state,

        showDeleteModal: false,
        deleteConfirm: false
      };

    case StoryActionTypes.SHOW_DELETE_MODAL_TRUE:
      return {
        ...state,
        showDeleteModal: true,
        deleteConfirm: false
      };

    case StoryActionTypes.SHOW_DELETE_MODAL_FALSE:
      return {
        ...state,
        showDeleteModal: false,
        deleteConfirm: false
      };

    case StoryActionTypes.GET_STORY_ADMIN:
      return {
        ...state,
        storyListAdminReviewed: null,
        storyListAdminOthers: null,
        storyAdminLoading: true
      };

    case StoryActionTypes.GET_STORY_ADMIN_SUCCESS:
      return {
        ...state,
        storyListAdminReviewed: action.payload.body['Waiting to be Reviewed'],
        storyListAdminOthers: action.payload.body['Other Stories'],
        storyAdminAuthor: null,
        storyAdminLoading: false
      };

    case StoryActionTypes.GET_STORY_ADMIN_SUCCESS_WITH_AUTHOR:
      if (state.searchBy === 'Creator name') {
        return {
          ...state,
          storyListAdminReviewed: action.payload.body['Waiting to be Reviewed']
            ? action.payload.body['Waiting to be Reviewed'].filter(
                storyReviewed =>
                  storyReviewed.createdBy
                    .toLowerCase()
                    .includes(action.authorNameAdded.toLowerCase())
              )
            : [],
          storyListAdminOthers: action.payload.body['Other Stories']
            ? action.payload.body['Other Stories'].filter(storyOther =>
                storyOther.createdBy
                  .toLowerCase()
                  .includes(action.authorNameAdded.toLowerCase())
              )
            : [],
          storyAdminAuthor: action.authorNameAdded,
          storyAdminLoading: false
        };
      } else {
        return {
          ...state,
          storyListAdminReviewed: action.payload.body['Waiting to be Reviewed']
            ? action.payload.body['Waiting to be Reviewed'].filter(
                storyReviewed =>
                  storyReviewed.title
                    .toLowerCase()
                    .includes(action.authorNameAdded.toLowerCase())
              )
            : [],
          storyListAdminOthers: action.payload.body['Other Stories']
            ? action.payload.body['Other Stories'].filter(storyOther =>
                storyOther.title
                  .toLowerCase()
                  .includes(action.authorNameAdded.toLowerCase())
              )
            : [],
          storyAdminAuthor: action.authorNameAdded,
          storyAdminLoading: false
        };
      }

    case StoryActionTypes.GET_STORY_ADMIN_FAIL:
      return {
        ...state,
        storyAdminLoading: false
      };

    case StoryActionTypes.RESET_CHAPTER_STORY_ERROR:
      return {
        ...state,
        storyScenesErrors: [],
        commandObj: null,
        commandObjBkup: null,
        cursorPostion: null,
        isEditor: null
      };

    case StoryActionTypes.RESET_CHAPTER_STORY_DETAILS:
      return {
        ...state,
        storyScenesErrors: [],
        commandObj: null,
        commandObjBkup: null,
        cursorPostion: null,
        isEditor: null
      };
    //new logic foe add commnads from command panel

    case StoryActionTypes.GET_AVAILABLE_BLOCKS:
      return {
        ...state,
        avialableBlocks: []
      };

    case StoryActionTypes.GET_AVAILABLE_BLOCKS_SUCCESS:
      return {
        ...state,
        avialableBlocks: action.payload
      };

    case StoryActionTypes.GET_AVAILABLE_BLOCKS_FAIL:
      return {
        ...state,
        avialableBlocks: []
      };

    case StoryActionTypes.GET_COMMANNDS:
      return {
        ...state,
        // commandObj: null,
        updateCommandObj: null,
        storyTitleTextEditor: null,
        storySubTitleTextEditor: null,
        // cursorPostion: null,
        isEditor: true,
        variableListDTO: [],
        localVariableListDTO: [],
        originalVariableLocalDTO: [],
        changeVariableIds: [],
        storyStatusObj: null,
        loading: true
      };

    case StoryActionTypes.GET_COMMANNDS_SUCCESS:
      const localVariableDTOWindow = flowchartServices.fetchLocalVariable(
        action.payload.variableDTOs
      );
      return {
        ...state,
        commandObj: action.payload,
        commandObjBkup: action.payload.commands,
        storyTitleTextEditor: action.payload.storyTitle,
        storySubTitleTextEditor: action.payload.name,
        updateCommandObj: action.payload,
        variableListDTO: action.payload.variableDTOs,
        localVariableListDTO: localVariableDTOWindow,
        storyStatusObj: action.payload.storyStatus,
        changeVariableIds: [],
        lastSavedTime: action.payload.updatedAt,
        isEditor: true,
        loading: false
      };

    case StoryActionTypes.RESET_ALL_VARIABLE_WINDOW:
      return {
        ...state,
        localVariableListDTO: [],
        changeVariableIds: []
      };

    case StoryActionTypes.RESET_ALL_VARIABLE_WINDOW_SUCCESS:
      const localVariableDTOWindowReset = flowchartServices.fetchLocalVariable(
        action.payload.variableDTOs
      );
      return {
        ...state,
        changeVariableIds: [],
        localVariableListDTO: localVariableDTOWindowReset
      };

    case StoryActionTypes.UPDATE_VARIABLE_WINDOW:
      return {
        ...state
      };

    case StoryActionTypes.UPDATE_VARIABLE_WINDOW_SUCCESS:
      const variableList = action.changedVariableList;
      const variableListunique = [...new Set(variableList)];
      return {
        ...state,
        localVariableListDTO: action.payload,
        changeVariableIds: variableListunique
      };

    case StoryActionTypes.GET_COMMANNDS_FAIL:
      return {
        ...state,
        commandObj: null,
        commandObjBkup: null,
        updateCommandObj: null,
        cursorPostion: null,
        isEditor: true,
        storyStatusObj: null,
        loading: false
      };

    case StoryActionTypes.SET_RELEASE_LOCK:
      return {
        ...state
      };

    case StoryActionTypes.SET_RELEASE_LOCK_SUCCESS:
      return {
        ...state
      };

    case StoryActionTypes.SET_RELEASE_LOCK_FAIL:
      return {
        ...state
      };

    case StoryActionTypes.GET_COMMANNDS_EDIT:
      return {
        ...state,
        commandsLevel2: null,
        isEditCommand: false,
        commandId: null
      };

    case StoryActionTypes.GET_COMMANNDS_EDIT_SUCCESS:
      return {
        ...state,
        commandsLevel2: action.commandsLevel2,
        commandId: action.commandId,
        commandsLevel2loading: false,
        isEditCommand: true,
        viewId:
          action.commandId === 37 || action.commandId === 19
            ? action.commandsLevel2.viewId
            : null,
        fadeinView:
          action.commandId === 37 || action.commandId === 19
            ? action.commandsLevel2.fadeInToView
            : null
      };

    case StoryActionTypes.GET_COMMANNDS_EDIT_FAIL:
      return {
        ...state,
        isEditCommand: false,
        viewId: null,
        fadeinView: null
      };

    case StoryActionTypes.SET_COMMAND_LEVEL:
      return {
        ...state,
        commandLevel: action.commandLevel,
        isEditCommand: false
      };

    case StoryActionTypes.UPDATE_COMMAND_SCRIPT_EDITOR:
      return {
        ...state,
        commandObj: action.payload,
        updateCommandObj: action.payload,
        isEditor: true
      };

    case StoryActionTypes.SET_COMMANNDS:
      return {
        ...state,
        command: action.payload,
        commandId: null,
        isEditCommand: false,
        loading: action.savefromMenu === true ? false : true
      };

    case StoryActionTypes.SET_COMMANNDS_SUCCESS:
      return {
        ...state,
        commandId: null,
        isEditCommand: false,
        // lastSavedTime: new Date(),
        loading: false
      };

    case StoryActionTypes.SET_COMMANNDS_PANEL:
      return {
        ...state,
        command: action.payload
      };

    case StoryActionTypes.SET_COMMANNDS_SUCCESS_PANEL:
      return {
        ...state,
        commandId: '',
        isEditCommand: false,
        // lastSavedTime: new Date(),
        commandObj: action.payload,
        commandObjBkup: action.payload.commands,
        loading: false
      };

    case StoryActionTypes.SET_COMMANNDS_FAIL:
      return {
        ...state,
        commandId: null,
        isEditCommand: false,
        loading: false
      };

    case StoryActionTypes.GET_PREVIEW_ASSET:
      return {
        ...state,
        previewAssetObj: null
      };

    case StoryActionTypes.GET_PREVIEW_ASSET_SUCCESS:
      return {
        ...state,
        previewAssetObj: action.payload
      };

    case StoryActionTypes.GET_PREVIEW_ASSET_FAIL:
      return {
        ...state,
        previewAssetObj: null
      };

    case StoryActionTypes.GET_COLLABORATOR_LIST:
      return {
        ...state,
        collaboratorList: []
      };

    case StoryActionTypes.GET_COLLABORATOR_LIST_SUCCESS:
      return {
        ...state,
        collaboratorList: action.payload
      };

    case StoryActionTypes.GET_VIEWER_LIST:
      return {
        ...state,
        viewerList: []
      };

    case StoryActionTypes.GET_VIEWER_LIST_SUCCESS:
      return {
        ...state,
        viewerList: action.payload
      };

    case StoryActionTypes.GET_WRITER_LIST_SUCCESS:
      return {
        ...state,
        writerListAll: action.payload
      };

    case StoryActionTypes.CHECK_STORY_VALID:
      return {
        ...state,
        reviewLoading: true
      };

    case StoryActionTypes.CHECK_STORY_VALID_SUCCESS:
      return {
        ...state,
        storyReviewErrorModal:
          action.payload.body.title === 'Chapters are not available' ||
          (action.payload.body.hasOwnProperty('scenes') &&
            action.payload.body.scenes.length > 0)
            ? true
            : false,
        storyReviewTitle: action.payload.body.title,
        storyReviewSubTitle: action.payload.body.text,
        storyReviewScenes: action.payload.body.hasOwnProperty('scenes')
          ? action.payload.body.scenes
          : [],
        showReviewModal: action.payload.body.hasOwnProperty('scenes')
          ? false
          : true,
        showReviewModalChapterNotAvailable:
          action.payload.body.title === 'Chapters are not available'
            ? true
            : false,
        reviewConfirm: false,
        reviewLoading: false,
        storyReviewWarning: action.payload.body.hasOwnProperty('warning')
          ? action.payload.body.warning
          : ''
      };

    case StoryActionTypes.CHECK_STORY_VALID_FAIL:
      return {
        ...state,
        reviewLoading: false
      };

    case StoryActionTypes.RESET_STORY_REVIEW_ERROR:
      return {
        ...state,
        storyReviewErrorModal: false,
        storyReviewTitle: '',
        storyReviewSubTitle: '',
        storyReviewScenes: [],
        showReviewModalChapterNotAvailable: false
      };
    case ActionTypes.RESET_STORY_DETAILS:
      return {
        ...state,
        storyDetails: []
      };

    case StoryActionTypes.CHANGE_LOG_MODAL_VIEW:
      return {
        ...state,
        showChangeLogModal: action.viewModal
      };

    case StoryActionTypes.GET_CHANGE_LOG_SCRIPT:
      return {
        ...state,
        oldScript: null,
        newScript: null
      };

    case StoryActionTypes.GET_CHANGE_LOG_SCRIPT_SUCCESS:
      return {
        ...state,
        oldScript: action.payload.oldVersion,
        newScript: action.payload.currentVersion,
        lastVersionDate: action.payload.lastVersionDate,
        showChangeLogModal: true
      };

    case StoryActionTypes.GET_CHANGE_LOG_SCRIPT_FAIL:
      return {
        ...state,
        oldScript: null,
        newScript: null,
        showChangeLogModal: false,
        lastVersionDate: null
      };

    case StoryActionTypes.CLOSE_CHANGE_LOG_MODAL:
      return {
        ...state,
        oldScript: null,
        newScript: null,
        showChangeLogModal: false,
        lastVersionDate: null
      };

    case ActionTypes.EDITOR_AUTO_SAVE_FLAG:
      return {
        ...state,
        autoSaveFlag: action.flag
      };

    case ActionTypes.RESET_DATA:
      return {
        ...state,
        [action.key]: action.value
      };

    case StoryActionTypes.SET_AUTHOR_NAME:
      return {
        ...state,
        authorName: action.authorName
      };

    case StoryActionTypes.SET_SEARCH_BY:
      return {
        ...state,
        searchBy: action.searchBy
      };

    case StoryActionTypes.UPDATE_STORY_ID_SUCCESS:
      return {
        ...state,
        successUpdate: true
      };

    case StoryActionTypes.UPDATE_SUCCESS_HIDE:
      return {
        ...state,
        successUpdate: false
      };

    case StoryActionTypes.SET_AUTOSAVE_COMMANNDS:
      return {
        ...state,
        commandObj: action.payload,
        autosaveFlagLoading: true
      };

    case StoryActionTypes.SET_AUTOSAVE_COMMANNDS_SUCCESS:
      return {
        ...state,
        autosaveFlagLoading: false,
        autoSaveFlag: false,
        lastSavedTime: action.payload.updatedAt
        // commandObj: action.payload,
      };

    case StoryActionTypes.SET_GRAMMAR_SPELL:
      return {
        ...state,
        loadGrammar: true
      };

    case StoryActionTypes.SET_GRAMMAR_SPELL_SUCCESS:
      return {
        ...state,
        spellGrammar: action.payload,
        loadGrammar: false
      };

    case StoryActionTypes.SET_GRAMMAR_SPELL_FAILURE:
      return {
        ...state,
        loadGrammar: false
      };

    case StoryActionTypes.SET_GRAMMAR_IGNORE:
      return {
        ...state,
        loadGrammar: true
      };

    case StoryActionTypes.SET_GRAMMAR_IGNORE_SUCCESS:
    case StoryActionTypes.SET_GRAMMAR_IGNORE_FAILURE:
      return {
        ...state,
        loadGrammar: false
      };

    default:
      return {
        ...state
      };
  }
};

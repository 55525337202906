import React , { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card } from 'react-bootstrap';
import { LibraryServices } from './../service';
import * as translations from './../../translations';
import * as Constants from './../../../../common/constants';


function UploadFile(props) {
    const maxSize = Constants.ASSET_MAX_FILE_SIZE;
    const { files,isEdit } = props;
    /* State of component */
    const [cardBorder, setCardBorder] = useState('');
    const [cloudUploadIconColor, setCloudUploadIconColor] = useState('text-secondary');

    const onDragOver = useCallback(() => {
        setCardBorder('primary');
        setCloudUploadIconColor('text-primary');
    }, []);

    const onDragLeave = useCallback(() => {
        setCardBorder('');
        setCloudUploadIconColor('');
    });

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            props.setFiles(LibraryServices.createPreviewUrl(acceptedFiles));
            setCardBorder('');
            setCloudUploadIconColor('');
            props.setShowClearFile(true);
            props.setContinueToAddInfoClass('button-active');
            props.setShowFileError(false);
            props.setAutoSaveFlag(true);
        }
    }, [props]);

    const { 
        isDragActive, 
        getRootProps, 
        getInputProps, 
        isDragReject, 
        rejectedFiles 
    } = useDropzone({
        onDrop,
        accept: Constants.LIBRARY_UI_INTERFACE_FILE_ALLOWED_EXTENSIONS.toString(),
        minSize: 0,
        maxSize:Constants.ASSET_MAX_FILE_SIZE,
        onDragOver,
        onDragLeave
    });

    const isFileTooLarge = LibraryServices.checkFileSize(rejectedFiles, maxSize);
  
    const thumbs = LibraryServices.createPreviewThumb(files,isEdit);
 
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
      }, [files]);

    return (
        <div className="container text-center lg-12">
            {
                thumbs.length === 0 &&
                <div {...getRootProps()}>
                    <Card bg="light" border={props.showFileError===true?'danger':cardBorder}>
                        <Card.Body className="card-content">
                        <img alt='' src="/images/icons/backup-big.svg" /><br />
                            <Card.Text className="text-secondary">{translations.DRAG_DROP}</Card.Text>
                            <input {...getInputProps()} id="file"/>
                            <Card.Text className="text-secondary">
                                {!isDragActive && translations.YOUR_FILE_TO_ASSETS} &nbsp;
                                <span className="text-primary light">
                                    <ins>{!isDragActive && translations.BROWSE}</ins>
                                </span>
                                <div className='text-later'>
                                    {!isDragActive && translations.CHAR_SUPPORTED_FILE_FORMATS_PNG}<br/>
                                    {!isDragActive && translations.UPLOAD_SIZE_FILE}
                                </div>
                                {isDragActive && !isDragReject && translations.DROP_IT}
                                {isDragReject && translations.FILES_TYPE_NOT_ACCEPTED}
                                {isFileTooLarge && (
                                    <div className="text-danger mt-2 mb-2">
                                        {translations.FILE_IS_TOO_LARGE}
                                    </div>
                                )}
                                {!isFileTooLarge && rejectedFiles.length > 0 && (
                                    <div className="text-danger mt-2 mb-2">
                                        {LibraryServices.showError(rejectedFiles)}
                                    </div>
                                )}
                                {!isFileTooLarge && rejectedFiles.length === 0 && props.showFileError===true && (
                                    <div className="text-danger mt-2 mb-2">
                                    Please upload a file.
                                    </div>
                                )}
                            </Card.Text> 
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                thumbs.length > 0 &&
                <Card bg="light" border={cardBorder}>
                    <Card.Body className="card-content card-content-custom">
                        <aside className="thumbs-container">
                            {thumbs}
                        </aside>
                </Card.Body>
                </Card>
            }
        </div>
    );
}

export default UploadFile
import React, { useEffect } from 'react';
import { PlayerService } from './player-service';
import ChoiceCommands from './choice-commands';
import ChoiceTimer from './choice-timer';

const createButton = (
  ifElseDecisionParameters,
  decisionParameters,
  handleDecisionClick,
  blobImages,
  gameInterfaceList,
  gameFonts,
  hideIfVisitedArray
) => {
  let buttons = [];
  let conditionalChoiceCommands = ifElseDecisionParameters;
  const lengthOfChoice = conditionalChoiceCommands.length;
  if (decisionParameters !== null) {
    for (let i = 1; i <= 5; i++) {
      if (decisionParameters['Destination' + i] != undefined) {
        let affinity = PlayerService.getAffinity(decisionParameters, i);
        buttons.push(
          <ChoiceCommands
            decisionParameters={decisionParameters}
            hideIfVisited={decisionParameters['hideIfVisited' + i]}
            affinityType={affinity.type}
            affinityWeight={affinity.weight}
            choiceCount={i}
            handleDecisionClick={handleDecisionClick}
            blobImages={blobImages}
            gameInterfaceList={gameInterfaceList}
            key={`choiceCommands${i}`}
            gameFonts={gameFonts}
            hideIfVisitedArray={hideIfVisitedArray}
          />
        );
      }
    }
  }

  if (conditionalChoiceCommands.length > 0 && buttons.length >= 0) {
    for (let j = 0; j <= conditionalChoiceCommands.length; j++) {
      if (
        conditionalChoiceCommands[j] !== undefined &&
        conditionalChoiceCommands[j]['Destination' + '1'] != undefined
      ) {
        for (let k = 1; k <= 5; k++) {
          if (conditionalChoiceCommands[j]['Destination' + k] != undefined) {
            let affinityConditional = PlayerService.getAffinity(
              conditionalChoiceCommands[j],
              k
            );
            buttons.push(
              <ChoiceCommands
                decisionParameters={conditionalChoiceCommands[j]}
                affinityType={affinityConditional.type}
                affinityWeight={affinityConditional.weight}
                choiceCount={k}
                handleDecisionClick={handleDecisionClick}
                blobImages={blobImages}
                gameInterfaceList={gameInterfaceList}
                key={`choiceCommandsConditional${k}`}
                gameFonts={gameFonts}
                hideIfVisitedArray={hideIfVisitedArray}
              />
            );
          }
        }
      }
    }
  }

  if (
    decisionParameters !== null &&
    decisionParameters['Time limit (optional)'] === 'true'
  ) {
    buttons.push(
      <ChoiceTimer
        key="choiceTimer"
        gameInterfaceList={gameInterfaceList}
        blobImages={blobImages}
        waitTime={decisionParameters['Wait up to']}
      />
    );
  } else if (
    conditionalChoiceCommands !== null &&
    conditionalChoiceCommands[0] &&
    conditionalChoiceCommands[0]['Time limit (optional)'] === 'true'
  ) {
    buttons.push(
      <ChoiceTimer
        key="choiceTimer"
        gameInterfaceList={gameInterfaceList}
        blobImages={blobImages}
        waitTime={conditionalChoiceCommands[0]['Wait up to']}
      />
    );
  } else if (
    conditionalChoiceCommands !== null &&
    conditionalChoiceCommands[lengthOfChoice - 1] &&
    conditionalChoiceCommands[lengthOfChoice - 1]['Time limit (optional)'] ===
      'true'
  ) {
    buttons.push(
      <ChoiceTimer
        key="choiceTimer"
        gameInterfaceList={gameInterfaceList}
        blobImages={blobImages}
        waitTime={conditionalChoiceCommands[lengthOfChoice - 1]['Wait up to']}
      />
    );
  }

  return buttons;
};

function CreateChoice({
  ifElseDecisionParameters,
  decisionParameters,
  handleDecisionClick,
  blobImages,
  gameInterfaceList,
  gameFonts,
  hideIfVisitedArray
}) {
  useEffect(() => {
  }, [decisionParameters, ifElseDecisionParameters, gameFonts]);
  return (
    <React.Fragment>
      <div className="button-preview-wrapper" id="button-preview-wrapper">
        {createButton(
          ifElseDecisionParameters,
          decisionParameters,
          handleDecisionClick,
          blobImages,
          gameInterfaceList,
          gameFonts,
          hideIfVisitedArray
        )}
      </div>
    </React.Fragment>
  );
}

export default CreateChoice;

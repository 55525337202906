export const commonServices = {
    filterErrors,
    sortList,
    sortListOthers,
    sortListReviewed,
    getQueryParams
}

function filterErrors(error) {
 
 
    return error ? error.substring(error.indexOf(":") + 1) : '';
 
}

function sortList(accountList, order) {
    if (order === 'desc') {
        return accountList.sort((a, b) => {
            return new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
        }).reverse();
    }

    return accountList.sort((a, b) => {
        return new Date(a.createdAt).getTime() -
            new Date(b.createdAt).getTime()
    }).reverse();
}

function sortListOthers(storyAdminList, order) {
    if (order === 'desc') {
        if (storyAdminList && storyAdminList.length > 0) {
            return storyAdminList.sort((a, b) => {
                return new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
            }).reverse();
        } else {
            return ([])
        }
    }
    if (storyAdminList && storyAdminList.length > 0) {

        return storyAdminList.sort((a, b) => {
            return new Date(a.updatedAt).getTime() -
                new Date(b.updatedAt).getTime()
        }).reverse();
    } else {
        return ([])
    }
}

function sortListReviewed(storyAdminListReviewed, order) {
    if (order === 'desc') {
        if (storyAdminListReviewed && storyAdminListReviewed.length > 0) {
            return storyAdminListReviewed.sort((a, b) => {
                return new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
            }).reverse();
        } else {
            return ([])
        }
    }
    if (storyAdminListReviewed && storyAdminListReviewed.length > 0) {
        return storyAdminListReviewed.sort((a, b) => {
            return new Date(a.updatedAt).getTime() -
                new Date(b.updatedAt).getTime()
        }).reverse();
    } else {
        return ([])
    }
}

function getQueryParams(search) {
    const params = new URLSearchParams(search);
    let paramObj = {};
    for(var value of params.keys()) {
        paramObj[value] = params.get(value);
    }

    return paramObj;
}
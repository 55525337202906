
import {endpoint} from "../../config/app.config";

export const fetchGameInterfaceList = (payload) => {
    return fetch(`${endpoint}/api/stories/${payload.storyId}/gameinterface`,
    {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}

export const saveGameInterface = (payload) => {
    return fetch(`${endpoint}/api/stories/${payload.storyId}/gameinterface/${payload.id}`, {
        method: 'POST',
        body: payload.formData,
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
        }
    })
    .then(res => res.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}

export const resetElement = (payload) => {
    return fetch(`${endpoint}/api/stories/${payload.storyId}/gameinterface/${payload.id}`,
    {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}

export const fetchGameFonts = (payload) => {
    return fetch (`${endpoint}/api/stories/${payload.storyId}/gamefonts`,
    {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}

export const putGameFonts = (payload) => {
    return fetch (`${endpoint}/api/stories/${payload.storyId}/gamefonts/${payload.fonts.id}`,
    {
        method: 'PUT',
        body: JSON.stringify(payload.fonts),
        headers: {
            'Authorization': `Bearer ${payload.authToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch((err) => {
        throw err;
    });
}
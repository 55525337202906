export const aspectRatioNineByNineteen = '9/19';
export const aspectRatioThreeByFour = '3/4';
export const cropPanelWidth = 322;
export const cropPanelHeight = 430;
export const defaultSelecterWidth = 98;
export const defaultSelecterHeight = 130;

export const aspectRatioParameters = {
    '3/4': {
        previewWidth: 233,
        previewHeight: 310,
        staticPreviewWidth: 150,
        staticPreviewHeight: 200,
        objectViewWidth: 322,
        objectViewHeight: 430,
        aspectRatio: 3/4,
        characterWidth: 310,
        characterHeight: 300,
        libraryViewWidth: 520,
        libraryViewHeight: 693 
    },
    '9/19': {
        previewWidth: 205,
        previewHeight: 432,
        staticPreviewWidth: 150,
        staticPreviewHeight: 200,
        objectViewWidth: 322,
        objectViewHeight: 430,
        aspectRatio: 9/19,
        characterWidth: 440,
        characterHeight: 430
    }
}
export const aspectRatioCalcThreeByFour = 3/4;
export const aspectRatioCalcNineByNineteen = 9/19;


// preview mode 

export const preview = {
    '3/4': {
        width: 233,
        height: 310,
        buttonPanelWidth: 233
    },
    '9/19': {
        width: 205,
        height: 432,
        buttonPanelWidth: 205
    }
}

export const characterPosition = {
    '3/4': {
        left: -41,
        right: -98,
        middle: -40
    },
    '9/19': {
        left: -96,
        right: -203,
        middle: -120
    }
}
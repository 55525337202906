import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const RequestAccount = styled.div`
height:100vh;
width: 100%;
background-image: url(/images/reset_paassword.png);
background-size: 100% 100%;
.width-85{
  width:85%;
}
  
  .login-wrapper{
    height: 100vh;
    width: 100%;
    background:#fff;
    position:relative;
    .request-account-success{
      padding:0 60px;
      .check-img{
        margin-bottom:32px;
      }
      h3{
        font-size:32px;
        color:#303030;
        line-height:normal;
        margin-bottom:25px;
      }
      p{
        font-size:14px;
        color:#707070;
        margin-bottom:40px;
        line-height:normal;
      }
    }
  }
  .input-error {
    border: 1px solid red !important;
  }
`;

export const LoginLogo = styled.div`
  position:absolute;
  right:-50px;
  top:15%;
  z-index:1;
`;

export const Login = styled.div`
  position:relative;
  height:100vh;
`;
export const LoginTitle = styled.div`
  color: ${colors.color_black};
  font-size: 32px;
  margin-bottom: 35px;
  line-height:normal;
`;

export const LoginForm = styled.div`
  width:100%;
  padding:0 80px;
  font-family:PT Sans;
  .form-group{
    margin-bottom:35px;
  .form-label{
    font-weight:bold;
    font-size:14px;
    color:#303030;
    margin-bottom:5px;
  }
}
  .modal-button-wrapper{
    .back-login-btn{
      padding:0px !important;
      background:none;
      border:none;
      font-weight:bold;
      font-size: 14px;
      font-family: Roboto;
      letter-spacing: 0px;
      color: #E6A554;
    }
  }

  .meter-red{
    width:300px;
    heigth:20px !important;
    // color:red;
  }
  .progress{
    margin-top: 5px;
    height:10px !important;
    background-color:#F5F5F5 !important;
  }
`;



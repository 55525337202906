 
import * as ActionTypes from '../actions/action-types';

export const initialState = {
  boxes: [],
  faces: [],
  blocks: [],
  drawPanelResult: [],
  displayLinks: [],
  displayBlocks: [],
  backgrounds: [],
}

export const SceneReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_DRAWPANEL_BLOCKS:
      return {
        ...state,
      }

    case ActionTypes.SET_FLOWCHART_BLOCKS:
      let indexConnector = 0;
      let getConnectorsAll = [];
      let getBlocksAll = [];
      const actionBlocks = state.blocks;
      actionBlocks.map((frames, index) => {
        if (frames.metaData !== undefined) {
          getBlocksAll[index] = frames.metaData;
          if (frames.connectors !== undefined) {
            frames.connectors.map((framesC, key) => {
              getConnectorsAll[indexConnector] = framesC;
              indexConnector++;
            });
          } else {
            getBlocksAll[index].svg = '../flowchart/red_rect.png';
          }
        }
      });
      return {
        ...state,
        displayBlocks: getBlocksAll,
        displayLinks: getConnectorsAll
      }

    case ActionTypes.GET_SUCCESS_BLOCKS_DETAILS:
      return {
        ...state,
        blocks: action.payload
      }

    case ActionTypes.GET_FAIL_BLOCKS_DETAILS:
      return {
        ...state
      }

    case ActionTypes.GET_BLOCKS_DETAILS:
      return {
        ...state,
      }

    case ActionTypes.ADD_NEW_BLOCK:
      const displayBlocksPrev = state.blocks;
      const frameLength = displayBlocksPrev.length;
      let chapterLinkNew = state.displayLinks
      let chapterBlockNew = state.displayBlocks;

      let newId = 0
      if (frameLength > 0) {
        newId = frameLength + 1;
      }

      const blockDataNew = {
        id: newId,
        name: `STORY-${newId}`,
        highlightColor: "blue",
        color: "blue",
        highlightStrokeColor: "blue",
        prevTarget: frameLength,
        x: 100,
        y: (newId) * 75,
        symbolType: action.data,
        svg: "../flowchart/red_rect.png"
      };

      const connectorsNew =
      {
        source: frameLength,
        target: newId,
        description: "next"
      };

      if (displayBlocksPrev[frameLength - 1].connectors === undefined) {
        displayBlocksPrev[frameLength - 1].connectors = [connectorsNew]
      } else {
        displayBlocksPrev[frameLength - 1].connectors.push(connectorsNew)
      }
      displayBlocksPrev[frameLength - 1].metaData.svg = '../flowchart/blk_bg.png';
      const MergeData = {
        metaData: blockDataNew,
        characters: [],
        background: [
          {
            "name": "bg1",
            "title": "bg1",
            "url": "bg1.png",
            "type": "backgroud"
          }
        ],
        id: newId
      };
      displayBlocksPrev.push(MergeData);
      chapterLinkNew.push(connectorsNew);
      chapterBlockNew.push(blockDataNew);
      return {
        ...state,
        blocks: displayBlocksPrev,
        displayLinks: chapterLinkNew,
        displayBlocks: chapterBlockNew
      }

    case ActionTypes.FAIL_ADD_NEW_BLOCK:
      return {
        ...state
      }

    case ActionTypes.ADD_NEW_LINKS_BLOCK:
      let chapterLinkUpdateData = state.displayLinks;
      let displayBlocksUpdate = state.blocks;
      const linkData = action.nodeLinkDataNew;
      if (displayBlocksUpdate[linkData.source - 1].connectors === undefined) {
        displayBlocksUpdate[linkData.source - 1].connectors = [linkData]
        displayBlocksUpdate[linkData.source - 1].metaData.svg = '../flowchart/blk_bg.png';
      } else {
        displayBlocksUpdate[linkData.source - 1].connectors.push(linkData)
      }
      chapterLinkUpdateData.push(action.nodeLinkDataNew)
      return {
        ...state,
        blocks: displayBlocksUpdate,
        displayLinks: chapterLinkUpdateData
      }

    case ActionTypes.UPDATE_BLOCK:
      let displayDrawBlocksUpdate = state.blocks;
      displayDrawBlocksUpdate[action.activeNodeId-1].characters = action.boxList;
      return{
        ...state,
        blocks: displayDrawBlocksUpdate
      }

    case ActionTypes.GET_CHARACTERS_DETAILS:
      return {
        ...state
      };

    case ActionTypes.GET_SUCCESS_CHARACTERS_DETAILS:
      return {
        ...state,
        boxes: action.payload
      };

    case ActionTypes.GET_FAIL_CHARACTERS_DETAILS:
      return {
        ...state
      };

    case ActionTypes.GET_BACKGROUND_DETAILS:
      return {
        ...state
      };

    case ActionTypes.GET_SUCCESS_BACKGROUND_DETAILS:
      return {
        ...state,
        backgrounds: action.payload
      };

    case ActionTypes.GET_FAIL_BACKGROUND_DETAILS:
      return {
        ...state
      }

    default:
      return state
  }
}
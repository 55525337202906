import styled from "styled-components";
import { colors } from './../../../common/styles/variables';

const addAssetMessageBox = styled.div`
    display: flex;
    justify-content: center;

    .btn-okay {
        margin-top: 30px;
        width: 120px;
        height: 40px;
    }

    .submit-asset-label {
        font-size: 24px;
        color: ${colors.shades_shade500};
        margin-top: 0px;
    }

    .text-note{
        font-size: 14px;
        color: #707070;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .cancel-popop {
        margin-top: 26px;
    }
`;

const successAssetContent = styled.div`
    width: 100%;
    text-align: center;
    .btn-snacktool{
        min-width:auto;
      }
      .success-icon{
          margin-bottom:30px;
          height:64px;
      }
      .submit-asset-label{
          margin-bottom:40px;
          font-size:24px;
          color: #303030;
          line-height:normal;
      }
`;

export const ModalStyled = {
    addAssetMessageBox,
    successAssetContent
}
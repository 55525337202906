export const CREATE_NEW_CHAPTER = 'Add new chapter';
export const EDIT_CHAPTER = 'Edit chapter';

export const CREATE_NEW_ROUTE = 'Create new character route';
export const UPDATE_NEW_ROUTE = 'Update character route';

export const DRAG_DROP = 'DRAG & DROP';
export const YOUR_FILE_TO_ASSETS = 'Your files to assets or';
export const BROWSE = 'browse';
export const SUPPORTED_FILE_FORMATS = 'Supported file formats: PNG, JPG, JPEG, MP3, WAV, OGG';
export const UPLOAD_SIZE_FILE = 'File size limit: 15 Mb ';
export const DROP_IT = 'Drop it!';
export const FILES_TYPE_NOT_ACCEPTED = 'Cannot upload. File type not support!';
export const FILE_IS_TOO_LARGE = 'File size cannot be bigger than 15 MB';
export const FILE_INVALID = 'Incorrect dimension.';
export const REMOVE_FILE = 'Remove file';

export const NEW_ROUTE_SAVE = 'Your character route has been created.';
export const NEW_ROUTE_UPDATE = 'Your character route has been updated.';

export const UPDATING_ROUTE = 'Updating route';
export const SAVING_NEW_ROUTE = 'Saving new route';

export const CANCEL = 'Cancel';

export const ASSET_FILE_ALLOWED_EXTENSIONS = [
  // image files
  // 'image/jpeg',
  'image/png',
  // 'image/jpg',
  // 'image/tiff',
  // 'image/bmp',
];

export const CHARACTER_ROUTE_FILE_ALLOWED_EXTENSIONS = [
  // image files
  //  'image/jpeg',
  'image/png',
  //  'image/jpg',
  // 'image/tiff',
  // 'image/bmp',
];


export const INTRO_OPTIONS = {
  skipLabel: "x",
  prevLabel: "Previous",
  nextLabel: "Next",
  exitOnEsc: true,
  exitOnOverlayClick: true,
  showStepNumbers: false,
  hidePrev: true,
  hideNext: true,
  doneLabel: 'Finish',
  overlayOpacity: 0,

};

export const stepRouteConstant = [
  {
    element: '.step-route-main',
    intro: '<h3>CHARACTER ROUTES</h3><p>Learn how to create different branches for each character in your story.</p>',
    position: 'bottom',
    tooltipClass: 'writetool_library_5',
  },
  {
    element: '.step-route',
    intro: '<h3>PROLOGUE</h3> <p>Before branching off into different stories, the player will be introduced to everything via the prologue.</p>',
    position: 'bottom',
    tooltipClass:'writetool_library_5'
  },
  {
    element: '.step-route',
    intro: '<h3>AFTER THE PROLOGUE</h3><p>Once a player finishes the prologue, they will be given a choice of which character’s story to follow.</p>',
    position: 'bottom',
    tooltipClass:'writetool_library_5'
  },
  {
    element: '.step-route',
    intro: '<h3>CREATE CHARACTER ROUTES</h3><p>When you create a new folder for a character, they will become an option for players to choose in the story. <br/>Then, begin writing under the character’s route to tell their story.</p>',
    position: 'bottom',
    tooltipClass:'writetool_library_1'
  },

];

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Translations from './translation';

function ReviewAdminStoryModal(props) {
  //APPROVED PUBLISH UNPUBLISH
  let title = '';
  let subTitle = '';
  let btnText = '';
  switch (props.reviewStatus) {
    case 'APPROVED':
      title = Translations.TITLE_APPROVE;
      subTitle = Translations.SUBTITLE_APPROVE;
      btnText = Translations.BTN_APPROVE;
      break;
    case 'REJECTED':
      title = Translations.TITLE_REJECT;
      subTitle = Translations.SUBTITLE_REJECT;
      btnText = Translations.BTN_REJECT;

      break;
    case 'PUBLISH':
      title = Translations.TITLE_PUBLISH;
      subTitle = Translations.SUBTITLE_PUBLISH;
      btnText = Translations.BTN_PUBLISH;
      break;
    case 'UNPUBLISH':
      title = Translations.TITLE_UNPUBLISH;
      subTitle = Translations.SUBTITLE_UNPUBLISH;
      btnText = Translations.BTN_UNPUBLISH;
      break;

    default:
      break;
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      backdrop="static"
    >
      <Modal.Body className="mt-5">
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">{title}</label>
            <label className="sub-heading">{subTitle}</label>
          </div>
        </div>
        <div className="modal-button-wrapper justify-content-between">
          <Button
            onClick={props.handleHideAdminReviewModal}
            className="btn-snacktool btn-snacktool-golden"
          >
            Cancel
          </Button>
          <Button
            onClick={props.handleStoryAdminReview}
            disabled={props.disableConfirmButton}
            className="btn-snacktool btn-snacktool-golden"
          >
            {btnText}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ReviewAdminStoryModal;

import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

function ToggleCheckBox({
  component,
  commandId,
  checkbox,
  handleChange,
  fullNameToggle,
  checkboxisChange,
  isCommandEdit
}) {
  // console.log(checkbox,"checkbox,")
  useEffect(() => {
    console.log('useEffect', '');
  }, [checkbox]);
  return (
    <React.Fragment>
      <Form.Group
        controlId={component.widgetName}
        className="commandLabelSuffix d-flex toggle-checkbox"
      >
        <label className="switch-flip">
          <input
            type="checkbox"
            name="toggleCharacter"
            className="checkbox-flip"
            label={component.label}
            onChange={event => handleChange(event)}
            value={
              (checkbox == true && checkboxisChange == 'change') ||
              (fullNameToggle == 'true' && checkboxisChange == '')
                ? true
                : false
            }
            checked={
              (checkbox == true && checkboxisChange == 'change') ||
              (fullNameToggle == 'true' && checkboxisChange == '')
                ? 'checked'
                : null
            }
          />
          <span className="slider-flip round"></span>
        </label>
        {checkbox} Show full names
      </Form.Group>
    </React.Fragment>
  );
}

export default ToggleCheckBox;

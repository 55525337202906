import React from 'react';
import * as translations from './../../translations';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
import './../../style/style.css';
import { LibraryServices } from './../service';
import * as Constant from './../../../../common/helper/pattern';

function AddInfo(props) {
    const {
        validated,
        handleSaveInfo,
        handleFormChange,
        handleSwitchChange,
        handleTagChange,
        asset,
        isEdit,
        defaultMax,
        handleNameBlur ,
        editAssetName,
        isAssetExist
    } = props;

    let assetTypes = LibraryServices.getCharacterTypeList(props.files);
    let assetTypeOptions = assetTypes.map((value, key) => {
        return (<option key={key} value={value}>{LibraryServices.getAssetTypeLabel(value)}</option>);
    });

    return (
        <LibraryStyle.addInfoContainer>
            <div className="mt-4">
            <Form id="add-info-form" 
                noValidate 
                validated={validated} 
                onSubmit={(e) => handleSaveInfo(e)}>
                 <Form.Group>
                    <Form.Label className="label-title">{translations.CHARACTER_TYPE}</Form.Label>
                    <Form.Control as="select"
                        name="assetType" 
                        onChange={handleFormChange}
                        defaultValue={asset.assetType}
                        disabled={(isEdit && asset.defaultAsset === true) ?'disabled':null}
                        required>
                            <option value="">Select type</option>
                        {assetTypeOptions}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {translations.ASSET_TYPE_ERROR_MESSAGE}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-0">
                    <Form.Label className="label-title">{translations.ASSET_CHARACTER_NAME}</Form.Label>
                    <Form.Control 
                        type="text"
                        name="assetName" 
                        onChange={handleFormChange} 
                        required 
                        pattern={Constant.ASSET_NAME_PATTERN}
                        onBlur={handleNameBlur}
                        defaultValue={asset.assetName}
                        autoComplete="off"/>
                    <Form.Control.Feedback type="invalid">
                        {translations.ASSET_NAME_ERROR_MESSAGE}
                    </Form.Control.Feedback>
                    {
                      isAssetExist === true && 
                          <Form.Text className="text-danger">
                        <h6>{translations.ASSET_NAME_UNIQUE}</h6>
                        </Form.Text>
                                                    }
                </Form.Group>
               
               
                {/* {`${props.disableDefaultAssetCheckbox || defaultMax?'bgDisable':''}  */}

        <Form.Group controlId = 'defaultAsset' className="commandLabelSuffix mb-0 pl-0">
            <div className="d-flex mt-4">
            <label className="switch-flip">
                <input type="checkbox" 
                    name='defaultAsset' 
                    className='checkbox-flip' 
                    
                    onChange={handleFormChange}
                   disabled={isEdit && asset.defaultAsset === true}
                   
                    checked={asset.defaultAsset === true || props.disableDefaultAssetCheckbox===true ? 'checked': ''}
                 />
                <span className={`${isEdit && asset.defaultAsset === true?'bgDisable slider-flip round':'slider-flip round'}`}></span>
            </label>
            <div className="ml-4">
                <p className="bold-text mb-0 d-flex align-items-center">Default image for this category <OverlayTrigger
                                key={`image-category`}
                                placement="top"
                                overlay={
                                  <Tooltip id={`image-category`} className="tooltip-320">
                                    Only one asset can be default by category at a given time. When a character is quickly added to the script the default images will be used.
                                </Tooltip>
                                }
                              >
                                <span className="info-icon ml-2" ></span>
                              </OverlayTrigger></p>
              
            </div>
            </div>
        </Form.Group>
                
        {/* checked = {default_asset === 'true' ? 'checked' : null}/> */}
               


               
            </Form>
            </div>
        </LibraryStyle.addInfoContainer>
    );
}

export default AddInfo
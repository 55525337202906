import styled from 'styled-components';
import { colors } from './../../../common/styles/variables';

export const TabLabel = styled.label`
  letter-spacing: 0;
  color: #4d4f5c;
  opacity: 1;
`;

const addInfoContainer = styled.div`
  .infoContainer {
    margin-top: 5%;
    min-height: 250px;
  }
  .text-secondary {
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    line-height: normal;
  }
  .form-label {
    font-weight: bold;
  }
  .upload-files-wrapper {
    .album-cover-upload,
    .series-background-upload {
      ${'' /* width:110px;
      margin-right:28px; */}

      .row-100 {
        width: 100%;
      }
      .info-icon {
        background: url('/images/icons/info.svg') no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
      }
      .info-icon:hover {
        background: url('/images/icons/info-active.svg') no-repeat;
      }
      .title-text {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #303030;
      }
      .subtitle-text {
        font-size: 14px;
        color: #707070;
        margin-bottom: 5px;
      }
      div:focus {
        outline: 0;
        box-shadow: none;
      }
      .reset-file-block {
        margin-top: 5px;
        span {
          text-decoration: underline;
        }
      }

      .card.bg-light {
        background: #f5f5f5;
        border: 1px solid #bfbfbf;
        .card-content {
          width: 100%;
          height: 176px;
          padding: 0px;
          img {
            width: 100%;
          }
          .fa-cloud-upload:before {
            content: '';
            background: url(/images/icons/backup.svg) no-repeat !important;
            width: 54px;
            height: 36px;
            display: block;
          }
        }
      }
    }
    .remove-width {
      padding: 5px 0 0 10px;
    }
    .padding-0 {
      padding: 0 !important;
    }
    .margin-20 {
      margin-top: 20% !important;
    }
    .margin-16 {
      margin-top: 16% !important;
    }

    .subtitle-text-uplaod {
      text-align: left;
      font: normal normal normal 14px/19px PT Sans;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
    .series-background-upload {
      ${'' /* width:275px; */}
      .card.bg-light {
        .card-content {
        }
        .thumbs-container {
          height: 100%;
        }
      }
    }

    .btn-upload-file {
      cursor: pointer;
    }
    .btn-upload-file:hover {
      box-shadow: 0px 3px 4px #00000033 !important;
      border-radius: 50%;
    }
    .btn-cross {
      cursor: pointer;
    }

    .title-wrapper {
      padding: 35% 0% 0 16%;
    }
    .subtitle-caption {
      margin-top: 4%;
    }
    .subtitle-spacing {
      padding-right: 5px;
    }

    .border-row {
      box-sizing: border-box;
      border-right: 4px solid #17162c;
      border-left: 4px solid #17162c;
    }
    .border-row1 {
      box-sizing: border-box;
      border-right: 4px solid #17162c;
      border-left: 4px solid #17162c;
      border-bottom: 4px solid #17162c;
    }

    .border-row-bg {
      word-wrap: break-word;
      background: transparent linear-gradient(180deg, #17152e 0%, #5d5c7c 100%)
        0% 0% no-repeat padding-box;
    }

    .title-caption {
      height: 76px;

      background: transparent
        linear-gradient(180deg, #17152e00 0%, #17152e 100%) 0% 0% no-repeat
        padding-box;
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: center;
      font: normal normal normal 7px/9px PT Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .title-caption-border {
      height: 76px;

      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: center;
      font: normal normal normal 7px/9px PT Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .mid-col-wrapper {
      background: #17152e 0% 0% no-repeat padding-box;
      width: 33%;
      height: 337px;
    }

    .margin-5 {
      box-sizing: border-box;

      border-bottom: 0;
    }

    .play-button {
      margin: 5% 9% 2%;

      width: 171px;
      height: 23px;
      background: #eed9ff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      text-align: center;
      font: normal normal bold 7px/9px PT Sans;
      letter-spacing: 0.7px;
      color: #3a3352;
      opacity: 1;
    }
    .border-box {
      margin: 5px;
      box-sizing: border;
    }
    .cover-content {
      max-width: 400px !important;
      overflow: hidden;
      min-height: 96px;
      max-height: 96px;
      overflow-x: hidden;
      overflow-y: hidden;
      text-align: left;
      font: normal normal normal 9px/14px PT Sans;
      letter-spacing: 0px;
      color: #ffffff;
      margin: 11%;
    }
    .cover-bottom {
      width: 100%;
      min-height: 35px;
      background: transparent linear-gradient(180deg, #17152e 0%, #5d5c7c 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
    }

    .margin-footer {
      margin-top: 1% !important;
    }

    .epmtyBorder {
      border: 4px solid #17162c;
      margin: 1%;
    }

    .fileBorder {
      margin: 1%;
      border: 4px solid #6cc4ae !important;
      z-index: 9999 !important;
    }

    .cover-grey {
      width: 100%;
      height: 100px;
      background: var(--lightest-grey) 0% 0% no-repeat padding-box;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border: 1px solid #bfbfbf;
      opacity: 0.2;
    }

    .cover-title-main {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font: normal normal normal 8px/14px PT Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      max-width: 68px;
      word-wrap: break-word;
      text-align: center !important;
    }
    .cover-title {
      text-align: center;
      font: normal normal normal 8px/14px PT Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .cover-heading {
      text-align: left;
      font: normal normal normal 12px/15px PT Sans;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
  }
`;

const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
`;

const submitAssetContent = styled.div`
  width: 100%;
  margin-top: 20%;
  text-align: center;
`;

const successAssetContent = styled.div`
  width: 100%;
  margin-top: 10%;
  text-align: center;
`;

const tabLayout = styled.div`
  display: FLEX;
  width: 100%;
`;

const leftTabLabelLayout = styled.div`
  width: 44%;
  display: flex;
  position: relative;
`;

const rightTabLabelLayout = styled.div`
  width: 44%;
  display: flex;
  position: relative;
  &:last-child {
    width: 10%;
  }
`;

const leftTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rightTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const leftTabLine = styled.div`
  width: 93%;
  height: 3px;
  background: #157ffb;
  position: absolute;
  left: 50px;
  top: 15px;
  z-index: -1;
`;

const rightTabLine = styled.div`
  top: 231px;
  left: 641px;
  width: 65px;
  height: 3px;
  background: #157ffb 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 16px;
  float: left;
`;

const lastTabLine = styled.div`
  top: 231px;
  left: 641px;
  width: 160px;
  height: 3px;
  background: #157ffb 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 15px;
  float: left;
`;

const firstCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #4d4f5c;
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
`;

const secondCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  color: #4d4f5c;
  margin-top: 5px;
  white-space: nowrap;
`;

const listContentPanel = styled.div`
  margin-left: 233px;
  margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  height: 880px;
  background: ${colors.color_white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  height: calc(100vh - 90px);
`;

const listHeading = styled.div`
  padding: 4px 30px 3px 25px;
  font-familly: PT Sans Regular;
  font-size: 24px;
  padding-left: 25px;
  width: 100%;
  text-align: left;
  letter-spacing: 0;
  color: ${colors.shades_shade500};

  .filter-button {
    background: ${colors.shades_shade200} 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    text-align: center;
    font: Roboto;
    font-size: 12px;
    letter-spacing: 0.22px;
    color: #ffffffde;
    opacity: 1;
    width: auto;
    float: right;
    padding: 3px 13px 3px 13px;
    margin: 12px 7px 0 0;
    cursor: pointer;
    border: ${colors.shades_shade200};
  }

  .last-filter-button {
    margin-right: 5px;
  }

  .filter-button-active {
    background-color: #530acd;
    border: #530acd;
  }
`;

const listMainSection = styled.div`
  margin-top: 8px;
  left: 234px;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  opacity: 1;

  .button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid ${colors.primary_green};
    border-radius: 40px;
    opacity: 1;
    color: ${colors.primary_green};
    font-family: Robot;
    font-size: 16px;
    text-align: center;
    margin-right: 20px;
  }

  .button-de-active {
    background: #efefef 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    border: #efefef;
    padding: 12px 30px 12px 30px;
    text-align: center;
    font: Bold 14px/16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
    margin-right: 20px;
  }

  .content-head-row {
    height: 100px;
    padding-top: 40px;
  }

  .right-text {
    text-align: right;
    font: Regular 14px/19px PT Sans;
    letter-spacing: 0;
    color: #707070;
    opacity: 0.5;
    padding-top: 6px;
  }
`;

const listTable = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 500px;
  text-align: center;

  .table-head {
    background-color: #f5f5fa;
    text-align: center;
    font: Regular 11px/14px PT Sans;
    letter-spacing: 1.1px;
    color: #606060;
    text-transform: uppercase;
    opacity: 1;
    top: 0;
  }

  .table-head-row th {
    border-top: #f5f5fa;
    border-bottom: #f5f5fa;
    font-size: 14px;
    color: #606060;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    opacity: 1;
    font-weight: normal;
  }

  input[type='checkbox'] {
    display: none;
  }

  /* The checkboxContainer */
  .checkboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid ${colors.shades_shade200};
    border-radius: 6px;
  }

  span.disable {
    background: #e0e0e0;
    border: #e0e0e0;
  }

  /* On mouse-over, add a grey background color */
  .checkmark .disable:hover ~ .checkmark {
    background-color: #e0e0e0 !important;
  }
  .enabled:hover {
    border: 2px solid #3b86ff;
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .table-head-col {
    width: 4%;
  }

  .table-body-row td {
    font-size: 14px;
    font: PT Sans;
    letter-spacing: 0;
    color: ${colors.shades_shade500};
    opacity: 1;
    font-weight: normal;
  }

  .shared-asset-active {
    color: #707070;
  }

  .shared-asset-in-active {
    color: #dfdfdf;
  }

  .badges {
    background-color: #dfdfdf;
    border-radius: 46px;
    opacity: 1;
    text-align: center;
    font: Regular 12px/14px Roboto;
    letter-spacing: 0.22px;
    color: #000000de;
    opacity: 1;
    padding: 8px 16px 9px 16px;
    font-weight: normal;
    font-size: 14px;
  }

  .text {
    color: #acacac !important;
    opacity: 1;
    font-size: 15px;
    text-align: center;
  }

  .action-icons {
    font-size: 20px !important;
    padding-right: 15px;
  }

  .action-icon-active {
    color: #530acd;
  }

  .action-icon-in-active {
    color: #707070;
  }

  .asset-name-col {
    width: 30%;
    text-align: left;
  }
`;

const addAssetConfirmBox = styled.div`
  display: flex;
  justify-content: center;

  .btn-okay {
    margin-top: 20%;
    width: 120px;
    height: 40px;
  }

  .submit-asset-label {
    font-size: 24px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
  }

  .delete-asset-label {
    font-size: 21px;
    color: ${colors.shades_shade500};
    margin-top: 0px;
  }

  .text-note {
    font-size: 14px;
    color: #707070;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const confirmAssetContent = styled.div`
  width: 100%;
  margin-top: 8%;
  text-align: center;
`;

const commandPanel = styled.div`
.command-panel-head-row {
    height: 51px;
    verticle-align: center;
    border-bottom: 0.5px solid ${colors.shades_shade200};
    padding: 0px 10px 0px 20px;
    cursor:pointer;
    font-size: 14px;
    // background:#E6E4E4;
    background:#F0EEF8;
    color: #3A2C61;
    width:100%;
}
.command-head {
    width:100% !important;
    border-bottom: 1px solid ${colors.shades_shade200};
    .right-col{
      border-right: 1px solid ${colors.shades_shade200};
    }
    .left-col{
      padding-top: 10px;
    color: #ACACAC;
    margin-left: -16px;
    margin-bottom: 10px;
    .sub-heading{
      color:#000000DE;
    }
    }
    .row{
      width:100% !important;
    }
    .head-icon{
      font-size: 40px;
      padding-top: 15px;
      color:${colors.black} !important;
      margin-left: 0% !important;
      cursor: pointer;
    }
}


.spinner-class{
  text-align: center !important;
  vertical-align: middle !important;
  margin: 50% 40% !important;
  color: #FFFFFF !important;
}
.head-title {
  font-size:14px;
  // color: #530ACD;
  width:100%;
  font-weight:bold;
}
.head-icon {
    color: ${colors.shades_shade200};
    text-align:right;
}
.command-title {
    font-size: 11px;
    // color: #ACACAC;
    color: #3A2C61;
    text-transform: uppercase;
    float: left;
    font-family:PT Sans;
    letter-spacing: 1.1px;
}

.command-shortcut {
    font-size: 14px;
    color: #ACACAC;
    float:right;
     
}
.command-key-active {
  // background: #530ACD;
    /* width: 27px; */
    height: 25px;
}
.command-key {
  cursor:pointer;
  height: 15px;
    // background: #ffffff;
}



.command-btn {
  border: none !important;
    font-size: 14px;
    // color: ${colors.color_black};
    color: #3A2C61;
    text-decoration: none !important;

}
.command-btn:hover {
    color: ${colors.primary_yellowRgb};
    text-decoration: underline;
    font-weight:bold;
    
}
.commands {
    height: calc(100vh - 262px);
    overflow: auto;
    border-bottom: 1px solid #BFBFBF; 
    padding: 19px 10px 0 16px;
    // background:#E6E4E4;
    background:#F0EEF8;
}
.commands-2 {
  .chrome-picker {
    width:100% !important;
  }
}
.accordion{
  margin-bottom: 8px;
}
.accordion .badge{
  padding:5px !important;
  font-size:12px !important;
}
.badge-dark{
  background:#43425D !important;
  
}

.command-head-panel{
  border-bottom: 0.5px solid #3A2C61;
  font-size: 14px;
  background: #F0EEF8;
  display:flex;
  align-items:center;
  height:50px;
}
.command-head-panel .head-icon{
  color:${colors.medium_light_gray};
  font-size:24px;
  padding:0 15px;
  cursor:pointer;
}

.command-head-panel .title-block{
    display:flex;
    flex-direction:column;
    padding:7px 0 7px 6px;
    border-left:1px solid #E3E3E3;
}

.command-head-panel  .title{
  color: #3A2C61;
  text-transform: uppercase;
  font-size:11px;
  font-family:PT Sans;
  line-height: 14px;
}

.command-head-panel .sub-title{
  color:#3A2C61;
  font-size:14px;
  font-family:Roboto;
}

.commands-body{
  height: calc(100vh - 200px);
  overflow: auto;
  border-bottom: 1px solid #FFFFFF; 
  background:#F0EEF8;
  padding:15px 18px;
    .form-row{
      margin:0;
      .col{
        padding:0;
      }
    }
    .add-decision-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px 15px !important;
      margin-bottom: 15px;
      .fa-plus-circle{
        margin-left:5px;
      }
    }

}


.back-btn {
    border-right: 1px solid ${colors.shades_shade200};
    width: 40px;
    float: left;
    text-align: center;
}
.command-second-muted-text{
    font-size: 11px;
    text-transform: uppercase;
    color: #ACACAC;
    float:left;
}
.command-second-text {
    color: #000000DE;
    font-size: 10px;
    font-weight: bold;
}

.submit-btn{
  width: 100%;
  height: 40px;
  background: ${colors.primary_green} 0% 0% no-repeat padding-box;
}


.textlength{
  padding-right:0 !important;
}
.commandLabel {
  text-align: left;
  font-weight: Bold;
  font-size : 14px;
  line-height: 19px
  color: #3A2C61;
  margin:0;
}
.commandLabelDelete{
  text-align: left;
  font-weight: Bold;
  font-size : 14px;
  line-height: 19px
  color: ${colors.color_black};
  margin:0;
}
.commandSubLabel {
  font-size: 13px;
  color: #A9A9AD;
  margin: 4px 0;
}
.commandLevelHeading{
  font-weight:bold;
  color: #3A2C61;
}
}
.commandlabelsuffixDiv{
  align-items: center;
  display: flex;
  margin-left: 8px;
}
.commandLabelSuffix {
  font-weight:normal;
  font-size : 14px;
  line-height: 19px
  letter-spacing: 0;
  // color: ${colors.shades_shade500};
  color: #3A2C61;
  opacity: 1;
  padding-left : 5px;
}

.switch-flip {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch-flip input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-flip {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-flip:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-flip {
  background-color: #2196F3;
}

input:focus + .slider-flip {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-flip:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider-flip.round {
  border-radius: 34px;
}

.slider-flip.round:before {
  border-radius: 50%;
}

.commandLevelHeading {
  font-weight:bold;
  color: #3A2C61;
}

.commandlabelsuffixDiv {
    text-align: left;
    padding-top: 2.5%;
}

.commandLabelSuffix{
    // color: #000000DE;
    color: #3A2C61;
    padding: 0px !important;
    margin-top: 0% !important;
    font-weight:bold;
}
.toggle-checkbox {
  margin-top: 15px !important;
}

.commandNote {
  padding: 0px !important;
  margin-top: 0% !important;
  // color: #707070;
  color: #ACACAC;
  padding-top: 5px !important;
}

.commandSuffixSetVar{
  color: ${colors.medium_light_gray}
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
}

.keyboard-opacity {
  opacity: 0.3;
}
.btn-link{
  padding-left:1rem !important;
}
`;

const routeInfo = styled.div`
  .left {
    .prompt,
    .synopsis {
      height: 415px;
      background: #17152d;
      padding: 0 16px;
      .text-wrapper {
        background: #332d48;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        padding: 18px 16px 14px;
        color: #fff;
        text-align: center;
        width: 100%;
        margin-top: -60px;
        .title {
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0px;
          margin-bottom: 5px;
        }
        .text {
          font-family: PT Sans;
          font-size: 9px;
        }
        button {
          background: rgba(238, 217, 255, 1);
          border-radius: 5px;
          text-align: center;
          font-size: 8px;
          font-weight: bold;
          letter-spacing: 0.4px;
          color: rgba(58, 51, 82, 1);
          padding: 5px 0;
          width: 100%;
          text-transform: uppercase;
          margin-top: 15px;
          border: none;
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
      }
    }
    .synopsis {
      padding: 0;
      background: url(/images/synopsis.png) no-repeat;
      background-size: 100% 100%;
      .text-wrapper {
        text-align: left;
        border-radius: 0;
        padding: 14px 16px;
        background: #393450;
        margin-bottom: 15px;
        .title {
          text-transform: uppercase;
          font-size: 8px;
        }
        .text {
          font-size: 8px;
          color: rgba(238, 217, 255, 1);
        }
      }
    }
  }
  .right {
    width: 100%;
    height: 100%;
    padding: 0 14px 0 40px !important;
    .title {
      font-size: 24px;
      letter-spacing: 0px;
      color: #303030;
      margin-bottom: 20px;
    }
    .right-route-info {
      padding-left: 20px;
      .vertical-line {
        padding-left: 25px;
        border-left: 3px solid rgb(21, 127, 251);
        position: relative;
        padding-bottom: 43px;
        &:last-child {
          border-left: 3px solid transparent;
        }
      }
      .circle {
        position: absolute;
        background: #fff;
        border: 3px solid #157ffb;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        color: #157ffb;
        font-size: 13px;
        font-weight: bold;
        font-family: sourceSansProBold;
        left: -20px;
        top: 0;
        text-align: center;
        padding-top: 6px;
      }
      .circle.active {
        background: #157ffb;
        color: #fff;
      }
      .label-title {
        font-size: 16px;
        font-weight: bold;
        color: #303030;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 0.5rem;
        width: 100%;
      }
      .textAreaResize {
        border-radius: 4px;
        font-size: 14px;
        color: #707070;
        line-height: normal;
      }
    }
  }
`;

export const LibraryStyle = {
  addInfoContainer,
  addAssetMessageBox,
  submitAssetContent,
  successAssetContent,
  tabLayout,
  leftTabLabelLayout,
  rightTabLabelLayout,
  leftTabLabelContent,
  rightTabLabelContent,
  leftTabLine,
  rightTabLine,
  firstCircleContent,
  secondCircleContent,
  listContentPanel,
  listHeading,
  listMainSection,
  listTable,
  lastTabLine,
  addAssetConfirmBox,
  confirmAssetContent,
  commandPanel,
  routeInfo
};

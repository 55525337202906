import { all, fork } from 'redux-saga/effects';
import { postBlockDataWatcher } from './block-saga';

import {
  postUserDataWatcher,
  fetchUserAccountsWatcher,
  deleteUserDataWatcher,
  requestAccountWatcher,
  deleteAccountDataWatcher,
  updateMyAccountDetailsWatcher,
  changePasswordMyAccountWatcher,
  deleteMyAccountWatcher,
  passwordRecoveryWatcher,
  changePasswordWatcher
} from './accounts-saga';

import {
  postLoginDataWatcher,
  postRefreshTokenWatcher,
  postTutorialDataWatcher,
  getTutorialDataWatcher,
  toggleCharacterFullName,
  fontChangeSettingWatcher
} from './login-saga';

import { getCharacterDataWatcher, getBlocksDataWatcher } from './scene-saga';

import {
  addAssetDataWatcher,
  listAssetDataWatcher,
  fetchAssetDeleteDataWatcher,
  AssetDeleteDataWatcher,
  fetchAssetByIdDataWatcher,
  setLibraryChangeLogWatcher,
  fetchAssetEditInfoWatcher,
  setAssetUploadDataWatcher,
  listCoverDataWatcher,
  setCoverUploadDataWatcher,
  fetchCoverByIdDataWatcher,
  fetchCoverEditInfoWatcher,
  addCoverDataWatcher,
  CoverDeleteDataWatcher,
  coverActiveChangeWatcher
} from './library-saga';

import {
  getFlowchartDataWatcher,
  getBlockFlowchartDataWatcher,
  getBlockNameValidWatcher,
  createSceneWatcher,
  updateSceneWatcher,
  fetchPreviewDataOnClick,
  createRouteWatcher,
  listRouteWatcher,
  fetchRoutesByIDDataWatcher,
  fetchdeleteRoutesDataWatcher,
  fetchMarkStoryAsCompleted,
  saveFlowchartDragData,
  fetchDraftStatus,
  createCastWatcher,
  fetchCastDataWatcher,
  fetchdeleteCastDataWatcher
} from './flowchart-saga';

import {
  characterDataWatcher,
  fetchCharacterAssetsDataWatcher,
  saveFinalCharacterWatcher,
  checkVariationExistsWatcher,
  fetchCharacterDataWatcher,
  fetchCharacterDeleteDataWatcher,
  CharacterDeleteDataWatcher,
  fetchCharacterForScriptDataWatcher,
  fetchCharacterByIDDataWatcher,
  fetchRouteCharacterDataWatcher
} from './character-saga';
import {
  getCmdLevel1Watcher,
  getCmdLevel2Watcher,
  getCmdLevel2ScriptWatcher,
  // getCharacterVariationsWatcher,
  // getCharacterExpressionsWatcher,
  getCharacterColorWatcher,
  getStoryDataWatcher,
  storyNameDataWatcher,
  getStoryGenereDataWatcher,
  addStoryDataWatcher,
  getStoryDetailsDataWatcher,
  getStoryAdminDataWatcher,
  storyReviewDataWatcher,
  storyDeleteDataWatcher,
  setCommandDataWatcher,
  getCommandsDataWatcher,
  getAvaialbleBlockDataWatcher,
  getPreviewAssetDataWatcher,
  getCommandsEditDataWatcher,
  storyAdminReviewDataWatcher,
  storyCollaboratorListWatcher,
  addCollaboratorToStoryWatcher,
  getWriterListWatcher,
  revokeCollaboratorToStoryWatcher,
  getReleaseLockDataWatcher,
  storyLockedDataWatcher,
  localVariableWatcher,
  resetAllVariableWindowWatcher,
  getCharacterRoutesListAllWatcher,
  storyValidDataWatcher,
  updateRouteInfoWatcher,
  changelogScriptWatcher,
  storyViewerListWatcher,
  storyDuplicateDataWatcher,
  addViewerToStoryWatcher,
  revokeViewerToStoryWatcher,
  setAutoSaveCommandDataWatcher,
  setCommandPanelDataWatcher,
  revokeViewerToStory,
  updateStoryIdWatcher,
  addStoryCreditsWatcher,
  chapterSpellCheckWatcher,
  grammarIgnoreWords
} from './story-saga';
import { postStoryProgressDataWatcher } from './story-progress-saga';

import {
  getCommandBackgroundWatcher,
  getCommandCharacterWatcher,
  getCommandDialgoueWatcher,
  getCommandMediaWatcher,
  getCommandPlayMusicWatcher
} from './command-saga';

import { playerModelDataWatcher } from './player-saga';

import {
  addCommentDataWatcher,
  getCommentsWatcher,
  updateCommentDataWatcher
} from './comment-saga';

import {
  listGameInterfaceDataWatcher,
  saveGameInterfaceDataWatcher,
  resetGameInterfaceDataWatcher,
  getGameFontsDataWatcher,
  setGameFontsDataWatcher
} from './game-interface-saga';

import {
  placeBackgroundDataWatcher,
  saveViewLaterWatcher,
  getViewsWatcher,
  getEditableViewFlagWatcher,
  getOtherViewFromIdWatcher
} from './place-background-saga';

export function* rootSaga() {
  //yield all - runs all the actions concurrent
  yield all(
    [
      createSceneWatcher,
      getCommandBackgroundWatcher,
      getCommandCharacterWatcher,
      getCommandDialgoueWatcher,
      getCommandMediaWatcher,
      getCommandPlayMusicWatcher,
      getCmdLevel1Watcher,
      getCmdLevel2Watcher,
      getCmdLevel2ScriptWatcher,
      getCharacterColorWatcher,
      postBlockDataWatcher,
      getCharacterDataWatcher,
      getBlocksDataWatcher,
      postUserDataWatcher,
      fetchUserAccountsWatcher,
      postLoginDataWatcher,
      postTutorialDataWatcher,
      getTutorialDataWatcher,
      addAssetDataWatcher,
      listAssetDataWatcher,
      fetchAssetDeleteDataWatcher,
      AssetDeleteDataWatcher,
      getStoryDataWatcher,
      deleteUserDataWatcher,
      getFlowchartDataWatcher,
      getBlockFlowchartDataWatcher,
      characterDataWatcher,
      fetchCharacterAssetsDataWatcher,
      storyNameDataWatcher,
      getStoryGenereDataWatcher,
      addStoryDataWatcher,
      saveFinalCharacterWatcher,
      postStoryProgressDataWatcher,
      checkVariationExistsWatcher,
      getBlockNameValidWatcher,
      fetchCharacterDataWatcher,
      playerModelDataWatcher,
      getStoryDetailsDataWatcher,
      listGameInterfaceDataWatcher,
      listGameInterfaceDataWatcher,
      saveGameInterfaceDataWatcher,
      resetGameInterfaceDataWatcher,
      fetchCharacterDeleteDataWatcher,
      CharacterDeleteDataWatcher,
      getStoryAdminDataWatcher,
      storyReviewDataWatcher,
      setCommandDataWatcher,
      getCommandsDataWatcher,
      getAvaialbleBlockDataWatcher,
      fetchCharacterForScriptDataWatcher,
      placeBackgroundDataWatcher,
      saveViewLaterWatcher,
      getViewsWatcher,
      updateSceneWatcher,
      getPreviewAssetDataWatcher,
      getCommandsEditDataWatcher,
      fetchPreviewDataOnClick,
      fetchCharacterByIDDataWatcher,
      storyDeleteDataWatcher,
      storyAdminReviewDataWatcher,
      fetchAssetByIdDataWatcher,
      addCommentDataWatcher,
      getCommentsWatcher,
      updateCommentDataWatcher,
      storyCollaboratorListWatcher,
      addCollaboratorToStoryWatcher,
      getWriterListWatcher,
      revokeCollaboratorToStoryWatcher,
      getReleaseLockDataWatcher,
      storyLockedDataWatcher,
      postRefreshTokenWatcher,
      localVariableWatcher,
      resetAllVariableWindowWatcher,
      createRouteWatcher,
      listRouteWatcher,
      fetchRouteCharacterDataWatcher,
      fetchRoutesByIDDataWatcher,
      fetchdeleteRoutesDataWatcher,
      fetchMarkStoryAsCompleted,
      storyValidDataWatcher,
      getGameFontsDataWatcher,
      setGameFontsDataWatcher,
      updateRouteInfoWatcher,
      updateMyAccountDetailsWatcher,
      deleteAccountDataWatcher,
      requestAccountWatcher,
      changePasswordMyAccountWatcher,
      deleteMyAccountWatcher,
      passwordRecoveryWatcher,
      changePasswordWatcher,
      changelogScriptWatcher,
      storyDuplicateDataWatcher,
      setLibraryChangeLogWatcher,
      fetchAssetEditInfoWatcher,
      saveFlowchartDragData,
      fetchDraftStatus,
      setAssetUploadDataWatcher,
      toggleCharacterFullName,
      getEditableViewFlagWatcher,
      storyViewerListWatcher,
      addViewerToStoryWatcher,
      revokeViewerToStoryWatcher,
      createCastWatcher,
      fetchCastDataWatcher,
      fetchdeleteCastDataWatcher,
      listCoverDataWatcher,
      setCoverUploadDataWatcher,
      fetchCoverByIdDataWatcher,
      fetchCoverEditInfoWatcher,
      addCoverDataWatcher,
      CoverDeleteDataWatcher,
      coverActiveChangeWatcher,
      updateStoryIdWatcher,
      setAutoSaveCommandDataWatcher,
      setCommandPanelDataWatcher,
      getOtherViewFromIdWatcher,
      addStoryCreditsWatcher,
      fontChangeSettingWatcher,
      chapterSpellCheckWatcher,
      grammarIgnoreWords
    ].map(fork)
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import * as styled from './../../player/style';



const FindAndReplace = (props) => {
    let textInput = null
    useEffect(() => {
        if (props.findReplace === true) {
            // textInput.focus()
        }

    });


    return (
        <div className="backgroundFindReplace">
            <div className="main-form-find-replace mt-1">
                <div className="d-flex space-between">
                    <div className="flex-items mr-2">
                        <input
                            // ref={(input) => { textInput = input; }}
                            className="form-control input-width"
                            value={props.valueFind}
                            onChange={(event) => props.onChangeFindValue(event)}
                            placeholder="Search for"
                            autoFocus={true}
                            id="search-for"
                        />
                    </div>
                    <div
                        className="flex-items mr-3">
                        <button
                            className="btn-snacktool btn-snacktool-brown find-button"
                            onClick={() => props.onClickSearch()}

                            disabled={props.valueFind ? false : true}

                        >
                            <i className="fa fa-search" /> Find
                        </button>
                    </div>
                    <div
                        className="flex-items mr-1 padding-top search-count"
                    >
                        {
                            props.searchCounter !== '0 of 0' &&

                            <span>{props.searchCounter}</span>
                        }
                    </div>
                    <div
                        className="flex-items mr-1 w-50"
                    >
                        {
                            props.searchCounter !== '0 of 0' &&
                            <>
                                <img src="/images/icons/Preivous.svg"
                                    // className="fa fa-chevron-left mr-3"
                                    onClick={props.searchCounter === '0 of 0' ? '' : () => props.prevFindClickNextPrevious('previousFind')}

                                />
                                <img src="/images/icons/Next.svg"
                                    className="fa fa-chevron-right"
                                    onClick={props.searchCounter === '0 of 0' ? '' : () => props.prevFindClickNextPrevious('nextFind')}
                                />
                            </>
                        }
                    </div>
                    <div className="flex-items">
                        <img src="/images/icons/Icon-Close-Light.svg"
                            className="closeButton"
                            onClick={() => props.closeFindReplace(false)}
                        />
                    </div>
                </div>

                <div className="d-flex mt-1 space-between">
                    <div className="flex-items mr-2">
                        <input
                            className="form-control input-width"
                            value={props.valueReplace}
                            placeholder="Replace with"
                            onChange={(event) => props.onChangeReplaceValue(event)}
                        />
                    </div>
                    <div className="flex-items mr-2">
                        <button
                            key="singleReplace"
                            className="btn-snacktool btn-snacktool-brown replace-button"
                            onClick={() => props.onClickReplace('single')}
                            disabled={props.searchCounter === '0 of 0' ? true : false}
                        >
                            Replace
                        </button>
                    </div>
                    <div className="flex-items mr-2">
                        <button
                            key="alleReplace"
                            className="btn-snacktool btn-snacktool-brown replace-all"
                            onClick={() => props.onClickReplace('all')}
                            disabled={props.searchCounter === '0 of 0' ? true : false}
                        >
                            Replace All
                        </button>
                    </div>
                    <div className="flex-items mr-2">

                    </div>
                </div>

                <div className="d-flex mt-2 space-between" >
                    <div className="flex-items mr-3">
                        <styled.scrollHighlight className="d-flex">
                            <label className="switch-flip">
                                <input
                                    type="checkbox"
                                    name={`checkbox_whole_word`}
                                    className='checkbox-flip'
                                    label="checkbox"
                                    onChange={() => props.findAndReplaceParameters('wholeWord')}
                                    checked={props.searchWholeWord === true ? 'checked' : ''}
                                />
                                <span className="slider-flip round"></span>
                            </label>
                            <span
                                className="label-text checkboxLabel">Match entire words</span>
                        </styled.scrollHighlight>
                    </div>
                    <div className="flex-items mr-3">
                        <styled.scrollHighlight className="d-flex">

                            <label className="switch-flip">
                                <input
                                    type="checkbox"
                                    name={`checkbox_case_sensitive_word`}
                                    className='checkbox-flip'
                                    label="checkbox"
                                    onChange={() => props.findAndReplaceParameters('caseSensitive')}
                                    checked={props.searchCaseSensitive === true ? 'checked' : ''}
                                />
                                <span className="slider-flip round"></span>
                            </label>
                            <span className="label-text checkboxLabel">
                                Case sensitive
                            </span>
                        </styled.scrollHighlight>

                    </div>
                    <div className="flex-items mr-3">
                        <styled.scrollHighlight className="d-flex">
                            <label className="switch-flip">
                                <input
                                    type="checkbox"
                                    name={`checkbox_reg_match`}
                                    className='checkbox-flip'
                                    label="checkbox"
                                    onChange={() => props.findAndReplaceParameters('regExp')}
                                    checked={props.searchRegExp === true ? 'checked' : ''}
                                />
                                <span className="slider-flip round"></span>
                            </label>
                            <span className="label-text checkboxLabel">
                                RegEx
                            </span>
                        </styled.scrollHighlight>
                    </div>
                    <div className="flex-items mr-2">

                    </div>
                </div>
            </div>
        </div >
    )
}

export default FindAndReplace
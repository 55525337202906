import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AddAssetTabs from './Tabs';
import * as translations from './translations';
import AddStoryModalBottom from './AddStoryModalBottom';
import './../style/style.css';
import * as Constant from './constant';
import ConfirmationWindow from './confirm-box';

function AddStoryModal(props) {
  const {
    asset,
    activeTabKey,
    progress,
    files,
    editStory,
    storyDetails,
    setArtTitleFiles,
    artTitleFiles,
    seriesBackgroundFiles,
    setSeriesBackgroundFiles,
    titleCoverFiles,
    setTitleCoverFile,
    titleCoverDimension,
    storyLists,
    finishPressed,
    storyStatus
  } = props;
  const [continueToAddInfoClass, setContinueToAddInfoClass] = useState(
    props.continuetoaddinfoclass
  );
  const [showClearFile, setShowClearFile] = useState(
    JSON.parse(props.showclearfile)
  );
  const [firstTabLabel, setFirstTabLabel] = useState('');
  const [secondTabLabel, setSecondTabLabel] = useState('');

  const [generes, setGeneres] = useState([]);
  const [validated, setValidated] = useState(false);

  const [enable, setEnableInfo] = useState(true);
  const [invalidTag, setInvalidTag] = useState(false);
  const [showConfirm, setshowConfirm] = useState(false);
  const [title, setTitle] = useState('');
  const [storyId, setStoryId] = useState(null);
  const [description, setDescription] = useState('');

  const [clearfile, setClearFile] = useState(true);
  const [seriesClearFile, setSeriesClearFile] = useState(true);
  const [artClearfile, setArtClearFile] = useState(true);
  const [enableContinueToStepTwo, setEnableContinueToStepTwo] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const [showTitleIamge, setShowTitleIamge] = useState(false);

  const [showAlbumIamge, setShowAlbumIamge] = useState(false);

  const [showSeriesIamge, setShowSeriesIamge] = useState(false);
  const [storyNameValidity, setStoryNameValidity] = useState(false);

  useEffect(() => {
    setGeneres(
      editStory && storyDetails.genres !== undefined ? storyDetails.genres : []
    );
    setEnableInfo(
      editStory &&
        (storyDetails.title !== '' || storyDetails.description !== '')
        ? false
        : true
    );

    setDescription(editStory ? storyDetails.description : '');
    setClearFile(editStory && storyDetails.titleCover != null ? true : false);
    setSeriesClearFile(
      editStory && storyDetails.seriesBackground != null ? true : false
    );
    setArtClearFile(editStory && storyDetails.artTitle != null ? true : false);

    if (editStory) {
      setTitle(storyDetails.title);
      setStoryId(storyDetails.id);
      if (files && files.length > 0) setClearFile(true);
      if (seriesBackgroundFiles && seriesBackgroundFiles.length > 0)
        setSeriesClearFile(true);
      if (titleCoverFiles && titleCoverFiles.length > 0) setClearFile(true);
      if (artTitleFiles && artTitleFiles.length > 0) setArtClearFile(true);
    }
    //setTitle(editStory ?storyDetails.title:'');

    props.setAsset({
      ...asset,
      [title]: storyDetails.title
      // storyType: storyDetails.storyType,
      // cost: storyDetails.cost
    });

    props.setAsset({
      ...asset,
      [description]: storyDetails.description
    });

    // props.setAsset({
    //   ...asset,
    //   genres: storyDetails.genres
    // });

    // props.setAsset({
    //   ...asset
    // });

    props.setAsset({
      ...asset,
      titleCover: storyDetails.titleCover
    });

    props.setAsset({
      ...asset,
      // storyType: storyDetails.storyType,
      //cost: storyDetails.cost,
      storyType: editStory ? storyDetails.storyType : 'RouteStory',
      cost: editStory ? storyDetails.cost : 0,
      seriesBackground: storyDetails.seriesBackground
    });
  }, [storyDetails]);

  useEffect(() => {
    if (props.files.length > 0) {
      setEnableContinueToStepTwo(true);
    } else {
      setEnableContinueToStepTwo(false);
    }
  }, [props.files]);

  const handleFormChange = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    props.setAsset({
      ...asset,
      [event.target.name]: event.target.value
    });
    if (event.target.name === 'title') {
      if (editStory && description === '') {
        setDescription(description);
      }

      setTitle(event.target.value);
    }

    if (event.target.name === 'description') {
      if (editStory && title === '') {
        setTitle(title);
      }
      setDescription(event.target.value);
    }

    if (event.target.value !== '') {
      if (editStory) {
        if (event.target.name === 'title') {
          let isTitleExist = false;
          //storyId
          setTitle(event.target.value);
          for (let i = 0; i < storyLists.length; i++) {
            if (storyLists[i].id !== storyId) {
              if (
                storyLists[i].title.toLowerCase() ===
                event.target.value.toLowerCase()
              ) {
                isTitleExist = true;

                break;
              }
            }
          }
          if (isTitleExist == true) {
            setStoryNameValidity(true);
            setEnableInfo(true);
          } else {
            setStoryNameValidity(false);
            setEnableInfo(false);
          }
          if (title !== '' && description !== '' && isTitleExist == false) {
            setEnableInfo(false);
          }
          //setEnableInfo(false);
        } else if (event.target.name === 'description') {
          let isTitleExist = false;
          for (let i = 0; i < storyLists.length; i++) {
            if (storyLists[i].id !== storyId) {
              if (storyLists[i].title.toLowerCase() === title.toLowerCase()) {
                isTitleExist = true;
                break;
              }
            }
          }
          setDescription(event.target.value);
          if (isTitleExist == true) {
            setStoryNameValidity(true);
            setEnableInfo(true);
          } else if (
            title !== '' &&
            description !== '' &&
            isTitleExist == false
          ) {
            setEnableInfo(false);
          }
        }
      } else if (asset.title !== '' && asset.description !== '') {
        let isExist = false;
        for (let i = 0; i < storyLists.length; i++) {
          if (storyLists[i].title.toLowerCase() === asset.title.toLowerCase()) {
            isExist = true;

            break;
          }
        }
        if (isExist == true) {
          setStoryNameValidity(true);
          setEnableInfo(true);
        } else {
          setStoryNameValidity(false);
          setEnableInfo(false);
        }
      }
    } else {
      setEnableInfo(true);
    }

    setValidated(true);
  };

  const handleSwitchChange = () => {};

  const handleResetFile = flag => {
    if (editStory && flag === false) props.setTitleCoverFile([]);
    if (flag === false) props.setTitleCoverFile([]);
    props.setAsset({
      ...asset,
      titleCover: null
    });

    setClearFile(flag);
  };

  const handleResetSeriesFile = flag => {
    if (editStory && flag === false) props.setSeriesBackgroundFiles([]);
    if (flag === false) props.setSeriesBackgroundFiles([]);
    props.setAsset({
      ...asset,
      seriesBackground: null
    });

    setSeriesClearFile(flag);
    setRefreshList(!refreshList);
  };

  const handleResetArtFile = flag => {
    if (editStory && flag === false) props.setArtTitleFiles([]);
    if (flag === false) props.setArtTitleFiles([]);
    props.setAsset({
      ...asset,
      artTitle: null
    });

    setArtClearFile(flag);
    setRefreshList(!refreshList);
  };

  const handleActiveKey = key => {
    props.setActiveTabKey(key);

    if (key === Constant.FIRST_TAB_KEY) {
      setFirstTabLabel('');
      setSecondTabLabel('');
    }
    if (key === Constant.SECOND_TAB_KEY) {
      setSecondTabLabel('');
      setFirstTabLabel('icon');
    }
    if (key === Constant.THIRD_TAB_KEY) {
      setFirstTabLabel('icon');
      setSecondTabLabel('icon');
    }
  };

  const handleClearFile = () => {
    props.setFiles([]);
    setShowClearFile(false);
    setContinueToAddInfoClass('');
    setFirstTabLabel('');
  };

  const handleGenreChange = event => {
    let genereName = event.target.value;
    let genereId = event.target.name;
    let isremoved = false;
    let obj = {};
    if (genereName !== undefined && genereId !== undefined) {
      obj = { id: genereId, name: genereName };
    } else {
      return;
    }
    if (generes.length > 0) {
      for (var i = 0; i < generes.length; ++i) {
        if (parseInt(generes[i].id) === parseInt(event.target.name)) {
          generes.splice(i, 1); // Remove even numbers
          isremoved = true;
        }
      }
      if (!isremoved) {
        generes.push(obj);
      }
    } else {
      generes.push(obj);
    }

    if (generes.length > 3) {
      generes.pop();
    }

    setGeneres(generes);
    props.setAsset({
      ...asset,
      genres: generes
    });
  };

  const handleTagChange = event => {
    let tags = event.target.value;
    let invalidTag = false;

    let tagList = tags
      .replace(/\s/g, '')
      .trim()
      .split(',');

    if (tagList[tagList.length - 1] === '') {
      tagList.pop();
    }
    const regExpTags = new RegExp(Constant.TAGS_PATTERN_MAX_5);

    if (event.keyCode !== 8 && event.keyCode !== 46) {
      event.target.value = tags.replace(/\s*,\s*/g, ', ');
    } else {
      event.target.value = tags.replace(/\s*,\s*/g, ',');
    }

    if (!regExpTags.test(tags.replace(/\s*,\s*/g, ', '))) {
      document.getElementById('tagError').innerHTML = 'Invalid Tags';
      document.getElementById('tagError').removeAttribute('style');
      document.getElementById('tagError').style.display = 'block';
      invalidTag = true;
      setInvalidTag(true);
    } else {
      document.getElementById('tagError').removeAttribute('style');
      document.getElementById('tagError').style.display = 'none';
      invalidTag = false;
      setInvalidTag(false);
    }

    if (tagList.length > 5) {
      document.getElementById('tagError').innerHTML =
        'A maximum 5 tags allowed';
      if (invalidTag === false) {
        document.getElementById('tagError').removeAttribute('style');
        document.getElementById('tagError').style.display = 'block';
      }
      tagList.pop();
    } else {
      if (invalidTag === false) {
        document.getElementById('tagError').removeAttribute('style');
        document.getElementById('tagError').style.display = 'none';
      }
    }
    //asset.tags=tagList;

    props.setAsset({
      ...asset,
      tags: tagList
    });
    // setTags(tags);
  };

  const handleSelectChange = event => {
    const storyType = event.target.value;
    if (storyType === 'RouteStory') {
      props.setAsset({
        ...asset,
        storyType: storyType,
        cost: 0
      });
    } else {
      props.setAsset({
        ...asset,
        storyType: storyType
      });
    }
  };

  const handleNumberChange = event => {
    const cost = event.target.value === '' ? 0 : parseInt(event.target.value);
    props.setAsset({
      ...asset,
      cost: cost
    });
  };

  const showConfirmModal = () => {
    setshowConfirm(true);
  };
  const hideConfirmModal = () => {
    setshowConfirm(false);
  };

  const highlightALbum = () => {
    setShowAlbumIamge(true);
  };
  const resetAlbumBorder = () => {
    setShowAlbumIamge(false);
  };

  const highlightSeries = () => {
    setShowSeriesIamge(true);
  };
  const resetSeriesBorder = () => {
    setShowSeriesIamge(false);
  };

  const highlighttitle = () => {
    setShowTitleIamge(true);

    setRefreshList(!refreshList);
  };
  const resetTitleBorder = () => {
    setShowTitleIamge(false);

    setRefreshList(!refreshList);
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={showConfirmModal}
      backdrop="static"
      dialogClassName="create-new-story"
    >
      {(activeTabKey === Constant.FIRST_TAB_KEY ||
        activeTabKey === Constant.SECOND_TAB_KEY ||
        activeTabKey === Constant.THIRD_TAB_KEY) && (
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
            {props.editStory
              ? translations.EDIT_STORY_INFO
              : translations.CREATE_NEW_STORY}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <AddAssetTabs
          activeTabKey={activeTabKey}
          handleActiveKey={handleActiveKey}
          handleSaveInfo={props.handleSaveInfo}
          handleValidStoryName={props.handleValidStoryName}
          storyNameValidity={storyNameValidity}
          validated={validated}
          enable={enable}
          setShowClearFile={data => setShowClearFile(data)}
          setContinueToAddInfoClass={data => setContinueToAddInfoClass(data)}
          files={files}
          genreList={props.genreList}
          setFiles={props.setFiles}
          handleFormChange={event => handleFormChange(event)}
          handleSwitchChange={event => handleSwitchChange(event)}
          handleTagChange={event => handleTagChange(event)}
          handleGenreChange={handleGenreChange}
          firstTabLabel={firstTabLabel}
          secondTabLabel={secondTabLabel}
          progress={progress}
          generes={generes}
          errors={props.errors}
          handleSuccesClick={props.handleSuccesClick}
          handleRollbackAsset={props.handleRollbackAsset}
          editStory={editStory}
          storyDetails={storyDetails}
          title={title}
          description={description}
          tags={asset.tags}
          handleResetFile={handleResetFile}
          clearfile={clearfile}
          setClearFile={setClearFile}
          titleCoverFiles={titleCoverFiles}
          setTitleCoverFile={setTitleCoverFile}
          seriesBackgroundFiles={seriesBackgroundFiles}
          setSeriesBackgroundFiles={setSeriesBackgroundFiles}
          setArtTitleFiles={setArtTitleFiles}
          artTitleFiles={artTitleFiles}
          artClearfile={artClearfile}
          setArtClearFile={setArtClearFile}
          handleResetSeriesFile={handleResetSeriesFile}
          handleResetArtFile={handleResetArtFile}
          seriesClearFile={seriesClearFile}
          setSeriesClearFile={setSeriesClearFile}
          setEnableContinueToStepTwo={setEnableContinueToStepTwo}
          setAsset={props.setAsset}
          asset={props.asset}
          invalidTag={invalidTag}
          highlightALbum={highlightALbum}
          resetAlbumBorder={resetAlbumBorder}
          highlightSeries={highlightSeries}
          resetSeriesBorder={resetSeriesBorder}
          highlighttitle={highlighttitle}
          resetTitleBorder={resetTitleBorder}
          refreshList={refreshList}
          showTitleIamge={showTitleIamge}
          showAlbumIamge={showAlbumIamge}
          showSeriesIamge={showSeriesIamge}
          storyStatus={storyStatus}
          handleSelectChange={handleSelectChange}
          handleNumberChange={handleNumberChange}
          cost={asset.cost}
          storyType={asset.storyType}
        />
        <AddStoryModalBottom
          activeTabKey={activeTabKey}
          enable={enable}
          generes={generes}
          continueToAddInfoClass={continueToAddInfoClass}
          handleActiveKey={handleActiveKey}
          showClearFile={showClearFile}
          handleClearFile={() => handleClearFile()}
          editStory={editStory}
          storyDetails={storyDetails}
          enableContinueToStepTwo={enableContinueToStepTwo}
          tags={asset.tags}
          storyCost={asset.cost}
          storyType={asset.storyType}
          invalidTag={invalidTag}
          finishPressed={finishPressed}
        />
        <ConfirmationWindow
          show={showConfirm}
          onHideClcik={props.onHideModal}
          onCancelClick={hideConfirmModal}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddStoryModal;

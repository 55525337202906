import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function RecaptchaComponent(props) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const token = executeRecaptcha();

    if (token) {
        props.setVerified(true);
        props.setShowCaptchaError(false);
    }
    return null;
}

export default RecaptchaComponent
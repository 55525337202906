import React, { useEffect, useState } from 'react';
import ListElements from './../../components/library/UIElements/list-elements';
import { connect } from 'react-redux';
import * as Actions from './../../actions/game-interface-action';
import * as LibraryActions from './../../actions/library-action';
import LibraryMenu from './../../components/library/LibraryMenu';
import AddUIElementModal from './../../components/library/AddUIElements/AddUIElementModal';
import * as Constant from './../../components/library/constant';
import { LibraryServices } from './../../components/library/service';
import ConfirmBox from './../../components/library/AddUIElements/Confirm';
import ResetSuccess from './../../components/library/AddUIElements/ResetSuccess';
import UiElementPreviewModal from './../../components/library/UIElements/UiElementPreviewModal';
import * as PlaceBackgroundActions from './../../actions/place-background-action';
import { Row, Col } from 'react-bootstrap';

function Interface(props) {
  const [files, setFiles] = useState([]);
  const [specs, setSpecs] = useState(null);
  const [id, setId] = useState(null);
  const [showClearFile, setShowClearFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUIElementPreview, setShowUIElementPreview] = useState(false);
  useEffect(() => {
    props.fetchUIElements(props.storyId, props.authToken);
    props.fetchGameFonts(props.authToken, props.storyId);
  }, []);

  const showModalBox = id => {
    props.showModal();
    props.setActiveTabKey(Constant.FIRST_TAB_KEY);
    setShowClearFile(false);
    setFiles([]);
    setId(id);
  };

  const handleClearFile = () => {
    setFiles([]);
    setShowClearFile(false);
  };

  const handleSaveImage = () => {
    let formData = LibraryServices.getElementFormData(
      files[0],
      id,
      specs,
      props.storyId
    );
    props.saveElement(formData, props.storyId, id, props.authToken);
    props.setActiveTabKey(Constant.THIRD_TAB_KEY);
    const intervalId = interval();
    setIntervalId(intervalId);
  };

  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 25);
    }, 800);
  };

  const resetElement = id => {
    setId(id);
    setShowConfirm(true);
  };

  const onProceedClick = () => {
    setShowConfirm(false);
    props.resetElement(props.storyId, id, props.authToken);
  };

  const handleSuccesClick = () => {
    props.hideResetSuccessModal();
    setShowConfirm(false);
  };

  const handleSuccesModalClick = () => {
    props.hideModal();
    setShowConfirm(false);
  };

  const hidePreviewModal = () => {
    setShowUIElementPreview(false);
  };

  const handleGameFontChange = event => {
    props.setFonts(props.authToken, props.storyId, {
      ...props.gameFonts,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
          <LibraryMenu
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            resetViewAutoSaveFlag={props.resetViewAutoSaveFlag}
            resetViews={props.resetViews}
            setPlaceBackgroundProperties={props.setPlaceBackgroundProperties}
          />
        </Col>
        <Col className="col-8">
          <ListElements
            listInterface={props.listInterface}
            loading={props.loading}
            storyTitle={props.storyTitle}
            showModal={showModalBox}
            resetElement={resetElement}
            setShowUIElementPreview={setShowUIElementPreview}
            gameFonts={props.gameFonts}
            setFonts={props.setFonts}
            handleGameFontChange={handleGameFontChange}
          />
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
      {
        <AddUIElementModal
          uploadModal={props.uploadModal}
          hideModal={props.hideModal}
          successModal={props.successModal}
          files={files}
          setFiles={data => setFiles(data)}
          activeTabKey={LibraryServices.getActiveTabKey(
            props.elementStatus,
            props.activeTab,
            props.hideModal,
            intervalId,
            'uiInterface'
          )}
          showClearFile={showClearFile}
          setShowClearFile={setShowClearFile}
          handleClearFile={handleClearFile}
          handleSaveImage={handleSaveImage}
          progress={progress}
          setSpecs={setSpecs}
          message={props.message}
          successModal={props.successModal}
        />
      }
      {showConfirm === true && (
        <ConfirmBox
          show={showConfirm}
          onCancelClick={() => setShowConfirm(false)}
          onProceedClick={onProceedClick}
        />
      )}
      {props.resetSuccess && (
        <ResetSuccess
          show={props.resetSuccess}
          handleSuccesClick={handleSuccesClick}
          textSuccess={' The element has been reset to its default image'}
        />
      )}
      {props.successModal && (
        <ResetSuccess
          show={props.successModal}
          handleSuccesClick={handleSuccesModalClick}
          textSuccess={'New image has been saved!'}
        />
      )}
      {showUIElementPreview && (
        <UiElementPreviewModal
          showModal={showUIElementPreview}
          hidePreviewModal={hidePreviewModal}
          interfaceList={props.listInterface}
          gameFonts={props.gameFonts}
          storyTitle={props.storyTitle}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    listInterface: state.GameInterfaceReducer.interfaceList,
    storyId: state.StoryReducer.storyId,
    authToken: state.LoginReducer.authToken,
    selectedMenu: state.LibraryReducer.selectedMenu,
    loading: state.GameInterfaceReducer.loading,
    storyTitle: state.StoryReducer.storyTitle,

    uploadModal: state.GameInterfaceReducer.uploadModal,

    activeTab: state.GameInterfaceReducer.activeTab,
    message: state.GameInterfaceReducer.message,
    elementStatus: state.GameInterfaceReducer.elementStatus,
    resetSuccess: state.GameInterfaceReducer.resetSuccess,
    gameFonts: state.GameInterfaceReducer.gameFonts,
    viewAutoSaveFlag: state.PlaceBackgroundReducer.autoSaveFlag,
    successModal: state.GameInterfaceReducer.successModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUIElements: (storyId, authToken) =>
      dispatch(Actions.getGameInterface(storyId, authToken)),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    showModal: () => dispatch(Actions.showModal()),
    hideModal: () => dispatch(Actions.hideModal()),
    saveElement: (formData, storyId, id, authToken) =>
      dispatch(Actions.saveElement(formData, storyId, id, authToken)),
    setActiveTabKey: activeTabKey =>
      dispatch(Actions.setActiveTabKey(activeTabKey)),
    resetElement: (storyId, id, authToken) =>
      dispatch(Actions.resetElement(storyId, id, authToken)),
    hideResetSuccessModal: () => dispatch(Actions.hideResetSuccessModal()),
    setFonts: (authToken, storyId, fonts) =>
      dispatch(Actions.setGameFonts(authToken, storyId, fonts)),
    fetchGameFonts: (authToken, storyId) =>
      dispatch(Actions.getGameFonts(authToken, storyId)),
    resetViews: () => dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    setAutoSaveFlag: flag =>
      dispatch(PlaceBackgroundActions.setAutoSaveFlag(flag)),
    resetViewAutoSaveFlag: () =>
      dispatch(PlaceBackgroundActions.resetAutoSaveFlag()),
    setPlaceBackgroundProperties: (key, value) =>
      dispatch(PlaceBackgroundActions.setBackgroundProperties(key, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Interface);

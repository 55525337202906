import React from 'react';
import { LibraryStyle } from './../style/style';
import { NavLink } from 'react-router-dom';
import * as Translations from './../translations';
import ListRow from './ListRow';
import DeleteCharacterModal from './delete-character/DeleteCharacterModal';
import DeleteConfirm from './delete-character/DeleteConfirm';
import Success from './delete-character/Success';

function ListContent({
  initializeCreateCharacter,
  characterList,
  previewStatus,
  handlePreviewClick,
  expressionList,
  outfitList,
  hairsList,
  accesoryList,
  listPreviewSprites,
  handleExpressionChange,
  handleOutfitChange,
  handleHairChange,
  handleAccesoryChange,
  tokenData,
  setSortCharacters,
  sortCharacters,
  sortCharacterList,
  listPreviewExpression,
  showModal,
  handleShowDeleteModal,
  handleAddCastModal,
  storyDetailsCheck,
  handleHideDeleteModal,
  handleDeleteCharacter,
  characterInfoList,
  deleteConfirm,
  handleEditClick,
  listPreviewOutfit,
  storyRoutes,
  characterIndex,
  characterId,
  handleEditCast
}) {
  const handleClick = () => {
    initializeCreateCharacter();
  };

  const handleSortingClick = () => {
    setSortCharacters(sortCharacters === 'asc' ? 'desc' : 'asc');
    sortCharacterList();
  };

  let storyDetails = -1;
  let sceneRoutePresent = false;

  if (characterId) {
    storyDetails = storyRoutes.findIndex(
      routes => routes.characterId === characterId
    );
    if (storyDetails >= 0) {
      sceneRoutePresent = true;
    }
  }

  return (
    <LibraryStyle.listPanel>
      <div className="scroll-div">
        <div className="container">
          <LibraryStyle.listHeader>
            <NavLink
              className="btn-snacktool-brown btn-snacktool"
              style={{ textDecoration: 'none' }}
              // onClick={handleClick}
              onClick={handleClick}
              // onClick={handleClick}
              to="/create-character-step-one"
            >
              {Translations.CREATE_NEW_CHARACTER}
              {/* <img
                alt=""
                src="/images/icons/ic_account_circle.svg"
                className="ml-1"
              />
              {/* <img src="../images/new_image/Create_New_Character_2.png"  */}
              {/* onClick={handleClick} */}
            </NavLink>
          </LibraryStyle.listHeader>

          <LibraryStyle.listContainer>
            <LibraryStyle.tableContainer>
              <thead>
                <tr>
                  {/* <th className="checkbox-box"></th> */}
                  <th className="text-left">{Translations.CHARACTER_NAME}</th>
                  <th>{Translations.ASSETS_TITLE}</th>
                  <th>{Translations.CREATOR_TITLE}</th>
                  <th>
                    {Translations.CREATED_ON}
                    <i
                      className={`fa ${
                        sortCharacters === 'asc'
                          ? 'fa-sort-asc'
                          : `fa-sort-desc`
                      }`}
                      aria-hidden="true"
                      onClick={handleSortingClick}
                    ></i>
                  </th>
                  <th>{Translations.ACTIONS}</th>
                  <th>{Translations.PREVIEW}</th>
                </tr>
              </thead>
              <tbody>
                {characterList !== undefined &&
                  characterList.length > 0 &&
                  characterList.map((character, index) => {
                    return (
                      <ListRow
                        character={character}
                        index={index}
                        previewStatus={previewStatus}
                        handlePreviewClick={handlePreviewClick}
                        expressionList={expressionList}
                        outfitList={outfitList}
                        hairsList={hairsList}
                        accesoryList={accesoryList}
                        listPreviewSprites={listPreviewSprites}
                        handleExpressionChange={handleExpressionChange}
                        handleOutfitChange={handleOutfitChange}
                        handleHairChange={handleHairChange}
                        handleAccesoryChange={handleAccesoryChange}
                        tokenData={tokenData}
                        listPreviewExpression={listPreviewExpression}
                        showModal={showModal}
                        handleShowDeleteModal={handleShowDeleteModal}
                        castValue={false}
                        handleAddCastModal={handleAddCastModal}
                        handleHideDeleteModal={handleHideDeleteModal}
                        handleDeleteCharacter={handleDeleteCharacter}
                        handleEditClick={handleEditClick}
                        listPreviewOutfit={listPreviewOutfit}
                        characterIndex={characterIndex}
                        handleEditCast={handleEditCast}
                        storyDetails={storyDetailsCheck}
                      />
                    );
                  })}
              </tbody>

              {characterList !== undefined && characterList.length === 0 && (
                <tr>
                  <td colSpan="7" className="text-center">
                    No characters has been added yet!
                  </td>
                </tr>
              )}
            </LibraryStyle.tableContainer>
          </LibraryStyle.listContainer>

          {showModal &&
            ((characterInfoList && characterInfoList.length > 0) ||
              sceneRoutePresent === true) && (
              <DeleteCharacterModal
                showModal={showModal}
                handleHideDeleteModal={handleHideDeleteModal}
                characterInfoList={characterInfoList}
                sceneRoutePresent={sceneRoutePresent}
                storyRoutes={storyRoutes}
                storyDetails={storyDetails}
                characterInfoList={characterInfoList}
              />
            )}
          {showModal &&
            (characterInfoList && characterInfoList.length === 0) &&
            sceneRoutePresent === false && (
              <DeleteConfirm
                showModal={showModal}
                handleHideDeleteModal={handleHideDeleteModal}
                handleDeleteCharacter={handleDeleteCharacter}
                characterId={characterId}
              />
            )}
          {showModal === true && deleteConfirm === true && (
            <Success
              showModal={showModal}
              handleHideDeleteModal={handleHideDeleteModal}
            />
          )}
        </div>
      </div>
    </LibraryStyle.listPanel>
  );
}

export default ListContent;

import React, { useState, useEffect, useRef } from 'react';
import Cropper from 'react-cropper';
import { StoryService } from './../service';
import DraggableObject from './DraggableObject';
import { useDrop } from 'react-dnd';
import * as Config from './../../../config/aspectRatio.config';
import { ImageMapper } from './../../../common/helper/image-mapper';
import { Spinner } from 'react-bootstrap';
import './style.css';
import { PlaceBackgroundService } from './../place-background-service';

function renderObject(
  object,
  asset,
  index,
  viewWidth,
  viewHeight,
  libraryChange
) {
  return (
    <DraggableObject
      index={index}
      asset={asset}
      object={object}
      id={'objectImage-' + index}
      key={'objectImage-' + index}
      left={object.xcoordinate}
      top={object.ycoordinate}
      viewWidth={viewWidth}
      viewHeight={viewHeight}
      libraryChange={libraryChange}
    />
  );
}

function CreateViewLeftContainer(props) {
  // const [showLoader, setShowLoader] = useState(false);
  const cropperRef = useRef(null);

  useEffect(() => {
    if (props.backgroundImage !== null) {
      props.setShowLoader(true);
    }
  }, [props.backgroundImage]);

  const [{ isDragging }, drop] = useDrop({
    accept: 'box',
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      let objects = props.viewObjects.slice().reverse();

      if (props.selectBackground === false) {
        let dragObjectParameters = PlaceBackgroundService.prepareObjectDragDropCoordinates(
          objects,
          item,
          props.libraryChange,
          delta
        );
        if (dragObjectParameters.notColliding === false) {
          if (props.libraryChange) {
            // Prepare co-ordinates for small view port
            let newCoordinates = ImageMapper.objectCoordinate({
              xcoordinate: dragObjectParameters.left,
              ycoordinate: dragObjectParameters.top
            });
            dragObjectParameters.left = parseInt(newCoordinates[0]);
            dragObjectParameters.top = parseInt(newCoordinates[1]);
          }
          props.changePosition(
            item,
            dragObjectParameters.left,
            dragObjectParameters.top
          );
          if (props.setAutoSaveFlag) {
            props.setAutoSaveFlag(true);
          }
        }
      }

      return undefined;
    }
  });

  const handleLoad = () => {
    PlaceBackgroundService.addInlineElement();
    if (cropperRef.current.props.isEditView === true) {
      props.setEditView(false);
    }
    props.setShowLoader(false);
  };

  let defaultDropData = {
    x: props.xcoordinate,
    y: props.ycoordinate,
    height: props.height,
    width: props.width
  };

  if (
    props.selectedBackground !== undefined &&
    (defaultDropData.width === 0 || defaultDropData.height === 0)
  ) {
    let specs = ImageMapper.getOriginalSpecsOfImage(
      props.selectedBackground.specs
    );
    let newX = 0;
    if (specs[0] > specs[1]) {
      newX = (specs[0] - props.width) / 4;
    }
    defaultDropData = {
      x: newX,
      y: 0,
      width: specs[0],
      height: specs[1]
    };
  }

  return (
    <div
      className="left-content mr-5"
      id="left-content"
      key="objectImagesList"
      ref={drop}
    >
      {props.showLoader && (
        <Spinner
          variant="primary"
          animation="border"
          className="spinner-class"
        />
      )}
      {props.selectBackground === true && props.backgroundImage !== null && (
        <Cropper
          ref={cropperRef}
          src={props.backgroundImage}
          style={{
            height: props.libraryChange === true ? '693px' : '430px',
            backgroundColor: '#F5F5FA'
          }}
          // Cropper.js options
          aspectRatio={parseFloat(Config.aspectRatioCalcThreeByFour)}
          guides={true}
          crop={newCrop => {
            if (cropperRef.current.props.isEditView === false) {
              props.setCrop(newCrop);
            }
          }}
          data={defaultDropData}
          isEditView={props.editView}
          center={true}
          autoCrop={true}
          zoomable={false}
          ready={handleLoad}
          background={true}
          autoCropArea={1}
          viewMode={1}
          className="cropper-section"
          minCropBoxWidth={27}
          minCropBoxHeight={36}
        />
      )}
      {props.viewObjects.length > 0 &&
        props.selectBackground === false &&
        props.viewObjects
          .slice()
          .reverse()
          .map((object, index) => {
            let asset = StoryService.getImagePath(
              props.assetList,
              object.asset.id
            );
            return renderObject(
              object,
              asset,
              index,
              props.width,
              props.height,
              props.libraryChange
            );
          })}
    </div>
  );
}

export default CreateViewLeftContainer;

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CreateDropdownOptions from './dropdown-options';
import { characterHelper } from '../../../common/helper/character-helper';
import * as Constant from './../../../common/constants';
import ToggleCheckBox from './toggle-checkbox';

function DropDownWidget({
  component,
  index,
  dependentOptions,
  setSelectedChar,
  flowchartNode,
  parametersEnterTheScene,
  flowchartData,
  commandId,
  handleSetDuration,
  enableDisableCheckBox,
  flowchartAllScenes,
  characterList,
  SelectedValue,
  isCommandEdit,
  setFilterCharacter,
  filterCharacter,
  setCurrentValueForShakeCamera,
  setHandleSetDisabledXandY,
  setNumberBoxDefaultValue,
  setNumberBoxDisabled,
  changeValueWidget,
  selectedChar,
  variableFields,
  variableListDTO,
  addGenderedText,
  gotoChapterRequired,
  dropDownCharChangedEdit,
  setDropDownCharChangedEdit,
  fullNameToggle
}) {
  const [changeVariableType, setChangeVariableType] = useState(false);
  const [dropDownChanged, setdropDownChanged] = useState(true);
  const [dropDownChangedChar, setDropDownChangedChar] = useState(true);
  const [checkboxisChange, setCheckboxisChange] = useState(
    isCommandEdit ? 'change' : ''
  );
  const [dropDownChangedLeave, setDropDownChangedLeave] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  let changedTrue = false;

  let defaultValue = component.properties.defaultValue;
  let defaultDisabled = null;
  let required = component.required ? `required` : '';

  useEffect(() => {
    if (
      component.widgetName === 'characterDropdown' ||
      component.widgetName === 'newCharacterDropdown' ||
      component.widgetName === 'originalCharacterDropdown' ||
      component.widgetName === 'CharacterDropdown'
    ) {
      setCheckbox(component.showFullNames);
    }
  }, []);

  switch (component.widgetName) {
    case 'transitionTypeDropdown':
      if (isCommandEdit) {
        if (
          SelectedValue !== 'No-transition' &&
          SelectedValue !== '' &&
          dropDownChangedLeave === false
        ) {
          handleSetDuration(false);
          setDropDownChangedLeave(true);
        }
      }
      if (
        commandId === Constant.COMMAND_LEAVES_THE_SCENE &&
        SelectedValue === Constant.TRANSITION_TYPE_FADE_OUT &&
        dropDownChangedLeave === false
      ) {
        handleSetDuration(false);
        setDropDownChangedLeave(true);
      }
      break;

    case 'appearDropdown':
      if (isCommandEdit) {
        if (SelectedValue === 'None' && dropDownChangedLeave === false) {
          setNumberBoxDisabled('disabled');
          setDropDownChangedLeave(true);
        }
      }
      if (SelectedValue === 'None' && dropDownChangedLeave === false) {
        setNumberBoxDisabled('disabled');
        setDropDownChangedLeave(true);
      }

      // if (commandId === Constant.COMMAND_LEAVES_THE_SCENE
      //     && SelectedValue === Constant.TRANSITION_TYPE_FADE_OUT && dropDownChangedLeave === false) {
      //     handleSetDuration(false)
      //     setDropDownChangedLeave(true)

      // }
      break;
    case 'affinityWeightDropdown':
      defaultDisabled = 'disabled';
      required = '';
      if (isCommandEdit) {
        if (defaultValue !== '') {
          defaultDisabled = '';
        } else if (selectedChar) {
          defaultDisabled = '';
          required = 'required';
        }
        if (selectedChar === '') {
          defaultDisabled = 'disabled';
          required = '';
        }
      } else if (selectedChar) {
        defaultDisabled = '';
        required = 'required';
      }
      break;

    case 'variableTypeDropdown':
      if (
        commandId === Constant.COMMAND_SET_VAR &&
        changeVariableType === false
      ) {
        changeValueWidget(component.properties.defaultValue);
      }
      if (isCommandEdit) {
        defaultDisabled = 'disabled';
      } else {
        defaultDisabled = '';
      }
      break;

    case 'operatorDropdown':
      defaultValue = component.properties.defaultValue;

      if (commandId === Constant.COMMAND_SET_VAR) {
        if (isCommandEdit) {
          defaultDisabled = 'disabled';
        } else {
          defaultDisabled = '';
        }
      }
      break;

    case 'timeDropDown':
      if (enableDisableCheckBox === false) {
        defaultDisabled = true;
        required = '';
      }
      break;

    case 'variableDropdown':
      switch (commandId) {
        case Constant.COMMAND_ADD_IF:
        case Constant.COMMAND_ADD_ELSE_IF:
          if (isCommandEdit && variableFields === false) {
            changeValueWidget(component.properties.defaultValue);
          }
          break;
        default:
          break;
      }
      break;

    case 'variableDropdownSelect':
      if (addGenderedText == 'false' || addGenderedText == false) {
        return null;
      }
      break;

    case 'characterDropdown':
      const defaultFilterCharacter = characterList.filter(
        character => parseInt(defaultValue) === parseInt(character.id)
      );
      if (defaultFilterCharacter.length > 0 && dropDownChangedChar !== false) {
        setFilterCharacter(defaultFilterCharacter[0]);
      }
      break;

    case 'originalCharacterDropdown':
      if (isCommandEdit && dropDownChangedChar) {
        setSelectedChar(defaultValue);
      }

      break;

    case 'newCharacterDropdown':
      const defaultFilterCharacterNew = characterList.filter(
        character => parseInt(defaultValue) === parseInt(character.id)
      );
      if (defaultFilterCharacterNew.length > 0 && dropDownChanged !== false) {
        setFilterCharacter(defaultFilterCharacterNew[0]);
      }

      break;

    case 'chapterNameDropdown':
      required = 'required';
      if (
        component.properties.values &&
        component.properties.values.length > 0
      ) {
        defaultDisabled = '';
      }
      if (gotoChapterRequired == 'true') {
        return null;
      }
      break;

    case 'expressionDropdown':
      if (filterCharacter) {
        const filterExpression = filterCharacter.expressions.findIndex(
          expressions => expressions.defaultAsset === true
        );
        if (
          isCommandEdit &&
          component.properties.defaultValue === defaultValue &&
          dropDownCharChangedEdit === false
        ) {
          defaultValue = component.properties.defaultValue;
        } else {
          defaultValue = filterCharacter.expressions[filterExpression].id;
        }
      } else if (
        commandId === Constant.COMMAND_SET_OUTFIT ||
        commandId === Constant.COMMAND_SHOW_OUTFIT ||
        commandId === Constant.COMMAND_REPLACE_OUTFIT
      ) {
        defaultDisabled = 'disabled';
      }
      break;

    case 'outFitDropdown':
      if (filterCharacter) {
        const filterOutfit = filterCharacter.outfits.findIndex(
          expressions => expressions.defaultAsset === true
        );
        if (
          isCommandEdit &&
          component.properties.defaultValue === defaultValue &&
          dropDownCharChangedEdit === false
        ) {
          defaultValue = component.properties.defaultValue;
        } else {
          defaultValue = filterCharacter.outfits[filterOutfit].id;
        }
      } else if (commandId === Constant.COMMAND_SET_OUTFIT) {
        defaultDisabled = 'disabled';
      }
      break;

    case 'hairDropdown':
      if (filterCharacter) {
        const filterHair = filterCharacter.hairs.findIndex(
          expressions => expressions.defaultAsset === true
        );
        if (
          isCommandEdit &&
          component.properties.defaultValue === defaultValue &&
          dropDownCharChangedEdit === false
        ) {
          defaultValue = component.properties.defaultValue;
        } else {
          defaultValue = filterCharacter.hairs[filterHair].id;
        }
      } else if (commandId === Constant.COMMAND_SET_OUTFIT) {
        defaultDisabled = 'disabled';
      }
      break;

    case 'accessoryDropdown':
      if (filterCharacter) {
        const filterAcc = filterCharacter.accessories.findIndex(
          expressions => expressions.defaultAsset === true
        );
        if (
          isCommandEdit &&
          component.properties.defaultValue === defaultValue &&
          dropDownCharChangedEdit === false
        ) {
          defaultValue = component.properties.defaultValue;
        } else {
          defaultValue = filterCharacter.accessories[filterAcc].id;
        }
      } else if (commandId === Constant.COMMAND_SET_OUTFIT) {
        defaultDisabled = 'disabled';
      }
      break;

    default:
      defaultDisabled = null;
      if (commandId === Constant.COMMAND_SHAKE_CAMERA) {
        setSelectedChar(defaultValue);
      }
      break;
  }

  const onChangeDropdown = (evt, commandId) => {
    const eventValue = evt.target.value;
    const eventTargetName = evt.target.name;
    switch (commandId) {
      case Constant.COMMAND_LEAVES_THE_SCENE:
      case Constant.COMMAND_HIDDEN_CHARACTER:
        if (eventTargetName === 'transitionTypeDropdown') {
          if (eventValue === 'No-transition') {
            handleSetDuration(true);
          } else {
            handleSetDuration(false);
          }
        }
        break;

      case Constant.COMMAND_SHAKE_CAMERA:
        setHandleSetDisabledXandY(true);
        setCurrentValueForShakeCamera(eventValue);
        switch (eventValue) {
          case 'Low':
            setNumberBoxDefaultValue(0.5);
            setNumberBoxDisabled('disabled');
            break;

          case 'Medium':
            setNumberBoxDefaultValue(1);
            setNumberBoxDisabled('disabled');
            break;

          case 'High':
            setNumberBoxDefaultValue(1.75);
            setNumberBoxDisabled('disabled');
            break;

          case 'Custom':
            setHandleSetDisabledXandY(false);
            setNumberBoxDefaultValue('');
            setNumberBoxDisabled('');
            break;

          default:
            setNumberBoxDisabled('disabled');
            break;
        }
        break;

      default:
        switch (eventTargetName) {
          case 'characterDropdown':
          case 'originalCharacterDropdown':
            setSelectedChar(eventValue);
            setDropDownChangedChar(false);
            if (
              commandId !== Constant.COMMAND_REPLACE_CHARACTER &&
              commandId !== Constant.COMMAND_REPLACE_OUTFIT
            ) {
              setFilterCharacter(
                characterHelper.fetchCharacterByIdAllData(
                  characterList,
                  eventValue
                )
              );
              setDropDownCharChangedEdit(true);
            }
            break;

          case 'newCharacterDropdown':
            setFilterCharacter(
              characterHelper.fetchCharacterByIdAllData(
                characterList,
                eventValue
              )
            );
            setdropDownChanged(false);
            setDropDownCharChangedEdit(true);
            break;

          case 'affinityTypeDropdown':
            setSelectedChar(eventValue);
            break;

          case 'appearDropdown':
            if (eventValue === 'None') {
              setNumberBoxDisabled('disabled');
            } else {
              setNumberBoxDisabled('');
            }
            break;

          case 'variableTypeDropdown':
          case 'variableDropdown':
            changeValueWidget(eventValue);
            setChangeVariableType(true);
            break;

          default:
            if (
              commandId === Constant.COMMAND_SHOW_OUTFIT &&
              setSelectedChar === null
            ) {
              defaultDisabled = 'disabled';
            }
            break;
        }
        break;
    }
  };

  const handleChange = event => {
    setCheckboxisChange('change');
    if (checkboxisChange == 'change') {
      setCheckbox(!checkbox);
    } else {
      setCheckbox(event.target.value == 'false' ? true : false);
    }
  };

  return (
    <Form.Group className="custom-select-dropdown" controlId={component.order}>
      <Form.Label className="commandLabel">{component.label}</Form.Label>
      {component.note !== '' && (
        <label className="commandNote">{component.note}</label>
      )}
      {component.suffix !== null && component.suffix !== '' && (
        <Form.Label className="commandSubLabel">{component.suffix}</Form.Label>
      )}
      {(component.widgetName === 'characterDropdown' ||
        component.widgetName === 'originalCharacterDropdown' ||
        component.widgetName === 'newCharacterDropdown' ||
        component.widgetName === 'CharacterDropdown') && (
        <ToggleCheckBox
          component={component}
          variableFields=""
          commandId={commandId}
          checkbox={checkbox}
          handleChange={handleChange}
          fullNameToggle={fullNameToggle}
          isCommandEdit={isCommandEdit}
          checkboxisChange={checkboxisChange}
        />
      )}
      <Form.Control
        name={component.widgetName}
        key={component.widgetName}
        required={required}
        as="select"
        onChange={evt => onChangeDropdown(evt, commandId)}
        label={component.label}
        index={component.index}
        placeholder={component.placeholder}
        defaultValue={defaultValue !== '' ? defaultValue : ''}
        disabled={defaultDisabled === 'disabled' ? defaultDisabled : null}
      >
        <CreateDropdownOptions
          component={component}
          key={index}
          dependentOptions={dependentOptions}
          label={component.label}
          flowchartNode={flowchartNode}
          parametersEnterTheScene={parametersEnterTheScene}
          flowchartData={flowchartData}
          commandId={commandId}
          currentSelected={selectedChar}
          flowchartAllScenes={flowchartAllScenes}
          characterList={characterList}
          filterCharacter={filterCharacter}
          defaultValue={defaultValue}
          variableFields={variableFields}
          variableListDTO={variableListDTO}
          checkbox={checkbox}
          checkboxisChange={checkboxisChange}
          fullNameToggle={fullNameToggle}
        />
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        Field is required
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default DropDownWidget;

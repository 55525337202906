import React from 'react';
import * as StyleBlankPage from './style-blank-page';

const StoryBlankPage = (props) => {
    return(
        <StyleBlankPage.StoryBlankContainer>
            <StyleBlankPage.StoryCreateArrow>
            </StyleBlankPage.StoryCreateArrow>
                {/* <StyleBlankPage.StartLabel>Start creating your first Story</StyleBlankPage.StartLabel> */}
        </StyleBlankPage.StoryBlankContainer>
    )
}


export default StoryBlankPage;
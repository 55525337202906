import React from 'react';
import { LibraryStyle } from './../style/style';
import ListAssetRow from './ListAssetRow';
import ItemListLoader from './../../common/list-loader';
import './../style/style.css';
import * as Translations from './../translations';

function ListTable({ listInterface, loading, showModal, resetElement }) {
  return (
    <LibraryStyle.tableContainer>
      <thead className="bg-light">
        <tr className="table-head-row">
          <th className="text-left pl-3 w-25">ELEMENT</th>
          <th className="text-left pl-3 w-10">SOURCE</th>
          <th className="w-10">FORMAT</th>
          <th className="w-10">IMAGE SIZE</th>
          <th className="w-10">{Translations.ACTIONS.toUpperCase()}</th>
        </tr>
      </thead>
      <tbody className="table-body">
        {listInterface.map((gameInterface, index) => {
          return (
            <ListAssetRow
              key={index}
              gameInterface={gameInterface}
              showModal={showModal}
              listLength={listInterface.length}
              resetElement={resetElement}
            />
          );
        })}
        {loading === true && (
          <tr>
            <td colSpan="7" className="text-center">
              <ItemListLoader />
            </td>
          </tr>
        )}
        {listInterface.length === 0 && loading === false && (
          <tr>
            <td colSpan="7" className="text-center">
              No elements has been added yet!
            </td>
          </tr>
        )}
      </tbody>
    </LibraryStyle.tableContainer>
  );
}

export default ListTable;

import React from 'react';
import { Table } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
import ListAssetRow from './ListAssetRow';
import ItemListLoader from './../../../common/list-loader';
import './../../style/style.css';
import * as Translations from './../../translations';

function ListTable({
  assetList,
  files,
  loading,
  columns,
  handleAssetSelection,
  handleDeleteKey,
  handleEditKey,
  outfitCount,
  hairCount,
  expressionCount,
  accessoryCount
}) {
  return (
    <LibraryStyle.listTable>
      <Table responsive="md">
        <thead className="bg-light">
          <tr className="table-head-row">
            {columns.indexOf('image') > -1 && (
              <th className="text-left">
                {Translations.ASSET_IMAGE.toUpperCase()}
              </th>
            )}

            {columns.indexOf('type') > -1 && (
              <th className="text-left">
                {Translations.ASSET_CHAR_TYPE.toUpperCase()}
              </th>
            )}
            {columns.indexOf('default') > -1 && (
              <th>{Translations.ASSET_DEFAULT.toUpperCase()}</th>
            )}

            {columns.indexOf('actions') > -1 && (
              <th>{Translations.ACTIONS.toUpperCase()}</th>
            )}
          </tr>
        </thead>
        <tbody className="table-body">
          {assetList.length > 0 &&
            assetList.map((asset, index) => {
              if (asset.awsPath === null) {
                return;
              } else {
                return (
                  <ListAssetRow
                    columns={columns}
                    key={index}
                    index={index}
                    asset={asset}
                    handleDeleteKey={handleDeleteKey}
                    handleEditKey={handleEditKey}
                    handleAssetSelection={handleAssetSelection}
                    outfitCount={outfitCount}
                    hairCount={hairCount}
                    expressionCount={expressionCount}
                    accessoryCount={accessoryCount}
                  />
                );
              }
            })}
          {loading === true && (
            <tr>
              <td colSpan="7" className="text-center">
                <ItemListLoader />
              </td>
            </tr>
          )}
          {assetList.length === 0 && loading === false && (
            <tr>
              <td colSpan="7" className="text-center">
                No images uploaded yet
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </LibraryStyle.listTable>
  );
}

export default ListTable;

import React from 'react';
import * as VariableWindowStyle from './style-variable/variable-style';
import ErrorList from './error-list';
// import * as Constant from './Constant';
// import { Steps } from 'intro.js-react';
// import { Overlay, Tooltip, Col } from 'react-bootstrap';
// import 'intro.js/introjs.css';

const ErrorWindow = props => {

  return (
    <VariableWindowStyle.Body>
      <div className="variable-window-wrapper">
        {props.showScriptErrorWindow === true && (
          <>
            <div className="help-div"></div>
            <table className="table mb-0 mt-2 tableheigth">
              <thead className="thead-dark">
                <tr>
                  <th className="width10" style={{ width: '8%' }}>
                    Line no
                  </th>
                  <th scope="">Description</th>
                  <th className="width15" style={{ width: '10%' }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.errorList &&
                  props.errorList.length > 0 &&
                  props.errorList.map((errorInfo, index) => {
                    return (
                      <ErrorList
                        key={index}
                        errorInfo={errorInfo}
                        activeTabSelected={props.activeTabSelected}
                        handleClickGotoLine={props.handleClickGotoLine}
                      />
                    );
                  })}
                <tr>
                  <td colSpan={3} className="stesp-var-vlaue"></td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </VariableWindowStyle.Body>
  );
};

export default ErrorWindow;

// import { prop } from 'lodash/fp';
import React from 'react';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
// import { BACKGROUND_IMAGE } from '../../../common/constants';
// import { PlaceBackgroundService } from './../place-background-service';
// import Sh÷owList from './show-list'

function ShowList(props) {
  return (
    <OverlayTrigger
      key={`otherView`}
      placement="top"
      overlay={<Tooltip id={`otherView`}>{props.name}</Tooltip>}
    >
      <div
        id={props.name}
        className="view-show"
        style={{
          height: `${(props.width * 4) / 3}px`,
          width: `${props.width}px`,
          top: `${props.top}px`,
          left: `${props.left}px`
        }}
      ></div>
    </OverlayTrigger>
  );
}

export default ShowList;

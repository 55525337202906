import React from 'react';
import { LibraryStyle } from './../../style/style';
import { Row, Col } from 'react-bootstrap';
import * as translations from './../../translations';

function Success(props) {

    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <Row>
                    <Col className="success-icon mb-3">
                        <img alt="" className="mediumIcon" src="/images/icons/ic_done_24px.svg" />
                    </Col>
                </Row>
                <Row><Col className="submit-asset-label-text-center">{props.isEdit === true ? translations.NEW_ASSET_UPDATE : translations.NEW_ASSET_SAVE}</Col></Row>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Success
import React, { useState, useRef, useEffect } from 'react';
import { Button, ListGroup, Card, Modal, Spinner } from 'react-bootstrap';
import GrammarSuggestion from './grammar-suggestion';
import GrammarNoSuggestion from './grammar-no-suggestion';

import { colors } from '../../../common/styles/variables';
import * as styled from '../../player/style';

const GrammarModal = props => {
  let arraySuggest = [];
  const currentRow = props.ace.current.editor.getSelectionRange();

  let transform = 0;
  if (document.getElementsByClassName('ace_cursor')) {
    transform = document.querySelector('.ace_cursor');
    transform = window.getComputedStyle(transform).transform;
  }
  let display = 'none';
  let textSpell = 'Grammar mistake';
  let color = 'blue';
  if (props.suggestionList && props.suggestionList.length > 0) {
    arraySuggest = props.suggestionList.filter(
      grammar =>
        grammar.lineNumber === props.lineNumber + 1 &&
        grammar.fromPos <= currentRow.start.column &&
        grammar.toPos >= currentRow.end.column
    );
    if (arraySuggest[0]) {
      display = 'block';
      if (arraySuggest[0].misspelled === true) {
        textSpell = 'Spelling Mistake';
        color = 'red';
      }
    }
  }

  return (
    <>
      {props.loadGrammar === true ? (
        <Modal.Dialog
          style={{
            position: 'absolute',
            transform: `${transform}`,
            margin: '10px !important'
          }}
          className="mt-2"
        >
          <Modal.Header style={{ padding: '0px' }}></Modal.Header>
          <Modal.Body
            style={{ overflow: 'scroll', maxHeight: '200px', padding: '0px' }}
          >
            <Button
              variant="primary"
              className="btn btn-snacktool-golden"
              disabled
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Applying...
            </Button>
          </Modal.Body>
        </Modal.Dialog>
      ) : arraySuggest[0] &&
        arraySuggest[0].suggestions &&
        arraySuggest[0].suggestions.length > 0 ? (
        <GrammarSuggestion
          transform={transform}
          display={display}
          arraySuggest={arraySuggest}
          color={color}
          textSpell={textSpell}
          onClickReplace={props.onClickReplace}
        />
      ) : (
        <GrammarNoSuggestion
          transform={transform}
          display={display}
          arraySuggest={arraySuggest}
          color={color}
          textSpell={textSpell}
          onClickReplace={props.onClickReplace}

        />
      )}
    </>
  );
};

export default GrammarModal;

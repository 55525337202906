import React, { useState, useRef, useEffect } from 'react';

import { Button, ListGroup, Card, Modal, Spinner } from 'react-bootstrap';

const GrammarSuggestion = ({
  transform,
  display,
  arraySuggest,
  color,
  textSpell,
  onClickReplace
}) => {
  return (
    <Modal.Dialog
      style={{
        position: 'absolute',
        transform: `${transform}`,
        display: `${display}`
      }}
      className="mt-2"
    >
      <Modal.Header style={{ padding: '0px' }}></Modal.Header>
      <Modal.Body
        style={{ overflow: 'scroll', maxHeight: '200px', padding: '0px' }}
      >
        <Card>
          <Card.Body style={{ padding: '0.5rem', maxWidth: '210px' }}>
            <Card.Title style={{ fontSize: '12px', fontWeight: 'bold' }}>
              <i className="fa fa-circle" style={{ color: color }} />{' '}
              {textSpell}
            </Card.Title>

            <Card.Text style={{ fontSize: '12px' }}>
              {arraySuggest[0].message}
            </Card.Text>
            <div
              style={{
                borderBottom: '1px solid #ACACAC',
                paddingBottom: '20px'
              }}
            >
              {arraySuggest &&
                arraySuggest[0] &&
                arraySuggest[0].suggestions.length > 0 &&
                arraySuggest[0].suggestions.map(suggest => {
                  return (
                    <Button
                      onClick={() => onClickReplace(suggest, arraySuggest[0])}
                      variant="primary"
                      className="mr-2 mt-1"
                      style={{ fontSize: '12px' }}
                      key={suggest}
                    >
                      {suggest}
                    </Button>
                  );
                })}
            </div>
            <Card.Text
             className="ignoreGrammar"
              onClick={() => onClickReplace(arraySuggest[0].word, arraySuggest[0], true)}
            >
              <i className="fa fa-list" /> Ignore in this text
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal.Dialog>
  );
};

export default GrammarSuggestion;

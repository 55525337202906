import React, { useEffect, useState, useRef } from 'react';
import * as VariableWindowStyle from './style-variable/variable-style';
import VariableList from './variable-list';
import * as Constant from './Constant';
import { Steps } from 'intro.js-react';
import { Overlay, Tooltip, Col } from 'react-bootstrap';
import 'intro.js/introjs.css';

const VariableWindow = props => {
  const [stepsVarenabled, setStepsVarEnabled] = useState(false);

  const [initialVarStep, setInitialVarStep] = useState(0);
  const [stepsVar, setStepsVar] = useState(Constant.stepVarConstant);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const clickOutside = useRef(null);

  useEffect(() => {
    if (
      props.tutorials.firstVarWindow === false &&
      props.showHideVariableState === true &&
      props.tutorials.firstTextEditor === true
    ) {
      setStepsVarEnabled(false);
      setStepsVarEnabled(
        props.tutorials.firstVarWindow === false ? true : false
      );
    }
  }, [props.showHideVariableState, props.tutorials]);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('click', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside);
      };
    });
  }
  useOutsideAlerter(clickOutside);

  function onExit() {
    setStepsVarEnabled(false);
    if (props.showHideVariableState === true) {
      props.onVarTutorialExit();
    }
  }

  return (
    <VariableWindowStyle.Body>
      <div
        className="variable-window-wrapper step-var"
        style={{
          background: `${
            props.showHideVariableState == true ? '#F0F0F0' : '#3A2C61'
          }`
        }}
      >
        <Steps
          enabled={stepsVarenabled}
          steps={stepsVar}
          initialStep={initialVarStep}
          onExit={onExit}
          options={Constant.INTRO_OPTIONS}
        />
        {props.showHideVariableState === true && (
          <>
            <div className="help-div">
              <img src="/images/icons/ic_help_24px.svg" className="checkIcon" />
              <span
                ref={target}
                onClick={() => setShow(!show)}
                className="help-icon-variable ml-1"
              >
                Help: How to use variables
              </span>
              <Overlay
                key={`tooltip-avtar-help`}
                placement="bottom"
                show={show}
                target={target.current}
              >
                {props => (
                  <Tooltip
                    id={`tooltip-avtar-help`}
                    {...props}
                    className="tooltip-320 tooltip-top-change"
                  >
                    <div
                      ref={clickOutside}
                      dangerouslySetInnerHTML={{
                        __html: `
                                    <b>STRING</b> variables store text values. For example, the player character’s name is represented by the string variable nombre.<br/><br/>
                                    <b>BOOLEAN</b> variables store true/false. For example, the boolean variable upright can be toggled true or false.<br/><br/>
                                    <b>INTEGER</b> variables store integer values (0,1,2,3, etc.)<br/><br/>
                                    <b>FLOAT</b> variables store values with decimal points (1.2, 3.14, etc.)<br/><br/>
                                    You can display the current value of any variable type as text in your story with the following syntax: {$variablename}<br/>
                                    For example, if you wish to display the player character’s name, use {$nombre}<br/><br/>
                                    You can set new variables, change existing variables, and compare variables using the variable commands.`
                      }}
                    ></div>
                  </Tooltip>
                )}
              </Overlay>
            </div>
            <table className="table mb-0 mt-2 tableheigth">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">VARIABLE NAME</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">VALUE</th>
                </tr>
              </thead>
              <tbody>
                {props.localVariableListDTO &&
                  props.localVariableListDTO.length > 0 &&
                  props.localVariableListDTO.map((localVariable, index) => {
                    return (
                      <VariableList
                        key={index}
                        variableInfo={localVariable}
                        activeTabSelected={props.activeTabSelected}
                        updateVariableWithId={props.updateVariableWithId}
                        updateValueArrayIds={props.updateValueArrayIds}
                        resetToDefaultValue={props.resetToDefaultValue}
                        currentStoryStatus={props.currentStoryStatus}
                        play={props.play}
                      />
                    );
                  })}
                <tr>
                  <td colSpan={3} className="stesp-var-vlaue"></td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </VariableWindowStyle.Body>
  );
};

export default VariableWindow;

import React from 'react';
import { Button, Modal, } from 'react-bootstrap';
import * as Translations from './translation';
 
 

function ReviewStoryModal(props) {
 // storyStatus={storyStatus}
 let title='';
 let subTitle='';
 let btnText='';
 let subTitleNext='';
 let warning = ''
 switch(props.storyStatus) {

  case 'In Progress':
  case 'In Revision':
      title=Translations.TITLE_REVIEW;
      subTitle=Translations.SUBTITLE_REVIEW;
      btnText=Translations.BTN_REVIEW;
      warning = props.storyReviewWarning
  break;
 case 'Approved':
    title=Translations.TITLE_SUBMIT_PUBLISH;
      subTitle=Translations.SUBTITLE_SUBMIT_PUBLISH;
      btnText=Translations.BTN_SUBMIT_PUBLISH;
      
 break;
 case 'UpdateApproved':
    title=Translations.TITLE_UPDATE_APPROVED;
      subTitle=Translations.SUBTITLE_UPDATE_APPROVED;
       subTitle=Translations.SUBTITLE_UPDATE_APPROVED;
       subTitleNext=Translations.SUBTITLE_NEXT_UPDATE_APPROVED;
      btnText=Translations.BTN_UPDATE_APPROVED;
      
 break;
 case 'UpdatePublished':
    title=Translations.TITLE_UPDATE_PUBLISHED;
      subTitle=Translations.SUBTITLE_UPDATE_PUBLISHED;
      subTitleNext=Translations.SUBTITLE_NEXT_UPDATE_APPROVED;
      btnText=Translations.BTN_UPDATE_PUBLISHED;
      
 break;
 //Rejected
 case 'undoReview':
    title=Translations.TITLE_UNDO_REVIEW;
      subTitle=Translations.SUBTITLE_UNDO_REVIEW;
      btnText=Translations.BTN_UNDO_REVIEW;
      
 break;
 case 'UpdateRejected':
    title=Translations.TITLE_UPDATE_REJECTED;
      subTitle=Translations.SUBTITLE_UNDO_UPDATE_REJECTED;
      btnText=Translations.BTN_UPDATE_REJECTED;
      
 break;
  default:
     break;
}
   
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
     
      backdrop="static"
    >
      
      <Modal.Body className="mt-5">
       <div className="modal-content-wrapper">
           <div  className="modal-icon">
              <img alt="" className="checkIcon" src="/images/icons/ic_warning_24px.svg"/>
           </div>
           <div  className="modal-text">
              <label className="heading">{title}</label>
              <label className="sub-heading">
              {subTitle}  <br/><br/>{subTitleNext} </label>
            
           </div>
            {
              warning != '' &&
              <div className="warning d-flex align-items-center">
                <div className="warning-text">{warning}</div>
              </div>
            }
            
         
       </div>
       <div className="modal-button-wrapper justify-content-between">
       <Button onClick={props.handleHideReviewModal} className="btn-snacktool btn-snacktool-golden">Cancel</Button>
          <Button onClick={props.handleStoryReview} className="btn-snacktool btn-snacktool-golden">{btnText}</Button>
       </div>

      </Modal.Body>
    </Modal>
  );
}
export default ReviewStoryModal;

import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from '../actions/action-types';
import * as StoryActionTypes from '../actions/story-action-types';
import * as StoryActions from './../actions/story-action';

import {
  getBlockFlowchartFromApi,
  lockTheChapter,
  getBlockNameValidApi,
  createSceneFromApi,
  updateSceneFromApi,
  getPreviewDataOnClickApi,
  createRouteFromApi,
  createCastFromApi,
  listRouteFromApi,
  getRouteById,
  getCast,
  deletetRouteData,
  markRouteDataApi,
  markDraftStatusApi,
  deleteCastApi,
  postFlowchartChangesApi
} from './api/flowchart-api';

import { getCharacters } from './api/character-api';
import * as CharacterActions from './../actions/library-action';
import { CharacterService } from './../components/library/character';

//List Scene : Begin
export function* listSceneWorker(payloads) {
  // const sceneListData = yield call(listSceneFromApi, payloads);
  try {
    yield put({
      type: StoryActionTypes.LIST_SCENE_SUCCESS,
      payload: [],
      sceneFlag: true
    });
    // if (sceneListData.result === 'Success') {
    //   yield put({
    //     type: StoryActionTypes.LIST_SCENE_SUCCESS,
    //     payload: sceneListData.body,
    //     sceneFlag: true
    //   });
    //   yield put({
    //     type: ActionTypes.GET_RESET_FLOWCHART_DETAILS,
    //   });

    // } else {
    //   yield put(StoryActions.listSceneFail(sceneListData.message[0]));
    // }
  } catch (err) {
    //yield put(StoryActions.listSceneFail(sceneListData.message[0]));
  }
}

export function* listSceneWatcher() {
  yield takeLatest(StoryActionTypes.LIST_SCENE, listSceneWorker);
}
//List Scene : End

//Create Scene : Begin
export function* createSceneWorker(payloads) {
  if (payloads.authToken) {
    const data = yield call(createSceneFromApi, payloads);
    try {
      if (data.result === 'Success') {
        yield put(StoryActions.createSceneSuccess(data.body));
        const routeDataList = yield call(listRouteFromApi, payloads);
        if (routeDataList.result === 'Success') {
          yield put(StoryActions.listRouteSuccess(routeDataList.body));
        } else {
          yield put(StoryActions.listSceneFail(routeDataList.message[0]));
        }
      } else {
        yield put(StoryActions.createSceneFail(data.message[0]));
      }
    } catch (err) {
      yield put(StoryActions.createSceneFail(data.message[0]));
    }
  }
}

export function* createSceneWatcher() {
  yield takeLatest(StoryActionTypes.CREATE_SCENE, createSceneWorker);
}
//Create Scene : End

//Create Route : Begin

export function* listRouteWorker(payloads) {
  if (payloads.authToken) {
    const listData = yield call(listRouteFromApi, payloads);
    try {
      if (listData.result === 'Success') {
        yield put(StoryActions.listRouteSuccess(listData.body));

        const Chardata = yield call(getCharacters, payloads);

        if (Chardata.result === 'Success') {
          let characterList = CharacterService.sortCharacterListByName(
            Chardata.body
          );
          let characterRouteList = CharacterService.sortCharacterListByName(
            Chardata.body
          );

          yield put(
            CharacterActions.getCharactersSuccess(
              characterList,
              characterRouteList
            )
          );
        } else {
          yield put(CharacterActions.getCharactersFail());
          if (
            Chardata.message[0] ===
            'Full authentication is required to access this resource'
          ) {
            yield put({
              type: ActionTypes.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
          } else {
            yield put({
              type: ActionTypes.SET_API_ERROR,
              errorMsg: Chardata.message[0]
            });
          }
        }
      } else {
        yield put(StoryActions.listSceneFail(listData.message[0]));
        if (
          listData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: listData.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.listSceneFail(listData.message[0]));
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* listRouteWatcher() {
  yield takeLatest(StoryActionTypes.GET_ROUTE, listRouteWorker);
}

export function* getRoutesByIdWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(getRouteById, payload);
      if (data.result === 'Success') {
        let routeData = data.body;
        yield put(StoryActions.getRouteByIdSuccess(routeData));
      } else {
        yield put(StoryActions.getRouteByIdFail([]));
      }
    } catch (err) {
      yield put(StoryActions.getRouteByIdFail());
    }
  }
}

export function* getCastWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(getCast, payload);
      if (data.result === 'Success') {
        let castData = data.body;
        yield put(StoryActions.getCastSuccess(castData));
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: data.message[0]
        });
      }
    } catch (err) {
      yield put(StoryActions.getCastFail());
    }
  }
}

export function* deleteCastWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(deleteCastApi, payload);
      if (data.result === 'Success') {
        let castData = data.body;
        yield put(StoryActions.deleteCastSuccess(castData));
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: data.message[0]
        });
      }
    } catch (err) {
      yield put(StoryActions.getCastFail());
    }
  }
}

export function* fetchRoutesByIDDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_ROUTE_BY_ID, getRoutesByIdWorker);
}

export function* fetchCastDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_CAST, getCastWorker);
}

export function* deleteRoutesWorker(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(getRouteById, payload);
      if (data.result === 'Success') {
        yield put(StoryActions.deleteRouteSuccess([]));
        const routeDataList = yield call(deletetRouteData, payload);
        if (routeDataList.result === 'Success') {
          const routeDataListNew = yield call(listRouteFromApi, payload);
          if (routeDataListNew.result === 'Success') {
            yield put(StoryActions.listRouteSuccess(routeDataListNew.body));
            const Chardata = yield call(getCharacters, payload);

            if (Chardata.result === 'Success') {
              let characterList = CharacterService.sortCharacterListByName(
                Chardata.body
              );
              let characterRouteList = CharacterService.sortCharacterListByName(
                Chardata.body
              );

              yield put(
                CharacterActions.getCharactersSuccess(
                  characterList,
                  characterRouteList
                )
              );
            } else {
              yield put(CharacterActions.getCharactersFail());
              if (
                Chardata.message[0] ===
                'Full authentication is required to access this resource'
              ) {
                yield put({
                  type: ActionTypes.SET_LOGIN_DETAILS,
                  payload: null,
                  isLogOut: true
                });
              } else {
                yield put({
                  type: ActionTypes.SET_API_ERROR,
                  errorMsg: Chardata.message[0]
                });
              }
            }
          } else {
            yield put(StoryActions.listSceneFail(routeDataListNew.message[0]));
            if (
              routeDataListNew.message[0] ===
              'Full authentication is required to access this resource'
            ) {
              yield put({
                type: ActionTypes.SET_LOGIN_DETAILS,
                payload: null,
                isLogOut: true
              });
            } else {
              yield put({
                type: ActionTypes.SET_API_ERROR,
                errorMsg: routeDataListNew.message[0]
              });
            }
          }
        } else {
          yield put(StoryActions.listSceneFail(routeDataList.message[0]));
          if (
            routeDataList.message[0] ===
            'Full authentication is required to access this resource'
          ) {
            yield put({
              type: ActionTypes.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
          } else {
            if (
              data.message[0] ===
              'Full authentication is required to access this resource'
            ) {
              yield put({
                type: ActionTypes.SET_LOGIN_DETAILS,
                payload: null,
                isLogOut: true
              });
            } else {
              yield put({
                type: ActionTypes.SET_API_ERROR,
                errorMsg: data.message[0]
              });
            }
          }
        }
      } else {
        yield put(StoryActions.deleteRouteFail([]));
        yield put({
          type: ActionTypes.SET_API_ERROR
        });
      }
    } catch (err) {
      yield put(StoryActions.deleteRouteFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* createRouteWorker(payloads) {
  if (payloads.authToken) {
    const data = yield call(createRouteFromApi, payloads);
    try {
      if (data.result === 'Success') {
        yield put(StoryActions.createRouteSuccess(data.body));
        const routeDataList = yield call(listRouteFromApi, payloads);
        if (routeDataList.result === 'Success') {
          yield put(StoryActions.listRouteSuccess(routeDataList.body));

          const Chardata = yield call(getCharacters, payloads);

          if (Chardata.result === 'Success') {
            let characterList = CharacterService.sortCharacterListByName(
              Chardata.body
            );
            let characterRouteList = CharacterService.sortCharacterListByName(
              Chardata.body
            );

            yield put(
              CharacterActions.getCharactersSuccess(
                characterList,
                characterRouteList
              )
            );
          } else {
            yield put(CharacterActions.getCharactersFail());
          }
        } else {
          yield put(StoryActions.listSceneFail(routeDataList.message[0]));
        }
      } else {
        yield put(StoryActions.createRouteFail(data.message[0]));
      }
    } catch (err) {
      yield put(StoryActions.createRouteFail(data.message[0]));
    }
  }
}

export function* createCastWorker(payloads) {
  if (payloads.authToken) {
    const data = yield call(createCastFromApi, payloads);
    try {
      if (data.result === 'Success') {
        yield put(StoryActions.createCastSuccess(data.body));
        // const routeDataList = yield call(listRouteFromApi, payloads);
        // if (routeDataList.result === 'Success') {
        //   yield put(StoryActions.listRouteSuccess(routeDataList.body));

        //   const Chardata = yield call(getCharacters, payloads);

        //   if (Chardata.result === 'Success') {
        //     let characterList = CharacterService.sortCharacterListByName(Chardata.body);
        //     let characterRouteList = CharacterService.sortCharacterListByName(Chardata.body);

        //     yield put(CharacterActions.getCharactersSuccess(characterList, characterRouteList));
        //   } else {
        //     yield put(CharacterActions.getCharactersFail());
        //   }

        // } else {
        //   yield put(StoryActions.listSceneFail(routeDataList.message[0]));
        // }
      } else {
        yield put(StoryActions.createCastFail(data.message[0]));
      }
    } catch (err) {
      yield put(StoryActions.createCastFail(data.message[0]));
    }
  }
}

export function* createRouteWatcher() {
  yield takeLatest(StoryActionTypes.CREATE_ROUTE, createRouteWorker);
}

export function* createCastWatcher() {
  yield takeLatest(StoryActionTypes.CREATE_CAST, createCastWorker);
}

export function* markStoryRouteWorker(payload) {
  if (payload.authToken) {
    try {
      // const data = yield call(getRouteById, payload);
      // if (data.result === 'Success') {
      const routeDataList = yield call(markRouteDataApi, payload);
      if (routeDataList.result === 'Success') {
        yield put(StoryActions.markCompleteStoryRouteSuccess([]));
        // const routeDataListNew = yield call(listRouteFromApi, payload);
        // if (routeDataListNew.result === 'Success') {
        //   yield put(StoryActions.listRouteSuccess(routeDataListNew.body));
        // } else {
        //   yield put(StoryActions.listSceneFail(routeDataListNew.message[0]));
        // }
      } else {
        yield put(StoryActions.listSceneFail(routeDataList.message[0]));
      }
      // } else {
      //   yield put(StoryActions.markCompleteStoryRouteFail([]));
      // }
    } catch (err) {
      yield put(StoryActions.deleteRouteFail());
    }
  }
}

///////markDraftStatusWorker

export function* markDraftStatusWorker(payload) {
  if (payload.authToken) {
    try {
      const routeDataList = yield call(markDraftStatusApi, payload);
      if (routeDataList.result === 'Success') {
        yield put(StoryActions.markDraftStatusSuccess([]));
        const routeDataListNew = yield call(listRouteFromApi, payload);
        if (routeDataListNew.result === 'Success') {
          yield put(StoryActions.listRouteSuccess(routeDataListNew.body));
        } else {
          yield put(StoryActions.listSceneFail(routeDataListNew.message[0]));
        }
      } else {
        yield put(StoryActions.markDraftStatusFail([]));
      }
    } catch (err) {}
  }
}

export function* fetchdeleteRoutesDataWatcher() {
  yield takeLatest(StoryActionTypes.DELETE_ROUTE, deleteRoutesWorker);
}

export function* fetchdeleteCastDataWatcher() {
  yield takeLatest(StoryActionTypes.DELETE_CAST, deleteCastWorker);
}

export function* fetchMarkStoryAsCompleted() {
  yield takeLatest(StoryActionTypes.MARK_STORY_ROUTE, markStoryRouteWorker);
}

export function* fetchDraftStatus() {
  yield takeLatest(
    StoryActionTypes.MARK_STORY_DRAFT_STATUS,
    markDraftStatusWorker
  );
}

//Create Route : End

//Update Scene List : Start
export function* updateSceneWorker(payloads) {
  if (payloads.authToken) {
    const data = yield call(updateSceneFromApi, payloads);
    try {
      if (data.result === 'Success') {
        yield put(StoryActions.saveListSceneSuccess(data.body));
        const routeDataList = yield call(listRouteFromApi, payloads);
        if (routeDataList.result === 'Success') {
          yield put(StoryActions.listRouteSuccess(routeDataList.body));
        } else {
          yield put(StoryActions.listSceneFail(routeDataList.message[0]));
        }
      } else {
        yield put(StoryActions.saveListSceneFail(data.message[0]));
      }
    } catch (err) {
      yield put(StoryActions.saveListSceneFail(data.message[0]));
    }
  }
}

export function* updateSceneWatcher() {
  yield takeLatest(StoryActionTypes.UPDATE_LIST_SCENE, updateSceneWorker);
}
//Update Scene List : End

export function* getFlowchart(payloads) {
  if (payloads.authToken) {
    try {
      const flowchartListdata = undefined;
      if (flowchartListdata === 'Success') {
        yield put({
          type: ActionTypes.GET_SUCCESS_FLOWCHART_DETAILS,
          payload: flowchartListdata,
          sceneFlag: true
        });
      } else {
        yield put({ type: ActionTypes.GET_FAIL_FLOWCHART_DETAILS });
      }
    } catch (e) {
      yield put({ type: ActionTypes.GET_FAIL_FLOWCHART_DETAILS });
    }
  }
}

export function* getBlockFlowchart(payloads) {
  if (payloads.authToken) {
    try {
      const flowchartListdata = yield call(getBlockFlowchartFromApi, payloads);

      const lockChapter = yield call(lockTheChapter, payloads);
      if (flowchartListdata.result === 'Success') {
        yield put({
          type: ActionTypes.GET_SUCCESS_BLOCK_FLOWCHART_DETAILS,
          payload: flowchartListdata,
          sceneId: payloads.blockId,
          sceneFlag: false
        });

        const flowchartDataPreview = yield call(
          getPreviewDataOnClickApi,
          payloads
        );
        if (flowchartDataPreview.result === 'Success') {
          yield put({
            type: ActionTypes.GET_SUCCESS_PREVIEW_DATA_ON_CLICK,
            payload: flowchartDataPreview,
            sceneId: payloads.blockId,
            sceneFlag: false
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: flowchartDataPreview.message[0]
          });
        }
      } else {
        yield put({
          type: ActionTypes.GET_FAIL_FLOWCHART_DETAILS,
          sceneId: payloads.blockId
        });
        // yield put({
        //   type: ActionTypes.SET_API_ERROR,
        // });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.GET_FAIL_FLOWCHART_DETAILS,
        sceneId: payloads.blockId
      });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getPreviewDataOnClick(payloads) {
  if (payloads.authToken) {
    try {
      const previewData = yield call(getPreviewDataOnClickApi, payloads);
      if (previewData.result === 'Success') {
        yield put({
          type: ActionTypes.GET_SUCCESS_PREVIEW_DATA_ON_CLICK,
          payload: previewData,
          sceneId: payloads.blockId,
          sceneFlag: false
        });
      } else {
        yield put({
          type: ActionTypes.GET_FAIL_PREVIEW_DATA_ON_CLICK,
          sceneId: payloads.blockId
        });
        if (
          previewData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        }
      }
    } catch (e) {
      yield put({
        type: ActionTypes.GET_FAIL_PREVIEW_DATA_ON_CLICK,
        sceneId: payloads.sceneId
      });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getBlockNameValidWorker(payloads) {
  if (payloads.authToken) {
    try {
      const validateUnique = payloads.flowchartNode.some(
        blocks => payloads.title === blocks.displayName
      );
      let data = [];
      if (validateUnique !== true) {
        data = yield call(getBlockNameValidApi, payloads);
      }
      if (data.result === 'Success') {
        yield put({
          type: ActionTypes.GET_BLOCK_NAME_VALID_SUCCESS,
          payload: data.body
        });
      } else {
        yield put({ type: ActionTypes.GET_BLOCK_NAME_VALID_FAIL });
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (e) {
      yield put({ type: ActionTypes.GET_BLOCK_NAME_VALID_FAIL });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* postFlowchartChanges(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(postFlowchartChangesApi, payloads);
      if (data.result === 'Success') {
        yield put({
          type: StoryActionTypes.SUCCESS_SAVE_FLOWCHART_CHANGES,
          payload: data.body
        });
      } else {
        yield put({ type: StoryActionTypes.FAIL_SAVE_FLOWCHART_CHANGES });
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (e) {
      yield put({ type: StoryActionTypes.FAIL_SAVE_FLOWCHART_CHANGES });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* addNewBlockFlowchartData(payloads) {
  if (payloads.authToken) {
    try {
      yield put({
        type: ActionTypes.ADD_NEW_BLOCK_FLOWCHART,
        payload: payloads
      });
    } catch (e) {
      yield put({ type: ActionTypes.GET_BLOCK_NAME_VALID_FAIL });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getFlowchartDataWatcher() {
  yield takeLatest(ActionTypes.GET_FLOWCHART_DETAILS, getFlowchart);
}

export function* getBlockFlowchartDataWatcher() {
  yield takeLatest(ActionTypes.GET_BLOCK_FLOWCHART_DETAILS, getBlockFlowchart);
}

export function* getBlockNameValidWatcher() {
  yield takeLatest(ActionTypes.GET_BLOCK_NAME_VALID, getBlockNameValidWorker);
}

export function* addNewBlockFlowchart() {
  yield takeLatest(
    ActionTypes.ADD_NEW_BLOCK_FLOWCHART,
    addNewBlockFlowchartData
  );
}

export function* fetchPreviewDataOnClick() {
  yield takeLatest(
    ActionTypes.GET_PREVIEW_DATA_ON_CLICK,
    getPreviewDataOnClick
  );
}

export function* saveFlowchartDragData() {
  yield takeLatest(
    StoryActionTypes.SAVE_FLOWCHART_CHANGES,
    postFlowchartChanges
  );
}

export default {
  getFlowchartDataWatcher,
  getBlockNameValidWatcher,
  createSceneWatcher,
  listSceneWatcher,
  updateSceneWatcher,
  fetchPreviewDataOnClick,
  createRouteWatcher,
  listRouteWatcher,
  fetchRoutesByIDDataWatcher,
  fetchdeleteRoutesDataWatcher,
  fetchMarkStoryAsCompleted,
  saveFlowchartDragData,
  fetchDraftStatus,
  createCastWatcher,
  fetchCastDataWatcher,
  fetchdeleteCastDataWatcher
};

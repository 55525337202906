import React  from 'react';

import CommandsLevel1 from './commands-level1';
import CommandsLevel2 from './commands-level2';

const CommandsPanel = props => {
  return (
    <div>
      {
        (props.level === 1) ?
          <CommandsLevel1
            setLevel={props.setLevel}
            commandsList={props.commandsList}
            loading={props.loading}
            showShortCut={props.showShortCut}
            handleCommnadMenuClick={props.handleCommnadMenuClick}
            handleCommnadShortCutClick={props.handleCommnadShortCutClick}
            // flowchartNode={props.flowchartNode}
            // commandBackground={props.commandBackground}
            // commandCharacter={props.commandCharacter}
            // commandMedia={props.commandMedia}
            // commandPlayMusic={props.commandPlayMusic}
            // commandAllFlags={props.commandAllFlags}
            // availableCharacters={props.availableCharacters}
            handlePlaceViewMenuClick={props.handlePlaceViewMenuClick}
            handlePlaceMoveViewMenuClick={props.handlePlaceMoveViewMenuClick}
            // backgroundImages={props.backgroundImages}
            isViewStory={props.isViewStory}
            tokenDataUser={props.tokenDataUser}
            variableWindoOnOffStatus={props.variableWindoOnOffStatus}
          />
          :
          props.commandsList2 &&
          <CommandsLevel2
          
            key={`command-level-2-${props.commandId}`}
            setLevel={props.setLevel}
            commandsList={props.commandsList2}
            loading={props.loading2}
            handleSetLevel={props.handleSetLevel}
            handleSaveCommandInfo={props.handleSaveCommandInfo}

            validated={props.validated}
            isValidText={props.isValidText}
            handleTextFieldChange={props.handleTextFieldChange}
            flowchartNode={props.flowchartNode}

            commandId={props.commandId}

            characterList={props.characterList}
            full_name_toggle={props.full_name_toggle}
            isCommandEdit={props.isCommandEdit}
            variableListDTO={props.variableListDTO}
          />
      }
    </div>
  );
}

export default CommandsPanel;


import React from 'react';
import { CommentStyle } from './styles';
import { Row, Col } from 'react-bootstrap';
import { CommentServices } from './service';
import {
  STORY_PENDING_APPROVAL,
  SNACK_USER_EDITOR,
  SNACK_USER_ADMIN,
  SNACK_USER_WRITER
} from './../../../common/constants';

function AllComments(props) {
  return (
    <div className="comment-body">
      {props.commentLists.map((commentInfo, index) => (
        <CommentStyle.listHeading>
          <div className="comment-content borderTop">
            <Row>
              <Col xs={6}>
                <div className="pl-4 pr-4">
                  <h4 className="comment-subject">{`Comment #${index + 1}`}</h4>
                  <p className="m-0">{`By ${commentInfo.createdBy}`}</p>
                </div>
              </Col>
              <Col xs={6}>
                <div className="pl-4 pr-4 d-flex justify-content-end">
                  {commentInfo.status === 'Resolved' &&
                    ((props.showPanels === false ||
                      (props.tokenData.roles === SNACK_USER_EDITOR ||
                        props.tokenData.roles === SNACK_USER_ADMIN)) &&
                      props.currentStoryStatus === 'Pending Approval') && (
                      <a
                        href="#"
                        onClick={() =>
                          props.handleResolveComment(index, 'unresolved')
                        }
                      >
                        <div className="reopen-link mr-3">Re-open</div>
                      </a>
                    )}
                  <div
                    className={`status ${
                      commentInfo.status === 'Resolved' ? 'green' : 'red'
                    }`}
                  >
                    <span className="circle"></span>
                    <label>{commentInfo.status}</label>
                  </div>
                </div>
              </Col>
            </Row>
            {commentInfo.blockName !== null && commentInfo.command !== null && (
              <Row>
                <Col className="ml-4 mr-4 mt-4 cmdscript-box">
                  <h3>{`Command in script (Block: “${commentInfo.blockName}”)`}</h3>
                  <p>
                    {' '}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: CommentServices.getCommandColor(
                          commentInfo.command
                        )
                      }}
                    />{' '}
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <div className="pl-4 pr-4">
                  <h4 className="mt-4">Comment:</h4>
                  <p className="comment-text">{commentInfo.commentText}</p>
                  <div className="reply-text">
                    <span>Reply:</span>{' '}
                    {commentInfo.reply !== null ? commentInfo.reply : ''}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </CommentStyle.listHeading>
      ))}
    </div>
  );
}

export default AllComments;

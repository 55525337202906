import { createGlobalStyle } from 'styled-components';

import amaranthRegular from './amaranth/Amaranth-Regular.ttf';
import Source_Code_Pro from './Source_Code_Pro/SourceCodePro-Black.ttf';
import Inconsolata from './Inconsolata/Inconsolata-VariableFont_wdth,wght.ttf';
import amaranthBold from './amaranth/Amaranth-Bold.ttf';
import amaranthItalic from './amaranth/Amaranth-Italic.ttf';
import amaranthBoldItalic from './amaranth/Amaranth-Regular.ttf';

import antonRegular from './anton/anton-regular.ttf';

import arvoRegular from './arvo/arvo-regular.ttf';
import arvoItalic from './arvo/arvo-italic.ttf';
import arvoBoldItalic from './arvo/arvo-bold-italic.ttf';
import arvoBold from './arvo/arvo-bold.ttf';

import averiaSansLibreRegular from './averia_sans_libre/AveriaSansLibre-Regular.ttf';
import carterOneRegular from './carter_one/CarterOne-Regular.ttf';

import graduateRegular from './graduate/Graduate-Regular.ttf';

import josefinSansRegular from './josefin_sans/static/JosefinSans-Regular.ttf';

import latoRegular from './lato/Lato-Regular.ttf';
import latoBold from './lato/Lato-Bold.ttf';

import livvicRegular from './livvic/Livvic-Regular.ttf';

import londrinaSolidRegular from './londrina_solid/LondrinaSolid-Regular.ttf';

import merriweatherRegular from './merriweather/Merriweather-Regular.ttf';
import merriweatherBold from './merriweather/Merriweather-Bold.ttf';

import montSerratRegular from './montserrat/Montserrat-Regular.ttf';

import mPlusRoundedRegular from './m_plus_rounded_1c/MPLUSRounded1c-Regular.ttf';

import nanumMyeongjoRegular from './nanum_myeongjo/NanumMyeongjo-Regular.ttf';

import permanentMarkerRegular from './permanent_marker/PermanentMarker-Regular.ttf';

import prataRegular from './prata/Prata-Regular.ttf';

import pridiRegular from './pridi/Pridi-Regular.ttf';

import quicksandRegular from './quicksand/static/Quicksand-Regular.ttf';

import ralewayRegular from './raleway/Raleway-Regular.ttf';
import ralewayMedium from './raleway/Raleway-Medium.ttf';

import robotoRegular from './roboto/Roboto-Regular.ttf';
import robotoMedium from './roboto/Roboto-Medium.ttf';
import robotoCondensedRegular from './roboto_condensed/RobotoCondensed-Regular.ttf';
import robotoMonoRegular from './roboto_mono/RobotoMono-Regular.ttf';
import robotoSlabRegular from './roboto_slab/static/RobotoSlab-Regular.ttf';

import rockSaltRegular from './rock_salt/RockSalt-Regular.ttf';

import sanchezRegular from './sanchez/Sanchez-Regular.ttf';
import sourceSansProBold from './source_sans_pro/SourceSansPro-Bold.ttf';
import sourceSansProRegular from './source_sans_pro/SourceSansPro-Regular.ttf';

import PT_MONO from './PT_Mono/PTMono_Regular.ttf';
import syne_mono from './Syne_Mono/SyneMono_Regular.ttf';
import nova_mono from './Nova_Mono/NovaMono_Regular.ttf';
import VT323 from './VT323/VT323_Regular.ttf';
import Xanh_mono from './Xanh_Mono/XanhMono_Regular.ttf';   
import OxygenMono from './Oxygen_Mono/OxygenMono-Regular.ttf';
import JetBrainsMono from './JetBrains_Mono/static/JetBrainsMono-Regular.ttf';
import Consolas from './consolas/CONSOLAS_regular.ttf';
import Monaco from './Monaco/Monaco.ttf';
import Menlo from './Menlo/Menlo-Regular.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'amaranth';
        src: url(${amaranthRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Source Code Pro';
        src: url(${Source_Code_Pro}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Inconsolata';
        src: url(${Inconsolata}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    


    @font-face {
        font-family: 'amaranthbold';
        url(${amaranthBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'amaranthitalic';
        src: url(${amaranthItalic}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'amaranthbold_italic';
        src: url(${amaranthBoldItalic}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Anton font */
    @font-face {
        font-family: 'anton';
        src: url(${antonRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Arvo font */
    @font-face {
        font-family: 'arvo';
        src: url(${arvoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'arvo_bold';
        src: url(${arvoBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'arvo_italic';
        src: url(${arvoItalic}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'arvo_bold_italic';
        src: url(${arvoBoldItalic}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Averia Sans Libre */
    @font-face {
        font-family: 'averia_Sans_Libre';
        src: url(${averiaSansLibreRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }


    /* Cartor one */
    @font-face {
        font-family: 'carter_one';
        src: url(${carterOneRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Graduate */
    @font-face {
        font-family: 'graduate';
        src: url(${graduateRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Josefin Sans */
    @font-face {
        font-family: 'josefin_sans';
        src: url(${josefinSansRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Lato */
    @font-face {
        font-family: 'lato';
        src: url(${latoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    /* Lato */
    @font-face {
        font-family: 'lato_bold';
        src: url(${latoBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Livvic */
    @font-face {
        font-family: 'livvic';
        src: url(${livvicRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Londrina solid */
    @font-face {
        font-family: 'londrina_solid';
        src: url(${londrinaSolidRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Merriweather */
    @font-face {
        font-family: 'merriweather';
        src: url(${merriweatherRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'merriweather_bold';
        src: url(${merriweatherBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* MontSerrat */
    @font-face {
        font-family: 'montserrat';
        src: url(${montSerratRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* M Plus rounded */
    @font-face {
        font-family: 'mplus_rounded';
        src: url(${mPlusRoundedRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Nanum myeongjo */
    @font-face {
        font-family: 'nanum_myeongjo';
        src: url(${nanumMyeongjoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    } 

    /* Permanent marker */
    @font-face {
        font-family: 'permanent_marker';
        src: url(${permanentMarkerRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Prata */
    @font-face {
        font-family: 'prata';
        src: url(${prataRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Pridi */
    @font-face {
        font-family: 'pridi';
        src: url(${pridiRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
  
    /* Quicksand */
    @font-face {
        font-family: 'quicksand';
        src: url(${quicksandRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Raleway */
    @font-face {
        font-family: 'raleway';
        src: url(${ralewayRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'raleway_medium';
        src: url(${ralewayMedium}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Robot */
    @font-face {
        font-family: 'roboto';
        src: url(${robotoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'roboto_medium';
        src: url(${robotoMedium}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Roboto condensed */
    @font-face {
        font-family: 'roboto_condensed';
        src: url(${robotoCondensedRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Roboto mono */
    @font-face {
        font-family: 'roboto_mono';
        src: url(${robotoMonoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /*PT mono */
    @font-face {
        font-family: 'PT Mono';
        src: url(${PT_MONO}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /*Syne mono */
    @font-face {
        font-family: 'Syne Mono';
        src: url(${syne_mono}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }


    /*Nova mono */
    @font-face {
        font-family: 'Nova Mono';
        src: url(${nova_mono}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }


    @font-face {
        font-family: 'Roboto Mono';
        src: url(${robotoMonoRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Roboto slab */
    @font-face {
        font-family: 'robotoslab';
        src: url(${robotoSlabRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Rock salt */
    @font-face {
        font-family: 'rock_salt';
        src: url(${rockSaltRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* Sanchez */
    @font-face {
        font-family: 'sanchez';
        src: url(${sanchezRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* sourceSansProBold */
    @font-face {
        font-family: 'sourceSansProRegular';
        src: url(${sourceSansProRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* sourceSansProBold */
    @font-face {
        font-family: 'sourceSansProBold';
        src: url(${sourceSansProBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'VT323';
        src: url(${VT323}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Xanh Mono';
        src: url(${Xanh_mono}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Oxygen Mono';
        src: url(${OxygenMono}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Jetbrains Mono';
        src: url(${JetBrainsMono}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Consolas';
        src: url(${Consolas}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Monaco';
        src: url(${Monaco}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Menlo';
        src: url(${Menlo}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
`;

import React from 'react';
import { ChapterStyle} from './style';
import { Row,Modal,Button, Col} from 'react-bootstrap';
import * as translations from './translations';

function  Success(props) {

  return (

    <Modal 
    aria-labelledby="contained-modal-title-vcenter"
    dialogClassName="confirmModal"
   className="modalbg" 
   size="md" 
   show={props.showModal}
   onHide={props.handleHideRouteResult}
   >
     <Modal.Body  >
       
     
     <ChapterStyle.addAssetMessageBox>
            <ChapterStyle.successAssetContent>
                <Row>
                    <Col className="success-icon mb-3">
                        <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
                    </Col>
                </Row>
                <Row><Col className="submit-asset-label">{props.isRouteEditSuccess===true?translations.NEW_ROUTE_UPDATE:translations.NEW_ROUTE_SAVE}</Col></Row>
               
                <Row>
                    <Col className="submit-asset-cancel-label ">
                    <br/>
                   <br/>
                   <br/>
                    <Button data-testid="success-btn"  onClick={props.handleHideRouteResult}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
                    </Col>
                </Row> 
            </ChapterStyle.successAssetContent>
        </ChapterStyle.addAssetMessageBox>
     </Modal.Body>
   </Modal>
 );
  
     
}

export default Success
// This version uses IF ELSE END syntax of NH
// a public class
class ConditionAnalyser {
    constructor() {
        this.conditionValues = [];
        this.endConditionCount = 0;
        this.ifConditionCount = 0;
    }

    addCondition(conditionStr) { // if-action
        this.conditionValues.push(eval(conditionStr));
    }

    shouldExecute() { // action on non-condition-lines
        return this.conditionValues.every((boolVal) => boolVal === true)
    }

    negateLastCondition() { // else-action
        let lastConditionValue = this.conditionValues.pop();
        this.conditionValues.push(!lastConditionValue);
    }

    addConditionEndCount() {
        this.endConditionCount += 1
    }

    removeLastCondition() { //end-action
        this.conditionValues.pop();
        this.endConditionCount -= 1;
        this.ifConditionCount -= 1;

        if (this.endConditionCount == 0 && this.ifConditionCount == 0) {
            this.conditionValues = [];
        }
    }

    getAllValues() {
        return this.conditionValues;
    }

    addIfCount() {
        this.ifConditionCount += 1
    }

    // a public function
    whetherToExecute(line, ind = 0) {
        ++ind;
        if (line.trim().length === 0) { //line is empty
            return false;
        } else if (line.search(/\bif\b/) !== -1 && line.search(/\bif\b/) === 0) {
            this.addIfCount();
            const conditionPortion = line.replace('if', '').trim().replace('=/=', '!=');
            this.addCondition(conditionPortion);
            return false;
        } else if (line.search(/\belse\b/) !== -1 && line.search(/\belse\b/) == 0) {
            this.negateLastCondition();
            return false;
        } else if (line.search(/\bend\b/) !== -1 && line.search(/\bend\b/) == 0 ) {
            this.addConditionEndCount();
            this.removeLastCondition();
            return false;
        } else if (line.search(/\belseIf\b/) !== -1 && line.search(/\belseIf\b/) == 0) {
            this.negateLastCondition();
            const conditionPortion = line.replace('elseIf', '').trim().replace('=/=', '!=');
            this.addCondition(conditionPortion);
            return false;
        } {
            return this.shouldExecute();
        }
    }
}

export default ConditionAnalyser
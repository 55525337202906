import React from 'react'
 

const CollaboratorList = (props) => {
 
 
    return (
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">VIEWER</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.collaboratorList.map((collabborator, index) => {
                        return(<tr key = {index}>
                            <td>{collabborator.user.username}</td>
                            <td className="text-right">{collabborator.status ==='INVITATION_EXPIRED' && <span>{collabborator.status}. </span>}
                            {props.tokenDataUser.userId!==collabborator.user.id &&  collabborator.status !=='INVITATION_EXPIRED'   &&
                             <a    onClick={(event) => props.revokeCollaboratorAccess(event,collabborator.storyId,collabborator.id)} href="#">Revoke access</a>}
                            {collabborator.status ==='INVITATION_EXPIRED' && <a onClick={(event) => props.resendCollaboratorAccess(event,collabborator.storyId,collabborator.id)} href="#">Resend</a>}
                            </td>
                        </tr>)
                    })
                }
            
            </tbody>
        </table>
    )
}

export default CollaboratorList
import { endpoint } from "../../config/app.config";






export const postComment = (payloads) => {
 
  return fetch(`${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/comments`, {
    method: "POST",
    body: JSON.stringify(payloads.payload),
    headers: {
      "Content-Type": "application/json",
      "Authorization":"Bearer " + payloads.authToken
    },
     
  })
    .then(res => {
     
      return res.json();
    })
    .then(resp=>{
      
    return resp;
  })
    .catch((err) => {
    
      throw err;
    });
};


export const updateComment = (payloads) => {
  
  if(payloads.isComment===false)
  {
   return fetch(`${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/comments/${payloads.commentId}`, {
     method: "PUT",
     body: JSON.stringify(payloads.payload),
     headers: {
       "Content-Type": "application/json",
       "Authorization":"Bearer " + payloads.authToken
     },
      
   })
     .then(res => {
      
       return res.json();
     })
     .then(resp=>{
       
     return resp;
   })
     .catch((err) => {
     
       throw err;
     });
    }
    else{
       return fetch (`${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/comments/${payloads.commentId}`, {
  method: 'DELETE',
  //body: JSON.stringify(payloads.payload),
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${payloads.authToken}`
  }
})
.then(resp => resp.json())
.then(resp => {return resp})
.catch((err) => {
  throw err
})

    }
 };



  export const getAllComments = (payload) => {
    
    return fetch(`${endpoint}/api/stories/${payload.storyId}/scenes/${payload.sceneId}/comments`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${payload.authToken}`,
          
        }
      }
    ).then(res => res.json())
      .then(resp => {
       
        return resp;
      })
      .catch((err) => {
        throw err;
      });
  };
import { combineReducers } from "redux";
import * as ActionTypes from './../actions/action-types';
import { SceneReducer } from "./scene-reducer";
import { StoryReducer } from "./story-reducer";
import { BlockReducer } from "./block-reducer";
import { AccountReducer } from "./accounts-reducer";
import { LoginReducer } from "./login-reducer";
import { LibraryReducer } from './library-reducer';
import { FlowchartReducer } from './flowchart-reducer';
import { CharacterReducer } from './character-reducer';
import { StoryProgressReducer } from './story-progress-reducer';
import { PlayerReducer } from './player-reducer';
import { CommandReducer } from './command-reducer';
import { GameInterfaceReducer } from './game-interface-reducer';
import { PlaceBackgroundReducer } from './place-background-reducer';
import { CommentReducer } from './comment-reducer'
import { MyAccountReducer } from './myaccount-reducer';




const rootReducer = combineReducers({
    StoryReducer,
    SceneReducer,
    BlockReducer,
    AccountReducer,
    LoginReducer,
    LibraryReducer,
    FlowchartReducer,
    CharacterReducer,
    StoryProgressReducer,
    PlayerReducer,
    CommandReducer,
    GameInterfaceReducer,
    PlaceBackgroundReducer,
    CommentReducer,
    MyAccountReducer
});

export default (state, action) => (
    action.type === ActionTypes.SET_LOGIN_DETAILS
        ? rootReducer(undefined, action)
        : rootReducer(state, action)
)
import styled from 'styled-components';
import { colors } from './../../common/styles/variables';

export const StoryList = styled.div`
  background: url('../../images/new_image/book2.png') 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 2px 3px #00000033;
  border-radius: 6px;
  height: 420px;
  margin-bottom: 30px;
  // min-width:340px;
  width: 100% !important;
  background-size:100% 105%

  .btn .btnMainStory {
    background-color: none !important;
    box-shadow: none !important;
    text-color: ${colors.shades_shade500};
  }

  .genersList {
    margin-top: 8px;
    height: 13px;
    text-align: left;
    font-size: 12px;
    font-family: PT Sans;
    letter-spacing: 0;
    line-height: 14px;
    color: #f6b665;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-spacing: 25px;
  }
  .margin-story {
    margin-left: 26px;
  }
`;

export const StoryTitle = styled.div`
  font-family: PT Sans;
  font-size: 24px;
  line-height: 32px;
  // color: #303030;
  color: #f6b665;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

export const StoryCreator = styled.label`
  // margin-left: 30px;
  margin-bottom: 10px;
  font-family: PT Sans;
  font-size: 15px;
  line-height: 19px;
  color: #f6b665;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StoryCover = styled.div`

  height: 232px;
  background: url(${props =>
    props.storyCoverImage || '/images/placeholder.png'})
    no-repeat;
  opacity: 1;
  background-size:cover;
  background-position: center;
  // background-size: cover;
  margin-right:10px;
`;

export const StoryStatus = styled.label`
  margin: 5px 16px 5px 16px;
  height: 14px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto;
  letter-spacing: 0.22px;
  color: #000000de;
  opacity: 1;
`;

export const StoryStatusSpan = styled.label`
  border-radius: 46px;
  opacity: 1;
  margin-top: 6px;
  margin-left: 10px;
  margin-bottom: 0px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 46px;
  opacity: 1;
`;

export const StoryGeners = styled.label`
    margin-top: 8px;
    // margin-left : 30px;
    height: 13px;
    text-align: left;
    font-size:11px
    font-family: PT Sans;
    letter-spacing: 0;
    line-height : 14px
    // color: #606060;
    color:#F6B665;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom : 0px;
`;

export const StoryActionButton = styled.div`
    display:flex;
    justify-content:center
    margin: 15px 0;
`;

export const StoryButtonLabel = styled.label`
  margin-bottom: 0px;
  height: 16px;
  text-align: center;
  font-familty: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${colors.primary_green};
  opacity: 1;
  cursor: pointer;
`;

export const StoryRightPanelTab = styled.div`
  .right-panel-tabs {
    border: 0;
    // border-bottom: 1px solid #3A2C61;
    background: #F0EEF8;
    .nav-item {
      font-size: 14px;
      font-weight: bold;
      // color: #acacac;
      color: #3A2C61;
      padding: 15px 8px;
      width: 50%;
      display: flex;
      padding: 15px 0;
      justify-content: center;
      border: 0;
    }
    .active {
      // color: #530acd !important;
      color: #3A2C61 !important;
      border: 0;
      border-bottom: 4px solid #ffffff !important;
    }
  }
  .tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 190px);
  }
  .nav-link.active {
    background-color: transparent !important;
  }
  .vertical-align-middle {
    vertical-align: middle;
  }
`;

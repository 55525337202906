import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as styled from './style';
import PasswordRecoveryForm from './../../components/login/password-recovery-form';
import PasswordRecoverySuccess from './../../components/login/password-recovery-success';
import ChangePasswordRecoveryForm from './../../components/login/change-password-recovery-form';
import { connect } from 'react-redux';
import * as actions from './../../actions/password-recovery-actions';
import { commonServices } from './../../common/helper/utils';
import SuccessModal from '../../components/myaccount/myAccountSuccess'
import * as ActionTypes from './../../actions/action-types';
import * as Pattern from './../../common/helper/pattern';

function PasswordRecovery(props) {
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);
    const [disabledState, setDisabledState] = useState(true);
    const [verified, setVerified] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        if (props.location.search.includes('email=')) {
            let params = commonServices.getQueryParams(props.location.search);
            setEmail(params.email);
            props.changePassword();
        }
    }, [props.location.search])

    useEffect(() => {
        props.resetInvalidLoginAttempts();
    }, [])

    const onChange = (event) => {
        let email = event.target.value;
        setEmail(email);
        setEmailError('');
        if (email !== '') {
            setDisabledState(false);
            if (new RegExp(Pattern.EMAIL_PATTERN_ACCOUNT).test(email) !== true) {
                setEmailError('Please enter valid email address')
            }
        } else {
            setDisabledState(true);
        }
        props.resetPasswordRecovery();
        setValidated(true);
    }

    const onSubmit = (event) => {
        let form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            if (verified === false) {
                setShowCaptchaError(true);
            } else {
                setShowCaptchaError(false);
                props.sendPasswordRecovery(email);
                setDisabledState(true)
            }
        }
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
    }

    const backToLogin = () => {
        props.history.push(`/login`);
        return null;
    }

    const backToChangePassword = (data) => {
        props.changePassword(data);
    }

    const reloadPageToLogin = () => {
        window.location.href = '/login';
    }

    if ((props.passwordRecovery === 1 || props.passwordRecovery === 0) && props.passwordRecoveryError !== null) {
        if (disabledState === true) {
            setDisabledState(false)
        }
    }

    return (
        <>
            <styled.RequestAccount>
                {
                    <Row>
                        <Col className="login-wrapper col-md-6 offset-md-3 d-flex align-items-center justify-content-center">
                            {
                                (props.passwordRecovery === 1 || props.passwordRecovery === 0) &&
                                <styled.LoginForm className='width-85'>
                                    <styled.LoginTitle>Reset your password</styled.LoginTitle>
                                    <PasswordRecoveryForm
                                        email={email}
                                        validated={validated}
                                        disabledState={disabledState}
                                        onChange={onChange}
                                        onSubmit={onSubmit}
                                        backToLogin={backToLogin}
                                        passwordRecovery={props.passwordRecovery}
                                        verified={verified}
                                        showCaptchaError={showCaptchaError}
                                        setShowCaptchaError={setShowCaptchaError}
                                        setVerified={setVerified}
                                        passwordRecoveryError={props.passwordRecoveryError}
                                        emailError={emailError} />
                                </styled.LoginForm>
                            }
                            {
                                props.passwordRecovery === 2 &&
                                <PasswordRecoverySuccess backToChangePassword={backToChangePassword} />
                            }
                            {
                                props.passwordRecovery === 3 &&
                                <styled.LoginForm>
                                    <styled.LoginTitle>Reset your password</styled.LoginTitle>
                                    <ChangePasswordRecoveryForm backToLogin={backToLogin}
                                        submitChangePassword={props.submitChangePassword}
                                        email={email}
                                        changePasswordError={props.changePasswordError}
                                        ressetChangePassword={props.ressetChangePassword}
                                        verified={verified}
                                        showCaptchaError={showCaptchaError}
                                        setShowCaptchaError={setShowCaptchaError}
                                        setVerified={setVerified} />
                                </styled.LoginForm>
                            }
                        </Col>
                    </Row>
                }
            </styled.RequestAccount>
            {
                props.passwordRecovery === 4 &&
                <SuccessModal label={'Your password has been updated.'}
                    label_next={'Please log-in again.'}
                    imageChange={true}
                    handleClick={reloadPageToLogin} />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        passwordRecovery: state.AccountReducer.passwordRecovery,
        changePasswordError: state.AccountReducer.changePasswordError,
        passwordRecoveryError: state.AccountReducer.passwordRecoveryError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendPasswordRecovery: (email) => dispatch(actions.passwordRecovery(email)),
        resetPasswordRecovery: () => dispatch(actions.resetPasswordRecovery()),
        changePassword: () => dispatch(actions.changePassword()),
        submitChangePassword: (data) => dispatch(actions.setChangePassword(data)),
        ressetChangePassword: () => dispatch(actions.resetChangePassword()),
        resetInvalidLoginAttempts: () => dispatch({
            type: ActionTypes.RESET_INVALID_LOGIN_ATTEMPTS
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery)
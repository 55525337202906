import * as ActionTypes from './comment-action-types';


export function addComments(payload, authToken,storyId,sceneId) {
  return {
      type: ActionTypes.ADD_COMMENT,
      payload: payload,
      authToken: authToken,
      storyId:storyId,
      sceneId:sceneId
      
  }
}

export function setCommentsSuccess(payload) {
  return {
      type: ActionTypes.ADD_COMMENT_SUCCESS,
      payload: payload
  }
}

export function setCommentsFail(payload) {
  return {
      type: ActionTypes.ADD_COMMENT_FAIL,
      payload:payload
  }
}
export function updateComments(payload, authToken,storyId,sceneId,commentId,isComment) {
 
  return {
      type: ActionTypes.UPDATE_COMMENT,
      payload: payload,
      authToken: authToken,
      storyId:storyId,
      sceneId:sceneId,
      commentId:commentId,
      isComment:isComment
      
  }
}

export function setUpdateCommentsSuccess(payload) {
  return {
      type: ActionTypes.UPDATE_COMMENT_SUCCESS,
      payload: payload
  }
}

export function setUpdateCommentsFail(payload) {
  return {
      type: ActionTypes.UPDATE_COMMENT_FAIL,
      payload:payload
  }
}

export function hideCommentStatus() {
  return {
      type: ActionTypes.HIDE_COMMENT_STATUS,
     
  }
}


export function getComments(authToken,storyId,sceneId) {
  return {
      type: ActionTypes.GET_COMMENT,
      authToken:authToken,
      storyId:storyId,
      sceneId:sceneId
  }
}

export function getCommentsSuccess(payload) {
  
  return {
      type: ActionTypes.GET_COMMENT_SUCCESS,
      payload: payload
  }
}

export function getCommentsFail() {
  return {
      type: ActionTypes.GET_COMMENT_FAIL
  }
}





import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';

function RouteInfo(props) {

    const [routeInfo, setRouteInfo] = useState({
        promptText: props.routeInfo.promptText,
        synopsis: props.routeInfo.synopsis
    })
    const [saveEnabled, setSaveEnabled] = useState(props.routeInfo.promptText === '' || props.routeInfo.synopsis === '' ? false : true);

    const [showPanel, setShowPanel] = useState('promptText');
    const [validated, setValidated] = useState(false);

    const handleChange = (event) => {
        let newData = {
            ...routeInfo,
            [event.target.name]: event.target.value
        }
        setRouteInfo(newData);
        checkEnabled(newData);
    }

    const checkEnabled = (data) => {
        setSaveEnabled(true);
        if (data.promptText === '' || data.synopsis === '') {
            setSaveEnabled(false);
            setValidated(true);
        }
    }

    const handleClick = (event) => {
        let type = event.target.getAttribute('routeType');
        setShowPanel(type);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            // show error
        } else {
            props.updateRouteInfo(routeInfo);
            props.handleHideRouteInfoModal();
        }

        event.stopPropagation();
        event.preventDefault();
        setValidated(true);
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            dialogClassName="route-info"
            onHide={props.handleHideRouteInfoModal}
            backdrop="static">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <LibraryStyle.routeInfo>
                        <div className="row">
                            <div className="left col-4 ">
                                {
                                    showPanel === 'promptText' &&
                                    <div className="prompt d-flex align-items-center justify-content-center">
                                        <div className="text-wrapper">
                                            <div className="title">Paths unlocked</div>
                                            <div className="text">{routeInfo.promptText}</div>
                                            <button>Ok</button>
                                        </div>
                                    </div>
                                }
                                {
                                    showPanel === 'synopsis' &&
                                    <div className="synopsis d-flex align-items-center">
                                    <div className="text-wrapper">
                                        <div className="title">CHARACTER PATHS</div>
                                        <div className="text">{routeInfo.synopsis}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="right col-8">
                                <div className="title">Info for all character routes</div>
                                <div className="right-route-info">
                                    <Form onSubmit={handleSubmit}
                                        noValidate 
                                        id="route-info" 
                                        validated={validated}>
                                        <div routeType="promptText" className="vertical-line">
                                            <span className={`circle ${showPanel === 'promptText' ? 'active' : ''}`}>1</span>
                                            <div className="form-group mb-0">
                                                <Form.Label onClick={handleClick} 
                                                    routeType="promptText" 
                                                    className="label-title">
                                                        Alternative route prompt text
                                                </Form.Label>
                                                <Form.Control as="textarea" 
                                                    type='textarea' 
                                                    className={`textAreaResize ${showPanel === 'promptText' ? 'active' : ''}`} 
                                                    rows="2"
                                                    required 
                                                    maxLength="80"
                                                    autoComplete="off"
                                                    name="promptText"
                                                    value = {routeInfo.promptText}
                                                    onChange = {handleChange}
                                                    onClick={handleClick} 
                                                    disabled={props.showPanels===false}
                                                    routeType="promptText" />
                                                <Form.Control.Feedback type="invalid">
                                                    Invalid prompt text
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div routeType="synopsis" className="vertical-line">
                                            <span className={`circle ${showPanel === 'synopsis' ? 'active' : ''}`}>2</span>
                                            <div className="form-group mb-0">
                                                <Form.Label onClick={handleClick} 
                                                    routeType="synopsis" 
                                                    className="label-title">
                                                        Alternative route synopsis
                                                </Form.Label>
                                                <Form.Control 
                                                    as="textarea" 
                                                    type='textarea' 
                                                    className={`textAreaResize ${showPanel === 'synopsis' ? 'active' : ''}`}
                                                    rows="2"
                                                    name="synopsis"
                                                    autoCapitalize="off"
                                                    maxLength="64"
                                                    required 
                                                    value = {routeInfo.synopsis}
                                                    onChange = {handleChange}
                                                    disabled={props.showPanels===false}
                                                    onClick={handleClick} 
                                                    routeType="synopsis" />
                                                <Form.Control.Feedback type="invalid">
                                                    Invalid synopsis
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        
                                    </Form>
                                </div>
                               {props.showPanels===true && <div className="modal-button-wrapper justify-content-between">
                                    <Button onClick={props.handleHideRouteInfoModal}
                                        className="btn-snacktool btn-snacktool-golden"
                                        >
                                            Cancel
                                    </Button>
                                    <Button
                                        form="route-info"
                                        type="submit"
                                        className="btn-snacktool btn-snacktool-golden"
                                        disabled = {saveEnabled ? '' : 'disabled'}>
                                            Save
                                    </Button>
                               
                                </div>}
                            </div>
                        </div>
                    </LibraryStyle.routeInfo>
                </Modal.Body>
            </Modal>
    )
}

export default RouteInfo
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
 
 
 

function RevokeCollabrator(props) {
 
   
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
     
      backdrop="static"
    >
      
      <Modal.Body className="mt-5">
       <div className="modal-content-wrapper">
           <div  className="modal-icon">
              <img alt="" className="checkIcon" src="/images/icons/ic_warning_24px.svg"/>
           </div>
           <div  className="modal-text">
              <label className="heading"> By revoking access, this user will no longer be able to edit this story. This action can be undone.</label>
              <label className="sub-heading">
             </label>
            
           </div>
         
       </div>
       <div className="modal-button-wrapper justify-content-between">
       <Button onClick={props.hideRevokeModal} className="btn-snacktool btn-snacktool-golden">Cancel</Button>
          <Button onClick={(event)=>props.handleRevokeAccess(event,props.collaboratorStoryId,props.collaboratorName)} className="btn-snacktool btn-snacktool-golden">Revoke access</Button>
       </div>

      </Modal.Body>
    </Modal>
  );
}
export default RevokeCollabrator;
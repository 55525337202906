import * as ActionTypes from '../actions/action-types';

export const initialState = {
   
}

export const BlockReducer = (state = initialState, action) => {
  
    switch(action.type) {
     
    case ActionTypes.SET_BLOCK_DETAILS:
            return {
              ...state,
              background: action.payload
            }
    case ActionTypes.SET_BOXES:
            return {
              ...state,
              boxes: action.boxes
            }
      default:
        return state
    }
}
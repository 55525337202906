import styled from 'styled-components';
import { colors } from '../../../common/styles/variables';

export const TabLabel = styled.label`
  letter-spacing: 0;
  color: ${colors.Very_dark_grayish_blue};
  opacity: 1;
`;

const addInfoContainer = styled.div`
  .add-info-head-title {
    font-size: 20px;
    color: ${colors.very_dark_gray};
    margin: 30px 0 15px 0;
  }

  .add-info-label {
    font-size: 14px;
    color: ${colors.very_dark_gray};
    font-weight: bold;
  }

  .add-info-note {
    font-size: 12px;
    color: ${colors.gray44};
  }
`;

const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
`;

const submitAssetContent = styled.div`
  width: 100%;
  margin-top: 20%;
  text-align: center;
`;

const successAssetContent = styled.div`
  width: 100%;
  margin-top: 25%;
  text-align: center;
`;

const tabLayout = styled.div`
  display: FLEX;
  width: 100%;
  justify-content: center;
`;

const leftTabLabelLayout = styled.div`
  width: 85%;
  display: flex;
  position: relative;
`;

const rightTabLabelLayout = styled.div`
  width: 45%;
  display: flex;
  position: relative;
  &:last-child {
    width: 10%;
  }
`;

const leftTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rightTabLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const leftTabLine = styled.div`
  width: 93%;
  height: 3px;
  background: #157ffb;
  position: absolute;
  left: 48px;
  top: 15px;
  z-index: 0;
`;

const rightTabLine = styled.div``;

const firstCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #4d4f5c;
  text-align: center;
  margin-top: 5px;
`;

const secondCircleContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  color: #4d4f5c;
  margin-top: 5px;
`;
const listContentPanel = styled.div`
  margin-left: 233px;
  margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  background: ${colors.color_white};
  box-shadow: 0px 0px 10px ${colors.dark_red};
  margin-bottom: 5px;
  height: calc(100vh - 90px);
  .add-info-modal-body {
    padding-right: 56px;
    padding-left: 56px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

const listHeading = styled.div`
  padding: 0px 30px 0px 25px;
  font-size: 24px;
  width: 100%;
  text-align: left;
  letter-spacing: 0;
  color: ${colors.very_dark_gray};
  line-height: 60px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${colors.color_white};

  .filter-button {
    background: ${colors.shades_shade200} 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    text-align: center;
    font: Roboto;
    font-size: 12px;
    letter-spacing: 0.22px;
    color: ${colors.very_pale_yellow};
    width: auto;
    float: right;
    cursor: pointer;
    line-height: 20px;
    border: ${colors.shades_shade200};
  }

  .last-filter-button {
    margin-right: 5px;
  }

  .filter-button-active {
    background-color: ${colors.strong_violet};
    border: ${colors.strong_violet};
  }

  .cancel-create-character-icon {
    color: ${colors.medium_light_gray};
    opacity: 1;
    float: left;
    cursor: pointer;
    border-right: 1px solid ${colors.secondary_gray100};
  }

  .panel-header {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0 -20px;
    .back-arrow {
      border-right: 1px solid #e3e3e3;
      display: flex;
      height: 100%;
      align-items: center;
      height: 60px;
      padding: 0 24px;
      margin-right: 16px;

      cursor:pointer .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
    }
    .story-heading {
      width: 100%;
    }
    .sceneName {
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0;
      color: ${colors.color_black};
      opacity: 1;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const listMainSection = styled.div`
  left: 234px;
  width: 100%;
  opacity: 1;

  .button {
    background: ${colors.color_white};
    border: 2px solid ${colors.primary_green};
    border-radius: 40px;
    opacity: 1;
    color: ${colors.primary_green};
    font-family: Robot;
    font-size: 16px;
    text-align: center;
    margin-right: 20px;
  }

  .button-de-active {
    background: ${colors.shades_shade200};
    border-radius: 40px;
    opacity: 1;
    border: ${colors.shades_shade200};
    padding: 12px 30px 12px 30px;
    text-align: center;
    font: Bold 14px/16px Roboto;
    letter-spacing: 0;
    color: ${colors.color_white};
    opacity: 1;
    margin-right: 20px;
  }

  .content-head-row {
    padding: 40px 15px 25px 15px !important;
    .btn-snacktool {
      margin-right: 15px;
    }
  }

  .right-text {
    text-align: right;
    font-size: 14px/19px;
    letter-spacing: 0;
    color: ${colors.gray44};
    opacity: 0.5;
    padding-top: 6px;
  }
`;

const listTable = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 500px;
  text-align: center;

  .table-head {
    background-color: ${colors.secondary_gray100};
    text-align: center;
    font-size: 11px/14px;
    letter-spacing: 1.1px;
    color: ${colors.scorpion_gray};
    text-transform: uppercase;
    opacity: 1;
    top: 0;
  }

  .table-head-row th {
    border-top: ${colors.secondary_gray100};
    border-bottom: ${colors.secondary_gray100};
    font-size: 14px;
    color: ${colors.scorpion_gray};
    text-transform: uppercase;
    letter-spacing: 1.1px;
    opacity: 1;
    font-weight: normal;
    vertical-align: unset;
    padding: 10px 0;
    margin: 0;
  }

  input[type='checkbox'] {
    display: none;
  }

  /* The checkboxContainer */
  .checkboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: ${colors.color_white};
    border: 2px solid ${colors.shades_shade200};
    border-radius: 4px;
  }

  span.disable {
    background: ${colors.shades_shade200};
    border: ${colors.shades_shade200};
  }

  /* On mouse-over, add a grey background color */
  .checkmark .disable:hover ~ .checkmark {
    background-color: ${colors.shades_shade200} !important;
  }
  .enabled:hover {
    border: 2px solid ${colors.light_blue};
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: ${colors.vivid_blue};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .table-head-col {
    width: 4%;
  }

  .table-body-row td {
    font-size: 14px;
    letter-spacing: 0;
    color: ${colors.very_dark_gray};
    opacity: 1;
    font-weight: normal;
    vertical-align: unset;
    padding: 10px 0;
    margin: 0;
  }

  .shared-asset-active {
    color: ${colors.gray44};
  }

  .shared-asset-in-active {
    color: ${colors.shades_shade200};
  }

  .badges {
    background-color: ${colors.shades_shade200};
    border-radius: 46px;
    opacity: 1;
    text-align: center;
    font: Regular 12px/14px Roboto;
    letter-spacing: 0.22px;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
  }

  .text {
    color: ${colors.medium_light_gray} !important;
    opacity: 1;
    font-size: 15px;
    text-align: center;
    font-size: 10px;
  }

  .action-icons {
    font-size: 20px !important;
    padding-right: 15px;
  }

  .action-icon-active {
    color: ${colors.strong_violet};
  }

  .action-icon-in-active {
    color: ${colors.gray44};
  }

  .asset-name-col {
    width: 30%;
    text-align: left;
  }

  .variations-action-icons {
    height: 16px;
    width: 16px;
  }
`;

const libraryMenu = styled.div`
  display: flex;
  width: 232px;
  float: left;
  margin-top: 60px;
`;

const libraryMenuList = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0px;
`;

const libraryMenuListItem = styled.li`
  height: 53px;
  line-height: 53px;
  v-align: center;
  background-color: ${props => (props.selectedMenu === true ? '#E6E4E4' : '')};
  padding-left: 15px;
  ${props =>
    props.selectedMenu === true
      ? `border-right: 5px solid ${colors.strong_violet}`
      : null}

  .menu-icon {
    padding-right: 15px;
  }

  .menu-disabled {
    color: ${colors.gray44} !important;
    text-decoration: none !important;
    img {
      height: 24px;
    }
  }

  .menu-enabled {
    color: ${colors.strong_violet} !important;
    text-decoration: none !important;
    img {
      height: 24px;
    }
  }
`;

const characterContent = styled.div`
  .content-head {
    height: 65px;
    v-align: center;
    line-height: 65px !important;
    border-bottom: 1px solid ${colors.secondary_gray100};
    font-size: 15px;
  }

  .btn-primary.disabled {
    background-color: ${colors.shades_shade200} !important;
    border: ${colors.shades_shade200} !important;
  }

  .col-title {
    v-align: center;
    font: 16px/20px;
    font-weight: bold;
  }

  .step-three-col-title {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .menu-nav {
    text-decoration: none;
    padding-right: 20px;
  }

  .search-text-field {
    float: left;
    margin-right: 11px !important;
    padding: 10px !important;
    width: 250px !important;
    height: 40px !important;
    background: ${colors.color_white};
    border: 2px solid ${colors.light_grey};
    border-radius: 4px;
  }

  .search-text-field::placeholder {
    font-size: 13px;
    color: ${colors.shades_shade200};
  }

  .row-count {
    height: 80px;
    v-align: center;
    line-height: 80px !important;
    font-size: 14px;
    letter-spacing: 0;
    color: ${colors.gray44};
  }

  .list-head-row {
    height: 40px;
    line-height: 40px !important;
    border-bottom: 1px solid ${colors.light_grey};
    opacity: 1 !important;
  }

  .list-col {
    font-size: 14px;
    color: ${colors.scorpion_gray};
    letter-spacing: 1.1px;
  }

  .file-name {
    color: ${colors.very_dark_gray};
  }

  .file-info {
    text-align: right;
    font-size: 10px;
    letter-spacing: 0;
    color: ${colors.shades_shade200};
  }

  .list-asset-table {
    height: 650px;
    overflow-x: auto;
  }

  .fixed-row {
    position: sticky;
    top: 0;
    background: ${colors.secondary_gray100};
  }

  .finish-button {
    font-size: 15px;
  }

  .info-label {
    font-size: 11px;
  }
`;

const characterComposer = styled.div`
    width: 350px;
    text-align: center;

    .character-composer-block {
        text-align: center !important;
        background-color: ${colors.secondary_gray100} !important;
    }

    .character-composer-frame {
        height: 295px;
        width: 295px;
        margin-left: 28px;
        border: 0.5px solid black;
        position: relative;
    }

    .empty-row {
        height: 20px;
    }

    .frame-image {
        position: absolute;
        left: 0;
    }

    .sprite-row{
        padding: 12px  12px  12px 0px;
        cursor:pointer
        display:flex;
        justify-content:space-between;
        align-items: center;
        .asset-details{
            display: flex;
            align-items: center;
            .fa-bars{
                color:#ACACAC;
                font-size:13px;
            }
            .asset-name{
                margin-left:16px;
                color:#303030;
                font-family:PT Sans;
                font-size:14px
            }
        }
        .sprite-row-name{
            color:#303030;
            font-size:13px;
        }
    }

    .btn-save-variation {
        position: relative;
        bottom: 21px;
        left: 15px;
    }
`;

const spriteLayer = styled.div`
  .enabled {
    opacity: 1;
  }
  .disabled {
    opacity: 0.15;
  }
  .bars {
    color: ${colors.medium_light_gray};
  }
  .sprite-layer-text {
    font-size: 11px;
    text-transform: uppercase;
    color: #606060;
    padding-bottom: 10px;
  }
  .sprite-row-name {
    font-size: 14px;
  }
  .sprite-layers-rows {
    height: 235px;
    overflow-y: auto;
  }
`;

const listPanel = styled.div`
  background: #fff;
  opacity: 1;
  display: inline-block;
  height: calc(100vh - 160px);
  width: 100%;
  overflow: auto;
  padding: 25px;
  border-top: 1px solid ${colors.secondary_gray100};
`;
const listHeader = styled.div`
  padding: 15px 0 40px 0 !important;
`;
const listContainer = styled.div`
.table-title{
    font-family:PT Sans;
    font-weight:bold;
    font-size:16px
    color: ##303030;
    margin-bottom:16px;
}
`;
const tableContainer = styled.table`
    width: 100%;
    .checkbox-box{
        padding:10px;
        .checkboxContainer{
            margin:0;
        }
    }
    .text-ellipse{
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        width:170px;
        padding-left: 10px;
    }
    .imageWarning {
        vertical-align: text-top;
    }
    .no-result {
        text-align: center;
        font: 14px/19px PT Sans;
        letter-spacing: 0;
        color: #707070;
        opacity: 1;
    }
    .icon-box{

    }
    .show {
        display: block;
    }
    .hide {
        display: none;
    }
    thead{
        background: #F5F5FA 0% 0% no-repeat padding-box;
        border-radius: 4px 4px 0px 0px;
        
        th{
            text-align: center;
            font-family:PT Sans;
            font-size:11px
            color: #606060;
            text-transform: uppercase;
            padding: 12px;
            font-weight:normal;
            .fa{
                vertical-align: top;
                color:#707070;
                margin-left:6px;
                font-size:12px;
            }
        }
        .story-title{
            font-family: PT Sans;
            font-size: 11px;
            color: #606060;
            text-transform: uppercase;
            padding: 12px;
            font-weight: normal;
            text-align: left;
            padding-left: 33px;
        }
       

    }

    tbody{
        tr{
            border-bottom:1px solid #D6D6D6;
            td{
                text-align: center;
                font-family:PT Sans;
                font-size:14px
                letter-spacing: 0;
                color: #707070;
                padding:15px 0;
                .fa-angle-down{
                    color:#707070;
                    font-size:20px;
                    cursor:pointer;
                }
                .create-details{
                    font-size:10px;
                    color:#BFBFBF;
                    span{
                        line-height:10px;
                        margin-top:4px;
                    }
                }
                .fa-users{
                    font-size:14px;
                    color:#707070;
                    margin-left: 5px;
                }
                &.p-l-20{
                    padding-left:20px;
                }
                .status{
                    background:#DFDFDF;
                    width: fit-content;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color:rgba(0, 0, 0, 0.87)
                    font-family:Roboto;
                    font-size:12px
                    padding:5px 10px 5px 7px;
                    border-radius: 46px;
                    .circle{
                        width:10px;
                        height:10px;
                        border-radius:50%;
                        margin-right: 6px;
                      
                    }
                    &.yellow{
                        .circle{
                            background:#EDA831;
                        }
                    }
                    &.blue{
                        .circle{
                            background:#157FFB;
                        }
                    }
                    label{
                        margin-bottom:0;
                    }
                }
                .action-dropdown{
                    font-family:Roboto;
                    font-size:14px
                    // color:#45C7AD;
      color: #E6A554 !important;

                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    cursor:pointer;
                    background:none !important;
                    border:0!important;
                    &:focus{
                        color:#45C7AD;
                        outline:0 !important;
                        box-shadow:none !important;
                    }
                    .fa-chevron-down{
                        font-size:12px;
                        margin-left:6px;
                    }
                }
            
            }
            .text-bold-dark{
                color:#303030;
                font-weight: bold;
            }
            .text-small-light{
                color:#ACACAC;
                font-size:12px;
            }
            .disabled{
                color:#BFBFBF !important;
            }
            .actions{
                img{
                    margin: 0 5px;
                }
            }
            .shared-asset-active {
                color: ${colors.gray44} !important;
            }
        
            .shared-asset-in-active {
                color: ${colors.shades_shade200} !important;
            }
        }
        .exanded-row-wrapper{
            .exanded-row-block{
                display: flex;
                align-items: flex-start;
                .choose-expression-block{
                    position:relative;
                    display: flex;
                    flex-direction: column;
                    width: 150px;
                    border: 1px solid #707070;
                    background:#F5F5FA;
                    color:#707070;
                    padding: 0 25px;
                    justify-content: center;
                    height: 150px;
                    font-size:12px;
                    padding-bottom: 2px;
                    .fa-eye{
                        font-size: 23px;
                        color: #BFBFBF;
                    }
                }
                .list-preview-img {
                    position: absolute;
                    left: 0;
                }
                .select-block{
                    display: flex;
                    margin-left:60px;
                    width: 50%;
                    .custom-select-dropdown{
                        margin-right:30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                        select{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;

export const AdminStyle = {
  addInfoContainer,
  addAssetMessageBox,
  submitAssetContent,
  successAssetContent,
  tabLayout,
  leftTabLabelLayout,
  rightTabLabelLayout,
  leftTabLabelContent,
  rightTabLabelContent,
  leftTabLine,
  rightTabLine,
  firstCircleContent,
  secondCircleContent,
  listContentPanel,
  listHeading,
  listMainSection,
  listTable,
  libraryMenu,
  libraryMenuList,
  libraryMenuListItem,
  characterContent,
  characterComposer,
  spriteLayer,

  listPanel,
  listHeader,
  listContainer,
  tableContainer
};

import * as Constant from './../../common/constants';
import * as Config from './../../config/aspectRatio.config';
import { characterHelper } from '../../common/helper/character-helper';

//Command Panel Uniqueness
const getFilterArray = components => {
  let updateTotalFormInput = [];
  let uniquefilterArray = [];
  let updateIndexInput = 0;

  components.map(comp =>
    comp.widgets.map(widgetsData => {
      {
        updateTotalFormInput[updateIndexInput] = widgetsData.widgetName;
        uniquefilterArray = updateTotalFormInput.filter(function(
          widgetItem,
          pos
        ) {
          return updateTotalFormInput.indexOf(widgetItem) == pos;
        });
        updateIndexInput++;
      }
    })
  );

  return [uniquefilterArray, updateTotalFormInput];
};

const saveCommand = (
  setTotalFormInput,
  commandObj,
  commandData,
  commandId,
  color,
  form,
  cursorPosition,
  isCommandEdit,
  syntax,
  characterList
) => {
  switch (commandId) {
    case Constant.COMMAND_ADD_BLOCK:
      const commandEmpty = {
        commandId: null,
        cmdText: '',
        blockCommand: false,
        deleted: false,
        modified: false
      };
      commandObj.commandId = commandId ? commandId : null;
      commandObj.cmdText = createCommandSyntax(
        null,
        setTotalFormInput,
        form,
        commandId,
        syntax,
        []
      );
      commandObj.color = color;
      commandObj.blockCommand = true;

      if (cursorPosition >= 0) {
        if (commandData.commands.length > 0) {
          if (
            commandData.commands[0].cmdText.trim() === '' &&
            (commandData.commands.length === 1 || cursorPosition === 0) &&
            !isCommandEdit
          ) {
            commandData.commands[0] = commandObj;
          } else {
            if (isCommandEdit) {
              commandData.commands.splice(cursorPosition, 1, commandObj);
            } else {
              // commandData.commands.splice(cursorPosition, 0, commandEmpty);
              commandData.commands.splice(cursorPosition + 1, 0, commandObj);
            }
          }
        } else {
          if (isCommandEdit) {
            commandData.commands.splice(cursorPosition, 1, commandObj);
          } else {
            commandData.commands.splice(cursorPosition, 0, commandObj);
          }
        }
      } else {
        if (commandData.commands.length > 0) {
          commandData.commands.push(commandEmpty);
        }
        commandData.commands.push(commandObj);
      }
      break;
    default:
      commandObj={};
      commandObj.commandId = commandId ? commandId : null;
      commandObj.cmdText = createCommandSyntax(
        syntax,
        setTotalFormInput,
        form,
        commandId,
        characterList
      );
      commandObj.color = color;
      commandObj.blockCommand = false;
      if (cursorPosition >= 0) {
        if (isCommandEdit) {
          commandData.commands.splice(cursorPosition, 1, commandObj);
        } else {
          if (
            commandData.commands.length > 0 &&
            commandData.commands[0].cmdText.trim() === '' &&
            (commandData.commands.length === 1 || cursorPosition === 0) &&
            !isCommandEdit
          ) {
            commandData.commands[0] = commandObj;
          } else if (
            commandData.commands[cursorPosition] &&
            commandData.commands[cursorPosition].cmdText.trim() === ''
          ) {
            // commandData.commands.splice(cursorPosition, 0, commandObj);
            commandData.commands[cursorPosition] = commandObj;
          } else {
            commandData.commands.splice(cursorPosition + 1, 0, commandObj);
          }
        }
      } else {
        commandData.commands.push(commandObj);
      }
      break;
  }
  return commandData;
};

const getCommandSuffix = commandId => {
  let suffixStr = '';
  switch (commandId) {
    case Constant.COMMAND_ADD_BLOCK:
      suffixStr = 'block';
      break;

    case Constant.COMMAND_GO_TO_BLOCK:
      suffixStr = 'goto';
      break;

    case Constant.COMMAND_ENTER_THE_SCENE:
      suffixStr = 'place';
      break;

    case Constant.COMMAND_REPLACE_CHARACTER:
      suffixStr = 'replace';
      break;

    case Constant.COMMAND_PAID_ADD_DECISION:
      suffixStr = 'choice';
      break;

    case Constant.COMMAND_CHOICE_TIMER:
      suffixStr = 'choicetimer';
      break;

    case Constant.COMMAND_LEAVES_THE_SCENE:
      suffixStr = 'hide';
      break;

    case Constant.COMMAND_CHANGE_EXPRESSION:
      suffixStr = 'show';
      break;

    case Constant.COMMAND_PLAY_MUSIC:
      suffixStr = 'music';
      break;

    case Constant.COMMAND_PLAY_SOUND:
      suffixStr = 'sfx';
      break;

    case Constant.COMMAND_STOP_MUSIC:
      suffixStr = 'stopmusic';
      break;

    case Constant.COMMAND_SET_AUDIO:
      suffixStr = 'volume';
      break;

    case Constant.COMMAND_TIME_DELAY:
      suffixStr = 'wait';
      break;

    case Constant.COMMAND_SHOW_OBJECT:
      suffixStr = 'showobj';
      break;

    case Constant.COMMAND_HIDE_OBJECT:
      suffixStr = 'hideobj';
      break;

    case Constant.COMMAND_TAP_OBJECT:
      suffixStr = 'tap';
      break;

    case Constant.COMMAND_SHAKE_CAMERA:
      suffixStr = 'shake';
      break;

    case Constant.COMMAND_SHOW_NAME_DIALOGUE:
      suffixStr = 'shownamedialog';
      break;
    case Constant.COMMAND_END_JUICY_BLOCK:
        suffixStr = 'endjuicyblock';
        break;
    case Constant.COMMAND_SET_VAR:
      suffixStr = 'setvar';
      break;

    case Constant.COMMAND_ADD_IF:
      suffixStr = 'if';
      break;

    case Constant.COMMAND_ADD_ELSE:
      suffixStr = 'else';
      break;

    case Constant.COMMAND_ADD_ELSE_IF:
      suffixStr = 'elseIf';
      break;

    case Constant.COMMAND_ADD_END:
      suffixStr = 'end';
      break;

    case Constant.COMMAND_GOTO_CHAPTER:
      suffixStr = 'endchapter';
      break;

    case Constant.COMMAND_SAY_GENDERED:
      suffixStr = 'saygendered';
      break;

    case Constant.COMMAND_SET_OUTFIT:
      suffixStr = 'setoutfit';
      break;

    case Constant.COMMAND_SHOW_OUTFIT:
      suffixStr = 'oshow';
      break;

    case Constant.COMMAND_REPLACE_OUTFIT:
      suffixStr = 'oreplace';
      break;

    default:
      break;
  }

  return suffixStr + ' ';
};

const fetchReplaceDefaultCharacter = (characterList, characterId, flagExp) => {
  let defaultCharacterValues = '';
  const selectedCharacter = characterHelper.fetchCharacterByIdAllData(
    characterList,
    characterId
  );
  if (selectedCharacter) {
    if (!flagExp) {
      const filterExpression = selectedCharacter.expressions.findIndex(
        expressions => expressions.defaultAsset === true
      );
      defaultCharacterValues = defaultCharacterValues.concat(
        `${selectedCharacter.expressions[filterExpression].assetName} `
      );
    }
    const filterOutFits = selectedCharacter.outfits.findIndex(
      outfits => outfits.defaultAsset === true
    );
    defaultCharacterValues = defaultCharacterValues.concat(
      `${selectedCharacter.outfits[filterOutFits].assetName} `
    );

    const filterHairs = selectedCharacter.hairs.findIndex(
      hairs => hairs.defaultAsset === true
    );
    defaultCharacterValues = defaultCharacterValues.concat(
      `${selectedCharacter.hairs[filterHairs].assetName} `
    );

    const filterAcc = selectedCharacter.accessories.findIndex(
      accessories => accessories.defaultAsset === true
    );
    defaultCharacterValues = defaultCharacterValues.concat(
      `${selectedCharacter.accessories[filterAcc].assetName}`
    );
  }
  return defaultCharacterValues;
};

const createCommandSyntax = (
  syntax,
  setTotalFormInput,
  form,
  commandId,
  characterList = []
) => {
  let suffixStr = getCommandSuffix(commandId);
  let finalString = suffixStr;
  let formLabel = '';
  let replaceString = '';
  let setVarElements = '';
  let variableCheck = false;
  let stringLengthVar = 1;
  let valueSetvar = '';
  let characterId = '';
  if (commandId === Constant.COMMAND_SHAKE_CAMERA) {
    finalString = syntax;
  }
  setTotalFormInput.map(commandPanelFormSubmitData => {
    if (
      form[commandPanelFormSubmitData] !== undefined &&
      form[commandPanelFormSubmitData].name !== 'toggleCharacter' &&
      form[commandPanelFormSubmitData].type !== 'button'
    ) {
      formLabel = form[commandPanelFormSubmitData].getAttribute('label');
      if ('select-one' === form[commandPanelFormSubmitData].type) {
        const selectedIndexCheck =
          form[commandPanelFormSubmitData].selectedIndex;
        const valueText =
          form[commandPanelFormSubmitData][selectedIndexCheck].text;
        if (
          'characterDropdown' === commandPanelFormSubmitData ||
          'newCharacterDropdown' === commandPanelFormSubmitData
        ) {
          characterId = form[commandPanelFormSubmitData].value;
        }
        if (commandId === Constant.COMMAND_ADD_DIALOGUE) {
          finalString =
            valueText === '<Game Narration>'
              ? finalString.concat('_: ')
              : finalString.concat(valueText + ': ');
        } else if (commandId === Constant.COMMAND_SAY_GENDERED) {
          finalString =
            valueText === '<Game Narration>'
              ? finalString.concat('_ ')
              : finalString.concat(valueText + ' ');
        } else if (Constant.COMMAND_SHAKE_CAMERA === commandId) {
          finalString = finalString.concat('');
        } else if (
          Constant.COMMAND_SET_VAR === commandId &&
          commandPanelFormSubmitData === 'variableDropdownSelect'
        ) {
          if (valueText) {
            variableCheck = true;
            finalString = finalString.concat(valueText + ' ');
          }
        } else if (
          valueText === 'Select one' &&
          commandPanelFormSubmitData === 'optCurrencyDropdown'
        ) {
          finalString = finalString.concat('0 ');
        } else if (commandPanelFormSubmitData === 'chapterNameDropdown') {
          if (valueText === 'Select one') {
            finalString = finalString.concat('');
          } else {
            finalString = finalString.concat(`"${valueText}"`);
          }
        } else if (valueText === 'Select one') {
          finalString = finalString.concat('');
        } else {
          finalString = finalString.concat(valueText + ' ');
        }
      } else {
        let value = form[commandPanelFormSubmitData].value;
        if (Constant.COMMAND_ENTER_THE_SCENE === commandId) {
          value =
            form[commandPanelFormSubmitData].value == 'true'
              ? 'flip'
              : form[commandPanelFormSubmitData].value == 'false'
              ? 'no-flip'
              : value;
          finalString = finalString.concat(value + ' ');
        } else if (Constant.COMMAND_SET_VAR === commandId) {
          switch (commandPanelFormSubmitData) {
            case 'opCrossCheckbox':
              value =
                form[commandPanelFormSubmitData].value == 'true'
                  ? 'Cross-chapter'
                  : 'Private';
              finalString = finalString.concat(value + ' ');
              break;

            case 'nameTextBox':
              value = form[commandPanelFormSubmitData].value;
              finalString = finalString.concat(value + ' ');
              break;

            case 'opCheckbox':
              value = '';
              finalString = finalString.concat(value + ' ');
              break;

            case 'valueTextBox':
            case 'booleanCheckbox':
              stringLengthVar = value.split(' ').length;
              valueSetvar = form[commandPanelFormSubmitData].value;
              finalString = finalString.concat(valueSetvar + ' ');
              break;
          }
        } else if (
          Constant.COMMAND_PAID_ADD_DECISION === commandId &&
          formLabel != 'Button Label'
        ) {
          if (
            commandPanelFormSubmitData !== 'optDropdown' &&
            commandPanelFormSubmitData !== 'optCurrencyDropdown' &&
            commandPanelFormSubmitData !== 'interactableCheckbox' &&
            commandPanelFormSubmitData !== 'hideIfVisitedCheckbox'
          ) {
            //value = `"${value}"`;
            value = '`' + value + '`';
          } else if (
            commandPanelFormSubmitData === 'optCurrencyDropdown' &&
            value === ''
          ) {
            value = '0';
          } else if (commandPanelFormSubmitData === 'interactableCheckbox') {
            if (form[commandPanelFormSubmitData].value == 'true') {
              value = 'interactable';
            } else {
              value = 'non-interactable';
            }
          } else if (commandPanelFormSubmitData === 'hideIfVisitedCheckbox') {
            if (form[commandPanelFormSubmitData].value == 'true') {
              value = 'hideifvisited';
            } else {
              value = 'showifvisited';
            }
          }
          finalString = finalString.concat(value + ' ');
        } else if (Constant.COMMAND_PLAY_MUSIC === commandId) {
          value =
            form[commandPanelFormSubmitData].value == 'true'
              ? 'loop'
              : form[commandPanelFormSubmitData].value == 'false'
              ? 'noloop'
              : value;
          if (form[commandPanelFormSubmitData].type === 'number') {
            finalString = finalString.concat(` ${value}`);
          } else {
            finalString = finalString.concat(`${value}`);
          }
        } else if (Constant.COMMAND_STOP_MUSIC === commandId) {
          finalString = finalString.concat(`${value}`);
        } else if (Constant.COMMAND_SHAKE_CAMERA === commandId) {
          finalString = finalString.replace(
            `{${commandPanelFormSubmitData}}`,
            value
          );
        } else if (Constant.COMMAND_SAY_GENDERED === commandId) {
          finalString = finalString.concat(`\`${value}\` `);
        } else {
          finalString = finalString.concat(value + ' ');
        }
      }
    }
  });

  switch (commandId) {
    case Constant.COMMAND_ENTER_THE_SCENE:
      const defaultParamEnter = fetchReplaceDefaultCharacter(
        characterList,
        characterId,
        false
      );
      let arrayCharacterStringParam = finalString.trim().split(' ');
      if (
        arrayCharacterStringParam[arrayCharacterStringParam.length - 2] ===
          'None' &&
        arrayCharacterStringParam[arrayCharacterStringParam.length - 1] == '0'
      ) {
        // replaceString = ` 0`;
        arrayCharacterStringParam.pop();
        finalString = arrayCharacterStringParam.join(' ');
        replaceString = '';
      }
      if (
        arrayCharacterStringParam[arrayCharacterStringParam.length - 1] ===
        'None'
      ) {
        replaceString = `${defaultParamEnter} Middle no-flip 0.3 None`;
      } else {
        replaceString = '';
      }
      break;

    case Constant.COMMAND_CHANGE_EXPRESSION:
      const defaultParamChangeExp = fetchReplaceDefaultCharacter(
        characterList,
        characterId,
        true
      );
      replaceString = `${defaultParamChangeExp} 0.3`;
      break;

    case Constant.COMMAND_REPLACE_CHARACTER:
      const defaultParamReplace = fetchReplaceDefaultCharacter(
        characterList,
        characterId,
        false
      );
      replaceString = `${defaultParamReplace} 0.3`;
      break;

    case Constant.COMMAND_SHOW_OUTFIT:
      replaceString = `0.3`;
      break;

    case Constant.COMMAND_REPLACE_OUTFIT:
      replaceString = `0.3`;
      break;

    case Constant.COMMAND_LEAVES_THE_SCENE:
      replaceString = 'Fade-out 0.3';
      break;

    case Constant.COMMAND_PLAY_MUSIC:
      //replaceString = 'loop';
      let playMusicCommandCheck = finalString.split(' ');
      if (playMusicCommandCheck[playMusicCommandCheck.length - 1] === '0') {
        replaceString = 'loop 1';
      }
      break;

    case Constant.COMMAND_PAID_ADD_DECISION:
      finalString = finalString.trim();
      const choiceCommand = finalString.split(' ');
      if (choiceCommand[choiceCommand.length - 1] === 'interactable') {
        replaceString = '0 interactable';
      }
      break;

    case Constant.COMMAND_STOP_MUSIC:
      let stopMusicCommandCheck = finalString.split(' ');
      if (stopMusicCommandCheck[stopMusicCommandCheck.length - 1] === '0') {
        replaceString = '0';
      }
      break;

    case Constant.COMMAND_SHOW_OBJECT:
    case Constant.COMMAND_HIDE_OBJECT:
      replaceString = ' 0.5';
      break;

    case Constant.COMMAND_SET_AUDIO:
      finalString = finalString.trim();
      let wordsCheck = finalString.split(' ');

      if (wordsCheck[wordsCheck.length - 1] == 1 && wordsCheck.length >= 3) {
        wordsCheck.pop();
        finalString = wordsCheck.join(' ');
      }
      break;

    case Constant.COMMAND_SET_VAR:
      const finalStringChecking = finalString.trim();
      setVarElements = finalStringChecking.split(' ');
      if (variableCheck === false) {
        setVarElements[5] = `"${valueSetvar}"`;
      }
      finalString = `${setVarElements[0]} ${setVarElements[1]} ${
        setVarElements[3]
      } ${setVarElements[5]} ${setVarElements[2]} ${
        setVarElements[5 + stringLengthVar]
      }`;
      finalString = finalString.trim();
      replaceString = 'Boolean Cross-chapter';
      break;

    case Constant.COMMAND_ADD_IF:
    case Constant.COMMAND_ADD_ELSE_IF:
      setVarElements = finalString.trim().split(' ');
      finalString = `${setVarElements[0]} ${setVarElements[1]} ${
        setVarElements[2]
      } "${setVarElements[3]}"`;
      break;

    case Constant.COMMAND_GOTO_CHAPTER:
      setVarElements = finalString.trim().split(' ');
      if (setVarElements[1] == 'true') {
        finalString = `${setVarElements[0]} ${setVarElements[1]}`;
      }

    default:
      break;
  }

  finalString = finalString
    .replace(new RegExp('\\b' + replaceString + '\\b'), '')
    .trim();

  return finalString.trim();
};

// Useful in Place View Object
const checkDuplicateName = (views, name, editId = null) => {
  let exist = views.filter(view => {
    if (
      (editId == null && view.name.toLowerCase() === name.toLowerCase()) ||
      (editId != null &&
        editId != view.id &&
        view.name.toLowerCase() === name.toLowerCase())
    ) {
      return view;
    }
  });

  if (exist.length > 0) {
    return true;
  }
  return false;
};

const getView = (views, id) => {
  let returnView = views.find(view => view.id === parseInt(id));
  return returnView;
};

const searchText = (views, searchText) => {
  let searchView = views.filter(view => {
    if (view.name.toLowerCase().includes(searchText.toLowerCase())) {
      return view;
    }
  });
  return searchView;
};

function resizeImage(
  url,
  viewWidth,
  viewHeight,
  x,
  y,
  callback,
  id,
  staticView = true
) {
  var imageObj = new Image();
  imageObj.crossOrigin = 'anonymous';

  imageObj.onload = function() {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    let width =
      Config.aspectRatioParameters[Config.aspectRatioThreeByFour]
        .objectViewWidth;
    let height =
      Config.aspectRatioParameters[Config.aspectRatioThreeByFour]
        .objectViewHeight;
    if (staticView === true) {
      width =
        Config.aspectRatioParameters[Config.aspectRatioThreeByFour]
          .staticPreviewWidth;
      height =
        Config.aspectRatioParameters[Config.aspectRatioThreeByFour]
          .staticPreviewHeight;
    }

    canvas.height = height;
    canvas.width = width;
    try {
      context.drawImage(
        imageObj,
        x,
        y,
        viewWidth,
        viewHeight,
        0,
        0,
        width,
        height
      );
    } catch (e) {}

    callback(canvas.toDataURL(), id);
  };

  imageObj.src = url;
}

const getImagePath = (assetList, assetId) => {
  let asset = assetList.filter(item => item.id == assetId);
  return asset[0];
};

const setRouteCharacterLists = (charList, routData, isRouteEdit, charId) => {
  let listdata = charList ? charList : [];
  for (let i = 0; i < routData.length; i++) {
    for (let j = 0; j < listdata.length; j++) {
      if (listdata[j] && listdata[j].id === routData[i].characterId) {
        if (isRouteEdit === true) {
          if (routData[i].characterId !== charId) {
            listdata.splice(j, 1);
            break;
          }
        } else {
          listdata.splice(j, 1);
          break;
        }
      }
    }
  }

  return listdata;
};

const getrouteFormData = (route, files, isRouteEdit) => {
  var formData = new FormData();
  if (files === null) {
    if (isRouteEdit) {
      route.removeImage = true;
    } else {
      route.removeImage = false;
    }
  } else if (typeof files[0] !== 'string') {
    if (isRouteEdit) {
      route.removeImage = true;
    } else {
      route.removeImage = false;
    }
    formData.append('file', files[0]);
  }

  if (isRouteEdit) {
    delete route.imagePath;
  }

  formData.append('characterRoute', JSON.stringify(route));
  return formData;
};

const getCastFormData = (route, files, isRouteEdit) => {
  var formData = new FormData();
  if (files === null) {
    if (isRouteEdit) {
      route.removeImage = true;
    } else {
      route.removeImage = false;
    }
  } else if (typeof files[0] !== 'string') {
    if (isRouteEdit) {
      route.removeImage = true;
    } else {
      route.removeImage = false;
    }
    formData.append('file', files[0]);
  }

  if (isRouteEdit) {
    delete route.imagePath;
  }

  formData.append('castinfo', JSON.stringify(route));
  return formData;
};

const getChapterData = (routeList, chapterId) => {
  if (chapterId === 0) {
    return null;
  }

  let chapterExists = null;

  routeList.forEach(route => {
    route.scenes.map(chapter => {
      if (chapter.id === chapterId) {
        chapterExists = chapter;
      }
    });
  });

  if (chapterExists !== null) {
    return chapterExists;
  }

  return null;
};

const prepareViewName = name => {
  if (name.length > 17) {
    return name.substring(0, 17) + '...';
  }

  return name;
};

const getObjectByView = (view, objectAssetId) => {
  return view.viewObjects.filter(object => object.asset.id == objectAssetId);
};

export const StoryService = {
  saveCommand,
  getFilterArray,
  checkDuplicateName,
  getView,
  searchText,
  resizeImage,
  getImagePath,
  getrouteFormData,
  getChapterData,
  setRouteCharacterLists,
  prepareViewName,
  getObjectByView,
  getCastFormData
};

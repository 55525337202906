import React from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import './../style/style.css';

import AddInfo from './AddInfo';
import AddTags from './AddTags';
import * as translations from './translations';
import { LibraryStyle } from './../style/style';
import SubmitAsset from './SubmitAsset';
import Success from './Success';
import * as Constant from './constant';
import Fail from './Fail';
import Cancel from './Cancel';
import AddFiles from './AddFiles';

function AddAssetTabs(props) {
  let secondTabClass =
    props.activeTabKey === Constant.SECOND_TAB_KEY
      ? 'circle-fill inactivelinks'
      : 'circle-empty inactivelinks';
  let thirdTabClass =
    props.activeTabKey === Constant.THIRD_TAB_KEY
      ? 'circle-fill inactivelinks'
      : 'circle-empty inactivelinks';
  return (
    <LibraryStyle.addInfoContainer>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={Constant.FIRST_TAB_KEY}
        activeKey={props.activeTabKey}
        onSelect={k => props.handleActiveKey(k)}
      >
        {(props.activeTabKey === Constant.FIRST_TAB_KEY ||
          props.activeTabKey === Constant.SECOND_TAB_KEY ||
          props.activeTabKey === Constant.THIRD_TAB_KEY) && (
          <Row>
            <Col>
              <LibraryStyle.tabLayout>
                <LibraryStyle.leftTabLabelLayout>
                  <LibraryStyle.leftTabLabelContent>
                    <Nav.Link
                      className="inactivelinks circle-fill"
                      eventKey={Constant.FIRST_TAB_KEY}
                    >
                      {props.firstTabLabel === '' && 1}
                      {props.firstTabLabel === 'icon' && (
                        <i className="fa fa-check check-font" />
                      )}
                    </Nav.Link>
                    <LibraryStyle.firstCircleContent>
                      {translations.GENERAL_INFO}
                    </LibraryStyle.firstCircleContent>
                  </LibraryStyle.leftTabLabelContent>
                  <LibraryStyle.leftTabLine></LibraryStyle.leftTabLine>
                </LibraryStyle.leftTabLabelLayout>
                <LibraryStyle.rightTabLabelLayout>
                  <LibraryStyle.rightTabLabelContent>
                    <Nav.Link
                      className={
                        props.secondTabLabel === 'icon'
                          ? 'circle-fill inactivelinks'
                          : secondTabClass
                      }
                      eventKey={Constant.SECOND_TAB_KEY}
                    >
                      {props.secondTabLabel === '' && 2}
                      {props.secondTabLabel === 'icon' && (
                        <i className="fa fa-check check-font" />
                      )}
                    </Nav.Link>
                    <LibraryStyle.secondCircleContent>
                      {translations.ADDITIONAL_INFO}
                    </LibraryStyle.secondCircleContent>
                  </LibraryStyle.rightTabLabelContent>
                  <LibraryStyle.leftTabLine></LibraryStyle.leftTabLine>
                </LibraryStyle.rightTabLabelLayout>
                <LibraryStyle.rightTabLabelLayout>
                  <LibraryStyle.rightTabLabelContent>
                    <Nav.Link
                      className={thirdTabClass}
                      eventKey={Constant.THIRD_TAB_KEY}
                    >
                      3
                    </Nav.Link>
                    <LibraryStyle.secondCircleContent>
                      {translations.UPLOAD_FILE}
                    </LibraryStyle.secondCircleContent>
                  </LibraryStyle.rightTabLabelContent>
                </LibraryStyle.rightTabLabelLayout>
              </LibraryStyle.tabLayout>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="p-0" sm={12}>
            <Tab.Content className="tabBody">
              <Tab.Pane eventKey={Constant.FIRST_TAB_KEY}>
                <AddInfo
                  handleSaveInfo={props.handleSaveInfo}
                  handleValidStoryName={props.handleValidStoryName}
                  storyNameValidity={props.storyNameValidity}
                  validated={props.validated}
                  handleFormChange={props.handleFormChange}
                  handleSwitchChange={props.handleSwitchChange}
                  handleActiveKey={props.handleActiveKey}
                  files={props.files}
                  activeTabKey={props.activeTabKey}
                  enable={props.enable}
                  editStory={props.editStory}
                  storyDetails={props.storyDetails}
                  title={props.title}
                  description={props.description}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.THIRD_TAB_KEY}>
                <AddFiles
                  setShowClearFile={props.setShowClearFile}
                  setContinueToAddInfoClass={props.setContinueToAddInfoClass}
                  files={props.files}
                  setFiles={props.setFiles}
                  editStory={props.editStory}
                  storyDetails={props.storyDetails}
                  handleResetFile={props.handleResetFile}
                  clearfile={props.clearfile}
                  setClearFile={props.setClearFile}
                  titleCoverFiles={props.titleCoverFiles}
                  setTitleCoverFile={props.setTitleCoverFile}
                  activeTabKey={props.activeTabKey}
                  setAsset={props.setAsset}
                  asset={props.asset}
                  seriesClearFile={props.seriesClearFile}
                  seriesBackgroundFiles={props.seriesBackgroundFiles}
                  setSeriesBackgroundFiles={props.setSeriesBackgroundFiles}
                  handleResetSeriesFile={props.handleResetSeriesFile}
                  setSeriesClearFile={props.setSeriesClearFile}
                  setArtTitleFiles={props.setArtTitleFiles}
                  setArtClearFile={props.setArtClearFile}
                  handleResetArtFile={props.handleResetArtFile}
                  artTitleFiles={props.artTitleFiles}
                  artClearfile={props.artClearfile}
                  highlightALbum={props.highlightALbum}
                  resetAlbumBorder={props.resetAlbumBorder}
                  refreshList={props.refreshList}
                  highlightSeries={props.highlightSeries}
                  resetSeriesBorder={props.resetSeriesBorder}
                  highlighttitle={props.highlighttitle}
                  resetTitleBorder={props.resetTitleBorder}
                  generes={props.generes}
                  description={props.description}
                  showTitleIamge={props.showTitleIamge}
                  showAlbumIamge={props.showAlbumIamge}
                  showSeriesIamge={props.showSeriesIamge}
                />
              </Tab.Pane>

              <Tab.Pane eventKey={Constant.SECOND_TAB_KEY}>
                <AddTags
                  handleSaveInfo={props.handleSaveInfo}
                  validated={props.validated}
                  handleFormChange={props.handleFormChange}
                  handleSwitchChange={props.handleSwitchChange}
                  handleActiveKey={props.handleActiveKey}
                  handleTagChange={props.handleTagChange}
                  handleGenreChange={props.handleGenreChange}
                  generes={props.generes}
                  files={props.files}
                  genreList={props.genreList}
                  activeTabKey={props.activeTabKey}
                  editStory={props.editStory}
                  storyDetails={props.storyDetails}
                  tags={props.tags}
                  invalidTag={props.invalidTag}
                  handleSelectChange={props.handleSelectChange}
                  handleNumberChange={props.handleNumberChange}
                  cost={props.cost}
                  storyType={props.storyType}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FOURTH_TAB_KEY}>
                <SubmitAsset
                  progress={props.progress}
                  handleActiveKey={props.handleActiveKey}
                  handleRollbackAsset={props.handleRollbackAsset}
                  editStory={props.editStory}
                  storyDetails={props.storyDetails}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FIFTH_TAB_KEY}>
                <Success
                  handleSuccesClick={props.handleSuccesClick}
                  editStory={props.editStory}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.SIXTH_TAB_KEY}>
                <Fail errors={props.errors} storyStatus={props.storyStatus} />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.SEVENTH_TAB_KEY}>
                <Cancel />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </LibraryStyle.addInfoContainer>
  );
}

export default AddAssetTabs;

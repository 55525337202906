import * as ActionTypes from '../actions/story-action-types';
import * as StoryActionTypes from '../actions/action-types';

 

export const initialState = {
 lastSavedTime:null,
 loading:false
};

export const StoryProgressReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case ActionTypes.SET_STORY_SAVE_PROGRESS:
      return {
        ...state,
        story: action.payload,
        lastSavedTime:null,
        loading:true
        
      };
      case ActionTypes.SET_STORY_SAVE_PROGRESS_SUCCESS:
        
      return {
        ...state,
       lastSavedTime: new Date(),
       loading:false

      };

    case ActionTypes.SET_STORY_SAVE_PROGRESS_FAIL:
      return {
        ...state,
        lastSavedTime:null,
        loading:false
      };
      case StoryActionTypes.SET_STORY_ID:
        return {
          ...state,
          lastSavedTime:null
        };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import * as Constant from './../../../common/constants';

function BooleanCheckBox({
    component,
    variableFields,
    commandId,
    variableListDTO,
    addGenderedText
}) {

    const [checkbox, setCheckbox] = useState(component.properties.defaultValue.replace(new RegExp(/['"]+/g), ''));
    const handleChange = () => {
        let checkBoxVal = checkbox === 'false' ? 'true' : 'false';
        setCheckbox(checkBoxVal);
    }

    switch (commandId) {
        case Constant.COMMAND_ADD_IF:
        case Constant.COMMAND_ADD_ELSE:
        case Constant.COMMAND_ADD_ELSE_IF:
        case Constant.COMMAND_ADD_END:

            const listSelectedVar = variableListDTO.filter(varialeList => varialeList.name === variableFields)
            if (listSelectedVar.length > 0) {
                switch (listSelectedVar[0].datatype) {
                    case 'Integer':
                    case 'Float':
                    case 'String':
                        return null;

                    default:
                        break;
                }
            }
            break;
        case Constant.COMMAND_SET_VAR:
            if (variableFields !== 'Boolean' || addGenderedText == 'true') {
                return null;
            }
            break;

        default:
            break;
    }
    return (
        <React.Fragment>
            <Form.Label className="commandLabel">{component.label}</Form.Label>
            {
                commandId === Constant.COMMAND_SET_VAR &&
                <Row><label className='commandSuffixSetVar'>{component.suffix}</label></Row>
            }
            <Form.Group controlId={component.widgetName} className="commandLabelSuffix">
                <label className="switch-flip">
                    <input type="checkbox"
                        name={component.widgetName}
                        className='checkbox-flip'
                        label={component.label}
                        onChange={handleChange}
                        value={checkbox}
                        checked={checkbox === 'true' ? 'checked' : null}
                    />
                    <span className="slider-flip round"></span>
                </label>
                {checkbox.replace(new RegExp(/['"]+/g), '')}
            </Form.Group>
        </React.Fragment >
    )
}

export default BooleanCheckBox
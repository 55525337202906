import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as styled from './style';
import moment from 'moment';
import * as ActionTypes from '../../actions/action-types';
import LoginForm from '../../components/login/login-form';
import * as Constant from './../../common/constants';
import ItemListLoader from '../../components/common/spin-loader';
import * as Config from './../../config/app.config';

const Login = props => {
  const [login, setLiogin] = useState({ email: '', password: '' });
  const [validated, setValidated] = useState(false);
  const [enable, setEnableLogin] = useState(true);
  const [showCustomError, setCustomError] = useState(false);

  const [verified, setVerified] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  useEffect(() => {
    if (props.isLogOut === true) {
      return;
    } else {
      if (props.isLoggedIn) {
        if (props.tokenData.roles === Constant.SNACK_USER_ADMIN) {
          if (props.isAcceptInvite === true) {
            props.history.push('/home');
          } else {
            props.history.push('/users');
          }
        } else if (props.tokenData.roles === Constant.SNACK_USER_EDITOR) {
          if (props.isAcceptInvite === true) {
            props.history.push('/home');
          } else {
            props.history.push('/stories');
          }
        } else if (props.tokenData.roles === Constant.SNACK_USER_WRITER) {
          props.history.push('/home');
        } else {
          return;
        }
      } else {
        let storyId = getParameterByName('share');
        if (storyId !== null) {
          props.history.push(`/login?share=${storyId}`);
        } else {
          let storageData = localStorage.getItem(Constant.SNACK_USER_TOKEN);

          if (storageData) {
            let sanckUserExpires = localStorage.getItem(
              Constant.SNACK_USER_EXPIRES
            );
            let now = moment(new Date()); //todays date
            let end = moment(new Date(sanckUserExpires * 1000));

            let duration = moment.duration(end.diff(now));
            let expireDuratio = duration.asDays();

            if (expireDuratio < 0) {
              props.addLogin(null, true);
              props.history.push('/login');
            } else {
              props.refreshToken(storageData);
            }
          }
        }
      }
    }
  }, [props.authToken]);

  useEffect(() => {
    let storyId = getParameterByName('share');
    if (storyId !== null) {
      props.addLogin(null, true);
      props.history.push(`/login?share=${storyId}`);
    }
  }, []);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const handleFormChange = event => {
    setCustomError(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setLiogin({
      ...login,
      [event.target.name]: event.target.value
    });

    if (event.target.name === 'password' || event.target.name === 'email') {
      event.target.value !== '' ? setEnableLogin(false) : setEnableLogin(true);
      if (
        (login.email === '' && event.target.name === 'password') ||
        (login.password === '' && event.target.name === 'email')
      )
        setEnableLogin(true);
    }

    if (event.target.name === 'email') {
      if (
        new RegExp('^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$').test(
          event.target.value
        ) !== true
      ) {
        setEnableLogin(true);
      }
    }
    if (
      new RegExp('^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$').test(
        login.email
      ) !== true &&
      event.target.name === 'password'
    ) {
      setEnableLogin(true);
    }

    setValidated(true);
    props.resetError();
  };

  const handleLogin = event => {
    if (
      parseInt(props.invalidLoginAttempts) ===
      Config.INVALID_LOGIN_ATTEMPTS_FORGOT
    ) {
      props.history.push('/password-recovery');
      return;
    }

    if (
      parseInt(props.invalidLoginAttempts) ===
      Config.INVALID_LOGIN_ATTEMPTS_LOCKED
    ) {
      setCustomError(props.error ? true : false);
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();

      if (verified === false) {
        setShowCaptchaError(true);
        //return false;
      } else {
        props.addLogin(login, false);
      }

      setCustomError(props.error ? true : false);
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  if (
    parseInt(props.invalidLoginAttempts) ===
    Config.INVALID_LOGIN_ATTEMPTS_FORGOT
  ) {
    props.history.push('/password-recovery');
    return null;
  }

  return (
    <styled.LoginMain>
      <Container className="col-12">
        {props.loadingRefreshToken === false && (
          <Row className="row-100">
            <Col xs={7}>
              <styled.Login>
                {/* <img class="login-bg" src="/images/login.jpg" /> */}
                <div className="login-bg">&nbsp;</div>
                <styled.LoginLogo>
                  <img
                    alt=""
                    className="login-logo"
                    src="./images/logo.svg"
                    width="120"
                    height="120"
                  />
                </styled.LoginLogo>
              </styled.Login>
            </Col>
            <Col xs={5} className="login-wrapper">
              <styled.LoginContainer>
                <styled.LoginForm>
                  <styled.LoginTitle>Login to Write Fictif</styled.LoginTitle>
                  <LoginForm
                    validated={validated}
                    email={login.email}
                    password={login.password}
                    error={props.error}
                    showError={props.showError}
                    enable={enable}
                    onSendClick={handleLogin}
                    onFormChange={handleFormChange}
                    verified={verified}
                    setVerified={setVerified}
                    showCaptchaError={showCaptchaError}
                    setShowCaptchaError={setShowCaptchaError}
                  />
                </styled.LoginForm>
              </styled.LoginContainer>
              <div className="login-bottom-text">
                Do not have an invite? <a href="/request-account">Click here</a>{' '}
                to learn more about how you can create your own stories on Write
                Fictif.
              </div>
            </Col>
          </Row>
        )}

        {props.loadingRefreshToken === true && (
          <Row className="row-100">
            <ItemListLoader />
          </Row>
        )}
      </Container>
    </styled.LoginMain>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    error: state.LoginReducer.error,
    isLoggedIn: state.LoginReducer.isLoggedIn,
    tokenData: state.LoginReducer.tokenData,
    isLogOut: state.LoginReducer.isLogOut,
    loadingRefreshToken: state.LoginReducer.loadingRefreshToken,
    showError: state.LoginReducer.showError,
    invalidLoginAttempts: state.LoginReducer.invalidLoginAttempts,
    passwordRecovery: state.AccountReducer.passwordRecovery,
    isAcceptInvite: state.LoginReducer.isAcceptInvite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addLogin: (login, isLogOut) =>
      dispatch({
        type: ActionTypes.SET_LOGIN_DETAILS,
        payload: login,
        isLogOut: isLogOut
      }),
    restoreSession: storage =>
      dispatch({
        type: ActionTypes.SET_LOCAL_STORAGE_LOGIN_DETAILS,
        payload: storage
      }),
    refreshToken: storage =>
      dispatch({
        type: ActionTypes.SET_TOKEN_REFRESH,
        authToken: storage
      }),
    getTutorials: authToken =>
      dispatch({
        type: ActionTypes.GET_TUTORIAL_DATA,
        payloads: authToken
      }),
    resetError: () =>
      dispatch({
        type: ActionTypes.RESET_ERROR
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

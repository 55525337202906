import React from 'react';
import { Table } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import ListAssetRow from './ListAssetRow';
import ItemListLoader from './../../common/list-loader';
import DeleteAssetModal from './delete-assets/DeleteAssetModal';
import DeleteConfirm from './delete-assets/DeleteConfirm';
import Success from './delete-assets/Success';
import './../style/style.css';
import * as Translations from './../translations';

function ListTable({
  assetList,
  loading,
  handleSorting,
  sorting,
  columns,
  alwaysEnabledCheckBox,
  handleAssetSelection,
  selectedAsset,
  handleSelectAll,
  selectAll,
  showDeleteAssetModal,
  showModal,
  hideDeleteAssetModal,
  handleShowDeleteModal,
  handleHideDeleteModal,
  handleDeleteAsset,
  assetInfoList,
  deleteConfirm,
  deleteAsset,
  handleEditAssetClick,
  filters
}) {
  return (
    <LibraryStyle.listTable>
      <Table responsive="md" className="">
        <thead className="bg-light">
          <tr className="table-head-row">
            {/* <th className="table-head-col">
                            <label className="checkboxContainer">
                                <input type="checkbox" 
                                    onChange={handleSelectAll}
                                    checked={selectAll === true ? 'checked': ''} />
                                <span className="checkmark sprite-list-checkbox"></span>
                            </label>
                        </th> */}
            <th>&nbsp;</th>
            {columns.indexOf('assetName') > -1 && (
              <th className="text-left">
                {Translations.ASSET_NAME.toUpperCase()}
              </th>
            )}
            {<th>TYPE</th>}

            {columns.indexOf('format') > -1 && (
              <th>{Translations.FORMAT.toUpperCase()}</th>
            )}
            {columns.indexOf('specs') > -1 && (
              <th>{Translations.SPECS.toUpperCase()}</th>
            )}
            {columns.indexOf('uploaded') > -1 && (
              <th>
                {Translations.UPLOADED.toUpperCase()} &nbsp;
                <i
                  onClick={handleSorting}
                  className={`fa ${
                    sorting === `asc` ? `fa-arrow-down` : `fa-arrow-up`
                  }`}
                />
              </th>
            )}
            {columns.indexOf('actions') > -1 && (
              <th>{Translations.ACTIONS.toUpperCase()}</th>
            )}
          </tr>
        </thead>
        {loading === false && (
          <tbody className="table-body">
            {assetList.length > 0 &&
              assetList.map((asset, index) => {
                return (
                  <ListAssetRow
                    columns={columns}
                    key={index}
                    asset={asset}
                    alwaysEnabledCheckBox={alwaysEnabledCheckBox}
                    handleAssetSelection={handleAssetSelection}
                    selectedAsset={selectedAsset}
                    selectAll={selectAll}
                    handleShowDeleteModal={handleShowDeleteModal}
                    handleEditAssetClick={handleEditAssetClick}
                    listLength={assetList.length}
                  />
                );
              })}

            {assetList.length === 0 &&
              loading === false &&
              filters.image === false &&
              filters.audio === false && (
                <tr>
                  <td colSpan="7" className="text-center">
                    No background, objects and sound files have been added yet!
                  </td>
                </tr>
              )}

            {assetList.length === 0 &&
              loading === false &&
              filters.image === true && (
                <tr>
                  <td colSpan="7" className="text-center">
                    No image files have been added yet!
                  </td>
                </tr>
              )}
            {assetList.length === 0 &&
              loading === false &&
              filters.audio === true && (
                <tr>
                  <td colSpan="7" className="text-center">
                    No sound files have been added yet!
                  </td>
                </tr>
              )}
          </tbody>
        )}
        {loading === true && (
          <tr>
            <td colSpan="7" className="text-center">
              <ItemListLoader />
            </td>
          </tr>
        )}
      </Table>

      {showModal && (assetInfoList && assetInfoList.length > 0) && (
        <DeleteAssetModal
          showModal={showModal}
          handleHideDeleteModal={handleHideDeleteModal}
          assetInfoList={assetInfoList}
          deleteAsset={deleteAsset}
        />
      )}
      {showModal && (assetInfoList && assetInfoList.length === 0) && (
        <DeleteConfirm
          showModal={showModal}
          handleHideDeleteModal={handleHideDeleteModal}
          handleDeleteAsset={handleDeleteAsset}
        />
      )}
      {showModal === true && deleteConfirm === true && (
        <Success
          showModal={showModal}
          handleHideDeleteModal={handleHideDeleteModal}
        />
      )}
    </LibraryStyle.listTable>
  );
}

export default ListTable;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as StoryActions from '../../actions/story-action';
import StoryAdmin from '../../components/story-admin/story-admin';
import AdminMenu from '../../components/admin-menu/admin-menu';
import { commonServices } from './../../common/helper/utils';
import * as LibraryActions from './../../actions/library-action';
import * as ActionTypes from '../../actions/action-types';

import DeleteStoryModal from './../../components/story/Delete-story/storyDeleteModal';
import DeleteSuccess from './../../components/story/Delete-story/successDelete';

import AdminReviewModal from './../../components/story/admin-review/adminReviewModal';
import Success from './../../components/story/admin-review/successReview';

import StoryInfoModal from '../../components/story/story-info/StoryInfoModal';

import UpdateStoryId from '../../components/story-admin/update-story-id';
import SuccessUpdate from '../../components/common/Modals/Success';

function Library(props) {
  // Add asset state
  const [sortingOthers, setSortingOthers] = useState('asc');
  const [sortingReviewed, setSortingReviewed] = useState('asc');
  const [deletestoryId, setDeleteStoryId] = useState(null);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [showStoryInfoModal, setShowStoryInfoModal] = useState(false);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const [showModalStoryId, setShowModalStoryId] = useState(false);
  const [storyInfoId, setStoryInfoId] = useState(null);
  const [updateButtonEnable, setUpdateButtonEnable] = useState(false);
  const [storyInfoIdValue, setStoryInfoIdValue] = useState(null);
  const [storyIdError, setStoryIdError] = useState(null);

  useEffect(() => {
    props.fetchStoryforAdmin(props.authToken, '');
    props.resetPermission();
    setDeleteStoryId(null);
    setReviewStatus(null);
    props.setAuthorName('');
  }, [refreshList]);

  useEffect(() => {
    const interval = setInterval(() => setRefreshList(!refreshList), 60000 * 5);
    return () => clearInterval(interval);
  }, [refreshList]);

  useEffect(() => {
    props.fetchGenereList(props.authToken);
    setRefreshList(!refreshList);
    props.setAuthorName('');
  }, []);

  const handleSorting = storyListType => {
    if (storyListType === 'waiting') {
      setSortingReviewed(sortingReviewed === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingOthers(sortingOthers === 'asc' ? 'desc' : 'asc');
    }
  };

  const searchWithAuthor = (event, authorNameAdded) => {
    props.fetchStoryforAdmin(props.authToken, authorNameAdded);
    event.preventDefault();
  };

  const handleDeleteClick = storyId => {
    setDeleteStoryId(storyId);
    props.handleShowDeleteModal();
  };

  const handleDuplicateClick = storyId => {
    props.handleDuplicateStory(props.authToken, storyId);
  };

  const handleHideDeleteModal = () => {
    props.handleHideDeleteModal();
  };

  const handleStoryDelete = () => {
    props.setStoryDelete(props.authToken, deletestoryId);
    //send for review
  };

  const handleAdminReviewClick = (event, storyId, status) => {
    setDeleteStoryId(storyId);
    setReviewStatus(status);
    props.handleShowAdminReviewModal();
  };

  const handleHideAdminReviewModal = () => {
    setDisableConfirmButton(false);
    props.handleHideAdminReviewModal();
  };

  const handleShowStoryId = storyDetails => {
    setStoryInfoId(storyDetails);
    setStoryInfoIdValue(storyDetails.unityStoryId);
    setShowModalStoryId(true);
  };

  const handleStoryAdminReview = event => {
    setDisableConfirmButton(true);
    props.setStoryAdminReview(props.authToken, deletestoryId, reviewStatus);
    //send for review
  };

  const handleHideUpdateStoryId = () => {
    setShowModalStoryId(false);
    setStoryInfoId(null);
    setUpdateButtonEnable(true);
    setStoryIdError('');
  };

  const hanldeStoryIdChange = event => {
    let storyId = event.target.value.trim().toLowerCase();
    // console.log(
    //   "storyId.match('[a-zA-Z0-9_-]{4,9}')",
    //   storyId.match('^[a-z][a-z0-9_]*$')
    // );
    if (
      storyId !== '' &&
      storyId.length > 3 &&
      storyId.length < 10 &&
      storyId.match('^[a-z][a-z0-9_]*$') != null
    ) {
      setUpdateButtonEnable(false);
      setStoryIdError('');
    } else {
      setUpdateButtonEnable(true);
      setStoryIdError('Please enter valid story Id');
    }
    setStoryInfoIdValue(storyId);
  };

  const updateStoryIdSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const unityStoryId = form['unityStoryId'].value.trim().toLowerCase();
    let isPresent = false;
    if (
      props.storyAdminOthers &&
      props.storyAdminOthers.length > 0 &&
      !isPresent
    ) {
      isPresent = props.storyAdminOthers.some(function(el) {
        return el.unityStoryId == unityStoryId && el.id !== storyInfoId.id;
      });
    }
    if (
      props.storyAdminReviewed &&
      props.storyAdminReviewed.length > 0 &&
      !isPresent
    ) {
      isPresent = props.storyAdminReviewed.some(function(el) {
        return el.unityStoryId == unityStoryId && el.id !== storyInfoId.id;
      });
    }

    if (isPresent === true) {
      setStoryIdError('Please enter unique story Id');
      setUpdateButtonEnable(true);
    } else {
      setStoryIdError(null);
      setShowModalStoryId(false);
      props.updateStoryId(unityStoryId, storyInfoId.id, props.authToken);
    }
  };

  const handleStoryIdOnBlur = event => {
    console.log('');
    // console.log(props.storyAdminOthers.length);
    // console.log(storyInfoId.id);

    // console.log('isPresent', isPresent);
  };

  const handleClick = () => {
    props.handleHideAdminUpdateSuccess();
  };

  const handleSetStoryId = (
    storyIdAction,
    status,
    storyOwnerOrCollab,
    createdBy
  ) => {
    props.setStoryId(storyIdAction, '/stories');
    props.history.push(`write-story/${storyIdAction}`);
    if (storyOwnerOrCollab === true) {
      if (
        status !== 'New' &&
        status !== 'In Progress' &&
        status !== 'In Revision'
      ) {
        props.setStoryViewStatus(true);
      } else {
        props.setStoryViewStatus(false);
      }
    } else {
      props.setStoryViewStatus(true);
    }

    if (
      status === 'New' &&
      status == 'In Progress' &&
      status === 'In Revision'
    ) {
      props.setStoryAddComment(true, status);
    } else {
      props.setStoryAddComment(false, status);
    }

    if (status === 'Published' || status === 'Submitted') {
      props.setRouteInfoViewStatus(false);
    } else {
      props.setRouteInfoViewStatus(true);
    }
  };

  const handleShowStoryInfoModal = id => {
    setShowStoryInfoModal(true);
    props.getStoryDetails(id, props.authToken);
  };

  const handleHideStoryInfoModal = () => {
    setShowStoryInfoModal(false);
  };

  const getStoryList = () => {
    // setRefreshList(!refreshList)
  };

  return (
    <div>
      {props.tokenData && (
        <AdminMenu
          selectedMenu={props.selectedMenu}
          setSelectedMenu={props.setSelectedMenu}
          roleAdmin={props.tokenData.roles}
          getStoryList={getStoryList}
        />
      )}
      <StoryAdmin
        loading={props.loading}
        handleSorting={storyType => handleSorting(storyType)}
        sortingOthers={sortingOthers}
        sortingReviewed={sortingReviewed}
        storyAdminAuthor={props.storyAdminAuthor}
        storyListAdminOthers={commonServices.sortListOthers(
          props.storyAdminOthers,
          sortingOthers
        )}
        storyAdminReviewed={commonServices.sortListReviewed(
          props.storyAdminReviewed,
          sortingReviewed
        )}
        searchWithAuthor={searchWithAuthor}
        handleDeleteClick={handleDeleteClick}
        handleShowStoryId={handleShowStoryId}
        handleDuplicateClick={handleDuplicateClick}
        handleAdminReviewClick={handleAdminReviewClick}
        handleSetStoryId={handleSetStoryId}
        handleShowStoryInfoModal={handleShowStoryInfoModal}
        tokenData={props.tokenData}
        refreshList={refreshList}
        authorName={props.authorName}
        setAuthorName={props.setAuthorName}
        setSearchBy={props.setSearchBy}
        searchBy={props.searchBy}
      />

      {props.showDeleteModal === true && props.deleteConfirm === false && (
        <DeleteStoryModal
          showModal={props.showDeleteModal}
          handleStoryDelete={handleStoryDelete}
          handleHideDeleteModal={handleHideDeleteModal}
        />
      )}
      {props.showDeleteModal === true && props.deleteConfirm === true && (
        <DeleteSuccess
          showModal={props.showDeleteModal}
          handleHideDeleteModal={handleHideDeleteModal}
        />
      )}
      {props.showAdminReviewModal === true &&
        props.adminReviewConfirm === false && (
          <AdminReviewModal
            showModal={props.showAdminReviewModal}
            handleStoryAdminReview={handleStoryAdminReview}
            handleHideAdminReviewModal={handleHideAdminReviewModal}
            reviewStatus={reviewStatus}
            disableConfirmButton={disableConfirmButton}
          />
        )}
      {props.showAdminReviewModal === true &&
        props.adminReviewConfirm === true && (
          <Success
            showModal={props.showAdminReviewModal}
            handleHideAdminReviewModal={handleHideAdminReviewModal}
            reviewStatus={reviewStatus}
          />
        )}
      {showStoryInfoModal && (
        <StoryInfoModal
          showModal={showStoryInfoModal}
          onHideModal={handleHideStoryInfoModal}
          storyDetails={props.storyDetails}
          genreList={props.genreList}
        />
      )}
      {showModalStoryId && (
        <UpdateStoryId
          updateButtonEnable={updateButtonEnable}
          showModal={showModalStoryId}
          storyInfoId={storyInfoId}
          storyInfoIdValue={storyInfoIdValue}
          hanldeStoryIdChange={hanldeStoryIdChange}
          storyIdError={storyIdError}
          handleHideUpdateStoryId={handleHideUpdateStoryId}
          handleStoryIdOnBlur={handleStoryIdOnBlur}
          updateStoryId={updateStoryIdSubmit}
        />
      )}

      {props.successUpdate === true && (
        <SuccessUpdate
          handleClick={handleClick}
          showModal={props.successUpdate}
          label={'Story ID updated successfully'}
          note={''}
        />
      )}
    </div>
  );
}

export const mapStateToProps = state => {
  return {
    storyAdminReviewed: state.StoryReducer.storyListAdminReviewed,
    tokenData: state.LoginReducer.tokenData,
    storyAdminOthers: state.StoryReducer.storyListAdminOthers,
    storyAdminAuthor: state.StoryReducer.storyAdminAuthor,
    authToken: state.LoginReducer.authToken,
    loading: state.StoryReducer.storyAdminLoading,
    selectedMenu: state.LibraryReducer.selectedMenu,
    showDeleteModal: state.StoryReducer.showDeleteModal,
    deleteConfirm: state.StoryReducer.deleteConfirm,

    showAdminReviewModal: state.StoryReducer.showAdminReviewModal,
    adminReviewConfirm: state.StoryReducer.adminReviewConfirm,
    storyDetails: state.StoryReducer.storyDetails,
    genreList: state.StoryReducer.genreList,
    authorName: state.StoryReducer.authorName,
    searchBy: state.StoryReducer.searchBy,
    successUpdate: state.StoryReducer.successUpdate
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchStoryforAdmin: (authToken, authorNameAdded) =>
      dispatch(StoryActions.getStoryAdmin(authToken, authorNameAdded)),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),

    //Delete Story
    handleShowDeleteModal: () =>
      dispatch(StoryActions.setShowDeleteModalTrue()),
    handleHideDeleteModal: () =>
      dispatch(StoryActions.setShowDeleteModalFalse()),
    setStoryDelete: (authToken, storyId) =>
      dispatch(StoryActions.setStoryDelete(authToken, storyId)),

    //Review Story

    handleShowAdminReviewModal: () =>
      dispatch(StoryActions.setShowAdminReviewModalTrue()),
    handleHideAdminReviewModal: () =>
      dispatch(StoryActions.setShowAdminReviewModalFalse()),
    handleHideAdminUpdateSuccess: () =>
      dispatch(StoryActions.updateSuccessHide()),
    setStoryAdminReview: (authToken, storyId, reviewStatus) =>
      dispatch(
        StoryActions.setStoryAdminReview(authToken, storyId, reviewStatus)
      ),
    setStoryViewStatus: flag => dispatch(StoryActions.setStoryViewStatus(flag)),
    setRouteInfoViewStatus: paramsShow =>
      dispatch(StoryActions.setRouteInfoViewStatus(paramsShow)),

    setStoryId: (storyId, backPage) =>
      dispatch({
        type: ActionTypes.SET_STORY_ID,
        storyId: storyId,
        storyTitle: '',
        backPage: backPage
      }),
    setStoryAddComment: (status, storyStatus) =>
      dispatch(StoryActions.setStoryAddComment(status, storyStatus)),
    getStoryDetails: (storyId, authToken) =>
      dispatch({
        type: ActionTypes.GET_STORY_DETAILS,
        storyId: storyId,
        authToken: authToken
      }),
    resetPermission: flag =>
      dispatch({
        type: ActionTypes.SET_PANEL_PERMISSION_FALSE
      }),
    fetchGenereList: authToken =>
      dispatch(StoryActions.getStoryGenere(authToken)),
    handleDuplicateStory: (authToken, storyId) =>
      dispatch(StoryActions.setStoryDuplicate(authToken, storyId)),
    setAuthorName: authorName =>
      dispatch(StoryActions.setAuthorName(authorName)),
    setSearchBy: searchBy => dispatch(StoryActions.setSearchBy(searchBy)),
    updateStoryId: (unityStoryId, storyId, authToken) =>
      dispatch(StoryActions.updateStoryId(unityStoryId, storyId, authToken))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Library);

import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { PlaceBackgroundService } from './../../story/place-background-service';
import 'cropperjs/dist/cropper.css';
import { PlaceBgStyle } from './style';
import * as AspectRatioConfig from './../../../config/aspectRatio.config';
import { ImageMapper } from './../../../common/helper/image-mapper';
import { StoryService } from './../service';
import * as Pattern from './../../../common/helper/pattern';

const CreateViewTab = props => {
  const [buttonEnable, setButtonEnable] = useState('disabled');
  const [validated, setValidated] = useState(false);
  const [view, setView] = useState(null);
  const [viewName, setViewName] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [fadeIntoSeconds, setFadeIntoSeconds] = useState(1);

  useEffect(() => {
    if (props.viewId !== null) {
      let bgArray = PlaceBackgroundService.getView(props.viewId, props.views);
      setView(bgArray[0]);
      setBackground(bgArray);
      if (props.fadeinView !== null && props.isEditCommand === true) {
        setFadeIntoSeconds(props.fadeinView);
      }
    }
  }, [props.viewId, props.fadeinView]);

  useEffect(() => {
    if (viewName !== null) {
      setShowLoader(true);
    }
  }, viewName);

  const saveMovetoView = e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const destinationViewName = data.get('destinationview');
    const transition = data.get('transition');
    if (transition) {
      props.handleMovePlaceViewScript(destinationViewName, transition);
    } else {
      setValidated(false);
    }
    setValidated(true);
  };

  const callbackFunction = (x, objects = '') => {
    let element = document.getElementById('view');
    if (element != null) {
      element.style.backgroundImage = `url(${x})`;
    }

    if (objects !== '' && element != null) {
      element.insertAdjacentHTML('beforeend', objects);
    }

    setShowLoader(false);
  };

  const onChangeDropdown = e => {
    let bgArray = PlaceBackgroundService.getViewByName(
      e.target.value,
      props.views
    );
    document.getElementById('view').innerHTML = '';
    document.getElementById('view').style.backgroundImage = '';
    setBackground(bgArray);
    if (bgArray.length === 1) {
      setShowLoader(true);
    }
  };

  const handleFadeIntoSecondsChange = event => {
    let value = event.target.value;
    if (value == '' || Pattern.NUMBER_FIELD_PATTERN.test(value)) {
      setFadeIntoSeconds(value);
    }
  };

  const setBackground = bgArray => {
    if (bgArray.length > 0) {
      let view = bgArray[0];
      setView(view);
      setButtonEnable('');

      // Get crop image from view
      let previewWidth =
        AspectRatioConfig.aspectRatioParameters[
          AspectRatioConfig.aspectRatioThreeByFour
        ].objectViewWidth;
      let previewHeight =
        AspectRatioConfig.aspectRatioParameters[
          AspectRatioConfig.aspectRatioThreeByFour
        ].objectViewHeight;
      let objectImages = ImageMapper.mapDefaultObjectsOnView(
        view.viewObjects,
        view.width,
        view.height,
        previewWidth,
        previewHeight,
        0,
        AspectRatioConfig.aspectRatioThreeByFour,
        [],
        true
      );
      StoryService.resizeImage(
        view.asset.awsPath,
        view.width,
        view.height,
        view.xcoordinate,
        view.ycoordinate,
        callbackFunction,
        objectImages,
        false
      );
    } else {
      setButtonEnable('disabled');
    }
  };

  return (
    <React.Fragment>
      <PlaceBgStyle.moveBg>
        <Row>
          <Col className="left-content" data-testid="first-label" id="view">
            {showLoader && (
              <Spinner
                variant="primary"
                animation="border"
                className="spinner-class"
              />
            )}
          </Col>
          <Col className="right-content" data-testid="first-label">
            <Form
              noValidate
              name="movePlaceView"
              validated={validated}
              method="POST"
              id="movePlaceView"
              onSubmit={evt => saveMovetoView(evt)}
            >
              <Form.Group
                className="custom-select-dropdown"
                controlId="destinationview"
              >
                <Form.Label className="label-title-text">
                  Destination View
                </Form.Label>
                <Form.Control
                  name="destinationview"
                  key="destinationview"
                  required
                  as="select"
                  onChange={evt => onChangeDropdown(evt)}
                  label="destinationview"
                  index="destinationview"
                  defaultValue={
                    props.isEditCommand === true && view !== null
                      ? view.name
                      : ''
                  }
                  placeholder="Select one"
                  className="form-control"
                  value={view !== null ? view.name : null}
                >
                  <option value="">Select one</option>
                  {props.views.map((background, index) => {
                    return (
                      <option
                        value={background.name}
                        key={index}
                        selected={
                          background.id === parseInt(props.background)
                            ? 'selected'
                            : null
                        }
                      >
                        {background.name}
                      </option>
                    );
                  })}
                  <Form.Control.Feedback type="invalid">
                    Destination view is required
                  </Form.Control.Feedback>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Row} controlId="transition">
                <Form.Label className="label-title-text">
                  Transition duration
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="transition">
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="transition"
                    id="transition"
                    label="3 (disabled)"
                    defaultValue={
                      props.isEditCommand === true ? fadeIntoSeconds : 1
                    }
                    value={fadeIntoSeconds}
                    Step="0.1"
                    placeholder="Transition"
                    aria-describedby="inputGroupPrepend"
                    className="transitionTaxtBox form-control"
                    // value={props.isEditCommand === true ? props.fadeinView : '0.5'}
                    onChange={handleFadeIntoSecondsChange}
                    required
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text>Seconds</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control.Feedback type="invalid">
                    Please enter valid value in duration
                  </Form.Control.Feedback>
                </InputGroup>
                <div className="warningMessageBox d-flex">
                  <span className="warning-icon">
                    <img
                      className="svg-icon-image"
                      src="/images/icons/ic_warning_yellow.svg"
                    />
                  </span>
                  <p className="warningMessage mb-0">
                    The destination view you are moving to should be another
                    section of the same background. This prevents the camera
                    from moving across gaps between two different images.
                  </p>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <div className="button-wrapper">
          <div>
            <Button
              className="btn-snacktool btn-snacktool-golden"
              onClick={props.onHideModal}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              form="movePlaceView"
              type="submit"
              disabled={buttonEnable}
              className="btn-snacktool btn-snacktool-golden"
            >
              Save and place into script
            </Button>
          </div>
        </div>
      </PlaceBgStyle.moveBg>
    </React.Fragment>
  );
};

export default CreateViewTab;

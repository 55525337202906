import * as ActionTypes from '../actions/action-types';
import * as RequestAccountActionTypes from '../actions/request-account-action-types';
import * as PasswordRecoveryActionTypes from '../actions/password-recovery-action-types';

export const initialState = {
  userCreated: false,
  userDeactivate: false,
  error: [],
  user: [],
  accountList: [],
  loading: true,
  showUserDeleteSuccess: false,
  showUserDeleteFail: false,
  isEditAccount: false,
  requestAccount: null,
  showApiError: false,

  passwordRecovery: 1,

  changePasswordError: [],
  requestAccountError: null,
  passwordRecoveryError: null,
  errormsg: '',
  accountCreationLoader: false
};

export const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_API_ERROR:
      return {
        ...state,
        showApiError: true,
        errormsg: action.errorMsg
      };

    case ActionTypes.RESET_API_ERROR:
      return {
        ...state,
        showApiError: false
      };
    case ActionTypes.FETCH_USER_ACCOUNTS:
      return {
        ...state,
        loading: true,
        userCreated: false,
        userDeactivate: false,
        error: []
      };

    case ActionTypes.FETCH_USER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountList: action.userAccounts,
        loading: false
      };

    case ActionTypes.FETCH_USER_ACCOUNTS_FAIL:
      return {
        ...state
      };

    case ActionTypes.SET_EDIT_ACCOUNT:
      return {
        ...state,
        isEditAccount: action.isEdit
      };
    case ActionTypes.HIDE_DELETE_MODAL:
      return {
        ...state,
        showUserDeleteSuccess: false
      };

    case ActionTypes.CREATE_ACCOUNT_DETAILS:
      return {
        ...state,
        user: action.payload,
        token: action.token,
        isEditAccount: action.isEditAccount,
        userId: action.userId,
        userCreated: false,
        error: [],
        accountCreationLoader: true
      };

    case ActionTypes.CREATE_SUCCESS_ACCOUNT_DETAILS:
      return {
        ...state,
        userCreated: action.payload.result.result === 'Success' ? true : false,
        error:
          action.payload.result.result === 'Failure'
            ? action.payload.result.message
            : [],
        accountCreationLoader: false
      };

    case ActionTypes.CREATE_FAIL_ACCOUNT_DETAILS:
      return {
        ...state,
        userCreated: false,
        accountCreationLoader: false
      };

    case ActionTypes.DEACTIVATE_ACCOUNT_DETAILS:
      return {
        ...state,
        user: action.payload,
        token: action.token,
        error: []
      };

    case ActionTypes.DEACTIVATE_SUCCESS_ACCOUNT_DETAILS:
      return {
        ...state,
        userDeactivate: true
      };

    case ActionTypes.DEACTIVATE_FAIL_ACCOUNT_DETAILS:
      return {
        ...state
      };

    case ActionTypes.DELETE_ACCOUNT_DETAILS:
      return {
        ...state,
        user: action.payload,
        token: action.token,
        error: []
      };

    case ActionTypes.DELETE_SUCCESS_ACCOUNT_DETAILS:
      return {
        ...state,
        showUserDeleteSuccess: true
      };

    case ActionTypes.DELETE_FAIL_ACCOUNT_DETAILS:
      return {
        ...state,
        showUserDeleteFail: true
      };

    // Request account
    case RequestAccountActionTypes.SET_REQUEST_ACCOUNT_FAIL:
      return {
        ...state,
        requestAccount: false,
        requestAccountError: action.payload
      };
    case RequestAccountActionTypes.RESET_REQUEST_ACCOUNT:
      return {
        ...state,
        requestAccount: null,
        requestAccountError: null
      };
    case RequestAccountActionTypes.SET_REQUEST_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestAccount: true
      };

    // Password recovery
    case PasswordRecoveryActionTypes.SET_PASSWORD_RECOVERY_FAIL:
      return {
        ...state,
        passwordRecovery: 0,
        passwordRecoveryError: action.payload
      };
    case PasswordRecoveryActionTypes.RESET_PASSWORD_RECOVERY:
      return {
        ...state,
        passwordRecovery: 1,
        passwordRecoveryError: null
      };
    case PasswordRecoveryActionTypes.SET_PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        passwordRecovery: 2
      };
    case PasswordRecoveryActionTypes.CHANGE_PASSWORD_RECOVERY:
      return {
        ...state,
        passwordRecovery: 3
      };
    case PasswordRecoveryActionTypes.SET_CHANGE_PASSWORD:
      return {
        ...state
      };
    case PasswordRecoveryActionTypes.SET_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordError: action.payload ? action.payload : []
      };
    case PasswordRecoveryActionTypes.SET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordRecovery: 4
      };
    case PasswordRecoveryActionTypes.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordError: []
      };
    default:
      return state;
  }
};

import React from 'react';
import { LibraryStyle} from './../style/style';
import { Row,Modal, Col, ProgressBar } from 'react-bootstrap';
import * as translations from './../translations';

function  Progress(props) {
//uploadCount
    return (
      <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show = {props.showModal}
      
      backdrop='static'
      dialogClassName="add-asset-modal-dialog" 
      >
        
        <Modal.Body>
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.submitAssetContent>
                <Row className="pb-5">
                    <Col className="submit-asset-label">
                        {props.uploadCount===1? `Saving ${props.uploadCount} new file`:`Saving ${props.uploadCount} new files`}
                    </Col>
                </Row>
                <Row>
                    <Col className="progress-bar-col pb-4">
                       
                        <ProgressBar	style={{ width: `${props.progress<60?props.progress:60}%` }} aria-valuenow={props.progress} aria-valuemin='0' aria-valuemax='100' className="progress-bar-custom"   >
                         
                         </ProgressBar>
                    </Col>
                </Row>
                {/* <Row>
                    <Col className="submit-asset-cancel-label close">
                        <Nav.Link onClick={props.handleRollbackAsset}>{translations.CANCEL}</Nav.Link>
                    </Col>
                </Row> */}
            </LibraryStyle.submitAssetContent>
        </LibraryStyle.addAssetMessageBox>
        </Modal.Body>
        </Modal>
    );
}

export default Progress
import React, { memo } from "react";
 


const ItemListLoader = () => {
  return (
    <React.Fragment>
       
      <div style={{padding:'5%',marginLeft:'40%',marginTop:'13%'}}  role="status">
   
    <img  src='/images/spinner1.gif' alt='loading' />   
 
   </div>
    

    </React.Fragment>
  )
};

export default memo(ItemListLoader);

import React, { useEffect, useState } from 'react';
import ListCharacters from './../../components/library/characters/ListCharacters';
import LibraryMenus from './../../components/library/LibraryMenu';
import { connect } from 'react-redux';
import * as LibraryActions from './../../actions/library-action';
import { CharacterService } from './../../components/library/character';
import AddCastModal from './../../components/library/characters/add-cast-modal.js';

import LockedAssets from './../../components/library/list-assets/delete-assets/LockedAssets';
import * as PlaceBackgroundActions from './../../actions/place-background-action';
import * as ActionTypes from '../../actions/action-types';
import * as Constant from './../../common/constants';
import { StoryService } from './../../components/story/service';
import * as StoryAction from '../../actions/story-action';
import Success from '../../components/common/Modals/Success';
import { Row, Col } from 'react-bootstrap';

function Characters(props) {
  const [previewStatus, setPreviewStatus] = useState([]);
  const [expressionList, setExpressionList] = useState([]);
  const [outfitList, setOutfitList] = useState([]);
  const [hairsList, setHairsList] = useState([]);
  const [accesoryList, setAccesoryList] = useState([]);
  const [editCastCharId, setEditCastCharId] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [characterIndex, setCharacterIndex] = useState({
    outfit: '-1',
    expression: '-1',
    hair: '-1',
    acc: '-1'
  });

  const [successDelete, setSuccessDelete] = useState(false);

  const [castButtonState, setCastButtonState] = useState(false);
  const [editCharCast, setEditCharCast] = useState(false);

  const castConstant = {
    description: '',
    lastName: '',
    specs: '1024 x 1024',
    removeImage: false
  };

  const [showClearFile, setShowClearFile] = useState(false);
  const [castCharacter, setCastCharacter] = useState(castConstant);
  const [editCastId, setEditCastId] = useState(null);
  const [castValidated, setCastValidated] = useState(false);

  const [files, setFiles] = useState(null);
  const [listPreviewExpression, setListPreviewExpression] = useState(null);

  const [listPreviewOutfit, setListPreviewOutfit] = useState(null);
  const [listPreviewHairs, setListPreviewHairs] = useState(null);
  const [listPreviewAccesory, setListPreviewAccesory] = useState(null);

  const [listPreviewSprites, setListPreviewSprites] = useState([]);

  const [listPreviewCharacter, setListPreviewCharacter] = useState(null);
  const [sortCharacters, setSortCharacters] = useState('asc');

  const [addCostModal, setAddCostModal] = useState(false);
  const [characterId, setCharacterId] = useState(null);
  const [routeValidated, setRouteValidated] = useState(false);
  const [successShow, setSuccessShow] = useState(false);

  useEffect(() => {
    props.getStoryDetails(props.storyId, props.authToken);

    if (props.showDeleteModal === false) setCharacterId(null);

    props.getCharacters(props.storyId, props.authToken);
  }, [props.showDeleteModal]);

  useEffect(() => {
    if (props.IsCharacterEdit === true && props.showCharLockModal === false) {
      props.history.push('create-character-step-one');
    }
    if (
      props.tokenDataUser &&
      (props.tokenDataUser.roles === Constant.SNACK_USER_EDITOR ||
        props.tokenDataUser.roles === Constant.SNACK_USER_ADMIN)
    ) {
      props.setPermission(true);
    }
  }, [props.IsCharacterEdit, props.showCharLockModal]);

  useEffect(() => {
    let editFile = null;
    let editfileNew = [];
    if (props.isCastEdit === true && props.castData) {
      setAddCostModal(true);
      castConstant.characterId = props.castData.characterId;
      setCharacterId(props.castData.characterId);
      castConstant.description = props.castData.description;
      castConstant.specs = props.castData.specs;
      castConstant.lastName = props.castData.lastName;
      castConstant.imagePath = props.castData.imagePath;

      setShowClearFile(props.castData.imagePath === null ? false : true);

      if (props.castData.imagePath === null) {
        editFile = props.castData.imagePath;
        setFiles(editFile);
      } else {
        editfileNew.push(props.castData.imagePath);
        setFiles(editfileNew);
      }
      setCastCharacter(castConstant);
    } else {
      castConstant.characterId = null;
      // setCharacterId(null);
      castConstant.description = '';
      castConstant.specs = '';
      castConstant.lastName = '';
      castConstant.imagePath = '';
      setFiles(null);
      setAddCostModal(false);
      setCastCharacter(castConstant);
    }
  }, [props.isCastEdit, props.characterList]);

  const handlePreviewClick = event => {
    let rowId = event.currentTarget.id;
    let states = CharacterService.preparePreviewRowState(props.characterList);
    states[parseInt(rowId)] = !states[parseInt(rowId)];
    setPreviewStatus({
      ...states,
      [parseInt(rowId)]: !previewStatus[rowId]
    });

    setExpressionList(props.characterList[rowId].expressions);
    setOutfitList(props.characterList[rowId].outfits);
    setHairsList(props.characterList[rowId].hairs);
    setAccesoryList(props.characterList[rowId].accessories);

    const filterAcc = props.characterList[rowId].accessories.findIndex(
      accessories => accessories.defaultAsset === true
    );

    const filterOutfit = props.characterList[rowId].outfits.findIndex(
      outfit => outfit.defaultAsset === true
    );

    const filterHairs = props.characterList[rowId].hairs.findIndex(
      hair => hair.defaultAsset === true
    );

    const filterExp = props.characterList[rowId].expressions.findIndex(
      expressions => expressions.defaultAsset === true
    );

    setListPreviewCharacter(rowId);

    setListPreviewSprites([
      props.characterList[rowId].outfits[filterOutfit].awsPath,
      props.characterList[rowId].expressions[filterExp].awsPath,
      props.characterList[rowId].hairs[filterHairs].awsPath,
      props.characterList[rowId].accessories[filterAcc].awsPath
    ]);
    setListPreviewOutfit(
      props.characterList[rowId].outfits[filterOutfit].awsPath
    );
    setListPreviewExpression(
      props.characterList[rowId].expressions[filterExp].awsPath
    );
    setListPreviewHairs(props.characterList[rowId].hairs[filterHairs].awsPath);
    setListPreviewAccesory(
      props.characterList[rowId].accessories[filterAcc].awsPath
    );

    setCharacterIndex({
      outfit: filterOutfit,
      expression: filterExp,
      acc: filterAcc,
      hair: filterHairs
    });
  };

  const handleExpressionChange = event => {
    let exp = null;
    if (event.target.value !== '-1') {
      exp = props.characterList[listPreviewCharacter].expressions[
        event.target.value
      ].awsPath
        ? props.characterList[listPreviewCharacter].expressions[
            event.target.value
          ].awsPath
        : null;
    }
    let spritesExpression = CharacterService.getListCharacterPreviewSprites(
      listPreviewOutfit,
      exp,
      listPreviewHairs,
      listPreviewAccesory
    ); //

    if (spritesExpression.length > 0) {
      setListPreviewSprites(spritesExpression);
      setCharacterIndex({
        expression: event.target.value
      });
    } else {
      setListPreviewSprites([]);
    }
    setListPreviewExpression(exp);
  };

  const handleOutfitChange = event => {
    let otFit = null;
    if (event.target.value !== '-1') {
      otFit = props.characterList[listPreviewCharacter].outfits[
        event.target.value
      ].awsPath
        ? props.characterList[listPreviewCharacter].outfits[event.target.value]
            .awsPath
        : null;
    }
    let spritesOutfit = CharacterService.getListCharacterPreviewSprites(
      otFit,
      listPreviewExpression,
      listPreviewHairs,
      listPreviewAccesory
    ); //
    if (spritesOutfit.length > 0) {
      setListPreviewSprites(spritesOutfit);
      setCharacterIndex({
        outfit: event.target.value
      });
    } else {
      setListPreviewSprites([]);
    }
    setListPreviewOutfit(otFit);
  };

  const handleHairChange = event => {
    let hrs = null;
    if (event.target.value !== '-1') {
      hrs = props.characterList[listPreviewCharacter].hairs[event.target.value]
        .awsPath
        ? props.characterList[listPreviewCharacter].hairs[event.target.value]
            .awsPath
        : null;
    }
    let spritesHairs = CharacterService.getListCharacterPreviewSprites(
      listPreviewOutfit,
      listPreviewExpression,
      hrs,
      listPreviewAccesory
    ); //
    // props.characterList[listPreviewCharacter].outfits[event.target.value].awsPath;
    if (spritesHairs.length > 0) {
      setListPreviewSprites(spritesHairs);
      setCharacterIndex({
        hair: event.target.value
      });
    } else {
      setListPreviewSprites([]);
    }

    setListPreviewHairs(hrs);
  };

  const handleAccesoryChange = event => {
    let acc = null;
    if (event.target.value !== '-1') {
      acc = props.characterList[listPreviewCharacter].accessories[
        event.target.value
      ].awsPath
        ? props.characterList[listPreviewCharacter].accessories[
            event.target.value
          ].awsPath
        : null;
    }
    let spritesAccesory = CharacterService.getListCharacterPreviewSprites(
      listPreviewOutfit,
      listPreviewExpression,
      listPreviewHairs,
      acc
    ); //
    // props.characterList[listPreviewCharacter].outfits[event.target.value].awsPath;
    if (spritesAccesory.length > 0) {
      setListPreviewSprites(spritesAccesory);
      setCharacterIndex({
        acc: event.target.value
      });
    } else {
      setListPreviewSprites([]);
    }
    setListPreviewAccesory(acc);
  };

  const sortCharacterList = () => {
    props.sortCharacterList(sortCharacters);
  };

  const handleShowDeleteModal = charId => {
    setCharacterId(charId);
    props.getCharacterDelete(props.storyId, props.authToken, charId);
    // props.handleShowModal();
  };

  const handleCreateCast = event => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      setCastButtonState(true);
      setEditCharCast(false);
      setAddCostModal(false);
      // setCastCharacterList([]);
      // const intervalId = interval();
      // setIntervalId(intervalId);
      if (props.isCastEdit) {
        setSuccessMsg(
          'This character’s cast entry has been successfully updated.'
        );
      } else {
        setSuccessMsg(
          'This character has been added to the cast for this story.'
        );
      }
      props.saveCast(
        StoryService.getCastFormData(castCharacter, files, props.isCastEdit),
        props.authToken,
        props.storyId,
        props.isCastEdit,
        editCastId,
        characterId
      );
      setTimeout(() => {
        props.getCharacters(props.storyId, props.authToken);
      }, 1500);
    }
    setAddCostModal(false);
    setTimeout(() => {
      setSuccessShow(true);
      props.getCharacters(props.storyId, props.authToken);
    }, 1500);
    event.preventDefault();
    event.stopPropagation();
    setCastValidated(true);
    setFiles(null);
  };

  const handleAddCastModal = characterId => {
    setAddCostModal(!addCostModal);
    setCharacterId(characterId);
    setCastCharacter({
      ...castCharacter,
      characterId: characterId
    });
  };

  const handleHideDeleteModal = () => {
    setCharacterId(null);
    props.handleHideModal();
    setCastCharacter({
      characterId: '',
      lastName: '',
      description: '',
      removeImage: false
    });
  };

  const handleDeleteCharacter = charId => {
    props.deleteCharacter(props.storyId, props.authToken, charId);
    //delete char api call
  };

  const handleDeleteCast = () => {
    props.deleteAddCast(props.storyId, props.authToken, characterId);
    setCastCharacter({
      characterId: '',
      lastName: '',
      description: '',
      removeImage: false
    });

    setAddCostModal(false);
    setFiles(null);
    setTimeout(() => {
      setSuccessDelete(true);
      props.getCharacters(props.storyId, props.authToken);
    }, 1500);
    props.resetCast();
  };

  const handleAddCastModalHide = () => {
    setAddCostModal(false);
    castConstant.characterId = null;
    setCharacterId(null);
    castConstant.description = '';
    castConstant.specs = '';
    castConstant.lastName = '';
    castConstant.imagePath = '';
    setFiles(null);
    setAddCostModal(false);
    setCastCharacter(castConstant);
    props.resetCast();
  };

  const handleEditClick = characterId => {
    // props.getCharacterById(props.storyId,characterId,props.authToken);
    props.resetEditCharacters();
    props.setEditCharacters(characterId);
    props.getCharacterById(props.storyId, characterId, props.authToken);
    // props.history.push('create-character-step-one');
    // props.history.push('create-character-step-one');
  };

  const handleHideAssetLockModal = () => {
    props.handleResetCharacterLockModal();
  };

  const handleEditCast = characterId => {
    // setRouteCharacterList([]);
    // props.getCharacters(props.storyId, props.authToken);
    props.getCastById(props.storyId, characterId, props.authToken);

    // let routeEditCharList = StoryService.setRouteCharacterLists(props.characterList, props.storyRoutes, true, characterId)
    // setRouteCharacterList(routeEditCharList)
    setCastButtonState(false);
    setEditCharCast(true);
    // setEditCastId(routeId);
    setEditCastCharId(characterId);
  };

  const handleCastHideSuccess = () => {
    setTimeout(() => {
      props.getCharacters(props.storyId, props.authToken);
    }, 1500);
    setSuccessShow(false);
    setSuccessDelete(false);
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
          <LibraryMenus
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            initializeCreateCharacter={props.initializeCreateCharacter}
            resetEditCharacter={props.resetEditCharacters}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            resetViewAutoSaveFlag={props.resetViewAutoSaveFlag}
            resetViews={props.resetViews}
            setPlaceBackgroundProperties={props.setPlaceBackgroundProperties}
          />
        </Col>
        <Col className="col-8">
          <ListCharacters
            initializeCreateCharacter={props.initializeCreateCharacter}
            characterList={props.characterList}
            previewStatus={previewStatus}
            handlePreviewClick={handlePreviewClick}
            expressionList={expressionList}
            outfitList={outfitList}
            hairsList={hairsList}
            accesoryList={accesoryList}
            listPreviewSprites={listPreviewSprites}
            handleExpressionChange={handleExpressionChange}
            handleOutfitChange={handleOutfitChange}
            handleHairChange={handleHairChange}
            handleAccesoryChange={handleAccesoryChange}
            tokenData={props.tokenData}
            setSortCharacters={setSortCharacters}
            sortCharacters={sortCharacters}
            sortCharacterList={sortCharacterList}
            listPreviewExpression={listPreviewExpression}
            storyTitle={props.storyTitle}
            showModal={props.showDeleteModal}
            handleAddCastModal={handleAddCastModal}
            handleShowDeleteModal={handleShowDeleteModal}
            handleHideDeleteModal={handleHideDeleteModal}
            handleDeleteCharacter={handleDeleteCharacter}
            characterInfoList={props.characterInfoList}
            deleteConfirm={props.deleteConfirm}
            handleEditClick={handleEditClick}
            listPreviewOutfit={listPreviewOutfit}
            storyRoutes={props.storyRoutes}
            characterId={characterId}
            handleEditCast={handleEditCast}
            characterIndex={characterIndex}
            storyDetails={props.storyDetails}
            deleteCast={handleDeleteCast}
          />
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
      {props.showCharLockModal === true && (
        <LockedAssets
          showModal={props.showCharLockModal}
          lockedAssetData={props.characterLockData}
          handleHideAssetLockModal={handleHideAssetLockModal}
          type="characters"
        />
      )}
      {addCostModal === true && (
        <AddCastModal
          showModal={addCostModal}
          handleAddCastModal={handleAddCastModal}
          setFiles={data => setFiles(data)}
          files={files}
          setCast={data => setCastCharacter(data)}
          cast={castCharacter}
          setShowClearFile={setShowClearFile}
          showClearFile={showClearFile}
          characterId={characterId}
          setRouteValidated={setRouteValidated}
          routeValidated={routeValidated}
          handleCreateCast={handleCreateCast}
          handleEditCast={handleEditCast}
          isCastEdit={props.isCastEdit}
          deleteCast={handleDeleteCast}
          handleAddCastModalHide={handleAddCastModalHide}
        />
      )}
      {successShow === true && (
        <Success
          label={successMsg}
          note=""
          handleClick={handleCastHideSuccess}
        ></Success>
      )}
      {successDelete === true && (
        <Success
          label="This character has been deleted from the cast for this story."
          note=""
          handleClick={handleCastHideSuccess}
        ></Success>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedMenu: state.LibraryReducer.selectedMenu,
    storyId: state.StoryReducer.storyId,
    authToken: state.LoginReducer.authToken,
    characterList: state.CharacterReducer.characterList,
    tokenData: state.LoginReducer.tokenData,
    storyTitle: state.StoryReducer.storyTitle,
    showDeleteModal: state.CharacterReducer.showDeleteModal,
    characterInfoList: state.CharacterReducer.characterInfoList,
    deleteConfirm: state.CharacterReducer.deleteConfirm,
    IsCharacterEdit: state.CharacterReducer.IsCharacterEdit,
    characterData: state.CharacterReducer.characterData,
    characterLockData: state.CharacterReducer.characterLockData,
    showCharLockModal: state.CharacterReducer.showCharLockModal,
    storyRoutes: state.StoryReducer.storyRoutes,
    editCharaterId: state.CharacterReducer.editCharaterId,
    storyDetails: state.StoryReducer.storyDetails,
    selectedMenu: state.LibraryReducer.selectedMenu,
    viewAutoSaveFlag: state.PlaceBackgroundReducer.autoSaveFlag,
    tokenDataUser: state.LoginReducer.tokenDataUser,
    isCastEdit: state.StoryReducer.isCastEdit,
    castData: state.StoryReducer.castData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initializeCreateCharacter: () =>
      dispatch(LibraryActions.initializeCreateCharacter()),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    getCharacters: (storyId, authToken) =>
      dispatch(LibraryActions.getCharacters(storyId, authToken)),
    getCharacterById: (storyId, characterId, authToken) =>
      dispatch(
        LibraryActions.getCharactersById(storyId, characterId, authToken)
      ),
    sortCharacterList: sortCharacters =>
      dispatch(LibraryActions.sortCharacterList(sortCharacters)),
    handleShowModal: () => dispatch(LibraryActions.setShowModalDeleteTrue()),
    handleHideModal: () => dispatch(LibraryActions.setShowModalDeleteFalse()),
    handleResetCharacterLockModal: () =>
      dispatch(LibraryActions.handleResetCharacterLockModal()),
    getCharacterDelete: (storyId, authToken, charId) =>
      dispatch(LibraryActions.getCharacterDelete(storyId, authToken, charId)),
    deleteCharacter: (storyId, authToken, charId) =>
      dispatch(LibraryActions.deleteCharacter(storyId, authToken, charId)),

    resetEditCharacters: () => dispatch(LibraryActions.setCharacterEditFalse()),
    setEditCharacters: characterId =>
      dispatch(LibraryActions.setCharacterEditTrue(characterId)),
    resetViews: () => dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    setAutoSaveFlag: flag =>
      dispatch(PlaceBackgroundActions.setAutoSaveFlag(flag)),
    resetViewAutoSaveFlag: () =>
      dispatch(PlaceBackgroundActions.resetAutoSaveFlag()),
    setPlaceBackgroundProperties: (key, value) =>
      dispatch(PlaceBackgroundActions.setBackgroundProperties(key, value)),
    setPermission: flag =>
      dispatch({
        type: ActionTypes.SET_PANEL_PERMISSION_TRUE
      }),
    saveCast: (
      data,
      authToken,
      storyId,
      isRouteEdit,
      editRouteId,
      characterId
    ) =>
      dispatch(
        StoryAction.createCast(
          data,
          authToken,
          storyId,
          isRouteEdit,
          editRouteId,
          characterId
        )
      ),
    resetPermission: flag =>
      dispatch({
        type: ActionTypes.SET_PANEL_PERMISSION_FALSE
      }),
    getCastById: (storyId, characterId, authToken) =>
      dispatch(StoryAction.getCast(storyId, characterId, authToken)),
    deleteAddCast: (storyId, authToken, characterId) =>
      dispatch(StoryAction.deleteCast(storyId, authToken, characterId)),
    getStoryDetails: (storyId, authToken) =>
      dispatch({
        type: ActionTypes.GET_STORY_DETAILS,
        storyId: storyId,
        authToken: authToken
      }),
    resetCast: () => dispatch(StoryAction.resetCast())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Characters);

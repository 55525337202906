import React, { useState } from 'react';
import {
  Button,
  Col,
  Modal,
  Row,
  Form,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Overlay
} from 'react-bootstrap';
import AddObjectSection from './AddObjectSection';

// react cropper

import 'cropperjs/dist/cropper.css';
import { StoryService } from './../service';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import CreateViewLeftContainer from './CreateViewLeftContainer';
import * as AspectRatioConfig from './../../../config/aspectRatio.config';
import { PlaceBackgroundService } from './../place-background-service';
import './style';
import CheckViews from './checkViews'

import {
  NAME_OF_THIS_VIEW,
  BLANK_SPACES_NOT_ALLOWED,
  ERROR_DUPLICATE,
  ERROR_NAME_VIEW,
  ADD_TO_GALLERY,
  IS_FOUND_IN_JUICY,
  CAPTION_TEXT,
  CAPTION_TEXT_ERROR,
  FADE_INTO_SCREEN,
  EXPORT_TO_GALLERY,
  EXPORT_TO_GALLERY_SUB_TEXT
} from '../../../components/library/translations';
import * as Pattern from './../../../common/helper/pattern';

function CreateViewLibraryTab(props) {
  let DefaultValue = '';
  if (props.characterRoutesList && props.characterRoutesList.length > 0) {
    DefaultValue = props.characterRoutesList.findIndex(
      characterId =>
        characterId.characterId === null && characterId.name === 'Prologue'
    );
  }
  let addNewStatus = props.backgroundImage !== null ? '' : 'disabled';

  const [handleUpdate, setHandleUpdate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [checkViewModal, setCheckViewModal] = useState(false)

  const handleAddObjectLableClick = () => {
    props.setAddObject(true);
    props.setSelectBackground(false);
  };

  const handleCloseModalViews = () => {
    setCheckViewModal(false);
  };

  const handleModalViews = () => {
    setCheckViewModal(true);
  };


  const handleSelectBackgroundClick = () => {
    props.setSelectBackground(true);
    props.setAddObject(false);
  };

  const changePosition = (item, left, top) => {
    let object = props.viewObjects.slice().reverse()[item.index];
    object.xcoordinate = left;
    object.ycoordinate = top;

    let newObjects = props.viewObjects.slice().reverse();
    newObjects[item.index] = object;

    props.setObject(newObjects.slice().reverse());
    setHandleUpdate(!handleUpdate);
  };

  const callbackFunction = (x, id) => {
    let element = document.getElementById(id);
    if (element != null) {
      element.style.backgroundImage = `url(${x})`;
    }
  };

  if (props.selectBackground === false && props.backgroundImage != null) {
    StoryService.resizeImage(
      props.backgroundImage,
      props.width,
      props.height,
      props.xcoordinate,
      props.ycoordinate,
      callbackFunction,
      'left-content',
      false
    );
  } else {
    let element = document.getElementById('left-content');
    if (element) {
      element.style.backgroundImage = '';
    }
  }

  let defaultValues = {
    x: 0,
    y: 0,
    width: AspectRatioConfig.defaultSelecterWidth,
    height: AspectRatioConfig.defaultSelecterHeight
  };
  if (
    props.selectedBackground != null &&
    props.backgroundImage != null &&
    props.backgroundCopy != null
  ) {
    defaultValues = PlaceBackgroundService.getEditParameterValues(
      props.selectedBackground.specs,
      props.xcoordinate,
      props.ycoordinate,
      props.width,
      props.height
    );
  }

  return (
    <React.Fragment>
      <div className="view-tab-content">
        <div className="row view-form-row">
          <Col className="col-lg-6 pl-0">
            <Row className="pl-0">
              <div className="bold col-lg-8 pl-0">
                <Form.Label>
                  <b>{NAME_OF_THIS_VIEW}</b>
                </Form.Label>
                <p className="commandLabelSuffix text-secondary">
                  {BLANK_SPACES_NOT_ALLOWED}
                </p>
              </div>
            </Row>
            <Row className="mb-4 pl-0">
              <Form
                noValidate
                validated={props.validated}
                onSubmit={e => props.saveForLater(e, props.clickedEventCheck)}
                name="placeBackground"
                id="placeBackground"
              >
                {props.tabButtonClicked !== 'browseTab' && (
                  <React.Fragment>
                    <div className="">
                      <Row>
                        <Form.Control
                          type="textbox"
                          className="form-control"
                          onChange={props.handleChange}
                          maxLength="50"
                          autoComplete="off"
                          pattern={Pattern.VIEW_NAME_PATTERN}
                          required
                          disabled={
                            props.isEditCommand === true ? 'disabled' : ''
                          }
                          value={props.name}
                          name="name"
                        />
                        <Form.Control.Feedback type="invalid">
                          {ERROR_NAME_VIEW}
                        </Form.Control.Feedback>
                      </Row>
                      {
                        // props.clickedEventCheck === false &&
                        <Row>
                          <div className="mb-4 mt-3">
                            {/* <Col> */}
                            <InputGroup>
                              <label className="switch-flip">
                                <Form.Control
                                  type="checkbox"
                                  name="exportToGallary"
                                  className="checkbox-flip"
                                  label="checkbox"
                                  id="view-checkbox"
                                  key="view-checkbox"
                                  onChange={props.handleChange}
                                  value={props.exportToGallary}
                                  // disabled={props.defaultDisabledView}
                                  checked={
                                    props.exportToGallary == true
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <span className="slider-flip round"></span>
                              </label>
                              <span className="label-text">
                                {EXPORT_TO_GALLERY}
                              </span>
                            </InputGroup>
                            <div className="label-sub-text">
                              {EXPORT_TO_GALLERY_SUB_TEXT}
                            </div>
                            {/* </Col> */}
                          </div>
                        </Row>
                      }
                      {// props.clickedEventCheck === false &&
                      props.exportToGallary === true && (
                        <>
                          <Row>
                            <div className="d-flex">
                              <div className="mb-4 mr-4">
                                <Form.Label
                                  className={`d-flex align-items-center form-label`}
                                >
                                  {' '}
                                  {CAPTION_TEXT}
                                  <OverlayTrigger
                                    key={`caption-text`}
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`caption-text`}>
                                        Enter the illustration's caption. This
                                        caption will appear with the
                                        illustration in Fictif's gallery.
                                      </Tooltip>
                                    }
                                  >
                                    <span className="info-icon ml-2"></span>
                                  </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                  required
                                  as="input"
                                  name="captionText"
                                  key="caption"
                                  onChange={props.handleChange}
                                  id="caption"
                                  placeholder="Add caption"
                                  pattern="\s*\S+.*"
                                  // pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                                  defaultValue={
                                    props.captionText === '' ||
                                    props.captionText === null
                                      ? ''
                                      : props.captionText
                                  }
                                  maxLength="48"
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {CAPTION_TEXT_ERROR}
                                </Form.Control.Feedback>
                              </div>
                              <div className="mb-4 ">
                                <Form.Label className={`commandLabel`}>
                                  {' '}
                                  {ADD_TO_GALLERY}{' '}
                                </Form.Label>
                                <Form.Control
                                  required
                                  as="select"
                                  onChange={props.handleChange}
                                  name="characterRoute"
                                  key="addtogallery"
                                  id="addtogallery"
                                  defaultValue={
                                    props.characterRoute === '' ||
                                    props.characterRoute === null
                                      ? props.characterRoutesList.length > 0
                                        ? props.characterRoutesList[
                                            DefaultValue
                                          ].id
                                        : ''
                                      : props.characterRoute
                                  }
                                >
                                  {/* <option>General Gallery</option> */}
                                  {props.characterRoutesList.length > 0 &&
                                    props.characterRoutesList.map(
                                      (characterRoute, index) => {
                                        if (
                                          characterRoute.characterId === null &&
                                          (characterRoute.name === 'Prologue' ||
                                            characterRoute.name === 'Chapters')
                                        ) {
                                          return (
                                            <option value={characterRoute.id}>
                                              General Gallery
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option value={characterRoute.id}>
                                              {characterRoute.name}
                                            </option>
                                          );
                                        }
                                      }
                                    )}
                                </Form.Control>
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <div className="mb-4 mt-3">
                              <InputGroup>
                                <label className="switch-flip">
                                  <Form.Control
                                    type="checkbox"
                                    name="foundInJuicy"
                                    className="checkbox-flip"
                                    label="checkbox"
                                    id="juicy-checkbox"
                                    key="juicy-checkbox"
                                    onChange={props.handleChange}
                                    value={props.foundInJuicy}
                                    // disabled={props.defaultDisabledView}
                                    checked={
                                      props.foundInJuicy == true
                                        ? 'checked'
                                        : ''
                                    }
                                  />
                                  <span className="slider-flip round"></span>
                                </label>
                                <span className="label-text">
                                  {IS_FOUND_IN_JUICY}
                                </span>
                              </InputGroup>
                            </div>
                          </Row>
                          <Row></Row>
                        </>
                      )}
                      {props.clickedEventCheck === false && (
                        <Row>
                          <div className="mb-1">
                            <Form.Label>{FADE_INTO_SCREEN}</Form.Label>
                          </div>
                        </Row>
                      )}
                      <Row>
                        <Button
                          type="submit"
                          className="btn-snacktool btn-snacktool-golden ml-3"
                          disabled={
                            props.name !== '' &&
                            props.background !== null &&
                            props.background !== ''
                              ? false
                              : true
                          }
                          form="placeBackground"
                        >
                          {'Save view'}
                        </Button>
                        <Button
                          type="button"
                          className="btn-snacktool btn-snacktool-golden ml-3"
                          disabled={
                            props.background !== null && showLoader !== true
                              ? false
                              : true
                          }
                          onClick={() => handleModalViews()}
                        >
                          Show Views
                        </Button>
                      </Row>
                    </div>
                    {props.duplicateName && (
                      <Form.Text className="text-danger">
                        {ERROR_DUPLICATE}
                      </Form.Text>
                    )}
                  </React.Fragment>
                )}
              </Form>
            </Row>
          </Col>
          <Col>
            {props.editViewFinal && (
              <div className="warning-popup col-lg-4 p-3">
                <Row>
                  <Col className="col-lg-1">
                    <div className="warning-popup-icon mr-2">
                      <img className="svg-icon-image" src="/images/icons/ic_warning_yellow.svg" />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Changing the name of the view could lead to an invalid
                      script where this view was previously placed.
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </div>
        <div className="row library-view-container p-6">
          <div className="d-flex col-lg-12 p-5">
            <DndProvider backend={Backend}>
              <CreateViewLeftContainer
                selectBackground={props.selectBackground}
                backgroundImage={props.backgroundImage}
                xcoordinate={props.xcoordinate}
                ycoordinate={props.ycoordinate}
                height={props.height}
                width={props.width}
                viewObjects={props.viewObjects}
                setCrop={props.setCrop}
                assetList={props.assetList}
                setHandleUpdate={setHandleUpdate}
                handleUpdate={handleUpdate}
                changePosition={changePosition}
                backgroundCopy={props.backgroundCopy}
                views={props.views}
                selectedBackground={props.selectedBackground}
                defaultValues={defaultValues}
                setEditView={props.setEditView}
                editView={props.editView}
                libraryChange={props.libraryChange}
                setAutoSaveFlag={props.setAutoSaveFlag}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
              />
            </DndProvider>
            <div className="right-content">
              <div id="createViewTabRightPanel">
                <div className="position-relative step-background-view vertical-line mb-0">
                  <span
                    className={
                      props.background !== null && props.background !== ''
                        ? 'circle'
                        : 'circle-empty'
                    }
                  >
                    {props.addObject === true &&
                    props.background !== null &&
                    props.background !== '' ? (
                      <i className="fa fa-check check-font"></i>
                    ) : (
                      1
                    )}
                  </span>
                  <div className="custom-select-dropdown pb-4">
                    <label
                      className="label-title mt-2"
                      onClick={handleSelectBackgroundClick}
                    >
                      Select a background
                    </label>
                    <div
                      className={
                        props.selectBackground === false ? 'd-none' : ''
                      }
                    >
                      <label className="select-label form-label mt-2">
                        Background image
                      </label>
                      <select
                        className="form-control  "
                        onChange={props.handleChange}
                        required
                        name="background"
                      >
                        <option value="">Select one</option>
                        {props.backgroundImages.map((background, index) => {
                          return (
                            <option
                              value={background.id}
                              key={index}
                              selected={
                                background.id === parseInt(props.background)
                                  ? 'selected'
                                  : null
                              }
                            >
                              {background.name}
                            </option>
                          );
                        })}
                      </select>
                      <Form.Control.Feedback type="invalid">
                        Background image selection is mandatory
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="form-group position-relative step-object-view">
                  <span
                    className={
                      props.viewObjects.length > 0 ? 'circle' : 'circle-empty'
                    }
                  >
                    2
                  </span>
                  <div>
                    <label
                      className="label-title mt-2 ml-28"
                      onClick={handleAddObjectLableClick}
                    >
                      Add objects
                    </label>
                    <AddObjectSection
                      addObject={props.addObject}
                      addNewStatus={addNewStatus}
                      setShowAddObjectForm={props.setShowAddObjectForm}
                      viewObjects={props.viewObjects}
                      handleObjectDeleteClick={props.handleObjectDeleteClick}
                      handleEditObjectClick={props.handleEditObjectClick}
                      handleAddObjectClick={props.handleAddObjectClick}
                      handleReorderList={props.handleReorderList}
                      setAutoSaveFlag={props.setAutoSaveFlag}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {checkViewModal && (
        <CheckViews
          showModal={checkViewModal}
          handleModalViews={handleCloseModalViews}
          backgroundImage={props.backgroundImage}
          otherView={props.otherView}
          selectedBackground={props.selectedBackground}
        />
      )}
      </div>
    </React.Fragment>
  );
}

export default CreateViewLibraryTab;

import { takeLatest,call } from "redux-saga/effects";
import * as ActionTypes from '../actions/action-types';
import { postBlockData } from "./api/chapter-api";

export function* postBlockDataWorker({ payload }) {
  try {
     
    const data = yield call(postBlockData, payload);

     
  } catch (e) {
   
  }
}

export function* postBlockDataWatcher() {
  yield takeLatest(ActionTypes.SET_BLOCK_DETAILS, postBlockDataWorker);
}

export default {
  postBlockDataWatcher
};

import { endpoint } from '../../config/app.config';
import * as Constant from './../../common/constants';

export const postAsset = payloads => {
  if (payloads.IsAssetEdit === true) {
    return fetch(
      endpoint +
        `/api/stories/${payloads.storyId}/assets/${payloads.assetData.id}`,
      {
        method: 'PUT',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(endpoint + `/api/stories/${payloads.storyId}/assets`, {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    })
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  }
};

export const postCover = payloads => {
  if (payloads.IsAssetEdit === true) {
    return fetch(
      endpoint +
        `/api/stories/${payloads.storyId}/coverimages/${payloads.assetData.id}`,
      {
        method: 'PUT',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(endpoint + `/api/stories/${payloads.storyId}/coverimages`, {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    })
      .then(res => res.json())
      .then(resp => resp)
      .catch(err => {
        throw err;
      });
  }
};

export const uploadAsset = payloads => {
  return fetch(
    endpoint + `/api/stories/${payloads.storyId}/assets/batch/upload`,
    {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const uploadCover = payloads => {
  return fetch(
    endpoint + `/api/stories/${payloads.storyId}/coverimages/batch/upload`,
    {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const fetchAsset = payload => {
  let url = getFetchAssetUrl(payload);

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const fetchCover = payload => {
  let url = `${endpoint}/api/stories/${payload.storyId}/coverimages`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const rollbackAsset = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/assets/rollback?name=${payload.name}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(resp => resp.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

const getFetchAssetUrl = payload => {
  let url = `${endpoint}/api/stories/${payload.storyId}/assets`;

  if (payload.hasOwnProperty('assetType')) {
    switch (payload.assetType) {
      case Constant.ASSET_TYPES_IMAGES[0]:
        url = url + `/type/` + Constant.ASSET_TYPES_IMAGES[0];
        break;
      default:
        break;
    }
  }

  return url;
};

//Delete Asset
export const getDeleteAsset = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/assets/${payload.assetId}/usage`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getLockedAssetById = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/assets/${payload.assetId}/editable`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getAssetById = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/assets/${payload.assetId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getCoverById = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/coverimages/${payload.assetId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const activeCover = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/coverimages/${payload.assetId}/active/${payload.value}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const deleteAsset = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/assets/${payload.assetId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(resp => resp.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const deleteCover = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/coverimages/${payload.assetId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(resp => resp.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const fetchLibraryChangeLog = payload => {
  return fetch(`${endpoint}/api/changelog/${payload.storyId}/library_version`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

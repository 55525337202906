import React, { useEffect } from 'react';
import { CommentStyle } from './styles';
import { Row, Button, Form, Col } from 'react-bootstrap';
import { CommentServices } from './service';
import {
  STORY_PENDING_APPROVAL,
  SNACK_USER_EDITOR,
  SNACK_USER_ADMIN,
  SNACK_USER_WRITER
} from './../../../common/constants';

function CommentBody(props) {
  // isCommentStory={props.isCommentStory}

  useEffect(() => {
    if (props.filterList.reply !== null) {
      props.setEnableReply(false);
    } else {
      props.setEnableReply(true);
    }

    if (props.filterList.commentText !== null) {
      props.setEnableComment(false);
    } else {
      props.setEnableComment(true);
    }
  }, []);

  return (
    <CommentStyle.listHeading>
      <div className="comment-content">
        <Row>
          <Col className="pl-4 pr-4">
            {(!props.showPanels ||
              (props.tokenData.roles === SNACK_USER_EDITOR ||
                props.tokenData.roles === SNACK_USER_ADMIN)) &&
              props.currentStoryStatus === 'Pending Approval' && (
                <button
                  onClick={() =>
                    props.handleResolveComment(props.commentNumber, 'resolved')
                  }
                  type="button"
                  className="btn-snacktool btn-snacktool-golden btn btn-primary"
                >
                  Mark as resolved
                </button>
              )}
          </Col>
        </Row>
        {props.filterList !== undefined &&
          props.filterList.blockName !== null &&
          props.filterList.command !== null && (
            <Row>
              <Col className="cmdscript-box ml-4 mr-4 mt-4">
                <h3>{`Command in script (Block: “${props.filterList.blockName}”)`}</h3>
                <p>
                  {' '}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CommentServices.getCommandColor(
                        props.filterList.command
                      )
                    }}
                  />{' '}
                </p>
              </Col>
            </Row>
          )}
        <Row>
          <Col className="mt-3 pl-4 pr-4">
            {props.showCommentForm === false && (
              <div className="d-flex align-items-center justify-content-between">
                <div className="reply-text">
                  <span>Comment:</span> <p> {props.filterList.commentText}</p>
                </div>

                {props.currentStoryStatus === 'Pending Approval' && (
                  <p className="mb-0">
                    <img
                      alt=""
                      onClick={() => props.showCommentBox(true)}
                      src={`/images/icons/active-edit.svg`}
                      className="mr-1 edit-icon svg-icon-image"
                    />
                    <img
                      alt=""
                      onClick={event =>
                        props.handleDeleteComment(
                          event,
                          props.commentNumber,
                          true
                        )
                      }
                      src={`/images/icons/active-deactivate.svg`}
                      className="delete-icon svg-icon-image"
                    />
                  </p>
                )}
              </div>
            )}

            {props.showEditComment === true &&
              props.currentStoryStatus === 'Pending Approval' &&
              props.showCommentForm === true && (
                <Form
                  id="edit-comment-form"
                  noValidate
                  validated={props.commentValidated}
                >
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <div className="mr-3 reply-input">
                        <Form.Control
                          as="textarea"
                          type="textarea"
                          className="textAreaResize"
                          rows="3"
                          required
                          maxlength="250"
                          minlength="3"
                          autocomplete="off"
                          pattern="[a-zA-Z0-9_-]{3,250}"
                          name="commentText"
                          defaultValue={props.filterList.commentText}
                          onChange={props.handleCommnetFormChange}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </div>
                      <div className="mr-3">
                        <Button
                          type="submit"
                          onClick={event =>
                            props.handleEditComment(event, props.commentNumber)
                          }
                          disabled={props.enableComment}
                          className="btn-snacktool-brown btn-snacktool"
                          form="edit-comment-form"
                        >
                          Update
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => props.showCommentBox(false)}
                          className="btn-snacktool btn-snacktool-golden btn btn-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              )}

            {!props.isViewStory &&
              props.isOwnerOrCollab &&
              props.showPanels &&
              props.showReplyForm === false &&
              props.filterList.reply === null && (
                <button
                  onClick={() => props.showReplyBox(true)}
                  className="reply-btn"
                >
                  Reply
                </button>
              )}

            {props.showReply === true &&
              props.showPanels &&
              props.isOwnerOrCollab &&
              props.showReplyForm === true && (
                <Form
                  id="reply-comment-form"
                  noValidate
                  validated={props.validated}
                  onSubmit={e => props.handleSaveInfo(e)}
                >
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <div className="mt-3  mr-3 reply-input">
                        <Form.Control
                          as="textarea"
                          type="textarea"
                          className="textAreaResize"
                          rows="1"
                          required
                          maxlength="250"
                          minlength="3"
                          autocomplete="off"
                          pattern="[a-zA-Z0-9_-]{3,250}"
                          name="reply"
                          defaultValue={props.filterList.reply}
                          onChange={props.handleFormChange}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </div>
                      <div className="mr-3">
                        <Button
                          type="submit"
                          onClick={event =>
                            props.handleReplyComment(event, props.commentNumber)
                          }
                          disabled={props.enableReply}
                          className="btn-snacktool-brown btn-snacktool"
                          form="reply-comment-form"
                        >
                          Reply
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => props.showReplyBox(false)}
                          className="btn-snacktool btn-snacktool-golden btn btn-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              )}
          </Col>
        </Row>
        {props.filterList.reply !== null && props.showReplyForm === false && (
          <Row>
            <Col className="mt-3 pl-4 pr-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="reply-text">
                  <span>Reply:</span> <p>{props.filterList.reply}</p>
                </div>
                {props.showPanels &&
                  props.isOwnerOrCollab &&
                  !props.isViewStory && (
                    <p className="mb-0">
                      <img
                        alt=""
                        onClick={() => props.showReplyBox(true)}
                        src={`/images/icons/active-edit.svg`}
                        className="mr-1 edit-icon"
                      />
                      <img
                        alt=""
                        onClick={event =>
                          props.handleDeleteComment(
                            event,
                            props.commentNumber,
                            false
                          )
                        }
                        src={`/images/icons/active-deactivate.svg`}
                        className="delete-icon"
                      />
                    </p>
                  )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </CommentStyle.listHeading>
  );
}

export default CommentBody;

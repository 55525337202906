import styled from 'styled-components';
import { colors } from './../../../../common/styles/variables';
 


const ConfirmBox = styled.div`
display: flex;
justify-content: center;

.btn-okay {
  margin-top: 20%;
  width: 120px;
  height: 40px;
}

.submit-asset-label {
  font-size: 24px;
  color: ${colors.shades_shade500};
  margin-top: 0px;
}

.text-note {
  font-size: 14px;
  color: #707070;
}

.cursor-pointer {
  cursor: pointer;
}

`;

const confirmContent = styled.div`
width: 100%;
margin-top: 8%;
text-align: center;
`;
 
const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
`;

 

const successAssetContent = styled.div`
  width: 100%;
  margin-top: 10%;
  text-align: center;
`;

export const DeleteStyle = {
   
  ConfirmBox,
  confirmContent,
  addAssetMessageBox,
  successAssetContent
  
 
};

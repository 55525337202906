import { takeLatest, put, call } from "redux-saga/effects";
import * as ActionTypes from '../actions/action-types';
import { getCharacterList, 
  getBackgroundList,
getBlocksList } from "./api/chapter-api";

export function* getCharactresDataWorker() {
  try {
    const data = yield call(getCharacterList);
    yield put({
      type: ActionTypes.GET_SUCCESS_CHARACTERS_DETAILS,
      payload: data
    });
  } catch (e) {
    yield put({ type: ActionTypes.GET_FAIL_CHARACTERS_DETAILS });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}


export function* getBackgroundDataWorker() {
  try {
    const data = yield call(getBackgroundList);
    yield put({
      type: ActionTypes.GET_SUCCESS_BACKGROUND_DETAILS,
      payload: data
    });
  } catch (e) {
    yield put({ type: ActionTypes.GET_FAIL_BACKGROUND_DETAILS });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });

  }
}

export function* getBlocksDetails() {
  try{
    const data = yield call(getBlocksList);
    yield put({
      type: ActionTypes.GET_SUCCESS_BLOCKS_DETAILS,
      payload: data
    });
    yield put({
      type: ActionTypes.SET_FLOWCHART_BLOCKS,
      payload: data
    });
  }catch(e){
    yield put({type: ActionTypes.GET_FAIL_BLOCKS_DETAILS});
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}

export function* addNewBlockDetails() {
  alert();
  try{
      yield ({
        type:ActionTypes.ADD_NEW_BLOCK
      })
  } catch(e) {
    yield put({type:ActionTypes.FAIL_ADD_NEW_BLOCK})
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}

export function* addNewBlockLink(nodeLinkDataNew){
  try{
    yield ({
      type:ActionTypes.ADD_NEW_LINKS_BLOCK,
      data : nodeLinkDataNew
    })
  } catch(e) {
    yield put({type:ActionTypes.ADD_NEW_LINKS_BLOCK})   
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    }); 
  }
}

export function* updateBlock(nodeId, boxList){
  try{
    yield ({
      type:ActionTypes.UPDATE_BLOCK,
      data : nodeId,
      boxList: boxList
    })
  } catch(e) {
    yield put({type:ActionTypes.ADD_NEW_LINKS_BLOCK})   
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    }); 
  }
}

export function* getCharacterDataWatcher() {
  yield takeLatest(ActionTypes.GET_CHARACTERS_DETAILS, getCharactresDataWorker);
  yield takeLatest(ActionTypes.GET_BACKGROUND_DETAILS, getBackgroundDataWorker);
}

export function* getBlocksDataWatcher() {  
  yield takeLatest(ActionTypes.GET_BLOCKS_DETAILS, getBlocksDetails);
  yield takeLatest(ActionTypes.ADD_NEW_BLOCK, addNewBlockDetails);
  yield takeLatest(ActionTypes.ADD_NEW_LINKS_BLOCK, addNewBlockLink);
  yield takeLatest( ActionTypes.UPDATE_BLOCK, updateBlock);
}

export default {
  getCharacterDataWatcher,
  getBlocksDataWatcher
};

import React from 'react';
const createCharacterImage = (styles, sprites, blobImages) => {
  styles['position'] = 'absolute';

  const findSprite = (spriteLayers, spriteName) => {
    return spriteLayers.filter(sprite => sprite.spriteType === spriteName);
  };
  let actions = [];
  if (sprites !== undefined) {
    // let reverseSprites = [...sprites].reverse()
    // reverseSprites.map((sprite) => {
        // let imgElem = document.createElement('img');
        // imgElem.src = blobImages[sprite.url];
        // imgElem.className = 'characterImage';
        // imgElem.style.cssText = createStyle(styles)
        // actions.push(imgElem)
    // });

    let accesory = findSprite(sprites, 'Accessory');
    let hair = findSprite(sprites, 'Hair');
    let expression = findSprite(sprites, 'Expression');
    let outfit = findSprite(sprites, 'Outfit');
    let imgElem = '';
    
    
    if (outfit !== undefined && outfit.length > 0) {
        imgElem = document.createElement('img');
        imgElem.src = blobImages[outfit[0].url];
        imgElem.className = 'characterImage';
        imgElem.style.cssText = createStyle(styles)
        actions.push(imgElem)
    }
    if (expression !== undefined && expression.length > 0) {
        imgElem = document.createElement('img');
        imgElem.src = blobImages[expression[0].url];
        imgElem.className = 'characterImage';
        imgElem.style.cssText = createStyle(styles)
        actions.push(imgElem)
    }
    if (hair !== undefined && hair.length > 0) {
        imgElem = document.createElement('img');
        imgElem.src = blobImages[hair[0].url];
        imgElem.className = 'characterImage';
        imgElem.style.cssText = createStyle(styles)
        actions.push(imgElem)
    }
    if (accesory !== undefined && accesory.length > 0) {
        imgElem = document.createElement('img');
       imgElem.src = blobImages[accesory[0].url];
       imgElem.className = 'characterImage';
       imgElem.style.cssText = createStyle(styles)
       actions.push(imgElem)
   }
  }

  return actions;
};

const createStyle = styles => {
  let style = '';

  if (styles.hasOwnProperty('left')) {
    style += 'left: ';
    style += styles['left'] + '; ';
  }

  if (styles.hasOwnProperty('right')) {
    style += 'right: ';
    style += styles['right'] + '; ';
  }

  if (styles.hasOwnProperty('bottom')) {
    style += 'bottom: ';
    style += styles['bottom'] + '; ';
  }

  if (styles.hasOwnProperty('transform')) {
    style += 'transform: ';
    style += styles['transform'] + '; ';
  }

  if (styles.hasOwnProperty('animationDelay')) {
    style += 'animation-delay: ';
    style += styles['animationDelay'] + '; ';
  }

  if (styles.hasOwnProperty('animation')) {
    style += 'animation: ';
    style += styles['animation'] + '; ';
  }

  if (styles.hasOwnProperty('position')) {
    style += 'position: ';
    style += styles['position'] + ';';
  }

  return style;
};

const createObjectImage = (
  styles,
  imagePath,
  x,
  y,
  width,
  height,
  objectId,
  isStaticView = false
) => {
  styles['left'] = x;
  styles['top'] = y;
  styles['position'] = 'absolute';
  styles['height'] = height;
  styles['width'] = width;
  if (imagePath !== undefined) {
    return (
      <img
        alt=""
        src={imagePath}
        style={styles}
        className="viewObjectsImages"
        id={isStaticView === false ? `object-${objectId}` : ``}
      />
    );
  }
};

export const GenerateElements = {
  createCharacterImage,
  createObjectImage
};

import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from './../actions/library-action-types';
import * as Actions from './../actions/library-action';
import * as MainActions from './../actions/action-types';
import { checkCharacterNameValidity, 
    saveAsset, 
   
    getCharacters,
    getRouteCharacters,
    getCharactersScript,
    getCharactersById,
    getDeleteCharacters,deleteCharacter,
    getLockedCharactersById
   } from './api/character-api';
import { fetchAsset } from './api/library-api';
import { CharacterService } from './../components/library/character';

export function* checkCharacterValid(payload) {
    try {
        yield put(Actions.setCharacterNameValidityTrue());
        const data = yield call (checkCharacterNameValidity, payload);
        if (data.result === 'Success' && data.body === 'false') {
            yield put(Actions.setCharacterNameValidityTrue());
          
        } else {
            yield put(Actions.setCharacterNameValidityFalse());
            yield put(Actions.setContinueToSelectAssetDisabled());

        }
    } catch (err) {
        yield put(Actions.setCharacterNameValidityFalse());
        yield put(Actions.setContinueToSelectAssetDisabled());
    }
}

export function* setCharacterAssetList(payload) {
  if(payload.authToken){
    try {
        const data = yield call (fetchAsset, payload);
        if (data.result === 'Success') {
            yield put(Actions.setCharacterAssetListSuccess(data.body));
        } else {
            yield put(Actions.setCharacterAssetListFail());
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
  
            }else{
              
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            }

          

            
        }
    } catch (err) {
        yield put(Actions.setCharacterAssetListFail());
    }
  }
}

export function* saveFinalCharacterWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (saveAsset ,payload);
    
        if (data.result === 'Success') {
            yield put(Actions.setSaveFinalCharacterSuccess());
            yield put(Actions.setShowCharacterSaveSuccessModalTrue());
        } else {
            yield put(Actions.setSaveFinalCharacterFail());
            yield put(Actions.setShowCharacterSaveFailModalTrue());
            
            yield put(Actions.setShowCharacterSaveSuccessModalFalse());
        }  
    } catch (err) {
        yield put(Actions.setSaveFinalCharacterFail());
        yield put(Actions.setShowCharacterSaveSuccessModalFalse());
        yield put(Actions.initializeCreateCharacter());
    }
  }
}

export function* checkVariationExistsWorker(payload) {
    // try {
    //     const data = yield call (checkVariationExists, payload.payload);
    //     if (data.result === 'Success') {
    //         if (data.body === 'false') {
    //             yield put(Actions.setVariationExists(false));
    //         }   else {
    //             yield put(Actions.setVariationExists(true));
    //         }
    //     } else {
    //         yield put(Actions.setVariationExists(false));
    //     }
    // } catch (err) {
    //     yield put(Actions.setVariationExists(false));
    // }
}

export function* getCharactersWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (getCharacters, payload);
        if (data.result === 'Success') {
          //  let characterList = CharacterService.sortCharacterListByName(data.body);
            //let characterRouteList = CharacterService.sortCharacterListByName(data.body);
            
            yield put(Actions.getCharactersSuccess(data.body,data.body));
        } else {
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });

          }else{
            
            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
            yield put(Actions.getCharactersFail());
        }
    } catch (err) {
        yield put(Actions.getCharactersFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* getRouteCharactersWorker(payload) {
  if(payload.authToken){
  try {
      const data = yield call (getRouteCharacters, payload);
      if (data.result === 'Success') {
         // let characterList = CharacterService.sortCharacterListByName(data.body);
          yield put(Actions.getRouteCharactersSuccess(data.body));
      } else {
          yield put(Actions.getRouteCharactersFail());
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });

          }else{
            
            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
          

      }
  } catch (err) {
      yield put(Actions.getRouteCharactersFail());
      yield put({
        type: MainActions.SET_API_ERROR,
        
      });
  }
}
}


export function* getCharactersByIdWorker(payload) {
  if(payload.authToken){
   
  try {
    const lockedCharacterData=yield call (getLockedCharactersById, payload);
   
    if(lockedCharacterData.result === 'Success' && lockedCharacterData.body.length===0){
       
      const data = yield call (getCharactersById, payload);
      if (data.result === 'Success') {
          let characterData = data.body;
          yield put(Actions.getCharactersByIdSuccess(characterData));
      } else {
          yield put(Actions.getCharactersByIdFail([]));
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });

          }else{
            
            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
      }
    }
    else{
      if(lockedCharacterData.result !== 'Success'){
        yield put({
          type: MainActions.SET_API_ERROR,
          errorMsg:lockedCharacterData.message[0]
        });
        yield put(Actions.getCharactersLockFail(lockedCharacterData.body));
      }else{
        yield put(Actions.getCharactersByIdFail(lockedCharacterData.body));
      }
      
     
      // yield put({
      //   type: MainActions.SET_API_ERROR,
        
      // });
    }
  } catch (err) {
    
      yield put(Actions.getCharactersByIdFail());
      yield put({
        type: MainActions.SET_API_ERROR,
        
      });
  }
}
}



export function* getCharactersScriptWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (getCharactersScript, payload);
        if (data.result === 'Success') {
            //let characterList = CharacterService.sortCharacterListByName(data.body);
            yield put(Actions.getCharactersForScriptSuccess(data.body));
        } else {
            yield put(Actions.getCharactersForScriptFail());
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
  
            }else{
              
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            }
        }
    } catch (err) {
        yield put(Actions.getCharactersForScriptFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}


export function* getCharacterDeleteWorker(payload) {
  if(payload.authToken){
  try {
    //change api call
      const data = yield call (getDeleteCharacters, payload);
      if (data.result === 'Success') {
          let characterList = data.body;
          yield put(Actions.getCharacterDeleteSuccess(characterList));
      } else {
          yield put(Actions.getCharacterDeleteFail());
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
          }else{

            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
      }
  } catch (err) {
      yield put(Actions.getCharacterDeleteFail());
      yield put({
        type: MainActions.SET_API_ERROR,
        
      });
  }
}
}

export function* CharacterDeleteWorker(payload) {
  if(payload.authToken){
  try {
    //change api
      const data = yield call (deleteCharacter, payload);
     
      if (data.result === 'Success') {
        let characterList = data.body;
        yield put(Actions.deleteCharacterSuccess(characterList));
      } else {
          yield put(Actions.deleteCharacterFail());
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });

          }else{
           
            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
      }
  } catch (err) {
      yield put(Actions.deleteCharacterFail());
      yield put({
        type: MainActions.SET_API_ERROR,
        
      });
  }
}
}

export function* characterDataWatcher() {
    yield takeLatest(ActionTypes.SET_CHARACTER_NAME_VALIDITY, checkCharacterValid);
}

export function* fetchCharacterAssetsDataWatcher() {
    yield takeLatest(ActionTypes.SET_CHARACTER_ASSET_LIST, setCharacterAssetList);
}

export function* saveFinalCharacterWatcher() {
    yield takeLatest(ActionTypes.SET_SAVE_FINAL_CHARACTER, saveFinalCharacterWorker);
}

export function* checkVariationExistsWatcher() {
    yield takeLatest(ActionTypes.CHECK_VARIATION_EXISTS, checkVariationExistsWorker);
}

export function* fetchCharacterDataWatcher() {
    yield takeLatest(ActionTypes.SET_GET_CHARACTERS, getCharactersWorker);
}
export function* fetchRouteCharacterDataWatcher() {
  yield takeLatest(ActionTypes.SET_GET_ROUTE_CHARACTERS, getRouteCharactersWorker);
}




export function* fetchCharacterByIDDataWatcher() {
  yield takeLatest(ActionTypes.GET_CHARACTERS_BY_ID, getCharactersByIdWorker);
}


 




export function* fetchCharacterDeleteDataWatcher() {
  yield takeLatest(ActionTypes.GET_CHARACTER_DELETE, getCharacterDeleteWorker);
}


export function* CharacterDeleteDataWatcher() {
  yield takeLatest(ActionTypes.CHARACTER_DELETE, CharacterDeleteWorker);
}


export function* fetchCharacterForScriptDataWatcher() {
    yield takeLatest(ActionTypes.SET_GET_CHARACTERS_SCRIPT, getCharactersScriptWorker);
}

export default {
    characterDataWatcher,
    fetchCharacterForScriptDataWatcher,
    fetchCharacterAssetsDataWatcher,
    saveFinalCharacterWatcher,
    checkVariationExistsWatcher,
    fetchCharacterDataWatcher,
    fetchCharacterDeleteDataWatcher,
    CharacterDeleteDataWatcher,
    fetchCharacterByIDDataWatcher,
    fetchRouteCharacterDataWatcher
}
import * as actionTypes from './password-recovery-action-types';

export const passwordRecovery = (email) => {
    return {
        type: actionTypes.SET_PASSWORD_RECOVERY,
        email: email
    }
}

export const passwordRecoverySuccess = () => {
    return {
        type: actionTypes.SET_PASSWORD_RECOVERY_SUCCESS
    }
}

export const passwordRecoveryFail = (payload) => {
    return {
        type: actionTypes.SET_PASSWORD_RECOVERY_FAIL,
        payload: payload
    }
}

export const resetPasswordRecovery = () => {
    return {
        type: actionTypes.RESET_PASSWORD_RECOVERY
    }
}

export const changePassword = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_RECOVERY
    }
}

export const setChangePassword = (payload) => {
    return {
        type: actionTypes.SET_CHANGE_PASSWORD,
        payload: payload
    }
}

export const setChangePasswordSuccess = (payload) => {
    return {
        type: actionTypes.SET_CHANGE_PASSWORD_SUCCESS,
        payload: payload
    }
}

export const setChangePasswordFail = (payload) => {
    return {
        type: actionTypes.SET_CHANGE_PASSWORD_FAIL,
        payload: payload
    }
}

export const resetChangePassword = () => {
    return {
        type: actionTypes.RESET_CHANGE_PASSWORD
    }
}
import React from 'react';
import { Modal } from 'react-bootstrap';
import * as translations from './../translations';
import AddElementTab from './AddElementTab';
import * as Constant from './../constant';
import AddUIElementBottom from './AddUIElementBottom';

function AddUIElementModal(props) {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.uploadModal}
      onHide={props.hideModal}
      backdrop="static"
      dialogClassName="add-asset-modal-dialog"
    >
      {props.activeTabKey === Constant.FIRST_TAB_KEY && (
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
            {translations.UPLOAD_NEW_ELEMENT_IMAGE}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <AddElementTab
          activeTabKey={props.activeTabKey}
          files={props.files}
          setFiles={props.setFiles}
          showClearFile={props.showClearFile}
          setShowClearFile={props.setShowClearFile}
          setActiveTabKey={props.setActiveTabKey}
          progress={props.progress}
          setSpecs={props.setSpecs}
          message={props.message}
          successModal={props.successModal}
        />
        <AddUIElementBottom
          activeTabKey={props.activeTabKey}
          showClearFile={props.showClearFile}
          handleClearFile={props.handleClearFile}
          handleSaveImage={props.handleSaveImage}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddUIElementModal;

import styled from "styled-components";
import { colors } from '../../common/styles/variables';


export const StoryContainer = styled.div`
    margin-top: 0px;
    margin-left: 0px;
    background: #F0EEEE;
    opacity: 1;
    position: relative;

`;

export const Row = styled.div`
padding : 60px 140px 0;
height: calc(100vh - 60px);
overflow: auto;
//margin-top: 40px;
background-image:url('../../images/homebg.png');
background-repeat: 'no-repeat';
background-size:100% 100%;
`


export const StoryCreateButton = styled.div`
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    // background-color: ${colors.primary_green}
    // box-shadow: 0px 8px 6px #45C7AD4D;
    border-radius: 40px;
    opacity: 1;
    border-style: none;
    position: absolute;
    //top: -40px;
`

export const StoryCreateLabel = styled.label`
    text-align: center;
    font-weight : 400;
    font-size : 14px;
    line-height : 16px;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
`;
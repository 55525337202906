import React from 'react';

const showError = rejectedFiles => {
  let file = rejectedFiles[0];
  let fileType = getFileType(file);
  let error = '';
  switch (fileType) {
    case 'image':
      error = 'Invalid file type'; //translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
      break;
    case 'audio':
      error = 'Invalid file type'; //translations.ASSET_AUDIO_TYPE_ERROR_MESSAGE;
      break;
    default:
      error = 'Invalid file type'; // translations.FILES_TYPE_NOT_ACCEPTED;
  }

  return error;
};

const checkFileSize = (rejectedFiles, maxSize) => {
  return rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
};

const createEditPreviewThumb = files => {
  let thumbs = files.map(file => (
    <div>
      {
        <img
          alt=""
          src={files[0]}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      }
    </div>
  ));

  return thumbs;
};

const createPreviewThumb = files => {
  let thumbs = files.map(file => (
    <div key={file.name}>
      {getFileType(file) === 'image' && (
        <img
          alt=""
          src={file.preview}
          key={file.name}
          height="100%"
          width="100%"
          alt="previewImage"
        />
      )}
      {getFileType(file) === 'audio' && (
        <audio
          autoPlay={true}
          src={file.preview}
          controls
          className="audio-play"
          key={file.name}
          alt="previewAudio"
        />
      )}
    </div>
  ));

  return thumbs;
};

const getFileType = file => {
  if (file && file !== null) {
    if (file.type) return file.type.split('/')[0];
  }

  return null;
};

const createPreviewUrl = acceptedFiles => {
  let newFiles = acceptedFiles[0];
  return [newFiles].map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
  );
};

const getRouteTypeLabel = (routeTypeName, displayName) => {
  let explodeName = `${displayName} (${routeTypeName}) route`;
  return explodeName;
};

const getRouteTypeLabelName = (charList, charId) => {
  let explodeName = '';
  for (let i = 0; i < charList.length; i++) {
    if (charList[i].id === charId) {
      explodeName = charList[i].characterName;
    }
  }
  return explodeName;
};

const checkRouteExist = (routData, charId) => {
  let isExist = false;
  if (charId) {
    for (let i = 0; i < routData.length; i++) {
      if (routData[i].characterId !== null) {
        if (
          routData[i].characterId.toString() === charId &&
          routData[i].scenes.length > 0
        ) {
          isExist = true;
        }
      }
    }
  }

  return isExist;
};

const checkChapterExist = (routData, charId) => {
  let isExist = false;
  if (charId) {
    for (let i = 0; i < routData.length; i++) {
      if (routData[i].characterId !== null) {
        if (routData[i].characterId.toString() === charId.toString()) {
          if (routData[i].scenes && routData[i].scenes.length > 0) {
            isExist = true;
          }
        }
      }
    }
  }
  return isExist;
};

const isChapterNameExists = (routeList, newChapterName) => {
  let chapterExists = false;

  routeList.forEach((route, rIndex) => {
    route.scenes.forEach((chapter, cIndex) => {
      if (chapter.name.toLowerCase() === newChapterName.toLowerCase()) {
        chapterExists = true;
      }
    });
  });

  return chapterExists;
};

const editChapterWithParticularId = (chapterData, chapterId, data) => {
  let indexes = getChapterIndex(chapterData, chapterId);
  let chapter = chapterData[indexes.routeIndex].scenes[indexes.chapterIndex];

  chapter.name = data.name;
  chapter.authorNotes = data.authorNotes;
  chapter.description = data.description;
  chapter.caption = data.caption;
  chapter.endingDesc = data.endingDesc;
  chapter.startEnding = data.startEnding;

  return [chapter];
};

const getChapterIndex = (routeList, chapterId) => {
  let routeIndex = null;
  let chapterIndex = null;

  routeList.forEach((route, rIndex) => {
    route.scenes.forEach((chapter, cIndex) => {
      if (parseInt(chapter.id) === parseInt(chapterId)) {
        routeIndex = rIndex;
        chapterIndex = cIndex;
      }
    });
  });

  return { routeIndex: routeIndex, chapterIndex: chapterIndex };
};

const getRoute = (routeList, routeId) => {
  let route = routeList.filter(route => route.id === routeId);

  return route[0];
};

const showLibraryChangeLog = changeLog => {
  if (
    changeLog.assets.length > 0 ||
    changeLog.characters.length > 0 ||
    changeLog.gameInterfaces.length > 0 ||
    changeLog.views.length > 0
  ) {
    return true;
  }

  return false;
};

export const RouteServices = {
  createPreviewThumb,
  checkFileSize,
  createPreviewUrl,
  showError,
  createEditPreviewThumb,
  getFileType,
  getRouteTypeLabel,
  getRouteTypeLabelName,
  isChapterNameExists,
  editChapterWithParticularId,
  checkRouteExist,
  checkChapterExist,
  getChapterIndex,
  getRoute,
  showLibraryChangeLog
};

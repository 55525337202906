import React from 'react';
import { LibraryStyle } from './../style/style';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { LibraryServices } from './../service';

function ViewsChangeLog(props) {
    return (
        <React.Fragment>
            <LibraryStyle.listTable >
                {
                    props.views.length > 0 &&
                    <Table responsive="md">
                        <thead className="bg-light">
                            <tr className="table-head-row">
                                <th className="text-left">VIEW NAME</th>
                                <th className="text-left">BACKGROUND IMAGE</th>
                                <th>EXPORTED TO GALLERY</th>
                                <th>
                                    LAST CHANGE ON
                                    <i className={`fa fa-sort-${props.viewsLogSort}`} 
                                        onClick={props.handleSortClick} 
                                        sort-name="views"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                props.views.map((view) => {
                                    return <tr className="table-body-row">
                                        <td className={`asset-name-col text-left`}>
                                            {LibraryServices.makeCamelCaseString(view.name)}
                                        </td>
                                        <td className="text-left">
                                            {LibraryServices.makeCamelCaseString(view.asset.name)}
                                        </td>
                                        <td>{view.exportToGallary === true ? <img src="/images/icons/ic_check_circle_24px_gray.svg" />: null}</td>
                                        <td className="blur-text">{moment(new Date(view.updatedAt)).format('MM-DD-YYYY')}</td>
                                    </tr>
                                })
                            }
                        </tbody>     
                    </Table>
                }
                {
                    props.views.length === 0 &&
                    <div className="no-result">
                        <h3 className="text-center">No Views have been changed</h3>
                    </div>
                }
            </LibraryStyle.listTable>
        </React.Fragment>
    )
}

export default ViewsChangeLog
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ModalStyled } from './style/modal-style';

const DeleteMyAccount = ({
  label,
  note,
  label_next,
  onClose,
  handleDeleteStoryCall,
  roleAdmin
  // deleteStoryValue,
  // onChangeDeleteStory
}) => {
  const [deleteStoryValue, setDeleteStoryValue] = useState(false);

  const onChangeDeleteStory = () => {
    setDeleteStoryValue(!deleteStoryValue);
  };

  const onConfirmClick = () => {
    handleDeleteStoryCall(deleteStoryValue);
  };

  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="delete-account-modal"
    >
      <Modal.Body>
        <ModalStyled.addAssetMessageBox>
          <ModalStyled.successAssetContent>
            <div className="success-icon mb-3">
              <img alt="" className="mediumIcon" src="/images/icons/ic_warning_24px.svg" />
            </div>
            <div className="submit-asset-label" data-testid="success-label">
              {label}
              <div>{label_next}</div>
            </div>
            <div className="text-muted text-note" data-testid="success-note">
              {note}

              {roleAdmin !== '[ROLE_EDITOR]' && roleAdmin !== '[ROLE_ADMIN]' && (
                <div className="mt-3">
                  <Form.Group className="commandLabelSuffix custom-checkbox">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      id="styled-checkbox1"
                      onChange={() => onChangeDeleteStory()}
                      checked={deleteStoryValue === true ? 'checked' : ''}
                    />
                    <label for="styled-checkbox1" className="commandLabelDelete">
                      {`Also delete all the stories I created`}{' '}
                    </label>
                  </Form.Group>
                  {/* <input
                                type="checkbox"
                                onChange={() => onChangeDeleteStory()}
                                checked={deleteStoryValue === true ? 'checked' : ''}
                            />
                            Also delete all the stories I created */}
                </div>
              )}
            </div>
            <div className="mt-5 d-flex align-items-center justify-content-between">
              <Button
                className="btn-snacktool btn-snacktool-golden"
                onClick={() => onClose()}
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  onConfirmClick();
                  onClose();
                }}
                className="btn-snacktool btn-snacktool-golden"
              >
                Yes, delete my account
              </Button>
            </div>
          </ModalStyled.successAssetContent>
        </ModalStyled.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteMyAccount;

import React  from 'react';
import { InfoStyle} from './style';
 
 

function  LoginTutorialFrame(props) {
 
   
  
  return (
   
        
      <InfoStyle.addAssetMessageBox>
           {props.element && <InfoStyle.successAssetContent>
              <div className="img-bg" style={{ backgroundColor: `${props.element.bgColor}` }}>
                <div className="img-wrapper">
                {props.index===0 &&  <img alt=''  src={props.element.bgImag}/>}
                {props.index===1 &&  <img alt=''  src={props.element.bgImag}/>}
                {props.index===2 &&  <img alt=''  src={props.element.bgImag}/>}
                
              </div>
              </div>
              <div className="content-wrapper">
              <div  data-testid="success-label" className="submit-asset-label">{props.element.title}</div>
              <div  data-testid="success-note" className="submit-asset-subtitle ">{props.element.subTitle}</div>
             
              <div className="d-flex justify-content-around align-items-center mt-4">
                <button style={{ visibility: `${props.index>0?'visible':'hidden'}` }}  onClick={()=>props.handlePrevClick(props.index)} className="btn-snacktool btn-snacktool-tertiary">{props.element.prevButtonText} </button> 
                <div className="dot-wrapper d-flex">
                  <span className={`dot  ${props.index===0?'active':''}`}></span>
                  <span className={`dot  ${props.index===1?'active':''}`}></span>
                  <span className={`dot  ${props.index===2?'active':''}`}></span>
                </div>
                <button className={`${props.index===2?'btn-snacktool btn-snacktool-golden ':'btn-snacktool btn-snacktool-tertiary'}`}  onClick={()=>props.handleNextClick(props.index)} >{props.element.NexButtonTitle} </button>
              </div>
              </div>
              
              <div>
              
              </div>

             
           
            </InfoStyle.successAssetContent>}
        </InfoStyle.addAssetMessageBox>
     
  );
  }

export default LoginTutorialFrame
import React from 'react';
import { Modal } from 'react-bootstrap';
import PlaceBackgroundTabs from './PlaceBackgroundTabs';
import * as Constant from './Constant';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

function PlaceBackground(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.onHideModal}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="header-text">
          Create or select a view
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          {props.showModal && props.stepsViewEnabled === true && (
            <Steps
              enabled={props.stepsViewEnabled}
              steps={Constant.stepViewConstant}
              initialStep={0}
              onExit={props.onExit}
              options={Constant.INTRO_OPTIONS}
            />
          )}
          <PlaceBackgroundTabs
            activeKey={props.activeKey}
            setActiveKey={props.setActiveKey}
            backgroundImages={props.backgroundImages}
            handleChange={props.handleChange}
            setCrop={props.setCrop}
            onHideModal={props.onHideModal}
            saveForLater={props.saveForLater}
            background={props.background}
            xcoordinate={props.xcoordinate}
            ycoordinate={props.ycoordinate}
            height={props.height}
            width={props.width}
            aspect={props.aspect}
            name={props.name}
            zoom={props.zoom}
            clickedEventCheck={props.clickedEventCheck}
            handleClickEvent={props.handleClickEvent}
            handleSaveForLaterClickEvent={props.handleSaveForLaterClickEvent}
            fadeIn={props.fadeIn}
            validated={props.validated}
            views={props.views}
            duplicateName={props.duplicateName}
            handleBrowseSelection={props.handleBrowseSelection}
            handleDuplicateClick={props.handleDuplicateClick}
            handleViewSearch={props.handleViewSearch}
            searchViews={props.searchViews}
            backgroundCopy={props.backgroundCopy}
            searchText={props.searchText}
            handlePlaceViewScript={props.handlePlaceViewScript}
            setShowAddObjectForm={props.setShowAddObjectForm}
            viewObjects={props.viewObjects}
            handleObjectDeleteClick={props.handleObjectDeleteClick}
            handleEditObjectClick={props.handleEditObjectClick}
            handleAddObjectClick={props.handleAddObjectClick}
            handleReorderList={props.handleReorderList}
            assetList={props.assetList}
            setObject={props.setObject}
            handleViewEditClick={props.handleViewEditClick}
            selectBackground={props.selectBackground}
            setSelectBackground={props.setSelectBackground}
            addObject={props.addObject}
            setAddObject={props.setAddObject}
            setEditView={props.setEditView}
            editView={props.editView}
            libraryChange={props.libraryChange}
            characterRoutesList={props.characterRoutesList}
            otherView={props.otherView}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PlaceBackground;

import React, { useEffect } from 'react';
import { PlaceBgStyle } from './style';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ImageMapper } from './../../../common/helper/image-mapper';
import * as Config from './../../../config/aspectRatio.config';
import { aspectRatio } from '../../../common/constants';

function DraggableObject(props) {
    const { id, left, top, index } = props
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: 'box', id, left, top, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    let splitSpecs = ImageMapper.getOriginalSpecsOfImage(props.object.asset.specs);
    let originalWidth = splitSpecs[0];
    let originalHeight = splitSpecs[1];

    let objectViewWidth = Config.aspectRatioParameters[aspectRatio].objectViewWidth;
    let objectViewHeight = Config.aspectRatioParameters[aspectRatio].objectViewHeight;

    if (props.libraryChange) {
        objectViewWidth = Config.aspectRatioParameters[aspectRatio].libraryViewWidth;
        objectViewHeight = Config.aspectRatioParameters[aspectRatio].libraryViewHeight;
    }
    let newSize = ImageMapper.calculateObjectImageRatio(originalWidth,
        originalHeight,
        props.viewWidth,
        props.viewHeight,
        objectViewWidth,
        objectViewHeight,
        Config.aspectRatioCalcThreeByFour);

    let x = props.object.xcoordinate;
    let y = props.object.ycoordinate;
    
    if (props.libraryChange) {
        // Prepare co-ordinates for large view port
        let newCoordinates = ImageMapper.prepareObjectCoordinate(props.object, 
            Config.aspectRatioParameters[aspectRatio].objectViewWidth,
            Config.aspectRatioParameters[aspectRatio].objectViewHeight,
            Config.aspectRatioParameters[aspectRatio].libraryViewWidth,
            Config.aspectRatioParameters[aspectRatio].libraryViewHeight,
            Config.aspectRatioCalcThreeByFour)
        x = Math.round(newCoordinates[0], 2);
        y = Math.round(newCoordinates[1], 2);
    }

    return (
        <div ref={drag}>
            <PlaceBgStyle.objectLayerImages
                key={`objectImage-${props.index}`}
                src={props.asset.awsPath}
                x={x}
                y={y}
                draggable
                id={`objectLayer-${props.index}`}
                interactivecheck={props.object.interactive ? 'interactive' : ''}
                className={props.object.hidden ? 'fadeObject' : ''}
                height={newSize[1]}
                width={newSize[0]}
            />
        </div>
    );
}

export default DraggableObject
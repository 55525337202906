import React from 'react';
import { Button } from 'react-bootstrap';

function PasswordRecoverySuccess(props) {
    return ( 
        <div className="request-account-success text-center">
            {/* <img src="/images/icons/ic_check_circle.svg" className="check-img" /> */}
            <h3>The password reset code has been sent</h3>
            <p>Please check your inbox and follow the instructions in the email.</p>
            <Button
                type="button"
                className="btn-snacktool btn-snacktool-golden"
                onClick = {props.backToChangePassword}>
                    Okay
            </Button>
        </div>
    )
}

export default PasswordRecoverySuccess
import React, { useEffect } from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import {
  STORY_PENDING_APPROVAL,
  SNACK_USER_EDITOR,
  SNACK_USER_ADMIN
} from './../../../common/constants';

const TextEditorHead = props => {
  let isLocked = true;
  let saveLocked = true;
  if (props.commandList && props.commandList.lockAcquired === true) {
    if (props.commandList.lockAcquiredBy === props.tokenDataUser.userId) {
      isLocked = true;
      saveLocked = true;
    } else {
      isLocked = false;
      saveLocked = false;
    }
  }

  if (props.commandList && props.commandList.lockAcquired === false) {
    isLocked = false;
    saveLocked = true;
  }

  useEffect(
    () => {
      const backdropElement = document.getElementById('story-heding');
      if (backdropElement)
        backdropElement.addEventListener('click', hideCommentPoup);
    },
    props.editIconState,
    props.expandEditor,
    []
  );

  const hideCommentPoup = () => {
    props.changeCommentPoup();
  };

  const indentClick = () => {
    if (document.getElementById('button-tooltip')) {
      document.getElementById('button-tooltip').style.display = 'none';
    }
    props.clicktoIndent(true);
  };

  const validateClick = () => {
    if (document.getElementById('button-tooltip_validate')) {
      document.getElementById('button-tooltip_validate').style.display = 'none';
    }
    props.handleClickValidate();
  };

  // const renderTooltipIndent = props => {
  //   return (
  //     <Tooltip id="button-tooltip" className="tooltip_textEditor" {...props}>
  //       Add an indentation to all the commands inside every block. This way the
  //       script will be more easy to read.
  //     </Tooltip>
  //   );
  // };

  const renderTooltipValidate = props => {
    return (
      <Tooltip
        id="button-tooltip_validate"
        className="tooltip_textEditor"
        {...props}
      >
        <div
          className="tooltip_textEditor"
          dangerouslySetInnerHTML={{
            __html: `Verify the correct syntax of the script, highlighting errors in order to:
          <br/><br/>- Preview the chapter
        <br/>- Edit commands with the Command Panel`
          }}
        ></div>
      </Tooltip>
    );
  };

  const backToChapter = isReleaseLock => {
    props.showHideVariableWindow(true);
    props.backToScene(isReleaseLock);
  };

  return (
    <div
      className={`${
        props.commandList &&
        props.commandList.lockAcquired === true &&
        props.commandList.lockAcquiredBy !== props.tokenDataUser.userId
          ? 'panel-header panel-header-dark'
          : 'panel-header'
      }`}
    >
      <div
        className="back-arrow"
        onClick={
          isLocked ? () => backToChapter(true) : () => backToChapter(false)
        }
      >
        <i className="fa fa-angle-left" />
      </div>
      <div
        className={`${
          props.commandList &&
          props.commandList.lockAcquired === true &&
          props.commandList.lockAcquiredBy === props.tokenDataUser.userId
            ? 'flex-item story-heading'
            : 'flex-item story-heading-long'
        }`}
        id="story-heding"
      >
        <div className="storyName">{props.storyTitle} </div>
        <div className="sceneName"> {props.storySubTitle} </div>
      </div>

      <div className="lock-container flex-item">
        {props.commandList &&
          props.commandList.lockAcquired === true &&
          props.commandList.lockAcquiredBy === props.tokenDataUser.userId && (
            <span className="lock-block">
              {/* <img alt="" className="checkIcon" src="/images/icons/ic_httpsw_24px.svg" /> */}
              You are in edit mode.
            </span>
          )}
        {props.commandList &&
          props.commandList.lockAcquired === true &&
          props.commandList.lockAcquiredBy !== props.tokenDataUser.userId && (
            <span className="lock-block">
              {/* <img alt="" className="checkIcon" src="/images/icons/ic_httpsw_24px.svg" /> */}
              You are in view-only mode.
            </span>
          )}
      </div>

      <div className="flex-item margin-top-minus">
        <Dropdown
          // alignRight={'start'}
          drop={'start'}
          className="btnMainStory"

          // title={<i className="fa fa-ellipsis-v" />}
        >
          <Dropdown.Toggle className="btnMainStory">
            <i className="fa fa-ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={`${
              props.expandEditor !== true ? '' : 'leftSide'
            } dropDownMenuCss`}
            style={{}}
          >
            {!props.isViewStory && saveLocked && props.showPanels && (
              <Dropdown.Item
                className="dropDownItemCss"
                onClick={() => indentClick()}
                disabled={props.indentButtonClick === true}
              >
                <i className="fa fa-indent" aria-hidden="true"></i> Indent
                script
              </Dropdown.Item>
            )}

            <Dropdown.Item
              className="dropDownItemCss"
              onClick={() => props.setFindReplace(true)}
            >
              <i className="fa fa-search" aria-hidden="true"></i> Find and
              replace{' '}
              {window.navigator.userAgent.indexOf('Mac') != -1
                ? '(⌘+f OR ⌃+f)'
                : '(Ctrl+f)'}
            </Dropdown.Item>
            <Dropdown.Item
              className="dropDownItemCss"
              onClick={() => props.fontFamilyModal(true)}
            >
              <i className="fa fa-font" aria-hidden="true"></i> Change font
            </Dropdown.Item>
            <Dropdown.Item
              className={`dropDownItemCss ${
                props.spellCheckerOn ? `active` : ``
              }`}
              onClick={() => props.spellChecker()}
            >
              <i className="fa fa-file-text" aria-hidden="true"></i> Spell
              Checker
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {
        <>
          {
            <div className="add-save-line">
              <OverlayTrigger
                placement="bottom"
                key={`button-tooltip_validate`}
                trigger={['hover', 'focus']}
                overlay={renderTooltipValidate}
              >
                <button
                  className={`btn-snacktool mr-3 btn-validate btn-snacktool-golden`}
                  disabled={
                    props.textEditorValidate || props.textEditorloading === true
                      ? true
                      : false
                  }
                  onClick={() => validateClick()}
                >
                  {props.textEditorValidate === true
                    ? `Validate script`
                    : `Validate script`}
                </button>
              </OverlayTrigger>
              {!props.isViewStory && saveLocked && props.showPanels && (
                <button
                  onClick={e => props.handleSaveClick(e)}
                  className="btn-snacktool btn-snacktool-golden save-btn"
                  disabled={props.saveButtonEnable ? false : true}
                >
                  {' '}
                  Save
                </button>
              )}
              {props.compareFlag &&
                (props.tokenDataUser.roles === SNACK_USER_EDITOR ||
                  props.tokenDataUser.roles === SNACK_USER_ADMIN) &&
                props.currentStoryStatus === STORY_PENDING_APPROVAL && (
                  <button
                    className="btn-snacktool-golden btn-snacktool mr-3"
                    onClick={() =>
                      props.handleChangeLogScript(props.currentSceneId)
                    }
                  >
                    {'See latest changes'}
                  </button>
                )}
              {(!props.showPanels ||
                props.tokenDataUser.roles === SNACK_USER_EDITOR ||
                props.tokenDataUser.roles === SNACK_USER_ADMIN) &&
                props.currentStoryStatus === STORY_PENDING_APPROVAL && (
                  <button
                    onClick={() =>
                      props.onAddCommentClick(
                        props.cursorPostion + 1,
                        props.commandList.commands,
                        props.cursorPostion
                      )
                    }
                    className="btn-snacktool btn-snacktool-golden mr-3"
                  >
                    <img
                      alt=""
                      src="/images/icons/add-circle.svg"
                      width="14"
                      height="14"
                      className="mr-2"
                    />
                    {props.commentLineNo === null
                      ? 'Add comment to chapter'
                      : `Add comment to line ${props.cursorPostion + 1}`}
                  </button>
                )}
            </div>
          }
        </>
      }
    </div>
  );
};

export default TextEditorHead;

import React from 'react';
import { Style } from './style';
import { Row,Modal, Col,Button} from 'react-bootstrap';

function  Warning(props) {

    return (
      <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show = {props.showModal}
      backdrop='static'
      dialogClassName="add-asset-modal-dialog"
      >
        
                <Modal.Body>
        <Style.addAssetMessageBox>
            <Style.successAssetContent>
                <Row>
                    &nbsp;
                </Row>
                <Row>
                    <Col className="submit-asset-label">
                        Changing the name of the view could lead to an invalid script where this view was previously placed
                    </Col>
                </Row>

                <Row><Col className="submit-asset-label">
                <Row>
                    &nbsp;
                </Row><Row>
                    &nbsp;
                </Row> 
                <Button onClick={() => props.handleWarningClick(null, true)} className="btn-snacktool btn-snacktool-golden btn btn-primary" 
                        >
                         Okay
                    </Button></Col></Row>

                <div></div>
            </Style.successAssetContent>
        </Style.addAssetMessageBox>
        </Modal.Body>
        </Modal>
    );
}

export default Warning
import React from 'react';
import { LibraryStyle } from './../style/style';
import ListHead from './ListHead';
import ListContent from './ListContent';

function ListElements(props) {
    return (
        <LibraryStyle.listContentPanelInterface>
            <ListHead 
                handleFilterClick = {props.handleFilterClick}
                filters = {props.filters}
                storyTitle = {props.storyTitle}
                setShowUIElementPreview={props.setShowUIElementPreview} />
            <ListContent listInterface = {props.listInterface} 
                loading = {props.loading}
                showModal = {props.showModal}
                resetElement = {props.resetElement}
                gameFonts = {props.gameFonts}
                setFonts = {props.setFonts}
                handleGameFontChange = {props.handleGameFontChange} />
        </LibraryStyle.listContentPanelInterface>
    )
}

export default ListElements
import React, { useState } from 'react';
import * as Constants from './../../common/constants';


const MarkAsCompletedForm = (props) => {

    const [markStoryValue,setMarkStoryValue ] = useState(props.routeValue);


    const handleMarkStoryRoute = (event, routeId) => {
         
        setMarkStoryValue(markStoryValue)
        props.handleMarkStoryRoute(event.target.value, routeId)
    }

  let assetTypes = Constants.ROUTE_TYPES_STATUS;

  let assetTypeOptions = assetTypes.map((value, key) => {
    return (
      <option key={key} value={value}>
      {value}
      </option>
    );
  });
 

    return (
        <div className="ml-1 align-items-center custom-select-dropdown">
 
<select
                className="form-control  "
                title={markStoryValue}
                onChange={e => handleMarkStoryRoute(e,  props.routeId)}
               disabled={   props.showPanels===false || 
                                props.isViewStory ===true ||  props.storyStatus !== 'New' && props.storyStatus !== 'In Progress' && props.storyStatus !== 'In Revision'? true : false}
                defaultValue={markStoryValue}
                name="background"
              >
                
                {assetTypeOptions}
              </select>
            {/* <label className="switch-flip">
                <input
                    type="checkbox"
                    name='reverseChapter'
                    key={`key_${props.routeId}`}
                    className='checkbox-flip'
                    label='Reversed chapter.'
                    checked={markStoryValue === true || markStoryValue === 'true' ? 'checked' : ''}
                    onChange={(event) => handleMarkStoryRoute(event, props.routeValue, props.routeId)}
                />
                <span className="slider-flip round"></span>
            </label>
            <p className="bold-text mb-0">{props.completeForStory === true ? `Mark as a complete story` : `Mark as a complete route`}</p> */}
        </div>
    )
}

export default MarkAsCompletedForm
import React from 'react';
import { LibraryStyle } from './../style/style';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { LibraryServices } from './../service';

function CharactersChangeLog(props) {

    return (
        <React.Fragment>
            <LibraryStyle.listTable >
                {
                    props.characters.length > 0 &&
                    <Table responsive="md">
                        <thead className="bg-light">
                            <tr className="table-head-row">
                                <th className="text-left">CHARACTER NAME</th>
                                <th className="text-left">CHANGED BY</th>
                                <th>
                                    LAST CHANGE ON
                                    <i className={`fa fa-sort-${props.charactersLogSorts}`} 
                                        onClick={props.handleSortClick} 
                                        sort-name="characters"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                props.characters.map((character) => {
                                    return <tr className="table-body-row">
                                        <td className={`asset-name-col`}>
                                            {LibraryServices.makeCamelCaseString(character.characterName)} ({LibraryServices.makeCamelCaseString(character.displayName)})
                                        </td>
                                        <td className="text-left">
                                            {LibraryServices.makeCamelCaseString(character.updatedBy)}
                                        </td>
                                        <td className="blur-text">{moment(new Date(character.updatedAt)).format('MM-DD-YYYY')}</td>
                                    </tr>
                                })
                            }
                        </tbody>     
                    </Table>
                }
                {
                    props.characters.length === 0 &&
                    <div className="no-result">
                        <h3 className="text-center">No Characters have been changed</h3>
                    </div>
                }
            </LibraryStyle.listTable>
        </React.Fragment>
    )
}

export default CharactersChangeLog
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import ListRow from './ListRow';

import './../style/style.css';
import * as Translations from './../translations';

function ListTable({
  assetFiles,
  fileNames,
  handleAssetFileDelete,
  handleTypeChange,
  handleNameChange,
  handleUploadInfo,
  refreshList,
  loading,
  focusElement,
  dummyAsset,
  setDummyAsset,
  show,
  setShow,
  target
}) {
  useEffect(() => {}, [refreshList]);
  return (
    <LibraryStyle.listTable>
      <Table responsive="md">
        <thead className="bg-light">
          <tr className="table-head-row">
            <th></th>
            <th className="text-left">FILE NAME</th>

            <th className="text-left">TYPE</th>

            <th>REMOVE</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {assetFiles.length > 0 &&
            assetFiles.map((asset, index) => {
              return (
                <ListRow
                  index={index}
                  asset={asset}
                  fileNames={fileNames}
                  handleAssetFileDelete={handleAssetFileDelete}
                  handleNameChange={handleNameChange}
                  handleTypeChange={handleTypeChange}
                  handleUploadInfo={handleUploadInfo}
                  refreshList={refreshList}
                  focusElement={focusElement}
                  dummyAsset={dummyAsset}
                  setDummyAsset={setDummyAsset}
                  show={show}
                  setShow={setShow}
                  target={target}
                />
              );
            })}

          {loading === false && <div></div>}
        </tbody>
      </Table>
    </LibraryStyle.listTable>
  );
}

export default ListTable;

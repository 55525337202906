import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
const ErrorList = props => {
  if (props.errorInfo.type !== 'warning') {
    return (
      <tr>
        <td className="width10 boldFont">{props.errorInfo.row + 1}</td>
        <td className="red">{props.errorInfo.text}</td>
        <td className="width15 gotoline">
          <span
            onClick={event =>
              props.handleClickGotoLine(event, props.errorInfo.row + 1)
            }
          >
            Go to line
          </span>
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

export default ErrorList;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as LibraryActions from './../../actions/library-action';
import ViewsContent from './../../components/views/views-content';
import LibraryMenus from './../../components/library/LibraryMenu';
import { StoryService } from './../../components/story/service';
import * as AspectRatioConfig from './../../config/aspectRatio.config';
import { ImageMapper } from './../../common/helper/image-mapper';
import * as PlaceBackgroundActions from './../../actions/place-background-action';
import * as StoryAction from '../../actions/story-action';
import AddObjectsModal from './../../components/story/place-background/AddObjectsModal';
import CreateViewModal from './../../components/story/place-background/CreateViewModal';
import LockedAssets from './../../components/library/list-assets/delete-assets/LockedAssets';

function Views(props) {
  const [tabButtonClicked, setTabButtonClicked] = useState('placeView');
  const [clickedEventCheck, setClickedEventCheck] = useState(true);
  const [duplicateName, setDuplicateName] = useState(props.duplicateName);
  const [searchText, setSearchText] = useState('');
  const [showAddObjectForm, setShowAddObjectForm] = useState(false);
  const [editObjectIndex, setEditObjectIndex] = useState(null);
  const [createViewModal, setCreateViewModal] = useState(false);
  const [browseViewId, setBrowseViewId] = useState(null);
  const [editView, setEditView] = useState(false);
  const [selectBackground, setSelectBackground] = useState(true);
  const [addObject, setAddObject] = useState(false);
  const [collaboratorModal, setCollaboratorModal] = useState(false);
  const [stepsScriptEnabled, setStepsScriptEnabled] = useState(
    props.tutorials.firstTextEditor === false ? true : false
  );
  const [stepsViewEnabled, setStepsViewEnabled] = useState(
    props.tutorials.firstView === false ? true : false
  );
  const [editViewFinal, setEditViewFinal] = useState(false);

  useEffect(() => {
    props.getBackgroundImages(props.storyId, props.authToken);
    props.getCharacterRoutes(props.authToken, props.storyId);
    props.getViews(props.storyId, props.authToken);

    // props.setCollaboratorModalFlag(false);
  }, []);

  useEffect(
    () => {
      console.log('', '');
    },
    collaboratorModal,
    []
  );

  const handleChange = event => {
    if (event.target.name === 'exportToGallary') {
      props.setPlaceBackgroundProperties(
        [event.target.name],
        event.target.value == '' ||
          event.target.value == 'false' ||
          event.target.value == 'off'
          ? true
          : false
      );
    } else if (
      event.target.name === 'caption' ||
      event.target.name === 'captionText'
    ) {
      if (event.target.value.trim() !== '') {
        props.setPlaceBackgroundProperties(
          [event.target.name],
          event.target.value
        );
      }
    } else {
      props.setPlaceBackgroundProperties(
        [event.target.name],
        event.target.value
      );
    }
    if (event.target.name === 'background') {
      props.setPlaceBackgroundProperties('x', 0);
      props.setPlaceBackgroundProperties('y', 0);
      props.setPlaceBackgroundProperties('height', 0);
      props.setPlaceBackgroundProperties('width', 0);
      props.getOtherViewOfCurrentBackground(
        props.authToken,
        props.storyId,
        event.target.value
      );
    }
    if (event.target.name === 'name') {
      setDuplicateName(false);
    }

    if (event.target.name === 'foundInJuicy') {
      props.setPlaceBackgroundProperties(
        [event.target.name],
        event.target.value == '' ||
          event.target.value == 'false' ||
          event.target.value == 'off'
          ? true
          : false
      );
    }
    props.setAutoSaveFlag(true);
  };

  const setCrop = crop => {
    props.setPlaceBackgroundProperties('x', Math.round(crop.detail.x));
    props.setPlaceBackgroundProperties('y', Math.round(crop.detail.y));
    props.setPlaceBackgroundProperties(
      'height',
      Math.round(crop.detail.height)
    );
    props.setPlaceBackgroundProperties('width', Math.round(crop.detail.width));
  };

  const handleBrowseSelection = id => {
    let view = StoryService.getView(props.views, id);
    //commented the line which set name when browse view
    props.setPlaceBackgroundProperties('name', view.name);
    if (id === props.backgroundCopy) {
      props.setPlaceBackgroundProperties('backgroundCopy', null);
    } else {
      props.setPlaceBackgroundProperties('backgroundCopy', id);
      props.getViewsEditable(props.storyId, id, props.authToken);
    }
    setEditView(true);
  };

  const handleDuplicateClick = () => {
    setEditView(true);
    setEditViewFinal(false);
    props.resetViews();
    let view = StoryService.getView(props.views, props.backgroundCopy);
    setPlaceBackgroundProps(view);
    props.getOtherViewOfCurrentBackground(
      props.authToken,
      props.storyId,
      view.asset.id
    );
  };

  const handleViewSearch = event => {
    let searchViews = StoryService.searchText(props.views, event.target.value);
    props.setPlaceBackgroundProperties('searchViews', searchViews);
    setSearchText(event.target.value);
  };

  const onHideAddObjectsForm = () => {
    setShowAddObjectForm(false);
  };

  const onCancelClickAddObject = () => {
    setShowAddObjectForm(false);
  };

  const handleObjectDeleteClick = id => {
    let objectList = props.viewObjects.filter((item, index) => index !== id);
    props.setObject(objectList);
    props.setAutoSaveFlag(true);
  };

  const handleEditObjectClick = id => {
    setShowAddObjectForm(true);
    setEditObjectIndex(id);
  };

  const handleAddObjectClick = () => {
    setShowAddObjectForm(true);
    setEditObjectIndex(null);
  };

  const hideCreateViewModal = () => {
    setCreateViewModal(false);
  };

  const handleViewEditClick = () => {
    if (!props.editableFlag) {
      setCollaboratorModal(false);

      let view = StoryService.getView(props.views, props.backgroundCopy);
      props.getOtherViewOfCurrentBackground(
        props.authToken,
        props.storyId,
        view.asset.id
      );
      props.setPlaceBackgroundProperties('name', view.name);
      props.setPlaceBackgroundProperties(
        'exportToGallary',
        view.exportToGallary
      );
      props.setPlaceBackgroundProperties('foundInJuicy', view.foundInJuicy);
      props.setPlaceBackgroundProperties('characterRoute', view.characterRoute);
      props.setPlaceBackgroundProperties('captionText', view.captionText);
      setPlaceBackgroundProps(view);
      setDuplicateName(false);
      setEditViewFinal(true);
    } else {
      setCollaboratorModal(true);
    }
  };

  const handleHideAssetLockModal = () => {
    setCollaboratorModal(false);
  };

  const setPlaceBackgroundProps = (view, fromTab = 'view') => {
    let viewPortWidth = AspectRatioConfig.cropPanelWidth;
    let viewPortHeight = AspectRatioConfig.cropPanelHeight;
    let newObjects = ImageMapper.prepareCoordinatesByViewPort(
      view.viewObjects,
      view.width,
      view.height,
      viewPortWidth,
      viewPortHeight,
      false
    );
    if (fromTab !== 'browse') {
      props.setPlaceBackgroundProperties('background', view.asset.id);
      setBrowseViewId(view.asset.id);
      props.setPlaceBackgroundProperties('activeKey', 'view');
    } else {
      setBrowseViewId(view.asset.id);
    }
    props.setPlaceBackgroundProperties('exportToGallary', view.exportToGallary);
    props.setPlaceBackgroundProperties('foundInJuicy', view.foundInJuicy);
    props.setPlaceBackgroundProperties('captionText', view.captionText);
    props.setPlaceBackgroundProperties('characterRoute', view.characterRoute);

    props.setPlaceBackgroundProperties('x', view.xcoordinate);
    props.setPlaceBackgroundProperties('y', view.ycoordinate);
    props.setPlaceBackgroundProperties('height', view.height);
    props.setPlaceBackgroundProperties('width', view.width);
    props.setPlaceBackgroundProperties('viewObjects', newObjects);
  };

  const saveForLater = event => {
    const form = event.currentTarget;
    const checkDuplicate = checkViewNameDuplicate(props.name.trim());
    // let originalView = props.views.filter(item => item.id === props.backgroundCopy)
    if (form.checkValidity() === false || checkDuplicate === true) {
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();

      saveView(event.currentTarget[1].attributes.value.value);
    }
    event.preventDefault();
    setDuplicateName(checkDuplicate);
    props.setPlaceBackgroundProperties('validated', true);
  };

  const checkViewNameDuplicate = name => {
    if (tabButtonClicked === 'placeView') {
      return StoryService.checkDuplicateName(
        props.views,
        name,
        props.backgroundCopy
      );
    } else {
      return false;
    }
  };

  const handleReorderObjectList = objectLists => {
    props.reOrderObject(objectLists);
  };

  const handleClickEvent = (clickedEvent, tabofView = 'placeView') => {
    setClickedEventCheck(clickedEvent);
    setTabButtonClicked(tabofView);
    setCreateViewModal(true);
  };

  const handleSaveForLaterClickEvent = () => {
    setClickedEventCheck(true);
    setCreateViewModal(true);
    setTabButtonClicked('placeView');
  };

  function onViewExit() {
    setStepsScriptEnabled(false);
    props.setTutorials(props.tutorials, props.authToken, 'firstView');
  }

  const saveView = (exportToGallary = false) => {
    let routeName = props.characterRoute;
    if (exportToGallary === 'true' && props.characterRoute === '') {
      for (var i = 0; i < props.storyRoutes.length; i++) {
        if (props.storyRoutes[i].name === 'Prologue') {
          routeName = props.storyRoutes[i].id.toString();
          break;
        }
      }
    }
    let viewObject = {
      asset: { id: props.background },
      height: Math.round(props.height),
      width: Math.round(props.width),
      xcoordinate: props.xcoordinate,
      ycoordinate: props.ycoordinate,
      name: props.name,
      viewObjects: props.viewObjects,
      exportToGallary: exportToGallary,

      characterRoute: exportToGallary == false ? '' : routeName,
      captionText: exportToGallary == false ? '' : props.captionText,
      foundInJuicy: exportToGallary == false ? false : props.foundInJuicy
    };

    let newObjects = ImageMapper.prepareCoordinatesByViewPort(
      props.viewObjects,
      viewObject.width,
      viewObject.height,
      AspectRatioConfig.cropPanelWidth,
      AspectRatioConfig.cropPanelHeight
    );

    viewObject['viewObjects'] = newObjects;

    if (props.backgroundCopy != null) {
      viewObject.id = props.backgroundCopy;
    }

    if (viewObject.asset.id !== null) {
      props.saveForLater(viewObject, props.storyId, props.authToken);
    }

    setSearchText('');
    props.resetViews();
    setSelectBackground(true);
    setAddObject(false);
    setCreateViewModal(false);
    props.setActiveKey('browse');
    setClickedEventCheck(true);
    setBrowseViewId(null);
    setEditView(false);
    setEditViewFinal(false);
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
          <LibraryMenus
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            resetViewAutoSaveFlag={props.resetViewAutoSaveFlag}
            resetViews={props.resetViews}
            setEditViewFinal={setEditViewFinal}
            setSelectBackground={setSelectBackground}
            setPlaceBackgroundProperties={props.setPlaceBackgroundProperties}
            setAddObject={setAddObject}
            setEditViewFinal={setEditViewFinal}
          />
        </Col>
        <Col className="col-8">
          <ViewsContent
            storyTitle={props.storyTitle}
            handleChange={handleChange}
            setCrop={setCrop}
            saveForLater={saveForLater}
            clickedEventCheck={clickedEventCheck}
            handleClickEvent={handleClickEvent}
            handleSaveForLaterClickEvent={handleSaveForLaterClickEvent}
            duplicateName={duplicateName}
            handleBrowseSelection={handleBrowseSelection}
            handleDuplicateClick={handleDuplicateClick}
            handleViewSearch={handleViewSearch}
            searchText={searchText}
            backgroundImages={props.backgroundImages}
            views={props.views}
            searchViews={props.searchViews}
            setActiveKey={props.setActiveKey}
            background={props.background}
            setShowAddObjectForm={setShowAddObjectForm}
            viewObjects={props.viewObjects}
            handleObjectDeleteClick={handleObjectDeleteClick}
            handleEditObjectClick={handleEditObjectClick}
            editObjectIndex={editObjectIndex}
            handleAddObjectClick={handleAddObjectClick}
            handleReorderList={handleReorderObjectList}
            activeKey={props.activeKey}
            assetList={props.assetsAll}
            setObject={props.setObject}
            xcoordinate={props.xcoordinate}
            ycoordinate={props.ycoordinate}
            height={props.height}
            width={props.width}
            backgroundCopy={props.backgroundCopy}
            handleViewEditClick={handleViewEditClick}
            selectBackground={selectBackground}
            setSelectBackground={setSelectBackground}
            addObject={addObject}
            setAddObject={setAddObject}
            tutorials={props.tutorials}
            onExit={onViewExit}
            stepsViewEnabled={stepsViewEnabled}
            editView={editView}
            setEditView={setEditView}
            libraryChange={true}
            validated={props.validated}
            name={props.name}
            editViewFinal={editViewFinal}
            setAutoSaveFlag={props.setAutoSaveFlag}
            characterRoutesList={props.storyRoutes}
            exportToGallary={props.exportToGallary}
            foundInJuicy={props.foundInJuicy}
            characterRoute={props.characterRoute}
            captionText={props.captionText}
            otherView={props.otherView}
          />
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
      {showAddObjectForm === true && (
        <AddObjectsModal
          showModal={showAddObjectForm}
          onHideModal={onHideAddObjectsForm}
          assetsList={props.assetsAll}
          onCancelClickAddObject={onCancelClickAddObject}
          addObject={props.addObject}
          setShowAddObjectForm={setShowAddObjectForm}
          editObjectIndex={editObjectIndex}
          viewObjects={props.viewObjects}
          setObject={props.setObject}
          setAutoSaveFlag={props.setAutoSaveFlag}
        />
      )}
      {createViewModal === true && (
        <CreateViewModal
          showModal={createViewModal}
          onHideModal={hideCreateViewModal}
          validated={props.validated}
          duplicateName={duplicateName}
          saveForLater={saveForLater}
          handleChange={handleChange}
          clickedEventCheck={clickedEventCheck}
          fadeinView={props.fadeinView}
          isEditCommand={props.isEditCommand}
          tabButtonClicked={tabButtonClicked}
          characterRoutesList={props.storyRoutes}
          backgroundCopy={props.backgroundCopy}
          characterRoute={props.viewcharacterRoute}
          exportToGallary={props.viewexportogallery}
          foundInJuicy={props.foundInJuicy}
          name={props.name}
          otherView={props.otherView}
        />
      )}
      {collaboratorModal == true && (
        <LockedAssets
          showModal={collaboratorModal}
          lockedAssetData={props.editableData}
          handleHideAssetLockModal={handleHideAssetLockModal}
          type="view"
        />
      )}
    </div>
  );
}

export const mapStateToProps = state => {
  return {
    activeKey: state.PlaceBackgroundReducer.activeKey,
    background: state.PlaceBackgroundReducer.background,
    fadeIn: state.PlaceBackgroundReducer.fadeIn,
    name: state.PlaceBackgroundReducer.name,
    xcoordinate: state.PlaceBackgroundReducer.x,
    ycoordinate: state.PlaceBackgroundReducer.y,
    viewexportogallery: state.PlaceBackgroundReducer.exportToGallary,

    viewcharacterRoute: state.PlaceBackgroundReducer.characterRoute,
    height: state.PlaceBackgroundReducer.height,
    width: state.PlaceBackgroundReducer.width,
    zoom: state.PlaceBackgroundReducer.zoom,
    aspect: state.PlaceBackgroundReducer.aspect,
    validated: state.PlaceBackgroundReducer.validated,
    searchViews: state.PlaceBackgroundReducer.searchViews,
    viewObjects: state.PlaceBackgroundReducer.viewObjects,
    backgroundCopy: state.PlaceBackgroundReducer.backgroundCopy,

    previewAssetObj: state.StoryReducer.previewAssetObj,
    isEditCommand: state.StoryReducer.isEditCommand,
    commandId: state.StoryReducer.commandId,
    viewId: state.StoryReducer.viewId,
    views: state.PlaceBackgroundReducer.views,

    backgroundImages: state.PlaceBackgroundReducer.backgroundImageList,
    tutorials: state.LoginReducer.tutorials,
    assetsAll: state.LibraryReducer.assetList,
    storyRoutes: state.StoryReducer.storyRoutes,
    authToken: state.LoginReducer.authToken,
    storyId: state.StoryReducer.storyId,
    storyTitle: state.StoryReducer.storyTitle,
    selectedMenu: state.LibraryReducer.selectedMenu,
    viewAutoSaveFlag: state.PlaceBackgroundReducer.autoSaveFlag,
    editableFlag: state.PlaceBackgroundReducer.editableFlag,
    editableData: state.PlaceBackgroundReducer.editableData,
    exportToGallary: state.PlaceBackgroundReducer.exportToGallary,
    foundInJuicy: state.PlaceBackgroundReducer.foundInJuicy,
    captionText: state.PlaceBackgroundReducer.captionText,
    characterRoute: state.PlaceBackgroundReducer.characterRoute,
    otherView: state.PlaceBackgroundReducer.otherView

    // collaboratorModal: state.PlaceBackgroundReducer.collaboratorModal
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    saveForLater: (data, storyId, authToken) =>
      dispatch(PlaceBackgroundActions.saveForLater(data, storyId, authToken)),
    saveRoute: (data, authToken, storyId, IsRouteEdit, editRouteId) =>
      dispatch(
        StoryAction.createRoute(
          data,
          authToken,
          storyId,
          IsRouteEdit,
          editRouteId
        )
      ),
    resetRouteModal: () => dispatch(StoryAction.resetRouteModal()),
    setActiveKey: activeKey =>
      dispatch(PlaceBackgroundActions.setActiveKey(activeKey)),
    setPlaceBackgroundProperties: (key, value) =>
      dispatch(PlaceBackgroundActions.setBackgroundProperties(key, value)),
    addObject: object => dispatch(PlaceBackgroundActions.addObject(object)),
    reOrderObject: objects =>
      dispatch(PlaceBackgroundActions.reOrderObject(objects)),
    setObject: objectList =>
      dispatch(PlaceBackgroundActions.setObject(objectList)),
    resetViews: () => dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    setAutoSaveFlag: flag =>
      dispatch(PlaceBackgroundActions.setAutoSaveFlag(flag)),
    resetViewAutoSaveFlag: () =>
      dispatch(PlaceBackgroundActions.resetAutoSaveFlag()),
    getBackgroundImages: (storyId, authToken) =>
      dispatch(PlaceBackgroundActions.getBackgroundImages(storyId, authToken)),
    getViewsEditable: (storyId, viewId, authToken) =>
      dispatch(
        PlaceBackgroundActions.getViewsEditable(storyId, viewId, authToken)
      ),
    getCharacterRoutes: (authToken, storyId) =>
      dispatch(StoryAction.listRoute(authToken, storyId)),
    getOtherViewOfCurrentBackground: (authToken, storyId, backgroundId) =>
      dispatch(
        PlaceBackgroundActions.getOtherViewsFromId(
          authToken,
          storyId,
          backgroundId
        )
      ),
    getViews: (storyId, authToken) =>
      dispatch(PlaceBackgroundActions.getViews(storyId, authToken))
    // setCollaboratorModalFlag: flagEditable =>
    //   PlaceBackgroundActions.updateCollaboratorModal(flagEditable)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Views);

export const ASSET_TYPES_IMAGES = ['Background_Image', 'Object_Image'];

export const ASSET_TYPES_IMAGES_JPG = ['Background_Image'];

export const STORY_TYPES_ALBUM_IMAGES = ['ALBUM_COVER'];

export const STORY_TYPES_SERIES_IMAGES = ['SERIES_BACKGROUND'];

export const STORY_TYPES_TITLE_IMAGES = ['STORY_TITLE'];

export const ASSET_TYPES_CHARACTER = [
  'Expression',
  'Outfit',
  'Hair',
  'Accessory'
];

export const ROUTE_TYPES_STATUS = [
  'Released',
  'Coming Soon',
  'Production Not Started',
  'Completed'
];

export const ASSET_TYPES_AUDIO = ['Music', 'SFX'];

export const ASSET_TYPES_AUDIO1 = ['Background_Music', 'Incidental_Sound'];

export const ASSET_MAX_FILE_SIZE = 15728640;
export const SERIES_BACKGROUND_WIDTH = 1024;
export const SERIES_BACKGROUND_HEIGHT = 649;
export const TITLE_COVER_WIDTH = 512;
export const TITLE_COVER_HEIGHT = 819;

export const TITLE_WIDTH = 390;
export const TITLE_HEIGHT = 100;

export const ASSET_FILE_ALLOWED_EXTENSIONS = [
  // image files
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/bmp',

  //audio files
  'audio/wav',
  'audio/mp3',
  'audio/mp4',
  //'audio/aac',
  'audio/ogg',
  'audio/webm',
  'audio/mpeg'
];

export const ASSET_FILE_ALLOWED_PNG = ['image/png'];

export const ASSET_FILE_ALLOWED_EXTENSIONS_IMAGE = [
  // // image files
  'image/jpeg',
  'image/png',
  'image/jpg'
  // 'image/tiff',
  // 'image/bmp',
];

export const ASSET_FILE_ALLOWED_EXTENSIONS_AUDIO = [
  //audio files
  'audio/wav',
  'audio/mp3',
  'audio/mp4',
  //'audio/aac',
  'audio/ogg',
  'audio/webm',
  'audio/mpeg'
];

export const STORY_FILE_ALLOWED_EXTENSIONS = [
  // image files

  'image/png'
];

export const LIBRARY_STORY_FILE_ALLOWED_EXTENSIONS = [
  // image files
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/bmp'
];

export const LIBRARY_UI_INTERFACE_FILE_ALLOWED_EXTENSIONS = [
  // image files
  // 'image/jpeg',
  'image/png'
  // 'image/jpg',
  // 'image/tiff',
  // 'image/bmp',
];

export const ASSET_LIST_COLUMNS_ALL = [
  'assetName',

  'format',
  'specs',
  'uploaded',
  'actions'
];

export const ASSET_LIST_COLUMNS_SELECT_ASSET = [
  'image',
  'type',
  'default',
  'actions'
];

export const COST_IN_GAME_CHOICE = [
  '0',
  '10',
  '12',
  '15',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30'
];

export const FONT_SIZE = [
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30'
];

export const FONT_FAMILY = [
  'Consolas',
  'Courier',
  'Courier new',
  'Inconsolata',
  'Jetbrains mono',
  'Menlo',
  'Monaco',
  'Monospace',
  'Nova mono',
  'Oxygen mono',
  'PT mono',
  'Roboto mono',
  'Source code pro',
  'Syne mono',
  'VT323',
  'Xanh mono'
];

///Local storage timeout duration
export const STORAGE_DURATION = 5;

//add empty commant in script editor
export const COMMAND_LIST = -1;

//Block Related
export const COMMAND_ADD_BLOCK = 1;
export const COMMAND_GO_TO_BLOCK = 2;
export const COMMAND_CHOICE_TIMER = 4;
export const COMMAND_PAID_ADD_DECISION = 5;
export const COMMAND_TIME_DELAY = 7;
export const COMMAND_ADD_IF = 28;
export const COMMAND_ADD_ELSE = 29;
export const COMMAND_ADD_ELSE_IF = 30;
export const COMMAND_ADD_END = 31;
export const COMMAND_GOTO_CHAPTER = 43;

//Character Related
export const COMMAND_ENTER_THE_SCENE = 9;
export const COMMAND_CHANGE_EXPRESSION = 10;
export const COMMAND_REPLACE_CHARACTER = 11;
export const COMMAND_HIDDEN_CHARACTER = 12;
export const COMMAND_FADE_TO_COLOR = 13;
export const COMMAND_LEAVES_THE_SCENE = 14;
export const COMMAND_ADD_DIALOGUE = 15;
export const COMMAND_SAY_GENDERED = 20;
export const COMMAND_END_JUICY_BLOCK = 6;
export const COMMAND_HIDE_OBJECT = 18;
export const COMMAND_TAP_OBJECT = 38;
export const COMMAND_SET_OUTFIT = 45;
export const COMMAND_SHOW_OUTFIT = 46;
export const COMMAND_REPLACE_OUTFIT = 47;
// Background Related
export const COMMAND_CUSTOM_DIALOGUE = 16;

export const COMMAND_MOVE_BACKGROUND = 19;
export const COMMAND_SHOW_OBJECT = 17;
export const COMMAND_SET_AUDIO = 27;
export const COMMAND_PLACE_VIEW = 37;

// Media Related
export const COMMAND_PLAY_SOUND = 24;
export const COMMAND_STOP_MUSIC = 25;
export const COMMAND_PLAY_MUSIC = 26;

export const COMMAND_SHAKE_CAMERA = 40;
export const COMMAND_SHOW_NAME_DIALOGUE = 41;
export const COMMAND_SET_VAR = 42;
export const COMMAND_ADD_COMMENT = 44;

export const COMMAND_LEVEL_ONE = 1;
export const COMMAND_LEVEL_TWO = 2;

//Decision Blocks Name Array
export const COMMAND_DECISION_DROPDOWN_LISTNAMES = [
  {
    dropDownName: 'opt1Dropdown',
    textBoxName: 'opt1Textbox'
  },
  {
    dropDownName: 'opt2Dropdown',
    textBoxName: 'opt2Textbox'
  },
  {
    dropDownName: 'opt3Dropdown',
    textBoxName: 'opt3Textbox'
  },
  {
    dropDownName: 'opt4Dropdown',
    textBoxName: 'opt4Textbox'
  },
  {
    dropDownName: 'opt5Dropdown',
    textBoxName: 'opt5Textbox'
  }
];

export const COMMAND_ENTER_POSITION = [
  {
    name: 'Left',
    meta: 'Position'
  },
  {
    name: 'Right',
    meta: 'Position'
  },
  {
    name: 'Middle',
    meta: 'Position'
  }
];

export const INTERACTABLE_CHOICE = [
  {
    name: 'interactable',
    meta: 'Interactable Choice'
  },
  {
    name: 'non-interactable',
    meta: 'Interactable Choice'
  }
];

export const HIDESHOW_CHOICE = [
  {
    name: 'hideifvisited',
    meta: 'Hide If Visited'
  },
  {
    name: 'showifvisited',
    meta: 'Show If Visited'
  }
];

export const AUTO_AFFINITY_TYPE = [
  {
    name: 'Upright',
    meta: 'Affinity type'
  },
  {
    name: 'Reversed',
    meta: 'Affinity type'
  }
];

export const AUTO_AFFINITY_WEIGHT = [
  {
    name: 'Low',
    meta: 'Affinity weight'
  },
  {
    name: 'Medium',
    meta: 'Affinity weight'
  },
  {
    name: 'High',
    meta: 'Affinity weight'
  }
];

export const COMMAND_INTESITY_SHAKE_CAMERA = [
  {
    name: 'Low',
    meta: 'Intensity'
  },
  {
    name: 'Medium',
    meta: 'Intensity'
  },
  {
    name: 'High',
    meta: 'Intensity'
  }
];

export const COMMAND_OFF_POSITION = [
  {
    name: 'None',
    meta: 'Appear From'
  },
  {
    name: 'Off-Left',
    meta: 'Appear From'
  },
  {
    name: 'Off-Right',
    meta: 'Appear From'
  }
];

export const OPERATOR_FOR_SETVAR_BOOLEAN = [
  {
    name: '=',
    meta: 'Operator'
  },
  {
    name: '=!',
    meta: 'Operator'
  }
];

export const OPERATOR_FOR_SETVAR_STRING = [
  {
    name: '=',
    meta: 'Operator'
  }
];

export const OPERATOR_FOR_INTEGER_FLOAT = [
  {
    name: '=',
    meta: 'Operator'
  },
  {
    name: '+=',
    meta: 'Operator'
  },
  {
    name: '-=',
    meta: 'Operator'
  },
  {
    name: '*=',
    meta: 'Operator'
  },
  {
    name: '/=',
    meta: 'Operator'
  }
];

export const AUTOCOMPLETE_ALL_OPERATOR_SET_VAR = [
  {
    name: '=',
    meta: 'Operator'
  },
  {
    name: '+=',
    meta: 'Operator'
  },
  {
    name: '-=',
    meta: 'Operator'
  },
  {
    name: '*=',
    meta: 'Operator'
  },
  {
    name: '/=',
    meta: 'Operator'
  },
  {
    name: '=!',
    meta: 'Operator'
  }
];

export const AUTOCOMPLETE_BOOLEAN = [
  {
    name: 'Boolean',
    meta: 'Variable Type'
  }
];

export const AUTOCOMPLETE_STRING = [
  {
    name: 'String',
    meta: 'Variable Type'
  }
];

export const AUTOCOMPLETE_INT = [
  {
    name: 'Integer',
    meta: 'Variable Type'
  }
];

export const AUTOCOMPLETE_FLOAT = [
  {
    name: 'Float',
    meta: 'Variable Type'
  }
];

export const AUTOCOMPLETE_CROSS_CHAPTER = [
  {
    name: 'Cross-chapter',
    meta: 'Global Scope'
  },
  {
    name: 'Private',
    meta: 'Private Scope'
  }
];

export const OPERATOR_FOR_BOOLEAN = [
  {
    name: '==',
    meta: 'Operator'
  },
  {
    name: '=/=',
    meta: 'Operator'
  }
];

export const OPERATOR_FOR_OTHER = [
  {
    name: '==',
    meta: 'Operator'
  },
  {
    name: '=/=',
    meta: 'Operator'
  },
  {
    name: '<',
    meta: 'Operator'
  },
  {
    name: '>',
    meta: 'Operator'
  },
  {
    name: '<=',
    meta: 'Operator'
  },
  {
    name: '>=',
    meta: 'Operator'
  }
];

export const COMMAND_SET_AUDIO_AUTOCOMPLETE = [
  '10',
  '9',
  '8',
  '7',
  '6',
  '5',
  '4',
  '3',
  '2',
  '1'
];

export const COMMAND_ENTER_FLIP = [
  {
    name: 'flip',
    meta: 'Flip Horizontally'
  },
  {
    name: 'no-flip',
    meta: 'Flip Horizontally'
  }
];

export const COMMAND_LAST_CHAPTER = [
  {
    name: 'true',
    meta: 'last chapter'
  },
  {
    name: 'false',
    meta: 'last chapter'
  }
];

export const COMMAND_TRANSITION_EFFECT = [
  {
    name: 'No-transition',
    meta: 'Transition'
  },
  {
    name: 'Slide-out-left',
    meta: 'Transition'
  },
  {
    name: 'Slide-out-right',
    meta: 'Transition'
  },
  {
    name: 'Fade-out',
    meta: 'Transition'
  }
];

export const COMMAND_MUSIC_LOOP = [
  {
    name: 'noloop',
    meta: 'Music Loop'
  },
  {
    name: 'loop',
    meta: 'Music Loop'
  }
];

export const SNACK_USER_TOKEN = 'snackUserToken';
export const SNACK_USER_ROLE = 'snackUserRole';
export const SNACK_USER_EXPIRES = 'sanckUserExpires';
export const SNACK_LAST_PAGE = 'snackLastPage';
export const SNACK_STORY_ID = 'story_id';

export const SNACK_USER_ADMIN = '[ROLE_ADMIN]';
export const SNACK_USER_EDITOR = '[ROLE_EDITOR]';
export const SNACK_USER_WRITER = '[ROLE_WRITER]';

// Character commands parameters constants
export const CHARACTER_POSITION_IN_SCREEN = 'Position in screen';
export const CHARACTER_FADE_INTO_SCREEN = 'Fade into screen';
export const CHARACTER_FLIP_HORIZONTALLY = 'Flip horizontally';
export const CHARACTER_DELAY_TO_APPEAR = 'Delay to appear';
export const CHARACTER_TRANSITION_TYPE = 'Transition type';
export const UI_TEXT = 'UI Text';
export const CHARACTER_SLIDE_IN_DURATION = 'Slide-in duration';
export const CHARACTER_APPEAR_FROM = 'Appear from';
export const CHARACTER_APPEAR_FROM_OFF_LEFT = 'Off-Left';
export const CHARACTER_APPEAR_FROM_OFF_RIGHT = 'Off-Right';

export const CHARACTER_APPEAR_FROM_NONE = 'None';

// Background command parameters
export const BACKGROUND_POSITION = 'Position';
export const BACKGROUND_SCALE = 'Scale';
export const BACKGROUND_IMAGE = 'Background image';
export const BACKGROUND_POSITION_TO = 'Move to position';
export const BACKGROUND_SCALE_TO = 'Scale to';
export const BACKGROUND_MOVE_DURATION = 'Move Duration';
export const BACKGROUND_FADE_INTO_SCREEN = 'Fade into screen';

//enter the scene editing parameters
export const SCENE_EXPRESSION = 'Expression (if available)';
export const SCENE_FADE = 'Fade into screen';
export const SCENE_FLIP = 'Flip horizontally';
export const SCENE_POSITIION = 'Position in screen';
export const SCENE_DELAY = 'Delay to appear';

// Change Background command parameters
export const BACKGROUND_FADE = 'Fade into screen';
export const BACKGROUND_NEW = 'New background';
export const BACKGROUND_CHANGE_POSITION = 'Position in screen';

export const MUSIC_FILE = 'Music file';

// Player constant
export const EVENT_PLAY = 'PLAY';
export const EVENT_PAUSE = 'PAUSE';
export const EVENT_FIRST = 'FIRST';
export const EVENT_LAST = 'LAST';
export const EVENT_PREV = 'PREV';
export const EVENT_NEXT = 'NEXT';

//Command Category
export const COMMANDS_STORYFLOW = 'STORYFLOW';
export const COMMANDS_CHARACTER = 'CHARACTER';
export const COMMANDS_DIALOGUE = 'DIALOGUE';
export const COMMANDS_BACKGROUND = 'BACKGROUND';
export const COMMANDS_MEDIA = 'MEDIA';
export const COMMANDS_EFFECTS = 'EFFECTS';

// Design constatn
export const TRANSITION_TYPE_FADE_OUT = 'Fade-out';
export const TRANSITION_TYPE_FADE_IN = 'Fade-in';
export const DURATION = 'Duration';
export const TRANSITION_TYPE_SLIDING_OUT_RIGHT = 'Slide-out right';
export const TRANSITION_TYPE_SLIDING_OUT_LEFT = 'Slide-out left';
export const TRANSITION_TYPE_NO_TRANSITION = 'No-transition';
export const TRANSITION_TYPE_SLIDING_IN_LEFT = 'Slide-in-left';
export const TRANSITION_TYPE_SLIDING_IN_RIGHT = 'Slide-in-right';

//storyt Admin

export const OTHER_STORIES = 'Other Stories';
export const WAITING_TO_BE_REVIEWED = 'Waiting to be Reviewed';

export const CURRENT_BACKGROUND = 'Current background';

export const interfaceList = [
  'Dialogue box',
  'Character name plate',
  'Continue button',
  'Timer bar',
  'Regular button',
  'Regular disabled button',
  'Regular pressed button',
  'Paid button',
  'Paid pressed button',
  'Premium price'
];

export const aspectRatio = '3/4';
export const aspectRatioParameters = {
  '3/4': {
    previewWidth: 307,
    previewHeight: 400,
    staticPreviewWidth: 150,
    staticPreviewHeight: 200
  }
};
export const aspectRatioCalc = 3 / 4;

export const affinityType = {
  upright: 'Upright',
  reversed: 'Reversed'
};

export const affinityWeight = {
  Upright: {
    Low: 1,
    Medium: 2,
    High: 3
  },
  Reversed: {
    Low: -1,
    Medium: -2,
    High: -3
  }
};

export const defaultInterfaceImageList = [
  'BoilerPlate_DialogueBackground.png',
  'BoilerPlate_NameBackground.png',
  'continue.png',
  'progressbarBackground.png',
  'btn_option.png',
  'btn_option_disabled.png',
  'btn_option_pressed.png',
  'btn_option_juicy.png',
  'btn_option_juicy_pressed.png',
  'currencycoins.png'
];

export const defaultInterfaceImages = {
  'Dialogue box': 'BoilerPlate_DialogueBackground.png',
  'Character name plate': 'BoilerPlate_NameBackground.png',
  'Continue button': 'continue.png',
  'Timer bar': 'progressbarBackground.png',
  'Regular button': 'btn_option.png',
  'Regular disabled button': 'btn_option_disabled.png',
  'Regular pressed button': 'btn_option_pressed.png',
  'Paid button': 'btn_option_juicy.png',
  'Paid pressed button': 'btn_option_juicy_pressed.png'
};

export const reservedVariables = ['nombre', 'pronoun', 'upright'];

export const redirectionalCommandIds = [
  COMMAND_GO_TO_BLOCK,
  COMMAND_CHOICE_TIMER,
  COMMAND_PAID_ADD_DECISION,
  COMMAND_TAP_OBJECT
];

export const INTRO_OPTIONS = {
  skipLabel: 'x',
  prevLabel: 'Previous',
  nextLabel: 'Next',
  exitOnEsc: true,
  exitOnOverlayClick: true,
  showStepNumbers: false,
  hidePrev: true,
  hideNext: true,
  doneLabel: 'Finish'
};

export const stepScriptsConstant = [
  {
    element: '.step-story',
    intro:
      '<div className="writer-tool"><h3>WRITE SECTION</h3><p>Here is where you will start writing your story.</p></div>',
    position: 'top',
    tooltipClass: 'writetool'
  },
  {
    element: '.step-write',
    intro:
      "<h3>SWITCH BETWEEN SECTIONS</h3> <p>You can switch between sections at will. You'll always start here in the Write section when you log in.</p>",
    position: 'bottom',
    tooltipClass: 'writetool-second'
  },
  {
    element: '.step-command',
    intro:
      '<h3>COMMAND PANEL</h3><p>Scripts use commands to make everything happen. Click on them to find out more or customize each action.</p>',
    position: 'right',
    tooltipClass: 'writetool-second'
  },
  {
    element: '.step-script',
    intro:
      '<h3>SCRIPT</h3><p>The story and commands are added here, you can type commands while writing or use the command panel in addition.</p>',
    position: 'top',
    tooltipClass: 'writetool-script'
  },
  {
    element: '.step-flowchart',
    intro:
      '<h3>FLOWCHART AND PREVIEW</h3><p>The flowchart shows a map of your story, to plan out player choices. The preview lets you see how it will look when its played on a device.</p>',
    position: 'left',
    tooltipClass: 'writetool-flowchart'
  },
  {
    element: '.step-library-menu',
    intro:
      '<h3>LIBRARY</h3><p>This is where you will upload assets like characters, backgrounds, and music. Let’s start here!</p>',
    position: 'bottom',
    tooltipClass: 'writetool-library'
  }
];

export const stepIfCommand = [
  {
    element: '.step-command2',
    intro:
      '<h3>IF / ELSE / END</h3><p>These commands can help build complex flows for your story.</p>',
    position: 'right',
    tooltipClass: 'writetool-if_else_1'
  },
  {
    element: '.step-command2',
    intro:
      '<h3>IF TRUE</h3> <p>This statement will execute a block or command if a specified condition is true.</p>',
    position: 'right',
    tooltipClass: 'writetool-if_else_2'
  },
  {
    element: '.step-command2',
    intro:
      '<h3>ELSE</h3><p>If the condition is false, another block of code can be executed.</p>',
    position: 'right',
    tooltipClass: 'writetool-if_else_3'
  },
  {
    element: '.step-command2',
    intro:
      "<h3>EXAMPLE</h3><p>if (condition)</p><p class='text-italic'>  // commands if the condition is true <br/>else<br/> // commands if the condition is false <br/>end</p>",
    position: 'top',
    tooltipClass: 'writetool-if_else_4'
  }
];

export const stepChoiceCommand = [
  {
    element: '.step-command2',
    intro:
      '<h3>CHOICES</h3><p>Here, you can create choices for the player to make in your game.</p>',
    position: 'right',
    tooltipClass: 'writetool_choice_1'
  },
  {
    element: '.step-command2',
    intro:
      '<h3>CHOICE TEXT</h3> <p>This is the text that will appear in a menu option for the player to select. You can display from 1 to 5 menu options at a time.</p>',
    position: 'right',
    tooltipClass: 'writetool_choice_2'
  },
  {
    element: '.step-command2',
    intro:
      '<h3>DESTINATION BLOCK</h3><p>All choices must lead to another block in your script. Here, you set which block each choice leads to. Multiple choices can lead to the same block.</p>',
    position: 'right',
    tooltipClass: 'writetool_choice_3'
  },
  {
    element: '.step-command2',
    intro:
      '<h3>OPTIONAL FEATURES</h3><p>Choices can also have extra optional features. If a decision has long-term consequences it can be tracked using the "Affinity" system. If the same menu is repeated in a loop, "Hide if Visited" will prevent it from being shown again once the player has already chosen it.</p>',
    position: 'top',
    tooltipClass: 'writetool_choice_4'
  }
];

export const dialogBoxFonts = [
  'Amaranth',
  'Anton',
  'Arvo',
  'Averia_sans_libre',
  'Carter_one',
  'Graduate',
  'Josefin_Sans',
  'Lato',
  'Lato_Bold',
  'Livvic',
  'Merriweather',
  'Merriweather_Bold',
  'Montserrat',
  'mPlus_Rounded',
  'Nanum_Myeongjo',
  'Permanent_Marker',
  'Prata',
  'Pridi',
  'Quicksand',
  'Raleway',
  'Raleway_Medium',
  'Roboto',
  'Roboto_Medium',
  'Roboto_Condensed',
  'Roboto_Mono',
  'RobotoSlab',
  'Rock_Salt',
  'Sanchez'
];

export const characterNamePlateFonts = [
  'Amaranth',
  'Anton',
  'Arvo',
  'Averia_sans_libre',
  'Carter_one',
  'Graduate',
  'Josefin_Sans',
  'Lato',
  'Lato_Bold',
  'Livvic',
  'Londrina_Solid',
  'Merriweather',
  'Merriweather_Bold',
  'Montserrat',
  'mPlus_Rounded',
  'Nanum_Myeongjo',
  'Permanent_Marker',
  'Prata',
  'Pridi',
  'Quicksand',
  'Raleway',
  'Raleway_Medium',
  'Roboto',
  'Roboto_Medium',
  'Roboto_Condensed',
  'Roboto_Mono',
  'RobotoSlab',
  'Rock_Salt',
  'Sanchez'
];

export const buttonLabelFonts = [
  'Amaranth',
  'Anton',
  'Arvo',
  'Averia_sans_libre',
  'Carter_one',
  'Graduate',
  'Josefin_Sans',
  'Lato',
  'Lato_Bold',
  'Livvic',
  'Londrina_Solid',
  'Merriweather',
  'Merriweather_Bold',
  'Montserrat',
  'mPlus_Rounded',
  'Nanum_Myeongjo',
  'Permanent_Marker',
  'Prata',
  'Pridi',
  'Quicksand',
  'Raleway',
  'Raleway_Medium',
  'Roboto',
  'Roboto_Medium',
  'Roboto_Condensed',
  'Roboto_Mono',
  'RobotoSlab',
  'Rock_Salt',
  'Sanchez'
];
export const optionsFlowchart = {
  layout: {
    clusterThreshold: 160,
    hierarchical: {
      enabled: true,
      levelSeparation: 200 / 2,
      nodeSpacing: 300 / 2,
      treeSpacing: 200 / 2,
      blockShifting: true,
      edgeMinimization: true,
      parentCentralization: true,
      direction: 'UD', // UD, DU, LR, RL
      sortMethod: 'directed' // hubsize, directed
    },
    improvedLayout: true
  },
  edges: {
    color: {
      color: '#bfbfbf',
      highlight: '#157FFB',
      hover: '#157FFB',
      inherit: 'from',
      opacity: 1.0
    },
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 0.8
      }
    },
    selectionWidth: 1,
    smooth: {
      enabled: true,
      type: 'cubicBezier',
      roundness: 1,
      forceDirection: 'vertical'
    }
  },
  nodes: {
    borderWidthSelected: 1,
    widthConstraint: {
      minimum: 70,
      maximum: 70
    },
    mass: 1,
    color: {
      border: '#157FFB'
    },

    physics: true,

    value: 2
  },
  interaction: {
    dragNodes: false,
    dragView: true,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hover: true,
    hoverConnectedEdges: false,
    keyboard: {
      enabled: false,
      speed: { x: 10, y: 10, zoom: 2 },
      bindToWindow: true
    },
    navigationButtons: true,
    selectable: true,
    selectConnectedEdges: true,
    zoomView: true
  },
  height: '490px',
  width: '270px',
  autoResize: true
};

export const STORY_PENDING_APPROVAL = 'Pending Approval';

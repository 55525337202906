import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import * as ActionTypes from '../actions/action-types';

import * as RequestAccountActionTypes from '../actions/request-account-action-types';
import * as RequestAccountActions from '../actions/request-account-actions';
import * as MyAccountAction from '../actions/myaccount-action';
import * as PasswordRecoveryActionTypes from '../actions/password-recovery-action-types';
import * as PasswordRecoveryActions from '../actions/password-recovery-actions';

import {
  postUserData,
  fetchUserAccounts,
  deletetUserData,
  requestAccount,
  deletetAccountData,
  updateMyAccountApi,
  changePasswordAccountApi,
  deleteMyAccount,
  passwordRecovery,
  changePassword
} from "./api/accounts-api";

//  AKHIL {
export function* fetchUserAccountsWorker(payloads) {
  if(payloads.authToken){
  try {
    const userAccounts = yield call(fetchUserAccounts, payloads);

    yield put({
      type: ActionTypes.FETCH_USER_ACCOUNTS_SUCCESS,
      userAccounts: userAccounts
    });
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_USER_ACCOUNTS_FAIL });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}
}

export function* fetchUserAccountsWatcher() {

  yield takeEvery(ActionTypes.FETCH_USER_ACCOUNTS, fetchUserAccountsWorker)
}
//  AKHIL }

export function* postUserDataWorker({ payload, token, isEditAccount, userId }) {
  if(token){

  try {


    const data = yield call(postUserData, { payload, token, isEditAccount, userId });

    yield put({
      type: ActionTypes.CREATE_SUCCESS_ACCOUNT_DETAILS,
      payload: {
        result: data

      }
    });
  } catch (e) {
    yield put({ type: ActionTypes.CREATE_FAIL_ACCOUNT_DETAILS });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}
}

export function* deleteUserDataWorker({ payload, token, status }) {
  if(token){

  try {
    const data = yield call(deletetUserData, { payload, token, status });
    yield put({
      type: ActionTypes.DEACTIVATE_SUCCESS_ACCOUNT_DETAILS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: ActionTypes.DEACTIVATE_FAIL_ACCOUNT_DETAILS });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}
}

export function* myAccountUpdateDataWorker(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(updateMyAccountApi, payloads);
    if (data.result === 'Success') {
      yield put(MyAccountAction.updateMyAccountDetailsSuccess(data));
    } else {
      yield put(MyAccountAction.updateMyAccountDetailsFailMessage(data.message));
    }
  } catch (e) {
    // yield put(MyAccountAction.updateMyAccountDetailsFail(e));
    
  }
}
}

export function* changePasswordMyAccountWorker(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(changePasswordAccountApi, payloads);
    if (data.result === 'Success') {
      yield put(MyAccountAction.changePasswordMyAccountSuccess(data));
    } else {
      if(data.message[0]==="Full authentication is required to access this resource"){
        yield put({
          type: ActionTypes.SET_LOGIN_DETAILS,
        payload: null,
        isLogOut: true
      });

      }else{
       
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg:data.message[0]
        });
      }
      yield put(MyAccountAction.changePasswordMyAccountFail(data.message));
    }
  } catch (e) {
    // yield put(MyAccountAction.changePasswordMyAccountFail(e));
  }
}
}

export function* deleteMyAccountWorker(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(deleteMyAccount, payloads);
    if (data.result === 'Success') {
      yield put(MyAccountAction.deleteMyAccountSuccess(data));
    } else {
      yield put(MyAccountAction.deleteMyAccountFail(data.message));
    }
  } catch (e) {
    yield put(MyAccountAction.changePasswordMyAccountFail(e));
  }
}
}

export function* requestAccountWorker(payload) {
  
  try {
    const data = yield call(requestAccount, payload);
    if (data.result === 'Success') {
      yield put(RequestAccountActions.requestAccountSuccess());
    } else {
      yield put(RequestAccountActions.requestAccountFail(data.message[0]));
      if(data.message[0]==="Full authentication is required to access this resource"){
        yield put({
          type: ActionTypes.SET_LOGIN_DETAILS,
        payload: null,
        isLogOut: true
      });

      }else{
       
        if(data.message[0]==="Full authentication is required to access this resource"){
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
          payload: null,
          isLogOut: true
        });
  
        }else{
         
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
    
          }else{
           
            yield put({
              type: ActionTypes.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
        }
      }
    }
  } catch (e) {
    yield put(RequestAccountActions.requestAccountFail());
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
 
}


export function* deleteAccountDataWorker({ payload, token }) {
  if(token){
  try {


    const data = yield call(deletetAccountData, { payload, token });

    yield put({
      type: ActionTypes.DELETE_SUCCESS_ACCOUNT_DETAILS,
      payload: {
        result: data
      }
    });
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_FAIL_ACCOUNT_DETAILS });
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }}
}

export function* passwordRecoveryWorker(payload) {
   
  try {
    const data = yield call(passwordRecovery, payload);
    if (data.result === 'Success') {
      yield put(PasswordRecoveryActions.passwordRecoverySuccess());
    } else {
      yield put(PasswordRecoveryActions.passwordRecoveryFail(data.message[0]));
      if(data.message[0]==="Full authentication is required to access this resource"){
        yield put({
          type: ActionTypes.SET_LOGIN_DETAILS,
        payload: null,
        isLogOut: true
      });

      }else{
       
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg:data.message[0]
        });
      }
    }
  } catch (e) {
    yield put(PasswordRecoveryActions.passwordRecoveryFail());
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
 
}

export function* changePasswordWorker(payload) {
  try {
    const data = yield call(changePassword, payload.payload);
    if (data.result === 'Success') {
      yield put(PasswordRecoveryActions.setChangePasswordSuccess());
    } else {
      yield put(PasswordRecoveryActions.setChangePasswordFail(data.message));
      if(data.message[0]==="Full authentication is required to access this resource"){
        yield put({
          type: ActionTypes.SET_LOGIN_DETAILS,
        payload: null,
        isLogOut: true
      });

      }else{
       
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg:data.message[0]
        });
      }
    }
  } catch (e) {
    yield put(PasswordRecoveryActions.setChangePasswordFail());
    yield put({
      type: ActionTypes.SET_API_ERROR,
      
    });
  }
}

export function* postUserDataWatcher() {
  yield takeLatest(ActionTypes.CREATE_ACCOUNT_DETAILS, postUserDataWorker);
}

export function* deleteUserDataWatcher() {
  yield takeLatest(ActionTypes.DEACTIVATE_ACCOUNT_DETAILS, deleteUserDataWorker);
}

export function* updateMyAccountDetailsWatcher() {
  yield takeLatest(ActionTypes.MY_ACCOUNT_UPDATE_DETAILS, myAccountUpdateDataWorker);
}

export function* changePasswordMyAccountWatcher() {
  yield takeLatest(ActionTypes.SET_MY_CHANGE_PASSWORD, changePasswordMyAccountWorker);
}

export function* deleteMyAccountWatcher() {
  yield takeLatest(ActionTypes.MY_ACCOUNT_DELETE, deleteMyAccountWorker);
}

export function* deleteAccountDataWatcher() {
  yield takeLatest(ActionTypes.DELETE_ACCOUNT_DETAILS, deleteAccountDataWorker);
}

export function* requestAccountWatcher() {
  yield takeLatest(RequestAccountActionTypes.SET_REQUEST_ACCOUNT, requestAccountWorker);
}

export function* passwordRecoveryWatcher() {
  yield takeLatest(PasswordRecoveryActionTypes.SET_PASSWORD_RECOVERY, passwordRecoveryWorker);
}

export function* changePasswordWatcher() {
  yield takeLatest(PasswordRecoveryActionTypes.SET_CHANGE_PASSWORD, changePasswordWorker);
}

export default {
  postUserDataWatcher,
  fetchUserAccountsWatcher,
  deleteUserDataWatcher,
  updateMyAccountDetailsWatcher,
  deleteAccountDataWatcher,
  requestAccountWatcher,
  changePasswordMyAccountWatcher,
  deleteMyAccountWatcher,
  passwordRecoveryWatcher,
  changePasswordWatcher
};

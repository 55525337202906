import React from 'react';
import * as Translations from './../../translations';
import { Row, Col, Table } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
import { styledIcons } from './../../../../common/styles/icons';
import { CharacterService } from './../../character';


function VariationList({
    variations,

    handlePreviewClick,
    reorderCharcterList

}) {


    let sourceIndex = 0;
    let targetIndex = 0;
    let sourceId = 0;
    let targetId = 0;
    const handleDragOver = (e) => {
        targetIndex = e.target.getAttribute('data-id');
        targetId = e.target.getAttribute('data-assetid');
    }

    const handleDragStart = (e) => {
        e.dataTransfer.setData('text', '');
        sourceIndex = e.currentTarget.dataset.id;
        sourceId = e.currentTarget.dataset.assetid;
    }

    const handleDragEnd = (e) => {
        let sprites = CharacterService.reorderSpriteLayers(variations, sourceIndex, targetIndex, sourceId, targetId);

        reorderCharcterList(sprites);
    }

    let spriteRows = variations.map((variation, index) => {
        if (variation.awsPath === null) {
            return
        }
        else {

            return <tr data-id={index} key={index}>

                <td className="variation-name">  {variation.assetName}</td>
                <td data-id={index} className="text-left">{variation.assetType}</td>
                <td data-id={index} className="text-center">{variation.defaultAsset && <styledIcons.defaultIcon />}</td>
                <td data-id={index} className="text-center"><label class="switch">
                    <input onChange={(event) => handlePreviewClick(event, variation.fileIndex, variation.assetType)}
                        type="checkbox"
                        checked={variation.preview && variation.preview === true ? 'checked' : ''}
                    />
                    <span class="slider round"></span>
                </label></td>


            </tr>
        }
    });



    return (
        <div>
            <span className="step-three-col-title">{Translations.SAVED_VARIATIONS.toUpperCase()}</span><br />
            <span className="info-label">{Translations.SAVED_VARIATIONS_NOTE}</span>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                <Col>
                    <LibraryStyle.listTable >
                        <Table responsive className="text-left">
                            <thead className="bg-light">
                                <tr className="table-head-row">

                                    <th className="table-head-col">{Translations.ASSET_IMAGE.toUpperCase()}</th>
                                    <th className="table-head-col text-left">{Translations.ASSET_CHAR_TYPE.toUpperCase()}</th>
                                    <th className="table-head-col text-center">{Translations.ASSET_DEFAULT.toUpperCase()}</th>
                                    <th className="table-head-col text-center">{Translations.PREVIEW.toUpperCase()}</th>
                                </tr>
                            </thead>
                            <tbody className="table-body-row" >

                                {spriteRows}


                                {
                                    variations.length === 0 &&
                                    <tr className="table-body-row text-center">
                                        <td colSpan="3">{Translations.NO_VARIATIONS_CREATED_YET}</td>
                                    </tr>
                                }

                            </tbody>
                        </Table>
                    </LibraryStyle.listTable>
                </Col>
            </Row>
        </div>
    );
}

export default VariationList
import React, { useEffect, useState } from 'react';
import LibraryMenu from './../../components/library/LibraryMenu';
import SelectCharacterImage from './../../components/library/characters/create-character/SelectCharacterImage';
import { connect } from 'react-redux';
import * as LibraryActions from './../../actions/library-action';
import { LibraryServices } from './../../components/library/service';
import { CharacterService } from './../../components/library/character';
import AddCharacterModal from './../../components/library/characters/add-character/AddCharacterModal';
import Warning from './../../components/library/characters/add-character/Warning';
import * as Constant from './../../components/library/constant';
import ConfirmDeleteCharacterAsset from '../../components/common/Modals/Confirm';

import ListFile from './../../components/library/characters/list-files/ListFile';

import ConfirmationWindow from './../../components/library/characters/list-files/ConfirmationWindow';
import { Row, Col } from 'react-bootstrap';

import Progress from './../../components/library/characters/list-files/Progress';

function CreateCharacterStepTwo(props) {
  const assetConstant = {
    fileIndex: '',
    specs: '',
    defaultAsset: false,
    assetName: '',
    assetType: '',
    fileUploaded: true
  };

  const [activeTabKey, setActiveTabKey] = useState(Constant.SECOND_TAB_KEY);
  const [progress, setProgress] = useState(0);
  const [validated, setValidated] = useState(false);
  const [refreshList, setrefreshList] = useState(false);
  const [files, setFiles] = useState([]);
  const [asset, setAsset] = useState(assetConstant);
  const [isEdit, setIsEdit] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [editAssetName, setEditAssetName] = useState(null);

  const [fileNames, setFileNames] = useState([]);
  const [assetFiles, setAssetFiles] = useState([]);
  // const [refreshList, setRefreshList] = useState(true);
  const [maxFileError, setMaxFileError] = useState(false);
  const [enableUpload, setEnableUpload] = useState(false);
  const [showUploadPopup, setShowUploadPopupEnableUpload] = useState(false);

  const [variationData, setVariationData] = useState([]);
  const [variationPreviewData, setVariationPreviewData] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);

  const [focusElement, setFocusElement] = useState('');

  const [outfitCount, setOutfitCount] = useState(0);
  const [hairCount, setHairCount] = useState(0);
  const [expressionCount, setExpressionCOunt] = useState(0);
  const [accessoryCount, setAccesoryCount] = useState(0);
  const [dummyAsset, setDummyAsset] = useState(false);

  //fileNames  assetFiles
  const [confirmAssetDelete, setConfirmAssetDelete] = useState(false);
  const [fileIndex, setFileIndex] = useState(null);

  useEffect(() => {
    if (showUploadPopup === true) {
      props.setUplaodCharacter();
      getFilesData(fileNames);
      // setShowUploadPopupEnableUpload(false);
      setDummyAsset(true);
      setrefreshList(!refreshList);
    }
  }, [fileNames]);

  //}, [fileNames,showUploadPopup]);

  async function getFilesData(fileNames) {
    if (fileNames.length > 0) {
      if (fileNames.length < 16) {
        setAssetFiles(
          await LibraryServices.setCharacterFileData(
            fileNames,
            asset,
            props.variations
          )
        );
      } else {
        setMaxFileError(true);
      }
    }
  }

  useEffect(() => {
    if (props.isUploadCharacter === true) {
      setFileNames([]);
      setAssetFiles([]);
      setDummyAsset(true);
      setShowUploadPopupEnableUpload(false);
    }
  }, [props.isUploadCharacter]);

  useEffect(() => {
    props.fetchCharacterAssetList(props.storyId, props.authToken);
  }, [props.storyId]);

  useEffect(() => {
    setDeleteDefaultData();
  }, []);
  useEffect(() => {
    setDeleteDefaultData();
  }, [props.variations]);

  useEffect(() => {
    if (props.showCharModal === false) {
      setIsEdit(false);
    }
  }, [props.showCharModal]);

  useEffect(() => {
    let sprites = CharacterService.buildSpriteLayers(
      props.selectedAsset,
      props.spriteLayers,
      props.characterAssetList
    );
    props.setSpriteLayers(sprites);
  }, [props.selectedAsset]);

  const setDeleteDefaultData = () => {
    let variations = props.variations;
    let otCount = 0,
      hrCount = 0,
      expCount = 0,
      accCount = 0;
    for (let i = 0; i < variations.length; i++) {
      if (variations[i].assetType === 'Outfit') {
        otCount++;
      }
      if (variations[i].assetType === 'Expression') {
        expCount++;
      }
      if (variations[i].assetType === 'Hair') {
        hrCount++;
      }
      if (variations[i].assetType === 'Accessory') {
        accCount++;
      }
    }
    setOutfitCount(otCount);
    setHairCount(hrCount);
    setExpressionCOunt(expCount);
    setAccesoryCount(accCount);
  };

  /////New Character Logic
  const handleUploadClick = () => {
    setActiveTabKey(Constant.FIRST_TAB_KEY);
    setFiles([]);
    setAsset(assetConstant);
    setValidated(false);
    props.handleShowModal();
  };

  const handleDeleteKey = index => {
    setConfirmAssetDelete(true);
    setFileIndex(index);
  };

  const handleDeleteKeyConfirm = () => {
    props.deleteCharacterAsset(
      LibraryServices.deleteVariationsData(props.variations, fileIndex)
    );
    props.deleteCharacterAsset(
      LibraryServices.deleteVariationsData(props.previewVariations, fileIndex)
    );
    setDeleteDefaultData();
    setrefreshList(!refreshList);
    props.setAutoSaveFlag(true);
    setFileIndex(null);
    setConfirmAssetDelete(false);
  };

  const handleEditKey = index => {
    setActiveTabKey(Constant.FIRST_TAB_KEY);
    setFiles(LibraryServices.setEditAssetFileData(props.Files, index));
    setAsset(LibraryServices.setEditAssetData(props.variations, index));
    setEditAssetName(LibraryServices.setEditAssetName(props.variations, index));

    setValidated(false);
    setIsEdit(true);
    props.handleShowModal();
  };
  const handleSaveInfo = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();

      event.stopPropagation();
      setActiveTabKey(Constant.FOURTH_TAB_KEY);
      let varData = LibraryServices.checkVariationsDefaultData(
        props.variations,
        asset
      );
      let varPreviewData = LibraryServices.checkPreviewVariationsDefaultData(
        props.previewVariations,
        asset
      );

      props.saveCharacterAsset(
        LibraryServices.getVariationsData(varData, asset, props.Files, isEdit),
        LibraryServices.getFilesData(props.Files, files, isEdit, asset)
      );
      props.saveCharacterPreviewAsset(
        LibraryServices.getVariationsPreviewData(
          varPreviewData,
          asset,
          props.Files,
          isEdit
        )
      );
      setrefreshList(!refreshList);
      //setIsEdit(false);
    }

    event.preventDefault();
    setValidated(true);
  };
  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 25);
    }, 200);
  };

  //to="create-character-step-three"
  const redirectToPreview = event => {
    props.history.push('/create-character-step-three');
    event.stopPropagation();
    //   let isEnableVarations =LibraryServices.checkAssetsCount(props.variations);
    //   if (isEnableVarations===true) {
    //     props.history.push('/create-character-step-three');
    // }else
    // {if(props.continueToVariations==='disabled'){

    //   event.stopPropagation();

    //   return;

    // }else{
    //   setShowPreviewModal(true);
    // }
    // }
  };

  const onCancelDeleteClick = () => {
    setFileIndex(null);
    setConfirmAssetDelete(false);
  };

  const HideModal = () => {
    props.handleHideModal();
    setIsEdit(false);
    setEditAssetName(null);
  };

  const handleWarningClick = () => {
    setShowPreviewModal(false);
  };

  const hideAssetFileList = () => {
    setShowConfirm(true);
  };

  const onHideClcik = () => {
    setShowConfirm(false);
    setFileNames([]);
    setAssetFiles([]);
    setShowUploadPopupEnableUpload(false);
  };

  const onCancelClick = () => {
    setShowConfirm(false);
  };

  const handleAssetFileDelete = key => {
    // setFileNames(fileNames.splice(key,0));
    let tempAsset = assetFiles;
    tempAsset.splice(key, 1);
    for (let i = 0; i < tempAsset.length; i++) {
      tempAsset[i].fileIndex = i;
    }

    setAssetFiles(tempAsset);

    let tempFile = fileNames;
    tempFile.splice(key, 1);

    setFileNames(tempFile);

    if (tempAsset.length === 0) {
      setFileNames([]);
      setAssetFiles([]);
      setShowUploadPopupEnableUpload(false);
    }
    setDummyAsset(true);
    setrefreshList(!refreshList);
  };

  const handleNameChange = (e, key) => {
    assetFiles[key].assetName = e.target.value;
    let isUnique = LibraryServices.checkCharacterUniqueAssetName(
      props.variations,
      assetFiles,
      e.target.value,
      key
    );
    if (isUnique === false) {
      assetFiles[key].isUnique = false;
    } else {
      assetFiles[key].isUnique = true;
    }

    if (new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(e.target.value) !== true) {
      assetFiles[key].isValid = false;
    } else {
      assetFiles[key].isValid = true;
    }
    //focusElement,
    setDummyAsset(false);
    setrefreshList(!refreshList);
  };

  const handleDefaultTypeChange = (e, key) => {
    assetFiles[key].defaultAsset = e.target.checked;
    let assetType = assetFiles[key].assetType;
    for (let j = 0; j < assetFiles.length; j++) {
      if (j !== key && e.target.checked === true) {
        if (assetFiles[j].assetType === assetType) {
          if (assetFiles[j].defaultAsset === true) {
            assetFiles[j]['defaultAsset'] = false;
          }
        }
      }
    }

    if (assetFiles[key].assetType === '') {
      assetFiles[key].defaultAsset = false;
    }

    setrefreshList(!refreshList);
  };

  const handleTypeChange = (e, key) => {
    if (e.target.value === '') {
      assetFiles[key].assetType = e.target.value;
      assetFiles[key].defaultAsset = false;
    } else {
      assetFiles[key].assetType = e.target.value;
      let checkDefaultExist = LibraryServices.setUploadVariationsDefaultData(
        props.variations,
        assetFiles,
        e.target.value,
        key
      );

      if (checkDefaultExist === false) {
        assetFiles[key].defaultAsset = true;
      } else {
        let assetType = e.target.value;
        let isDefault = assetFiles[key].defaultAsset;
        for (let j = 0; j < assetFiles.length; j++) {
          if (j !== key && isDefault === true) {
            if (assetFiles[j].assetType === assetType) {
              if (assetFiles[j].defaultAsset === true) {
                assetFiles[j]['defaultAsset'] = false;
              }
            }
          }
        }
      }
    }
    setrefreshList(!refreshList);
  };

  const backToOne = () => {
    props.setToStepOne(true);

    props.history.push('/create-character-step-one');
  };

  const handleUploadInfo = event => {
    // event.preventDefault();
    // event.stopPropagation();
    // const intervalId = interval();
    //         setIntervalId(intervalId);
    //         setProgress(0);
    //         setActiveTabKey(Constant.THIRD_TAB_KEY);
    //         props.UploadAsset(LibraryServices.getUploadFormData( assetFiles,fileNames), props.authToken, props.storyId);

    //fileNames  assetFiles\

    for (let i = 0; i < assetFiles.length; i++) {
      let uploadCountFiles = 0;
      let varData = [];
      let varPreviewData = [];
      if (assetFiles[i].isUpload === true) {
        uploadCountFiles++;
      }
      varData = LibraryServices.checkVariationsDefaultData(
        props.variations,
        assetFiles[i]
      );

      varPreviewData = LibraryServices.checkUploadPreviewVariationsDefaultData(
        props.previewVariations,
        assetFiles[i]
      );

      props.saveCharacterAsset(
        LibraryServices.getUploadVariationsData(
          varData,
          assetFiles[i],
          props.Files,
          isEdit
        ),
        LibraryServices.getUplodFilesData(
          props.Files,
          fileNames[i],
          isEdit,
          assetFiles[i]
        ),
        uploadCountFiles,
        props.UploadFileCount
      );
      props.saveCharacterPreviewAsset(
        LibraryServices.getUploadVariationsPreviewData(
          varPreviewData,
          assetFiles[i],
          props.Files,
          isEdit
        )
      );
    }
    setDummyAsset(true);
    setrefreshList(!refreshList);
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
        <LibraryMenu
          selectedMenu={props.selectedMenu}
          setSelectedMenu={props.setSelectedMenu}
          characterAutoSaveFlag={props.characterAutoSaveFlag}
          resetCharacterAutoSaveFlag={props.resetCharacterAutoSaveFlag}
        />
        </Col>
        <Col className="col-8">
        {props.variations && (
          <SelectCharacterImage
            characterAssetList={props.characterAssetList}
            selectedAssetCount={props.selectedAssetCount}
            selectedAsset={props.selectedAsset}
            filterAssetList={props.filterAssetList}
            continueToVariations={props.continueToVariations}
            initializeCreateCharacter={props.initializeCreateCharacter}
            selectAll={props.selectAll}
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            handleUploadClick={handleUploadClick}
            variations={props.variations}
            files={props.Files}
            handleDeleteKey={handleDeleteKey}
            handleEditKey={handleEditKey}
            redirectToPreview={redirectToPreview}
            IsCharacterEdit={props.IsCharacterEdit}
            setFileNames={setFileNames}
            maxFileError={maxFileError}
            setMaxFileError={setMaxFileError}
            setShowUploadPopupEnableUpload={setShowUploadPopupEnableUpload}
            outfitCount={outfitCount}
            hairCount={hairCount}
            expressionCount={expressionCount}
            accessoryCount={accessoryCount}
            UploadFileCount={props.UploadFileCount}
            backToOne={backToOne}
          />
        )}
        {/* fileNames.length>0 && props.isUploadAsset===false && */}
        {fileNames.length > 0 && props.isUploadCharacter === false && (
          <ListFile
            fileNames={fileNames}
            assetFiles={assetFiles}
            refreshList={refreshList}
            onHide={hideAssetFileList}
            handleAssetFileDelete={handleAssetFileDelete}
            handleNameChange={handleNameChange}
            handleTypeChange={handleTypeChange}
            handleUploadInfo={event => handleUploadInfo(event)}
            enableUpload={enableUpload}
            setEnableUpload={setEnableUpload}
            setShowUploadPopupEnableUpload={setShowUploadPopupEnableUpload}
            handleDefaultTypeChange={handleDefaultTypeChange}
            focusElement={focusElement}
            dummyAsset={dummyAsset}
            setDummyAsset={setDummyAsset}
          />
        )}
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
      {showConfirm === true && (
        <ConfirmationWindow
          show={showConfirm}
          onCancelClick={onCancelClick}
          onHideClcik={onHideClcik}
        />
      )}

      {props.showCharModal === true && (
        <AddCharacterModal
          showModal={props.showCharModal}
          onHideModal={HideModal}
          continuetoaddinfoclass={isEdit ? 'button-active' : ''}
          activeTabKey={LibraryServices.getActiveTabKey(
            props.assetStatus,
            activeTabKey,
            props.handleHideModal,
            intervalId
          )}
          validated={validated}
          setValidated={setValidated}
          showclearfile={false.toString()}
          setAsset={data => setAsset(data)}
          setActiveTabKey={data => setActiveTabKey(data)}
          progress={progress}
          handleSaveInfo={event => handleSaveInfo(event)}
          variations={props.variations}
          files={files}
          asset={asset}
          setFiles={data => setFiles(data)}
          isEdit={isEdit}
          IsCharacterEdit={props.IsCharacterEdit}
          editAssetName={editAssetName}
          // handleRollbackAsset = {() => handleRollbackAsset()}
          setAutoSaveFlag={props.setAutoSaveFlag}
        />
      )}
      {showPreviewModal === true && (
        <Warning
          handleWarningClick={handleWarningClick}
          showModal={showPreviewModal}
        />
      )}
      {confirmAssetDelete === true && (
        <ConfirmDeleteCharacterAsset
          firstLineLabel="If you delete this assset, you may see errors in script wherever it is used. You need to change it manually"
          secondLineLabel="&nbsp;"
          onClose={onCancelDeleteClick}
          cancel="Cancel"
          confirm="Yes, delete it"
          show={confirmAssetDelete}
          handleClearCharacter={handleDeleteKeyConfirm}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    characterAssetList: state.CharacterReducer.characterAssetList,
    storyId: state.StoryReducer.storyId,
    authToken: state.LoginReducer.authToken,
    selectedAsset: state.CharacterReducer.selectedAsset,
    selectedAssetCount: state.CharacterReducer.selectedAssetCount,
    filterAssetList: state.CharacterReducer.filterAssetList,
    continueToVariations: state.CharacterReducer.continueToVariations,
    spriteLayers: state.CharacterReducer.spriteLayers,
    selectAll: state.CharacterReducer.selectAll,
    selectedMenu: state.LibraryReducer.selectedMenu,

    //New Character creation process
    showCharModal: state.LibraryReducer.showCharModal,
    variations: state.CharacterReducer.variations,
    previewVariations: state.CharacterReducer.previewVariations,
    Files: state.CharacterReducer.Files,
    IsCharacterEdit: state.CharacterReducer.IsCharacterEdit,
    characterAutoSaveFlag: state.CharacterReducer.autoSaveFlag,
    isUploadCharacter: state.CharacterReducer.isUploadCharacter,
    UploadFileCount: state.CharacterReducer.UploadFileCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCharacterAssetList: (storyId, authToken) =>
      dispatch(LibraryActions.setCharacterAssetList(storyId, authToken)),
    setSelectedAsset: characterAssetId =>
      dispatch(LibraryActions.setSelectedAsset(characterAssetId)),
    setFilterAssetList: filterAssetList =>
      dispatch(LibraryActions.setFilterAssetList(filterAssetList)),
    setSpriteLayers: sprites =>
      dispatch(LibraryActions.setSpriteLayers(sprites)),
    initializeCreateCharacter: () =>
      dispatch(LibraryActions.initializeCreateCharacter()),
    setSelectAll: selectAll => dispatch(LibraryActions.setSelectAll(selectAll)),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),

    handleShowModal: () => dispatch(LibraryActions.setCharShowModalTrue()),
    handleHideModal: () => dispatch(LibraryActions.setCharShowModalFalse()),

    saveCharacterAsset: (asset, file, uploadCountFiles, UploadFileCount) =>
      dispatch(
        LibraryActions.setCharacterAsset(
          asset,
          file,
          uploadCountFiles,
          UploadFileCount
        )
      ),
    setUplaodCharacter: () => dispatch(LibraryActions.setUplaodCharacter()),

    saveCharacterPreviewAsset: asset =>
      dispatch(LibraryActions.setCharacterPreviwAsset(asset)),

    deleteCharacterAsset: asset =>
      dispatch(LibraryActions.deleteCharacterAsset(asset)),
    deleteCharacterPreviewAsset: asset =>
      dispatch(LibraryActions.deleteCharacterPreviewAsset(asset)),
    resetCharacterAutoSaveFlag: () =>
      dispatch(LibraryActions.resetAutoSaveFlag()),
    setAutoSaveFlag: flag => dispatch(LibraryActions.setAutoSaveFlag(flag)),

    setToStepOne: flag => dispatch(LibraryActions.setToStepOne(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCharacterStepTwo);

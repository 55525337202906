import React, { useEffect, useState } from 'react';
import { PlayerService } from './service/player-service';
import * as Constant from './../../common/constants';
import { DialogueCommands } from './service/dialgoue-commands';
import { CharacterCommands } from './service/character-commands';
import { BackGroundCommands } from './service/background-commands';
import { MediaCommands } from './service/media-commands';
import { BlockCommands } from './service/block-commands';
import { ObjectCommands } from './service/object-commands';
import ShowNameDialgoue from './widgets/ShowNameDialogue';
import ConditionAnalyser from './ConditionAnalyser';
import CreateChoice from './service/create-choice';
import DialogComponentNew from './service/DialogComponentNew';

function BlocksPlayer({
  commandsTimeoutIds,
  setCommandsTimeoutIds,
  blocks,
  play,
  firstPlay,
  lastPlay,
  prevPlay,
  nextPlay,
  gotoPlay,
  setGotoPlay,
  currentBlock,
  setCurrentBlock,
  currentCommand,
  setCurrentCommand,
  currentScene,
  setCurrentScene,
  fastPreview,
  checkCommandState,
  setFastPreview,
  setCheckCommandState,
  setPlay,
  handlePlayerButtonsClick,
  handleDecisionClick,
  setShowDecision,
  showDecision,
  decisionParameters,
  setDecisionParameters,
  ifElseDecisionParameters,
  setIfElseDecisionParameters,
  showEnd,
  blobImages,
  setChoiceTimeout,
  choiceTimeout,
  lastView,
  setLastView,
  showDialogue,
  setShowDialogue,
  views,
  setShakeX,
  setShakeY,
  setPlayFromStart,
  showNameDialogue,
  setShowNameDialogue,
  setDialogueVariables,
  aspectRatio,
  interfaceList,
  localVariableListDTO,
  consoleMessage,
  updateVariableWithId,
  setShowEnd,
  changeCursorPosition,
  gameFonts,
  setExternalPlayPauseHandler,
  executedCommand,
  setExecutedCommand,
  setShowScriptError,
  setCommand,
  dialogueText,
  dialogueCount,
  setDialogueText,
  setDialogueCount,
  previewScroll,
  highlightBlockIndex,
  startFromHighlightBlock,
  setStartFromHighlightBlock,
  hideIfVisitedArray
}) {
  var totalTimeout = 0;
  let skipTimeoutTemp = false;
  let flowchartData = [];
  let previousCommand = [];
  let currentView = null;
  let currentObjectBaseView = null;
  let lastEnterCharacter = {};
  let lastHideObj = null;

  const [dialogueCommand, setDialogueCommand] = useState(null);
  const [outsideClick, setOutsideClick] = useState(false);

  const cmdCondition = new ConditionAnalyser();

  useEffect(() => {
    if (true === play && blocks != null) {
      skipTimeoutTemp = fastPreview;
      flowchartData = blocks;
      playScenes(blocks, fastPreview);
    }
  }, [
    play,
    firstPlay,
    lastPlay,
    prevPlay,
    nextPlay,
    gotoPlay,
    localVariableListDTO
  ]);

  // useEffect(
  //   () => {
  //     console.log(ifElseDecisionParameters);
  //   },
  //   ifElseDecisionParameters],
  //   []
  // );

  const playScenes = (scenes, skipTimeout) => {
    totalTimeout = 0;
    if (scenes.length === 0) {
      return [];
    }
    scenes.map((scene, sceneIndex) => {
      if (scene.blocks.length > 0) {
        playBlocks(scene.blocks, sceneIndex, skipTimeout);
      }
    });
  };

  const playBlocks = (blocks, sceneIndex, skipTimeout) => {
    if (blocks.length === 0) {
      return [];
    }

    blocks.map((block, blockIndex) => {
      if (
        startFromHighlightBlock === true &&
        blockIndex >= highlightBlockIndex
      ) {
        setStartFromHighlightBlock(false);
        playCommands(block, blockIndex, sceneIndex, skipTimeout);
      } else if (startFromHighlightBlock === false) {
        playCommands(block, blockIndex, sceneIndex, skipTimeout);
      }
    });
  };

  let gameInterfaceList = PlayerService.mapInterfaceElements(interfaceList);

  const playCommands = (block, blockIndex, sceneIndex, skipTimeout) => {
    if (block.commands.length === 0) {
      playLastBlock(totalTimeout);
    }
    let currentTimeoutId = 0;
    setDecisionParameters(null);
    block.commands.map((command, commandIndex) => {
      skipTimeoutTemp = PlayerService.prepareSkipTimeout(
        checkCommandState,
        skipTimeout,
        currentScene,
        currentBlock,
        currentCommand,
        sceneIndex,
        blockIndex,
        commandIndex,
        skipTimeoutTemp
      );
      switch (command.commandId) {
        case Constant.COMMAND_ENTER_THE_SCENE:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('enter character')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  enterCharacterCommand: command
                }
              };
              if (
                lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand !== undefined
              ) {
                delete lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand;
              }

              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('enter character')) {
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  enterCharacterCommand: command
                }
              };
              if (
                lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand !== undefined
              ) {
                delete lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand;
              }
            }
          }
          break;

        case Constant.COMMAND_PLACE_VIEW:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('view')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              BackGroundCommands.setBackGround(
                command,
                blobImages,
                lastView,
                aspectRatio,
                skipTimeoutTemp,
                currentView
              );
              currentView = command.view.id;
              currentObjectBaseView = command.view;
              setLastView(command);
              previousCommand = command;
              lastHideObj = null;
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('view')) {
              previousCommand = command;
              BackGroundCommands.setBackGround(
                command,
                blobImages,
                lastView,
                aspectRatio,
                skipTimeoutTemp,
                currentView
              );
              currentView = command.view.id;
              currentObjectBaseView = command.view;
              lastHideObj = null;
            }
          }
          break;

        case Constant.COMMAND_GO_TO_BLOCK:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('gotoBlock')
          ) {
            currentTimeoutId = setTimeout(() => {
              if (block.connectors.length > 0) {
                PlayerService.clearAllTimeout(commandsTimeoutIds);
                PlayerService.clearPreviewDocument();
                setFastPreview(true);
                let indexes = PlayerService.collectIndexes(
                  flowchartData,
                  command.parameters.Destination
                );
                if (previewScroll === true && play === true) {
                  changeCursorPosition(command.parameters.lineNumber - 1);
                }
                setCurrentBlock(indexes[1]);
                setCurrentScene(indexes[0]);
                setCurrentCommand(0);
                setGotoPlay(!gotoPlay);
                setPlayFromStart(false);
                setCheckCommandState(false);
                setCommand(command);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          }
          break;

        case Constant.COMMAND_LEAVES_THE_SCENE:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('leave Character')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              let commandExecution = CharacterCommands.leaveTheScene(
                command,
                skipTimeoutTemp
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  hideCharacterCommand: command
                }
              };
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);

              addConsoleMessage(commandExecution, command);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('leave character')) {
              let commandExecution = CharacterCommands.leaveTheScene(
                command,
                skipTimeoutTemp
              );
              addConsoleMessage(commandExecution, command);
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  hideCharacterCommand: command
                }
              };
            }
          }
          break;

        case Constant.COMMAND_CHANGE_EXPRESSION:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('change expression')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  changeExpressionCommand: command
                }
              };

              if (
                lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand !== undefined
              ) {
                delete lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand;
              }
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('changeexpression')) {
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  changeExpressionCommand: command
                }
              };

              if (
                lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand !== undefined
              ) {
                delete lastEnterCharacter[command.parameters.Character]
                  .hideCharacterCommand;
              }
            }
          }
          break;

        case Constant.COMMAND_REPLACE_CHARACTER:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('replace character')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              // let originalCharacterParameters = lastEnterCharacter[command.parameters['Original character']].enterCharacterCommand.parameters
              let originalCharacterParameters;
              if (
                lastEnterCharacter[command.parameters['Original character']] &&
                lastEnterCharacter[command.parameters['Original character']]
                  .enterCharacterCommand
              ) {
                originalCharacterParameters =
                  lastEnterCharacter[command.parameters['Original character']]
                    .enterCharacterCommand.parameters;
              } else {
                originalCharacterParameters = {
                  [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
                  [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false'
                };
              }
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters['New character']]: {
                  ...lastEnterCharacter[command.parameters['New character']],
                  enterCharacterCommand: {
                    parameters: {
                      [Constant.CHARACTER_POSITION_IN_SCREEN]:
                        originalCharacterParameters[
                          Constant.CHARACTER_POSITION_IN_SCREEN
                        ],
                      [Constant.CHARACTER_FLIP_HORIZONTALLY]:
                        originalCharacterParameters[
                          Constant.CHARACTER_FLIP_HORIZONTALLY
                        ]
                    }
                  }
                }
              };
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('replace character')) {
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter
              );
              let originalCharacterParameters;
              if (
                lastEnterCharacter[command.parameters['Original character']] &&
                lastEnterCharacter[command.parameters['Original character']]
                  .enterCharacterCommand
              ) {
                originalCharacterParameters =
                  lastEnterCharacter[command.parameters['Original character']]
                    .enterCharacterCommand.parameters;
              } else {
                originalCharacterParameters = {
                  [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
                  [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false'
                };
              }

              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters['New character']]: {
                  ...lastEnterCharacter[command.parameters['New character']],
                  enterCharacterCommand: {
                    parameters: {
                      [Constant.CHARACTER_POSITION_IN_SCREEN]:
                        originalCharacterParameters[
                          Constant.CHARACTER_POSITION_IN_SCREEN
                        ],
                      [Constant.CHARACTER_FLIP_HORIZONTALLY]:
                        originalCharacterParameters[
                          Constant.CHARACTER_FLIP_HORIZONTALLY
                        ]
                    }
                  }
                }
              };
            }
          }
          break;

        case Constant.COMMAND_SAY_GENDERED:
        case Constant.COMMAND_ADD_DIALOGUE:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('dialogueCommand')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              PlayerService.clearAllTimeout(commandsTimeoutIds);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              let dialogue = DialogueCommands.replaceNombre(
                command,
                localVariableListDTO
              );
              dialogue = DialogueCommands.replaceUpright(
                localVariableListDTO,
                dialogue
              );
              dialogue = DialogueCommands.replaceVariables(
                localVariableListDTO,
                dialogue
              );

              dialogue =
                dialogue[0] === '`'
                  ? dialogue.substr(1, dialogue.length)
                  : dialogue;
              dialogue =
                dialogue[dialogue.length - 1] === '`'
                  ? dialogue.substr(0, dialogue.length - 1)
                  : dialogue;

              let dialogueList = [dialogue.trim()];
              setDialogueText(dialogueList);
              setDialogueCommand(command);

              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setPlayFromStart(false);
              setPlay(false);
              setShowDialogue(true);
              if (dialogueList.length > 1) {
                setDialogueCount(1);
              }

              if (dialogueList.length === 1 && validEnd) {
                if (totalTimeout === 0) {
                  playLastBlock(2000);
                } else {
                  playLastBlock(parseInt(dialogueList[0].length / 2) * 100);
                }
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('dialogueCommand')) {
              DialogueCommands.parseDialogueDiv(
                command,
                true,
                gameInterfaceList,
                blobImages,
                '',
                gameFonts
              );
              setShowDialogue(false);
            }
          }
          break;

        case Constant.COMMAND_MOVE_BACKGROUND:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('move bg')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              let commandExecution = BackGroundCommands.moveBackGround(
                command,
                blobImages,
                aspectRatio,
                skipTimeoutTemp,
                previousCommand,
                currentView,
                lastHideObj
              );
              addConsoleMessage(commandExecution, command);
              currentObjectBaseView = command.view;
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('move bg')) {
              let commandExecution = BackGroundCommands.moveBackGround(
                command,
                blobImages,
                aspectRatio,
                skipTimeoutTemp,
                previousCommand,
                currentView,
                lastHideObj
              );
              currentObjectBaseView = command.view;
              addConsoleMessage(commandExecution, command);
            }
          }
          break;

        case Constant.COMMAND_STOP_MUSIC:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('stopmusic')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              let commandExecution = MediaCommands.stopMusic(command);
              addConsoleMessage(commandExecution, command);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          }
          break;

        case Constant.COMMAND_PLAY_MUSIC:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('music command')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              MediaCommands.playMusic(command);

              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          }
          break;

        case Constant.COMMAND_PLAY_SOUND:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('play sound')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              MediaCommands.setMedia(command);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          }
          break;

        case Constant.COMMAND_PAID_ADD_DECISION:
          let objectChoiceCommand = command.parameters;
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('choice command')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            if (cmdCondition.ifConditionCount <= 0) {
              currentTimeoutId = setTimeout(() => {
                let waitingTimeoutId = BlockCommands.addDecision(
                  command,
                  skipTimeoutTemp,
                  handleDecisionClick,
                  totalTimeout
                );
                setChoiceTimeoutState(waitingTimeoutId);
                setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
                setShowDecision(true);
                setDecisionParameters(command.parameters);
                PlayerService.clearAllTimeout(commandsTimeoutIds);
                if (previewScroll === true && play === true) {
                  changeCursorPosition(command.parameters.lineNumber - 1);
                }
                executedCommand.push(command.parameters.lineNumber);
                setExecutedCommand(executedCommand);
                setCommand(command);
                if (validEnd) {
                  playLastBlock(totalTimeout);
                }
              }, totalTimeout);
              setCommandTimeoutState(currentTimeoutId);
            } else if (blockIndex === currentBlock) {
              let arrayOfConditionChoice = ifElseDecisionParameters;

              objectChoiceCommand['block'] = block.name;
              objectChoiceCommand['commandIndex'] = commandIndex;
              if (
                ifElseDecisionParameters.length > 0 &&
                cmdCondition.conditionValues.length > 0
              ) {
                const duplicateChoice = arrayOfConditionChoice.findIndex(
                  choice =>
                    block.name === choice.block &&
                    commandIndex === choice.commandIndex
                );
                if (duplicateChoice < 0) {
                  arrayOfConditionChoice.push(command.parameters);
                  setIfElseDecisionParameters(arrayOfConditionChoice);
                }
              } else {
                arrayOfConditionChoice.push(command.parameters);
                setIfElseDecisionParameters(arrayOfConditionChoice);
              }
            }
          }
          break;

        case Constant.COMMAND_HIDDEN_CHARACTER:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('hide character')
          ) {
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              let commandExecution = CharacterCommands.showHiddenCharacter(
                command,
                skipTimeoutTemp
              );
              addConsoleMessage(commandExecution, command);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('hide character')) {
              let commandExecution = CharacterCommands.showHiddenCharacter(
                command
              );
              addConsoleMessage(commandExecution, command);
            }
          }
          break;

        case Constant.COMMAND_TIME_DELAY:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('time delay')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);

            setCommandTimeoutState(currentTimeoutId);
          }
          break;

        case Constant.COMMAND_SET_AUDIO:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('volume command')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              MediaCommands.addVolume(command, false);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('volume command')) {
              MediaCommands.addVolume(command, true);
            }
          }
          break;

        case Constant.COMMAND_SHOW_OBJECT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('show object')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              let commandExecution = ObjectCommands.showObject(
                command,
                false,
                blobImages,
                views,
                aspectRatio,
                currentObjectBaseView.id
              );
              addConsoleMessage(commandExecution, command);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
            setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('show object')) {
              let commandExecution = ObjectCommands.showObject(
                command,
                true,
                blobImages,
                views,
                aspectRatio,
                currentObjectBaseView.id
              );
              addConsoleMessage(commandExecution, command);
            }
          }
          break;

        case Constant.COMMAND_HIDE_OBJECT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('hide object')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );

            currentTimeoutId = setTimeout(() => {
              let commandExecution = ObjectCommands.hideObject(
                command,
                currentObjectBaseView,
                false
              );
              lastHideObj = commandExecution;
              addConsoleMessage(commandExecution, command);
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
            setCommandTimeoutState(currentTimeoutId);
            setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('hide object')) {
              let commandExecution = ObjectCommands.hideObject(
                command,
                currentObjectBaseView,
                true
              );
              lastHideObj = commandExecution;
              addConsoleMessage(commandExecution, command);
            }
          }
          break;

        case Constant.COMMAND_TAP_OBJECT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('tap object')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              PlayerService.clearAllTimeout(commandsTimeoutIds);

              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              ObjectCommands.tapObject(
                block,
                flowchartData,
                handleObjectClick,
                currentObjectBaseView
              );
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              setPlayFromStart(false);
              setPlay(false);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
          }
          break;

        case Constant.COMMAND_SHAKE_CAMERA:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('shake camera')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              setShakeX(command.parameters.X);
              setShakeY(command.parameters.Y);
              MediaCommands.shakeCamera(command, currentView);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);

              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
          }
          break;

        case Constant.COMMAND_SHOW_NAME_DIALOGUE:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('show name dialogue')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setPlayFromStart(false);
              setShowNameDialogue(true);
              setPlay(false);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
          }
          break;

        case Constant.COMMAND_ADD_IF:
          let checkingCondition = PlayerService.getVariable(
            localVariableListDTO,
            command.parameters.Variable
          );

          if (
            checkingCondition.datatype == 'Integer' ||
            checkingCondition.datatype == 'Float'
          ) {
            cmdCondition.whetherToExecute(
              `if ${checkingCondition.value} ${
                command.parameters['Compare type']
              } ${command.parameters['Variable value']}`
            );
          } else {
            cmdCondition.whetherToExecute(
              `if "${checkingCondition.value}" ${
                command.parameters['Compare type']
              } "${command.parameters['Variable value']}"`
            );
          }
          break;

        case Constant.COMMAND_ADD_ELSE_IF:
          const checkingConditionElseIf = PlayerService.getVariable(
            localVariableListDTO,
            command.parameters.Variable
          );
          if (
            checkingConditionElseIf.datatype == 'Integer' ||
            checkingConditionElseIf.datatype == 'Float'
          ) {
            cmdCondition.whetherToExecute(
              `elseIf ${checkingConditionElseIf.value} ${
                command.parameters['Compare type']
              } ${command.parameters['Variable value']}`
            );
          } else {
            cmdCondition.whetherToExecute(
              `elseIf "${checkingConditionElseIf.value}" ${
                command.parameters['Compare type']
              } "${command.parameters['Variable value']}"`
            );
          }
          break;

        case Constant.COMMAND_ADD_END:
          if (
            cmdCondition.ifConditionCount === 1 &&
            blockIndex === currentBlock &&
            ifElseDecisionParameters.length > 0
          ) {
            let commandSkip = { parameters: '' };
            commandSkip.parameters =
              ifElseDecisionParameters[ifElseDecisionParameters.length - 1];
            currentTimeoutId = setTimeout(() => {
              let waitingTimeoutId = BlockCommands.addDecision(
                commandSkip,
                skipTimeoutTemp,
                handleDecisionClick,
                totalTimeout
              );
              setChoiceTimeoutState(waitingTimeoutId);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setShowDecision(true);
              // setDecisionParameters(command.parameters);
              PlayerService.clearAllTimeout(commandsTimeoutIds);
              // if (previewScroll === true && play === true) {
              //   changeCursorPosition(command.parameters.lineNumber - 1);
              // }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCommand(command);
              // if (validEnd) {
              //   playLastBlock(totalTimeout);
              // }
              if (previewScroll === true && play === true) {
                changeCursorPosition(
                  ifElseDecisionParameters[0].lineNumber - 1
                );
              }
            }, totalTimeout);

            setCommandTimeoutState(currentTimeoutId);
          } else {
            setDecisionParameters(null);

            if (previewScroll === true && play === true) {
              changeCursorPosition(command.parameters.lineNumber - 1);
            }
          }
          cmdCondition.whetherToExecute('end');
          break;

        case Constant.COMMAND_ADD_ELSE:
          cmdCondition.whetherToExecute('else');
          break;

        case Constant.COMMAND_SET_VAR:
          // setDecisionParameters(null);
          // setIfElseDecisionParameters([]);
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('set var')
          ) {
            currentTimeoutId = setTimeout(() => {
              let varialbeValueTrue = 0;
              let SetVariableDTO =
                command.parameters['Variable value'] == null
                  ? ''
                  : command.parameters['Variable value'];
              const LocalSetVar = PlayerService.getVariable(
                localVariableListDTO,
                command.parameters.Variable
              );
              if (command.parameters['Is Variable'] == 'true') {
                varialbeValueTrue = PlayerService.getVariable(
                  localVariableListDTO,
                  command.parameters['Variable value']
                );
              }
              if (LocalSetVar !== command.parameters['Variable value']) {
                setDecisionParameters(null);
                setIfElseDecisionParameters([]);
              }
              switch (command.parameters.Operator) {
                case '=!':
                  SetVariableDTO = SetVariableDTO == 'true' ? 'false' : 'true';
                  break;
                case '+=':
                  if (command.parameters['Is Variable'] == 'true') {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) +
                      parseFloat(varialbeValueTrue.value);
                  } else {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) +
                      parseFloat(command.parameters['Variable value']);
                  }
                  break;
                case '-=':
                  if (command.parameters['Is Variable'] == 'true') {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) -
                      parseFloat(varialbeValueTrue.value);
                  } else {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) -
                      parseFloat(command.parameters['Variable value']);
                  }
                  break;
                case '/=':
                  if (command.parameters['Is Variable'] == 'true') {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) /
                      parseFloat(varialbeValueTrue.value);
                  } else {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) /
                      parseFloat(command.parameters['Variable value']);
                  }
                  break;
                case '*=':
                  if (command.parameters['Is Variable'] == 'true') {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) *
                      parseFloat(varialbeValueTrue.value);
                  } else {
                    SetVariableDTO =
                      parseFloat(LocalSetVar.value) *
                      parseFloat(command.parameters['Variable value']);
                  }
                  break;
                case '=':
                  if (command.parameters['Is Variable'] == 'true') {
                    SetVariableDTO = varialbeValueTrue.value;
                  } else {
                    SetVariableDTO = command.parameters['Variable value'];
                  }
                  break;
              }
              if (command.parameters['Variable type'] === 'Integer') {
                SetVariableDTO = parseInt(SetVariableDTO);
              }
              if (
                command.parameters['Variable value'] ===
                LocalSetVar.defaultValue
              ) {
                updateVariableWithId(LocalSetVar.id, SetVariableDTO, 'reset');
              } else {
                updateVariableWithId(LocalSetVar.id, SetVariableDTO, 'normal');
              }
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);

              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          }

          break;

        case Constant.COMMAND_GOTO_CHAPTER:
          if (skipTimeoutTemp === false) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
            totalTimeout += PlayerService.mapTimeout(command, skipTimeoutTemp);
          }
          break;

        case Constant.COMMAND_SET_OUTFIT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('set outfit')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);

              const LocalSetVar = PlayerService.getVariable(
                localVariableListDTO,
                `${command.parameters.characterDisplayName}-Outfit`
              );

              const LocalSetVarHair = PlayerService.getVariable(
                localVariableListDTO,
                `${command.parameters.characterDisplayName}-Hair`
              );

              const LocalSetVarAcc = PlayerService.getVariable(
                localVariableListDTO,
                `${command.parameters.characterDisplayName}-Accessory`
              );

              if (
                command.parameters.variationDisplayName ===
                LocalSetVar.defaultValue
              ) {
                updateVariableWithId(
                  LocalSetVar.id,
                  command.parameters.variationDisplayName,
                  'reset'
                );
              } else {
                updateVariableWithId(
                  LocalSetVar.id,
                  command.parameters.variationDisplayName,
                  'normal'
                );
              }

              if (
                command.parameters.accessoryDisplayName ===
                LocalSetVarAcc.defaultValue
              ) {
                updateVariableWithId(
                  LocalSetVarAcc.id,
                  command.parameters.accessoryDisplayName,
                  'reset'
                );
              } else {
                updateVariableWithId(
                  LocalSetVarAcc.id,
                  command.parameters.accessoryDisplayName,
                  'normal'
                );
              }

              if (
                command.parameters.hairDisplayName ===
                LocalSetVarHair.defaultValue
              ) {
                updateVariableWithId(
                  LocalSetVarHair.id,
                  command.parameters.hairDisplayName,
                  'reset'
                );
              } else {
                updateVariableWithId(
                  LocalSetVarHair.id,
                  command.parameters.hairDisplayName,
                  'normal'
                );
              }

              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  setOutfitCommand: command
                }
              };

              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('set outfit')) {
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  setOutfitCommand: command
                }
              };
            }
          }
          break;

        case Constant.COMMAND_SHOW_OUTFIT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('show outfit')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);

              let variableDto = PlayerService.preparePlayerVariableDto(
                command.parameters,
                localVariableListDTO
              );
              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  showOutfitCommand: command
                }
              };
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                false,
                aspectRatio,
                lastEnterCharacter,
                variableDto
              );

              const LocalSetExpression = PlayerService.getVariable(
                localVariableListDTO,
                `${command.parameters.characterDisplayName}-Expression`
              );

              if (
                command.parameters.expressionDisplayName ===
                LocalSetExpression.defaultValue
              ) {
                updateVariableWithId(
                  LocalSetExpression.id,
                  command.parameters.expressionDisplayName,
                  'reset'
                );
              } else {
                updateVariableWithId(
                  LocalSetExpression.id,
                  command.parameters.expressionDisplayName,
                  'normal'
                );
              }

              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('show outfit')) {
              let variableDto = PlayerService.preparePlayerVariableDto(
                command.parameters,
                localVariableListDTO
              );

              lastEnterCharacter = {
                ...lastEnterCharacter,
                [command.parameters.Character]: {
                  ...lastEnterCharacter[command.parameters.Character],
                  showOutfitCommand: command
                }
              };
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                true,
                aspectRatio,
                lastEnterCharacter,
                variableDto
              );
            }
          }
          break;

        case Constant.COMMAND_REPLACE_OUTFIT:
          if (
            skipTimeoutTemp === false &&
            cmdCondition.whetherToExecute('replace outfit')
          ) {
            let validEnd = PlayerService.isChapterEnd(
              flowchartData,
              blockIndex,
              commandIndex
            );
            currentTimeoutId = setTimeout(() => {
              if (previewScroll === true && play === true) {
                changeCursorPosition(command.parameters.lineNumber - 1);
              }
              executedCommand.push(command.parameters.lineNumber);
              setExecutedCommand(executedCommand);
              setCurrentStateOfPlay(sceneIndex, blockIndex, commandIndex);
              setCommand(command);
              let variableDto = PlayerService.preparePlayerVariableDto(
                command.parameters,
                localVariableListDTO
              );

              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter,
                variableDto
              );

              const LocalSetExpression = PlayerService.getVariable(
                localVariableListDTO,
                `${command.parameters.characterDisplayName}-Expression`
              );
              if (LocalSetExpression) {
                if (
                  command.parameters.expressionDisplayName ===
                  LocalSetExpression.defaultValue
                ) {
                  updateVariableWithId(
                    LocalSetExpression.id,
                    command.parameters.expressionDisplayName,
                    'reset'
                  );
                } else {
                  updateVariableWithId(
                    LocalSetExpression.id,
                    command.parameters.expressionDisplayName,
                    'normal'
                  );
                }
              }

              if (validEnd) {
                playLastBlock(totalTimeout);
              }
            }, totalTimeout);
            setCommandTimeoutState(currentTimeoutId);
          } else {
            if (!executedCommand.includes(command.parameters.lineNumber)) {
              return;
            }
            if (cmdCondition.whetherToExecute('replace outfit')) {
              let variableDto = PlayerService.preparePlayerVariableDto(
                command.parameters,
                localVariableListDTO
              );
              CharacterCommands.playEnterTheScene(
                command,
                blobImages,
                skipTimeoutTemp,
                aspectRatio,
                lastEnterCharacter,
                variableDto
              );
            }
          }
          break;
      }
    });

    return totalTimeout;
  };

  const playLastBlock = totalTimeout => {
    let lastTimeout = setTimeout(() => {
      PlayerService.flushPreviewDocument();
      setShowEnd(true);
      setPlay(false);
      setExternalPlayPauseHandler(false);
      setPlayFromStart(true);
      PlayerService.clearAllTimeout(commandsTimeoutIds);
      setExecutedCommand([]);
      setShowScriptError(false);
      setShowDialogue(false);
      setFastPreview(false);
    }, parseInt(totalTimeout + 4000));
    setCommandTimeoutState(lastTimeout);
  };

  const handleObjectClick = (flowchartData, destinationBlock) => {
    let indexes = PlayerService.collectIndexes(flowchartData, destinationBlock);

    handlePlayerButtonsClick('', Constant.EVENT_PLAY, false, {
      currentScene: indexes[0],
      currentBlock: indexes[1],
      currentCommand: 0
    });
  };

  const setCommandTimeoutState = currentTimeoutId => {
    commandsTimeoutIds.push(currentTimeoutId);
    setCommandsTimeoutIds(commandsTimeoutIds);
  };

  const setChoiceTimeoutState = currentTimeoutId => {
    choiceTimeout.push(currentTimeoutId);
    setChoiceTimeout(choiceTimeout);
  };

  const handleStartPlaying = event => {
    handlePlayerButtonsClick(event, Constant.EVENT_PLAY);
    setDialogueText([]);
    setDialogueCount(0);
  };

  const setCurrentStateOfPlay = (sceneIndex, blockIndex, commandIndex) => {
    setCurrentBlock(blockIndex);
    setCurrentCommand(commandIndex);
    setCurrentScene(sceneIndex);
  };

  const addConsoleMessage = (commandExecution, command) => {
    if (commandExecution !== false) {
      return;
    }

    let message = PlayerService.getConsoleMessage(
      command.commandId,
      command.parameters.lineNumber
    );

    consoleMessage.push(message);
  };

  const handleOutsideClick = () => {
    setOutsideClick(!outsideClick);
  };

  return (
    <React.Fragment>
      <div className="preview" onClick={handleOutsideClick}>
        <div
          id="preview-content"
          className={showNameDialogue ? 'blur-background' : ''}
        ></div>
        {showDecision && (
          <CreateChoice
            ifElseDecisionParameters={ifElseDecisionParameters}
            decisionParameters={decisionParameters}
            handleDecisionClick={handleDecisionClick}
            blobImages={blobImages}
            gameInterfaceList={gameInterfaceList}
            gameFonts={gameFonts}
            hideIfVisitedArray={hideIfVisitedArray}
          />
        )}
        {showEnd && (
          <div className="button-preview-wrapper" id="button-preview-wrapper">
            <div className="btn-img-wrapper">
              <span className="btn-img-label endMsg">The End</span>
            </div>
          </div>
        )}
        {showDialogue && (
          <div id="quickdialgoue" className="quickdialgoue">
            {
              <DialogComponentNew
                command={dialogueCommand}
                gameInterfaceList={gameInterfaceList}
                blobImages={blobImages}
                dialogList={dialogueText[dialogueCount]}
                gameFonts={gameFonts}
                skipTimeout={false}
                dialogueText={dialogueText}
                handlePlayerButtonsClick={handlePlayerButtonsClick}
                setDialogueText={setDialogueText}
                setDialogueCount={setDialogueCount}
                outsideClick={outsideClick}
                setOutsideClick={setOutsideClick}
                localVariableListDTO={localVariableListDTO}
              />
            }
          </div>
        )}
        {showNameDialogue && (
          <ShowNameDialgoue
            setShowNameDialogue={setShowNameDialogue}
            handleStartPlaying={handleStartPlaying}
            setVariables={setDialogueVariables}
          />
        )}
        <div id="media-preview"></div>
        <div id="media-sound-preview"></div>
      </div>
    </React.Fragment>
  );
}

export default BlocksPlayer;

import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { LibraryStyle} from './../story/style/style';

const OkayDeleteWindow = (props) => {
  return (
    <Modal 
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="confirmModal"
        className="modalbg" 
        size="md"
        show={props.okayWindow}
        onHide={() => props.okayWindowClick()}
    >
      <Modal.Body>
        <LibraryStyle.addAssetConfirmBox> 
            <LibraryStyle.confirmAssetContent>
                <Row>
                    <Col className="fail-icon">
                        <img alt="" className="mediumIcon" src="/images/icons/ic_clear_24px.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="delete-asset-label" data-testid="first-label">
                        The chapter has been deleted
                    </Col>
                </Row>
               
                <Row className="mt-3">
                    <Col>
                    <Button
                        onClick={() => props.okayWindowClick()}
                        className="btn-snacktool btn-snacktool-golden btn btn-primary"
                    >
                        Okay
                    </Button>
                    </Col>
                </Row>
            </LibraryStyle.confirmAssetContent></LibraryStyle.addAssetConfirmBox>
        </Modal.Body>
    </Modal>
  );
};
export default OkayDeleteWindow;
import * as ActionTypes from './game-interface-action-types';

export function getGameInterface(storyId, authToken) {
    return {
        type: ActionTypes.GET_GAME_INTERFACE,
        storyId: storyId,
        authToken: authToken,
        loading: true,
    }
}

export function getGameInterfaceSuccess(gameInterfaceList) {
    return {
        type: ActionTypes.GET_GAME_INTERFACE_SUCCESS,
        gameInterfaceList: gameInterfaceList,
        loading: false
    }
} 

export function getGameInterfaceFail(){
    return {
        type: ActionTypes.GET_GAME_INTERFACE_FAIL,
        loading: false
    }
}

export function showModal() {
    return {
        type: ActionTypes.SET_SHOW_MODAL_TRUE
    }
}

export function hideModal() {
    return {
        type: ActionTypes.SET_SHOW_MODAL_FALSE
    }
}

export function saveElement(formData, storyId, id, authToken) {
    return {
        type: ActionTypes.SAVE_ELEMENT,
        formData: formData,
        authToken: authToken,
        storyId: storyId,
        id: id
    }
}

export function saveElementSuccess() {
    return {
        type: ActionTypes.SAVE_ELEMENT_SUCCESS,
    }
}

export function saveElementFail(message) {
    return {
        type: ActionTypes.SAVE_ELEMENT_FAIL,
        message: message
    }
}

export function setActiveTabKey(activeTabKey) {
    return {
        type: ActionTypes.SET_ACTIVE_TAB_KEY,
        activeTabKey: activeTabKey
    }
}

export function resetElement(storyId, id, authToken) {
    return {
        type: ActionTypes.SET_RESET_ELEMENT,
        storyId: storyId,
        id: id,
        authToken: authToken
    }
}

export function resetElementSuccess() {
    return {
        type: ActionTypes.SET_RESET_ELEMENT_SUCCESS
    }
}

export function resetElementFail() {
    return {
        type: ActionTypes.SET_RESET_ELEMENT_FAIL
    }
}

export function hideResetSuccessModal() {
    return {
        type: ActionTypes.HIDE_RESET_SUCCESS_MODAL
    }
}

export function setGameFonts(authToken, storyId, fonts) {
    return {
        type: ActionTypes.SET_GAME_FONTS,
        authToken: authToken,
        storyId: storyId,
        fonts: fonts
    }
}

export function setGameFontsSuccess(payload) {
    return {
        type: ActionTypes.SET_GAME_FONTS_SUCCESS,
        payload: payload
    }
}

export function setGameFontsFail() {
    return {
        type: ActionTypes.SET_GAME_FONTS_FAIL
    }
}

export function getGameFonts(authToken, storyId) {
    return {
        type: ActionTypes.GET_GAME_FONTS,
        authToken: authToken,
        storyId: storyId
    }
}

export function getGameFontsSuccess(payload) {
    return {
        type: ActionTypes.GET_GAME_FONTS_SUCCESS,
        payload: payload
    }
}

export function getGameFontsFail() {
    return {
        type: ActionTypes.GET_GAME_FONTS_FAIL
    }
}
import React from 'react';
import { LibraryStyle } from './../style/style';
import ListHead from './ListHead';
import ListContent from './ListContent';

function ListCharacters({
  initializeCreateCharacter,
  characterList,
  previewStatus,
  handlePreviewClick,
  expressionList,
  outfitList,
  hairsList,
  accesoryList,

  listPreviewSprites,
  handleExpressionChange,
  handleOutfitChange,
  handleHairChange,
  handleAccesoryChange,
  characterIndex,
  tokenData,
  setSortCharacters,
  sortCharacters,
  sortCharacterList,
  listPreviewExpression,
  storyTitle,
  showModal,
  handleShowDeleteModal,
  handleAddCastModal,
  handleHideDeleteModal,
  handleDeleteCharacter,
  characterInfoList,
  deleteConfirm,
  listPreviewOutfit,
  storyRoutes,
  characterId,
  storyDetails,
  handleEditClick,
  handleEditCast
}) {
  // console.log("storyDetailsChecking",storyDetails)
  return (
    <LibraryStyle.listContentPanel>
      <ListHead storyTitle={storyTitle} />
      <ListContent
        initializeCreateCharacter={initializeCreateCharacter}
        characterList={characterList}
        previewStatus={previewStatus}
        handlePreviewClick={handlePreviewClick}
        expressionList={expressionList}
        outfitList={outfitList}
        hairsList={hairsList}
        accesoryList={accesoryList}
        listPreviewSprites={listPreviewSprites}
        handleExpressionChange={handleExpressionChange}
        handleOutfitChange={handleOutfitChange}
        handleHairChange={handleHairChange}
        handleAccesoryChange={handleAccesoryChange}
        tokenData={tokenData}
        setSortCharacters={setSortCharacters}
        sortCharacters={sortCharacters}
        sortCharacterList={sortCharacterList}
        listPreviewExpression={listPreviewExpression}
        showModal={showModal}
        handleShowDeleteModal={handleShowDeleteModal}
        handleAddCastModal={handleAddCastModal}
        handleHideDeleteModal={handleHideDeleteModal}
        handleDeleteCharacter={handleDeleteCharacter}
        characterInfoList={characterInfoList}
        deleteConfirm={deleteConfirm}
        handleEditClick={handleEditClick}
        listPreviewOutfit={listPreviewOutfit}
        storyRoutes={storyRoutes}
        characterId={characterId}
        characterIndex={characterIndex}
        handleEditCast={handleEditCast}
        storyDetailsCheck={storyDetails}
      />
    </LibraryStyle.listContentPanel>
  );
}

export default ListCharacters;

export const EMAIL_PATTERN =
  '^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\\-+)|([A-Za-z0-9]+\\.+)|([A-Za-z0-9]+\\++))*[A-Za-z0-9]+@((\\w+\\-+)|(\\w+\\.))*\\w{1,63}([\\.]{0,})[a-zA-Z]{2,6}$';

export const USER_NAME_PATTERN = '^[a-zA-Z0-9_.-]{1,50}$';

export const TAGS_PATTERN_MAX_5 = '^[0-9a-zA-Z]+(?:, [0-9a-zA-Z]+){0,4}$';

export const ASSET_NAME_PATTERN = '[a-zA-Z0-9_-]{1,50}';

export const CHARACTER_NAME_PATTERN = '[a-zA-Z0-9_-]{1,20}';

export const VARIATION_NAME_PATTERN = '[a-zA-Z0-9_-]{1,20}';

export const VIEW_NAME_PATTERN = '[a-zA-Z0-9_-]{1,50}';

export const BLOCK_NAME_PATTERN = '^[A-Za-z0-9]*$';
export const DIALOGUE_NAME_PATTERN = '[a-zA-Z0-9_ -@./#&+-]{1,20}';
export const SET_VAR_PATTERN = '^[a-zA-Z][A-Za-z0-9_]*$';

export const PASSWORD_PATTERN =
  '^((?=.*[a-z])(?=.*\\d)(?=.*[A-Z])(?=.*[@#$%&!_]).{8,20})$';

export const USER_NAME_PATTERN_MY_ACCOUNT = '^[a-zA-Z0-9_.-]{1,50}$';

export const PASSWORD_BAD = '(?=.{6,}).*';
//Alpha Numeric plus minimum 8
export const PASSWORD_GOOD =
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})';
export const EMAIL_PATTERN_ACCOUNT = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const OTP_PATTERN = '[a-zA-Z0-9]{1,6}';

export const NUMBER_FIELD_PATTERN = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;

export const NUMBER_PATTERN = /^(\d+)?([.]?\d{0,1})?$/;

export const INT_NUMBER_PATTERN = /^[0-9]+([.][0-9]+)?$/;

import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { LibraryStyle } from './style/style';
import * as Translations from './translations';
import * as Config from './../../config/app.config';
import ConfirmAutoSavePopup from '../../components/common/Modals/WarnigToSave';

function LibraryMenu(props) {
  const [viewAutoSaveFlagCheck, setViewAutoSaveFlagCheck] = useState(false);
  const [characterAutoSaveFlagCheck, setCharacterAutoSaveFlagCheck] = useState(
    false
  );
  const [clickedMenu, setClickedMenu] = useState(null);

  let {
    selectedMenu,
    setSelectedMenu,
    viewAutoSaveFlag,
    characterAutoSaveFlag
  } = props;

  const handleMenuSelection = menuName => {
    if (viewAutoSaveFlag === true) {
      setViewAutoSaveFlagCheck(true);
      setClickedMenu(menuName);
    } else if (characterAutoSaveFlag === true) {
      setCharacterAutoSaveFlagCheck(true);
      setClickedMenu(menuName);
    } else {
      props.history.push('/' + menuName);
      setSelectedMenu(menuName);
      if (menuName === 'views') {
        resetViewData();
      }
    }
  };

  const leaveViewAutoSavePopup = () => {
    setViewAutoSaveFlagCheck(false);
    props.resetViewAutoSaveFlag();
    props.resetViews();
    setSelectedMenu(clickedMenu);
    resetViewData();
    props.history.push('/' + clickedMenu);
  };

  const resetViewData = () => {
    if (props.setEditViewFinal) {
      props.setEditViewFinal(false);
    }
    if (props.setSelectBackground) {
      props.setSelectBackground(true);
      props.setAddObject(false);
    }
    if (props.resetViews) {
      props.resetViews();
    }
    if (props.setPlaceBackgroundProperties) {
      props.setPlaceBackgroundProperties('activeKey', 'view');
    }
  };

  const cancelViewAutoSavePopup = () => {
    setViewAutoSaveFlagCheck(false);
  };

  const leaveCharacterAutoSavePopup = () => {
    setCharacterAutoSaveFlagCheck(false);
    props.resetCharacterAutoSaveFlag();
    setSelectedMenu(clickedMenu);
    props.history.push('/' + clickedMenu);
  };

  const cancelCharacterAutoSavePopup = () => {
    setCharacterAutoSaveFlagCheck(false);
  };

  return (
    <LibraryStyle.libraryMenu>
      <LibraryStyle.libraryMenuList>
        <LibraryStyle.libraryMenuListItem
          className="step-character"
          selectedMenu={selectedMenu === 'characters' ? true : false}
        >
          <NavLink
            onClick={() => handleMenuSelection('characters')}
            className={
              selectedMenu === 'characters' ? 'menu-enabled ' : 'menu-disabled'
            }
          >
            {selectedMenu === 'characters' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'user_circle_active.svg'}
              />
            )}
            {selectedMenu !== 'characters' && (
              <img alt="" src={Config.IMAGE_ICON_PATH + 'user_circle.svg'} />
            )}
            &nbsp;&nbsp;{Translations.CHARACTERS}
          </NavLink>
        </LibraryStyle.libraryMenuListItem>
        <LibraryStyle.libraryMenuListItem
          className="step-views"
          selectedMenu={selectedMenu === 'views' ? true : false}
        >
          <NavLink
            onClick={() => handleMenuSelection('views')}
            className={
              selectedMenu === 'views' ? 'menu-enabled ' : 'menu-disabled '
            }
          >
            {selectedMenu === 'views' && (
              <img alt="" src={Config.IMAGE_ICON_PATH + 'views-active.svg'} />
            )}
            {selectedMenu !== 'views' && (
              <img alt="" src={Config.IMAGE_ICON_PATH + 'views.svg'} />
            )}
            &nbsp;&nbsp;{'Views'}
          </NavLink>
        </LibraryStyle.libraryMenuListItem>
        <LibraryStyle.libraryMenuListItem
          className="step-assets"
          selectedMenu={selectedMenu === 'library' ? true : false}
        >
          <NavLink
            exact
            onClick={() => handleMenuSelection('library')}
            className={
              selectedMenu === 'library' ? 'menu-enabled ' : 'menu-disabled '
            }
          >
            {selectedMenu === 'library' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'ic_insert_photo_24px.svg'}
              />
            )}
            {selectedMenu !== 'library' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'deactivateobject.svg'}
              />
            )}
            &nbsp;&nbsp;<span>Background, Object and </span>
            <br /> &nbsp;&nbsp;
            <span style={{ paddingLeft: '7%' }}>Sound files</span>
          </NavLink>
        </LibraryStyle.libraryMenuListItem>

        <LibraryStyle.libraryMenuListItem
          className="step-cover"
          selectedMenu={selectedMenu === 'coverimages' ? true : false}
        >
          <NavLink
            onClick={() => handleMenuSelection('coverimages')}
            className={
              selectedMenu === 'coverimages' ? 'menu-enabled ' : 'menu-disabled'
            }
          >
            {selectedMenu === 'coverimages' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'ic_insert_photo_24px.svg'}
              />
            )}
            {selectedMenu !== 'coverimages' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'deactivateobject.svg'}
              />
            )}
            &nbsp;&nbsp;{Translations.COVER_IMAGES}
          </NavLink>
        </LibraryStyle.libraryMenuListItem>

        <LibraryStyle.libraryMenuListItem
          className="step-interface"
          selectedMenu={selectedMenu === 'interface' ? true : false}
        >
          <NavLink
            onClick={() => handleMenuSelection('interface')}
            className={
              selectedMenu === 'interface' ? 'menu-enabled ' : 'menu-disabled'
            }
          >
            {selectedMenu === 'interface' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'game-interface-active.svg'}
              />
            )}
            {selectedMenu !== 'interface' && (
              <img
                alt=""
                src={Config.IMAGE_ICON_PATH + 'game-interface-inactive.svg'}
              />
            )}
            &nbsp;&nbsp;{Translations.GAME_INTERFACE}
          </NavLink>
        </LibraryStyle.libraryMenuListItem>
      </LibraryStyle.libraryMenuList>
      {viewAutoSaveFlagCheck === true && (
        <ConfirmAutoSavePopup
          firstLineLabel="You have unsaved work in the view. Do you wish to leave anyways?"
          secondLineLabel="&nbsp;"
          onClose={cancelViewAutoSavePopup}
          cancel="Cancel"
          confirm="Yes, leave"
          show={true}
          handleClearCharacter={leaveViewAutoSavePopup}
          viewAutoSaveFlag={props.viewAutoSaveFlag}
          autoSaveFlag={props.autoSaveFlag}
        />
      )}
      {characterAutoSaveFlagCheck === true && (
        <ConfirmAutoSavePopup
          firstLineLabel="You have unsaved work in the character. Do you wish to leave anyways?"
          secondLineLabel="&nbsp;"
          onClose={cancelCharacterAutoSavePopup}
          cancel="Cancel"
          confirm="Yes, leave"
          show={true}
          handleClearCharacter={leaveCharacterAutoSavePopup}
          viewAutoSaveFlag={props.viewAutoSaveFlag}
          autoSaveFlag={props.autoSaveFlag}
        />
      )}
    </LibraryStyle.libraryMenu>
  );
}

const LibraryMenus = withRouter(LibraryMenu);

export default LibraryMenus;

export const UPLOAD_NEW_FILE = 'Upload new file';
export const CONTINUE_TO_ADD_INFO = 'Continue to add info';
export const CONTINUE_TO_UPDATE_INFO = 'Continue to update info';

export const UPLOAD_FILE = 'Upload file';
export const UPDATE_FILE = 'Update file';
export const ADD_INFO = 'Add info';
export const ADD_CHARACTER_ASSET = 'Save this asset';
export const YOUR_FILE_TO_ASSETS = 'Your files to assets or';
export const FILES_TYPE_NOT_ACCEPTED =
  'Cannot upload. File type not supported!';
export const FILE_IS_TOO_LARGE = 'File size cannot be bigger than 15 MB';
export const SUPPORTED_FILE_FORMATS =
  'Supported file formats: PNG, JPG, JPEG, MP3, WAV, OGG';

export const SUPPORTED_FILE_FORMATS_COVER = 'Supported file format: PNG ';
export const SUPPORTED_FILE_FORMATS_AUDIO =
  'Supported file formats: MP3, WAV, OGG';
export const CHAR_SUPPORTED_FILE_FORMATS =
  'Supported file formats: PNG, JPG, JPEG';
export const CHAR_SUPPORTED_FILE_FORMATS_PNG = 'Supported file format: PNG';
export const UPLOAD_SIZE_FILE = 'File size limit: 15 Mb ';
export const DROP_IT = 'Drop it!';
export const BACK_TO_UPLOAD_FILE = 'Back to upload file';
export const REMOVE_FILE = 'Remove file';
export const ASSET_NAME = 'File name';
export const ASSET_CHARACTER_NAME = 'Asset name';
export const TAGS = 'Tags (Optional)';
export const MAXIMUM = 'Seperated by comma (Maximum: 5)';
export const ASSET_TYPE = 'Asset type';
export const CHARACTER_TYPE = 'Character image type';

export const ASSET_IMAGE = 'Image';
export const ASSET_CHAR_TYPE = 'Type';
export const ASSET_DEFAULT = 'default';

export const CLEAR_FILE = 'Clear file';
export const SAVE_INFO = 'Save info';
export const UPDATE_INFO = 'Update info';

export const ASSET_NAME_ERROR_MESSAGE =
  'Please enter a name without blank spaces and special characters.';
export const ASSET_NAME_UNIQUE_MESSAGE = 'Name must be unique';
export const ASSET_NAME_UNIQUE = 'File name must be unique';
export const TAGS_ERROR_MESSAGE = 'Invalid Tags';
export const ASSET_TYPE_ERROR_MESSAGE = 'Please select a type.';
export const SAVING_NEW_ASSET = 'Saving new file';

export const SAVING_NEW_ASSETS = 'Saving new files';

export const UPDATING_ASSET = 'Updating file';

export const SAVING_NEW_CHARACTER = 'Saving new character';
export const UPDATE_NEW_CHARACTER = 'Updating existing character';

export const CANCEL = 'Cancel';
export const DRAG_DROP = 'DRAG & DROP';
export const BROWSE = 'browse';
export const NEW_ASSET_SAVE = 'New file has been saved!';
export const NEW_ASSETS_SAVE = 'New files has been saved!';
export const NEW_ASSET_UPDATE = 'File has been updated!';

export const CHARACTER_PREVIEW_WARNIMNG =
  'Please upload at least one default outfit.';

export const ASSET_IMAGE_TYPE_ERROR_MESSAGE =
  'Cannot upload. The supported file types are .png, .jpg, .jpeg.';
export const ASSET_IMAGE_TYPE_ERROR_MESSAGE_PNG =
  'Cannot upload. The supported file type are .png.';

export const ASSET_AUDIO_TYPE_ERROR_MESSAGE =
  'Cannot upload. The supported file types are .mp3, .wav, .ogg.';

export const ASSETS = 'Background, Object and Sound files';
export const CHARACTERS = 'Characters';
export const CREATE_NEW_CHARACTER = 'Create new character';

export const COVER_IMAGES = 'Cover Images';

export const UPDATE_CHARACTER = 'Update character';

export const BASIC_INFO_TITLE = 'Step 1: Basic info';
export const CONTINUE_TO_SELECT_ASSET = 'Continue to upload assets';
export const CHARACTER_SETTING = 'CHARACTER SETTINGS';
export const CHARACTER_NAME = 'Character name';

export const SHORT_SCRIPT_NAME = 'Short script name';

export const DISPLAY_NAME_NOTE =
  'This name will appear when your character speaks in-game.';
export const CHARACTER_NAME_NOTE =
  'Used in the Script to identify the character in your Story. This name must be unique.';
export const CHARACTER_DISPLAY_NAME = 'Short script name';
export const CHARACTER_DISPLAY_NAME_NOTE =
  'This is an abbreviation for this character so that you don’t have to type out the full name each time in the script. Only you can see this abbreviation of your character’s name. It makes for convenient writing!';

export const INVALID_CHARACTER_NAME =
  'Please enter a short script name without spaces and special characters.';
export const INVALID_CHARACTER_LONG_NAME =
  'Please enter a name without spaces and special characters.';
export const INVALID_CHARACTER_DISPLAY_NAME =
  'Please enter a character display name.';
export const CHARACTER_NAME_UNIQUE = 'Short script name must be unique';
export const CHARACTER_LONG_NAME_UNIQUE = 'Character name must be unique';
export const INVALID_CHARACTER_NAME_MSG =
  'Character names and short script names must be unique across all characters.';
export const INVALID_CHARACTER_NAME_MSG_UNITY =
  'Character names and short script names should not be same as story Id.';
export const DIALOGUE_COLOR = 'Name Color';
export const DIALOGUE_COLOR_NOTE =
  'Customize the color of your character’s name that appears when your character speaks in game.';
export const SELECT_CHARACTER_IMAGE = 'Step 2: Upload character images';
export const BACK_TO_STEP_ONE = 'Back to step 1';
export const CONTINUE_TO_VARIATIONS = 'Continue to variations';
export const CONTINUE_TO_PREVIEW = 'Continue to preview';

export const SEARCH_SPRITES = 'SEARCH SPRITES';
export const SEARCH_SPRITES_NOTE =
  'Find and select all images you want to use to set different variations of your character';
export const SEARCH = 'Search';

export const FORMAT = 'Format';
export const SPECS = 'Specs';
export const UPLOADED = 'Uploaded';
export const ACTIONS = 'Actions';
export const SET_CHARACTER_VARIATIONS = 'Step 3: Preview';
export const BACK_TO_STEP_TWO = 'Back to step 2';
export const FINISH_AND_SAVE_CHARACTER = 'Save character';

export const ARRANGE_IMAGES_TO_CREATE_VAIATIONS =
  'ARRANGE IMAGES TO CREATE VARIATIONS';
export const ARRANGE_IMAGE_NOTE =
  'Order, hide and unhide sprites to create variations of your character.';
export const SAVED_VARIATIONS = 'preview your character';
export const SAVED_VARIATIONS_NOTE =
  'Before saving, see the different versions of the character that you can create when adding this character in the script.';
export const NO_VARIATIONS_CREATED_YET = 'No variations created yet.';
export const VARIATIONS = 'VARIATIONS';
export const EXPRESSIONS = 'EXPRESSIONS';
export const SAVE_VARIATIONS = 'Save this variation';
export const CANCEL_CREATE_CHARACTER_PROCESS_WARNING =
  'Are you sure you want to cancel the process to create character';
export const WARNING = 'Warning!';
export const NAME_AND_SAVE_VARIATION = 'Name and save this variation';
export const VARIATION_NAME = 'Variation name';
export const INVALID_VARIATION_NAME = 'Invalid variation name';
export const EXPRESSION_LABEL = 'Expression (Optional)';
export const EXPRESSION_NOTE =
  'This helps to change expressions of the same variation';
export const SAVE = 'Save';
export const CHARACTER_SAVED = 'Your character has been created';

export const CHARACTER_UPDATED = 'Your character has been updated';

export const CHARACTER_SAVED_LABEL =
  'You can now use this character on the Script Editor, while writing your story';
export const CHARACTER_NOT_SAVED_MESSAGE =
  "Character can't be saved. Something went wrong!";
export const SEARCH_SPRITES_PLACEHOLDER = 'Search sprites by name or tag';
export const VARIATION_NAME_UNIQUE =
  'Expressions within a single variation should be unique.';
export const VARIATION_TITLE = 'Variations';
export const ASSETS_TITLE = 'ASSETS';
export const CREATOR_TITLE = 'CREATOR';
export const EXPRESSION_TITLE = 'Expression';

export const OUTFIT_TITLE = 'Outfit';
export const HAIRS_TITLE = 'Hair';
export const ACCESORY_TITLE = 'Accessory';
export const CREATED_ON = 'Created on';

export const PREVIEW = 'Preview';
export const CREATED_BY = 'Created by';
export const CHARACTER_LIST_PREVIEW_NOTE =
  'Choose an image among the different image categories to preview the character';
export const SELECT_ONE = 'Select one';
export const CHARACTERS_IN_THIS_STORY = 'Characters in this story';
export const GAME_INTERFACE = 'Game Interface';

// UI Element translations
export const UPLOAD_NEW_ELEMENT_IMAGE = 'Upload new element image';
export const SAVE_NEW_IMAGE = 'Save new image';
export const SAVE_NEW_IMAGE_MESSAGE = 'New image has been saved!';
export const RESET_WARNING_MESSAGE =
  'Are you sure you want to reset the element to its default image?';
export const PROCEED = 'Yes, proceed';
export const YOUR_FILES_UPLOAD_UI_ELEMENT = 'your file or ';

export const SUPPORTED_FILE_TYPE_MESSAGE = 'Supported file format: PNG';

export const FILE_SIZE_MESSAGE = 'File size limit: 15 Mb';
export const ASSET_IMAGE_TYPE_ERROR_MESSAGE_UI_ELEMENT =
  'Cannot upload. Invalid file type!';

//Place view
export const NAME_OF_THIS_VIEW = 'Name this view';
export const BLANK_SPACES_NOT_ALLOWED =
  'Blank spaces are not allowed, but you can use underscore or hyphen to separate words';
export const ERROR_DUPLICATE = 'Already exist, please use another name.';
export const ERROR_NAME_VIEW = 'View name is not correct';

export const EXPORT_TO_GALLERY = 'Export to gallery';
export const EXPORT_TO_GALLERY_SUB_TEXT =
  'The background image used in this view will be available in the Fictif app, in the gallery section of this story';
export const FADE_INTO_SCREEN = 'Fade into screen';
export const FADE_INTO_SCREEN_ERROR =
  'Please add valid number to field (limit: 0.1-4.5)';
export const CAPTION_TEXT_ERROR = 'Please enter valid caption text';
export const CAPTION_TEXT = 'Caption';

export const ADD_TO_GALLERY = 'Add to gallery';
export const NAME_TEXT = 'Name text';
export const IS_FOUND_IN_JUICY = 'Is found in Premium Choice';

//Character Data Command
export const GET_COMMAND_CHARACTER = 'GET_COMMAND_CHARACTER';
export const GET_COMMAND_CHARACTER_SUCCESS = 'GET_COMMAND_CHARACTER_SUCCESS';
export const GET_COMMAND_CHARACTER_FAIL = 'GET_COMMAND_CHARACTER_FAIL';

//Dialogues
export const GET_COMMAND_DIALOGUE = 'GET_COMMAND_DIALOGUE';
export const GET_COMMAND_DIALOGUE_SUCCESS = 'GET_COMMAND_DIALOGUE_SUCCESS';
export const GET_COMMAND_DIALOGUE_FAIL = 'GET_COMMAND_DIALOGUE_FAIL';


//Background
export const GET_COMMAND_BACKGROUND = 'GET_COMMAND_BACKGROUND';
export const GET_COMMAND_BACKGROUND_SUCCESS = 'GET_COMMAND_BACKGROUND_SUCCESS';
export const GET_COMMAND_BACKGROUND_FAIL = 'GET_COMMAND_BACKGROUND_FAIL';

//Media
export const GET_COMMAND_PLAY_SOUND = 'GET_COMMAND_PLAY_SOUND';
export const GET_COMMAND_PLAY_SOUND_SUCCESS = 'GET_COMMAND_PLAY_SOUND_SUCCESS';
export const GET_COMMAND_PLAY_SOUND_FAIL = 'GET_COMMAND_PLAY_SOUND_FAIL';
//Play Music
export const GET_COMMAND_PLAY_MUSIC = 'GET_COMMAND_PLAY_MUSIC';
export const GET_COMMAND_PLAY_MUSIC_SUCCESS = 'GET_COMMAND_PLAY_MUSIC_SUCCESS';
export const GET_COMMAND_PLAY_MUSIC_FAIL = 'GET_COMMAND_PLAY_MUSIC_FAIL';

//Effect

export const GET_COMMAND_EFFECT = 'GET_COMMAND_EFFECT';
export const GET_COMMAND_EFFECT_SUCCESS = 'GET_COMMAND_EFFECT_SUCCESS';
export const GET_COMMAND_EFFECT_FAIL = 'GET_COMMAND_EFFECT_FAIL';
import React from 'react';
import * as translations from '../create-story/translations';
import { Form, Button } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import './../style/style.css';

function Tags(props) {
  let genreFirst = props.generes[0] ? props.generes[0].id : '0',
    genreSecond = props.generes[1] ? props.generes[1].id : '0',
    genreThird = props.generes[2] ? props.generes[2].id : '0';
  return (
    <LibraryStyle.addInfoContainer>
      <Form id="add-tag-form">
        <Form.Group className="mb-3 mt-3">
          <Form.Label>{translations.STORY_TYPE}</Form.Label>
          <Form.Control
            as="select"
            disabled={true}
            //   defaultValue="RouteStory"
            name="story_type"
            key="story_type"
            //   onChange={e => handleSelectChange(e)}
            value={props.storyDetails.storyType}
          >
            <option value="RouteStory">Route story</option>
            <option value="ShortStory">Short story</option> ̰
          </Form.Control>
        </Form.Group>
        {props.storyDetails.storyType === 'ShortStory' && (
          <Form.Group className="mb-3">
            <Form.Label>{translations.STORY_COST}</Form.Label>
            <Form.Control
              type="number"
              name="story_cost"
              key="story_cost"
              disabled
              value={props.storyDetails.cost}
              required
              placeholder="Story cost"
            ></Form.Control>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>{translations.STORY_GENRE}</Form.Label>
          <Form.Text className="text-muted">
            {translations.GENRELABEL}
          </Form.Text>

          <Form.Group>
            {props.genreList.map((datum, index) => (
              <Button
                className={
                  genreFirst.toString() === datum.id.toString() ||
                  genreSecond.toString() === datum.id.toString() ||
                  genreThird.toString() === datum.id.toString()
                    ? 'inputTagSelect inputTagCursor'
                    : 'inputTag inputTagCursor'
                }
                value={datum.name}
                variant="outline-primary"
              >
                {genreFirst.toString() === datum.id.toString() ||
                genreSecond.toString() === datum.id.toString() ||
                genreThird.toString() === datum.id.toString() ? (
                  <i
                    className="fa fa-check"
                    value={datum.name}
                    aria-hidden="true"
                  ></i>
                ) : (
                  ''
                )}
                {datum.name}
              </Button>
            ))}
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            {translations.STORY_NAME_ERROR_MESSAGE}
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group>
                    <Form.Label>
                        {translations.STORY_TAGS}
                        <Form.Text className="text-muted">
                        {translations.TAGS_MAXIMUM}
                        </Form.Text>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="tags"
                        defaultValue={props.tags}
                        disabled
                    />
                </Form.Group> */}
      </Form>
    </LibraryStyle.addInfoContainer>
  );
}

export default Tags;

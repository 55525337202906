import React from 'react';
import { PlaceBgStyle } from './style';
import MoveViewTab from './MoveViewTab';

function PlaceBackgroundTabs(props) {

    return (
        <PlaceBgStyle.Tabdiv>
            <MoveViewTab
                handleChange={props.handleChange}
                onHideModal={props.onHideModal}
                background={props.background}
                views={props.views}
                handleMovePlaceViewScript={props.handleMovePlaceViewScript}
                viewId={props.viewId}
                fadeinView={props.fadeinView}
                isEditCommand={props.isEditCommand}
            />
        </PlaceBgStyle.Tabdiv>
    );
}

export default PlaceBackgroundTabs
 


const getCommandColor = (command) => {
  let cmdText='';
   
   let cmdArray=[]
   let cmdNextArray=[]
   //  /^\t\bhide\b/   **  /^\t\breplace\b/   /^\t\bshow\b/
   if(/^\bhideobj\b/.test(command) || /^\bshowobj\b/.test(command) || /^\benter\b/.test(command) || /^\breplace\b/.test(command) || /^\bshow\b/.test(command) || /^\bhide\b/.test(command) ){
    cmdArray=command.split(' ');
    cmdNextArray=cmdArray.splice(0, 1);
    cmdText=`<span class='commandEnter'>${cmdNextArray[0]}</span>&nbsp;<span class='commandNext'>${cmdArray.join(' ')}</span>`
   
     
   }
   else if(/^\t\bmoveto\b/.test(command) || /^\bview\b/.test(command)){
   
    cmdArray=command.split(' ');
    cmdNextArray=cmdArray.splice(0, 1);
    cmdText=`<span class='commandView'>${cmdNextArray[0]}</span>&nbsp;<span class='commandNext'>${cmdArray.join(' ')}</span>`
   

   }
   else if(/^\bshake\b/.test(command)){
      
    cmdArray=command.split(' ');
    cmdNextArray=cmdArray.splice(0, 1);
    cmdText=`<span class='commandShake'>${cmdNextArray[0]}</span>&nbsp;<span class='commandNext'>${cmdArray.join(' ')}</span>`
   }
   else if(/^\bvolume\b/.test(command) || /^\bsfx\b/.test(command) ||  /^\bmusic\b/.test(command) || /^\bstopmusic\b/.test(command)){
    
   cmdArray=command.split(' ');
   cmdNextArray=cmdArray.splice(0, 1);
   cmdText=`<span class='commandMusic'>${cmdNextArray[0]}</span>&nbsp;<span class='commandNext'>${cmdArray.join(' ')}</span>`
  }
  else if(/^\bsetvar\b/.test(command) ||   /^\bwait\b/.test(command) || /^\bshownamedialogue\b/.test(command) || /^\bgoto\b/.test(command) || /^\bchoice\b/.test(command) ||  /^\bchoicetimer\b/.test(command) ||  /^\btap\b/.test(command) ||  /^\endjuicyblock\b/.test(command) ){
    
   cmdArray=command.split(' ');
   cmdNextArray=cmdArray.splice(0, 1);
   cmdText=`<span class='commandShowName'>${cmdNextArray[0]}</span>&nbsp;<span class='commandNext'>${cmdArray.join(' ')}</span>`
  }
  
   else{
  
    cmdText=command;
   }
   return cmdText;
}
 
 
const getResolvedComment = (commentList,commentNo,status) => {
   let commentObj=null;
  for(let i=0;i<commentList.length;i++)
  {
    if(i===commentNo){
      commentList[i].status=status;
      commentObj=commentList[i];
    }
   
  }
   
  return commentObj;
}
 

const getReplyComment = (commentList,commentNo,reply) => {
  let commentObj=null;
 for(let i=0;i<commentList.length;i++)
 {
   if(i===commentNo){
     commentList[i].reply=reply;
     commentObj=commentList[i];
   }
  
 }
  
 return commentObj;
}


const getEditComment = (commentList,commentNo,comment) => {
  let commentObj=null;
 for(let i=0;i<commentList.length;i++)
 {
   if(i===commentNo){
     commentList[i].commentText=comment;
     commentObj=commentList[i];
   }
  
 }
  
 return commentObj;
}
 
const getRepeatTutorialState = (tutorial) => {
  let showTutorial=false;
 


  for (let [key, value] of Object.entries(tutorial)) {
   
    if(key !=='firstLogin'){
    if(value===true){
      showTutorial=true;
      break;
     }
    }
  }

 

 return showTutorial;
}

 
 
export const CommentServices = {
  getCommandColor,
  getResolvedComment,
  getReplyComment,
  getEditComment,
  getRepeatTutorialState
}
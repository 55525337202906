import React, { useEffect, useState } from 'react';
import { LibraryStyle } from './../../style/style';
import CreateCharacterHead from './CreateCharacterHead';
import CreateCharacterContent from './CreateCharacterContent';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import * as pattern from './../../../../common/helper/pattern';
import * as Translations from './../../translations';

function BasicInfo(props) {
  const {
    continueToSelectAsset,
    dialogueColor,
    handleColorChange,
    characterName,
    characterDisplayName,
    handleCharacterName,
    handleCharacterDisplayName,
    initializeCreateCharacter,
    setContinueToSelectAssetDisabled,
    setContinueToSelectAssetEnabled,
    IsCharacterEdit,
    characterList,
    characterData,
    handleCharacterLongName,
    characterLongName,
    unityStoryId
  } = props;

  const [validated, setValidated] = useState(false);
  const [characterNameValidity, setCharacterNameValidity] = useState(null);
  const [characterLongNameValidity, setCharacterLongNameValidity] = useState(
    null
  );
  const [characterNameValidityUnity, setCharacterNameValidityUnity] = useState(
    null
  );
  const [
    characterLongNameValidityUnity,
    setCharacterLongNameValidityUnity
  ] = useState(null);

  const [character, setCharacter] = useState({
    name: '',
    longName: '',
    displayName: ''
  });

  useEffect(() => {
    setCharacter({
      name: characterName,
      longName: characterLongName,
      displayName: characterDisplayName
    });
  }, [characterName, characterDisplayName, characterLongName]);

  useEffect(() => {
    if (characterNameValidity !== null && characterLongNameValidity !== null)
      changeContiueToSelectAssetButtonState();
  }, [characterNameValidity, characterLongNameValidity]);

  useEffect(() => {
    changeContiueToSelectAssetButtonState();
  }, [character.displayName, character.longName, character.name]);

  useEffect(() => {
    if (IsCharacterEdit === true) {
      setCharacterNameValidity(true);
      setCharacterLongNameValidity(true);
    }
  }, []);

  const checkCharacterValidity = (name, value) => {
    if (name === '') {
      return false;
    }
    switch (name) {
      case 'displayName':
        if (
          value === '' ||
          character.name === '' ||
          character.longName === ''
        ) {
          setContinueToSelectAssetDisabled();
        } else {
          setContinueToSelectAssetEnabled();
        }
        break;

      case 'longName':
        if (
          value === '' ||
          character.name === '' ||
          character.displayName === ''
        ) {
          setContinueToSelectAssetDisabled();
          // if(character.name !== ''){
          //   setCharacterNameValidity(false);
          // }
        } else {
          setContinueToSelectAssetEnabled();
          // setCharacterNameValidity(true);
        }
        break;

      case 'name':
        if (
          value === '' ||
          character.displayName === '' ||
          character.longName === ''
        ) {
          setContinueToSelectAssetDisabled();
          // if(character.longName !== ''){
          //   setCharacterLongNameValidity(false);
          // }
        } else {
          setContinueToSelectAssetEnabled();
          // setCharacterLongNameValidity(true);
        }
        break;

      default:
        break;
    }

    return true;
  };

  const checkNameDuplicate = (existingName, name) => {
    let isValid = true;
    if (existingName.toLowerCase() === name.toLowerCase()) {
      setContinueToSelectAssetDisabled();
      isValid = false;
    }

    return isValid;
  };

  const checkCharacterDuplicates = (name, field) => {
    if (field === 'name') {
      setCharacterNameValidityUnity(true);
      setCharacterNameValidity(true);
      handleCharacterName(name);
    }
    if (field === 'longName') {
      setCharacterLongNameValidityUnity(true);
      setCharacterLongNameValidity(true);
      handleCharacterLongName(name);
    }
    if (field === 'displayName') {
      handleCharacterDisplayName(name);
    }

    if (characterList) {
      for (let i = 0; i < characterList.length; i++) {
        if (IsCharacterEdit) {
          if (characterData.id !== characterList[i].id) {
            if (field === 'name') {
              let isNameValid = checkNameDuplicate(
                characterList[i].characterName,
                name
              );
              let isLongNameValid = checkNameDuplicate(
                characterList[i].characterLongName,
                name
              );

              if (!isNameValid || !isLongNameValid) {
                setCharacterNameValidity(false);
                break;
              }
              let isUnityNameValid = checkNameDuplicate(unityStoryId, name);
              if (!isUnityNameValid) {
                setCharacterNameValidityUnity(false);
                break;
              }
            }
            if (field === 'longName') {
              let isNameValid = checkNameDuplicate(
                characterList[i].characterName,
                name
              );
              let isLongNameValid = checkNameDuplicate(
                characterList[i].characterLongName,
                name
              );

              if (!isNameValid || !isLongNameValid) {
                setCharacterLongNameValidity(false);
                break;
              }

              let isUnityNameValid = checkNameDuplicate(unityStoryId, name);
              if (!isUnityNameValid) {
                setCharacterLongNameValidityUnity(false);
                break;
              }
            }
          }
        } else {
          if (field === 'name') {
            let isNameValid = checkNameDuplicate(
              characterList[i].characterName,
              name
            );
            let isLongNameValid = checkNameDuplicate(
              characterList[i].characterLongName,
              name
            );

            if (!isNameValid || !isLongNameValid) {
              setCharacterNameValidity(false);
              break;
            }
            let isUnityNameValid = checkNameDuplicate(unityStoryId, name);
            if (!isUnityNameValid) {
              setCharacterNameValidityUnity(false);
              break;
            }
          }
          if (field === 'longName') {
            let isNameValid = checkNameDuplicate(
              characterList[i].characterName,
              name
            );
            let isLongNameValid = checkNameDuplicate(
              characterList[i].characterLongName,
              name
            );

            if (!isNameValid || !isLongNameValid) {
              setCharacterLongNameValidity(false);
              break;
            }

            let isUnityNameValid = checkNameDuplicate(unityStoryId, name);
            if (!isUnityNameValid) {
              setCharacterLongNameValidityUnity(false);
              break;
            }
          }
        }
      }
    }
  };

  const changeContiueToSelectAssetButtonState = () => {
    if (
      characterNameValidity === true &&
      characterLongNameValidity === true &&
      characterNameValidityUnity === true &&
      characterLongNameValidityUnity === true &&
      character.displayName !== '' &&
      character.longName !== '' &&
      character.name !== ''
    ) {
      setContinueToSelectAssetEnabled();
    }

    if (
      characterNameValidity === false ||
      characterLongNameValidity === false ||
      characterNameValidityUnity === false ||
      characterLongNameValidityUnity === false ||
      character.displayName === '' ||
      character.name === '' ||
      character.longName === '' //||
      // characterNameValidity === null ||
      // characterLongNameValidity === null
    ) {
      setContinueToSelectAssetDisabled();
    }
  };

  const handleChange = event => {
    setCharacter({
      ...character,
      [event.target.name]: event.target.value
    });

    if (event.currentTarget.form.checkValidity() == false) {
      setContinueToSelectAssetDisabled();
    } else {
      setContinueToSelectAssetEnabled();
    }

    let isCharacterValid = checkCharacterValidity(
      event.target.name,
      event.target.value
    );

    if (isCharacterValid === false) {
      setContinueToSelectAssetDisabled();
    } else {
      setContinueToSelectAssetEnabled();
    }

    checkCharacterDuplicates(event.target.value, event.target.name);

    changeContiueToSelectAssetButtonState();

    setValidated(true);
  };
  return (
    <LibraryStyle.listContentPanel>
      <LibraryStyle.characterContent>
        <CreateCharacterHead
          IsCharacterEdit={IsCharacterEdit}
          initializeCreateCharacter={initializeCreateCharacter}
        />
        <CreateCharacterContent>
          <Container>
            <Form noValidate validated={validated}>
              <Form.Group>
                <Row className="content-head">
                  <Col md={4} className="text-left left-title">
                    {Translations.BASIC_INFO_TITLE}
                  </Col>
                  <Col md={{ span: 4, offset: 4 }} className="text-right">
                    <Button
                      className={`btn-snacktool btn btn-primary ${
                        continueToSelectAsset == 'disabled'
                          ? `disabled`
                          : `btn-snacktool-golden`
                      }`}
                      onClick={props.handleContinueToSelectAssetClick}
                    >
                      {Translations.CONTINUE_TO_SELECT_ASSET}
                    </Button>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row className="col-title">
                    <Col>SCRIPT NAMES</Col>
                    <Col>&nbsp;DISPLAY</Col>
                  </Row>
                  <Row>
                    <Col className="pl-0">
                      <div className="pr-3">
                        <Row>
                          <Col className="mt-4">
                            <div className="bold-text col-title">
                              {Translations.CHARACTER_NAME}
                            </div>
                            <div className="info-label">
                              {Translations.CHARACTER_NAME_NOTE}
                            </div>
                            <div>
                              <Form.Control
                                type="text"
                                name="longName"
                                required
                                placeholder="e.g., Alexandra"
                                pattern={pattern.CHARACTER_NAME_PATTERN}
                                autoComplete="off"
                                onChange={handleChange}
                                value={character.longName}
                                maxlength="20"
                                minlength="1"
                                className={
                                  characterLongNameValidity === false
                                    ? 'input-error'
                                    : ''
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {Translations.INVALID_CHARACTER_LONG_NAME}
                              </Form.Control.Feedback>
                              {characterLongNameValidity === false && (
                                <Form.Text className="text-danger">
                                  <h6>
                                    {Translations.INVALID_CHARACTER_NAME_MSG}
                                  </h6>
                                </Form.Text>
                              )}
                              {characterLongNameValidityUnity === false && (
                                <Form.Text className="text-danger">
                                  <h6>
                                    {
                                      Translations.INVALID_CHARACTER_NAME_MSG_UNITY
                                    }
                                  </h6>
                                </Form.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-4">
                            <div className="bold-text col-title">
                              {Translations.CHARACTER_DISPLAY_NAME}
                            </div>
                            <div className="info-label">
                              {Translations.CHARACTER_DISPLAY_NAME_NOTE}
                            </div>
                            <div>
                              <Form.Control
                                type="text"
                                name="name"
                                required
                                pattern={pattern.CHARACTER_NAME_PATTERN}
                                //onBlur={handleNameBlur}
                                placeholder="e.g, A"
                                autoComplete="off"
                                minlength="1"
                                maxlength="12"
                                onChange={handleChange}
                                value={character.name}
                                className={
                                  characterNameValidity === false
                                    ? 'input-error'
                                    : ''
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {Translations.INVALID_CHARACTER_NAME}
                              </Form.Control.Feedback>
                              {characterNameValidity === false && (
                                <Form.Text className="text-danger">
                                  <h6>
                                    {Translations.INVALID_CHARACTER_NAME_MSG}
                                  </h6>
                                </Form.Text>
                              )}
                              {characterNameValidityUnity === false && (
                                <Form.Text className="text-danger">
                                  <h6>
                                    {
                                      Translations.INVALID_CHARACTER_NAME_MSG_UNITY
                                    }
                                  </h6>
                                </Form.Text>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="mt-4">
                          <div className="bold-text col-title">
                            {Translations.NAME_TEXT}
                          </div>
                          <div className="info-label">
                            {Translations.DISPLAY_NAME_NOTE}
                          </div>
                          <div>
                            <Form.Control
                              type="text"
                              name="displayName"
                              required
                              placeholder="e.g., Alexandra"
                              autoComplete="off"
                              onChange={handleChange}
                              value={character.displayName}
                              minlength="1"
                              maxlength="30"
                            />
                            <Form.Control.Feedback type="invalid">
                              {Translations.INVALID_CHARACTER_DISPLAY_NAME}
                            </Form.Control.Feedback>
                          </div>
                        </Col>
                      </Row>
                      <Row className="col-title">
                        <Col className="mt-4">
                          {Translations.DIALOGUE_COLOR}
                        </Col>
                      </Row>
                      <Row className="info-label">
                        <Col>{Translations.DIALOGUE_COLOR_NOTE}</Col>
                      </Row>
                      <Row>
                        <Col className="mb-4">
                          <br />
                          <ChromePicker
                            color={dialogueColor}
                            defaultValue={dialogueColor}
                            onChangeComplete={handleColorChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Form.Group>
            </Form>
          </Container>
        </CreateCharacterContent>
      </LibraryStyle.characterContent>
    </LibraryStyle.listContentPanel>
  );
}

export default BasicInfo;

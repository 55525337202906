import React from 'react';
import * as commandPanelStyle from '../../command-panel/command-panel-style';
import { Row, Col, Container } from 'react-bootstrap';

function ListHead(props) {
  return (
    <div>
      <commandPanelStyle.Header>
        <Row>
          <Col md={6}>
            <div className="panel-header">
              <div className="story-heading">
                <div className="storyName">
                  {props.storyTitle ? props.storyTitle : ''}
                </div>
                <div className="sceneName">
                  Cover Images
                </div>
              </div>
            </div>
          </Col>
          {/*  */}
        </Row>
      </commandPanelStyle.Header>
    </div>
  );
}

export default ListHead;

import React from 'react';
import { LibraryStyle} from './../../library/style/style';
import { Row,Button,Modal, Col} from 'react-bootstrap';

function  CommentsFail(props) {
    return (
      <Modal 
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
     className="modalbg" 
     size="md" 
     show={props.show}
     onHide={props.onHideCommentStatusModal}
     >
       <Modal.Body  >
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <Row>
                    <Col className="fail-icon">
                    <img src="/images/icons/ic_clear_24px.svg" className="mediumIcon"/>

                    </Col>
                </Row>
                <Row>
                    <Col className="submit-asset-label">
                    Comment can't be saved. Something went wrong!"   
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row><Col>
                <Button data-testid="success-btn"  onClick={props.onHideCommentStatusModal}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
                </Col></Row>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
        </Modal.Body>
    </Modal>
    );
}

export default CommentsFail
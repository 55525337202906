import styled from 'styled-components';
import { colors } from '../../common/styles/variables';


export const Header = styled.div`
background: ${colors.color_white};
border-radius: 6px 6px 0px 0px;
opacity: 1;
border-bottom : 1px solid ${colors["command-header"]}
 padding : 0 0px 0 24px;
 margin-bottom:20px;

 .btn-filter-disabled{
  background-color: #BFBFBF !important;
    border: #BFBFBF !important;
    border-radius: 20px;
    margin: 5px;
    font-size: 14px;
    border: 0;
    color: white !important;
    
    padding: 8px 18px;
 }
 .filter-inputTagSelect{
  margin: 5px 8px 5px 0; 
 
 }
 .panel-header-right{
    margin-left: 5% !important;
  }
.panel-header{
  display: flex;
    align-items: center;
    height: 60px;
    position:relative;
  .back-arrow{
    border-right: 1px solid #E3E3E3;
    display: flex;
    height: 100%;
    align-items: center;
    height: 60px;
    padding: 0 24px;
    margin-right:16px;
    .fa-angle-left{
      font-size:20px;
    }
  }
  
  .story-heading{
    width:100%;
  }
    .storyName {
      text-align: left;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 1.1px;
      color: ${colors.scorpion_gray};
      text-transform: uppercase;
      opacity: 1;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }  

   .storyName-right {    
     padding-right:0px !important;
     padding-left: 0px !important;
   }       
   .storyName-right-margin {    
     margin-top:12px;
     
   }    
  .sceneName {
      text-align: left;
      font-size: 22px;
      line-height:27px;
      letter-spacing: 0;
      color: ${colors.color_black};
      opacity: 1;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .preview-btn-wrapper{
    position:absolute;
    right:25px;
    bottom:-17px;
    .preview-btn{
      background: rgba(43,139,250,1);
      background: -moz-linear-gradient(left, rgba(43,139,250,1) 0%, rgba(85,0,201,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(43,139,250,1)), color-stop(100%, rgba(85,0,201,1)));
      background: -webkit-linear-gradient(left, rgba(43,139,250,1) 0%, rgba(85,0,201,1) 100%);
      background: -o-linear-gradient(left, rgba(43,139,250,1) 0%, rgba(85,0,201,1) 100%);
      background: -ms-linear-gradient(left, rgba(43,139,250,1) 0%, rgba(85,0,201,1) 100%);
      background: linear-gradient(to right, rgba(43,139,250,1) 0%, rgba(85,0,201,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b8bfa', endColorstr='#5500c9', GradientType=1 );
      color:#fff;
      letter-spacing: 1.1px;
      border:none !important;
      
    }
    .preview-btn:focus{
      box-shadow: 0px 3px 4px #00000033 !important;
      bordre:none !important;
    }
  }
  
}


`
export const Body = styled.div`
  background: #fff;
  opacity: 1;
  display:inline-block;
  height:calc(100vh - 160px);
  width:100%;
  overflow:auto;
  padding: 0 0 150px 0 !important;

.square {
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
}
.blockSquare{
    color: ${colors.shades_shade300}
}

.commandSquare{
    color: ${colors.dark_yellow};
}
.decisionSquare {
  color: ${colors.primary_purple};
  
}
.backgroundSquare {
  color: ${colors.very_dark_cyan};
}
.mediaSquare {
  color: ${colors.dark_lime_green};
}
.characterSquare {
  color: ${colors.very_dark_pink}
}
.effectSquare{
  color: ${colors.light_blue_effect}
}
.ace_commentSquare{
  color: ${colors.medium_light_gray}
}
.bodyText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
}
.bodyTextBlock{
  margin-top:30px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
}
.bodyBlockContent{

}
.blockBorder{
  border-bottom: 2px solid ${colors.primary_purple};
}
.commandBorder{
  border-bottom: 2px solid ${colors.primary_yellow};
}
.decisionBorder {
  border-bottom: 2px solid ${colors.primary_purple};
}
.backgroundBorder {
  border-bottom: 2px solid ${colors.very_soft_cyan};
}
.mediaBorder {
  border-bottom: 2px solid ${colors.moderate_lime_green};
}
.characterBorder {
  border-bottom: 2px solid ${colors.very_soft_pink};
}
.bodyBlockContent {
 .hideEdit{
  visibility: hidden;

 }
 .showEdit{
   visibility: visible;
 }
  .hide{
   display:none;
  }
}
.rowHighLight{
.bodyBlockContent {
  span{
    background-color:pink;
  }
  .hide{
   display:none;
  }
}
}
`;
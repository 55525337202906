import React, { useEffect, useState } from 'react';
import * as FlowchartStyle from './style-flowchart-view'
import Graph from "react-graph-vis";
import { optionsFlowchart } from '../../common/constants'
import { max, merge } from 'lodash';

const FlowchartViewComponent = (props) => {
  const [currentNetwork, setCurrentNetwork] = useState()
  const [flowchartJson, setFlowChartJson] = useState({})
  useEffect(() => {
    setFlowChartJson(props.flowchartJson)
  }, [props.flowchartJson])

  const createData = (nodes, edges) => merge({}, {
    nodes,
    edges
  })

  const currentGraph = createData(props.flowchartNode, props.flowchartConnector);

  const customFlowchartOptions = {}

  const mergeFlowchartOptions = merge({}, optionsFlowchart, customFlowchartOptions)

  // toggle on/off specific options to be passed to the network,
  // to enable/disable automatica hierarchical arrangement
  const toggleAutoArrange = enabled => {
    currentNetwork && currentNetwork.setOptions(merge({}, mergeFlowchartOptions, {
      nodes: {
        physics: enabled
      },
      layout: {
        hierarchical: {
          enabled: enabled
        }
      },
      physics: {
        enabled: enabled
      }
    }))
  }

  // const storePositions = positions => {
  //   props.storeFlowchartJson(JSON.stringify(positions))
  // }

  // remove local storage item storing flowchart positions
  // const clearPositions = () => {
  //   if (typeof window !== undefined) {
  //     window.localStorage.removeItem(`flowchart_${props.storyId}_${props.chapterId}`)
  //   }
  // }

  let graph = {
    nodes: [
      {
        id: 'NoData',
        label: '',
        color: 'transparent',
        size: 1520,
      }
    ],
    edges: []
  };

  if (props.flowchartNode.length > 0) {
    graph = {
      nodes: props.flowchartNode,
      edges: props.flowchartConnector
    };
  }

  if (flowchartJson !== null && Object.keys(flowchartJson).length !== 0) {
    currentGraph.nodes.forEach(node => {
      node.x = flowchartJson[node.id] && flowchartJson[node.id].x
      node.y = flowchartJson[node.id] && flowchartJson[node.id].y
    })
    toggleAutoArrange(false)
  } else {

  }

  // force network to fit at first render
  // currentNetwork && currentNetwork.fit()

  // then, de-activate physics to enable freedom of movement...
  if (flowchartJson !== null && Object.keys(flowchartJson).length !== 0) {
    setTimeout(() => {
      toggleAutoArrange(false)
    }, 100)
  } else {
    setTimeout(() => {
      toggleAutoArrange(true)
    }, 100)
  }
  const events = {
    dragging: () => {
      // fit while dragging; this way, the user could break loose, playing and
      // messing around with the diagram
      // currentNetwork.fit()
    },
    dragEnd: () => {
      // store positions every time a node stops being dragged
      // setFlowchartJson(currentNetwork.getPositions())
    },
    select: function (event) {
      var { nodes, edges } = event;
      props.handleNodeClickforFlowchartNode(nodes[0])
    },
  };

  let options = optionsFlowchart
  options.height = props.levelFlowchart > 0 && props.levelFlowchart < 5 ? `${parseInt(props.levelFlowchart) * 155}px` : '640px'

  return (
    <FlowchartStyle.GraphCss>
      {
        props.flowchartNode && props.flowchartNode.length > 0
          ? <Graph
            graph={currentGraph}
            options={mergeFlowchartOptions}
            events={events}
            getNetwork={network => {
              setCurrentNetwork(network);
            }}
          />
          : <div>Error</div>
      }
    </FlowchartStyle.GraphCss>
  )
}

export default FlowchartViewComponent;
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LibraryStyle } from '../../style/style';

function LockedAssets(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleHideAssetLockModal}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="header-text"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">
              This {props.type} cannot be edited now
            </label>
            <label className="sub-heading">
              The users are currently editing a chapter that uses this{' '}
              {props.type}.
            </label>
          </div>

          <div className="Modal-extra-content">
            <LibraryStyle.tableContainer>
              <thead>
                <tr>
                  <th className="text-left w-50 text-break d-inline-block p-3">
                    CHAPTER
                  </th>

                  <th className="text-left w-50 text-break d-inline-block p-3">
                    USER
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.lockedAssetData && props.lockedAssetData.length > 0 &&
                  props.lockedAssetData.map((asset, index) => (
                    <tr key={index}>
                      <td className="text-small-light w-50 text-left text-break d-inline-block p-3">
                        {asset.name}
                      </td>

                      <td className="text-small-light w-50 text-left w-50 d-inline-block p-3">
                        {asset.lockAcquiredByUsername}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </LibraryStyle.tableContainer>
          </div>
        </div>
        <div className="modal-button-wrapper justify-content-center">
          <Button
            onClick={props.handleHideAssetLockModal}
            className="btn-snacktool btn-snacktool-golden"
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default LockedAssets;

import React, { useState } from 'react';
import * as translations from './translations';
import { Button, Row, Col } from 'react-bootstrap';
import './../style/style.css';
import * as Constant from './constant';

function AddStoryModalBottom(props) {
  return (
    <div className="mt-4">
      <Row>
        <Col
          md={6}
          className="footer-left-button-story d-flex align-items-center p-0"
        >
          {props.activeTabKey === Constant.SECOND_TAB_KEY && (
            <>
              <img
                alt=""
                src="/images/icons/ic_chevron_right.svg"
                className="mr-2 checkIcon"
              />
              <span
                onClick={() => props.handleActiveKey(Constant.FIRST_TAB_KEY)}
              >
                {translations.BACK_TO_STEP_ONE}
              </span>
            </>
          )}
          {props.activeTabKey === Constant.THIRD_TAB_KEY && (
            <>
              <img
                alt=""
                src="/images/icons/ic_chevron_right.svg"
                className="mr-2 checkIcon"
              />
              <span
                onClick={() => props.handleActiveKey(Constant.SECOND_TAB_KEY)}
              >
                {translations.BACK_TO_STEP_TWO}
              </span>
            </>
          )}
        </Col>
        {/* disabled = {props.enableContinueToStepTwo ? '' : 'disabled'} */}
        <Col md={6} className="text-right footer-right-button p-0">
          {props.activeTabKey === Constant.SECOND_TAB_KEY && (
            <Button
              className={`btn-snacktool btn-snacktool-golden  `}
              disabled={

                props.generes.length == 0 ||
                ((props.storyType === 'ShortStory' &&
                  props.storyCost > parseInt(2147483647)))

              }
              onClick={() => props.handleActiveKey(Constant.THIRD_TAB_KEY)}
            >
              {translations.CONTINUE_TO_STEP_THREE}
            </Button>
          //   props.generes.length == 0 ||
          //       ((props.storyType === 'ShortStory' &&
          //         props.storyCost > parseInt(2147483647)))?
          //         <img
          //           src="../../images/new_image/Continue_to_Step_3_1.png"
          //           // onClick={() => props.handleActiveKey(Constant.THIRD_TAB_KEY)}
          //         ></img>
          //         :  <img
          //         src="../../images/new_image/Continue_to_Step_3_2.png"
          //         onClick={() => props.handleActiveKey(Constant.THIRD_TAB_KEY)}
          //       ></img>
          )
          }
          {props.activeTabKey === Constant.FIRST_TAB_KEY && (
            <Button
              type="submit"
              onClick={() => props.handleActiveKey(Constant.SECOND_TAB_KEY)}
              disabled={props.enable}
              className="btn-snacktool btn-snacktool-golden "
              form="add-info-form"
            >
              {translations.CONTINUE_TO_ADD_INFO}
            </Button>
          )}
          {props.activeTabKey === Constant.THIRD_TAB_KEY && (
            <Button
              type="submit"
              disabled={
                props.generes.length === 0 || props.finishPressed === true
              }
              className="btn-snacktool btn-snacktool-golden"
              form="add-tag-form"
            >
              {translations.SAVE_INFO}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default AddStoryModalBottom;

import React, { useState, useRef, useEffect } from 'react';
import { Button, ListGroup, Card, Modal, Spinner } from 'react-bootstrap';

const GrammarNoSuggestion = ({
  transform,
  display,
  arraySuggest,
  color,
  textSpell,
  onClickReplace
}) => {
  return arraySuggest && arraySuggest[0] ? (
    <Modal.Dialog
      style={{
        position: 'absolute',
        transform: `${transform}`
        // display: `${display}`
      }}
      className="mt-2"
    >
      <Modal.Header style={{ padding: '0px' }}></Modal.Header>
      <Modal.Body
        style={{ overflow: 'scroll', maxHeight: '200px', padding: '0px' }}
      >
        <Card>
          <Card.Body style={{ padding: '0.5rem', maxWidth: '210px' }}>
            <Card.Title style={{ fontSize: '12px', fontWeight: 'bold' }}>
              <i className="fa fa-circle" style={{ color: color }} />{' '}
              {textSpell}
            </Card.Title>
            <Card.Text
              style={{
                fontSize: '12px',
                paddingBottom: '5px',
                borderBottom: '1px solid #ACACAC',
                marginBottom: '5px'
              }}
            >
              {arraySuggest[0].message}
            </Card.Text>
            <Card.Text
              style={{
                fontSize: '12px',
                paddingBottom: '5px',
                borderBottom: '1px solid #ACACAC',
                marginBottom: '5px'
              }}
            >
              No suggestions found.
            </Card.Text>
            <Card.Text
             
              className="ignoreGrammar"
              onClick={() => onClickReplace(arraySuggest[0].word, arraySuggest[0], true)}
            >
              <i className="fa fa-list" /> Ignore in this text
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal.Dialog>
  ) : (
    ''
  );
};

export default GrammarNoSuggestion;

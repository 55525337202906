import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LibraryStyle } from '../../style/style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Constants from './../../../../common/constants';

function DeleteAssetModal(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleHideDeleteModal}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="header-text"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">
              This file cannot be deleted as it is currently being used
            </label>
            <label className="sub-heading">
              Please remove all references to this file in order to delete it
            </label>
          </div>

          <div className="Modal-extra-content">
            <LibraryStyle.tableContainer>
              <thead>
                <tr>
                  {
                    props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[1] && props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[0] &&
                    < th className="text-left w-50 text-break d-inline-block p-3">
                      Chapter
                    </th>
                  }
                  <th className="text-left w-50 text-break d-inline-block p-3">
                    {
                      props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[1] && props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[0] ? 'Block' : 'View'
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.assetInfoList !== undefined &&
                  props.assetInfoList.map((asset, index) => (
                    <tr key={`tooltip1-${asset.id + index}`}>

                      {
                        props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[1] && props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[0] &&
                        <td className="text-small-light text-left w-50 text-break d-inline-block p-3">
                          {asset.sceneName}
                        </td>
                      }
                      <OverlayTrigger
                        key={`tooltip-${asset.id}`}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${asset.id}`}>
                            {asset.blockNames.toString()}
                          </Tooltip>
                        }
                      >
                        <td className="text-small-light text-left w-50 d-inline-block p-3">
                          {asset.blockNames.toString()}
                        </td>
                      </OverlayTrigger>

                    </tr>
                  ))}
              </tbody>
            </LibraryStyle.tableContainer>
          </div>
        </div>
        <div className="modal-button-wrapper justify-content-center">
          <Button
            onClick={props.handleHideDeleteModal}
            className="btn-snacktool btn-snacktool-golden"
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal >
  );
}
export default DeleteAssetModal;

import React from 'react';
import { LibraryStyle } from './../../style/style';

function CreateCharacterContent(props) {
    return (
        <LibraryStyle.listMainSection>
            {props.children}
        </LibraryStyle.listMainSection>
    );
}

export default CreateCharacterContent
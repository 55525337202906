import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import * as Constant from './../../../common/constants';
import * as Pattern from './../../../common/helper/pattern';

function TextBox({
    component,
    isValidText,
    handleTextFieldChange,
    commandId,
    durationLeaveScene,
    allBlockList,
    currentSelectedVariationName,
    variableFields,
    variableListDTO,
    addGenderedText,
    isCommandEdit
}) {

    let defaultDisabled = false;
    let required = component.required ? `required` : '';
    let classNameDisabled = '';
    let textBoxType = false;
    let stepforNumber = false;
    let defaultValue = component.properties.defaultValue;

    const [variableValidation, setVariableValidation] = useState('');

    const handleValueForTextBox = (event, widgetNameCheck, commandId) => {
        setVariableValidation('')
        if (commandId === Constant.COMMAND_SET_VAR && widgetNameCheck === "nameTextBox") {
            if (new RegExp(Pattern.SET_VAR_PATTERN).test(event.target.value) !== true) {
                setVariableValidation("Variable names cannot start with a number or _");
            } else if (event.target.value.toLowerCase() === 'nombre' ||
                event.target.value.toLowerCase() === 'pronoun' ||
                event.target.value.toLowerCase() === 'upright' ||
                event.target.value.toLowerCase() === 'float' ||
                event.target.value.toLowerCase() === 'boolean' ||
                event.target.value.toLowerCase() === 'string' ||
                event.target.value.toLowerCase() === 'integer') {
                setVariableValidation("This variable name is system reserved.");
            } else {
                setVariableValidation('')
            }
        } else if (commandId === Constant.COMMAND_ADD_BLOCK) {
            if (new RegExp(Pattern.BLOCK_NAME_PATTERN).test(event.target.value) !== true) {
                setVariableValidation("Do not use any spaces and special character in block name");
            }
        }
    }

    const checkTextBoxHandle = (event, widgetNameCheck, commandId) => {
        setVariableValidation('')
        let value = event.target.value
        switch (commandId) {
            case Constant.COMMAND_ADD_BLOCK:
                if (allBlockList.findIndex(blocksName => blocksName.displayName === event.target.value) >= 0) {
                    setVariableValidation(`Block "${value}" is already available`)
                    event.target.value = ''
                    value = ''
                } else if (new RegExp(Pattern.BLOCK_NAME_PATTERN).test(event.target.value) !== true) {
                    setVariableValidation("Do not use any spaces and special character in block name");
                }
                break;

            case Constant.COMMAND_SET_VAR:
                if (widgetNameCheck === "nameTextBox") {
                    if (new RegExp(Pattern.SET_VAR_PATTERN).test(event.target.value) !== true) {
                        event.target.value = ''
                        setVariableValidation("Variable names cannot start with a number or _");
                    } else if (event.target.value.toLowerCase() === 'nombre' ||
                        event.target.value.toLowerCase() === 'pronoun' ||
                        event.target.value.toLowerCase() === 'upright' ||
                        event.target.value.toLowerCase() === 'float' ||
                        event.target.value.toLowerCase() === 'boolean' ||
                        event.target.value.toLowerCase() === 'string' ||
                        event.target.value.toLowerCase() === 'integer') {
                        event.target.value = ''
                        setVariableValidation("This variable name is system reserved.");
                    }
                }
                break;
        }

    }

    const onKeyPressHandle = (event) => {
        if (event.key === 'Enter') {
            if (allBlockList.findIndex(blocksName => blocksName.displayName === event.target.value) >= 0) {
                setVariableValidation(`Block "${event.target.value}" is already available`)
                event.target.value = ''
            }
        }
    }

    switch (commandId) {
        case Constant.COMMAND_LEAVES_THE_SCENE:
            if (durationLeaveScene === true) {
                defaultDisabled = true;
                required = '';
                defaultValue = '';
            }
            break;

        case Constant.COMMAND_HIDDEN_CHARACTER:
            if (durationLeaveScene === true) {
                defaultDisabled = true;
                required = '';
                defaultValue = 2;
            }
            break;

        case Constant.COMMAND_CHANGE_EXPRESSION:
            defaultDisabled = true;
            defaultValue = currentSelectedVariationName
            break;

        case Constant.COMMAND_SET_VAR:
            if (component.widgetName === 'valueTextBox' && variableFields === 'Boolean') {
                return null;
            }
            if ((addGenderedText === true || addGenderedText == 'true') && component.widgetName === 'valueTextBox') {
                return null;
            }
            if (component.widgetName !== "nameTextBox") {
                if (variableFields === 'Integer') {
                    textBoxType = 'number';
                    stepforNumber = 1
                } else if (variableFields === 'Float') {
                    textBoxType = 'number';
                    stepforNumber = 0.01
                }
                if (defaultValue && defaultValue !== '') {
                    defaultValue = defaultValue.replace(new RegExp(/['"]+/g), '')
                }
                if (component.widgetName === "nameTextBox" && isCommandEdit === true) {
                    defaultDisabled = true;

                }
            }
            break;

        case Constant.COMMAND_PAID_ADD_DECISION:
            if (defaultValue && defaultValue !== '') {
                defaultValue = defaultValue.replace(new RegExp(/['"]+/g), '')
            }
            break;

        case Constant.COMMAND_ADD_IF:
        case Constant.COMMAND_ADD_ELSE:
        case Constant.COMMAND_ADD_ELSE_IF:
        case Constant.COMMAND_ADD_END: 
            if (defaultValue && defaultValue !== '') {
                defaultValue = defaultValue.replace(new RegExp(/['"]+/g), '')
            }
            const listSelectedVar = variableListDTO.filter(varialeList => varialeList.name === variableFields)
            if (listSelectedVar.length > 0) {
                switch (listSelectedVar[0].datatype) {
                    case 'Boolean':
                        return null;

                    case 'Integer':
                        textBoxType = 'number';
                        stepforNumber = 1
                        break;

                    case 'Float':
                        textBoxType = 'number';
                        stepforNumber = 0.01;
                        break;

                    default:
                        if (variableFields == null) {
                            return null;
                        }

                        break;
                }
            }
            break;

        default:
            break;
    }

    if (component.suffix === '') {
        return (
            <Form.Group controlId={component.widgetName}>
                <Form.Label className={`commandLabel`}>{component.label}</Form.Label>
                {
                    component.note !== '' &&
                    <label className='commandNote'>{component.note}</label>
                }
                <Form.Control
                    type={textBoxType !== false ? textBoxType : component.type}
                    className={`textlength ${classNameDisabled}`}
                    label={component.label}
                    maxLength={component.properties.maxLength}
                    minLength={component.properties.minLength}
                    defaultValue={defaultValue}
                    placeholder={component.placeHolder}
                    disabled={defaultDisabled ? true : null}
                    step={stepforNumber !== false ? stepforNumber : ''}
                    required={required}
                    autoComplete="off"
                    key={component.widgetName}
                    pattern={commandId === Constant.COMMAND_ADD_BLOCK ? Pattern.BLOCK_NAME_PATTERN : null}
                    onKeyPress={commandId === Constant.COMMAND_ADD_BLOCK ? (event) => onKeyPressHandle(event) : ''}
                    index={component.index}
                    onChange={(event) => handleValueForTextBox(event, component.widgetName, commandId)}
                    onBlur={(event) => checkTextBoxHandle(event, component.widgetName, commandId)}
                // pattern = {textPattern}
                />
                <Form.Control.Feedback type="invalid">
                    {
                        commandId === Constant.COMMAND_ADD_BLOCK ? 'Please enter valid block name' : 'Field is required'
                    }
                </Form.Control.Feedback>
                {
                    isValidText === false &&
                    <Form.Text className="text-danger">
                        Block name already exists
                    </Form.Text>
                }
                {
                    variableValidation !== '' &&
                    <Form.Text className="text-danger">
                        {variableValidation}
                    </Form.Text>
                }
            </Form.Group>
        )
    }
    else {
        return (<Form.Row>
            <Form.Group controlId={component.widgetName}>
                <Form.Label className="commandLabel">{component.label}</Form.Label>
                {
                    commandId === Constant.COMMAND_SET_VAR &&
                    <Row><label className='commandLabelSuffix'>{component.suffix}</label></Row>
                }
                <Form.Row>
                    <Col>
                        <Form.Control
                            type={textBoxType !== false ? textBoxType : component.type}
                            className="textlength"
                            label={component.label}
                            maxLength={component.properties.maxLength}
                            placeholder={component.placeHolder}
                            defaultValue={defaultValue}
                            required={required}
                            step={stepforNumber !== false ? stepforNumber : ''}
                            disabled={defaultDisabled ? true : null}
                            autoComplete="off"
                            index={component.index}
                            key={component.widgetName}
                            onChange={handleTextFieldChange} />
                        <Form.Control.Feedback type="invalid">
                            {(variableFields === 'Integer' || variableFields === 'Float') && component.widgetName === 'valueTextBox' ? `Please enter valid ${variableFields.toLowerCase()} value` : `Field is required`}
                        </Form.Control.Feedback>

                    </Col>
                    {
                        commandId !== Constant.COMMAND_SET_VAR &&
                        <Col className='commandlabelsuffixDiv'>
                            <label className='commandSuffixSetVar'>{component.suffix}</label>
                        </Col>
                    }
                </Form.Row>
            </Form.Group>
        </Form.Row>)
    }
}
export default TextBox
import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const Login = styled.div`
  margin: 40px 0 0 0;

  .row {
    margin-top: 5%;
  }
  .loginError{
    color:red;
  }
`;
export const AccountIcon = styled.div`
  background: ${colors.primary_green} 0% 0% no-repeat padding-box;
`;

export const AccountCreated = styled.div`
  margin: 100px 0 0px 0px;
  height: 150px;
  width: 100%;
  text-align: center;
  color: ${colors.color_black};
  font-size: 22px;
`;
export const AccountCreatedEmail = styled.div`
  margin: 20px 0 0px 0px;

  text-align: center;
  font-size: 14px;
  color:  ${colors.davy_grey};
`;
export const Links = styled.div`
  margin: 10px 0 0px 0px;

  color: ${colors.vivid_blue};
`;


export const Errors = styled.span`
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color:  ${colors.bright_red};
`;


export const RecoveryPassword = styled.div`
  .invalid-feedback {
    font-size: 80% !important;
  }
  .text-danger{
    font-size: 80% !important;
  }
  .text-secondary{
    font-size: 80% !important;
  }
  .info-container{
  margin:10%;
background: #F5F5F5 0% 0% no-repeat padding-box;
border-radius: 4px;
text-align: left;
font: Bold 14px  PT Sans;
letter-spacing: 0px;
color: #707070;
width: 90%;
ol.info-list {
  list-style-type: disc;
}

}
`;
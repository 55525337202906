import React from 'react';
import CustomFlowchart from '../../components/flowchart/custom-flowchart'



function fetchFlowchartData(blocks) {
    let getConnectorsAll = [];
    let getBlocksAll = [];
    return ({
        'flowchartNode': getBlocksAll,
        'flowchartConnector': getConnectorsAll
    })
}

function fetchLocalVariable(variableDTO) {
    let localVariableDtoNew = []
    let variableDTOs = variableDTO
    if (variableDTOs && variableDTOs.length > 0) {
        //   const filterNotVariableSelected = variableDTOs.filter(variable => (variable.isValusAsVariable === false))
        if (variableDTOs.length > 0) {
            variableDTOs.forEach(listFlagTrue => {
                let array2 = variableDTOs.find(nottrueFlag => nottrueFlag.name == listFlagTrue.value && listFlagTrue.isValusAsVariable === true);
                if (array2) {
                    listFlagTrue.value = array2.value;
                }
                const objectNew = {
                    'id': listFlagTrue.id,
                    'name': listFlagTrue.name,
                    'value': listFlagTrue.value,
                    'defaultValue': listFlagTrue.value,
                    'datatype': listFlagTrue.datatype,
                    'chapterName': listFlagTrue.chapterName,
                    'scope': listFlagTrue.scope,
                    'type': listFlagTrue.type,
                    'sprites': listFlagTrue.spriteDTO,
                    'uiFeildType': listFlagTrue.uiFeildType
                }

                if (listFlagTrue.hasOwnProperty('options')) {
                    objectNew['options'] = listFlagTrue.options;
                }
                localVariableDtoNew.push(objectNew)
            })
        }
    }

    return localVariableDtoNew
}

function fetchBlockFlowchartData(blocks, clickedBlockId = 0) {
    let indexConnector = 0;
    let getConnectorsAll = [];
    let getBlocksAll = [];
    let data = [];
    let currentIndex = 0;
    let previousParentStore = null;
    let totalParentStore = 0;
    let calculateYwithParent = 0;
    let lastLevel = 0
    const actionBlocks = blocks;
    actionBlocks.blocks.map((frames, index) => {

        let name = frames.name

        if (frames.level !== 0) {
            if (lastLevel < frames.level) {
                lastLevel = frames.level
            }
        } else {
            lastLevel = lastLevel + 1
        }

        if (index === 0) {
            data = {
                metaData: {
                    "id": frames.id,
                    'label': frames.name,
                    'displayName': frames.name,
                    'level': frames.level !== 0 ? frames.level : lastLevel,
                    'shape': 'box',
                    'color': {
                        'background': frames.color,
                        'highlight': {
                            'background': frames.color,
                        },
                        'hover': {
                            'background': frames.color,
                        },
                    },

                    'font': {
                        'color': frames.fontColor
                    },
                    'index': frames.index,
                    'physics': true,
                }
            };
        }
        data = {
            metaData: {
                "id": frames.id,
                'label': frames.name,
                'displayName': frames.name,
                'level': frames.level !== 0 ? frames.level : lastLevel,
                'shape': 'box',
                'color': {
                    'background': frames.color,
                    'highlight': {
                        'background': frames.color,
                    },
                    'hover': {
                        'background': frames.color,
                    },
                },
                'shadow': {
                    'enabled': false,
                },
                'margin': {
                    'top': 6,
                    'right': 12,
                    'bottom': 6,
                    'left': 12
                },
                'labelHighlightBold': false,
                'font': {
                    'color': frames.fontColor
                },
                'index': frames.index,
            }
        };

        if ((frames.id === clickedBlockId)) {
            data = {
                metaData: {
                    "id": frames.id,
                    'labelHighlightBold': false,
                    'label': frames.name,
                    'displayName': frames.name,
                    'level': frames.level !== 0 ? frames.level : lastLevel,
                    'shape': 'box',
                    'color': {
                        'background': frames.color,
                        'highlight': {
                            'background': frames.color,
                        },
                        'hover': {
                            'background': frames.color,
                        },
                    },
                    'margin': {
                        'top': 6,
                        'right': 12,
                        'bottom': 6,
                        'left': 12
                    },
                    'shadow': {
                        'enabled': true,
                        'color': '#00000066',
                        'size': 4,
                        'x': 1,
                        'y': 1,
                    },
                    'font': {
                        'color': frames.fontColor
                    },
                    'index': frames.index,
                }
            };
        }

        if (frames.name !== undefined) {
            getBlocksAll[index] = data.metaData;
            if (frames.connectors !== undefined) {
                frames.connectors.map((framesC, key) => {
                    getConnectorsAll[indexConnector] = framesC;
                    indexConnector++;
                });
            }
        }
    });
    return ({
        'flowchartNode': getBlocksAll,
        'flowchartConnector': getConnectorsAll,
        'actionBlocksFinal': actionBlocks,
        'maxLevel': lastLevel
    })
}

function fetchBlockFlowchartDataWithAddBlock(blocks, sceneId) {
    let indexConnector = 0;
    let getConnectorsAll = [];
    let getBlocksAll = [];
    let data = [];
    let previousParentStore = null;
    let totalParentStore = 0;
    let calculateYwithParent = 0;
    const actionBlocks = blocks;
    let currentIndex = 0;
    actionBlocks.blocks.map((frames, index) => {
        let name = frames.name
        name = name.slice(0, 12) + (name.length > 12 ? "..." : "");

        data = {
            metaData: {
                "id": frames.id.toString(),
                'name': name.toString(),
                'displayName': frames.name,
                'x': 120,
                'y': 100 + 55 * (index - calculateYwithParent),
                'size': 1520,
                viewGenerator: node => <CustomFlowchart classNameProps='nodescenes' />,
            }
        };

        if (index === 0) {
            data = {
                metaData: {
                    "id": frames.id,
                    "color": "#157FFB",
                    "fontColor": "#FFFFFF",
                    'name': name,
                    'displayName': frames.name,
                    'size': 1520,
                    'x': 120,
                    'y': 100 + 55 * (index),
                    viewGenerator: node => <CustomFlowchart classNameProps='nodeblocksfirst' />,
                }
            };
        }

        if (frames.parentId !== undefined && frames.parentId !== null) {
            let calculateX = 80;
            let calculateY = 100 + 55 * (index - calculateYwithParent)

            totalParentStore++;

            if (previousParentStore === frames.parentId && totalParentStore === 1 && (index - currentIndex) === 1) {

                totalParentStore = 0;
                previousParentStore = null;
                calculateX = 200;
                calculateY = 100 + 55 * (index - 1 - calculateYwithParent);
                calculateYwithParent++;
            } else {
                previousParentStore = frames.parentId;
                currentIndex = index;
                totalParentStore = 0;
            }
            data = {
                metaData: {
                    "id": frames.id,
                    "color": "#E3E3E3",
                    "fontColor": "#313131",
                    'name': name,
                    'displayName': frames.name,
                    'strokeColor': '#E3E3E3',
                    'x': calculateX,
                    'y': calculateY,
                    'size': 1520,
                    viewGenerator: node => <CustomFlowchart classNameProps='nodescenesblocks' />,
                }
            };
        } else {
            totalParentStore++;
        }

        if (frames.name !== undefined) {
            getBlocksAll[index] = data.metaData;
            if (frames.connectors !== undefined) {
                frames.connectors.map((framesC, key) => {
                    getConnectorsAll[indexConnector] = framesC;
                    indexConnector++;
                });
            }
        }
    });
    return ({
        'flowchartNode': getBlocksAll,
        'flowchartConnector': getConnectorsAll,
        'actionBlocksFinal': actionBlocks

    })
}


export const flowchartServices = {
    fetchFlowchartData,
    fetchBlockFlowchartData,
    fetchBlockFlowchartDataWithAddBlock,
    fetchLocalVariable
};
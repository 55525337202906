import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as translations from './../helpers/traslations';
import * as styled from './../style';
import * as Constant from './../../../common/constants';
import * as aspectRatioConfig from './../../../config/aspectRatio.config';


const PlayerButtons = props => {
   
  const [replaySrc, setReplaySrc] = useState('/images/icons/replay-gray.svg');

  let playPauseImage = props.externalPlayPauseHandler ? '/images/icons/play-pause.svg' : '/images/icons/play.svg';

  const handlePlayerButtonsClickPausePlay = (event, playPauseEvent) => {
    props.handlePlayerButtonsClick(event, playPauseEvent, true)
    props.setStartFromHighlightBlock(true);
    let playMusic = document.getElementById("playMusic");
    let playSound = document.getElementById("playSound");
    
    if (playMusic && playPauseEvent === Constant.EVENT_PAUSE){
      playMusic.pause()
    }
    if (playMusic && playPauseEvent === Constant.EVENT_PLAY){
      playMusic.play()
    }
    
    if (playSound && playPauseEvent === Constant.EVENT_PAUSE){
      playSound.pause()
    }
    if (playSound && playPauseEvent === Constant.EVENT_PLAY){
      playSound.play()
    }
  }

  const handleOver = () => {
    setReplaySrc('/images/icons/replay-gray.svg');
  }
  
  const handleOut = () => {
    setReplaySrc('/images/icons/replay-gray.svg')
  }
  return (
    <styled.playerButtons buttonPanelWidth = {aspectRatioConfig.preview[props.aspectRatio].buttonPanelWidth}>
      <div className="col-md-6 p-0 text-center replayButton" 
        onMouseOver={handleOver}
        onMouseOut={handleOut}>
      <OverlayTrigger
        key="first"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`}>
           {props.sceneId?translations.TOOTIP_FIRST_BLOCK: translations.TOOTIP_FIRST_STORY}
          </Tooltip>
        }
      >
        <img  
        onClick={e => props.replayStory(e, Constant.EVENT_FIRST, true)}  
        alt="" 
        src={replaySrc} width="24" height="24"/>
      </OverlayTrigger>
      </div>
      
      <div className="col-md-6 p-0">
        <OverlayTrigger
          key="playPause"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>
            { props.externalPlayPauseHandler?translations.TOOTIP_PAUSE:translations.TOOTIP_PLAY}
            </Tooltip>
          }
        >
          <img  onClick={e => handlePlayerButtonsClickPausePlay(e, props.externalPlayPauseHandler ? Constant.EVENT_PAUSE : Constant.EVENT_PLAY, true)} alt="" 
            src={playPauseImage} height="30" width="30" style={{marginLeft: '-15px'}}/>
        </OverlayTrigger>
      </div>
    
    </styled.playerButtons>
  );
};

export default PlayerButtons;

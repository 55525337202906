import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PlayerButtons from './widgets/player-buttons';

import * as styled from './style';
import './style.css';

import BlocksPlayer from './BlocksPlayer';
 

function PlayerModal({
  showModal,
  hidePreviewModal,
  commandsTimeoutIds,
  setCommandsTimeoutIds,
  handlePlayerButtonsClick,
  blocks,
  sceneId,
  play,
  setPlay,
  firstPlay,
  lastPlay,
  nextPlay,
  prevPlay,
  gotoPlay,
  setGotoPlay,
  currentBlock,
  setCurrentBlock,
  currentCommand,
  setCurrentCommand,
  currentScene,
  setCurrentScene,
  handleDialogueDisplayEvent,
  previewTitle,
  showSpinner,
  fastPreview,
  setFastPreview,
  checkCommandState,
  setCheckCommandState,
  handleDecisionClick,
  setShowDecision,
  showDecision,
  decisionParameters,
  setDecisionParameters,
  setIfElseDecisionParameters,
  ifElseDecisionParameters,
  showEnd,
  setShowEnd,
  loading,
  blobImages,
  updateVariableWithId
}) {
  return (
    <Modal
      size="lg"
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="pereviewModal"
    >
      <Modal.Header >
        <Modal.Title>{previewTitle}</Modal.Title>
        <Button onClick={hidePreviewModal} variant="secondary" className="btn-snacktool-tertiary btn-snacktool">
          Close Preview
        <i className="fa fa-times" aria-hidden="true"></i>
        </Button>
      </Modal.Header>
      {/* <PlayerState 
      currentScene = {currentScene}
      currentBlock = {currentBlock}
      currentCommand = {currentCommand}/> */}
      <Modal.Body>
        <styled.smartplayer>
          {
            loading === true &&
            <Spinner className="spinner-class" animation="border" />
          }
          {
            loading === false &&
            <BlocksPlayer
              blocks={blocks}
              commandsTimeoutIds={commandsTimeoutIds}
              setCommandsTimeoutIds={setCommandsTimeoutIds}
              play={play}
              firstPlay={firstPlay}
              lastPlay={lastPlay}
              prevPlay={prevPlay}
              nextPlay={nextPlay}
              gotoPlay={gotoPlay}
              setGotoPlay={setGotoPlay}
              currentBlock={currentBlock}
              setCurrentBlock={setCurrentBlock}
              currentCommand={currentCommand}
              setCurrentCommand={setCurrentCommand}
              currentScene={currentScene}
              setCurrentScene={setCurrentScene}
              handleDialogueDisplayEvent={handleDialogueDisplayEvent}
              previewTitle={previewTitle}
              fastPreview={fastPreview}
              setFastPreview={setFastPreview}
              checkCommandState={checkCommandState}
              setCheckCommandState={setCheckCommandState}
              setPlay={setPlay}
              handlePlayerButtonsClick={handlePlayerButtonsClick}
              handleDecisionClick={handleDecisionClick}
              setShowDecision={setShowDecision}
              showDecision={showDecision}
              decisionParameters={decisionParameters}
              setDecisionParameters={setDecisionParameters}
              setIfElseDecisionParameters={setIfElseDecisionParameters}
              ifElseDecisionParameters={ifElseDecisionParameters}
              showEnd={showEnd}
              setShowEnd={setShowEnd}
              blobImages={blobImages}
              updateVariableWithId={updateVariableWithId}
            />
          }

        </styled.smartplayer>
      </Modal.Body>
      <Modal.Footer>
        <PlayerButtons
          sceneId={sceneId}
          handlePlayerButtonsClick={handlePlayerButtonsClick}
          play={play}
          setPlay={setPlay}
        />

      </Modal.Footer>
    </Modal>
  );
}

export default PlayerModal;

import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const Header = styled.div`
  .header-container {
    /*background: transparent linear-gradient(96deg, #BC45AD 0%, #530ACD 100%) 0% 0% no-repeat padding-box;*/
    width: 100%;
    color: #ffffff;
    background-image: url('../../images/headerbg.png');
    background-size: 100% 100%;
  }
  .container {
    max-width: 100%;
    padding: 0;
  }

  .tooltip-head {
    ${'' /* width: 200px;
    height: 55px; */}
    width: 90%;
    background: #43425d 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-left: 10%;
    position: relative;
    border-radius: 5px;
    .tooltip-content {
      padding: 1% 15% 1% 10%;
      /* margin: 10%; */
      font: normal normal normal 12px/14px Roboto;
      letter-spacing: 0.4px;
      color: #ffffff;
      opacity: 1;
      text-align: left;
      color: #ffffff;
      opacity: 1;
    }
  }
  .tooltip-head:after {
    content: '';
    position: absolute;
    right: -15px;
    top: 60%;
    border: 5px solid transparent;
    border-left: 10px solid #43425d;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .activeNav {
    color: #ffffff !important;
    border-bottom: 2px solid #ffffff;
  }
  .navbar {
    padding: 0;
    display: flex;
    align-items: center;
    .fa {
      color: #fff;
    }
    .fa-chevron-down {
      font-size: 12px;
      margin-left: 6px;
    }
    .fa-user-circle {
      margin-left: 12px;
    }
  }
  nav-link {
    display: block;
    padding: 0.2rem 1rem;
  }

  .saveLink {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
  }
  .storySaveLabel {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-family: Roboto;
    margin-top: -20px !important;
  }
  .saveContainer {
    display: flex;
    align-items: center;
  }

  .version {
    font-size: 12px;
    color: lightgray;
  }
  .dropdown-menu {
    min-width: auto;
    .disabled {
      color: lightgray !important;
    }
  }
  .logoStyle {
    color: #ffffff;
    text-decoration: none;
  }
  .header-left {
    width: 50%;
  }
  .header-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .col-2 {
      display: flex;
      justify-content: center;
    }
    .btnMainStory {
      padding: 0;
    }

    .btn:focus {
      box-shadow: none !important;
    }
  }

  .user-account-dropdown {
    .dropdown-menu {
      .dropdown-item {
        color: #303030 !important;
        font-size: 14px;
        padding: 5px 25px !important;
        a {
          text-decoration: none;
          color: #303030 !important;
          font-size: 14px;
        }
        &:hover {
          text-decoration: none;
          background: #eeeeee !important;
          color: #303030 !important;
          font-size: 14px;
          a {
            text-decoration: none;
            background: #eeeeee !important;
            color: #303030 !important;
            font-size: 14px;
          }
        }
      }
      .disabled {
        color: lightgray !important;
      }
    }
  }
  .header-container-small {
    height: 68px;
  }

  .header-container-big {
    height: 150px;
  }

  .saving_ellipses {
    .ellipsis-anim span {
      opacity: 0;
      -webkit-animation: ellipsis-dot 1s infinite;
      animation: ellipsis-dot 1s infinite;
    }

    .ellipsis-anim span:nth-child(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .ellipsis-anim span:nth-child(2) {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    .ellipsis-anim span:nth-child(3) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }

    @-webkit-keyframes ellipsis-dot {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes ellipsis-dot {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
export const userTitle = styled.span`
  padding: 0 0px 0 10px;
  color: #fff;
  text-transform: capitalize;
`;
export const HeaderMenu = styled.div`
  padding: 24px;
  letter-spacing: 0.23px;
  display: flex;
  justify-content: space-between;

  .notification {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    padding: 4px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    background: #ff6565;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -5px;
    top: -5px;
  }
  .nav-link {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .activeNav {
    color: rgba(255, 255, 255, 1) !important;
  }
`;
export const LogoTitle = styled.span`
  font-family: SignPainter;
  font-size: 24px;
`;
export const LogoSubTitle = styled.span`
  font-size: 24px;
  font-family: PT Sans Narrow;
  text-transform: uppercase;
  font-weight: bold;
`;

export const HeaderLabel = styled.div`
  display: none;
`;

export const NavBar = styled.div`
  height: 40px;
  background-color: ${colors.light_sea_green} !important;
  text-align: left;
  padding: 10px;
`;

export const NavBarTitle = styled.div`
  color: white;
  font-size: 30px;
  float-align: left;
  padding-left: 40px;
  font-weight: 80px;
`;

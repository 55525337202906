import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { ModalStyled } from './Style';

function Confirm(props) {
  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <ModalStyled.addAssetMessageBox>
          <ModalStyled.successAssetContent>
            <Row>
              <Col className="fail-icon">
                <img
                  alt=""
                  className="mediumIcon"
                  src="/images/icons/ic_warning_24px.svg"
                />
              </Col>
            </Row>
            <Row>
              <Col className="submit-asset-label" data-testid="first-label">
                {props.firstLineLabel}
              </Col>
            </Row>
            <Row>
              <Col className="submit-asset-label" data-testid="second-label">
                {props.secondLineLabel}
              </Col>
            </Row>
            <Row className="mt-4">
              {props.cancel ? (
                <Col className="mt-4">
                  <Button
                    className="btn-snacktool btn-snacktool-golden"
                    onClick={() => props.onClose()}
                  >
                    {' '}
                    {props.cancel}{' '}
                  </Button>
                </Col>
              ) : (
                ''
              )}
              <Col className="mt-4">
                <Button
                  onClick={() => {
                    props.handleClearCharacter();
                    props.onClose();
                  }}
                  className="btn-snacktool btn-snacktool-golden"
                >
                  {props.confirm ? props.confirm : 'Leave'}
                </Button>
              </Col>
            </Row>
          </ModalStyled.successAssetContent>
        </ModalStyled.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}

export default Confirm;

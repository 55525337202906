import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function AddObjectSection(props) {
  const reorderObjectLayers = (
    reorderObjectList,
    sourceIndex,
    targetIndex,
    sourceId,
    targetId
  ) => {
    // Get current moved Object
    let selectedObject = reorderObjectList[sourceIndex];
    let sliceOne = [];
    let sliceTwo = [];
    // Remove from current Object list
    if (sourceIndex < targetIndex) {
      sliceOne = reorderObjectList.slice(0, targetIndex + 1);
      sliceTwo = reorderObjectList.slice(
        targetIndex + 1,
        reorderObjectList.length
      );
    } else {
      sliceOne = reorderObjectList.slice(0, targetIndex);
      sliceTwo = reorderObjectList.slice(targetIndex, reorderObjectList.length);
    }

    let newObjectLayersOne = sliceOne.filter((slice, index) => {
      if (parseInt(slice.asset.id) !== sourceId) {
        return slice;
      }
    });

    let newObjectLayersTwo = sliceTwo.filter((slice, index) => {
      if (parseInt(slice.asset.id) !== sourceId) {
        return slice;
      }
    });
    let newObjectList = [
      ...newObjectLayersOne,
      selectedObject,
      ...newObjectLayersTwo
    ];

    return newObjectList;
  };

  let sourceIndex = 0;
  let targetIndex = 0;
  let sourceId = 0;
  let targetId = 0;

  const handleDragOverLayerObject = e => {
    targetIndex = parseInt(e.currentTarget.dataset.id);
    targetId = parseInt(e.currentTarget.dataset.assetid);
    e.stopPropagation();
  };

  const handleDragStartObject = e => {
    sourceIndex = parseInt(e.currentTarget.dataset.id);
    sourceId = parseInt(e.currentTarget.dataset.assetid);
    e.stopPropagation();
  };

  const handleDragEndObject = e => {
    let objectList = reorderObjectLayers(
      props.viewObjects,
      sourceIndex,
      targetIndex,
      sourceId,
      targetId
    );
    props.handleReorderList(objectList);
    e.stopPropagation();
    if (props.setAutoSaveFlag) {
      props.setAutoSaveFlag(true);
    }
  };

  return (
    <div className={props.addObject === false ? 'd-none' : ''}>
      <Button
        type="submit"
        disabled={props.addNewStatus}
        onClick={props.handleAddObjectClick}
        className="btn-snacktool btn-snacktool-brown ml-28"
      >
        Add new +
      </Button>
      <div className="mt-24">
        {props.viewObjects.length > 0 ? (
          <p className="layer-title d-flex">
            Object layers{' '}
            <OverlayTrigger
              key={`object-layers`}
              placement="top"
              overlay={
                <Tooltip id={`object-layers`} className="tooltip-320">
                  This represent the order of the objects added to the view.
                  They can be rearranged.
                </Tooltip>
              }
            >
              <span className="info-icon ml-2"></span>
            </OverlayTrigger>
          </p>
        ) : (
          ''
        )}
        <div className="overflow-scroll-y">
          {props.viewObjects.length > 0 &&
            props.viewObjects.map((object, index) => {
              return (
                <div
                  className="d-flex align-items-center mb-3"
                  key={`objectkeyObject-${index}`}
                  data-assetid={object.asset.id}
                  onDragEnd={handleDragEndObject}
                  onDragStart={handleDragStartObject}
                  onDragOver={handleDragOverLayerObject}
                  data-id={index}
                  draggable="true"
                >
                  <div className="mr-4">
                    <i
                      className="fa fa-bars bars mr-4"
                      // data-id={index}
                      // draggable="true"
                      // data-assetid={object.asset.id}
                      // onDragEnd={handleDragEndObject}
                      // onDragStart={handleDragStartObject}
                    ></i>
                    <span className="mr-4 layer-text align-items-center object-title">
                      {object.asset.name}&nbsp;
                      {object.hidden && (
                        <i className="fa fa-eye-slash disabled" />
                      )}
                    </span>

                    <span className="ml-auto">
                      <OverlayTrigger
                        key={`active-edit`}
                        placement="left"
                        overlay={
                          <Tooltip id={`active-edit`}>Edit object</Tooltip>
                        }
                      >
                        <img
                          src={`/images/icons/active-edit.svg`}
                          className="svg-icon-image"
                          onClick={() => props.handleEditObjectClick(index)}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        key={`remove-object`}
                        placement="left"
                        overlay={
                          <Tooltip id={`remove-object`}>Remove object</Tooltip>
                        }
                      >
                        <img
                          src={`/images/icons/active-deactivate.svg`}
                          className="svg-icon-image"
                          onClick={() => props.handleObjectDeleteClick(index)}
                        />
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AddObjectSection;

import React from 'react';
import * as FlowchartStyle from './../flowchart/style-flowchart-view';
import FlowchartView from './../flowchart/flowchart-view';

function FlowchartPanel({
    flowchartNode,
    flowchartConnector,
    currentSceneId,
    handleNodeClickforFlowchartNode,
    levelFlowchart,
    flowchartHasErrors,
    commandListForScriptEditor,
    storyId,
    flowchartJson,
    storeFlowchartJson,
    setUpdatedFlowchartJson
}) {

    const indentAllCommands = (commandsScript) => {
        let valueIndent = ''
        if (commandsScript && commandsScript.commands.length > 0) {
            commandsScript.commands.map(commands => {
                valueIndent =
                    valueIndent !== ""
                        ? `${valueIndent}\n${commands.cmdText}`
                        : commands.cmdText
                if (commands.cmdText !== '') {
                    return valueIndent
                }
            })
        }
        return valueIndent
    }

    const checkNull = indentAllCommands(commandListForScriptEditor)

    return <React.Fragment>
        {
            <FlowchartStyle.writeStoryFlowchart>
                {
                    currentSceneId !== null && flowchartNode.length > 0 ?
                        <FlowchartStyle.showHide>
                            <FlowchartView
                                handleNodeClickforFlowchartNode={handleNodeClickforFlowchartNode}
                                flowchartNode={flowchartNode}
                                flowchartConnector={flowchartConnector}
                                currentSceneId={currentSceneId}
                                levelFlowchart={levelFlowchart}
                                storyId={storyId}
                                flowchartJson={flowchartJson}
                                storeFlowchartJson={storeFlowchartJson}
                                setUpdatedFlowchartJson={setUpdatedFlowchartJson}
                            />
                        </FlowchartStyle.showHide>
                        : currentSceneId !== null && checkNull !== '' && flowchartHasErrors &&
                        <div className="showErrorFlowchart">
                            Fix errors in the script to see this Flowchart
                        </div>
                }
            </FlowchartStyle.writeStoryFlowchart>
        }
    </React.Fragment >
}

export default FlowchartPanel
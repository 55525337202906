import styled, { keyframes } from "styled-components";
import { colors } from "../../../common/styles/variables";
import { mobileRange, tabletRange, mobileAndTabletRange } from "../../../common/styles/breakpoints";

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`
export const LoaderWrapper = styled.div`
  overflow: hidden;
  background-color: ${colors.color_white};
`;

export const ItemLoader = styled.div`
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: ${colors.alabaster};
  background: linear-gradient(to left,#A8A8A8 , #E8E8E8 30%,#A8A8A8 );
  height: 168px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: 40px;
  @media ${mobileAndTabletRange} {
    height: 168px;
  }
`;
export const MaskList100 = styled.div`
  background: ${colors.color_white};
  width: 100%;
  height: 20px;
  float: right;
`;
export const MaskList80 = styled.div`
  background: ${colors.color_white};
  width: 80%;
  height: 22px;
  float: right;
`;

export const MaskList50 = styled.div`
  background: ${colors.color_white};
  width: 50%;
  height: 22px;
  float: right;
`;

export const MaskList25 = styled.div`
  background: ${colors.color_white};
  width: 25%;
  height: 22px;
  float: right;
`;
export const MaskList60 = styled.div`
  background: ${colors.color_white};
  width: 60%;
  height: 22px;
  float: right;
`;

export const LoaderContainer = styled.div`
  min-width: 260px;
  max-height:654px !important;
  background-color: ${colors.color_white};
  // margin-left: 40px;
  padding: 30px 0 0;
  // @media ${tabletRange} {
  //   min-width: 220px;
  //   padding: 20px 0;
  //   margin-left: 0;
  // }
  // @media ${mobileRange} {
  //   display: none;
  // }
`;

export const LoaderContainerMobile = styled.div`
  display: none;
  @media ${mobileRange} {
    display: block;
    background-color: ${colors.color_white};
    width: 100%;
    display: block;
    margin-left: 0;
  }
`;


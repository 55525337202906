import React, { useState, useRef, useEffect } from 'react';
import AddAssetModal from './../../components/library/add-cover-image/AddAssetModal';
import CreateAssetModal from './../../components/library/create-asset/CreateAssetModal';
import * as Constant from './../../components/library/constant';
import { connect } from 'react-redux';
import * as LibraryActions from './../../actions/library-action';

import { LibraryServices } from './../../components/library/service';
import ListAsset from './../../components/library/cover-image/ListAsset';
import ListFile from './../../components/library/list-files-cover/ListFile';
import Progress from './../../components/library/list-files-cover/Progress';

import ConfirmationWindow from './../../components/library/list-files-cover/ConfirmationWindow';

import LockedAssets from './../../components/library/cover-image/delete-assets/LockedAssets';
import LibraryMenu from './../../components/library/LibraryMenu';
import EditAssetListInfo from './../../components/library/cover-image/delete-assets/EditAssetListInfo';
import * as PlaceBackgroundActions from './../../actions/place-background-action';
import { Row, Col } from 'react-bootstrap';
// import { Steps } from 'intro.js-react';

// import 'intro.js/introjs.css';

function CoverImage(props) {
  const assetConstant = {
    fileIndex: '',
    name: '',
    width: '',
    height: '',
    extension: '',
    assetType: '',
    type: '',
    specs: '',
    awsPath: null,
    isUnique: true,
    isValid: true
  };

  // Add asset state
  const [finishPressed, setFinishPressed] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(Constant.SECOND_TAB_KEY);

  const [validated, setValidated] = useState(false);
  const [files, setFiles] = useState([]);
  const [asset, setAsset] = useState(assetConstant);
  const [modalForEdit, showModalForEdit] = useState(assetConstant);

  const [sorting, setSorting] = useState('asc');
  const [showModal, setShowModal] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [deleteAsset, setDeleteAsset] = useState({ id: null, type: null });

  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [changeFileFlag, setChangeFileFlag] = useState(false);

  const [filters, setFilters] = useState({
    image: false,
    audio: false
  });
  const [fileNames, setFileNames] = useState([]);
  const [assetFiles, setAssetFiles] = useState([]);
  const [refreshList, setRefreshList] = useState(true);
  const [maxFileError, setMaxFileError] = useState(false);
  const [enableUpload, setEnableUpload] = useState(false);
  const [showUploadPopup, setShowUploadPopupEnableUpload] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [focusElement, setFocusElement] = useState('');

  const [uploadCount, setUploadCount] = useState(0);
  const [dummyAsset, setDummyAsset] = useState(false);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (showUploadPopup === true) {
      getFilesData(fileNames);
      // setShowUploadPopupEnableUpload(false);
      setRefreshList(!refreshList);
    }
  }, [fileNames, showUploadPopup]);

  async function getFilesData(fileNames) {
    if (fileNames.length > 0) {
      if (fileNames.length < 16) {
        setAssetFiles(
          await LibraryServices.setAsssetFileData(
            fileNames,
            asset,
            props.assetList,
            'cover'
          )
        );
      } else {
        setMaxFileError(true);
      }
    }
  }
  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 5);
    }, 100);
  };

  useEffect(() => {
    props.fetchCovers(props.authToken, props.storyId);
    setProgress(0);
    clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (props.isUploadAsset === true) {
      setFileNames([]);
      setAssetFiles([]);
    }
  }, [props.isUploadAsset]);

  useEffect(() => {
    let editFile = [];
    if (props.IsAssetEdit === true && props.assetData) {
      assetConstant.name = props.assetData.name;
      assetConstant.type = props.assetData.type;
      assetConstant.extension = props.assetData.extension;
      assetConstant.specs = props.assetData.specs;
      assetConstant.awsPath = props.assetData.awsPath;
      assetConstant.id = props.assetData.id;
      editFile.push(props.assetData.awsPath ? props.assetData.awsPath : []);
      setFiles(editFile);
      setAsset(assetConstant);
    }
  }, [props.IsAssetEdit]);

  const handleFilterClick = event => {
    if (event.target.name === 'image') {
      setFilters({
        image: true,
        audio: false
      });
    } else {
      setFilters({
        image: false,
        audio: false
      });
    }
    // setFilters({
    //     ...filters,
    //     [event.target.name]: !filters[event.target.name]
    // });
  };

  const handleUploadClick = () => {
    setActiveTabKey(Constant.FIRST_TAB_KEY);
    setFiles([]);
    setAsset(assetConstant);
    setValidated(false);

    props.handleShowModal();
  };

  const handleRollbackAsset = () => {
    props.handleRollbackAsset(asset.name, props.authToken);
  };

  const handleSorting = () => {
    setSorting(sorting === 'asc' ? 'desc' : 'asc');
  };

  const handleShowDeleteModal = (assetId, assetType) => {
    setAssetId(assetId);
    props.getCoverDelete(props.storyId, props.authToken, assetId);
    setShowModal(true);
    setDeleteAsset({ id: assetId, type: assetType });
  };

  const handleHideDeleteModal = () => {
    setAssetId(null);
    props.handleHideAssetModal();
  };
  const handleDeleteAsset = () => {
    props.deleteCover(props.storyId, props.authToken, assetId);
    //delete char api call
  };

  const handleEditAssetClick = (assetId, assetType) => {
    setAsset(assetConstant);
    props.getCoverAsset(props.authToken, props.storyId, assetId);
    setActiveTabKey(Constant.FIRST_TAB_KEY);
    props.getCoverEditInfo(props.storyId, props.authToken, assetId);
    setDeleteAsset({ id: assetId, type: assetType });
    //delete char api call
  };

  const pressOkay = () => {
    setActiveTabKey(Constant.THIRD_TAB_KEY);

    const intervalId = interval();
    setIntervalId(intervalId);
    setProgress(0);
    setChangeFileFlag(false);
    props.saveAsset(
      LibraryServices.getFormData(asset, files),
      props.authToken,
      props.storyId,
      props.IsAssetEdit,
      props.assetData
    );
    setValidated(true);
    showModalForEdit(false);
  };

  const handleEditAsset = () => {
    //props.deleteAsset(props.storyId,props.authToken,assetId);
    //delete char api call
  };

  const handleHideAssetLockModal = () => {
    props.handleResetLockModal();
  };

  const closeModalOperation = () => {
    showModalForEdit(false);
  };

  const handleAssetFileDelete = key => {
    // setFileNames(fileNames.splice(key,0));
    setLoading(true);
    let tempAsset = assetFiles;
    tempAsset.splice(key, 1);

    for (let i = 0; i < tempAsset.length; i++) {
      tempAsset[i].fileIndex = i;
    }

    setAssetFiles(tempAsset);

    let tempFile = fileNames;
    tempFile.splice(key, 1);

    setFileNames(tempFile);

    if (tempAsset.length === 0) {
      setFileNames([]);
      setAssetFiles([]);
      setShowUploadPopupEnableUpload(false);
    }
    setLoading(false);
    setDummyAsset(true);
    setShow(false);
    setRefreshList(!refreshList);
  };

  const handleChangeActiveCover = (assetId, value) => {
    props.postActiveCover(props.storyId, props.authToken, assetId, !value);
  };

  const handleSaveInfo = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();

      asset['storyId'] = props.storyId;
      event.preventDefault();
      // if (
      //   props.IsAssetEdit &&
      //   props.assetInfoListEdit &&
      //   props.assetInfoListEdit.length > 0 &&
      //   ((props.assetData.name.toLocaleLowerCase() !==
      //     asset.name.toLocaleLowerCase() ||
      //     props.assetData.type !== asset.type ||
      //     changeFileFlag === true) &&
      //     props.assetData.type !== 'Background_Image')
      // ) {
      //   showModalForEdit(true);
      // } else if (
      //   props.IsAssetEdit &&
      //   props.assetInfoListEdit &&
      //   props.assetInfoListEdit.length > 0 &&
      //   changeFileFlag === true &&
      //   props.assetData.type === 'Background_Image'
      // ) {
      //   showModalForEdit(true);
      // } else {
      event.preventDefault();
      event.stopPropagation();
      setActiveTabKey(Constant.THIRD_TAB_KEY);
      setChangeFileFlag(false);
      const intervalId = interval();
      setIntervalId(intervalId);
      setProgress(0);
      props.saveAsset(
        LibraryServices.getFormDataCover(asset, files),
        props.authToken,
        props.storyId,
        props.IsAssetEdit,
        props.assetData
      );
      setFinishPressed(true);
      setValidated(true);
      // }
    }
  };

  const handleUploadInfo = event => {
    event.preventDefault();
    event.stopPropagation();
    let uploadCnt = 0;
    for (let j = 0; j < assetFiles.length; j++) {
      if (assetFiles[j].isUpload === true) {
        uploadCnt++;
      }
    }
    setUploadCount(uploadCnt);
    const intervalId = interval();
    setIntervalId(intervalId);
    setProgress(0);

    setActiveTabKey(Constant.THIRD_TAB_KEY);
    props.UploadCover(
      LibraryServices.getUploadFormDataCover(assetFiles, fileNames),
      props.authToken,
      props.storyId
    );
    //uploadAsset
  };

  const handleTypeChange = (e, key) => {
    assetFiles[key].type = e.target.value;
    setRefreshList(!refreshList);
  };

  const handleNameChange = (e, key) => {
    assetFiles[key].name = e.target.value;
    let isUnique = LibraryServices.checkUniqueAssetName(
      props.assetList,
      assetFiles,
      e.target.value,
      key
    );

    if (isUnique === false) {
      assetFiles[key].isUnique = false;
    } else {
      assetFiles[key].isUnique = true;
    }

    if (new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(e.target.value) !== true) {
      assetFiles[key].isValid = false;
    } else {
      assetFiles[key].isValid = true;
    }

    setDummyAsset(false);
    setFocusElement(`asset-${key}`);
    setRefreshList(!refreshList);
  };

  const hideAssetFileList = () => {
    setShowConfirm(true);
  };

  const onHideClcik = () => {
    setShowConfirm(false);
    setFileNames([]);
    setAssetFiles([]);
    setShowUploadPopupEnableUpload(false);
  };

  const onCancelClick = () => {
    setShowConfirm(false);
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
          <LibraryMenu
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            viewAutoSaveFlag={props.viewAutoSaveFlag}
            resetViewAutoSaveFlag={props.resetViewAutoSaveFlag}
            resetViews={props.resetViews}
            setPlaceBackgroundProperties={props.setPlaceBackgroundProperties}
          />
        </Col>
        <Col className="col-8">
          <ListAsset
            handleUploadClick={() => handleUploadClick()}
            assetList={LibraryServices.filterAssetList(
              props.assetList,
              filters,
              sorting
            )}
            handleFilterClick={handleFilterClick}
            filters={filters}
            loading={props.loading}
            showLockModal={props.showLockModal}
            handleSorting={() => handleSorting()}
            sorting={sorting}
            showModal={props.showDeleteModal}
            deleteConfirm={props.deleteConfirm}
            handleShowDeleteModal={handleShowDeleteModal}
            handleHideDeleteModal={handleHideDeleteModal}
            handleDeleteAsset={handleDeleteAsset}
            assetInfoList={props.assetInfoList}
            deleteAsset={deleteAsset}
            IsAssetEdit={props.IsAssetEdit}
            storyTitle={props.storyTitle}
            handleEditAssetClick={handleEditAssetClick}
            setFileNames={setFileNames}
            maxFileError={maxFileError}
            setMaxFileError={setMaxFileError}
            setShowUploadPopupEnableUpload={setShowUploadPopupEnableUpload}
            handleChangeActiveCover={handleChangeActiveCover}
          />
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
      {fileNames.length > 0 && props.isUploadAsset === false && (
        <ListFile
          fileNames={fileNames}
          assetFiles={assetFiles}
          refreshList={refreshList}
          onHide={hideAssetFileList}
          handleAssetFileDelete={handleAssetFileDelete}
          handleNameChange={handleNameChange}
          handleTypeChange={handleTypeChange}
          handleUploadInfo={event => handleUploadInfo(event)}
          enableUpload={enableUpload}
          setEnableUpload={setEnableUpload}
          setShowUploadPopupEnableUpload={setShowUploadPopupEnableUpload}
          loading={loading}
          focusElement={focusElement}
          dummyAsset={dummyAsset}
          setDummyAsset={setDummyAsset}
          show={show}
          setShow={setShow}
          target={target}
        />
      )}
      {props.showProgress === true && (
        <Progress
          showModal={props.showProgress}
          progress={progress}
          uploadCount={uploadCount}
        />
      )}

      {showConfirm === true && (
        <ConfirmationWindow
          show={showConfirm}
          onCancelClick={onCancelClick}
          onHideClcik={onHideClcik}
        />
      )}

      {props.showModal === true && (
        <AddAssetModal
          showModal={props.showModal}
          onHideModal={() => props.handleHideModal()}
          continuetoaddinfoclass={props.IsAssetEdit ? false : true}
          activeTabKey={LibraryServices.getAssetActiveTabKey(
            props.assetStatus,
            activeTabKey,
            props.handleHideModal,
            intervalId
          )}
          validated={validated}
          setValidated={setValidated}
          showclearfile={
            props.IsAssetEdit === true ? true.toString() : false.toString()
          }
          asset={asset}
          setAsset={data => setAsset(data)}
          setActiveTabKey={data => setActiveTabKey(data)}
          progress={progress}
          handleSaveInfo={event => handleSaveInfo(event)}
          files={files}
          setFiles={data => setFiles(data)}
          handleRollbackAsset={() => handleRollbackAsset()}
          IsAssetEdit={props.IsAssetEdit}
          handleEditAsset={handleEditAsset}
          assetList={props.assetList}
          assetInfoListEdit={props.assetInfoListEdit}
          assetData={props.assetData}
          setChangeFileFlag={setChangeFileFlag}
          uploadCount={uploadCount}
          finishPressed={finishPressed}
        />
      )}

      {props.showLockModal === true && (
        <LockedAssets
          showModal={props.showLockModal}
          lockedAssetData={props.lockedAssetData}
          handleHideAssetLockModal={handleHideAssetLockModal}
          type="asset"
        />
      )}
      {modalForEdit === true && (
        <EditAssetListInfo
          showModal={modalForEdit}
          closeModalOperation={closeModalOperation}
          assetInfoList={props.assetInfoListEdit}
          deleteAsset={deleteAsset}
          pressOkay={pressOkay}
          asseDataType={props.assetData}
        />
      )}
    </div>
  );
}

export const mapStateToProps = state => {
  return {
    asset: state.LibraryReducer.asset,
    assetStatus: state.LibraryReducer.assetStatus,
    authToken: state.LoginReducer.authToken,
    assetList: state.LibraryReducer.coverList,
    showModal: state.LibraryReducer.showModal,
    loading: state.LibraryReducer.loading,
    storyId: state.StoryReducer.storyId,
    selectedMenu: state.LibraryReducer.selectedMenu,
    storyTitle: state.StoryReducer.storyTitle,
    assetInfoList: state.LibraryReducer.assetInfoList,
    deleteConfirm: state.LibraryReducer.deleteConfirm,
    showDeleteModal: state.LibraryReducer.showDeleteModal,
    assetData: state.LibraryReducer.assetData,
    IsAssetEdit: state.LibraryReducer.IsAssetEdit,
    lockedAssetData: state.LibraryReducer.lockedAssetData,
    showLockModal: state.LibraryReducer.showLockModal,
    tutorials: state.LoginReducer.tutorials,
    assetInfoListEdit: state.LibraryReducer.coverInfoListEdit,
    selectedMenu: state.LibraryReducer.selectedMenu,
    viewAutoSaveFlag: state.PlaceBackgroundReducer.autoSaveFlag,

    isUploadAsset: state.LibraryReducer.isUploadAsset,

    showProgress: state.LibraryReducer.showProgress
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    saveAsset: (asset, authToken, storyId, IsAssetEdit, assetData) =>
      dispatch(
        LibraryActions.setCover(
          asset,
          authToken,
          storyId,
          IsAssetEdit,
          assetData
        )
      ),
    UploadCover: (asset, authToken, storyId) =>
      dispatch(LibraryActions.uploadCover(asset, authToken, storyId)),
    getCoverAsset: (authToken, storyId, assetId) =>
      dispatch(LibraryActions.getCoverAssetById(authToken, storyId, assetId)),

    fetchCovers: (authToken, storyId) =>
      dispatch(LibraryActions.setCoverList(authToken, storyId)),
    handleShowModal: () => dispatch(LibraryActions.setShowModalTrue()),
    handleHideModal: () => dispatch(LibraryActions.setShowModalFalse()),
    handleResetLockModal: () => dispatch(LibraryActions.handleResetLockModal()),
    handleHideAssetModal: () =>
      dispatch(LibraryActions.setShowModalDeleteFalse()),
    handleRollbackAsset: (name, authToken) =>
      dispatch(LibraryActions.setRollbackAsset(name, authToken)),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    getCoverDelete: (storyId, authToken, assetId) =>
      dispatch(LibraryActions.getCoverDelete(storyId, authToken, assetId)),
    getCoverEditInfo: (storyId, authToken, assetId) =>
      dispatch(LibraryActions.getAssetEditInfo(storyId, authToken, assetId)),
    deleteCover: (storyId, authToken, assetId) =>
      dispatch(LibraryActions.deleteCover(storyId, authToken, assetId)),
    resetViews: () => dispatch(PlaceBackgroundActions.resetPlaceBackground()),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    setAutoSaveFlag: flag =>
      dispatch(PlaceBackgroundActions.setAutoSaveFlag(flag)),
    resetViewAutoSaveFlag: () =>
      dispatch(PlaceBackgroundActions.resetAutoSaveFlag()),
    setPlaceBackgroundProperties: (key, value) =>
      dispatch(PlaceBackgroundActions.setBackgroundProperties(key, value)),
    postActiveCover: (storyId, authToken, assetId, value) =>
      dispatch(
        LibraryActions.activeCoverChange(storyId, authToken, assetId, value)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverImage);

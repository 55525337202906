import React from 'react';
import * as translations from './translations';
import { Form } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import './../style/style.css';
import * as Constant from './constant';

function AddInfo(props) {
  const {
    validated,
    handleSaveInfo,
    handleFormChange,
    handleValidStoryName,
    storyNameValidity,
    editStory,
    storyDetails,
    title,
    description,
    generes
  } = props;

  return (
    <LibraryStyle.addInfoContainer>
      <Form
        id="add-info-form"
        noValidate
        validated={validated}
        onSubmit={e => handleSaveInfo(e)}
      >
        <div className="mt-3">
          <Form.Group>
            <Form.Label>{translations.STORY_TITLE}</Form.Label>
            <Form.Control
              type="text"
              name="title"
              autocomplete="off"
              onBlur={handleValidStoryName}
              onChange={handleFormChange}
              onKeyUp={handleFormChange}
              maxlength="30"
              minlength="1"
              defaultValue={
                editStory && title === '' ? storyDetails.title : title
              }
              disabled={storyDetails.id === 1 ? 'disabled' : ''}
              required
              pattern={Constant.STORY_NAME_PATTERN}
            />
            <Form.Control.Feedback type="invalid">
              {!storyNameValidity && translations.STORY_NAME_REQ_MESSAGE}
            </Form.Control.Feedback>

            {storyNameValidity && title !== '' && (
              <span className="invalid-name">
                {translations.STORY_NAME_ERROR_MESSAGE}
                {storyNameValidity}
              </span>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {translations.DESCRIPTION}
              <Form.Text className="text-muted">
                {translations.MAXIMUM}
              </Form.Text>
            </Form.Label>
            <Form.Control
              as="textarea"
              type="textarea"
              className="textAreaResize"
              rows="5"
              required
              maxlength="645"
              minlength="1"
              autocomplete="off"
              pattern={Constant.STORY_DESCRIPTION_PATTERN}
              name="description"
              value={description}
              onChange={handleFormChange}
              onKeyUp={handleFormChange}
            />
            <Form.Control.Feedback type="invalid">
              {translations.DESCRIPTION_ERROR_MESSAGE}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Form>
    </LibraryStyle.addInfoContainer>
  );
}

export default AddInfo;

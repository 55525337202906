import React from 'react';
import { NavLink } from 'react-router-dom';
import { MyAccountMenuStyle } from './style/my-account-menu-style';
import * as Config from './../../config/app.config';

const MyAccountMenu = ({
  selectedMenu,
  setSelectedMenu,
  roleAdmin,
  handleDeleteMyAccount
}) => {
  const handleMenuSelection = menuName => {
    setSelectedMenu(menuName);
  };

  return (
    <MyAccountMenuStyle.libraryMenu>
      {roleAdmin !== '' && roleAdmin !== null && (
        <MyAccountMenuStyle.libraryMenuList>
          <MyAccountMenuStyle.libraryMenuListItem
            selectedMenu={selectedMenu === 'myaccount' ? true : false}
          >
            <NavLink
              variant="link"
              exact
              onClick={() => handleMenuSelection('myaccount')}
              to="myaccount"
              className={
                selectedMenu === 'myaccount' ? 'menu-enabled' : 'menu-disabled'
              }
            >
              {selectedMenu === 'myaccount' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'user_circle_active.svg'}
                  className="menu-icon"
                />
              )}
              {selectedMenu !== 'myaccount' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'user_circle.svg'}
                  className="menu-icon"
                />
              )}
              Account Details
            </NavLink>
          </MyAccountMenuStyle.libraryMenuListItem>

          <MyAccountMenuStyle.libraryMenuListItem
            selectedMenu={selectedMenu === 'changepassword' ? true : false}
          >
            <NavLink
              variant="link"
              exact
              onClick={() => handleMenuSelection('changepassword')}
              to="changepassword"
              className={
                selectedMenu === 'changepassword'
                  ? 'menu-enabled'
                  : 'menu-disabled'
              }
            >
              {selectedMenu === 'changepassword' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'password-lock-icon-active.svg'}
                  className="menu-icon"
                />
              )}
              {selectedMenu !== 'changepassword' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'password-lock-icon.svg'}
                  className="menu-icon"
                />
              )}
              Password
            </NavLink>
          </MyAccountMenuStyle.libraryMenuListItem>

          <MyAccountMenuStyle.libraryMenuListItem
            selectedMenu={selectedMenu === 'settings' ? true : false}
          >
            <NavLink
              variant="link"
              exact
              onClick={() => handleMenuSelection('settings')}
              to="settings"
              className={
                selectedMenu === 'settings' ? 'menu-enabled' : 'menu-disabled'
              }
            >
              {selectedMenu === 'settings' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'ic_settings_24px.svg'}
                  className="menu-icon"
                />
              )}
              {selectedMenu !== 'settings' && (
                <img
                  alt=""
                  src={Config.IMAGE_ICON_PATH + 'ic_settings_24px-disabled.svg'}
                  className="menu-icon"
                />
              )}
              Settings
            </NavLink>
          </MyAccountMenuStyle.libraryMenuListItem>
        </MyAccountMenuStyle.libraryMenuList>
      )}
      {roleAdmin !== '[ROLE_ADMIN]' && (
        <div onClick={() => handleDeleteMyAccount()} className="delete-account">
          Delete your account
        </div>
      )}
    </MyAccountMenuStyle.libraryMenu>
  );
};

export default MyAccountMenu;

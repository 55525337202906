import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const Users = styled.div`
  
  margin-left: 233px;
  margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  margin-bottom:5px;
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
`;

export const Heading = styled.div`
  padding: 4px 0 3px 25px;
  
  font-size: 24px;
  padding-left: 25px;
  width: 100%;
  /* height: 29px; */
  text-align: left;
  font: PT Sans;
  letter-spacing: 0;
  color: #303030;
`;

export const ListAccountWrapper = styled.div`
  
`;

export const MainSection = styled.div`
  margin-top: 8px;
  left: 234px;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  opacity: 1;
  overflow-y:auto;
  overflow-x:hidden;
  height:calc(100vh - 160px);
`;

export const CreateAccount = styled.div`
  margin: 30px 0 0 30px;
  .button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid ${colors.primary_green};
    border-radius: 40px;
    opacity: 1;
    color: ${colors.primary_green};
     
    font-size: 16px;
    text-align: center;
  }
`;
export const Modal = styled.div`
  
  .modal-title {
   
    font-size: 24px;
    color: #303030;
    letter-spacing: 0;
  }
  .btn {
    background: ${colors.primary_green} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 4px #059d7f4a;
    border-radius: 20px;
    border: 1px solid ${colors.primary_green};
    color: #ffffff;
  }
  .modal-footer{
    border:none;
  }
`;

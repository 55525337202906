import * as CommandActionTypes from '../actions/command-action-types';

export const initialState = {
  commandCharacter: [],
  commandDiagloue: [],
  commandBackground: [],
  commandMedia: [],
  commandPlayMusic: [],
  commandLevelErrors: []
}

export const CommandReducer = (state = initialState, action) => {

  switch (action.type) {

    case CommandActionTypes.GET_COMMAND_CHARACTER:
      return {
        ...state,
      };

    case CommandActionTypes.GET_COMMAND_CHARACTER_FAIL:
      return {
        ...state,
        commandLevelErrors: action.errors
      };

    case CommandActionTypes.GET_COMMAND_CHARACTER_SUCCESS:
      return {
        ...state,
        commandCharacter: action.commandCharacter,
      };

    case CommandActionTypes.GET_COMMAND_DIALOGUE:
        return {
          ...state,
        };
      
    case CommandActionTypes.GET_COMMAND_DIALOGUE_FAIL:
      return {
        ...state,
        commandLevelErrors: action.errors
      };

    case CommandActionTypes.GET_COMMAND_DIALOGUE_SUCCESS:
      return {
        ...state,
        commandDiagloue: action.commandDiagloue,
      };

    case CommandActionTypes.GET_COMMAND_BACKGROUND:
      return {
        ...state,
      };

    case CommandActionTypes.GET_COMMAND_BACKGROUND_FAIL:
      return {
        ...state,
        commandLevelErrors: action.errors
      };

    case CommandActionTypes.GET_COMMAND_BACKGROUND_SUCCESS:
      return {
        ...state,
        commandBackground: action.commandBackground,

      };

    case CommandActionTypes.GET_COMMAND_PLAY_SOUND:
        return {
          ...state,
        };
    
    case CommandActionTypes.GET_COMMAND_PLAY_SOUND_FAIL:
      return {
        ...state,
        commandLevelErrors: action.errors
      };

    case CommandActionTypes.GET_COMMAND_PLAY_SOUND_SUCCESS:
      return {
        ...state,
        commandMedia: action.commandMedia,
      };
    
    case CommandActionTypes.GET_COMMAND_PLAY_MUSIC:
        return {
          ...state,
        };
    
    case CommandActionTypes.GET_COMMAND_PLAY_MUSIC_FAIL:
      return {
        ...state,
        commandLevelErrors: action.errors
      };

    case CommandActionTypes.GET_COMMAND_PLAY_MUSIC_SUCCESS:
      return {
        ...state,
        commandPlayMusic: action.commandPlayMusic,
      };
    default:
      return {
        ...state
      };
  }
};

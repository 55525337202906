import React, { useRef, useEffect, useState } from 'react';

import AceEditor from 'react-ace';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CommandParser from './command-parser';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-elixir';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-github';
import * as Constant from './../../../common/constants';
import * as commandPanelStyle from '../../../pages/script-writer/style';
import 'ace-builds/src-noconflict/mode-javascript';
import { EditorService } from './constants';
import CharacterInfo from './widegts/character-info';
import * as Constants from './../../../common/constants';
import TextEditorHeader from './text-editor-head';
import TextEditorFooter from './text-editor-footer';
import SearchBox from 'ace-builds/src-noconflict/ext-searchbox';
import FindAndReplace from './find-and-replace';

import VariableWindow from './widegts/variable-window/variable-window';
import ErrorWindow from './widegts/variable-window/error-window';

import IdleTimer from 'react-idle-timer';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import FontFamily from './font-family';
import GrammerModal from './grammer-modal';
import { fonts } from '../../../common/styles/variables';
import { setFontSetting } from '../../../saga/api/accounts-api';

// import { search } from "core-js/fn/symbol";

const AceNewCommand = props => {
  const [localValidateCheck, setlocalValidateCheck] = useState(false);
  //const [saveButtonEnable, setSaveButtonEnable] = useState(false);

  const [cursorLastPositionFlag, setCursorLastPositionFlag] = useState(false);

  const [spellCheckerOn, setSpellCheckerOn] = useState(false);
  const [showModalGrammer, setShowModalGrammer] = useState(false);

  const [highlightHint, setHighlightHint] = useState({
    command: '',
    parameter: ''
  });

  //Edit, PreviewICon
  const previewIconState = true;
  const editIconState = true;

  //Variable Window
  const [showHideVariableState, setShowHideVariableState] = useState(false);
  const [
    showHideVariableStateClicked,
    setShowHideVariableStateClicked
  ] = useState(false);
  // const [localChangeOccurFlag, setLocalChangeOccurFlag] = useState(false);
  const [changeForcelyPreviewScroll, setChangeForcelyPreviewScroll] = useState(
    false
  );

  //Find and Replace
  const [findReplace, setFindReplace] = useState(false);
  const [valueFind, setValueFind] = useState('');
  const [valueReplace, setValueReplace] = useState('');
  const [searchWholeWord, setSearchWholeWord] = useState(false);
  const [searchCaseSensitive, setSearchCaseSensitive] = useState(false);
  const [searchRegExp, setSearchRegExp] = useState(false);

  // const [resetIntervalTime, setResetIntervalTime] = useState(false);
  // const [interval, setIntervalSave] = useState(null);
  // const

  const [fontFamily, setFontFamily] = useState(false);
  const [fontSize, setFontSize] = useState(props.fontSize);
  const [font, setFont] = useState(props.fontFamily);

  const aceEditor = useRef(null);
  const idletimeSave = useRef(null);

  if (document.getElementById('texteditorsave')) {
    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
    if ((OSName = 'Mac/iOS')) {
      document.getElementById('texteditorsave').onkeydown = function(e) {
        var key = e.key;
        var cmd_held = e.metaKey;
        if (e.keyCode == 70 && e.metaKey) {
          setFindReplace(true);
          document.getElementById('search-for').focus();
          e.preventDefault();
        }
      };
    } else {
      document.getElementById('texteditorsave').onkeydown = function(e) {
        var key = e.key;
        var cmd_held = e.metaKey;
        if (e.ctrlKey && e.which == 70) {
          setFindReplace(true);
          document.getElementById('search-for').focus();
          e.preventDefault();
        }
      };
    }
  }

  let currentRow = '';
  // let currentTop = 150;
  let columnEditor = 0;
  let rowErrors = [];
  let uniqueArray = [];
  let cmd = '';
  let value = null;
  let rowObj = {};
  let whichPartParamertes = 0;
  let wordList = props.commandSetFirstSet;
  let staticWordCompleter = '';
  // let interval = undefined;

  const MAX_COUNT = 999;

  const [searchCounter, setSearchCounter] = useState('0 of 0');
  const [showScriptErrorWindow, setShowScriptErrorWindow] = useState(false);

  const showHideVariableWindow = variableValue => {
    setShowScriptErrorWindow(false);
    setShowHideVariableStateClicked(false);

    setTimeout(() => setShowHideVariableState(!variableValue), 300);
    setTimeout(() => props.variableWindowClicked(!variableValue), 100);
  };

  const showHideErrorWindow = errorValue => {
    setShowHideVariableState(false);
    setShowHideVariableStateClicked(!errorValue);
    setTimeout(() => props.variableWindowClicked(false), 100);
    setTimeout(() => setShowScriptErrorWindow(!errorValue), 300);
  };

  const handleClickGotoLine = (event, errorLine) => {
    aceEditor.current.editor.gotoLine(errorLine, 0);
  };

  const setHighlightHintCall = _.debounce((command, parameter, optional = false, defaultValue) => {
    if (optional) {
      var defaultValueStr = defaultValue == '' ? '' : "Default: ".concat(defaultValue);
      parameter = "[ " + parameter + " ] " + defaultValueStr
    }


    if (
      command !== setHighlightHint.command ||
      setHighlightHint.parameter !== parameter
    ) {
      setHighlightHint({
        command: command,
        parameter: parameter
      });
    }
  }, 500);

  const setHighlightHintCallOnChange = (command, parameter, optional = false, defaultValue) => {
    if (optional) {
      var defaultValueStr = defaultValue == '' ? '' : "Default: ".concat(defaultValue);
      parameter = "[ " + parameter + " ] " + defaultValueStr
    }
    if (
      command !== setHighlightHint.command ||
      setHighlightHint.parameter !== parameter
    ) {
      setHighlightHint({
        command: command,
        parameter: parameter
      });
    }
  };

  const handleTabCount = (tablist, tillCount) => {
    let tabs = '\t';
    for (let i = 0; i < tillCount; i++) {
      tabs = `${tabs}\t`;
    }
    return tabs;
  };

  const closePopup = () => {
    setShowModalGrammer(false);
  };

  const handleSaveClick = (e = false) => {
    // getDifferenceInMinutes(props.lastSavedTime, new Date("YYYY-MM-DDTHH:MM:SSZ"))
    props.handleSaveStory(e);
    props.setComamndSavePanel(null);
    aceEditor.current.editor.focus(true);
    props.setSaveButtonEnable(false);
    props.setScriptChanged(false);

    if (props.localChangeOccurFlag === true) {
      props.autopopupSave(false);
      props.setLocalChangeOccurFlag(false);
    }
    if (changeForcelyPreviewScroll === true) {
      props.setPreviewScroll(true);
      setChangeForcelyPreviewScroll(false);
    }
  };

  const updateCounter = () => {
    var editor = aceEditor.current.editor;
    var regex = editor.$search.$options.re;
    var all = 0;
    var before = 0;
    if (regex) {
      var value = editor.searchRange
        ? editor.session.getTextRange(editor.searchRange)
        : editor.getValue();

      var offset = editor.session.doc.positionToIndex(editor.selection.anchor);
      if (editor.searchRange)
        offset -= editor.session.doc.positionToIndex(editor.searchRange.start);

      var last = (regex.lastIndex = 0);
      var m;
      while ((m = regex.exec(value))) {
        all++;
        last = m.index;
        if (last <= offset) before++;
        if (all > MAX_COUNT) break;
        if (!m[0]) {
          regex.lastIndex = last += 1;
          if (last >= value.length) break;
        }
      }
    }
    setSearchCounter(
      before + ' of ' + (all > MAX_COUNT ? MAX_COUNT + '+' : all)
    );
  };

  const onClickSearch = () => {
    if (aceEditor && aceEditor.current) {
      aceEditor.current.editor.find(valueFind, {
        wrap: true,
        caseSensitive: searchCaseSensitive,
        wholeWord: searchWholeWord,
        regExp: searchRegExp
      });
      updateCounter();
    }
  };

  const onClickReplace = singleOrAll => {
    if (singleOrAll === 'single') {
      aceEditor.current.editor.replace(valueReplace);
    } else {
      aceEditor.current.editor.replaceAll(valueReplace);
    }
    onClickSearch();
    updateCounter();
  };

  const GrammarOnClickReplace = (text, range, ignore=false) => {
    aceEditor.current.editor.session.replace(
      {
        end: { row: range.lineNumber - 1, column: range.toPos },
        start: { row: range.lineNumber - 1, column: range.fromPos }
      },
      text
    );

    let allMarkersG = aceEditor.current.editor.session.getMarkers();

    Object.entries(allMarkersG).forEach(([key, value]) => {
      if (value.range) {
        if (value.range.end.row === range.lineNumber - 1) {
          aceEditor.current.editor.session.removeMarker(value.id);
        }
      }
    });

    if(ignore === false){
      props.spellCheckerCall();
    } else{
      props.addToIgnoreCall(text);
    }

    // closePopup();
  };

  const onChangeFindValue = event => {
    setValueFind(event.target.value);
    setSearchCounter('0 of 0');
  };

  const onChangeReplaceValue = event => {
    setValueReplace(event.target.value);
  };

  const closeFindReplace = () => {
    setFindReplace(false);
    setValueFind(null);
    setValueReplace(null);
    setSearchWholeWord(false);
    setSearchCaseSensitive(false);
    setSearchRegExp(false);
    setSearchCounter('0 of 0');
  };

  const handleOnAction = event => {
    if (props.saveButtonEnable === true) {
      handleSaveClick();
    }
  };

  const fontFamilyModal = () => {
    setFontFamily(true);
  };

  const fontChangeSave = () => {
    props.fontChangeSave(fontSize, font);
    setFontFamily(false);
  };

  const fontChange = (event, fontType) => {
    const valueFont = event.target.value;
    if (fontType == 'fontSize') {
      aceEditor.current.editor.setOptions({
        fontSize: `${valueFont}px`
      });
      setTimeout(() => {
        setFontSize(valueFont);
      }, 100);
    } else {
      aceEditor.current.editor.setOptions({
        fontFamily: `${valueFont}`
      });
      setTimeout(() => {
        setFont(valueFont);
      }, 100);
    }
  };

  const fontChangeCancel = () => {
    setTimeout(() => {
      setFontSize(props.fontSize);
    }, 100);

    setTimeout(() => {
      setFont(props.fontFamily);
    }, 100);
    setFontFamily(false);
    aceEditor.current.editor.setOptions({
      fontSize: `${props.fontSize}`,
      fontFamily: props.fontFamily
    });
  };

  const spellChecker = () => {
    if (spellCheckerOn === true) {
      let allMarkersRemove = aceEditor.current.editor.session.getMarkers();

      Object.entries(allMarkersRemove).forEach(([key, value]) => {
        if (value.clazz) {
          if (
            value.clazz === 'spellMarker' ||
            value.clazz === 'grammarMarker'
          ) {
            aceEditor.current.editor.session.removeMarker(value.id);
          }
        }
      });
    }
    setSpellCheckerOn(!spellCheckerOn);
    props.spellCheckerCall();
    closePopup();
  };

  const prevFindClickNextPrevious = findaction => {
    if (searchCounter !== '0 of 0') {
      switch (findaction) {
        case 'all':
          aceEditor.current.editor.findAll(valueFind, {
            skipCurrent: true,
            backwards: false,
            caseSensitive: searchCaseSensitive,
            wholeWord: searchWholeWord,
            regExp: searchRegExp
          });
          break;

        case 'previousFind':
          aceEditor.current.editor.find(valueFind, {
            skipCurrent: true,
            backwards: true,
            caseSensitive: searchCaseSensitive,
            wholeWord: searchWholeWord,
            regExp: searchRegExp
          });
          updateCounter();
          break;

        case 'nextFind':
          aceEditor.current.editor.find(valueFind, {
            skipCurrent: true,
            backwards: false,
            caseSensitive: searchCaseSensitive,
            wholeWord: searchWholeWord,
            regExp: searchRegExp
          });
          updateCounter();
          break;

        default:
          break;
      }
    }
  };

  const findAndReplaceParameters = conditionFindReplace => {
    switch (conditionFindReplace) {
      case 'wholeWord':
        setSearchWholeWord(!searchWholeWord);
        break;

      case 'caseSensitive':
        setSearchCaseSensitive(!searchCaseSensitive);
        break;

      case 'regExp':
        setSearchRegExp(!searchRegExp);
        break;

      default:
        break;
    }
  };

  if (
    props.commandListForScriptEditor !== null &&
    props.commandListForScriptEditor !== undefined &&
    props.commandListForScriptEditor.commands !== undefined
  ) {
    let ifCounter = 0;
    let defualtTabIndent = '\t';

    props.commandListForScriptEditor.commands.map((commands, index) => {
      const tabCountStart =
        commands.cmdText.length - commands.cmdText.trimLeft().length;
      const cmdObj = new CommandParser(commands.cmdText.trim(), tabCountStart);
      const commandTextIndentFirst =
        commands.cmdText.trimLeft() !== ''
          ? commands.cmdText.trimLeft()
          : `\t${commands.cmdText.trimLeft()}`;
      if (props.indentationTrue === true) {
        const whichPartParamertesObj = cmdObj.getCommandPartAt(2);
        if (
          commands.commandId === Constant.COMMAND_ADD_BLOCK ||
          whichPartParamertesObj.commandStart === 'block'
        ) {
          ifCounter = 0;
          value =
            value != null
              ? `${value}\n${commandTextIndentFirst}`
              : `${commandTextIndentFirst}`;
        } else if (commandTextIndentFirst) {
          switch (whichPartParamertesObj.commandStart) {
            case 'if':
              defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter);
              ifCounter += 1;
              break;
            case 'elseIf':
            case 'else':
              defualtTabIndent = handleTabCount(
                defualtTabIndent,
                ifCounter - 1
              );
              break;
            case 'end':
              ifCounter -= 1;
              defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter);
              break;
            default:
              defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter);
              break;
          }

          if (commands.commandId !== Constant.COMMAND_ADD_COMMENT) {
            value =
              value != null
                ? `${value}\n${defualtTabIndent}${commandTextIndentFirst}`
                : `\t${commandTextIndentFirst}`;
          } else {
            value =
              value != null
                ? `${value}\n${commands.cmdText}`
                : `${commands.cmdText}`;
          }
        }
      } else {
        value =
          value != null
            ? `${value}\n${commands.cmdText}`
            : `${commands.cmdText}`;
      }

      if (
        commands.errors &&
        commands.errors.length > 0 &&
        !props.textEditorloading
      ) {
        rowObj = {
          row: index,
          column: 0,
          text: `${commands.errors[0]}`, // Or the Json reply from the parser
          type: 'error' // also "warning" and "information"
        };
        rowErrors.push(rowObj);
      } else if (
        commands.warnings &&
        commands.warnings.length > 0 &&
        localValidateCheck &&
        !props.textEditorloading
      ) {
        rowObj = {
          row: index,
          column: 0,
          text: `${commands.warnings[0]}`, // Or the Json reply from the parser
          type: 'warning' // also "warning" and "information"
        };
        rowErrors.push(rowObj);
      }

      if (aceEditor && aceEditor.current) {
        // aceEditor.current.editor.$useWorker = false
        if (!props.textEditorloading) {
          uniqueArray = rowErrors.filter(
            (ele, ind) =>
              ind ===
              rowErrors.findIndex(
                elem => elem.row === ele.row && elem.text === ele.text
              )
          );
          if (localValidateCheck) {
            aceEditor.current.editor.getSession().setAnnotations(uniqueArray);
          }
        } else {
          aceEditor.current.editor.getSession().setAnnotations([]);
          aceEditor.current.editor.session.setUseWrapMode(true);
        }

        if (
          aceEditor.current.editor.session.$undoManager.$rev === 1 &&
          aceEditor.current.editor.session.$undoManager.$undoStack.length > 0
        ) {
          aceEditor.current.editor.session.$undoManager.$undoStack = [];
        }
        // aceEditor.current.editor.container.style.background="#8452E8"
        // aceEditor.current.editor.container.style.color="#FFFFFF"
      }

      if (props.previewScroll === true && props.play === true) {
        aceEditor.current.editor.gotoLine(parseInt(props.cursorPostion) + 1);
      }
      if (props.blockClickedEvent === true) {
        aceEditor.current.editor.gotoLine(parseInt(props.cursorPostion));
        props.setBlockClickedEvent(false);
      }
    });
    if (props.indentationTrue) {
      //props.onChange(value, 'place', null);
      // props.setTextEditorValidate(false)
      aceEditor.current.editor.moveCursorTo(props.cursorPostion, 250);
    }
    if (cursorLastPositionFlag === false) {
      let cursorLocation = localStorage.getItem(
        `cursorLocationLast_${props.storyId}_${props.currentSceneId}`
      );
      if (cursorLocation) {
        cursorLocation = JSON.parse(cursorLocation);
        if (aceEditor.current) {
          aceEditor.current.editor.gotoLine(
            parseInt(cursorLocation.row) + 1,
            parseInt(cursorLocation.column)
          );
        }

        setTimeout(
          () => props.changeCursorPosition(parseInt(cursorLocation.row)),
          500
        );
        // localStorage.removeItem('cursorLocationLast');
      }
      setTimeout(() => setCursorLastPositionFlag(true), 600);
    }
  }

  useEffect(() => {
    let ifCounteruse = 0;
    let defualtTabIndentuse = '\t';
    value = null;

    if (aceEditor.current.editor !== undefined) {
      // aceEditor.current.editor.$useWorker = false
      aceEditor.current.editor.session.setOption(
        'readOnly',
        props.isViewStory ||
          (props.commandListForScriptEditor &&
            props.commandListForScriptEditor.lockAcquired === true &&
            props.commandListForScriptEditor.lockAcquiredBy !==
              props.tokenDataUser.userId)
      );

      aceEditor.current.editor.session.setUseWrapMode(true);
      ///First remove the markers to avoid the cashing issue
      let allMarkers = aceEditor.current.editor.session.getMarkers();
      var cmdRange = ace.require('ace/range').Range;

      Object.entries(allMarkers).forEach(([key, value]) => {
        if (value.clazz) {
          if (
            value.clazz === 'commandTextBorder' ||
            value.clazz === 'myMarker' ||
            value.clazz === 'spellMarker' ||
            value.clazz === 'grammarMarker'
          ) {
            aceEditor.current.editor.session.removeMarker(value.id);
          }
        }
      });

      // if (cursorLastPositionFlag === false) {
      //   setTimeout(() => setCursorLastPositionFlag(true), 100)
      // }

      aceEditor.current.editor.setOptions({
        // editor options
        selectionStyle: 'line', // “line”|“text”
        highlightActiveLine: true, // boolean
        highlightSelectedWord: true, // boolean
        mergeUndoDeltas: true, // false|true|“always”
        behavioursEnabled: true, // boolean: true if enable custom behaviours
        wrapBehavioursEnabled: true, // boolean
        autoScrollEditorIntoView: undefined, // boolean: this is needed if editor is inside scrollable page
        keyboardHandler: null, // function: handle custom keyboard events
        useWrapMode: true, // wrap text to view
        // wrap: true,
        indentedSoftWrap: true,
        // renderer options
        showPrintMargin: false, // boolean: true if show the vertical print margin
        printMarginColumn: 10, // number: number of columns for vertical print margin
        printMargin: undefined, // boolean | number: showPrintMargin | printMarginColumn
        showGutter: true, // boolean: true if show line gutter
        showFoldWidgets: true, // boolean: true if the fold lines should be shown ?
        showLineNumbers: true,
        highlightGutterLine: true, // boolean: true if the gutter line should be highlighted
        fontSize: `${props.fontSize}`, // number | string: set the font size to this many pixels
        fontFamily: props.fontFamily, // string: set the font-family css value
        maxLines: undefined, // number: set the maximum lines possible. This will make the editor height changes
        minLines: undefined, // number: set the minimum lines possible. This will make the editor height changes
        fixedWidthGutter: false, // boolean: true if the gutter should be fixed width
        theme: 'ace/theme/textmate', // theme string from ace/theme or custom?
        // mouseHandler options

        scrollSpeed: 2, // number: the scroll speed index
        dragDelay: 0, // number: the drag delay before drag starts. it's 150ms for mac by default
        dragEnabled: true, // boolean: enable dragging
        focus: true,
        focusTimeout: 0, // number: the focus delay before focus starts.
        tooltipFollowsMouse: true, // boolean: true if the gutter tooltip should follow mouse
        // session options
        firstLineNumber: 1, // number: the line number in first line
        newLineMode: 'auto', // “auto” | “unix” | “windows”
        useWorker: false, // boolean: true if use web worker for loading scripts
        useSoftTabs: true, // boolean: true if we want to use spaces than tabs
        tabSize: 4, // number
        displayIndentGuides: false,
        foldStyle: 'markbegin', // enum: 'manual'/'markbegin'/'markbeginend'.
        mode: 'ace/mode/text', // string: path to language mode
        cursorStart: 10,
        replace: true
      });

      wordList = props.commandSetFirstSet;
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption:
                  word.meta === 'Command' ? `${word.name}` : `${word.name}:`,
                value:
                  word.meta === 'Command' ? `${word.name}` : `${word.name}:`,
                meta: word.meta
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }

    if (aceEditor && aceEditor.current) {
      aceEditor.current.editor.session.setMode('ace/mode/text', function() {
        aceEditor.current.editor.session.$mode.$highlightRules.addRules({
          start: [
            {
              token: 'string',
              start: '"',
              end: '"',
              next: [
                {
                  token: 'constant.language.escape.lsl',
                  regex: /\\[tn"\\]/
                }
              ]
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)block\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)goto\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)endchapter\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)choice\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)choicetimer\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)tap\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)place\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)setoutfit\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)oshow\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)oreplace\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)replace\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)show\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)hide\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)showobj\b/
            },
            {
              token: 'characterSquare',
              regex: /^([\t]*|[\s]*)hideobj\b/
            },
            {
              token: 'mediaSquare',
              regex: /^([\t]*|[\s]*)music\b/
            },
            {
              token: 'mediaSquare',
              regex: /^([\t]*|[\s]*)sfx\b/
            },
            {
              token: 'mediaSquare',
              regex: /^([\t]*|[\s]*)stopmusic\b/
            },
            {
              token: 'viewSquare',
              regex: /^([\t]*|[\s]*)view\b/
            },
            {
              token: 'viewSquare',
              regex: /^([\t]*|[\s]*)moveto\b/
            },
            {
              token: 'mediaSquare',
              regex: /^([\t]*|[\s]*)volume\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)wait\b/
            },
            {
              token: 'dialogSqaure',
              regex: /(?:\s+)`.*`$/
            },
            {
              token: 'effectSquare',
              regex: /^([\t]*|[\s]*)shake\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)shownamedialog\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)endjuicyblock\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)setvar\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)if\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)elseIf\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)else\b/
            },
            {
              token: 'blockSquare',
              regex: /^([\t]*|[\s]*)end\b/
            },
            {
              token: 'commentSquare',
              regex: /^([\t]*|[\s]*)\/\/.*/
            }
          ]
        });

        // force recreation of tokenizer
        aceEditor.current.editor.session.$mode.$tokenizer = null;
        aceEditor.current.editor.session.bgTokenizer.setTokenizer(
          aceEditor.current.editor.session.$mode.getTokenizer()
        );
        // force re-highlight whole document
        aceEditor.current.editor.session.bgTokenizer.start(0);
      });
    }

    if (
      props.commandListForScriptEditor !== null &&
      props.commandListForScriptEditor !== undefined
    ) {
      props.commandListForScriptEditor.commands.map((commands, index) => {
        const tabCount =
          commands.cmdText.length - commands.cmdText.trimLeft().length;
        const cmdObj = new CommandParser(commands.cmdText.trim(), tabCount / 2);
        const whichPartParamertesObj = cmdObj.getCommandPartAt(2);
        if (props.indentationTrue === true) {
          const commandTextIndent =
            commands.cmdText.trimLeft() !== ''
              ? commands.cmdText.trimLeft()
              : `\t${commands.cmdText.trimLeft()}`;
          if (
            commands.commandId === Constant.COMMAND_ADD_BLOCK ||
            whichPartParamertesObj.commandStart === 'block'
          ) {
            value =
              value != null
                ? `${value}\n${commandTextIndent}`
                : `${commandTextIndent}`;
          } else if (commandTextIndent) {
            switch (whichPartParamertesObj.commandStart) {
              case 'if':
                defualtTabIndentuse = handleTabCount(
                  defualtTabIndentuse,
                  ifCounteruse
                );
                ifCounteruse += 1;
                break;

              case 'elseIf':
              case 'else':
                defualtTabIndentuse = handleTabCount(
                  defualtTabIndentuse,
                  ifCounteruse - 1
                );
                break;
              case 'end':
                ifCounteruse = ifCounteruse - 1;

                defualtTabIndentuse = handleTabCount(
                  defualtTabIndentuse,
                  ifCounteruse
                );
                break;
              default:
                break;
            }

            if (commands.commandId !== Constant.COMMAND_ADD_COMMENT) {
              value =
                value != null
                  ? `${value}\n${defualtTabIndentuse}${commandTextIndent}`
                  : `\t${commandTextIndent}`;
            } else {
              value =
                value != null
                  ? `${value}\n${commands.cmdText}`
                  : `\t${commands.cmdText}`;
            }
          }
        } else {
          value =
            value != null
              ? `${value}\n${commands.cmdText}`
              : `${commands.cmdText}`;
        }

        aceEditor.current.editor.session.setOption('useWorker', false);
        aceEditor.current.editor.commands.addCommand({
          name: 'find',
          bindKey: { win: 'Ctrl-f', mac: 'Ctrl-f' },
          exec: function() {
            setFindReplace(true);
          }
        });
        if (
          commands.errors &&
          commands.errors.length > 0 &&
          !props.textEditorloading
        ) {
          rowObj = {
            row: index,
            column: 0,
            text: commands.errors[0], // Or the Json reply from the parser
            type: 'error' // also "warning" and "information"
          };
          rowErrors.push(rowObj);

          ///add charcater rows for edit
          var Range = ace.require('ace/range').Range;

          aceEditor.current.editor.session.addMarker(
            new Range(
              index,
              commands.commandId === Constant.COMMAND_ADD_BLOCK ? 0 : 1,
              index,
              commands.cmdText.length
            ),
            'myMarker',
            true
          );
        } else if (
          commands.warnings &&
          commands.warnings.length > 0 &&
          localValidateCheck &&
          !props.textEditorloading
        ) {
          rowObj = {
            row: index,
            column: 0,
            text: commands.warnings[0], // Or the Json reply from the parser
            type: 'warning' // also "warning" and "information"
          };
          rowErrors.push(rowObj);

          ///add charcater rows for edit
          var Range = ace.require('ace/range').Range;

          aceEditor.current.editor.session.addMarker(
            new Range(
              index,
              commands.commandId === Constant.COMMAND_ADD_BLOCK ? 0 : 1,
              index,
              commands.cmdText.length
            ),
            'myMarker',
            true
          );
        }
        ///get command border color data////
        else {
          if (whichPartParamertesObj.partsBoundary) {
            if (
              commands.commandId !== Constant.COMMAND_ADD_DIALOGUE &&
              commands.commandId !== Constant.COMMAND_SAY_GENDERED &&
              whichPartParamertesObj.partsBoundary[1]
            ) {
              for (
                let i = 0;
                i < whichPartParamertesObj.partsBoundary.length;
                i++
              ) {
                if (i > 0) {
                  let startCol, endCol;
                  let tabAddStartEnd = ifCounteruse;
                  switch (commands.commandId) {
                    case Constant.COMMAND_ADD_BLOCK:
                      startCol =
                        whichPartParamertesObj.partsBoundary[i].begin + 1;
                      endCol = whichPartParamertesObj.partsBoundary[i].end;
                      break;

                    default:
                      startCol =
                        whichPartParamertesObj.partsBoundary[i].begin + 1;
                      endCol = whichPartParamertesObj.partsBoundary[i].end;
                      break;
                  }

                  aceEditor.current.editor.session.addMarker(
                    new cmdRange(index, startCol, index, endCol),
                    EditorService.getCommandColor(commands.commandId),
                    true
                  );
                }
              }
            }
          }
          ///get command border color data////
        }
      });
      if (aceEditor && aceEditor.current) {
        aceEditor.current.editor.session.setOption('useWorker', false);
        if (!props.textEditorloading) {
          uniqueArray = rowErrors.filter(
            (ele, ind) =>
              ind ===
              rowErrors.findIndex(
                elem => elem.row === ele.row && elem.text === ele.text
              )
          );
          if (localValidateCheck) {
            aceEditor.current.editor.getSession().setAnnotations(uniqueArray);
          }
        } else {
          aceEditor.current.editor.getSession().setAnnotations([]);
        }
      }

      if (props.indentationTrue) {
        // aceEditor.current.editor.moveCursorTo(props.commandListForScriptEditor.commands.length, 250)
        aceEditor.current.editor.moveCursorTo(props.cursorPostion, 250);
      }

      switch (props.comamndSavePanel) {
        case true:
          props.cursorPostion === null
            ? aceEditor.current.editor.moveCursorTo(1, 250)
            : aceEditor.current.editor.moveCursorTo(
                props.commandListForScriptEditor.commands.length,
                250
              );
          break;

        case false:
          aceEditor.current.editor.moveCursorTo(props.cursorPostion + 1, 250);
          break;

        case null:
          aceEditor.current.editor.moveCursorTo(props.cursorPostion, 250);
          break;
        default:
          // aceEditor.current.editor.moveCursorTo((props.cursorPostion), 250)
          break;
      }
    }
    if (findReplace !== true) {
      aceEditor.current.editor.focus(true);
    }

    if (props.scriptChanged !== false) {
      props.setSaveButtonEnable(true);
      var column = aceEditor.current.editor.session.getLine(
        props.cursorPostion + 1
      ).length;
      aceEditor.current.editor.moveCursorTo(props.cursorPostion + 1, column);
    }

    if (
      spellCheckerOn === true &&
      props.spellGrammar &&
      props.spellGrammar.length > 0
    ) {
      props.spellGrammar.map(spellGrammarIn => {
        aceEditor.current.editor.session.addMarker(
          new cmdRange(
            spellGrammarIn.lineNumber - 1,
            spellGrammarIn.fromPos,
            spellGrammarIn.lineNumber - 1,
            spellGrammarIn.toPos
          ),
          spellGrammarIn.misspelled === true ? 'spellMarker' : 'grammarMarker',
          true
        );
      });
      // aceEditor.current.editor.findAll('Eonia', {
      //   // skipCurrent: true,
      //   // backwards: false,
      //   // caseSensitive: searchCaseSensitive,
      //   wholeWord: true,
      //   regExp: true
      // });

      if (spellCheckerOn === true) {
        aceEditor.current.editor.on('dblclick', function() {
          setTimeout(() => {
            setShowModalGrammer(true);
          }, 10);
        });
      }
    }
  }, [
    aceEditor,
    props.commandListForScriptEditor,
    findReplace,
    props.scriptChanged,
    props.spellGrammar
  ]);

  const handleClickValidate = e => {
    if (value !== null) {
      props.onChange(value);
    }
    if (
      props.commandListForScriptEditor &&
      props.commandListForScriptEditor.lockAcquired === true
    ) {
      if (
        props.commandListForScriptEditor.lockAcquiredBy ===
        props.tokenDataUser.userId
      ) {
        props.handleSaveStory(e);
      }
    } else if (
      props.commandListForScriptEditor.lockAcquired === false &&
      props.textEditorValidate === false &&
      props.tokenDataUser.roles !== Constant.SNACK_USER_ADMIN &&
      props.tokenDataUser.roles !== Constant.SNACK_USER_EDITOR
    ) {
      props.handleSaveStory(e);
    }
    aceEditor.current.editor.focus(true);
    props.setTextEditorValidate(true);
    props.setSaveButtonEnable(false);
    props.setScriptChanged(false);
    setlocalValidateCheck(true);
    props.autopopupSave(false);

    if (props.flowchartHasErrors === false) {
      props.setShowScriptError(false);
    }
  };

  const onPreviewClick = () => {
    let topPos = aceEditor.current.editor.session.getScrollTop();
    let position = 0;
    if (props.cursorPostion > 20 && topPos == 0) {
      position = props.cursorPostion * 10;
    } else if (props.cursorPostion > 20 && topPos > 0) {
      let actualTop = topPos / 30;
      if (actualTop > 0) {
        let checkPos = actualTop > 30 ? actualTop : actualTop * 2.4;
        if (checkPos > 20) {
          position = 20 * 7;
        } else {
          position = checkPos * 10;
        }
      } else {
        position = (props.cursorPostion - actualTop) * 5;
      }
    } else {
      position = props.cursorPostion * 20;
    }
    props.showHideModal(props.cursorPostion, 0, 184 + position);
  };

  const onEditClick = () => {
    if (props.commandListForScriptEditor) {
      let commandId =
        props.commandListForScriptEditor.commands[props.cursorPostion]
          .commandId;

      props.editCommand(props.cursorPostion, commandId);
    }

    // window.scrollTo(0, 500);
  };

  const setAutocompleteCondtionalCommand = () => {
    if (
      props.variableListDTO !== undefined &&
      props.variableListDTO.length > 0
    ) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            props.variableListDTO.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: word.datatype
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setAutocompleteCondtionalCommandSetVar = selectedListofVariables => {
    if (
      selectedListofVariables !== undefined &&
      selectedListofVariables.length > 0
    ) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            selectedListofVariables.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: word.datatype
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setVariationWordList = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: word.meta
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };


  function removeDuplicates(arr) {
    const result = [];
    const duplicatesIndices = [];

    // Loop through each item in the original array
    arr.forEach((current, index) => {
      if (duplicatesIndices.includes(index)) return;

      result.push(current);

      // Loop through each other item on array after the current one
      for (
        let comparisonIndex = index + 1;
        comparisonIndex < arr.length;
        comparisonIndex++
      ) {
        const comparison = arr[comparisonIndex];
        const currentKeys = Object.keys(current);
        const comparisonKeys = Object.keys(comparison);

        // Check number of keys in objects
        if (currentKeys.length !== comparisonKeys.length) continue;

        // Check key names
        const currentKeysString = currentKeys
          .sort()
          .join('')
          .toLowerCase();
        const comparisonKeysString = comparisonKeys
          .sort()
          .join('')
          .toLowerCase();
        if (currentKeysString !== comparisonKeysString) continue;

        // Check values
        let valuesEqual = true;
        for (let i = 0; i < currentKeys.length; i++) {
          const key = currentKeys[i];
          if (current[key] !== comparison[key]) {
            valuesEqual = false;
            break;
          }
        }
        if (valuesEqual) duplicatesIndices.push(comparisonIndex);
      } // end for loop
    }); // end arr.forEach()

    return result;
  }

  const getWordList = wordList => {
    let firstList = wordList.map(function(word) {
      return {
        caption: word.characterName,
        value: word.characterName,
        meta: word.meta
      };
    });
    let secondList = wordList.map(function(word) {
      return {
        caption: word.characterLongName,
        value: word.characterLongName,
        meta: word.meta
      };
    });
    let finalList = firstList
      .concat(secondList)
      .filter(item => item.caption !== undefined);

    return removeDuplicates(finalList);
  };

  const setCharacterAutoComplete = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(null, getWordList(wordList));
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setCharacterOtherAutoComplete = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption: word.assetName,
                value: word.assetName,
                meta: word.meta
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setPlaceViewList = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: 'View'
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setMusicList = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: 'Music'
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const setCommandWordList = () => {
    let commandWordList = props.commandSetFirstSet;
    let commandStaticWordCompleter = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(
          null,
          // commandWordList.map(function (word) {
          //   return {
          //     caption:
          //       word.meta === "Command" ? `${word.name}` : `${word.characterName}:`,
          //     value: word.meta === "Command" ? `${word.name}` : `${word.characterName}:`,
          //     meta: word.meta
          //   };
          // })
          prepareCommandWordList(commandWordList)
        );
      }
    };
    aceEditor.current.editor.completers = [commandStaticWordCompleter];
  };

  const setBlocksWordList = () => {
    let commandWordListBlocks = props.avialableBlocks;
    let commandStaticWordCompleterBlocks = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(
          null,
          commandWordListBlocks.map(function(word) {
            return {
              caption: word.displayName,
              value: word.displayName,
              meta: 'Block-Name'
            };
          })
        );
      }
    };
    aceEditor.current.editor.completers = [commandStaticWordCompleterBlocks];
  };

  const setChapterWordList = wordList => {
    // let commandWordListBlocks = props.avialableBlocks;
    let commandStaticWordCompleterBlocks = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(
          null,
          wordList.map(function(word) {
            return {
              caption: word.name,
              value: `"${word.name}"`,
              meta: 'Next chapter'
            };
          })
        );
      }
    };
    aceEditor.current.editor.completers = [commandStaticWordCompleterBlocks];
  };

  const setNoAutocompleteList = wordList => {
    aceEditor.current.editor.completers = [];
  };

  const setAudioAutoComplete = () => {
    staticWordCompleter = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(
          null,
          Constant.COMMAND_SET_AUDIO_AUTOCOMPLETE.map(function(word) {
            return {
              caption: word,
              value: word,
              meta: 'Volume',
              score: 10 - word
            };
          })
        );
      }
    };
    aceEditor.current.editor.completers = [staticWordCompleter];
  };

  const setObjectListAutocomplete = wordList => {
    if (wordList !== undefined && wordList.length > 0) {
      staticWordCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function(word) {
              return {
                caption: word.name,
                value: word.name,
                meta: 'Object'
              };
            })
          );
        }
      };
      aceEditor.current.editor.completers = [staticWordCompleter];
    }
  };

  const onChange = (newValue, event) => {
    // setResetIntervalTime(true);

    if (props.textEditorValidate === true) {
      props.setTextEditorValidate(false);
    }

    if (props.indentButtonClick === true) {
      props.setIndentButtonClick(false);
    }

    if (props.previewScroll === true) {
      props.setPreviewScroll(false);
      setChangeForcelyPreviewScroll(true);
    }

    if (props.saveButtonEnable === false) {
      setTimeout(() => props.setSaveButtonEnable(true), 1000);
    }
    if (props.localChangeOccurFlag === false) {
      props.autopopupSave(true);
      props.setLocalChangeOccurFlag(true);
    }

    props.onChange(newValue, event.action, '');
  };

  const onCursorChange = (selection, event) => {
    currentRow = selection.cursor.document.$lines[selection.cursor.row];
    // const currentTop = 150 + selection.cursor.row;

    let tabCountRow = 0;

    if (
      props.play === false &&
      props.blockClickedEvent === false &&
      aceEditor.current
    ) {
      if (selection.cursor.row !== props.cursorPostion) {
        setTimeout(
          () => props.changeCursorPosition(selection.cursor.row),
          1000
        );
        if (cursorLastPositionFlag === false) {
          setTimeout(() => setCursorLastPositionFlag(true), 1000);
        }
      }
    }
    if (cursorLastPositionFlag === true) {
      localStorage.setItem(
        `cursorLocationLast_${props.storyId}_${props.currentSceneId}`,
        JSON.stringify({
          row: selection.cursor.row,
          column: selection.cursor.column
        })
      );
    }

    if (props.highlightBlock === true && selection.session.curOp.command.name) {
      props.changeCheckBoxHighlightBlock(selection.cursor.row);
    }

    if (currentRow) {
      currentRow = currentRow.replace(/^\s+/g, '');
    }

    columnEditor = selection.cursor.column;

    if (
      currentRow !== undefined &&
      currentRow.substring(0, 5) !== 'block' &&
      selection.cursor.document.$lines[selection.cursor.row][0] == ' '
    ) {
      columnEditor = columnEditor - 4;
      if (currentRow.match(new RegExp('\t', 'g')) !== null) {
        tabCountRow = currentRow.match(new RegExp('\t', 'g')).length - 2;
      }
    }

    aceEditor.current.editor.session.getAnnotations(uniqueArray);

    cmd = new CommandParser(currentRow, tabCountRow);
    whichPartParamertes = cmd.getCommandPartAt(columnEditor);
    if (whichPartParamertes) {
      switch (whichPartParamertes.commandStart) {
        case 'place':
          setHighlightHintCall('place', '');

          setNoAutocompleteList([]);
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('place', 'Character Name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              setHighlightHintCall('place', 'Character Expression', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              setHighlightHintCall('place', 'Character Outfit', true, '');

              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              setHighlightHintCall('place', 'Character Hair', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              setHighlightHintCall('place', 'Character Accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 6:
              setHighlightHintCall('place', 'Character Position', true, 'Middle');
              wordList = Constant.COMMAND_ENTER_POSITION;
              setVariationWordList(wordList);
              break;

            case 7:
              setHighlightHintCall('place', 'Character flip/no-flip', true, 'no-flip');
              wordList = Constant.COMMAND_ENTER_FLIP;
              setVariationWordList(wordList);
              break;

            case 8:
              setNoAutocompleteList([]);
              setHighlightHintCall('place', 'Character fade in duration', true, '0.3 seconds');
              break;

            case 9:
              setHighlightHintCall('place', 'Character appear from', true, 'None');
              wordList = Constant.COMMAND_OFF_POSITION;
              setVariationWordList(wordList);
              break;

            case 10:
              setNoAutocompleteList([]);
              setHighlightHintCall('place', 'Character slide in duration', true, '0 seconds');

              break;

            default:
              setHighlightHintCall('place', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case '_:':
          setHighlightHintCall('Add Dialogue', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else if (whichPartParamertes.partNumber === 1) {
            setHighlightHintCall('Add Dialogue', 'Dialogue Text');
            setNoAutocompleteList([]);
          } else {
            setNoAutocompleteList([]);
          }
          break;

        case 'hide':
          setHighlightHintCall('hide', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              wordList = props.characterListForScript;
              setHighlightHintCall('hide', 'Character Name');
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              setHighlightHintCall('hide', 'Transition', true, 'Fade-out');
              wordList = Constant.COMMAND_TRANSITION_EFFECT;
              setVariationWordList(wordList);
              break;

            case 3:
              setHighlightHintCall('hide', 'Duration', true, '0.3 seconds');
              wordList = Constant.COMMAND_TRANSITION_EFFECT;
              setNoAutocompleteList([]);
              break;

            default:
              setHighlightHintCall('hide', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'block':
          setNoAutocompleteList([]);

          setHighlightHintCall('block', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else if (whichPartParamertes.partNumber === 1) {
            setHighlightHintCall('block', 'Block name');
          } else {
            setHighlightHintCall('block', '');
          }

          break;

        case 'goto':
          setHighlightHintCall('goto', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('goto', 'Block name');
              setBlocksWordList();
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'choice':
          setHighlightHintCall('Choice', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('choice', 'Choice text');
              setNoAutocompleteList([]);
              break;

            case 2:
              setHighlightHintCall('choice', 'Destination block');
              setBlocksWordList();
              break;

            case 3:
              setHighlightHintCall('choice', 'Cost in game currency', true, '');
              setNoAutocompleteList([]);
              break;

            case 4:
              wordList = Constant.INTERACTABLE_CHOICE;
              setHighlightHintCall('choice', 'Interactable / Non-Interactable', true, 'Interactable');
              setVariationWordList(wordList);
              break;

            case 5:
              wordList = Constant.HIDESHOW_CHOICE;
              setHighlightHintCall(
                'Hide if visited',
                'hideifvisited / showifvisited', true, 'hideifvisited'
              );
              setVariationWordList(wordList);
              break;

            case 6:
              wordList = Constant.AUTO_AFFINITY_TYPE;
              setHighlightHintCall('choice', 'Affinity type', true, '');
              setVariationWordList(wordList);
              break;

            case 7:
              wordList = Constant.AUTO_AFFINITY_WEIGHT;
              setHighlightHintCall('choice', 'Affinity weight', true, '');
              setVariationWordList(wordList);
              break;

            default:
              wordList = Constant.AUTO_AFFINITY_WEIGHT;
              setHighlightHintCall('choice', '');
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'choicetimer':
          setHighlightHintCall('choicetimer', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;
            case 1:
              setHighlightHintCall('choicetimer', 'Destination name');
              setBlocksWordList();
              break;

            case 2:
              setHighlightHintCall('choicetimer', 'Wait up to');
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'shownamedialog':
          if (whichPartParamertes.partNumber === 0) {
            setHighlightHintCall('shownamedialog', '');
            setCommandWordList();
          } else {
            setHighlightHintCall('shownamedialog', '');
            setNoAutocompleteList([]);
          }
          break;

        case 'endjuicyblock':
          if (whichPartParamertes.partNumber === 0) {
            setHighlightHintCall('endjuicyblock', '');
            setCommandWordList();
          } else {
            setHighlightHintCall('endjuicyblock', '');
            setNoAutocompleteList([]);
          }
          break;

        case 'music':
          setHighlightHintCall('music', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('music', 'Music file');
              if (props.backgroundSongs.length > 0) {
                wordList = props.backgroundSongs;
                setMusicList(wordList);
              }
              break;

            case 2:
              setHighlightHintCall('music', 'Loop / no-loop', true, 'Loop');
              wordList = Constant.COMMAND_MUSIC_LOOP;
              setVariationWordList(wordList);
              break;

            case 3:
              setHighlightHintCall('music', 'Fade in duration', true, '1 Seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'stopmusic':
          setHighlightHintCall('stopmusic', '');
          if (whichPartParamertes.partNumber === 1) {
            setHighlightHintCall('stopmusic', 'Fade out effect');
            setNoAutocompleteList([]);
          }
          break;

        case 'sfx':
          setHighlightHintCall('sfx', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('sfx', 'Select sound');
              if (props.incidentalSongs.length > 0) {
                wordList = props.incidentalSongs;
                setMusicList(wordList);
              } else {
                setNoAutocompleteList([]);
              }
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'show':
          setHighlightHintCall('show', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              wordList = props.characterListForScript;
              setHighlightHintCall('show', 'character name');
              setCharacterAutoComplete(wordList);
              break;

            case 3:
              setHighlightHintCall('show', 'character outfits', true, '');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 2:
              setHighlightHintCall('show', 'character expressions', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              setHighlightHintCall('show', 'character hairs', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              setHighlightHintCall('show', 'character accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }

              break;

            case 6:
              setHighlightHintCall('show', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'replace':
          setNoAutocompleteList([]);
          setHighlightHintCall('replace', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('replace', 'Original character name');
              setCharacterAutoComplete(props.characterListForScript);
              break;

            case 2:
              setHighlightHintCall('replace', 'New character name');
              const characterListForScriptReplace = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterName &&
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterLongName
              );
              setCharacterAutoComplete(characterListForScriptReplace);
              break;

            case 4:
              setHighlightHintCall('replace', 'character outfits', true, '');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }

              break;

            case 3:
              setHighlightHintCall('replace', 'character expressions', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              setHighlightHintCall('replace', 'character hairs', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 6:
              setHighlightHintCall('replace', 'character accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 7:
              setHighlightHintCall('replace', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'view':
          // setpreviewIconState(true);
          // setEditIconState(true);
          setHighlightHintCall('view', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('view', 'View Name');
              wordList = props.views;
              setPlaceViewList(wordList);
              break;

            case 2:
              setHighlightHintCall('view', 'Fade into screen', true, '1 second');
              wordList = props.views;
              setPlaceViewList(wordList);
              setNoAutocompleteList([]);

              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'moveto':
          setHighlightHintCall('moveto', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('moveto', 'Destination view');
              wordList = props.views;
              setPlaceViewList(wordList);
              break;

            case 2:
              setHighlightHintCall('moveto', 'Transition Duration', true, '1 second');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'volume':
          setHighlightHintCall('volume', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('volume', 'volume');
              setAudioAutoComplete();
              break;

            case 2:
              setHighlightHintCall('volume', 'fade in time', true, '1 second');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'showobj':
        case 'hideobj':
          const parameterPass =
            whichPartParamertes.commandStart === 'showobj'
              ? 'showobj'
              : 'hideobj';
          setHighlightHintCall(parameterPass, '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setNoAutocompleteList([]);
              setHighlightHintCall(parameterPass, 'Object name');
              const wordlist = props.assetsAll.filter(
                item => item.type === Constants.ASSET_TYPES_IMAGES[1]
              );
              setObjectListAutocomplete(wordlist);
              break;

            case 2:
              setHighlightHintCall(parameterPass, 'Duration', true, '0.5 second');
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'tap':
          setHighlightHintCall('tap', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('tap', 'object name');
              setNoAutocompleteList([]);
              const wordlist = props.assetsAll.filter(
                item => item.type === Constants.ASSET_TYPES_IMAGES[1]
              );
              setObjectListAutocomplete(wordlist);
              break;

            case 2:
              setHighlightHintCall('tap', 'destination block');
              setBlocksWordList();
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'shake':
          // setEditIconState(true);
          setHighlightHintCall('shake', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('shake', 'Duration');
              setNoAutocompleteList([]);
              break;

            case 2:
              setHighlightHintCall('shake', 'X-axis');
              setNoAutocompleteList([]);
              break;

            case 3:
              setHighlightHintCall('shake', 'Y-axis');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'if':
        case 'elseIf':
          const paramtIfElse =
            whichPartParamertes.commandStart === 'if' ? 'if' : 'elseIf';
          setHighlightHintCall(paramtIfElse, '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall(paramtIfElse, 'Variable');
              setAutocompleteCondtionalCommand();
              break;

            case 2:
              setHighlightHintCall(paramtIfElse, 'Operator');
              wordList = Constant.OPERATOR_FOR_OTHER;
              const variableSelected = props.variableListDTO.filter(
                variable =>
                  whichPartParamertes.allCommandParamValue[1] === variable.name
              );
              if (variableSelected.length > 0) {
                if (
                  variableSelected[0].datatype === 'Boolean' ||
                  variableSelected[0].datatype === 'String'
                ) {
                  wordList = Constant.OPERATOR_FOR_BOOLEAN;
                }
                setVariationWordList(wordList);
              } else {
                setNoAutocompleteList([]);
              }
              break;

            case 3:
              setHighlightHintCall(paramtIfElse, 'Comparison value');
              break;

            default:
              setNoAutocompleteList([]);
          }
          break;

        case 'setvar':
          setHighlightHintCall('setvar', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setNoAutocompleteList([]);
              setHighlightHintCall('setvar', 'name');
              break;

            case 2:
              setHighlightHintCall('setvar', 'Operator');
              wordList = Constant.AUTOCOMPLETE_ALL_OPERATOR_SET_VAR;
              setVariationWordList(wordList);
              break;

            case 3:
              setHighlightHintCall('setvar', 'Value');
              if (
                !whichPartParamertes.allCommandParamValue[3].startsWith('"')
              ) {
                const opertorSelected =
                  whichPartParamertes.allCommandParamValue[2];
                switch (opertorSelected) {
                  case '=!':
                    const variableAutoCompleteBoolean = props.variableListDTO.filter(
                      variable => 'Boolean' === variable.datatype
                    );
                    if (variableAutoCompleteBoolean.length > 0) {
                      setAutocompleteCondtionalCommandSetVar(
                        variableAutoCompleteBoolean
                      );
                    } else {
                      setNoAutocompleteList([]);
                    }
                    break;

                  case '=':
                    setAutocompleteCondtionalCommand();
                    break;

                  case '+=':
                  case '-=':
                  case '*=':
                  case '/=':
                    const variableAutoCompleteIntFloat = props.variableListDTO.filter(
                      variable =>
                        'Integer' === variable.datatype ||
                        'Float' === variable.datatype
                    );
                    if (variableAutoCompleteIntFloat.length > 0) {
                      setAutocompleteCondtionalCommandSetVar(
                        variableAutoCompleteIntFloat
                      );
                    } else {
                      setNoAutocompleteList([]);
                    }
                    break;
                }
              } else {
                setNoAutocompleteList([]);
              }
              break;

            case 4:
              setHighlightHintCall('setvar', 'Variable type', true, 'Boolean');
              const opertorSelected =
                whichPartParamertes.allCommandParamValue[2];
              let dataTypeDefault = '';
              const variableSelectedForDataType = props.variableListDTO.filter(
                variable =>
                  variable.name === whichPartParamertes.allCommandParamValue[3]
              );
              if (variableSelectedForDataType.length > 0) {
                dataTypeDefault = variableSelectedForDataType[0].datatype;
                setVariationWordList([
                  {
                    name: dataTypeDefault,
                    meta: dataTypeDefault
                  }
                ]);
              } else {
                switch (opertorSelected) {
                  case '=!':
                    setVariationWordList(Constant.AUTOCOMPLETE_BOOLEAN);
                    break;

                  case '=':
                    wordList = [
                      ...Constant.AUTOCOMPLETE_BOOLEAN,
                      ...Constant.AUTOCOMPLETE_STRING,
                      ...Constant.AUTOCOMPLETE_FLOAT,
                      ...Constant.AUTOCOMPLETE_INT
                    ];
                    setVariationWordList(wordList);
                    break;

                  case '+=':
                  case '-=':
                  case '*=':
                  case '/=':
                    wordList = [
                      ...Constant.AUTOCOMPLETE_FLOAT,
                      ...Constant.AUTOCOMPLETE_INT
                    ];
                    setVariationWordList(wordList);
                    break;
                }
              }
              break;

            case 5:
              setHighlightHintCall('setvar', 'Cross-chapter / Private', true, 'Cross-chapter');
              setVariationWordList(Constant.AUTOCOMPLETE_CROSS_CHAPTER);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'endchapter':
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              setHighlightHintCall('endchapter', '');
              break;

            case 1:
              setVariationWordList(Constant.COMMAND_LAST_CHAPTER);
              setHighlightHintCall('endchapter', 'Last chapter');
              break;

            case 2:
              setNoAutocompleteList([]);
              if (whichPartParamertes.allCommandParamValue[1] == 'false') {
                const allStoryRoute = props.allStoryRoutes.filter(
                  routesSelected =>
                    routesSelected.id === props.currentCharacterRouteId
                );
                if (allStoryRoute.length > 0) {
                  setChapterWordList(
                    allStoryRoute[0].scenes.filter(
                      scene => scene.id !== props.currentSceneId
                    )
                  );
                }
              }
              setHighlightHintCall('endchapter', 'Next chapter');
              break;

            default:
              setHighlightHintCall('endchapter', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'wait':
          setNoAutocompleteList([]);
          // setpreviewIconState(false);
          // setEditIconState(true);
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              setHighlightHintCall('wait', '');
              break;

            case 1:
              setHighlightHintCall('wait', 'duration');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'saygendered':
          setNoAutocompleteList([]);
          setHighlightHintCall('saygendered', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              break;

            case 1:
              setHighlightHintCall('saygendered', 'Character or Game Narrator');
              const nonCharacterGenderedAutocomplete = [
                {
                  characterName: '_',
                  meta: 'Non-character'
                }
              ];
              let sayGenderedAutocomplete = props.characterListForScript;

              setCharacterAutoComplete([
                ...sayGenderedAutocomplete,
                ...nonCharacterGenderedAutocomplete
              ]);
              break;

            case 2:
              setHighlightHintCall('saygendered', 'Dialogue Text for she/her');
              setNoAutocompleteList([]);

              break;

            case 3:
              setHighlightHintCall('saygendered', 'Dialogue Text for he/him');
              setNoAutocompleteList([]);

              break;

            case 4:
              setHighlightHintCall(
                'saygendered',
                'Dialogue Text for they/them'
              );
              setNoAutocompleteList([]);

              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'setoutfit':
          setHighlightHintCall('setoutfit', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('setoutfit', 'character name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              setHighlightHintCall('setoutfit', 'character outfits');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              setHighlightHintCall('setoutfit', 'character hairs');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              setHighlightHintCall('setoutfit', 'character accessories');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'oshow':
          setHighlightHintCall('oshow', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('oshow', 'Character name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              setHighlightHintCall('oshow', 'Character expressions');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              setHighlightHintCall('oshow', 'Fade in time', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'oreplace':
          setNoAutocompleteList([]);
          setHighlightHintCall('oreplace', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setHighlightHintCall('oreplace', 'Original character name');
              setCharacterAutoComplete(props.characterListForScript);
              break;

            case 2:
              setHighlightHintCall('oreplace', 'New character name');
              const characterListForScriptReplace = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterName &&
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterLongName
              );
              setCharacterAutoComplete(characterListForScriptReplace);
              break;

            case 3:
              setHighlightHintCall('oreplace', 'Character expressions');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              setHighlightHintCall('oreplace', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        default:
          setHighlightHintCall(
            whichPartParamertes.commandStart === 'end'
              ? 'end'
              : whichPartParamertes.commandStart === 'else'
              ? 'else'
              : '',
            ''
          );
          // if (previewIconState === true) {
          //   // setpreviewIconState(false);
          // }
          // if (editIconState === true) {
          //   // setEditIconState(false);
          // }
          const commandListCharacterFound = props.characterListForScript.filter(
            character =>
              `${character.characterName}:` ===
                `${whichPartParamertes.allCommandParamValue[0]}` ||
              `${character.characterLongName}:` ===
                `${whichPartParamertes.allCommandParamValue[0]}`
          );
          if (commandListCharacterFound.length > 0) {
            setHighlightHintCall('Dialgoue', 'Dialogue Text');
            setNoAutocompleteList([]);
          }
          // if (commandListCharacterFound.length > 0) {
          //   // setEditIconState(true);
          // }
          if (
            whichPartParamertes.partNumber === 0 ||
            whichPartParamertes.allCommandParamValue[0].length < 4
          ) {
            setCommandWordList();
          } else {
            setNoAutocompleteList([]);
          }
          if (
            whichPartParamertes.commandStart !== undefined &&
            whichPartParamertes.commandStart.startsWith('//')
          ) {
            setNoAutocompleteList([]);
          }
          break;
      }
    }
  };

  const onCursorChangeDisableSave = (selection, event) => {
    currentRow = selection.cursor.document.$lines[selection.cursor.row];
    // const currentTop = 150 + selection.cursor.row;

    let tabCountRow = 0;

    if (
      props.play === false &&
      props.blockClickedEvent === false &&
      aceEditor.current
    ) {
      if (selection.cursor.row !== props.cursorPostion) {
        setTimeout(
          () => props.changeCursorPosition(selection.cursor.row),
          1000
        );
        if (cursorLastPositionFlag === false) {
          setTimeout(() => setCursorLastPositionFlag(true), 1000);
        }
      }
    }
    if (cursorLastPositionFlag === true) {
      localStorage.setItem(
        `cursorLocationLast_${props.storyId}_${props.currentSceneId}`,
        JSON.stringify({
          row: selection.cursor.row,
          column: selection.cursor.column
        })
      );
    }

    if (props.highlightBlock === true && selection.session.curOp.command.name) {
      props.changeCheckBoxHighlightBlock(selection.cursor.row);
    }

    if (currentRow) {
      currentRow = currentRow.replace(/^\s+/g, '');
    }

    columnEditor = selection.cursor.column;

    if (
      currentRow !== undefined &&
      currentRow.substring(0, 5) !== 'block' &&
      selection.cursor.document.$lines[selection.cursor.row][0] == ' '
    ) {
      columnEditor = columnEditor - 4;
      if (currentRow.match(new RegExp('\t', 'g')) !== null) {
        tabCountRow = currentRow.match(new RegExp('\t', 'g')).length - 2;
      }
    }

    aceEditor.current.editor.session.getAnnotations(uniqueArray);

    cmd = new CommandParser(currentRow, tabCountRow);
    whichPartParamertes = cmd.getCommandPartAt(columnEditor);
    if (whichPartParamertes) {
      switch (whichPartParamertes.commandStart) {
        case 'place':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('place', '')
            : setHighlightHintCall('place', '');

          setNoAutocompleteList([]);
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Name')
                : setHighlightHintCall('place', 'Character Name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Expression', true, '')
                : setHighlightHintCall('place', 'Character Expression', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Outfit', true, '')
                : setHighlightHintCall('place', 'Character Outfit', true, '');

              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Hair', true, '')
                : setHighlightHintCall('place', 'Character Hair', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Accessories', true, '')
                : setHighlightHintCall('place', 'Character Accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 6:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character Position', true, 'Middle')
                : setHighlightHintCall('place', 'Character Position', true, 'Middle');
              wordList = Constant.COMMAND_ENTER_POSITION;
              setVariationWordList(wordList);
              break;

            case 7:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'place',
                  'Character flip/no-flip', true, 'no-flip'
                  )
                : setHighlightHintCall('place', 'Character flip/no-flip', true, 'no-flip');
              wordList = Constant.COMMAND_ENTER_FLIP;
              setVariationWordList(wordList);
              break;

            case 8:
              setNoAutocompleteList([]);
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'place',
                  'Character fade in duration', true, '0.3 seconds'
                  )
                : setHighlightHintCall('place', 'Character fade in duration', true, '0.3 seconds');
              break;

            case 9:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('place', 'Character appear from', true, 'None')
                : setHighlightHintCall('place', 'Character appear from', true, 'None');
              wordList = Constant.COMMAND_OFF_POSITION;
              setVariationWordList(wordList);
              break;

            case 10:
              setNoAutocompleteList([]);
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'place',
                  'Character slide in duration', true, '0 seconds'
                  )
                : setHighlightHintCall('place', 'Character slide in duration', true, '0 seconds');
              break;

            default:
              setHighlightHintCall('place', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case '_:':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('Add Dialogue', '')
            : setHighlightHintCall('Add Dialogue', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else if (whichPartParamertes.partNumber === 1) {
            props.saveButtonEnable === false
              ? setHighlightHintCallOnChange('Add Dialogue', 'Dialogue Text')
              : setHighlightHintCall('Add Dialogue', 'Dialogue Text');
            setNoAutocompleteList([]);
          } else {
            setNoAutocompleteList([]);
          }
          break;

        case 'hide':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('hide', '')
            : setHighlightHintCall('hide', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              wordList = props.characterListForScript;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('hide', 'Character Name')
                : setHighlightHintCall('hide', 'Character Name');
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('hide', 'Transition', true, 'Fade-out')
                : setHighlightHintCall('hide', 'Transition', true, 'Fade-out');
              wordList = Constant.COMMAND_TRANSITION_EFFECT;
              setVariationWordList(wordList);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('hide', 'Duration', true, '0.3 seconds')
                : setHighlightHintCall('hide', 'Duration', true, '0.3 seconds');
              wordList = Constant.COMMAND_TRANSITION_EFFECT;
              setNoAutocompleteList([]);
              break;

            default:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('hide', '')
                : setHighlightHintCall('hide', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'block':
          setNoAutocompleteList([]);
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('block', '')
            : setHighlightHintCall('block', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else if (whichPartParamertes.partNumber === 1) {
            setHighlightHintCall('block', 'Block name');
          } else {
            setHighlightHintCall('block', '');
          }

          break;

        case 'goto':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('goto', '')
            : setHighlightHintCall('goto', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('goto', 'Block name')
                : setHighlightHintCall('goto', 'Block name');
              setBlocksWordList();
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'choice':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('Choice', '')
            : setHighlightHintCall('Choice', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('choice', 'Choice text')
                : setHighlightHintCall('choice', 'Choice text');
              setNoAutocompleteList([]);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('choice', 'Destination block')
                : setHighlightHintCall('choice', 'Destination block');
              setBlocksWordList();
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'choice',
                  'Cost in game currency', true, ''
                  )
                : setHighlightHintCall('choice', 'Cost in game currency', true, '');
              setNoAutocompleteList([]);
              break;

            case 4:
              wordList = Constant.INTERACTABLE_CHOICE;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'choice',
                  'Interactable / Non-Interactable', true, 'Interactable'
                  )
                : setHighlightHintCall(
                    'choice',
                  'Interactable / Non-Interactable', true, 'Interactable'
                  );
              setVariationWordList(wordList);
              break;

            case 5:
              wordList = Constant.HIDESHOW_CHOICE;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'Hide if visited',
                  'hideifvisited / showifvisited', true, 'hideifvisited'
                  )
                : setHighlightHintCall(
                    'Hide if visited',
                  'hideifvisited / showifvisited', true, 'hideifvisited'
                  );
              setVariationWordList(wordList);
              break;

            case 6:
              wordList = Constant.AUTO_AFFINITY_TYPE;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('choice', 'Affinity type', true, '')
                : setHighlightHintCall('choice', 'Affinity type', true, '');
              setVariationWordList(wordList);
              break;

            case 7:
              wordList = Constant.AUTO_AFFINITY_WEIGHT;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('choice', 'Affinity weight', true, '')
                : setHighlightHintCall('choice', 'Affinity weight', true, '');
              setVariationWordList(wordList);
              break;

            default:
              wordList = Constant.AUTO_AFFINITY_WEIGHT;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('choice', '')
                : setHighlightHintCall('choice', '');
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'choicetimer':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('choicetimer', '')
            : setHighlightHintCall('choicetimer', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;
            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'choicetimer',
                    'Destination name'
                  )
                : setHighlightHintCall('choicetimer', 'Destination name');
              setBlocksWordList();
              break;

            case 2:
              setHighlightHintCall('choicetimer', 'Wait up to');
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'shownamedialog':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('shownamedialog', '')
            : setHighlightHintCall('shownamedialog', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else {
            setNoAutocompleteList([]);
          }
          break;

        case 'endjuicyblock':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('endjuicyblock', '')
            : setHighlightHintCall('endjuicyblock', '');
          if (whichPartParamertes.partNumber === 0) {
            setCommandWordList();
          } else {
            setNoAutocompleteList([]);
          }
          break;

        case 'music':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('music', '')
            : setHighlightHintCall('music', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('music', 'Music file')
                : setHighlightHintCall('music', 'Music file');
              if (props.backgroundSongs.length > 0) {
                wordList = props.backgroundSongs;
                setMusicList(wordList);
              }
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('music', 'Loop / no-loop', true, 'Loop')
                : setHighlightHintCall('music', 'Loop / no-loop', true, 'Loop');
              wordList = Constant.COMMAND_MUSIC_LOOP;
              setVariationWordList(wordList);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('music', 'Fade in duration', true, '0 Seconds')
                : setHighlightHintCall('music', 'Fade in duration', true, '0 Seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'stopmusic':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('stopmusic', '')
            : setHighlightHintCall('stopmusic', '');
          if (whichPartParamertes.partNumber === 1) {
            setHighlightHintCall('stopmusic', 'Fade out effect');
            setNoAutocompleteList([]);
          }
          break;

        case 'sfx':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('sfx', '')
            : setHighlightHintCall('sfx', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('sfx', 'Select sound')
                : setHighlightHintCall('sfx', 'Select sound');
              if (props.incidentalSongs.length > 0) {
                wordList = props.incidentalSongs;
                setMusicList(wordList);
              } else {
                setNoAutocompleteList([]);
              }
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'show':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('show', '')
            : setHighlightHintCall('show', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              wordList = props.characterListForScript;
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('show', 'character name')
                : setHighlightHintCall('show', 'character name');
              setCharacterAutoComplete(wordList);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('show', 'character outfits', true, '')
                : setHighlightHintCall('show', 'character outfits', true, '');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCall('show', 'character expressions', true, '')
                : setHighlightHintCall('show', 'character expressions', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('show', 'character hairs', true, '')
                : setHighlightHintCall('show', 'character hairs', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('show', 'character accessories', true, '')
                : setHighlightHintCall('show', 'character accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }

              break;

            case 6:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('show', 'Fade into screen', true, '0.3 seconds')
                : setHighlightHintCall('show', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'replace':
          setNoAutocompleteList([]);

          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('replace', '')
            : setHighlightHintCall('replace', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'replace',
                    'Original character name'
                  )
                : setHighlightHintCall('replace', 'Original character name');
              setCharacterAutoComplete(props.characterListForScript);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('replace', 'New character name')
                : setHighlightHintCall('replace', 'New character name');
              const characterListForScriptReplace = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterName &&
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterLongName
              );
              setCharacterAutoComplete(characterListForScriptReplace);
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('replace', 'character outfits', true, '')
                : setHighlightHintCall('replace', 'character outfits', true, '');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }

              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'replace',
                  'character expressions', true, ''
                  )
                : setHighlightHintCall('replace', 'character expressions', true, '');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 5:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('replace', 'character hairs', true, '')
                : setHighlightHintCall('replace', 'character hairs', true, '');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 6:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'replace',
                  'character accessories', true, ''
                  )
                : setHighlightHintCall('replace', 'character accessories', true, '');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 7:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('replace', 'Fade into screen', true, '0.3 seconds')
                : setHighlightHintCall('replace', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'view':
          // setpreviewIconState(true);
          // setEditIconState(true);
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('view', '')
            : setHighlightHintCall('view', '');

          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('view', 'View Name')
                : setHighlightHintCall('view', 'View Name');
              wordList = props.views;
              setPlaceViewList(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('view', 'Fade into screen', true, '1 second')
                : setHighlightHintCall('view', 'Fade into screen', true, '1 second');
              wordList = props.views;
              setPlaceViewList(wordList);
              setNoAutocompleteList([]);

              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'moveto':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('moveto', '')
            : setHighlightHintCall('moveto', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('moveto', 'Destination view')
                : setHighlightHintCall('moveto', 'Destination view');
              wordList = props.views;
              setPlaceViewList(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('moveto', 'Transition Duration', true, '1 second')
                : setHighlightHintCall('moveto', 'Transition Duration', true, '1 second');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'volume':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('volume', '')
            : setHighlightHintCall('volume', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('volume', 'volume')
                : setHighlightHintCall('volume', 'volume');
              setAudioAutoComplete();
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('volume', 'fade in time', true, '1 second')
                : setHighlightHintCall('volume', 'fade in time', true, '1 second');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'showobj':
        case 'hideobj':
          const parameterPass =
            whichPartParamertes.commandStart === 'showobj'
              ? 'showobj'
              : 'hideobj';
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange(parameterPass, '')
            : setHighlightHintCall(parameterPass, '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setNoAutocompleteList([]);
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(parameterPass, 'Object name')
                : setHighlightHintCall(parameterPass, 'Object name');
              const wordlist = props.assetsAll.filter(
                item => item.type === Constants.ASSET_TYPES_IMAGES[1]
              );
              setObjectListAutocomplete(wordlist);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(parameterPass, 'Duration')
                : setHighlightHintCall(parameterPass, 'Duration');
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'tap':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('tap', '')
            : setHighlightHintCall('tap', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('tap', 'object name')
                : setHighlightHintCall('tap', 'object name');
              setNoAutocompleteList([]);
              const wordlist = props.assetsAll.filter(
                item => item.type === Constants.ASSET_TYPES_IMAGES[1]
              );
              setObjectListAutocomplete(wordlist);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('tap', 'destination block')
                : setHighlightHintCall('tap', 'destination block');
              setBlocksWordList();
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'shake':
          // setEditIconState(true);
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('shake', '')
            : setHighlightHintCall('shake', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('shake', 'Duration')
                : setHighlightHintCall('shake', 'Duration');
              setNoAutocompleteList([]);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('shake', 'X-axis')
                : setHighlightHintCall('shake', 'X-axis');
              setNoAutocompleteList([]);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('shake', 'Y-axis')
                : setHighlightHintCall('shake', 'Y-axis');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }

          break;

        case 'if':
        case 'elseIf':
          const paramtIfElse =
            whichPartParamertes.commandStart === 'if' ? 'if' : 'elseIf';
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange(paramtIfElse, '')
            : setHighlightHintCall(paramtIfElse, '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(paramtIfElse, 'Variable')
                : setHighlightHintCall(paramtIfElse, 'Variable');
              setAutocompleteCondtionalCommand();
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(paramtIfElse, 'Operator')
                : setHighlightHintCall(paramtIfElse, 'Operator');
              wordList = Constant.OPERATOR_FOR_OTHER;
              const variableSelected = props.variableListDTO.filter(
                variable =>
                  whichPartParamertes.allCommandParamValue[1] === variable.name
              );
              if (variableSelected.length > 0) {
                if (
                  variableSelected[0].datatype === 'Boolean' ||
                  variableSelected[0].datatype === 'String'
                ) {
                  wordList = Constant.OPERATOR_FOR_BOOLEAN;
                }
                setVariationWordList(wordList);
              } else {
                setNoAutocompleteList([]);
              }
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(paramtIfElse, 'Comparison value')
                : setHighlightHintCall(paramtIfElse, 'Comparison value');
              break;

            default:
              setNoAutocompleteList([]);
          }
          break;

        case 'setvar':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('setvar', '')
            : setHighlightHintCall('setvar', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              setNoAutocompleteList([]);
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setvar', 'name')
                : setHighlightHintCall('setvar', 'name');
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setvar', 'Operator')
                : setHighlightHintCall('setvar', 'Operator');
              wordList = Constant.AUTOCOMPLETE_ALL_OPERATOR_SET_VAR;
              setVariationWordList(wordList);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setvar', 'Value')
                : setHighlightHintCall('setvar', 'Value');
              if (
                !whichPartParamertes.allCommandParamValue[3].startsWith('"')
              ) {
                const opertorSelected =
                  whichPartParamertes.allCommandParamValue[2];
                switch (opertorSelected) {
                  case '=!':
                    const variableAutoCompleteBoolean = props.variableListDTO.filter(
                      variable => 'Boolean' === variable.datatype
                    );
                    if (variableAutoCompleteBoolean.length > 0) {
                      setAutocompleteCondtionalCommandSetVar(
                        variableAutoCompleteBoolean
                      );
                    } else {
                      setNoAutocompleteList([]);
                    }
                    break;

                  case '=':
                    setAutocompleteCondtionalCommand();
                    break;

                  case '+=':
                  case '-=':
                  case '*=':
                  case '/=':
                    const variableAutoCompleteIntFloat = props.variableListDTO.filter(
                      variable =>
                        'Integer' === variable.datatype ||
                        'Float' === variable.datatype
                    );
                    if (variableAutoCompleteIntFloat.length > 0) {
                      setAutocompleteCondtionalCommandSetVar(
                        variableAutoCompleteIntFloat
                      );
                    } else {
                      setNoAutocompleteList([]);
                    }
                    break;
                }
              } else {
                setNoAutocompleteList([]);
              }
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setvar', 'Variable type', true, 'Boolean')
                : setHighlightHintCall('setvar', 'Variable type', true, 'Boolean');
              const opertorSelected =
                whichPartParamertes.allCommandParamValue[2];
              let dataTypeDefault = '';
              const variableSelectedForDataType = props.variableListDTO.filter(
                variable =>
                  variable.name === whichPartParamertes.allCommandParamValue[3]
              );
              if (variableSelectedForDataType.length > 0) {
                dataTypeDefault = variableSelectedForDataType[0].datatype;
                setVariationWordList([
                  {
                    name: dataTypeDefault,
                    meta: dataTypeDefault
                  }
                ]);
              } else {
                switch (opertorSelected) {
                  case '=!':
                    setVariationWordList(Constant.AUTOCOMPLETE_BOOLEAN);
                    break;

                  case '=':
                    wordList = [
                      ...Constant.AUTOCOMPLETE_BOOLEAN,
                      ...Constant.AUTOCOMPLETE_STRING,
                      ...Constant.AUTOCOMPLETE_FLOAT,
                      ...Constant.AUTOCOMPLETE_INT
                    ];
                    setVariationWordList(wordList);
                    break;

                  case '+=':
                  case '-=':
                  case '*=':
                  case '/=':
                    wordList = [
                      ...Constant.AUTOCOMPLETE_FLOAT,
                      ...Constant.AUTOCOMPLETE_INT
                    ];
                    setVariationWordList(wordList);
                    break;
                }
              }
              break;

            case 5:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'setvar',
                  'Cross-chapter / Private',
                  true,
                  'Cross-chapter'
                  )
                : setHighlightHintCall('setvar', 'Cross-chapter / Private', true, 'Cross-chapter');
              setVariationWordList(Constant.AUTOCOMPLETE_CROSS_CHAPTER);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'endchapter':
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('endchapter', '')
                : setHighlightHintCall('endchapter', '');
              break;

            case 1:
              setVariationWordList(Constant.COMMAND_LAST_CHAPTER);
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('endchapter', 'Last chapter')
                : setHighlightHintCall('endchapter', 'Last chapter');
              break;

            case 2:
              setNoAutocompleteList([]);
              if (whichPartParamertes.allCommandParamValue[1] == 'false') {
                const allStoryRoute = props.allStoryRoutes.filter(
                  routesSelected =>
                    routesSelected.id === props.currentCharacterRouteId
                );
                if (allStoryRoute.length > 0) {
                  setChapterWordList(
                    allStoryRoute[0].scenes.filter(
                      scene => scene.id !== props.currentSceneId
                    )
                  );
                }
              }
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('endchapter', 'Next chapter')
                : setHighlightHintCall('endchapter', 'Next chapter');
              break;

            default:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('endchapter', '')
                : setHighlightHintCall('endchapter', '');
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'wait':
          setNoAutocompleteList([]);
          // setpreviewIconState(false);
          // setEditIconState(true);
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              props.saveButtonEnable === false
                ? setHighlightHintCall('wait', '')
                : setHighlightHintCall('wait', '');
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('wait', 'duration')
                : setHighlightHintCall('wait', 'duration');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'saygendered':
          setNoAutocompleteList([]);
          props.saveButtonEnable === false
            ? setHighlightHintCall('saygendered', '')
            : setHighlightHintCall('saygendered', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setAutocompleteCondtionalCommand();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'saygendered',
                    'Character or Game Narrator'
                  )
                : setHighlightHintCall(
                    'saygendered',
                    'Character or Game Narrator'
                  );
              const nonCharacterGenderedAutocomplete = [
                {
                  characterName: '_',
                  meta: 'Non-character'
                }
              ];
              let sayGenderedAutocomplete = props.characterListForScript;

              setCharacterAutoComplete([
                ...sayGenderedAutocomplete,
                ...nonCharacterGenderedAutocomplete
              ]);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'saygendered',
                    'Dialogue Text for she/her'
                  )
                : setHighlightHintCall(
                    'saygendered',
                    'Dialogue Text for she/her'
                  );
              setNoAutocompleteList([]);

              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'saygendered',
                    'Dialogue Text for he/him'
                  )
                : setHighlightHintCall(
                    'saygendered',
                    'Dialogue Text for he/him'
                  );
              setNoAutocompleteList([]);

              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'saygendered',
                    'Dialogue Text for they/them'
                  )
                : setHighlightHintCall(
                    'saygendered',
                    'Dialogue Text for they/them'
                  );
              setNoAutocompleteList([]);

              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'setoutfit':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('setoutfit', '')
            : setHighlightHintCall('setoutfit', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setoutfit', 'character name')
                : setHighlightHintCall('setoutfit', 'character name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setoutfit', 'character outfits')
                : setHighlightHintCall('setoutfit', 'character outfits');
              const characterOutfits = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterOutfits.length > 0) {
                wordList = characterOutfits[0].outfits;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('setoutfit', 'character hairs')
                : setHighlightHintCall('setoutfit', 'character hairs');
              const characterHairs = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterHairs.length > 0) {
                wordList = characterHairs[0].hairs;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'setoutfit',
                    'character accessories'
                  )
                : setHighlightHintCall('setoutfit', 'character accessories');
              const characterAccessories = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterAccessories.length > 0) {
                wordList = characterAccessories[0].accessories;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'oshow':
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('oshow', '')
            : setHighlightHintCall('oshow', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('oshow', 'Character name')
                : setHighlightHintCall('oshow', 'Character name');
              wordList = props.characterListForScript;
              setCharacterAutoComplete(wordList);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('oshow', 'Character expressions')
                : setHighlightHintCall('oshow', 'Character expressions');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[1] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('oshow', 'Fade in time', true, '0.3 seconds')
                : setHighlightHintCall('oshow', 'Fade in time', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        case 'oreplace':
          setNoAutocompleteList([]);
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange('oreplace', '')
            : setHighlightHintCall('oreplace', '');
          switch (whichPartParamertes.partNumber) {
            case 0:
              setCommandWordList();
              break;

            case 1:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'oreplace',
                    'Original character name'
                  )
                : setHighlightHintCall('oreplace', 'Original character name');
              setCharacterAutoComplete(props.characterListForScript);
              break;

            case 2:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('oreplace', 'New character name')
                : setHighlightHintCall('oreplace', 'New character name');
              const characterListForScriptReplace = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterName &&
                  whichPartParamertes.allCommandParamValue[1] !==
                    characterList.characterLongName
              );
              setCharacterAutoComplete(characterListForScriptReplace);
              break;

            case 3:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange(
                    'oreplace',
                    'Character expressions'
                  )
                : setHighlightHintCall('oreplace', 'Character expressions');
              const characterExpressions = props.characterListForScript.filter(
                characterList =>
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterName ||
                  whichPartParamertes.allCommandParamValue[2] ===
                    characterList.characterLongName
              );
              if (characterExpressions.length > 0) {
                wordList = characterExpressions[0].expressions;
                setCharacterOtherAutoComplete(wordList);
              }
              break;

            case 4:
              props.saveButtonEnable === false
                ? setHighlightHintCallOnChange('oreplace', 'Fade into screen', true, '0.3 seconds')
                : setHighlightHintCall('oreplace', 'Fade into screen', true, '0.3 seconds');
              setNoAutocompleteList([]);
              break;

            default:
              setNoAutocompleteList([]);
              break;
          }
          break;

        default:
          props.saveButtonEnable === false
            ? setHighlightHintCallOnChange(
                whichPartParamertes.commandStart === 'end'
                  ? 'end'
                  : whichPartParamertes.commandStart === 'else'
                  ? 'else'
                  : '',
                ''
              )
            : setHighlightHintCall(
                whichPartParamertes.commandStart === 'end'
                  ? 'end'
                  : whichPartParamertes.commandStart === 'else'
                  ? 'else'
                  : '',
                ''
              );
          // if (previewIconState === true) {
          //   // setpreviewIconState(false);
          // }
          // if (editIconState === true) {
          //   // setEditIconState(false);
          // }
          const commandListCharacterFound = props.characterListForScript.filter(
            character =>
              `${character.characterName}:` ===
                `${whichPartParamertes.allCommandParamValue[0]}` ||
              `${character.characterLongName}:` ===
                `${whichPartParamertes.allCommandParamValue[0]}`
          );
          if (commandListCharacterFound.length > 0) {
            props.saveButtonEnable === false
              ? setHighlightHintCallOnChange('Dialgoue', 'Dialogue Text')
              : setHighlightHintCall('Dialgoue', 'Dialogue Text');
            setNoAutocompleteList([]);
          }
          // if (commandListCharacterFound.length > 0) {
          //   // setEditIconState(true);
          // }
          if (
            whichPartParamertes.partNumber === 0 ||
            whichPartParamertes.allCommandParamValue[0].length < 4
          ) {
            setCommandWordList();
          } else {
            setNoAutocompleteList([]);
          }
          if (
            whichPartParamertes.commandStart !== undefined &&
            whichPartParamertes.commandStart.startsWith('//')
          ) {
            setNoAutocompleteList([]);
          }
          break;
      }
    }
  };

  const clicktoIndent = checkValueofButton => {
    if (props.saveButtonEnable === false) {
      props.setSaveButtonEnable(true);
    }

    if (value !== null) {
      props.setIndetationTrue(checkValueofButton);
    }

    props.setIndentButtonClick(true);
    aceEditor.current.editor.focus(true);
  };

  const prepareCommandWordList = commandWordList => {
    // Get list with character name
    let firstWordList = commandWordList.map(function(word) {
      return {
        caption:
          word.meta === 'Command' ? `${word.name}` : `${word.characterName}:`,
        value:
          word.meta === 'Command' ? `${word.name}` : `${word.characterName}:`,
        meta: word.meta
      };
    });

    // Get list with character long name
    let secondWordList = commandWordList.map(function(word) {
      return {
        caption:
          word.meta === 'Command'
            ? `${word.name}`
            : `${word.characterLongName}:`,
        value:
          word.meta === 'Command'
            ? `${word.name}`
            : `${word.characterLongName}:`,
        meta: word.meta
      };
    });

    // Concate first and second list
    let finalWordList = firstWordList.concat(secondWordList);

    // Return distinct object values from final list
    return finalWordList.filter((obj, pos, arr) => {
      return (
        arr.map(mapObj => mapObj['caption']).indexOf(obj['caption']) === pos
      );
    });
  };

  // const onLoadEditor = event => {
  //   if (aceEditor && aceEditor.current) {
  //     aceEditor.current.editor.moveCursorTo(10);
  //   }
  // };

  const uniqueArrayErro = uniqueArray.filter(
    errorList => errorList.type !== 'warning'
  );

  if (uniqueArrayErro.length == 0 && showScriptErrorWindow) {
    setShowScriptErrorWindow(false);
  }

  if (showHideVariableStateClicked === true && uniqueArrayErro.length !== 0) {
    setTimeout(() => setShowScriptErrorWindow(true), 200);
  }

  return (
    <div>
      <commandPanelStyle.Header>
        <TextEditorHeader
          backToScene={props.backToScene}
          storyTitle={props.storyTitle}
          storySubTitle={props.storySubTitle}
          previewIconState={previewIconState}
          cursorPostion={props.cursorPostion}
          onPreviewClick={onPreviewClick}
          onEditClick={onEditClick}
          isViewStory={props.isViewStory}
          showPanels={props.showPanels}
          onAddCommentClick={props.onAddCommentClick}
          isCommentStory={props.isCommentStory}
          commandList={props.commandListForScriptEditor}
          commentLineNo={props.commentLineNo}
          changeCommentPoup={props.changeCommentPoup}
          currentStoryStatus={props.currentStoryStatus}
          tokenDataUser={props.tokenDataUser}
          showHideVariableWindow={showHideVariableWindow}
          handleSaveStory={props.handleSaveStory}
          compareFlag={props.compareFlag}
          handleChangeLogScript={props.handleChangeLogScript}
          currentSceneId={props.currentSceneId}
          scriptValue={value}
          handleSaveClick={handleSaveClick}
          editIconState={editIconState}
          handleClickValidate={handleClickValidate}
          clicktoIndent={clicktoIndent}
          textEditorValidate={props.textEditorValidate}
          indentationTrue={props.indentationTrue}
          externalPlayPauseHandler={props.externalPlayPauseHandler}
          indentButtonClick={props.indentButtonClick}
          saveButtonEnable={props.saveButtonEnable}
          textEditorloading={props.textEditorloading}
          setFindReplace={setFindReplace}
          findReplace={findReplace}
          fontFamilyModal={fontFamilyModal}
          spellChecker={spellChecker}
          expandEditor={props.expandEditor}
          spellCheckerOn={spellCheckerOn}
        />
      </commandPanelStyle.Header>

      <commandPanelStyle.VariableWindow>
        {showScriptErrorWindow === false && (
          <VariableWindow
            showHideVariableState={showHideVariableState}
            localVariableListDTO={props.localVariableListDTO}
            activeTabSelected={props.activeTabSelected}
            updateVariableWithId={props.updateVariableWithId}
            updateValueArrayIds={props.updateValueArrayIds}
            resetToDefaultValue={props.resetToDefaultValue}
            currentStoryStatus={props.storyStatusObj}
            play={props.play}
            tutorials={props.tutorials}
            onVarTutorialExit={props.onVarTutorialExit}
            stepsScriptEnabled={props.stepsScriptEnabled}
            flowchartHasErrors={props.flowchartHasErrors}
            textEditorValidate={props.textEditorValidate}
          />
        )}
        {showScriptErrorWindow === true && uniqueArrayErro.length > 0 && (
          <ErrorWindow
            showScriptErrorWindow={showScriptErrorWindow}
            errorList={uniqueArrayErro}
            handleClickGotoLine={handleClickGotoLine}
          />
        )}

        <div className="variables-btn-wrapper">
          <button
            onClick={() => showHideVariableWindow(showHideVariableState)}
            className="btn-snacktool variables-btn align-items-center d-flex justify-content-center"
          >
            {showHideVariableState === true ? (
              <span className="variable-icon-open"></span>
            ) : (
              <span className="variable-icon-hide"></span>
            )}
            Variables
          </button>
          <button
            onClick={() => showHideErrorWindow(showScriptErrorWindow)}
            className="btn-snacktool error-btn align-items-center d-flex justify-content-center"
            disabled={uniqueArrayErro && uniqueArrayErro.length <= 0}
          >
            <i className="fa fa-warning mr-1" />
            Script Errors
          </button>
          <OverlayTrigger
            key={`command-key`}
            placement="right"
            overlay={
              <Tooltip id={`command-key`}>
                Use this button to expand the writing section to the entire
                window. This will hide the command and preview panels. You can
                click it again to bring them back.
              </Tooltip>
            }
          >
            <button
              onClick={() => props.expandEditorClick()}
              className="ml-5 btn-snacktool expand-btn align-items-center d-flex justify-content-center"
            >
              {props.expandEditor !== true ? (
                <i className="fa fa-expand" />
              ) : (
                <i className="fa fa-compress" />
              )}
            </button>
          </OverlayTrigger>
        </div>
        {findReplace === true && (
          <div className="find-replace">
            <FindAndReplace
              onChangeFindValue={onChangeFindValue}
              prevFindClickNextPrevious={prevFindClickNextPrevious}
              onClickSearch={onClickSearch}
              valueFind={valueFind}
              valueReplace={valueReplace}
              onChangeReplaceValue={onChangeReplaceValue}
              onClickReplace={onClickReplace}
              findAndReplaceParameters={findAndReplaceParameters}
              searchRegExp={searchRegExp}
              searchCaseSensitive={searchCaseSensitive}
              searchWholeWord={searchWholeWord}
              searchCounter={searchCounter}
              findReplace={findReplace}
              setFindReplace={setFindReplace}
              closeFindReplace={closeFindReplace}
            ></FindAndReplace>
          </div>
        )}
        {showModalGrammer === true && (
          <GrammerModal
            onChangeFindValue={onChangeFindValue}
            prevFindClickNextPrevious={prevFindClickNextPrevious}
            onClickSearch={onClickSearch}
            valueFind={valueFind}
            valueReplace={valueReplace}
            onChangeReplaceValue={onChangeReplaceValue}
            onClickReplace={GrammarOnClickReplace}
            findAndReplaceParameters={findAndReplaceParameters}
            searchRegExp={searchRegExp}
            searchCaseSensitive={searchCaseSensitive}
            searchWholeWord={searchWholeWord}
            searchCounter={searchCounter}
            findReplace={findReplace}
            setFindReplace={setFindReplace}
            closeFindReplace={closeFindReplace}
            setShowModalGrammer={setShowModalGrammer}
            closePopup={closePopup}
            lineNumber={props.cursorPostion}
            suggestionList={props.spellGrammar}
            ace={aceEditor}
            loadGrammar={props.loadGrammar}
          />
        )}
        {fontFamily === true && (
          <div className="font-family">
            <FontFamily
              onChangeFindValue={onChangeFindValue}
              prevFindClickNextPrevious={prevFindClickNextPrevious}
              onClickSearch={onClickSearch}
              valueFind={valueFind}
              valueReplace={valueReplace}
              onChangeReplaceValue={onChangeReplaceValue}
              onClickReplace={onClickReplace}
              findAndReplaceParameters={findAndReplaceParameters}
              searchRegExp={searchRegExp}
              searchCaseSensitive={searchCaseSensitive}
              searchWholeWord={searchWholeWord}
              searchCounter={searchCounter}
              findReplace={findReplace}
              setFindReplace={setFindReplace}
              closeFindReplace={closeFindReplace}
              fontChange={fontChange}
              fontChangeSave={fontChangeSave}
              font={font}
              fontSize={fontSize}
              fontChangeCancel={fontChangeCancel}
            />
          </div>
        )}
      </commandPanelStyle.VariableWindow>
      <commandPanelStyle.Body1
        conditionalHeight={
          showHideVariableState || showScriptErrorWindow ? true : false
        }
        conditionalFindReplace={findReplace}
        id="texteditorsave"
      >
        <IdleTimer
          ref={idletimeSave}
          timeout={300000}
          onIdle={event => handleOnAction(event)}
          events={['keydown']}
        >
          <AceEditor
            ref={aceEditor}
            mode="javascript"
            showGutter={true}
            highlightActiveLine={true}
            focus={true}
            readOnly={
              props.textEditorloading ||
              props.isViewStory ||
              showHideVariableState === true ||
              (props.commandListForScriptEditor &&
                props.commandListForScriptEditor.lockAcquired === true &&
                props.commandListForScriptEditor.lockAcquiredBy !==
                  props.tokenDataUser.userId)
            }
            commands={[
              {
                name: 'preview',
                bindKey: { win: 'Ctrl-P', mac: 'Ctrl-P' },
                exec: function(editor) {
                  let topPos = aceEditor.current.editor.session.getScrollTop();
                  let position = 0;
                  if (props.cursorPostion > 20 && topPos == 0) {
                    position = props.cursorPostion * 10;
                  } else if (props.cursorPostion > 20 && topPos > 0) {
                    let actualTop = topPos / 30;
                    if (actualTop > 0) {
                      let checkPos =
                        actualTop > 30 ? actualTop : actualTop * 2.4;
                      if (checkPos > 20) {
                        position = 20 * 7;
                      } else {
                        position = checkPos * 10;
                      }
                    } else {
                      position = (props.cursorPostion - actualTop) * 5;
                    }
                  } else {
                    position = props.cursorPostion * 20;
                  }

                  props.showHideModal(
                    editor.selection.cursor.row,
                    0,
                    184 + position
                  );
                }
              },
              {
                name: 'edit',
                bindKey: { win: 'Ctrl-E', mac: 'Ctrl-E' },
                exec: function(editor) {
                  if (props.commandListForScriptEditor)
                    props.editCommand(editor.selection.cursor.row, null);
                }
              },
              {
                name: 'find',
                bindKey: { win: 'Ctrl-F', mac: 'Cmd-F' },
                exec: function(editor) {
                  setFindReplace(true);
                }
              }
            ]}
            editorProps={{
              $blockScrolling: true
            }}
            setShowPrintMargin={false}
            height={
              showHideVariableState === true || showScriptErrorWindow === true
                ? 'calc(100vh - 590px)'
                : 'calc(100vh - 260px)'
            }
            width="100%"
            enableLiveAutocompletion={true}
            enableBasicAutocompletion={true}
            onChange={(newValue, event) => onChange(newValue, event)}
            onCursorChange={(selection, event) =>
              props.saveButtonEnable === false
                ? onCursorChangeDisableSave(selection, event)
                : onCursorChange(selection, event)
            }
            value={value != null ? value : ''}
          />
        </IdleTimer>
        {props.previewAssetObj &&
          (props.previewAssetObj.sprites !== null ||
            props.previewAssetObj.view !== null) && (
            <CharacterInfo
              hideCharModal={props.hideCharModal}
              show={props.showModal}
              previewAssetObj={props.previewAssetObj}
              pageX={props.pageX}
              pageY={props.pageY}
            ></CharacterInfo>
          )}
      </commandPanelStyle.Body1>
      <commandPanelStyle.FooterArea>
        <Row>
          <Col>
            {' '}
            <TextEditorFooter
              previewIconState={previewIconState}
              cursorPostion={aceEditor.current ? props.cursorPostion : 0}
              onPreviewClick={onPreviewClick}
              onEditClick={onEditClick}
              isViewStory={props.isViewStory}
              showPanels={props.showPanels}
              onAddCommentClick={props.onAddCommentClick}
              isCommentStory={props.isCommentStory}
              commandList={props.commandListForScriptEditor}
              commentLineNo={props.commentLineNo}
              changeCommentPoup={props.changeCommentPoup}
              currentStoryStatus={props.currentStoryStatus}
              tokenDataUser={props.tokenDataUser}
              showHideVariableWindow={showHideVariableWindow}
              handleSaveStory={props.handleSaveStory}
              compareFlag={props.compareFlag}
              handleChangeLogScript={props.handleChangeLogScript}
              currentSceneId={props.currentSceneId}
              scriptValue={value}
              handleSaveClick={handleSaveClick}
              editIconState={editIconState}
              handleClickValidate={handleClickValidate}
              clicktoIndent={clicktoIndent}
              textEditorValidate={props.textEditorValidate}
              indentationTrue={props.indentationTrue}
              externalPlayPauseHandler={props.externalPlayPauseHandler}
              indentButtonClick={props.indentButtonClick}
              flowchartHasErrors={props.flowchartHasErrors}
              value={value}
              highlightHint={highlightHint}
            />
          </Col>
        </Row>
      </commandPanelStyle.FooterArea>
    </div>
  );
};

export default AceNewCommand;

//common action for error handling

export const SET_API_ERROR = "SET_API_ERROR";
export const RESET_API_ERROR = "RESET_API_ERROR";


export const SET_BLOCK_DETAILS = "SET_BLOCK_DETAILS";
export const SET_BOXES = "SET_BOXES";


// Actions For Scene Reducer
export const GET_CHARACTERS_DETAILS = "GET_CHARACTERS_DETAILS";
export const GET_SUCCESS_CHARACTERS_DETAILS = "GET_SUCCESS_CHARACTERS_DETAILS";
export const GET_FAIL_CHARACTERS_DETAILS = "GET_FAIL_CHARACTERS_DETAILS";
export const GET_BACKGROUND_DETAILS = "GET_BACKGROUND_DETAILS";
export const GET_SUCCESS_BACKGROUND_DETAILS = "GET_SUCCESS_BACKGROUND_DETAILS";
export const GET_FAIL_BACKGROUND_DETAILS = "GET_FAIL_BACKGROUND_DETAILS";



// Flowcharts action
export const GET_BLOCKS_DETAILS = "GET_BLOCKS_DETAILS";
export const GET_SUCCESS_BLOCKS_DETAILS = "GET_SUCCESS_BLOCKS_DETAILS";
export const GET_FAIL_BLOCKS_DETAILS = "GET_FAIL_BLOCKS_DETAILS";
export const SET_FLOWCHART_BLOCKS = "SET_FLOWCHART_BLOCKS";
export const SET_DRAWPANEL_BLOCKS = "SET_DRAWPANEL_BLOCKS";
export const ADD_NEW_BLOCK = "ADD_NEW_BLOCK";
export const FAIL_ADD_NEW_BLOCK = "FAIL_ADD_NEW_BLOCK";
export const ADD_NEW_LINKS_BLOCK = "ADD_NEW_LINKS_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const FAIL_UPDATE_BLOCK = "FAIL_UPDATE_BLOCK";
export const ADD_NEW_BLOCK_FLOWCHART = "ADD_NEW_BLOCK_FLOWCHART";

// Updated Flowchart Section 
export const GET_BLOCK_FLOWCHART_DETAILS = "GET_BLOCK_FLOWCHART_DETAILS";
export const GET_SUCCESS_BLOCK_FLOWCHART_DETAILS = "GET_SUCCESS_BLOCK_FLOWCHART_DETAILS";

export const RESET_COMMAND_DETAILS = 'RESET_COMMAND_DETAILS'

export const SET_CURRENT_PREVIOUS_BLOCK_ID = "SET_CURRENT_PREVIOUS_BLOCK_ID";
export const GET_FAIL_FLOWCHART_DETAILS = "GET_FAIL_FLOWCHART_DETAILS";
export const GET_FLOWCHART_DETAILS = "GET_FLOWCHART_DETAILS";
export const GET_SUCCESS_FLOWCHART_DETAILS = "GET_SUCCESS_FLOWCHART_DETAILS";
export const GET_RESET_FLOWCHART_DETAILS = "GET_RESET_FLOWCHART_DETAILS";
export const GET_RESET_ONLY_FLOWCHART = "GET_RESET_ONLY_FLOWCHART";

export const SAVE_FLOWCHART = "SAVE_FLOWCHART";

export const SET_FLOWCHART_DETAILS = 'SET_FLOWCHART_DETAILS';


//Create Account Actions
export const CREATE_ACCOUNT_DETAILS = "CREATE_ACCOUNT_DETAILS";
export const CREATE_SUCCESS_ACCOUNT_DETAILS = "CREATE_SUCCESS_ACCOUNT_DETAILS";
export const CREATE_FAIL_ACCOUNT_DETAILS = "CREATE_FAIL_ACCOUNT_DETAILS";
export const FETCH_USER_ACCOUNTS = "FETCH_USER_ACCOUNTS";
export const FETCH_USER_ACCOUNTS_SUCCESS = "FETCH_USER_ACCOUNTS_SUCCESS";
export const FETCH_USER_ACCOUNTS_FAIL = "FETCH_USER_ACCOUNTS_FAIL";

export const SET_EDIT_ACCOUNT = "SET_EDIT_ACCOUNT";


export const HIDE_DELETE_MODAL = "HIDE_DELETE_MODAL";



//Deactivate Account actions
export const DEACTIVATE_ACCOUNT_DETAILS = "DEACTIVATE_ACCOUNT_DETAILS";
export const DEACTIVATE_SUCCESS_ACCOUNT_DETAILS = "DEACTIVATE_SUCCESS_ACCOUNT_DETAILS";
export const DEACTIVATE_FAIL_ACCOUNT_DETAILS = "DEACTIVATE_FAIL_ACCOUNT_DETAILS";

export const DELETE_ACCOUNT_DETAILS = "DELETE_ACCOUNT_DETAILS";
export const DELETE_SUCCESS_ACCOUNT_DETAILS = "DELETE_SUCCESS_ACCOUNT_DETAILS";
export const DELETE_FAIL_ACCOUNT_DETAILS = "DELETE_FAIL_ACCOUNT_DETAILS";


//Login Actions
export const SET_LOGIN_DETAILS = "SET_LOGIN_DETAILS";
export const SET_SUCCESS_LOGIN_DETAILS = "SET_SUCCESS_LOGIN_DETAILS";
export const SET_FAIL_LOGIN_DETAILS = "SEY_FAIL_LOGIN_DETAILS";

export const SET_TOKEN_REFRESH = "SET_TOKEN_REFRESH";
export const SET_TOKEN_REFRESH_SUCCESS = "SET_TOKEN_REFRESH_SUCCESS";
export const SET_TOKEN_REFRESH_FAIL = "SET_TOKEN_REFRESH_FAIL";

export const SET_LOCAL_STORAGE_LOGIN_DETAILS = "SET_LOCAL_STORAGE_LOGIN_DETAILS";

export const SET_INVITE_ACCEPT = "SET_INVITE_ACCEPT";
export const SET_SUCCESS_INVITE_ACCEPT = "SET_SUCCESS_INVITE_ACCEPT";
export const SET_FAIL_INVITE_ACCEPT = "SET_FAIL_INVITE_ACCEPT";


export const GET_TUTORIAL_DATA = "GET_TUTORIAL_DATA";
export const GET_TUTORIAL_DATA_SUCCESS = "GET_TUTORIAL_DATA_SUCCESS";
export const GET_TUTORIAL_DATA_FAIL = "GET_TUTORIAL_DATA_FAIL";

export const SET_TUTORIAL_DATA = "SET_TUTORIAL_DATA";
export const SET_TUTORIAL_DATA_SUCCESS = "SET_TUTORIAL_DATA_SUCCESS";
export const SET_TUTORIAL_DATA_FAIL = "SET_TUTORIAL_DATA_FAIL";


export const SET_PANEL_PERMISSION_TRUE = "SET_PANEL_PERMISSION_TRUE";
export const SET_PANEL_PERMISSION_FALSE = "SET_PANEL_PERMISSION_FALSE";


//Story Page
export const GET_STORY_LIST_DETAILS = "GET_STORY_LIST_DETAILS";
export const GET_SUCCESS_STORY_LIST_DETAILS = "GET_SUCCESS_STORY_LIST_DETAILS";
export const GET_FAIL_STORY_LIST_DETAILS = "GET_FAIL_STORY_LIST_DETAILS";
export const SET_STORY_ID = "SET_STORY_ID";


export const GET_STORY_DETAILS = 'GET_STORY_DETAILS';
export const GET_STORY_DETAILS_SUCCESS = 'GET_STORY_DETAILS_SUCCESS';
export const GET_STORY_DETAILS_FAIL = 'GET_STORY_DETAILS_FAIL';
export const RESET_STORY_DETAILS = 'RESET_STORY_DETAILS';

export const EDITOR_AUTO_SAVE_FLAG = 'EDITOR_AUTO_SAVE_FLAG';
export const GET_BLOCK_NAME_VALID = 'GET_BLOCK_NAME_VALID';
export const GET_BLOCK_NAME_VALID_SUCCESS = 'GET_BLOCK_NAME_VALID_SUCCESS';
export const GET_BLOCK_NAME_VALID_FAIL = 'GET_BLOCK_NAME_VALID_FAIL';
export const SET_BLOCK_NAME_VALID = 'SET_BLOCK_NAME_VALID'

export const SET_SCRIPT = 'SET_SCRIPT';

export const GET_PREVIEW_DATA_ON_CLICK = 'GET_PREVIEW_DATA_ON_CLICK'
export const GET_SUCCESS_PREVIEW_DATA_ON_CLICK = 'GET_SUCCESS_PREVIEW_DATA_ON_CLICK'
export const GET_FAIL_PREVIEW_DATA_ON_CLICK = 'GET_FAIL_PREVIEW_DATA_ON_CLICK'


export const MY_ACCOUNT_UPDATE_DETAILS = 'MY_ACCOUNT_UPDATE_DETAILS'
export const MY_ACCOUNT_UPDATE_DETAILS_FAIL = 'MY_ACCOUNT_UPDATE_DETAILS_FAIL'
export const MY_ACCOUNT_UPDATE_DETAILS_FAILED = 'MY_ACCOUNT_UPDATE_DETAILS_FAILED'
export const MY_ACCOUNT_UPDATE_DETAILS_SUCCESS = 'MY_ACCOUNT_UPDATE_DETAILS_SUCCESS'
export const SET_SELECTED_ACCOUNT_MENU = 'SET_SELECTED_ACCOUNT_MENU'

export const SET_MY_CHANGE_PASSWORD = 'SET_MY_CHANGE_PASSWORD'
export const SET_MY_CHANGE_PASSWORD_SUCCESS = 'SET_MY_CHANGE_PASSWORD_SUCCESS'
export const SET_MY_CHANGE_PASSWORD_FAIL = 'SET_MY_CHANGE_PASSWORD_FAIL'

export const MY_ACCOUNT_DELETE = 'MY_ACCOUNT_DELETE'
export const MY_ACCOUNT_DELETE_SUCCESS = 'MY_ACCOUNT_DELETE_SUCCESS'
export const MY_ACCOUNT_DELETE_FAIL = 'MY_ACCOUNT_DELETE_FAIL'

export const RESET_INVALID_LOGIN_ATTEMPTS = 'RESET_INVALID_LOGIN_ATTEMPTS'
export const RESET_ERROR = 'RESET_ERROR'

export const RESET_DATA = 'RESET_DATA'

export const SET_TOGGLE_CHARACTER = "SET_TOGGLE_CHARACTER"
export const SET_TOGGLE_CHARACTER_SUCCESS = "SET_TOGGLE_CHARACTER_SUCCESS"
export const SET_TOGGLE_CHARACTER_FAILURE = "SET_TOGGLE_CHARACTER_FAILURE"
export const SET_SUCCESS_FALSE = "SET_SUCCESS_FALSE"
import Home from './../../pages/story';
import WriteStory from './../../pages/story/write-story.js';
import Users from './../../pages/accounts';
import Library from './../../pages/library';
import StoryAdmin from './../../pages/storyadmin';
import Characters from './../../pages/characters/characters';
import MyAccount from './../../pages/myaccount';
import Player from './../../pages/player';
import CreateCharacterStepOne from './../../pages/characters/create-character-step-one';
import CreateCharacterStepTwo from './../../pages/characters/create-character-step-two';
import CreateCharacterStepThree from './../../pages/characters/create-character-step-three';
import Interface from './../../pages/interface';
import Views from './../../pages/views';
import CoverImages from '../../pages/coverimages';

export const Routes = [
  {
    headerValue: false,
    path: '/home',
    component: Home
  },
  {
    headerValue: true,
    path: '/write-story',
    component: WriteStory
  },
  {
    headerValue: true,
    path: '/users',
    component: Users
  },
  {
    headerValue: true,
    path: '/stories',
    component: StoryAdmin
  },
  {
    headerValue: true,
    path: '/library',
    component: Library
  },
  {
    headerValue: true,
    path: '/characters',
    component: Characters
  },
  {
    headerValue: true,
    path: '/preview',
    component: Player
  },
  {
    headerValue: true,
    path: '/create-character-step-one',
    component: CreateCharacterStepOne
  },
  {
    headerValue: true,
    path: '/create-character-step-two',
    component: CreateCharacterStepTwo
  },
  {
    headerValue: true,
    path: '/create-character-step-three',
    component: CreateCharacterStepThree
  },
  {
    headerValue: true,
    path: '/interface',
    component: Interface
  },
  {
    headerValue: false,
    path: '/myaccount',
    component: MyAccount
  },
  {
    headerValue: false,
    path: '/changepassword',
    component: MyAccount
  },
  {
    headerValue: false,
    path: '/settings',
    component: MyAccount
  },
  {
    headerValue: true,
    path: '/views',
    component: Views
  },
  {
    headerValue: true,
    path: '/coverimages',
    component: CoverImages
  }
];

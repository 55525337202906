import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import * as Constants from './../../../common/constants';
import {PlaceBgStyle} from './style'

function AddObjectsModal(props) {
    let objectsList = props.assetsList.filter(item => item.type === Constants.ASSET_TYPES_IMAGES[1]);
    
    let editObject = props.editObjectIndex !== null ? props.viewObjects.filter((item, index) => index == props.editObjectIndex) : null;
    let editIndexId = editObject != null ? editObject[0].asset.id : 0;
    let editInteractive = editObject != null ? editObject[0].interactive : false;
    let editHidden = editObject != null ? editObject[0].hidden : false;
    let editXCoordinate = editObject != null ? editObject[0].xcoordinate : 0;
    let editYCoordinate = editObject != null ? editObject[0].ycoordinate : 0;

    const [objectImage, setObjectImage] = useState(editIndexId);
    const [interactive, setInteractive] = useState(editInteractive);
    const [hiddenObject, setHiddenObject] = useState(editHidden);
    const [objectExists, setObjectExists] = useState(false);

    const handleSelectObjectChange = (event) => {
        if(checkObjectExistsInViews(event.target.value) === true) {
            setObjectExists(true);
            return;
        }
        if (event.target.value === '') {
            setObjectImage(0);
        } else {
            setObjectImage(event.target.value);
        }
        
        setObjectExists(false);
    }

    const handleInteractiveChange = () => {
        setInteractive(!interactive);
    }

    const handleHiddenObjectChange = () => {
        setHiddenObject(!hiddenObject);
    }

    const handleAddObjectClick = (assetList) => {
        if (objectExists === true) {
            return ;
        }
        
        let newObject = prepareObject(assetList);

        props.addObject(newObject);
        props.setShowAddObjectForm(false);

        if (props.setAutoSaveFlag) {
            props.setAutoSaveFlag(true);
        }
    }

    const handleSaveClick = (assetList, viewObjects, index) => {
        let newObject = prepareObject(assetList);

        viewObjects[index] = newObject;
        props.setObject(viewObjects);
        props.setShowAddObjectForm(false);
        if (props.setAutoSaveFlag) {
            props.setAutoSaveFlag(true)
        }
    }

    const prepareObject = (assetList) => {
        let object = assetList.filter(item => item.id == objectImage);
        let newObject = {
            asset: {
                id: objectImage,
                name: object[0].name,
                specs: object[0].specs
            },
            objectUniqueId: props.viewObjects !== undefined ?(parseInt(props.viewObjects.length)+1) : 1,
            xcoordinate: editXCoordinate,
            ycoordinate: editYCoordinate,
            hidden: hiddenObject,
            interactive: interactive
        };

        return newObject;
    }

    const checkObjectExistsInViews = (newObjId) => {
        let obj = props.viewObjects.filter(object => newObjId == object.asset.id);

        if (obj.length === 0) {
            return false;
        }

        return true;
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show = {props.showModal}
            onHide = {props.onHideModal}
            backdrop='static'
            className="addObjectModal"
            id="addObjectModal">
            <PlaceBgStyle.addObjectPopup>
            <div  className="add-object-popup">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="header-text">
                    {editObject === null ? `Add` : `Edit`} object into view
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-object-content">
                    <Row>
                        <Form.Label>Object image</Form.Label>
                        <select className="form-control"
                            onChange = {handleSelectObjectChange}>
                            <option value="">Select one</option>
                            {
                                objectsList.length > 0 &&
                                objectsList.map((object, index) => {
                                    return <option value={object.id} 
                                        key={`object-${index}`}
                                        selected = {editObject !== null
                                            && editObject[0].asset.id == object.id ? 'selected' : ''}>
                                            {object.name}
                                        </option>;
                                })
                            }
                        </select>
                    </Row>
                    <Row>
                        {/* <div className="d-flex"> */}
                        <Form.Group className="commandLabelSuffix col mt-3">
                        <div className="d-flex">
                            <label className="switch-flip">
                                <input type="checkbox" 
                                    name="interactiveCheckbox" 
                                    className='checkbox-flip' 
                                    label="interactiveCheckbox"
                                    onChange={handleInteractiveChange}
                                    value = {interactive}
                                    checked = {interactive === true ? 'checked' : null}/>
                                <span className="slider-flip round"></span>
                            </label>
                            <div className="ml-4">
                                <Form.Label>Mark as interactive</Form.Label>
                                <p>This allowes the object to work with the Tap Object command</p>
                            </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="commandLabelSuffix col mt-3">
                        <div className="d-flex">
                            <label className="switch-flip">
                                <input type="checkbox" 
                                    name="hiddenCheckbox" 
                                    className='checkbox-flip' 
                                    label="hiddenCheckbox"
                                    onChange={handleHiddenObjectChange}
                                    value = {hiddenObject}
                                    checked = {hiddenObject === true ? 'checked' : null}/>
                                <span className="slider-flip round"></span>
                            </label>
                            <div className="ml-4">
                            <Form.Label>Mark as hidden</Form.Label>
                            <p>You can make it visible later</p>
                            </div>
                            </div>
                            </Form.Group>
                            {/* </div> */}
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label className="text-danger">
                                {
                                    objectExists &&
                                    `Object already used.`
                                }
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className="btn-snacktool btn-snacktool-golden"
                                onClick={props.onCancelClickAddObject}>
                                Cancel
                            </Button>
                        </Col>
                        <Col className='text-right'>
                            {
                                editObject === null &&
                                <Button type="submit"
                                    className="btn-snacktool btn-snacktool-golden"
                                    disabled = {objectImage !== 0 ? false : true}
                                    onClick = {() => handleAddObjectClick(props.assetsList)}>
                                        Add object
                                </Button>
                            }
                            {
                                editObject !== null &&
                                <Button type="submit"
                                    className="btn-snacktool btn-snacktool-golden"
                                    onClick = {() => handleSaveClick(props.assetsList, props.viewObjects, props.editObjectIndex)}>
                                        Save
                                </Button>
                            }
                        </Col>
                    </Row>
            </Modal.Body>
            </div>
            </PlaceBgStyle.addObjectPopup>
        </Modal>
    );
}

export default AddObjectsModal
import styled from 'styled-components';
import { colors } from '../../common/styles/variables';

export const CreateAccount = styled.div`
  margin: 38px 25px 27px;

  .createAccountButton {
    width: 163px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 2px solid ${colors.primary_green} !important;
    border-radius: 40px !important;
    opacity: 1;
    text-align: center;
    font: Bold 14px/16px Roboto;
    letter-spacing: 0;
    color: ${colors.primary_green};
    opacity: 1;
    box-shadow:none !important;
  }
  .modal-button-wrapper{
      display: flex;
      justify-content: flex-end;
      margin-top:30px;
      .btn-snacktool-primary{
      }
  }
  .accountCreationLoader {
    float: right; 
    font-size: 10px;
    height: 16px;
    width: 16px;
    margin-top: 4px;
  } 
  
`;

 

export const Errors = styled.span`
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
`;

export const ListAccounnt = styled.div`
  padding:0 25px;  
  .accountTable{
    overflow-y:auto;
    width:100%;
    margin: 0px 27px 0 0px;
  }
  .action-btn{
      cursor:pointer;
      height:24px;
    }

`;

export const AccountIcon = styled.div`
  background: #fff 0% 0% no-repeat padding-box;
  .success-icon{
    margin:35px 0 35px !important;
    height:64px;
  }
`;

export const AccountCreated = styled.div`
  text-align: center;
  .heading{
    color: ${colors.shades_shade500};
    font-size: 24px;
  }
    button{
      padding: 0 30px 0 30px;
    }
    .button-space{
      margin:50px 0;
    }
`;

export const AccountDeleted= styled.div`
    margin: 10px 0 0px 0px;
    width: 100%;
    text-align: center;
    color: ${colors.shades_shade500};
    font-size: 22px;
    
    button{
      padding: 0 30px 0 30px;
    }
    
    .row{
      margin: 20px;
    }
   
`;

export const ConfirmModal = styled.div`
  ..modal-backdrop {
    .show {
      opacity: 0.05 !important;
    }
  }

  .btn {
    background: ${colors.primary_green} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 4px #059d7f4a;
    border-radius: 20px;
    border: 1px solid ${colors.primary_green};
  }
`;
export const AccountCreatedEmail = styled.div`
  margin: 20px 0 0px 0px;

  text-align: center;
  font-size: 14px;
  color: #707070;
`;
export const Modal = styled.div`
  .modal-title {
    padding: 0 0 0 35px;
    font-familly: Regular PT Sans;
    font-size: 24px;
    color: ${colors.shades_shade500};
    letter-spacing: 0;
  }
  .btn {
    background: ${colors.primary_green} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 4px #059d7f4a;
    border-radius: 20px;
    border: 1px solid ${colors.primary_green};
    color: #ffffff;
  }

  .modal-footer {
    padding: 1rem 0;
    border: none;
  }
  .modal-body {
    margin: 0 40px 0 40px;
  }
  .form-label {
    font-size: 14px;
    color: #707070;
  }
  .registerError {
    color: red;
  }
`;

export const AccountsTHead = styled.thead`

    height: 40px;
    margin: 100px 0 0px 0px;

    text-align: center;
    font: 11px/14px PT Sans;
    letter-spacing: 1.1px;
    color: #606060;
    text-transform: uppercase;
    font-weight: normal;
  
    background: #F5F5FA 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;

    thead > tr {
      width: 100%;
      height: 40px;
      background: #F5F5FA 0% 0% no-repeat padding-box;
      border-radius: 4px 4px 0px 0px;
      opacity: 1;
    }

    th {
      font-weight:normal;
    }

    .headEmail{
      text-align:left;
    }
`;

export const AccountsTBody = styled.tbody`
  top: 255px;
  left: 257px;
  width: 100%;
  height: 40px;
  margin: 100px 0 0px 0px;

  text-align: left;
  font: Regular 14px/19px PT Sans;
  letter-spacing: 0;
  color: ${colors.shades_shade500};
  opacity: 1;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  .show {
    opacity: 0.05 !important;
  }
`;
export const AccountsTr = styled.tr`
  text-align: center;
  font: 11px/14px PT Sans;
  letter-spacing: 1.1px;
  color: #606060;
  opacity: 1;
  height:45px;  
  padding:5px;

  input[type='checkbox'] {
    display: none;
  }

  td {
    padding: 9px 0px 5px 0px !important;
    vertical-align: unset !important;
  }

  /* The checkboxContainer */
  .checkboxContainer {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 14px;
    margin-left: 11px;
    margin-right: 0px;
  }

  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid ${colors.shades_shade200};
    border-radius: 6px;
    opacity: 1;
  }

  .acctEmail {
    text-align: left;
    font: 14px/19px PT Sans;
    letter-spacing: 0;
    color: ${colors.shades_shade500};
    opacity: 1;
    
  }

  .acctRole {
    text-align: center;
    font: 14px/19px PT Sans;
    letter-spacing: 0;
    color: ${colors.shades_shade500};
    opacity: 1;
    
  }

  .acctStatusBackground {
    background: #dfdfdf 0% 0% no-repeat padding-box;
    border-radius: 46px;
    opacity: 1;
    width: auto;
    height: 23px;
    min-width:69px;
    max-width:160px;
  }

  .acctStatus{
    
  }

  .acctStatusLabel {
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.22px;
    color: #000000de;
    opacity: 1;
    font-weight: normal;
    height: 23px;
    font-weigth: ;
    padding: 4% 6% 9% 6%;
  }

  .acctDate {
    text-align: center;
    font: 12px/15px PT Sans;
    letter-spacing: 0;
    color: #acacac;
    opacity: 1;
    font-weight:normal;
    
    height:17px;
  }

  .actionButtonList {
    border-radius: 50% !important;
    background: #530acd 0% 0% no-repeat padding-box !important;
    border-color: #530acd !important;
    margin-right: 10px !important;
  }

  .tdCheckbox {
   width:10%;
    margin: 11px 10px 10px 16px !important;
  }

  img{
    margin-right:4px;
  }

  .acctActionButtons{
    padding-bottom: 0px !important;
    vertical-align: middle !important;
    .btn-toolbar{
      display:inline;
    }
  }
`;

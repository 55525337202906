import React, {useCallback, useState, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import * as translations from './../translations';
import * as Constants from './../../../common/constants';
import { Card } from 'react-bootstrap';
import { LibraryServices } from './../service';

function UploadFile(props) {

    const maxSize = Constants.ASSET_MAX_FILE_SIZE;
    const { files } = props;

    /* State of component */
    const [cardBorder, setCardBorder] = useState('');
    const [cloudUploadIconColor, setCloudUploadIconColor] = useState('text-secondary');

    const onDragOver = useCallback(() => {
        setCardBorder('primary');
        setCloudUploadIconColor('text-primary');
    }, []);

    const onDragLeave = useCallback(() => {
        setCardBorder('');
        setCloudUploadIconColor('');
    });

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            let files = LibraryServices.createPreviewUrl(acceptedFiles);
            readImageFile(files[0]);
            props.setFiles(files);
            setCardBorder('');
            setCloudUploadIconColor('');
            props.setShowClearFile(true);
        }
    }, [props]);

    function readImageFile(file) {
        var reader = new FileReader(); // CREATE AN NEW INSTANCE.
        reader.onload = function (e) {
            var img = new Image();      
            img.src = e.target.result;
            img.onload = function () {
                var w = this.width;
                var h = this.height;
                props.setSpecs(`${w}x${h}px`);
            }
        };
        return reader.readAsDataURL(file);
    }

    const { 
        isDragActive, 
        getRootProps, 
        getInputProps, 
        isDragReject, 
        rejectedFiles 
    } = useDropzone({
        onDrop,
        accept: Constants.LIBRARY_UI_INTERFACE_FILE_ALLOWED_EXTENSIONS.toString(),
        minSize: 0,
        maxSize:Constants.ASSET_MAX_FILE_SIZE,
        onDragOver,
        onDragLeave
    });

    const isFileTooLarge = LibraryServices.checkFileSize(rejectedFiles, maxSize);
    const thumbs = LibraryServices.createPreviewUIThumb(files);

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className="container text-center lg-12">
            {
                thumbs.length === 0 &&
                <div {...getRootProps()}>
                    <Card bg="light" border={cardBorder}>
                        <Card.Body className="card-content">
                            <i className={`fa fa-cloud-upload lg cloud-upload-icon ${cloudUploadIconColor}`}/>
                            <div className="text-secondary">{translations.DRAG_DROP}</div>
                            <input {...getInputProps()} id="file"/>
                            <Card.Text className="text-secondary">
                                {!isDragActive && translations.YOUR_FILES_UPLOAD_UI_ELEMENT} &nbsp;
                                <span className="text-primary light">
                                    <ins>{!isDragActive && translations.BROWSE}</ins>
                                </span>
                                <br />
                                <span style={{color: '#BFBFBF'}}>{translations.SUPPORTED_FILE_TYPE_MESSAGE}</span>
                                <br />
                                <span style={{color: '#BFBFBF'}}>{translations.FILE_SIZE_MESSAGE}</span>
                            </Card.Text>
                            {isDragActive && !isDragReject && translations.DROP_IT}
                            {isDragReject && translations.FILES_TYPE_NOT_ACCEPTED}
                            {isFileTooLarge && (
                                <div className="text-danger mt-2">
                                    {translations.FILE_IS_TOO_LARGE}
                                </div>
                            )}
                            {!isFileTooLarge && rejectedFiles.length > 0 && (
                                <div className="text-danger">
                                    {translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE_UI_ELEMENT}
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                thumbs.length > 0 &&
                <Card bg="light" border={cardBorder}>
                    <Card.Body className="card-content card-content-custom">
                        <aside className="thumbs-container">
                            {thumbs}
                        </aside>
                </Card.Body>
                </Card>
            }
        </div>
    );
}

export default UploadFile
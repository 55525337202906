export const COLLABORATOR_IN_THIS_STORY = 'Collaborators in this story';
export const VIEWER_IN_THIS_STORY = 'Viewers in this story';

export const SEND_INVITATION = 'Send invitation';
export const SEND_INVITATION_VIEWER = 'Add Viewer';
export const ADD_COLLABORATOR = 'Add collaborator'
export const ADD_VIEWER = 'Add viewer'


export const ADD_STORY_CREDITS = 'Add Story Credits';
export const EDIT_STORY_CREDITS = 'Edit Story Credits';
export const STORY_CREDITS = 'Story Credits';
export const ADD_CREDITS = 'Add Credits'
export const EDIT_CREDITS = 'Edit Credits'

export const CANCEL = 'Cancel'
import React, { useCallback, useState, useEffect } from 'react';
import { LibraryStyle } from './../style/style';
import { Row, Col, Container } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Card } from 'react-bootstrap';
import ListTable from './ListTable';
import * as Constant from './../../../common/constants';

function ListContent(props) {
  return (
    <LibraryStyle.listMainSection>
      {/* <Container> */}
        <Row>
          <Col>
            <ListTable
              fileNames={props.fileNames}
              assetFiles={props.assetFiles}
              handleAssetFileDelete={props.handleAssetFileDelete}
              handleTypeChange={props.handleTypeChange}
              handleNameChange={props.handleNameChange}
              refreshList={props.refreshList}
              handleUploadInfo={props.handleUploadInfo}
              loading={props.loading}
              focusElement={props.focusElement}
              dummyAsset={props.dummyAsset}
              setDummyAsset={props.setDummyAsset}
              show={props.show}
              setShow={props.setShow}
              target={props.target}
            />
          </Col>
        </Row>
      {/* </Container> */}
    </LibraryStyle.listMainSection>
  );
}

export default ListContent;

import styled from 'styled-components';
 


export const modalCollaborator = styled.div`
  font-family: PT Sans;
  color: #303030;
  font-size: 14px;
    .modal-body{
      padding: 30px 70px 30px 70px;
      .modal-title{
        font-size:24px;
      }
      .input-wrapper{
        flex:1;
        position:relative;
        input[type="text"]::-webkit-input-placeholder {
          color: #BFBFBF;
          font-size: 14px !important;
        }
        input[type="text"]::-moz-placeholder {
          color: #BFBFBF;
          font-size: 14px !important;
        }
        input[type="text"]:-ms-input-placeholder {
          color: #BFBFBF;
          font-size: 14px !important;
        }
        input[type="text"]::placeholder {
          color: #BFBFBF;
          font-size: 14px !important;
        }
      }
      .form-label{
        font-weight:bold;
      }
      p{
        letter-spacing: 0;
        color: #707070;
        margin-bottom: 23px;
      }
      .table{
        margin-top: 32px !important;
        .thead-dark{
          th{
          background:#F5F5FA !important;
          letter-spacing: 1.1px;
          color: #606060;
          text-transform: uppercase;
          font-size:11px;
          border-color: #F5F5FA;
          font-weight: normal;
          }
        }
        tbody{
          td{
            font-size: 14px;
            letter-spacing: 0;
            color: #303030;
            border-color: #D6D6D6;
            
            a{
              color:#157FFB;
              text-decoration: underline;
            }
            span{
              color:#ACACAC;
            }
          }
        }
      }
    
  } 
  
`;

export const AutocompleteDiv = styled.div`
  position:absolute;
  border-top:none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border-radius: 4px;
`;
export const autocompleteItems = styled.div`
  padding: 5px;
  cursor: pointer;
  background-color: #fff; 
  border-right: 1px solid #d4d4d4; 
  border-bottom: 1px solid #d4d4d4; 
  border-left: 1px solid #d4d4d4; 
  font-size: 16px;
  &:hover{
    background:#F5F5FA;
  }
`;
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function DeleteCharacterModal(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleHideDeleteModal}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="header-text"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">
              {
                props.sceneRoutePresent === true &&
                <div>
                  This character cannot be deleted as there is a character route created for it.
                  <br />
                  Please remove the route for this character in order to delete it.
                </div>
              }
              {
                (props.characterInfoList && props.characterInfoList.length > 0) &&
                <div>This character cannot be deleted as it is currently being used</div>
              }
            </label>
            <label className="sub-heading">
              Please remove all references to this character in order to delete
              it
            </label>
          </div>

          {
            (props.characterInfoList && props.characterInfoList.length > 0) &&
            <div className="Modal-extra-content">
              <LibraryStyle.tableContainer>
                <thead>
                  <tr>

                    <th className="text-left w-50 text-break d-inline-block">
                      Chapter
                  </th>
                    <th className="text-left w-50 text-break d-inline-block">
                      Block
                  </th>
                  </tr>
                </thead>
                <tbody>
                  {props.characterInfoList !== undefined &&
                    props.characterInfoList.map(char => (
                      <tr>
                        {/* <OverlayTrigger
                          key={`tooltip-${char.id}`}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${char.id}`}>
                              {char.storyTitle}
                            </Tooltip>
                          }
                        >
                          <td className="text-small-light text-left pl-3 text-ellipse w-50 text-break d-inline-block">
                            {char.storyTitle}
                          </td>
                        </OverlayTrigger> */}
                        <OverlayTrigger
                          key={`tooltip-${char.id}`}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${char.id}`}>
                              {char.sceneName}
                            </Tooltip>
                          }
                        >
                          <td className="text-small-light text-left w-50 text-break d-inline-block">
                            {char.sceneName}
                          </td>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key={`tooltip-${char.id}`}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${char.id}`}>
                              {char.blockNames.toString()}
                            </Tooltip>
                          }
                        >
                          <td className="text-small-light text-left w-50 d-inline-block">
                            {char.blockNames.toString()}
                          </td>
                        </OverlayTrigger>
                      </tr>
                    ))}

                </tbody>
              </LibraryStyle.tableContainer>
            </div>
          }
        </div>
        <div className="modal-button-wrapper justify-content-center">
          <Button
            onClick={props.handleHideDeleteModal}
            className="btn-snacktool btn-snacktool-golden"
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default DeleteCharacterModal;

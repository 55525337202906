import React from 'react';
import moment from 'moment';

const buildSpriteLayers = (selectedAsset, spriteLayers, characterAssetList) => {
  // add layer
  let maxPosition = spriteLayers.length;
  selectedAsset.map(assetId => {
    if (!checkSpriteExists(spriteLayers, assetId)) {
      let asset = getCharacterAssetFromList(characterAssetList, assetId);
      spriteLayers[maxPosition] = {
        asset: asset,
        hidden: false,
        id: asset.id
      };
      maxPosition++;
    }
  });

  return spriteLayers;
};

const checkSpriteExists = (spriteLayers, assetId) => {
  var hasMatch = false;
  for (var index = 0; index < spriteLayers.length; ++index) {
    var asset = spriteLayers[index];

    if (parseInt(asset.id) === parseInt(assetId)) {
      hasMatch = true;
      break;
    }
  }

  return hasMatch;
};

const getCharacterAssetFromList = (characterAssetList, assetId) => {
  let asset = characterAssetList.filter(characterAsset => {
    if (assetId === characterAsset.id) {
      return characterAsset;
    }
  });

  return asset[0];
};

const updateVisibilityOfLayers = (assetId, spriteLayers) => {
  let sprites = spriteLayers.map((sprite, spriteKey) => {
    if (spriteKey === assetId) {
      sprite.hidden = !sprite.hidden;
    }

    return sprite;
  });

  return sprites;
};

const removeSpriteLayer = (spriteLayers, assetId) => {
  if (Object.keys(spriteLayers).length > 0) {
    let assetExists = '';
    spriteLayers.map((sprite, spriteKey) => {
      if (sprite.id === assetId) {
        assetExists = spriteKey;
      }
    });

    if (assetExists !== '') {
      spriteLayers.splice(assetExists);
    }
  }

  return spriteLayers;
};

const updateVisibilityOfPreviewLayersEdit = (spriteLayers, varPreviewData) => {
  let spriteUpdates = [];

  for (let i = 0; i < varPreviewData.length; i++) {
    if (varPreviewData[i].defaultAsset === true)
      spriteUpdates.push(varPreviewData[i]['fileIndex']);
  }

  return spriteUpdates;
};

const updatePreviewDataEdit = variationData => {
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i].defaultAsset === true)
      variationData[i]['preview'] = true;
  }

  return variationData;
};

const updateVisibilityOfPreviewLayers = finalSprites => {
  let spriteLayers = [];

  for (let i = 0; i < finalSprites.length; i++) {
    if (finalSprites[i].preview && finalSprites[i].preview === true) {
      spriteLayers.push(finalSprites[i].fileIndex);
    }
  }

  return spriteLayers;
};

const updatePreviewData = (variationData, isPreviewOn, index, assetType) => {
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i].fileIndex === index) {
      variationData[i]['preview'] = isPreviewOn;
    } else if (assetType === variationData[i].assetType) {
      variationData[i]['preview'] = false;
    }
  }

  return variationData;
};

const updatePreviewReorderData = variationData => {
  let sprites = [];
  for (let i = 0; i < variationData.length; i++) {
    if (variationData[i]['preview'] && variationData[i]['preview'] === true)
      sprites.push(variationData[i]['fileIndex']);
  }

  return sprites;
};

const reorderSpriteLayers = (
  spriteLayers,
  sourceIndex,
  targetIndex,
  sourceId,
  targetId
) => {
  // Get current moved sprite

  let selectedSprite = spriteLayers[sourceIndex];
  let sliceOne = [];
  let sliceTwo = [];
  // Remove from current sprite list
  if (sourceIndex < targetIndex) {
    sliceOne = spriteLayers.slice(0, parseInt(targetIndex) + 1);
    sliceTwo = spriteLayers.slice(
      parseInt(targetIndex) + 1,
      parseInt(spriteLayers.length)
    );
  } else {
    sliceOne = spriteLayers.slice(0, parseInt(targetIndex));
    sliceTwo = spriteLayers.slice(
      parseInt(targetIndex),
      parseInt(spriteLayers.length)
    );
  }

  let newSpriteLayersOne = sliceOne.filter(slice => {
    if (slice.fileIndex !== parseInt(sourceId)) {
      return slice;
    }
  });

  let newSpriteLayersTwo = sliceTwo.filter(slice => {
    if (slice.fileIndex !== parseInt(sourceId)) {
      return slice;
    }
  });

  let newSprites = [
    ...newSpriteLayersOne,
    selectedSprite,
    ...newSpriteLayersTwo
  ];

  return newSprites;
};

const getCharacterImagePosition = (index, imageCount) => {
  if (imageCount !== index) {
    return 'frame-image';
  }

  return '';
};

const getVisibleImageCount = spriteLayers => {
  let count = 0;
  spriteLayers.map(sprite => {
    if (sprite.hidden === false) {
      count++;
    }
  });

  return count;
};

const setVariations = (index, variationList, variation, spriteLayers) => {
  let newVariationList = variationList;
  let assetIds = getVisibleSprites(spriteLayers);
  variation['assetIds'] = assetIds;
  if (index === '') {
    newVariationList.push(variation);
  }
  if (index !== '') {
    newVariationList[index] = variation;
  }

  return newVariationList;
};

const checkVariationExists = (variationList, variation) => {
  let exists = false;

  variationList.map(v => {
    if (
      v.name.toLowerCase() === variation.name.trim().toLowerCase() &&
      v.expressions.toLowerCase() === variation.expressions.trim().toLowerCase()
    ) {
      exists = true;
    }
  });

  return exists;
};

const buildFinalCharacter = characterStore => {
  var formData = new FormData();

  let finalVarObject = characterStore.variations;
  var fileEmptyObject = new Blob([''], { type: 'image/png' });

  var filesLength = characterStore.Files.length;

  for (let n = 0; n < characterStore.Files.length; n++) {
    let isExist = false;

    for (let m = 0; m < finalVarObject.length; m++) {
      if (n === finalVarObject[m].fileIndex) {
        isExist = true;
      }
    }

    if (isExist === false) {
      characterStore.Files.splice(n, 1, fileEmptyObject);
    }
  }

  for (let i = 0; i < filesLength; i++) {
    if (typeof characterStore.Files[i] === 'string') {
      formData.append('files', fileEmptyObject);
      for (let j = 0; j < finalVarObject.length; j++) {
        if (finalVarObject[j].fileIndex === i) {
          finalVarObject[j]['fileUploaded'] = false;
        }
      }
    } else {
      // if(finalVarObject[i]){
      //   formData.append("files",characterStore.Files[i]);
      // }
      // else
      // {
      //   formData.append("files",fileEmptyObject);
      // }
      formData.append('files', characterStore.Files[i]);
      for (let k = 0; k < finalVarObject.length; k++) {
        if (characterStore.Files[i] === null) {
          finalVarObject[k]['fileUploaded'] = false;
        } else if (finalVarObject[k].fileIndex === i) {
          finalVarObject[k]['fileUploaded'] = true;
        }
      }
    }
  }

  for (let j = 0; j < finalVarObject.length; j++) {
    delete finalVarObject[j].preview;
    delete finalVarObject[j].awsPath;
    delete finalVarObject[j].extensionId;
    delete finalVarObject[j].file;

    delete finalVarObject[j].originalFileName;
    delete finalVarObject[j].meta;
    delete finalVarObject[j].layerOrder;
    delete finalVarObject[j].isUpload;

    delete finalVarObject[j].fileType;
    delete finalVarObject[j].extension;
    delete finalVarObject[j].size;
    delete finalVarObject[j].isValid;

    delete finalVarObject[j].isUnique;
  }
  let finalCharacter = {
    characterName: characterStore.characterName,
    displayName: characterStore.characterDisplayName,
    characterLongName: characterStore.characterLongName,
    labelColor: characterStore.dialogueColor,

    variations: finalVarObject
  };
  formData.append('character', JSON.stringify(finalCharacter));

  return formData;
};

const getVisibleSprites = spriteLayers => {
  let sprites = [];
  spriteLayers.filter(sprite => {
    if (sprite.hidden === false) {
      sprites.push(sprite.id);
    }
  });

  return sprites;
};

const getImageLayers = spriteLayers => {
  let position = 0;

  let layers = spriteLayers.map((sprite, index) => {
    if (sprite.hidden === false) {
      position++;
      return (
        <img
          src={sprite.asset.awsPath}
          alt="Char image"
          key={index}
          width="295px"
          height="295px"
          className="frame-image"
        />
      );
    }
  });

  return layers;
};

const getPreviewImageLayers = (
  spriteLayers,
  Files,
  isProgress,
  IsCharacterEdit
) => {
  let spritLayer = spriteLayers;
  let layers = [];
  for (let j = 0; j < spritLayer.length; j++) {
    for (let i = 0; i < Files.length; i++) {
      if (spritLayer[j] === i) {
        if (Files[i] !== null) {
          layers.push(
            <img
              src={
                typeof Files[i] === 'string'
                  ? Files[i]
                  : URL.createObjectURL(Files[i])
              }
              alt={j}
              key={j}
              width="295px"
              height="295px"
              //className={getCharacterImagePosition(position-1, visibleImageCount -1)}
              className="frame-image"
            />
          );
        }
      }
    }
  }

  if (isProgress === true) {
    return layers[0];
  } else {
    return layers.reverse();
  }
};

const preparePreviewRowState = characterList => {
  if (characterList !== undefined || characterList.length === 0) {
    return [];
  }

  let states = characterList.map(character => {
    return false;
  });

  return states;
};

const prepareExpressionList = (variationList, variationName) => {
  let expressions = variationList.map(variation => {
    if (variation.name === variationName) {
      return variation.expressions;
    }
  });

  let filtersExpressions = expressions.filter(
    expression => expression != undefined
  );

  return filtersExpressions;
};

const prepareVariations = variationList => {
  let variations = variationList.map(variation => {
    return variation.name;
  });

  var filteredArray = variations.filter(function(item, pos) {
    return variations.indexOf(item) == pos;
  });

  return filteredArray;
};

const getListPreviewSprites = (variations, variationName, expressionName) => {
  let sprites = variations.filter(variation => {
    if (
      variation.name === variationName &&
      variation.expressions === expressionName
    ) {
      return variation.sprites;
    }
  });

  return sprites;
};

const getListCharacterPreviewSprites = (sprite1, sprite2, sprite3, sprite4) => {
  let sprites = [];
  if (sprite1 !== null) {
    sprites.push(sprite1);
  }
  if (sprite2 !== null) {
    sprites.push(sprite2);
  }
  if (sprite3 !== null) {
    sprites.push(sprite3);
  }
  if (sprite4 !== null) {
    sprites.push(sprite4);
  }

  return sprites;
};

const sortCharacterList = (characterList, order) => {
  if (order === 'desc') {
    return characterList
      .sort((a, b) => {
        return moment(new Date(b.createdAt)) - moment(new Date(a.createdAt));
      })
      .reverse();
  }

  return characterList
    .sort((a, b) => {
      return moment(new Date(a.createdAt)) - moment(new Date(b.createdAt));
    })
    .reverse();
};

const sortCharacterListByName = (characterList, order = 'asc') => {
  return characterList
    .sort((a, b) => {
      let diff = a.characterName
        .toLowerCase()
        .localeCompare(b.characterName.toLowerCase());

      return -1 * diff;
    })
    .reverse();
};

const getExpressionStatus = expressionList => {
  if (expressionList !== undefined && expressionList.length === 0) {
    return 'disabled';
  }

  return '';
};

export const CharacterService = {
  buildSpriteLayers,
  updateVisibilityOfLayers,
  reorderSpriteLayers,
  removeSpriteLayer,
  getCharacterImagePosition,
  getVisibleImageCount,
  setVariations,
  buildFinalCharacter,
  getImageLayers,
  checkVariationExists,
  preparePreviewRowState,
  prepareExpressionList,
  prepareVariations,
  getListPreviewSprites,
  getListCharacterPreviewSprites,
  sortCharacterList,
  sortCharacterListByName,
  getExpressionStatus,
  updateVisibilityOfPreviewLayers,
  getPreviewImageLayers,
  updatePreviewData,
  updatePreviewReorderData,
  updateVisibilityOfPreviewLayersEdit,
  updatePreviewDataEdit
};

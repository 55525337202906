import styled from 'styled-components';
import { colors } from './../../../common/styles/variables';
 


const ConfirmBox = styled.div`
height: 350px
display: flex;
justify-content: center;

.btn-okay {
  margin-top: 20%;
  width: 120px;
  height: 40px;
}

.submit-asset-label {
  font-size: 24px !important;
  color: ${colors.shades_shade500};
  margin-top: 0px;
}

.text-note {
  font-size: 14px;
  color: #707070;
}

.cursor-pointer {
  cursor: pointer;
}

`;

const confirmContent = styled.div`
width: 100%;
margin-top: 8%;
text-align: center;
`;
 
const addAssetMessageBox = styled.div`
  display: flex;
  justify-content: center;
  .submit-asset-label {
  font-size: 24px !important;
  color: ${colors.shades_shade500};
  margin-top: 0px;
}
`;

 

const successAssetContent = styled.div`
  width: 100%;
  text-align: center;
  .img-bg{
    height:370px;
    position:relative;
  }
  .img-wrapper{
    width:250px;
    height:250px;
    position:absolute;
    left: 50%;
    transform: translate(-50%);
    bottom:-20px;
    img{
      width:100%;
      height:100%;
    }
  }
  .content-wrapper{
    padding:40px 30px;
  .submit-asset-label{
    font-size:24px;
    letter-spacing: 0px;
    color: #303030;
  }
  .submit-asset-subtitle{
    font-size:14px;
    letter-spacing: 0px;
    color: #707070;
    margin:5px 0;
  }
  .dot-wrapper{
    margin:0 20px;
    .dot{
      width:10px;
      height:10px;
      background:#3B86FF;
      opacity: 0.3;
      cursor:pointer;
      display:block;
      margin-right:7px;
      border-radius:50%;
      &:last-child{
        margin-right:0px;
      }
    }
    .dot.active{
      opacity: 1;
    }
  }
}
  
`;
const modalBox = styled.div`
  .modal-body{
    padding:0;
  }
`;
 

export const InfoStyle = {
   
  ConfirmBox,
  confirmContent,
  addAssetMessageBox,
  successAssetContent,
  modalBox
 
};

import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactHtmlParser from 'react-html-parser';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LibraryServices } from './service';
import * as translations from './translations';
import * as Constants from './../../../common/constants';

function UploadFile(props) {
  const maxSize = Constants.ASSET_MAX_FILE_SIZE;
  const imageWidth = Constants.TITLE_WIDTH;
  const imageHeight = Constants.TITLE_HEIGHT;

  const {
    artTitleFiles,
    editStory,
    storyDetails,
    handleResetArtFile,
    artClearfile,
    asset
  } = props;

  const [cardBorder, setCardBorder] = useState('');
  const [cloudUploadIconColor, setCloudUploadIconColor] = useState(
    'text-secondary'
  );
  const [fileDimensionError, setFileDimensionError] = useState(false);
  const [showError, setShowError] = useState(false);
  const onDragOver = useCallback(() => {
    setCardBorder('primary');
    setCloudUploadIconColor('text-primary');
  }, []);

  const onDragLeave = useCallback(() => {
    setCardBorder('');
    setCloudUploadIconColor('');
  });

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        props.setArtTitleFiles(LibraryServices.createPreviewUrl(acceptedFiles));

        // Validate image dimenstions
        var image = new Image();
        image.addEventListener(
          'load',
          function() {
            if (image.width > imageWidth || image.height > imageHeight) {
              props.setArtTitleFiles([]);

              setCardBorder('danger');
              setFileDimensionError(true);
              setShowError(true);
              props.setShowClearFile(false);
              props.handleResetArtFile(false);
              props.setArtClearFile(false);
            } else {
              props.setAsset({
                ...asset,
                artTitleDimension: `${image.width}x${image.height} px`

                // extension: LibraryServices.getFileExtension(file),
              });
              setFileDimensionError(false);
              props.setShowClearFile(true);
              props.handleResetArtFile(true);
              props.setArtClearFile(true);
            }
          }.bind(this)
        );
        image.src = acceptedFiles[0].preview;

        setCardBorder('');
        setCloudUploadIconColor('');

        props.setContinueToAddInfoClass('button-active');
      }
    },
    [props]
  );

  const onDropRejected = useCallback(
    rejectedFiles => {
      const isFileTooLarge = LibraryServices.checkFileSize(
        rejectedFiles,
        maxSize
      );
      setShowError(true);
      if (isFileTooLarge) {
        setCardBorder('danger');
      }
      setFileDimensionError(false);
    },
    [props]
  );

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: Constants.STORY_FILE_ALLOWED_EXTENSIONS.toString(),
    minSize: 0,
    maxSize: Constants.ASSET_MAX_FILE_SIZE,
    onDragOver,
    onDragLeave,
    onDropRejected
  });

  const isFileTooLarge = LibraryServices.checkFileSize(rejectedFiles, maxSize);

  let thumbs = [];

  if (
    editStory &&
    storyDetails.artTitle === null &&
    artTitleFiles && artTitleFiles.length === 0
  ) {
    thumbs = [];
  } else {
    if (editStory && artClearfile === false) {
      thumbs = [];
    } else {
      thumbs =
        editStory && artTitleFiles.length === 0
          ? `<img src=${storyDetails.artTitle} key=${storyDetails.artTitle} height="188px" alt=''/>`
          : LibraryServices.createPreviewThumb(artTitleFiles, 'art');
    }
  }

  //addded
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (artTitleFiles)
        artTitleFiles.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [artTitleFiles]
  );

  useEffect(() => {
    if (props.activeTabKey === 'first' || props.activeTabKey === 'third') {
      setFileDimensionError(false);
      setShowError(false);
      //props.setSeriesClearFile(false)
      setCardBorder('');
    }
  }, [props.activeTabKey]);

  return (
    <>
      <div className="series-background-upload mt-4">
        <Row>
          <Col className="mt-1" md={4}>
            {(props.storyDetails.artTitle === null) && (
              <div>
                <div {...getRootProps()}>
                  <img
                    src="/images/icons/Upload_button.svg"
                    className="ml-1  btn-upload-file"
                  />
                </div>
              </div>
            )}
            {props.storyDetails.artTitle !== null && (
                <span className="remove-width">
                  <img
                    width="22px"
                    src="/images/icons/clear.svg"
                    className="mr-1 btn-cross"
                  />{' '}
                </span>
              )}
          </Col>

          <Col className="padding-0 " md={8}>
            <div
              onMouseOver={props.highlighttitle}
              onMouseOut={props.resetTitleBorder}
            >
              <div className="title-text d-flex align-items-center">
                Story title
              </div>
              <div className=" subtitle-text-uplaod">
                390x100 pixels max size
              </div>
            </div>
            <div>
              {fileDimensionError === false &&
                !isFileTooLarge &&
                rejectedFiles.length > 0 &&
                showError === true && (
                  <div className="text-danger mt-2">
                    Cannot upload. Supported file formats: PNG
                  </div>
                )}

              {rejectedFiles.length === 0 &&
                fileDimensionError &&
                isFileTooLarge === false &&
                showError === true && (
                  <Card.Text className="text-danger">
                    {translations.INCORRECT_DIMENSIONS}
                  </Card.Text>
                )}
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  {translations.INCORRECT_FILE_SIZE}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UploadFile;

// Add assets
export const SET_ASSET = 'SET_ASSET';
export const SET_ASSET_SUCCESS = 'SET_ASSET_SUCCESS';
export const SET_ASSET_FAIL = 'SET_ASSET_FAIL';

export const SET_ASSET_UPLOAD = 'SET_ASSET_UPLOAD';
export const SET_ASSET_UPLOAD_SUCCESS = 'SET_ASSET_UPLOAD_SUCCESS';
export const SET_ASSET_UPLOAD_FAIL = 'SET_ASSET_UPLOAD_FAIL';

export const SET_SHOW_MODAL_TRUE = 'SET_SHOW_MODAL_TRUE';
export const SET_SHOW_MODAL_FALSE = 'SET_SHOW_MODAL_FALSE';
export const SET_ROLLBACK_ASSET = 'SET_ROLLBACK_ASSET';
export const SET_ASSET_CANCEL = 'SET_ASSET_CANCEL';

export const SET_SHOW_LOCK_MODAL_FALSE = 'SET_SHOW_LOCK_MODAL_FALSE';

export const GET_ASSET_BY_ID = 'GET_ASSET_BY_ID';
export const GET_ASSET_BY_ID_SUCCESS = 'GET_ASSET_BY_ID_SUCCESS';
export const GET_ASSET_BY_ID_FAIL = 'GET_ASSET_BY_ID_FAIL';

export const SET_UPLAOD_CHARACTER = 'SET_UPLAOD_CHARACTER';
export const SET_CHARACTER_ASSET = 'SET_CHARACTER_ASSET';
export const DELETE_CHARACTER_ASSET = 'DELETE_CHARACTER_ASSET';

export const DELETE_CHARACTER_PREVIEW_ASSET = 'DELETE_CHARACTER_PREVIEW_ASSET';

export const SET_CHARACTER_PREVIEW_ASSET = 'SET_CHARACTER_PREVIEW_ASSET';

export const SET_CHAR_SHOW_MODAL_TRUE = 'SET_CHAR_SHOW_MODAL_TRUE';
export const SET_CHAR_SHOW_MODAL_FALSE = 'SET_CHAR_SHOW_MODAL_FALSE';

//Cover
export const SET_COVER = 'SET_COVER';
export const SET_COVER_SUCCESS = 'SET_COVER_SUCCESS';
export const SET_COVER_FAIL = 'SET_COVER_FAIL';

export const GET_COVER_BY_ID = 'GET_COVER_BY_ID';
export const GET_COVER_BY_ID_SUCCESS = 'GET_COVER_BY_ID_SUCCESS';
export const GET_COVER_BY_ID_FAIL = 'GET_COVER_BY_ID_FAIL';

export const SET_COVER_LIST = 'SET_COVER_LIST';
export const SET_COVER_LIST_SUCCESS = 'SET_COVER_LIST_SUCCESS';
export const SET_COVER_LIST_FAIL = 'SET_COVER_LIST_FAIL';

export const SET_COVER_UPLOAD = 'SET_COVER_UPLOAD';
export const SET_COVER_UPLOAD_SUCCESS = 'SET_COVER_UPLOAD_SUCCESS';
export const SET_COVER_UPLOAD_FAIL = 'SET_COVER_UPLOAD_FAIL';

// List asset
export const SET_ASSET_LIST = 'SET_ASSET_LIST';
export const SET_ASSET_LIST_SUCCESS = 'SET_ASSET_LIST_SUCCESS';
export const SET_ASSET_LIST_FAIL = 'SET_ASSET_LIST_FAIL';
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';

// Character actions
export const SET_CHARACTER_NAME_VALIDITY = 'SET_CHARACTER_NAME_VALIDITY';
export const SET_CHARACTER_NAME_VALIDITY_TRUE =
  'SET_CHARACTER_NAME_VALIDITY_TRUE';
export const SET_CHARACTER_NAME_VALIDITY_FALSE =
  'SET_CHARACTER_NAME_VALIDITY_FALSE';

export const RESET_CHARACTER_NAME_VALIDITY = 'RESET_CHARACTER_NAME_VALIDITY';

export const SET_CONTINUE_TO_SELECT_ASSET_ENABLED =
  'SET_CONTINUE_TO_SELECT_ASSET_ENABLED';
export const SET_CONTINUE_TO_SELECT_ASSET_DISABLED =
  'SET_CONTINUE_TO_SELECT_ASSET_DISABLED';

export const SET_DIALOGUE_COLOR = 'SET_DIALOGUE_COLOR';

export const SET_CHARACTER_ASSET_LIST = 'SET_CHARACTER_ASSET_LIST';
export const SET_CHARACTER_ASSET_LIST_SUCCESS =
  'SET_CHARACTER_ASSET_LIST_SUCCESS';
export const SET_CHARACTER_ASSET_LIST_FAIL = 'SET_CHARACTER_ASSET_LIST_FAIL';

export const SET_CHARACTER_NAME = 'SET_CHARACTER_NAME';
export const SET_CHARACTER_DISPLAY_NAME = 'SET_CHARACTER_DISPLAY_NAME';

export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';
export const SET_FILTER_ASSET_LIST = 'SET_FILTER_ASSET_LIST';

export const SET_CONTINUE_TO_VARIATIONS_ENABLED =
  'SET_CONTINUE_TO_VARIATIONS_ENABLED';
export const SET_CONTINUE_TO_VARIATIONS_DISABLED =
  'SET_CONTINUE_TO_VARIATIONS_DISABLED';

export const SET_SPRITE_LAYERS = 'SET_SPRITE_LAYERS';

export const SET_VARIATION = 'SET_VARIATION';
export const SET_REMOVE_VARIATION = 'SET_REMOVE_VARIATION';
export const INITIALIZE_CREATE_CHARACTER = 'INITIALIZE_CREATE_CHARACTER';
export const SET_FINAL_AND_SAVE_CHARACTER_BUTTON_ENABLED =
  'SET_FINAL_AND_SAVE_CHARACTER_BUTTON_ENABLED';
export const SET_FINAL_AND_SAVE_CHARACTER_BUTTON_DISABLED =
  'SET_FINAL_AND_SAVE_CHARACTER_BUTTON_DISABLED';

export const SET_SAVE_FINAL_CHARACTER = 'SET_SAVE_FINAL_CHARACTER';
export const SET_SAVE_FINAL_CHARACTER_SUCCESS =
  'SET_SAVE_FINAL_CHARACTER_SUCCESS';
export const SET_SAVE_FINAL_CHARACTER_FAIL = 'SET_SAVE_FINAL_CHARACTER_FAIL';

export const SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_TRUE =
  'SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_TRUE';
export const SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_FALSE =
  'SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_FALSE';

export const SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_TRUE =
  'SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_TRUE';
export const SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_FALSE =
  'SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_FALSE';

export const SET_SELECT_ALL = 'SET_SELECT_ALL';

export const SET_VARIATION_EXISTS = 'SET_VARIATION_EXISTS';
export const CHECK_VARIATION_EXISTS = 'CHECK_VARIATION_EXISTS';

export const SET_SELECTED_LIBRARY_MENU = 'SET_SELECTED_LIBRARY_MENU';

export const GET_CHARACTERS_BY_ID = 'GET_CHARACTERS_BY_ID';
export const GET_CHARACTERS_BY_ID_SUCCESS = 'GET_CHARACTERS_BY_ID_SUCCESS';
export const GET_CHARACTERS_BY_ID_FAIL = 'GET_CHARACTERS_BY_ID_FAIL';

export const GET_CHARACTERS_BY_LOCK_FAIL = 'GET_CHARACTERS_BY_LOCK_FAIL';

export const SET_CHARACTER_EDIT_TRUE = 'SET_CHARACTER_EDIT_TRUE';
export const SET_CHARACTER_EDIT_FALSE = 'SET_CHARACTER_EDIT_FALSE';

export const SET_GET_CHARACTERS = 'SET_GET_CHARACTERS';
export const SET_GET_CHARACTERS_SUCCESS = 'SET_GET_CHARACTERS_SUCCESS';
export const SET_GET_CHARACTERS_FAIL = 'SET_GET_CHARACTERS_FAIL';

export const SET_GET_ROUTE_CHARACTERS = 'SET_GET_ROUTE_CHARACTERS';
export const SET_GET_ROUTE_CHARACTERS_SUCCESS =
  'SET_GET_ROUTE_CHARACTERS_SUCCESS';
export const SET_GET_ROUTE_CHARACTERS_FAIL = 'SET_GET_ROUTE_CHARACTERS_FAIL';

export const SET_GET_CHARACTERS_SCRIPT = 'SET_GET_CHARACTERS_SCRIPT';
export const SET_GET_CHARACTERS_SCRIPT_SUCCESS =
  'SET_GET_CHARACTERS_SCRIPT_SUCCESS';
export const SET_GET_CHARACTERS_SCRIPT_FAIL = 'SET_GET_CHARACTERS_SCRIPT_FAIL';

export const SET_CHARACTER_LIST_SORT = 'SET_CHARACTER_LIST_SORT';

export const GET_CHARACTER_DELETE = 'GET_CHARACTER_DELETE';
export const GET_CHARACTER_DELETE_SUCCESS = 'GET_CHARACTER_DELETE_SUCCESS';
export const GET_CHARACTER_DELETE_FAIL = 'GET_CHARACTER_DELETE_FAIL';

export const CHARACTER_DELETE = 'CHARACTER_DELETE';
export const CHARACTER_DELETE_SUCCESS = 'CHARACTER_DELETE_SUCCESS';
export const CHARACTER_DELETE_FAIL = 'CHARACTER_DELETE_FAIL';

export const SET_SHOW_DELTE_MODAL_TRUE = 'SET_SHOW_DELTE_MODAL_TRUE';
export const SET_SHOW_DELETE_MODAL_FALSE = 'SET_SHOW_DELETE_MODAL_FALSE';

export const SET_SHOW_CHAR_LOCAK_MODAL_FALSE =
  'SET_SHOW_CHAR_LOCAK_MODAL_FALSE';

export const SET_CHARACTER_LONG_NAME = 'SET_CHARACTER_LONG_NAME';
export const RESET_CHARACTER_LONG_NAME_VALIDITY =
  'RESET_CHARACTER_LONG_NAME_VALIDITY';

// Asset actions

export const GET_ASSET_DELETE = 'GET_ASSET_DELETE';
export const GET_ASSET_DELETE_SUCCESS = 'GET_ASSET_DELETE_SUCCESS';
export const GET_ASSET_DELETE_FAIL = 'GET_ASSET_DELETE_FAIL';

export const GET_COVER_DELETE = 'GET_COVER_DELETE';
export const GET_COVER_DELETE_SUCCESS = 'GET_COVER_DELETE_SUCCESS';
export const GET_COVER_DELETE_FAIL = 'GET_COVER_DELETE_FAIL';

export const ASSET_DELETE = 'ASSET_DELETE';
export const ASSET_DELETE_SUCCESS = 'ASSET_DELETE_SUCCESS';
export const ASSET_DELETE_FAIL = 'ASSET_DELETE_FAIL';

export const COVER_DELETE = 'COVER_DELETE';
export const COVER_DELETE_SUCCESS = 'COVER_DELETE_SUCCESS';
export const COVER_DELETE_FAIL = 'COVER_DELETE_FAIL';

export const COVER_ACTIVE_CHANGE = 'COVER_ACTIVE_CHANGE';

export const SET_SHOW_ASSET_DELTE_MODAL_TRUE =
  'SET_SHOW_ASSET_DELTE_MODAL_TRUE';
export const SET_SHOW_ASSET_DELETE_MODAL_FALSE = 'SET_SHOW_DELETE_MODAL_FALSE';

export const SET_LIBRARY_CHANGELOG = 'SET_LIBRARY_CHANGELOG';
export const SET_LIBRARY_CHANGELOG_SUCCESS = 'SET_LIBRARY_CHANGELOG_SUCCESS';
export const SET_LIBRARY_CHANGELOG_FAIL = 'SET_LIBRARY_CHANGELOG_FAIL';

export const GET_ASSET_EDIT_INFO = 'GET_ASSET_EDIT_INFO';
export const GET_ASSET_EDIT_INFO_SUCCESS = 'GET_ASSET_EDIT_INFO_SUCCESS';
export const GET_ASSET_EDIT_INFO_FAIL = 'GET_ASSET_EDIT_INFO_FAIL';

export const GET_COVER_EDIT_INFO = 'GET_COVER_EDIT_INFO';
export const GET_COVER_EDIT_INFO_SUCCESS = 'GET_COVER_EDIT_INFO_SUCCESS';
export const GET_COVER_EDIT_INFO_FAIL = 'GET_COVER_EDIT_INFO_FAIL';

export const SET_CHARACTER_AUTOSAVE_FLAG = 'SET_CHARACTER_AUTOSAVE_FLAG';
export const RESET_CHARACTER_AUTOSAVE_FLAG = 'RESET_CHARACTER_AUTOSAVE_FLAG';

export const SET_CHARACTER_STEP_ONE = 'SET_CHARACTER_STEP_ONE';

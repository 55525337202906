 

function fetchCharacterByIdAllData(characterList, characterId) {
    const filterCharacter = characterList.filter(character => (parseInt(characterId) === parseInt(character.id)))
    return filterCharacter[0];
}

function fetchCharacterByNameAllData(characterList, characterName) {
    const filterCharacterByName = characterList.filter(character => (characterName === character.name))
    return filterCharacterByName[0];
}

export const characterHelper = {
    fetchCharacterByIdAllData,
    fetchCharacterByNameAllData
};
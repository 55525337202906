import React from 'react';
import { LibraryStyle} from './../style/style';
import { Row, Col} from 'react-bootstrap';
import * as translations from './../translations';

function  Success(props) {
  
    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <Row>
                    <Col className="success-icon mb-3">
                        <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
                    </Col>
                </Row>
                <Row><Col className="submit-asset-label-sucess">
                
                {props.IsAssetEdit===false && props.uploadCount>1 && translations.NEW_ASSETS_SAVE}
                {props.IsAssetEdit===false && props.uploadCount<2 &&  translations.NEW_ASSET_SAVE}
                {props.IsAssetEdit===true ?translations.NEW_ASSET_UPDATE:''}</Col></Row>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Success
import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from './../actions/comment-action-types';
import * as Actions from './../actions/comment-action';
import { postComment,updateComment,
  getAllComments
   } from './api/comment-api';


   export function* setCommentWorker(payloads) {
    if(payloads.authToken){
 
    try {
         const postData = yield call(postComment, payloads);
         
         if (postData.result !== 'Success') {
          
            yield put(Actions.setCommentsFail(postData));
           
         } else {
            yield put(Actions.setCommentsSuccess(postData)); 
            
            yield put({ type: ActionTypes.GET_COMMENT,
              authToken: payloads.authToken,
              storyId: payloads.storyId,
              sceneId:payloads.sceneId
            })
              
         }
    } catch(e) {
        yield put(Actions.setCommentsFail());
    }
  }
  }

  export function* setUpdateCommentWorker(payloads) {
  
    if(payloads.authToken){
    try {
         const postData = yield call(updateComment, payloads);
         
         if (postData.result !== 'Success') {
          
            yield put(Actions.setUpdateCommentsFail(postData));
           
         } else {
            yield put(Actions.setUpdateCommentsSuccess(postData)); 
            
            yield put({ type: ActionTypes.GET_COMMENT,
              authToken: payloads.authToken,
              storyId: payloads.storyId,
              sceneId:payloads.sceneId
            })
              
         }
    } catch(e) {
        yield put(Actions.setUpdateCommentsFail());
    }
  }
  }
  
  export function* getCommentsWorker(payloads) {
    if(payloads.authToken){
    try {
      const data = yield call (getAllComments, payloads);
  
      if (data.result === 'Success') {
          yield put(Actions.getCommentsSuccess(data.body));
      } else {
          yield put(Actions.getCommentsFail());
      }
    } catch (err) {
        yield put(Actions.getCommentsFail());
    }
  }
  }
  
  export function* getCommentsWatcher() {
    yield takeLatest(ActionTypes.GET_COMMENT, getCommentsWorker);
  }
  

  
export function* addCommentDataWatcher() {
  yield takeLatest(ActionTypes.ADD_COMMENT, setCommentWorker);
}

export function* updateCommentDataWatcher() {
  yield takeLatest(ActionTypes.UPDATE_COMMENT, setUpdateCommentWorker);
}




export default {
  addCommentDataWatcher,
  getCommentsWatcher,
  updateCommentDataWatcher
   

};

import React from 'react';
import { LibraryStyle } from './../style/style';
import {  Button } from 'react-bootstrap';
import * as translations from './translations';

function Success(props) {

    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <div className="success-icon mb-3">
                    <img alt="" className="mediumIcon" src="/images/icons/ic_done_24px.svg" />
                </div>
                <div data-testid="success-label" className="submit-asset-label">{props.editStory ? translations.NEW_STORY_UPDATE : translations.NEW_STORY_SAVE}</div>
                <div data-testid="success-note" className="text-muted submit-asset-subtitle ">{!props.editStory && translations.NEW_STORY_SAVE_LABEL}</div>
                <div><Button data-testid="success-btn" onClick={props.handleSuccesClick} className="btn-snacktool btn-snacktool-golden btn btn-primary"
                >
                    Okay
                    </Button></div>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Success


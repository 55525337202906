import React from 'react';
import * as styled from './style';
import { Button, Form, Row, Col } from 'react-bootstrap';
 
import { commonServices } from '../../common/helper/utils';
import * as Config from './../../config/app.config';
import RecaptchaComponent from './recaptcha-component';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const LoginForm = ({
  validated,
  email,
  password,
  error,
  showError,
  enable,
  onSendClick,
  onFormChange,
  setVerified,
  showCaptchaError,
  setShowCaptchaError,
  verified
}) => {

  return (
    <styled.Login>
      <Form noValidate validated={validated} onSubmit={onSendClick} autocomplete="off">
      
     
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <input id="email" style={{display:'none'}} type="text" name="fakeusernameremembered"></input>
          <Form.Control
            required
            type="email"
            name="email"
            id="real-email"
            value={email}
            onChange={onFormChange}
            onInput={onFormChange}
            pattern='^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$'
            autocomplete="nope"
            
          />
          <Form.Control.Feedback type="invalid">
          {showError===false && email!=='' &&  'Please enter a valid email.'}
         
          </Form.Control.Feedback>
           <Row className="loginError">
          <Col md="auto"> {showError && error.map((datum, index) => (
            <styled.Errors>
              {
                datum.split(':')[0] === "email" ? commonServices.filterErrors(datum) : '' 
              }
            </styled.Errors>
          ))}</Col>
        </Row> 
        </Form.Group>

        <Form.Group controlId="validationCustomUsername">
          <Form.Label>Password</Form.Label>
          <input id="password" style={{display:'none'}} type="password" name="fakepasswordremembered"/>
          <Form.Control
            // className = {datum.includes("password") ? 'error-border':'' }
            name="password"
            value={password}
            onChange={onFormChange}
            onInput={onFormChange}  
            onKeyUp={onFormChange}  
            id="real-password"
            type="password"
            required
            autocomplete="new-password"
             
              
          />
          {
            showError === false &&
            <Form.Control.Feedback type="invalid">
              Please enter your password.
            </Form.Control.Feedback>
          }
          <Row className="loginError">
          <Col md="auto"> {showError && error.map((datum, index) => (
            <styled.Errors>
              {
                datum.split(':')[0] === "password" ? commonServices.filterErrors(datum) : '' 
              }
            </styled.Errors>
          ))}</Col>
        </Row>
        </Form.Group>
         
        <Form.Group>
          <GoogleReCaptchaProvider reCaptchaKey={Config.CAPTCHA_SECURITY_TOKEN}>
            {
              verified === false &&
              <RecaptchaComponent setVerified = {setVerified}
                setShowCaptchaError = {setShowCaptchaError}/>
            }
          </GoogleReCaptchaProvider>
          {
            showCaptchaError &&
            <div className="text-danger">Captcha fails to recognise you as a human</div>
          }
        </Form.Group>
        <Form.Group as={Row}>
          <Col xs={7}>
            <styled.Links>
              <a href="/password-recovery">Forgot password ?</a>
            </styled.Links>
          </Col>
          <Col xs={5} className="justifycontent-flexend">
            <Button id="btnLogin" 
              type="submit" 
              className="btn-snacktool btn-snacktool-golden" 
              disabled={enable}  
              onClick={onSendClick}>
              Enter
            </Button>
          </Col>
        </Form.Group>
        <Form.Group as = {Row} className="mt-2">
          <Col xs={7}>
          <Form.Text className="text-secondary">
            This site is protected by Google reCAPTCHA. The Google
            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
          </Form.Text>
          </Col>
        </Form.Group>
        
      </Form>
    </styled.Login>
  );
};
export default LoginForm;

import styled from 'styled-components';

export const modalCollaborator = styled.div`
  font-family: PT Sans;
  color: #303030;
  font-size: 14px;
  .right-align: {
    right: 0px !important;
    float: right !important;
  }
  .modal-body {
    padding: 30px 70px 30px 70px;
    .modal-title {
      font-size: 24px;
    }
    
    .input-wrapper {
      flex: 1;
      position: relative;
      input[type='text']::-webkit-input-placeholder {
        color: #bfbfbf;
        font-size: 14px !important;
      }
      input[type='text']::-moz-placeholder {
        color: #bfbfbf;
        font-size: 14px !important;
      }
      input[type='text']:-ms-input-placeholder {
        color: #bfbfbf;
        font-size: 14px !important;
      }
      input[type='text']::placeholder {
        color: #bfbfbf;
        font-size: 14px !important;
      }
    }
    .form-label {
      font-weight: bold;
    }
    p {
      letter-spacing: 0;
      color: #707070;
      margin-bottom: 23px;
    }
    .table {
      margin-top: 32px !important;
      .thead-dark {
        th {
          background: #f5f5fa !important;
          letter-spacing: 1.1px;
          color: #606060;
          text-transform: uppercase;
          font-size: 11px;
          border-color: #f5f5fa;
          font-weight: normal;
        }
      }
      tbody {
        td {
          font-size: 14px;
          letter-spacing: 0;
          color: #303030;
          border-color: #d6d6d6;

          a {
            color: #157ffb;
            text-decoration: underline;
          }
          span {
            color: #acacac;
          }
        }
      }
    }
  }
`;

export const AutocompleteDiv = styled.div`
  position: absolute;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border-radius: 4px;
`;
export const autocompleteItems = styled.div`
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
  border-right: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  font-size: 16px;
  &:hover {
    background: #f5f5fa;
  }
`;

import React, { useState } from 'react';
import { Form, Accordion, Card, Button } from 'react-bootstrap';
import * as Constant from './../../../common/constants';
import './spin-box.css'

const TextArea = ({
    commandId,
    component,
    maxLengthTextArea,
    defaultValue,
    isCommandEdit,
}) => {

    const [valueTextArea, setValueTextArea] = useState('')

    const handleChangeTextArea = (e) => {
        if (commandId !== Constant.COMMAND_ADD_DIALOGUE) {
            setValueTextArea(`${e.target.value.replace('"', '')}`)
        }
        // setValueTextArea(`${e.target.value.replace('"', '')}`)
    }

    const onKeyPressHandle = (e) => {
        if (e.key === 'Enter' || (e.key === '`' && commandId === Constant.COMMAND_SAY_GENDERED)) {
            e.preventDefault();
            return false;
        }
    }

    let required = component.required ? `required` : '';
    let maxLengthDefault = maxLengthTextArea !== null ? maxLengthTextArea : component.properties.maxLength;
    if (component.suffix === '') {
        let dValue = isCommandEdit && defaultValue != undefined && commandId !== Constant.COMMAND_ADD_DIALOGUE
            ? defaultValue.replace(/\`/g, "")
            : defaultValue;
        return (
            <Form.Group controlId={component.widgetName}>
                {
                    component.properties.hints && component.properties.hints.length > 0 &&
                    component.properties.hints.map((hint, index) => {
                        return (
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            className="textAreaSuffixHeader"
                                            as={Card.Header}
                                            variant="link"
                                            eventKey={index}
                                        >
                                            {hint.id}
                                            <i className="fa fa-caret-down pr-2" />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse className="textAreaSuffixDescription" eventKey={index}>
                                        <Card.Body>
                                            {hint.displayName}
                                        </Card.Body >
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )
                    })
                }
                <Form.Label className="commandLabel">{component.label}</Form.Label>

                <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder={component.placeHolder}
                    label={component.label}
                    maxlength={maxLengthDefault}
                    required={required}
                    defaultValue={dValue}
                    key={component.label}
                    onKeyPress={commandId === Constant.COMMAND_ADD_DIALOGUE || commandId === Constant.COMMAND_SAY_GENDERED ? (event) => onKeyPressHandle(event) : ''}
                />
                <Form.Control.Feedback type="invalid">
                    Field is required
                </Form.Control.Feedback>
            </Form.Group>
        )
    } else {

        return (
            <Form.Group controlId={component.widgetName}>
                <Form.Label className="commandLabel">{component.label}</Form.Label>
                <Form.Label className="commandSubLabel" dangerouslySetInnerHTML={{ __html: `${component.suffix}<br/>` }}></Form.Label>
                <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder={component.placeHolder}
                    label={component.label}
                    maxlength={maxLengthDefault}
                    required={required}
                    defaultValue={isCommandEdit && commandId !== Constant.COMMAND_ADD_DIALOGUE ? defaultValue.replace(/\"/g, "") : defaultValue}
                    onChange={(event) => handleChangeTextArea(event)}
                    value={valueTextArea}
                    key={component.label}
                    onKeyPress={commandId === Constant.COMMAND_ADD_DIALOGUE || commandId === Constant.COMMAND_SAY_GENDERED ? (event) => onKeyPressHandle(event) : ''}
                />
                <Form.Control.Feedback type="invalid">
                    Field is required
                </Form.Control.Feedback>
            </Form.Group>
        )
    }
}


export default TextArea
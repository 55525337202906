import * as ActionTypes from '../actions/action-types';
import * as StoryActionTypes from '../actions/story-action-types';
import * as ScriptEditorActionTypes from '../actions/script-writer-action-types';

import { flowchartServices } from '../common/helper/flowchart';

export const initialState = {
  flowchartNode: [],
  flowchartConnector: [],
  flowchartData: null,
  flowchartDataCnt: 0,
  currentSceneId: null,
  previousStoryId: null,
  showingSceneFlag: true,
  isValidText: '',
  blockData: {},
  script: [],
  storyTitle: '',
  storySubTitle: '',
  currentCharacterRouteId: null,

  flowchartPreviewData: null,
  flowchartAllScenes: null,
  commandData: null,
  flowchartJson: null,
  currentBlockClicked: null,

  storyScenesList: [],
  storyScenesListLoading: true,
  storyScenesListErrors: [],

  flowchartHasErrors: false,
  levelFlowchart: 0,
  loadingPreview:false,
};

export const FlowchartReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoryActionTypes.LIST_SCENE:
      return {
        ...state,
        storyScenesListLoading: true,
        storyScenesListErrors: []
      };

    case StoryActionTypes.LIST_SCENE_SUCCESS:
      return {
        ...state,
        storyScenesList: action.payload.scenes,
        storyTitle: action.payload.title,
        storyScenesListLoading: false,
        storyScenesListErrors: []
      };

    case StoryActionTypes.LIST_SCENE_FAILURE:
      return {
        ...state,
        storyScenesListLoading: false,
        storyScenesListErrors: action.errors
      };

    case ActionTypes.GET_FLOWCHART_DETAILS:
      return {
        ...state
      };

    case ActionTypes.GET_SUCCESS_FLOWCHART_DETAILS:
      let flowchartDetails = action.payload.body;
      return {
        ...state,
        flowchartNode: [],
        flowchartConnector: [],
        showingSceneFlag: action.sceneFlag,
        flowchartData: flowchartDetails,
        storyTitle: flowchartDetails.title ? flowchartDetails.title : '',
        storySubTitle: '',
        currentSceneId: null,
        flowchartPreviewData: flowchartDetails.scenes,
        flowchartAllScenes: flowchartDetails
      };

    case ActionTypes.GET_RESET_FLOWCHART_DETAILS:
      return {
        ...state,
        flowchartNode: [],
        flowchartConnector: [],
        flowchartData: null,
        storySubTitle: '',
        currentSceneId: null,
        flowchartPreviewData: null,
        flowchartAllScenes: null,
        storyRouteList: [],
        levelFlowchart: 0,
        flowchartJson: {}
      };

    case ActionTypes.SAVE_FLOWCHART:
      return {
        ...state,
        flowchartJson:
          Object.keys(action.flowchartJson).length !== 0
            ? JSON.parse(action.flowchartJson)
            : JSON.stringify({})
      };

    case ActionTypes.GET_RESET_ONLY_FLOWCHART:
      return {
        ...state,
        flowchartNode: [],
        flowchartConnector: [],
        levelFlowchart: 0,
        flowchartJson: null
      };

    case ActionTypes.GET_SUCCESS_BLOCK_FLOWCHART_DETAILS:
      const flowchartBlocks = flowchartServices.fetchBlockFlowchartData(
        action.payload.body,
        0
      );
      return {
        ...state,
        flowchartNode: flowchartBlocks.flowchartNode,
        flowchartConnector: flowchartBlocks.flowchartConnector,
        currentCharacterRouteId: action.payload.body.characterRouteId,
        showingSceneFlag: action.sceneFlag,
        storyTitle: action.payload.body.title ? action.payload.body.title : '',
        flowchartData: flowchartBlocks.actionBlocksFinal,
        storySubTitle: flowchartBlocks.actionBlocksFinal.name
          ? flowchartBlocks.actionBlocksFinal.name
          : '',
        currentSceneId: action.sceneId,
        flowchartHasErrors: false,
        levelFlowchart: flowchartBlocks.maxLevel,
        flowchartJson: action.payload.body.blockCoordinates
      };

    case ActionTypes.GET_SUCCESS_PREVIEW_DATA_ON_CLICK:
      return {
        ...state,
        flowchartPreviewData: action.payload.body.scenes,
        loadingPreview: false
      };

    case ActionTypes.GET_PREVIEW_DATA_ON_CLICK:
      return {
        ...state,
        loadingPreview: true
      };

    case ActionTypes.GET_FAIL_PREVIEW_DATA_ON_CLICK:
      return {
        ...state
      };

    case ActionTypes.ADD_NEW_BLOCK_FLOWCHART:
      const flowchartAddBlocks = flowchartServices.fetchBlockFlowchartDataWithAddBlock(
        action.newChapter
      );
      return {
        ...state,
        flowchartNode: flowchartAddBlocks.flowchartNode,
        flowchartConnector: flowchartAddBlocks.flowchartConnector,
        levelFlowchart: flowchartBlocks.maxLevel
      };

    case ActionTypes.GET_FAIL_FLOWCHART_DETAILS:
      return {
        ...state,
        currentSceneId: action.sceneId,
        flowchartNode: [],
        flowchartConnector: [],
        flowchartHasErrors: true,
        levelFlowchart: 0,
        flowchartJson: {}
      };

    case ActionTypes.SET_CURRENT_PREVIOUS_BLOCK_ID:
      return {
        ...state,
        storyId: action.storyId
      };

    case ActionTypes.SET_FLOWCHART_DETAILS:
      return {
        ...state,
        flowchartData: action.flowchartData
      };

    case ActionTypes.GET_BLOCK_NAME_VALID:
      return {
        ...state,
        blockData: {},
        isValidText: ''
      };
    case ActionTypes.GET_BLOCK_NAME_VALID_SUCCESS:
      return {
        ...state,
        isValidText: true,
        blockData: action.payload
      };

    case ActionTypes.GET_BLOCK_NAME_VALID_FAIL:
      return {
        ...state,
        blockData: {},
        isValidText: false
      };

    case ActionTypes.SET_BLOCK_NAME_VALID:
      return {
        ...state,
        blockData: [],
        isValidText: action.isValid
      };

    case ActionTypes.SET_SCRIPT:
      return {
        ...state,
        script: action.script
      };
    // case StoryActionTypes.SET_COMMAND_LEVEL:
    //     return {
    //         ...state,
    //         commandLevel: action.commandLevel
    //     }
    case ScriptEditorActionTypes.SET_FLOWCHART_DATA:
      return {
        ...state,
        flowchartData: action.flowchartData,
        flowchartDataCnt: new Date()
      };

    case ScriptEditorActionTypes.SET_NEWCOMMANDLEVLE1_DATA:
      return {
        ...state,
        commandData: action.commandData
      };

    case StoryActionTypes.CLICK_FLOWCHART:
      const flowchartBlocksAfterClick = flowchartServices.fetchBlockFlowchartData(
        state.flowchartData,
        action.blockId
      );
      return {
        ...state,
        flowchartNode: flowchartBlocksAfterClick.flowchartNode,
        flowchartConnector: flowchartBlocksAfterClick.flowchartConnector,
        levelFlowchart: flowchartBlocksAfterClick.maxLevel
      };

    case StoryActionTypes.RESET_CLICK_FLOWCHART:
      return {
        ...state
      };

    default:
      return {
        ...state
      };
  }
};

import React from 'react';
import { Modal,Button } from 'react-bootstrap';
import * as styled from './../../player/style';
import './../../player/style.css';
import { LibraryServices } from './../service';
import * as Constants from './../../../common/constants';
import * as Config from './../../../config/app.config';


function PlayerModal({
  showModal,
  hidePreviewModal,
  previewTitle,
  interfaceList,
  gameFonts,
  storyTitle
}) {
  let interfaces = LibraryServices.mapInterfaceElements(interfaceList);

  let uploadDialogBackground = interfaces[Constants.interfaceList[0]];
  let defaultDialogBackground = Constants.defaultInterfaceImageList[0];
  let uploadNamePlate = interfaces[Constants.interfaceList[1]];
  let defaultNamePlate = Constants.defaultInterfaceImageList[1];
  let uploadContinueIcon = interfaces[Constants.interfaceList[2]];
  let defaultContinueIcon = Constants.defaultInterfaceImageList[2];
  let uploadTimeBar = interfaces[Constants.interfaceList[3]];
  let defaultTimeBar = Constants.defaultInterfaceImageList[3];
  let dialogBackground = uploadDialogBackground !== null ? uploadDialogBackground : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultDialogBackground;
  let namePlate = uploadNamePlate !== null ? uploadNamePlate : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultNamePlate;
  let continueIcon = uploadContinueIcon !== null ? uploadContinueIcon : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultContinueIcon;
  let timeBar = uploadTimeBar !== null ? uploadTimeBar : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultTimeBar;

  return (
    <Modal
      size="lg"
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="pereviewModal"
    >
    
    
      <Modal.Header >
        <Modal.Title>
          <styled.uielementpreviewtitle>
          <p>{storyTitle}</p>
          <h3 className="mb-0">Game Interface</h3>
          </styled.uielementpreviewtitle>
        </Modal.Title>
        <Button onClick={hidePreviewModal} variant="secondary" className="btn-snacktool-tertiary btn-snacktool">
        Close Preview
        <i className="fa fa-times" aria-hidden="true"></i>
        </Button>
      </Modal.Header>
        <Modal.Body>
      <styled.uielementpreviewwrapper >
        <div className="d-flex justify-content-center">
        <div className="mr-5">
        <styled.uielementpreview 
          className="d-flex justify-content-center align-items-center"
          timeBar = {timeBar}
          Normaldecisionbuttondisabled = {interfaces[Constants.interfaceList[5]]}
          Normaldecisionbuttonnormal = {interfaces[Constants.interfaceList[4]]}
          Paiddecisionbtn = {interfaces[Constants.interfaceList[7]]}
          Currencycoin = {interfaces[Constants.interfaceList[12]]}
          fonts = {gameFonts}>
          <div className="screen-wrapper screen-wrapper-left">
            <div className="normal-decision-disabled-btn d-flex justify-content-center align-items-center">Negotiate</div>
            <div className="paid-decision-btn mt-0 d-flex justify-content-center align-items-center">
              Escape <span className="currency-coin"></span> 99
            </div>
            <div className="normal-decision-btn d-flex justify-content-center align-items-center">Attack</div>
            <div className="slider-wrapper">
              <div className="slider-fill-timer"></div>
            </div>
            
          </div>
          
          
        </styled.uielementpreview>
        <p className="text-center mt-3 mobile-bottom-text">In-game decision</p>
        </div>
        <div>
        <styled.uielementpreview 
          className="d-flex justify-content-center align-items-center"
          Dialoguebackground = {dialogBackground}
          ContinueIcon = {continueIcon}
          NamePlate = {namePlate}
          fonts = {gameFonts}
          >
        <div className="screen-wrapper screen-wrapper-right">
        <div className="right-mobile-top d-flex justify-content-center align-items-center">
          <div className="continue-icon"></div>
        </div>
        <div className="right-mobile-title title-default-bg" id="namePlate">Asra</div>
          <div className="right-mobile-text dialogueBackgroundImage hideText text-default-bg" id="dialogBackground">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            <div className="continue-icon"></div>
          </div>
        </div>
        </styled.uielementpreview>
        <p className="text-center mt-3 mobile-bottom-text">Character dialogue screen</p>
        </div>
        </div>
        <p className="text-center mt-1 bottom-text">Character and background images are intentionally blurred</p>
        </styled.uielementpreviewwrapper >
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
    
  );
}

export default PlayerModal;

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/login';
import SnackContainer from './pages/snack-container';
import LoginContainer from './pages/login-container';
import { GlobalStyles } from './common/styles/globalStyles';
import GlobalFonts from './fonts/GlobalFonts';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './pages/NotFound';
import { Routes } from './common/helper/routes';
import RequestAccount from './pages/request-account';
import PasswordRecovery from './pages/password-recovery';

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <GlobalFonts />
        <Switch>
            <Route exact path="/">  
            <Redirect to="/login" />  
            </Route>
            {
                Routes.map((route, index) => {
                    return <SnackContainer {...route} key = {index} />
                })
            } 
            <LoginContainer  path="/login" component={Login} />
            <LoginContainer path="/request-account" component={RequestAccount} />
            <LoginContainer path="/password-recovery" component={PasswordRecovery} />
            <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;

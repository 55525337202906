import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmDeleteAccount = props => {
  

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal confirm-delete-account"
      className="modalbg"
      size="md"
      show={props.show}
      onHide={() => props.onCancelAccountDeleteClick()}
    >
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">
              {`Are you sure you want to delete the account`}{' '}
              <b>{` ${props.acct.email}?`}</b>
            </label>
            <div className="sub-heading mt-3 mb-3">
              The Stories this user created will become unsearchable by author.
            </div>
          </div>
        </div>
        <div className="modal-button-wrapper justify-content-between">
          <Button
            className="btn-snacktool btn-snacktool-golden"
            onClick={() => props.onCancelAccountDeleteClick()}
          >
            Cancel
          </Button>
          <Button
            onClick={event => props.onConfirmAccountDelete(event, props.acct)}
            className="btn-snacktool btn-snacktool-golden btn btn-primary"
          >
            Yes, delete this account
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmDeleteAccount;

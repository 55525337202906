import React from 'react';
import { LibraryStyle } from './../style/style';
import { Tab, Row, Col } from 'react-bootstrap';
import * as Constant from './../constant';
import UploadFile from './UploadFile';
import SubmitAsset from './../add-asset/SubmitAsset';
import Success from '../AddUIElements/Success';
import Fail from '../AddUIElements/Fail';

function AddElementTab(props) {
  return (
    <LibraryStyle.addInfoContainer>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={Constant.FIRST_TAB_KEY}
        activeKey={props.activeTabKey}
        onSelect={k => props.handleActiveKey(k)}
      >
        <Row>
          <Col sm={12} className="p-0">
            <Tab.Content>
              <Tab.Pane eventKey={Constant.FIRST_TAB_KEY}>
                <UploadFile
                  files={props.files}
                  setFiles={props.setFiles}
                  showClearFile={props.showClearFile}
                  setShowClearFile={props.setShowClearFile}
                  setSpecs={props.setSpecs}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.THIRD_TAB_KEY}>
                <SubmitAsset
                  progress={props.progress}
                  handleActiveKey={props.handleActiveKey}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FOURTH_TAB_KEY}>
                <Success />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FIFTH_TAB_KEY}>
                <Fail message={props.message} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </LibraryStyle.addInfoContainer>
  );
}

export default AddElementTab;

import React from 'react';
import { Form } from 'react-bootstrap';
import { ChromePicker } from 'react-color';


function HiddenTextBox({
    component, 
    hiddenTextBoxData,
    handleColorChange
}) {

    return(
        <Form.Group controlId = {component.widgetName}>
            <Form.Label className="commandLabel">{component.label}</Form.Label>
            <Form.Control 
                type="hidden" 
                className="textlength"
                label={component.label} 
                required = "required"
                index = {component.index}
                value= {hiddenTextBoxData}
            />
            <ChromePicker color={hiddenTextBoxData} onChangeComplete={handleColorChange}/>
            <Form.Control.Feedback type="invalid">
                Field is required
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default HiddenTextBox
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Row,
  Col
} from 'react-bootstrap';
import * as commandPanelStyle from '../../pages/script-writer/style';
import { withRouter } from 'react-router-dom';
import FormEditChapter from './form-edit-chapter';
import ConfirmationWindowDelete from './delete-chapter-confirm';
import SuccessWindowDelete from './okay-delete-chapter';
import ItemListLoader from '../common/list-loader';
import { RouteServices } from './create-chapter/service';
import MarkAsCompletedForm from './mark-as-completed-checkbox';
import {
  STORY_PENDING_APPROVAL,
  SNACK_USER_EDITOR,
  SNACK_USER_ADMIN,
  SNACK_USER_WRITER
} from './../../common/constants';
import story from '../../pages/story';
import { props } from 'lodash/fp';

const onClickGoto = (
  nodeId,
  handleNodeClickforBlock,
  routeId,
  compare,
  handleChangeLogScript = null,
  isCompareFlagPass = false
) => {
  handleNodeClickforBlock(nodeId, routeId, compare, isCompareFlagPass);
  if (compare === true) {
    handleChangeLogScript(nodeId);
  }
};

const SceneList = ({
  flowchartData,
  storyTitle,
  newSceneName,
  handleNodeClickforBlock,
  storyScenesErrors,
  handleSaveList,
  history,
  isViewStory,
  showPanels,
  tokenDataUser,
  isCommentStory,
  handleShowChapterModal,
  handleShowRouteModal,
  handleEditRoute,
  setShowChapterModal,
  setEditChapter,
  handleRouteDeleteClick,
  characterList,
  storyRouteListLoading,
  handleMarkStoryRoute,
  routesData,
  routeButtonState,
  handleShowRouteInfoModal,
  currentStoryStatus,
  handleChangeLogScript,
  libraryChangeLog,
  handleShowLibraryChangeLogClick,
  isViewRoute,
  routeCharaList,
  storyDetails,
  backPage,
  handleDraftStory,
  storyStatus,
  releaseLockChapter,
  viewOnly
}) => {
  const [storyScenesErrorsDisplay, setStoryScenesErrorsDisplay] = useState(
    storyScenesErrors
  );
  const [disabledButton, setDisabledButton] = useState(isViewStory ? true : '');
  const [flowchartChapterDataMain, setFlowchartChapterDataMain] = useState([]);
  const [editAction, setEditAction] = useState(null);
  const [deleteAction, setDeleteAction] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [okayWindow, setOkayWindow] = useState(false);

  // let sourceIndex = 0;
  // let targetIndex = 0;
  // let sourceId = 0;
  // let targetId = 0;
  let buttonState = false;

  if (characterList) {
    if (characterList.length === 0 || isViewStory === true ? true : false) {
      buttonState = true;
    }
  }

  useEffect(() => {
    setStoryScenesErrorsDisplay(storyScenesErrors);
    if (newSceneName === '' || newSceneName === null) {
      setDisabledButton('disabled');
    }
    if (flowchartData !== null) {
      setFlowchartChapterDataMain(flowchartData);
    }
  }, [flowchartData, storyScenesErrors, disabledButton]);

  // const reorderChapterLayers = (
  //   flowchartData,
  //   sourceIndex,
  //   targetIndex,
  //   sourceId,
  //   targetId
  // ) => {
  //   // Get current moved Chapter
  //   let selectedChapter = flowchartData[sourceIndex];
  //   let sliceOne = [];
  //   let sliceTwo = [];
  //   // Remove from current Chapter list
  //   if (sourceIndex < targetIndex) {
  //     sliceOne = flowchartData.slice(0, parseInt(targetIndex) + 1);
  //     sliceTwo = flowchartData.slice(
  //       parseInt(targetIndex) + 1,
  //       parseInt(flowchartData.length)
  //     );
  //   } else {
  //     sliceOne = flowchartData.slice(0, parseInt(targetIndex));
  //     sliceTwo = flowchartData.slice(
  //       parseInt(targetIndex),
  //       parseInt(flowchartData.length)
  //     );
  //   }

  //   let newChapterLayersOne = sliceOne.filter(slice => {
  //     if (slice.id !== parseInt(sourceId)) {
  //       return slice;
  //     }
  //   });

  //   let newChapterLayersTwo = sliceTwo.filter(slice => {
  //     if (slice.id !== parseInt(sourceId)) {
  //       return slice;
  //     }
  //   });

  //   let newChapter = [
  //     ...newChapterLayersOne,
  //     selectedChapter,
  //     ...newChapterLayersTwo
  //   ];

  //   return newChapter;
  // };

  const handleEditChapterProgress = value => {
    let editedChapters = editChapterWithParticularId(
      flowchartData,
      editAction,
      value
    );
    handleSaveList(editedChapters);
    setEditAction(null);
  };

  const onConfirmDelete = () => {
    let editedChapters = editChapterWithParticularId(
      flowchartData,
      deleteAction,
      true
    );
    handleSaveList(editedChapters);
    setEditAction(null);
    setDeleteAction(null);
    setShowConfirm(false);
    setOkayWindow(true);
  };

  const editChapterWithParticularId = (chapterData, chapterId, value) => {
    let indexes = RouteServices.getChapterIndex(chapterData, chapterId);
    let scene = null;
    if (deleteAction !== null) {
      scene = chapterData[indexes.routeIndex].scenes[indexes.chapterIndex];
      scene.deleted = value;
    } else {
      chapterData[indexes.routeIndex].scenes[indexes.chapterIndex].name = value;
    }
    return [scene];
  };

  const handleEditAction = clickedChapterId => {
    setDeleteAction(null);
    setShowChapterModal(true);
    setEditChapter(clickedChapterId);
  };

  const handleDeleteAction = clickedChapterId => {
    setDeleteAction(clickedChapterId);
    setShowConfirm(true);
    setEditAction(null);
  };

  const onCancelClick = () => {
    setDeleteAction(null);
    setShowConfirm(false);
    setEditAction(null);
  };

  const okayWindowClick = () => {
    setDeleteAction(null);
    setShowConfirm(false);
    setEditAction(null);
    setOkayWindow(false);
  };

  const handleBackClick = () => {
    // if (tokenDataUser.roles === SNACK_USER_EDITOR) {
    //   history.push("/stories");
    // } else if (tokenDataUser.roles === SNACK_USER_ADMIN) {
    //   history.push("/stories");
    // } else {
    //   history.push("/home");
    // }

    history.push(backPage);
  };

  let findIndex = null;

  if (flowchartData && flowchartData.length > 0) {
    findIndex = flowchartData.findIndex(route => route.name === 'Prologue');
  }

  const checkRouteCharacterList = () => {
    if (routeCharaList) {
      if (routeCharaList.length === 0) {
        return true;
      }

      if (
        routeCharaList.length === 1 &&
        routeCharaList[0].isDefaultPlayer === true
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div>
      <commandPanelStyle.Header>
        <div className="panel-header">
          <div className="back-arrow" onClick={() => handleBackClick()}>
            <i className="fa fa-angle-left" />
          </div>
          <div className="story-heading-long">
            <div className="storyName">{storyTitle}</div>
            <div className="sceneName">Chapters in this story</div>
          </div>
          {findIndex >= 0 && flowchartData && flowchartData.length > 0 && (
            <div
              className="d-flex align-items-center justify-content-end ml-auto"
              style={{ width: '40%', marginRight: '25px' }}
            >
              {/* {showPanels && isViewStory === false && (
                <MarkAsCompletedForm
                  routeId={flowchartData[findIndex].id}
                  routeValue={flowchartData[findIndex].completed}
                  handleMarkStoryRoute={handleMarkStoryRoute}
                  completeForStory={true}
                />
              )} */}
              {/* {showPanels && isViewStory === false && (
                <OverlayTrigger
                  key={`tooltip-avtar-pro`}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-avtar-pro`} className="tooltip-320">
                      If unchecked, the game player will see that more chapters
                      are going to be released and the story is not complete.
                    </Tooltip>
                  }
                >
                  <span className="info-icon ml-2"></span>
                </OverlayTrigger>
              )} */}
            </div>
          )}
        </div>
      </commandPanelStyle.Header>

      {storyRouteListLoading === true && <ItemListLoader />}
      {storyRouteListLoading === false && (
        <div>
          <commandPanelStyle.Body>
            <div className="create-scene-container">
              {showPanels && (
                <Button
                  className="btn-snacktool btn-snacktool-golden mr-3 mt-4 golden_button"
                  disabled={
                    isViewStory === true ||
                    (storyStatus !== 'New' &&
                      storyStatus !== 'In Progress' &&
                      storyStatus !== 'In Revision' &&
                      storyStatus !== undefined)
                      ? true
                      : false
                  }
                  onClick={() => handleShowChapterModal()}
                >
                  New chapter
                </Button>
              )}
              {showPanels &&
                storyDetails.storyType !== 'ShortStory' &&
                (routeButtonState === false ||
                checkRouteCharacterList() ||
                (storyStatus !== 'New' &&
                  storyStatus !== 'In Progress' &&
                  storyStatus !== 'In Revision') ? (
                  <OverlayTrigger
                    key={`disable-route`}
                    placement="top"
                    overlay={
                      <Tooltip
                        className="disable-route-tooltip"
                        id={`disable-route`}
                      >
                        There are no Characters available to create a Route
                      </Tooltip>
                    }
                  >
                    <div
                      style={{ display: 'inline-block', cursor: 'not-allowed' }}
                    >
                      <Button
                        className="btn-snacktool mt-4 mr-3 btn-snacktool-golden"
                        disabled={
                          routeButtonState === false ||
                          checkRouteCharacterList() ||
                          (storyStatus !== 'New' &&
                            storyStatus !== 'In Progress' &&
                            storyStatus !== 'In Revision' &&
                            storyStatus !== undefined)
                            ? true
                            : buttonState
                        }
                        onClick={() => handleShowRouteModal()}
                      >
                        <span className="folder-shared-icon"></span>
                        Character route
                      </Button>{' '}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <Button
                    className="btn-snacktool mt-4 mr-3 btn-snacktool-golden"
                    disabled={
                      routeButtonState === false ||
                      checkRouteCharacterList() ||
                      (storyStatus !== 'New' &&
                        storyStatus !== 'In Progress' &&
                        storyStatus !== 'In Revision')
                        ? true
                        : buttonState
                    }
                    onClick={() => handleShowRouteModal()}
                  >
                    <span className="folder-shared-icon"></span>
                    Character route
                  </Button>
                ))}

              {routesData.length > 1 &&
                isViewRoute === true &&
                storyDetails.storyType !== 'ShortStory' && (
                  <Button
                    className="btn-snacktool btn-snacktool-brown mt-4 mr-3"
                    disabled={
                      tokenDataUser.roles === SNACK_USER_WRITER &&
                      isViewStory === true
                        ? true
                        : false
                    }
                    onClick={handleShowRouteInfoModal}
                  >
                    <span className="route-info-icon"></span>
                    Routes info
                  </Button>
                )}
              {RouteServices.showLibraryChangeLog(libraryChangeLog) &&
                (currentStoryStatus === 'Pending Approval' ||
                  currentStoryStatus === 'In Revision') && (
                  <Button
                    className="align-items-center btn-snacktool btn-snacktool-brown mt-4 mr-3"
                    onClick={handleShowLibraryChangeLogClick}
                  >
                    <span className="library-changes-icon"></span>
                    See Library Changes
                  </Button>
                )}

              {storyScenesErrors !== null && (
                <div className="sceneError">{storyScenesErrorsDisplay}</div>
              )}
              {flowchartData &&
                flowchartData.length > 0 &&
                flowchartData.map((route, key) => (
                  <div className="scenes-list-wrapper" key={`flowchart-${key}`}>
                    <div className="row mb-2">
                      <Col xs={7}>
                        <div className="d-flex align-items-center">
                          <h3 className="m-0">
                            {route.name === 'Prologue' &&
                            flowchartData.length === 1
                              ? 'Prologue'
                              : route.name}
                          </h3>
                          {route.name !== 'Prologue' &&
                            showPanels === true &&
                            viewOnly !== true && (
                              <Dropdown direction="right">
                                <Dropdown.Toggle className="btnMainStory">
                                  <img
                                    alt=""
                                    className="checkIcon svg-icon-image"
                                    src="/images/icons/ic_settings_24px.svg"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropDownMenuCss">
                                  <Dropdown.Item
                                    className="dropDownItemCss"
                                    onClick={() =>
                                      isViewStory
                                        ? null
                                        : handleEditRoute(
                                            route.id,
                                            route.characterId
                                          )
                                    }
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropDownItemCss"
                                    onClick={() =>
                                      isViewStory
                                        ? null
                                        : handleRouteDeleteClick(route.id)
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <Row>
                          <Col className=" right-col text-right" xs={5}>
                            {' '}
                            Production status
                          </Col>

                          <Col xs={7}>
                            <div className="align-items-center justify-content-end">
                              <MarkAsCompletedForm
                                routeId={route.id}
                                routeValue={route.routeStatus}
                                handleMarkStoryRoute={handleMarkStoryRoute}
                                completeForStory={false}
                                storyStatus={storyStatus}
                                showPanels={showPanels}
                                isViewStory={isViewStory}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* {showPanels && isViewStory === false && (
                              <OverlayTrigger
                                key={`tooltip-avtar${route.id}`}
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`tooltip-avtar${route.id}`}
                                    className="tooltip-320"
                                  >
                                    The game player will see that more chapters
                                    are going to be released for this character
                                    route.
                                  </Tooltip>
                                }
                              >
                                <span className="info-icon ml-2"></span>
                              </OverlayTrigger>
                            )} */}
                      </Col>
                    </div>

                    {route.scenes && route.scenes.length > 0 && (
                      <table className="mt-3 drag-drop-table">
                        <tbody>
                          <tr>
                            <th style={{ width: '50px' }}></th>
                            <th className="text-left w-65">CHAPTERS</th>
                            {((route.name !== 'Prologue' &&
                              storyDetails.storyType === 'RouteStory') ||
                              (route.name === 'Prologue' &&
                                storyDetails.storyType === 'ShortStory')) && (
                              <th className="text-center">ENDING CHAPTER</th>
                            )}
                            <th className="text-center">PUBLISH LIVE</th>
                            <th className="text-center">COMMENTS</th>
                            <th className="text-center">REPLIES</th>
                            <th className="text-center">RESOLVED</th>

                            {isViewStory === false && (
                              <th className="text-center">ACTIONS</th>
                            )}

                            {isViewStory !== false &&
                              currentStoryStatus === STORY_PENDING_APPROVAL &&
                              (tokenDataUser.roles === SNACK_USER_EDITOR ||
                                tokenDataUser.roles === SNACK_USER_ADMIN) && (
                                <th className="text-center">ACTIONS</th>
                              )}
                          </tr>
                          {route.scenes.map(
                            (aScene, index) =>
                              aScene.deleted !== true && (
                                <tr
                                  key={`acene-${index}`}
                                  className={
                                    aScene.lockAcquired === true &&
                                    tokenDataUser.userId !==
                                      aScene.lockAcquiredBy
                                      ? 'bgTr'
                                      : 'empty-007'
                                  }
                                >
                                  <td className="text-center">
                                    {aScene.lockAcquired === true &&
                                    tokenDataUser.roles === SNACK_USER_ADMIN ? (
                                      <OverlayTrigger
                                        key={`tooltip-unlock-${index}`}
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id={`tooltip-unlock-${index}`}
                                          >
                                            Unlock chapter
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          alt=""
                                          className="checkIcon"
                                          src="/images/icons/ic_https_24px.svg"
                                          onClick={() =>
                                            releaseLockChapter(aScene.id, true)
                                          }
                                        />
                                      </OverlayTrigger>
                                    ) : aScene.lockAcquired === true ? (
                                      <img
                                        alt=""
                                        className="checkIcon"
                                        src="/images/icons/ic_https_24px.svg"
                                        
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </td>

                                  <td className="text-left width-20">
                                    {editAction !== null &&
                                    editAction === aScene.id ? (
                                      <FormEditChapter
                                        chapterName={aScene.name}
                                        handleEditChapterProgress={
                                          handleEditChapterProgress
                                        }
                                        allChapterList={flowchartData}
                                      />
                                    ) : (
                                      <span
                                        className="scene-name"
                                        onClick={
                                          aScene.lockAcquired === true &&
                                          tokenDataUser.userId !==
                                            aScene.lockAcquiredBy &&
                                          showPanels === true
                                            ? null
                                            : () =>
                                                onClickGoto(
                                                  aScene.id,
                                                  handleNodeClickforBlock,
                                                  route.id,
                                                  false,
                                                  null,
                                                  aScene.changed
                                                )
                                        }
                                      >
                                        {aScene.name}
                                      </span>
                                    )}
                                  </td>
                                  {((route.name !== 'Prologue' &&
                                    storyDetails.storyType === 'RouteStory') ||
                                    (route.name === 'Prologue' &&
                                      storyDetails.storyType ===
                                        'ShortStory')) && (
                                    <td className="text-center">
                                      {/* <div className="align-items-center">
                                        <label className="switch-flip">
                                          <input
                                            type="checkbox"
                                            name="reverseChapter"
                                            key={`key_${index}`}
                                            className="checkbox-flip"
                                            label="Reversed chapter."
                                            disabled={'disabled'}
                                            checked={
                                              aScene.startEnding == true
                                                ? 'checked'
                                                : ''
                                            }
                                          />
                                          <span className="slider-flip round"></span>
                                        </label>
                                      </div> */}
                                      <span className="scene-name">
                                        {aScene.startEnding == true
                                          ? 'Yes'
                                          : 'No'}
                                      </span>
                                    </td>
                                  )}
                                  {(aScene.lockAcquired === false ||
                                    tokenDataUser.userId ===
                                      aScene.lockAcquiredBy) && (
                                    <td className="text-center">
                                      <div className="align-items-center">
                                        <label className="switch-flip">
                                          <input
                                            type="checkbox"
                                            name="reverseChapter"
                                            key={`key_${index}`}
                                            className="checkbox-flip"
                                            label="Reversed chapter."
                                            disabled={
                                              (storyStatus !== 'New' &&
                                                storyStatus !== 'In Progress' &&
                                                storyStatus !==
                                                  'In Revision') ||
                                              showPanels === false ||
                                              viewOnly === true
                                            }
                                            checked={
                                              aScene.publishLive === true
                                                ? 'checked'
                                                : ''
                                            }
                                            onChange={event =>
                                              handleDraftStory(event, aScene.id)
                                            }
                                          />
                                          <span
                                            className={
                                              aScene.publishLive === true
                                                ? (storyStatus !== 'New' &&
                                                    storyStatus !==
                                                      'In Progress' &&
                                                    storyStatus !==
                                                      'In Revision') ||
                                                  showPanels === false
                                                  ? 'slider-flip round checkRoundBlue'
                                                  : 'slider-flip round'
                                                : 'slider-flip round'
                                            }
                                          ></span>
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {/* ||
                                    tokenDataUser.userId ===
                                      aScene.lockAcquiredBy ||
                                    showPanels === false */}
                                  {aScene.lockAcquired === false && (
                                    <td className="text-center">
                                      {(aScene.totalCommentResolved > 0 ||
                                        aScene.totalReplies > 0 ||
                                        aScene.totalComments > 0) &&
                                      isCommentStory === false ? (
                                        <img
                                          alt=""
                                          className="checkIcon"
                                          src="/images/icons/ic_announcement_24px.svg"
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {(aScene.totalCommentResolved > 0 ||
                                        aScene.totalReplies > 0 ||
                                        aScene.totalComments > 0) &&
                                      isCommentStory === false
                                        ? aScene.totalComments
                                        : ''}
                                    </td>
                                  )}
                                  {aScene.lockAcquired === false && (
                                    <td className="text-center">
                                      {(aScene.totalCommentResolved > 0 ||
                                        aScene.totalReplies > 0 ||
                                        aScene.totalComments > 0) &&
                                      isCommentStory === false
                                        ? aScene.totalReplies
                                        : ''}
                                    </td>
                                  )}
                                  {aScene.lockAcquired === false && (
                                    <td className="text-center">
                                      {(aScene.totalCommentResolved > 0 ||
                                        aScene.totalReplies > 0 ||
                                        aScene.totalComments > 0) &&
                                      isCommentStory === false
                                        ? aScene.totalCommentResolved
                                        : ''}
                                    </td>
                                  )}

                                  {isViewStory === false &&
                                    aScene.lockAcquired === false && (
                                      <td className="text-center">
                                        <OverlayTrigger
                                          key={`tooltip-edit-${index}`}
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-edit-${index}`}
                                            >
                                              Edit chapter
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            alt="edit"
                                            disabled={
                                              isViewStory ? true : false
                                            }
                                            className="svg-icon-image"
                                            title="edit"
                                            onClick={() =>
                                              isViewStory
                                                ? null
                                                : handleEditAction(aScene.id)
                                            }
                                            src="/images/icons/edit.svg"
                                          />
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                          key={`tooltip-delete-${index}`}
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-delete-${index}`}
                                            >
                                              Delete chapter
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            alt="delete"
                                            disabled={
                                              isViewStory ? true : false
                                            }
                                            className="svg-icon-image"
                                            title="delete"
                                            onClick={() =>
                                              isViewStory
                                                ? null
                                                : handleDeleteAction(aScene.id)
                                            }
                                            src="/images/icons/delete.svg"
                                          />
                                        </OverlayTrigger>
                                      </td>
                                    )}
                                  {isViewStory !== false &&
                                    currentStoryStatus ===
                                      STORY_PENDING_APPROVAL &&
                                    (tokenDataUser.roles ===
                                      SNACK_USER_EDITOR ||
                                      tokenDataUser.roles ===
                                        SNACK_USER_ADMIN) &&
                                    aScene.changed === true && (
                                      <td className="text-center">
                                        <OverlayTrigger
                                          key={`tooltip-delete-${index}`}
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-delete-${index}`}
                                            >
                                              See latest changes
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            alt=""
                                            onClick={() =>
                                              onClickGoto(
                                                aScene.id,
                                                handleNodeClickforBlock,
                                                route.id,
                                                true,
                                                handleChangeLogScript,
                                                aScene.changed
                                              )
                                              
                                            }
                                            className="svg-icon-image"
                                            src="/images/icons/compare.svg"
                                          />
                                        </OverlayTrigger>
                                      </td>
                                    )}
                                  {aScene.lockAcquired === true &&
                                    tokenDataUser.userId !==
                                      aScene.lockAcquiredBy && (
                                      <td
                                        colSpan={3}
                                        className="text-right"
                                        style={{ paddingLeft: '5%' }}
                                      >
                                        {`This chapter is being edited by ${aScene.lockAcquiredByUsername}`}{' '}
                                      </td>
                                    )}

                                  {aScene.lockAcquired === true &&
                                    tokenDataUser.userId !==
                                      aScene.lockAcquiredBy && (
                                      <td
                                        colSpan={2}
                                        className="text-left"
                                        style={{ paddingLeft: '2%' }}
                                      >
                                        <a
                                          onClick={() =>
                                            onClickGoto(
                                              aScene.id,
                                              handleNodeClickforBlock,
                                              route.id,
                                              false,
                                              null,
                                              aScene.changed
                                            )
                                          }
                                          href="#"
                                        >
                                          Access in view-only mode
                                        </a>
                                      </td>
                                    )}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}
                    {route.scenes && route.scenes.length === 0 && (
                      <table className="mt-3 drag-drop-table">
                        <tbody>
                          <tr>
                            <td className="empty-row text-center">
                              <img
                                alt=""
                                className="empty-icon mediumIcon"
                                src="/images/icons/ic_folder_24px.svg"
                              />
                              <br />
                              <br />
                              <span className="empty-text">
                                {' '}
                                No chapters created yet
                              </span>
                              <br />
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
            </div>
          </commandPanelStyle.Body>
        </div>
      )}

      <ConfirmationWindowDelete
        show={showConfirm}
        onCancelClick={onCancelClick}
        onConfirmDelete={onConfirmDelete}
      />
      <SuccessWindowDelete
        okayWindow={okayWindow}
        okayWindowClick={okayWindowClick}
      />
    </div>
  );
};

export default withRouter(SceneList);

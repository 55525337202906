
import {endpoint} from "../../config/app.config";

export const postBlockData = (userInput) => {
  return fetch(`${endpoint}/users`, {
    method: "POST",
    body: JSON.stringify({"background":userInput}),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


export const getCharacterList = () => {
  return fetch(`${endpoint}/boxes`).then(res => res.json())
  .then(resp => {
    return resp;
  })
  .catch((err) => {
    throw err;
  });
};

export const getBackgroundList = () => {
  return fetch(`${endpoint}/backgrounds`).then(res => res.json())
  .then(resp => {
    return resp;
  })
  .catch((err) => {
    throw err;
  });
};

export const getBlocksList = () => {
  return fetch(`${endpoint}/blocks`).then(resp => resp.json())
  .then(resp => {
    return resp;
  })
  .catch((err) => {
    throw err;
  })
}

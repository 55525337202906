import React from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

function StoryListRow({
  key,
  storyInfo,
  symbolcolor,
  handleDeleteClick,
  handleShowStoryId,
  handleDuplicateClick,
  handleAdminReviewClick,
  handleSetStoryId,
  isReview,
  showReview,
  handleShowStoryInfoModal
}) {
  const classStatus = `status ${symbolcolor}`;

  return (
    <tr className="table-body-row" id={storyInfo.id}>
      <td className="text-left p-l-20">
        <div className="text-ellipse">
          {' '}
          {storyInfo.publishError !== null &&
            storyInfo.publishError !== '' &&
            storyInfo.status === 'Submitted' && (
              <img
                className="imageWarning"
                src="/images/icons/warning_icon.svg"
              />
            )}{' '}
          {storyInfo.title}
        </div>
      </td>
      <td>{storyInfo.unityStoryId}</td>
      <td>{storyInfo.createdBy}</td>
      <td>
        <div className={classStatus}>
          <span className="circle"></span>
          <label>{storyInfo.status}</label>
        </div>
      </td>
      <td className="text-small-light">
        {moment(new Date(storyInfo.updatedAt)).format('MM-DD-YYYY')}
      </td>
      <td>
        <div className="action-dropdown">
          <Dropdown direction="right">
            <Dropdown.Toggle className="action-dropdown">
              Take Action{' '}
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="">
              {storyInfo.storyOwnerOrCollab === true && showReview === true && (
                <Dropdown.Item
                  onClick={() =>
                    handleSetStoryId(
                      storyInfo.id,
                      storyInfo.status,
                      storyInfo.storyOwnerOrCollab,
                      storyInfo.createdBy
                    )
                  }
                  className="dropDownItemCss d-flex justify-content-between align-items-center"
                >
                  {'Go to story'}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </Dropdown.Item>
              )}

              {storyInfo.storyOwnerOrCollab === false && (
                <Dropdown.Item
                  onClick={() =>
                    handleSetStoryId(
                      storyInfo.id,
                      storyInfo.status,
                      storyInfo.storyOwnerOrCollab,
                      storyInfo.createdBy
                    )
                  }
                  className="dropDownItemCss d-flex justify-content-between align-items-center"
                >
                  {isReview === true && storyInfo.readOnly !== true
                    ? 'Review story'
                    : 'View story'}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </Dropdown.Item>
              )}

              <Dropdown.Item
                onClick={() => handleShowStoryInfoModal(storyInfo.id)}
                className="dropDownItemCss d-flex justify-content-between align-items-center"
              >
                See story info
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </Dropdown.Item>
              {storyInfo.storyOwnerOrCollab === false &&
                storyInfo.readOnly !== true &&
                storyInfo.status !== 'New' &&
                  storyInfo.status === 'Submitted' && (
                  <Dropdown.Item
                    onClick={event =>
                      handleAdminReviewClick(event, storyInfo.id, 'PUBLISH')
                    }
                    className="dropDownItemCss"
                  >
                    {storyInfo.publishError !== null &&
                    storyInfo.publishError !== '' &&
                    storyInfo.status === 'Submitted'
                      ? 'Retry Publish'
                      : 'Publish'}
                  </Dropdown.Item>
                )}
              {storyInfo.storyOwnerOrCollab === false &&
                storyInfo.readOnly !== true &&
                storyInfo.status === 'Published' && (
                  <Dropdown.Item
                    onClick={event =>
                      handleAdminReviewClick(event, storyInfo.id, 'UNPUBLISH')
                    }
                    className="dropDownItemCss"
                  >
                    UnPublish
                  </Dropdown.Item>
                )}
              {storyInfo.storyOwnerOrCollab === false &&
                storyInfo.readOnly !== true &&
                storyInfo.status !== 'New' &&
                  storyInfo.status === 'Pending Approval' && (
                  <Dropdown.Item
                    onClick={event =>
                      handleAdminReviewClick(event, storyInfo.id, 'APPROVED')
                    }
                    className="dropDownItemCss"
                  >
                    Approve
                  </Dropdown.Item>
                )}
              {storyInfo.storyOwnerOrCollab === false &&
                storyInfo.readOnly !== true &&
                storyInfo.status !== 'New' &&
                  storyInfo.status === 'Pending Approval' && (
                  <Dropdown.Item
                    onClick={event =>
                      handleAdminReviewClick(event, storyInfo.id, 'REJECTED')
                    }
                    className="dropDownItemCss"
                  >
                    Reject
                  </Dropdown.Item>
                )}
              {storyInfo.storyOwnerOrCollab === false &&
                storyInfo.status === 'Submitted For Publishing' && (
                  <Dropdown.Item
                    onClick={event =>
                      handleAdminReviewClick(event, storyInfo.id, 'PUBLISH')
                    }
                    className="dropDownItemCss"
                  >
                    {storyInfo.publishError !== null &&
                    storyInfo.publishError !== '' &&
                    storyInfo.status === 'Submitted'
                      ? 'Retry Publish'
                      : 'Retry Publish'}
                  </Dropdown.Item>
                )}
              <Dropdown.Item
                onClick={() => handleDuplicateClick(storyInfo.id)}
                className="dropDownItemCss"
              >
                Duplicate
              </Dropdown.Item>
              {storyInfo.id !== 1 && (
                <Dropdown.Item
                  onClick={() => handleDeleteClick(storyInfo.id)}
                  className="dropDownItemCss"
                  style={{ borderTop: '1px solid #D6D6D6' }}
                >
                  Delete
                </Dropdown.Item>
              )}
              {storyInfo.status !== 'Published' && (
                <Dropdown.Item
                  onClick={() => handleShowStoryId(storyInfo)}
                  className="dropDownItemCss"
                >
                  Update Story ID
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}

export default StoryListRow;

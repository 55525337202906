import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Row
} from 'react-bootstrap';

import * as translations from './translations';
import { ChapterStyle } from './style';
import UploadFile from './UploadFile';
import { RouteServices } from './service';
import ItemListLoader from '../../common/spin-loader';
import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

function CreateRoute(props) {
  const {
    route,
    setRoute,
    characterList,
    setShowClearFile,
    showClearFile,
    handleCreateRoute,
    isRouteEdit,
    routesData,
    validated,
    loading,
    editRouteId,
    setRouteValidated,
    charLoading,
    refreshRouteList
  } = props;
  let routeTypeOptions = [];

  const [addInfoClass, setAddInfoClass] = useState(true);
  const [disableCharacterList, setDisableCharacterList] = useState(false);
  const [showRouteError, setShowRouteError] = useState(false);
  const [showFileError, setShowFileError] = useState(false);

  const [initialRouteStep, setInitialRouteStep] = useState(0);
  const [stepsRoute, setStepsRoute] = useState(translations.stepRouteConstant);

  const [stepsRouteEnabled, setStepsRouteEnabled] = useState(false);

  useEffect(() => {
    setAddInfoClass(isRouteEdit === true ? false : true);

    if (isRouteEdit === true) {
      let checkChapters = RouteServices.checkChapterExist(
        props.routesData,
        route.characterId
      );
      setDisableCharacterList(checkChapters === true ? true : false);
    } else {
      setDisableCharacterList(false);
    }

    setTimeout(function() {
      setStepsRouteEnabled(
        props.tutorials.firstCharacterRoute === false ? true : false
      );
    }, 1000);
  }, [isRouteEdit, props.characterList, refreshRouteList, props.tutorials]);

  const handleHideTutorial = () => {
    setStepsRouteEnabled(false);
    props.onCharacterRouteExit();
  };
  const handleClearFile = () => {
    props.setFiles(null);
    setShowClearFile(false);
    //   setShowFileError(true);
    //  setAddInfoClass(true);
  };

  const handleFormChange = event => {
    if (event.target.name === 'characterId') {
      if (
        isRouteEdit === true &&
        editRouteId.toString() !== event.target.value.toString()
      ) {
        let checkRoute = RouteServices.checkRouteExist(
          props.routesData,
          event.target.value
        );
        if (checkRoute === true) {
          setAddInfoClass(true);
          setShowRouteError(true);
        } else {
          setShowRouteError(false);
        }
      }
    }
    setRoute({
      ...route,
      [event.target.name]: event.target.value
    });

    if (
      event.currentTarget.form.checkValidity() === false ||
      (event.target.name === 'characterId' && event.target.value === '')
    ) {
      setAddInfoClass(true);
    } else {
      setAddInfoClass(false);
    }

    props.setRouteValidated(true);
  };

  if (characterList && characterList.length > 0) {
    routeTypeOptions = characterList.map((char, key) => {
      if (char.isDefaultPlayer === null || char.isDefaultPlayer === false) {
        return (
          <option key={key} value={char.id}>
            {RouteServices.getRouteTypeLabel(
              char.characterName,
              char.displayName
            )}
          </option>
        );
      }
    });
  }
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={() => props.handleHideRouteModal()}
      backdrop="static"
    >
      <ChapterStyle.addInfoContainer className="step-route">
        <Modal.Header closeButton>
          {loading === false && (
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="header-text"
            >
              {isRouteEdit === true
                ? translations.UPDATE_NEW_ROUTE
                : translations.CREATE_NEW_ROUTE}
            </Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <Steps
            enabled={stepsRouteEnabled}
            steps={stepsRoute}
            initialStep={initialRouteStep}
            onExit={handleHideTutorial}
            options={translations.INTRO_OPTIONS}
          />

          {loading === true && (
            <Row className="row-100">
              <ItemListLoader />
            </Row>
          )}

          {loading === false && (
            <div>
              <Form
                id="add-route-form"
                noValidate
                validated={validated}
                onSubmit={e => props.handleCreateRoute(e)}
              >
                <Form.Group>
                  <div className="row">
                    <div className="col mr-4 mb-1">
                      <Form.Label>Character</Form.Label>
                      <p className="lable-bottom-text">
                        This character name and portrait will be displayed in
                        the game info.
                      </p>
                      {disableCharacterList === false && (
                        <Form.Control
                          as="select"
                          name="characterId"
                          onChange={handleFormChange}
                          value={route.characterId}
                          disabled={disableCharacterList}
                          required
                        >
                          <option value="">Select one</option>
                          {routeTypeOptions}
                        </Form.Control>
                      )}
                      {disableCharacterList === true && (
                        <input
                          className="disabled-text"
                          disabled
                          value={RouteServices.getRouteTypeLabelName(
                            characterList,
                            route.characterId
                          )}
                        ></input>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Please select character name.
                      </Form.Control.Feedback>
                      {showRouteError && (
                        <Form.Text className="text-danger">
                          This character already has a route.
                        </Form.Text>
                      )}
                    </div>
                    <div className="upload-file mb-1">
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          Character portrait
                          <OverlayTrigger
                            key={`tooltip-avtar`}
                            placement="top"
                            overlay={
                              <Tooltip
                                id={`tooltip-avtar`}
                                className="tooltip-320"
                              >
                                Supported file format: PNG. <br /> File
                                dimensions: 1024x1024 pixels. <br />
                                File size limit: 15 Mb.
                              </Tooltip>
                            }
                          >
                            <span className=" ml-2">
                              <img
                                alt=""
                                className="checkIcon"
                                src="/images/icons/ic_info_24px.svg"
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </Form.Label>
                      <Form.Label>
                        <span className="secondary-text">
                          {' '}
                          1024x1024 pixels
                        </span>
                      </Form.Label>

                      <UploadFile
                        setFiles={props.setFiles}
                        files={props.files}
                        setShowClearFile={setShowClearFile}
                        showRouteError={showRouteError}
                        showClearFile={showClearFile}
                        handleClearFile={handleClearFile}
                        route={route}
                        setRoute={setRoute}
                        setAddInfoClass={setAddInfoClass}
                        isRouteEdit={isRouteEdit}
                        setShowFileError={setShowFileError}
                        showFileError={showFileError}
                        isCharacterRoute={true}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Character's last name (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    name="chapterLastName"
                    autocomplete="off"
                    onChange={handleFormChange}
                    onKeyUp={handleFormChange}
                    maxlength="12"
                    defaultValue={route.chapterLastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {/* {translations.DESCRIPTION_ERROR_MESSAGE} */}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Summary
                    <Form.Text className="text-muted">
                      Limit: 355 characters
                    </Form.Text>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="textarea"
                    className="textAreaResize"
                    rows="5"
                    maxlength="355"
                    minlength="1"
                    required
                    autocomplete="off"
                    name="description"
                    value={route.description}
                    onChange={handleFormChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a summary.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
              <div className="modal-button-wrapper justify-content-between">
                <Button
                  onClick={() => props.handleHideRouteModal()}
                  className="btn-snacktool btn-snacktool-golden"
                >
                  Cancel
                </Button>
                <Button
                  onClick={e => props.handleCreateRoute(e)}
                  disabled={addInfoClass}
                  form="add-chapter-form"
                  className="btn-snacktool btn-snacktool-golden"
                >
                  {isRouteEdit === true ? 'Update route' : 'Add route'}
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </ChapterStyle.addInfoContainer>
    </Modal>
  );
}
export default CreateRoute;

import React, { useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import CharactersChangeLog from './characters-change-log';
import AssetsChangeLog from './assets-change-log';
import GameInterfaceChangeLog from './game-interface-change-log';
import ViewsChangeLog from './views-change-log';
import { commonServices } from './../../../common/helper/utils';
import * as style from '../style/library-change-style';
import { LibraryServices } from './../service';

function LibraryChangeLog(props) {

    const [charactersLogSorts, setCharactersLogSorts] = useState('desc');
    const [assetsLogSort, setAssetsLogSort] = useState('desc');
    const [gameInterfacesLogSort, setGameInterfacesLogSort] = useState('desc');
    const [viewsLogSort, setViewsLogSort] = useState('desc');

    const handleSortClick = (event) => {
        let sortName = event.target.getAttribute('sort-name');
        let newSort = '';
        switch(sortName) {
            case 'characters':
                newSort = charactersLogSorts === 'desc' ? 'asc' : 'desc';
                setCharactersLogSorts(newSort);
            break;
            case 'assets':
                newSort = assetsLogSort === 'desc' ? 'asc' : 'desc';
                setAssetsLogSort(newSort);
            break;
            case 'gameInterfaces':
                newSort = gameInterfacesLogSort === 'desc' ? 'asc' : 'desc';
                setGameInterfacesLogSort(newSort);
            break;
            case 'views':
                newSort = viewsLogSort === 'desc' ? 'asc' : 'desc';
                setViewsLogSort(newSort);
            break;
        }
    }

    return (
        
        <Modal
            show={props.showModal}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            onHide={props.onHide}
            dialogClassName="libraryChangeLog">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="mb-0">Library changes history</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <style.libraryChangeLogModal>
                    <Tabs defaultActiveKey={LibraryServices.getDefaultActiveTabInLibraryChangeLog(props.libraryChangeLog)}>
                        <Tab eventKey="characters" 
                            title="Characters">
                            <style.libraryChangeLogModalContant>
                                <CharactersChangeLog characters = {commonServices.sortListOthers(props.libraryChangeLog.characters, charactersLogSorts)}
                                    charactersLogSorts = {charactersLogSorts}
                                    handleSortClick = {handleSortClick}/>
                            </style.libraryChangeLogModalContant>
                        </Tab>
                        <Tab eventKey="assets" 
                            title="Backgrounds and Objects">
                            <style.libraryChangeLogModalContant>
                                <AssetsChangeLog assets = {commonServices.sortListOthers(props.libraryChangeLog.assets, assetsLogSort)}
                                    assetsLogSort = {assetsLogSort}
                                    handleSortClick = {handleSortClick}/>
                            </style.libraryChangeLogModalContant>
                        </Tab>
                        <Tab eventKey="gameInterface" 
                            title="Game interface">
                            <style.libraryChangeLogModalContant>
                                <GameInterfaceChangeLog gameInterfaces = {commonServices.sortListOthers(props.libraryChangeLog.gameInterfaces, gameInterfacesLogSort)}
                                    gameInterfacesLogSort = {gameInterfacesLogSort}
                                    handleSortClick = {handleSortClick}/>
                            </style.libraryChangeLogModalContant>
                        </Tab>
                        <Tab eventKey="views" 
                            title="Views">
                            <style.libraryChangeLogModalContant>
                                <ViewsChangeLog views = {commonServices.sortListOthers(props.libraryChangeLog.views, viewsLogSort)}
                                    viewsLogSort = {viewsLogSort}
                                    handleSortClick = {handleSortClick}/>
                            </style.libraryChangeLogModalContant>
                        </Tab>
                    </Tabs>
                    </style.libraryChangeLogModal>
                </Modal.Body>
            </Modal>
            
    )
}

export default LibraryChangeLog
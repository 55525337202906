import React from 'react';
import * as translations from '../create-story/translations';
import { Button, Row, Col } from 'react-bootstrap';
import './../style/style.css';
import * as Constant from '../create-story/constant';

function StoryInfoBottom(props) {
    return (
        <div className="mt-4">
        <Row>
            <Col md={6} className="footer-left-button-story d-flex align-items-center p-0">
            
                {
                    props.activeTabKey === Constant.SECOND_TAB_KEY &&
                    <> 
                        <i className="fa fa-arrow-left cpointer mr-2" />
                        <span onClick={() => props.handleActiveTabKey(Constant.FIRST_TAB_KEY)}>
                            {translations.BACK}
                        </span>
                    </>
                }
                {
                    props.activeTabKey === Constant.THIRD_TAB_KEY &&
                    <> 
                        <i className="fa fa-arrow-left cpointer mr-2" />
                        <span onClick={() => props.handleActiveTabKey(Constant.SECOND_TAB_KEY)}>
                            {translations.BACK}
                        </span>
                    </>
                }
                
               
            </Col>
            <Col md={6} className="text-right footer-right-button p-0">
                {
                    props.activeTabKey === Constant.SECOND_TAB_KEY &&
                    <Button  className={`btn-snacktool btn-snacktool-golden  `}
                        onClick={() => props.handleActiveTabKey(Constant.THIRD_TAB_KEY)}>
                       {translations.GO_TO_ADDITIONAL_INFO}
                    </Button>
                }
                {
                    props.activeTabKey === Constant.FIRST_TAB_KEY &&
                    <Button className="btn-snacktool btn-snacktool-golden"
                        onClick = {() => props.handleActiveTabKey(Constant.SECOND_TAB_KEY)}>
                    {translations.GO_TO_COVER_IMAGES} 
                    </Button>
                }
                {
                    props.activeTabKey === Constant.THIRD_TAB_KEY &&
                    <Button className="btn-snacktool btn-snacktool-golden"
                        onClick = {props.onHideModal}>
                        {translations.CLOSE}
                    </Button>
                }
            </Col>
        </Row>
        </div>
    )
}

export default StoryInfoBottom
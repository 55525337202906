import { takeLatest, put, call } from 'redux-saga/effects';
import * as Actions from './../actions/player-action';
import * as ActionTypes from './../actions/player-action-types';
import { PlayerService } from './../components/player/service/player-service';
import { 
   
    preloadWithCanvas,
    preloadWithoutCanvas,
    prepareBlobImageWithCanvas,
    prepareBlobImagesWithoutCanvas,
    preloadCharacterSVGImages,
    preloadBackgroundSVGImages
} from './api/player-api';

export function* playerModelDataWorker(payload) {
    try {
        yield put(Actions.setLoadingTrue());
        const images = yield PlayerService.collectImages(payload.flowchartPreviewData);
        const interfaceImages = yield PlayerService.collectInterfaceImages(payload.gameInterfaceList);

        if (Object.keys(images.images).length > 0 || interfaceImages.length > 0) {
            yield put({type: ActionTypes.SET_LOADING_MESSAGE, loadingMessage: 'SVG character images'});
            const characterSvg = yield call (preloadCharacterSVGImages, { images: images.imagesWithCanvas })

            yield put({type: ActionTypes.SET_LOADING_MESSAGE, loadingMessage: 'character images'});
            const dataWithCanvas = yield call(preloadWithCanvas, {
                images: images.imagesWithCanvas,
                aspectRatio: payload.aspectRatio,
                svgImages: characterSvg
            });

            yield put({type: ActionTypes.SET_LOADING_MESSAGE, loadingMessage: 'SVG background images'})
            const backgroundSvg = yield call(preloadBackgroundSVGImages, {images: images.imagesWithoutCanvas});

            yield put({type: ActionTypes.SET_LOADING_MESSAGE, loadingMessage: 'background images'});
            const dataWithoutCanvas = yield call(preloadWithoutCanvas, {
                images: images.imagesWithoutCanvas,
                svgImages: backgroundSvg.filter(item => item !== undefined),
                isAsset: true
            });

            yield put({type: ActionTypes.SET_LOADING_MESSAGE, loadingMessage: 'game interface'});
            const gameInterfaceData = yield call(preloadWithoutCanvas, {
                images: interfaceImages,
                svgImages: [],
                isAsset: false
            });
            
            const blobImagesWithCanvas = yield call(prepareBlobImageWithCanvas, {
                data: dataWithCanvas,
                images: images.imagesWithCanvas,
            });

            const blobImagesWithoutCanvas = yield call(prepareBlobImagesWithoutCanvas, {
                data: dataWithoutCanvas,
                images: images.imagesWithoutCanvas,
                isAsset: true
            });

            const blobGameInterfaceImages = yield call(prepareBlobImagesWithoutCanvas, {
                data: gameInterfaceData,
                images: interfaceImages,
                isAsset: false
            })


            let bloblImg = {...blobImagesWithoutCanvas, ...blobImagesWithCanvas, ...blobGameInterfaceImages};
            if (images.imagesWithCanvas.length > 0
                || images.imagesWithoutCanvas.length > 0
                || interfaceImages.length > 0) {
                yield put(Actions.setBlobImages(bloblImg));
                yield put(Actions.setLoadingFalse());
            }
            yield put({type: ActionTypes.RESET_LOADING_MESSAGE, loadingMessage: ''});
        } else {
            yield put(Actions.setLoadingFalse());
        }
        
    } catch (err) {
        yield put(Actions.setLoadingTrue());
    }
 }

export function* playerModelDataWatcher() {
    yield takeLatest(ActionTypes.SET_PLAYER_MODAL_TRUE, playerModelDataWorker);
}

export default {
    playerModelDataWatcher
}
import * as ActionTypes from './../actions/library-action-types';

export const initialState = {
  asset: '',
  assetStatus: '',
  assetList: [],
  showModal: false,
  showCharModal: false,
  loading: false,
  selectedMenu: 'characters',
  assetInfoList: null,
  assetData: [],
  IsAssetEdit: false,
  showLockModal: false,
  changeLog: {
    assets: [],
    gameInterfaces: [],
    characters: [],
    views: [],
    gameFonts: null
  },
  assetInfoListEdit: null,
  coverInfoListEdit: null,
  isUploadAsset: false,
  showProgress: false,
  coverList: []
};

export const LibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ASSET:
      return {
        ...state,
        asset: action.payload
      };
    case ActionTypes.SET_ASSET_SUCCESS:
      return {
        ...state,
        assetStatus: 'success',
        showModal: true,
        asset: action.payload
      };
    case ActionTypes.SET_ASSET_FAIL:
      return {
        ...state,
        assetStatus: 'fail',
        showModal: true,
        asset: ''
      };

    case ActionTypes.SET_COVER:
      return {
        ...state,
        asset: action.payload
      };
    case ActionTypes.SET_COVER_SUCCESS:
      return {
        ...state,
        assetStatus: 'success',
        showModal: true,
        asset: action.payload
      };
    case ActionTypes.SET_COVER_FAIL:
      return {
        ...state,
        assetStatus: 'fail',
        showModal: true,
        asset: ''
      };

    case ActionTypes.SET_ASSET_UPLOAD:
      return {
        ...state,
        asset: action.payload,
        isUploadAsset: true,
        showProgress: true
      };

    case ActionTypes.SET_ASSET_UPLOAD_SUCCESS:
      return {
        ...state,
        assetStatus: 'success',
        showModal: true,
        asset: action.payload,
        isUploadAsset: false,
        showProgress: false
      };

    case ActionTypes.SET_ASSET_UPLOAD_FAIL:
      return {
        ...state,
        assetStatus: 'fail',
        showModal: true,
        asset: '',
        isUploadAsset: false,
        showProgress: false
      };

    //Cover

    case ActionTypes.SET_COVER_UPLOAD:
      return {
        ...state,
        asset: action.payload,
        isUploadAsset: true,
        showProgress: true
      };

    case ActionTypes.SET_COVER_UPLOAD_SUCCESS:
      return {
        ...state,
        assetStatus: 'success',
        showModal: true,
        asset: action.payload,
        isUploadAsset: false,
        showProgress: false
      };

    case ActionTypes.SET_COVER_UPLOAD_FAIL:
      return {
        ...state,
        assetStatus: 'fail',
        showModal: true,
        asset: '',
        isUploadAsset: false,
        showProgress: false
      };

    case ActionTypes.GET_ASSET_BY_ID:
      return {
        ...state,
        assetData: [],
        IsAssetEdit: false
      };
    case ActionTypes.GET_ASSET_BY_ID_SUCCESS:
      return {
        ...state,
        assetData: action.payload,
        IsAssetEdit: true,
        showModal: true,
        showLockModal: false
      };

    case ActionTypes.GET_ASSET_BY_ID_FAIL:
      return {
        ...state,
        assetData: [],
        IsAssetEdit: false,
        showModal: false
        // lockedAssetData: action.payload,
        // showLockModal: action.payload.length > 0 ? true : false
      };

    case ActionTypes.GET_COVER_BY_ID:
      return {
        ...state,
        assetData: [],
        IsAssetEdit: false
      };
    case ActionTypes.GET_COVER_BY_ID_SUCCESS:
      return {
        ...state,
        assetData: action.payload,
        IsAssetEdit: true,
        showModal: true,
        showLockModal: false
      };

    case ActionTypes.GET_COVER_BY_ID_FAIL:
      return {
        ...state,
        assetData: [],
        IsAssetEdit: false,
        showModal: false
        // lockedAssetData: action.payload,
        // showLockModal: action.payload.length > 0 ? true : false
      };

    case ActionTypes.SET_ASSET_CANCEL:
      return {
        ...state,
        assetStatus: 'cancel',
        asset: ''
      };
    case ActionTypes.SET_ASSET_LIST:
      return {
        ...state
      };
    case ActionTypes.SET_ASSET_LIST_SUCCESS:
      return {
        ...state,
        assetList: action.payload
      };
    case ActionTypes.SET_ASSET_LIST_FAIL:
      return {
        ...state
      };
    //cover list
    case ActionTypes.SET_COVER_LIST:
      return {
        ...state
      };

    case ActionTypes.SET_COVER_LIST_SUCCESS:
      return {
        ...state,
        coverList: action.payload
      };

    case ActionTypes.SET_COVER_LIST_FAIL:
      return {
        ...state
      };

    case ActionTypes.SET_SHOW_MODAL_TRUE:
      return {
        ...state,
        showModal: true,
        assetInfoList: null
      };

    case ActionTypes.SET_SHOW_LOCK_MODAL_FALSE:
      return {
        ...state,
        showLockModal: false,
        lockedAssetData: [],
        assetInfoList: null
      };

    case ActionTypes.SET_SHOW_MODAL_FALSE:
      return {
        ...state,
        asset: '',
        assetStatus: '',
        showModal: false,
        assetData: [],
        IsAssetEdit: false
      };

    case ActionTypes.SET_CHAR_SHOW_MODAL_TRUE:
      return {
        ...state,
        showCharModal: true
      };

    case ActionTypes.SET_CHAR_SHOW_MODAL_FALSE:
      return {
        ...state,
        asset: '',
        assetStatus: '',
        showCharModal: false
      };

    case ActionTypes.SET_ROLLBACK_ASSET:
      return {
        ...state
      };

    case ActionTypes.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.SET_SELECTED_LIBRARY_MENU:
      return {
        ...state,
        selectedMenu: action.menuName
      };

    case ActionTypes.GET_COVER_DELETE:
      return {
        ...state,
        showDeleteModal: true
      };
      break;

    case ActionTypes.GET_ASSET_DELETE:
      return {
        ...state
      };

    case ActionTypes.GET_ASSET_DELETE_SUCCESS:
      return {
        ...state,
        assetInfoList: action.assetList,
        showDeleteModal: true
      };

    case ActionTypes.ASSET_DELETE_FAIL:
      return {
        ...state,
        assetInfoList: null
      };

    case ActionTypes.GET_ASSET_EDIT_INFO:
      return {
        ...state,
        assetInfoListEdit: null
      };

    case ActionTypes.GET_ASSET_EDIT_INFO_SUCCESS:
      return {
        ...state,
        assetInfoListEdit: action.assetList
        // showDeleteModal: true
      };

    case ActionTypes.GET_ASSET_EDIT_INFO_FAIL:
      return {
        ...state,
        assetInfoListEdit: null
      };

    case ActionTypes.GET_COVER_EDIT_INFO:
      return {
        ...state,
        coverInfoListEdit: null
      };

    case ActionTypes.GET_COVER_EDIT_INFO_SUCCESS:
      return {
        ...state,
        coverInfoListEdit: action.assetList
      };

    case ActionTypes.GET_COVER_EDIT_INFO_FAIL:
      return {
        ...state,
        coverInfoListEdit: null
      };

    case ActionTypes.ASSET_DELETE:
      return {
        ...state
      };
    case ActionTypes.ASSET_DELETE_SUCCESS:
      return {
        ...state,
        assetInfoList: null,
        showDeleteModal: true,
        deleteConfirm: true
      };
    case ActionTypes.GET_ASSET_DELETE_FAIL:
      return {
        ...state,
        assetInfoList: null,
        showDeleteModal: false,
        deleteConfirm: false
      };

      case ActionTypes.COVER_DELETE:
      return {
        ...state
      };
    case ActionTypes.COVER_DELETE_SUCCESS:
      return {
        ...state,
        assetInfoList: null,
        showDeleteModal: true,
        deleteConfirm: true
      };
    case ActionTypes.GET_COVER_DELETE_FAIL:
      return {
        ...state,
        assetInfoList: null,
        showDeleteModal: false,
        deleteConfirm: false
      };

    case ActionTypes.SET_SHOW_ASSET_DELTE_MODAL_TRUE:
      return {
        ...state,
        showDeleteModal: true,
        assetInfoList: null
      };
    case ActionTypes.SET_SHOW_ASSET_DELETE_MODAL_FALSE:
      return {
        ...state,
        showDeleteModal: false,
        deleteConfirm: false,
        assetInfoList: null
      };
    case ActionTypes.SET_LIBRARY_CHANGELOG:
      return {
        ...state
      };
    case ActionTypes.SET_LIBRARY_CHANGELOG_SUCCESS:
      return {
        ...state,
        changeLog: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

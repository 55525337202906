import React, {useState } from 'react';
import { InfoStyle} from './style';
import { Modal} from 'react-bootstrap';
import * as Constant from './Constant';

import LoginTutorialFrame from './LoginTutorialFrame';
 

function  LoginTutorial(props) {
  const [tutorialData] = useState(Constant.LoginConstant);
  const [tutorialElement, setTutorialElement] = useState(Constant.LoginConstant[0]);
  const [tutorialNumber, setTutorialNumber] = useState(0);
  
  const handlePrevClick = (tutorialNumber) => {
    
    setTutorialElement(tutorialData[tutorialNumber===0?0:tutorialNumber-1]);
    setTutorialNumber(tutorialNumber===0?0:tutorialNumber-1);
  }
  const handleNextClick = (tutorialNumber) => {
    if(tutorialNumber===tutorialData.length-1){
      props.handleHideTutorial();
    }else
    {
    setTutorialElement(tutorialData[tutorialNumber+1]);
    setTutorialNumber(tutorialNumber+1);
    }
    
  }
  return (
    
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal onboarding-login"
    className="modalbg" 
    show={props.show}
    >
    <InfoStyle.modalBox>
      <Modal.Body  >
      <LoginTutorialFrame
        data={tutorialData}
        element={tutorialElement}
        index={tutorialNumber}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
         
      />
      </Modal.Body>
      </InfoStyle.modalBox>
    </Modal>
  );

  }

export default LoginTutorial
import React, { useState, useEffect, useRef } from 'react';
import {
  Badge,
  Button,
  Row,
  Col,
  Overlay,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { styledIcons } from './../../../../common/styles/icons';
import { LibraryServices } from './../../service';
import './../../style/style.css';
import { ListStyle } from './style';

import * as Constants from './../../../../common/constants';
import * as Pattern from './../../../../common/helper/pattern';

function ListRow({
  asset,
  index,
  fileNames,
  handleAssetFileDelete,
  onPreviewClick,
  handleTypeChange,
  handleNameChange,
  handleUploadInfo,
  handleDefaultTypeChange,
  refreshList,
  focusElement,
  outfitCount,
  hairCount,
  expressionCount,
  accessoryCount,
  dummyAsset,
  setDummyAsset
}) {
  const maxSize = Constants.ASSET_MAX_FILE_SIZE;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  useEffect(() => {
    if (dummyAsset === true) {
      setDummyAsset(false);
    }
  }, [dummyAsset]);

  let thumb = LibraryServices.createUploadPreviewThumb(fileNames[index]);
  let assetTypes = Constants.ASSET_TYPES_CHARACTER;

  let assetTypeOptions = assetTypes.map((value, key) => {
    return (
      <option key={key} value={value}>
        {LibraryServices.getCharacterTypeLabel(value)}
      </option>
    );
  });

  const handleDelete = key => {
    setShow(false);

    handleAssetFileDelete(key);
  };

  return (
    <tr
      style={{ height: '100px' }}
      key={dummyAsset === true ? asset.assetName : `assetrow-${index}`}
      className="trChar table-body-row"
    >
      <td>
        {asset.size < maxSize &&
          (asset.extension === 'PNG') && (
            <div>
              <img
                ref={target}
                onClick={() => setShow(!show)}
                src="/images/icons/preview.svg"
                alt=""
                className="cursor-pointer checkIcon"
              />
              <Overlay
                onHide={() => setShow(false)}
                target={target.current}
                show={show}
                rootCloseEvent="click"
                rootClose={true}
                placement="right"
              >
                {props => (
                  <Tooltip id="overlay-example" {...props}>
                    {thumb}
                  </Tooltip>
                )}
              </Overlay>
            </div>
          )}{' '}
      </td>
      <td className="asset-upload-name-char-col">
        <div
          className={` ${
            asset.assetName === '' || asset.isUnique === false
              ? 'margin-28'
              : asset.isValid === false && asset.isUpload === true
              ? 'margin-50'
              : asset.isUpload === false
              ? ''
              : ''
          }`}
        >
          <input
            className="form-control"
            id={`asset-${index}`}
            title="File Name"
            type="text"
            onChange={e => handleNameChange(e, index)}
            maxLength={50}
            minLength={1}
            disabled={asset.size > maxSize || asset.isUpload === false}
            defaultValue={asset.assetName}
          />
        </div>
        <div>
          {asset.assetName === '' && (
            <span className="text-danger form-text">
              {' '}
              Please enter an Image name.
            </span>
          )}
          {asset.assetName !== '' &&
            asset.isUnique === false &&
            asset.isUpload === true && (
              <span className="text-danger form-text">
                {' '}
                Image name must be unique.
              </span>
            )}

          {asset.assetName !== '' &&
            asset.isValid === false &&
            asset.isUpload === true && (
              <span className="text-danger form-text">
                {' '}
                Please enter a name without special characters.
              </span>
            )}
        </div>
      </td>
 
      {asset.size < maxSize &&
        (asset.extension === 'PNG') && (
          <td>
            <div className={`${asset.assetType === '' ? 'margin-28' : ''}`}>
              <select
                 
                className={`${ asset.defaultAsset === true ? 'form-control bgCHar' : 'form-control'}`}
                title="Select type"
                onChange={e => handleTypeChange(e, index)}
                required
               disabled={asset.defaultAsset === true?true:false } 
                defaultValue={asset.assetType}
                name="background"
              >
                <option value="">Select one</option>
                {assetTypeOptions}
              </select>
            </div>
            <div>
              {asset.assetType === '' && (
                <span className="text-danger form-text">
                  {' '}
                  Please select a type.
                </span>
              )}
            </div>
          </td>
        )}

      {asset.size < maxSize &&
        (asset.extension === 'PNG' ) && (
          <td>
            <ListStyle.ListRow>
              <label className="switch-flip">
                <input
                  type="checkbox"
                  name="defaultAsset"
                  className="checkbox-flip"
                  onChange={e => handleDefaultTypeChange(e, index)}
                  disabled={
                    asset.defaultAsset === true || asset.type === ''
                      ? true
                      : false
                  }
                  // checked={asset.defaultAsset === true || props.disableDefaultAssetCheckbox===true ? 'checked': ''}
                  checked={asset.defaultAsset === true ? 'checked' : ''}
                />

                <span
                  className={`${
                    asset.defaultAsset === true ? 'bgDisable' : ''
                  } slider-flip round`}
                ></span>
              </label>
            </ListStyle.ListRow>
          </td>
        )}

      {asset.size < maxSize &&
        (asset.extension === 'PNG' ) && (
          <td className="text">
            {asset.defaultAsset === false && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
              >
                <styledIcons.deleteIcon
                  title="delete"
                  onClick={() => handleDelete(index)}
                  className="cursor-pointer"
                />
              </OverlayTrigger>
            )}

            {asset.defaultAsset === true &&
              hairCount > 1 &&
              asset.assetType === 'Hair' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={
                    <Tooltip id={`delete-asset`}>
                      Default assets cannot be deleted unless they are the only
                      one of their type.
                    </Tooltip>
                  }
                >
                  <img src="/images/icons/deactivatedelete.svg" alt="" />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              hairCount === 1 &&
              asset.assetType === 'Hair' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
                >
                  <styledIcons.deleteIcon onClick={() => handleDelete(index)} />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              outfitCount > 1 &&
              asset.assetType === 'Outfit' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={
                    <Tooltip id={`delete-asset`}>
                      Default assets cannot be deleted unless they are the only
                      one of their type.
                    </Tooltip>
                  }
                >
                  <img src="/images/icons/deactivatedelete.svg" alt="" />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              outfitCount === 1 &&
              asset.assetType === 'Outfit' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
                >
                  <styledIcons.deleteIcon onClick={() => handleDelete(index)} />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              expressionCount > 1 &&
              asset.assetType === 'Expression' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={
                    <Tooltip id={`delete-asset`}>
                      Default assets cannot be deleted unless they are the only
                      one of their type.
                    </Tooltip>
                  }
                >
                  <img src="/images/icons/deactivatedelete.svg" alt="" />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              expressionCount === 1 &&
              asset.assetType === 'Expression' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
                >
                  <styledIcons.deleteIcon onClick={() => handleDelete(index)} />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              accessoryCount > 1 &&
              asset.assetType === 'Accessory' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={
                    <Tooltip id={`delete-asset`}>
                      Default assets cannot be deleted unless they are the only
                      one of their type.
                    </Tooltip>
                  }
                >
                  <img src="/images/icons/deactivatedelete.svg" alt="" />
                </OverlayTrigger>
              )}

            {asset.defaultAsset === true &&
              accessoryCount === 1 &&
              asset.assetType === 'Accessory' && (
                <OverlayTrigger
                  key={`delete-asset`}
                  placement="top"
                  overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
                >
                  <styledIcons.deleteIcon onClick={() => handleDelete(index)} />
                </OverlayTrigger>
              )}
          </td>
        )}
      {asset.size > maxSize &&
        (asset.extension === 'PNG' ) && (
          <td colSpan={3}>
            <Row>
              <Col className="error-div-left" xs={2}>
                <img
                  src="/images/icons/ic_clear_24px.svg"
                  width="16px"
                  height="16px"
                  alt=""
                  className="  mt-3 ml-4"
                />
              </Col>
              <Col className="error-div" xs={9}>
                <span className="ml-3">
                  <span>Cannot upload</span> <br />
                  <span style={{ paddingLeft: '17px' }}>
                    The file exceeds 15 MB size limit!
                  </span>
                </span>
              </Col>
            </Row>
          </td>
        )}

      {asset.extension !== 'PNG' &&
         (
          <td colSpan={3}>
            <Row>
              <Col className="error-div-left" xs={2}>
                <img
                  src="/images/icons/ic_clear_24px.svg"
                  width="16px"
                  height="16px"
                  alt=""
                  className="  mt-3 ml-2"
                />
              </Col>
              <Col className="error-div" xs={10}>
                <span className="ml-3">
                  <span> Cannot upload.</span> <br />
                  <span style={{ paddingLeft: '17px' }}>
                    File type not supported!
                  </span>
                </span>
              </Col>
            </Row>
          </td>
        )}
    </tr>
  );
}

export default ListRow;

import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import * as styled from '../../components/myaccount/style/myaccount';
import * as ActionTypes from '../../actions/action-types';
import * as MyAccountAction from './../../actions/myaccount-action';

import MyAccountMenu from '../../components/myaccount/myAccountMenu';
import AccountDetail from '../../components/myaccount/accountDetail';
import SuccessModal from '../../components/myaccount/myAccountSuccess';
import ChangePassword from '../../components/myaccount/accountChangePassword';
import DeleteMyAccount from '../../components/myaccount/deleteAccountModal';
import SuccessDeleteMyAccount from '../../components/myaccount/successDeleteModal';
import MyAccountSettings from '../../components/myaccount/myAccountSettings';

const Accounts = props => {
  const [acctdelete, setAccountDelete] = useState([]);
  const [accStatus, setAccStatus] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [toggleCharacter, setToggleCharacter] = useState(
    props.full_name_toggle
  );
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  let token = props.authToken;

  let tokenData = props.tokenData ? props.tokenData : '';

  if (tokenData === '') {
    props.addLogin(null, false);
    props.history.push('/login');
  }

  useEffect(
    () => {
      setShowModalSuccess(props.successUpdated);
      setToggleCharacter(props.full_name_toggle)
    },
    props.successUpdated,
    props.full_name_toggle,
    []
  );

  const handleLogout = () => {
    setShowModalSuccess(false);
    props.addLogin(null, false);
    props.history.push('/login');
  };

  const handleCloseConfirm = () => {
    setShowConfirmPopup(false);
  };

  const handleDeleteUser = (e, userSelected, flag) => {
    setAccountDelete(userSelected);
    setAccStatus(flag);
    props.deleteUser(userSelected.id, token, flag);
  };

  const handleDeleteMyAccount = () => {
    setShowConfirmPopup(true);
  };

  const handleDeleteStoryCall = eventDelete => {
    props.deleteMyAccount(props.authToken, eventDelete);
  };

  const handleToggleCharacterSettings = event => {
    // console.log(toggleCharacter);
    setToggleCharacter(toggleCharacter == 'false' ? 'true' : 'false');
  };

  const handleClose = () => {
    props.setSuccessFalse();
  };

  return (
    <div>
      {tokenData && (
        <MyAccountMenu
          selectedMenu={props.selectedMenu}
          setSelectedMenu={props.setSelectedMenu}
          roleAdmin={props.tokenData.roles}
          handleDeleteMyAccount={handleDeleteMyAccount}
        />
      )}

      <styled.ListAccountWrapper>
        <styled.Users>
          <styled.Heading>
            <styled.Heading>Your Write Fictif account</styled.Heading>
          </styled.Heading>
          <styled.MainSection
            currentMenu={
              props.selectedMenu === 'myaccount'
                ? 'calc(100vh)'
                : 'calc(100vh - 160px)'
            }
          >
            <styled.MyAccountMainPage>
              {props.selectedMenu === 'myaccount' && tokenData && (
                <AccountDetail
                  tokenDataUser={props.tokenDataUser}
                  authToken={props.authToken}
                  updateMyAccountDetails={props.updateMyAccount}
                  usernameError={props.usernameError}
                  emailError={props.emailError}
                />
              )}
              {props.selectedMenu === 'changepassword' && tokenData && (
                <ChangePassword
                  tokenDataUser={props.tokenDataUser}
                  authToken={props.authToken}
                  changePasswordMyAccount={props.changePasswordMyAccount}
                  errorPassword={props.errorPassword}
                  usernameError={props.usernameError}
                />
              )}
              {props.selectedMenu === 'settings' && tokenData && (
                <MyAccountSettings
                  tokenDataUser={props.tokenDataUser}
                  // authToken={props.authToken}
                  toggleCheckbox={toggleCharacter}
                  setToggleCharacter={setToggleCharacter}
                  handleToggleCharacterSettings={handleToggleCharacterSettings}
                  // handleSaveToggle={handleSaveToggle}
                  toggleCharacterChange={props.toggleCharacterChange}
                  authToken={props.authToken}
                />
              )}
            </styled.MyAccountMainPage>
          </styled.MainSection>
        </styled.Users>
      </styled.ListAccountWrapper>
      {props.successUpdated === true && props.selectedMenu === 'myaccount' && (
        <SuccessModal
          label={'Your account details have been updated.'}
          label_next={'Please log-in again.'}
          imageChange={true}
          handleClick={handleLogout}
        />
      )}
      {props.successUpdated === true &&
        props.selectedMenu === 'changepassword' && (
          <SuccessModal
            label={'Your password has been updated.'}
            label_next={'Please log-in again.'}
            imageChange={true}
            handleClick={handleLogout}
          />
        )}
      {props.setSuccess === true && props.selectedMenu === 'settings' && (
        <SuccessModal
          label={'Your toggle character settings updated successfully.'}
          label_next={''}
          imageChange={true}
          handleClick={handleClose}
        />
      )}
      {props.errorDelete === true && (
        <SuccessDeleteMyAccount handleLogout={handleLogout} />
      )}
      {showConfirmPopup === true && (
        <DeleteMyAccount
          label="Are you sure you want to delete your Write Fictif account?"
          note="Your account will be deactivated until our Admin deletes it permanently. This process will take 7 days and after this, all your user data will be removed and deleted."
          onClose={handleCloseConfirm}
          cancel="Cancel"
          confirm="Yes, delete my account"
          show={showConfirmPopup}
          handleDeleteStoryCall={handleDeleteStoryCall}
          roleAdmin={props.tokenData.roles}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    selectedMenu: state.MyAccountReducer.selectedMenu,
    tokenData: state.LoginReducer.tokenData,
    tokenDataUser: state.LoginReducer.tokenDataUser,
    usernameError: state.MyAccountReducer.usernameError,
    emailError: state.MyAccountReducer.emailError,
    successUpdated: state.MyAccountReducer.successUpdated,
    errorPassword: state.MyAccountReducer.errorPassword,
    errorDelete: state.MyAccountReducer.errorDelete,
    full_name_toggle: state.LoginReducer.full_name_toggle,
    setSuccess: state.LoginReducer.setSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedMenu: menuName =>
      dispatch(MyAccountAction.setSelectedAccountMenu(menuName)),
    addLogin: (login, isLogOut) =>
      dispatch({
        type: ActionTypes.SET_LOGIN_DETAILS,
        payload: login,
        isLogOut: isLogOut
      }),
    deleteUser: (userId, token, flag) =>
      dispatch({
        type: ActionTypes.DEACTIVATE_ACCOUNT_DETAILS,
        payload: userId,
        token: token,
        status: flag
      }),

    updateMyAccount: (authToken, username, email) =>
      dispatch(
        MyAccountAction.updateMyAccountDetails(authToken, username, email)
      ),
    changePasswordMyAccount: (
      authToken,
      oldPassword,
      newPassword,
      confirmPassword
    ) =>
      dispatch(
        MyAccountAction.changePasswordMyAccount(
          authToken,
          oldPassword,
          newPassword,
          confirmPassword
        )
      ),
    deleteMyAccount: (authToken, deleteStory) =>
      dispatch(MyAccountAction.deleteMyAccount(authToken, deleteStory)),

    toggleCharacterChange: (authToken, value) =>
      dispatch(MyAccountAction.setToggleCharacterValue(authToken, value)),

    setSuccessFalse: () => dispatch(MyAccountAction.setSuccessFalse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as PlayerActions from './../../actions/player-action';
import * as StoryAction from './../../actions/story-action';
import * as styled from './style';
import PlayerModal from '../../components/player/PlayerModal';
import { PlayerService } from './../../components/player/service/player-service';
import * as Constant from './../../common/constants';

const PlayerPage = props => {
  //all ids in Comandtimeout ids
  const [commandsTimeoutIds, setCommandsTimeoutIds] = useState([]);
  const [play, setPlay] = useState(true);
  const [firstPlay, setFirstPlay] = useState(false);
  const [lastPlay, setLastPlay] = useState(false);
  const [prevPlay, setPrevPlay] = useState(false);
  const [nextPlay, setNextPlay] = useState(false);
  const [gotoPlay, setGotoPlay] = useState(null);
  const [currentCommand, setCurrentCommand] = useState(0);
  const [currentScene, setCurrentScene] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [showSpinner, setshowSpinner] = useState(false);
  const [fastPreview, setFastPreview] = useState(false);
  const [checkCommandState, setCheckCommandState] = useState(false);
  const [showDecision, setShowDecision] = useState(false);
  const [decisionParameters, setDecisionParameters] = useState(null);
  const [ifElseDecisionParameters, setIfElseDecisionParameters] = useState([]);

  const [showEnd, setShowEnd] = useState(false);

  useEffect(() => {
    console.log('');
  }, [ifElseDecisionParameters]);

  const hidePreviewModal = () => {
    PlayerService.clearAllTimeout(commandsTimeoutIds);
    setCurrentBlock(0);
    setCurrentCommand(0);
    setCurrentScene(0);
    setShowEnd(false);
    setDecisionParameters(null);
    setIfElseDecisionParameters([]);
    setShowDecision(false);
    props.handlePreviewModal();
  };

  const handleDialogueDisplayEvent = () => {
    setPlay(!play);
  };

  const updateVariableWithIdPlayer = (
    variableId,
    variableUpdatedValue,
    clicked
  ) => {
    let localVariable = props.localVariableListDTO;
    let localChangedVariable = props.changeVariableIds;
    if (localVariable && localVariable.length > 0) {
      let variableIndex = localVariable.findIndex(
        variable => parseInt(variable.id) === parseInt(variableId)
      );
      if (variableIndex >= 0) {
        if (clicked === 'reset') {
          localChangedVariable = props.changeVariableIds.filter(
            ids => ids !== variableId
          );
        } else {
          localChangedVariable.push(variableId);
        }
        localVariable[variableIndex].value = variableUpdatedValue;
        props.updateVariableWindowCheck(localVariable, localChangedVariable);
      }
    }
  };

  const handlePlayerButtonsClick = (e, action) => {
    PlayerService.clearAllTimeout(commandsTimeoutIds);

    switch (action) {
      case Constant.EVENT_FIRST:
        PlayerService.clearPreviewDocument();
        setCurrentScene(0);
        setCurrentBlock(0);
        setCurrentCommand(0);
        setFirstPlay(!firstPlay);
        setFastPreview(false);
        setCheckCommandState(false);
        setPlay(true);
        break;
      case Constant.EVENT_LAST:
        PlayerService.clearPreviewDocument();
        let csl = PlayerService.getLastPlayScene(
          props.flowchartPreviewData,
          props.playOn
        );
        setCurrentScene(csl);
        let cbl = PlayerService.getLastPlayBlock(
          props.flowchartPreviewData,
          props.playOn
        );
        setCurrentBlock(cbl);
        setCurrentCommand(0);
        setLastPlay(!lastPlay);
        setFastPreview(true);
        setCheckCommandState(false);
        setPlay(true);
        break;
      case Constant.EVENT_PREV:
        PlayerService.clearPreviewDocument();
        let csp = PlayerService.getPrevPlayScene(
          props.flowchartPreviewData,
          currentScene,
          props.playOn
        );
        setCurrentScene(csp);
        let cbp = PlayerService.getPrevPlayBlock(
          props.flowchartPreviewData,
          currentBlock,
          props.playOn,
          currentScene
        );
        setCurrentBlock(cbp);
        let ccp = 0;
        setCurrentCommand(ccp);
        setPrevPlay(!prevPlay);
        setFastPreview(true);
        setCheckCommandState(false);
        setPlay(true);
        break;
      case Constant.EVENT_NEXT:
        PlayerService.clearPreviewDocument();
        let csn = PlayerService.getNextPlayScene(
          props.flowchartPreviewData,
          currentScene
        );
        setCurrentScene(csn);
        let cbn = PlayerService.getNextPlayBlock(
          props.flowchartPreviewData,
          currentBlock,
          props.playOn,
          currentScene
        );
        setCurrentBlock(cbn);
        let ccn = 0;
        setCurrentCommand(ccn);
        setNextPlay(!nextPlay);
        setFastPreview(true);
        setCheckCommandState(false);
        setPlay(true);
        break;
      case Constant.EVENT_PLAY:
        PlayerService.clearPreviewDocument();
        let cc = PlayerService.getNextPlayCommand(
          props.flowchartPreviewData,
          currentBlock,
          currentCommand,
          props.playOn,
          currentScene
        );
        let cs = currentScene;
        let cb = currentBlock;
        if (cc === 0) {
          cs = PlayerService.getNextPlayScene(
            props.flowchartPreviewData,
            currentScene
          );
          cb = PlayerService.getNextPlayBlock(
            props.flowchartPreviewData,
            currentBlock,
            props.playOn,
            currentScene
          );
        }
        setCurrentScene(cs);
        setCurrentBlock(cb);
        setCurrentCommand(cc);
        setPlay(true);
        setFastPreview(true);
        setCheckCommandState(true);
        break;
      case Constant.EVENT_PAUSE:
        setPlay(false);
        break;
    }
  };

  const handleDecisionClick = value => {
    PlayerService.clearAllTimeout(commandsTimeoutIds);
    PlayerService.clearPreviewDocument();
    let flowchartData = null;
    if (props.playOn === 'scenes') {
      flowchartData = props.flowchartPreviewData;
    } else {
      //let scenes = [];
      //scenes.push(props.flowchartPreviewData);
      //flowchartData = scenes;
      flowchartData = props.flowchartPreviewData;
    }

    let indexes = PlayerService.collectIndexes(flowchartData, value);
    setCurrentBlock(indexes[1]);
    setCurrentScene(indexes[0]);
    setCurrentCommand(0);
    setFastPreview(true);
    setCheckCommandState(false);
    setGotoPlay(!gotoPlay);
    setShowDecision(false);
    setIfElseDecisionParameters([]);
  };

  return (
    <styled.PlayerMain>
      {props.showPlayer === true && (
        <PlayerModal
          showModal={props.showPlayer}
          hidePreviewModal={hidePreviewModal}
          handlePlayerButtonsClick={handlePlayerButtonsClick}
          SceneId={props.SceneId}
          blocks={props.flowchartPreviewData}
          sceneId={props.sceneId}
          commandsTimeoutIds={commandsTimeoutIds}
          setCommandsTimeoutIds={setCommandsTimeoutIds}
          play={play}
          setPlay={setPlay}
          firstPlay={firstPlay}
          lastPlay={lastPlay}
          prevPlay={prevPlay}
          nextPlay={nextPlay}
          gotoPlay={gotoPlay}
          setGotoPlay={setGotoPlay}
          currentBlock={currentBlock}
          setCurrentBlock={setCurrentBlock}
          currentCommand={currentCommand}
          setCurrentCommand={setCurrentCommand}
          currentScene={currentScene}
          setCurrentScene={setCurrentScene}
          handleDialogueDisplayEvent={handleDialogueDisplayEvent}
          previewTitle={props.previewTitle}
          showSpinner={showSpinner}
          fastPreview={fastPreview}
          setFastPreview={setFastPreview}
          checkCommandState={checkCommandState}
          setCheckCommandState={setCheckCommandState}
          handleDecisionClick={handleDecisionClick}
          setShowDecision={setShowDecision}
          showDecision={showDecision}
          decisionParameters={decisionParameters}
          setDecisionParameters={setDecisionParameters}
          setIfElseDecisionParameters={setIfElseDecisionParameters}
          ifElseDecisionParameters={ifElseDecisionParameters}
          showEnd={showEnd}
          setShowEnd={setShowEnd}
          loading={props.loading}
          blobImages={props.blobImages}
          updateVariableWithId={updateVariableWithIdPlayer}
        />
      )}
    </styled.PlayerMain>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    showPlayer: state.PlayerReducer.showPlayer,
    sceneId: state.FlowchartReducer.currentSceneId,
    flowchartData: state.FlowchartReducer.flowchartData,
    flowchartPreviewData: state.FlowchartReducer.flowchartPreviewData,
    loading: state.PlayerReducer.loading,
    blobImages: state.PlayerReducer.blobImages,
    variableListDTO: state.StoryReducer.variableListDTO,
    localVariableListDTO: state.StoryReducer.localVariableListDTO,
    changeVariableIds: state.StoryReducer.changeVariableIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePreviewModal: () => dispatch(PlayerActions.setPlayerModalFalse()),
    updateVariableWindowCheck: (localVariableList, changedVariableList) =>
      dispatch(
        StoryAction.updateLocalVariableWindow(
          localVariableList,
          changedVariableList
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerPage);

import React, { useState, useEffect, useRef } from 'react';
import {
  Badge,
  Button,
  Row,
  Col,
  Overlay,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { styledIcons } from './../../../common/styles/icons';
import { LibraryServices } from './../service';
import './../style/style.css';
import * as Constants from './../../../common/constants';
import * as Pattern from './../../../common/helper/pattern';

function ListRow({
  asset,
  index,
  fileNames,
  handleAssetFileDelete,
  onPreviewClick,
  handleTypeChange,
  handleNameChange,
  handleUploadInfo,
  refreshList,
  focusElement,
  dummyAsset,
  setDummyAsset
}) {
  const maxSize = Constants.ASSET_MAX_FILE_SIZE;
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (dummyAsset === true) {
      setDummyAsset(false);
    }
  }, [dummyAsset, []]);
  let thumb = LibraryServices.createUploadPreviewThumb(fileNames[index]);
  let assetTypes = LibraryServices.getAssetFileTypeList(asset.assetType);
  let assetTypeOptions = assetTypes.map((value, key) => {
    return (
      <option
        key={key}
        value={
          value === 'SFX'
            ? 'Incidental_Sound'
            : value === 'Music'
            ? 'Background_Music'
            : value
        }
      >
        {LibraryServices.getAssetTypeLabel(value)}
      </option>
    );
  });

  const handleDelete = key => {
    setShow(false);

    handleAssetFileDelete(key);
  };
  //asset.assetType === 'audio' || asset.assetType  === 'video'
  //preview_audio.svg
  return (
    <tr
      style={{ height: '100px' }}
      key={dummyAsset === true ? asset.name : `assetrow-${index}`}
      className="table-body-row"
    >
      <td key={`asset-${index}`}>
        {asset.size < maxSize &&
          (asset.extension === 'PNG' ||
            asset.extension === 'JPG' ||
            asset.extension === 'JPEG' ||
            asset.extension === 'MP3' ||
            asset.extension === 'WAV' ||
            asset.extension === 'OGG' ||
            asset.extension === 'MPEG') && (
            <div>
              <img
                ref={target}
                onClick={() => setShow(!show)}
                src={`${
                  asset.assetType === 'audio' || asset.assetType === 'video'
                    ? '/images/icons/preview_audio.svg'
                    : '/images/icons/preview.svg'
                }`}
                alt=""
                className="cursor-pointer checkIcon"
              />
              <Overlay
                onHide={() => setShow(false)}
                target={target.current}
                show={show}
                rootCloseEvent="click"
                rootClose={true}
                placement={index > fileNames.length - 3 ? 'top' : 'right'}
              >
                {props => (
                  <Tooltip
                    id="overlay-example"
                    className="tooltip-upload"
                    {...props}
                  >
                    {thumb}
                  </Tooltip>
                )}
              </Overlay>
            </div>
          )}{' '}
      </td>
      <td key={`asset-${index}`} className="asset-upload-name-col">
        <div
          className={`${
            asset.name === '' || asset.isUnique === false
              ? 'margin-28'
              : asset.isValid === false && asset.isUpload === true
              ? 'margin-28'
              : asset.isUpload === false
              ? ''
              : ''
          }`}
        >
          <input
            className="form-control"
            id={`asset-${index}`}
            maxLength={50}
            minLength={1}
            title="Image name"
            type="text"
            onChange={e => handleNameChange(e, index)}
            disabled={
              asset.size > maxSize ||
              asset.isUpload === false ||
              (asset.extension !== 'PNG' && asset.type === 'Object_Image')
            }
            defaultValue={asset.name}
          />
        </div>
        <div>
          {asset.name === '' && (
            <span className="text-danger form-text">
              {' '}
              Please enter a file name.
            </span>
          )}
          {asset.name !== '' &&
            asset.isUnique === false &&
            asset.isUpload === true && (
              <span className="text-danger form-text">
                {' '}
                File name must be unique.
              </span>
            )}

          {asset.name !== '' &&
            asset.isValid === false &&
            asset.isUpload === true && (
              <span className="text-danger form-text">
                {' '}
                Please enter a name without special characters.
              </span>
            )}
        </div>
      </td>

      {asset.size < maxSize &&
        (asset.extension === 'PNG' ||
          asset.extension === 'JPG' ||
          asset.extension === 'JPEG' ||
          asset.extension === 'MP3' ||
          asset.extension === 'WAV' ||
          asset.extension === 'OGG' ||
          asset.extension === 'MPEG') && (
          <td>
            <div
              className={`${
                asset.type === ''
                  ? 'margin-28'
                  : asset.type === 'Object_Image' && asset.extension !== 'PNG'
                  ? 'margin-28'
                  : ''
              }`}
            >
              <select
                className="form-control"
                onChange={e => handleTypeChange(e, index, asset.extension)}
                required
                defaultValue={asset.type}
                name="background"
              >
                <option value="">Select one</option>
                {assetTypeOptions}
              </select>
            </div>
            <div>
              {asset.type === 'Object_Image' && asset.extension !== 'PNG' && (
                <span className="text-danger form-text">
                  An object can only be PNG image
                </span>
              )}
              {asset.type === '' && (
                <span className="text-danger form-text">
                  {' '}
                  Please select a type.
                </span>
              )}
            </div>
          </td>
        )}

      {asset.size < maxSize &&
        (asset.extension === 'PNG' ||
          asset.extension === 'JPG' ||
          asset.extension === 'JPEG' ||
          asset.extension === 'MP3' ||
          asset.extension === 'WAV' ||
          asset.extension === 'OGG' ||
          asset.extension === 'MPEG') && (
          <td key={`asset-${index}`} className="text">
            <OverlayTrigger
              key={`delete-asset`}
              placement="top"
              overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
            >
              <styledIcons.deleteIcon
                title="delete"
                onClick={() => handleDelete(index)}
                className="cursor-pointer"
              />
            </OverlayTrigger>
          </td>
        )}
      {asset.size > maxSize &&
        (asset.extension === 'PNG' ||
          asset.extension === 'JPG' ||
          asset.extension === 'JPEG' ||
          asset.extension === 'MP3' ||
          asset.extension === 'WAV' ||
          asset.extension === 'OGG' ||
          asset.extension === 'MPEG') && (
          <td key={`asset-${index}`} colSpan={2}>
            <Row>
              <Col className="error-div-left" xs={3}>
                <img
                  src="/images/icons/ic_clear_24px.svg"
                  width="16px"
                  height="16px"
                  alt=""
                  className=" mt-3 ml-2"
                />
              </Col>
              <Col className="error-div" xs={9}>
                <span>
                  Cannot upload <br />
                  The file exceeds 15 MB size limit!
                </span>
              </Col>
            </Row>
          </td>
        )}

      {asset.extension !== 'PNG' &&
        asset.extension !== 'JPG' &&
        asset.extension !== 'JPEG' &&
        asset.extension !== 'MP3' &&
        asset.extension !== 'WAV' &&
        asset.extension !== 'OGG' &&
        asset.extension !== 'MPEG' && (
          <td key={`asset-${index}`} colSpan={2}>
            <Row>
              <Col className="error-div-left" xs={2}>
                <img
                  src="/images/icons/ic_clear_24px.svg"
                  width="16px"
                  height="16px"
                  alt=""
                  className="mt-3 ml-2"
                />
              </Col>
              <Col className="error-div" xs={10}>
                <span className="ml-3">Cannot upload.</span>
                <br />
                <span className="ml-3">File type not supported!</span>
              </Col>
            </Row>
          </td>
        )}
    </tr>
  );
}

export default ListRow;

import * as Constant from './../../../common/constants';

const calculateTotalTimeout = (command) => {
    let timeout = 0;
    switch (command.commandId) {
        case Constant.COMMAND_ENTER_THE_SCENE:
            // if (command.parameters.hasOwnProperty(Constant.CHARACTER_DELAY_TO_APPEAR)) {
            //     timeout += parseFloat(command.parameters[Constant.CHARACTER_DELAY_TO_APPEAR]);
            // }
            // if (command.parameters.hasOwnProperty(Constant.CHARACTER_FADE_INTO_SCREEN)) {
            //     timeout += parseFloat(command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN]);
            // }
            // if (command.parameters.hasOwnProperty(Constant.CHARACTER_SLIDE_IN_DURATION)) {
            //     timeout += parseFloat(command.parameters[Constant.CHARACTER_SLIDE_IN_DURATION]);
            // }
            break;
        case Constant.COMMAND_TIME_DELAY:
            if (command.parameters.hasOwnProperty(Constant.DURATION)) {
                timeout += parseFloat(command.parameters[Constant.DURATION]);
            }
            break;
        case Constant.COMMAND_SHOW_OBJECT:
        case Constant.COMMAND_HIDE_OBJECT:
            // if (command.parameters.hasOwnProperty(Constant.DURATION)) {
            //     timeout += parseFloat(command.parameters[Constant.DURATION]);
            // }
            break;
        case Constant.COMMAND_SHAKE_CAMERA:
            // if (command.parameters.hasOwnProperty(Constant.DURATION)) {
            //     timeout += parseFloat(command.parameters[Constant.DURATION]);
            // }
            break;
        case Constant.COMMAND_SHOW_NAME_DIALOGUE:
            timeout += 1000;
            break;
        case Constant.COMMAND_LEAVES_THE_SCENE:
            // if (command.parameters.hasOwnProperty(Constant.DURATION)) {
            //     timeout += parseFloat(command.parameters[Constant.DURATION]);
            // }
            break;
        case Constant.COMMAND_PLACE_VIEW:
            if (command.parameters.hasOwnProperty(Constant.BACKGROUND_FADE)) {
                timeout += parseFloat(command.parameters[Constant.BACKGROUND_FADE]);
            } else {
                timeout += 1;
            }
            break;
        case Constant.COMMAND_MOVE_BACKGROUND:
            if (command.parameters.hasOwnProperty(Constant.BACKGROUND_MOVE_DURATION)) {
                timeout += parseFloat(command.parameters[Constant.BACKGROUND_MOVE_DURATION]);
            }
            break;
    }

    return timeout * 500;
}

const calculateBlockTotalTimeout = (commands) => {
    let timeout = 0;
    commands.map((command) => {
        switch (command.commandId) {
            case Constant.COMMAND_ENTER_THE_SCENE:
                if (command.parameters.hasOwnProperty(Constant.CHARACTER_DELAY_TO_APPEAR)) {
                    timeout += parseInt(command.parameters[Constant.CHARACTER_DELAY_TO_APPEAR]);
                }
                if (command.parameters.hasOwnProperty(Constant.CHARACTER_FADE_INTO_SCREEN)) {
                    timeout += parseInt(command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN]);
                }
                break;
        }
    });

    return timeout;
}

const clearAllTimeout = (timeout) => {
    timeout.map((id) => clearTimeout(id));
}

const mapTimeout = (command) => {
    //let delay = 5000;
    // if (command.parameters[Constant.CHARACTER_DELAY_TO_APPEAR]) {
    //     delay = parseInt(command.parameters[Constant.CHARACTER_DELAY_TO_APPEAR]) * 1000
    // }
    // if (command.parameters[Constant.DURATION]) {
    //     delay = parseInt(command.parameters[Constant.DURATION])
    // }
    return (parseInt(calculateTotalTimeout(command) * 2));
}

const getNextPlayBlock = (previewData, currentBlock, playOn, currentScene = 0) => {
    let blocks = previewData[0].blocks;
    let nextBlock = 0;
    if ('scenes' === playOn) {
        blocks = previewData[currentScene].blocks;
    }

    if ((blocks.length - 1) > currentBlock) {
        nextBlock = currentBlock + 1;
    }

    return nextBlock;
}

const getPrevPlayBlock = (previewData, currentBlock, playOn) => {
    if ('scenes' === playOn) {
        return 0;
    }

    let blocks = previewData.blocks;
    let nextBlock = 0;

    if (parseInt(currentBlock) === 0) {
        return blocks.length - 1;
    }

    if (parseInt(currentBlock) > 0) {
        return parseInt(currentBlock) - 1;
    }

    return nextBlock;
}

const getNextPlayCommand = (previewData, currentBlock = 0, currentCommand = 0, playOn, currentScene = 0) => {
    let commands = [];
    let nextCommand = 0;

    if ('scenes' === playOn) {
        commands = previewData[currentScene].blocks[currentBlock].commands;
    } else {
        commands = previewData[0].blocks[currentBlock].commands;
    }

    if ((commands.length - 1) > currentCommand) {
        return currentCommand + 1;
    }

    return nextCommand;
}

const getNextPlayScene = (previewData, currentScene = 0) => {
    let nextScene = 0;

    if ((previewData.length - 1) > currentScene) {
        nextScene = currentScene + 1;
    }

    return nextScene;
}

const getPrevPlayScene = (previewData, currentScene = 0, playOn) => {
    let nextScene = 0;

    if ('blocks' === playOn) {
        return 0;
    }

    if (currentScene === 0) {
        return previewData.length - 1;
    }

    if (parseInt(currentScene) > 0) {
        return parseInt(currentScene) - 1;
    }

    return nextScene;
}

const getLastPlayScene = (previewData, playOn) => {
    let blocks = previewData;
    if ('blocks' === playOn) {
        return 0
    }

    return blocks.length - 1;
}

const getLastPlayBlock = (previewData, playOn) => {
    let blocks = [];
    if ('scenes' === playOn) {
        blocks = previewData[previewData.length - 1].blocks;
        return 0;
    } else {
        blocks = previewData.blocks;
    }

    return blocks.length - 1
}

const clearPreviewDocument = () => {
    let previewContent = document.getElementById('preview-content');
    //previewContent.innerHTML = '';
    //previewContent.style.background = 'none';
    //document.getElementById('media-preview').innerHTML = '';
    let dialogueElement = document.getElementById('quickdialgoue');
    if (dialogueElement) {
        dialogueElement.innerHTML = '';
    }
}

const collectIndexes = (flowchartData, targetBlockId) => {
    if (flowchartData.length <= 0) {
        return [];
    }

    let sceneIndex = 0;
    let blockIndex = 0;
    flowchartData.map((scenes, sIndex) => {
        if (scenes.blocks.length > 0) {
            scenes.blocks.map((block, bIndex) => {
                if (block.name === targetBlockId) {
                    sceneIndex = sIndex;
                    blockIndex = bIndex;
                }
            })
        }
    });

    return [sceneIndex, blockIndex];
}

const getLastCommandId = (flowchartData) => {

    if (flowchartData === null) {
        return null;
    }

    let sceneLength = flowchartData.length;
    let blockLength = flowchartData[sceneLength - 1].blocks.length;
    if (flowchartData[sceneLength - 1].blocks[blockLength - 1] !== undefined) {
        let commandLength = flowchartData[sceneLength - 1].blocks[blockLength - 1].commands.length;

        let lastCommand = flowchartData[sceneLength - 1].blocks[blockLength - 1].commands[commandLength - 1];
        if (lastCommand != undefined) {
            return lastCommand.id;
        }
    }

    return null;
}

const preloadImages = (flowchartData) => {
    let images = [];
    let loadedImageCount = 0;
    let totalImageCount = 0;
    // Parse scenes
    flowchartData.map(scene => {
        // Parse blocks from scene
        scene.blocks.map(block => {
            // Parse commands
            block.commands.map(command => {
                switch (command.commandId) {
                    case Constant.COMMAND_PLACE_VIEW:
                        command.sprites.map(sprite => {
                            images[command.id] = new Image();
                            images[command.id].src = sprite;
                            images[command.id].onload = () => {
                                loadedImageCount++;
                            }
                            totalImageCount++;
                        });
                }
            })
        });
    });

    return [images, totalImageCount, loadedImageCount];
}

const collectComponents = (flowchartData) => {
    let images = [];
    // Parse scenes
    flowchartData.map(scene => {
        // Parse blocks from scene
        scene.blocks.map((block) => {
            // Parse commands
            block.commands.map((command, index) => {
                switch (command.commandId) {
                    case Constant.COMMAND_ENTER_THE_SCENE:
                    case Constant.COMMAND_CHANGE_EXPRESSION:
                    case Constant.COMMAND_REPLACE_CHARACTER:
                        images.push(command);
                        break;

                    case Constant.COMMAND_PLACE_VIEW:
                    case Constant.COMMAND_MOVE_BACKGROUND:
                        images.push(command);
                        break;
                }
            });
        })

    });

    return images;
}

const collectImages = (flowchartData) => {
    let images = [];
    let imagesWithoutCanvas = [];
    let imagesWithCanvas = [];
    // Parse scenes
    flowchartData.map(scene => {
        // Parse blocks from scene
        scene.blocks.map(block => {
            // Parse commands
            block.commands.map(command => {
                switch (command.commandId) {
                    case Constant.COMMAND_ENTER_THE_SCENE:
                    case Constant.COMMAND_CHANGE_EXPRESSION:
                    case Constant.COMMAND_REPLACE_CHARACTER:
                    case Constant.COMMAND_SET_OUTFIT:
                    case Constant.COMMAND_SHOW_OUTFIT:
                    case Constant.COMMAND_REPLACE_OUTFIT:
                        command.sprites.map(sprite => {
                            images.push(sprite.url);
                            imagesWithCanvas.push(sprite);
                        });
                        break;

                    case Constant.COMMAND_PLACE_VIEW:
                    case Constant.COMMAND_MOVE_BACKGROUND:
                        images.push(command.view.asset.awsPath);
                        imagesWithoutCanvas.push(command.view.asset);
                        command.view.viewObjects.map(object => {
                            images.push(object.asset.awsPath);
                            imagesWithoutCanvas.push(object.asset);
                        })
                        break;
                }
            })
        });
    });

    flowchartData.map(scene => {
        // Parse variable dtos from scene
        scene.variableDTOs.map((variable) => {
            if (variable.spriteDTO !== null && variable.spriteDTO.hasOwnProperty('url')) {
                images.push(variable.spriteDTO.url);
                imagesWithCanvas.push(variable.spriteDTO);
            }
            if (variable.options != null && variable.options.length > 0) {
                variable.options.map((option) => {
                    if (option.hasOwnProperty('url')) {
                        images.push(option.url);
                        imagesWithCanvas.push(option);
                    }
                })
            }
        })
    });

    return {
        images: images,
        imagesWithCanvas: getDistinctImages(imagesWithCanvas),
        imagesWithoutCanvas: [...new Set(imagesWithoutCanvas)]
    };
}

const getDistinctImages = (images) => {
    const result = Array.from(new Set(images.map(s => s.url)))
        .map(url => {
            return {
                url: url,
                specs: images.find(s => s.url === url).specs
            }
        });

    return result;
}

const clearPlayer = () => {
    let previewContent = document.getElementById('preview-content');
    if (previewContent) {
        previewContent.innerHTML = '';
        previewContent.style.background = 'none';
    }

    let mediaPreview = document.getElementById('media-preview');
    if (mediaPreview) {
        mediaPreview.innerHTML = '';
    }

    let mediaSoundPreview = document.getElementById('media-sound-preview');
    if (mediaSoundPreview) {
        mediaSoundPreview.innerHTML = '';
    }

    let dialogueElement = document.getElementById('quickdialgoue');
    if (dialogueElement !== null) {
        dialogueElement.innerHTML = '';
    }
}

const collectInterfaceImages = (gameInterfaceList) => {
    let images = gameInterfaceList.map(gameInterface => {
        if (gameInterface.awsPath != null) {
            return gameInterface.awsPath
        }
    }).filter(gameInterface => gameInterface != undefined);

    return images;
}

const mapInterfaceElements = (interfaceList) => {
    let list = {};
    Constant.interfaceList.map(item => {
        let interfaceItem = getInterfaceItem(item, interfaceList);
        if (interfaceItem[0] !== undefined) {
            list[item] = interfaceItem[0].awsPath;
        }
    });

    return list;
}

const getInterfaceItem = (item, interfaceList) => {
    return interfaceList.filter(interfaceItem => item === interfaceItem.element);
}

const getAffinity = (decisionParameters, count) => {
    let affinityType = '';
    let affinityWeight = 0;
    if (decisionParameters.hasOwnProperty('Affinity type' + count)) {
        affinityType = decisionParameters['Affinity type' + count];
        affinityWeight = decisionParameters['Affinity weight' + count];
    }

    return { type: affinityType, weight: affinityWeight };
}

const getVariableIndex = (localVariable, variableId) => {
    let variableIndex = localVariable.filter(
        variable => parseInt(variable.id) === parseInt(variableId)
    );

    return variableIndex;
}

const getVariableId = (localVariables, key) => {
    let variableId = localVariables.map(variable => {
        if (variable.name === key) {
            return variable.id
        }
    }).filter(id => id != undefined);

    if (variableId.length > 0) {
        return variableId[0];
    }

    return 0;
}

const getVariable = (localVariables, key) => {
    let variableValue = localVariables.filter(variable => variable.name === key);

    if (variableValue.length === 0) {
        return '';
    }

    return variableValue[0];
}

const getConsoleMessage = (commandId, lineNumber) => {
    let message = '';

    switch (commandId) {
        case Constant.COMMAND_LEAVES_THE_SCENE:
            message = 'Leave the scene';
            break;
        case Constant.COMMAND_MOVE_BACKGROUND:
            message = 'Move background';
            break;
        case Constant.COMMAND_STOP_MUSIC:
            message = 'Stop music';
            break;
        case Constant.COMMAND_HIDDEN_CHARACTER:
            message = 'Hidden character';
            break;
        case Constant.COMMAND_SHOW_OBJECT:
            message = 'Show object';
            break;
        case Constant.COMMAND_HIDE_OBJECT:
            message = 'Hide object';
            break;
    }

    message = `LN ${lineNumber}: ${message} failed`;

    return message
}

const getDialogueList = (dialogue) => {
    let dotSplit = dialogue.split('.');
    let finalList = [];
    dotSplit.map(text => {
        if (text.length > 0) {
            if (text.length > 110) {
                let textSplit = text.split(' ');
                let divideTextSplitOne = textSplit.splice(0, textSplit.length / 2);
                let divideTextSplitTwo = textSplit.splice((textSplit.length / 2) + 1, textSplit.length);
                let joinOne = divideTextSplitOne.join(' ') + '...';
                let joinTwo = divideTextSplitTwo.join(' ');
                finalList.push(joinOne);
                finalList.push(joinTwo);
            } else {
                finalList.push(text);
            }
        }
    });

    return finalList;
}

const prepareSkipTimeout = (checkCommandState,
    skipTimeout,
    currentScene,
    currentBlock,
    currentCommand,
    sceneIndex,
    blockIndex,
    commandIndex,
    skipTimeoutTemp) => {
    if (checkCommandState === true) {
        if (skipTimeout === true
            && currentScene === sceneIndex
            && currentBlock === blockIndex
            && currentCommand === commandIndex) {
            skipTimeoutTemp = false;
        }
    } else if (skipTimeout === true
        && currentScene === sceneIndex
        && currentBlock === blockIndex) {
        skipTimeoutTemp = false;
    }

    return skipTimeoutTemp
}

const flushPreviewDocument = () => {
    let previewContent = document.getElementById('preview-content');
    if (previewContent) {
        previewContent.innerHTML = '';
        previewContent.style.background = 'none';
    }

    let mediaPreview = document.getElementById('media-preview');
    if (mediaPreview) {
        mediaPreview.innerHTML = '';
    }

    let mediaSoundPreview = document.getElementById('media-sound-preview');
    if (mediaSoundPreview) {
        mediaSoundPreview.innerHTML = '';
    }

    let dialogueElement = document.getElementById('quickdialgoue');
    if (dialogueElement !== null) {
        dialogueElement.innerHTML = '';
    }
}

const isChapterEnd = (flowchartData, blockIndex, lastCommandIndex) => {
    if (flowchartData === null) {
        return;
    }

    let commandLength = flowchartData[0].blocks[blockIndex].commands.length - 1;
    let currentBlockLastCommand = flowchartData[0].blocks[blockIndex].commands[commandLength];
    let lastBlock = false;
    if (lastCommandIndex === commandLength
        && !Constant.redirectionalCommandIds.includes(currentBlockLastCommand.commandId)) {
        lastBlock = true;
    }

    return lastBlock;
}

const getObjectByName = (name, objects) => {
    return objects.filter(object => object.asset.name === name);
}

const getCurrentStateOfCommandExecution = (
    flowchartPreviewData,
    currentIndexes,
    playOn,
    command,
    externalPlayPauseHandler,
    selectedIndexes) => {
    let cc = getNextPlayCommand(
        flowchartPreviewData,
        currentIndexes.currentBlock,
        currentIndexes.currentCommand,
        playOn,
        currentIndexes.currentScene);
    let cs = currentIndexes.currentScene;
    let cb = currentIndexes.currentBlock;
    if (cc === 0) {
        cs = getNextPlayScene(flowchartPreviewData, currentIndexes.currentScene);
        cb = getNextPlayBlock(
            flowchartPreviewData,
            currentIndexes.currentBlock,
            playOn,
            currentIndexes.currentScene);
    }

    let directionCommands = [
        Constant.COMMAND_PAID_ADD_DECISION,
        Constant.COMMAND_GOTO_CHAPTER,
        Constant.COMMAND_CHOICE_TIMER,
        Constant.COMMAND_TAP_OBJECT,
        Constant.COMMAND_ADD_DIALOGUE,
        Constant.COMMAND_SAY_GENDERED
    ];

    if (directionCommands.indexOf(command.commandId) !== -1
        && externalPlayPauseHandler === false) {
        cc = currentIndexes.currentCommand;
        cs = currentIndexes.currentScene;
        cb = currentIndexes.currentBlock;
    }

    if (selectedIndexes.hasOwnProperty('currentScene')) {
        cs = selectedIndexes.currentScene;
        cb = selectedIndexes.currentBlock;
        cc = selectedIndexes.currentCommand;
    }

    return {
        'scene': cs,
        'block': cb,
        'command': cc
    }
}


const getCurrentStateOfCommandExecutionHighlightBlock = (
    flowchartPreviewData,
    currentIndexes,
    playOn,
    command,
    externalPlayPauseHandler,
    selectedIndexes,
    cursorPosition) => {
    if (flowchartPreviewData && flowchartPreviewData[0].blocks.length > 0) {
        for (let blockLoop = 0; blockLoop < flowchartPreviewData[0].blocks.length; blockLoop++) {
            for (let commandLoop = 0; commandLoop < flowchartPreviewData[0].blocks[blockLoop].commands.length; commandLoop++) {
                if (flowchartPreviewData[0].blocks[blockLoop].commands[commandLoop].commandId == Constant.COMMAND_END_JUICY_BLOCK) {
                    continue;
                }
                if (parseInt(flowchartPreviewData[0].blocks[blockLoop].commands[commandLoop].parameters.lineNumber) === parseInt(cursorPosition + 1)) {
                    return {
                        'scene': 0,
                        'block': blockLoop,
                        'command': commandLoop
                    }
                } else if (parseInt(flowchartPreviewData[0].blocks[blockLoop].commands[commandLoop].parameters.lineNumber) > parseInt(cursorPosition + 1)) {
                    return {
                        'scene': 0,
                        'block': blockLoop,
                        'command': 0
                    }
                }
            }
        }
    }

}

const changeSpecialChar = (str, specialChar, replaceChar) => {
    if (!str) {
        return '';
    }
    let newString = '';
    for (let i = 0; i < str.length; i++) {
        if (str[i] === specialChar) {
            newString += replaceChar;
        } else {
            newString += str[i];
        }
    }
    return newString;
}

const escapeHtml = (str) => {
    if (!str) return;

    return str
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/{span/g, "<span")
        .replace(/"}/g, '">')
        .replace(/{\/span}/g, "</span>")
}

const getCharacterName = (parameters, localVariableListDTO) => {
    let characterName = parameters.characterDisplayName !== undefined ? parameters.characterDisplayName : parameters.character;
    if (characterName && characterName === '{$nombre}') {
        characterName = getVariable(localVariableListDTO, Constant.reservedVariables[0]);
        return characterName.value;
    }

    return characterName;
}

const preparePlayerVariableDto = (commandParameters, localVariableListDTO) => {
    let accessoryVariable = commandParameters['accessoryDisplayName'].replace('{$', '').replace('}', '');
    let hairVariable = commandParameters['hairDisplayName'].replace('{$', '').replace('}', '');
    let outfitVariable = commandParameters['variationDisplayName'].replace('{$', '').replace('}', '');
    
    let accessoryDto = getVariable(localVariableListDTO, accessoryVariable);
    let hairDto = getVariable(localVariableListDTO, hairVariable);
    let outfitDto = getVariable(localVariableListDTO, outfitVariable);
    
    return {
        accessory: accessoryDto,
        hair: hairDto,
        outfit: outfitDto
    }
}

const rearrangeSpritesLayer = (spritesLayer) => {
    let layer = [];
    let accesory = findSprite(spritesLayer, 'Accessory');
    let hair = findSprite(spritesLayer, 'Hair');
    let expression = findSprite(spritesLayer, 'Expression');
    let outfit = findSprite(spritesLayer, 'Outfit');
    if (accesory !== undefined && accesory.length > 0) {
        layer.push(accesory[0]);
    }
    if (hair !== undefined && hair.length > 0) {
        layer.push(hair[0]);
    }
    if (expression !== undefined && expression.length > 0) {
        layer.push(expression[0]);
    }
    if (outfit !== undefined && outfit.length > 0) {
        layer.push(outfit[0]);
    }

    return layer;
}

const findSprite = (spriteLayers, spriteName) => {
    return spriteLayers.filter((sprite) => sprite.spriteType === spriteName);
}

export const PlayerService = {
    calculateTotalTimeout,
    clearAllTimeout,
    calculateBlockTotalTimeout,
    mapTimeout,
    getNextPlayBlock,
    getPrevPlayBlock,
    getNextPlayCommand,
    getNextPlayScene,
    getPrevPlayScene,
    getLastPlayScene,
    clearPreviewDocument,
    getLastPlayBlock,
    collectIndexes,
    getLastCommandId,
    preloadImages,
    collectImages,
    collectComponents,
    clearPlayer,
    collectInterfaceImages,
    mapInterfaceElements,
    getAffinity,
    getVariableIndex,
    getVariableId,
    getVariable,
    getConsoleMessage,
    getDialogueList,
    prepareSkipTimeout,
    flushPreviewDocument,
    isChapterEnd,
    getObjectByName,
    getCurrentStateOfCommandExecution,
    getCurrentStateOfCommandExecutionHighlightBlock,
    escapeHtml,
    getCharacterName,
    changeSpecialChar,
    preparePlayerVariableDto,
    rearrangeSpritesLayer
}

import React, { useState } from 'react';
import {
  Modal,
  Row,
  Form,
  Col,
  Button,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Overlay
} from 'react-bootstrap';
import { PlaceBgStyle } from './style';
import * as Pattern from './../../../common/helper/pattern';
import {
  FADE_INTO_SCREEN,
  FADE_INTO_SCREEN_ERROR,
  EXPORT_TO_GALLERY,
  EXPORT_TO_GALLERY_SUB_TEXT,
  NAME_OF_THIS_VIEW,
  BLANK_SPACES_NOT_ALLOWED,
  ERROR_DUPLICATE,
  ERROR_NAME_VIEW,
  ADD_TO_GALLERY,
  IS_FOUND_IN_JUICY,
  CAPTION_TEXT,
  CAPTION_TEXT_ERROR
} from '../../../components/library/translations';

const CreateViewModal = props => {
  const [exporToGalleryValue, setExportToGalleryValue] = useState(
    props.exportToGallary
  );
  
  const [foundInJuicy, setFoundInJuicy] = useState(
    props.foundInJuicy
  );
  let DefaultValue = '';
  if (props.characterRoutesList.length > 0) {
    DefaultValue = props.characterRoutesList.findIndex(
      characterId =>
        characterId.characterId === null && characterId.name === 'Prologue'
    );
  }

  return (
    
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.onHideModal}
      backdrop="static"
      className="margin-less"
    >
      <PlaceBgStyle.addObjectPopup>
        <div className="add-object-popup">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            className="add-object-content"
            style={{ padding: '0px 50px 30px 50px !important;' }}
          >
            <Form
              noValidate
              validated={props.validated}
              onSubmit={e => props.saveForLater(e, props.clickedEventCheck)}
              name="placeBackground"
              id="placeBackground"
            >
              {props.tabButtonClicked !== 'browseTab' && (
                <Row>
                  <div className="mb-4">
                    <Form.Label>{NAME_OF_THIS_VIEW}</Form.Label>
                    <p className="commandLabelSuffix">
                      {BLANK_SPACES_NOT_ALLOWED}
                    </p>
                    <Form.Control
                      type="textbox"
                      className="form-control"
                      onChange={props.handleChange}
                      maxLength="50"
                      autoComplete="off"
                      pattern={Pattern.VIEW_NAME_PATTERN}
                      required
                      disabled={props.isEditCommand === true ? 'disabled' : ''}
                      value={props.name}
                      name="name"
                    />
                    {props.duplicateName && (
                      <Form.Text className="text-danger">
                        {ERROR_DUPLICATE}
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {ERROR_NAME_VIEW}
                    </Form.Control.Feedback>
                  </div>
                </Row>
              )}
              {props.clickedEventCheck === false && (
                <Row>
                  <div className="mb-4">
                    <Col>
                      <InputGroup>
                        <label className="switch-flip">
                          <Form.Control
                            type="checkbox"
                            name="exporttogallery"
                            className="checkbox-flip"
                            label="checkbox"
                            id="view-checkbox"
                            key="view-checkbox"
                            onChange={() =>
                              setExportToGalleryValue(!exporToGalleryValue)
                            }
                            value={exporToGalleryValue}
                            // disabled={props.defaultDisabledView}
                            checked={
                              exporToGalleryValue === true ? 'checked' : ''
                            }
                          />
                          <span className="slider-flip round"></span>
                        </label>
                        <span className="label-text">{EXPORT_TO_GALLERY}</span>
                      </InputGroup>
                      <div className="label-sub-text">
                        {EXPORT_TO_GALLERY_SUB_TEXT}
                      </div>
                    </Col>
                  </div>
                </Row>
              )}
              {props.clickedEventCheck === false &&
                exporToGalleryValue === true && (
                  <>
                    <Row>
                      <div class="d-flex">
                        <div className="mb-4 mr-4">
                          <Form.Label
                            className={`d-flex align-items-center form-label`}
                          >
                            {' '}
                            {CAPTION_TEXT}
                            <OverlayTrigger
                              key={`caption-text`}
                              placement="top"
                              overlay={
                                <Tooltip id={`caption-text`}>
                                  Enter the illustration's caption. This caption
                                  will appear with the illustration in Fictif's
                                  gallery.
                                </Tooltip>
                              }
                            >
                              <span className="info-icon ml-2"></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            required
                            as="input"
                            name="caption"
                            key="caption"
                            id="caption"
                            placeholder="Add caption"
                            pattern="\s*\S+.*"
                            // pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                            defaultValue={
                              props.captionText === '' ||
                                props.captionText === null
                                ? ''
                                : props.captionText
                            }
                            maxLength="48"
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {CAPTION_TEXT_ERROR}
                          </Form.Control.Feedback>
                        </div>
                        <div className="mb-4">
                          <Form.Label className={`commandLabel`}>
                            {' '}
                            {ADD_TO_GALLERY}{' '}
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            name="addtogallery"
                            key="addtogallery"
                            id="addtogallery"
                            defaultValue={
                              props.characterRoute === '' ||
                                props.characterRoute === null
                                ? props.characterRoutesList[DefaultValue].id
                                : props.characterRoute
                            }
                          >
                            {/* <option>General Gallery</option> */}
                            {props.characterRoutesList.length > 0 &&
                              props.characterRoutesList.map(
                                (characterRoute, index) => {
                                  if (
                                    characterRoute.characterId === null &&
                                    (characterRoute.name === 'Prologue' ||
                                      characterRoute.name === 'Chapters')
                                  ) {
                                    return (
                                      <option value={characterRoute.id}>
                                        General Gallery
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option value={characterRoute.id}>
                                        {characterRoute.name}
                                      </option>
                                    );
                                  }
                                }
                              )}
                          </Form.Control>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-4">
                        <Col>
                          <InputGroup>
                            <label className="switch-flip">
                              <Form.Control
                                type="checkbox"
                                name="foundInJuicy"
                                className="checkbox-flip"
                                label="checkbox"
                                id="juicy-checkbox"
                                key="juicy-checkbox"
                                onChange={() =>
                                  
                                  setFoundInJuicy(!foundInJuicy)
                                }
                                value={foundInJuicy}
                                // disabled={props.defaultDisabledView}
                                checked={
                                  foundInJuicy === true ? 'checked' : ''
                                }
                              />
                              <span className="slider-flip round"></span>
                            </label>
                            <span className="label-text">{IS_FOUND_IN_JUICY}</span>
                          </InputGroup>
                         
                        </Col>
                      </div>
                    </Row>
                    <Row></Row>
                  </>
                )}
              {props.clickedEventCheck === false && (
                <Row>
                  <div className="mb-1">
                    <Form.Label>{FADE_INTO_SCREEN}</Form.Label>
                  </div>
                </Row>
              )}
              {props.clickedEventCheck === false && (
                <Row>
                  <div className="mb-4 ">
                    <Col>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          className="placeViewNumber"
                          label={'Fade into screen'}
                          placeholder="Fade into screen"
                          step="0.1"
                          required="true"
                          size="50"
                          min="0"
                          defaultValue={
                            props.isEditCommand === true
                              ? props.fadeinView
                              : '1'
                          }
                          name="fadeintoseconds"
                        />
                        <InputGroup.Prepend>
                          <InputGroup.Text className="noBackground">
                            Seconds
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control.Feedback type="invalid">
                          {FADE_INTO_SCREEN_ERROR}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </div>
                </Row>
              )}
            </Form>
            <Row>
              <Col>
                <Button
                  className="btn-snacktool btn-snacktool-golden"
                  onClick={props.onHideModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col className="text-right">
                <Button
                  type="submit"
                  className="btn-snacktool btn-snacktool-golden"
                  form="placeBackground"
                >
                  {props.tabButtonClicked === 'placeView' &&
                    props.clickedEventCheck === true
                    ? 'Save view'
                    : 'Place view into script'}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </PlaceBgStyle.addObjectPopup>
    </Modal>
  );
};

export default CreateViewModal;

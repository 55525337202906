import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LibraryMenu from './../../components/library/LibraryMenu';
import SetCharacterVariations from './../../components/library/characters/create-character/SetCharacterVariations';
import * as LibraryActions from './../../actions/library-action';
import { CharacterService } from './../../components/library/character';
import { Row, Col } from 'react-bootstrap';

import Progress from './../../components/library/characters/add-character/Progress';

const CreateCharacterStepThree = props => {
  const [redirectPage, setRedirectPage] = useState(false);
  const [refreshVarList, setrefreshVarList] = useState(false);

  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let sprites = CharacterService.updateVisibilityOfPreviewLayersEdit(
      props.spriteLayers,
      props.previewVariations
    );
    props.setSpriteLayers(sprites);

    props.saveCharacterPreviewAsset(
      CharacterService.updatePreviewDataEdit(props.previewVariations)
    );
    setProgress(0);
    clearInterval(intervalId);
  }, []);

  const handleSpriteVisibility = spriteId => {
    let sprites = CharacterService.updateVisibilityOfLayers(
      spriteId,
      props.spriteLayers
    );
    props.setSpriteLayers(sprites);
  };

  const handlePreviewClick = (event, index, assetType) => {
    let isPreviewOn = event.target.checked;

    let previeFinalData = CharacterService.updatePreviewData(
      props.previewVariations,
      isPreviewOn,
      index,
      assetType
    );

    props.saveCharacterPreviewAsset(previeFinalData);

    let sprites = CharacterService.updateVisibilityOfPreviewLayers(
      previeFinalData
    );
    props.setSpriteLayers(sprites);
    props.setAutoSaveFlag(true);
  };
  const reorderCharcterList = varData => {
    props.saveCharacterPreviewAsset(varData);
    let sprites = CharacterService.updatePreviewReorderData(varData);
    props.setSpriteLayers(sprites);
    setrefreshVarList(!refreshVarList);
  };
  const hideModal = () => {
    setShowProgress(false);
  };

  const handleSaveCharacter = () => {
    // const intervalId = interval();
    //       setIntervalId(intervalId);
    setShowProgress(true);

    let finalCharacter = CharacterService.buildFinalCharacter(
      props.characterState
    );
    const intervalId = interval();
    setIntervalId(intervalId);
    props.saveFinalCharacter(
      finalCharacter,
      props.authToken,
      props.storyId,
      props.IsCharacterEdit,
      props.IsCharacterEdit ? props.characterData.id : null
    );
    // alert('1');
    // clearInterval(intervalId);
  };

  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 10);
    }, 200);
  };

  const handleSuccessModalClick = () => {
    setRedirectPage(true);
    props.hideSuccessModal();
    props.initializeCreateCharacter();
  };

  const handleFailModalClick = () => {
    setRedirectPage(true);
    props.hideFailModal();
    props.initializeCreateCharacter();
  };

  return (
    <div>
      {redirectPage === true && <Redirect to="characters" />}
      <Row>
        <Col className="col-2">
          <LibraryMenu
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            characterAutoSaveFlag={props.characterAutoSaveFlag}
            resetCharacterAutoSaveFlag={props.resetCharacterAutoSaveFlag}
          />
        </Col>
        <Col className="col-8">
          {
            <SetCharacterVariations
              spriteLayers={props.spriteLayers}
              handleSpriteVisibility={handleSpriteVisibility}
              setSpriteLayers={props.setSpriteLayers}
              variations={props.previewVariations}
              removeVariation={props.removeVariation}
              initializeCreateCharacter={props.initializeCreateCharacter}
              handleSaveCharacter={handleSaveCharacter}
              showCharacterSaveSuccessModal={
                props.showCharacterSaveSuccessModal
              }
              showCharacterSaveFailModal={props.showCharacterSaveFailModal}
              hideSuccessModal={handleSuccessModalClick}
              hideFailModal={handleFailModalClick}
              isVariationExists={props.isVariationExists}
              setVariationExists={props.setVariationExists}
              handlePreviewClick={handlePreviewClick}
              Files={props.Files}
              reorderCharcterList={reorderCharcterList}
              IsCharacterEdit={props.IsCharacterEdit}
              showProgress={showProgress}
            />
          }
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>

      {showProgress === true &&
        props.showCharacterSaveSuccessModal === false && (
          <Progress
            IsCharacterEdit={props.IsCharacterEdit}
            variations={props.previewVariations}
            showModal={showProgress}
            progress={progress}
          />
        )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedAsset: state.CharacterReducer.selectedAsset,
    spriteLayers: state.CharacterReducer.spriteLayers,
    characterAssetList: state.CharacterReducer.characterAssetList,

    //variations: state.CharacterReducer.variations,
    previewVariations: state.CharacterReducer.previewVariations,
    Files: state.CharacterReducer.Files,
    // dialogueColor: state.CharacterReducer.dialogueColor,
    // characterName: state.CharacterReducer.characterName,
    // characterDisplayName: state.CharacterReducer.characterDisplayName,

    characterState: state.CharacterReducer,
    storyId: state.StoryReducer.storyId,
    authToken: state.LoginReducer.authToken,
    saveFinalCharacter: state.CharacterReducer.saveFinalCharacter,
    showCharacterSaveSuccessModal:
      state.CharacterReducer.showCharacterSaveSuccessModal,
    showCharacterSaveFailModal:
      state.CharacterReducer.showCharacterSaveFailModal,
    isVariationExists: state.CharacterReducer.isVariationExists,
    authToken: state.LoginReducer.authToken,
    selectedMenu: state.LibraryReducer.selectedMenu,
    characterData: state.CharacterReducer.characterData,
    IsCharacterEdit: state.CharacterReducer.IsCharacterEdit,
    characterAutoSaveFlag: state.CharacterReducer.autoSaveFlag
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSpriteLayers: sprites =>
      dispatch(LibraryActions.setSpriteLayers(sprites)),

    removeVariation: index =>
      dispatch(LibraryActions.setRemoveVariation(index)),
    initializeCreateCharacter: () =>
      dispatch(LibraryActions.initializeCreateCharacter()),
    saveFinalCharacter: (
      character,
      authToken,
      storyId,
      IsCharacterEdit,
      charId
    ) =>
      dispatch(
        LibraryActions.setSaveFinalCharacter(
          character,
          authToken,
          storyId,
          IsCharacterEdit,
          charId
        )
      ),
    hideSuccessModal: () =>
      dispatch(LibraryActions.setShowCharacterSaveSuccessModalFalse()),
    hideFailModal: () =>
      dispatch(LibraryActions.setShowCharacterSaveFailModalFalse()),
    checkVariationExists: payload =>
      dispatch(LibraryActions.checkVariationExits(payload)),
    setVariationExists: status =>
      dispatch(LibraryActions.setVariationExists(status)),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    saveCharacterPreviewAsset: asset =>
      dispatch(LibraryActions.setCharacterPreviwAsset(asset)),
    resetCharacterAutoSaveFlag: () =>
      dispatch(LibraryActions.resetAutoSaveFlag()),
    setAutoSaveFlag: flag => dispatch(LibraryActions.setAutoSaveFlag(flag))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCharacterStepThree);

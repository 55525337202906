import { ImageMapper } from './../../common/helper/image-mapper';
import * as AspectRatioConfig from './../../config/aspectRatio.config';
import { aspectRatio } from '../../common/constants';

const getBackgroundImage = (backgroundImages, selectedBackgroundId) => {
  if (selectedBackgroundId === null) {
    return [];
  }

  let background = backgroundImages.filter(background => {
    if (background.id == selectedBackgroundId) {
      return background;
    }
  });

  return background;
};

const getView = (id, views) => {
  let background = views.filter(view => view.id === id);

  return background;
};

const getViewByName = (name, views) => {
  let background = views.filter(view => view.name === name);

  return background;
};

const getEditParameterValues = (imageSpecs, x, y, width, height) => {
  let specs = ImageMapper.getOriginalSpecsOfImage(imageSpecs);
  let zoomFactorW = specs[0] / AspectRatioConfig.cropPanelWidth;
  let zoomFactorH = specs[1] / AspectRatioConfig.cropPanelHeight;

  let detail = {
    x: Math.round(x / zoomFactorW),
    y: Math.round(y / zoomFactorH),
    height: Math.round(height / zoomFactorH),
    width: Math.round(width / zoomFactorW)
  };
  return detail;
};

const getEditParameterValuesShow = (imageSpecs, x, y, width, height) => {
  let specs = ImageMapper.getOriginalSpecsOfImage(imageSpecs);
  let zoomFactorW = specs[0] / 317.33;
  let zoomFactorH = specs[1] / 423.66;
  let detail = {
    x: Math.round(x / zoomFactorW),
    y: Math.round(y / zoomFactorW),
    height: (height / zoomFactorW),
    width: (width / zoomFactorW)
  };
  return detail;
};


const prepareObjectDragDropCoordinates = (
  objects,
  item,
  libraryChange,
  delta
) => {
  let left = 0;
  let top = 0;
  let newCoordinatesX = item.left;
  let newCoordinatesY = item.top;

  if (libraryChange) {
    // Prepare co-ordinates for large view port
    let newCoordinates = ImageMapper.prepareObjectCoordinate(
      {
        xcoordinate: item.left,
        ycoordinate: item.top
      },
      AspectRatioConfig.aspectRatioParameters[aspectRatio].objectViewWidth,
      AspectRatioConfig.aspectRatioParameters[aspectRatio].objectViewHeight,
      AspectRatioConfig.aspectRatioParameters[aspectRatio].libraryViewWidth,
      AspectRatioConfig.aspectRatioParameters[aspectRatio].libraryViewHeight,
      AspectRatioConfig.aspectRatioCalcThreeByFour
    );
    newCoordinatesX = newCoordinates[0];
    newCoordinatesY = newCoordinates[1];
  }

  left = Math.round(delta.x + newCoordinatesX);
  top = Math.round(delta.y + newCoordinatesY);

  let notColliding = false;
  if (objects) {
    const draggingImage = document.getElementById(`objectLayer-${item.index}`);
    const bottom = top + draggingImage.offsetHeight;
    const right = left + draggingImage.offsetWidth;
    objects.map((object, index) => {
      if (item.index !== index && notColliding !== true) {
        const imgSizes = document.getElementById(`objectLayer-${index}`);
        const imgSizesbottom = imgSizes.offsetTop + imgSizes.offsetHeight;
        const imgSizesright = imgSizes.offsetLeft + imgSizes.offsetWidth;

        notColliding =
          right > imgSizes.offsetLeft &&
          left < imgSizesright &&
          top < imgSizesbottom &&
          bottom > imgSizes.offsetTop &&
          (object.interactive === true ||
            objects[item.index].interactive === true);
        if (notColliding === true) {
          return undefined;
        }
      }
    });
  }

  return {
    left: left,
    top: top,
    notColliding: notColliding
  };
};

const addInlineElement = () => {
  let element = document.getElementsByClassName('cropper-crop-box');
  let firstItem = element.item(0);
  firstItem.insertAdjacentHTML(
    'beforeend',
    `<span style="height: 100%;
    width : 3px;
    margin: 0 auto;
    top: 0;
    left: 18%;
    position: absolute;
    cursor: move;
    background: #45c7ad;"></span>
    <span style="height: 100%;
    width : 3px;
    margin: 0 auto;
    top: 0;
    position: absolute;
    cursor: move;
    margin-left: 80%;
    background: #45c7ad;"></span>`
  );
};

export const PlaceBackgroundService = {
  getBackgroundImage,
  getView,
  getViewByName,
  getEditParameterValues,
  prepareObjectDragDropCoordinates,
  addInlineElement,
  getEditParameterValuesShow
};

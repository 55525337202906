import React, { useEffect, useState } from 'react';

import AceEditorComponent from '../../components/script-writer/text-editor/text-editor-new';
import * as Constant from './../../common/constants';
import CommandParser from './../../components/script-writer/text-editor/command-parser';
import _ from 'lodash';

// Render editor
const TextEditor = props => {
  const commandArray = [
    {
      name: 'block',
      value: 'block',
      meta: 'Command'
    },
    {
      name: 'goto',
      value: 'goto',
      meta: 'Command'
    },
    {
      name: 'place',
      value: 'place',
      meta: 'Command'
    },
    {
      name: 'hide',
      value: 'hide',
      meta: 'Command'
    },
    {
      name: '_:',
      value: '_:',
      meta: 'Command'
    },
    {
      name: 'music',
      value: 'music',
      meta: 'Command'
    },
    {
      name: 'sfx',
      value: 'sfx',
      meta: 'Command'
    },
    {
      name: 'stopmusic',
      value: 'stopmusic',
      meta: 'Command'
    },
    {
      name: 'show',
      value: 'show',
      meta: 'Command'
    },
    {
      name: 'replace',
      value: 'replace',
      meta: 'Command'
    },
    {
      name: 'view',
      value: 'view',
      meta: 'Command'
    },
    {
      name: 'choice',
      value: 'choice',
      meta: 'Command'
    },
    {
      name: 'choicetimer',
      value: 'choicetimer',
      meta: 'Command'
    },
    {
      name: 'moveto',
      value: 'moveto',
      meta: 'Command'
    },
    {
      name: 'volume',
      value: 'volume',
      meta: 'Command'
    },
    {
      name: 'wait',
      value: 'wait',
      meta: 'Command'
    },
    {
      name: 'showobj',
      value: 'showobj',
      meta: 'Command'
    },
    {
      name: 'hideobj',
      value: 'hideobj',
      meta: 'Command'
    },
    {
      name: 'tap',
      value: 'tap',
      meta: 'Command'
    },
    {
      name: 'shake',
      value: 'shake',
      meta: 'Command'
    },
    {
      name: 'shownamedialog',
      value: 'shownamedialog',
      meta: 'Command'
    },
    {
      name: 'setvar',
      value: 'setvar',
      meta: 'Command'
    },
    {
      name: 'if',
      value: 'if',
      meta: 'Command'
    },
    {
      name: 'elseIf',
      value: 'elseIf',
      meta: 'Command'
    },
    {
      name: 'end',
      value: 'end',
      meta: 'Command'
    },
    {
      name: 'else',
      value: 'else',
      meta: 'Command'
    },
    {
      name: 'endchapter',
      value: 'endchapter',
      meta: 'Command'
    },
    {
      name: 'saygendered',
      value: 'saygendered',
      meta: 'Command'
    },
    {
      name: 'setoutfit',
      value: 'setoutfit',
      meta: 'Command'
    },
    {
      name: 'oshow',
      value: 'oshow',
      meta: 'Command'
    },
    {
      name: 'oreplace',
      value: 'oreplace',
      meta: 'Command'
    },
    {
      name: 'endjuicyblock',
      value: 'endjuicyblock',
      meta: 'Command'
    }
  ];

  const [indentationTrue, setIndetationTrue] = useState(false);
  const [saveButtonEnable, setSaveButtonEnable] = useState(false);
  const [localChangeOccurFlag, setLocalChangeOccurFlag] = useState(false);

  const [commandSetFirstSet, setCommandSetFirstSet] = useState([
    ...commandArray,
    ...(props.characterListForScript ? props.characterListForScript : [])
  ]);

  let commandValueObj = props.commandList;
  let commandId = null;

  const onChange = (newValue, action = 'insert', currentRowCursor) => {
    if (indentationTrue === true) {
      setIndetationTrue(false);
    }
    if (props.scriptChanged === true) {
      setSaveButtonEnable(true);
    }
    let arrayNewValues = _.split(newValue, '\n');
    commandValueObj['commands'] = [];
    arrayNewValues
      .filter(arrayCommands => arrayCommands !== undefined)
      .map((arrayCommands, index) => {
        // if (arrayCommands !== undefined) {

        const cmdObj = new CommandParser(arrayCommands.trim());
        const whichPartParamertesObj = cmdObj.getCommandPartAt(2);
        let isBlockCommand = false;

        switch (whichPartParamertesObj.commandStart) {
          case 'block':
            commandId = Constant.COMMAND_ADD_BLOCK;

            isBlockCommand = true;
            break;

          case 'goto':
            commandId = Constant.COMMAND_GO_TO_BLOCK;
            break;

          case 'place':
            commandId = Constant.COMMAND_ENTER_THE_SCENE;
            break;

          case '_:':
            commandId = Constant.COMMAND_ADD_DIALOGUE;
            break;

          case 'hide':
            commandId = Constant.COMMAND_LEAVES_THE_SCENE;
            break;

          case 'music':
            commandId = Constant.COMMAND_PLAY_MUSIC;
            break;

          case 'sfx':
            commandId = Constant.COMMAND_PLAY_SOUND;
            break;

          case 'stopmusic':
            commandId = Constant.COMMAND_STOP_MUSIC;
            break;

          case 'show':
            commandId = Constant.COMMAND_CHANGE_EXPRESSION;
            break;

          case 'replace':
            commandId = Constant.COMMAND_REPLACE_CHARACTER;
            break;

          case 'view':
            commandId = Constant.COMMAND_PLACE_VIEW;
            break;

          case 'moveto':
            commandId = Constant.COMMAND_MOVE_BACKGROUND;
            break;

          case 'choice':
            commandId = Constant.COMMAND_PAID_ADD_DECISION;
            break;

          case 'choicetimer':
            commandId = Constant.COMMAND_CHOICE_TIMER;
            break;

          case 'volume':
            commandId = Constant.COMMAND_SET_AUDIO;
            break;

          case 'wait':
            commandId = Constant.COMMAND_TIME_DELAY;
            break;

          case 'showobj':
            commandId = Constant.COMMAND_SHOW_OBJECT;
            break;

          case 'hideobj':
            commandId = Constant.COMMAND_HIDE_OBJECT;
            break;

          case 'tap':
            commandId = Constant.COMMAND_TAP_OBJECT;
            break;

          case 'shake':
            commandId = Constant.COMMAND_SHAKE_CAMERA;
            break;

          case 'shownamedialog':
            commandId = Constant.COMMAND_SHOW_NAME_DIALOGUE;
            break;

          case 'setvar':
            commandId = Constant.COMMAND_SET_VAR;
            break;
          case 'if':
            commandId = Constant.COMMAND_ADD_IF;
            break;
          case 'elseIf':
            commandId = Constant.COMMAND_ADD_ELSE_IF;

            break;
          case 'else':
            commandId = Constant.COMMAND_ADD_ELSE;

            break;
          case 'end':
            commandId = Constant.COMMAND_ADD_END;

            break;
          case 'endchapter':
            commandId = Constant.COMMAND_GOTO_CHAPTER;
            break;

          case 'saygendered':
            commandId = Constant.COMMAND_SAY_GENDERED;
            break;

          case 'setoutfit':
            commandId = Constant.COMMAND_SET_OUTFIT;
            break;

          case 'oshow':
            commandId = Constant.COMMAND_SHOW_OUTFIT;
            break;

          case 'oreplace':
            commandId = Constant.COMMAND_REPLACE_OUTFIT;
            break;
          case 'endjuicyblock':
            commandId = Constant.COMMAND_END_JUICY_BLOCK;
            break;

          default:
            const commandListCharacterFound = props.characterListForScript.filter(
              character =>
                `${character.characterName}:` ===
                  `${whichPartParamertesObj.commandStart}` ||
                `${character.characterLongName}:` ===
                  `${whichPartParamertesObj.commandStart}`
            );
            if (commandListCharacterFound.length > 0) {
              commandId = Constant.COMMAND_ADD_DIALOGUE;
            } else if (
              whichPartParamertesObj.commandStart !== undefined &&
              whichPartParamertesObj.commandStart.startsWith('//')
            ) {
              commandId = Constant.COMMAND_ADD_COMMENT;
            } else {
              commandId = null;
            }
            break;
        }

        const commandObj = {
          commandId: commandId,
          cmdText: arrayCommands,
          blockCommand: isBlockCommand,
          deleted: false,
          modified: true,
          //  errors:
          //   props.commandObjBkup[index] === undefined
          //      ? []
          //      : props.commandObjBkup[index].errors,
          //  warnings:
          //     props.commandObjBkup[index] === undefined
          //      ? []
          //      : props.commandObjBkup[index].warnings
          warnings: [],
          errors: []
        };

        commandValueObj['commands'].push(commandObj);
        // }
      });
    props.handleSaveReducerForHeader(commandValueObj);
  };

  const handleAutoSaveClick = e => {
    if (saveButtonEnable == true || props.saveButtonEnable == true) {
      props.handleAutoSaveStory();
      setSaveButtonEnable(false);
      setLocalChangeOccurFlag(false);
      props.setScriptChanged(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => handleAutoSaveClick(), 60000 * 1);
    return () => clearInterval(interval);
  }, [props.commandList, saveButtonEnable]);

  return (
    <div>
      <AceEditorComponent
        commandSetFirstSet={commandSetFirstSet}
        setOpenPopupForSave={props.setOpenPopupForSave}
        characterListForScript={props.characterListForScript}
        commandListForScriptEditor={commandValueObj}
        commandListOld={props.commandList}
        handleSaveReducerForHeader={props.handleSaveReducerForHeader}
        storyTitle={props.storyTitle}
        storySubTitle={props.storySubTitle}
        onChange={onChange}
        avialableBlocks={props.avialableBlocks}
        backToScene={props.backToScene}
        backgroundSongs={
          props.assetsAll.length > 0
            ? props.assetsAll.filter(
                backgroundSong => backgroundSong.type === 'Background_Music'
              )
            : []
        }
        incidentalSongs={
          props.assetsAll.length > 0
            ? props.assetsAll.filter(
                incidentalSong => incidentalSong.type === 'Incidental_Sound'
              )
            : []
        }
        views={props.views}
        changeCursorPosition={props.changeCursorPosition}
        showHideModal={props.showHideModal}
        hideCharModal={props.hideCharModal}
        showModal={props.showModal}
        previewAssetObj={props.previewAssetObj}
        pageX={props.pageX}
        pageY={props.pageY}
        cursorPostion={props.cursorPostion}
        editCommand={props.editCommand}
        assetsAll={props.assetsAll}
        isViewStory={props.isViewStory}
        showPanels={props.showPanels}
        onAddCommentClick={props.onAddCommentClick}
        isCommentStory={props.isCommentStory}
        commentLineNo={props.commentLineNo}
        changeCommentPoup={props.changeCommentPoup}
        currentStoryStatus={props.currentStoryStatus}
        variableListDTO={props.variableListDTO}
        tokenDataUser={props.tokenDataUser}
        localVariableListDTO={props.localVariableListDTO}
        variableWindowClicked={props.variableWindowClicked}
        activeTabSelected={props.activeTabSelected}
        updateVariableWithId={props.updateVariableWithId}
        updateValueArrayIds={props.updateValueArrayIds}
        handleSaveStory={props.handleSaveStory}
        storyStatusObj={props.storyStatusObj}
        play={props.play}
        blockClickedEvent={props.blockClickedEvent}
        setBlockClickedEvent={props.setBlockClickedEvent}
        allStoryRoutes={props.allStoryRoutes}
        currentCharacterRouteId={props.currentCharacterRouteId}
        currentSceneId={props.currentSceneId}
        storyId={props.storyId}
        previewScroll={props.previewScroll}
        tutorials={props.tutorials}
        onVarTutorialExit={props.onVarTutorialExit}
        stepsScriptEnabled={props.stepsScriptEnabled}
        compareFlag={props.compareFlag}
        handleChangeLogScript={props.handleChangeLogScript}
        setPreviewScroll={props.setPreviewScroll}
        setIndetationTrue={setIndetationTrue}
        indentationTrue={indentationTrue}
        comamndSavePanel={props.comamndSavePanel}
        setComamndSavePanel={props.setComamndSavePanel}
        textEditorValidate={props.textEditorValidate}
        setTextEditorValidate={props.setTextEditorValidate}
        flowchartHasErrors={props.flowchartHasErrors}
        setShowScriptError={props.setShowScriptError}
        externalPlayPauseHandler={props.externalPlayPauseHandler}
        indentButtonClick={props.indentButtonClick}
        setIndentButtonClick={props.setIndentButtonClick}
        autopopupSave={props.autopopupSave}
        textEditorloading={props.textEditorloading}
        changeCheckBoxHighlightBlock={props.changeCheckBoxHighlightBlock}
        highlightBlock={props.highlightBlock}
        lastSavedTime={props.lastSavedTime}
        handleAutoSaveStory={props.handleAutoSaveStory}
        setSaveButtonEnable={setSaveButtonEnable}
        saveButtonEnable={props.saveButtonEnable ? true : saveButtonEnable}
        setScriptChanged={props.setScriptChanged}
        scriptChanged={props.scriptChanged}
        localChangeOccurFlag={localChangeOccurFlag}
        setLocalChangeOccurFlag={setLocalChangeOccurFlag}
        fontSize={props.fontSize}
        fontFamily={props.fontFamily}
        fontChangeSave={props.fontChangeSave}
        spellGrammar={props.spellGrammar}
        spellCheckerCall={props.spellCheckerCall}
        addToIgnoreCall={props.addToIgnoreCall}
        expandEditorClick={props.expandEditorClick}
        expandEditor={props.expandEditor}
        loadGrammar={props.loadGrammar}
      />
    </div>
  );
};

export default TextEditor;

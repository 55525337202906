import React from 'react';
import * as translations from './../translations';
import { Form } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import './../style/style.css';
import { LibraryServices } from './../service';
import * as Constant from './../../../common/helper/pattern';

function AddInfo(props) {
  const {
    validated,

    handleSaveInfo,
    handleFormChange,
    asset,
    showAssetNameError,
    assetInfoListEdit,
    showObjectError
    // handleTagChange
  } = props;

  let assetTypes =
    props.files.length > 0 && typeof props.files[0] === 'string'
      ? LibraryServices.getEditAssetTypeList(props.files, asset.type)
      : LibraryServices.getAssetTypeList(props.files);
  let assetTypeOptions = assetTypes.map((value, key) => {
    return (
      <option
        key={key}
        value={
          value === 'SFX'
            ? 'Incidental_Sound'
            : value === 'Music'
            ? 'Background_Music'
            : value
        }
      >
        {LibraryServices.getAssetTypeLabel(value)}
      </option>
    );
  });

  let disabled = '';
  if (
    props.IsAssetEdit &&
    assetInfoListEdit &&
    assetInfoListEdit.length > 0 &&
    (asset.type === 'Background_Image' || asset.type === 'Object_Image')
  ) {
    disabled = 'disabled';
  }

  return (
    <LibraryStyle.addInfoContainer>
      <div className="mt-4">
        <Form
          id="add-info-form"
          noValidate
          validated={validated}
          onSubmit={e => handleSaveInfo(e)}
        >
          <Form.Group>
            <Form.Label>{'Type'}</Form.Label>
            <Form.Control
              as="select"
              name="type"
              onChange={handleFormChange}
              value={asset.type}
              disabled={disabled}
              required
            >
              {props.IsAssetEdit === false && (
                <option value="">Select type</option>
              )}
              {assetTypeOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {translations.ASSET_TYPE_ERROR_MESSAGE}
            </Form.Control.Feedback>
            {showObjectError && (
              <Form.Control.Feedback className="invalid-feedback">
                An object can only be PNG image
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{'Name'}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={handleFormChange}
              required
              maxLength="50"
              minLength="1"
              pattern={Constant.ASSET_NAME_PATTERN}
              defaultValue={asset.name}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {translations.ASSET_NAME_ERROR_MESSAGE}
            </Form.Control.Feedback>
            {showAssetNameError && (
              <Form.Control.Feedback className="invalid-feedback">
                {translations.ASSET_NAME_UNIQUE_MESSAGE}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>
      </div>
    </LibraryStyle.addInfoContainer>
  );
}

export default AddInfo;

import React from 'react';
import * as commandPanelStyle from '../command-panel/command-panel-style';
 

function Head(props) {
    return (
      <div>
      <commandPanelStyle.Header>
          
      <div className="panel-header">
              <div className="story-heading">
                  <div className='storyName'>
                  {props.storyTitle ? props.storyTitle : ''}
                  </div>
                  <div className='sceneName'>
                  Views
                  </div>
              </div>
          </div>
         
      </commandPanelStyle.Header>
  </div>
    );
}

export default Head
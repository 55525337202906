import React from 'react';
import { DeleteStyle } from './style';
import { Modal,  Button } from 'react-bootstrap';
 

function Warning(props) {

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
      className="modalbg"
      size="md"
      show={props.showModal}
      onHide={props.handleHideUndoReviewModal}
    >
      <Modal.Body  >

        <DeleteStyle.addAssetMessageBox>
          <DeleteStyle.successAssetContent>
            <div className="success-icon mb-3">
              <img alt="" className="mediumIcon" src="/images/icons/ic_warning_24px.svg" />
            </div>
            <div data-testid="success-label" className="submit-asset-label">This story is currently under review by an Editor Admin</div>
            <div data-testid="success-note" className="text-muted submit-asset-subtitle ">You cannot request to cancel the review at this time.</div>
            <div>
              <Button data-testid="success-btn" onClick={props.handleHideUndoReviewModal} className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
            </div>
          </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}

export default Warning
import * as ActionTypes from './command-action-types.js';

export function getCommandsCharacter(token, storyId, commandId) {
    return {
        type: ActionTypes.GET_COMMAND_CHARACTER,
        authToken: token,
        storyId: storyId,
        commandId: commandId
    }
  }

  export function getCommandsCharacterSuccess(commandCharacters) {
    return {
        type: ActionTypes.GET_COMMAND_CHARACTER_SUCCESS,
        commandCharacter: commandCharacters
    }
  }

  export function getCommandsCharacterFail(errors) {
    return {
        type: ActionTypes.GET_COMMAND_CHARACTER_FAIL,
        errors: errors
    }
  }

  export function getCommandsDialogue(token, storyId, commandId) {
    return {
        type: ActionTypes.GET_COMMAND_DIALOGUE,
        authToken: token,
        storyId: storyId,
        commandId: commandId
    }
  }

  export function getCommandsDialogueSuccess(commandDialogues) {
    return {
        type: ActionTypes.GET_COMMAND_DIALOGUE_SUCCESS,
        commandDialogues: commandDialogues
    }
  }

  export function getCommandsDialogueFail(errors) {
    return {
        type: ActionTypes.GET_COMMAND_DIALOGUE_FAIL,
        errors: errors
    }
  }

  export function getCommandsBackground(token, storyId, commandId) {
    return {
        type: ActionTypes.GET_COMMAND_BACKGROUND,
        authToken: token,
        storyId: storyId,
        commandId: commandId
    }
  }

  export function getCommandsBackgroundSuccess(commandBackgrounds) {
    return {
        type: ActionTypes.GET_COMMAND_BACKGROUND_SUCCESS,
        commandBackground: commandBackgrounds
    }
  }

  export function getCommandsBackgroundFail(errors) {
    return {
        type: ActionTypes.GET_COMMAND_BACKGROUND_FAIL,
        errors: errors
    }
  }

  export function getCommandsMedia(token, storyId, commandId) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_SOUND,
        authToken: token,
        storyId: storyId,
        commandId: commandId
    }
  }

  export function getCommandsMediaSuccess(commandMedia) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_SOUND_SUCCESS,
        commandMedia: commandMedia
    }
  }

  export function getCommandsMediaFail(errors) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_SOUND_FAIL,
        errors: errors
    }
  }

  export function getCommandsPlayMusic(token, storyId, commandId) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_MUSIC,
        authToken: token,
        storyId: storyId,
        commandId: commandId
    }
  }

  export function getCommandsPlayMusicSuccess(commandPlayMusic) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_MUSIC_SUCCESS,
        commandPlayMusic: commandPlayMusic
    }
  }

  export function getCommandsPlayMusicFail(errors) {
    return {
        type: ActionTypes.GET_COMMAND_PLAY_MUSIC_FAIL,
        errors: errors
    }
  }
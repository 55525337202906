export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS= 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const HIDE_COMMENT_STATUS = 'HIDE_COMMENT_STATUS';

export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENT_SUCCESS= 'GET_COMMENT_SUCCESS';
export const GET_COMMENT_FAIL = 'GET_COMMENT_FAIL';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS= 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';
import * as ActionTypes from '../actions/comment-action-types';

export const initialState = {
  
comments:[],
commentStatus:null,
commentLists:[],
filterCommnetList:[],
commentCount:null
 
};

export const CommentReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case ActionTypes.ADD_COMMENT:
			return {
				...state,
        comments:[],
			 
			};

		case ActionTypes.ADD_COMMENT_SUCCESS:
			return {
				...state,
				commentStatus: 'success',
			 
			};

		case ActionTypes.ADD_COMMENT_FAIL:
			return {
				...state,
				commentStatus: 'fail',
				 
      };
      
      case ActionTypes.UPDATE_COMMENT:
			return {
				...state,
       
			 
			};

		case ActionTypes.UPDATE_COMMENT_SUCCESS:
			return {
				...state,
			 
			 
			};

		case ActionTypes.UPDATE_COMMENT_FAIL:
			return {
				...state,
				 
				 
			};
      case ActionTypes.HIDE_COMMENT_STATUS:
          return {
            ...state,
            commentStatus: null,
             
          };

     case ActionTypes.GET_COMMENT:
            return {
              ...state,
              payload: action.payload
            };
      
    case ActionTypes.GET_COMMENT_SUCCESS:
      
              let filterList = []; 
              filterList =  action.payload.filter(comment => {
                if(comment.status==='Unresolved')
                return comment;
              });
              
            return {
              ...state,
              commentLists: action.payload,
              filterCommnetList:filterList,
              commentCount:filterList?filterList.length:null
              
            };
      
  case ActionTypes.GET_COMMENT_FAIL:
            return {
              ...state,
              
            };
    
    default:
      return state;
  }
};

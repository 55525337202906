import React from 'react';
import { ChapterStyle } from './style';
import { Row, Modal, Button, Col } from 'react-bootstrap';

function Error(props) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal warning-popup"
      className="modalbg"
      size="md"
      show={props.show}
      onHide={props.handleHideErrorModal}
    >
      <Modal.Body>
        <ChapterStyle.addAssetMessageBox>
          <ChapterStyle.successAssetContent>
            <Row>
              <Col className="success-icon mb-3">
                <img
                  alt=""
                  src="/images/icons/popup_warning_icon.svg"
                  width="86"
                  height="86"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="heading">Oops! Something went wrong</h3>
                <div className="sub-heading">
                  {props.errormsg}
                  <br />
                  {props.errormsg ==
                  'Story ID should not be same as character name or short script name.'
                    ? ''
                    : `The action was not performed. Please try again.`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  data-testid="success-btn"
                  onClick={props.handleHideErrorModal}
                  className="btn-snacktool btn-snacktool-golden btn btn-primary"
                >
                  {' '}
                  Okay{' '}
                </Button>
              </Col>
            </Row>
          </ChapterStyle.successAssetContent>
        </ChapterStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}

export default Error;

import React from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import './../style/style.css';
import UploadFile from './UploadFile';
import AddInfo from './AddInfo';
import * as translations from '../translations';
import { LibraryStyle } from '../style/style';
import SubmitAsset from './SubmitAsset';
import Success from './Success';
import * as Constant from '../constant';
import Fail from './Fail';
import Cancel from './Cancel';

function AddAssetTabs(props) {
  let secondTabClass =
    props.activeTabKey === Constant.SECOND_TAB_KEY
      ? 'circle-fill'
      : 'circle-empty';
  return (
    <LibraryStyle.addInfoContainer>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={Constant.FIRST_TAB_KEY}
        activeKey={props.activeTabKey}
        onSelect={k => props.handleActiveKey(k)}
      >
        {(props.activeTabKey === Constant.FIRST_TAB_KEY ||
          props.activeTabKey === Constant.SECOND_TAB_KEY) && (
          <Row>
            <Col>
              <LibraryStyle.tabLayout>
                <LibraryStyle.leftTabLabelLayout>
                  <LibraryStyle.leftTabLabelContent>
                    <Nav.Link
                      className="circle-fill"
                      eventKey={Constant.FIRST_TAB_KEY}
                    >
                      {props.firstTabLabel === '' && 1}
                      {props.firstTabLabel === 'icon' && (
                        <i className="fa fa-check check-font" />
                      )}
                    </Nav.Link>
                    <LibraryStyle.firstCircleContent>
                      {translations.UPLOAD_FILE}
                    </LibraryStyle.firstCircleContent>
                  </LibraryStyle.leftTabLabelContent>
                  <LibraryStyle.leftTabLine></LibraryStyle.leftTabLine>
                </LibraryStyle.leftTabLabelLayout>
                <LibraryStyle.rightTabLabelLayout>
                  <LibraryStyle.rightTabLabelContent>
                    <Nav.Link
                      className={secondTabClass}
                      eventKey={Constant.SECOND_TAB_KEY}
                    >
                      2
                    </Nav.Link>
                    <LibraryStyle.secondCircleContent>
                      {props.IsAssetEdit
                        ? translations.UPDATE_INFO
                        : translations.ADD_INFO}
                    </LibraryStyle.secondCircleContent>
                  </LibraryStyle.rightTabLabelContent>
                  <LibraryStyle.rightTabLine></LibraryStyle.rightTabLine>
                </LibraryStyle.rightTabLabelLayout>
              </LibraryStyle.tabLayout>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12} className="p-0">
            <Tab.Content>
              <Tab.Pane eventKey={Constant.FIRST_TAB_KEY}>
                <UploadFile
                  setShowClearFile={props.setShowClearFile}
                  setContinueToAddInfoClass={props.setContinueToAddInfoClass}
                  files={props.files}
                  setFiles={props.setFiles}
                  IsAssetEdit={props.IsAssetEdit}
                  asset={props.asset}
                  setShowFileError={props.setShowFileError}
                  showFileError={props.showFileError}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.SECOND_TAB_KEY}>
                <AddInfo
                  handleSaveInfo={props.handleSaveInfo}
                  validated={props.validated}
                  handleFormChange={props.handleFormChange}
                  asset={props.asset}
                  handleTagChange={props.handleTagChange}
                  files={props.files}
                  IsAssetEdit={props.IsAssetEdit}
                  showAssetNameError={props.showAssetNameError}
                  assetInfoListEdit={props.assetInfoListEdit}
                  showObjectError={props.showObjectError}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.THIRD_TAB_KEY}>
                <SubmitAsset
                  progress={props.progress}
                  handleActiveKey={props.handleActiveKey}
                  handleRollbackAsset={props.handleRollbackAsset}
                  IsAssetEdit={props.IsAssetEdit}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FOURTH_TAB_KEY}>
                <Success
                  IsAssetEdit={props.IsAssetEdit}
                  uploadCount={props.uploadCount}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.FIFTH_TAB_KEY}>
                <Fail IsAssetEdit={props.IsAssetEdit} />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.SIXTH_TAB_KEY}>
                <Cancel IsAssetEdit={props.IsAssetEdit} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </LibraryStyle.addInfoContainer>
  );
}

export default AddAssetTabs;

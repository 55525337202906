
import React, { useState } from 'react';
import { Modal, Row, Form, Col, Button } from 'react-bootstrap';
import * as translations from './../create-story/translations'
import { CommentServices } from './../../../components/Header/comments/service';
import { CommentStyle } from './../../../components/Header/comments/styles';
 
 


function CommentsModal(props) {
  const {
    comments,
    handleSaveInfo
  } = props;
  const [validated, setValidated] = useState(false);
  const [addInfoClass, setAddInfoClass] = useState(true);

  const handleFormChange = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    props.setComments({
      ...comments,
      [event.target.name]: event.target.value
    });
     

     
    if (event.currentTarget.form.checkValidity() === false) {
      setAddInfoClass(true);
  }else{
    setAddInfoClass(false);
  }
  };

  //${props.filterList.blockName}
    return (
        <Modal
             size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show = {props.showModal}
            onHide = {props.onHideModal}
            
            backdrop='static'>
            
            <div  className="add-object-popup">
            <Modal.Header closeButton>
            <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
           {translations.ADD_NEW_COMMENT}
               
          </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-object-content">
                <Form
                   id="add-comment-form" 
                noValidate 
                validated={validated}
                onSubmit={(e) => handleSaveInfo(e)} >
                 <Form.Group>
                   
                      
                     
                        <CommentStyle.listHeading>
                   {props.commentLineNo!==null &&     <Row>
                    <Col xs='12' className="cmdscript-box  mr-4 mt-4"  >
                        <h3>{`Command in script (Block: “${props.commentBlockText.trim()}”)`}</h3>
                        <p> <div dangerouslySetInnerHTML={{__html: CommentServices.getCommandColor(props.commentCmdText)}} /> </p>
                        
                        
                    </Col>
                </Row>}
            </CommentStyle.listHeading>
                
                      
                       
                   
                </Form.Group>
                   <Form.Group>
                    <Form.Label>
                        {translations.COMMENT}
                        <Form.Text className="text-muted">
                            {translations.COMMENT_SUB_TITLE}
                        </Form.Text>
                    </Form.Label>
                    <Form.Control as="textarea" type='textarea' className='textAreaResize' rows="5"
                         required 
                         maxlength="250"
                         minlength="1"
                         autocomplete="off"
                        name="commentText" 
                        onChange={handleFormChange}
                        
                          />
                    <Form.Control.Feedback type="invalid">
                        {translations.DESCRIPTION_ERROR_MESSAGE}
                    </Form.Control.Feedback>
                </Form.Group>
                </Form>
                <Row>
                    <Col>
                        <Button
                            className="btn-snacktool btn-snacktool-golden"
                            onClick={props.onHideModal}
                            >
                            Cancel
                        </Button>
                    </Col>
                    <Col className='text-right'>
                        <Button type="submit"
                        disabled={addInfoClass}
                            className="btn-snacktool btn-snacktool-golden"
                            form = "add-comment-form"
                            
                            >
                                Add comment
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            </div>
            
            </Modal>
    );
}

export default CommentsModal
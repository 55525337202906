import * as ActionTypes from './../actions/library-action-types';
import { LibraryServices } from './../components/library/service';
import { CharacterService } from './../components/library/character';

export const initialState = {
    continueToSelectAsset: 'disabled',
    characterNameValidity: true,
    dialogueColor: '#FFFFFF',
    UploadFileCount:0,
    characterAssetList: [],
    characterName: '',
    characterDisplayName:'',
    selectedAsset: [],
    selectedAssetCount: 0,
    filterAssetList : [],
    continueToVariations : 'disabled',
    spriteLayers: [],
      
    finalAndSaveCharacterButton: 'disabled',
    saveFinalCharacter: false,
    showCharacterSaveSuccessModal: false,
    showCharacterSaveFailModal: false,
    selectAll: false,
    isVariationExists: '',
    loadCharacters: false,
    characterList: [],
    characterInfoList:null,
    showDeleteModal:false,
    deleteConfirm:false,
    characterListForScript:[],

    //New Char Creation 
    Files:[],
    variations:[],
    previewVariations:[],
    IsCharacterEdit:false,
    characterData: [],
    editCharaterId:null,
    characterLockData: [],
    showCharLockModal:false,
    routeCharaList:[],
    charLoading:false,
    loadingCharacterNameValidity:true,
    showCharLockFail:false,
    autoSaveFlag: false,
    isUploadCharacter:true,
    isBackToStepOne:false,
    characterLongName: '',
    loadingCharacterLongNameValidity: true
   
}

export const CharacterReducer = (state = initialState, action) => {
 
    switch (action.type) {
        case ActionTypes.SET_CHARACTER_NAME_VALIDITY:
            return {
                ...state,
                //loadingCharacterNameValidity:true,
                loadingCharacterNameValidity:true,
                continueToSelectAsset: 'disabled'
            }
        case ActionTypes.SET_CHARACTER_NAME_VALIDITY_TRUE:
          
            return {
                ...state,
                characterNameValidity: true,
                // loadingCharacterNameValidity:action.body==='false'?false:true,
                // continueToSelectAsset: action.body==='false'?'':'disabled',
                loadingCharacterNameValidity:false,  
                continueToSelectAsset: '',
            }
        case ActionTypes.SET_CHARACTER_NAME_VALIDITY_FALSE:
      
            return {
                ...state,
                characterNameValidity: false,
                loadingCharacterNameValidity:true,
                // loadingCharacterNameValidity:false,
              
                 continueToSelectAsset: 'disabled'
            }
        case ActionTypes.RESET_CHARACTER_NAME_VALIDITY:
            
            return {
                ...state,
                loadingCharacterNameValidity:true, 
                continueToSelectAsset: 'disabled',
            }
        
        case ActionTypes.SET_CONTINUE_TO_SELECT_ASSET_ENABLED:
            return {
                ...state,
                continueToSelectAsset: ''
            }
        case ActionTypes.SET_CONTINUE_TO_SELECT_ASSET_DISABLED:
            return {
                ...state,
                
                continueToSelectAsset: 'disabled',
            }
        case ActionTypes.SET_DIALOGUE_COLOR:
            return {
                ...state,
                dialogueColor: action.color
            }
        
        case ActionTypes.SET_CHARACTER_NAME:
            return {
                ...state,
                characterName: action.characterName
            }
        case ActionTypes.SET_CHARACTER_DISPLAY_NAME:
            return {
                ...state,
                characterDisplayName: action.characterDisplayName
            }
        case ActionTypes.SET_CHARACTER_ASSET_LIST:
            return {
                ...state
            }
        case ActionTypes.SET_CHARACTER_ASSET_LIST_SUCCESS:
            return {
                ...state,
                characterAssetList: action.characterAssetList
            }
        case ActionTypes.SET_CHARACTER_ASSET_LIST_FAIL:
            return {
                ...state
            }
        case ActionTypes.SET_SELECTED_ASSET:
            let response = LibraryServices.checkAssetsCount(state.variations);
            state.continueToVariations = 'disabled';
            if (response[0].length > 0) {
                state.continueToVariations = '';
            }
            // Update sprite layer
            let spriteLayers = state.spriteLayers;
            if (response[1]) {
                spriteLayers = CharacterService.removeSpriteLayer(state.spriteLayers, action.characterAssetId)
            }

            return {
                ...state,
                selectedAsset: response[0],
                selectedAssetCount: response[0].length,
                spriteLayers: spriteLayers
            }
        case ActionTypes.SET_FILTER_ASSET_LIST:
            return {
                ...state,
                filterAssetList: action.filterAssetList
            }
        case ActionTypes.SET_CONTINUE_TO_VARIATIONS_ENABLED:
            return {
                ...state,
                continueToVairations: ''
            }
        case ActionTypes.SET_CONTINUE_TO_VARIATIONS_DISABLED:
            return {
                ...state,
                continueToVairations: 'disabled'
            }
        case ActionTypes.SET_SPRITE_LAYERS:
            return {
                ...state,
                spriteLayers: action.spriteLayers
            }
        case ActionTypes.SET_VARIATION:
            let finalCharacterOnAdd = action.variations.length === 0 ? 'disabled' : '';
            return {
                ...state,
                variations: action.variations,
                finalAndSaveCharacterButton: finalCharacterOnAdd,

            }
        case ActionTypes.SET_REMOVE_VARIATION:
            let variationList = state.variations.filter((variation, index) => {
                return index !== action.index
            });
            let finalCharacterOnRemove = variationList.length === 0 ? 'disabled' : '';
            return {
                ...state,
                variations: variationList,
                finalAndSaveCharacterButton: finalCharacterOnRemove,
            }
        case ActionTypes.INITIALIZE_CREATE_CHARACTER:
           
            return {
                continueToSelectAsset: 'disabled',
                characterNameValidity: true,
                dialogueColor: '#FFFFFF',
                characterList:state.characterList,
                characterAssetList: [],
                characterName: '',
                characterDisplayName:'',
                selectedAsset: [],
                selectedAssetCount: 0,
                filterAssetList : [],
                continueToVariations : 'disabled',
                spriteLayers: [],
                variations: [],
                previewVariations:[],
                Files:[],
                finalAndSaveCharacterButton: 'disabled',
                saveFinalCharacter: false,
                showCharacterSaveSuccessModal: false,
                showCharacterSaveFailModal: false,
                selectAll: false,
                IsCharacterEdit:false,
                characterData:[],
                loadingCharacterNameValidity:true,
                UploadFileCount:0,
                isUploadCharacter:true,
                isBackToStepOne:false,
                characterLongName: ''
            }

             //Get charcter by ID for Edit
        case ActionTypes.GET_CHARACTERS_BY_ID:
            return {
                ...state,
                characterName: '',
                characterDisplayName:'',
                characterLongName: '',
                dialogueColor: '#FFFFFF',
               // variations: [],
               // previewVariations:[],
                isBackToStepOne:false,
                Files:[],
                characterLockData: [],
                showCharLockModal:false,
                showCharLockFail: false,
                loadingCharacterNameValidity:false,  

            }
        case ActionTypes.GET_CHARACTERS_BY_ID_SUCCESS:
            let varDataForEdit=[];
            let file=[];
                if (action.characterData && action.characterData.allVariationsOfCharacter.length >0) {
                for(let i=0;i<action.characterData.allVariationsOfCharacter.length;i++){
                    varDataForEdit.push(action.characterData.allVariationsOfCharacter[i]);
                    varDataForEdit[i]['fileIndex']=i;
                    varDataForEdit[i]['fileUploaded']=false;
                //let f=new File([""], "", {type: "text/plain"});
                
                //  let fileN= LibraryServices.loadImageWithoutCanvas(action.characterData.allVariationsOfCharacter[i].awsPath);
    
                    file.push(action.characterData.allVariationsOfCharacter[i].awsPath);
                }
            }

            // LibraryServices.checkAssetsCount(action.variation);
            
            state.continueToVariations = '';
            state.continueToSelectAsset = '';
            
            return {
                ...state,
                characterData: action.characterData,
                characterName:action.characterData?action.characterData.characterName:'',
                characterDisplayName:action.characterData?action.characterData.displayName:'',
                characterLongName: action.characterData ? action.characterData.characterLongName : '',
                dialogueColor:action.characterData?action.characterData.labelColor:'',
                variations:varDataForEdit,
                previewVariations:varDataForEdit,
                Files:file,
                IsCharacterEdit:true,
                characterLockData: [],
                showCharLockModal:false,
                showCharLockFail: false,
            }

        case ActionTypes.GET_CHARACTERS_BY_ID_FAIL:
            return {
                ...state,
                characterData: [],
                IsCharacterEdit:false,
                characterLockData: action.payload,
                showCharLockModal: action.payload.length>0?true:false,
            }
        case ActionTypes.GET_CHARACTERS_BY_LOCK_FAIL:
            return {
                ...state,
                characterData: [],
                IsCharacterEdit:false,
                characterLockData: [],
                showCharLockFail: true,
            }

            
        case ActionTypes.SET_SHOW_CHAR_LOCAK_MODAL_FALSE:
            return {
                ...state,
                characterLockData: [],
            showCharLockModal:false,
                
            };
        
        case ActionTypes.SET_CHARACTER_EDIT_TRUE:
            
            return {
                ...state,
                IsCharacterEdit: true,
                editCharaterId:action.characterId
            
            }
        case ActionTypes.SET_CHARACTER_EDIT_FALSE:
                
            return {
                ...state,
                IsCharacterEdit: false,
                editCharaterId:null
                    
            }

            case ActionTypes.SET_UPLAOD_CHARACTER:
                
              return {
                  ...state,
                  isUploadCharacter:false
                      
              }

            
        case ActionTypes.SET_CHARACTER_ASSET:
            let isEnableVarations =action.variation.length>0?true:false;// LibraryServices.checkAssetsCount(action.variation);
            state.continueToVariations = 'disabled';
            if (isEnableVarations===true) {
                state.continueToVariations = '';
            }
         
           let cnt=state.UploadFileCount + action.uploadCountFiles?action.uploadCountFiles:0;
            return {
                ...state,
                variations: action.variation,
                Files:action.files,
                isUploadCharacter:true,
                UploadFileCount: cnt
                
            }
        case ActionTypes.DELETE_CHARACTER_ASSET:
            let isDeltedEnableVarations = action.variation.length>0?true:false;//LibraryServices.checkAssetsCount(action.variation);
            state.continueToVariations = 'disabled';
            if (isDeltedEnableVarations===true) {
                state.continueToVariations = '';
            }
            return {
                ...state,
                variations: action.variation
            
            }
        case ActionTypes.DELETE_CHARACTER_PREVIEW_ASSET:
            
            return {
                ...state,
                previewVariations: action.previewVariations
            
            }
        case ActionTypes.SET_CHARACTER_PREVIEW_ASSET:
                
            return {
                ...state,
                previewVariations: action.previewVariations,
                    
            }
            
        case ActionTypes.SET_SAVE_FINAL_CHARACTER_FAIL:
            return {
                ...state,
                saveFinalCharacter: false,
                UploadFileCount:0,
                variations: [],
                previewVariations:[],
            }
        case ActionTypes.SET_SAVE_FINAL_CHARACTER_SUCCESS:
            return {
                ...state,
                saveFinalCharacter: true,
                UploadFileCount:0,
                    variations: [],
               previewVariations:[],
            }
        case ActionTypes.SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_TRUE:
            return {
                ...state,
                showCharacterSaveSuccessModal: true
            }
        case ActionTypes.SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_FALSE:
                return {
                    ...state,
                    showCharacterSaveSuccessModal: ''
                }
        case ActionTypes.SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_TRUE:
            return {
                ...state,
                showCharacterSaveFailModal: true,
                showCharacterSaveSuccessModal:''
            }
        case ActionTypes.SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_FALSE:
            return {
                ...state,
                 
                showCharacterSaveFailModal: false,
            }
        case ActionTypes.SET_SELECT_ALL:
            return {
                ...state,
                selectAll: action.selectAll
            }
        case ActionTypes.SET_VARIATION_EXISTS:
            return {
                ...state,
                isVariationExists: action.isVariationExists
            }
        case ActionTypes.SET_GET_CHARACTERS:
   
            return {
                ...state,
                IsCharacterEdit: false,
                    editCharaterId:null,
                    characterName: '',
                    characterDisplayName:'',
                    dialogueColor: '#FFFFFF',
                    variations: [],
                    previewVariations:[],
                    Files:[],
                    charLoading:true,
                    characterList:[],
                    routeCharaList:[],
                    showCharLockFail:true,
                    characterLongName: ''
            }
        case ActionTypes.SET_GET_CHARACTERS_SUCCESS:
        
            return {
                ...state,
                characterList: action.characterList,
                routeCharaList: action.characterRouteList,
                IsCharacterEdit: false,
                editCharaterId:null,
                charLoading:false,
                showCharLockFail:false
            }

        case ActionTypes.SET_GET_CHARACTERS_FAIL:
     
            return {
                ...state,
                characterList: [],
                routeCharaList:[],
                IsCharacterEdit: false,
                editCharaterId:null,
                charLoading:false,
                showCharLockFail:false
            }

        case ActionTypes.SET_GET_ROUTE_CHARACTERS:
      
            return {
                ...state,
                routeCharacterList:[]
            }
        case ActionTypes.SET_GET_ROUTE_CHARACTERS_SUCCESS:
          
            return {
                ...state,
                routeCharacterList:action.routeCharacterList
            }

        case ActionTypes.SET_GET_ROUTE_CHARACTERS_FAIL:
            return {
                ...state,
                routeCharacterList:[]
            }

       

        case ActionTypes.SET_GET_CHARACTERS_SCRIPT:
            return{
                ...state,
                characterListForScript :[]
            }

        case ActionTypes.SET_GET_CHARACTERS_SCRIPT_FAIL:
            return{
                ...state,
                characterListForScript :[]
            }

        case ActionTypes.SET_GET_CHARACTERS_SCRIPT_SUCCESS:
           
            return{
                ...state,
                characterListForScript :action.characterList
            }

        case ActionTypes.SET_CHARACTER_LIST_SORT:
            return {
                ...state,
                characterList: CharacterService.sortCharacterList(state.characterList, action.sortingOrder)
            }
        case ActionTypes.GET_CHARACTER_DELETE:
            return {
                ...state,
            }
        case ActionTypes.GET_CHARACTER_DELETE_SUCCESS:
            return {
                ...state,
                characterInfoList: action.characterList,
                showDeleteModal: true
            }
        case ActionTypes.CHARACTER_DELETE_FAIL:
            return {
                ...state,
                characterInfoList: null
            }

        case ActionTypes.CHARACTER_DELETE:
            return {
                ...state,
            }
        case ActionTypes.CHARACTER_DELETE_SUCCESS:
            return {
                ...state,
                characterInfoList: null,
                showDeleteModal: true,
                deleteConfirm:true
            }
        case ActionTypes.GET_CHARACTER_DELETE_FAIL:
            return {
                ...state,
                characterInfoList: null,
                showDeleteModal: false,
                deleteConfirm:false
            }


        case ActionTypes.SET_SHOW_DELTE_MODAL_TRUE:
            return {
                ...state,
                showDeleteModal: true,
                characterInfoList:null
                
            };
        case ActionTypes.SET_SHOW_DELETE_MODAL_FALSE:
            return {
                ...state,
                showDeleteModal: false,
                deleteConfirm:false,
                characterInfoList: null
                
            };
        case ActionTypes.SET_CHARACTER_AUTOSAVE_FLAG:
            return {
                ...state,
                autoSaveFlag: action.payload
            }
     case ActionTypes.SET_CHARACTER_STEP_ONE:
              return {
                  ...state,
                  isBackToStepOne: action.payload
              }
        case ActionTypes.RESET_CHARACTER_AUTOSAVE_FLAG:
            return {
                ...state,
                autoSaveFlag: false
            }
        case ActionTypes.SET_CHARACTER_LONG_NAME:
            return {
                ...state,
                characterLongName: action.characterLongName
            }
        case ActionTypes.RESET_CHARACTER_LONG_NAME_VALIDITY:
            return {
                ...state,
                loadingCharacterLongNameValidity:true, 
                continueToSelectAsset: 'disabled',
            }
        default :
            return {
                ...state,
            }
    }
}
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DeleteStyle } from './style';
import * as Translations from './translation';

function Success(props) {
  let title = '';
  let subTitle = '';

  switch (props.reviewStatus) {
    case 'APPROVED':
      title = Translations.TITLE_SUCCESS_APPROVE;
      subTitle = Translations.SUBTITLE_SUCCESS_APPROVE;

      break;
    case 'REJECTED':
      title = Translations.TITLE_SUCCESS_REJECT;
      subTitle = Translations.SUBTITLE_SUCCESS_REJECT;

      break;
    case 'PUBLISH':
      title = Translations.TITLE_SUCCESS_PUBLISH;
      subTitle = Translations.SUBTITLE_SUCCESS_REJECT;

      break;
    case 'UNPUBLISH':
      title = Translations.TITLE_SUCCESS_UNPUBLISH;
      subTitle = Translations.SUBTITLE_SUCCESS_UNPUBLISH;

      break;

    default:
      break;
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal"
      className="modalbg"
      size="md"
      show={props.showModal}
      onHide={props.handleHideAdminReviewModal}
    >
      <Modal.Body>
        <DeleteStyle.addAssetMessageBox>
          <DeleteStyle.successAssetContent>
            <div className="success-icon mb-3">
              <img alt="" className="mediumIcon" src="/images/icons/ic_done_24px.svg" />
            </div>
            <div data-testid="success-label" className="submit-asset-label">
              {title}
            </div>
            <div
              data-testid="success-note"
              className="text-muted submit-asset-subtitle "
            >
              {subTitle}
            </div>
            <div>
              <Button
                data-testid="success-btn"
                onClick={props.handleHideAdminReviewModal}
                className="btn-snacktool btn-snacktool-golden btn btn-primary"
              >
                {' '}
                Okay{' '}
              </Button>
            </div>
          </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}
export default Success;

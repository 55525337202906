import React from 'react';
import * as Constants from './../../../common/constants';
import * as LibraryConstant from './../constant';
import * as translations from './../translations';

const createPreviewThumb = (files,isEdit,IsCharacterEdit) => {
  let thumbs=[];
  
   if (typeof files[0] === 'string'){
     thumbs.push((
      <>
          {
            <img alt='' src={files[0]}  key='1' width="100%" height="100%" alt="previewImage"/>
            }
         
      </>));
  

   }
 else{
     thumbs =  files.map(file => (
        <>
            {
              
              
              getFileType(file) === 'image' && 
              <img alt='' src={isEdit?URL.createObjectURL(file):file.preview} key={file.name} width="100%" height="100%"  alt="previewImage"/>
            }
           
        </>
    ));
          }
         

    return thumbs;
}

const checkFileSize = (rejectedFiles, maxSize) => {
    return rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize
}

const createPreviewUrl = (acceptedFiles) => {
    let newFiles = acceptedFiles[0];
    return [newFiles].map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
    }))
}

const getAssetTypeList = (files) => {
    if (files.length === 0) {
        return Constants.ASSET_TYPES_IMAGES;
    }
    let fileType = getFileType(files[0]);
    if (fileType === 'image') {
        return Constants.ASSET_TYPES_IMAGES;
    }

    return Constants.ASSET_TYPES_AUDIO;
}

const getCharacterTypeList = (files) => {
   

  return Constants.ASSET_TYPES_CHARACTER;
}

const getFileType = (file) => {
 
    if (file !== null) {
        return file.type.split('/')[0]
    }
    
    return null;
}

const getFileExtension = (file) => {
    if (file !== null) {
        return file.type.split('/')[1].split('+')[0].toUpperCase();
    }
    
    return null;
}

const getActiveTabKey = (assetStatus, activeTabKey, hideModal, intervalId) => {
    let newActiveTab = activeTabKey;

    if (assetStatus === 'success') {
        newActiveTab = LibraryConstant.FOURTH_TAB_KEY;
    }

    if (assetStatus === 'fail') {
        newActiveTab = LibraryConstant.FIFTH_TAB_KEY;
    }

    if (assetStatus === 'cancel') {
        newActiveTab = LibraryConstant.SIXTH_TAB_KEY;
    }

    if (newActiveTab === LibraryConstant.FOURTH_TAB_KEY 
        || newActiveTab === LibraryConstant.FIFTH_TAB_KEY
        || newActiveTab === LibraryConstant.SIXTH_TAB_KEY) {
        setTimeout(hideModal, 3000);
        clearInterval(intervalId);
    }

    return newActiveTab;
}

const getFormData = (asset, files) => {
    var formData = new FormData();
    formData.append("file", files[0]);
    formData.append("asset", JSON.stringify(asset));

    return formData;
}

const filterAssetList = (assets, filters, order) => {
    let filterList = []; 
        filterList = assets.filter(asset => {
            if (filters.image === true) {
                return  Constants.ASSET_TYPES_IMAGES.indexOf(asset.type) > -1;
            }

            if (filters.audio === true) {
                return  Constants.ASSET_TYPES_AUDIO.indexOf(asset.type) > -1;
            }

           

            return asset;
        });

    let newList = sortList(filterList, order);

    return newList;
}

const sortList = (assetList, order) => {
    if (order === 'desc') {
        return assetList.sort((a,b) => {
            return new Date(b.updatedAt).getTime() - 
                new Date(a.updatedAt).getTime()
        }).reverse();    
    } 

    return assetList.sort((a,b) => {
        return new Date(a.updatedAt).getTime() - 
            new Date(b.updatedAt).getTime()
    }).reverse();
}

const getAssetTypeLabel = (assetTypeName) => {
    let explodeName = assetTypeName 
    return explodeName;
}

const showError = (rejectedFiles) => {
    let file = rejectedFiles[0];
    let fileType = getFileType(file);
    let error = '';
    switch (fileType) {
        case 'image': 
            error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
            break;
        case 'audio':
            error = translations.ASSET_AUDIO_TYPE_ERROR_MESSAGE;
            break;
        default:
            error = translations.FILES_TYPE_NOT_ACCEPTED;
    }

    return error;
}

const filterAssetListByCharacter = (assetList, searchAsset) => {
    let filterList = assetList.filter((asset) => {
        return asset.name.toLowerCase().includes(searchAsset.toLowerCase()) || asset.tags.indexOf(searchAsset) > -1;
    });

    return filterList;
}

const getSelectedAssetIds = (selectedAsset, characterAssetId, selectAll) => {
    let selectedAssetIds = [];
    let remove = false;
    if (selectedAsset.indexOf(characterAssetId) > -1 && selectAll === false) {
        selectedAssetIds = selectedAsset.filter(x => x !== characterAssetId);
        remove = true;
    } else {
        selectedAsset.push(characterAssetId);
        selectedAssetIds = [...new Set(selectedAsset)];
    }

    return [selectedAssetIds, remove];
}

const getElementFormData = (file, id, specs, storyId) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append('story_id', storyId);
    formData.append('id', id);
    formData.append('specs', specs);

    return formData;
}

const checkDefaultAssetCount = (asset,type) => {
  let isEnabled=true;
   let outCnt=0; 
      for(let j =0; j< asset.length; j++) {
        if(asset[j].assetType===type){
            outCnt++
      }
     }
    if( outCnt>0){
      isEnabled=false;
    }
  return isEnabled;
}

const checkDefaultAssetsCount = (asset,type,defaultAsset) => {
  let isMaxCount=true;
   let outCnt=1; 
      for(let j =0; j< asset.length; j++) {
        if(asset[j].assetType===type){
            outCnt++
      }
     }
     
    if( outCnt>1 ){
      isMaxCount=false;
    }
    else{
      isMaxCount=true;
    }
    // if(defaultAsset===true){
    //   isMaxCount=true;
    // }
  return isMaxCount;
}


const checkAssetName = (asset,val) => {
  let isExist=false;
   let outCnt=0; 
      for(let j =0; j< asset.length; j++) {
        if(asset[j].assetName.toLowerCase() === val.toLowerCase()){
            outCnt++
      }
     }
    if( outCnt>0){
      isExist=true;
    }
  return isExist;
}



export const LibraryServices = {
    createPreviewThumb,
    checkFileSize,
    createPreviewUrl,
    getAssetTypeList,
    getCharacterTypeList,
    getFileType,
    getFileExtension,
    getActiveTabKey,
    getFormData,
    filterAssetList,
    getAssetTypeLabel,
    showError,
    filterAssetListByCharacter,
    getSelectedAssetIds,
    getElementFormData,
    checkDefaultAssetCount,
    checkAssetName,
    checkDefaultAssetsCount

}
import { SliderPicker } from 'react-color';
import { endpoint } from '../../config/app.config';

//list Scene API
export const listSceneFromApi = payload => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/scenes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//create Scene API
export const createSceneFromApi = payload => {
  //const bodyObj = { "name": payload.sceneName };

  return fetch(`${endpoint}/api/stories/${payload.storyId}/scenes`, {
    method: 'POST',
    body: JSON.stringify(payload.sceneData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//create Route API
export const createRouteFromApi = payloads => {
  if (payloads.IsRouteEdit === true) {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/routes/${payloads.routeId}`,
      {
        method: 'PUT',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(`${endpoint}/api/stories/${payloads.storyId}/routes`, {
      method: 'POST',
      body: payloads.payload,
      headers: {
        Authorization: `Bearer ${payloads.authToken}`
      }
    })
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  }
};

export const createCastFromApi = payloads => {
  if (payloads.isCastEdit === true) {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/characters/${payloads.characterId}/casts/`,
      {
        method: 'PUT',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  } else {
    return fetch(
      `${endpoint}/api/stories/${payloads.storyId}/characters/${payloads.characterId}/casts/`,
      {
        method: 'POST',
        body: payloads.payload,
        headers: {
          Authorization: `Bearer ${payloads.authToken}`
        }
      }
    )
      .then(res => res.json())
      .then(jsonresp => jsonresp)
      .catch(err => {
        throw err;
      });
  }
};

export const listRouteFromApi = payload => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/routes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

//api/stories/{story_id}/routes/{id}
export const deletetRouteData = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/routes/${payloads.routeId}`,
    {
      method: 'PATCH',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payloads.authToken,
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

////api/stories/{story_id}/routes/{id}/markvalue
export const markRouteDataApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/routes/${payloads.routeId}/status/${payloads.flag}`,
    {
      method: 'PATCH',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payloads.authToken
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const markDraftStatusApi = payloads => {
  return fetch(
    `${endpoint}/api/stories/${payloads.storyId}/scenes/${payloads.sceneId}/publish/${payloads.flag}`,
    {
      method: 'PATCH',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + payloads.authToken,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PUT,GET,PATCH'
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

//getRouteById

export const getRouteById = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/routes/${payload.routeId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getCast = paylaod => {
  return fetch(
    `${endpoint}/api/stories/${paylaod.storyId}/characters/${paylaod.characterId}/casts`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${paylaod.authToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const updateSceneFromApi = payload => {
  return fetch(`${endpoint}/api/stories/${payload.storyId}/scenes`, {
    method: 'PUT',
    body: JSON.stringify(payload.updatedList),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(jsonresp => jsonresp)
    .catch(err => {
      throw err;
    });
};

export const getFlowchartFromApi = payload => {
  // return fetch(`http://localhost:3001/scene/`,
  return fetch(`${endpoint}/api/stories/${payload.storyId}/details`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const lockTheChapter = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/scenes/${payload.blockId}/acquire_lock`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getBlockFlowchartFromApi = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/scenes/${payload.blockId}/flowchart/`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const postFlowchartChangesApi = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/scenes/${payload.chapterId}/flowchart/`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
        'Content-Type': 'application/json'
      },
      body:
        payload.flowchartJson != null
          ? JSON.stringify(payload.flowchartJson)
          : JSON.stringify({})
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const deleteCastApi = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/characters/${payload.characterId}/casts/`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getPreviewDataOnClickApi = payload => {
      
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/scenes/${payload.blockId}/recipe/`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

export const getBlockNameValidApi = payload => {
  return fetch(
    `${endpoint}/api/stories/${payload.storyId}/scenes/${
      payload.sceneId
    }/blocks?name=${encodeURIComponent(payload.title)}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => {
      return resp;
    })
    .catch(err => {
      throw err;
    });
};

import * as Constant from './../../../common/constants';
import { GenerateElements } from './generate-element';
import * as AspectRatioConfig from './../../../config/aspectRatio.config';
import { PlayerService } from './player-service';
import { last } from 'lodash';

const parseEnterTheScene = (
  command,
  blobImages,
  skipTimeout = false,
  aspectRatio,
  lastEnterCharacter,
  variableDTO
) => {
  let styles = {};
  let sprites = [];
  if (
    (command.commandId === Constant.COMMAND_REPLACE_CHARACTER ||
      command.commandId === Constant.COMMAND_CHANGE_EXPRESSION) &&
    lastEnterCharacter != null
  ) {
    let newCharacter =
      command.commandId === Constant.COMMAND_CHANGE_EXPRESSION
        ? command.parameters.Character
        : command.parameters['Original character'];
    if (lastEnterCharacter[newCharacter] !== undefined) {
      let newCommand =
        lastEnterCharacter[newCharacter].enterCharacterCommand === undefined
          ? lastEnterCharacter[newCharacter].setOutfitCommand
          : lastEnterCharacter[newCharacter].enterCharacterCommand;
      if (
        lastEnterCharacter[newCharacter].enterCharacterCommand === undefined &&
        lastEnterCharacter[newCharacter].setOutfitCommand === undefined
      ) {
        newCommand = {};
      }
      if (
        lastEnterCharacter[newCharacter].enterCharacterCommand === undefined
      ) {
        newCommand['parameters'] = {
          ...newCommand,
          [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
          [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false'
        };
      }
      let commandParameters = newCommand.parameters;
      if (
        command.commandId === Constant.COMMAND_CHANGE_EXPRESSION ||
        command.commandId == Constant.COMMAND_REPLACE_CHARACTER
      ) {
        commandParameters = command.parameters;
        commandParameters[Constant.CHARACTER_POSITION_IN_SCREEN] =
          newCommand.parameters[Constant.CHARACTER_POSITION_IN_SCREEN];
        commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] =
          newCommand.parameters[Constant.CHARACTER_FLIP_HORIZONTALLY];
      }

      // if (
      //   lastEnterCharacter[newCharacter].changeExpressionCommand &&
      //   commandParameters.hasOwnProperty(Constant.CHARACTER_FADE_INTO_SCREEN)
      // ) {
      //   // delete commandParameters[Constant.CHARACTER_FADE_INTO_SCREEN];
      // }

      styles = setCommandParameters(
        commandParameters,
        styles,
        skipTimeout,
        aspectRatio,
        command.commandId,
        lastEnterCharacter
      );
      sprites = command.sprites;
    }
  } else if (command.commandId === Constant.COMMAND_SHOW_OUTFIT) {
    let showOutfitParameters = prepareShowOutfitCharacterCommand(
      command,
      lastEnterCharacter,
      styles,
      skipTimeout,
      aspectRatio,
      sprites,
      variableDTO
    );
    if (showOutfitParameters === undefined) {
      return;
    }
    styles = showOutfitParameters.styles;
    sprites = showOutfitParameters.sprites;
  } else if (command.commandId === Constant.COMMAND_REPLACE_OUTFIT) {
    let replaceOutfitParameters = prepareReplaceOutfitCharacterCommand(
      command,
      lastEnterCharacter,
      styles,
      skipTimeout,
      aspectRatio,
      sprites,
      variableDTO
    );
    if (replaceOutfitParameters === undefined) {
      return;
    }
    styles = replaceOutfitParameters.styles;
    sprites = replaceOutfitParameters.sprites;
  } else {
    styles = setCommandParameters(
      command.parameters,
      styles,
      skipTimeout,
      aspectRatio,
      command.commandId,
      lastEnterCharacter
    );
    sprites = command.sprites;
  }
  let imageTag = GenerateElements.createCharacterImage(
    styles,
    sprites,
    blobImages
  );

  return imageTag;
};

const prepareShowOutfitCharacterCommand = (
  command,
  lastEnterCharacter,
  styles,
  skipTimeout,
  aspectRatio,
  sprites,
  variableDTO
) => {
  let newCharacter = command.parameters.Character;
  let enterCharacterCommand =
    lastEnterCharacter[newCharacter].enterCharacterCommand;
  let hideCharacterCommand =
    lastEnterCharacter[newCharacter].hideCharacterCommand;
  if (hideCharacterCommand) {
    if (
      hideCharacterCommand.parameters.hasOwnProperty(
        Constant.CHARACTER_TRANSITION_TYPE
      ) &&
      (hideCharacterCommand.parameters[Constant.CHARACTER_TRANSITION_TYPE] ===
        Constant.TRANSITION_TYPE_SLIDING_OUT_RIGHT ||
        hideCharacterCommand.parameters[Constant.CHARACTER_TRANSITION_TYPE] ===
          Constant.TRANSITION_TYPE_SLIDING_OUT_LEFT)
    ) {
    } else {
      let commandParameters = enterCharacterCommand.parameters;
      commandParameters[Constant.CHARACTER_FADE_INTO_SCREEN] =
        command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN];
      [styles, sprites] = prepareSetOutfitStyleSprites(
        commandParameters,
        styles,
        sprites,
        skipTimeout,
        aspectRatio,
        command,
        variableDTO,
        lastEnterCharacter,
        newCharacter
      );
    }
  } else {
    let commandObject = null;
    if (enterCharacterCommand) {
      commandObject = enterCharacterCommand.parameters;
      commandObject[Constant.CHARACTER_FADE_INTO_SCREEN] =
        command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN];
    } else {
      commandObject = {
        [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
        [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false',
        [Constant.CHARACTER_FADE_INTO_SCREEN]:
          command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN]
      };
    }
    [styles, sprites] = prepareSetOutfitStyleSprites(
      commandObject,
      styles,
      sprites,
      skipTimeout,
      aspectRatio,
      command,
      variableDTO,
      lastEnterCharacter,
      newCharacter
    );
  }

  return {
    styles: styles,
    sprites: sprites
  };
};

const prepareReplaceOutfitCharacterCommand = (
  command,
  lastEnterCharacter,
  styles,
  skipTimeout,
  aspectRatio,
  sprites,
  variableDTO
) => {
  let newCharacter = command.parameters['New character'];

  if (
    lastEnterCharacter[command.parameters['Original character']] !== undefined
  ) {
    let enterCharacterCommand =
      lastEnterCharacter[command.parameters['Original character']]
        .enterCharacterCommand;
    let hideCharacterCommand =
      lastEnterCharacter[command.parameters['Original character']]
        .hideCharacterCommand;
    if (hideCharacterCommand) {
      if (
        hideCharacterCommand.parameters.hasOwnProperty(
          Constant.CHARACTER_TRANSITION_TYPE
        ) &&
        (hideCharacterCommand.parameters[Constant.CHARACTER_TRANSITION_TYPE] ===
          Constant.TRANSITION_TYPE_SLIDING_OUT_RIGHT ||
          hideCharacterCommand.parameters[
            Constant.CHARACTER_TRANSITION_TYPE
          ] === Constant.TRANSITION_TYPE_SLIDING_OUT_LEFT)
      ) {
      } else {
        let commandParameters = enterCharacterCommand.parameters;
        commandParameters[Constant.CHARACTER_FADE_INTO_SCREEN] =
          command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN];
        [styles, sprites] = prepareSetOutfitStyleSprites(
          enterCharacterCommand.parameters,
          styles,
          sprites,
          skipTimeout,
          aspectRatio,
          command,
          variableDTO,
          lastEnterCharacter,
          newCharacter
        );
      }
    } else {
      let commandObject = null;
      if (enterCharacterCommand) {
        commandObject = enterCharacterCommand.parameters;
        commandObject[Constant.CHARACTER_FADE_INTO_SCREEN] =
          command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN];
      } else {
        commandObject = {
          [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
          [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false',
          [Constant.CHARACTER_FADE_INTO_SCREEN]:
            command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN]
        };
      }
      [styles, sprites] = prepareSetOutfitStyleSprites(
        commandObject,
        styles,
        sprites,
        skipTimeout,
        aspectRatio,
        command,
        variableDTO,
        lastEnterCharacter,
        newCharacter
      );
    }
  } else if (variableDTO !== undefined) {
    let commandObject = {
      [Constant.CHARACTER_POSITION_IN_SCREEN]: 'Middle',
      [Constant.CHARACTER_FLIP_HORIZONTALLY]: 'false',
      [Constant.CHARACTER_FADE_INTO_SCREEN]:
        command.parameters[Constant.CHARACTER_FADE_INTO_SCREEN]
    };
    styles = setCommandParameters(
      commandObject,
      styles,
      skipTimeout,
      aspectRatio,
      command.commandId,
      lastEnterCharacter
    );
    sprites = prepareVariableDTOSprites(command, variableDTO, sprites);
    if (sprites.length === 0) {
      sprites = command.sprites;
    }
  }
  return {
    styles: styles,
    sprites: sprites
  };
};

const getSpritesFromOptions = variableDTO => {
  if (
    (variableDTO.options && variableDTO.options.length === 0) ||
    variableDTO.options === undefined
  ) {
    return [];
  }

  let option = variableDTO.options.filter(
    item => item.name === variableDTO.value
  );
  return option;
};

const prepareVariableDTOSprites = (command, variableDTO, sprites) => {
  let optionsSprites = [];
  let hairSprites = [];
  let accessorySprites = [];
  let outfitSprites = [];

  // Outfit sprites changes
  if (
    variableDTO.outfit.sprites !== undefined &&
    variableDTO.outfit.sprites.name === variableDTO.outfit.value &&
    variableDTO.outfit.sprites.hasOwnProperty('url')
  ) {
    outfitSprites = command.sprites.concat([variableDTO.outfit.sprites]);
  } else if ((optionsSprites = getSpritesFromOptions(variableDTO.outfit))) {
    if (optionsSprites.length > 0 && optionsSprites[0].hasOwnProperty('url')) {
      outfitSprites = command.sprites.concat([optionsSprites[0]]);
    }
  }

  // Hair sprites changes
  if (
    variableDTO.hair.sprites !== undefined &&
    variableDTO.hair.sprites.name === variableDTO.hair.value &&
    variableDTO.hair.sprites.hasOwnProperty('url')
  ) {
    hairSprites = command.sprites.concat([variableDTO.hair.sprites]);
  } else if ((optionsSprites = getSpritesFromOptions(variableDTO.hair))) {
    if (optionsSprites.length > 0 && optionsSprites[0].hasOwnProperty('url')) {
      hairSprites = command.sprites.concat([optionsSprites[0]]);
    }
  }

  // Accessory sprites changes
  if (
    variableDTO.accessory.sprites !== undefined &&
    variableDTO.accessory.sprites.name === variableDTO.accessory.value &&
    variableDTO.accessory.sprites.hasOwnProperty('url')
  ) {
    accessorySprites = command.sprites.concat([variableDTO.accessory.sprites]);
  } else if ((optionsSprites = getSpritesFromOptions(variableDTO.accessory))) {
    if (optionsSprites.length > 0 && optionsSprites[0].hasOwnProperty('url')) {
      accessorySprites = command.sprites.concat([optionsSprites[0]]);
    }
  }

  let newSet = accessorySprites.concat(hairSprites).concat(outfitSprites);
  newSet = PlayerService.rearrangeSpritesLayer(newSet);

  // use filter for unique entries
  return newSet.filter((item, index) => newSet.indexOf(item) === index);
};

const prepareSetOutfitStyleSprites = (
  parameters,
  styles,
  sprites,
  skipTimeout,
  aspectRatio,
  command,
  variableDTO,
  lastEnterCharacter,
  newCharacter
) => {
  styles = setCommandParameters(
    parameters,
    styles,
    skipTimeout,
    aspectRatio,
    command.commandId,
    lastEnterCharacter
  );
  if (
    lastEnterCharacter[newCharacter] &&
    lastEnterCharacter[newCharacter].setOutfitCommand &&
    lastEnterCharacter[newCharacter].hasOwnProperty('setOutfitCommand')
  ) {
    sprites = lastEnterCharacter[newCharacter].setOutfitCommand.sprites.concat(
      command.sprites
    );
  } else if (variableDTO !== undefined) {
    sprites = prepareVariableDTOSprites(command, variableDTO, sprites);
    if (sprites.length === 0) {
      sprites = command.sprites;
    }
  }

  return [styles, sprites];
};

// Character style guid for animation and positions
const setCommandParameters = (
  commandParameters,
  styles,
  skipTimeout,
  aspectRatio,
  commandId,
  lastEnterCharacter
) => {
  if (Object.keys(commandParameters).length !== 0) {
    if (
      commandParameters.hasOwnProperty(Constant.CHARACTER_POSITION_IN_SCREEN)
    ) {
      styles = imagePosition(
        styles,
        commandParameters[Constant.CHARACTER_POSITION_IN_SCREEN],
        aspectRatio
      );
    }
    if (
      commandParameters.hasOwnProperty(Constant.CHARACTER_FLIP_HORIZONTALLY)
    ) {
      styles = flipHorizontally(
        styles,
        commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY]
      );
    }

    if (commandId === Constant.COMMAND_ENTER_THE_SCENE) {
      if (
        skipTimeout === false &&
        commandParameters.hasOwnProperty(Constant.CHARACTER_DELAY_TO_APPEAR)
      ) {
        styles = delayToAppear(
          styles,
          commandParameters[Constant.CHARACTER_DELAY_TO_APPEAR]
        );
      }

      
      if (
        skipTimeout === false &&
        commandParameters.hasOwnProperty(Constant.CHARACTER_FADE_INTO_SCREEN)
      ) {
        // if (
        //   lastEnterCharacter.hasOwnProperty(commandParameters['Character'])
        //     .hideCharacterCommand
        // ) {
          styles = fadeIntoScreen(
            styles,
            commandParameters[Constant.CHARACTER_FADE_INTO_SCREEN]
          );
        // }
      }
      if (
        skipTimeout === false &&
        commandParameters.hasOwnProperty(Constant.CHARACTER_APPEAR_FROM)
      ) {
        styles = appearFrom(styles, commandParameters, lastEnterCharacter);
      }
    }
    if (
      commandId === Constant.COMMAND_SHOW_OUTFIT ||
      commandId === Constant.COMMAND_REPLACE_OUTFIT ||
      commandId === Constant.COMMAND_REPLACE_CHARACTER ||
      commandId === Constant.COMMAND_CHANGE_EXPRESSION
    ) {
      if (
        skipTimeout === false &&
        commandParameters.hasOwnProperty(Constant.CHARACTER_FADE_INTO_SCREEN)
      ) {
        // let character = document.getElementById(
        //   'char-' + commandParameters.characterDisplayName
        // );
        styles = fadeIntoScreen(
          styles,
          commandParameters[Constant.CHARACTER_FADE_INTO_SCREEN]
        );
        if ((commandId === Constant.COMMAND_CHANGE_EXPRESSION
          && lastEnterCharacter.hasOwnProperty(commandParameters['Character'])
          && !lastEnterCharacter[commandParameters['Character']].hasOwnProperty('hideCharacterCommand'))
          || (commandId === Constant.COMMAND_CHANGE_EXPRESSION
            && !lastEnterCharacter.hasOwnProperty(commandParameters['Character']))) {
              delete styles['WebkitAnimation'];
              delete styles['animation'];
          }
      }
    }
  }
  return styles;
};

const appearFrom = (styles, commandParameters, lastEnterCharacter) => {
  let duration = commandParameters[Constant.CHARACTER_SLIDE_IN_DURATION];
  let appearFromStyle = {};
  switch (commandParameters[Constant.CHARACTER_APPEAR_FROM]) {
    case Constant.CHARACTER_APPEAR_FROM_OFF_LEFT:
      appearFromStyle[
        'animation'
      ] = `charAnimationLeftToRight ${duration}s linear`;
      if (commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true') {
        appearFromStyle[
          'animation'
        ] = `charAnimationLeftToRightWithFlip ${duration}s linear`;
      }

      break;
    case Constant.CHARACTER_APPEAR_FROM_OFF_RIGHT:
      appearFromStyle[
        'animation'
      ] = `charAnimationRightToLeft ${duration}s linear`;
      if (commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true') {
        appearFromStyle[
          'animation'
        ] = `charAnimationRightToLeftWithFlip ${duration}s linear`;
      }
      break;
    case Constant.CHARACTER_APPEAR_FROM_NONE:
      let currentPosition =
        commandParameters[Constant.CHARACTER_POSITION_IN_SCREEN];
      if (lastEnterCharacter[commandParameters['Character']]) {
        let lastPosition =
          lastEnterCharacter[commandParameters['Character']]
            .enterCharacterCommand.parameters[
            Constant.CHARACTER_POSITION_IN_SCREEN
          ];
        if (lastPosition === 'Middle' && currentPosition === 'Left') {
          appearFromStyle[
            'animation'
          ] = `charAnimationMiddleToLeft ${duration}s linear`;

          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationMiddleToLeftWithFlip ${duration}s linear`;
          }
        }
        if (lastPosition === 'Right' && currentPosition === 'Left') {
          appearFromStyle[
            'animation'
          ] = `charAnimationRightToLeft ${duration}s linear`;

          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationRightToLeftWithFlip ${duration}s linear`;
          }
        }
        if (lastPosition === 'Middle' && currentPosition === 'Right') {
          appearFromStyle[
            'animation'
          ] = `charAnimationMiddleToRight ${duration}s linear`;
          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationMiddleToRightWithFlip ${duration}s linear`;
          }
        }
        if (lastPosition === 'Left' && currentPosition === 'Right') {
          appearFromStyle[
            'animation'
          ] = `charAnimationLeftToRight ${duration}s linear`;
          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationLeftToRightWithNoneFlip ${duration}s linear`;
          }
        }
        if (lastPosition === 'Left' && currentPosition === 'Middle') {
          appearFromStyle[
            'animation'
          ] = `charAnimationLeftToMiddle ${duration}s linear`;

          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationLeftToMiddleWithFlip ${duration}s linear`;
          }
        }
        if (lastPosition === 'Right' && currentPosition === 'Middle') {
          appearFromStyle[
            'animation'
          ] = `charAnimationRightToMiddle ${duration}s linear`;

          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationRightToMiddleWithFlip ${duration}s linear`;
          }
        }
      } else {
        if (currentPosition === 'Right') {
          appearFromStyle[
            'animation'
          ] = `charAnimationMiddleToRight ${duration}s linear`;
          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationMiddleToRightWithFlip ${duration}s linear`;
          }
        } else if (currentPosition === 'Left') {
          appearFromStyle[
            'animation'
          ] = `charAnimationMiddleToLeft ${duration}s linear`;

          if (
            commandParameters[Constant.CHARACTER_FLIP_HORIZONTALLY] === 'true'
          ) {
            appearFromStyle[
              'animation'
            ] = `charAnimationMiddleToLeftWithFlip ${duration}s linear`;
          }
        }
      }
      break;
  }

  if (styles.hasOwnProperty('animation')) {
    styles['animation'] = `${styles['animation']}, ${
      appearFromStyle['animation']
    }`;
  } else {
    styles['animation'] = appearFromStyle['animation'];
  }

  return styles;
};

const delayToAppear = (styles, delay) => {
  styles['animationDelay'] = `${delay}s`;
  styles['WebkitAnimationDelay'] = `${delay}s`;
  return styles;
};

const flipHorizontally = (styles, flip) => {
  if (flip === 'true') {
    styles['transform'] = 'scaleX(-1)';
  }

  return styles;
};

const imagePosition = (styles, position, aspectRatio) => {
  switch (position) {
    case 'Left':
      styles[
        'left'
      ] = `${AspectRatioConfig.characterPosition[aspectRatio].left}%`;
      styles['bottom'] = '0px';
      break;
    case 'Right':
      styles[
        'right'
      ] = `${AspectRatioConfig.characterPosition[aspectRatio].right}px`;
      styles['bottom'] = '0px';
      break;
    case 'Middle':
      styles[
        'left'
      ] = `${AspectRatioConfig.characterPosition[aspectRatio].middle}px`;
      styles['bottom'] = '0px';
      break;
    case 'Off-screen Left':
      styles['left'] = '-106%';
      styles['bottom'] = '0px';
      break;
    case 'Off-screen Right':
      styles['right'] = '-106%';
      styles['bottom'] = '0px';
      break;
  }

  return styles;
};

const fadeIntoScreen = (styles, fadeIn) => {
  styles['WebkitAnimation'] = `fadeIn ${fadeIn * 4}s`;
  styles['animation'] = `fadeIn ${fadeIn * 4}s`;

  return styles;
};

const transitionType = (transitionType, displayName, duration) => {
  let character = document.getElementById('char-' + displayName);
  if (character == undefined) {
    return;
  }
  switch (transitionType) {
    case Constant.TRANSITION_TYPE_FADE_OUT:
      character.style.opacity = 0;
      character.style.transition = `opacity ${duration}s`;
      break;
    case Constant.TRANSITION_TYPE_SLIDING_OUT_RIGHT:
      character.style.transitionDuration = `${duration}s`;
      character.style.transform = 'translateX(1000%)';
      break;
    case Constant.TRANSITION_TYPE_SLIDING_OUT_LEFT:
      character.style.transitionDuration = `${duration}s`;
      character.style.transform = 'translateX(-1000%)';
      break;
    case Constant.TRANSITION_TYPE_NO_TRANSITION:
      character.style.display = 'none';
      break;
    case Constant.TRANSITION_TYPE_SLIDING_IN_LEFT:
      character.style.transitionDuration = `${duration}s`;
      character.style.transform = 'translateX(100%)';
      break;
    case Constant.TRANSITION_TYPE_SLIDING_IN_RIGHT:
      character.style.transitionDuration = `${duration}s`;
      character.style.transform = 'translateX(-500%)';
      break;
  }
};

const leaveTheScene = command => {
  let commandParameters = command.parameters;

  // Log error if character not found
  let character = document.getElementById(
    'char-' + command.parameters.characterDisplayName
  );
  if (character == undefined) {
    return false;
  }

  if (Object.keys(commandParameters).length !== 0) {
    if (commandParameters.hasOwnProperty(Constant.CHARACTER_TRANSITION_TYPE)) {
      transitionType(
        commandParameters[Constant.CHARACTER_TRANSITION_TYPE],
        command.parameters.characterDisplayName,
        commandParameters[Constant.DURATION]
      );
    } else {
      let character = document.getElementById(
        'char-' + command.parameters.characterDisplayName
      );
      character.style.display = 'none';
    }
  }

  // Remove character after effect end
  if (commandParameters[Constant.DURATION] > 0) {
    setTimeout(() => {
      if (character) {
        character.remove();
      }
    }, commandParameters[Constant.DURATION] * 1000);
  }

  return true;
};

const playEnterTheScene = (
  command,
  blobImages,
  skipTimeout = false,
  aspectRatio,
  lastEnterCharacter,
  variableDto
) => {
  // Character creation and set all animation and styling effect to them
  let previewContent = document.getElementById('preview-content');
  let actions = parseEnterTheScene(
    command,
    blobImages,
    skipTimeout,
    aspectRatio,
    lastEnterCharacter,
    variableDto
  );
  let character = document.createElement('div');

  // Character actions
  setBackground(aspectRatio);
  if (actions === undefined) {
    return;
  }
  actions.map(action => {
    character.appendChild(action);
  });

  // Remove the character from dom if already exists
  let removeCharacterId = command.parameters.characterDisplayName;
  if (
    command.commandId === Constant.COMMAND_REPLACE_CHARACTER ||
    command.commandId === Constant.COMMAND_REPLACE_OUTFIT
  ) {
    removeCharacterId = command.parameters.originalCharacterDropdown;
  }

  let characterName = `char-${removeCharacterId}`;

  let characterElement = document.getElementById(characterName);
  let childIndex = getChildIndex(characterElement);

  childIndex = childIndex === undefined ? 0 : childIndex;
  if (characterElement !== null) {
    characterElement.remove();
  }

  // Remove character from dom for change expression of character
  if (
    command.commandId === Constant.COMMAND_REPLACE_CHARACTER ||
    command.commandId === Constant.COMMAND_REPLACE_OUTFIT
  ) {
    characterName = `char-${command.parameters.characterDisplayName}`;
    characterElement = document.getElementById(characterName);
    if (characterElement !== null) {
      characterElement.remove();
    }
  }
  character.setAttribute('id', characterName);
  if (
    command.commandId !== Constant.COMMAND_REPLACE_CHARACTER &&
    command.commandId !== Constant.COMMAND_CHANGE_EXPRESSION
  ) {
    previewContent.insertBefore(character, null);
  } else {
    previewContent.insertBefore(character, previewContent.children[childIndex]);
  }
};

const getChildIndex = child => {
  if (child === null) {
    return;
  }
  var parent = child.parentNode;
  var children = parent.children;
  var i = children.length - 1;
  for (; i >= 0; i--) {
    if (child == children[i]) {
      break;
    }
  }
  return i;
};

const showHiddenCharacter = (command, skipTimeout) => {
  let commandParameters = command.parameters;

  if (Object.keys(commandParameters).length !== 0) {
    if (
      commandParameters.hasOwnProperty(Constant.CHARACTER_POSITION_IN_SCREEN)
    ) {
      return imagePositionHiddenCharacter(
        commandParameters[Constant.CHARACTER_POSITION_IN_SCREEN],
        command.parameters.characterDisplayName,
        commandParameters[Constant.DURATION],
        commandParameters[Constant.CHARACTER_TRANSITION_TYPE],
        skipTimeout
      );
    }
  }
};

const imagePositionHiddenCharacter = (
  position,
  displayName,
  duration,
  transitionType,
  skipTimeout
) => {
  let character = document.getElementById('char-' + displayName);
  if (character == undefined) {
    return false;
  }
  switch (position + transitionType) {
    case 'Right' + Constant.TRANSITION_TYPE_SLIDING_IN_RIGHT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.webkitTransform = 'translateX(155%)';
      break;
    case 'Left' + Constant.TRANSITION_TYPE_SLIDING_IN_RIGHT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.webkitTransform = 'translateX(60%)';
      break;
    case 'Right' + Constant.TRANSITION_TYPE_SLIDING_IN_LEFT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.transform = 'translateX(-60%)';
      break;
    case 'Left' + Constant.TRANSITION_TYPE_SLIDING_IN_LEFT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.transform = 'translateX(-150%)';
      break;
    case 'Middle' + Constant.TRANSITION_TYPE_SLIDING_IN_RIGHT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.transform = 'translateX(106%)';
      break;
    case 'Middle' + Constant.TRANSITION_TYPE_SLIDING_IN_LEFT:
      if (skipTimeout === false) {
        character.style.transitionDuration = `${duration}s`;
      }
      character.style.transform = 'translateX(-106%)';
      break;
    case 'Left' + Constant.TRANSITION_TYPE_FADE_IN:
      fadeInHiddenCharacter(character, duration, position, skipTimeout);
      break;
    case 'Right' + Constant.TRANSITION_TYPE_FADE_IN:
      fadeInHiddenCharacter(character, duration, position, skipTimeout);
      break;
    case 'Middle' + Constant.TRANSITION_TYPE_NO_TRANSITION:
      character.style.left = '0%';
      noTransationHiddenCharacter(character, position, skipTimeout);
      break;
    case 'Left' + Constant.TRANSITION_TYPE_NO_TRANSITION:
      character.style.left = '-45%';
      noTransationHiddenCharacter(character, position, skipTimeout);
      break;
    case 'Right' + Constant.TRANSITION_TYPE_NO_TRANSITION:
      character.style.right = '-45%';
      noTransationHiddenCharacter(character, position);
      break;
  }

  return true;
};

const fadeInHiddenCharacter = (character, duration, position, skipTimeout) => {
  let childImages = character.getElementsByClassName('characterImage');
  if (childImages.length > 0) {
    for (let i = 0; i < childImages.length; i++) {
      if (position === 'Left') {
        childImages[i].style.right = 'unset';
        childImages[i].style.left = '-45%';
      } else {
        childImages[i].style.left = 'unset';
        childImages[i].style.right = '-45%';
      }

      if (skipTimeout === false) {
        childImages[i].style.WebkitAnimation = `fadeIn ${duration}s`;
        childImages[i].style.animation = `fadeIn ${duration}s`;
      }
    }
  }
};

const noTransationHiddenCharacter = (character, position) => {
  let childImages = character.getElementsByClassName('characterImage');
  if (childImages.length > 0) {
    for (let i = 0; i < childImages.length; i++) {
      if (position === 'Middle') {
        childImages[i].style.right = 'unset';
        childImages[i].style.left = 'unset';
      }
      if (position === 'Left') {
        childImages[i].style.right = '45%';
        childImages[i].style.left = 'unset';
      }
      if (position === 'Right') {
        childImages[i].style.right = '-45%';
        childImages[i].style.left = 'unset';
      }
    }
  }
};

const setBackground = aspectRatio => {
  let previewContentOriginal = document.getElementById('preview-content');
  let previewContent = document.getElementsByClassName(
    'preview-content-background'
  );

  if (previewContent.length > 0) {
    return false;
  }

  let width = AspectRatioConfig.aspectRatioParameters[aspectRatio].previewWidth;
  let height =
    AspectRatioConfig.aspectRatioParameters[aspectRatio].previewHeight;
  previewContent = document.createElement('div');
  previewContent.className = 'preview-content-background';
  previewContent.id = 'preview-content-background';
  previewContent.style = `background-image: url(""); width: ${width}px; height: ${height}px; background-repeat: no-repeat`;
  previewContentOriginal.insertBefore(
    previewContent,
    previewContentOriginal.childNodes[0]
  );
};

export const CharacterCommands = {
  parseEnterTheScene,
  leaveTheScene,
  playEnterTheScene,
  showHiddenCharacter
};

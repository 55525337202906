import React, { useState, useEffect } from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import * as Constant from './../../../common/constants';
import * as Pattern from './../../../common/helper/pattern';

function NumberTextBox({
  component,
  commandId,
  currentValueForShakeCamera,
  disabledNumber,
  defaultValueNumber,
  setNumberBoxDefaultValue,
  selectedChar,
  isCommandEdit
}) {
  let required = component.required ? `required` : '';

  const [valueNumber, setValueNumber] = useState(
    component.properties.defaultValue
  );

  const [disabled, setDisabled] = useState(component.show);
  useEffect(() => {
    if (commandId === Constant.COMMAND_SHAKE_CAMERA) {
      const currentValueForDropDown = currentValueForShakeCamera
        ? currentValueForShakeCamera
        : selectedChar;
      switch (currentValueForDropDown) {
        case 'Low':
          setValueNumber(0.5);
          setNumberBoxDefaultValue(0.5);
          if (component.widgetName === 'durationTextbox') {
            setDisabled('');
            setValueNumber(component.properties.defaultValue);
          } else {
            setDisabled('disabled');
          }
          break;

        case 'Medium':
          setValueNumber(1);
          setNumberBoxDefaultValue(1);
          if (component.widgetName === 'durationTextbox') {
            setDisabled('');
            setValueNumber(component.properties.defaultValue);
          } else {
            setDisabled('disabled');
          }
          break;

        case 'High':
          setValueNumber(1.75);
          setNumberBoxDefaultValue(1.75);
          if (component.widgetName === 'durationTextbox') {
            setDisabled('');
            setValueNumber(component.properties.defaultValue);
          } else {
            setDisabled('disabled');
          }

          break;

        case 'Custom':
          setNumberBoxDefaultValue('');
          if (!isCommandEdit) {
            setValueNumber('');
          }
          setDisabled('');
          break;

        default:
          setDisabled(component.show === 'enabled' ? '' : component.show);
          break;
      }
    }
  }, [defaultValueNumber, currentValueForShakeCamera]);

  const handleOnchangeNumberBox = (event, commandId) => {
    if (component.widgetName === 'durationTextbox') {
      setValueNumber(event.target.value);
    }
    if (new RegExp(Pattern.NUMBER_PATTERN).test(event.target.value) === true) {
      if (component.widgetName === 'fadeTextbox') {
        const value = event.target.value === '' ? 0 : event.target.value;
        setValueNumber(value);
      } else {
        setValueNumber(event.target.value);
      }
    }
  };

  const handleKeyPress = e => {
    e = e || window.event;
    var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (e.target.id === 'optCurrencyDropdown') {
      if (charStr === '.') {
        e.preventDefault();
        return false;
      }
    }

    if (charStr === 'e') {
      e.preventDefault();
      return false;
    }
  };

  if (component.suffix !== '') {
    return (
      <Form.Row>
        <Form.Group controlId={component.widgetName}>
          {commandId === Constant.COMMAND_STOP_MUSIC && (
            <span className="d-flex mb-3 text-secondary">
              Current background music will stop playing
            </span>
          )}
          <Form.Label className="commandLabel">{component.label}</Form.Label>
          <Form.Row>
            <Col>
              <InputGroup>
                <Form.Control
                  type="number"
                  className="textlength"
                  label={component.label}
                  maxLength={component.properties.maxLength}
                  placeholder={component.placeHolder}
                  step="0.1"
                  required={required}
                  pattern={Pattern.NUMBER_PATTERN}
                  autoComplete="off"
                  index={component.index}
                  min={component.properties.min ? component.properties.min : 0}
                  max={
                    component.properties.max ? component.properties.max : 999
                  }
                  disabled={disabled}
                  // defaultValue={valueNumber}
                  value={valueNumber}
                  onChange={event => handleOnchangeNumberBox(event, commandId)}
                  onKeyPress={event => handleKeyPress(event)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>{component.suffix}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                  Please add valid number to field (limit:
                  {`${component.properties.min}-${component.properties.max}`})
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Row>
        </Form.Group>
      </Form.Row>
    );
  }
  return (
    <Form.Group controlId={component.widgetName}>
      {commandId === Constant.COMMAND_STOP_MUSIC && (
        <span className="commandLabelSuffix">
          Current background music will stop playing
        </span>
      )}
      <Form.Label className="commandLabel">{component.label}</Form.Label>
      {component.note !== '' && (
        <label className="commandNote">{component.note}</label>
      )}

      <Form.Control
        type="number"
        className="textlength"
        label={component.label}
        min={component.properties.min ? component.properties.min : 0}
        max={component.properties.max ? component.properties.max : 999}
        step={component.widgetName === 'optCurrencyDropdown' ? '1' : '0.1'}
        defaultValue={
          component.widgetName === 'optCurrencyDropdown'
            ? valueNumber != 0
              ? valueNumber
              : ''
            : disabledNumber
        }
        placeholder={component.placeHolder}
        required={required}
        autoComplete="off"
        index={component.index}
        onKeyPress={event => handleKeyPress(event)}
      />
      <Form.Control.Feedback type="invalid">
        {component.widgetName === 'optCurrencyDropdown'
          ? `Enter a value from 5 to 300, or leave blank for 0 cost.`
          : `Please enter values between min:${component.properties.min} to max: ${component.properties.max}`}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default NumberTextBox;

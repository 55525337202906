import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from '../actions/story-action-types';
import * as StoryActions from './../actions/story-action';
import { postStoryProgressData } from './api/story-api';
import {GET_SUCCESS_BLOCK_FLOWCHART_DETAILS, GET_SUCCESS_FLOWCHART_DETAILS, GET_RESET_FLOWCHART_DETAILS} from '../actions/action-types';

export function* postStoryProgressDataWorker(payloads) {
  try {
    const postData = yield call(postStoryProgressData, payloads);
    
    if (postData.result !== 'Success') {
      yield put(StoryActions.setStoryProgressFail(postData));
    } else {
      
      
      if(payloads.sceneId !== null && payloads.sceneId !== undefined) {
        yield put({
          type: GET_RESET_FLOWCHART_DETAILS,
        });
        yield put({
          type: GET_SUCCESS_BLOCK_FLOWCHART_DETAILS,
          payload: postData,
          sceneId:payloads.sceneId,
          sceneFlag : false
        });
      yield put(StoryActions.setStoryProgressSuccess(postData));
      } else {
        yield put({
          type: GET_SUCCESS_FLOWCHART_DETAILS,
          payload: postData,
          sceneFlag:true
        });
        yield put(StoryActions.setStoryProgressSuccess(postData));
        yield put(StoryActions.resetChapterStoryError(payloads))
      }
    }
  } catch (e) {
    yield put({ type: ActionTypes.SET_STORY_SAVE_PROGRESS_FAIL });
  }
}

export function* postStoryProgressDataWatcher() {
  yield takeLatest(
    ActionTypes.SET_STORY_SAVE_PROGRESS,
    postStoryProgressDataWorker
  );
}

export default {
  postStoryProgressDataWatcher
};

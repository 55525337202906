import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { PlaceBgStyle } from './style';
import { PlaceBackgroundService } from './../place-background-service';
import CreateViewTab from './CreateViewTab';
import CreateViewLibraryTab from './CreateViewLibraryTab';
import BrowseViewTab from './BrowseViewTab';
import BrowseViewLibraryTab from './BrowseViewLibraryTab';

function PlaceBackgroundTabs(props) {
  let selectedBackground = PlaceBackgroundService.getBackgroundImage(
    props.backgroundImages,
    props.background
  );
  let backgroundImage =
    selectedBackground.length > 0 ? selectedBackground[0].awsPath : null;
  return (
    <PlaceBgStyle.Tabdiv libraryChange={props.libraryChange}>
      <Tabs
        defaultActiveKey={props.activeKey}
        className="tab-panel step-tab-view "
        onSelect={k => props.setActiveKey(k)}
        activeKey={props.activeKey}
      >
        <Tab
          eventKey="view"
          title={props.libraryChange ? 'Create' : 'Create view'}
        >
          {props.libraryChange === false && (
            <CreateViewTab
              handleChange={props.handleChange}
              backgroundImages={props.backgroundImages}
              backgroundImage={backgroundImage}
              setCrop={props.setCrop}
              onHideModal={props.onHideModal}
              saveForLater={props.saveForLater}
              background={props.background}
              xcoordinate={props.xcoordinate}
              ycoordinate={props.ycoordinate}
              height={props.height}
              width={props.width}
              aspect={props.aspect}
              name={props.name}
              zoom={props.zoom}
              fadeIn={props.fadeIn}
              clickedEventCheck={props.clickedEventCheck}
              handleClickEvent={props.handleClickEvent}
              handleSaveForLaterClickEvent={props.handleSaveForLaterClickEvent}
              validated={props.validated}
              duplicateName={props.duplicateName}
              setShowAddObjectForm={props.setShowAddObjectForm}
              viewObjects={props.viewObjects}
              handleObjectDeleteClick={props.handleObjectDeleteClick}
              handleEditObjectClick={props.handleEditObjectClick}
              handleAddObjectClick={props.handleAddObjectClick}
              handleReorderList={props.handleReorderList}
              assetList={props.assetList}
              setObject={props.setObject}
              characterRoutesList={props.characterRoutesList}
              backgroundCopy={props.backgroundCopy}
              views={props.views}
              selectedBackground={selectedBackground[0]}
              selectBackground={props.selectBackground}
              setSelectBackground={props.setSelectBackground}
              addObject={props.addObject}
              setAddObject={props.setAddObject}
              setEditView={props.setEditView}
              editView={props.editView}
              libraryChange={props.libraryChange}
              otherView={props.otherView}
            />
          )}
          {props.libraryChange === true && (
            <CreateViewLibraryTab
              handleChange={props.handleChange}
              backgroundImages={props.backgroundImages}
              backgroundImage={backgroundImage}
              setCrop={props.setCrop}
              onHideModal={props.onHideModal}
              saveForLater={props.saveForLater}
              background={props.background}
              xcoordinate={props.xcoordinate}
              ycoordinate={props.ycoordinate}
              height={props.height}
              width={props.width}
              aspect={props.aspect}
              name={props.name}
              zoom={props.zoom}
              fadeIn={props.fadeIn}
              clickedEventCheck={props.clickedEventCheck}
              handleClickEvent={props.handleClickEvent}
              handleSaveForLaterClickEvent={props.handleSaveForLaterClickEvent}
              validated={props.validated}
              duplicateName={props.duplicateName}
              setShowAddObjectForm={props.setShowAddObjectForm}
              viewObjects={props.viewObjects}
              handleObjectDeleteClick={props.handleObjectDeleteClick}
              handleEditObjectClick={props.handleEditObjectClick}
              handleAddObjectClick={props.handleAddObjectClick}
              handleReorderList={props.handleReorderList}
              assetList={props.assetList}
              setObject={props.setObject}
              backgroundCopy={props.backgroundCopy}
              views={props.views}
              selectedBackground={selectedBackground[0]}
              selectBackground={props.selectBackground}
              setSelectBackground={props.setSelectBackground}
              addObject={props.addObject}
              setAddObject={props.setAddObject}
              setEditView={props.setEditView}
              editView={props.editView}
              libraryChange={props.libraryChange}
              editViewFinal={props.editViewFinal}
              setAutoSaveFlag={props.setAutoSaveFlag}
              characterRoutesList={props.characterRoutesList}
              exportToGallary={props.exportToGallary}
              characterRoute={props.characterRoute}
              captionText={props.captionText}
              foundInJuicy={props.foundInJuicy}
              otherView={props.otherView}
            />
          )}
        </Tab>
        <Tab eventKey="browse" title="Browse">
          {props.libraryChange === false && (
            <BrowseViewTab
              views={props.views}
              handleBrowseSelection={props.handleBrowseSelection}
              handleDuplicateClick={props.handleDuplicateClick}
              handleViewSearch={props.handleViewSearch}
              searchViews={props.searchViews}
              onHideModal={props.onHideModal}
              backgroundCopy={props.backgroundCopy}
              handleClickEvent={props.handleClickEvent}
              searchText={props.searchText}
              handlePlaceViewScript={props.handlePlaceViewScript}
              handleViewEditClick={props.handleViewEditClick}
              libraryChange={props.libraryChange}
            />
          )}
          {props.libraryChange === true && (
            <BrowseViewLibraryTab
              views={props.views}
              handleBrowseSelection={props.handleBrowseSelection}
              handleDuplicateClick={props.handleDuplicateClick}
              handleViewSearch={props.handleViewSearch}
              searchViews={props.searchViews}
              onHideModal={props.onHideModal}
              backgroundCopy={props.backgroundCopy}
              handleClickEvent={props.handleClickEvent}
              searchText={props.searchText}
              handlePlaceViewScript={props.handlePlaceViewScript}
              handleViewEditClick={props.handleViewEditClick}
              libraryChange={props.libraryChange}
            />
          )}
        </Tab>
      </Tabs>
    </PlaceBgStyle.Tabdiv>
  );
}

export default PlaceBackgroundTabs;

import * as ActionTypes from '../actions/action-types';
import {FONT_CHANGE} from '../actions/story-action-types'
import jwt from 'jsonwebtoken';
import * as Constant from './../common/constants';

export const initialState = {
  authToken: '',
  error: [],
  showError: false,
  roles: [],
  isLoggedIn: false,
  showPanels: true,
  isOwnerOrCollab: false,
  tokenDataUser: null,
  isAcceptInvite: false,
  iniviteStoryId: null,

  invitationExpired: false,
  invitationCancel: false,
  inviteAccept: '',
  loadingRefreshToken: false,
  showLoginTutorial: false,
  tutorials: [],
  invalidLoginAttempts: 0,
  setSuccess: false,
  fontFamily: undefined,
  fontSize: '14px'
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case ActionTypes.SET_LOGIN_DETAILS:
      if (action.isLogOut && action.isLogOut === true) {
        localStorage.removeItem(Constant.SNACK_USER_TOKEN);
        localStorage.removeItem(Constant.SNACK_USER_ROLE);
        localStorage.removeItem(Constant.SNACK_USER_EXPIRES);
      }
      return {
        ...state,
        user: action.payload,
        isLoggedIn: false,
        error: [],
        showError: false,
        invitationExpired: false,
        invitationCancel: false,
        inviteAccept: '',
        authToken: '',
        isLogOut: action.isLogOut,
        full_name_toggle: false,
        fontFamily: undefined,
        fontSize: '14px'
      };

    case ActionTypes.SET_SUCCESS_LOGIN_DETAILS:
      let tokenDataUser = jwt.decode(
        action.payload.result.body ? action.payload.result.body.accessToken : []
      );

      localStorage.setItem(
        Constant.SNACK_USER_TOKEN,
        action.payload.result.body.accessToken
      );
      localStorage.setItem(Constant.SNACK_USER_ROLE, tokenDataUser.roles);
      localStorage.setItem(Constant.SNACK_USER_EXPIRES, tokenDataUser.exp);

      return {
        ...state,
        authToken: action.payload.result.body
          ? action.payload.result.body.accessToken
          : null,
        error:
          action.payload.result.result === 'Failure'
            ? action.payload.result.message
            : [],
        showError: action.payload.result.result === 'Failure' ? true : false,
        isLoggedIn: action.payload.result.result === 'Success' ? true : false,
        tokenData: jwt.decode(
          action.payload.result.body
            ? action.payload.result.body.accessToken
            : []
        ),
        rolse: tokenDataUser.roles,
        showPanels:
          tokenDataUser.roles === Constant.SNACK_USER_WRITER ? true : false,
        tokenDataUser: tokenDataUser,
        full_name_toggle: action.payload.result.body.settings.FULL_NAME_TOGGLE,
        fontFamily: action.payload.result.body.settings.FONT_FAMILY,
        fontSize: action.payload.result.body.settings.FONT_SIZE
      };

    case ActionTypes.SET_FAIL_LOGIN_DETAILS:
      return {
        ...state,
        isLoggedIn: false,
        error:
          action.payload && action.payload.result.result === 'Failure'
            ? action.payload.result.message
            : [],
        showError: true,
        invalidLoginAttempts:
          action.payload && action.payload.result.result === 'Failure'
            ? action.payload.result.invalidLoginAttempts
            : 0
      };

    case ActionTypes.SET_PANEL_PERMISSION_TRUE:
      return {
        ...state,
        showPanels: true,
        isOwnerOrCollab: true
      };

    case ActionTypes.SET_PANEL_PERMISSION_FALSE:
      return {
        ...state,
        showPanels: false,
        isOwnerOrCollab: false
      };

    case ActionTypes.SET_TUTORIAL_DATA:
      return {
        ...state,
        data: action.payload,
        authToken: action.authToken,
        flag: action.flag
      };

    case ActionTypes.SET_TUTORIAL_DATA_SUCCESS:
      return {
        ...state,
        showLoginTutorial: false
      };

    case ActionTypes.SET_TUTORIAL_DATA_FAIL:
      return {
        ...state,
        showLoginTutorial: false
      };

    case ActionTypes.GET_TUTORIAL_DATA:
      return {
        ...state,

        payload: action.payloads
      };

    case ActionTypes.GET_TUTORIAL_DATA_SUCCESS:
      return {
        ...state,
        showLoginTutorial:
          action.result.body.firstLogin === false ? true : false,
        tutorials: action.result.body
      };

    case ActionTypes.GET_TUTORIAL_DATA_FAIL:
      return {
        ...state,
        showLoginTutorial: false,
        tutorials: []
      };

    case ActionTypes.SET_TOKEN_REFRESH:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: false,
        error: [],
        invitationExpired: false,
        invitationCancel: false,
        inviteAccept: '',
        authToken: '',
        isLogOut: true,
        loadingRefreshToken: true,
        full_name_toggle: false
      };

    case ActionTypes.SET_TOKEN_REFRESH_SUCCESS:
      let tokenRefreshDataUser = jwt.decode(
        action.payload.result.body ? action.payload.result.body.accessToken : []
      );
      localStorage.setItem(
        Constant.SNACK_USER_TOKEN,
        action.payload.result.body.accessToken
      );
      localStorage.setItem(
        Constant.SNACK_USER_ROLE,
        tokenRefreshDataUser.roles
      );
      localStorage.setItem(
        Constant.SNACK_USER_EXPIRES,
        tokenRefreshDataUser.exp
      );

      return {
        ...state,
        authToken: action.payload.result.body
          ? action.payload.result.body.accessToken
          : null,
        error:
          action.payload.result.result === 'Failure'
            ? action.payload.result.message
            : [],
        isLoggedIn: action.payload.result.result === 'Success' ? true : false,
        tokenData: jwt.decode(
          action.payload.result.body
            ? action.payload.result.body.accessToken
            : []
        ),
        rolse: tokenRefreshDataUser.roles,
        showPanels:
          tokenRefreshDataUser.roles === Constant.SNACK_USER_WRITER
            ? true
            : false,
        tokenDataUser: tokenRefreshDataUser,
        isLogOut: false,
        loadingRefreshToken: false,
        full_name_toggle: action.payload.result.body.settings.FULL_NAME_TOGGLE,
        fontFamily: action.payload.result.body.settings.FONT_FAMILY,
        fontSize: action.payload.result.body.settings.FONT_SIZE
      };

    case ActionTypes.SET_TOKEN_REFRESH_FAIL:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: false,
        error: [],
        invitationExpired: false,
        invitationCancel: false,
        inviteAccept: '',
        authToken: '',
        loadingRefreshToken: false,
        full_name_toggle: false
      };

    case ActionTypes.SET_INVITE_ACCEPT:
      return {
        ...state,
        storyId: action.payload,
        isAcceptInvite: false
      };

    case ActionTypes.SET_LOCAL_STORAGE_LOGIN_DETAILS:
      let tokenstorageDataUser = jwt.decode(
        action.payload ? action.payload : []
      );

      return {
        ...state,
        user: null,
        authToken: action.payload ? action.payload : null,
        isLoggedIn: true,
        tokenData: jwt.decode(action.payload ? action.payload : []),
        rolse: tokenstorageDataUser.roles,
        showPanels:
          tokenstorageDataUser.roles === Constant.SNACK_USER_WRITER
            ? true
            : false,
        tokenDataUser: tokenstorageDataUser
      };

    //

    case ActionTypes.SET_SUCCESS_INVITE_ACCEPT:
      return {
        ...state,

        isAcceptInvite: true,
        iniviteStoryId: action.payload,
        inviteAccept: action.acceptMsg
      };

    case ActionTypes.SET_FAIL_INVITE_ACCEPT:
      return {
        ...state,
        isAcceptInvite: false,
        iniviteStoryId: null,
        invitationExpired: action.expired,
        invitationCancel: action.canceled
      };

    case ActionTypes.RESET_INVALID_LOGIN_ATTEMPTS:
      return {
        ...state,
        invalidLoginAttempts: 0,
        showError: false,
        error: []
      };

    case ActionTypes.RESET_ERROR:
      return {
        ...state,
        showError: false,
        error: []
      };

    case ActionTypes.SET_TOGGLE_CHARACTER:
      return {
        ...state,
        full_name_toggle: action.toggleCharacter,
        setSuccess: false
      };

    case ActionTypes.SET_TOGGLE_CHARACTER_SUCCESS:
      return {
        ...state,
        setSuccess: true
      };

    case ActionTypes.SET_TOGGLE_CHARACTER_FAILURE:
      return {
        ...state,
        setSuccess: false
      };

    case ActionTypes.SET_SUCCESS_FALSE:
      return {
        ...state,
        setSuccess: false
      };

    case FONT_CHANGE:
      return{
        ...state,
        fontSize:action.fontSize,
        fontFamily:action.font
      }

    default:
      return state;
  }
};

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import './../style/style.css';
import { Row, Col, Container } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Card } from 'react-bootstrap';
import ListTable from './ListTable';

import * as Constant from './../../../common/constants';
import { useDropzone } from 'react-dropzone';

function UploadFile(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      props.setMaxFileError(false);
      if (acceptedFiles.length > 0) {
        if (acceptedFiles.length < 16) {
          props.setShowUploadPopupEnableUpload(true);
          props.setFileNames(acceptedFiles);
        } else {
          props.setMaxFileError(true);
        }
      }
    },
    [props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    noDrag: true
  });

  return (
    <div>
      <div
        className="btn-snacktool-brown btn-snacktool browse-btn"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <img src="/images/icons/ic_file_upload.svg" className="ml-1" /> Browse
        files
      </div>
    </div>
  );
}

export default UploadFile;

import * as ActionTypes from '../actions/action-types';

export const initialState = {
  loading: false,
  errordetail: null,
  success: null,
  selectedMenu: '',
  usernameError: null,
  emailError: null,
  successUpdated: false,
  errorPassword: null,
  errorDelete: null
};

export const MyAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_ACCOUNT_MENU:
      return {
        ...state,
        selectedMenu: action.menuName,
        success: null,
        usernameError: null,
        emailError: null,
        errorPassword: null,
        errorDelete: null
      };

    case ActionTypes.MY_ACCOUNT_UPDATE_DETAILS:
      return {
        ...state,
        loading: true,
        usernameError: null,
        emailError: null,
        errorPassword: null,
        errorDelete: null
      };

    case ActionTypes.MY_ACCOUNT_UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        usernameError: null,
        emailError: null,
        successUpdated: true
      };

    case ActionTypes.MY_ACCOUNT_UPDATE_DETAILS_FAIL:
      let userNameError = '';
      let emailError = '';
      if (action.errorFail.length > 0) {
        action.errorFail.map(errorcheck => {
          if (errorcheck.includes('username:') === true) {
            userNameError = errorcheck.replace('username:', '');
          } else if (errorcheck.includes('email:') === true) {
            emailError = errorcheck.replace('email:', '');
          }
        });
      }
      return {
        ...state,
        success: false,
        loading: false,
        emailError: emailError,
        usernameError: userNameError,
        successUpdated: false
      };

    case ActionTypes.SET_MY_CHANGE_PASSWORD:
      return {
        ...state,
        successUpdated: false,
        userNameError: null,
        emailError: null,
        loading: true,
        errorPassword: null,
        errorDelete: null
      };

    case ActionTypes.SET_MY_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        successUpdated: true,
        userNameError: null,
        emailError: null,
        loading: false
      };

    case ActionTypes.SET_MY_CHANGE_PASSWORD_FAIL:
      let oldPasswordError = '';
      if (action.errorFail.length > 0) {
        action.errorFail.map(errorcheck => {
          if (errorcheck.includes('oldPassword:') === true) {
            oldPasswordError = errorcheck.replace('oldPassword:', '');
          }
        });
      }
      return {
        ...state,
        successUpdated: false,
        errorPassword: oldPasswordError
      };

    case ActionTypes.MY_ACCOUNT_DELETE:
      return {
        ...state,
        loading: true,
        usernameError: null,
        emailError: null,
        errorPassword: null,
        errorDelete: null
      };

    case ActionTypes.MY_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        usernameError: null,
        emailError: null,
        errorPassword: null,
        errorDelete: true
      };

    default:
      return {
        ...state
      };
  }
};

import React from 'react';
import * as translations from '../create-story/translations';
import { Form } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import './../style/style.css';

function AddInfo(props) {
    return (
        <LibraryStyle.addInfoContainer>
            <Form id="add-info-form" 
                noValidate>
                <div className="mt-3">
                <Form.Group>
                    <Form.Label>{translations.STORY_TITLE}</Form.Label>
                    <Form.Control 
                        type="text"
                        name="title"
                        value = {props.storyDetails.title} 
                        disabled
                    />  
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        {translations.DESCRIPTION}
                        <Form.Text className="text-muted">
                            {translations.MAXIMUM}
                        </Form.Text>
                    </Form.Label>
                    <Form.Control as="textarea" 
                        type='textarea' 
                        className='textAreaResize' 
                        rows="5"
                        value = {props.storyDetails.description}
                        disabled/>
                </Form.Group>
                </div>
            </Form>
        </LibraryStyle.addInfoContainer>
    )
}

export default AddInfo
import React from 'react';
import { CommentStyle } from './styles';
import { Row, Col } from 'react-bootstrap';

function CommentFooter(props) {
  return (
    <CommentStyle.listHeading className="modal-footer pt-4 pb-4">
      <Col className="d-flex justify-content-center align-items-center justify-content-between">
        <button
          onClick={
            props.commentNumber === 0 ? null : () => props.handlePrevClick()
          }
          disabled={props.commentNumber === 0 ? true : false}
          className="prev-btn"
        >
          <i className="fa fa-angle-left"></i> Previous
        </button>

        <button
          onClick={
            props.commentNumber === props.filterList.length - 1
              ? null
              : () => props.handleNextClick()
          }
          disabled={
            props.commentNumber === props.filterList.length - 1 ? true : false
          }
          className="next-btn"
        >
          Next <i className="fa fa-angle-right"></i>
        </button>
      </Col>
    </CommentStyle.listHeading>
  );
}

export default CommentFooter;

import React , { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card } from 'react-bootstrap';
import { RouteServices } from './service';
import * as translations from './translations';
 


function UploadFile(props) {
  const {
    route, 
    setRoute,
    
    setShowFileError,
    showFileError 
     
} = props;

    const maxSize = 5242880;
    const { files } = props;
    let thumbs=[];
    /* State of component */
    const [cardBorder, setCardBorder] = useState('');
    const [showDimention, setShowDimention] = useState(false);
    const [cloudUploadIconColor, setCloudUploadIconColor] = useState('text-secondary');
    const [refreshList, setrefreshList] = useState(false);

    const onDragOver = useCallback(() => {
        setCardBorder('primary');
        setCloudUploadIconColor('text-primary');
    }, []);

    const onDragLeave = useCallback(() => {
        setCardBorder('');
        setCloudUploadIconColor('');
    });

    const onDrop = useCallback(acceptedFiles => {
      setShowDimention(false);
        if (acceptedFiles.length > 0) {
            props.setFiles(RouteServices.createPreviewUrl(acceptedFiles));
           
         addSpec(RouteServices.createPreviewUrl(acceptedFiles));
        
            if(route.characterId && route.characterId!=='-1' && route.description!=='' && acceptedFiles.length > 0 && props.showRouteError===false ){
              props.setAddInfoClass(false);
            }else{
              props.setAddInfoClass(true);
            }
            
            setCardBorder('');
            setCloudUploadIconColor('');
            props.setShowClearFile(true);
            setShowFileError(false);
            //props.setContinueToAddInfoClass('button-active');
        }
    }, [props]);

    const addSpec = (files) => {
     
        readImageFile(files[0]);
      
     
  }
 
    function readImageFile(file) {
       
      var reader = new FileReader(); // CREATE AN NEW INSTANCE.
      reader.onload = function (e) {
       
          var img = new Image();      
          img.src = e.target.result;
          
          img.onload = function () {
             
              var w = this.width;
              var h = this.height;
            
          if(w===1024 && h===1024){
            setShowDimention(false);
            setRoute({
                  ...route,
                  specs: `${w}x${h} px`,
                 // extension: LibraryServices.getFileExtension(file),
              });
            }else{
              setShowDimention(true);
            //  props.setAddInfoClass(true);
              setrefreshList(!refreshList);
              
            }
          }
      };
      return reader.readAsDataURL(file);
  }

    const { 
         
        getRootProps, 
        getInputProps, 
        
        rejectedFiles 
    } = useDropzone({
        onDrop,
        accept: props.isCharacterRoute ? translations.CHARACTER_ROUTE_FILE_ALLOWED_EXTENSIONS.toString() : translations.ASSET_FILE_ALLOWED_EXTENSIONS.toString(),
        minSize: 0,
        maxSize,
        onDragOver,
        onDragLeave
    });

    const isFileTooLarge = RouteServices.checkFileSize(rejectedFiles, maxSize);
    
    if(files && files.length>0 ){
     
     
    if(files && typeof files[0] === 'string'){
      thumbs = RouteServices.createEditPreviewThumb(files);
    }
else{
  if(showDimention===true){
    props.setFiles(null);
    thumbs=[];
  }else{
 
    if(files!==null){
      thumbs = RouteServices.createPreviewThumb(files);
    }
  }
}
    }

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        if(files)
        files.forEach(file => URL.revokeObjectURL(file.preview));
      }, [files]);
 
    return (
        <div className="container text-center lg-12">
            {
                thumbs.length === 0 &&
                <div {...getRootProps()}>
                    <Card   border={showFileError===true?'danger':cardBorder}>
                        <Card.Body className="card-content ">
                            <i className={`fa fa-cloud-upload lg cloud-upload-icon ${cloudUploadIconColor}`}/><br />
                            <Card.Text className="text-secondary-file"></Card.Text>
                            <input {...getInputProps()} id="file"/>
                            <Card.Text className="text-secondary-file card-margin-15">
                               
                                {/* <span className="text-primary light">
                                    <ins>{!isDragActive && translations.BROWSE}</ins>
                                </span> */}
                                {isFileTooLarge && (
                                    <div className="text-danger">
                                        {translations.FILE_IS_TOO_LARGE}
                                    </div>
                                )}
                                {showDimention===true && (
                                    <div className="text-danger">
                                        {translations.FILE_INVALID}
                                    </div>
                                    )}

                                {!isFileTooLarge && rejectedFiles.length > 0 && (
                                    <div className="text-danger mt-2 mb-2">
                                        {RouteServices.showError(rejectedFiles)}
                                    </div>
                                )} 
                                {showDimention===false && showFileError===true  && (
                                  <div className="text-danger">
                                     Please Upload a file.
                                    </div>
                                )}
                            </Card.Text> 
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                thumbs.length > 0 &&
                <Card   border={cardBorder}>
                    <Card.Body className="card-content card-content-custom-asset">
                        <aside className="thumbs-container" style={{width: '84px'}}>
                            {thumbs}
                        </aside>
                </Card.Body>
                {
              props.showClearFile===true && 
              <span className="ml-2 left-button" onClick={props.handleClearFile}>
                            {translations.REMOVE_FILE}
                        </span>
            }
                </Card>
            }
        </div>
    );
}

export default UploadFile
import React from 'react';
import * as commandPanelStyle from '../../command-panel/command-panel-style';
import { Row, Col, Container } from 'react-bootstrap';
 

function ListHead(props) {
   
    return (
      <div>
      <commandPanelStyle.Header>
          <Row>
          <Col md={6}>
          <div className="panel-header">
              <div className="story-heading">
                  <div className='storyName'>
                  {props.storyTitle ? props.storyTitle : ''}
                  </div>
                  <div className='sceneName'>
                  Background, Object and Sound files
                  </div>
              </div>
          </div>
          </Col>
          <Col md={6}>
          <div className="panel-header">
          <Row className='panel-header-right' style={{'width':'100%'}}>
            <Col className='storyName storyName-right storyName-right-margin text-right   ' md={4}>FILTER ASSETS BY:</Col>
            <Col md={8}>
           <Row>


           
           
           {/* <Col md={3} className='text-left'>
           <button className={props.filters.audio===true?'filter-inputTagSelect cursor-pointer inputTagSelect btn btn-outline-primary mr-3':'filter-inputTagSelect btn-filter-disabled mr-3'} onClick={props.handleFilterClick} name='audio'>Audio</button>
           </Col> */}
           <Col md={12}>
           
           <button className={props.filters.audio===true?'filter-inputTagSelect cursor-pointer inputTagSelect btn btn-outline-primary ':'filter-inputTagSelect btn-filter-disabled mr-1'} onClick={props.handleFilterClick} name='audio'>Audio</button>
           <button className={props.filters.image===true?'filter-inputTagSelect cursor-pointer inputTagSelect btn btn-outline-primary ml-2':'filter-inputTagSelect btn-filter-disabled ml-1'}   onClick={props.handleFilterClick} name='image'>Image</button>
              {  props.filters.audio===true &&  <img  src='/images/icons/ic_clear_24px.svg' onClick={props.handleFilterClick}   width='12px'  height='12px' alt='' className=" cursor-pointer" />}
           {props.filters.image===true   &&  <img  src='/images/icons/ic_clear_24px.svg' onClick={props.handleFilterClick}   width='12px'  height='12px' alt='' className="   cursor-pointer" />}  
           </Col>



           </Row>
            
            </Col>
          </Row>
          </div>
          
          </Col>
          </Row>
     
         
      </commandPanelStyle.Header>
  </div>
    );
}

export default ListHead
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AddAssetTabs from './Tabs';
import * as translations from '../translations';
import AddAssetsModalBottom from './AddAssetsModalBottom';
import './../style/style.css';
import * as mmb from 'music-metadata-browser';
import { LibraryServices } from '../service';
import * as Constant from '../constant';

function AddAssetModal(props) {
  const {
    asset,
    activeTabKey,
    progress,
    validated,
    files,
    IsAssetEdit,
    handleEditAsset,
    assetList,
    setChangeFileFlag,
    finishPressed
  } = props;
  const [continueToAddInfoClass, setContinueToAddInfoClass] = useState(
    props.continuetoaddinfoclass
  );

  const [continueToAddInfo, setContinueToAddInfo] = useState(
    IsAssetEdit ? false : true
  );

  const [showClearFile, setShowClearFile] = useState(
    JSON.parse(props.showclearfile)
  );
  const [firstTabLabel, setFirstTabLabel] = useState('');
  const [showFileError, setShowFileError] = useState(false);
  const [showObjectError, setShowObjectError] = useState(false);
  const [showAssetNameError, setShowAssetNameError] = useState(false);

  const handleFormChange = event => {
    props.setAsset({
      ...asset,
      [event.target.name]: event.target.value
    });
    let isUnique = false;

    if (event.target.name === 'name') {
      setShowAssetNameError(false);
      if (assetList) {
        for (let i = 0; i < assetList.length; i++) {
          if (IsAssetEdit) {
            if (asset.id !== assetList[i].id) {
              if (
                assetList[i].name.toLowerCase() ===
                event.target.value.toLowerCase()
              ) {
                setContinueToAddInfo(true);
                setShowAssetNameError(true);
                isUnique = true;
                break;
              }
            }
          } else {
            if (
              assetList[i].name.toLowerCase() ===
              event.target.value.toLowerCase()
            ) {
              setContinueToAddInfo(true);
              setShowAssetNameError(true);
              isUnique = true;
              break;
            }
          }
        }
      }
    }

    if (
      event.currentTarget.form.checkValidity() === false ||
      isUnique === true
    ) {
      setContinueToAddInfo(true);
    } else {
      setContinueToAddInfo(false);
    }

    props.setValidated(true);
    if (event.target.name === 'type') {
      if (assetList) {
        // for (let i = 0; i < assetList.length; i++) {
        // if (IsAssetEdit) {
        //   if (
        //     event.target.value === 'Object_Image' &&
        //     asset.extension !== 'PNG'
        //   ) {
        //     setShowObjectError(true);
        //     setContinueToAddInfo(true);
        //   } else {
        //     setShowObjectError(false);
        //     setContinueToAddInfo(false);
        //   }
        // }
      }
    }
  };

  const handleSwitchChange = () => {};

  const handleActiveKey = key => {
    if (showClearFile === true) {
      if (typeof files[0] !== 'string') {
        addSpec(LibraryServices.getFileType(files[0]));
      }
      props.setActiveTabKey(key);
    }
    if (key === Constant.SECOND_TAB_KEY) {
      setFirstTabLabel('icon');
    }
  };

  const addSpec = type => {
    if (type === 'image') {
      readImageFile(files[0]);
    }
  };

  const handleClearFile = () => {
    props.setFiles([]);
    setShowClearFile(false);
    setContinueToAddInfoClass(true);
    setFirstTabLabel('');
    setShowFileError(true);
    setShowObjectError(false);
    setContinueToAddInfo(false);
    if (IsAssetEdit === true) {
      setChangeFileFlag(true);
    }
  };

  function readImageFile(file) {
    var reader = new FileReader(); // CREATE AN NEW INSTANCE.
    reader.onload = function(e) {
      var img = new Image();
      img.src = e.target.result;
      img.onload = function() {
        var w = this.width;
        var h = this.height;
        props.setAsset({
          ...asset,
          specs: `${w}x${h} px`,
          width: `${w}`,
          height: `${h}`,
          extension: LibraryServices.getFileExtension(file)
        });
      };
    };
    return reader.readAsDataURL(file);
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.onHideModal}
      backdrop="static"
      dialogClassName="add-asset-modal-dialog"
    >
      {(activeTabKey === Constant.FIRST_TAB_KEY ||
        activeTabKey === Constant.SECOND_TAB_KEY) && (
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="header-text"
          >
            {IsAssetEdit === true
              ? translations.UPDATE_FILE
              : translations.UPLOAD_NEW_FILE}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <AddAssetTabs
          activeTabKey={activeTabKey}
          handleActiveKey={handleActiveKey}
          handleSaveInfo={props.handleSaveInfo}
          validated={validated}
          setShowClearFile={data => setShowClearFile(data)}
          setContinueToAddInfoClass={data => setContinueToAddInfoClass(data)}
          files={files}
          setFiles={props.setFiles}
          handleFormChange={event => handleFormChange(event)}
          handleSwitchChange={event => handleSwitchChange(event)}
          // handleTagChange = {(event) => handleTagChange(event)}
          firstTabLabel={firstTabLabel}
          progress={progress}
          handleRollbackAsset={props.handleRollbackAsset}
          asset={asset}
          IsAssetEdit={IsAssetEdit}
          handleEditAsset={handleEditAsset}
          setShowFileError={setShowFileError}
          showFileError={showFileError}
          showAssetNameError={showAssetNameError}
          assetInfoListEdit={props.assetInfoListEdit}
          uploadCount={props.uploadCount}
          showObjectError={showObjectError}
          finishPressed={finishPressed}
        />
        <AddAssetsModalBottom
          activeTabKey={activeTabKey}
          continueToAddInfoClass={continueToAddInfoClass}
          continueToAddInfo={continueToAddInfo}
          handleActiveKey={handleActiveKey}
          showClearFile={showClearFile}
          handleClearFile={() => handleClearFile()}
          IsAssetEdit={IsAssetEdit}
          handleEditAsset={handleEditAsset}
          showObjectError={showObjectError}
          finishPressed={finishPressed}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddAssetModal;

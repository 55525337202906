import { takeLatest, put, call } from "redux-saga/effects";
import * as ActionTypes from '../actions/action-types';
import * as CommandActions from '../actions/command-action';
import * as CommandActionTypes from '../actions/command-action-types';
import {
  getCommandLevel2FromApi,
} from "./api/story-api";

//Command Character
export function* getCmdCharacter(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(getCommandLevel2FromApi, payloads);
    if (data.result === 'Success') {
      yield put(CommandActions.getCommandsCharacterSuccess(data.body, payloads.commandId));
    } else {
      yield put(CommandActions.getCommandsCharacterFail());
      yield put({
        type: ActionTypes.SET_API_ERROR,
      });
    }
  } catch (err) {
    yield put(CommandActions.getCommandsCharacterFail(err));
    yield put({
      type: ActionTypes.SET_API_ERROR,
    });
  }
}
}

export function* getCmdBackground(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(getCommandLevel2FromApi, payloads);

    if (data.result === 'Success') {
      yield put(CommandActions.getCommandsBackgroundSuccess(data.body, payloads.commandId));
    } else {
      yield put(CommandActions.getCommandsBackgroundFail());
    }
  } catch (err) {
    yield put(CommandActions.getCommandsBackgroundFail(err));
  }
}
}

export function* getCmdDialgoue(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(getCommandLevel2FromApi, payloads);

    if (data.result === 'Success') {
      yield put(CommandActions.getCommandsDialogueSuccess(data.body, payloads.commandId));
    } else {
      yield put(CommandActions.getCommandsDialogueFail());
    }
  } catch (err) {
    yield put(CommandActions.getCommandsDialogueFail(err));
  }
}
}

export function* getCmdMedia(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(getCommandLevel2FromApi, payloads);

    if (data.result === 'Success') {
      yield put(CommandActions.getCommandsMediaSuccess(data.body, payloads.commandId));
    } else {
      yield put(CommandActions.getCommandsMediaFail());
    }
  } catch (err) {
    yield put(CommandActions.getCommandsMediaFail(err));
  }
}
}

export function* getCmdPlayMusic(payloads) {
  if(payloads.authToken){
  try {
    const data = yield call(getCommandLevel2FromApi, payloads);

    if (data.result === 'Success') {
      yield put(CommandActions.getCommandsPlayMusicSuccess(data.body, payloads.commandId));
    } else {
      yield put(CommandActions.getCommandsPlayMusicFail());
    }
  } catch (err) {
    yield put(CommandActions.getCommandsPlayMusicFail(err));
  }
}
}

export function* getCommandBackgroundWatcher() {
  yield takeLatest(CommandActionTypes.GET_COMMAND_BACKGROUND, getCmdBackground);
}

export function* getCommandCharacterWatcher() {
  yield takeLatest(CommandActionTypes.GET_COMMAND_CHARACTER, getCmdCharacter);
}

export function* getCommandDialgoueWatcher() {
  yield takeLatest(CommandActionTypes.GET_COMMAND_DIALOGUE, getCmdDialgoue);
}

export function* getCommandMediaWatcher() {
  yield takeLatest(CommandActionTypes.GET_COMMAND_PLAY_SOUND, getCmdMedia);
}


export function* getCommandPlayMusicWatcher() {
  yield takeLatest(CommandActionTypes.GET_COMMAND_PLAY_MUSIC, getCmdPlayMusic);
}
// export function* getCommandEffectWatcher() {
//   yield takeLatest(CommandActionTypes.GET_COMMAND_EFFECT, getCmdEffect);
// }

export default {
  getCommandBackgroundWatcher,
  getCommandCharacterWatcher,
  getCommandDialgoueWatcher,
  getCommandMediaWatcher,
  getCommandPlayMusicWatcher
};

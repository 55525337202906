import styled from 'styled-components';
import { colors } from './../../../common/styles/variables';

export const Tabdiv = styled.div`
  width: 100%;
  padding: 0 ${props => (props.libraryChange ? '20' : '0')}px;
  .tab-panel {
    width: 50%;
    border: 0 !important;
    .nav-link {
      border: 0 !important;
      margin-bottom: 0 !important;
      padding: 7px 25px !important;
      color: #acacac !important;
      font-size: 14px;
      font-family: PT Sans;
      &.active {
        border-bottom: 4px solid #530acd !important;
        color: #530acd !important;
      }
    }
  }
  .inner-div {
    height: 100%;
    width: 65%;
    border-right: 2px solid #45c7ad;
    border-left: 2px solid #45c7ad;
    margin: 0 auto;
    top: 0;
    left: 50%;
  }
  .tab-content {
    .view-tab-content {
      width: 100%;
      display: ${props => (props.libraryChange ? `` : `flex`)};
      border-top: ${props => (props.libraryChange ? `0` : `1`)}px solid #f5f5fa;
      .left-content {
        width: ${props => (props.libraryChange ? '520' : '322')}px;
        background: #f5f5fa;
        border-radius: 4px;
        max-height: ${props => (props.libraryChange ? '693' : '430')}px;
        min-height: ${props => (props.libraryChange ? '693' : '430')}px;
        background-size: cover;
        position: relative;
        overflow: hidden;
      }
      .left-content img {
        height: 430px;
        width: 100%;
      }

      .cropper-section {
        background-color: black;
      }

      .right-content {
        width: ${props => (props.libraryChange ? '35%' : '45%')};
        padding: 30px;
        max-height: 430px;
        min-height: 430px;
        #createViewTabRightPanel {
          .form-label {
            color: #303030;
            font-size: 14px;
            font-family: PT Sans;
            font-weight: bold;
            margin-bottom: 0;
          }
          .vertical-line {
            border-left: 3px solid #157ffb;
            padding-left: 25px;
          }
          .circle {
            position: absolute;
            background: #157ffb;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            font-family: sourceSansProBold;
            color: #fff;
            font-size: 13px;
            left: -20px;
            top: 0;
            text-align: center;
            padding-top: 10px;
          }
          .circle-empty {
            position: absolute;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            font-family: sourceSansProBold;
            color: #157ffb;
            font-size: 18px;
            left: -20px;
            top: 0;
            text-align: center;
            padding-top: 3px;
            border: 3px solid #157ffb;
          }
          .label-title {
            font-size: 16px;
            font-weight: bold;
            color: #303030;
            text-transform: uppercase;
            cursor: pointer;
          }

          .ml-28 {
            margin-left: 28px;
          }
          .mt-24 {
            margin-top: 24px;
            .layer-title {
              font-size: 11px;
              letter-spacing: 1.1px;
              color: #606060;
              text-transform: uppercase;
            }
            .overflow-scroll-y {
              overflow-x: hidden;
              overflow-y: auto;
              height: ${props => (props.libraryChange ? '450' : '137')}px;
              padding-right: 12px;
              .layer-text {
                font-size: 14px;
                color: #303030;
              }
              .fa-bars::before {
                color: #acacac;
              }
            }
          }
          .commandLabelSuffix {
            // color: #707070;
            color: #FFF;
          }
        }
      }
      .spinner-class {
        text-align: center !important;
        vertical-align: middle !important;
        margin: 60% 45% !important;
      }
      .library-view-container {
        background-color: #f5f5fa;
      }
      .view-form-row {
        margin-bottom: 30px !important;
      }
    }
    .browse-tab-content {
      width: 100%;
      background: #f5f5fa;
      max-height: ${props => (props.libraryChange ? `auto` : `430`)}px;
      min-height: ${props => (props.libraryChange ? `auto` : `430`)}px;
      padding: 24px;
      overflow: hidden;

      .tab-header-content {
        display: FLEX;
        justify-content: space-between;
        align-items: center;

        .fa-clone {
        }
      }
      .tab-list-content {
        display: FLEX;
        justify-content: flex-start;
        align-items: center;
        max-height: ${props => (props.libraryChange ? `auto` : `320`)}px;
        overflow: auto;
        margin-top: 10px !important;
        .list-col {
          background: #ffffff;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          min-height: 210px;
          max-height: 210px;
          margin: 15px !important;
          width: 28%;
          position: relative;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
          cursor: pointer;

          .border-div {
            border-left: 2px solid #45c7ad;
            border-right: 2px solid #45c7ad;
            width: 62px;
            height: 100%;
            margin: 0 auto;
          }
          &.active {
            border: 2px solid #157ffb;
          }
          .browse-img-details {
            margin: 0 10px;
            .browse-img-name {
              color: #303030;
              font-size: 13px;
              font-family: PT Sans;
            }
            .browse-img-size {
              color: #707070;
              font-size: 8px;
              font-family: PT Sans;
            }
          }
        }
        .list-col-library {
          background: #ffffff;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          min-height: 211px;
          max-height: 211px;
          margin: 35px !important;
          width: 160px;
          position: relative;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
          cursor: pointer;

          .border-div {
            border-left: 2px solid #45c7ad;
            border-right: 2px solid #45c7ad;
            width: 62px;
            height: 100%;
            margin: 0 auto;
          }
          &.active {
            border: 2px solid #157ffb;
          }
          .browse-img-details {
            margin: 0 10px;
            .browse-img-name {
              color: #303030;
              font-size: 13px;
              font-family: PT Sans;
            }
            .browse-img-size {
              color: #707070;
              font-size: 8px;
              font-family: PT Sans;
            }
          }
        }
      }
    }
  }
  .tab-content > .tab-pane {
    padding: ${props => (props.libraryChange ? `31` : `0`)}px
      ${props => (props.libraryChange ? `27` : `0`)}px !important;
  }
  .button-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  .info-icon {
    background: url('/images/icons/info.svg') no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  .info-icon:hover {
    background: url('/images/icons/info-active.svg') no-repeat;
  }
  .variations-action-icons {
    cursor: pointer;
  }
  .tab-panel .nav-link {
    border-bottom: ${props => (props.libraryChange ? '1' : '0')}px solid #f0eeee !important;
  }
  .warning-popup {
    background-color: #f5f5fa;
  }

  .warning-popup-icon {
    display: block;
    height: 100%;
    width: 10%;
  }
  .object-title {
    min-width: 30px;
    word-break: break-word;
  }
`;

const addObjectPopup = styled.div`
  .add-object-popup {
    .add-object-content {
      font-size: 14px;
      font-family: Bold 14px/19px PT Sans;
      letter-spacing: 0;
      padding: 0 50px 30px 50px !important;
      .form-label {
        font-weight: Bold;
        color: #303030;
      }
      .pl-8 {
        padding-left: 2.25rem !important;
      }

      p {
        font-weight: Regular;
        color: #707070;
      }
      .custom-control-label::after {
        top: calc(0.25rem + -3px);
        left: calc(-2.25rem + 0px);
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 1px 3px #00000036;
      }
      .custom-control-label::before {
        width: 34px;
        height: 14px;
        background-color: #747474;
        border: none;
      }
    }
  }

  .placeViewNumber {
    flex: none !important;
    width: 30% !important;
  }

  .noBackground {
    background: none !important;
  }

  .commandlabelsuffixDiv {
    align-items: center;
    display: flex;
    margin-left: 8px;
  }
  .commandLabelSuffix {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    // color: ${colors.shades_shade500};
    color: #FFF;
    opacity: 1;
    padding-left: 5px;
  }

  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 25px;
  }

  .switch-flip input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 40px;
  }

  .slider-flip:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider-flip {
    background-color: #2196f3;
  }

  .label-text {
    margin-left: 30px;
    font-weight: Bold;
    font-size: 14px;
  }

  .label-sub-text {
    margin-left: 55px;
    font-size: 12px;
    color: #707070;
  }
  .margin-left {
    margin-left: 55px !important;
  }

  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }

  .slider-flip.round:before {
    border-radius: 50%;
  }

  .commandLevelHeading {
    font-weight: bold;
    color: #FFF;
  }

  .commandlabelsuffixDiv {
    text-align: left;
    padding-top: 2.5%;
  }

  .commandLabelSuffix {
    // color: #acacac;
    color: #FFF;
    padding: 0px !important;
  }

  .keyboard-opacity {
    opacity: 0.3;
  }
`;

const browseBg = styled.div`
  background-image: url(${props => props.bg});
  width: 70%;
  height: 130px;
  margin: 20px auto 10px auto;
  height: 130px;
  background-size: cover;
  border: 2px solid #45c7ad;
  top: 30px;
  left: 30px;
`;

const coverBg = styled.div`
  width: 98px;
  height: 130px;
  margin: 20px auto 10px auto;
  border: 2px solid #45c7ad;
  background: url('images/icons/no-image-icon-6.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  img {
    height: 100%;
    width: 100%;
  }
`;

const objectLayerImages = styled.img`
  width: ${props => props.width}px !important;
  height: ${props => props.height}px !important;
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
`;

const cropperPanel = styled.div`
  background-image: url(${props => props.image});
  height: 430px;
  width: 322px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
`;

const cropperSelection = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border: 2px solid #45c7ad;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  position: absolute;
`;

const cropperInnerSelection = styled.div`
  height: 100%;
  width: 65%;
  border-right: 2px solid #45c7ad;
  border-left: 2px solid #45c7ad;
  margin: 0 auto;
`;

export const PlaceBgStyle = {
  Tabdiv,
  browseBg,
  coverBg,
  addObjectPopup,
  objectLayerImages,
  cropperPanel,
  cropperSelection,
  cropperInnerSelection
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import EditViewTabs from './EditViewTabs';
import 'intro.js/introjs.css';

function EditViewCommand(props) {

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show = {props.showModal}
            onHide = {props.onHideModal}
            backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="header-text">
                    Select a view
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
          
                <div className="modal-content-wrapper">
                    <EditViewTabs activeKey = {props.activeKey}
                        setActiveKey = {props.setActiveKey} 
                        backgroundImages = {props.backgroundImages}
                        handleChange = {props.handleChange}
                        onHideModal={props.onHideModal}
                        background = {props.background}
                        fadeIn = {props.fadeIn}
                        views = {props.views}
                        handleViewSearch = {props.handleViewSearch}
                        searchViews = {props.searchViews}
                        backgroundCopy = {props.backgroundCopy}
                        searchText = {props.searchText}
                        assetList = {props.assetList}
                        fadeinView = {props.fadeinView}
                        handlePlaceViewScript = {props.handlePlaceViewScript} />
                </div>
            </Modal.Body>               
        </Modal>        
    );
}

export default EditViewCommand
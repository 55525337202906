import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, Row, Col } from 'react-bootstrap';
import { LibraryServices } from './service';
import * as translations from './translations';
import * as Constants from './../../../common/constants';

function UploadFilesAlbum(props) {
  const maxSize = Constants.ASSET_MAX_FILE_SIZE;
  const imageWidth = Constants.TITLE_COVER_WIDTH;
  const imageHeight = Constants.TITLE_COVER_HEIGHT;

  const {
    editStory,
    storyDetails,
    handleResetFile,
    titleCoverFiles,
    clearfile
  } = props;

  const [cardBorder, setCardBorder] = useState('');

  const [cloudUploadIconColor, setCloudUploadIconColor] = useState(
    'text-secondary'
  );
  const [fileDimensionError, setFileDimensionError] = useState(false);

  const [showError, setShowError] = useState(false);

  const onDragOver = useCallback(() => {
    setCardBorder('primary');
    setCloudUploadIconColor('text-primary');
  }, []);

  const onDragLeave = useCallback(() => {
    setCardBorder('');
    setCloudUploadIconColor('');
  });

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        props.setTitleCoverFile(
          LibraryServices.createPreviewUrl(acceptedFiles)
        );

        // Validate image dimensions
        var image = new Image();
        image.addEventListener(
          'load',
          function() {
            if (image.width !== imageWidth || image.height !== imageHeight) {
              props.setTitleCoverFile([]);
              setCardBorder('danger');
              setFileDimensionError(true);
              setShowError(true);
              props.setShowClearFile(false);
              props.handleResetFile(false);
              props.setClearFile(false);
            } else {
              props.setAsset({
                ...props.asset,
                titleCoverDimension: `${image.width}x${image.height} px`
                // extension: LibraryServices.getFileExtension(file),
              });
            }
            //titleCoverDimension
          }.bind(this)
        );
        image.src = acceptedFiles[0].preview;

        setCardBorder('');
        setCloudUploadIconColor('');
        setFileDimensionError(false);
        props.setShowClearFile(true);
        props.handleResetFile(true);
        props.setContinueToAddInfoClass('button-active');
      }
    },
    [props]
  );

  const onDropRejected = useCallback(
    rejectedFiles => {
      const isFileTooLarge = LibraryServices.checkFileSize(
        rejectedFiles,
        maxSize
      );
      setShowError(true);
      if (isFileTooLarge) {
        setCardBorder('danger');
        props.setFiles([]);
      }
      setFileDimensionError(false);
    },
    [props]
  );

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: Constants.STORY_FILE_ALLOWED_EXTENSIONS.toString(),
    minSize: 0,
    maxSize: Constants.ASSET_MAX_FILE_SIZE,
    onDragOver,
    onDragLeave,
    onDropRejected
  });

  const isFileTooLarge = LibraryServices.checkFileSize(rejectedFiles, maxSize);
  let thumbs = [];

  if (
    editStory &&
    storyDetails.titleCover === null &&
    titleCoverFiles && titleCoverFiles.length === 0
  ) {
    thumbs = [];
  } else {
    if (editStory && clearfile === false) {
      thumbs = [];
    } else {
      thumbs =
        editStory && titleCoverFiles.length === 0
          ? `<img src=${storyDetails.titleCover} key=${storyDetails.titleCover} height="176px" alt=''/>`
          : LibraryServices.createPreviewThumb(titleCoverFiles, 'series');
    }
  }

  //addded
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (titleCoverFiles)
        titleCoverFiles.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [titleCoverFiles]
  );

  useEffect(() => {
    if (props.activeTabKey === 'first' || props.activeTabKey === 'second') {
      setFileDimensionError(false);
      setShowError(false);
      //props.setClearFile(false)
      setCardBorder('');
    }
  }, [props.activeTabKey]);

  return (
    <>
      <div className="album-cover-upload">
        <Row>
          <Col className="mt-1" md={4}>
            {props.storyDetails.titleCover === null && (
              <div>
                <div {...getRootProps()}>
                  <img
                    src="/images/icons/Upload_button.svg"
                    className="ml-1 btn-upload-file"
                  />
                </div>
              </div>
            )}

            {props.storyDetails.titleCover !== null && (
              <span className="remove-width">
                <img
                  width="22px"
                  src="/images/icons/clear.svg"
                  className="mr-1 btn-cross"
                />{' '}
              </span>
            )}
          </Col>
          <Col className="padding-0 " md={8}>
            <div
              onMouseOver={props.highlightALbum}
              onMouseOut={props.resetAlbumBorder}
            >
              <div className="title-text  d-flex align-items-center">
                Album cover
              </div>
              <div className="subtitle-text-uplaod">Exactly 512x819 pixels</div>
            </div>
            <div>
              {!isFileTooLarge &&
                fileDimensionError === false &&
                rejectedFiles.length > 0 &&
                showError === true && (
                  <div className="text-danger mt-2">
                    Cannot upload. Supported file formats: PNG
                  </div>
                )}

              {rejectedFiles.length === 0 &&
                fileDimensionError === true &&
                isFileTooLarge === false &&
                showError === true && (
                  <Card.Text className="text-danger">
                    {translations.INCORRECT_DIMENSIONS}
                  </Card.Text>
                )}
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  {translations.INCORRECT_FILE_SIZE}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UploadFilesAlbum;

import { createGlobalStyle } from 'styled-components';
import { colors } from './variables';

export const GlobalStyles = createGlobalStyle`


.writetool{
  background-image:url('../../images/new_image/Tutorial_popups.png');
  color:white;
}

.writetool:below {
  /* ↓↓↓ the main CSS */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999;
}

.whiteColor{
  color: #3A2C61 !important;
}

.min-value,
.max-value {

	color: #3A2C61 !important;
}
.max-value {
	color: #3A2C61 !important;
}

.main_page{
  display:flex;
  justify-content:space-evenly !important;
}

.writetool-second{
  background-image:url('../../images/new_image/write_tab_and_command_panel_popup.png');
}
.writetool-script{
  background-image:url('../../images/new_image/script_popup.png');
}
.writetool-flowchart{
  background-image:url('../../images/new_image/flowchart_popup.png');
}
.writetool-library{
  background-image:url('../../images/new_image/library_popup.png');
}
.writetool-if_else_1{
  background-image:url('../../images/new_image/if_else_end_iftrue.png');
}

.writetool-if_else_2{
  background-image:url('../../images/new_image/if_else_end_iftrue.png');
}
.writetool-if_else_3{
  background-image:url('../../images/new_image/else.png');
}
.writetool-if_else_4{
  background-image:url('../../images/new_image/example.png');
}

.writetool_choice_1{
  background-image:url('../../images/new_image/choices_137.png');
}

.writetool_choice_2{
  background-image:url('../../images/new_image/choices_173.png');
}

.writetool_choice_3{
  background-image:url('../../images/new_image/destination.png');
}

.writetool_choice_4{
  background-image:url('../../images/new_image/optional.png');
}

.writetool_library_1{
  background-image:url('../../images/new_image/library_popup.png');
}

.writetool_library_2{
  background-image:url('../../images/new_image/create_characters.png');
}

.writetool_library_3{
  background-image:url('../../images/new_image/create_views.png');
}

.writetool_library_4{
  background-image:url('../../images/new_image/Upload_BG_Object_Sound.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
}

.writetool_library_5{
  background-image:url('../../images/new_image/customize_ui.png');
}

.lib-right-rail{
  background-image:url('../../images/new_image/right-rail.png');
background-size: cover;
background-repeat: no-repeat;
}

.main_story{
min-width: 375px !important;
max-width: 375px !important;
min-height: 420px !important;
max-height: 420px !important;

}

.spinner-grow{
  vertical-align: middle !important;
}

.ignoreGrammar{
  font-size:14px !important;
  cursor:grab !important;

}

.ignoreGrammar:hover{
  background-color:#CCC;
}
.fa-compress,
.fa-expand{
color:#3A2C61;
}

  body {
    height: 100%;
    width: 100%;
    position: absolute;
    font-family: 'PT Sans', sans-serif !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    background-color:#F0EEEE !important;
    overflow:hidden;
  }
  
  input:focus:invalid , .form-control:invalid,.form-control:valid,.was-validated{
  box-shadow:none !important;
  background-image: none !important;
}
.form-control:valid{
  box-shadow:none !important;
  border:1px solid #D6D6D6 !important;
  background-image: none !important;
}


input:focus,.form-control:focus{
  box-shadow:none !important;
  border:1px solid #157FFB !important;
  background-image: none !important;
}
input::-ms-clear {
  display: none;
}


.justifycontent-flexend{
  display:flex;
  justify-content:flex-end;
}

.w-50px{
  width:50px;
}
.w-10{
  width:10%;
}
.mt-20{
  margin-top:20px !important;
}
//ICon image

.svg-icon-image{
  height:24px;
}

.mediumIcon{
  height: 64px;
}

white{
  color: #FFF; !important;
}
.backgroundChange{
background: #F0EEF8;
}

/*Button Classes */

.btn-snacktool{
  min-width:120px;
  padding:8px 25px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align:center !important;
  border-radius:40px !important;
  cursor:pointer;
  background: ${colors.color_white};

}


.btn-snacktool-browse{
 
  padding: 8px 28px 8px 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align:center !important;
  border-radius:40px !important;
  cursor:pointer;
  background: ${colors.color_white};

}



.disable-browse{
  background: #E6E6E6 !important;
    border: 2px solid #E6E6E6 !important;
    box-shadow: none !important;
    color: #fff !important;
    cursor: auto;
}

.btn-snacktool-primary{
  background: ${colors.primary_green} !important;
  border:0 !important;
  color: ${colors.color_white} !important;
}

.btn-snacktool-golden{
  background:linear-gradient(180deg,#E09546,40%,#FAB965 100%) 30% 10% !important;
  border: 0 !important;
  color: #3E3527 !important;
}

.btn-snacktool-brown{
  background:transparent !important;
  border: 2px solid #F5AF55 !important;
  color:#F5AF55 !important;
  text-decoration:none !important;
  img{
    height:19px;
  }
}

.btn-snacktool-secondary{
  background: ${colors.color_white} !important;
  border:2px solid ${colors.primary_green} !important;
  box-shadow: none !important;
  color:${colors.primary_green} !important;
  
}
.margin-left-button{
  margin-left: 38% !important;
}
.btn-snacktool-tertiary{
  background: ${colors.color_white} !important;
  border:0 !important;
  box-shadow: none !important;
  color: ${colors.primary_green} !important;
}
.btn-snacktool-confetti{
  background: ${colors.confetti_gradient} !important;
  border:0 !important;
  box-shadow: 0px 3px 4px #00000033 !important;
  color: ${colors.color_white} !important;
}

.btn-snacktool:hover{
  box-shadow: 0px 3px 4px #00000033 !important;
   
}

.save-btn{
  background:#BC45AD;
  font-family:Roboto !important;
  font-weight:bold !important;
  font-size:14px !important;
  min-width:90px !important;
  // color:#fff !important;
  border: none;
}
.btn-snacktool:focus {
  outline:0 !important;
  border:1px solid ${colors.color_white} !important;
  box-shadow: none !important;
  box-shadow: 0 0 0 2px ${colors.vivid_blue} !important;
}


.btn-snacktool-golden:focus {
  outline: 0 !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: none !important;
  box-shadow: 0 0 0 2px #F1AE5A !important;
}

.btn-snacktool-brown:focus{
  outline: 0 !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: none !important;
  box-shadow: 0 0 0 2px #F1AE5A !important;
}

.btn-snacktool:disabled{
  background: ${colors.color_grey} !important;
  color: ${colors.color_white} !important;
  box-shadow: none !important;
  border:0 !important;
  cursor: default !important;
  opacity:1 !important;
  pointer-events: none !important;

}
.cursor-pointer{
  cursor: pointer;
}
.btn-primary.disabled{
  box-shadow: none !important;
  border:0 !important;
  cursor: default !important;
  pointer-events: none !important;

}
.btn-snacktool-validate-grey{
  background: ${colors.color_grey}  0% 0% no-repeat padding-box !important;
  border:0 !important;
  color: ${colors.color_white} !important;
  cursor:auto !important;
  box-shadow: none !important;
  
}


/*Button Classes */



/* Tooltip Styling */
#command-key{
  .tooltip-inner{
    max-width: 583px !important;
    text-align:left !important;
  }
}
.tooltip-320{
  .tooltip-inner{
    max-width: 320px !important;
    text-align:left !important;
  }
}

.tooltip-upload{
  left: 40px !important;
  .tooltip-inner{
    max-width: 250px !important;
    text-align:left !important;
  }
}
.tooltip-upload-200{
  .tooltip-inner{
    max-width: 200px !important;
    text-align:left !important;
  }
}



.tooltip-top-change{
  top:10px !important;
}

.tooltip_textEditor .tooltip-inner{
  max-width:320px !important;
  max-height:105px !important;
  width:320px !important;
  text-align: left !important;
}

.bs-tooltip-top{
  cursor: pointer;
  opacity:1 !important;
  .arrow{
    &:before{
      border-top-color: #43425D !important;
    }
  }
  .tooltip-inner{
    background-color:#43425D;
    font-size:12px;
    color:white;
    line-height:normal;
    font-family:Roboto;
    padding:10px;
  }
}
.bs-tooltip-left{
  cursor: pointer;
  opacity:1 !important;
  .arrow{
    &:before{
      border-left-color: #43425D !important;
    }
  }
  .tooltip-inner{
    background:#43425D;
    font-size:12px;
    color:white;
    line-height:normal;
    font-family:Roboto;
    padding:10px;
  }
}
.bs-tooltip-right{
  cursor: pointer;
  opacity:1 !important;
  .arrow{
    &:before{
      border-right-color: #43425D !important;
    }
  }
  .tooltip-inner{
    background:#43425D;
    font-size:12px;
    color:white;
    line-height:normal;
    font-family:Roboto;
    padding:10px;
  }
}
.bs-tooltip-bottom{
  cursor: pointer;
  opacity:1 !important;
  .arrow{
    &:before{
      border-bottom-color: #43425D !important;
    }
  }
  .tooltip-inner{
    background:#43425D;
    font-size:12px;
    color:white;
    line-height:normal;
    font-family:Roboto;
    padding:10px;
  }
}

/* Tooltip Styling */


/* Custom Checkbox */

.styled-checkbox {
  opacity: 0; // hide it
  display:none;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin:0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background: white;
    border:2px solid #BFBFBF;
    border-radius:6px;

  }

  // Box hover
  &:hover + label:before {
    border: 2px solid #3B86FF;
  }
  
  // Box focus
  &:focus + label:before {
    border: 2px solid #3B86FF;
  }

  // Box checked
  &:checked + label:before {
    background: #3B86FF;
    border: 2px solid #3B86FF;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}
/* Custom Checkbox */



/*Modal Classes */
 .modal-90w{
  width: 600px !important;
  height: 480px !important;
    max-width: none!important;
    top: calc(50% - 300px) !important;

 }

 .vis-network:focus {
    outline: 0;
  }

 .modal-dialog{
  z-index:9 !important;

 }
 .modal-content{
  z-index:9999 !important;
   .modal-header{
    padding:20px 70px;
    align-items:center;
   
   }
   .modal-body{
    padding:0 70px 30px 70px;
    .col,.container{
      padding:0;
    }


    .custom-body{
      padding:0 30px 30px 30px !important;
    }

    .modal-content-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .modal-icon{
          img{
            height: 64px;
          }
      }
      .modal-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:20px;
        .heading{
          color:#303030;
          font-size:23px;
          font-family:PT Sans;
          margin-bottom:0;
          text-align: center;
          line-height:normal;
        }
        .sub-heading{
          color:#707070;
          font-size:14px;
          font-family:PT Sans;
          margin-top:8px;
          margin-bottom:0;
          text-align: center;
          padding: 0 30px;
          line-height:normal;
        }
      
      }
      .Modal-extra-content{
        width: 100%;
        margin-top:20px;
        table{
          max-height: 200px;
          overflow: auto;
        }
      }
      .text-ellipse{
        overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width:100px;
    padding-left: 10px;

      }

    }
        .modal-button-wrapper{
          display: flex;
          justify-content: flex-end;
          margin-top:30px;
          .btn-snacktool-primary{

          }
        }

      

   }
 }
 /*Modal Classes */
  

 .info-label {
    text-align: left;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    font-size: 14px;
 }

 .bold-text {
  text-align: left;
  
  letter-spacing: 0;
  color: #303030;
  opacity: 1;
 }

 .shared-asset-active {
  color: #707070;
}

.shared-asset-in-active {
  color: #DFDFDF;
}


.row , .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  margin:0 !important;
  padding:0 !important;
}
.mt-20{
  margin-top:20px !important;
}
.navbar-dark .navbar-nav .nav-link{
  color: rgba(255,255,255,1) !important;
text-align: center;
font-family:Roboto;
letter-spacing: 1.25px;
color: rgba(255, 255, 255, 0.4);
font-size: 14px;
padding: 8px 35px !important;
text-transform:uppercase;
}


.custom-select-dropdown{
  position:relative
  .select-label,.commandLabel{
    color: #303030;
    font-family:PT Sans;
    font-size:14px;
    font-weight:bold;
    margin:0;
}
  select,.form-control{
    webkit-appearance: none;
    appearance:none;
    padding: 7px 8px;
    background: #fff !important;
    border: 2px solid #D6D6D6;
    border-radius: 4px;
    color:color: #707070;
    font-family:PT Sans;
    font-size:14px;
    cursor:pointer;
    background-image:url('/images/down-chevron.svg') !important;
    background-repeat: no-repeat !important;
    background-position: 95% center !important;
    background-size: 12px !important;
    &:focus{
      outline:0;
    }
    &:disabled{
      background:#E6E6E6 !important;
      cursor: auto;
    }
  }
}


@media (min-width: 1600px) {
  /* For desktop: */
  .col-xlg-1 {max-width: 8.33% !important;}
  .col-xlg-2 {max-width: 16.66% !important;}
  .col-xlg-3 {max-width: 25% !important;}
  .col-xlg-4 {max-width: 33.33% !important;}
  .col-xlg-5 {max-width: 41.66% !important;}
  .col-xlg-6 {max-width: 50% !important;}
  .col-xlg-7 {max-width: 58.33% !important;}
  .col-xlg-8 {max-width: 66.66% !important;}
  .col-xlg-9 {max-width: 75% !important;}
  .col-xlg-10 {max-width: 83.33% !important;}
  .col-xlg-11 {max-width: 91.66% !important;}
  .col-xlg-12 {max-width: 100% !important;}
}
@media (min-width: 1900px) {
  /* For desktop: */
  .col-xxl-1 {max-width: 8.33% !important;}
  .col-xxl-2 {max-width: 16.66% !important;}
  .col-xxl-3 {max-width: 25% !important;}
  .col-xxl-4 {max-width: 33.33% !important;}
  .col-xxl-5 {max-width: 41.66% !important;}
  .col-xxl-6 {max-width: 50% !important;}
  .col-xxl-7 {max-width: 58.33% !important;}
  .col-xxl-8 {max-width: 66.66% !important;}
  .col-xxl-9 {max-width: 75% !important;}
  .col-xxl-10 {max-width: 83.33% !important;}
  .col-xxl-11 {max-width: 91.66% !important;}
  .col-xxl-12 {max-width: 100% !important;}
}
.form-control{
&.error-border{
  border:1px solid #dc3545 !important;
}
}

.input-group-text {
  border: none !important;
  color: #ACACAC !important;
  background-color: transparent !important;
}

.no-input-label{
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  color: #acacac !important;
}  

.scroll-300{
  max-height: 300px;
  min-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
}

// search box styling
.show-result{
  color:#707070;
  font-size:14px;
  font-family:PT Sans;
  margin-right:16px;
}
.form-search-box{
  position:relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom:0 !important;
  .fa-search{
    position:absolute;
    right:1px;
    font-size:12px;
    color:#707070;
    padding: 8px;
    cursor: pointer;
  }
  input[type=text] {
    border: solid 1px #ccc;
    padding: 8px 25px 8px 8px;
    width: 190px;
    transition: all .5s;
    color:#707070;
    font-size:14px;
  }
  input[type=text]:focus {
    width: 200px;
    background-color: #fff;
    border: 1px solid #157FFB !important
    outline:0 !imortant;
  }

  

  .select-search:focus {
   
    background-color: #fff;
    border: 1px solid #157FFB !important
    outline:0 !imortant;
  }
  .select-search {
    border: solid 1px #ccc;
    
    width: 135px;
    
    color:#707070;
    font-size:14px;
  }
  .form-label-search{
  font-size: 14px;
  vertical-align: middle;
  margin-right: 7px;
  padding-top: 4px;
  font-weight: bold;
  }
}

.search_result_count {
  flex-direction: row-reverse !important;
  margin-top: -27px !important;
  margin-right: 1% !important;
}

.ReactCrop__crop-selection{
  border: 2px solid #45C7AD !important;
  border-image-source: none;
}

.ReactCrop__drag-handle {
  background-color: #45C7AD !important;
  border: 1px solid #45C7AD !important;
  outline: 1px solid #45C7AD !important;
}

.cropper-view-box {
  outline-color: #45C7AD !important;
  outline: 3px solid #45C7AD !important;
}

.no-padding{
  padding: 10px 10px !important;
}

.progress {
    display: flex; 

    height: 4px !important;
    overflow: hidden;
    font-size: .75rem;
    background: #157FFB 0% 0% no-repeat padding-box !important;
    border-radius: .50rem; 
}

.progress-bar-custom{
  float: left;
    width: 0%;
    height: 10%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    padding: 0 0 0 10px;
    background: #157FFB 0% 0% no-repeat padding-box !important;
   
    -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}


.cropper-line, .cropper-point {
  background-color: #45C7AD !important;
}

.cropper-point {
  height: 7px !important;
  width: 7px !important;
}
select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}
select {
  background: transparent !important;
}
.info-icon {
  background:url('/images/icons/info.svg') no-repeat;
  width:14px;
  height : 14px;
  display:inline-block;
  
}
.info-icon:hover{
  background:url('/images/icons/info-active.svg') no-repeat;
}
  /* .introjs-overlay{
  background-color:transparent  !important;

  
} */ 
.introjs-helperLayer{
      opacity:0.5 !important;
    }
.introjs-helperNumberLayer{
  display:none;
}
.introjs-tooltip{
  width:260px;
  padding: 0px !important;
  .introjs-tooltiptext{
    font-family:PT Sans;
    margin:25px 20px 20px;
    .text-italic{
      font-style:italic;
    }
    h3{
      font-size:16px;
      letter-spacing: 0px;
      color: #E7DCFB;
      text-transform: uppercase;
      // opacity:0.5;
    }
    p{
      font-size:14px;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin-bottom: 0;
      margin-top: 5px;
      // opacity:0.5;
      line-height: normal;
    }
  }
  .introjs-bullets{
    margin-bottom: 5px;
    ul{
      margin-top:0;
      li{
        a{
          width: 10px;
          height: 10px;
          background: #3B86FF;
          opacity: 0.3;
          &:hover{
            background: #3B86FF;
            opacity: 0.3;
          }
        }
        a.active{
          background: #3B86FF;
          opacity: 1;
        }
      } 
    } 
  } 
  
  .introjs-tooltipbuttons{
    background:#F5F5FA;
    height:50px;
    .introjs-nextbutton, .introjs-prevbutton, .introjs-skipbutton{
      border:none;
      color:#fff;
      font-family: PT Sans;
      font-size:14px;
      border-radius: 0;
      width: 50%;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:0;
      padding: 0;
      text-shadow: none;
      &:hover{
        border:0;
        margin:0;
        box-shadow:none;
      }
    }
    .introjs-hidden {
      display: none !important;
    }
    .introjs-prevbutton{
      // background:#43425D;
      background:#FFFFFF;
      color: black;
      float:left;
    }
    .introjs-nextbutton, .introjs-skipbutton{
      background:#6051A2;
      color: #FFFFFF;
      float:right;
    }
    
    .introjs-skipbutton{
      display:none;
      float:right;
    }
    .introjs-skipbutton.introjs-donebutton{
      display:flex;
      margin:0;

    }
  }
}
.route-info{
  .modal-content{
    .modal-body{
      padding: 0px 40px 40px;
    }
  }
}
.create-new-story{
  width:836px !important;
  max-width:836px !important;
  .modal-content{
    .modal-body{
      padding:0 50px 30px 50px !important;
      .footer-left-button-story{
        font-size:14px;
        justify-content: left;
        .fa-arrow-left:before {
          display:block;
        }
      }
    }
  }  
}
.onboarding-login{
  .modal-content{
    border-radius:6px;
    overflow: hidden;
  }
}
.step-flowchart .tab-content{
  height: calc(100vh - 210px);
  overflow-y:hidden;
  overflow-x:hidden;
  color: #FFFFFF;
  background:#F0EEF8;
}
.my-account-success{
  max-width:600px !important;
  .modal-content{
    .modal-body{
      padding:134px 60px;
    }
  }
}
.confirm-delete-account, .success-review{
  max-width:600px !important;
  .modal-content{
    .modal-body{
      padding:90px;
      .sub-heading{
        padding:0 !important;
      }
      .marginTop60{
        margin-top:60px;
      }
    }
  }
}
.delete-account-modal{
  max-width:600px !important;
  .modal-content{
    .modal-body{
      padding:78px 80px 40px 80px;
      .success-icon, .submit-asset-label{
        margin-bottom:20px !important;
        height:64px;
      }
      .text-note{
        text-align:left !important;
        color: #707070 !important;
        line-height:normal;

        a {
          color:#157FFB !important;
        }
      }
    }
  }
}
.warning-popup{
  max-width:600px !important;
  .modal-content{
    .modal-body{
      padding:112px 100px 63px;
      .sub-heading{
        padding:0 !important;
      }
    }
  }
}
.changeLogScriptView{
  max-width: 90% !important;
  .modal-content{
    .modal-header{
      padding:10px 24px;
      border-bottom:1px solid #E3E3E3 !important;
      .title{
        font-size:24px;
        letter-spacing: 0px;
        color: #303030;
        line-height:normal;
      }
      .sub-title{
        font-size:11px;
        letter-spacing: 1.1px;
        color: #606060;
        text-transform: uppercase;
      }
    }
    .modal-body{
      padding:10px 24px;
      .section-title{
        font-size:11px;
        letter-spacing: 1.1px;
        color: #606060;
        text-transform: uppercase;
        margin-bottom:5px;
      }
    }
  }
}

.libraryChangeLog{
    max-width: 60% !important;
    .modal-content{
      .modal-header{
        padding:24px 40px 0;
      }
      .modal-body{
        padding:16px 40px 24px;
        .sub-heading{
          padding:0 !important;
        }
      }
    }
}
.modal.introjs-fixParent {
  z-index:6 !important;
}

.disable-route-tooltip{
  top:-45px !important;
  width:200px !important;
 
}

.tooltip-bg{
  position: relative;
    width: 100%;
    height:200px !important;
    height: inherit;
    background-size: cover;
    background-position: 0 0;
    background-image: url(/images/bg-box.png);
    background-repeat: repeat;
  }
  .tooltip-inner-bg{
    position: absolute;
    top: 35%;
    left: 35%;
     
    margin: 0 auto;
    
    width: 64px;
    height: 64px;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    width: 50px;
    height: 50px;
    
  }
  .tooltip-inner-bg-big{
    position: absolute;
    top: 35%;
    left: 35%;
     
    margin: 0 auto;
    
    width: 64px;
    height: 64px;
    
    border-radius: 10px;
    opacity: 1;
    width: 50px;
    height: 50px;
    
  }
  .card-content-tooltip {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.gameui-tooltip{
  
  width:200px !important;
  ${
    '' /* .tooltip-inner{
    box-shadow: 0px 3px 12px #00000029 !important;
    border: 1px solid #FFFFFF !important;
    opacity: 1 !important;
    
  } */
  }
 
}

.audio-tooltip{
  .tooltip-inner{
    max-width:340px !important;
    width:340px !important;
  }
  .audio-play{
    margin-top:0px !important;
  }
}


.grecaptcha-badge { visibility: hidden; }
`;

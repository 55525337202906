 
import * as PlayerActionTypes from '../actions/player-action-types';

export const initialState = {
  showPlayer:false,
  preloadData: false,
  loading: null,
  blobImages: {},
  loadingMessage: ''
};

export const PlayerReducer = (state = initialState, action) => {
  

  switch (action.type) {
    case PlayerActionTypes.SET_PLAYER_LOADING_TRUE:
      return {
        ...state,
        loading: true
      }
    case PlayerActionTypes.SET_PLAYER_LOADING_FALSE:
      
      return {
        ...state,
        loading: false
      }
    case PlayerActionTypes.SET_BLOB_IMAGES:
      return {
        ...state,
        blobImages: action.blobImages
      }
    case PlayerActionTypes.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: 'Preparing ' + action.loadingMessage + '....'
      }
    case PlayerActionTypes.RESET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: ''
      }
    default:
      return {
        ...state
      };
  }
};

export const COLLABORATOR_IN_THIS_STORY = 'Collaborators in this story';
export const SEND_INVITATION = 'Send invitation';
export const ADD_COLLABORATOR = 'Add collaborator';

export const STORYID = 'Story ID';
export const STORYIDSUB =
  'The Story ID must begin with a letter, be between 4 and 9 characters long, and contain only lowercase letters, numbers, or underscores.';
export const STORYIDSUBSECOND = 'Story IDs cannot be changed after a story has been published.'
export const UPDATESTORYID = 'Update';
export const CANCEL = 'Cancel';

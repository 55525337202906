import React from 'react';
import { AdminStyle } from '../admin-menu/style/admin-style';
import StoryHead from './story-head';
import StoryContent from './story-content';

function StoryAdmin(props) {
    return (
        <AdminStyle.listContentPanel>
            <StoryHead 
                storyTitle = "Admin Story"
                searchWithAuthor = {props.searchWithAuthor}
                totalResultStoryList = {props.storyAdminReviewed.length + props.storyListAdminOthers.length}
                storyAdminAuthor = {props.storyAdminAuthor}
                refreshList={props.refreshList}
                authorName = {props.authorName}
                setAuthorName = {props.setAuthorName}
                setSearchBy = {props.setSearchBy}
                searchBy={props.searchBy}
            />
            <StoryContent 
                storyListAdminOthers = {props.storyListAdminOthers}
                storyAdminReviewed = {props.storyAdminReviewed}
                loading = {props.loading} 
                handleSorting = {props.handleSorting}
                sortingOthers = {props.sortingOthers}
                sortingReviewed = {props.sortingReviewed}
                handleDeleteClick={props.handleDeleteClick}
                handleShowStoryId={props.handleShowStoryId}
                handleAdminReviewClick={props.handleAdminReviewClick}
                handleSetStoryId={props.handleSetStoryId}
                handleShowStoryInfoModal = {props.handleShowStoryInfoModal}
                handleDuplicateClick={props.handleDuplicateClick}
                tokenData={props.tokenData}
                
            />
        </AdminStyle.listContentPanel>
    );
}

export default StoryAdmin 
import React from 'react';
import * as Constants from './../../../common/constants';
import * as LibraryConstant from './constant';
import * as translations from './translations';

const createPreviewThumb = (files, type) => {
  let imageWidth = type === 'series' ? 63 : 95;
  let imageHeight = type === 'title' ? 154 : 100;

  let thumbs = files.map(file => (
    <div key={file.name}>
      {getFileType(file) === 'image' && (
        <img
          className="epmtyBorder"
          src={file.preview}
          key={file.name}
          height={`${imageHeight}px`}
          alt={file.name}
          width={`${imageWidth}px`}
        />
      )}
    </div>
  ));

  return thumbs;
};

const createPreviewThumbWithBorder = (files, type) => {
  let imageWidth = type === 'series' ? 63 : 95;
  let imageHeight = type === 'title' ? 154 : 100;

  let thumbs = files.map(file => (
    <div key={file.name}>
      {getFileType(file) === 'image' && (
        <img
          className="fileBorder"
          src={file.preview}
          key={file.name}
          height={`${imageHeight}px`}
          alt={file.name}
          width={`${imageWidth}px`}
        />
      )}
    </div>
  ));

  return thumbs;
};

const createPreview = (files, type) => {
  let thumbs = files.preview;

  return thumbs;
};

const checkFileSize = (rejectedFiles, maxSize) => {
  return rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
};

const createPreviewUrl = acceptedFiles => {
  if (acceptedFiles) {
    let newFiles = acceptedFiles[0];
    return [newFiles].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
  }
};

const getAssetTypeList = files => {
  if (files.length === 0) {
    return Constants.ASSET_TYPES_IMAGES;
  }
  let fileType = getFileType(files[0]);
  if (fileType === 'image') {
    return Constants.ASSET_TYPES_IMAGES;
  }
};

const getFileType = file => {
  if (file !== null) {
    return file.type.split('/')[0];
  }

  return null;
};

const getFileExtension = file => {
  if (file !== null) {
    return file.type
      .split('/')[1]
      .split('+')[0]
      .toUpperCase();
  }

  return null;
};

const getActiveTabKey = (assetStatus, activeTabKey, hideModal, intervalId) => {
  let newActiveTab = activeTabKey;

  if (assetStatus === 'success') {
    newActiveTab = LibraryConstant.FIFTH_TAB_KEY;
  }

  if (assetStatus === 'fail') {
    newActiveTab = LibraryConstant.SIXTH_TAB_KEY;
  }

  if (assetStatus === 'cancel') {
    newActiveTab = LibraryConstant.SEVENTH_TAB_KEY;
  }

  if (
    newActiveTab === LibraryConstant.SIXTH_TAB_KEY ||
    newActiveTab === LibraryConstant.SEVENTH_TAB_KEY
  ) {
    setTimeout(hideModal, 5000);
    clearInterval(intervalId);
  }

  return newActiveTab;
};

const getFormData = (
  asset,
  files,
  editStory,
  storyDetails,
  seriesBackgroundFiles,
  titleCoverFiles,
  artTitleFiles
) => {
  var formData = new FormData();

  formData.append(
    'backgroundFile',
    seriesBackgroundFiles && seriesBackgroundFiles[0]
      ? seriesBackgroundFiles[0]
      : null
  );
  formData.append(
    'file',
    titleCoverFiles && titleCoverFiles[0] ? titleCoverFiles[0] : null
  );
  formData.append(
    'artTitleFile',
    artTitleFiles && artTitleFiles[0] ? artTitleFiles[0] : null
  );

  //titleCoverFiles
  if (editStory) {
    if (asset.title === '') {
      asset.title = storyDetails.title;
    }
    if (asset.description === '') {
      asset.description = storyDetails.description;
    }
    if (asset.genres.length === 0) {
      asset.genres = storyDetails.genres;
    }
    if (asset.tags.length === 0) {
      asset.tags = storyDetails.tags;
    }

    if (titleCoverFiles.length === 0) {
      if (asset.titleCover === null) {
        asset.titleCover = null;
      } else {
        asset.titleCover = storyDetails.titleCover;
      }
    }

    if (artTitleFiles.length === 0) {
      if (asset.artTitle === null) {
        asset.artTitle = null;
      } else {
        asset.artTitle = storyDetails.artTitle;
      }
    }
  }
  asset.seriesBackgroundDimension =
    seriesBackgroundFiles && seriesBackgroundFiles[0] ? '1024x649 px' : '';
  asset.titleCoverDimension =
    titleCoverFiles && titleCoverFiles[0] ? '512x819 px' : '';
  asset.artTitleDimension =
    artTitleFiles && artTitleFiles[0] ? '390x100 px' : '';

  delete asset.tags;

  formData.append('storyJson', JSON.stringify(asset));

  return formData;
};

const filterAssetList = (assets, filters, order) => {
  let filterList = [];
  filterList = assets.filter(asset => {
    if (filters.image === true) {
      return Constants.ASSET_TYPES_IMAGES.indexOf(asset.type) > -1;
    }

    if (filters.audio === true) {
      return Constants.ASSET_TYPES_AUDIO.indexOf(asset.type) > -1;
    }

    return asset;
  });

  let newList = sortList(filterList, order);

  return newList;
};

const sortList = (assetList, order) => {
  if (order === 'desc') {
    return assetList
      .sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      })
      .reverse();
  }

  return assetList
    .sort((a, b) => {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    })
    .reverse();
};

const getAssetTypeLabel = assetTypeName => {
  let explodeName = assetTypeName.split('_');
  return `${explodeName[0]} ${explodeName[1]}`;
};

const showError = rejectedFiles => {
  let file = rejectedFiles[0];
  let fileType = getFileType(file);
  let error = '';

  switch (fileType) {
    case 'image':
      error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
      break;
    case 'audio':
      error = translations.ASSET_IMAGE_TYPE_ERROR_MESSAGE;
      break;
    default:
      error = translations.FILES_TYPE_NOT_ACCEPTED;
  }

  return error;
};

export const LibraryServices = {
  createPreviewThumb,
  createPreviewThumbWithBorder,
  createPreview,
  checkFileSize,
  createPreviewUrl,
  getAssetTypeList,
  getFileType,
  getFileExtension,
  getActiveTabKey,
  getFormData,
  filterAssetList,
  getAssetTypeLabel,
  showError
};

import React, { useEffect } from 'react';

import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ItemListLoader from '../common/list-loader';

import moment from 'moment';
import * as styled from './style';
import ConfirmationWindow from './confirm-delete';

const ListAccounts = ({
  onHideClcik,
  fetchAccountList,
  loading,
  accountList,
  authToken,
  userDeactivate,
  onDeleteUser,

  acctdelete,
  handleSorting,
  sorting,
  accStatus,
  onDeleteAccount,
  onEditAccount
}) => {
  useEffect(() => {
    fetchAccountList(authToken);
  }, [authToken, fetchAccountList]);

  if (loading) {
    return <ItemListLoader />;
  } else {
    return (
      <styled.ListAccounnt>
        <Table className="accountTable">
          <styled.AccountsTHead className="table-head-col">
            <tr>
              <th className="headEmail">EMAIL</th>
              <th>Role</th>
              <th>Status</th>
              <th>
                Created
                <i
                  onClick={handleSorting}
                  className={`fa ${
                    sorting === `asc` ? `fa-arrow-down` : `fa-arrow-up`
                  }`}
                />
              </th>
              <th>Actions</th>
            </tr>
          </styled.AccountsTHead>
          <styled.AccountsTBody>
            {accountList !== undefined &&
              accountList.map(acct => (
                <styled.AccountsTr key={acct.id}>
                  <td className="acctEmail">{acct.email}</td>
                  <td className="acctRole">{acct.roles[0].name}</td>
                  <td className="acctStatus">
                    <label className="acctStatusBackground acctStatusLabel">
                      {substituteStatus(acct.status)}
                    </label>
                  </td>
                  <td className="acctDate">
                    {moment(new Date(acct.createdAt)).format('DD-MM-YYYY')}
                  </td>
                  <td className="acctActionButtons">
                    <ActionButtonBar
                      acctStatus={acct.status}
                      acct={acct}
                      onDeleteUser={onDeleteUser}
                      onDeleteAccount={onDeleteAccount}
                      onEditAccount={onEditAccount}
                    />
                  </td>
                </styled.AccountsTr>
              ))}
            {accountList === undefined && <div>Records not found</div>}
          </styled.AccountsTBody>
        </Table>
        <ConfirmationWindow
          acct={acctdelete}
          userDeactivate={userDeactivate}
          onHideClcik={onHideClcik}
          accStatus={accStatus}
        />
      </styled.ListAccounnt>
    );
  }
};

const substituteStatus = status => {
  switch (status) {
    case 'ACTIVE':
      return 'Active';
    case 'INACTIVE':
      return 'Inactive';
    case 'PENDING_APPROVAL':
      return 'Waiting for approval';
    default:
      break;
  }
};

const ActionButtonBar = ({
  acctStatus,
  acct,
  onDeleteUser,
  onDeleteAccount,
  onEditAccount
}) => {
  return (
    <ButtonToolbar aria-label="Toolbar with button groups">
      <ButtonGroup className="mr-2" aria-label="First group">
        {acctStatus === 'ACTIVE' ? (
          ''
        ) : (
          <OverlayTrigger
            key={`activate-account`}
            placement="top"
            overlay={
              <Tooltip id={`activate-account`}>Activate account</Tooltip>
            }
          >
            <img
              className="action-btn"
              onClick={e => onDeleteUser(e, acct, 'activated')}
              src="/images/icons/activate.svg"
            />
          </OverlayTrigger>
        )}

        {acctStatus === 'ACTIVE' ? (
          <OverlayTrigger
            key={`edit-account`}
            placement="top"
            overlay={<Tooltip id={`edit-account`}>Edit account</Tooltip>}
          >
            <img
              className="action-btn"
              onClick={e => onEditAccount(e, acct)}
              src="/images/icons/edit.svg"
            />
          </OverlayTrigger>
        ) : (
          ''
        )}

        {acctStatus === 'INACTIVE' ? (
          <OverlayTrigger
            key={`remove-account`}
            placement="top"
            overlay={<Tooltip id={`remove-account`}>Remove account</Tooltip>}
          >
            <img
              onClick={e => onDeleteAccount(e, acct)}
              className="action-btn"
              src="/images/icons/delete.svg"
            />
          </OverlayTrigger>
        ) : (
          ''
        )}
        {acctStatus === 'PENDING_APPROVAL' || acctStatus === 'ACTIVE' ? (
          <OverlayTrigger
            key={`deactivate-account`}
            placement="top"
            overlay={
              <Tooltip id={`deactivate-account`}>Deactivate account</Tooltip>
            }
          >
            <img
              className="action-btn"
              onClick={e => onDeleteUser(e, acct, 'deactivated')}
              src="/images/icons/deactivate.svg"
            />
          </OverlayTrigger>
        ) : (
          ''
        )}
      </ButtonGroup>
    </ButtonToolbar>
  );
};
export default ListAccounts;

import React from 'react';
import { LibraryStyle} from './../style/style';
import { Row, Col,ProgressBar} from 'react-bootstrap';
import * as translations from './translations';

function SubmitAsset(props) {

    return (
       <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.submitAssetContent>
                <Row>
                    <Col className="submit-asset-label">
                    {props.editStory?translations.UPDATING_NEW_STORY:translations.SAVING_NEW_STORY}
                    </Col>
                </Row>
                <Row>
                    <Col className="progress-bar-col">
                        <ProgressBar className="progress-bar-line" now={props.progress} />
                    </Col>
                </Row>
                
            </LibraryStyle.submitAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default SubmitAsset
import React from 'react';
import { LibraryStyle } from './../style/style';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ListTable from './ListTable';
import GameFonts from './GameFonts';

function ListContent(props) {
  return (
    <React.Fragment>
      <LibraryStyle.listPanel>
        <LibraryStyle.listContainer style={{ marginBottom: '10% !important' }}>
          <GameFonts
            gameFonts={props.gameFonts}
            setFonts={props.setFonts}
            handleGameFontChange={props.handleGameFontChange}
          />
          <Row>
            <Col>
              <h3>CUSTOMIZE THE UI ELEMENTS</h3>
              <h6 className="text-secondary font-15 mb-3">
                The background for the dialogue box and the character name plate
                will be created by using 9-slicing technique. &nbsp;{' '}
                <img src={'images/icons/ic_help_24px.svg'} height="18px" />
                <OverlayTrigger
                  key="first"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-top`}
                      className="preview-mobile-tooltip"
                    >
                      9-slicing is a 2D technique which allows you to reuse an
                      image at various sizes without needing to prepare multiple
                      assets. It involves splitting the image into nine
                      portions, so that when you re-size the sprite the
                      different portions scale or tile ( that is, repeat in a
                      grid formation) in different ways to keep the Sprite in
                      proportion.
                    </Tooltip>
                  }
                >
                  <span className="moreInfoLink"> More info.</span>
                </OverlayTrigger>
              </h6>
              <ListTable
                listInterface={props.listInterface}
                loading={props.loading}
                showModal={props.showModal}
                resetElement={props.resetElement}
                handleGameFontChange={props.handleGameFontChange}
              />
            </Col>
          </Row>
        </LibraryStyle.listContainer>
      </LibraryStyle.listPanel>
    </React.Fragment>
  );
}

export default ListContent;

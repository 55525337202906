import React from 'react';
import { Form } from 'react-bootstrap';
import './spin-box.css'
 

function NumberTextBox({
    component,
    isCommandEdit,
    defaultValue
}) {

    const handleChangeSpinBox = (event) => {
        let output = document.getElementById("volumDisplay");
        output.innerHTML = event.target.value;
    }

    return(
        <Form.Group controlId = {component.widgetName}>
            <Form.Label className="commandLabel">{component.label}</Form.Label>
            <div className="d-flex justify-content-center align-items-center">
                <span className="min-value">1</span>
                <div className = 'range-slider mr-1 ml-1'>
                <Form.Control
                    type="range"
                    className="custom-range"
                    name = {component.widgetName}
                    id="myRange"
                    label={component.label} 
                    max={component.properties.max}
                    min={component.properties.min}
                    placeholder={component.placeHolder} 
                    defaultValue = {isCommandEdit?defaultValue:1}
                    autoComplete = "off"
                    index = {component.index}
                    style = {{border:"none !important"}}
                    onChange = {(event) => handleChangeSpinBox(event)}
                /></div>
                <span className="max-value">10</span>
            </div>
            <p className="whiteColor">Volume: <span id="volumDisplay">{isCommandEdit?defaultValue:1}</span></p>
            <Form.Control.Feedback type="invalid">
                {`Please enter values between min:${component.properties.min} to max: ${component.properties.max}`}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default NumberTextBox
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Badge,
  Button,
  Row,
  Col,
  Overlay,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { styledIcons } from './../../../common/styles/icons';
import * as Constants from './../../../common/constants';
import * as Config from './../../../config/app.config';

function ListAssetRow({
  gameInterface,
  showModal,
  resetElement,
  listLength,
  key
}) {
  //awsPath

  const previewImage = styled.div`
    box-sizing: border-box;
    margin: 1% 7% 0 16%;
    width: 70%;
    height: 34px;
    background: url(${gameInterface.awsPath !== null
        ? gameInterface.awsPath
        : './images/Story_title_placeholder.svg'})
      no-repeat;
    opacity: 1;
    background-size: cover;
    border: 4px solid transparent;
  `;

  const TooltipInnerBg = styled.div`
position: absolute;
top: 35%;
left: 35%;
 
margin: 0 auto;

width: 64px;
height: 64px;
border-radius: 10px;
opacity: 1;
width: 50px;
height: 50px;
background-image: url('${props => props.defaultPath}') no-repeat;
`;

  const [show, setShow] = useState(false);
  const target = useRef(null);
  //fileNames.length - 3
  return (
    <tr className="table-body-row">
      <td className="text-left pl-3 text-dark">
        <img
          ref={target}
          onClick={() => setShow(!show)}
          src="/images/icons/preview.svg"
          alt=""
          className="cursor-pointer mr-3 checkIcon"
        />
        <Overlay
          onHide={() => setShow(false)}
          target={target.current}
          show={show}
          rootCloseEvent="click"
          rootClose={true}
          placement={key > listLength - 3 ? 'right' : 'right'}
        >
          {props => (
            <Tooltip className="gameui-tooltip" id="overlay-example" {...props}>
              <div className="tooltip-bg" key={key}>
                <div className="card-content-tooltip card-content-custom card-body">
                  <aside className="thumbs-container">
                    <div>
                      <img
                        src={
                          gameInterface.awsPath !== null
                            ? gameInterface.awsPath
                            : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH +
                              Constants.defaultInterfaceImages[
                                gameInterface.element
                              ]
                        }
                        // className="checkIcon"
                        key={gameInterface.element}
                        height="100%"
                        width="100%"
                        alt="previewImage"
                      />
                    </div>
                  </aside>
                </div>
              </div>
            </Tooltip>
          )}
        </Overlay>
        {gameInterface.element}
      </td>

      <td className=" text-left pl-3 over-text">
        <OverlayTrigger
          key={`${key}-over`}
          placement="top"
          overlay={<Tooltip id={`${key}-over`}>{gameInterface.source}</Tooltip>}
        >
          <span>
            {gameInterface.source !== 'Default'
              ? gameInterface.source
              : gameInterface.source}
          </span>
        </OverlayTrigger>
      </td>

      <td className="width-20">
        <Badge variant="secondary" className="badges light-badge">
          {gameInterface.extension}
        </Badge>
      </td>
      <td className="text text-small-light">{gameInterface.imageSize}</td>
      <td>
        <OverlayTrigger
          key={`upload`}
          placement="top"
          overlay={<Tooltip id={`upload`}>Upload</Tooltip>}
        >
          <styledIcons.uploadIcon
            className="cursor-pointer"
            onClick={() => showModal(gameInterface.id)}
          />
        </OverlayTrigger>
        {
          <OverlayTrigger
            key={`back-default`}
            placement="top"
            overlay={
              <Tooltip id={`back-default`}>Set back to default image</Tooltip>
            }
          >
            <styledIcons.restoreIcon
              className="cursor-pointer"
              showIcon={gameInterface.source === 'Default' ? false : true}
              onClick={() => resetElement(gameInterface.id)}
            />
          </OverlayTrigger>
        }
      </td>
    </tr>
  );
}

export default ListAssetRow;

import React from 'react';
import { Route } from 'react-router-dom';
import { MainContainer } from './styles';

const LoginContainerLayout = ({ children }) => {
  return <MainContainer>{children}</MainContainer>;
};

const LoginContainer = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <LoginContainerLayout>
          <Component {...matchProps} />
        </LoginContainerLayout>
      )}
    />
  );
};

export default LoginContainer;

import React, { useState } from 'react';
import * as styled from './../player/style';
import PlayerButtons from './../player/widgets/player-buttons';
import BlocksPlayer from './../player/BlocksPlayer';
import {
  Spinner,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form
} from 'react-bootstrap';
import * as Config from './../../config/app.config';
import * as AspectRatioConfig from './../../config/aspectRatio.config';
import * as Constant from './../../common/constants';
// import PlayerConsole from './PlayerConsole';

function PreviewPanel({
  commandsTimeoutIds,
  setCommandsTimeoutIds,
  handlePlayerButtonsClick,
  blocks,
  sceneId,
  play,
  setPlay,
  firstPlay,
  lastPlay,
  nextPlay,
  prevPlay,
  gotoPlay,
  setGotoPlay,
  currentBlock,
  setCurrentBlock,
  currentCommand,
  setCurrentCommand,
  currentScene,
  setCurrentScene,
  handleDialogueDisplayEvent,
  previewTitle,
  fastPreview,
  setFastPreview,
  checkCommandState,
  setCheckCommandState,
  handleDecisionClick,
  setShowDecision,
  showDecision,
  decisionParameters,
  setDecisionParameters,
  ifElseDecisionParameters,
  setIfElseDecisionParameters,
  showEnd,
  setShowEnd,
  loading,
  blobImages,
  setChoiceTimeout,
  choiceTimeout,
  lastView,
  setLastView,
  showDialogue,
  setShowDialogue,
  views,
  setPlayFromStart,
  showNameDialogue,
  setShowNameDialogue,
  setDialogueVariables,
  externalPlayPauseHandler,
  aspectRatio,
  setAspectRatio,
  variableListDTO,
  fetchPreviewDataOnClickPreview,
  interfaceList,
  preloadMessage,
  localVariableListDTO,
  consoleMessage,
  setConsoleMessage,
  updateVariableWithId,
  changeCursorPosition,
  gameFonts,
  previewScroll,
  setPreviewScroll,
  setExternalPlayPauseHandler,
  resetPlayerState,
  setExecutedCommand,
  executedCommand,
  flowchartHasErrors,
  showScriptError,
  textEditorValidate,
  setShowScriptError,
  isViewStory,
  commandListForScriptEditor,
  tokenDataUser,
  setCommand,
  setHighlightBlock,
  highlightBlock,
  changeCheckBoxHighlightBlock,
  highlightBlockIndex,
  setStartFromHighlightBlock,
  startFromHighlightBlock,
  hideIfVisitedArray
}) {
  const [shakeX, setShakeX] = useState('0x');
  const [shakeY, setShakeY] = useState('0y');
  const [dialogueText, setDialogueText] = useState([]);
  const [dialogueCount, setDialogueCount] = useState(0);

  const handlePreviewIconClick = event => {
    setAspectRatio(event.target.getAttribute('aspectratio'));
    fetchPreviewDataOnClickPreview();
    resetPlayerState();
  };

  const changeCheckBoxPreviewScroll = event => {
    setPreviewScroll(!previewScroll);
  };

  const replayStory = e => {
    setDialogueText([]);
    setDialogueCount(0);
    setStartFromHighlightBlock(true);
    handlePlayerButtonsClick(e, Constant.EVENT_FIRST, true);
  };

  let spinnerClass =
    aspectRatio === AspectRatioConfig.aspectRatioThreeByFour
      ? 'spinner-class'
      : 'spinner-class-9-19';
  let preloadMessageClass =
    aspectRatio === AspectRatioConfig.aspectRatioThreeByFour
      ? 'preloadMessage'
      : 'preloadMessage-9-19';

  return (
    <div>
      <styled.previewHeader>
        <Row>
          <div className="phone-icon-wrapper d-flex">
            <div className="mobile-col">
              <OverlayTrigger
                key="first"
                placement="bottom"
                overlay={
                  <Tooltip
                    id={`tooltip-top`}
                    className="preview-mobile-tooltip"
                  >
                    Mobile phone (9:19)
                  </Tooltip>
                }
              >
                <img
                  src={
                    Config.IMAGE_ICON_PATH +
                    (aspectRatio === AspectRatioConfig.aspectRatioNineByNineteen
                      ? 'ic_phone_iphone_24px_active.svg'
                      : 'ic_phone_iphone_24px.svg')
                  }
                  className="mobile"
                  height="34px"
                  alt=""
                  aspectratio="9/19"
                  onClick={handlePreviewIconClick}
                  tooltip="Mobile phone"
                />
              </OverlayTrigger>
            </div>
            <div className="tablet-col">
              <OverlayTrigger
                key="first"
                placement="bottom"
                overlay={
                  <Tooltip
                    id={`tooltip-top`}
                    className="preview-tablet-tooltip"
                  >
                    Tablet (3:4)
                  </Tooltip>
                }
              >
                <img
                  src={
                    Config.IMAGE_ICON_PATH +
                    (aspectRatio === AspectRatioConfig.aspectRatioThreeByFour
                      ? 'ic_tablet_mac_24px_active.svg'
                      : 'ic_tablet_mac_24px.svg')
                  }
                  height="34px"
                  className={`tablet`}
                  aspectratio="3/4"
                  alt=""
                  onClick={handlePreviewIconClick}
                  tooltip="Tablet"
                />
              </OverlayTrigger>
            </div>
          </div>
        </Row>
      </styled.previewHeader>

      <styled.smartplayer
        shakeX={parseFloat(shakeX.replace('x', ''))}
        shakeY={parseFloat(shakeY.replace('y', ''))}
        aspectRatio={aspectRatio}
        style={{ pointerEvents: externalPlayPauseHandler ? 'auto' : 'none' }}
      >
        {showScriptError === false &&
          flowchartHasErrors === false &&
          loading === true &&
          textEditorValidate === true && (
            <React.Fragment>
              <Spinner className={spinnerClass} animation="border" />
              <Col className={`text-center ${preloadMessageClass}`}>
                {preloadMessage}
              </Col>
            </React.Fragment>
          )}
        {showScriptError === false &&
          flowchartHasErrors === false &&
          loading === false &&
          textEditorValidate === true && (
            <BlocksPlayer
              blocks={blocks}
              commandsTimeoutIds={commandsTimeoutIds}
              setCommandsTimeoutIds={setCommandsTimeoutIds}
              play={play}
              firstPlay={firstPlay}
              lastPlay={lastPlay}
              prevPlay={prevPlay}
              nextPlay={nextPlay}
              gotoPlay={gotoPlay}
              setGotoPlay={setGotoPlay}
              currentBlock={currentBlock}
              setCurrentBlock={setCurrentBlock}
              currentCommand={currentCommand}
              setCurrentCommand={setCurrentCommand}
              currentScene={currentScene}
              setCurrentScene={setCurrentScene}
              handleDialogueDisplayEvent={handleDialogueDisplayEvent}
              previewTitle={previewTitle}
              fastPreview={fastPreview}
              setFastPreview={setFastPreview}
              checkCommandState={checkCommandState}
              setCheckCommandState={setCheckCommandState}
              setPlay={setPlay}
              handlePlayerButtonsClick={handlePlayerButtonsClick}
              handleDecisionClick={handleDecisionClick}
              setShowDecision={setShowDecision}
              showDecision={showDecision}
              decisionParameters={decisionParameters}
              setDecisionParameters={setDecisionParameters}
              ifElseDecisionParameters={ifElseDecisionParameters}
              setIfElseDecisionParameters={setIfElseDecisionParameters}
              showEnd={showEnd}
              setShowEnd={setShowEnd}
              blobImages={blobImages}
              choiceTimeout={choiceTimeout}
              setChoiceTimeout={setChoiceTimeout}
              lastView={lastView}
              setLastView={setLastView}
              showDialogue={showDialogue}
              setShowDialogue={setShowDialogue}
              views={views}
              setShakeX={setShakeX}
              setShakeY={setShakeY}
              setPlayFromStart={setPlayFromStart}
              showNameDialogue={showNameDialogue}
              setShowNameDialogue={setShowNameDialogue}
              setDialogueVariables={setDialogueVariables}
              aspectRatio={aspectRatio}
              variableListDTO={variableListDTO}
              interfaceList={interfaceList}
              localVariableListDTO={localVariableListDTO}
              setConsoleMessage={setConsoleMessage}
              consoleMessage={consoleMessage}
              updateVariableWithId={updateVariableWithId}
              changeCursorPosition={changeCursorPosition}
              gameFonts={gameFonts}
              setExternalPlayPauseHandler={setExternalPlayPauseHandler}
              resetPlayerState={resetPlayerState}
              setExecutedCommand={setExecutedCommand}
              executedCommand={executedCommand}
              setShowScriptError={setShowScriptError}
              setCommand={setCommand}
              dialogueText={dialogueText}
              setDialogueText={setDialogueText}
              dialogueCount={dialogueCount}
              setDialogueCount={setDialogueCount}
              previewScroll={previewScroll}
              highlightBlockIndex={highlightBlockIndex}
              setStartFromHighlightBlock={setStartFromHighlightBlock}
              startFromHighlightBlock={startFromHighlightBlock}
              hideIfVisitedArray={hideIfVisitedArray}
            />
          )}
        {showScriptError === true && textEditorValidate === false && (
          <div className="previewScriptError">
            {isViewStory ||
            (commandListForScriptEditor &&
              commandListForScriptEditor.lockAcquired === true &&
              commandListForScriptEditor.lockAcquiredBy !==
                tokenDataUser.userId)
              ? 'Please click on "Validate Script" first before you use the "Preview" functionality.'
              : 'Please click on the "Validate Script" button first and correct any mistakes in your story before you can use the "Preview" functionality.'}
          </div>
        )}
        {showScriptError === true &&
          textEditorValidate === true && flowchartHasErrors === true && (
            <div className="previewScriptError">
              {isViewStory ||
              (commandListForScriptEditor &&
                commandListForScriptEditor.lockAcquired === true &&
                commandListForScriptEditor.lockAcquiredBy !==
                  tokenDataUser.userId)
                ? 'Please wait until the Writer working on this chapter has corrected any mistakes in the story before you can use the "Preview" function.'
                : 'Please click on the "Validate Script" button first and correct any mistakes in your story before you can use the "Preview" functionality.'}
            </div>
          )}
      </styled.smartplayer>

      <PlayerButtons
        sceneId={sceneId}
        handlePlayerButtonsClick={handlePlayerButtonsClick}
        replayStory={replayStory}
        play={play}
        setPlay={setPlay}
        externalPlayPauseHandler={externalPlayPauseHandler}
        aspectRatio={aspectRatio}
        setStartFromHighlightBlock={setStartFromHighlightBlock}
      />
      <styled.scrollHighlight>
        <Row>
          <Col md="12">
            <Form.Group
              key="highligh-block"
              className="commandLabelSuffix d-flex"
            >
              <label className="switch-flip">
                <input
                  type="checkbox"
                  name={`checkbox_scroll_preview`}
                  className="checkbox-flip"
                  label="checkbox"
                  onChange={event => changeCheckBoxHighlightBlock(event)}
                  checked={highlightBlock === true ? 'checked' : ''}
                />
                <span className="slider-flip round"></span>
              </label>
              <span className="label-text">Play from Highlighted Block</span>
              <OverlayTrigger
                key={`play-text`}
                placement="top"
                overlay={
                  <Tooltip id={`play-text`}>
                    Assets and music don’t carry over from block to block in the preview tool when we play from highlighted blocks
                  </Tooltip>
                }
              >
                <span className="info-icon ml-2"></span>
              </OverlayTrigger>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group
              key="scroll-script"
              className="commandLabelSuffix d-flex"
            >
              <label className="switch-flip">
                <input
                  type="checkbox"
                  name={`checkbox_scroll_preview`}
                  className="checkbox-flip"
                  label="checkbox"
                  onChange={event => changeCheckBoxPreviewScroll(event)}
                  checked={previewScroll === true ? 'checked' : ''}
                />
                <span className="slider-flip round"></span>
              </label>
              <span className="label-text white">Follow Along Script</span>
            </Form.Group>
          </Col>
        </Row>
      </styled.scrollHighlight>
      {/* <PlayerConsole consoleMessage={consoleMessage} /> */}
    </div>
  );
}

export default PreviewPanel;
import React from "react";
import { Route } from "react-router-dom";
import { MainContainer } from "./styles";
import Header from '../../components/Header';

const SnackContainerLayout = ({ children, ...rest}) => {
  
  return (
    
    <MainContainer>
      {children}
    </MainContainer>
  )
};

const SnackContainer = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <SnackContainerLayout>  
        <Header headerValue = {rest.headerValue}/>
        <Component {...matchProps} />
      </SnackContainerLayout>  
    )} />  
  )  
};  
  

export default SnackContainer;
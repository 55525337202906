import React from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import CommandParser from "../script-writer/text-editor/command-parser"
import * as Constant from "../../common/constants"
// Load Prism CSS

const ChangeLogModalScript = (props) => {
    let ifCounter = 0;
    let defualtTabIndent = '\t'

    const handleTabCount = (tablist, tillCount) => {
        let tabs = '\t'
        for (let i = 0; i < tillCount; i++) {
            tabs = `${tabs}\t`
        }
        return tabs
    }


    //we can used in upcoming Feature
    // const highlightSyntax = str => (
    //     <pre
    //         style={{ display: 'inline' }}
    //         dangerouslySetInnerHTML={{
    //             __html: `<div className='Change'>${str}</div>`,
    //         }}
    //     />
    // );

    const indentAllCommands = (commands) => {
        let valueIndent = ''
        commands.map(commands => {
            const tabCountStart = (commands.cmdText.match(new RegExp("\t", "g")) || []).length
            const cmdObj = new CommandParser(commands.cmdText.trim(), tabCountStart)
            const whichPartParamertesObj = cmdObj.getCommandPartAt(2);
            if (commands.commandId === Constant.COMMAND_ADD_BLOCK) {
                ifCounter = 0;
                valueIndent =
                    valueIndent !== ""
                        ? `${valueIndent}\n${commands.cmdText}`
                        : commands.cmdText
            } else {
                switch (whichPartParamertesObj.commandStart) {
                    case 'if':
                        defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter)
                        ifCounter += 1
                        break

                    case 'elseIf':
                    case 'else':
                        defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter - 1)
                        break

                    case 'end':
                        ifCounter -= 1
                        defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter)
                        break

                    default:
                        defualtTabIndent = handleTabCount(defualtTabIndent, ifCounter)
                        break
                }
                valueIndent =
                    valueIndent !== ""
                        ? `${valueIndent}\n${defualtTabIndent}${commands.cmdText}`
                        : commands.cmdText
            }
        })

        return valueIndent
    }

    const oldCode = indentAllCommands(props.olderVersion)
    const newCode = indentAllCommands(props.newerVersion)

    const newStyles = {
        variables: {
            light: {
                addedBackground: '#DAF4EF',
                removedBackground: '#FFE0E0',
                addedGutterBackground: '#DAF4EF',
                removedGutterBackground: '#FFE0E0',
            },
        },
    };

    return (
        <ReactDiffViewer
            styles={newStyles}
            oldValue={oldCode}
            newValue={newCode}
            splitView={true}
            disableWordDiff={true}
            showDiffOnly={false}
            compareMethod={DiffMethod.CHARS}
        />
    );
}

export default ChangeLogModalScript;
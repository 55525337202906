import React, { useEffect, useState } from 'react';
import * as CollaboratorStyle from './style/collaborator-style';
import { Button, Form, Modal } from 'react-bootstrap';
import * as translations from './style/translations';
import CollaboratorList from './viewer-list';

const AddCollaborator = props => {

  const [writerSuggestionList, setWriterSuggestionList] = useState([]);


  const [invitationButtonEnabled, setInvitationButtonEnabled] = useState(
    'disabled'
  );

  const selecteSuggestedValue = event => {
    //setWriterSuggestionList([]);

    props.setdefaultCollaboratorName(event.target.value);
    // setInvitationButtonEnabled('')
  };

  useEffect(() => {
    console.log('');
  }, [props]);

  const handleFormChange = event => {
    props.setCollaboratorError('');
    if (event.target.value === '') {
      setInvitationButtonEnabled('disabled');
    } else {
      props.setdefaultCollaboratorName(event.target.value);
      setInvitationButtonEnabled('');
    }
  };

  const checkSuggestionList = event => {
    setInvitationButtonEnabled('disabled');

    const autocompleteDataValue = event.target.value;
    props.setdefaultCollaboratorName(autocompleteDataValue);

    props.setCollaboratorError('');

    if (autocompleteDataValue.length > 0) {
      if (autocompleteDataValue.length >= 3) {
        setInvitationButtonEnabled('');
      }
      let suggestions = [];
      const regex = new RegExp(`${autocompleteDataValue}`, 'i');
      props.writerListAll.map((writer, index) => {
        suggestions.push(writer.username);
      });
      suggestions = suggestions.sort().filter(v => regex.test(v));
      setWriterSuggestionList(suggestions);
    } else {
      setWriterSuggestionList([]);
    }
  };
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showCollaboratorModal}
      // onHide={props.setshowCollaboratorModal(false)}
      backdrop="static"
    >
      <CollaboratorStyle.modalCollaborator>
        <Modal.Body>
          <Modal.Title id="example-custom-modal-styling-title">
            {translations.VIEWER_IN_THIS_STORY}
          </Modal.Title>
          <p>
            Viewers can access this story in read-only mode, which allows them
            to see the flowchart, read the script, and use the Live Preview.
            Viewers cannot edit chapters or access the Library.
          </p>
          <Form
            method="POST"
            onSubmit={evt =>
              props.addNewCollaborator(evt, props.collaboratorStoryId)
            }
          >
            <Form.Group>
              <Form.Label>{translations.ADD_VIEWER}</Form.Label>
              <div className="d-flex">
                <div className="mr-4 input-wrapper">
                  <Form.Control
                    required
                    type="text"
                    name="collaboratorName"
                    // id="collaboratorName"
                    // value={editChapterName}
                    defaultValue={props.defaultcollaboratorName}
                    // onChange={(event) => checkSuggestionList(event)}
                    // value={valueCollaborator}
                    onBlur={handleFormChange}
                    onChange={handleFormChange}
                    placeholder="Add by username or email"
                    maxLength="100"
                    autoComplete="off" // onChange={(evt) => setNewEditChapterNameValidation(evt.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Field is required
                  </Form.Control.Feedback>
                  {props.collaboratorError !== '' && (
                    <Form.Text className="text-danger">
                      {props.collaboratorError}
                    </Form.Text>
                  )}
                  <CollaboratorStyle.AutocompleteDiv>
                    {writerSuggestionList.map((suggestedName, index) => {
                      return (
                        <CollaboratorStyle.autocompleteItems
                          onClick={e => selecteSuggestedValue(e, suggestedName)}
                          key={index}
                        >
                          {suggestedName}
                        </CollaboratorStyle.autocompleteItems>
                      );
                    })}
                  </CollaboratorStyle.AutocompleteDiv>
                </div>
                <div>
                  <Button
                    id="createSceneBtn"
                    type="submit"
                    className="btn-snacktool btn-snacktool-golden btn btn-primary"
                    disabled={invitationButtonEnabled}
                  >
                    {translations.SEND_INVITATION_VIEWER}
                  </Button>
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter valid and unique viewer name
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          {props.collaboratorList.length > 0 && (
            <CollaboratorList
              collaboratorList={props.collaboratorList}
              revokeCollaboratorAccess={props.revokeCollaboratorAccess}
              resendCollaboratorInvitation={props.resendCollaboratorInvitation}
              tokenDataUser={props.tokenDataUser}
              resendCollaboratorAccess={props.resendCollaboratorAccess}
            />
          )}
          <div className="text-right mt-4">
            <Button
              id="closeBtn"
              type="button"
              className="btn-snacktool btn-snacktool-golden btn btn-primary"
              onClick={() => props.handleHideCollaborator()}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </CollaboratorStyle.modalCollaborator>
    </Modal>
  );
};

export default AddCollaborator;

import * as Constant from './../../../common/constants';
import { ImageMapper } from './../../../common/helper/image-mapper';
import { StoryService} from './../../story/service';
import * as Config from './../../../config/aspectRatio.config';
import { PlayerService } from './player-service';

const showObject = (command, skipTimeout = false, blobImages, views, aspectRatio, selectedView) => {
    let commandParameters = command.parameters;
    let previewContent = document.getElementById('preview-content');
    let styles = {};

    let currentView = StoryService.getView(views, selectedView);
    let object = StoryService.getObjectByView(currentView, command.parameters.viewObjectAssetId)
    
    // Does not show if object have propery hidden
    // if (object[0] && object[0].hidden === true) {
    //     return;
    // }

    let newObject = document.getElementById(`object-${command.parameters.viewObjectAssetId}`);
    if (newObject !== null) {
        newObject.style.opacity = 0;
        newObject.style.animation = `fadeIn ${command.parameters['Duration']}s`;
        return false;
    }

    if (Object.keys(commandParameters).length !== 0) {

        if (skipTimeout === false && commandParameters.hasOwnProperty(Constant.DURATION)) {
            styles = fadeIntoScreen(styles, commandParameters[Constant.DURATION]);
        }
    }

    
    let pWidth = Config.aspectRatioParameters[aspectRatio].previewWidth;
    let pHeight = Config.aspectRatioParameters[aspectRatio].previewHeight;
    if (aspectRatio === Config.aspectRatioNineByNineteen) {
        pWidth = Config.aspectRatioParameters[aspectRatio].objectViewWidth;
        pHeight = Config.aspectRatioParameters[aspectRatio].objectViewHeight;
    }

    if (object.length === 0) {
        return true;
    }

    let objects = ImageMapper.mapHiddenObjectsOnView([object[0]], 
        styles, 
        currentView.width, 
        currentView.height, 
        pWidth,
        pHeight,
        commandParameters[Constant.DURATION], 
        aspectRatio,
        blobImages);
    previewContent.insertAdjacentHTML('beforeend', objects);

    return true;
}

const fadeIntoScreen = (styles, fadeIn) => {
    styles['WebkitAnimation'] = `fadeIn ${fadeIn}s`;
    styles['animation'] = `fadeIn ${fadeIn}s`;
    
    return styles;
}

const hideObject = (command, view, skipTimeout = false) => {
    let commandParameters = command.parameters;
    let object = StoryService.getObjectByView(view, command.parameters.viewObjectAssetId)
    let objectElement = document.getElementById(`object-${object[0].asset.id}`);

    if (objectElement === null) {
        return false;
    }

    if (Object.keys(commandParameters).length !== 0) {
        let removeElementTimeout = 0;
        if (skipTimeout === false && commandParameters.hasOwnProperty(Constant.DURATION)) {
            objectElement.style.opacity = 0;
            objectElement.style.transition = `opacity ${commandParameters[Constant.DURATION]}s`;
            removeElementTimeout = commandParameters[Constant.DURATION];
        } else {
            objectElement.style.opacity = 0;
        }

        setTimeout(() => {
            objectElement.remove();
        }, removeElementTimeout*1000)
    }

    return object[0];
}

const tapObject = (block, flowchartData, handleObjectClick, currentView) => {
    block.commands.map(command => {
        if (command.commandId !== Constant.COMMAND_TAP_OBJECT) {
            return;
        }
        let objName = command.parameters.objectName;
        let object = PlayerService.getObjectByName(objName, currentView.viewObjects);
        let objectElement = document.getElementById(`object-${object[0].asset.id}`);
        
        if (!objectElement) {
            return;
        }

        // If object is not interactive then return
        if (object[0] && object[0].interactive === false) {
            return;
        }

        objectElement.addEventListener('click', () => {
                handleObjectClick(flowchartData, command.parameters['Destination']);
            }
        )
    });
    
}

export const ObjectCommands = {
    showObject,
    hideObject,
    tapObject
}
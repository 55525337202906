import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { LibraryStyle } from './../style/style'; 
import GameFontsDropDown from './GameFontsDropDown';

function GameFonts(props) {

    return (
        <LibraryStyle.gameFonts fonts={props.gameFonts}>
            <Row>
                <Col>
                <Row>
                <Col>
                <h3>GAME FONTS</h3>
                </Col>
                </Row>
                <Row  className="d-flex">
                <Col>
               
                    <div className="card">
                        <div className="custom-select-dropdown">
                        <label className="select-label">
                            Dialogue box
                        </label>
                        <GameFontsDropDown gameFonts = {props.gameFonts} type="dialogueText"
                            handleDropDownChange = {props.handleGameFontChange} />
                        </div>
                        <div className="card-text">
                            <div className="dialogue-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            </div>
                        </div>               
                    </div>
                    </Col>
                    <Col>
                    <div className="card">
                        
                        <div className="custom-select-dropdown">
                        <label className="select-label">
                            Character name plate
                        </label>
                        <GameFontsDropDown gameFonts = {props.gameFonts}
                            type = "characterNamePlate"
                            handleDropDownChange = {props.handleGameFontChange} />
                        </div>
                        <div className="card-text characterNamePlateText">
                            <div className="dialogue-text single-line-text character-name-plate-text">
                                Lorem ipsum
                            </div>
                        </div>
                    </div>
                    </Col>    
                      <Col>
                    <div className="card">
                        
                        <div className="custom-select-dropdown">
                        <label className="select-label">
                            Button label
                        </label>
                        <GameFontsDropDown gameFonts = {props.gameFonts}
                            type = "buttonLabel"
                            handleDropDownChange = {props.handleGameFontChange} />
                        </div>
                        <div className="card-text">
                            <div className="dialogue-text single-line-text button-label-text">
                                Lorem ipsum
                            </div>
                        </div>
                    </div>
                    </Col>
                
               
                </Row>
                    
                </Col>
            </Row>
            
        </LibraryStyle.gameFonts>
    )
}

export default GameFonts
import React from 'react';
import { LibraryStyle } from '../style/style';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import * as translations from '../translations';

function SubmitAsset(props) {
  return (
    <LibraryStyle.addAssetMessageBox>
      <LibraryStyle.submitAssetContent>
        <Row className="pb-5">
          <Col className="submit-asset-label">
            {props.IsAssetEdit
              ? translations.UPDATING_ASSET
              : translations.SAVING_NEW_ASSET}
          </Col>
        </Row>
        <Row>
          <Col className="progress-bar-col pb-4">
            <ProgressBar
              style={{
                width: `${props.progress < 100 ? props.progress : 100}%`
              }}
              aria-valuenow={props.progress}
              aria-valuemin="0"
              aria-valuemax="100"
              className="progress-bar-custom"
            ></ProgressBar>
          </Col>
        </Row>
        {/* <Row>
                    <Col className="submit-asset-cancel-label close">
                        <Nav.Link onClick={props.handleRollbackAsset}>{translations.CANCEL}</Nav.Link>
                    </Col>
                </Row> */}
      </LibraryStyle.submitAssetContent>
    </LibraryStyle.addAssetMessageBox>
  );
}

export default SubmitAsset;

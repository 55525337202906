import React from 'react';
import * as styled from './style';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import * as Constant from '../../common/helper/pattern';
import { commonServices } from '../../common/helper/utils';

const CreateAccount = ({
  show,
  validated,
  email,
  username,
  role,
  isUserCreated,
  error,
  showError,
  enable,
  onCreateAccountClick,
  onCloseClick,
  onSendClick,
  onFormChange,
  isEditAccount,
  accountCreationLoader
}) => {
  //const [frames, setFrames] = useState(data);
  return (
    <styled.CreateAccount>
      <Button
        onClick={onCreateAccountClick}
        className="btn-snacktool-brown btn-snacktool "
      >
        Create New Account
      </Button>

      <Modal dialogClassName="modal-90w " show={show} onHide={onCloseClick}>
        <styled.Modal>
          {!isUserCreated && (
            <Modal.Header closeButton>
              {!isUserCreated && (
                <Modal.Title>
                  {isEditAccount
                    ? 'Update WRITE FICTIF account'
                    : ' Create a new WRITE FICTIF account'}
                </Modal.Title>
              )}
            </Modal.Header>
          )}
          <Modal.Body>
            {!isUserCreated && (
              <Form noValidate validated={validated} onSubmit={onSendClick}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    defaultValue={email}
                    onChange={onFormChange}
                    onInput={onFormChange}
                    onKeyUp={onFormChange}
                    disabled={accountCreationLoader ? true : false}
                    pattern="^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                  <Row className="loginError">
                    <Col md="auto">
                      {' '}
                      {showError &&
                        error.map((datum, index) => (
                          <styled.Errors>
                            {datum.includes('email')
                              ? commonServices.filterErrors(datum)
                              : ''}
                          </styled.Errors>
                        ))}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group controlId="validationCustomUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="username"
                    defaultValue={username}
                    onChange={onFormChange}
                    onInput={onFormChange}
                    onKeyUp={onFormChange}
                    type="text"
                    required
                    pattern={Constant.USER_NAME_PATTERN}
                    disabled={accountCreationLoader ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid username.
                  </Form.Control.Feedback>
                  <Row className="loginError">
                    <Col md="auto">
                      {' '}
                      {showError &&
                        error.map((datum, index) => (
                          <styled.Errors>
                            {datum.includes('username')
                              ? commonServices.filterErrors(datum)
                              : ''}
                          </styled.Errors>
                        ))}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    name="roleId"
                    value={role}
                    onChange={onFormChange}
                    required
                    as="select"
                    disabled={accountCreationLoader ? true : false}
                  >
                    <option value="1">Snack Admin</option>
                    <option value="2">Story Creator</option>
                    <option value="3">Editor Admin</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="error"></Form.Group>

                <Row className="modal-button-wrapper d-flex mt-4 justify-content-center">
                  {isEditAccount && (
                    <Col xs={isEditAccount ? 7 : 5}>
                      {' '}
                      <Button
                        onClick={() => onCloseClick()}
                        className="btn-snacktool btn-snacktool-brown"
                        disabled={enable}
                      >
                        Discard changes
                      </Button>
                    </Col>
                  )}
                  <Col xs={isEditAccount ? 5 : ''}>
                    {' '}
                    <Button
                      disabled={enable}
                      onClick={onSendClick}
                      className="btn-snacktool btn-snacktool-golden"
                    >
                      {isEditAccount
                        ? 'Update info'
                        : 'Send account information'}
                      {accountCreationLoader && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          style={{
                            float: 'right',
                            'font-size': '10px',
                            height: '16px',
                            width: '16px',
                            'margin-top': '4px'
                          }}
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}

            {isUserCreated && (
              <styled.AccountCreated>
                <styled.AccountIcon>
                  <Row>
                    <Col className="success-icon checkIcon">
                      <img
                        alt=""
                        className="mediumIcon"
                        src="/images/icons/ic_done_24px.svg"
                      />
                    </Col>
                  </Row>
                </styled.AccountIcon>
                <div className="heading">
                  {' '}
                  {isEditAccount
                    ? 'Account has been updated'
                    : 'Account has been created'}
                </div>

                <styled.AccountCreatedEmail>
                  <div className="sub-heading">
                    {' '}
                    An email to {email} has been sent
                  </div>
                </styled.AccountCreatedEmail>
                <div className="button-space">
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <Button
                        onClick={onCloseClick}
                        className="btn-snacktool btn-snacktool-golden"
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </div>
              </styled.AccountCreated>
            )}
          </Modal.Body>
        </styled.Modal>
      </Modal>
    </styled.CreateAccount>
  );
};
export default CreateAccount;

import React from 'react';
import { LibraryStyle } from '../style/style';
import { Row, Col } from 'react-bootstrap';

function Cancel(props) {
  return (
    <LibraryStyle.addAssetMessageBox>
      <LibraryStyle.successAssetContent>
        <Row>
          <Col className="fail-icon">
            <img src="/images/icons/ic_do_not_disturb_off_24px.svg" className="mediumIcon" />
          </Col>
        </Row>
        <Row>
          <Col className="submit-asset-label">
            {props.IsAssetEdit
              ? ' Updating asset has been canceld!'
              : 'Saving asset has been canceld!'}
          </Col>
        </Row>
      </LibraryStyle.successAssetContent>
    </LibraryStyle.addAssetMessageBox>
  );
}

export default Cancel;

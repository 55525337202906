import styled from 'styled-components';
import { colors } from '../../../common/styles/variables';

export const Users = styled.div`
  margin-left: 233px;
  margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  margin-bottom: 5px;
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const Heading = styled.div`
  padding: 4px 0 3px 15px;
  font-size: 32px;
  width: 100%;
  text-align: left;
  font: PT Sans;
  letter-spacing: 0;
  color: #303030;
`;

export const ListAccountWrapper = styled.div``;

export const MainSection = styled.div`
  margin-top: 8px;
  left: 234px;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  opacity: 1;
  overflow-y: auto;
  height: ${props => props.currentMenu || 'calc(100vh - 160px)'};
  overflow-x: hidden;
`;

export const MyAccountMainPage = styled.div`
  margin: 60px;
  height: calc(100vh - 190px);
  .button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid ${colors.primary_green};
    border-radius: 40px;
    opacity: 1;
    color: ${colors.primary_green};
    font-size: 16px;
    text-align: center;
  }
`;

export const Modal = styled.div`
  .modal-title {
    font-size: 24px;
    color: #303030;
    letter-spacing: 0;
  }
  .btn {
    background: ${colors.primary_green} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 4px #059d7f4a;
    border-radius: 20px;
    border: 1px solid ${colors.primary_green};
    color: #ffffff;
  }
  .modal-footer {
    border: none;
  }
`;

export const MyAccountDetail = styled.div`
  .title-label {
    font-size: 16px;
    color: #303030;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .form-label {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #303030;
    margin-bottom: 5px;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .btn-snacktool {
    min-width: auto;
  }
  .meter-red {
    width: 300px;
    heigth: 20px !important;
    // color:red;
  }
  .progress {
    margin-top: 5px;
    height: 10px !important;
    background-color: #f5f5f5 !important;
  }
  .input-error {
    border: 1px solid red !important;
  }
  .info-container {
    margin: 10%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
    font: Bold 14px PT Sans;
    letter-spacing: 0px;
    color: #707070;
    width: 60%;
    ol.info-list {
      list-style-type: disc;
    }
  }

  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 25px;
    margin-top:3px
  }
  
  .switch-flip input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 40px;
  }
  
  .slider-flip:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider-flip {
    background-color: #2196F3;
  }

  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }

  .slider-flip.round:before {
    border-radius: 50%;
  }

  .label-text{
    font-weight: bold;
    margin-left: 20px;
  }
`;

import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
import ListRow from './ListRow';

import './../../style/style.css';
import * as Translations from './../../translations';

function ListTable({
  assetFiles,
  fileNames,
  handleAssetFileDelete,
  handleTypeChange,
  handleNameChange,
  handleUploadInfo,
  handleDefaultTypeChange,
  refreshList,
  focusElement,
  outfitCount,
  hairCount,
  expressionCount,
  accessoryCount,
  dummyAsset,
  setDummyAsset
}) {
  return (
    <LibraryStyle.listTable>
      <Table responsive="md">
        <thead className="bg-light">
          <tr className="table-head-row">
            <th></th>
            <th className="text-left">IMAGE NAME</th>

            <th className="text-left">TYPE</th>

            <th>
              DEFAULT
              <OverlayTrigger
                key={`default-category`}
                placement="left"
                overlay={
                  <Tooltip id={`default-category-char`} className="tooltip-320">
                    Only one asset can be default by category at a given time.
                    When a character is quickly added to the script the default
                    images will be used.
                  </Tooltip>
                }
              >
                <img
                  src=" /images/icons/info.svg"
                  alt=""
                  className="ml-2 cursor-pointer"
                />
                {/* <span className="info-icon ml-2" ></span> */}
              </OverlayTrigger>
            </th>
            <th>REMOVE</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {assetFiles.length > 0 &&
            assetFiles.map((asset, index) => {
              return (
                <ListRow
                  index={index}
                  asset={asset}
                  fileNames={fileNames}
                  handleAssetFileDelete={handleAssetFileDelete}
                  handleNameChange={handleNameChange}
                  handleTypeChange={handleTypeChange}
                  handleUploadInfo={handleUploadInfo}
                  handleDefaultTypeChange={handleDefaultTypeChange}
                  refreshList={refreshList}
                  focusElement={focusElement}
                  outfitCount={outfitCount}
                  hairCount={hairCount}
                  expressionCount={expressionCount}
                  accessoryCount={accessoryCount}
                  dummyAsset={dummyAsset}
                  setDummyAsset={setDummyAsset}
                />
              );
            })}
        </tbody>
      </Table>
    </LibraryStyle.listTable>
  );
}

export default ListTable;

import React, { useEffect } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { EditorService } from './../constants';
import { PreviewStyle } from './style';
import * as Constant from './../../../../common/constants';
import './style.css';

import { ImageMapper } from './../../../../common/helper/image-mapper';

import * as AspectRatioConfig from './../../../../config/aspectRatio.config';

const callbackFunction = (x, objects = '') => {
  let element = document.getElementById('view');
  if (element != null) {
    element.style.backgroundImage = `url(${x})`;
  }

  if (objects !== '') {
    element.insertAdjacentHTML('beforeend', objects);
  }
};

function CharacterInfo(props) {
  let frameImages = null;
  let audioFile = null;
  let viewImages = null;
  useEffect(() => {
    const backdropElement = document.getElementById('backdropElement');
    backdropElement.addEventListener('click', hideCharacterPoup);
  }, []);
  const hideCharacterPoup = () => {
    document.getElementById('backdropElement').style.display = 'none';
    document.getElementById('characterPopup').style.display = 'none';
    const audioPlayer = document.getElementById('audio');
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
  };

  if (
    props.previewAssetObj.commandId === Constant.COMMAND_ENTER_THE_SCENE ||
    props.previewAssetObj.commandId === Constant.COMMAND_CHANGE_EXPRESSION ||
    props.previewAssetObj.commandId === Constant.COMMAND_REPLACE_CHARACTER ||
    props.previewAssetObj.commandId === Constant.COMMAND_REPLACE_OUTFIT ||
    props.previewAssetObj.commandId === Constant.COMMAND_SHOW_OUTFIT ||
    props.previewAssetObj.commandId === Constant.COMMAND_SET_OUTFIT ||
    props.previewAssetObj.commandId === Constant.COMMAND_SHOW_OBJECT ||
    props.previewAssetObj.commandId === Constant.COMMAND_TAP_OBJECT ||
    props.previewAssetObj.commandId === Constant.COMMAND_HIDE_OBJECT
  ) {
    frameImages = EditorService.getImageLayers(
      props.previewAssetObj,
      props.previewAssetObj.commandId
    );
  } else if (
    props.previewAssetObj.commandId === Constant.COMMAND_PLAY_MUSIC ||
    props.previewAssetObj.commandId === Constant.COMMAND_PLAY_SOUND
  ) {
    audioFile = props.previewAssetObj.sprites
      ? props.previewAssetObj.sprites[0].url
      : null;
  } else if (
    props.previewAssetObj.commandId === Constant.COMMAND_MOVE_BACKGROUND ||
    props.previewAssetObj.commandId === Constant.COMMAND_PLACE_VIEW
  ) {
    let staticPreviewWidth =
      AspectRatioConfig.aspectRatioParameters[
        AspectRatioConfig.aspectRatioThreeByFour
      ].staticPreviewWidth;
    let staticPreviewHeight =
      AspectRatioConfig.aspectRatioParameters[
        AspectRatioConfig.aspectRatioThreeByFour
      ].staticPreviewHeight;
    let objectImages = ImageMapper.mapDefaultObjectsOnView(
      props.previewAssetObj.view.viewObjects.slice().reverse(),
      props.previewAssetObj.view.width,
      props.previewAssetObj.view.height,
      staticPreviewWidth,
      staticPreviewHeight,
      0,
      AspectRatioConfig.aspectRatioThreeByFour,
      [],
      true
    );
    viewImages = EditorService.getImageLayers(
      props.previewAssetObj,
      props.previewAssetObj.commandId,
      callbackFunction,
      objectImages
    );
  }

  return (
    <>
      <div className="backdrop" id="backdropElement"></div>
      <div
        className={props.previewAssetObj.commandId == Constant.COMMAND_PLAY_MUSIC || props.previewAssetObj.commandId == Constant.COMMAND_PLAY_SOUND ?`character-popup audio-popup`:`character-popup`}
        id="characterPopup"
        style={{ top: `${props.pageY - 175}px` }}
      >
        <div className="modal-content">
          <Row>
            {frameImages && (
              <Col className="character-composer">
                <PreviewStyle.characterComposer>
                  <div className="character-composer-block bg-light d-flex justify-content-center">
                    <div className="character-composer-frame">
                      {frameImages}
                    </div>
                  </div>
                </PreviewStyle.characterComposer>
              </Col>
            )}
            {viewImages && (
              <Col className="character-composer">
                <PreviewStyle.characterComposer>
                  <div className="character-composer-block bg-light text-center">
                    <div
                      className="character-composer-frame character-composer-frame-view"
                      id="view"
                    >
                      {/* <img alt='' id="view" className="charecter-img"></img> */}
                    </div>
                    {/* <div className="innerLayer"></div> */}
                  </div>
                </PreviewStyle.characterComposer>
                {props.previewAssetObj.view.exportToGallary === true && (
                  <div className="illustration_image">
                    <strong>{props.previewAssetObj.view.name}</strong> image is
                    marked for exportation into the{' '}
                    <b>{props.previewAssetObj.view.characterRoute}</b> gallery
                  </div>
                )}
              </Col>
            )}

            {audioFile && (
              <Col className="character-composer">
                <PreviewStyle.characterComposer>
                  <div>
                    <audio id="audio" controls controlslist="nodownload">
                      <source src={audioFile} />
                    </audio>
                  </div>
                </PreviewStyle.characterComposer>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}
export default CharacterInfo;

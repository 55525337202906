import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import StoryInfoTabs from './StoryInfoTabs';
import * as Constant from '../create-story/constant';
import StoryInfoBottom from './StoryInfoBottom';
import './../style/style.css';

function StoryInfoModal(props) {
    const [activeTabKey, setActiveTabKey] = useState(Constant.FIRST_TAB_KEY);

    const handleActiveTabKey = (key) => {
        setActiveTabKey(key)
    }

    const titleCase = (str) => {
        if (str === undefined) {
            return false;
        }

        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }

        return splitStr.join(' '); 
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            onHide={props.onHideModal}
            backdrop="static"
            dialogClassName="create-new-story"
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="header-text">
                    {titleCase(props.storyDetails.title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {
                props.storyDetails.title === undefined &&
                <Spinner animation="border" variant="primary" className="spinner-class"/>
            }
                {
                props.storyDetails.title !== undefined &&
                <>
                    <StoryInfoTabs activeTabKey = {activeTabKey}
                        storyDetails = {props.storyDetails}
                        genreList = {props.genreList} />
                    <StoryInfoBottom activeTabKey = {activeTabKey}
                        handleActiveTabKey = {handleActiveTabKey}
                        onHideModal = {props.onHideModal} />
                </>
                }   
            </Modal.Body>
        </Modal>
    )
}

export default StoryInfoModal
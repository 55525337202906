import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { LibraryStyle } from './../style/style';
import { Row, Col, Container } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Card } from 'react-bootstrap';
import UploadFile from './UploadFile';
import ListTable from './ListTable';

import * as Constant from './../../../common/constants';
import { useDropzone } from 'react-dropzone';

function ListContent(props) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#E9B7F2',
    backgroundColor: '#bfbfbf',
    opacity: 0.5
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const onDrop = useCallback(
    acceptedFiles => {
      props.setMaxFileError(false);
      if (acceptedFiles.length > 0) {
        if (acceptedFiles.length < 16) {
          props.setShowUploadPopupEnableUpload(true);
          props.setFileNames(acceptedFiles);
        } else {
          props.setMaxFileError(true);
        }
      }
    },
    [props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    noClick: true
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  //   const handleDrop = acceptedFiles =>
  //     props.setFileNames(acceptedFiles);
  // //.map(file => file.name)

  return (
    <LibraryStyle.listMainSection>
      <Container>
        <Row>
          <UploadFile
            setFileNames={props.setFileNames}
            maxFileError={props.maxFileError}
            setMaxFileError={props.setMaxFileError}
            setShowUploadPopupEnableUpload={
              props.setShowUploadPopupEnableUpload
            }
          />
        </Row>
        <Row className="content-head-row">
          <Col xs={12} md={12}>
            <Row>
              <Col xs={12}>
                <div>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {props.maxFileError === false && (
                      <div
                        style={{ fontStyle: 'italic', marginLeft: '-140px' }}
                        className="mt-2"
                      >
                        Or drop directly up to 15 files on this page
                        <br />
                        <div style={{ fontStyle: 'normal', fontSize: '12px' }}>
                          <div>Supported file formats: PNG</div>
                          <div>Album Cover (Exactly 512x819 pixels)</div>
                          <div>Series Background (Exactly 1024x649 pixels)</div>
                          <div>Story title (390x100 pixels max size)</div>
                        </div>
                      </div>
                    )}

                    {props.maxFileError && (
                      <div className="mt-3">
                        <span className="text-danger form-text">
                          {' '}
                          Please only load up to 15 files at a time.
                        </span>
                        <br />
                      </div>
                    )}
                    <Row className="library_list mt-3">
                      <ListTable
                        assetList={props.assetList}
                        loading={props.loading}
                        handleSorting={props.handleSorting}
                        sorting={props.sorting}
                        columns={Constant.ASSET_LIST_COLUMNS_ALL}
                        alwaysEnabledCheckBox={false}
                        showDeleteAssetModal={props.showDeleteAssetModal}
                        hideDeleteAssetModal={props.hideDeleteAssetModal}
                        showModal={props.showModal}
                        handleShowDeleteModal={props.handleShowDeleteModal}
                        handleHideDeleteModal={props.handleHideDeleteModal}
                        handleDeleteAsset={props.handleDeleteAsset}
                        assetInfoList={props.assetInfoList}
                        deleteConfirm={props.deleteConfirm}
                        deleteAsset={props.deleteAsset}
                        handleEditAssetClick={props.handleEditAssetClick}
                        showLockModal={props.showLockModal}
                        IsAssetEdit={props.IsAssetEdit}
                        filters={props.filters}
                        handleChangeActiveCover={props.handleChangeActiveCover}
                      />
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <ListTable
              assetList={props.assetList}
              loading={props.loading}
              handleSorting={props.handleSorting}
              sorting={props.sorting}
              columns={Constant.ASSET_LIST_COLUMNS_ALL}
              alwaysEnabledCheckBox={false}
              showDeleteAssetModal={props.showDeleteAssetModal}
              hideDeleteAssetModal={props.hideDeleteAssetModal}
              showModal={props.showModal}
              handleShowDeleteModal={props.handleShowDeleteModal}
              handleHideDeleteModal={props.handleHideDeleteModal}
              handleDeleteAsset={props.handleDeleteAsset}
              assetInfoList={props.assetInfoList}
              deleteConfirm={props.deleteConfirm}
              deleteAsset={props.deleteAsset}
              handleEditAssetClick={props.handleEditAssetClick}
              showLockModal={props.showLockModal}
              IsAssetEdit={props.IsAssetEdit}
              filters={props.filters}
            /> */}
          </Col>
        </Row>
      </Container>
    </LibraryStyle.listMainSection>
  );
}

export default ListContent;

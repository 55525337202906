import { GenerateElements } from './../../components/player/service/generate-element';
import ReactDOMServer from 'react-dom/server';
import * as Config from './../../config/aspectRatio.config';

const mapDefaultObjectsOnView = (viewObjects, viewWidth, viewHeight, viewPortWidth, viewPortHeight, fadeIn, aspectRatio, blobImages = [], staticPreview = false) => {
    let objectImages = [];
    let objects = '';
    viewObjects.map(object => {
        if (staticPreview === false && object.hidden === true) {
          return;
        }

        let splitSpecs = getOriginalSpecsOfImage(object.asset.specs);
        let originalWidth = splitSpecs[0];
        let originalHeight = splitSpecs[1];

        let imagePath = object.asset.awsPath;
        if (blobImages.hasOwnProperty(object.asset.awsPath)) {
          imagePath = blobImages[object.asset.awsPath];
        }

        let newCoordinates = prepareObjectCoordinate(object, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio);
        let newSize = calculateObjectImageRatio(originalWidth, originalHeight, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio);
        
        let styles = [];
        if (parseInt(fadeIn) > 0) {
          styles['animation'] = `fadeInObj ${fadeIn}s`;
        }

        if (object.hidden === true) {
          styles['opacity'] = `0.5`;
        }

        objectImages.push(GenerateElements.createObjectImage(styles, 
          imagePath, 
          newCoordinates[0], 
          newCoordinates[1], 
          newSize[0], 
          newSize[1],
          object.asset.id,
          staticPreview));
    });
    
      objectImages.map(objectImage => {
        objects += ReactDOMServer.renderToString(objectImage);
      });

      return objects;
}


const mapDefaultObjectsOnBackground = (viewObjects, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio, blobImages = [], staticPreview = false, lastHideObj) => {
  let objectImages = [];
  viewObjects.map(object => {
      if (staticPreview === false && object.hidden === true) {
        return;
      }

      if (object === lastHideObj) {
        return;
      }

      let splitSpecs = getOriginalSpecsOfImage(object.asset.specs);
      let originalWidth = splitSpecs[0];
      let originalHeight = splitSpecs[1];

      let imagePath = object.asset.awsPath;
      if (blobImages.hasOwnProperty(object.asset.awsPath)) {
        imagePath = blobImages[object.asset.awsPath];
      }

      let newCoordinates = prepareObjectCoordinate(object, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio, true);
      let newSize = calculateObjectImageRatio(originalWidth, originalHeight, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio);
      
      objectImages.push([
        newCoordinates[0], 
        newCoordinates[1], 
        newSize[0], 
        newSize[1]
      ]);
  });

  return objectImages;
}

const mapHiddenObjectsOnView = (viewObjects, styles, viewWidth, viewHeight, viewPortWidth, viewPortHeight, fadeIn, aspectRatio, blobImages = []) => {
  let objectImages = [];
    let objects = '';
    viewObjects.map(object => {
        let splitSpecs = getOriginalSpecsOfImage(object.asset.specs);
        let originalWidth = splitSpecs[0];
        let originalHeight = splitSpecs[1];

        let imagePath = object.asset.awsPath;
        if (blobImages.hasOwnProperty(object.asset.awsPath)) {
          imagePath = blobImages[object.asset.awsPath];
        }

        let newCoordinates = prepareObjectCoordinate(object, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio);
        let newSize = calculateObjectImageRatio(originalWidth, originalHeight, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio);
        
        objectImages.push(GenerateElements.createObjectImage(styles, 
          imagePath, 
          newCoordinates[0], 
          newCoordinates[1], 
          newSize[0], 
          newSize[1],
          object.asset.id));
    });
    
      objectImages.map(objectImage => {
        objects += ReactDOMServer.renderToString(objectImage);
      });

      return objects;
}

const prepareBackgroundCoordinate = (object, originalViewWidth, viewPortWidth, aspectRatio = Config.aspectRatioThreeByFour, widthDiff = 0) => {
  let zoomFactor = originalViewWidth / viewPortWidth;

  let frontendX = 0;
  let frontendY = 0;

  frontendX = object.xcoordinate / zoomFactor;
  frontendY = object.ycoordinate / zoomFactor;

  if (aspectRatio === Config.aspectRatioNineByNineteen) {
    frontendX = (object.xcoordinate + (widthDiff / 2)) / zoomFactor;
  }

  return [frontendX, frontendY];
}

const prepareBackgroundYCoordinate = (object, originalViewHeight, viewPortWidth) => {
  let zoomFactor = originalViewHeight / viewPortWidth;

  let frontendX = 0;
  let frontendY = 0;

  frontendX = object.xcoordinate / zoomFactor;
  frontendY = object.ycoordinate / zoomFactor;

  return [frontendX, frontendY];
}

const prepareObjectCoordinate = (object, originalViewWidth, originalViewHeight, viewPortWidth, viewPortHeight, aspectRatio, fuseImage = false) => {
  let zoomFactor = originalViewWidth / viewPortWidth;
  let frontendX = 0;
  let frontendY = 0;

  frontendX = object.xcoordinate / zoomFactor;
  frontendY = object.ycoordinate / zoomFactor;

  if (aspectRatio === Config.aspectRatioNineByNineteen) {
    let zoomFactorH = originalViewHeight / viewPortHeight;
    frontendY = object.ycoordinate / zoomFactorH;
    if (fuseImage === true) {
      frontendX = object.xcoordinate / zoomFactor;
    } else {
      frontendX = frontendX - 60;
    }
  }

  return [frontendX, frontendY];
}

const prepareCoordinatesByViewPort = (objects, originalViewWidth, originalViewHeight, viewPortWidth, viewPortHeight, toBackend = true) => {
  
  let newObjects = objects.map(object => {
    let frontendX = 0;
    let frontendY = 0;
    let retObj = Object.assign({}, object);
    if (toBackend === true) {
      let zoomFactorW = originalViewWidth / viewPortWidth;
      let zoomFactorH = originalViewHeight / viewPortHeight;

      frontendX = zoomFactorW * object.xcoordinate;
      frontendY = zoomFactorH * object.ycoordinate;
    } else {
      let zoomFactorW = originalViewWidth / viewPortWidth;
      let zoomFactorH = originalViewHeight / viewPortHeight;

      frontendX = object.xcoordinate / zoomFactorW;
      frontendY = object.ycoordinate / zoomFactorH;
    }

    retObj.xcoordinate = frontendX;
    retObj.ycoordinate = frontendY;

    return retObj;
  });

  return newObjects;
}

const calculateObjectImageRatio = (objectWidth, objectHeight, viewWidth, viewHeight, viewPortWidth, viewPortHeight, aspectRatio) => {
  let zfw = viewPortWidth / viewWidth;
  let zfh = viewPortHeight / viewHeight;
  
  let retWidth = objectWidth * zfw;
  let retHeight = objectHeight * zfh;
  
  return [retWidth, retHeight];
}

const getOriginalSpecsOfImage = (originalSpecs) => {
  let splitSpecs = originalSpecs.split('px')[0].trim().split('x');
  let originalWidth = splitSpecs[0];
  let originalHeight = splitSpecs[1];

  return [originalWidth, originalHeight];
}

const objectCoordinate = (object) => {
  let zoomW = Config.cropPanelWidth / Config.aspectRatioParameters[Config.aspectRatioThreeByFour].libraryViewWidth;
  let zoomH = Config.cropPanelHeight / Config.aspectRatioParameters[Config.aspectRatioThreeByFour].libraryViewHeight;

  let x = (object.xcoordinate * zoomW);
  let y = (object.ycoordinate * zoomH);

  return [x, y];
}

export const ImageMapper = {
    mapDefaultObjectsOnView,
    prepareCoordinatesByViewPort,
    calculateObjectImageRatio,
    mapHiddenObjectsOnView,
    getOriginalSpecsOfImage,
    prepareBackgroundCoordinate,
    prepareBackgroundYCoordinate,
    prepareObjectCoordinate,
    mapDefaultObjectsOnBackground,
    objectCoordinate
}
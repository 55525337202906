import styled from 'styled-components';
import { colors } from './../../common/styles/variables';

export const writeStoryWrapper = styled.div`
  background: #f0eeee;
  .script-panel-container {
    // background: #fff;
    background:#3A2C61;
    opacity: 1;
    display: inline-block;
    width: 100%;
    height:auto;
    margin-top: -60px !important;
    border-radius: 6px 6px 0 0;
  }

  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
    !importantheight: 100%;
  }
  .col-2 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .col-8 {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .right-rail {
    background-image: url(../../images/new_image/right-rail.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .left-rail {
    background-image: url(../../images/new_image/left-rail.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const writerStoryAngleLeft = styled.div`
  text-align: center;
  cursor: pointer;
  font-size: 40px;
  font-weight: bold;
  text-align: end;
  // background: #E6E4E4;
  background: #F0EEF8;
  padding-right: 16px;
  color: ${colors.shades_shade200};
  display: ${props => props.commandPanelToggle || props.commandPanelToggle};
`;

export const writeStoryCommandPanel = styled.div`
  width: 100%;
  transition: all 1s linear;
  opacity: ${props => (props.commandPanelToggle === 'block' ? 1 : 0)};
  display: ${props => props.commandPanelToggle || props.commandPanelToggle};
`;

export const writeStoryFlowchart = styled.div`
  width: 100%;
  background: ${props => props.backgroundColor || props.backgroundColor} 0% 0%
    no-repeat padding-box;
  opacity: 1;

  padding-right: 0px;
  padding-left: 0px;
`;

export const writeStoryShow = styled.div`
  /* width:100%;
    margin-top: -60px;
    border-radius: 6px;
    margin-bottom:5px;
    height: calc(100vh - 90px);
    background: #ffffff 0% 0% no-repeat padding-box;*/

  .btnPreview {
    position: absolute;
    right: 30px;
    top: 38px;
  }
  .btnPreview .btn-snacktool-confetti {
    display: flex;
    align-items: center;
  }
  .btnPreview .fa-play-circle {
    font-size: 20px;
    margin-left: 8px;
  }
`;

import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import * as translations from './../translations';
import * as Constant from './../constant';

function AddUIElementBottom(props) {
    let disabledSave = props.showClearFile ? '' : 'disabled';
    return (
        <Row lg={12} className="mt-20">
            <Col lg={6} className="footer-left-button p-0">
                {
                    props.activeTabKey === Constant.FIRST_TAB_KEY && props.showClearFile === true &&
                    <> 
                        <i className="fa fa-arrow-left" />&nbsp;
                        <span onClick={props.handleClearFile}>
                            {translations.BACK_TO_UPLOAD_FILE}
                        </span>
                    </>
                }
            </Col>
            <Col lg={6} className="text-right footer-right-button p-0">
                {
                    props.activeTabKey === Constant.FIRST_TAB_KEY &&
                    <Button className={`btn-snacktool btn-snacktool-golden`}
                        onClick={() => props.handleSaveImage()}
                        disabled = {disabledSave}>
                        {translations.SAVE_NEW_IMAGE}
                    </Button>
                }
            </Col>
        </Row>
    );
}

export default AddUIElementBottom
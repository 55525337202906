import React from 'react';
import * as commandPanelStyle from './../../command-panel/command-panel-style';

function ListHead(props) {
    return (
      <commandPanelStyle.Header>
          <div className="panel-header">
              <div className="story-heading">
                  <div className='storyName'>
                    {props.storyTitle ? props.storyTitle : ''}
                  </div>
                  <div className='sceneName'>
                  Game Interface
                  </div>
                  <div className="preview-btn-wrapper">
                    <button className="btn-snacktool btn-snacktool-golden" onClick={() => props.setShowUIElementPreview(true)}>Preview</button>
                  </div>
              </div>
          </div>
      </commandPanelStyle.Header>
    );
}

export default ListHead
import React from 'react';
import { Modal,  Button } from 'react-bootstrap';
import { DeleteStyle } from './style';
import * as Translations from './translation';


function Success(props) {
  let title = '';
  let subTitle = '';

  switch (props.storyStatus) {

    case 'In Progress':
    case 'In Revision':
      title = Translations.TITLE_SUCCESS_REVIEW;
      subTitle = Translations.SUBTITLE_SUCCESS_REVIEW;

      break;
    case 'Approved':
      title = Translations.TITLE_SUCCESS_SUBMIT_PUBLISH;
      subTitle = Translations.SUBTITLE_SUCCESS_SUBMIT_PUBLISH;
      break;
    case 'UpdateApproved':
      title = Translations.TITLE_SUCCESS_UPDATE_APPROVED;
      subTitle = Translations.SUBTITLE_SUCCESS_UPDATE_APPROVED;

      break;
    case 'UpdateRejected':
      title = Translations.TITLE_SUCCESS_UPDATE_REJECTED;
      subTitle = Translations.SUBTITLE_SUCCESS_UPDATE_REJECTED;

      break;
    case 'UpdatePublished':
      title = Translations.TITLE_SUCCESS_UPDATE_PUBLISHED;
      subTitle = Translations.SUBTITLE_SUCCESS_UPDATE_PUBLISHED;
      break
    case 'undoReview':
      title = Translations.TITLE_SUCCESS_UNDO_REVIEW;
      subTitle = Translations.SUBTITLE_SUCCESS_UNDO_REVIEW;

      break;




    default:
      break;
  }
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="confirmModal success-review"
      className="modalbg"
      size="md"
      show={props.showModal}
      onHide={props.handleHideReviewModal}
    >
      <Modal.Body>
        <DeleteStyle.addAssetMessageBox>
          <DeleteStyle.successAssetContent>
            <div className="success-icon mb-3">
              <img alt="" className="mediumIcon" src="/images/icons/ic_done_24px.svg" />
            </div>
            <div data-testid="success-label" className="submit-asset-label">{title}</div>
            <div data-testid="success-note" className="text-muted submit-asset-subtitle ">{subTitle}</div>
            <div className="marginTop60">
              <Button data-testid="success-btn" onClick={props.handleHideReviewModal} className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
            </div>
          </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}
export default Success;
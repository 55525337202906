import * as Config from './../../config/app.config';
import * as Constant from './../../common/constants';

export const getBackgroundImagesApi = payload => {
  return fetch(
    `${Config.endpoint}/api/stories/${payload.storyId}/assets/type/${
      Constant.ASSET_TYPES_IMAGES[0]
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${payload.authToken}`
      }
    }
  )
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const saveView = payload => {
  let method = payload.data.id != undefined ? 'PUT' : 'POST';
  let url =
    payload.data.id == undefined
      ? `${Config.endpoint}/api/stories/${payload.storyId}/views`
      : `${Config.endpoint}/api/stories/${payload.storyId}/views/${payload.data.id}`;

  return fetch(url, {
    method: method,
    body: JSON.stringify(payload.data),
    headers: {
      Authorization: `Bearer ${payload.authToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const getViews = payload => {
  return fetch(`${Config.endpoint}/api/stories/${payload.storyId}/views`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

export const editable_flags = payload => {
  return fetch(`${Config.endpoint}/api/stories/${payload.storyId}/views/${payload.viewId}/editable`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};



export const otherViewsAPI = payload => {
  return fetch(`${Config.endpoint}/api/stories/${payload.storyId}/views/assets/${payload.backgroundId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload.authToken}`
    }
  })
    .then(res => res.json())
    .then(resp => resp)
    .catch(err => {
      throw err;
    });
};

import { editable_flags } from '../saga/api/place-background-api';
import * as ActionTypes from './place-background-action-types';

export function getBackgroundImages(storyId, authToken) {
  return {
    type: ActionTypes.GET_BACKGROUND_IMAGES,
    authToken: authToken,
    storyId: storyId
  };
}

export function getBackgroundImagesSuccess(imageList) {
  return {
    type: ActionTypes.GET_BACKGROUND_IMAGES_SUCCESS,
    imageList: imageList
  };
}

export function getBackgroundImagesFail() {
  return {
    type: ActionTypes.GET_BACKGROUND_IMAGES_FAIL
  };
}

export function saveForLater(data, storyId, authToken) {
  return {
    type: ActionTypes.SAVE_VIEW_LATER,
    data: data,
    storyId: storyId,
    authToken: authToken
  };
}

export function setActiveKey(activeKey) {
  return {
    type: ActionTypes.SET_ACTIVE_KEY,
    activeKey: activeKey
  };
}

export function setBackgroundProperties(key, value) {
  return {
    type: ActionTypes.SET_BACKGROUND_PROPERTIES,
    key: key,
    value: value
  };
}

export function resetPlaceBackground() {
  return {
    type: ActionTypes.SET_RESET_BACKGROUND_PROPERTIES
  };
}

export function getViews(storyId, authToken) {
  return {
    type: ActionTypes.GET_VIEWS,
    storyId: storyId,
    authToken: authToken
  };
}

export function getViewsSuccess(views) {
  return {
    type: ActionTypes.GET_VIEWS_SUCCESS,
    views: views
  };
}

export function getViewsFail() {
  return {
    type: ActionTypes.GET_VIEWS_FAIL
  };
}

export function getViewsEditable(storyId, viewId, authToken) {
  return {
    type: ActionTypes.GET_VIEWS_EDITABLE,
    storyId: storyId,
    viewId: viewId,
    authToken: authToken
  };
}

export function getViewsEditableSuccess(payload) {
  let editable = false;
  if (payload && payload.length > 0) {
    editable = true;
  }
  return {
    type: ActionTypes.GET_VIEWS_EDITABLE_SUCCESS,
    editableFlag: editable,
    editableData: payload
  };
}

export function getViewsEditableFail() {
  return {
    type: ActionTypes.GET_VIEWS_EDITABLE_FAIL
  };
}

export function addObject(object) {
  return {
    type: ActionTypes.ADD_OBJECT,
    payload: object
  };
}

export function reOrderObject(object) {
  return {
    type: ActionTypes.REORDER_OBJECT,
    payload: object
  };
}

export function setObject(objectList) {
  return {
    type: ActionTypes.SET_OBJECT,
    payload: objectList
  };
}

export function setSaveViewObject(viewObject, fadeIntoSeconds) {
  return {
    type: ActionTypes.SET_SAVE_VIEW_OBJECT,
    viewObject: viewObject,
    fadeIntoSeconds: fadeIntoSeconds
  };
}

export function resetSaveViewObject() {
  return {
    type: ActionTypes.RESET_SAVE_VIEW_OBJECT
  };
}

export function setAutoSaveFlag(flag) {
  return {
    type: ActionTypes.SET_AUTOSAVE_FLAG,
    payload: flag
  };
}

export function resetAutoSaveFlag() {
  return {
    type: ActionTypes.RESET_AUTOSAVE_FLAG
  };
}

export function updateCollaboratorModal(flagEditable) {
  return {
    type: ActionTypes.GET_VIEWS_COLLABORATOR_MODULE_FLAG,
    flagEditable: flagEditable
  };
}

export function getOtherViewsFromId(authToken, storyId, backgroundId) {
  return {
    type: ActionTypes.GET_OTHER_CREATED_VIEWS,
    authToken: authToken,
    storyId: storyId,
    backgroundId: backgroundId
  };
}

export function getOtherViewsFromIdFail() {
  return {
    type: ActionTypes.GET_OTHER_CREATED_VIEWS_FAIL
  };
}

export function getOtherViewsFromIdSuccess(payload) {
  return {
    type: ActionTypes.GET_OTHER_CREATED_VIEWS_SUCCESS,
    payload: payload
  };
}

import styled from 'styled-components';
import { colors } from '../../common/styles/variables';
 


export const LoginMain = styled.div`
height:100%;
width: 100%;
 
  .container {
    
    height:100vh;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .col-12{
    height:100vh
  }
  .col-6 {
    max-width: 100%;
  }
  .row-100{
    height:100%;
  }
  .login-bg{
    width: 100%;
    height: inherit;
    background-size: cover;
    background-position: 0 0;
    background-image: url(/images/login_new.png);
    background-repeat: no-repeat;
  }
  .login-wrapper{
    height: 100vh;
    display: flex;
    ALIGN-ITEMS: CENTER;
    JUSTIFY-CONTENT: CENTER;
    width: 100%;
    background:#fff;
    position:relative;
    .login-bottom-text{
      font-size:14px;
      color:#707070;
      position: absolute;
      bottom: 40px;
      left: 80px;
      right: 179px;
      a{
        color:#157FFB;
        text-decoration: underline;
      }
    }
  }
`;

export const LoginLogo = styled.div`
  position:absolute;
  right:-50px;
  top:15%;
  z-index:1;
`;

export const Login = styled.div`
  position:relative;
  height:100vh;
`;
export const LoginTitle = styled.div`
  color: ${colors.color_black};
  font-size: 32px;
`;

export const LoginForm = styled.div`

`;

export const LoginContainer = styled.div`
  border: none;
  width:100%;
  padding: 0 179px 0 80px;
`;

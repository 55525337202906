import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import * as ActionTypes from '../../actions/action-types';
import CreateAccount from '../../components/accounts/create-account';
import * as styled from './style';
import ListAccounts from '../../components/accounts/list-accounts';
import ConfirmDeleteAccount from '../../components/accounts/confirm-delete-account';

import SuccessDeleteAccount from '../../components/accounts/success-delete';


import { commonServices } from './../../common/helper/utils';
import AdminMenu from '../../components/admin-menu/admin-menu';
import * as LibraryActions from './../../actions/library-action';

const Accounts = props => {
  const [show, setShow] = useState(false);
  const [showconfirm, setShowConfirm] = useState(false);

  const [account, setAccount] = useState({
    email: '',
    username: '',
    roleId: 1
  });



  const [enable, setEnableLogin] = useState(true);
  const [acctdelete, setAccountDelete] = useState([]);
  const [validated, setValidated] = useState(false);
  const [showCustomError, setCustomError] = useState(false);
  const [sorting, setSorting] = useState('asc');
  const [accStatus, setAccStatus] = useState(null);

  const [showDelteModal, setShowDelteModal] = useState(false);

  let userCreated = props.userCreated;
  let userDeactivate = props.userDeactivate;
  let token = props.authToken;


  // props.setSelectedMenu('users');

  
  useEffect(() => {
    props.resetPermission()

}, []);
  useEffect(() => {
    props.setSelectedMenu('users');

}, [props.userCreated]);

  const handleClose = () => {
    setShow(false);
    userCreated = false;
    setAccount({ email: '', username: '', roleId: 1 });
    setValidated(false);
    props.setEdit(false);
    props.fetchAccountList(token);
    setEnableLogin(true);
  };

  const handleCloseConfirm = () => {
    userDeactivate = false;
    props.fetchAccountList(token);
  };

  const handleShow = () => {
    setShow(true);
    props.setEdit(false);
    userCreated = false;
  };

  const handleDeleteUser = (e, userSelected,flag) => {
    
    setAccountDelete(userSelected);
    setAccStatus(flag);
    props.deleteUser(userSelected.id, token,flag);
    
  };

  const handleDeleteAccount = (e, userSelected) => {
    
    setAccountDelete(userSelected);
    setShowDelteModal(true);
   
  };
  const onCloseDeleteSucces = (e) => {

    setAccountDelete([]);
   props.hideDeleteModals();
   props.fetchAccountList(token);
   
  };

  
  const onCancelAccountDeleteClick = (e, userSelected) => {
    setShowDelteModal(false);
   
  };
  const onConfirmAccountDelete = (e, userSelected) => {
    
    setAccountDelete(userSelected);
    setShowDelteModal(false);
    props.deleteAccount(userSelected.id, token);
    
   
  };

  const handleEditAccount = (e, userSelected) => {
   
    props.setEdit(true);
    setEnableLogin(false);
    setAccountDelete(userSelected);
    setAccount({ email: userSelected.email, username: userSelected.username, roleId: userSelected.roles[0].id });
    setShow(true);
   
  };

  
  
  
  const handleFormChange = event => {

    setCustomError(false);
    const form = event.currentTarget;
   
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAccount({
      ...account,
      [event.target.name]: event.target.value
    });

    
    if(event.target.name==='email'){
    if(account.email === '' || account.username === '' || new RegExp('^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$').test(event.target.value) !== true)
    {
     setEnableLogin(true);
    }else{
      setEnableLogin(false);
    }
  }else{
    if (event.target.value !== '') {
      if (account.email !== '' && account.username !== '' && new RegExp('^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$').test(account.email) === true)
        setEnableLogin(false);
    } else {
      setEnableLogin(true);
    }
  }

    setValidated(true);
  };

  const handleAccountCreate = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setEnableLogin(true)   
      props.addNewUser(account, token,props.isEditAccount,acctdelete?acctdelete.id:null);
      setCustomError(props.error ? true : false);
    }

    setValidated(true);
  };

  const handleSorting = () => {
      setSorting(sorting === 'asc' ? 'desc' : 'asc');
  }

  return (
    <div>
       {props.tokenData && <AdminMenu
                selectedMenu = {props.selectedMenu} 
                setSelectedMenu = {props.setSelectedMenu}
                roleAdmin = {props.tokenData.roles}
            />}
   
    <styled.ListAccountWrapper>
      <styled.Users>
        <styled.Heading>
          <styled.Heading>WRITE FICTIF accounts</styled.Heading>
        </styled.Heading>
        <styled.MainSection>
          <CreateAccount
            show = {show}
            validated = {validated}
            email = {account.email}
            username = {account.username}
            role = {account.roleId}
            isUserCreated = {userCreated}
            error = {props.error}
            showError = {showCustomError}
            enable = {enable}
            onCreateAccountClick = {handleShow}
            onCloseClick = {handleClose}
            onSendClick = {handleAccountCreate}
            onFormChange = {handleFormChange}
            isEditAccount={props.isEditAccount}
            accountCreationLoader = {props.accountCreationLoader}
          />
          {token && (
            <ListAccounts
              show = {showconfirm}
              onHideClcik = {handleCloseConfirm}
              fetchAccountList = {props.fetchAccountList}
              accountList = {commonServices.sortList(props.accountList, sorting)}
              loading = {props.accountListLoading}
              authToken = {token}
              userDeactivate = {userDeactivate}
              onDeleteUser = {(e, userSelected,flag) =>
                handleDeleteUser(e, userSelected,flag)
              }
              acctdelete = {acctdelete}
              handleSorting = {handleSorting}
              sorting = {sorting}
              accStatus={accStatus}
              onDeleteAccount={handleDeleteAccount}
              onEditAccount={handleEditAccount}
            />
          )}
          {
            showDelteModal && 
            <ConfirmDeleteAccount
show={showDelteModal}
onCancelAccountDeleteClick={onCancelAccountDeleteClick}
onConfirmAccountDelete={onConfirmAccountDelete}
acct = {acctdelete}
            />
          }
          {
            props.showUserDeleteSuccess && 
            <SuccessDeleteAccount
show={props.showUserDeleteSuccess}
onCloseDeleteSucces={onCloseDeleteSucces}
 
acct = {acctdelete}
            />
          }

          
        </styled.MainSection>
      </styled.Users>
    </styled.ListAccountWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.AccountReducer.users,
    userCreated: state.AccountReducer.userCreated,
    userDeactivate: state.AccountReducer.userDeactivate,
    authToken: state.LoginReducer.authToken,
    accountList: state.AccountReducer.accountList,
    accountListLoading: state.AccountReducer.loading,
    error: state.AccountReducer.error,
    selectedMenu: state.LibraryReducer.selectedMenu,
    tokenData: state.LoginReducer.tokenData,
    isEditAccount:state.AccountReducer.isEditAccount,
    showUserDeleteSuccess:state.AccountReducer.showUserDeleteSuccess,
    accountCreationLoader: state.AccountReducer.accountCreationLoader

    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedMenu: (menuName) => dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),


    
    setEdit: (isEdit) =>
    dispatch({
      type: ActionTypes.SET_EDIT_ACCOUNT,
      isEdit:isEdit
      
    }),
   
    addNewUser: (account, token,isEditAccount,userId) =>
      dispatch({
        type: ActionTypes.CREATE_ACCOUNT_DETAILS,
        payload: account,
        token: token,
        isEditAccount:isEditAccount,
        userId:userId

      }),
    fetchAccountList: authToken =>
      dispatch({
        type: ActionTypes.FETCH_USER_ACCOUNTS,
        authToken: authToken
      }),
    deleteUser: (userId, token,flag) =>
      dispatch({
        type: ActionTypes.DEACTIVATE_ACCOUNT_DETAILS,
        payload: userId,
        token: token,
        status:flag
      }),
      deleteAccount: (userId, token) =>
      dispatch({
        type: ActionTypes.DELETE_ACCOUNT_DETAILS,
        payload: userId,
        token: token,
     }),
       
     hideDeleteModals: () =>
    dispatch({
      type: ActionTypes.HIDE_DELETE_MODAL,
       
      
    }),

    resetPermission: (flag) => dispatch({
      type: ActionTypes.SET_PANEL_PERMISSION_FALSE,

  }),
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);

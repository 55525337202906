import styled from 'styled-components';
import { colors } from '../../../../../../common/styles/variables';

export const Header = styled.div`
  background: ${colors.color_white};
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid ${colors['command-header']} .panel-header {
    display: flex;
    align-items: center;
    height: 60px;
    position: relative;
    .back-arrow {
      border-right: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      width: 76px;
      justify-content: center;
      height: 60px;
      margin-right: 16px;

      cursor:pointer .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
    }
    .lock-block {
      text-align: left;

      border: 1px solid grey;
      border-radius: 16px;
      background-color: #bfbfbf;
      color: #fff;
      padding: 6px;
      font-size: 12px;
    }
    .lock-container {
      width: 335px;
    }
    .edit-block {
      padding: 0 24px;
      text-align: right;
      width: 17%;
      .fa-angle-left {
        font-size: 30px;
        color: #d6d6d6;
      }
      .selected-line-number {
        font-size: 11px;
        white-space: nowrap;
        color: #acacac;
        letter-spacing: 1.1px;
        text-transform: uppercase;
      }
    }
    .footer-left {
      padding: 5px 12px 12px 12px;
    }
    .footer-right {
      padding: 5px 12px 12px 12px;
    }
    .story-heading {
      width: 33%;
    }
    .storyName {
      text-align: left;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 1.1px;
      color: ${colors.scorpion_gray};
      text-transform: uppercase;
      opacity: 1;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .sceneName {
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0;
      color: ${colors.color_black};
      opacity: 1;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .add-comment-line {
      position: absolute;
      right: 10px;
      bottom: -15px;
      z-index: 1;
      button {
        border-radius: 20px;
        width: 224px;
      }
    }
  }
`;
export const Body = styled.div`
  padding: 0 !important;
  opacity: 1;
  display: inline-block;
  width: 100%;
  overflow: auto;

  .align-center{
    text-align:center;
    color:#A0A0A0 !important;
    font: 12px !important;
    text-transform: uppercase;
  }

  .help-icon-variable{
    color: #157FFB;
    text-decoration: underline;
    cursor:help;
  }
  .help-div{
    display: inline-flex;
    vertical-align: middle;
  }
  .tooltip-top-change{
    top:10px !important;
  }

  .scene-name{
    cursor: pointer;
    font-weight: bold;
    }
    .scene-name:hover{
      text-decoration: underline;
    }
    .bgTr{
      background-color: #F5F5FA !important;
    }
  .tableheigth{
    height: 300px; !important
  }

  .empty-gutter{
    background:#f0f0f0;
    width:60px;
    height:10px;
  }

  .ace_gutter-layer, .ace_gutter{
    width:60px !important;
    background-color: rgb(132, 82, 232)
  }

  .ace_gutter-cell.ace_error{
    background:url(/images/icons/error_icon.svg) no-repeat !important;
    left:10px;
  }

  .ace_gutter-cell.ace_warning{
    background:url(/images/icons/warning_icon.svg) no-repeat !important;
    left:10px;
  }
  .ace_scroller{
    left:59.8027px!important;
  }
  
  .ace_editor{
    width:100% !important;
    height: inherit !important;
  }
  .ace_gutter-cell.ace_info{
    background:url(/images/icons/preview.svg) no-repeat !important;
    background-size: 30% !important;
  }
  .emptyInput{
    background-color:yellow;
  }
  .myMarker {
    position: absolute;
    border-bottom: 1px solid red;
    z-index: 20
  }

  .ace_spellMarker{
      border-bottom: 1px solid red;
      background-color:#FDEEEF;
    }
  
  .ace_block {
    color: ${colors.primary_purple}
  }
}
  .selected{
    background:#F6E0F0;
    select,input{
        background:#F6E0F0;;
    }
  }

  select{
    border: 0;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
  }

  input{
    border:0;
    margin: 0 4px;
    &:focus{
      outline:0;
    }
    &.backgroundBorder{
      width:30px;
    }
  }

  .characterBorder,.mediaBorder,.backgroundBorder{
    margin: 0 4px;
  }

.square {
  min-width: 20px;
  text-align: right;
  display: inline-block;
}

.decisionText{
  text-align:left;
}
.bodyAddText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#F6E0F0;
  font-weight:bold;
  display:flex;
  align-items:center;
  select,input{
        background:#F6E0F0;;
    }
   
}
.blockAddSquare{
  margin: 0px 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: #F6E0F0;
  
}

.ace_blockSquare{
    color: #FFF;
}

.ace_effectSquare{
  color: ${colors.light_blue_effect}
}
 
.dialogueBorder{
  position: absolute;
    border-bottom: 1px solid #F9A602;
    color: yellow;
    z-index: 20

}
.commandTextBorder{
  position: absolute;
  border-bottom: 1px solid  ${colors.color_white};
  z-index: 20
}

.commandSquare{
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
    color: ${colors.dark_yellow};
}
.decisionSquare {
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: ${colors.primary_purple};
  
}
.backgroundSquare {
  margin: 0 10px 0 20px;
  min-width: 20px;
  text-align: right;
  display: inline-block;
  color: ${colors.very_dark_cyan};
}
.ace_viewSquare{
  // color: #157ffb;
  color: #FFFFFF;
}
.ace_tooltip{
  background: #43425D;
  box-shadow: 0px 0px 1px #000029 !important;
  opacity: 1;
  color:#fff;
  font-size:12px;
  padding:10px;
  border-radius:5px;
  font-family:Roboto !important;
  letter-spacing: 0.4px;
  border:none;
}

.ace_dialogSqaure{
  // color:#157ffb;
  color:#FFFFFF;
  }
.ace_mediaSquare {
  
  // color: ${colors.dark_lime_green};
  color: #FFFFFF;
}
.ace_characterSquare {
  // color: ${colors.very_dark_pink}
  color: #FFFFFF;
}
.bodyText{
  margin-top:7px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  align-items:center;
}
.bodyTextBlock{
  margin-top:30px;
  font-family: Roboto;
  font-size:13px;
  color:#303030;
  display:flex;
  // &:hover{
  //   .blockSquare{
  //     color:${colors.color_white};
  //   }
  //   background:${colors.primary_purple};
  //   color:${colors.color_white};
  //   .blockBorder{
  //       background:${colors.primary_purple};
  //       color:${colors.color_white};
  //   }
  // }

}
.bodyTextBlock,.bodyText{
  ${'' /* &:focus{
    outline:0;
  } */}
  &:hover{
    background:#F6E0F0;;
    select,input{
        background:#F6E0F0;;
    }
  }
}
.bodyBlockContent{

}
.blockBorder{
  border-bottom: 2px solid ${colors.primary_purple};
  border-top:none;
  border-left:none;
  border-right:none;
  text-align:center;
  min-width: 20px;
  max-width: 100px;
}
.commandBg{
  // background-color:${colors.primary_yellow}
}
.commandBorder{
  border-bottom: 2px solid ${colors.primary_yellow};
  border-top:none;
  border-left:none;
  border-right:none;
}
.decisionBorder {
  border-bottom: 2px solid ${colors.primary_purple};
}
.backgroundBorder {
  border-bottom: 2px solid ${colors.very_soft_cyan};
  text-align:center;
}
.mediaBorder {
  border-bottom: 2px solid ${colors.moderate_lime_green};
  
}
.characterBorder {
  border-bottom: 2px solid ${colors.very_soft_pink};
  text-align: center;
  
    
}

.enterScene{
  input{
    width: 4%;
  }
  select{
    width: 8%;
  }
}

.sceneError {
  margin-bottom:30px;
  color : red;
}

.variable-window-wrapper{
  background:#F0F0F0;
  // background:#6428E1 !important;
  padding:20px 40px 5px 60px;
  font-family:PT Sans;
  .table{
    font-family: PT Sans;
    font-size: 14px;
    font-weight: normal;
    .thead-dark{
      position:relative;
      display: block;
      width:100%;
  tr{
      width:100%;
      display:table;
      th{
        // background-color:#D8D8D8;
        border: none;
        padding: 1rem;
        // color: #606060;
        background-color:#3A2C61;
        color: #FFFFFF;
        font-weight: normal;
        font-size: 13px;
        width:30%;
        &:last-child{
          width:40%;
        }
      }
    }
    }
    tbody{
      display: block; position:relative;
      width:100%; overflow-y:scroll;
      max-height:250px;
      tr {
        width:100%;
        display:table;
      td{
        color: #343a40!important;
        width:30%;
        vertical-align:middle;
        padding: 7px 1rem;
        &:last-child{
          width:40%;
        }
        .info-icon {
          background:url('/images/icons/info.svg') no-repeat;
          width:14px;
          height : 14px;
          display:inline-block;
          
        }
        .info-icon:hover{
          background:url('/images/icons/info-active.svg') no-repeat;
        }
        .form-group{
          margin-bottom:0;
        }
      }
      :first-child td{
        border-top:none;
      }
    }
    }
    
    .width10{
      width: 8% !important;
    }

    .red{
      color: #FF0000 !important;
    }

    .width15{
      width: 10% !important;
    }

    .gotoline{
      color:#530acd !important;
      font-weight: bold;
      cursor:pointer;
    }

    .boldFont{
      font-weight: bold;
    }
    
  }
  .save-btn{
    background:#BC45AD;
    font-family:Roboto !important;
    font-weight:bold !important;
    font-size:14px !important;
    min-width:90px !important;
    // color:#fff !important;
    border: none;
  }
  .label-text{
    font-weight:bold;
  }
  .refresh-icon{
    cursor:pointer;
    width:24px;
    height:24px;
  }
  .switch-flip {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-right: 40px;
  }
  
  .switch-flip input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-flip {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 40px;
  }
  
  .slider-flip:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider-flip {
    background-color: #2196F3;
  }
  
  input:focus + .slider-flip {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider-flip:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  /* Rounded sliders */
  .slider-flip.round {
    border-radius: 34px;
  }
  
  .slider-flip.round:before {
    border-radius: 50%;
  }
  .variables-btn{
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
    color: #530ACD;
    box-shadow: 0px 0px 4px #0000001A;
    border: none;
    .variable-icon{
      background:url(/images/icons/call_to_action.svg) no-repeat !important;
      width:18px;
      height:22px;
      margin-right:8px;
      transform: rotate(90deg);
      display: inline-block;

    }
  }
}
//create-scene styling
.create-scene-container{
  margin: 0 25px;

    form{
      width:75%;
      display:flex;
      justify-content:flex-start;
      align-items:flex-end;
      margin-bottom:10px;
      width:75%;
      .form-group{
        margin-right:16px;
        margin-bottom:0;
        .form-label{
          color: #303030;
          font-family:PT Sans !important;
          font-size:14px;
          font-family:bold;
          margin:0;
        }
        .form-control{
          background: #FFFFFF;
          border: 2px solid #D6D6D6;
          border-radius: 4px;
          height:40px;
          margin:0;
          width:340px;
        }
      }
    }
  
  .drag-drop-table{
    width:100%;
    background:#F5F5F5;
    tr{

      th{
        color: #606060;
        font-family:PT Sans;
        font-size:11px;
        font-weight: normal;
        padding:16px 0 8px 0;

      
      }
      td{
        color: #303030;
        font-family:PT Sans;
        font-size:14px;
        padding:8px 0;

        .fa-bars{
          color: #ACACAC;
          font-size: 12px;
          cursor:pointer;
        }
        img{
          cursor:pointer;
        }
        
      }
    }
  }
}`;

import * as actionTypes from './request-account-action-types';

export const requestAccount = (email) => {
    return {
        type: actionTypes.SET_REQUEST_ACCOUNT,
        email: email
    }
}

export const requestAccountSuccess = () => {
    return {
        type: actionTypes.SET_REQUEST_ACCOUNT_SUCCESS
    }
}

export const requestAccountFail = (payload) => {
    return {
        type: actionTypes.SET_REQUEST_ACCOUNT_FAIL,
        payload: payload
    }
}

export const resetRequestAccount = () => {
    return {
        type: actionTypes.RESET_REQUEST_ACCOUNT
    }
}
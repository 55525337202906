import React, { useState } from 'react'
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';


const VariableNumberBox = (props) => {

    const [numberBoxValue, setNumberBoxValue] = useState(props.value)
    const [errorMessage, setErrorMessage] = useState(null)
    // const [defaultValuePresent, setDefaultValuePresent] = useState(props.defaultValueNumberbox)
    const changeNumberBox = (event, variableId, variableValue, clicked) => {
        if (clicked == 'reset') {
            const variableValueReset = variableValue
            props.onChangeNumberBox(variableId, variableValueReset, clicked);
            setNumberBoxValue(variableValueReset);
            setErrorMessage('')
        } else if (clicked == 'focuseout' && (numberBoxValue == null || numberBoxValue == '')) {
            setNumberBoxValue(variableValue);
            props.onChangeNumberBox(variableId, variableValue, 'reset');
            setErrorMessage('')
        } else {
            const variableValueChanged = event.target.value;
            if (variableValueChanged == '' || variableValueChanged == null) {
                setErrorMessage('Variable value should not be blanked')
                setNumberBoxValue(variableValueChanged);
            } else {
                setNumberBoxValue(variableValueChanged);
                setErrorMessage('')
                props.onChangeNumberBox(variableId, variableValueChanged, 'normal');

            }
        }
    }
    return (
        <Row>
            <Col md="9" className="p-0">
                <Form.Group className="custom-select-dropdown form-group">
                    <Form.Control
                        required
                        as="select"
                        name={`variableNumberBox-${props.id}`}
                        id={props.id}
                        className="ml-0"
                        defaultValue={numberBoxValue}
                        value={props.value}
                        disabled={props.defaultDisabledView}
                        onChange={(event) => changeNumberBox(event, props.id, numberBoxValue)}
                    // onBlur={(event) => changeNumberBox(event, props.id, numberBoxValue == '' ? props.defaultValueNumberbox : numberBoxValue, 'focuseout')}
                    >
                        {
                            props.options !== null && props.options.length === 0 &&
                            <React.Fragment>
                                <option value="2">He/Him</option>
                                <option value="1">She/Her</option>
                                <option value="0">They/Them</option>
                            </React.Fragment>
                        }
                                
                        {
                            props.options !== null && props.options !== undefined && props.options.length > 0 &&
                            props.options.map((option) => {
                                return <option value = {option.name}>{option.name}</option>
                            })
                        }
                        
                    </Form.Control>
                    <Form.Text className="text-danger">
                        {errorMessage}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col>
                <div className="ml-3 mt-2">
                    {
                        props.updateValueArrayIds.includes(props.id) === true &&
                        <OverlayTrigger
                            key={`tooltip-reset-${props.id}`}
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-${props.id}`} >
                                    Reset to default
                                </Tooltip>
                            }
                        >
                            <img
                                src={`/images/icons/refresh.svg`}
                                className="refresh-icon"
                                onClick={props.defaultDisabledView !== 'disabled' ? (event) => changeNumberBox(event, props.id, props.defaultValueNumberbox, 'reset') : ''}
                            />
                        </OverlayTrigger>


                    }
                </div>
            </Col>
        </Row>
    )
}

export default VariableNumberBox;
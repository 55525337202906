import { ImageMapper } from './../../common/helper/image-mapper';
import * as Config from './../../config/aspectRatio.config';

export const preloadWithCanvas = payload => {
  let images = payload.images;
  let aspectRatio = payload.aspectRatio;
  let svgImages = payload.svgImages;
  let svgIndex = 0;
  let newPromise = [];

  newPromise = images.map(sprite => {
    let imageSpecs = ImageMapper.getOriginalSpecsOfImage(sprite.specs);
    let url = sprite.url;

    return new Promise(resolve => {
      var imageObj = new Image();
      imageObj.crossOrigin = true;
      imageObj.onload = function() {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        let width = Config.aspectRatioParameters[aspectRatio].characterWidth;
        let height = Config.aspectRatioParameters[aspectRatio].characterHeight;
        canvas.height = height;
        canvas.width = width;
        // canvas.imageSmoothingEnabled = true;
        context.drawImage(
          imageObj,
          0,
          0,
          imageSpecs[0],
          imageSpecs[1],
          0,
          0,
          width,
          height
        );
        resolve(canvas.toDataURL());
      };

      if (sprite.url.split('.').pop() === 'svg' && svgImages.length > 0) {
        imageObj.src =
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(svgImages[svgIndex]);
        svgIndex++;
      } else {
        imageObj.src = url;
      }
    });
  });

  return Promise.all(newPromise).then(resp => resp);
};

export const preloadWithoutCanvas = images => {
  let newPromise1 = [];
  let svgImages = images.svgImages;
  let svgIndex = 0;

  newPromise1 = images.images.map(asset => {
    let imagePath = asset.awsPath;
    if (!images.isAsset) {
      imagePath = asset;
    }
    if (imagePath.split('.').pop() !== 'svg' && svgImages.length === 0) {
      return new Promise(resolve => {
        // Simulate a call to Dropbox or other service that can
        // return an image as an ArrayBuffer.
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        // Use JSFiddle logo as a sample image to avoid complicating
        // this example with cross-domain issues.
        xhr.open('GET', imagePath, true);

        // Ask for the result as an ArrayBuffer.
        xhr.responseType = 'arraybuffer';
        let blobImgUrl = '';

        xhr.onload = function(e) {
          // Obtain a blob: URL for the image data.
          var arrayBufferView = new Uint8Array(this.response);
          var blob = new Blob([arrayBufferView], { type: 'image/png' });
          var urlCreator = window.URL || window.webkitURL;
          blobImgUrl = urlCreator.createObjectURL(blob);
          resolve(blobImgUrl);
        };

        xhr.send();
      });
    } else {
      let aspectRatio = '9/19';
      var parser = new DOMParser();
      var result = parser.parseFromString(svgImages[svgIndex], 'text/xml');
      var inlineSVG = result.getElementsByTagName('svg')[0];
      let viewBox = inlineSVG.getAttribute('viewBox').split(' ');
      let viewOriginalWidth = viewBox[2].split('.')[0];
      let viewOriginalHeight = viewBox[3].split('.')[0];

      return new Promise(resolve => {
        var imageObj = new Image();
        imageObj.crossOrigin = true;
        imageObj.onload = function() {
          var canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');
          let width = Config.aspectRatioParameters[aspectRatio].characterWidth;
          let height = Config.aspectRatioParameters[aspectRatio].previewHeight;
          canvas.height = height;
          canvas.width = width;
          context.drawImage(
            imageObj,
            0,
            0,
            viewOriginalWidth,
            viewOriginalHeight,
            0,
            0,
            viewOriginalWidth,
            viewOriginalHeight
          );
          resolve(canvas.toDataURL());
        };

        imageObj.src =
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(svgImages[svgIndex]);
        svgIndex++;
      });
    }
  });

  return Promise.all(newPromise1).then(resp => resp);
};

export const prepareBlobImageWithCanvas = payload => {
  let mapImages = {};
  payload.data.map((image, index) => {
    let mapIndex = payload.images[index].url;
    mapImages[mapIndex] = payload.data[index];
  });

  return mapImages;
};

export const prepareBlobImagesWithoutCanvas = payload => {
  let mapImages = {};
  payload.data.map((image, index) => {
    let mapIndex = payload.images[index];
    if (payload.isAsset) {
      mapIndex = mapIndex.awsPath;
    }
    mapImages[mapIndex] = payload.data[index];
  });

  return mapImages;
};

const fetchSvgImg = (url, specs = null) => {
  return fetch(url, {
    method: 'GET'
  })
    .then(res => res.text())
    .then(resp => {
      var parser = new DOMParser();
      var result = parser.parseFromString(resp, 'text/xml');
      var inlineSVG = result.getElementsByTagName('svg')[0];
      // add the attributes Firefox needs. These should be absolute values, not relative
      if (specs != null) {
        inlineSVG.setAttribute('width', `${specs[0]}px`);
        inlineSVG.setAttribute('height', `${specs[1]}px`);
      }

      // convert the SVG to a data uri
      var svg64 = new XMLSerializer().serializeToString(inlineSVG);
      return svg64;
    })
    .catch(err => {
      throw err;
    });
};

export const preloadCharacterSVGImages = payload => {
  let images = payload.images;

  let promises = [];
  promises = images.map(sprite => {
    if (sprite.url.split('.').pop() === 'svg') {
      let specs = ImageMapper.getOriginalSpecsOfImage(sprite.specs);
      return fetchSvgImg(sprite.url, specs);
    }
  });

  return Promise.all(promises).then(resp => resp);
};

export const preloadBackgroundSVGImages = payload => {
  let images = payload.images;
  let promises = [];
  promises = images.map(asset => {
    let url = asset.awsPath;
    if (url.split('.').pop() === 'svg') {
      return fetchSvgImg(url);
    }
  });

  return Promise.all(promises).then(resp => resp);
};

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Badge, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './../style/style.css';
import ListContent from './ListContent';
import { LibraryServices } from './../../../components/library/service';




function ListFile(props) {
  const {
    fileNames,
    assetFiles,
    handleAssetFileDelete,
    handleTypeChange,
    handleNameChange,
    handleUploadInfo,
    enableUpload,
    setEnableUpload,
    refreshList,
    loading,

    focusElement,
    dummyAsset,
    setDummyAsset,
    show,
    setShow,
    target,

  } = props

  setEnableUpload(LibraryServices.setUploadEnabled(assetFiles))
  return (
    <Modal
      dialogClassName="upload-modal-dialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={fileNames.length > 0}
      onHide={props.onHide}
      backdrop='static'
      size='xl'
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="header-text">
          Upload multiple files
                    </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ 'padding': '0 70px 30px 15px !important' }}>
        <ListContent
          fileNames={fileNames}
          assetFiles={assetFiles}
          handleAssetFileDelete={handleAssetFileDelete}
          refreshList={refreshList}
          handleTypeChange={handleTypeChange}
          handleNameChange={handleNameChange}
          handleUploadInfo={handleUploadInfo}
          loading={loading}
          focusElement={focusElement}
          dummyAsset={dummyAsset}
          setDummyAsset={setDummyAsset}
          show={show}
          setShow={setShow}
          target={target}
        />

        <Row>
          <Col>
            <Button
              className="btn-snacktool btn-snacktool-golden"

              onClick={props.onHide}
            >
              Cancel
              </Button>
          </Col>
          <Col className='text-right'>
            <Button
              onClick={(e) => handleUploadInfo(e)}
              disabled={enableUpload}
              className="btn-snacktool btn-snacktool-golden btn btn-primary"
            >
              Upload
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ListFile
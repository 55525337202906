import React, { useEffect, useState } from 'react';
import LibraryMenu from './../../components/library/LibraryMenu';
import BasicInfo from './../../components/library/characters/create-character/BasicInfo';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import * as LibraryActions from './../../actions/library-action';

function CreateCharacterStepOne(props) {
  const [refreshList, setrefreshList] = useState(false);
  const [characterLongNameSpecail, setCharacterLongNameSpecail] = useState(
    null
  );

  const [character, setCharacter] = useState({
    name: '',
    longName: '',
    displayName: ''
  });

  useEffect(() => {
    if (
      props.IsCharacterEdit === true &&
      props.editCharaterId !== null &&
      props.isBackToStepOne === false
    ) {
      props.getCharacterById(
        props.storyId,
        props.editCharaterId,
        props.authToken
      );
    }
    setrefreshList(!refreshList);
  }, [props.IsCharacterEdit, props.isBackToStepOne]);

  useEffect(() => {
    if (props.isBackToStepOne === true) {
      props.setContinueToSelectAssetEnabled();
    }
  }, [props.isBackToStepOne]);

  useEffect(() => {
    if (props.showCharLockModal === true || props.showCharLockFail === true) {
      props.history.push('characters');
    }

    setrefreshList(!refreshList);
  }, [props.showCharLockModal, props.showCharLockFail]);
  useEffect(() => {
    if (props.IsCharacterEdit === true && props.editCharaterId !== null) {
      props.history.push('create-character-step-one');
    }
    setrefreshList(!refreshList);
  }, [props.characterName]);

  const handleCharacterNameValidity = characterName => {
    // props.setCharacterNameValidity(characterName, props.authToken,props.storyId,props.IsCharacterEdit,props.editCharaterId);
  };

  const handleColorChange = color => {
    props.setDialogueColor(color.hex);
    props.setAutoSaveFlag(true);

    if (
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterLongName) !==
        true ||
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterName) !== true
    ) {
      props.setContinueToSelectAssetDisabled();
    }
  };

  const handleCharacterName = name => {
    //props.characterLongName

    props.setCharacterName(name);
    if (
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterLongName) !==
        true ||
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(name) !== true
    ) {
      props.setContinueToSelectAssetDisabled();
    }
  };

  const handleCharacterDisplayName = name => {
    props.setCharacterDisplayName(name);
    if (
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterLongName) !==
        true ||
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterName) !== true
    ) {
      props.setContinueToSelectAssetDisabled();
    }
  };

  const handleContinueToSelectAssetClick = event => {
    if (props.continueToSelectAsset === 'disabled') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    } else {
      props.history.push('create-character-step-two');
    }
  };

  const handleCharacterLongName = name => {
    //characterName
    if (
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(name) !== true ||
      new RegExp('^[a-zA-Z0-9_-]{1,50}$').test(props.characterName) !== true
    ) {
      props.setContinueToSelectAssetDisabled();
    }
    props.setCharacterLongName(name);
  };

  return (
    <div>
      <Row>
        <Col className="col-2">
          <LibraryMenu
            selectedMenu={props.selectedMenu}
            setSelectedMenu={props.setSelectedMenu}
            characterAutoSaveFlag={props.characterAutoSaveFlag}
            resetCharacterAutoSaveFlag={props.resetCharacterAutoSaveFlag}
          />
        </Col>
        <Col className="col-8">
          <BasicInfo
            continueToSelectAsset={props.continueToSelectAsset}
            handleCharacterNameValidity={handleCharacterNameValidity}
            characterNameValidity={props.characterNameValidity}
            handleColorChange={handleColorChange}
            dialogueColor={props.dialogueColor}
            handleCharacterName={handleCharacterName}
            handleCharacterDisplayName={handleCharacterDisplayName}
            characterName={props.characterName}
            characterDisplayName={props.characterDisplayName}
            setCharacterNameValidityTrue={props.setCharacterNameValidityTrue}
            initializeCreateCharacter={props.initializeCreateCharacter}
            setContinueToSelectAssetDisabled={
              props.setContinueToSelectAssetDisabled
            }
            character={character}
            setCharacter={setCharacter}
            setContinueToSelectAssetEnabled={
              props.setContinueToSelectAssetEnabled
            }
            handleContinueToSelectAssetClick={handleContinueToSelectAssetClick}
            IsCharacterEdit={props.IsCharacterEdit}
            loadingCharacterNameValidity={props.loadingCharacterNameValidity}
            characterList={props.characterList}
            characterData={props.characterData}
            resetCharacterNameValidity={props.resetCharacterNameValidity}
            setAutoSaveFlag={props.setAutoSaveFlag}
            characterLongName={props.characterLongName}
            handleCharacterLongName={handleCharacterLongName}
            unityStoryId={props.unityStoryId}
          />
        </Col>
        <Col className="col-2 lib-right-rail"></Col>
      </Row>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    continueToSelectAsset: state.CharacterReducer.continueToSelectAsset,
    authToken: state.LoginReducer.authToken,
    characterNameValidity: state.CharacterReducer.characterNameValidity,
    dialogueColor: state.CharacterReducer.dialogueColor,
    storyId: state.StoryReducer.storyId,
    characterName: state.CharacterReducer.characterName,
    characterDisplayName: state.CharacterReducer.characterDisplayName,
    selectedMenu: state.LibraryReducer.selectedMenu,
    IsCharacterEdit: state.CharacterReducer.IsCharacterEdit,
    editCharaterId: state.CharacterReducer.editCharaterId,
    showCharLockModal: state.CharacterReducer.showCharLockModal,
    showCharLockFail: state.CharacterReducer.showCharLockFail,
    characterData: state.CharacterReducer.characterData,
    loadingCharacterNameValidity:
      state.CharacterReducer.loadingCharacterNameValidity,
    characterList: state.CharacterReducer.characterList,
    characterAutoSaveFlag: state.CharacterReducer.autoSaveFlag,
    isBackToStepOne: state.CharacterReducer.isBackToStepOne,
    characterLongName: state.CharacterReducer.characterLongName,
    unityStoryId:state.StoryReducer.unityStoryId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCharacterNameValidity: (
      characterName,
      authToken,
      storyId,
      IsCharacterEdit,
      editCharaterId
    ) =>
      dispatch(
        LibraryActions.setCharacterNameValidity(
          characterName,
          authToken,
          storyId,
          IsCharacterEdit,
          editCharaterId
        )
      ),
    setDialogueColor: color => dispatch(LibraryActions.setDialogueColor(color)),
    getCharacters: (storyId, authToken) =>
      dispatch(LibraryActions.getCharacters(storyId, authToken)),
    setCharacterName: name => dispatch(LibraryActions.setCharacterName(name)),
    setCharacterDisplayName: name =>
      dispatch(LibraryActions.setCharacterDisplayName(name)),
    setCharacterNameValidityTrue: () =>
      dispatch(LibraryActions.setCharacterNameValidityTrue()),
    initializeCreateCharacter: () =>
      dispatch(LibraryActions.initializeCreateCharacter()),
    setCharacterNameValidityFalse: () =>
      dispatch(LibraryActions.setCharacterNameValidityFalse()),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    setContinueToSelectAssetDisabled: () =>
      dispatch(LibraryActions.setContinueToSelectAssetDisabled()),
    setContinueToSelectAssetEnabled: () =>
      dispatch(LibraryActions.setContinueToSelectAssetEnabled()),
    resetCharacterNameValidity: () =>
      dispatch(LibraryActions.resetCharacterNameValidity()),

    getCharacterById: (storyId, characterId, authToken) =>
      dispatch(
        LibraryActions.getCharactersById(storyId, characterId, authToken)
      ),
    setAutoSaveFlag: flag => dispatch(LibraryActions.setAutoSaveFlag(flag)),
    resetCharacterAutoSaveFlag: () =>
      dispatch(LibraryActions.resetAutoSaveFlag()),
    setCharacterLongName: name =>
      dispatch(LibraryActions.setCharacterLongName(name))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCharacterStepOne);

class CommandParser {

    constructor(commandline, tabCount) {
        if (commandline !== undefined) {
            this.commandline = commandline;
            this.commandParts = commandline.split(/(`.*?`|\s+)/g);
            // this.commandParts = commandline.split(/\s+/)
            let totalParts = this.commandParts.length - 1
            if (this.commandParts[totalParts] == ``) {
                totalParts = this.commandParts.length - 2
            }
            this.commandParts = this.commandParts.filter(function (el, index) {
                if (index !== totalParts) {
                    return (el != "" && el != " ");
                } else {
                    return el
                }
            });
            let end = 0
            let start = 0

            this.partsBoundary = this.commandParts.map((part, index) => {
                if (index === 0) {
                    start = 0 + tabCount
                    end = part.length + tabCount
                } else {
                    start = end
                    if (index === 1) {
                        start = end + tabCount
                    }
                    end = start + part.length + 1
                }
                return {
                    begin: start,
                    end: end
                }
            });
        }
    }

    getCommandPartAt(curPosition) {
        let paramNumber = 0;
        // Let paramNumber = -1;
        if (this.commandParts) {
            for (let i = 0; i < this.commandParts.length; i++) {
                if (curPosition < this.partsBoundary[i].end) {
                    paramNumber = i;
                    break;
                } else {
                    paramNumber = i;
                }
            }
            return {
                position: curPosition,
                partNumber: paramNumber,
                isParam: (paramNumber === 0) ? false : true,
                part: this.commandParts[paramNumber],
                commandStart: this.commandParts[0],
                allCommandParamValue: this.commandParts,
                partsBoundary: this.partsBoundary
            }
        }
    }

    getCommandName() {
        return this.commandParts[0];
    }
}

export default CommandParser;
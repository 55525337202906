import styled from 'styled-components';

const characterComposer = styled.div`
    .charecter-img{
       
        height:295px;
    }
    .character-composer-block {
        text-align: center !important;
        background: url('/images/transparent-pattern-bg.svg') repeat;
        background-size:5%;

        .innerLayer {
            border: 2px black solid;
            width: 94px;
            height: 100%;
            position: absolute;
            z-index: 1000;
            top: 0;
            margin-left: 28px;
        }
    }

    .character-composer-frame {
        height: 150px;
        width: 200px;
        position: relative;
        background-repeat: no-repeat;
        background-position:center;
        overflow: hidden;
    }
    .character-composer-frame-view {
        width: 150px;
    }

    .empty-row {
        height: 20px;
    }

    .frame-image {
        position: absolute;
        left: 0%;
    }

    .sprite-row{
        padding: 12px  12px  12px 0px;
        cursor:pointer
        display:flex;
        justify-content:space-between;
        align-items: center;
        .asset-details{
            display: flex;
            align-items: center;
            .fa-bars{
                color:#ACACAC;
                font-size:13px;
            }
            .asset-name{
                margin-left:16px;
                color:#303030;
                font-family:PT Sans;
                font-size:14px
            }
        }
        .sprite-row-name{
            color:#303030;
            font-size:13px;
        }
    }
    
`;

export const PreviewStyle = {
  characterComposer
};

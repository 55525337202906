export const TOOTIP_FIRST_STORY = 'Replay';
export const TOOTIP_LAST_STORY = 'Last chapter in the story';
export const TOOTIP_PREV_STORY = 'Previous chapter in the story';
export const TOOTIP_NEXT_STORY = 'Next chapter in the story';

export const TOOTIP_PAUSE = 'Pause';
export const TOOTIP_PLAY = 'Play';

export const TOOTIP_FIRST_BLOCK = 'Replay';
export const TOOTIP_LAST_BLOCK = 'Last block in the chapter';
export const TOOTIP_PREV_BLOCK = 'Previous block in the chapter';
export const TOOTIP_NEXT_BLOCK = 'Next block in the chapter';

export const WHO_ARE_YOU = 'Who are you?';
export const DIALOGUE_NAME_NOT_CORRECT = 'Dialogue name not correct';
export const DIALOGUE_PRONOUNS_NOT_CORRECT = 'Pronouns must be selected';
import * as ActionTypes from './library-action-types';
import * as Constant from './../common/constants';

export function setAsset(payload, authToken, storyId, IsAssetEdit, assetData) {
  return {
    type: ActionTypes.SET_ASSET,
    payload: payload,
    authToken: authToken,
    storyId: storyId,
    IsAssetEdit: IsAssetEdit,
    assetData: assetData
  };
}

export function setAssetSuccess(payload) {
  return {
    type: ActionTypes.SET_ASSET_SUCCESS,
    payload: payload
  };
}

export function setAssetFail() {
  return {
    type: ActionTypes.SET_ASSET_FAIL
  };
}

export function setCover(payload, authToken, storyId, IsAssetEdit, assetData) {
  return {
    type: ActionTypes.SET_COVER,
    payload: payload,
    authToken: authToken,
    storyId: storyId,
    IsAssetEdit: IsAssetEdit,
    assetData: assetData
  };
}

export function setCoverSuccess(payload) {
  return {
    type: ActionTypes.SET_ASSET_SUCCESS,
    payload: payload
  };
}

export function setCoverFail() {
  return {
    type: ActionTypes.SET_ASSET_FAIL
  };
}

export function uploadAsset(payload, authToken, storyId) {
  return {
    type: ActionTypes.SET_ASSET_UPLOAD,
    payload: payload,
    authToken: authToken,
    storyId: storyId
  };
}

export function setAssetUploadSuccess(payload) {
  return {
    type: ActionTypes.SET_ASSET_UPLOAD_SUCCESS,
    payload: payload
  };
}

export function setAssetUploadFail() {
  return {
    type: ActionTypes.SET_ASSET_UPLOAD_FAIL
  };
}

//cover

export function uploadCover(payload, authToken, storyId) {
  return {
    type: ActionTypes.SET_COVER_UPLOAD,
    payload: payload,
    authToken: authToken,
    storyId: storyId
  };
}

export function setCoverUploadSuccess(payload) {
  return {
    type: ActionTypes.SET_COVER_UPLOAD_SUCCESS,
    payload: payload
  };
}

export function setCoverUploadFail() {
  return {
    type: ActionTypes.SET_COVER_UPLOAD_FAIL
  };
}

export function getCoverAssetById(authToken, storyId, assetId) {
  return {
    type: ActionTypes.GET_COVER_BY_ID,
    authToken: authToken,
    storyId: storyId,
    assetId: assetId
  };
}

export function getCoverByIdSuccess(payload) {
  return {
    type: ActionTypes.GET_COVER_BY_ID_SUCCESS,
    payload: payload
  };
}

export function getCoverByIdFail(payload) {
  return {
    type: ActionTypes.GET_COVER_BY_ID_FAIL,
    payload: payload
  };
}

export function getAssetById(authToken, storyId, assetId) {
  return {
    type: ActionTypes.GET_ASSET_BY_ID,
    authToken: authToken,
    storyId: storyId,
    assetId: assetId
  };
}

export function getAssetByIdSuccess(payload) {
  return {
    type: ActionTypes.GET_ASSET_BY_ID_SUCCESS,
    payload: payload
  };
}

export function getAssetByIdFail(payload) {
  return {
    type: ActionTypes.GET_ASSET_BY_ID_FAIL,
    payload: payload
  };
}

export function setAssetCancel() {
  return {
    type: ActionTypes.SET_ASSET_CANCEL
  };
}

export function setAssetList(authToken, storyId) {
  return {
    type: ActionTypes.SET_ASSET_LIST,
    authToken: authToken,
    storyId: storyId
  };
}

export function setAssetListSuccess(payload) {
  return {
    type: ActionTypes.SET_ASSET_LIST_SUCCESS,
    payload: payload
  };
}

export function setAssetListFail() {
  return {
    type: ActionTypes.SET_ASSET_LIST_FAIL
  };
}

///Cover image

//actions for delete assets & confirmations
export function getCoverDelete(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_COVER_DELETE,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function getCoverDeleteSuccess(assetList) {
  return {
    type: ActionTypes.GET_COVER_DELETE_SUCCESS,
    assetList: assetList
  };
}

export function getCoverDeleteFail() {
  return {
    type: ActionTypes.GET_COVER_DELETE_FAIL
  };
}

export function setCoverList(authToken, storyId) {
  return {
    type: ActionTypes.SET_COVER_LIST,
    authToken: authToken,
    storyId: storyId
  };
}

export function setCoverListSuccess(payload) {
  return {
    type: ActionTypes.SET_COVER_LIST_SUCCESS,
    payload: payload
  };
}

export function setCoverListFail() {
  return {
    type: ActionTypes.SET_COVER_LIST_FAIL
  };
}

//End

export function setCharacterAsset(
  variation,
  files,
  uploadCountFiles,
  UploadFileCount
) {
  return {
    type: ActionTypes.SET_CHARACTER_ASSET,
    variation: variation,
    files: files,
    uploadCountFiles: uploadCountFiles,
    UploadFileCount: UploadFileCount
  };
}

export function setUplaodCharacter() {
  return {
    type: ActionTypes.SET_UPLAOD_CHARACTER
  };
}

export function setCharacterPreviwAsset(previewVariations) {
  return {
    type: ActionTypes.SET_CHARACTER_PREVIEW_ASSET,
    previewVariations: previewVariations
  };
}

export function deleteCharacterAsset(variation) {
  return {
    type: ActionTypes.DELETE_CHARACTER_ASSET,
    variation: variation
  };
}

export function deleteCharacterPreviewAsset(previewVariations) {
  return {
    type: ActionTypes.DELETE_CHARACTER_PREVIEW_ASSET,
    previewVariations: previewVariations
  };
}
export function setShowModalTrue() {
  return {
    type: ActionTypes.SET_SHOW_MODAL_TRUE
  };
}

export function handleResetLockModal() {
  return {
    type: ActionTypes.SET_SHOW_LOCK_MODAL_FALSE
  };
}

export function setShowModalFalse() {
  return {
    type: ActionTypes.SET_SHOW_MODAL_FALSE
  };
}

export function setCharShowModalTrue() {
  return {
    type: ActionTypes.SET_CHAR_SHOW_MODAL_TRUE
  };
}

export function setCharShowModalFalse() {
  return {
    type: ActionTypes.SET_CHAR_SHOW_MODAL_FALSE
  };
}

export function setRollbackAsset(name, authToken) {
  return {
    type: ActionTypes.SET_ROLLBACK_ASSET,
    name: name,
    authToken: authToken
  };
}

export function setLoadingTrue() {
  return {
    type: ActionTypes.SET_LOADING_TRUE
  };
}

export function setLoadingFalse() {
  return {
    type: ActionTypes.SET_LOADING_FALSE
  };
}

export function setCharacterNameValidity(
  characterName,
  authToken,
  storyId,
  IsCharacterEdit,
  editCharaterId
) {
  return {
    type: ActionTypes.SET_CHARACTER_NAME_VALIDITY,
    characterName: characterName,
    authToken: authToken,
    storyId: storyId,
    IsCharacterEdit: IsCharacterEdit,
    editCharaterId: editCharaterId
  };
}
export function setCharacterNameValidityTrue() {
  return {
    type: ActionTypes.SET_CHARACTER_NAME_VALIDITY_TRUE
  };
}

export function setCharacterNameValidityFalse() {
  return {
    type: ActionTypes.SET_CHARACTER_NAME_VALIDITY_FALSE
  };
}

export function resetCharacterNameValidity() {
  return {
    type: ActionTypes.RESET_CHARACTER_NAME_VALIDITY
  };
}

export function setContinueToSelectAssetEnabled() {
  return {
    type: ActionTypes.SET_CONTINUE_TO_SELECT_ASSET_ENABLED
  };
}

export function setContinueToSelectAssetDisabled() {
  return {
    type: ActionTypes.SET_CONTINUE_TO_SELECT_ASSET_DISABLED
  };
}

export function setDialogueColor(color) {
  return {
    type: ActionTypes.SET_DIALOGUE_COLOR,
    color: color
  };
}

export function setCharacterAssetList(storyId, authToken) {
  return {
    type: ActionTypes.SET_CHARACTER_ASSET_LIST,
    storyId: storyId,
    authToken: authToken,
    assetType: Constant.ASSET_TYPES_IMAGES[0]
  };
}

export function setCharacterAssetListSuccess(characterAssetList) {
  return {
    type: ActionTypes.SET_CHARACTER_ASSET_LIST_SUCCESS,
    characterAssetList: characterAssetList
  };
}

export function setCharacterAssetListFail() {
  return {
    type: ActionTypes.SET_CHARACTER_ASSET_LIST_FAIL
  };
}

export function setCharacterName(characterName) {
  return {
    type: ActionTypes.SET_CHARACTER_NAME,
    characterName: characterName
  };
}

export function setCharacterDisplayName(characterDisplayName) {
  return {
    type: ActionTypes.SET_CHARACTER_DISPLAY_NAME,
    characterDisplayName: characterDisplayName
  };
}

export function setSelectedAsset(characterAssetId) {
  return {
    type: ActionTypes.SET_SELECTED_ASSET,
    characterAssetId: characterAssetId
  };
}

export function setFilterAssetList(filterAssetList) {
  return {
    type: ActionTypes.SET_FILTER_ASSET_LIST,
    filterAssetList: filterAssetList
  };
}

export function setContinueToVariationsEnabled() {
  return {
    type: ActionTypes.SET_CONTINUE_TO_VARIATIONS_ENABLED
  };
}

export function setContinueToVariationsDisabled() {
  return {
    type: ActionTypes.SET_CONTINUE_TO_VARIATIONS_DISABLED
  };
}

export function setSpriteLayers(spriteLayers) {
  return {
    type: ActionTypes.SET_SPRITE_LAYERS,
    spriteLayers: spriteLayers
  };
}

export function setVariation(variations) {
  return {
    type: ActionTypes.SET_VARIATION,
    variations: variations
  };
}

export function setRemoveVariation(index) {
  return {
    type: ActionTypes.SET_REMOVE_VARIATION,
    index: index
  };
}

export function initializeCreateCharacter() {
  return {
    type: ActionTypes.INITIALIZE_CREATE_CHARACTER
  };
}

export function setFinalAndSaveCharacterButtonEnabled() {
  return {
    type: ActionTypes.SET_FINAL_AND_SAVE_CHARACTER_BUTTON_ENABLED
  };
}

export function setFinalAndSaveCharacterButtonDisabled() {
  return {
    type: ActionTypes.SET_FINAL_AND_SAVE_CHARACTER_BUTTON_DISABLED
  };
}

export function setSaveFinalCharacter(
  character,
  authToken,
  storyId,
  IsCharacterEdit,
  charId
) {
  return {
    type: ActionTypes.SET_SAVE_FINAL_CHARACTER,
    character: character,
    authToken: authToken,
    storyId: storyId,
    IsCharacterEdit: IsCharacterEdit,
    charId: charId
  };
}

export function setSaveFinalCharacterSuccess() {
  return {
    type: ActionTypes.SET_SAVE_FINAL_CHARACTER_SUCCESS
  };
}

export function setSaveFinalCharacterFail() {
  return {
    type: ActionTypes.SET_SAVE_FINAL_CHARACTER_FAIL
  };
}

export function setShowCharacterSaveSuccessModalTrue() {
  return {
    type: ActionTypes.SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_TRUE
  };
}

export function setShowCharacterSaveSuccessModalFalse() {
  return {
    type: ActionTypes.SET_SHOW_CHARACTER_SAVE_SUCCESS_MODAL_FALSE
  };
}

export function setShowCharacterSaveFailModalTrue() {
  return {
    type: ActionTypes.SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_TRUE
  };
}

export function setShowCharacterSaveFailModalFalse() {
  return {
    type: ActionTypes.SET_SHOW_CHARACTER_SAVE_FAIL_MODAL_FALSE
  };
}

export function setSelectAll(selectAll) {
  return {
    type: ActionTypes.SET_SELECT_ALL,
    selectAll: selectAll
  };
}

export function checkVariationExits(payload) {
  return {
    type: ActionTypes.CHECK_VARIATION_EXISTS,
    payload: payload
  };
}
export function setVariationExists(status) {
  return {
    type: ActionTypes.SET_VARIATION_EXISTS,
    isVariationExists: status
  };
}

export function setSelectedLibraryMenu(menuName) {
  return {
    type: ActionTypes.SET_SELECTED_LIBRARY_MENU,
    menuName: menuName
  };
}

export function setCharacterEditTrue(characterId) {
  return {
    type: ActionTypes.SET_CHARACTER_EDIT_TRUE,
    characterId: characterId
  };
}

export function setCharacterEditFalse() {
  return {
    type: ActionTypes.SET_CHARACTER_EDIT_FALSE
  };
}

export function getCharacters(storyId, authToken) {
  return {
    type: ActionTypes.SET_GET_CHARACTERS,
    storyId: storyId,
    authToken: authToken
  };
}

export function getCharactersSuccess(characterList, characterRouteList) {
  return {
    type: ActionTypes.SET_GET_CHARACTERS_SUCCESS,
    characterList: characterList,
    characterRouteList: characterRouteList
  };
}

export function getCharactersFail() {
  return {
    type: ActionTypes.SET_GET_CHARACTERS_FAIL
  };
}

export function getRouteCharacters(storyId, authToken) {
  return {
    type: ActionTypes.SET_GET_ROUTE_CHARACTERS,
    storyId: storyId,
    authToken: authToken
  };
}

export function getRouteCharactersSuccess(characterList) {
  return {
    type: ActionTypes.SET_GET_ROUTE_CHARACTERS_SUCCESS,
    routeCharacterList: characterList
  };
}

export function getRouteCharactersFail() {
  return {
    type: ActionTypes.SET_GET_ROUTE_CHARACTERS_FAIL
  };
}

export function getCharactersById(storyId, characterId, authToken) {
  return {
    type: ActionTypes.GET_CHARACTERS_BY_ID,
    storyId: storyId,
    characterId: characterId,
    authToken: authToken
  };
}

export function getCharactersByIdSuccess(characterData) {
  return {
    type: ActionTypes.GET_CHARACTERS_BY_ID_SUCCESS,
    characterData: characterData
  };
}

export function getCharactersByIdFail(payload) {
  return {
    type: ActionTypes.GET_CHARACTERS_BY_ID_FAIL,
    payload: payload
  };
}
export function getCharactersLockFail(payload) {
  return {
    type: ActionTypes.GET_CHARACTERS_BY_LOCK_FAIL,
    payload: payload
  };
}

export function getCharactersForScript(storyId, authToken) {
  return {
    type: ActionTypes.SET_GET_CHARACTERS_SCRIPT,
    storyId: storyId,
    authToken: authToken
  };
}

export function getCharactersForScriptSuccess(characterList) {
  return {
    type: ActionTypes.SET_GET_CHARACTERS_SCRIPT_SUCCESS,
    characterList: characterList
  };
}

export function getCharactersForScriptFail() {
  return {
    type: ActionTypes.SET_GET_CHARACTERS_SCRIPT_FAIL
  };
}

export function sortCharacterList(sortingOrder) {
  return {
    type: ActionTypes.SET_CHARACTER_LIST_SORT,
    sortingOrder: sortingOrder
  };
}

//actions for delte character & confirmations
export function getCharacterDelete(storyId, authToken, charId) {
  return {
    type: ActionTypes.GET_CHARACTER_DELETE,
    storyId: storyId,
    charId: charId,
    authToken: authToken
  };
}

export function getCharacterDeleteSuccess(characterList) {
  return {
    type: ActionTypes.GET_CHARACTER_DELETE_SUCCESS,
    characterList: characterList
  };
}

export function getCharacterDeleteFail() {
  return {
    type: ActionTypes.GET_CHARACTER_DELETE_FAIL
  };
}

export function deleteCharacter(storyId, authToken, charId) {
  return {
    type: ActionTypes.CHARACTER_DELETE,
    storyId: storyId,
    charId: charId,
    authToken: authToken
  };
}

export function deleteCharacterSuccess(characterList) {
  return {
    type: ActionTypes.CHARACTER_DELETE_SUCCESS,
    characterList: characterList
  };
}

export function deleteCharacterFail() {
  return {
    type: ActionTypes.GET_CHARACTER_DELETE_FAIL
  };
}

export function setShowModalDeleteTrue() {
  return {
    type: ActionTypes.SET_SHOW_DELTE_MODAL_TRUE
  };
}

export function handleResetCharacterLockModal() {
  return {
    type: ActionTypes.SET_SHOW_CHAR_LOCAK_MODAL_FALSE
  };
}

export function setShowModalDeleteFalse() {
  return {
    type: ActionTypes.SET_SHOW_DELETE_MODAL_FALSE
  };
}

//actions for delete assets & confirmations
export function getAssetDelete(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_ASSET_DELETE,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function getAssetDeleteSuccess(assetList) {
  return {
    type: ActionTypes.GET_ASSET_DELETE_SUCCESS,
    assetList: assetList
  };
}

export function getAssetDeleteFail() {
  return {
    type: ActionTypes.GET_ASSET_DELETE_FAIL
  };
}

export function deleteAsset(storyId, authToken, assetId) {
  return {
    type: ActionTypes.ASSET_DELETE,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function deleteAssetSuccess(assetList) {
  return {
    type: ActionTypes.ASSET_DELETE_SUCCESS,
    assetList: assetList
  };
}

export function deleteAssetFail() {
  return {
    type: ActionTypes.GET_ASSET_DELETE_FAIL
  };
}

export function deleteCover(storyId, authToken, assetId) {
  return {
    type: ActionTypes.COVER_DELETE,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function activeCoverChange(storyId, authToken, assetId, value) {
  return {
    type: ActionTypes.COVER_ACTIVE_CHANGE,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken,
    value: value
  };
}

export function deleteCoverSuccess(assetList) {
  return {
    type: ActionTypes.COVER_DELETE_SUCCESS,
    assetList: assetList
  };
}

export function deleteCoverFail() {
  return {
    type: ActionTypes.GET_COVER_DELETE_FAIL
  };
}

export function setShowAssetModalDeleteTrue() {
  return {
    type: ActionTypes.SET_SHOW_ASSET_DELTE_MODAL_TRUE
  };
}

export function setShowAssetModalDeleteFalse() {
  return {
    type: ActionTypes.SET_SHOW_ASSET_DELETE_MODAL_FALSE
  };
}

export function setLibraryChangeLog(authToken, storyId) {
  return {
    type: ActionTypes.SET_LIBRARY_CHANGELOG,
    authToken: authToken,
    storyId: storyId
  };
}

export function setLibraryChangeLogSuccess(payload) {
  return {
    type: ActionTypes.SET_LIBRARY_CHANGELOG_SUCCESS,
    payload: payload
  };
}

export function setLibraryChangeLogFail() {
  return {
    type: ActionTypes.SET_LIBRARY_CHANGELOG_FAIL
  };
}

export function getCoverEditInfo(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_COVER_EDIT_INFO,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function getCoverEditInfoSuccess(assetList) {
  return {
    type: ActionTypes.GET_COVER_EDIT_INFO_SUCCESS,
    assetList: assetList
  };
}

export function getCoverEditInfoFail(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_COVER_EDIT_INFO_FAIL
  };
}

export function getAssetEditInfo(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_ASSET_EDIT_INFO,
    storyId: storyId,
    assetId: assetId,
    authToken: authToken
  };
}

export function getAssetEditInfoSuccess(assetList) {
  return {
    type: ActionTypes.GET_ASSET_EDIT_INFO_SUCCESS,
    assetList: assetList
  };
}

export function getAssetEditInfoFail(storyId, authToken, assetId) {
  return {
    type: ActionTypes.GET_ASSET_EDIT_INFO_FAIL
  };
}

export function setAutoSaveFlag(flag) {
  return {
    type: ActionTypes.SET_CHARACTER_AUTOSAVE_FLAG,
    payload: flag
  };
}

export function resetAutoSaveFlag() {
  return {
    type: ActionTypes.RESET_CHARACTER_AUTOSAVE_FLAG
  };
}

export function setToStepOne(flag) {
  return {
    type: ActionTypes.SET_CHARACTER_STEP_ONE,
    payload: flag
  };
}

export function setCharacterLongName(characterLongName) {
  return {
    type: ActionTypes.SET_CHARACTER_LONG_NAME,
    characterLongName: characterLongName
  };
}

const addDecision = (command, skipTimeout, handleDecisionClick, time) => {
    // console.log("command",command.parameters);
    let timeout = 0;
    if (command.parameters['Time limit (optional)'] === 'true') {
        timeout = setTimeout(() => {
            handleDecisionClick(command.parameters['Destination']);
        }, (parseInt(command.parameters['Wait up to']) + 0.2) * 1000);
    }

    return timeout;
}

export const BlockCommands = {
    addDecision    
}
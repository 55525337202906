import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from '../actions/action-types';
import * as StoryActions from './../actions/story-action';
import * as StoryActionTypes from './../actions/story-action-types';
import jwt from 'jsonwebtoken';

import {
  getStoryListFromApi,
  checkStoryNameValidity,
  getStoryGenereList,
  postStory,
  getCommandLevel1FromApi,
  getCommandLevel2FromApi,
  // getCharacterVariationsFromApi,
  // getCharacterExpressionsFromApi,
  getCharacterColorFromApi,
  getStoryDetail,
  getStoryAdminApi,
  reviewStory,
  postCommands,
  getCommandList,
  getAvialableBlocks,
  getPreviewAssetList,
  getCommandEditList,
  deleteStory,
  reviewAdminStory,
  getCollaboratorApi,
  addCollaboratorApi,
  addViewerApi,
  getAllWriterApi,
  revokeCollaboratorApi,
  releaseLock,
  lockedStory,
  validateStory,
  updateRouteInfoApi,
  changeLogApi,
  duplicateStory,
  getViewerApi,
  revokeViewerApi,
  updateStoryIdApi,
  postAutoSaveApi,
  addStoryCreditAPI,
  spellCheckApi,
  spellIgnoreApi
} from './api/story-api';

//Command Level1
export function* getCmdLevel1Worker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandLevel1FromApi, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.getCommandsLevel1Success(data.body));
      } else {
        yield put(StoryActions.getCommandsLevel1Fail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCommandsLevel1Fail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCmdLevel1Watcher() {
  yield takeLatest(StoryActionTypes.GET_COMMAND_LEVEL1, getCmdLevel1Worker);
}

//Command Level2
export function* getCmdLevel2Worker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandLevel2FromApi, payloads);

      if (data.result === 'Success') {
        yield put(
          StoryActions.getCommandsLevel2Success(data.body, payloads.commandId)
        );
      } else {
        yield put(StoryActions.getCommandsLevel2Fail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCommandsLevel2Fail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCmdLevel2Watcher() {
  yield takeLatest(StoryActionTypes.GET_COMMAND_LEVEL2, getCmdLevel2Worker);
}

//Command Level2 Script
export function* getCmdLevel2ScriptWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandLevel2FromApi, payloads);

      if (data.result === 'Success') {
        yield put(
          StoryActions.getCommandsLevel2ScriptSuccess(
            data.body,
            payloads.commandId
          )
        );
      } else {
        yield put(StoryActions.getCommandsLevel2ScriptFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCommandsLevel2ScriptFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCmdLevel2ScriptWatcher() {
  yield takeLatest(
    StoryActionTypes.GET_SCRIPT_COMMAND_LEVEL2,
    getCmdLevel2ScriptWorker
  );
}

//Command Level2: Character Color
export function* getCharacterColorWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCharacterColorFromApi, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.getCharacterColorSuccess(data.body));
      } else {
        yield put(StoryActions.getCharacterColorFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCharacterColorFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCharacterColorWatcher() {
  yield takeLatest(StoryActionTypes.GET_CHAR_COLOR, getCharacterColorWorker);
}

// //Command Level2: Character Variations
// export function* getCharacterVariationsWorker(payloads) {

//   try {
//     const data = yield call(getCharacterVariationsFromApi, payloads);

//     if (data.result === 'Success') {
//       yield put(StoryActions.getCharacterVariationsSuccess(data.body, payloads.isEdit ? payloads.isEdit : 0));
//     } else {
//       yield put(StoryActions.getCharacterVariationsFail());
//     }
//   } catch (err) {
//     yield put(StoryActions.getCharacterVariationsFail());
//   }
// }

// export function* getCharacterVariationsWatcher() {
//   yield takeLatest(StoryActionTypes.GET_CHAR_VARIATIONS, getCharacterVariationsWorker);
// }

//Command Level2: Character Expressions
// export function* getCharacterExpressionsWorker(payloads) {
//   ////////////////////////////////ASDASDASDAS

//   try {

//     const data = yield call(getCharacterExpressionsFromApi, payloads);

//     if (data.result === 'Success') {
//       yield put(StoryActions.getCharacterExpressionsSuccess(data.body, payloads.isEdit ? payloads.isEdit : 0));
//     } else {
//       yield put(StoryActions.getCharacterExpressionsFail());
//     }
//   } catch (err) {
//     yield put(StoryActions.getCharacterExpressionsFail());
//   }
// }

// export function* getCharacterExpressionsWatcher() {
//   yield takeLatest(StoryActionTypes.GET_CHAR_EXPRESSIONS, getCharacterExpressionsWorker);
// }

export function* getStoryList(payloads) {
  if (payloads.authToken) {
    try {
      const storyListdata = yield call(getStoryListFromApi, payloads.authToken);
      if (storyListdata.result === 'Success') {
        yield put({
          type: ActionTypes.GET_RESET_FLOWCHART_DETAILS
        });
        yield put({
          type: StoryActionTypes.RESET_CHAPTER_STORY_DETAILS
        });
        yield put({
          type: ActionTypes.GET_SUCCESS_STORY_LIST_DETAILS,
          payload: storyListdata
        });
      } else {
        yield put({ type: ActionTypes.GET_FAIL_STORY_LIST_DETAILS });
      }
    } catch (e) {
      yield put({ type: ActionTypes.GET_FAIL_STORY_LIST_DETAILS });
    }
  }
}
//get story details By ID
export function* getStoryDetails(payloads) {
  if (payloads.authToken) {
    try {
      yield put({
        type: ActionTypes.RESET_STORY_DETAILS
      });
      const storydata = yield call(getStoryDetail, payloads);
      if (storydata.result === 'Success') {
        yield put({
          type: ActionTypes.GET_STORY_DETAILS_SUCCESS,
          payload: storydata.body
        });
        // yield put({
        //   type: ActionTypes.SET_API_ERROR,
        //   errorMsg:'test error'
        // });
      } else {
        yield put({ type: ActionTypes.GET_STORY_DETAILS_FAIL });
        if (
          storydata.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: storydata.message[0]
          });
        }
      }
    } catch (e) {
      yield put({ type: ActionTypes.GET_STORY_DETAILS_FAIL });
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getStoryGenere(payloads) {
  if (payloads.payload) {
    try {
      const data = yield call(getStoryGenereList, payloads.payload);

      if (data.result === 'Success') {
        yield put(StoryActions.getStoryGenereSuccess(data.body));
      } else {
        yield put(StoryActions.getStoryGenereFail());
      }
    } catch (err) {
      yield put(StoryActions.getStoryGenereFail());
    }
  }
}

export function* checkStoryValid(payload) {
  if (payload.authToken) {
    try {
      const data = yield call(checkStoryNameValidity, payload);

      if (data.result === 'Success') {
        yield put(StoryActions.setStoryNameValidityTrue(data.body));
      }
    } catch (err) {
      yield put(StoryActions.setStoryNameValidityFalse());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setStoryWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(postStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.setStoryFail(postData));
      } else {
        yield put(StoryActions.setStorySuccess(postData));
        yield put({
          type: ActionTypes.GET_STORY_LIST_DETAILS,
          authToken: payloads.authToken
        });
      }
    } catch (e) {
      yield put(StoryActions.setStoryFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getStoryForAdmin(payloads) {
  if (payloads.authToken) {
    try {
      const storyAdminData = yield call(getStoryAdminApi, payloads);

      if (storyAdminData.result !== 'Success') {
        yield put(StoryActions.getStoryAdminFail(storyAdminData));
        if (
          storyAdminData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: storyAdminData.message[0]
          });
        }
      } else {
        if (
          payloads.authorNameAdded !== undefined &&
          payloads.authorNameAdded !== null &&
          payloads.authorNameAdded !== ''
        ) {
          yield put(
            StoryActions.getStoryAdminSuccessWithSearch(
              storyAdminData,
              payloads.authorNameAdded
            )
          );
        } else {
          yield put(StoryActions.getStoryAdminSuccess(storyAdminData));
        }
      }
    } catch (e) {
      yield put(StoryActions.setStoryFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setStoryReviewWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(reviewStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.setStoryReviewFail());
      } else {
        yield put(StoryActions.setStoryReviewSuccess());
        yield put({
          type: ActionTypes.GET_STORY_LIST_DETAILS,
          authToken: payloads.authToken
        });
      }
    } catch (e) {
      yield put(StoryActions.setStoryFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setStoryLockedWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(lockedStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.checkStoryLockedFail());
      } else {
        yield put(StoryActions.checkStoryLockedSuccess(postData));
      }
    } catch (e) {
      yield put(StoryActions.checkStoryLockedFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

//setStoryLockedWorker

export function* setStoryAdminReviewWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(reviewAdminStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.setStoryAdminReviewFail());
        if (
          postData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: postData.message[0]
          });
        }
      } else {
        yield put(StoryActions.setStoryAdminReviewSuccess());
        yield put({
          type: StoryActionTypes.GET_STORY_ADMIN,
          authToken: payloads.authToken
        });
      }
    } catch (e) {
      yield put(StoryActions.setStoryFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setStoryDuplicateWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(duplicateStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.setStoryDuplicateFail());
        if (
          postData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: postData.message[0]
          });
        }
      } else {
        yield put(StoryActions.setStoryDuplicateSuccess());
        yield put({
          type: StoryActionTypes.GET_STORY_ADMIN,
          authToken: payloads.authToken
        });
      }
    } catch (e) {
      yield put(StoryActions.setStoryDuplicateFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setStoryDeleteWorker(payloads) {
  if (payloads.authToken) {
    let tokenDataUser = jwt.decode(
      payloads.authToken ? payloads.authToken : []
    );

    try {
      const postData = yield call(deleteStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.setStoryDeleteFail());
      } else {
        yield put(StoryActions.setStoryDeleteSuccess());
        yield put({
          type: ActionTypes.GET_STORY_LIST_DETAILS,
          authToken: payloads.authToken
        });
        if (tokenDataUser.roles !== '[ROLE_WRITER]') {
          yield put({
            type: StoryActionTypes.GET_STORY_ADMIN,
            authToken: payloads.authToken
          });
        }
      }
    } catch (e) {
      yield put(StoryActions.setStoryFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setAutoSaveCommandWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(postAutoSaveApi, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.setAutoSaveCommandsSuccess(data.body));
      } else {
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setCommandPanelWorker(payloads) {
  if (payloads.authToken) {
    const data = yield call(postAutoSaveApi, payloads);
    // yield put(StoryActions.setCommandsPanelSuccess(payloads.payload));
    try {
      // const data = 'Success'
      if (data.result === 'Success') {
        yield put(StoryActions.setCommandsPanelSuccess(data.body));
      }
    } catch (err) {
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* setCommandWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(postCommands, payloads);
      yield put({
        type: ActionTypes.GET_RESET_ONLY_FLOWCHART
      });
      if (postData.result !== 'Success') {
        yield put(StoryActions.setCommandsFail(postData));
        yield put(
          StoryActions.getCommands(
            payloads.authToken,
            payloads.storyId,
            payloads.sceneId
          )
        );
        if (
          postData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: postData.message[0]
          });
        }
      } else {
        if (payloads.savefromMenu !== true) {
          yield put(StoryActions.setCommandsSuccess(postData));
          yield put(
            StoryActions.getCommands(
              payloads.authToken,
              payloads.storyId,
              payloads.sceneId
            )
          );
          yield put(
            StoryActions.getAvailableBlocks(
              payloads.authToken,
              payloads.sceneId,
              payloads.storyId
            )
          );
          yield put({
            type: ActionTypes.GET_BLOCK_FLOWCHART_DETAILS,
            authToken: payloads.authToken,
            storyId: payloads.storyId,
            blockId: payloads.sceneId
          });
        } else {
          yield put({
            type: ActionTypes.RESET_COMMAND_DETAILS
          });
        }
      }
    } catch (e) {
      yield put(StoryActions.setCommandsFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

// export function* setAutoSaveCommandWorker(payloads){
//   if (payloads.authToken) {
//     try {
//       const data = yield call(postAutoSaveApi, payloads);

//       if (data.result === 'Success') {
//         console.log('Success')
//       } else {
//         if (
//           data.message[0] ===
//           'Full authentication is required to access this resource'
//         ) {
//           yield put({
//             type: ActionTypes.SET_LOGIN_DETAILS,
//             payload: null,
//             isLogOut: true
//           });
//         } else {
//           yield put({
//             type: ActionTypes.SET_API_ERROR,
//             errorMsg: data.message[0]
//           });
//         }
//       }
//     } catch (err) {

//       yield put({
//         type: ActionTypes.SET_API_ERROR
//       });
//     }
//   }
// }

export function* getCommandsEditWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandEditList, payloads);

      if (data.result === 'Success') {
        if (data.body.commandId === 9) {
          const dataObj = yield call(getCommandEditList, payloads);
          if (dataObj.result === 'Success') {
            yield put(
              StoryActions.getCommandsEditSuccess(
                dataObj.body,
                dataObj.body.commandId
              )
            );
          }
        } else {
          yield put(
            StoryActions.getCommandsEditSuccess(data.body, data.body.commandId)
          );
        }
      } else {
        yield put(StoryActions.getCommandsEditFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCommandsEditFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCommandsWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandList, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.getCommandsSuccess(data.body));
      } else {
        yield put(StoryActions.getCommandsFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCommandsFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getReleaseLockWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(releaseLock, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.realeseLockSuccess(data.body));
        yield put({
          type: StoryActionTypes.GET_ROUTE,
          authToken: payloads.authToken,
          storyId: payloads.storyId
        });
      } else {
        yield put(StoryActions.realeseLockFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.realeseLockFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getPreviewAssetWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getPreviewAssetList, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.getPreviewAssetSuccess(data.body));
      } else {
        yield put(StoryActions.getPreviewAssetFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getPreviewAssetFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getAvaialbleBlocksWorker(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getAvialableBlocks, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.getAvailableBlocksSuccess(data.body));
      } else {
        yield put(StoryActions.getAvailableBlocksFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getAvailableBlocksFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getCollaboratorListFunction(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCollaboratorApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.getCollaboratorListSuccess(data.body));
      } else {
        yield put(StoryActions.getCollaboratorListFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getCollaboratorListFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* getViewerListFunction(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getViewerApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.getViewerListSuccess(data.body));
      } else {
        yield put(StoryActions.getViewerListFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.getViewerListFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* addCollaboratorToStory(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(addCollaboratorApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.addCollaboratorSuccess(data.body));
        const dataObjCollaborator = yield call(getCollaboratorApi, payloads);
        if (dataObjCollaborator.result === 'Success') {
          yield put(
            StoryActions.getCollaboratorListSuccess(dataObjCollaborator.body)
          );
        } else {
          yield put(StoryActions.getCollaboratorListFail());
          if (
            data.message[0] ===
            'Full authentication is required to access this resource'
          ) {
            yield put({
              type: ActionTypes.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
          } else {
            yield put({
              type: ActionTypes.SET_API_ERROR,
              errorMsg: data.message[0]
            });
          }
        }
      } else {
        yield put(StoryActions.addCollaboratorFail());
        yield put({
          type: ActionTypes.SET_API_ERROR
        });
      }
    } catch (err) {
      yield put(StoryActions.addCollaboratorFail(err));
    }
  }
}

export function* addViewerToStory(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(addViewerApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.addViewerSuccess(data.body));
        const dataObjCollaborator = yield call(getViewerApi, payloads);
        if (dataObjCollaborator.result === 'Success') {
          yield put(
            StoryActions.getViewerListSuccess(dataObjCollaborator.body)
          );
        } else {
          yield put(StoryActions.getViewerListFail());
          if (
            data.message[0] ===
            'Full authentication is required to access this resource'
          ) {
            yield put({
              type: ActionTypes.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
          } else {
            yield put({
              type: ActionTypes.SET_API_ERROR,
              errorMsg: data.message[0]
            });
          }
        }
      } else {
        yield put(StoryActions.addViewerFail());
        yield put({
          type: ActionTypes.SET_API_ERROR
        });
      }
    } catch (err) {
      yield put(StoryActions.addViewerFail(err));
    }
  }
}

export function* revokeCollaboratorToStory(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(revokeCollaboratorApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.revokeCollaboratorSuccess(data.body));
        const dataObjCollaborator = yield call(getCollaboratorApi, payloads);
        if (dataObjCollaborator.result === 'Success') {
          yield put(
            StoryActions.getCollaboratorListSuccess(dataObjCollaborator.body)
          );
        } else {
          yield put(StoryActions.getCollaboratorListFail());
        }
      } else {
        yield put(StoryActions.revokeCollaboratorFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.revokeCollaboratorFail(err));
    }
  }
}

export function* revokeViewerToStory(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(revokeViewerApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.revokeViewerSuccess(data.body));
        const dataObjCollaborator = yield call(getViewerApi, payloads);
        if (dataObjCollaborator.result === 'Success') {
          yield put(
            StoryActions.getViewerListSuccess(dataObjCollaborator.body)
          );
        } else {
          yield put(StoryActions.getViewerListFail());
        }
      } else {
        yield put(StoryActions.revokeViewerFail());
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
      }
    } catch (err) {
      yield put(StoryActions.revokeViewerFail(err));
    }
  }
}

export function* resetVariableWindowAll(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getCommandList, payloads);

      if (data.result === 'Success') {
        yield put(StoryActions.resetAllLocalVariableWindowSuccess(data.body));
      } else {
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: data.message[0]
          });
        }
        // yield put(StoryActions.getCommandsFail());
      }
    } catch (err) {
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
      // yield put(StoryActions.getCommandsFail());
    }
  }
}

export function* getWriterListForCollaborator(payloads) {
  if (payloads.authToken) {
    try {
      const data = yield call(getAllWriterApi, payloads);
      if (data.result === 'Success') {
        yield put(StoryActions.fetchWritersListSuccess(data.body));
      } else {
        yield put(StoryActions.fetchWritersListFail());
      }
    } catch (err) {
      yield put(StoryActions.fetchWritersListFail(err));
    }
  }
}

export function* setStoryValidWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(lockedStory, payloads);

      if (postData.result !== 'Success') {
        yield put(StoryActions.checkStoryLockedFail());
      } else {
        if (postData.body.length > 0) {
          yield put(StoryActions.checkStoryLockedSuccess(postData));
        }

        if (postData.body.length === 0) {
          let storyValid = yield call(validateStory, payloads);

          if (storyValid.result === 'Success') {
            yield put(StoryActions.checkStoryValidSuccess(storyValid));
          }
        }
      }
    } catch (e) {
      yield put(StoryActions.checkStoryLockedFail());
    }
  }
}

export function* updateRouteInfoWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(updateRouteInfoApi, payloads);
      if (postData.result === 'Success') {
        yield put(StoryActions.updateRouteInfoSuccess());
        yield put({
          type: ActionTypes.GET_STORY_DETAILS,
          authToken: payloads.authToken,
          storyId: payloads.storyId
        });
      } else {
        yield put(StoryActions.updateRouteInfoFail());
        if (
          postData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: postData.message[0]
          });
        }
      }
    } catch (e) {
      yield put(StoryActions.updateRouteInfoFail());
      yield put({
        type: ActionTypes.SET_API_ERROR
      });
    }
  }
}

export function* updateStoryIdWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(updateStoryIdApi, payloads);
      if (postData.result === 'Success') {
        yield put(StoryActions.updateStoryIdSuccess(postData.body));
        yield put({
          type: StoryActionTypes.GET_STORY_ADMIN,
          authToken: payloads.authToken
        });
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: postData.message[0]
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.SET_API_ERROR
        // errorMsg: postData ? postData.message[0] : ''
      });
    }
  }
}

export function* addStoryCreditWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(addStoryCreditAPI, payloads);
      if (postData.result === 'Success') {
        yield put({
          type: ActionTypes.GET_STORY_LIST_DETAILS,
          authToken: payloads.authToken
        });
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: postData.message[0]
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.SET_API_ERROR
        // errorMsg: postData ? postData.message[0] : ''
      });
    }
  }
}

export function* getSpellGrammar(payloads) {
  if (payloads.authToken) {
    try {
      const getData = yield call(spellCheckApi, payloads);
      if (getData.result === 'Success') {
        yield put(StoryActions.spellCheckSuccess(getData.body.suggestions));
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: getData.message[0]
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.SET_API_ERROR
        // errorMsg: postData ? postData.message[0] : ''
      });
    }
  }
}

export function* postGrammarIgnore(payloads) {
  if (payloads.authToken) {
    try {
      const getData = yield call(spellIgnoreApi, payloads);
      if (getData.result === 'Success') {
        yield put(StoryActions.spellCheckerCall(payloads.authToken, payloads.storyId, payloads.chapterId, payloads.commandObj));
      } else {
        yield put({
          type: ActionTypes.SET_API_ERROR,
          errorMsg: getData.message[0]
        });
      }
    } catch (e) {
      yield put({
        type: ActionTypes.SET_API_ERROR
        // errorMsg: postData ? postData.message[0] : ''
      });
    }
  }
}

export function* changeLogScriptWorker(payloads) {
  if (payloads.authToken) {
    try {
      const changelogData = yield call(changeLogApi, payloads);
      if (changelogData.result !== 'Success') {
        yield put(StoryActions.addCollaboratorFail());
        if (
          changelogData.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: ActionTypes.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put({
            type: ActionTypes.SET_API_ERROR,
            errorMsg: changelogData.message[0]
          });
        }
      } else {
        yield put(StoryActions.changeLogScriptSuccess(changelogData.body));
      }
    } catch (e) {
      yield put(StoryActions.addCollaboratorFail());
    }
  }
}

export function* updateVariableWindowFunction(payloads) {
  yield put(StoryActions.updateStoryWindowSuccess(payloads));
}

export function* getAvaialbleBlockDataWatcher() {
  yield takeLatest(
    StoryActionTypes.GET_AVAILABLE_BLOCKS,
    getAvaialbleBlocksWorker
  );
}

export function* setCommandDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_COMMANNDS, setCommandWorker);
}

export function* setAutoSaveCommandDataWatcher() {
  yield takeLatest(
    StoryActionTypes.SET_AUTOSAVE_COMMANNDS,
    setAutoSaveCommandWorker
  );
}

export function* setCommandPanelDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_COMMANNDS_PANEL, setCommandPanelWorker);
}

export function* getCommandsDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_COMMANNDS, getCommandsWorker);
}

export function* getReleaseLockDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_RELEASE_LOCK, getReleaseLockWorker);
}

export function* getPreviewAssetDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_PREVIEW_ASSET, getPreviewAssetWorker);
}

export function* getCommandsEditDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_COMMANNDS_EDIT, getCommandsEditWorker);
}

export function* storyNameDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_STORY_NAME_VALIDITY, checkStoryValid);
}

export function* getStoryDataWatcher() {
  yield takeLatest(ActionTypes.GET_STORY_LIST_DETAILS, getStoryList);
}

export function* getStoryDetailsDataWatcher() {
  yield takeLatest(ActionTypes.GET_STORY_DETAILS, getStoryDetails);
}

export function* getStoryGenereDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_STORY_GENERE, getStoryGenere);
}

export function* setStoryDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_STORY_GENERE, getStoryGenere);
}

export function* addStoryDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_STORY, setStoryWorker);
}

export function* getStoryAdminDataWatcher() {
  yield takeLatest(StoryActionTypes.GET_STORY_ADMIN, getStoryForAdmin);
}

export function* storyReviewDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_STORY_REVIEW, setStoryReviewWorker);
}

export function* storyLockedDataWatcher() {
  yield takeLatest(StoryActionTypes.CHECK_STORY_LOCKED, setStoryLockedWorker);
}

export function* storyDuplicateDataWatcher() {
  yield takeLatest(
    StoryActionTypes.SET_STORY_DUPLICATE,
    setStoryDuplicateWorker
  );
}

export function* storyAdminReviewDataWatcher() {
  yield takeLatest(
    StoryActionTypes.SET_STORY_ADMIN_REVIEW,
    setStoryAdminReviewWorker
  );
}

export function* storyDeleteDataWatcher() {
  yield takeLatest(StoryActionTypes.SET_STORY_DELETE, setStoryDeleteWorker);
}

export function* storyCollaboratorListWatcher() {
  yield takeLatest(
    StoryActionTypes.GET_COLLABORATOR_LIST,
    getCollaboratorListFunction
  );
}

export function* storyViewerListWatcher() {
  yield takeLatest(StoryActionTypes.GET_VIEWER_LIST, getViewerListFunction);
}

export function* addCollaboratorToStoryWatcher() {
  yield takeLatest(
    StoryActionTypes.ADD_COLLABORATOR_TO_STORY,
    addCollaboratorToStory
  );
}

export function* addViewerToStoryWatcher() {
  yield takeLatest(StoryActionTypes.ADD_VIEWER_TO_STORY, addViewerToStory);
}

export function* revokeCollaboratorToStoryWatcher() {
  yield takeLatest(
    StoryActionTypes.REVOKE_COLLABORATOR_TO_STORY,
    revokeCollaboratorToStory
  );
}

export function* revokeViewerToStoryWatcher() {
  yield takeLatest(
    StoryActionTypes.REVOKE_VIEWER_TO_STORY,
    revokeViewerToStory
  );
}

export function* getWriterListWatcher() {
  yield takeLatest(
    StoryActionTypes.GET_WRITER_LIST,
    getWriterListForCollaborator
  );
}

export function* localVariableWatcher() {
  yield takeLatest(
    StoryActionTypes.UPDATE_VARIABLE_WINDOW,
    updateVariableWindowFunction
  );
}

export function* resetAllVariableWindowWatcher() {
  yield takeLatest(
    StoryActionTypes.RESET_ALL_VARIABLE_WINDOW,
    resetVariableWindowAll
  );
}

export function* storyValidDataWatcher() {
  yield takeLatest(StoryActionTypes.CHECK_STORY_VALID, setStoryValidWorker);
}

export function* updateRouteInfoWatcher() {
  yield takeLatest(StoryActionTypes.UPDATE_ROUTE_INFO, updateRouteInfoWorker);
}

export function* updateStoryIdWatcher() {
  yield takeLatest(StoryActionTypes.UPDATE_STORY_ID, updateStoryIdWorker);
}

export function* addStoryCreditsWatcher() {
  yield takeLatest(StoryActionTypes.ADD_CREDITS, addStoryCreditWorker);
}

export function* chapterSpellCheckWatcher() {
  yield takeLatest(StoryActionTypes.SET_GRAMMAR_SPELL, getSpellGrammar);
}

export function* grammarIgnoreWords() {
  yield takeLatest(StoryActionTypes.SET_GRAMMAR_IGNORE, postGrammarIgnore);
}

// export function* setAutoSaveCommandDataWatcher() {
//   yield takeLatest(StoryActionTypes.SET_AUTOSAVE_COMMANNDS, setAutoSaveCommandWorker);
// }

export function* changelogScriptWatcher() {
  yield takeLatest(
    StoryActionTypes.GET_CHANGE_LOG_SCRIPT,
    changeLogScriptWorker
  );
}

export default {
  getStoryDataWatcher,
  storyNameDataWatcher,
  getStoryGenereDataWatcher,
  addStoryDataWatcher,
  getStoryDetailsDataWatcher,
  getStoryAdminDataWatcher,
  storyReviewDataWatcher,
  setCommandDataWatcher,
  getCommandsDataWatcher,
  getAvaialbleBlockDataWatcher,
  getPreviewAssetDataWatcher,
  getCommandsEditDataWatcher,
  storyDeleteDataWatcher,
  storyAdminReviewDataWatcher,
  storyCollaboratorListWatcher,
  addCollaboratorToStoryWatcher,
  getWriterListWatcher,
  revokeCollaboratorToStoryWatcher,
  getReleaseLockDataWatcher,
  storyLockedDataWatcher,
  localVariableWatcher,
  resetAllVariableWindowWatcher,
  storyValidDataWatcher,
  updateRouteInfoWatcher,
  changelogScriptWatcher,
  storyDuplicateDataWatcher,
  storyViewerListWatcher,
  addViewerToStoryWatcher,
  revokeViewerToStoryWatcher,
  updateStoryIdWatcher,
  setAutoSaveCommandDataWatcher,
  setCommandPanelDataWatcher,
  addStoryCreditsWatcher,
  chapterSpellCheckWatcher,
  grammarIgnoreWords
};

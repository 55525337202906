import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import Graph from 'react-graph-vis';
import { optionsFlowchart } from '../../common/constants';
import { merge } from 'lodash';

const LargeFlowchart = ({
  flowchartNode,
  flowchartConnector,
  show,
  close,
  storyId,
  chapterId,
  flowchartJson,
  setUpdatedFlowchartJson
}) => {
  const [currentNetwork, setCurrentNetwork] = useState();

  const createData = (nodes, edges) =>
    merge(
      {},
      {
        nodes,
        edges
      }
    );

  // this graph object will be passed to main `Graph` component
  const currentGraph = createData(flowchartNode, flowchartConnector);

  // @todo could probably be moved to a constant or config file
  const customFlowchartOptions = {
    height: '100%',
    width: '100%',
    layout: {
      clusterThreshold: 150,
      hierarchical: {
        enabled: true,
        levelSeparation: 100,
        nodeSpacing: 150,
        treeSpacing: 100
      }
    },
    physics: {
      enabled: true,
      hierarchicalRepulsion: {
        centralGravity: 0.0,
        springLength: 200,
        springConstant: 0.01,
        damping: 1
      },
      solver: 'hierarchicalRepulsion',
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 100,
        onlyDynamicEdges: false
      },
      timestep: 0.5,
      adaptiveTimestep: true
    }
  };

  // in order to not mutate the original options objects, we'll create this
  // new local object, merged between the default options from `constants`
  // and the custom ones, for this large flowchart
  const mergeFlowchartOptions = merge(
    {},
    optionsFlowchart,
    customFlowchartOptions
  );

  // toggle on/off specific options to be passed to the network,
  // to enable/disable automatica hierarchical arrangement
  const toggleAutoArrange = enabled => {
    currentNetwork &&
      currentNetwork.setOptions(
        merge({}, mergeFlowchartOptions, {
          nodes: {
            physics: enabled
          },
          layout: {
            hierarchical: {
              enabled: enabled
            }
          },
          physics: {
            enabled: enabled
          },
          interaction: {
            dragNodes: true
          }
        })
      );
  };

  const events = {
    dragging: () => {
      // console.log("currentNetwork.getPositions", currentNetwork.getPositions())
      // fit while dragging; this way, the user could break loose, playing and
      // messing around with the diagram
      // currentNetwork.fit()
    },
    dragEnd: () => {
      // store positions every time a node stops being dragged
      setTimeout(() => {
        setUpdatedFlowchartJson(JSON.stringify(currentNetwork.getPositions()));
      }, 100);
    }
  };

  // remove local storage item storing flowchart positions
  const clearPositions = () => {
    setTimeout(() => {
      setUpdatedFlowchartJson(JSON.stringify({}));
    }, 100);
  };

  // reset flowchart to the initial auto-organized hierarchical layout
  const resetLayout = () => {
    // clear stored positions
    clearPositions();

    // toggle automatic hierarchical layout
    toggleAutoArrange(true);

    // reset data with original passed props
    currentNetwork.setData(createData(flowchartNode, flowchartConnector));

    // need to redraw the thing with the new data
    currentNetwork.redraw();

    setUpdatedFlowchartJson(JSON.stringify({}));
    // and make it fit!
    // currentNetwork.fit()

    // then, in order to enable freedom again, we set a dreaded timeout
    // to override the former options for arranging the diagram in a tidy way
    setTimeout(() => {
      toggleAutoArrange(false);
    }, 100);
  };

  // check for stored positions in local storage
  const storedPositions = flowchartJson;

  // if any, we affect the corresponding nodes with the x and y values
  if (storedPositions) {
    currentGraph.nodes.forEach(node => {
      node.x = storedPositions[node.id] && storedPositions[node.id].x;
      node.y = storedPositions[node.id] && storedPositions[node.id].y;
    });
    toggleAutoArrange(false);
  }

  // force network to fit at first render
  // currentNetwork && currentNetwork.fit()

  // then, de-activate physics to enable freedom of movement...
  setTimeout(() => {
    toggleAutoArrange(false);
  }, 100);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => close()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Button
          className="btn-snacktool btn-snacktool-brown margin-left-button"
          onClick={() => resetLayout()}
        >
          <i className="fa fa-refresh" /> Reset flowchart
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Graph
          graph={currentGraph}
          options={mergeFlowchartOptions}
          events={events}
          style={{ height: '680px' }}
          getNetwork={network => {
            setCurrentNetwork(network);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default LargeFlowchart;

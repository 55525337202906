import React from 'react';
import { Button, Modal } from 'react-bootstrap';
 
import * as Translations from './../translations';

function ConfirmBox({ show, onProceedClick, onCancelClick }) {
  return (

    <Modal
    aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={show}
  >
    <Modal.Header >
      <Modal.Title
        id="contained-modal-title-vcenter"
        className="header-text"
      ></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <div className="modal-content-wrapper">
         <div  className="modal-icon">
            <img alt="" className="checkIcon" src="/images/icons/ic_warning_24px.svg"/>
         </div>
         <div  className="modal-text">
            <label className="heading">  {Translations.RESET_WARNING_MESSAGE}</label>
         </div>
       
        
     </div>
     <div className="modal-button-wrapper justify-content-between">
     <Button
              className="btn-snacktool btn-snacktool-golden"
              onClick={onCancelClick}
            >
              Cancel
              </Button>
     <Button
              onClick={onProceedClick}
              className="btn-snacktool btn-snacktool-golden btn btn-primary"
            >
              {Translations.PROCEED}
            </Button>
     </div>

    </Modal.Body>
  </Modal>
  
  );
};
export default ConfirmBox

import React, { useState, useEffect } from 'react';

import { CommentStyle } from './styles';
import './style.css';

import CommentHead from './CommentHead';
import CommentFooter from './CommentFooter';
import CommentBody from './CommentBody';
import AllComments from './AllComments';

//CommentHead
function Comments(props) {
  const { reply, comment } = props;

  // commentNumber={commentNumber}
  // commentLists={props.commentLists}
  // filterCommnetList={props.filterCommnetList}
  const [validated, setValidated] = useState(false);
  const [commentValidated, setCommentValidated] = useState(false);

  const [enableReply, setEnableReply] = useState(true);
  const [showReply, setShowReply] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);

  const [enableComment, setEnableComment] = useState(true);

  useEffect(() => {
    const backdropElement = document.getElementById('backdropElementComment');
    backdropElement.addEventListener('click', hideCharacterPoup);
  }, []);

  const hideCharacterPoup = () => {
    document.getElementById('backdropElementComment').style.display = 'none';
    document.getElementById('commentPopup').style.display = 'none';
    props.hidePopup();
  };
  const showReplyBox = flag => {
    setShowReply(flag);
    props.setShowReplyForm(flag);
  };

  const showCommentBox = flag => {
    setShowEditComment(flag);
    props.setShowCommentForm(flag);
  };

  const handleCommnetFormChange = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    props.setComment({
      ...comment,
      [event.target.name]: event.target.value
    });
    if (event.target.value !== '') {
      setEnableComment(false);
    } else {
      setEnableComment(true);
    }

    setCommentValidated(true);
  };

  const handleFormChange = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    props.setReply({
      ...reply,
      [event.target.name]: event.target.value
    });
    if (event.target.value !== '') {
      setEnableReply(false);
    } else {
      setEnableReply(true);
    }

    setValidated(true);
  };

  return (
    <>
      <div className="backdrop" id="backdropElementComment"></div>
      <div
        className="comment-popup"
        id="commentPopup"
        style={{ top: `${props.pageY - 40}px` }}
      >
        <div className="modal-content">
          <div className="character-composer">
            <CommentStyle.commentComposer>
              <div className=" d-flex justify-content-center">
                <div className="character-composer-frame">
                  <CommentHead
                    handleFilterClick={props.handleFilterClick}
                    commentFilter={props.commentFilter}
                    commentNumber={props.commentNumber}
                    filterList={props.filterCommnetList}
                  />
                  {props.commentFilter === true &&
                    props.filterCommnetList.length > 0 &&
                    props.filterCommnetList[props.commentNumber] !==
                      undefined && (
                      <CommentBody
                        commentNumber={props.commentNumber}
                        filterList={
                          props.filterCommnetList[props.commentNumber]
                        }
                        showPanels={props.showPanels}
                        isOwnerOrCollab={props.isOwnerOrCollab}
                        isViewStory={props.isViewStory}
                        handleResolveComment={props.handleResolveComment}
                        handleFormChange={handleFormChange}
                        validated={validated}
                        commentValidated={commentValidated}
                        enableReply={enableReply}
                        setEnableReply={setEnableReply}
                        showReply={showReply}
                        showReplyBox={showReplyBox}
                        handleReplyComment={props.handleReplyComment}
                        showReplyForm={props.showReplyForm}
                        handleDeleteComment={props.handleDeleteComment}
                        isCommentStory={props.isCommentStory}
                        currentStoryStatus={props.currentStoryStatus}
                        showCommentBox={showCommentBox}
                        showEditComment={showEditComment}
                        setShowCommentForm={props.setShowCommentForm}
                        showCommentForm={props.showCommentForm}
                        enableComment={enableComment}
                        setEnableComment={setEnableComment}
                        handleCommnetFormChange={handleCommnetFormChange}
                        handleEditComment={props.handleEditComment}
                        tokenData={props.tokenData}
                      />
                    )}
                  {props.commentFilter === true &&
                    props.filterCommnetList.length === 0 && (
                      <div className="no-comments">
                        <h3 className="mb-0">Comments</h3>
                        <div className="no-comments-bg text-center">
                          <div className="mb-3">
                            <img alt="" src="/images/icons/sms.svg" />
                          </div>
                          <h4 className="mb-3">No comments yet!</h4>
                          <p className="mb-0">
                            A Snack Editor can add comments to your script as
                            soon as you send your story for review
                          </p>
                        </div>
                      </div>
                    )}
                  {props.commentFilter === false &&
                    props.commentLists.length > 0 && (
                      <AllComments
                        commentLists={props.commentLists}
                        handleResolveComment={props.handleResolveComment}
                        showPanels={props.showPanels}
                        isCommentStory={props.isCommentStory}
                        currentStoryStatus={props.currentStoryStatus}
                        tokenData={props.tokenData}
                      />
                    )}

                  {props.commentFilter === false &&
                    props.commentLists.length === 0 && (
                      <div className="no-comments">
                        <h3 className="mb-0">Comments</h3>
                        <div className="no-comments-bg text-center">
                          <div className="mb-3">
                            <img alt="" src="/images/icons/sms.svg" />
                          </div>
                          <h4 className="mb-3">No comments yet!</h4>
                          <p className="mb-0">
                            A Snack Editor can add comments to your script as
                            soon as you send your story for review
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </CommentStyle.commentComposer>
          </div>

          {props.commentFilter === true &&
            props.filterCommnetList.length > 0 && (
              <CommentFooter
                handlePrevClick={props.handlePrevClick}
                handleNextClick={props.handleNextClick}
                commentNumber={props.commentNumber}
                filterList={props.filterCommnetList}
              />
            )}
        </div>
      </div>
    </>
  );
}
export default Comments;

export const CREATE_NEW_STORY = 'Create New Story';
export const EDIT_STORY_INFO = 'Edit Story info';
export const CONTINUE_TO_ADD_INFO = 'Continue to step 2';
export const CONTINUE_TO_STEP_THREE = 'Continue to step 3';
export const UPLOAD_FILE = 'Cover images';
export const ADDITIONAL_INFO = 'Additional info';
export const GENERAL_INFO = 'General info';
export const YOUR_FILE_TO_STORY = 'Your Story Cover,or';
export const SIZE_FILE_TO_STORY = 'Optimal Image size: 680 x 383 pixels';
export const YOUR_FILE_LATER = '(Your can add this later too)';
export const SUPPORTED_FILE_FORMATS = 'Supported file formats: PNG, JPG, JPEG';
export const UPLOAD_SIZE_FILE = 'File size limit: 15 Mb ';
export const INCORRECT_DIMENSIONS = 'Incorrect dimension';
export const INCORRECT_FILE_SIZE = 'Incorrect file size';

export const FILES_TYPE_NOT_ACCEPTED = 'Cannot upload. Supported file formats: PNG, JPG, JPEG';
export const FILE_IS_TOO_LARGE = 'File size cannot be bigger than 15 MB';
export const DROP_IT = 'Drop it!';
export const BACK_TO_UPLOAD_FILE = 'Back to upload file';
export const STORY_TITLE = 'Story Title';
export const STORY_TYPE = 'Story type';
export const STORY_COST = 'Story cost';
export const STORY_GENRE = 'Story genre';
export const STORY_TAGS = 'Tags(optional)';
export const SAVE_INFO = 'Finish and save story';
export const BACK_TO_STEP_TWO = 'Back to Step 2';
export const BACK_TO_STEP_ONE = 'Back to Step 1';
export const GENRELABEL = 'Select up to three';
export const SAVING_NEW_STORY = 'Saving new story';
export const UPDATING_NEW_STORY = 'Updating story info';
export const NEW_STORY_SAVE = 'The story has been created';
export const NEW_STORY_UPDATE = 'The story has been updated';


export const DESCRIPTION_ERROR_MESSAGE = 'Please enter a description';
export const NEW_STORY_SAVE_LABEL = 'You can still edit its details later';
export const TAGS_MAXIMUM = 'Seprated by comma (Maximum: 5)';

export const DESCRIPTION = 'Description';
export const MAXIMUM = 'Maximum length: 645 characters';
export const ASSET_TYPE = 'Asset type';
 
export const CLEAR_FILE = 'Clear file';

export const STORY_NAME_REQ_MESSAGE= 'Please enter a story title';
export const STORY_NAME_ERROR_MESSAGE = 'Sorry, this title is already in use. Please type a different one.';
export const TAGS_ERROR_MESSAGE = 'A maximum of 5 tags are allowed.';
export const ASSET_TYPE_ERROR_MESSAGE = 'Please select an asset type.';

export const CANCEL = 'Cancel';
export const DRAG_DROP = 'DRAG & DROP';
export const BROWSE = 'browse';

export const ASSET_IMAGE_TYPE_ERROR_MESSAGE =
  'Cannot upload. Supported file formats: PNG, JPG, JPEG.';
export const ASSET_AUDIO_TYPE_ERROR_MESSAGE =
  'Cannot upload. The allowed file types are .wav, .mp3, .mp4,.ogg, .webm.';

  //Comments section 
  export const ADD_NEW_COMMENT = 'Add new comment';
  export const COMMENT = 'Comment';
  export const COMMENT_SUB_TITLE = 'Tip: Explain to the Writer what is wrong with this line of the script and how to fix it.';
  export const COMMENT_COMMAND = 'Command in script';

  export const ALBUM_COVER_TOOLTIP_TEXT = 'This will be the cover for your story when selecting it among others in the Fictif app. It usually includes the title in the image.';
  export const SERIES_BACKGROUND_TOOLTIP_TEXT = 'This image will be displayed above the story title when selecting it in the Fictif app, just before playing it. Background images work well.';

export const GO_TO_COVER_IMAGES = 'Go to Cover images';
export const GO_TO_ADDITIONAL_INFO = 'Go to additional info';
export const BACK = 'Back';
export const CLOSE = 'Close';
import React from 'react';
import * as translations from './translations';
import { Form, Button } from 'react-bootstrap';
import { LibraryStyle } from './../style/style';
import { INT_NUMBER_PATTERN } from './../../../common/helper/pattern';
import './../style/style.css';

import * as Constant from './constant';

const AddTags = props => {
  const {
    validated,
    handleSaveInfo,

    handleGenreChange,
    handleTagChange,
    generes,
    editStory,
    storyDetails,
    genreList,
    tags,
    handleNumberChange,
    handleSelectChange,
    cost,
    storyType
  } = props;
  const handleGenreChangeIcon = (event, nameTag, nameId) => {
    event.target.value = nameTag;
    event.target.name = parseInt(nameId);
    handleTagChange(event);
  };

  let editTags =
    editStory && storyDetails.tags !== undefined && storyDetails.tags !== null
      ? storyDetails.tags.toString().replace(/\s*,\s*/g, ', ')
      : '';
  let genreFirst = generes[0] ? generes[0].id : '0',
    genreSecond = generes[1] ? generes[1].id : '0',
    genreThird = generes[2] ? generes[2].id : '0';

  return (
    <LibraryStyle.addInfoContainer>
      <Form
        id="add-tag-form"
        noValidate
        validated={validated}
        className="infoContainer"
        onSubmit={e => handleSaveInfo(e)}
      >
        <Form.Group>
          <Form.Label>{translations.STORY_TYPE}</Form.Label>
          <Form.Control
            as="select"
            defaultValue="RouteStory"
            name="story_type"
            key="story_type"
            onChange={e => handleSelectChange(e)}
            value={storyType}
          >
            <option value="RouteStory">Route story</option>
            <option value="ShortStory">Short story</option> ̰
          </Form.Control>
        </Form.Group>
        {storyType === 'ShortStory' && (
          <Form.Group>
            <Form.Label>{translations.STORY_COST}</Form.Label>
            <Form.Control
              type="number"
              name="story_cost"
              key="story_cost"
              onChange={e => handleNumberChange(e)}
              defaultValue="0"
              min="0"
              max="2147483647"
              step={1}
              value={cost}
              pattern={'0-9]*'}
              required
              placeholder="Story cost"
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              Please enter valid number between 0 to 2147483647.
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>{translations.STORY_GENRE}</Form.Label>
          <Form.Text className="text-muted">
            {translations.GENRELABEL}
          </Form.Text>
          <Form.Group>
            {genreList.map((datum, index) => (
              <Button
                className={
                  genreFirst.toString() === datum.id.toString() ||
                  genreSecond.toString() === datum.id.toString() ||
                  genreThird.toString() === datum.id.toString()
                    ? 'inputTagSelect'
                    : 'inputTag'
                }
                name={datum.id}
                onClick={event => handleGenreChange(event)}
                value={datum.name}
                variant="outline-primary"
              >
                {genreFirst.toString() === datum.id.toString() ||
                genreSecond.toString() === datum.id.toString() ||
                genreThird.toString() === datum.id.toString() ? (
                  <i
                    className="fa fa-check"
                    value={datum.name}
                    aria-hidden="true"
                    onClick={event =>
                      handleGenreChangeIcon(event, datum.name, datum.id)
                    }
                  ></i>
                ) : (
                  ''
                )}
                {datum.name}
              </Button>
            ))}
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            {translations.STORY_NAME_ERROR_MESSAGE}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </LibraryStyle.addInfoContainer>
  );
};

export default AddTags;

import React, { useState } from 'react';

import CreateDropdownWidget from './widgets/dropdown-widget';
import CreateTextBox from './widgets/text-box';
import NumberBox from './widgets/number-text-box';
import SpinBox from './widgets/number-spin-box';

import CreateTextArea from './widgets/text-area';
import CreateHiddenTextBox from './widgets/hidden-input';
import CreateCheckBox from './widgets/check-box';
import CreateSimpleCheckBox from './widgets/normal-check-box';
 
import BooleanCheckBox from './widgets/boolean-checkbox';
import * as Constant from './../../common/constants';

function CreateWidgets({
    components,
    isValidText,
    handleTextFieldChange,
    uniquefilterArray,
    flowchartNode,
    parametersEnterTheScene,
    flowchartData,
    commandId,
    addNewDecisionToForm,
    currentIndexOriginal,
    // availableBackgrounds,
    characterList,
    // flowchartAllScenes,
    isCommandEdit,
    filterCharacter,
    setFilterCharacter,
    variableListDTO,
    full_name_toggle
}) {

    const [dependentOptions, setDependentOptions] = useState([])
    const [selectedChar, setSelectedChar] = useState()
    const [durationLeaveScene, setDurationLeaveScene] = useState(true)
    const [enableDisableCheckBox, setenableDisableCheckBox] = useState(false)
    const [currentSelected, setCurrentSelected] = useState(null)
    const [dropDownCharChangedEdit, setDropDownCharChangedEdit] = useState(false)
    // const [currentSelectedVariationName, setCurrentSelectedVariationName] = useState(null);
    // const [currentSelectedVariationId, setCurrentSelectedVariationId] = useState(null);
    const [maxLengthTextArea, setMaxLengthTextArea] = useState(null)
    const [hiddenTextBoxData, setHiddenTextBoxData] = useState("#FFFFFF")
    const [handleSetDisabledXandY, setHandleSetDisabledXandY] = useState(true)
    const [currentValueForShakeCamera, setCurrentValueForShakeCamera] = useState(null)
    const [numberBoxDisabled, setNumberBoxDisabled] = useState(null)
    const [numberBoxDefaultValue, setNumberBoxDefaultValue] = useState(null)
    const [variableFields, setVariableFields] = useState(false);
    const [addGenderedText, setAddGenderedText] = useState(false);
    // const [addDecisionOperationBoolean, setAddDecisionOperationBoolean] = useState(false);

    const [gotoChapterRequired, setGotoChapterRequired] = useState(true);
    let headingNameCheck = null;
    let headingNameFlag = true;
    let addGenderedTextVar = 'false';
    // let addDecisionOperation = false;

    const handleColorChange = (color) => {
        setHiddenTextBoxData(color.hex);
    }

    const handleSetDuration = (checkLeaveScene) => {
        setDurationLeaveScene(checkLeaveScene);
    }

    const enableDisableUsingCheckBox = (values) => {
        setenableDisableCheckBox(values);
    }

    const handleCurrentSelectedClick = (selected) => {
        setCurrentSelected(selected);
    }

    const changeValueWidget = (valueDropDown) => {
        setVariableFields(valueDropDown);
    }

    const handleChangeAddGenderedText = (value) => {
        setAddGenderedText(value);
    }

    const fnDecideNCreateAWidget = (widgetsDataDetails, widgetName, defaultValue, isCommandEdit) => {
        switch (widgetsDataDetails.type) {
            case "dropdown":
                return (
                    <CreateDropdownWidget
                        component={widgetsDataDetails}
                        index={widgetName}
                        key={widgetName}
                        setFilterCharacter={setFilterCharacter}
                        filterCharacter={filterCharacter}
                        selectedChar={selectedChar}
                        setSelectedChar={setSelectedChar}
                        flowchartNode={flowchartNode}
                        parametersEnterTheScene={parametersEnterTheScene}
                        flowchartData={flowchartData}
                        commandId={commandId}
                        handleSetDuration={handleSetDuration}
                        currentSelected={currentSelected}
                        enableDisableUsingCheckBox={enableDisableUsingCheckBox}
                        enableDisableCheckBox={enableDisableCheckBox}
                        handleCurrentSelectedClick={handleCurrentSelectedClick}
                        characterList={characterList}
                        SelectedValue={defaultValue}
                        isCommandEdit={isCommandEdit}
                        setCurrentValueForShakeCamera={setCurrentValueForShakeCamera}
                        setHandleSetDisabledXandY={setHandleSetDisabledXandY}
                        currentValueForShakeCamera={currentValueForShakeCamera}
                        setNumberBoxDefaultValue={setNumberBoxDefaultValue}
                        setNumberBoxDisabled={setNumberBoxDisabled}
                        changeValueWidget={changeValueWidget}
                        variableFields={variableFields}
                        variableListDTO={variableListDTO}
                        addGenderedText={addGenderedText}
                        gotoChapterRequired={gotoChapterRequired}
                        dropDownCharChangedEdit={dropDownCharChangedEdit}
                        setDropDownCharChangedEdit={setDropDownCharChangedEdit}
                        fullNameToggle={full_name_toggle}
                    />
                );

            case "textbox":
            case "disabledTextbox":
                return (
                    <CreateTextBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        dependentOptions={dependentOptions}
                        setDependentOptions={setDependentOptions}
                        isValidText={isValidText}
                        handleTextFieldChange={handleTextFieldChange}
                        commandId={commandId}
                        durationLeaveScene={durationLeaveScene}
                        allBlockList={flowchartNode}
                        enableDisableUsingCheckBox={enableDisableUsingCheckBox}
                        defaultValue={defaultValue}
                        isCommandEdit={isCommandEdit}
                        variableFields={variableFields}
                        variableListDTO={variableListDTO}
                        addGenderedText={addGenderedText}
                    />
                )

            case "numberBox":
                return (
                    <NumberBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        dependentOptions={dependentOptions}
                        setDependentOptions={setDependentOptions}
                        isValidText={isValidText}
                        handleTextFieldChange={handleTextFieldChange}
                        commandId={commandId}
                        durationLeaveScene={durationLeaveScene}
                        enableDisableUsingCheckBox={enableDisableUsingCheckBox}
                        defaultValueNumber={numberBoxDefaultValue !== null ? numberBoxDefaultValue : defaultValue}
                        disabledNumber={numberBoxDisabled !== null ? numberBoxDisabled : ''}
                        isCommandEdit={isCommandEdit}
                        currentValueForShakeCamera={currentValueForShakeCamera}
                        handleSetDisabledXandY={handleSetDisabledXandY}
                        setNumberBoxDefaultValue={setNumberBoxDefaultValue}
                        selectedChar={selectedChar}
                    />
                )

            case "textarea":
                return (
                    <CreateTextArea
                        key={widgetName}
                        component={widgetsDataDetails}
                        dependentOptions={dependentOptions}
                        setDependentOptions={setDependentOptions}
                        maxLengthTextArea={maxLengthTextArea}
                        defaultValue={defaultValue}
                        isCommandEdit={isCommandEdit}
                        addGenderedText={addGenderedText}
                        commandId={commandId}
                        addGenderedTextVar={addGenderedTextVar}
                    />
                )
            // Checkbox with slide flipp
            case "checkbox":
                return (
                    <CreateCheckBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        isCommandEdit={isCommandEdit}
                        handleChangeAddGenderedText={handleChangeAddGenderedText}
                        commandId={commandId}
                        setGotoChapterRequired={setGotoChapterRequired}
                    />
                )

            // checkbox with check symbol    
            case "normalCheckbox":
                return (
                    <CreateSimpleCheckBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        enableDisableUsingCheckBox={enableDisableUsingCheckBox}
                        enableDisableCheckBox={enableDisableCheckBox}
                    />
                )

            case "spinbox":
                return (
                    <SpinBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        defaultValue={defaultValue}
                        isCommandEdit={isCommandEdit}
                    />)
                 

            case "colorPicker":
                return (
                    <CreateHiddenTextBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        hiddenTextBoxData={hiddenTextBoxData}
                        handleColorChange={handleColorChange}
                        defaultValue={defaultValue}
                        isCommandEdit={isCommandEdit}
                    />

                );
                 

            case "booleancheckbox":
                return (
                    <BooleanCheckBox
                        key={widgetName}
                        component={widgetsDataDetails}
                        variableFields={variableFields}
                        commandId={commandId}
                        variableListDTO={variableListDTO}
                        addGenderedText={addGenderedText}

                    />
                );
                

            default:
                return (<p>Coming Soon</p>);
        }
    }

    return components.map(comp => (
        comp.widgets.map((widgetsData) => {
            if (headingNameCheck !== comp.headingName) {
                headingNameCheck = comp.headingName
                headingNameFlag = true;
            } else {
                headingNameFlag = false;
            }

            // if (commandId === Constant.COMMAND_ADD_DIALOGUE
            //     && widgetsData.widgetName === 'genderedCheckbox') {
            //     addGenderedTextVar = widgetsData.properties.defaultValue;
            // }

            return (
                <div
                    key={widgetsData.widgetName}
                >
                    {
                        headingNameFlag === true && (comp.invisible === false) &&
                        <label className="commandLevelHeading" key={comp.headingName}> {comp.headingName} </label>
                    }
                    {comp.invisible === false &&
                        <React.Fragment key={widgetsData.widgetName}>
                            {fnDecideNCreateAWidget(widgetsData, widgetsData.widgetName, widgetsData.properties.defaultValue, isCommandEdit, uniquefilterArray)}
                        </React.Fragment>
                    }
                </div>
            )
        })
    )
    )
}

export default CreateWidgets
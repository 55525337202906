import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as PlayerStyled from '../style';
import * as Constants from './../../../common/constants';
import { PlayerService } from './player-service';
import * as Config from './../../../config/app.config';

// set up text to print, each item in array is new line
var aText = [];
var iSpeed = 25; // time delay of print out
var iIndex = 0; // start printing array at this posision
var iArrLength = 0; // the length of the text array
var iScrollAt = 20; // start scrolling up at this many lines

var iTextPos = 0; // initialise text position
var sContents = ''; // initialise contents variable
var iRow; // initialise current row


const parseDialogueDiv = (command, skipTimeout, gameInterfaceList, blobImages, dialogue, gameFonts) => {
    let dialogueElement = document.getElementById('quickdialgoue');
    
    aText = [escapeHtml(dialogue)];
 
    iArrLength = aText[0].length;
    iIndex = 0;
    iTextPos = 0;
    if (dialogueElement !== null) {
        if (skipTimeout) {
            document.getElementById('quickdialgoue').innerHTML = ReactDOMServer.renderToString(
                <div>
                    {
                        command.parameters.Character !== '' && command.parameters.Character !== undefined &&
                        <PlayerStyled.dialogueHeader parameters={command.characterDTO != null ? command.characterDTO.labelColor : ''}>
                            {command.parameters.characterDisplayName !== undefined ? command.parameters.characterDisplayName : command.parameters.character}
                        </PlayerStyled.dialogueHeader>
                    }
                    <div className="characterDialgoue typewriter">
                        <h6 dangerouslySetInnerHTML={{ __html: `${command.parameters['UI Text']}` }}></h6>
                    </div>
                </div>
            );
        } else {
            let backgroundImageKey = gameInterfaceList[Constants.interfaceList[0]];
            let continueIconKey = gameInterfaceList[Constants.interfaceList[2]];
            let nameBackgroundImageKey = gameInterfaceList[Constants.interfaceList[1]];
            let defaultContinueIconKey = Constants.defaultInterfaceImageList[2];
            let defaultBackgroundImageKey = Constants.defaultInterfaceImageList[0];
            let defaultNameBackgroundImageKey = Constants.defaultInterfaceImageList[1];

            let backgroundImage = backgroundImageKey != null && blobImages.hasOwnProperty(backgroundImageKey) ? blobImages[backgroundImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultBackgroundImageKey;
            let continueIconImage = continueIconKey != null && blobImages.hasOwnProperty(continueIconKey) ? blobImages[continueIconKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultContinueIconKey;
            let nameImage = nameBackgroundImageKey != null && blobImages.hasOwnProperty(nameBackgroundImageKey) ? blobImages[nameBackgroundImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultNameBackgroundImageKey;

                document.getElementById('quickdialgoue').innerHTML = ReactDOMServer.renderToString(
                    <PlayerStyled.dialogueBox
                        Dialoguebackground={backgroundImage}
                        Continueicon={continueIconImage}
                        NameImage = {nameImage}
                        color={command.characterDTO != null ? command.characterDTO.labelColor : ''}
                        gameFonts = {gameFonts}>
                        {
                            command.parameters.Character !== '' && command.parameters.Character !== undefined &&
                            <div className="right-mobile-title" id="namePlate">
                                {command.parameters.characterDisplayName !== undefined ? command.parameters.characterDisplayName : command.parameters.character}
                            </div>
                        }
                        <div className="right-mobile-text" id="dialogBackground">
                            <div id="typedtext"></div>
                            {
                                <div className="" id="icon"></div>
                            }
                        </div>
                    </PlayerStyled.dialogueBox>
                );
        }

        typewriter();
    }

}

const replaceNombre = (command, localVariableListDTO) => {
    let nombre = '';
    let pronouns = 1;
    let nombreVariable = PlayerService.getVariable(localVariableListDTO, Constants.reservedVariables[0]);
    let pronounsVariable = PlayerService.getVariable(localVariableListDTO, Constants.reservedVariables[1]);

    if (localVariableListDTO !== null && nombreVariable.value !== '') {
        nombre = nombreVariable.value;
    }

    let dialogue = command.parameters['UI Text'];
    let dialoguePronounceMap = {0: '3', 1: '1', 2: '2'};

    if (pronounsVariable.value === '') {
        pronounsVariable.value = 0;
    }

    if (command.parameters.hasOwnProperty('UI Text2')) {
        pronouns = dialoguePronounceMap[pronounsVariable.value];
        dialogue = command.parameters['UI Text' + pronouns];
        if (pronouns === '1') {
            dialogue = command.parameters['UI Text']    
        }
    }
    if (dialogue !== undefined) {
        dialogue = dialogue.replace('{$nombre}', nombre);
    }

    return dialogue;
}

const replaceUpright = (localVariableListDTO, dialogue) => {
    let uprightVariable = PlayerService.getVariable(localVariableListDTO, Constants.reservedVariables[2]);
    
    let uprightVar = true;
    if (Math.sign(uprightVariable.value) === -1) {

        uprightVar = false;
    }

    dialogue = dialogue.replace('{$upright}', uprightVar);

    return dialogue;
}

const replaceVariables = (localVariableListDTO, dialogue) => {
    if (localVariableListDTO.length === 0) {
        return dialogue;
    }
    localVariableListDTO.map(variable => {
        if (!Constants.reservedVariables.includes(variable.name)
            && variable.value !== '') {
            dialogue = dialogue.replace(`{$${variable.name}}`, variable.value);
        }
    })

    return dialogue;
}


const escapeHtml = (str) => {
  return str
  .replace(/</g, "&lt;")
  .replace(/>/g, "&gt;")
  .replace(/{span/g, "<span")
  .replace(/"}/g, '">')
  .replace(/{\/span}/g, "</span>");
}

const typewriter = () => {
    sContents =  ' ';
    iRow = Math.max(0, iIndex-iScrollAt);
    var destination = document.getElementById("typedtext");
    
    if (destination && destination.innerText.length > iArrLength) {
        return false;
    }

    while ( iRow < iIndex) {
        sContents += aText[iRow++] + '<br />';
    }
 
    if (destination && aText[iIndex]) {
        destination.innerHTML = sContents + aText[iIndex].substring(0, iTextPos);
    }

    if ( iTextPos++ === iArrLength ) {
        iTextPos = 0;
        iIndex++;
        if (aText[iIndex] != undefined &&  iIndex != aText.length ) {
            iArrLength = aText[iIndex].length;
            setTimeout(typewriter, 500);
        }

        if (document.getElementById('icon')) {
            document.getElementById('icon').className += 'continue-icon';
        }
    } else {
        setTimeout(typewriter, iSpeed);
    }
}

export const DialogueCommands = {
    parseDialogueDiv,
    //customDialogue,
    replaceNombre,
    replaceUpright,
    replaceVariables
}
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LibraryStyle } from '../../library/style/style';

function StoryReviewErrorModal(props) {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={props.handleHideAssetLockModal}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="header-text"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">{props.storyReviewTitle}</label>
            <label className="sub-heading">{props.storyReviewSubTitle}</label>
          </div>
          {props.storyReviewScenes.length > 0 &&
          props.storyReviewTitle ===
            'This story cannot be submitted for a review as it is missing a Set outfit command.' ? (
            <div className="Modal-extra-content">
              <LibraryStyle.tableContainer>
                <thead>
                  <tr>
                    <th className="text-left col-md-4 text-break d-inline-block p-3">
                      {' '}
                      CHAPTER{' '}
                    </th>
                    <th className="text-left col-md-4 text-break d-inline-block p-3">
                      {' '}
                      CHARACTER{' '}
                    </th>
                    <th className="text-left col-md-4 text-break d-inline-block p-3">
                      {' '}
                      LINE NO{' '}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.storyReviewScenes.map((scene, index) => (
                    <tr key={`scenes-${index}`}>
                      <td className="text-small-light col-md-4 text-left text-break d-inline-block p-3">
                        {scene.sceneName}
                      </td>
                      <td className="text-small-light col-md-4 text-left text-break d-inline-block p-3">
                        {scene.characterNames}
                      </td>
                      <td className="text-small-light col-md-4 text-left text-break d-inline-block p-3">
                        {scene.commandLineNumbers}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </LibraryStyle.tableContainer>
            </div>
          ) : (
            props.storyReviewScenes.length > 0 && (
              <div className="Modal-extra-content">
                <LibraryStyle.tableContainer>
                  <thead>
                    <tr>
                      <th className="text-left w-50 text-break d-inline-block p-3">
                        CHAPTER
                      </th>

                      {/* <th className="text-left w-25 text-break d-inline-block p-3">
                                    USER
                                </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.storyReviewScenes.map((scene, index) => (
                      <tr key={`scenes-${index}`}>
                        <td className="text-small-light text-left text-break d-inline-block p-3">
                          {scene}
                        </td>

                        {/* <td className="text-small-light text-left w-25  text-ellipse d-inline-block p-3">
                                            {asset.lockAcquiredByUsername}
                                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </LibraryStyle.tableContainer>
              </div>
            )
          )}
        </div>
        <div className="modal-button-wrapper justify-content-around">
          <Button
            onClick={props.handleHideStoryErrorModal}
            className="btn-snacktool btn-snacktool-golden"
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StoryReviewErrorModal;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as ActionTypes from '../../actions/action-types';
import * as LibraryActions from './../../actions/library-action';

//import StoryListContainer from '../../components/home/home-component.jsx';

import AddStoryModal from '../../components/story/create-story/AddStoryModal';
import * as Constant from './../../components/story/create-story//constant';
import * as StoryActions from './../../actions/story-action';
import { LibraryServices } from './../../components/story/create-story/service';

import StoryListing from './../../components/story/story-listing';
import FailStoryEdit from '../../components/common/Modals/Confirm';
import ReviewStoryModal from './../../components/story/Review-story/storyReviewModal';
import Warning from './../../components/story/Review-story/warning';
import Success from './../../components/story/Review-story/successReview';
import InviteModal from './../../components/story/accept-invite/InviteModal';

import LoginTutorial from './../../components/story/login-tutorial/LoginTutorial';

import InviteExpireModal from './../../components/story/accept-invite/inviteExpireModal';

import DeleteStoryModal from './../../components/story/Delete-story/storyDeleteModal';
import DeleteSuccess from './../../components/story/Delete-story/successDelete';

import { Container, Row, Button } from 'react-bootstrap';
import * as HomeStyled from './styles';
import StoryBlankPage from './../../components/story/story-blank-page.jsx';
import SuccessCollaborator from '../../components/common/Modals/Success';
import SuccessRevoke from '../../components/common/Modals/Success';

//Collaborator Component
import AddCollaborator from '../../components/story/collaborator/AddCollaborator';
import RevokeCollabrator from '../../components/story/collaborator/RevokeCollabrator';

import AddViewer from '../../components/story/viewer/AddViewer';
import RevokeViewer from '../../components/story/viewer/RevokeViewer';

import LockedStory from './../../components/library/list-assets/delete-assets/LockedStory';
import * as LoginActionTypes from '../../actions/action-types';

import StoryReviewErrorModal from './../../components/story/Review-story/story-review-error-modal';
import AddCredit from './../../components/story/story-credit/storyCredit';

const StoryPage = props => {
  const storyConstant = {
    title: '',
    description: '',
    genres: [],
    tags: [],
    titleCover: '',
    seriesBackgroundDimension: '',
    titleCoverDimension: '',
    artTitleDimension: '',
    storyType: 'RouteStory',
    cost: 0
  };

  // Add asset state
  const [activeTabKey, setActiveTabKey] = useState(Constant.FIRST_TAB_KEY);
  const [finishPressed, setFinishPressed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failEdit, setFailEdit] = useState(false);
  //const [validated, setValidated] = useState(false);
  const [files, setFiles] = useState([]);
  const [story, setStory] = useState(storyConstant);
  const [intervalId, setIntervalId] = useState(0);
  const [reviewstoryId, setReviewStoryId] = useState(null);
  const [deletestoryId, setDeleteStoryId] = useState(null);
  const [isImageRemove, setIsImageRemove] = useState(false);
  const [storyStatus, setStoryStatus] = useState(null);
  const [showUndoModal, setShowUndoModal] = useState(false);
  //CollaboratorModal
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [collaboratorStoryId, setCollaboratorStoryId] = useState();
  const [storyCreatedBy, setStoryCreatedBy] = useState();
  const [collaboratorError, setCollaboratorError] = useState(null);

  const [showViewerModal, setShowViewerModal] = useState(false);
  const [viewerStoryId, setShowViewerStoryId] = useState();
  // const [storyCreatedBy, setStoryCreatedBy] = useState();
  const [viewerError, setViewerError] = useState(null);

  const [invitationModalPopup, setInvitationModalPopup] = useState(false);
  const [invitationModalViewerPopup, setInvitationModalViewerPopup] = useState(
    false
  );

  const [succesRevokeModalPopup, setSuccesRevokeModalPopup] = useState(false);

  const [revokeModalPopup, setRevokeModalPopup] = useState(false);
  const [revokeViewerModalPopup, setRevokeViewerModalPopup] = useState(false);

  const [collaboratorName, setCollaboratorName] = useState(null);
  const [viewerName, setViewerName] = useState(null);

  const [defaultcollaboratorName, setdefaultCollaboratorName] = useState('');
  const [defaultviewerName, setdefaultViewerName] = useState('');

  // Series background
  const [seriesBackgroundFiles, setSeriesBackgroundFiles] = useState([]);

  const [artTitleFiles, setArtTitleFiles] = useState([]);

  const [titleCoverFiles, setTitleCoverFile] = useState([]);

  const [storyCredit, setStoryCreditModal] = useState(false);
  const [creditValue, setCreditValue] = useState('');

  //const [refreshList, setrefreshList] = useState(false);

  let token = props.authToken;

  useEffect(() => {
    props.fetchStoryList(props.authToken);
    if (props.editStory) setActiveTabKey(Constant.FIRST_TAB_KEY);
    setFinishPressed(false);
    props.fetchGenereList(token);
    setStory(storyConstant);
    setFiles([]);
    setReviewStoryId(null);
    setDeleteStoryId(null);
    setStoryStatus(null);
    props.setSelectedMenu('characters');
    props.fetchAccountList(props.authToken);
  }, [props.editStory, invitationModalPopup]);

  const handleSetStoryId = (
    storyIdAction,
    status,
    readOnly = false,
    storyTitle = '',
    viewOnly = false,
    unityStoryId = ''
  ) => {
    props.setStoryId(storyIdAction, storyTitle, '/home', unityStoryId);
    if (
      status !== 'New' &&
      status !== 'In Progress' &&
      status !== 'In Revision'
    ) {
      props.setStoryViewStatus(true);
    }

    if (status === 'Published' || status === 'Submitted') {
      props.setRouteInfoViewStatus();
    }

    if (readOnly === true) {
      props.setStoryViewStatus();
    }

    if (viewOnly === true) {
      props.setStoryViewStatus(true);
    }

    ///&& status !== 'Pending Approval'
    if (status !== 'In Progress' && status !== 'In Revision') {
      props.setStoryAddComment(true, status);
    } else {
      props.setStoryAddComment(false, status);
    }
  };
  const handleValidStoryName = event => {
    if (props.editStory && story.title === props.storyDetails.title) {
      // props.setStoryNameValidity(story.title, props.authToken);
    } else {
      props.setStoryNameValidity(story.title, props.authToken);
    }
  };

  const handleSaveInfo = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false && props.editStory !== true) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (activeTabKey === Constant.FIRST_TAB_KEY) {
        if (!props.storyNameValidity) setActiveTabKey(Constant.SECOND_TAB_KEY);
      }

      if (activeTabKey === Constant.THIRD_TAB_KEY) {
        if (files && files.length > 0) setActiveTabKey(Constant.FOURTH_TAB_KEY);
        const intervalId = interval();
        setIntervalId(intervalId);
        props.saveStory(
          LibraryServices.getFormData(
            story,
            files,
            props.editStory,
            props.storyDetails,
            seriesBackgroundFiles,
            titleCoverFiles,
            artTitleFiles
          ),
          props.authToken,
          props.editStory,
          props.storyId
        );
        setFinishPressed(true);
      }
    }

    // setValidated(true);
  };

  const handleUploadClick = () => {
    setFinishPressed(false);
    setActiveTabKey(Constant.FIRST_TAB_KEY);
    setFiles([]);
    setSeriesBackgroundFiles([]);
    setArtTitleFiles([]);
    setTitleCoverFile([]);
    setStory(storyConstant);
    //setValidated(false);
    props.setStoryNameValidity(story.title, props.authToken);

    props.handleShowModal();
  };

  const interval = () => {
    return setInterval(() => {
      setProgress(progress => progress + 25);
    }, 800);
  };

  const handleSuccesClick = () => {
    //props.fetchStoryList(props.authToken);
    setFiles([]);
    setSeriesBackgroundFiles([]);
    setArtTitleFiles([]);
    setTitleCoverFile([]);
    props.handleHideModal(false);
    setActiveTabKey(Constant.FIRST_TAB_KEY);
  };

  const handleEditClick = (storyId, storyStatus) => {
    if (storyStatus === 'Pending Approval' || storyStatus === 'Approved') {
      // props.handleShowModal(true);
      setFailEdit(true);
    } else {
      props.getStoryDetails(storyId, props.authToken);
      props.handleShowModal(true);
    }
  };

  const handleDeleteClick = storyId => {
    setDeleteStoryId(storyId);
    props.handleShowDeleteModal();
  };

  const handleHideDeleteModal = () => {
    props.handleHideDeleteModal();
  };

  const handleHideReviewModal = () => {
    props.handleHideReviewModal();
  };

  const handleRollbackAsset = () => {
    props.handleRollbackAsset(story.title, props.authToken);
  };

  const handleStoryDelete = () => {
    props.setStoryDelete(props.authToken, deletestoryId);
    //send for review
  };

  const handleViewStoryStatus = () => {
    //send for review
  };

  const handleShowCollaborator = (clickedStoryId, createdBy) => {
    props.fetchCollaboratorList(props.authToken, clickedStoryId);
    props.fetchShowViewerList(props.authToken, clickedStoryId);

    setShowCollaboratorModal(true);

    setCollaboratorStoryId(clickedStoryId);
    setStoryCreatedBy(createdBy);
  };

  const handleShowViewer = (clickedStoryId, createdBy) => {
    props.fetchShowViewerList(props.authToken, clickedStoryId);
    props.fetchCollaboratorList(props.authToken, clickedStoryId);

    setShowViewerModal(true);
    setShowViewerStoryId(clickedStoryId);
    setStoryCreatedBy(createdBy);
  };

  const handleHideCollaborator = () => {
    setShowCollaboratorModal(false);
    setCollaboratorStoryId(null);
    setStoryCreatedBy(null);
    setCollaboratorError('');
    setdefaultCollaboratorName('');
  };

  const handleHideViewer = () => {
    setShowViewerModal(false);
    setShowViewerStoryId(null);
    setStoryCreatedBy(null);
    setViewerError('');
    setCollaboratorError('');
    setdefaultViewerName('');
  };

  const addNewCollaborator = (event, storyId) => {
    const form = event.currentTarget;
    const collaboratorName = form['collaboratorName'].value;
    setCollaboratorError('');

    let isPresent = false;
    let error = false;
    if (props.writerListAll && props.writerListAll.length > 0) {
      isPresent = props.writerListAll.some(function(el) {
        return el.username == collaboratorName;
      });
      if (isPresent == false) {
        isPresent = props.writerListAll.some(function(el) {
          return el.email == collaboratorName;
        });
      }
      if (isPresent === false) {
        error = true;
        setCollaboratorError(
          'Please enter a valid Story Creator account email address or username'
        );
      }
    }

    if (
      props.collaboratorList &&
      props.collaboratorList.length > 0 &&
      error !== true
    ) {
      isPresent = props.collaboratorList.some(function(el) {
        return el.user.username == collaboratorName;
      });
      if (isPresent == false) {
        isPresent = props.collaboratorList.some(function(el) {
          return el.user.email == collaboratorName;
        });
      }
      if (isPresent === true) {
        error = true;
        setCollaboratorError(
          'This user is already a collaborator on this story.'
        );
      }
    }

    if (props.viewerList && props.viewerList.length > 0 && error !== true) {
      isPresent = props.viewerList.some(function(el) {
        return el.user.username == collaboratorName;
      });
      if (isPresent == false) {
        isPresent = props.viewerList.some(function(el) {
          return el.user.email == collaboratorName;
        });
      }
      if (isPresent === true) {
        error = true;
        setCollaboratorError(
          'This user is already a viewer. To add the user as a collaborator, revoke viewer access first, then add as collaborator.'
        );
      }
    }

    if (error !== true) {
      if (props.tokenDataUser.email === collaboratorName) {
        error = true;
        setCollaboratorError(
          'This is the logged-in user, cannot add as a collaborator'
        );
      } else if (props.tokenDataUser.username === collaboratorName) {
        error = true;
        setCollaboratorError(
          'This is the logged-in user, cannot add as a collaborator'
        );
      }
    }

    if (error !== true) {
      setCollaboratorError('');
      props.addCollaboratorToStory(
        props.authToken,
        collaboratorStoryId,
        collaboratorName
      );
      setdefaultCollaboratorName('');
      setInvitationModalPopup(true);
    }
    event.preventDefault();
  };

  const addNewViewer = (event, storyId) => {
    const form = event.currentTarget;
    const viewerName = form['collaboratorName'].value;
    setCollaboratorError('');

    let isPresent = false;
    let error = false;
    if (props.writerListAll && props.writerListAll.length > 0) {
      isPresent = props.writerListAll.some(function(el) {
        return (
          el.username == viewerName &&
          el.roles[0].name !== 'ROLE_ADMIN' &&
          el.roles[0].name !== 'ROLE_EDITOR'
        );
      });
      if (isPresent == false) {
        isPresent = props.writerListAll.some(function(el) {
          return (
            el.email == viewerName &&
            el.roles[0].name !== 'ROLE_ADMIN' &&
            el.roles[0].name !== 'ROLE_EDITOR'
          );
        });
      }
      if (isPresent === false) {
        error = true;
        setCollaboratorError(
          'Please enter a valid Story Creator account email address or username'
        );
      }
    }

    if (props.viewerList && props.viewerList.length > 0 && error !== true) {
      isPresent = props.viewerList.some(function(el) {
        return el.user.username == viewerName;
      });
      if (isPresent == false) {
        isPresent = props.viewerList.some(function(el) {
          return el.user.email == viewerName;
        });
      }
      if (isPresent === true) {
        error = true;
        setCollaboratorError('This user is already a viewer on this story.');
      }
    }

    if (
      props.collaboratorList &&
      props.collaboratorList.length > 0 &&
      error !== true
    ) {
      isPresent = props.collaboratorList.some(function(el) {
        return el.user.username == viewerName;
      });
      if (isPresent == false) {
        isPresent = props.collaboratorList.some(function(el) {
          return el.user.email == viewerName;
        });
      }
      if (isPresent === true) {
        error = true;
        setCollaboratorError(
          'This user is already a collaborator. To add the user as a viewer, revoke collaborator access first, then add as viewer.'
        );
      }
    }

    if (error !== true) {
      if (props.tokenDataUser.email === viewerName) {
        error = true;
        setCollaboratorError(
          'This is the logged-in user, cannot add as a viewer'
        );
      } else if (props.tokenDataUser.username === viewerName) {
        error = true;
        setCollaboratorError(
          'This is the logged-in user, cannot add as a viewer'
        );
      }
    }
    if (error !== true) {
      setCollaboratorError('');
      props.addViewerToStory(props.authToken, viewerStoryId, viewerName);
      setdefaultViewerName('');
      setInvitationModalViewerPopup(true);
      form['collaboratorName'].value = '';
    }
    event.preventDefault();
  };

  const handleReviewClick = (storyId, status) => {
    setReviewStoryId(storyId);
    setStoryStatus(status);
    if (status !== 'In Progress' && status !== 'In Revision') {
      props.handleShowReviewModal();
    } else {
      props.checkIfValid(props.authToken, storyId);
    }
  };

  const handleCreditClick = (storyId, credit) => {
    setStoryCreditModal(true);
    setReviewStoryId(storyId);
    setCreditValue(credit);
  };

  const handleAddCreditsClick = (e, credit) => {
    props.addStoryCredit(props.authToken, reviewstoryId, credit);
    setTimeout(() => setStoryCreditModal(false), 1000);
  };

  const handleUndoReviewClick = (storyId, isUndo, status) => {
    setReviewStoryId(storyId);
    if (isUndo === true) {
      props.handleShowReviewModal();
      setStoryStatus(status);
    } else {
      setShowUndoModal(true);
      // showUndoModal, setShowUndoModal
    }
    //storyStatus, setStoryStatus
  };

  const handleHideUndoReviewModal = () => {
    setShowUndoModal(false);
  };

  const handleSubmitPublishClick = (storyId, status) => {
    setReviewStoryId(storyId);
    props.handleShowReviewModal();
    setStoryStatus(status);
    //storyStatus, setStoryStatus
  };

  const handleStoryReview = () => {
    props.setStoryReview(props.authToken, reviewstoryId, storyStatus);
    //send for review
  };

  const onHideInviteModal = () => {
    props.closeInvite();
    //send for review
  };

  const handleHideAssetLockModal = () => {
    props.closeLockedModal();
    //send for review
  };

  const hideInvitationModal = () => {
    setInvitationModalPopup(false);
    setInvitationModalViewerPopup(false);
  };

  const revokeCollaboratorAccess = (event, storyId, collaboratorName) => {
    setRevokeModalPopup(true);
    setCollaboratorStoryId(storyId);
    setCollaboratorName(collaboratorName);
    event.preventDefault();
  };

  const revokeViewerAccess = (event, storyId, viewerNameCheck) => {
    setRevokeViewerModalPopup(true);
    setShowViewerStoryId(storyId);
    setViewerName(viewerNameCheck);
    event.preventDefault();
  };

  const resendViewerAccess = (event, storyId, viewerName) => {
    props.revokeViewerToStory(
      props.authToken,
      props.viewerStoryId,
      viewerName,
      false
    );
    event.preventDefault();
    setInvitationModalViewerPopup(true);
  };

  const resendCollaboratorAccess = (event, storyId, collaboratorName) => {
    props.revokeCollaboratorToStory(
      props.authToken,
      props.viewerStoryId,
      collaboratorName,
      false
    );
    event.preventDefault();
    setInvitationModalPopup(true);
  };

  const hideRevokeModal = () => {
    setRevokeModalPopup(false);
    setRevokeViewerModalPopup(false);
    setCollaboratorStoryId(null);
    setCollaboratorName(null);
  };

  const handleRevokeAccess = (event, storyId, collaboratorName) => {
    props.revokeCollaboratorToStory(
      props.authToken,
      collaboratorStoryId,
      collaboratorName,
      true
    );
    event.preventDefault();
    setRevokeModalPopup(false);

    setCollaboratorName(null);
    setSuccesRevokeModalPopup(true);
  };

  const handleRevokeAccessViewer = (event, storyId, viewerNameCheck) => {
    props.revokeViewerToStory(props.authToken, viewerStoryId, viewerName, true);
    event.preventDefault();
    setRevokeViewerModalPopup(false);

    setCollaboratorName(null);
    setViewerName(null);
    setSuccesRevokeModalPopup(true);
  };

  const hideSuccessRevokeModal = () => {
    setSuccesRevokeModalPopup(false);
  };

  const handleHideTutorial = () => {
    props.setTutorials(props.tutorials, props.authToken, 'firstLogin');
  };

  const handleHideStoryModal = () => {
    props.handleHideModal();
    setSeriesBackgroundFiles([]);
    setArtTitleFiles([]);
  };

  const handleHideStoryErrorModal = () => {
    props.resetStoryReviewErrorModal();
    setReviewStoryId(null);
  };

  const setFailEditChange = () => {
    setFailEdit(false);
  };

  return (
    <div>
      <HomeStyled.StoryContainer>
        <HomeStyled.StoryCreateButton>
          {/* <img
            onClick={handleUploadClick}
            src="../../images/new_image/create_story2.png"
          /> */}
          <Button
            className="btn-snacktool btn-snacktool-golden"
            onClick={handleUploadClick}
          >
            Create Story
          </Button>
        </HomeStyled.StoryCreateButton>
        <HomeStyled.Row>
          {ListingComponent(
            props.storyLists,
            handleSetStoryId,
            handleEditClick,
            handleReviewClick,
            setCreditValue,
            handleCreditClick,
            setStoryCreditModal,

            storyCredit,
            handleAddCreditsClick,

            creditValue,
            handleDeleteClick,
            props.showReviewModal,
            props.reviewConfirm,
            props.showDeleteModal,
            props.deleteConfirm,
            handleStoryReview,
            handleStoryDelete,
            handleHideDeleteModal,
            handleHideReviewModal,
            handleViewStoryStatus,
            handleSubmitPublishClick,
            storyStatus,
            handleUndoReviewClick,
            showUndoModal,
            handleHideUndoReviewModal,
            handleShowCollaborator,
            handleShowViewer,
            showCollaboratorModal,
            setShowCollaboratorModal,
            showViewerModal,
            setShowViewerModal,
            handleHideCollaborator,
            handleHideViewer,
            addNewCollaborator,
            addNewViewer,
            collaboratorStoryId,
            viewerStoryId,
            props.collaboratorList,
            props.viewerList,
            setCollaboratorError,
            revokeCollaboratorAccess,
            revokeViewerAccess,
            props.writerListAll,
            collaboratorError,

            hideInvitationModal,
            invitationModalPopup,
            invitationModalViewerPopup,

            props.tokenDataUser,
            revokeModalPopup,
            revokeViewerModalPopup,
            hideRevokeModal,
            handleRevokeAccess,
            handleRevokeAccessViewer,
            collaboratorName,
            succesRevokeModalPopup,
            hideSuccessRevokeModal,
            resendCollaboratorAccess,
            resendViewerAccess,
            defaultcollaboratorName,
            setdefaultCollaboratorName,
            defaultviewerName,
            setdefaultViewerName,
            props.showLockedModal,
            props.lockedObject,
            handleHideAssetLockModal,
            props.storyReviewErrorModal,
            props.storyReviewTitle,
            props.storyReviewSubTitle,
            props.storyReviewScenes,
            handleHideStoryErrorModal,
            props.storyReviewWarning,
            props.reviewLoading,
            props.showReviewModalChapterNotAvailable,
            reviewstoryId,
            props.storyStatus
          )}
        </HomeStyled.Row>
      </HomeStyled.StoryContainer>
      {props.showModal === true && (
        <AddStoryModal
          showModal={props.showModal}
          onHideModal={handleHideStoryModal}
          continuetoaddinfoclass=""
          activeTabKey={LibraryServices.getActiveTabKey(
            props.storyStatus,
            activeTabKey,
            props.handleHideModal,
            intervalId
          )}
          // validated={validated}
          showclearfile={false.toString()}
          asset={story}
          genreList={props.genreList}
          setAsset={data => setStory(data)}
          setActiveTabKey={data => setActiveTabKey(data)}
          progress={progress}
          handleSaveInfo={event => handleSaveInfo(event)}
          handleValidStoryName={event => handleValidStoryName(event)}
          storyNameValidity={props.storyNameValidity}
          files={files}
          errors={props.errors}
          setFiles={data => setFiles(data)}
          handleRollbackAsset={() => handleRollbackAsset()}
          handleSuccesClick={() => handleSuccesClick()}
          editStory={props.editStory}
          storyDetails={props.storyDetails}
          setIsImageRemove={setIsImageRemove}
          seriesBackgroundFiles={seriesBackgroundFiles}
          setArtTitleFiles={setArtTitleFiles}
          artTitleFiles={artTitleFiles}
          setStoryNameValidity={props.setStoryNameValidity}
          authToken={props.authToken}
          setSeriesBackgroundFiles={setSeriesBackgroundFiles}
          setTitleCoverFile={setTitleCoverFile}
          titleCoverFiles={titleCoverFiles}
          storyLists={props.storyLists}
          finishPressed={finishPressed}
          storyStatus={props.storyStatus}
        />
      )}
      {props.isAcceptInvite === true && (
        <InviteModal
          show={props.isAcceptInvite}
          onHideInviteModal={onHideInviteModal}
          iniviteStoryId={props.iniviteStoryId}
          inviteAccept={props.inviteAccept}
          storyLists={props.storyLists}
        />
      )}
      {props.invitationCancel === false &&
        props.isAcceptInvite === false &&
        props.invitationExpired === false &&
        props.showLoginTutorial === true && (
          <LoginTutorial
            show={props.showLoginTutorial}
            handleHideTutorial={handleHideTutorial}
          />
        )}

      {props.invitationExpired === true && (
        <InviteExpireModal
          show={props.invitationExpired}
          onHideExpiredModal={onHideInviteModal}
          invitationExpired={props.invitationExpired}
          invitationCancel={props.invitationCancel}
        />
      )}

      {props.invitationCancel === true && (
        <InviteExpireModal
          show={props.invitationCancel}
          onHideExpiredModal={onHideInviteModal}
          invitationExpired={props.invitationExpired}
          invitationCancel={props.invitationCancel}
        />
      )}
      {failEdit === true && (
        <FailStoryEdit
          firstLineLabel={
            'To update a story, the story should be in REJECTED or REVISION or NEW or IN PROGRESS state'
          }
          handleClearCharacter={setFailEditChange}
          onClose={setFailEditChange}
          confirm={'Okay'}
        />
      )}
    </div>
  );
};

const ListingComponent = (
  storyListing,
  handleSetStoryId,
  handleEditClick,
  handleReviewClick,
  setCreditValue,
  handleCreditClick,
  setStoryCreditModal,
  storyCredit,
  handleAddCreditsClick,
  creditValue,
  handleDeleteClick,
  // creditValue,
  // setCreditValue,
  showReviewModal,
  // storyCredit,
  reviewConfirm,
  showDeleteModal,
  deleteConfirm,
  handleStoryReview,
  handleStoryDelete,
  handleHideDeleteModal,
  handleHideReviewModal,
  handleViewStoryStatus,
  handleSubmitPublishClick,
  storyStatus,
  handleUndoReviewClick,
  showUndoModal,
  handleHideUndoReviewModal,
  handleShowCollaborator,
  handleShowViewer,
  showCollaboratorModal,
  setShowCollaboratorModal,
  showViewerModal,
  setShowViewerModal,
  handleHideCollaborator,
  handleHideViewer,
  addNewCollaborator,
  addNewViewer,
  viewerStoryId,
  collaboratorStoryId,
  collaboratorList,
  viewerList,
  setCollaboratorError,
  revokeCollaboratorAccess,
  revokeViewerAccess,
  writerListAll,
  collaboratorError,
  hideInvitationModal,
  invitationModalPopup,
  invitationModalViewerPopup,
  tokenDataUser,
  revokeModalPopup,
  revokeViewerModalPopup,
  hideRevokeModal,
  handleRevokeAccess,
  handleRevokeAccessViewer,
  collaboratorName,
  succesRevokeModalPopup,
  hideSuccessRevokeModal,
  resendCollaboratorAccess,
  resendViewerAccess,
  defaultcollaboratorName,
  setdefaultCollaboratorName,
  defaultviewerName,
  setdefaultViewerName,
  showLockedModal,
  lockedObject,
  handleHideAssetLockModal,
  storyReviewErrorModal,
  storyReviewTitle,
  storyReviewSubTitle,
  storyReviewScenes,
  handleHideStoryErrorModal,
  storyReviewWarning,
  showReviewModalChapterNotAvailable,
  reviewLoading,
  reviewstoryId
) => {
  if (storyListing !== undefined && storyListing.length > 0) {
    return (
      <div>
        <Row className="main_page">
          {storyListing.map((storyData, index) => (
            <StoryListing
              key={index}
              storyData={storyData}
              setStoryIdAction={handleSetStoryId}
              handleEditClick={handleEditClick}
              handleReviewClick={handleReviewClick}
              handleDeleteClick={handleDeleteClick}
              handleCreditClick={handleCreditClick}
              handleViewStoryStatus={handleViewStoryStatus}
              handleSubmitPublishClick={handleSubmitPublishClick}
              handleUndoReviewClick={handleUndoReviewClick}
              handleShowCollaborator={handleShowCollaborator}
              handleShowViewer={handleShowViewer}
              addNewCollaborator={addNewCollaborator}
              addNewViewer={addNewViewer}
              reviewLoading={reviewLoading}
              reviewstoryId={reviewstoryId}
              // handleCreditClick={handleCreditClick}
            />
          ))}
        </Row>
        {showReviewModal === true &&
          reviewConfirm === false &&
          storyReviewTitle !== '' &&
          storyReviewErrorModal === false &&
          showReviewModalChapterNotAvailable != 'true' && (
            <ReviewStoryModal
              showModal={showReviewModal}
              handleStoryReview={handleStoryReview}
              storyStatus={storyStatus}
              handleHideReviewModal={handleHideReviewModal}
              storyReviewWarning={storyReviewWarning}
            />
          )}

        {showReviewModal === true &&
          reviewConfirm === false &&
          storyReviewErrorModal === false &&
          storyStatus !== 'In Progress' &&
          storyStatus !== 'In Revision' && (
            <ReviewStoryModal
              showModal={showReviewModal}
              handleStoryReview={handleStoryReview}
              storyStatus={storyStatus}
              handleHideReviewModal={handleHideReviewModal}
              storyReviewWarning={storyReviewWarning}
            />
          )}
        {showUndoModal === true && (
          <Warning
            showModal={showUndoModal}
            handleHideUndoReviewModal={handleHideUndoReviewModal}
          />
        )}

        {showCollaboratorModal === true && (
          <AddCollaborator
            showCollaboratorModal={showCollaboratorModal}
            handleHideCollaborator={handleHideCollaborator}
            addNewCollaborator={addNewCollaborator}
            collaboratorStoryId={collaboratorStoryId}
            collaboratorList={collaboratorList}
            setCollaboratorError={setCollaboratorError}
            revokeCollaboratorAccess={revokeCollaboratorAccess}
            writerListAll={writerListAll}
            collaboratorError={collaboratorError}
            tokenDataUser={tokenDataUser}
            resendCollaboratorAccess={resendCollaboratorAccess}
            defaultcollaboratorName={defaultcollaboratorName}
            setdefaultCollaboratorName={setdefaultCollaboratorName}
            // handleHideUndoReviewModal={handleHideUndoReviewModal}
          />
        )}
        {revokeModalPopup === true && (
          <RevokeCollabrator
            showModal={revokeModalPopup}
            collaboratorName={collaboratorName}
            hideRevokeModal={hideRevokeModal}
            collaboratorStoryId={collaboratorStoryId}
            handleRevokeAccess={handleRevokeAccess}
          />
        )}
        {reviewConfirm === true && (
          <Success
            showModal={reviewConfirm}
            handleHideReviewModal={handleHideReviewModal}
            storyStatus={storyStatus}
          />
        )}

        {revokeViewerModalPopup === true && (
          <RevokeViewer
            showModal={revokeViewerModalPopup}
            collaboratorName={collaboratorName}
            hideRevokeModal={hideRevokeModal}
            collaboratorStoryId={viewerStoryId}
            handleRevokeAccess={handleRevokeAccessViewer}
          />
        )}
        {/* 913 */}

        {showViewerModal === true && (
          <AddViewer
            showCollaboratorModal={showViewerModal}
            handleHideCollaborator={handleHideViewer}
            addNewCollaborator={addNewViewer}
            collaboratorStoryId={viewerStoryId}
            collaboratorList={viewerList}
            setCollaboratorError={setCollaboratorError}
            revokeCollaboratorAccess={revokeViewerAccess}
            writerListAll={writerListAll}
            collaboratorError={collaboratorError}
            tokenDataUser={tokenDataUser}
            resendCollaboratorAccess={resendViewerAccess}
            defaultcollaboratorName={defaultviewerName}
            setdefaultCollaboratorName={setdefaultViewerName}
            // handleHideUndoReviewModal={handleHideUndoReviewModal}
          />
        )}

        {storyCredit === true && (
          <AddCredit
            creditValue={creditValue}
            setCreditValue={setCreditValue}
            showCreditModal={storyCredit}
            setStoryCreditModal={setStoryCreditModal}
            handleAddCredits={handleAddCreditsClick}
          />
        )}

        {showDeleteModal === true && deleteConfirm === false && (
          <DeleteStoryModal
            showModal={showDeleteModal}
            handleStoryDelete={handleStoryDelete}
            handleHideDeleteModal={handleHideDeleteModal}
          />
        )}

        {showDeleteModal === true && deleteConfirm === true && (
          <DeleteSuccess
            showModal={showDeleteModal}
            handleHideDeleteModal={handleHideDeleteModal}
          />
        )}

        {invitationModalPopup === true && (
          <SuccessCollaborator
            label="Invitation Sent by email"
            note="After the user accepts this invitation, the new collaborator will grant access to this story to edit its content."
            handleClick={hideInvitationModal}
          ></SuccessCollaborator>
        )}

        {invitationModalViewerPopup === true && (
          <SuccessCollaborator
            label="Invitation Sent by email"
            note="After the user accepts this invitation, the new viewer will grant access to this story to view its content."
            handleClick={hideInvitationModal}
          ></SuccessCollaborator>
        )}

        {succesRevokeModalPopup === true && (
          <SuccessRevoke
            label="Access has been revoked successfully."
            Successfully
            note=""
            handleClick={hideSuccessRevokeModal}
          ></SuccessRevoke>
        )}
        {showLockedModal === true && (
          <LockedStory
            showModal={showLockedModal}
            lockedAssetData={lockedObject}
            handleHideAssetLockModal={handleHideAssetLockModal}
            type="story"
          />
        )}
        {storyReviewErrorModal === true && (
          <StoryReviewErrorModal
            storyReviewTitle={storyReviewTitle}
            storyReviewSubTitle={storyReviewSubTitle}
            storyReviewScenes={storyReviewScenes}
            handleHideStoryErrorModal={handleHideStoryErrorModal}
          />
        )}
      </div>
    );
  } else {
    return <StoryBlankPage />;
  }
};

const mapStateToProps = state => {
  return {
    authToken: state.LoginReducer.authToken,
    storyLists: state.StoryReducer.storyLists,
    showModal: state.StoryReducer.showModal,
    storyNameValidity: state.StoryReducer.storyNameValidity,
    genreList: state.StoryReducer.genreList,
    storyStatus: state.StoryReducer.storyStatus,
    loading: state.StoryReducer.loading,
    storyId: state.StoryReducer.storyId,
    errors: state.StoryReducer.errors,
    editStory: state.StoryReducer.editStory,
    storyDetails: state.StoryReducer.storyDetails,
    showReviewModal: state.StoryReducer.showReviewModal,
    reviewConfirm: state.StoryReducer.reviewConfirm,
    showDeleteModal: state.StoryReducer.showDeleteModal,
    deleteConfirm: state.StoryReducer.deleteConfirm,
    commentLists: state.CommentReducer.commentLists,
    filterCommnetList: state.CommentReducer.filterCommnetList,
    collaboratorList: state.StoryReducer.collaboratorList,
    viewerList: state.StoryReducer.viewerList,
    writerListAll: state.StoryReducer.writerListAll,
    tokenDataUser: state.LoginReducer.tokenDataUser,
    tokenData: state.LoginReducer.tokenData,
    isAcceptInvite: state.LoginReducer.isAcceptInvite,
    iniviteStoryId: state.LoginReducer.iniviteStoryId,
    invitationExpired: state.LoginReducer.invitationExpired,
    invitationCancel: state.LoginReducer.invitationCancel,
    inviteAccept: state.LoginReducer.inviteAccept,
    showLockedModal: state.StoryReducer.showLockedModal,
    lockedObject: state.StoryReducer.lockedObject,
    showLoginTutorial: state.LoginReducer.showLoginTutorial,
    tutorials: state.LoginReducer.tutorials,
    storyReviewErrorModal: state.StoryReducer.storyReviewErrorModal,
    storyReviewTitle: state.StoryReducer.storyReviewTitle,
    storyReviewSubTitle: state.StoryReducer.storyReviewSubTitle,
    storyReviewScenes: state.StoryReducer.storyReviewScenes,
    storyReviewWarning: state.StoryReducer.storyReviewWarning,
    showReviewModalChapterNotAvailable:
      state.StoryReducer.showReviewModalChapterNotAvailable,
    reviewLoading: state.StoryReducer.reviewLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchStoryList: authToken =>
      dispatch({
        type: ActionTypes.GET_STORY_LIST_DETAILS,
        authToken: authToken
      }),
    fetchGenereList: authToken =>
      dispatch(StoryActions.getStoryGenere(authToken)),

    saveStory: (story, authToken, editStory, storyId) =>
      dispatch(StoryActions.setStory(story, authToken, editStory, storyId)),

    setStoryNameValidity: (name, authToken) =>
      dispatch(StoryActions.setStoryNameValidity(name, authToken)),
    setStoryViewStatus: flag => dispatch(StoryActions.setStoryViewStatus(flag)),
    setRouteInfoViewStatus: () =>
      dispatch(StoryActions.setRouteInfoViewStatus()),

    handleShowModal: editStory =>
      dispatch(StoryActions.setShowModalTrue(editStory)),
    handleHideModal: editStory =>
      dispatch(StoryActions.setShowModalFalse(editStory)),

    setStoryId: (storyId, title, backPage, unityStoryId) =>
      dispatch({
        type: ActionTypes.SET_STORY_ID,
        storyId: storyId,
        storyTitle: title,
        backPage: backPage,
        unityStoryId: unityStoryId
      }),
    getStoryDetails: (storyId, authToken) =>
      dispatch({
        type: ActionTypes.GET_STORY_DETAILS,
        storyId: storyId,
        authToken: authToken
      }),
    closeInvite: () =>
      dispatch({
        type: ActionTypes.SET_FAIL_INVITE_ACCEPT,
        expired: false,
        canceled: false
      }),

    setTutorials: (data, authToken, flag) =>
      dispatch({
        type: ActionTypes.SET_TUTORIAL_DATA,
        payload: data,
        authToken: authToken,
        flag: flag
      }),

    //LoginActionTypes

    // fetchFlowchart: (authToken, storyId) => dispatch({ type: ActionTypes.GET_FLOWCHART_DETAILS, authToken: authToken, storyId: storyId }),
    handleShowReviewModal: () =>
      dispatch(StoryActions.setShowReviewModalTrue()),
    handleHideReviewModal: () =>
      dispatch(StoryActions.setShowReviewModalFalse()),
    setStoryReview: (authToken, storyId, storyStatus) =>
      dispatch(StoryActions.setStoryReview(authToken, storyId, storyStatus)),

    ///check if chpaters are lockaed before sending it for review
    checkIfValid: (authToken, storyId) =>
      dispatch(StoryActions.checkStoryValid(authToken, storyId)),
    closeLockedModal: () => dispatch(StoryActions.checkStoryLockedFail()),

    //// Delete Story

    handleShowDeleteModal: () =>
      dispatch(StoryActions.setShowDeleteModalTrue()),
    handleHideDeleteModal: () =>
      dispatch(StoryActions.setShowDeleteModalFalse()),
    setStoryDelete: (authToken, storyId) =>
      dispatch(StoryActions.setStoryDelete(authToken, storyId)),
    setStoryAddComment: status =>
      dispatch(StoryActions.setStoryAddComment(status)),

    //Collaborator
    fetchCollaboratorList: (authToken, storyId) =>
      dispatch(StoryActions.getCollaboratorList(authToken, storyId)),

    fetchShowViewerList: (authToken, storyId) =>
      dispatch(StoryActions.getViewerList(authToken, storyId)),
    fetchAccountList: authToken =>
      dispatch(StoryActions.fetchWritersList(authToken)),
    addCollaboratorToStory: (authToken, storyId, collaboratorName) =>
      dispatch(
        StoryActions.addCollaborator(authToken, storyId, collaboratorName)
      ),
    addViewerToStory: (authToken, storyId, viewerName) =>
      dispatch(StoryActions.addViewer(authToken, storyId, viewerName)),
    revokeCollaboratorToStory: (
      authToken,
      storyId,
      collaboratorName,
      isRevoke
    ) =>
      dispatch(
        StoryActions.revokeCollaborator(
          authToken,
          storyId,
          collaboratorName,
          isRevoke
        )
      ),
    revokeViewerToStory: (authToken, storyId, viewerName, isRevoke) =>
      dispatch(
        StoryActions.revokeViewer(authToken, storyId, viewerName, isRevoke)
      ),

    resetStoryReviewErrorModal: () =>
      dispatch(StoryActions.resetStoryReviewError()),
    setSelectedMenu: menuName =>
      dispatch(LibraryActions.setSelectedLibraryMenu(menuName)),
    addStoryCredit: (authToken, storyId, storyCredits) =>
      dispatch(StoryActions.addStoryCredit(authToken, storyId, storyCredits))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryPage);

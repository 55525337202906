import * as ActionTypes from '../actions/place-background-action-types';

export const initialState = {
  backgroundImageList: [],
  activeKey: 'browse',
  background: null,
  fadeIn: 0.5,
  name: '',
  zoom: 0,
  x: 0,
  y: 0,
  height: 0,
  width: 0,
  aspect: 3 / 4,
  validated: false,
  views: [],
  backgroundCopy: null,
  characterRoute: '',
  captionText: '',
  exportToGallary: false,
  foundInJuicy: false,
  searchViews: [],
  viewObjects: [],
  saveViewObject: {},
  fadeIntoSeconds: null,
  autoSaveFlag: false,
  editableFlag: false,
  editableData: [],
  collaboratorModal: false,
  otherView:[]
};

export const PlaceBackgroundReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BACKGROUND_IMAGES:
      return {
        ...state,
        backgroundImageList: []
      };

    case ActionTypes.GET_BACKGROUND_IMAGES_SUCCESS:
      return {
        ...state,
        backgroundImageList: action.imageList
      };

    case ActionTypes.GET_BACKGROUND_IMAGES_FAIL:
      return {
        ...state,
        backgroundImageList: []
      };

    case ActionTypes.SET_ACTIVE_KEY:
      return {
        ...state,
        activeKey: action.activeKey
      };

    case ActionTypes.SET_BACKGROUND_PROPERTIES:
      return {
        ...state,
        [action.key]: action.value
      };

    case ActionTypes.SET_RESET_BACKGROUND_PROPERTIES:
      return {
        ...state,
        background: null,
        fadeIn: 0.5,
        name: '',
        zoom: 0,
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        aspect: 3 / 4,
        validated: false,
        searchViews: [],
        characterRoute: '',
        captionText: '',
        exportToGallary: false,
        foundInJuicy: false,
        backgroundCopy: null,
        viewObjects: [],
        saveViewObject: {},
        fadeIntoSeconds: null,
        autoSaveFlag: false
      };

    case ActionTypes.GET_VIEWS:
    case ActionTypes.GET_VIEWS_FAIL:
      return {
        ...state,
        views: [],
        editableData: [],
        editableFlag: false
      };

    case ActionTypes.GET_VIEWS_SUCCESS:
      return {
        ...state,
        views: action.views
      };

    case ActionTypes.ADD_OBJECT:
      state.viewObjects.push(action.payload);
      return {
        ...state
      };

    case ActionTypes.REORDER_OBJECT:
      return {
        ...state,
        viewObjects: action.payload
      };

    case ActionTypes.SET_OBJECT:
      return {
        ...state,
        viewObjects: action.payload
      };

    case ActionTypes.SET_SAVE_VIEW_OBJECT:
      return {
        ...state,
        saveViewObject: action.viewObject,
        fadeIntoSeconds: action.fadeIntoSeconds
      };

    case ActionTypes.RESET_SAVE_VIEW_OBJECT:
      return {
        ...state,
        saveViewObject: {},
        fadeIntoSeconds: null
      };

    case ActionTypes.SET_AUTOSAVE_FLAG:
      return {
        ...state,
        autoSaveFlag: action.payload
      };

    case ActionTypes.RESET_AUTOSAVE_FLAG:
      return {
        ...state,
        autoSaveFlag: false
      };

    case ActionTypes.GET_VIEWS_EDITABLE:
      return {
        ...state,
        editableFlag: false,
        editableData: []
      };
      break;

    case ActionTypes.GET_VIEWS_EDITABLE_SUCCESS:
      return {
        ...state,
        editableFlag: action.editableFlag,
        editableData: action.editableData
      };
      break;

    case ActionTypes.GET_VIEWS_EDITABLE_FAIL:
      return {
        ...state,
        editableFlag: false,
        editableData: []
      };
      break;

    case ActionTypes.GET_VIEWS_COLLABORATOR_MODULE_FLAG:
      return {
        ...state,
        collaboratorModal: action.flagEditable
      };
      break;

    case ActionTypes.GET_OTHER_CREATED_VIEWS:
      return{
        ...state
      }
      break;

    case ActionTypes.GET_OTHER_CREATED_VIEWS_SUCCESS:
      return{
        ...state,
        otherView:action.payload
      }

    default:
      return {
        ...state
      };
  }
};

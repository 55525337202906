import React from 'react';
import { AdminStyle } from '../admin-menu/style/admin-style';
import { Row, Col, Form } from 'react-bootstrap';

function StoryHead(props) {
  return (
    <AdminStyle.listHeading>
      <Row>
        <Col xs={6} md={4}>
          WRITE FICTIF Stories
        </Col>
        <Col
          xs={12}
          md={8}
          className="text-right d-flex align-items-center justify-content-end"
        >
          {/* {props.storyAdminAuthor !== '' &&
            props.storyAdminAuthor !== null &&
            props.storyAdminAuthor !== undefined && (
              <span className="show-result">{`Showing ${props.totalResultStoryList} matching results`}</span>
            )} */}
          <Form
            method="POST"
            onSubmit={evt => props.searchWithAuthor(evt, props.authorName)}
            style={{display:"flex"}}
          >
            <Form.Group className="form-search-box mr-2">
                <Form.Label className="form-label-search">Search By:</Form.Label>
              <Form.Control
                type="select"
                name="selectType"
                as="select"
                id="selectType"
                defaultValue="Creator name"
                className="select-search"
                onChange={evt => props.setSearchBy(evt.target.value)}
              >
                  <option>Story name</option>
                  <option>Creator name</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-search-box">
              <Form.Control
                type="text"
                name="scenename"
                id="scenename"
                placeholder={props.searchBy === 'Creator name' ?"Search stories by Creator" : 'Search stories by title'}
                value={props.authorName}
                onChange={evt => props.setAuthorName(evt.target.value)}
              />
              <i
                className="fa fa-search"
                onClick={evt => props.searchWithAuthor(evt, props.authorName)}
                aria-hidden="true"
              ></i>
            </Form.Group>
          </Form>
          
        </Col>
      </Row>
      <Row className="search_result_count" style={{
          
      }}>
      {props.storyAdminAuthor !== '' &&
            props.storyAdminAuthor !== null &&
            props.storyAdminAuthor !== undefined && (
              <span className="show-result">{`Showing ${props.totalResultStoryList} matching results`}</span>
            )}
      </Row>
    </AdminStyle.listHeading>
  );
}

export default StoryHead;

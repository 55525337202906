import React, { useState, useRef } from 'react';
import {
  Badge,
  Row,
  Col,
  OverlayTrigger,
  Overlay,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { styledIcons } from './../../../common/styles/icons';

function ListAssetRow({
  asset,
  columns,
  alwaysEnabledCheckBox,
  handleAssetSelection,
  selectedAsset,
  selectAll,
  handleShowDeleteModal,
  handleEditAssetClick,
  key,
  listLength
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  let type = '';
  switch (asset.type) {
    case 'Incidental_Sound':
      type = 'SFX';
      break;

    case 'Background_Music':
      type = 'Music';
      break;

    default:
      type = asset.type.replace('_', ' ');
      break;
  }
  return (
    <tr className="table-body-row">
      <td>
        <img
          src={
            asset.type !== 'Background_Music' &&
            asset.type !== 'Incidental_Sound'
              ? `/images/icons/preview.svg`
              : `/images/icons/preview_audio.svg`
          }
          alt="Preview"
          ref={target}
          onClick={() => setShow(!show)}
          className="cursor-pointer mr-3 checkIcon"
        />
        <Overlay
          onHide={() => setShow(false)}
          target={target.current}
          show={show}
          rootCloseEvent="click"
          rootClose={true}
          placement={key > listLength - 3 ? 'right' : 'right'}
        >
          {props => (
            <Tooltip
              className={
                asset.type !== 'Background_Music' &&
                asset.type !== 'Incidental_Sound'
                  ? `gameui-tooltip`
                  : `gameui-tooltip audio-tooltip`
              }
              id="overlay-example"
              {...props}
            >
              <div className="tooltip-bg" key={key}>
                <div className="card-content-tooltip card-content-custom card-body">
                  <aside className="thumbs-container">
                    <div>
                      {asset.type !== 'Background_Music' &&
                      asset.type !== 'Incidental_Sound' ? (
                        <img
                          src={asset.awsPath}
                          key={asset.element}
                          height="100%"
                          width="100%"
                          alt="previewImage"
                        />
                      ) : (
                        <audio
                          autoplay={true}
                          className="audio-play"
                          src={asset.awsPath}
                          key={asset.element}
                          height="100%"
                          width="100%"
                          alt="previewImage"
                          controls
                        />
                      )}
                    </div>
                  </aside>
                </div>
              </div>
            </Tooltip>
          )}
        </Overlay>
        {/* {gameInterface.element} */}
      </td>
      {columns.indexOf('assetName') > -1 && (
        <td className={alwaysEnabledCheckBox === true ? `` : `asset-name-col`}>
          <Row>
            <Col md={8} className="text-left">
              {asset.name}
            </Col>
          </Row>
        </td>
      )}
      {<td className="text">{type}</td>}

      {columns.indexOf('format') > -1 && (
        <td>
          <Badge variant="secondary" className="badges">
            {asset.extension}
          </Badge>
        </td>
      )}
      {columns.indexOf('specs') > -1 && <td className="text">{asset.specs}</td>}
      {columns.indexOf('uploaded') > -1 && (
        <td className="text">
          {moment(new Date(asset.updatedAt)).format('MM-DD-YYYY')}
        </td>
      )}
      {columns.indexOf('actions') > -1 && (
        <td>
          <OverlayTrigger
            key={`edit-asset`}
            placement="top"
            overlay={<Tooltip id={`edit-asset`}>Edit</Tooltip>}
          >
            <styledIcons.editIcon
              title="edit"
              onClick={() => handleEditAssetClick(asset.id, asset.type)}
              className="cursor-pointer"
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={`delete-asset`}
            placement="top"
            overlay={<Tooltip id={`delete-asset`}>Delete</Tooltip>}
          >
            <styledIcons.deleteIcon
              title="delete"
              onClick={() => handleShowDeleteModal(asset.id, asset.type)}
              className="cursor-pointer"
            />
          </OverlayTrigger>
        </td>
      )}
    </tr>
  );
}

export default ListAssetRow;

import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import * as AccoundDetail from './style/myaccount'
import { EMAIL_PATTERN_ACCOUNT, USER_NAME_PATTERN_MY_ACCOUNT } from '../../common/helper/pattern';

const MyAccountDetail = (props) => {
    const [username, setUsername] = useState(props.tokenDataUser.username);
    const [email, setEmail] = useState(props.tokenDataUser.email);
    const [errorUser, setErrorUser] = useState('')
    const [errorEmail, setErrorEmail] = useState('')

    const [errorAfterSubmitUser, setErrorAfterSubmitUser] = useState(null);
    const [errorAfterSubmitEmail, setErrorAfterSubmitEmail] = useState(null);

    const [disable, setDisable] = useState('')
    const [validated, setValidated] = useState(false);


    useEffect(() => {
        setErrorAfterSubmitUser(props.usernameError)
        setErrorAfterSubmitEmail(props.emailError);
    }, props.usernameError, props.emailError);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (new RegExp(USER_NAME_PATTERN_MY_ACCOUNT).test(username) !== true) {
                setErrorUser('Please enter valid username')
            }
            else if (new RegExp(EMAIL_PATTERN_ACCOUNT).test(email) !== true) {
                setErrorEmail('Please enter valid email address')
            } else {
                setValidated(true);
                props.updateMyAccountDetails(props.authToken, username, email)
            }
        }
        event.preventDefault();
    };


    const onChangeUserName = (event) => {
        const user = event.target.value
        setUsername(user)
        if (user === '') {
            setDisable('disabled')
            setErrorUser('Please enter a username')
            setErrorAfterSubmitUser(null)

        } else {
            setErrorUser('')
            setDisable('')
            setErrorAfterSubmitUser(null)
            if (new RegExp(USER_NAME_PATTERN_MY_ACCOUNT).test(user) !== true) {
                setErrorUser('Please enter valid username')
            }
        }
    }

    const onChangeEmail = (event) => {
        const emailCheck = event.target.value
        setEmail(emailCheck)
        if (emailCheck === '') {
            setDisable('disabled')
            setErrorEmail('Please enter an email')
            setErrorAfterSubmitUser(null)
        } else {
            setErrorAfterSubmitEmail(null);
            setErrorEmail('')
            setDisable('')
            if (new RegExp(EMAIL_PATTERN_ACCOUNT).test(emailCheck) !== true) {
                setErrorEmail('Please enter valid email address')
            }
        }
    }

    return (
        <AccoundDetail.MyAccountDetail>
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
            >
                <Form.Row>
                    <Form.Group

                        controlId="formGridusername"
                        className="col-md-5"
                    >
                        <h3 className="title-label">CHANGE YOUR USERNAME</h3>

                        <Form.Label className="form-label">Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            className={errorUser !== '' || (errorAfterSubmitUser !== null && errorAfterSubmitUser !== '') ? 'input-error form-input' : 'form-input'}
                            // onBlur={(event) => onChangeUserName(event)}
                            onChange={(event) => onChangeUserName(event)}
                            value={username}
                            required='required'
                            maxLength="50"
                        />
                        {
                            errorUser !== '' &&
                            <Form.Text className="text-danger">
                                {errorUser}
                            </Form.Text>
                        }
                        {
                            errorAfterSubmitUser !== '' &&
                            <Form.Text className="text-danger">
                                {errorAfterSubmitUser}
                            </Form.Text>
                        }
                    </Form.Group>

                    <Form.Group

                        controlId="formGridEmail"
                        className="col-md-5 offset-md-2"
                    >
                        <h3 className="title-label">YOUR EMAIL</h3>

                        <Form.Label className="form-label">Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="email"
                            className={errorEmail !== '' || (errorAfterSubmitEmail !== null && errorAfterSubmitEmail !== '') ? 'input-error form-input' : 'form-input'}
                            required='required'
                            value={email}
                            onBlur={(event) => onChangeEmail(event)}
                            onChange={(event) => onChangeEmail(event)}
                        />
                        {
                            errorEmail !== '' ?
                                <Form.Text className="text-danger">
                                    {errorEmail}
                                </Form.Text> :
                                ''
                        }
                        {
                            errorAfterSubmitEmail !== '' &&
                            <Form.Text className="text-danger">
                                {errorAfterSubmitEmail}
                            </Form.Text>
                        }
                    </Form.Group>
                </Form.Row>
                <Button
                    variant="primary"
                    className='btn-snacktool btn-snacktool-golden btn btn-primary'
                    type="submit"
                    disabled={disable}
                >
                    Save
                </Button>
            </Form>
        </AccoundDetail.MyAccountDetail>
    )
}

export default MyAccountDetail
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AddAssetTabs from './Tabs';
import * as translations from './../../translations';
import AddAssetsModalBottom from './AddCharacterModalBottom';
import './../../style/style.css';
import * as mmb from 'music-metadata-browser';
import {LibraryServices} from './../service';
import * as Constant from './../../constant';

function AddCharactertModal(props) {
    const {
        asset, 
        activeTabKey, 
        progress, 
        validated,
        files,
        isEdit ,
        variations,
        IsCharacterEdit,
        editAssetName
    } = props;
 
    const [continueToAddInfoClass, setContinueToAddInfoClass] = useState(props.continuetoaddinfoclass);
    const [addInfoClass, setAddInfoClass] = useState(asset.assetType!=='' && asset.assetName!==''?false: true);
    const [showClearFile, setShowClearFile] = useState(isEdit?true:JSON.parse(props.showclearfile));
    const [firstTabLabel, setFirstTabLabel] = useState('');
    const [disableDefaultAssetCheckbox, setDisableDefaultAssetCheckbox] = useState(false);
    const [isAssetExist, setIsAssetExist] = useState(false);

    const [defaultMax, setDefaultMax] = useState(isEdit?LibraryServices.checkDefaultAssetsCount(variations,asset.assetType,asset.defaultAsset):false);

    const [showFileError, setShowFileError] = useState(false);

    const handleFormChange = (event) => {
      
       let defaultAsset=false;

       if (event.currentTarget.form.checkValidity() === false) {
        setAddInfoClass(true);
    }else{
      setAddInfoClass(false);
    }
     
       if(event.target.name==='defaultAsset'){
        defaultAsset=event.target.checked?true:false;
      
        if(editAssetName!==asset.assetName){
          let assetExist=LibraryServices.checkAssetName(variations,asset.assetName);
          setIsAssetExist(assetExist);
          if(assetExist===true){
            setAddInfoClass(true);
          }
          else{
            setAddInfoClass(false);
          }
        }
       }
       if(event.target.name==='assetType'){
         
       let typeAsset='defaultAsset';
       let checkAsset=LibraryServices.checkDefaultAssetCount(variations,event.target.value);
       setDisableDefaultAssetCheckbox(checkAsset);
      if(isEdit){
            let isDefaualtMax=LibraryServices.checkDefaultAssetsCount(variations,event.target.value);
            setDefaultMax(isDefaualtMax);
      }
      if(editAssetName!==asset.assetName){
       let assetExist=LibraryServices.checkAssetName(variations,event.currentTarget.form[1].value);
       setIsAssetExist(assetExist);
      
       
       if(assetExist===true){
         setAddInfoClass(true);
         
         
       }
       else{
         setAddInfoClass(false);
       }
      }
      
       }
 
       
        props.setAsset({
            ...asset,
            [event.target.name]:event.target.name==='defaultAsset'?defaultAsset: event.target.value
        });

      props.setValidated(true);
      props.setAutoSaveFlag(true);
    }


    

    const handleNameBlur = (event) => {
      
if(editAssetName!==event.target.value){
      let assetExist=LibraryServices.checkAssetName(variations,event.target.value);
      setIsAssetExist(assetExist);
      if(assetExist===true){
        setAddInfoClass(true);
      }
      else{
        setAddInfoClass(false);
      }
    }
     
    }
    

    const handleSwitchChange = () => {
        
    }

    const handleActiveKey = (key) => {
  
        if (showClearFile === true) {
          if (typeof files[0] !== 'string'){
            addSpec(LibraryServices.getFileType(files[0]));
          }
            props.setActiveTabKey(key);
        }
        if (key === Constant.SECOND_TAB_KEY) {
            setFirstTabLabel('icon')
        }
    }

    const addSpec = (type) => {
        if (type === 'image') {
            readImageFile(files[0]);
        }
        if (type === 'audio') {
            readAudioFile(files[0]);
        }
    }

    const handleClearFile = () => {
        props.setFiles([]);
        setShowClearFile(false);
        setContinueToAddInfoClass('');
        setFirstTabLabel('');
        setShowFileError(true);
        props.setAutoSaveFlag(true)
    }

  
    function readImageFile(file) {
        var reader = new FileReader(); // CREATE AN NEW INSTANCE.
        reader.onload = function (e) {
            var img = new Image();      
            img.src = e.target.result;
            img.onload = function () {
                var w = this.width;
                var h = this.height;
                props.setAsset({
                    ...asset,
                    specs: `${w}x${h} px`,
                   // extension: LibraryServices.getFileExtension(file),
                });
            }
        };
        return reader.readAsDataURL(file);
    }

    function readAudioFile(file) {
        try {
            parseFile(file);
          } catch(err) {
              console.log('Parsing error in audio file');
          }
    }

    function parseFile(file) {
        return mmb.parseBlob(file, {native: true}).then(metadata => {
          props.setAsset({
                ...asset,
                specs: metadata.format.duration,
               // extension: LibraryServices.getFileExtension(file),
            })
        })
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show = {props.showModal}
            onHide = {props.onHideModal}
            backdrop='static'
            dialogClassName="add-asset-modal-dialog"
            >
            {
                (activeTabKey === Constant.FIRST_TAB_KEY || activeTabKey === Constant.SECOND_TAB_KEY) &&
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="header-text">
                        { activeTabKey === Constant.SECOND_TAB_KEY?translations.ADD_INFO:translations.UPLOAD_NEW_FILE}
                    </Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                <AddAssetTabs
                    activeTabKey = {activeTabKey}
                    handleActiveKey = {handleActiveKey}
                    handleSaveInfo = {props.handleSaveInfo}
                    validated = {validated}
                    setShowClearFile = {(data) => setShowClearFile(data)}
                    setContinueToAddInfoClass = {(data) => setContinueToAddInfoClass(data)}
                    files = {files}
                    setFiles = {props.setFiles}
                    handleFormChange = {(event) => handleFormChange(event)}
                    handleSwitchChange = {(event) => handleSwitchChange(event)}
                  
                    firstTabLabel = {firstTabLabel}
                    progress = {progress}
                    handleRollbackAsset = {props.handleRollbackAsset}
                    asset={asset}
                    isEdit={isEdit}
                    disableDefaultAssetCheckbox={disableDefaultAssetCheckbox}
                    IsCharacterEdit={IsCharacterEdit}
                    handleNameBlur={handleNameBlur}
                    isAssetExist={isAssetExist}
                    editAssetName={editAssetName}
                    setShowFileError={setShowFileError}
                              showFileError={showFileError}
                              defaultMax={defaultMax}
                              setAutoSaveFlag = {props.setAutoSaveFlag}
                    />
                <AddAssetsModalBottom 
                    activeTabKey = {activeTabKey}
                    continueToAddInfoClass = {continueToAddInfoClass} 
                    handleActiveKey = {handleActiveKey}
                    showClearFile = {showClearFile}
                    handleClearFile = {() => handleClearFile()}
                    isEdit={isEdit}
                    addInfoClass={addInfoClass}
                    IsCharacterEdit={IsCharacterEdit}
                    />
            </Modal.Body>
        </Modal>
    );
}

export default AddCharactertModal
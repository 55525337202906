import React from 'react';
import { LibraryStyle} from './../style/style';
 

function  Fail(props) {
    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                    <div className="fail-icon">
                    <img src="/images/icons/ic_clear_24px.svg" className="mediumIcon"/>

                    </div>
                    <div className="submit-asset-label">
                        Element can't be saved. {props.message}
                    </div>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Fail
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { LibraryStyle } from './../../style/style';
import CreateCharacterHead from './CreateCharacterHead';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ListTable from './../list-assets/ListTable';
import * as Constant from './../../../../common/constants';
import * as Translations from './../../translations';
import UploadFile from './UploadFile';
import { useDropzone } from 'react-dropzone';
import { props } from 'lodash/fp';

function SelectCharacterImage({
  filterAssetList,
  selectedAsset,
  handleAssetSelection,
  characterAssetList,
  continueToVariations,
  initializeCreateCharacter,
  handleUploadClick,
  variations,
  files,
  handleDeleteKey,
  handleEditKey,
  redirectToPreview,
  IsCharacterEdit,

  setMaxFileError,
  setShowUploadPopupEnableUpload,
  setFileNames,
  maxFileError,
  outfitCount,
  hairCount,
  expressionCount,
  accessoryCount,
  UploadFileCount,
  backToOne
}) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#E9B7F2',
    backgroundColor: '#bfbfbf',
    opacity: 0.5
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const onDrop = useCallback(acceptedFiles => {
    setMaxFileError(false);
    if (acceptedFiles.length > 0) {
      if (acceptedFiles.length < 16) {
        setShowUploadPopupEnableUpload(true);
        setFileNames(acceptedFiles);
      } else {
        setMaxFileError(true);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    noClick: true,
    disabled: UploadFileCount > 15 ? true : false
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const backToStepOne = () => {
    backToOne();
  };

  return (
    <LibraryStyle.listContentPanel>
      <LibraryStyle.characterContent>
        <CreateCharacterHead
          IsCharacterEdit={IsCharacterEdit}
          initializeCreateCharacter={initializeCreateCharacter}
        />
        <div className="scroll-div">
          <Container>
            <Row className="content-head">
              <Col md={6} className="text-left left-title">
                {Translations.SELECT_CHARACTER_IMAGE}
              </Col>
              <Col md={6} className="text-right">
                <NavLink
                  className="menu-nav back-to-step"
                  onClick={backToStepOne}
                >
                  <img
                    alt=""
                    src="/images/icons/ic_chevron_right.svg"
                    className="mr-1 mb-1 checkIcon"
                  />{' '}
                  {Translations.BACK_TO_STEP_ONE}
                </NavLink>
                <button
                  onClick={redirectToPreview}
                  className={`btn-snacktool btn-snacktool-golden btn btn-primary`}
                >
                  {Translations.CONTINUE_TO_PREVIEW}
                </button>
              </Col>
            </Row>
            <Row>
              <Row>&nbsp;</Row>
              <Col md={12}>
                <Row className="content-head-row">
                  <Col xs={12} md={12}>
                    <Row>
                      <div xs={3} className="mb-4">
                        <UploadFile
                          setFileNames={setFileNames}
                          maxFileError={maxFileError}
                          setMaxFileError={setMaxFileError}
                          setShowUploadPopupEnableUpload={
                            setShowUploadPopupEnableUpload
                          }
                          UploadFileCount={UploadFileCount}
                        />
                      </div>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />

                            {UploadFileCount < 16 && maxFileError === false && (
                              <p
                                style={{
                                  fontStyle: 'italic',
                                  marginLeft: '-140px'
                                }}
                                className="mt-3"
                              >
                                Or drop directly up to 15 files on this page
                                <br />
                                <span
                                  style={{
                                    fontStyle: 'normal',
                                    fontSize: '12px'
                                  }}
                                >
                                  Supported file format: PNG. Size limit per
                                  file: 15 Mb.
                                </span>
                              </p>
                            )}
                            {UploadFileCount > 15 && maxFileError === false && (
                              <p
                                style={{
                                  fontStyle: 'italic',
                                  marginLeft: '-140px'
                                }}
                                className="mt-3"
                              >
                                If you need to upload more files,please finish
                                uoploading the ones below first
                                <br />
                              </p>
                            )}
                            {maxFileError && (
                              <p className="mt-3">
                                <span className="text-danger form-text">
                                  {' '}
                                  Please only load up to 15 files at a time.
                                </span>
                                <br />
                              </p>
                            )}
                            <Row className="character_list">
                              <Col>
                                <Row>&nbsp;</Row>
                                <Row>
                                  <Col>
                                    {' '}
                                    <span className="step-three-col-title">
                                      IMAGE ASSETS
                                    </span>{' '}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <span className="info-label">
                                      You can add all the images of your
                                      character. When placing the character in
                                      the game, only one image type (outfit,
                                      expression, hair, accessory) will be
                                      displayed at the same type.
                                    </span>{' '}
                                  </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                  <Col>
                                    <ListTable
                                      assetList={
                                        variations.length > 0
                                          ? variations
                                          : variations
                                      }
                                      files={files}
                                      loading={false}
                                      handleSorting=""
                                      sorting="desc"
                                      columns={
                                        Constant.ASSET_LIST_COLUMNS_SELECT_ASSET
                                      }
                                      alwaysEnabledCheckBox={true}
                                      handleAssetSelection={
                                        handleAssetSelection
                                      }
                                      selectedAsset={selectedAsset}
                                      handleDeleteKey={handleDeleteKey}
                                      handleEditKey={handleEditKey}
                                      outfitCount={outfitCount}
                                      hairCount={hairCount}
                                      expressionCount={expressionCount}
                                      accessoryCount={accessoryCount}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    {' '}
                    <span className="step-three-col-title">
                      IMAGE ASSETS
                    </span>{' '}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="info-label">
                      You can add all the images of your character. When placing
                      the character in the game, only one image type (outfit,
                      expression, hair, accessory) will be displayed at the same
                      type.
                    </span>{' '}
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <ListTable
                      assetList={
                        variations.length > 0 ? variations : variations
                      }
                      files={files}
                      loading={false}
                      handleSorting=""
                      sorting="desc"
                      columns={Constant.ASSET_LIST_COLUMNS_SELECT_ASSET}
                      alwaysEnabledCheckBox={true}
                      handleAssetSelection={handleAssetSelection}
                      selectedAsset={selectedAsset}
                      handleDeleteKey={handleDeleteKey}
                      handleEditKey={handleEditKey}
                      outfitCount={outfitCount}
                      hairCount={hairCount}
                      expressionCount={expressionCount}
                      accessoryCount={accessoryCount}
                    />
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Container>
        </div>
      </LibraryStyle.characterContent>
    </LibraryStyle.listContentPanel>
  );
}

export default SelectCharacterImage;

import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import VariableCheckBox from './variable-checkbox';
import VariableTextBox from './variable-text-box';
import VariableNumberBox from './variable-number-box';
import VariableDropDownBox from './variable-drop-down-box';

const VariableList = (props) => {
    let conditionalType = props.variableInfo.type
    let displayVariableType = ''
    let dataTypeCheck = props.variableInfo.datatype
    if (conditionalType === 'custom') {
        if (props.variableInfo.scope === 'Cross-chapter') {
            displayVariableType = `(Cross-chapter)`;
        }
    } else if (conditionalType === 'reserved') {
        displayVariableType = `(${props.variableInfo.type})`;
        dataTypeCheck = '';
    }

    let defaultDisabledView = ''
    if (props.activeTabSelected === 'Flowchart') {
        defaultDisabledView = 'disabled'
    }
    return (
        <tr>
            <td>{props.variableInfo.name}</td>
            <td><div className='d-flex align-items-center'>{`${dataTypeCheck} ${displayVariableType}`}
                {
                    conditionalType === 'custom' && props.variableInfo.scope === 'Cross-chapter' &&
                    <OverlayTrigger
                        key={`tooltip-${props.variableInfo.id}`}
                        placement="right"
                        overlay={
                            <Tooltip id={`tooltip-${props.variableInfo.id}`} >
                                From Chapter "{props.variableInfo.chapterName}"
                            </Tooltip>
                        }
                    >
                        <span className="info-icon ml-1" ></span>
                    </OverlayTrigger>

                }
            </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="col-12">
                        {
                            props.variableInfo.datatype === 'Boolean' 
                            && props.variableInfo.name !== 'pronoun' &&
                            <VariableCheckBox
                                value={props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                defaultValueCheckbox={props.variableInfo.defaultValue}
                                defaultDisabledView={defaultDisabledView}
                                onChangeCheckBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                currentStoryStatus={props.currentStoryStatus}
                            />
                        }
                        
                        {
                            (props.variableInfo.datatype === 'Float' 
                            || props.variableInfo.datatype === 'Integer') 
                            && props.variableInfo.name !== 'pronoun' &&
                            <VariableNumberBox
                                value={props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                step={props.variableInfo.datatype === 'Integer' ? 1 : 0.01}
                                defaultDisabledView={defaultDisabledView}
                                onChangeNumberBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                defaultValueNumberbox={props.variableInfo.defaultValue}
                                currentStoryStatus={props.currentStoryStatus}

                            />
                        }
                        {
                            props.variableInfo.datatype === 'Integer' 
                            && props.variableInfo.name === 'pronoun' &&
                            <VariableDropDownBox
                                value={props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                defaultDisabledView={defaultDisabledView}
                                onChangeNumberBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                defaultValueNumberbox={props.variableInfo.defaultValue}
                                currentStoryStatus={props.currentStoryStatus}
                                options = {[]}
                            />
                        }
                        {
                            props.variableInfo.datatype === 'String' 
                            && props.variableInfo.name === 'nombre' &&
                            <VariableTextBox
                                value={props.variableInfo.value === null ? '': props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                defaultValueTextbox={props.variableInfo.defaultValue}
                                defaultDisabledView={defaultDisabledView}
                                onChangeTextBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                currentStoryStatus={props.currentStoryStatus}
                            />
                        }
                        {
                            props.variableInfo.datatype === 'String' 
                            && props.variableInfo.uiFeildType === 'dropdown'
                            && props.variableInfo.name !== 'nombre' &&
                            <VariableDropDownBox
                                value={props.variableInfo.value === null ? '': props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                defaultDisabledView={defaultDisabledView}
                                onChangeNumberBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                defaultValueNumberbox={props.variableInfo.defaultValue}
                                currentStoryStatus={props.currentStoryStatus}
                                options = {props.variableInfo.hasOwnProperty('options') ? props.variableInfo.options : []}
                            />
                        }
                        {
                            props.variableInfo.datatype == 'String' 
                            && props.variableInfo.uiFeildType == 'textbox'
                            && props.variableInfo.name != 'nombre' &&
                            <VariableTextBox
                                value={props.variableInfo.value === null ? '': props.variableInfo.value}
                                id={props.variableInfo.id}
                                key={props.variableInfo.id}
                                defaultValueTextbox={props.variableInfo.defaultValue}
                                defaultDisabledView={defaultDisabledView}
                                onChangeTextBox={props.updateVariableWithId}
                                updateValueArrayIds={props.updateValueArrayIds}
                                currentStoryStatus={props.currentStoryStatus}
                            />
                        }
                    </div>
                </div>
            </td>

        </tr>

    )
}

export default VariableList
export const TITLE_REVIEW = 'Ready for a review?';
export const SUBTITLE_REVIEW =
  'Our Editors will check out your story and get back to you with feedback. You won’t be able to edit your story while it’s being reviewed. Send to review?';
export const BTN_REVIEW = 'Yes, send it for review';
export const TITLE_SUCCESS_REVIEW = 'The story has been sent for review.';
export const SUBTITLE_SUCCESS_REVIEW =
  'You will be notified after our Editors have reviewed it.';

export const TITLE_SUBMIT_PUBLISH = 'Submit this story for publishing?';
export const SUBTITLE_SUBMIT_PUBLISH =
  'The story will be sent to the Editor Admin for publishing.';
export const BTN_SUBMIT_PUBLISH = 'Yes, publish it';

export const TITLE_SUCCESS_SUBMIT_PUBLISH =
  'The story has been submitted for publishing.';
export const SUBTITLE_SUCCESS_SUBMIT_PUBLISH =
  'You will be notified if our Editors publish it.';

export const TITLE_UPDATE_APPROVED =
  'This story has been approved by an Editor';
export const SUBTITLE_UPDATE_APPROVED =
  'If you make changes to it now, it will be moved to In Progress status, and you will later on have to request another review.';
export const SUBTITLE_NEXT_UPDATE_APPROVED = 'Do you want to proceed?';
export const BTN_UPDATE_APPROVED = 'Yes, proceed';

export const TITLE_SUCCESS_UPDATE_APPROVED =
  'The story has been  moved to In Progress';
export const SUBTITLE_SUCCESS_UPDATE_APPROVED = '';

export const TITLE_UPDATE_PUBLISHED =
  'This story has already been published in the Fictif app.';
export const SUBTITLE_UPDATE_PUBLISHED =
  'If you wish to edit this story, it will be moved back to In Progress status. You will have to request approval from an editor and republish the story later.';
export const BTN_UPDATE_PUBLISHED = 'Yes, proceed';

export const TITLE_SUCCESS_UPDATE_PUBLISHED =
  'The story has been moved  to  In Revision';
export const SUBTITLE_SUCCESS_UPDATE_PUBLISHED = '';

export const TITLE_UNDO_REVIEW =
  'This story has been sent for review to an Editor. ';
export const SUBTITLE_UNDO_REVIEW =
  'If you cancel the request to review it, it will be moved  to In Revision status, and you will later on have to request another review.';
export const BTN_UNDO_REVIEW = 'Yes, proceed';

export const TITLE_UPDATE_REJECTED =
  'This story has been rejected by an Editor Admin.';
export const SUBTITLE_UNDO_UPDATE_REJECTED =
  'It will be moved to In Revision status';
export const BTN_UPDATE_REJECTED = 'Yes, proceed';

export const TITLE_SUCCESS_UPDATE_REJECTED =
  'The story has been  moved to In Revision';
export const SUBTITLE_SUCCESS_UPDATE_REJECTED = '';

export const TITLE_SUCCESS_UNDO_REVIEW = 'You have canceled the story review.';
export const SUBTITLE_SUCCESS_UNDO_REVIEW =
  'The story has been moved  to In Revision.';

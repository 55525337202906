import React from 'react';
import { Button } from 'react-bootstrap';

function RequestAccountSuccess(props) {
    return ( 
        <div className="request-account-success text-center">
            <img alt='' src="/images/icons/ic_check_circle_24px.svg" className="check-img mediumIcon" />
            <h3>Your Write Fictif account has been requested!</h3>
            <p>Please wait for the Write Fictif administrators to activate your account. We will notify you by email so please check your inbox.</p>
            <Button
                type="button"
                className="btn-snacktool btn-snacktool-golden"
                onClick = {props.backToLogin}>
                    Okay!
            </Button>
        </div>
    )
}

export default RequestAccountSuccess
import React, { useState, useRef } from 'react';
import {
  Badge,
  Row,
  Col,
  OverlayTrigger,
  Overlay,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { styledIcons } from './../../../common/styles/icons';

function ListAssetRow({
  asset,
  columns,
  alwaysEnabledCheckBox,
  // handleAssetSelection,
  // selectedAsset,
  // selectAll,
  handleShowDeleteModal,
  handleEditAssetClick,
  key,
  handleChangeActiveCover,
  listLength
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  let type = '';
  switch (asset.type) {
    default:
      type = asset.type.replace('_', ' ');
      break;
  }
  return (
    <tr className="table-body-row">
      <td>
        <img
          src={`/images/icons/preview.svg`}
          alt="Preview"
          ref={target}
          onClick={() => setShow(!show)}
          className="cursor-pointer mr-3 checkIcon"
        />
        <Overlay
          onHide={() => setShow(false)}
          target={target.current}
          show={show}
          rootCloseEvent="click"
          rootClose={true}
          placement={key > listLength - 3 ? 'right' : 'right'}
        >
          {props => (
            <Tooltip
              className={`gameui-tooltip`}
              id="overlay-example"
              {...props}
            >
              <div className="tooltip-bg" key={key}>
                <div className="card-content-tooltip card-content-custom card-body">
                  <aside className="thumbs-container">
                    <div>
                      <img
                        src={asset.awsPath}
                        key={asset.element}
                        height="100%"
                        width="100%"
                        alt="previewImage"
                      />
                    </div>
                  </aside>
                </div>
              </div>
            </Tooltip>
          )}
        </Overlay>
        {/* {gameInterface.element} */}
      </td>
      {columns.indexOf('assetName') > -1 && (
        <td className={alwaysEnabledCheckBox === true ? `` : `asset-name-col`}>
          <Row>
            <span className="text-left">{asset.name}</span>
          </Row>
        </td>
      )}
      {<td className="text">{type}</td>}

      {columns.indexOf('format') > -1 && (
        <td>
          <Badge variant="secondary" className="badges">
            {asset.extension}
          </Badge>
        </td>
      )}
      {columns.indexOf('specs') > -1 && <td className="text">{asset.specs}</td>}
      {columns.indexOf('uploaded') > -1 && (
        <td className="text">
          {moment(new Date(asset.updatedAt)).format('MM-DD-YYYY')}
        </td>
      )}
      {columns.indexOf('actions') > -1 && (
        <td>
          <OverlayTrigger
            key={`edit-asset`}
            placement="top"
            overlay={<Tooltip id={`edit-asset`}>Edit</Tooltip>}
          >
            <styledIcons.editIcon
              title="edit"
              onClick={() => handleEditAssetClick(asset.id, asset.type)}
              className="cursor-pointer"
            />
          </OverlayTrigger>

          <OverlayTrigger
            key={`delete-asset`}
            placement="top"
            overlay={<Tooltip id={`delete-asset`}>Delete</Tooltip>}
          >
            <styledIcons.deleteIcon
              title="delete"
              onClick={() => handleShowDeleteModal(asset.id, asset.type)}
              className="cursor-pointer"
            />
          </OverlayTrigger>
        </td>
      )}
      <td>
        <label className="switch-flip">
          <input
            type="checkbox"
            name="toggleCharacter"
            className="checkbox-flip"
            label="Active"
            key={`Active${asset.id}`}
            onChange={event => handleChangeActiveCover(asset.id, asset.active)}
            value={false}
            checked={asset.active == true ? 'checked' : null}
          />
          <span className="slider-flip round"></span>
        </label>
      </td>
    </tr>
  );
}

export default ListAssetRow;

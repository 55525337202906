import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
 
import * as Config from './../../config/app.config';
import RecaptchaComponent from './recaptcha-component';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
  
function RequestAccountForm(props) {

    return (
        <>
            <Form onSubmit={props.onSubmit}
            validated={props.validated}
            noValidate
            id="request-account-form">
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        autoComplete="off"
                        type="email"
                        name="email"
                        required
                        value = {props.email}
                        onChange = {props.onChange}
                        className = {props.requestAccount === false && 'input-error'} />
                    <Form.Control.Feedback type="invalid">
                        {
                            props.email === '' &&
                            'Please enter an email address'
                        }
                    </Form.Control.Feedback>
                    {
                        props.requestAccountError !== null &&
                        <Form.Text className="text-danger">
                            {props.requestAccountError}
                        </Form.Text>
                    }
                    {
                        props.emailError &&
                        <Form.Text className="text-danger">{props.emailError}</Form.Text>
                    }
                </Form.Group>
                <Form.Group>
                    <GoogleReCaptchaProvider reCaptchaKey={Config.CAPTCHA_SECURITY_TOKEN}>
                        {
                            props.verified === false &&
                            <RecaptchaComponent setVerified = {props.setVerified}
                                setShowCaptchaError = {props.setShowCaptchaError}/>
                        }
                    </GoogleReCaptchaProvider>
                    {
                        props.showCaptchaError &&
                        <div className="text-danger">Please verify yourself as human</div>
                    }
                </Form.Group>
            </Form>
            <div className="modal-button-wrapper d-flex justify-content-between">
                <div onClick={props.backToLogin}
                    className="back-login-btn d-flex align-items-center cursor-pointer">
                    <img alt='' src="/images/icons/ic_chevron_right.svg" className="mr-2 checkIcon" /> Back to login
                </div>
                <Button
                    form="request-account-form"
                    type="submit"
                    disabled = {props.disabledState}
                    className="btn-snacktool btn-snacktool-golden">
                        Create account
                </Button>
            </div>
        </>
    )
}

export default RequestAccountForm
import React from 'react';
import { DeleteStyle} from './styles/modalstyles';
import {Button,Modal} from 'react-bootstrap';
 

function  InviteModal(props) {
  // let storyHost='';
 
  // if(props.iniviteStoryId!==null){
  //   storyHost =  props.storyLists.filter(author => {
  //     if(author.id===props.iniviteStoryId)
  //     return author.createdBy;
  //   });

  // }
 
  return (
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={props.show}
    onHide={props.onHideInviteModal}
    >
      <Modal.Body  >
        
      <DeleteStyle.addAssetMessageBox>
            <DeleteStyle.successAssetContent>
              <div className="success-icon mb-3">
                <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
              </div>
              <br/>
              <div  data-testid="success-label" className="submit-asset-label">{`You are a collaborator to the story`}<br/>{props.inviteAccept.storyTitle}</div>
              <div  data-testid="success-note" className="text-muted submit-asset-subtitle ">{`Now you can add content to this story. We suggest you to keep in contact with ${props.inviteAccept.collabAddedBy}.`}</div>
              <div>
                <Button data-testid="success-btn"  onClick={props.onHideInviteModal}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
              </div>
            </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );

  }

export default InviteModal
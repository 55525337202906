import React from 'react';
import { LibraryStyle } from './../style/style';
import { Table, Badge } from 'react-bootstrap';
import moment from 'moment';
import { LibraryServices } from './../service';

function AssetsChangeLog(props) {
    return (
        <React.Fragment>
            <LibraryStyle.listTable >
                {
                    props.assets.length > 0 &&
                    <Table responsive="md">
                        <thead className="bg-light">
                            <tr className="table-head-row">
                                <th className="text-left">FILE NAME</th>
                                <th className="text-left">TYPE</th>
                                <th>FORMAT</th>
                                <th>SPECS</th>
                                <th>
                                    LAST CHANGE ON
                                    <i className={`fa fa-sort-${props.assetsLogSort}`} 
                                        onClick={props.handleSortClick} 
                                        sort-name="assets"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                props.assets.map((asset) => {
                                    return <tr className="table-body-row">
                                        <td className={`asset-name-col text-left`}>
                                            {LibraryServices.makeCamelCaseString(asset.name)}
                                        </td>
                                        <td className="text-left">
                                            {asset.type.split('_').join(' ')}
                                        </td>
                                        <td><Badge variant="secondary" className="badges">{asset.extension}</Badge></td>
                                        <td className="blur-text">{asset.specs}</td>
                                        <td className="blur-text">{moment(new Date(asset.updatedAt)).format('MM-DD-YYYY')}</td>
                                    </tr>
                                })
                            }
                        </tbody>     
                    </Table>
                }
                {
                    props.assets.length === 0 &&
                    <div className="no-result">
                        <h3 className="text-center">No Background and objects have been changed</h3>
                    </div>
                }
            </LibraryStyle.listTable>
        </React.Fragment>
    )
}

export default AssetsChangeLog
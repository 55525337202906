import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { ModalStyled } from './Style';

function WarningToSave(props) {
  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Body className="no-padding">
        <ModalStyled.addAssetMessageBox>
          <ModalStyled.successAssetContent>
            <Row>
              <Col className="submit-asset-label" data-testid="first-label">
                {props.firstLineLabel}
              </Col>
            </Row>
            <Row>
              <Col className="submit-asset-label" data-testid="second-label">
                {props.secondLineLabel}
              </Col>
            </Row>
            <Row>
              {props.cancel && (
                <Col>
                  <Button
                    className="btn-snacktool btn-snacktool-golden"
                    onClick={() => props.onClose()}
                  >
                    {' '}
                    {props.cancel}{' '}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  onClick={() => {
                    props.handleClearCharacter(props.currentSceneId);
                    props.onClose();
                  }}
                  className="btn-snacktool btn-snacktool-golden"
                >
                  {props.confirm ? props.confirm : 'Leave'}
                </Button>
              </Col>
              {props.cancelMain && (
                <Col>
                  <Button
                    onClick={() => {
                      props.cancelMain();
                    }}
                    className="btn-snacktool btn-snacktool-brown"
                  >
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </ModalStyled.successAssetContent>
        </ModalStyled.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );
}

export default WarningToSave;

//List Scene Actions
export const LIST_SCENE = 'LIST_SCENE';
export const LIST_SCENE_SUCCESS = 'LIST_SCENE_SUCCESS';
export const LIST_SCENE_FAILURE = 'LIST_SCENE_FAILURE';

//Update List Scene Actions
export const UPDATE_LIST_SCENE = 'UPDATE_LIST_SCENE';
export const UPDATE_LIST_SCENE_SUCCESS = 'UPDATE_LIST_SCENE_SUCCESS';
export const UPDATE_LIST_SCENE_FAILURE = 'UPDATE_LIST_SCENE_FAILURE';

//Create Scene Actions
export const CREATE_SCENE = 'CREATE_SCENE';
export const CREATE_SCENE_SUCCESS = 'CREATE_SCENE_SUCCESS';
export const CREATE_SCENE_FAILURE = 'CREATE_SCENE_FAILURE';

//Create Route Actions
export const CREATE_CAST = 'CREATE_CAST';
export const CREATE_CAST_SUCCESS = 'CREATE_CAST_SUCCESS';
export const CREATE_CAST_FAILURE = 'CREATE_CAST_FAILURE';

export const RESET_CAST_MODALS = 'RESET_CAST_MODALS';

export const RESET_CAST_RESULT_MODALS = 'RESET_CAST_RESULT_MODALS';

export const GET_CAST = 'GET_CAST';
export const GET_CAST_SUCCESS = 'GET_CAST_SUCCESS';
export const GET_CAST_FAILURE = 'GET_CAST_FAILURE';

export const DELETE_CAST = 'DELETE_CAST';
export const DELETE_CAST_SUCCESS = 'DELETE_CAST_SUCCESS';
export const DELETE_CAST_FAILURE = 'DELETE_CAST_FAILURE';

//Create Route Actions
export const CREATE_ROUTE = 'CREATE_ROUTE';
export const CREATE_ROUTE_SUCCESS = 'CREATE_ROUTE_SUCCESS';
export const CREATE_ROUTE_FAILURE = 'CREATE_ROUTE_FAILURE';

export const RESET_ROUTE_MODALS = 'RESET_ROUTE_MODALS';
export const RESET_CAST = 'RESET_CAST';

export const RESET_ROUTE_RESULT_MODALS = 'RESET_ROUTE_RESULT_MODALS';

export const GET_ROUTE = 'GET_ROUTE';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const GET_ROUTE_FAILURE = 'GET_ROUTE_FAILURE';

export const DELETE_ROUTE = 'DELETE_ROUTE';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const DELETE_ROUTE_FAILURE = 'DELETE_ROUTE_FAILURE';

export const GET_ROUTE_BY_ID = 'GET_ROUTE_BY_ID';
export const GET_ROUTE_BY_ID_SUCCESS = 'GET_ROUTE_BY_ID_SUCCESS';
export const GET_ROUTE_BY_ID_FAIL = 'GET_ROUTE_BY_ID_FAIL';

export const MARK_STORY_ROUTE = 'MARK_STORY_ROUTE';
export const MARK_STORY_ROUTE_SUCCESS = 'MARK_STORY_ROUTE_SUCCESS';
export const MARK_STORY_ROUTE_FAIL = 'MARK_STORY_ROUTE_FAIL';

export const MARK_STORY_DRAFT_STATUS = 'MARK_STORY_DRAFT_STATUS';
export const MARK_STORY_DRAFT_STATUS_SUCCESS =
  'MARK_STORY_DRAFT_STATUS_SUCCESS';
export const MARK_STORY_DRAFT_STATUS_FAIL = 'MARK_STORY_DRAFT_STATUS_FAIL';

// Command Panel: Level 1
export const GET_COMMAND_LEVEL1 = 'GET_COMMAND_LEVEL1';
export const GET_COMMAND_LEVEL1_SUCCESS = 'GET_COMMAND_LEVEL1_SUCCESS';
export const GET_COMMAND_LEVEL1_FAIL = 'GET_COMMAND_LEVEL1_FAIL';

// Command Panel: Level 2
export const GET_COMMAND_LEVEL2 = 'GET_COMMAND_LEVEL2';
export const GET_COMMAND_LEVEL2_SUCCESS = 'GET_COMMAND_LEVEL2_SUCCESS';
export const GET_COMMAND_LEVEL2_FAIL = 'GET_COMMAND_LEVEL2_FAIL';

//FOR SCRIPT EDITOR RENDER COMMAND LEVEL 2 DATA
export const GET_SCRIPT_COMMAND_LEVEL2 = 'GET_SCRIPT_COMMAND_LEVEL2';
export const GET_SCRIPT_COMMAND_LEVEL2_SUCCESS =
  'GET_SCRIPT_COMMAND_LEVEL2_SUCCESS';
export const GET_SCRIPT_COMMAND_LEVEL2_FAIL = 'GET_SCRIPT_COMMAND_LEVEL2_FAIL';

//Command Panel: Level 2: DropDown Options for Variations
export const GET_CHAR_VARIATIONS = 'GET_CHAR_VARIATIONS';
export const GET_CHAR_VARIATIONS_SUCCESS = 'GET_CHAR_VARIATIONS_SUCCESS';
export const GET_CHAR_VARIATIONS_FAIL = 'GET_CHAR_VARIATIONS_FAIL';

//Command Panel: Level 2: DropDown Options for Expressions
export const GET_CHAR_EXPRESSIONS = 'GET_CHAR_EXPRESSIONS';
export const GET_CHAR_EXPRESSIONS_SUCCESS = 'GET_CHAR_EXPRESSIONS_SUCCESS';
export const GET_CHAR_EXPRESSIONS_FAIL = 'GET_CHAR_EXPRESSIONS_FAIL';

//Command Panel: Level 2: DropDown Options for Expressions
export const GET_CHAR_COLOR = 'GET_CHAR_COLOR';
export const GET_CHAR_COLOR_SUCCESS = 'GET_CHAR_COLOR_SUCCESS';
export const GET_CHAR_COLOR_FAIL = 'GET_CHAR_COLOR_FAIL';

// Add assets
export const SET_STORY = 'SET_STORY';
export const SET_STORY_SUCCESS = 'SET_STORY_SUCCESS';
export const SET_STORY_FAIL = 'SET_STORY_FAIL';
export const SET_SHOW_MODAL_TRUE = 'SET_SHOW_MODAL_TRUE';
export const SET_SHOW_MODAL_FALSE = 'SET_SHOW_MODAL_FALSE';

export const SET_CURSOR_POSITION = 'SET_CURSOR_POSITION';

export const SET_VIEW_STORY_STATUS = 'SET_VIEW_STORY_STATUS';

export const SET_VIEW_ROUTE_STATUS = 'SET_VIEW_ROUTE_STATUS';

export const SET_COMMENT_STORY_STATUS = 'SET_COMMENT_STORY_STATUS';

export const SET_STORY_REVIEW = 'SET_STORY_REVIEW';
export const SET_STORY_REVIEW_SUCCESS = 'SET_STORY_REVIEW_SUCCESS';
export const SET_STORY_REVIEW_FAIL = 'SET_STORY_REVIEW_FAIL';

export const CHECK_STORY_LOCKED = 'CHECK_STORY_LOCKED';
export const CHECK_STORY_LOCKED_SUCCESS = 'CHECK_STORY_LOCKED_SUCCESS';
export const CHECK_STORY_LOCKED_FAIL = 'CHECK_STORY_LOCKED_FAIL';

export const SHOW_REVIEW_MODAL_TRUE = 'SHOW_REVIEW_MODAL_TRUE';
export const SHOW_REVIEW_MODAL_FALSE = 'SHOW_REVIEW_MODAL_FALSE';

export const SET_STORY_ADMIN_REVIEW = 'SET_STORY_ADMIN_REVIEW';
export const SET_STORY_ADMIN_REVIEW_SUCCESS = 'SET_STORY_ADMIN_REVIEW_SUCCESS';
export const SET_STORY_ADMIN_REVIEW_FAIL = 'SET_STORY_ADMIN_REVIEW_FAIL';

export const SET_STORY_DUPLICATE = 'SET_STORY_DUPLICATE';
export const SET_STORY_DUPLICATE_SUCCESS = 'SET_STORY_DUPLICATE_SUCCESS';
export const SET_STORY_DUPLICATE_FAIL = 'SET_STORY_DUPLICATE_FAIL';

export const SHOW_ADMIN_REVIEW_MODAL_TRUE = 'SHOW_ADMIN_REVIEW_MODAL_TRUE';
export const SHOW_ADMIN_REVIEW_MODAL_FALSE = 'SHOW_ADMIN_REVIEW_MODAL_FALSE';

export const SET_STORY_DELETE = 'SET_STORY_DELETE';
export const SET_STORY_DELETE_SUCCESS = 'SET_STORY_DELETE_SUCCESS';
export const SET_STORY_DELETE_FAIL = 'SET_STORY_DELETE_FAIL';

export const SHOW_DELETE_MODAL_TRUE = 'SHOW_DELETE_MODAL_TRUE';
export const SHOW_DELETE_MODAL_FALSE = 'SHOW_DELETE_MODAL_FALSE';

export const CHECK_STORY_VALID = 'CHECK_STORY_VALID';
export const CHECK_STORY_VALID_SUCCESS = 'CHECK_STORY_VALID_SUCCESS';
export const CHECK_STORY_VALID_FAIL = 'CHECK_STORY_VALID_FAIL';
export const RESET_STORY_REVIEW_ERROR = 'RESET_STORY_REVIEW_ERROR';

// Character actions
export const SET_STORY_NAME_VALIDITY = 'SET_STORY_NAME_VALIDITY';
export const SET_STORY_NAME_VALIDITY_TRUE = 'SET_STORY_NAME_VALIDITY_TRUE';
export const SET_STORY_NAME_VALIDITY_FALSE = 'SET_STORY_NAME_VALIDITY_FALSE';

export const GET_STORY_GENERE = 'GET_STORY_GENERE';
export const GET_STORY_GENERE_SUCCESS = 'GET_STORY_GENERE_SUCCESS';
export const GET_STORY_GENERE_FAIL = 'GET_STORY_GENERE_FAIL';

// Save Story Progress

export const SET_STORY_SAVE_PROGRESS = 'SET_STORY_SAVE_PROGRESS';
export const SET_STORY_SAVE_PROGRESS_SUCCESS =
  'SET_STORY_SAVE_PROGRESS_SUCCESS';
export const SET_STORY_SAVE_PROGRESS_FAIL = 'SET_STORY_SAVE_PROGRESS_SUCCESS';

export const GET_STORY_ADMIN = 'GET_STORY_ADMIN';
export const GET_STORY_ADMIN_SUCCESS = 'GET_STORY_ADMIN_SUCCESS';
export const GET_STORY_ADMIN_SUCCESS_WITH_AUTHOR =
  'GET_STORY_ADMIN_SUCCESS_WITH_AUTHOR';

export const GET_STORY_ADMIN_FAIL = 'GET_STORY_ADMIN_FAIL';

export const SET_COMMAND_LEVEL = 'SET_COMMAND_LEVEL';

export const RESET_COMMAND_VALUE = 'RESET_COMMAND_VALUE';
export const RESET_CHAPTER_STORY_ERROR = 'RESET_CHAPTER_STORY_ERROR';

///ALL NEW COMMAND PANEL ACTIONS HERE
export const GET_COMMANNDS = 'GET_COMMANNDS';
export const GET_COMMANNDS_SUCCESS = 'GET_COMMANNDS_SUCCESS';
export const GET_COMMANNDS_FAIL = 'GET_COMMANNDS_FAIL';

export const SET_RELEASE_LOCK = 'SET_RELEASE_LOCK';
export const SET_RELEASE_LOCK_SUCCESS = 'SET_RELEASE_LOCK_SUCCESS';
export const SET_RELEASE_LOCK_FAIL = 'SET_RELEASE_LOCK_FAIL';

export const SET_COMMANNDS = 'SET_COMMANNDS';
export const SET_COMMANNDS_SUCCESS = 'SET_COMMANNDS_SUCCESS';
export const SET_COMMANNDS_FAIL = 'SET_COMMANNDS_FAIL';
export const SET_AUTOSAVE_COMMANNDS = 'SET_AUTOSAVE_COMMANDS';
export const SET_AUTOSAVE_COMMANNDS_SUCCESS = 'SET_AUTOSAVE_COMMANNDS_SUCCESS';

export const SET_COMMANNDS_PANEL = 'SET_COMMANNDS_PANEL';
export const SET_COMMANNDS_SUCCESS_PANEL = 'SET_COMMANNDS_SUCCESS_PANEL';
// export const SET_COMMANNDS_FAIL_PANEL = 'SET_COMMANNDS_FAIL_PANEL';

// export const SET_AUTOSAVE_COMMANNDS = 'SET_AUTOSAVE_COMMANDS'
// export const SET_AUTOSAVE_COMMANNDS_SUCCESS = 'SET_AUTOSAVE_COMMANNDS_SUCCESS'

export const GET_COMMANNDS_EDIT = 'GET_COMMANNDS_EDIT';
export const GET_COMMANNDS_EDIT_SUCCESS = 'GET_COMMANNDS_EDIT_SUCCESS';
export const GET_COMMANNDS_EDIT_FAIL = 'GET_COMMANNDS_EDIT_FAIL';

export const GET_AVAILABLE_BLOCKS = 'GET_AVAILABLE_BLOCKS';
export const GET_AVAILABLE_BLOCKS_SUCCESS = 'GET_AVAILABLE_BLOCKS_SUCCESS';
export const GET_AVAILABLE_BLOCKS_FAIL = 'GET_AVAILABLE_BLOCKS_FAIL';

export const UPDATE_COMMAND_SCRIPT_EDITOR = 'UPDATE_COMMAND_SCRIPT_EDITOR';
export const RESET_CHAPTER_STORY_DETAILS = 'RESET_CHAPTER_STORY_DETAILS';

export const GET_PREVIEW_ASSET = 'GET_PREVIEW_ASSET';
export const GET_PREVIEW_ASSET_SUCCESS = 'GET_PREVIEW_ASSET_SUCCESS';
export const GET_PREVIEW_ASSET_FAIL = 'GET_PREVIEW_ASSET_FAIL';

export const GET_COLLABORATOR_LIST = 'GET_COLLABORATOR_LIST';
export const GET_COLLABORATOR_LIST_FAIL = 'GET_COLLABORATOR_LIST_FAIL';
export const GET_COLLABORATOR_LIST_SUCCESS = 'GET_COLLABORATOR_LIST_SUCCESS';

export const GET_VIEWER_LIST = 'GET_VIEWER_LIST';
export const GET_VIEWER_LIST_FAIL = 'GET_VIEWER_LIST_FAIL';
export const GET_VIEWER_LIST_SUCCESS = 'GET_VIEWER_LIST_SUCCESS';

export const ADD_COLLABORATOR_TO_STORY = 'ADD_COLLABORATOR_TO_STORY';
export const ADD_COLLABORATOR_TO_STORY_SUCCESS =
  'ADD_COLLABORATOR_TO_STORY_SUCCESS';
export const ADD_COLLABORATOR_TO_STORY_FAIL = 'ADD_COLLABORATOR_TO_STORY_FAIL';

export const ADD_VIEWER_TO_STORY = 'ADD_VIEWER_TO_STORY';
export const ADD_VIEWER_TO_STORY_SUCCESS = 'ADD_VIEWER_TO_STORY_SUCCESS';
export const ADD_VIEWER_TO_STORY_FAIL = 'ADD_VIEWER_TO_STORY_FAIL';

export const REVOKE_VIEWER_TO_STORY = 'REVOKE_VIEWER_TO_STORY';
export const REVOKE_VIEWER_TO_STORY_SUCCESS = 'REVOKE_VIEWER_TO_STORY_SUCCESS';
export const REVOKE_VIEWER_TO_STORY_FAIL = 'REVOKE_VIEWER_TO_STORY_FAIL';

export const REVOKE_COLLABORATOR_TO_STORY = 'REVOKE_COLLABORATOR_TO_STORY';
export const REVOKE_COLLABORATOR_TO_STORY_SUCCESS =
  'REVOKE_COLLABORATOR_TO_STORY_SUCCESS';
export const REVOKE_COLLABORATOR_TO_STORY_FAIL =
  'REVOKE_COLLABORATOR_TO_STORY_FAIL';

export const GET_WRITER_LIST = 'GET_WRITER_LIST';
export const GET_WRITER_LIST_SUCCESS = 'GET_WRITER_LIST_SUCCESS';
export const GET_WRITER_LIST_FAIL = 'GET_WRITER_LIST_FAIL';

//Variable Window
export const UPDATE_VARIABLE_WINDOW = 'UPDATE_VARIABLE_WINDOW';
export const UPDATE_VARIABLE_WINDOW_SUCCESS = 'UPDATE_VARIABLE_WINDOW_SUCCESS';

export const RESET_ALL_VARIABLE_WINDOW = 'RESET_ALL_VARIABLE_WINDOW';
export const RESET_ALL_VARIABLE_WINDOW_SUCCESS =
  'RESET_ALL_VARIABLE_WINDOW_SUCCESS';

export const CLICK_FLOWCHART = 'CLICK_FLOWCHART';
export const RESET_CLICK_FLOWCHART = 'RESET_CLICK_FLOWCHART';

export const SAVE_FLOWCHART_CHANGES = 'SAVE_FLOWCHART_CHANGES';
export const SUCCESS_SAVE_FLOWCHART_CHANGES = 'SUCCESS_SAVE_FLOWCHART_CHANGES';
export const FAIL_SAVE_FLOWCHART_CHANGES = 'FAIL_SAVE_FLOWCHART_CHANGES';

export const UPDATE_ROUTE_INFO = 'UPDATE_ROUTE_INFO';
export const UPDATE_ROUTE_INFO_SUCCESS = 'UPDATE_ROUTE_INFO_SUCCESS';
export const UPDATE_ROUTE_INFO_FAIL = 'UPDATE_ROUTE_INFO_FAIL';

export const CHANGE_LOG_MODAL_VIEW = 'CHANGE_LOG_MODAL_VIEW';
export const GET_CHANGE_LOG_SCRIPT = 'GET_CHANGE_LOG_SCRIPT';
export const GET_CHANGE_LOG_SCRIPT_SUCCESS = 'GET_CHANGE_LOG_SCRIPT_SUCCESS';
export const GET_CHANGE_LOG_SCRIPT_FAIL = 'GET_CHANGE_LOG_SCRIPT_FAIL';

export const CLOSE_CHANGE_LOG_MODAL = 'CLOSE_CHANGE_LOG_MODAL';

export const SET_AUTHOR_NAME = 'SET_AUTHOR_NAME';
export const SET_SEARCH_BY = 'SET_SEARCH_BY';

export const UPDATE_STORY_ID = 'UPDATE_STORY_ID';
export const UPDATE_STORY_ID_SUCCESS = 'UPDATE_STORY_ID_SUCCESS';
export const UPDATE_SUCCESS_HIDE = 'UPDATE_SUCCESS_HIDE';

export const ADD_CREDITS = 'ADD_CREDITS';
export const FONT_CHANGE = 'FONT_CHANGE';
export const SET_FONT_SUCCESS = 'SET_FONT_SUCCESS';
export const SET_FONT_FAILURE = 'SET_FONT_FAILURE';

export const SET_GRAMMAR_SPELL = 'SET_GRAMMAR_SPELL';
export const SET_GRAMMAR_SPELL_SUCCESS = 'SET_GRAMMAR_SPELL_SUCCESS';
export const SET_GRAMMAR_SPELL_FAILURE = 'SET_GRAMMAR_SPELL_FAILURE';

export const SET_GRAMMAR_IGNORE = 'SET_GRAMMAR_IGNORE';
export const SET_GRAMMAR_IGNORE_FAILURE = 'SET_GRAMMAR_IGNORE_FAILURE';
export const SET_GRAMMAR_IGNORE_SUCCESS = 'SET_GRAMMAR_IGNORE_SUCCESS';
import React from 'react';
import { LibraryStyle } from './../../style/style';

import { Row, Modal, Col, ProgressBar } from 'react-bootstrap';
import * as translations from './../../translations';


function Progress(props) {

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}

            backdrop='static'
            dialogClassName="add-asset-modal-dialog"
        >

            <Modal.Body>
                <LibraryStyle.addAssetMessageBox>
                    <LibraryStyle.successAssetContent>
                        <Row className="pb-3 mb-2">
                            <Col className="submit-asset-label">
                                {/* {props.IsCharacterEdit===true?translations.UPDATE_NEW_CHARACTER:translations.SAVING_NEW_CHARACTER} */}
                                {`Saving ${props.variations.length > 1 ? `${props.variations.length} files` : `file`} `}
                                <div className="text-muted text-note">
                                    This could take some time, please don’t close this window
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="progress-bar-col pb-4 mt-2">
                                <ProgressBar style={{ width: `${props.progress < 60 ? props.progress : 60}%` }} aria-valuenow={props.progress} aria-valuemin='0' aria-valuemax='100' className="progress-bar-custom"   >
                                </ProgressBar>
                            </Col>
                        </Row>

                        <div></div>
                    </LibraryStyle.successAssetContent>
                </LibraryStyle.addAssetMessageBox>
            </Modal.Body>
        </Modal>
    );
}

export default Progress
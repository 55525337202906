import React, { useState } from 'react';
import { AdminStyle } from '../admin-menu/style/admin-style';
import StoryListRow from './story-list-row';
// import * as Constant from './../../common/constants';
import ItemListLoader from '../common/list-loader';

function StoryContent(props) {
  let sortReviewedDesc = 'fa fa-sort-desc';
  if (props.sortingReviewed === 'asc') {
    sortReviewedDesc = 'fa fa-sort-asc';
  }

  let sortOthersDesc = 'fa fa-sort-desc';
  if (props.sortingOthers === 'asc') {
    sortOthersDesc = 'fa fa-sort-asc';
  }

  return (
    <div>
      <AdminStyle.listPanel className="mt-4">
        <AdminStyle.listContainer>
          <label className="table-title">Waiting to be reviewed</label>
          <div className="scroll-300">
            <AdminStyle.tableContainer>
              <thead>
                <tr>
                  <div className="story-title">Story Title</div>
                  <th>Story ID</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th>
                    Last Modified
                    <i
                      className={sortReviewedDesc}
                      onClick={() => props.handleSorting('waiting')}
                    ></i>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              {props.loading === true && <ItemListLoader />}
              {props.loading === false && (
                <tbody>
                  {props.storyAdminReviewed !== undefined &&
                  props.storyAdminReviewed.length > 0 ? (
                    props.storyAdminReviewed.map(storyInfo => (
                      <StoryListRow
                        key={storyInfo.id}
                        storyInfo={storyInfo}
                        handleDeleteClick={props.handleDeleteClick}
                        handleShowStoryId={props.handleShowStoryId}
                        handleDuplicateClick={props.handleDuplicateClick}
                        handleAdminReviewClick={props.handleAdminReviewClick}
                        symbolcolor="yellow"
                        handleSetStoryId={props.handleSetStoryId}
                        isReview={
                          storyInfo.status !== 'Submitted' &&
                          storyInfo.status !== 'Submitted For Publishing'
                            ? true
                            : false
                        }
                        showReview={false}
                        handleShowStoryInfoModal={
                          props.handleShowStoryInfoModal
                        }
                        tokenData={props.tokenData}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <div className="no-result">
                          No Stories waiting to be reviewed
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </AdminStyle.tableContainer>
          </div>
        </AdminStyle.listContainer>

        <AdminStyle.listContainer className="mt-5">
          <label className="table-title">Other Stories</label>
          <div className="scroll-300">
            <AdminStyle.tableContainer>
              <thead>
                <tr>
                  <th className="story-title">Story Title</th>
                  <th>Story ID</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th>
                    Last Modified
                    <i
                      className={sortOthersDesc}
                      onClick={() => props.handleSorting('others')}
                    ></i>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              {props.loading === true && <ItemListLoader />}
              {props.loading === false && (
                <tbody>
                  {props.storyListAdminOthers !== undefined &&
                  props.storyListAdminOthers.length > 0 ? (
                    props.storyListAdminOthers.map(storyInfo => (
                      <StoryListRow
                        key={storyInfo.id}
                        storyInfo={storyInfo}
                        handleDeleteClick={props.handleDeleteClick}
                        handleShowStoryId={props.handleShowStoryId}
                        handleDuplicateClick={props.handleDuplicateClick}
                        symbolcolor="blue"
                        handleSetStoryId={props.handleSetStoryId}
                        handleAdminReviewClick={props.handleAdminReviewClick}
                        isReview={false}
                        showReview={true}
                        handleShowStoryInfoModal={
                          props.handleShowStoryInfoModal
                        }
                        tokenData={props.tokenData}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <div className="no-result">No Stories</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </AdminStyle.tableContainer>
          </div>
        </AdminStyle.listContainer>
      </AdminStyle.listPanel>
    </div>
  );
}

export default StoryContent;

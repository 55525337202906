import React from 'react';
 
import { Row, Col, Container } from 'react-bootstrap';
import { LibraryStyle } from './../../style/style';
 
import { CharacterService } from '../../character';

function ArrangeImages({
    spriteLayers,
    handleSpriteVisibility,
    setSpriteLayers,
    Files,
    showProgress ,
    IsCharacterEdit

    
}) {
  let frameImages=[];
   
  if(showProgress===false){
     frameImages = CharacterService.getPreviewImageLayers(spriteLayers,Files,false, IsCharacterEdit);
  }else
  {
    frameImages = CharacterService.getPreviewImageLayers(spriteLayers,Files,true,IsCharacterEdit);
  }
 

    return (
        <Container>
            
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>
                <Col className="character-composer">
                    <LibraryStyle.characterComposer>
                        <div className="character-composer-block character-composer-block-custom bg-light text-center">
                            <div className="character-composer-frame-custom">
                                {
                                    frameImages
                                }
                            </div>
                           
                           
                        </div>
                    </LibraryStyle.characterComposer>
                </Col>
            </Row>
        </Container>
    );
}

export default ArrangeImages
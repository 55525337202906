import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { LibraryStyle} from './../style/style';

function CancelPlaceBackground({ show, onLeaveClick, onCancelClick }) {
    return <Modal 
    aria-labelledby="contained-modal-title-vcenter"
    dialogClassName="confirmModal"
   className="modalbg" size="md" show={show}>
     <Modal.Body  >
       
       <LibraryStyle.addAssetConfirmBox> 
                   <LibraryStyle.confirmAssetContent>
       <Row>
         <Col className="fail-icon">
         <img alt="" className="mediumIcon" src="/images/icons/ic_warning_24px.svg"/>
         </Col>
       </Row>
       <Row>
         <Col className="submit-asset-label" data-testid="first-label">
           Leave the process?
         </Col>
       </Row>
       <Row>
         <Col className="submit-asset-label" data-testid="second-label">
           Changes will not be saved
         </Col>
       </Row>
       <div className="mt-4">
       <Row>
         <Col>
         <Button
             className="btn-snacktool btn-snacktool-golden"
             onClick={onCancelClick}
           >
             Cancel
             </Button>
         </Col>
         <Col>
           <Button
             onClick={onLeaveClick}
             className="btn-snacktool btn-snacktool-golden btn btn-primary"
           >
             Leave
           </Button>
         </Col>
       </Row>
       </div>
       </LibraryStyle.confirmAssetContent></LibraryStyle.addAssetConfirmBox>
     </Modal.Body>
   </Modal>
}

export default CancelPlaceBackground
import React from 'react';
import { DeleteStyle} from './styles/modalstyles';
import { Button,Modal} from 'react-bootstrap';
 

function  CommentsSuccess(props) {
 
  return (
    <Modal 
     aria-labelledby="contained-modal-title-vcenter"
     dialogClassName="confirmModal"
    className="modalbg" 
    size="md" 
    show={props.show}
    onHide={props.onHideCommentStatusModal}
    >
      <Modal.Body  >
        
      <DeleteStyle.addAssetMessageBox>
            <DeleteStyle.successAssetContent>
              <div className="success-icon mb-3">
                <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
              </div>
              <div  data-testid="success-label" className="submit-asset-label">Comment added successfully.</div>
              <div  data-testid="success-note" className="text-muted submit-asset-subtitle "></div>
              <div>
                <Button data-testid="success-btn"  onClick={props.onHideCommentStatusModal}     className="btn-snacktool btn-snacktool-golden btn btn-primary" > Okay </Button>
              </div>
            </DeleteStyle.successAssetContent>
        </DeleteStyle.addAssetMessageBox>
      </Modal.Body>
    </Modal>
  );

  }

export default CommentsSuccess
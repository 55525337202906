import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { PlaceBgStyle } from './style';
import BrowseView from './BrowseView';

function EditViewTabs(props) {
    return (
        <PlaceBgStyle.Tabdiv>
            <Tabs>
            <Tab eventKey="browse">
                <BrowseView views = {props.views}
                    handleBrowseSelection = {props.handleBrowseSelection}
                    handleDuplicateClick = {props.handleDuplicateClick}
                    handleViewSearch = {props.handleViewSearch}
                    searchViews = {props.searchViews}
                    onHideModal={props.onHideModal}
                    backgroundCopy = {props.backgroundCopy}
                    handleClickEvent  = {props.handleClickEvent}
                    searchText = {props.searchText}
                    handlePlaceViewScript = {props.handlePlaceViewScript}
                    handleViewEditClick = {props.handleViewEditClick}
                    fadeinView = {props.fadeinView}/>
            </Tab>
            </Tabs>
        </PlaceBgStyle.Tabdiv>
    );
}

export default EditViewTabs

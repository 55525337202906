import React, { useState } from 'react';
import { LibraryStyle } from './../../style/style';
import CreateCharacterHead from './CreateCharacterHead';
import CreateCharacterContent from './CreateCharacterContent';
import { Container, Row, Col } from 'react-bootstrap';
import * as Translations from './../../translations';
import { NavLink } from 'react-router-dom';
import ArrangeImages from './ArrangeImages';
import VariationList from './VariationList';

import Success from './../../../common/Modals/Success';
import Fail from './../../../common/Modals/Fail';

function SetCharacterVariations({
  spriteLayers,
  handleSpriteVisibility,
  setSpriteLayers,

  variations,
  removeVariation,
  initializeCreateCharacter,

  handleSaveCharacter,
  showCharacterSaveSuccessModal,
  showCharacterSaveFailModal,
  hideSuccessModal,
  hideFailModal,

  handlePreviewClick,
  Files,
  reorderCharcterList,
  IsCharacterEdit,
  showProgress
}) {
  return (
    <LibraryStyle.listContentPanel>
      <LibraryStyle.characterContent>
        <CreateCharacterHead
          IsCharacterEdit={IsCharacterEdit}
          initializeCreateCharacter={initializeCreateCharacter}
        />
        <CreateCharacterContent>
          <Container>
            <Row className="content-head">
              <Col md={5} className="text-left left-title">
                {Translations.SET_CHARACTER_VARIATIONS}
              </Col>
              <Col md={{ span: 6, offset: 1 }} className="text-right">
                <NavLink
                  to="create-character-step-two"
                  className="menu-nav back-to-step"
                >
                  <img
                    alt=""
                    src="/images/icons/ic_chevron_right.svg"
                    className="mr-1 mb-1 checkIcon"
                  />{' '}
                  {Translations.BACK_TO_STEP_TWO}
                </NavLink>{' '}
                &nbsp;
                <button
                  className={`btn-snacktool btn-snacktool-golden btn btn-primary finish-button`}
                  to="create-character-step-two"
                  onClick={handleSaveCharacter}
                >
                  {IsCharacterEdit === true
                    ? Translations.UPDATE_CHARACTER
                    : Translations.FINISH_AND_SAVE_CHARACTER}
                </button>
              </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col>
                <VariationList
                  variations={variations}
                  removeVariation={removeVariation}
                  spriteLayers={spriteLayers}
                  handlePreviewClick={handlePreviewClick}
                  reorderCharcterList={reorderCharcterList}
                />
              </Col>
              <Col>
                <ArrangeImages
                  spriteLayers={spriteLayers}
                  handleSpriteVisibility={handleSpriteVisibility}
                  setSpriteLayers={setSpriteLayers}
                  showProgress={showProgress}
                  IsCharacterEdit={IsCharacterEdit}
                  variations={variations}
                  Files={Files}
                />
              </Col>
            </Row>
          </Container>
        </CreateCharacterContent>
      </LibraryStyle.characterContent>
      {showCharacterSaveSuccessModal === true && (
        <Success
          handleClick={hideSuccessModal}
          label={
            IsCharacterEdit === true
              ? Translations.CHARACTER_UPDATED
              : Translations.CHARACTER_SAVED
          }
          note={Translations.CHARACTER_SAVED_LABEL}
        />
      )}
      {showCharacterSaveFailModal === true && (
        <Fail
          handleClick={hideFailModal}
          label={Translations.CHARACTER_NOT_SAVED_MESSAGE}
          note=""
        />
      )}
    </LibraryStyle.listContentPanel>
  );
}

export default SetCharacterVariations;

import React from 'react';
import { LibraryStyle} from './../../style/style';
import { Row,Modal, Col,Button} from 'react-bootstrap';
import * as translations from './../../translations';

function  Warning(props) {

    return (
      <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show = {props.showModal}
      onHide = {props.handleWarningClick}
      backdrop='static'
      dialogClassName="add-asset-modal-dialog"
      >
        
                <Modal.Body>
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                <Row>
                    &nbsp;
                </Row>
                <Row><Col className="submit-asset-label">{translations.CHARACTER_PREVIEW_WARNIMNG}</Col></Row>

                <Row><Col className="submit-asset-label">
                <Row>
                    &nbsp;
                </Row><Row>
                    &nbsp;
                </Row> 
                <Button onClick={props.handleWarningClick} className="btn-snacktool btn-snacktool-golden btn btn-primary" 
                        >
                         Okay
                    </Button></Col></Row>

                <div></div>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
        </Modal.Body>
        </Modal>
    );
}

export default Warning
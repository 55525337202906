import * as ActionTypes from './player-action-types';

export function setPlayerModalTrue(flowchartPreviewData, aspectRatio, gameInterfaceList) {
  return {
      type: ActionTypes.SET_PLAYER_MODAL_TRUE,
      flowchartPreviewData : flowchartPreviewData,
      aspectRatio: aspectRatio,
      gameInterfaceList: gameInterfaceList
  }
}

export function setPlayerModalFalse() {
  return {
      type: ActionTypes.SET_PLAYER_MODAL_FALSE
  }
}

export function setLoadingTrue() {
  return {
    type: ActionTypes.SET_PLAYER_LOADING_TRUE
  }
}

export function setLoadingFalse() {
  return {
    type: ActionTypes.SET_PLAYER_LOADING_FALSE
  }
}

export function setBlobImages(blobImages) {
  return {
    type: ActionTypes.SET_BLOB_IMAGES,
    blobImages: blobImages
  }
}
import React, { useState } from 'react'
import { Form, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';

const VariableCheckbox = (props) => {
    const [checkbox, setCheckbox] = useState(props.value)

    const changeCheckBox = (event, variableId, variableValue, clicked) => {
        if (clicked == 'reset') {
            const variableValueReset = variableValue == 'true' ? 'true' : 'false'
            props.onChangeCheckBox(variableId, variableValueReset, clicked);
            setCheckbox(variableValueReset);
        } else {
            const variableValueChanged = variableValue == 'true' ? 'false' : 'true'
            setCheckbox(variableValueChanged);
            props.onChangeCheckBox(variableId, variableValueChanged, 'normal');
        }
    }
    return (
        <Row>
            <Col md="9" className="p-0">
                <Form.Group className="commandLabelSuffix col-9">
                    <label className="switch-flip">
                        <input type="checkbox"
                            name={`checkbox_${props.id}`}
                            className='checkbox-flip'
                            label="checkbox"
                            id={props.id}
                            key={props.id}
                            onChange={(event) => changeCheckBox(event, props.id, checkbox)}
                            disabled={props.defaultDisabledView}
                            checked={props.value == 'true' || props.value == true ? 'checked' : ''}
                        />
                        <span className="slider-flip round"></span>
                    </label>
                    <span className="label-text">{props.value == 'true' ? 'True' : 'False'}</span>
                </Form.Group>
            </Col>
            <Col>
                <div className="ml-3">
                    {
                        props.updateValueArrayIds.includes(props.id) === true &&
                        <OverlayTrigger
                            key={`tooltip-reset-${props.id}`}
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-${props.id}`} >
                                    Reset to default
                            </Tooltip>
                            }
                        >
                            <img
                                src={`/images/icons/refresh.svg`}
                                className="refresh-icon"
                                onClick={props.defaultDisabledView !== 'disabled' ? (event) => changeCheckBox(event, props.id, props.defaultValueCheckbox, 'reset') : ''}
                            />
                        </OverlayTrigger>


                    }
                </div>
            </Col>
        </Row>
    )
}

export default VariableCheckbox;
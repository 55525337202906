import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { styledIcons } from './../../../../common/styles/icons';


function ListAssetRow({
  asset,
  columns,
  index,
  handleDeleteKey,
  handleEditKey,
  outfitCount,
  hairCount,
  expressionCount,
  accessoryCount
}) {
  return (
    <tr key={asset.assetName} className="table-body-row">
      {columns.indexOf('image') > -1 && (
        <td className="asset-name-col text-left">{asset.assetName}</td>
      )}

      {columns.indexOf('type') > -1 && (
        <td className="text text-left">{asset.assetType}</td>
      )}
      {columns.indexOf('default') > -1 && (
        <td>{asset.defaultAsset && <styledIcons.defaultIcon />}</td>
      )}

      {columns.indexOf('actions') > -1 && (
        <td>
          <styledIcons.editIcon
            onClick={() => handleEditKey(asset.fileIndex)}
          />

          {asset.defaultAsset === false && (
            <OverlayTrigger
              key={`delete-asset`}
              placement="top"
              overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
            >
              <styledIcons.deleteIcon
                onClick={() => handleDeleteKey(asset.fileIndex)}
              />
            </OverlayTrigger>
          )}

          {asset.defaultAsset === true &&
            hairCount > 1 &&
            asset.assetType === 'Hair' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={
                  <Tooltip id={`delete-asset`}>
                    Default assets cannot be deleted unless they are the only
                    one of their type.
                  </Tooltip>
                }
              >
                <img src="/images/icons/deactivatedelete.svg" alt="" />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            hairCount === 1 &&
            asset.assetType === 'Hair' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
              >
                <styledIcons.deleteIcon
                  onClick={() => handleDeleteKey(asset.fileIndex)}
                />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            outfitCount > 1 &&
            asset.assetType === 'Outfit' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={
                  <Tooltip id={`delete-asset`}>
                    Default assets cannot be deleted unless they are the only
                    one of their type.
                  </Tooltip>
                }
              >
                <img src="/images/icons/deactivatedelete.svg" alt="" />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            outfitCount === 1 &&
            asset.assetType === 'Outfit' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
              >
                <styledIcons.deleteIcon
                  onClick={() => handleDeleteKey(asset.fileIndex)}
                />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            expressionCount > 1 &&
            asset.assetType === 'Expression' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={
                  <Tooltip id={`delete-asset`}>
                    Default assets cannot be deleted unless they are the only
                    one of their type.
                  </Tooltip>
                }
              >
                <img src="/images/icons/deactivatedelete.svg" alt="" />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            expressionCount === 1 &&
            asset.assetType === 'Expression' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
              >
                <styledIcons.deleteIcon
                  onClick={() => handleDeleteKey(asset.fileIndex)}
                />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            accessoryCount > 1 &&
            asset.assetType === 'Accessory' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={
                  <Tooltip id={`delete-asset`}>
                    Default assets cannot be deleted unless they are the only
                    one of their type.
                  </Tooltip>
                }
              >
                <img src="/images/icons/deactivatedelete.svg" alt="" />
              </OverlayTrigger>
            )}

          {asset.defaultAsset === true &&
            accessoryCount === 1 &&
            asset.assetType === 'Accessory' && (
              <OverlayTrigger
                key={`delete-asset`}
                placement="top"
                overlay={<Tooltip id={`delete-asset`}>Remove</Tooltip>}
              >
                <styledIcons.deleteIcon
                  onClick={() => handleDeleteKey(asset.fileIndex)}
                />
              </OverlayTrigger>
            )}
        </td>
      )}
    </tr>
  );
}

export default ListAssetRow;

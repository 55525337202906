import React from 'react';
import { LibraryStyle} from './../style/style';
 
import * as translations from './../translations';

function  Success() {

    return (
        <LibraryStyle.addAssetMessageBox>
            <LibraryStyle.successAssetContent>
                    <div className="success-icon mb-3">
                        <img alt="" className="mediumIcon"  src="/images/icons/ic_done_24px.svg"/>
                    </div>
                <div className="submit-asset-label">{translations.SAVE_NEW_IMAGE_MESSAGE}</div>
            </LibraryStyle.successAssetContent>
        </LibraryStyle.addAssetMessageBox>
    );
}

export default Success
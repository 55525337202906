import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card } from 'react-bootstrap';
import { LibraryServices } from './../service';
import * as translations from './../translations';
import * as Constants from './../../../common/constants';

function UploadFile(props) {
  const maxSize = Constants.ASSET_MAX_FILE_SIZE;
  const { files } = props;
  let thumbs = [];
  /* State of component */
  const [cardBorder, setCardBorder] = useState('');
  const [cloudUploadIconColor, setCloudUploadIconColor] = useState(
    'text-secondary'
  );

  const onDragOver = useCallback(() => {
    setCardBorder('primary');
    setCloudUploadIconColor('text-primary');
  }, []);

  const onDragLeave = useCallback(() => {
    setCardBorder('');
    setCloudUploadIconColor('');
  });

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        props.setFiles(LibraryServices.createPreviewUrl(acceptedFiles));
        setCardBorder('');
        setCloudUploadIconColor('');
        props.setShowClearFile(true);
        props.setContinueToAddInfoClass(false);
        props.setShowFileError(false);
      }
    },
    [props]
  );

  let acceptTypes =
    props.IsAssetEdit && props.asset.type === 'Object_Image'
      ? Constants.ASSET_FILE_ALLOWED_PNG.toString()
      : Constants.ASSET_FILE_ALLOWED_EXTENSIONS.toString();

  let messageDisplay = translations.SUPPORTED_FILE_FORMATS;

  if (
    props.IsAssetEdit &&
    (props.asset.type === 'Background_Image' ||
      props.asset.type === 'Object_Image')
  ) {
    acceptTypes = Constants.ASSET_FILE_ALLOWED_EXTENSIONS_IMAGE.toString();
    acceptTypes =
      props.asset.type === 'Object_Image'
        ? Constants.ASSET_FILE_ALLOWED_PNG.toString()
        : Constants.ASSET_FILE_ALLOWED_EXTENSIONS.toString();
    messageDisplay =
      props.asset.type === 'Object_Image'
        ? translations.CHAR_SUPPORTED_FILE_FORMATS_PNG
        : translations.CHAR_SUPPORTED_FILE_FORMATS;
  } else if (
    props.IsAssetEdit &&
    (props.asset.type === 'Background_Music' ||
      props.asset.type === 'Incidental_Sound')
  ) {
    acceptTypes = Constants.ASSET_FILE_ALLOWED_EXTENSIONS_AUDIO.toString();
    messageDisplay = translations.SUPPORTED_FILE_FORMATS_AUDIO;
  }

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: acceptTypes,
    minSize: 0,
    maxSize: Constants.ASSET_MAX_FILE_SIZE,
    onDragOver,
    onDragLeave
  });

  const isFileTooLarge = LibraryServices.checkFileSize(rejectedFiles, maxSize);

  if (files && files.length > 0) {
    if (files[0] && typeof files[0] === 'string') {
      thumbs = LibraryServices.createEditPreviewThumb(files, props.asset);
    } else {
      thumbs = LibraryServices.createPreviewThumb(files);
    }
  }

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="container text-center lg-12">
      <div className="mt-4">
        {thumbs.length === 0 && (
          <div {...getRootProps()}>
            <Card
              bg="light"
              border={props.showFileError === true ? 'danger' : cardBorder}
            >
              <Card.Body className="card-content cursor-pointer">
                <img alt="" src="/images/icons/backup-big.svg" />
                <br />
                <Card.Text className="text-secondary"></Card.Text>
                <input {...getInputProps()} id="file" />
                <Card.Text className="text-secondary">
                  <div className="text-later">
                    {!isDragActive && messageDisplay}
                    <br />
                    {!isDragActive && translations.UPLOAD_SIZE_FILE}
                  </div>
                  {isDragActive && !isDragReject && translations.DROP_IT}
                  {isDragReject && translations.FILES_TYPE_NOT_ACCEPTED}
                  {isFileTooLarge && (
                    <div className="text-danger mt-2 mb-2">
                      {translations.FILE_IS_TOO_LARGE}
                    </div>
                  )}
                  {!isFileTooLarge && rejectedFiles.length > 0 && (
                    <div className="text-danger mt-2 mb-2">
                      {LibraryServices.showError(
                        rejectedFiles,
                        props.IsAssetEdit,
                        props.asset
                      )}
                    </div>
                  )}
                  {!isFileTooLarge &&
                    rejectedFiles.length === 0 &&
                    props.showFileError === true && (
                      <div className="text-danger mt-2 mb-2">
                        Please upload a file.
                      </div>
                    )}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        )}
        {thumbs.length > 0 && (
          <Card bg="light" border={cardBorder}>
            <Card.Body className="card-content card-content-custom-asset">
              <aside className="thumbs-container">{thumbs}</aside>
            </Card.Body>
          </Card>
        )}
      </div>
    </div>
  );
}

export default UploadFile;

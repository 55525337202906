import React, { useState } from 'react';
import * as PlayerStyled from './../style';
import { Form, Row, Col, Button } from 'react-bootstrap';
import * as Translations from './../helpers/traslations';
import * as Patterns from './../../../common/helper/pattern';

function ShowNameDialogue({
    setShowNameDialogue,
    handleStartPlaying,
    setVariables
}) {

    const [validated, setValidated] = useState(false);
    const [dialogueVariables, setDialogueVariables] = useState({
        name: '',
        pronouns: ''
    });
    const [pronounsError, setPronounsError] = useState(false);
    
    const handlePronounsChange = (event) => {
        setDialogueVariables({
            ...dialogueVariables,
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'pronouns') {
            setPronounsError(false);
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || dialogueVariables.pronouns === '') {
            event.stopPropagation();
            if (dialogueVariables.pronouns === '') {
                setPronounsError(true);
            }
        } else {
            setPronounsError(false);
            setVariables(dialogueVariables.name, dialogueVariables.pronouns);
            setShowNameDialogue(false);
            handleStartPlaying(event);
        }
        event.preventDefault();
        setValidated(true);
    }

    let disabledConfirm = dialogueVariables.name === '' || dialogueVariables.pronouns ===  '' ? 'disabled' : '';

    return (
        <PlayerStyled.showNameDialogue className="d-flex justify-content-center align-items-center">
            <Form 
                noValidate
                validated={validated}
                onSubmit = {handleSubmit}>
                <div className="showNameDialogue">
                <Row>
                    <Col>
                        <div className="text-center title">{Translations.WHO_ARE_YOU}</div>
                        <Form.Label>Name</Form.Label>
                        <Form.Group>
                            <Form.Control 
                                type="text"
                                name="name" 
                                placeholder="Enter name..."
                                required
                                autoComplete="off"
                                pattern = {Patterns.DIALOGUE_NAME_PATTERN}
                                onChange = {handlePronounsChange}/>
                            <Form.Control.Feedback type="invalid">
                                {Translations.DIALOGUE_NAME_NOT_CORRECT}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>PRONOUNS</Form.Label>
                            <div>
                            <label className="radioContainer">She / Her
                                <input 
                                type="radio" 
                                name="pronouns"
                                value="1"
                                checked={dialogueVariables.pronouns === '1' ? true : false}
                                className="form-check-input"
                                onChange = {handlePronounsChange} />
                                <span className="checkmark"></span>
                            </label>
                            </div>
                            <div>
                                <label className="radioContainer">He / Him
                                    <input 
                                    type="radio" 
                                    name="pronouns"
                                    value="2"
                                    checked={dialogueVariables.pronouns === '2' ? true : false}
                                    className="form-check-input"
                                    onChange = {handlePronounsChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>
                            <label className="radioContainer">They / Them
                                    <input 
                                    type="radio" 
                                    name="pronouns"
                                    value="0"
                                    checked={dialogueVariables.pronouns === '0' ? true : false}
                                    className="form-check-input"
                                    onChange = {handlePronounsChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {
                                pronounsError === true &&
                                <Form.Label className="label label-danger">
                                    {Translations.DIALOGUE_PRONOUNS_NOT_CORRECT}
                                </Form.Label>
                            }
                            
                        </Form.Group>
                        <div className="preview-btn-wrapper">
                            <Button name="submit" 
                                type="submit" 
                                value="Confirm" 
                                className="confirm-btn d-flex justify-content-center align-items-center"
                                disabled = {disabledConfirm}>Confirm</Button>
                        </div>
                    </Col>
                </Row>
                
                </div>
            </Form>
        </PlayerStyled.showNameDialogue>
    );
}

export default ShowNameDialogue
import styled from "styled-components";
 


const commentComposer = styled.div`
    .charecter-img{
       
        height:295px;
    }
    .no-comments{
        font-family:PT Sans;
        color:#303030 !important;
        h3{
            margin:0 40px;
        }
        h3, h4{
            font-size:24px;
        }
        .no-comments-bg{
            background:#F5F5FA;
            margin:40px;
            padding:77px 100px;
            p{
                color:#707070 !important;
                font-size:14px;
            }
        }
        
    }
    .character-composer-block {
        text-align: center !important;
        background: url('/images/transparent-pattern-bg.svg') repeat;
        background-size:5%;
    }

    .character-composer-frame {
        width: 100%;
        position: relative;
        background-repeat: no-repeat;
    }

    .empty-row {
        height: 20px;
    }

    .frame-image {
        position: absolute;
        left: 0;
    }

    .sprite-row{
        padding: 12px  12px  12px 0px;
        cursor:pointer
        display:flex;
        justify-content:space-between;
        align-items: center;
        .asset-details{
            display: flex;
            align-items: center;
            .fa-bars{
                color:#ACACAC;
                font-size:13px;
            }
            .asset-name{
                margin-left:16px;
                color:#303030;
                font-family:PT Sans;
                font-size:14px
            }
        }
        .sprite-row-name{
            color:#303030;
            font-size:13px;
        }
    }
    
`;


const listHeading = styled.div`
    font-family:PT Sans;
    color:#707070;
    font-size:14px;
    
    .comment-header{
        padding-bottom:25px !important;
    }
   
    .comment-title{
        font-size:24px;
        color:#303030;
    }
    .comment-subject{
        text-decoration: underline;
        font-size:16px;
        font-weight:bold;
        letter-spacing: 0;
        color: #303030;
        text-transform: uppercase;
        margin:0;
    }
    .reopen-link{
        text-decoration: underline;
        font-size:14px;
        color: #157FFB;
        cursor:pointer;
    }
    .reply-text{
        font-size:14px;
        color: #707070;
        width: 90%;
        span{
            font-weight:bold;
            color:#303030;
            word-wrap: break-word;
        }
        p{
          word-wrap: break-word;
    font-size: 14px;
    border-left: 4px solid #E3E3E3;
    padding-left: 8px;
    width: 90%;
        }
    }
    .edit-icon, .delete-icon{
        cursor:pointer;
        height:24px;
        width:24px;
    }
    .status{
        background:#DFDFDF;
        width: fit-content;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color:rgba(0, 0, 0, 0.87)
        font-family:Roboto;
        font-size:12px
        padding:5px 10px 5px 7px;
        border-radius: 46px;
        height:23px;
        .circle{
            width:10px;
            height:10px;
            border-radius:50%;
            margin-right: 6px;
          
        }
        &.red{
            .circle{
                background:#FF6565;
            }
        }
        &.green{
            .circle{
                background:#45C7AD;
            }
        }
        label{
            margin-bottom:0;
        }
    }
    .comment-content{
        padding-bottom:40px !important;
        
    }
    .borderTop{
        border-top:1px solid #E3E3E3;
        padding-top:20px;
    }
    .btn-wrapper{
        background: #FFFFFF;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        overflow: hidden;
        .filter-button {
            text-align: center;
            width:104px;
            height:33px;
            color:#707070;
            font-size:13px;
            border:none;
            background: #fff;
            border-left: 1px solid #D7DAE2;
            margin: 0;
            padding: 0;
            cursor: pointer;
        }
         .filter-button:hover{
            color:#3B86FF;
        }
        .filter-button:first-child{
            border-left: none;
        }
        .filter-button-active{
            color:#3B86FF;
        }
        .filter-button:focus {
            outline: 0 !important;
            outline-offset: 0  !important;
            background-image: none  !important;
            -webkit-box-shadow: none !important;
            box-shadow: none  !important;
        }
        
    }
    .filter-wrapper{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .cmdscript-box{
        background:#F5F5FA;
        padding:16px 25px !important;
        
        h3{
            font-weight: Bold;
            font-size:16px;
            letter-spacing: 0;
            color: #303030;
            text-transform: uppercase;
        }
        p{
            font-size:14px;
            color:#000;
            margin:0;
            .commandView{
                color:#157ffb;
            }
            .commandShake{
                color: #B3C1F0;
            }

            .commandEnter{
                color: #f151b4;
            }
            .commandMusic{
                color: #108c10;
            }
            .commandShowName{
              color: #530ACD;
            }

            .commandNext{
              text-decoration:underline;
            }
        }
    }
    h4{
        color:#303030;
        font-weight:bold;
        font-size:14px;
    }
    .comment-text{
        word-wrap: break-word;
        font-size:14px;
        border-left:4px solid #E3E3E3;
        padding-left:8px;
    }
    .reply-input{
        flex:1;
    }
    .reply-btn{
        font-family: Roboto;
        font-size:14px;
        font-weight:bold;
        color: #E6A554 !important;
        background:url(/images/icons/ic-chat.svg) center left no-repeat !important;
        border: none;
        padding-left:26px;
        margin:0 0 0 26px;
    }
    .reply-btn:focus {
        outline: 0 !important;
        outline-offset: 0  !important;
        background-image: none  !important;
        -webkit-box-shadow: none !important;
        box-shadow: none  !important;
        background:url(/images/icons/ic-chat.svg) center left no-repeat !important;
    }
  
    .modal-footer{
        border-top:1px solid #ff0000;
    }
    .prev-btn, .next-btn{
        border: none;
        color: #E6A554 !important;
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
        position:relative;
        padding: 1px 0 0 0;
        margin: 0 0 0 15px;
        background:transparent;
        .fa{
            font-size: 16px;
            font-weight: bold;
            margin-right: 5px;
        }
    }
    .next-btn{
        padding: 1px 0 0 0;
        margin: 0 15px 0 0;
        .fa{
            margin-right: 0px;
            margin-left:5px;
        }
    }
    .next-btn:disabled, .prev-btn:disabled{
        background:transparent;
        color:#ACACAC !important;
        cursor: not-allowed;
    }
    .next-btn:focus, .prev-btn:focus {
        outline: 0 !important;
        outline-offset: 0  !important;
        background-image: none  !important;
        -webkit-box-shadow: none !important;
        box-shadow: none  !important;
    }
    
     
`;


 
 

export const CommentStyle = {
  commentComposer,
  listHeading
}
import React from 'react';
import * as Translations from './../translations';
import * as commandPanelStyle from '../../command-panel/command-panel-style';

function ListHead(props) {
    return (
        <div>
            <commandPanelStyle.Header>
                
            <div className="panel-header">
                    <div className="story-heading">
                        <div className='storyName'>
                        {props.storyTitle ? props.storyTitle : ''}
                        </div>
                        <div className='sceneName'>
                        {Translations.CHARACTERS_IN_THIS_STORY}
                        </div>
                    </div>
                </div>
               
            </commandPanelStyle.Header>
        </div>
    );
}

export default ListHead
import React, { useEffect, useState } from 'react';
import * as PlayerStyled from './../style';
import * as Constants from './../../../common/constants';
import * as Config from './../../../config/app.config';

function ChoiceTimer(props) {
    let timerImageKey = props.gameInterfaceList[Constants.interfaceList[3]];
    let defaultTimerImage = Constants.defaultInterfaceImageList[3];
    let timerImage = timerImageKey != null && props.blobImages.hasOwnProperty(timerImageKey) ? props.blobImages[timerImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultTimerImage;
    //const [timer, setTimer] = useState(0);
    const [showTimer, setShowTimer] = useState(true);
    const [timerPercent, setTimerPercent] = useState(0);
    //let increamentPercentValue = 100 / props.waitTime;

    useEffect(() => {
        // let intervalId;
        // if (showTimer === true) {
        //     intervalId = setInterval(() => {
        //         setTimer(timer => timer + 1);
        //         setTimerPercent(timerPercent => (timerPercent + 1));
        //     }, 100)
        // }

        // if (parseInt(props.waitTime) - 2 < timer) {
        //     setShowTimer(false);
        // }

        // if (showTimer === true) {
        //     setTimeout(() => {
        //         setShowTimer(false);
        //     }, parseInt(props.waitTime) * 500)   
        // }

        //return () => clearInterval(intervalId)
    }, [])

    return (
        <PlayerStyled.choiceTimer timeBar = {timerImage} 
            timeBarWidth = {timerPercent}
            waitTime = {props.waitTime}>
            <div className="slider-wrapper">
                <div className="slider-fill-timer"></div>
                <div className="timerShadow"></div>
            </div>
        </PlayerStyled.choiceTimer>
    )
}

export default ChoiceTimer
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AddObjectSection from './AddObjectSection';
import CheckViews from './checkViews';
// react cropper

import 'cropperjs/dist/cropper.css';
import { StoryService } from './../service';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import CreateViewLeftContainer from './CreateViewLeftContainer';
import * as AspectRatioConfig from './../../../config/aspectRatio.config';
import { PlaceBackgroundService } from './../place-background-service';

function CreateViewTab(props) {
  let addNewStatus = props.backgroundImage !== null ? '' : 'disabled';

  const [handleUpdate, setHandleUpdate] = useState(false);
  const [checkViewModal, setCheckViewModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleAddObjectLableClick = () => {
    props.setAddObject(true);
    props.setSelectBackground(false);
  };

  const handleSelectBackgroundClick = () => {
    props.setSelectBackground(true);
    props.setAddObject(false);
  };

  const changePosition = (item, left, top) => {
    let object = props.viewObjects.slice().reverse()[item.index];
    object.xcoordinate = left;
    object.ycoordinate = top;

    let newObjects = props.viewObjects.slice().reverse();
    newObjects[item.index] = object;

    props.setObject(newObjects.slice().reverse());
    setHandleUpdate(!handleUpdate);
  };

  const handleModalViews = () => {
    setCheckViewModal(!checkViewModal);
  };

  const handleCloseModalViews = () => {
    setCheckViewModal(false);
  };

  const callbackFunction = (x, id) => {
    let element = document.getElementById(id);
    if (element != null) {
      element.style.backgroundImage = `url(${x})`;
    }
  };

  if (props.selectBackground === false && props.backgroundImage != null) {
    StoryService.resizeImage(
      props.backgroundImage,
      props.width,
      props.height,
      props.xcoordinate,
      props.ycoordinate,
      callbackFunction,
      'left-content',
      false
    );
  } else {
    let element = document.getElementById('left-content');
    if (element) {
      element.style.backgroundImage = '';
    }
  }

  let defaultValues = {
    x: 0,
    y: 0,
    width: AspectRatioConfig.defaultSelecterWidth,
    height: AspectRatioConfig.defaultSelecterHeight
  };
  if (
    props.selectedBackground != null &&
    props.backgroundImage != null &&
    props.backgroundCopy != null
  ) {
    defaultValues = PlaceBackgroundService.getEditParameterValues(
      props.selectedBackground.specs,
      props.xcoordinate,
      props.ycoordinate,
      props.width,
      props.height
    );
  }

  return (
    <React.Fragment>
      <div className="view-tab-content">
        <DndProvider backend={Backend}>
          <CreateViewLeftContainer
            selectBackground={props.selectBackground}
            backgroundImage={props.backgroundImage}
            xcoordinate={props.xcoordinate}
            ycoordinate={props.ycoordinate}
            height={props.height}
            width={props.width}
            viewObjects={props.viewObjects}
            setCrop={props.setCrop}
            assetList={props.assetList}
            setHandleUpdate={setHandleUpdate}
            handleUpdate={handleUpdate}
            changePosition={changePosition}
            backgroundCopy={props.backgroundCopy}
            views={props.views}
            selectedBackground={props.selectedBackground}
            defaultValues={defaultValues}
            setEditView={props.setEditView}
            editView={props.editView}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
          />
        </DndProvider>
        <div className="right-content">
          <div id="createViewTabRightPanel">
            <div className="position-relative step-background-view vertical-line mb-0">
              <span
                className={
                  props.background !== null && props.background !== ''
                    ? 'circle'
                    : 'circle-empty'
                }
              >
                {props.background !== null && props.background !== '' && (
                  <i className="fa fa-check check-font"></i>
                )}
                {(props.background == null || props.background == '') && 1}
              </span>
              <div className="custom-select-dropdown pb-4">
                <label
                  className="label-title mt-2"
                  onClick={handleSelectBackgroundClick}
                >
                  Select a background
                </label>
                <div
                  className={props.selectBackground === false ? 'd-none' : ''}
                >
                  <label className="select-label form-label mt-2">
                    Background image
                  </label>
                  <select
                    className="form-control  "
                    onChange={props.handleChange}
                    required
                    name="background"
                  >
                    <option value="">Select one</option>
                    {props.backgroundImages.map((background, index) => {
                      return (
                        <option
                          value={background.id}
                          key={index}
                          selected={
                            background.id === parseInt(props.background)
                              ? 'selected'
                              : null
                          }
                        >
                          {background.name}
                        </option>
                      );
                    })}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Background image selection is mandatory
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>
            <div className="form-group position-relative step-object-view">
              <span
                className={
                  props.viewObjects.length > 0 ? 'circle' : 'circle-empty'
                }
              >
                2
              </span>
              <div>
                <label
                  className="label-title mt-2 ml-28"
                  onClick={handleAddObjectLableClick}
                >
                  Add objects
                </label>
                <AddObjectSection
                  addObject={props.addObject}
                  addNewStatus={addNewStatus}
                  setShowAddObjectForm={props.setShowAddObjectForm}
                  viewObjects={props.viewObjects}
                  handleObjectDeleteClick={props.handleObjectDeleteClick}
                  handleEditObjectClick={props.handleEditObjectClick}
                  handleAddObjectClick={props.handleAddObjectClick}
                  handleReorderList={props.handleReorderList}
                />
              </div>
            </div>

            {/* <div>
                        <ul>
                            <li>X: {props.xcoordinate} px</li>
                            <li>Y: {props.ycoordinate} px</li>
                            <li>Width: {Math.round(props.width)} px</li>
                            <li>Height: {Math.round(props.height)} px</li>
                        </ul>
                    </div> */}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <div>
          {
            <Button
              className="btn-snacktool btn-snacktool-golden"
              onClick={props.onHideModal}
            >
              Cancel
            </Button>
          }
        </div>
        <div>
          <Button
            type="button"
            onClick={() => handleModalViews()}
            disabled={
              props.backgroundImage !== null && showLoader != true
                ? ''
                : 'disabled'
            }
            className="btn-snacktool btn-snacktool-brown mr-3"
          >
            Show Views
          </Button>
          <Button
            type="button"
            onClick={props.handleSaveForLaterClickEvent}
            className="btn-snacktool btn-snacktool-brown mr-3"
            disabled={props.backgroundImage !== null ? '' : 'disabled'}
          >
            Save for later
          </Button>
          {
            <Button
              type="button"
              onClick={() => props.handleClickEvent(false, 'placeView')}
              className="btn-snacktool btn-snacktool-golden"
              disabled={props.backgroundImage !== null ? '' : 'disabled'}
            >
              Place view into script
            </Button>
          }
        </div>
      </div>
      {checkViewModal && (
        <CheckViews
          showModal={checkViewModal}
          handleModalViews={handleCloseModalViews}
          backgroundImage={props.backgroundImage}
          otherView={props.otherView}
          selectedBackground={props.selectedBackground}
        />
      )}
    </React.Fragment>
  );
}

export default CreateViewTab;

import React, { useEffect, useState } from 'react';
import { Row, Form, Col, Button, InputGroup } from 'react-bootstrap';
import { PlaceBgStyle } from './style';
import { StoryService } from './../service';
import {
    FADE_INTO_SCREEN,
    FADE_INTO_SCREEN_ERROR,
} from '../../../components/library/translations';
import * as Pattern from './../../../common/helper/pattern';

const callbackFunction = (x, id) => {
    let element = document.getElementById('view-' + id);
    if (element != null) {
        element.style.backgroundImage = `url(${x})`;
        element.style.backgroundRepeat = 'no-repeat';
        element.style.backgroundPosition = 'center';
        element.style.backgroundSize = '100% 100%';
    }
}

function BrowseView(props) {
    let filterList = props.searchViews.length === 0 ? props.views : props.searchViews;

    const [name, setName] = useState(null);
    const [fadeIntoSeconds, setFadeIntoSeconds] = useState(props.fadeinView);
    const [viewId, setViewId] = useState();

    useEffect(() => {
        filterList.map(view => {
            let url = view.asset.awsPath;
            StoryService.resizeImage(url, view.width, view.height, view.xcoordinate, view.ycoordinate, callbackFunction, view.id);
        });
    }, [filterList]);

    useEffect(() => {
        if (props.backgroundCopy !== null) {
            setViewId(props.backgroundCopy);
        }
        if (props.fadeinView !== null) {
            setFadeIntoSeconds(props.fadeinView);
        }
    }, [props.backgroundCopy, props.fadeinView])

    const handleViewClick = (view) => {
        setName(view.name);
        setViewId(view.id);
        if (view.id === viewId) {
            setViewId(null);
        }
    }

    const handleFadeIntoSecondsChange = (event) => {
        let seconds = event.target.value;

        if (seconds == '' || Pattern.NUMBER_FIELD_PATTERN.test(seconds)) {
            setFadeIntoSeconds(seconds);
        }
    }

    return (
        <React.Fragment>
            <div className="browse-tab-content">
                <div className="tab-list-content row">
                    {
                        props.searchText != '' && props.searchViews.length == 0 &&
                        'No views found'
                    }
                    {
                        (props.searchText == '' || props.searchViews.length > 0) &&
                        filterList.map(view => {
                            let objectCountLabel = view.viewObjects.length === 1 ? 'object' : 'objects';
                            return <div className={`list-col ${view.id === viewId ? 'active' : null}`}
                                onClick={() => handleViewClick(view)}
                                key={'view' + view.id}>
                                <PlaceBgStyle.coverBg background={view.asset.awsPath} id={`view-${view.id}`}>

                                    <div className="border-div"></div>
                                </PlaceBgStyle.coverBg>
                                <div className="browse-img-details">
                                    <div className="browse-img-name">{view.name}</div>
                                    <div className="browse-img-size">
                                        {view.asset.specs} | {`${view.viewObjects.length} ${objectCountLabel}`}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="mt-3">
                {
                    <Row>
                        <div className="mb-1">
                            <Form.Label>{FADE_INTO_SCREEN}</Form.Label>
                        </div>
                    </Row>
                }
                {
                    <Row>
                        <div className="mb-4 ">
                            <Col>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        className="placeViewNumber mr-2"
                                        label={"Fade into screen"}
                                        placeholder="Fade into screen"
                                        step="0.1"
                                        required="true"
                                        size="50"
                                        min="0"
                                        defaultValue={fadeIntoSeconds}
                                        value={fadeIntoSeconds}
                                        name="fadeintoseconds"
                                        onChange={handleFadeIntoSecondsChange}
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="noBackground" >Seconds</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control.Feedback type="invalid" >
                                        {FADE_INTO_SCREEN_ERROR}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </div>
                    </Row>
                }
            </div>
            <div className="button-wrapper">
                <Button className="btn-snacktool btn-snacktool-golden"
                    onClick={props.onHideModal} >Cancel</Button>
                <Button
                    className="btn-snacktool btn-snacktool-golden"
                    disabled={`${viewId === null || fadeIntoSeconds === '' ? 'disabled' : ''}`}
                    onClick={() => props.handlePlaceViewScript(name, fadeIntoSeconds)}
                >
                    Place view into script
                    </Button>
            </div>
        </React.Fragment>
    )
}

export default BrowseView
import React, { useState, useEffect } from 'react';
import * as PlayerStyled from '../style';
import * as Constants from './../../../common/constants';
import * as Config from './../../../config/app.config';
import { PlayerService } from './player-service';
import Typed from 'react-typed';

function DialogComponentNew(props) {
    let {
        command, 
        gameInterfaceList,
        blobImages,
        dialogList,
        skipTimeout,
        gameFonts,
        dialogueText,
        handlePlayerButtonsClick,
        setDialogueText,
        setDialogueCount,
        outsideClick,
        setOutsideClick,
        localVariableListDTO
    } = props;

    const [showFullDialogue, setShowFullDialogue] = useState(false);
    useEffect(() => {
        if (outsideClick === true) {
            handleClick({})
        }
    }, [outsideClick])

    let backgroundImageKey = gameInterfaceList[Constants.interfaceList[0]];
    let continueIconKey = gameInterfaceList[Constants.interfaceList[2]];
    let nameBackgroundImageKey = gameInterfaceList[Constants.interfaceList[1]];
    let defaultContinueIconKey = Constants.defaultInterfaceImageList[2];
    let defaultBackgroundImageKey = Constants.defaultInterfaceImageList[0];
    let defaultNameBackgroundImageKey = Constants.defaultInterfaceImageList[1];

    let backgroundImage = backgroundImageKey != null && blobImages.hasOwnProperty(backgroundImageKey) ? blobImages[backgroundImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultBackgroundImageKey;
    let continueIconImage = continueIconKey != null && blobImages.hasOwnProperty(continueIconKey) ? blobImages[continueIconKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultContinueIconKey;
    let nameImage = nameBackgroundImageKey != null && blobImages.hasOwnProperty(nameBackgroundImageKey) ? blobImages[nameBackgroundImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultNameBackgroundImageKey;

    const typingDone = () => {
        let icon = document.getElementById('icon');
        if (icon)
            icon.className += ' continue-icon';
    }
    
    const handleClick = (event) => {
        let dialogElement = document.getElementsByClassName('typer');
        let dialogLength = 0;
        if (dialogueText[0]) {
            dialogLength = dialogueText[0].length;
        }

        let displayTextLength = 0;
        if (dialogElement[0]) {
            displayTextLength = dialogElement[0].children[0].innerHTML.length;
        }
        if (dialogLength > displayTextLength) {
            setShowFullDialogue(true);
            setOutsideClick(false);
            let icon = document.getElementById('icon');
            if (icon)
                icon.className += ' continue-icon';
        }
         else {
            handlePlayerButtonsClick(event, Constants.EVENT_PLAY);
            setDialogueText([]);
            setDialogueCount(0);
            setOutsideClick(false);
        }
    }

    const createDialogue = () => {
        return {
            __html: PlayerService.escapeHtml(newString)
        }
    }

    let newString = PlayerService.changeSpecialChar(dialogList, '&', '&amp;');

    return <React.Fragment>
        {
            skipTimeout === false &&
            <React.Fragment>
                {
                    command !== null &&
                    <PlayerStyled.dialogueBox
                        Dialoguebackground={backgroundImage}
                        Continueicon={continueIconImage}
                        NameImage = {nameImage}
                        color={command !== null && command.characterDTO != null ? command.characterDTO.labelColor : ''}
                        gameFonts = {gameFonts}>
                        {
                            command.parameters.Character !== '' && command.parameters.Character !== undefined &&
                            <div className="right-mobile-title" id="namePlate">
                                {
                                    PlayerService.getCharacterName(command.parameters, localVariableListDTO)
                                }
                            </div>
                        }
                        <div className="right-mobile-text" 
                            id="dialogBackground" >
                            {
                                showFullDialogue === false &&
                                <Typed strings={[PlayerService.escapeHtml(newString)]} 
                                    typeSpeed={30}
                                    onComplete={typingDone}
                                    id="typewriter"
                                    className="typer"
                                    cursorChar={''}/>
                            }
                            {
                                showFullDialogue === true &&
                                <span className="typer" id="typewriter">
                                    <span dangerouslySetInnerHTML={createDialogue()} />
                                </span>
                            }
                            {
                                <div className="" id="icon"></div>
                            }
                        </div>
                        
                    </PlayerStyled.dialogueBox>
                }
                
            </React.Fragment>
        }
        
    </React.Fragment>
}

export default DialogComponentNew
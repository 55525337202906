export const LoginConstant = [
  {
    title: "Welcome to Fictif's interactive story creation tool!",
    subTitle: 'Writers and artists, you can now create your own visual novels with this writer’s tool.',
    bgColor: '#F2AB30',
    prevButton:false,
    NextButton:true,
    prevButtonText:'Previous',
    NexButtonTitle:'Next',
    bgImag:'/images/OnboardingPage_1.jpg',
  },
  {
    title: 'Bring your story to life.',
    subTitle: 'Create your very own interactive visual novel by seamlessly integrating your story text, images, choices, and music.  Collaborate with a team, or work alone.',
    bgColor: '#157FFB',
    prevButton:false,
    NextButton:true,
    prevButtonText:'Previous',
    NexButtonTitle:'Next',
    bgImag:'/images/OnboardingPage_2.jpg',
  },
  {
    title: 'Ready to create your visual novel?',
    subTitle: 'You will be guided through the process of creating your first story!',
    bgColor: '#BC45AD',
    prevButton:false,
    NextButton:true,
    prevButtonText:'Previous',
    NexButtonTitle:"Let's go!",
    bgImag:'/images/OnboardingPage_3.jpg',
  },
 
];
import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { LibraryStyle } from '../../style/style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Constants from '../../../../common/constants';

function EditAssetInfoList(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleHideDeleteModal}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="header-text"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-wrapper">
          <div className="modal-icon">
            <img
              alt=""
              className="checkIcon"
              src="/images/icons/ic_warning_24px.svg"
            />
          </div>
          <div className="modal-text">
            <label className="heading">
              Are you sure want to edit this file?
            </label>
            <label className="sub-heading">
              {props.asseDataType.type === 'Background_Image'
                ? `If you change this background file then you may have undesired effects in all story views that use it`
                : `If you edit this asset, the scripts will change automatically in the following places`}
            </label>
          </div>

          <div className="Modal-extra-content">
            <LibraryStyle.tableContainer>
              <thead>
                <tr>
                  {props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[1] &&
                    props.deleteAsset.type !==
                      Constants.ASSET_TYPES_IMAGES[0] && (
                      <th className="text-left w-50 text-break d-inline-block p-3">
                        Chapter
                      </th>
                    )}
                  <th className="text-left w-50 text-break d-inline-block p-3">
                    {props.deleteAsset.type !==
                      Constants.ASSET_TYPES_IMAGES[1] &&
                    props.deleteAsset.type !== Constants.ASSET_TYPES_IMAGES[0]
                      ? 'Block'
                      : 'View'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.assetInfoList !== undefined &&
                  props.assetInfoList.map((asset, index) => (
                    <tr key={`tooltip1-${asset.id + index}`}>
                      {props.deleteAsset.type !==
                        Constants.ASSET_TYPES_IMAGES[1] &&
                        props.deleteAsset.type !==
                          Constants.ASSET_TYPES_IMAGES[0] && (
                          <td className="text-small-light text-left w-50 text-break d-inline-block p-3">
                            {asset.sceneName}
                          </td>
                        )}
                      <OverlayTrigger
                        key={`tooltip-${asset.id}`}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${asset.id}`}>
                            {asset.blockNames.toString()}
                          </Tooltip>
                        }
                      >
                        <td className="text-small-light text-left w-50 d-inline-block p-3">
                          {asset.blockNames.toString()}
                        </td>
                      </OverlayTrigger>
                    </tr>
                  ))}
              </tbody>
            </LibraryStyle.tableContainer>
          </div>
        </div>
        <div className="">
          <Row>
            <Col>
              <Button
                className="btn-snacktool btn-snacktool-golden btn btn-primary"
                onClick={() => props.closeModalOperation()}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={props.pressOkay}
                className="btn-snacktool btn-snacktool-golden col-lg-6"
                style={{ float: 'right' }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default EditAssetInfoList;

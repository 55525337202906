
import * as Constant from './../../../common/constants';
import * as Config from './../../../config/aspectRatio.config';
import React from 'react';

const getCommandColor = () => {
  let color = 'commandTextBorder';

  // switch (commandId) {
  //   case Constant.COMMAND_ADD_DIALOGUE:
  //     color = 'commandTextBorder';
  //     break;

  //   default:
  //     color = 'commandTextBorder'
  //     break;
  // }
  return color;
}

const getImageLayers = (spriteLayers, commandId, callback, objects = '') => {
  if (commandId === Constant.COMMAND_ENTER_THE_SCENE
    || commandId === Constant.COMMAND_CHANGE_EXPRESSION
    || commandId === Constant.COMMAND_REPLACE_CHARACTER
    || commandId === Constant.COMMAND_SHOW_OUTFIT
    || commandId === Constant.COMMAND_SET_OUTFIT
    || commandId === Constant.COMMAND_REPLACE_OUTFIT
    || commandId === Constant.COMMAND_SHOW_OBJECT
    || commandId === Constant.COMMAND_TAP_OBJECT
    || commandId === Constant.COMMAND_HIDE_OBJECT) {
    let position = 0;
    const spriteLayersNew = spriteLayers.sprites.filter(sprite => sprite.url !== null)
    let layers = spriteLayersNew.reverse().map((sprite, index) => {
      position++;
      if (sprite.hasOwnProperty('url')) {
        if (sprite.url !== null && sprite.url !== '') {
          if (spriteLayersNew.length > 1) {
            return (<img src={sprite.url}
              alt="Char image"
              key={index}
              width="100%"
              height="100%"
              className="frame-image" />)
          } else {
            return (<img src={sprite.url}
              alt="Char image"
              key={index}
              width="100%"
              height="100%"
              className="" />)
          }
        }
      }
    });

    return layers;
  } else {
    return new Promise(resolve => {

      let imageObj = new Image();
      imageObj.crossOrigin = true;
      imageObj.onload = function () {
        let canvas = document.createElement("canvas");
        let width = Config.aspectRatioParameters[Config.aspectRatioThreeByFour].staticPreviewWidth;
        let height = Config.aspectRatioParameters[Config.aspectRatioThreeByFour].staticPreviewHeight;

        canvas.height = height;
        canvas.width = width;
        let context = canvas.getContext('2d');
        context.drawImage(imageObj,
          spriteLayers.view.xcoordinate,
          spriteLayers.view.ycoordinate,
          spriteLayers.view.width,
          spriteLayers.view.height, 0, 0, width, height);
        callback(canvas.toDataURL(), objects);
      };
      imageObj.src = spriteLayers.view.asset.awsPath;

    });
  }
}

export const EditorService = {
  getCommandColor,
  getImageLayers
}
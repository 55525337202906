import React from 'react';
import { Button, Modal } from 'react-bootstrap';
 

const ConfirmationWindowDelete = (props) => {
  return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="confirmModal"
            className="modalbg" 
            size="md"
            show={props.show}
            onHide={() => props.onCancelClick()}
        >

            <Modal.Body className="mt-5">
                <div className="modal-content-wrapper">
                    <div  className="modal-icon">
                        <img alt="" className="checkIcon" src="/images/icons/ic_warning_24px.svg"/>
                    </div>
                    <div  className="modal-text">
                        <label className="heading">Deleting this chapter will delete all its content and cannot be undone.</label>
                        <label className="heading">Are you sure you want to proceed?</label>
                    </div>
                
                </div>
                <div className="modal-button-wrapper justify-content-between">
                    <Button className="btn-snacktool btn-snacktool-golden" onClick={() => props.onCancelClick()}>Cancel</Button>
                    <Button onClick={() => props.onConfirmDelete()} className="btn-snacktool btn-snacktool-golden btn btn-primary">Yes, delete it </Button>
                </div>

            </Modal.Body>
        </Modal>
  );
};
export default ConfirmationWindowDelete;
import styled from 'styled-components';
import { colors } from './../../common/styles/variables';

const contentPanel = styled.div`
overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 160px);
  margin-left: -6px;
//   margin-right: 233px;
  margin-top: -60px;
  border-radius: 6px 6px 0 0;
  background: ${colors.color_white};
  box-shadow: 0px 0px 10px ${colors.dark_red};
  margin-bottom:5px;
  height: calc(100vh - 90px);
  .add-info-modal-body {
      padding-right: 56px;
      padding-left: 56px;
  }
  .cursor-pointer {
      cursor: pointer;
  }
  `;

const addAssetMessageBox = styled.div`
    display: flex;
    justify-content: center;
`;

const successAssetContent = styled.div`
    width: 100%;
    margin-top: 25%;
    text-align: center;
`;
export const Style = {
    contentPanel,
    addAssetMessageBox,
    successAssetContent
}
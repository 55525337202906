import React from 'react';
import * as Constants from './../../../common/constants';
import { LibraryServices } from './../service';

function GameFontsDropDown(props) {
    let list=LibraryServices.getSortedList(Constants.dialogBoxFonts);
    if(props.type==='dialogueText') {
        list=LibraryServices.getSortedList(Constants.dialogBoxFonts);
    }else if(props.type==='characterNamePlate'){
        list=LibraryServices.getSortedList(Constants.characterNamePlateFonts);
    }else if(props.type==='buttonLabel'){
        list=LibraryServices.getSortedList(Constants.buttonLabelFonts);
    }
   
    return <>
        <select className="form-control" defaultValue={props.gameFonts[props.type]}
            onChange = {props.handleDropDownChange}
            name = {props.type}
            value={props.gameFonts[props.type]}>
        {
            list.map(font => {
                return <option value={font}>
                    {LibraryServices.prepareFontName(font)}
                </option>
            })
        }
        </select>
    </>
}

export default GameFontsDropDown
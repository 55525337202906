import styled from 'styled-components';
 

export const Tabdiv = styled.div`
    width: 100%;
    .tab-panel{
        width:100%;
        border:0 !important;
        .nav-link{
            border:0 !important;
            margin-bottom:0 !important;
            padding: 7px 25px !important;
            color:#ACACAC !important;
            font-size:14px;
            font-family:PT Sans;
            &.active{
                border-bottom: 4px solid #530ACD !important;
                color:#530ACD !important;
            }
        }
    }
    .tab-content{
        .view-tab-content{
            width:100%;
            display: flex
            border-top: 1px solid #F5F5FA;
            .left-content{
                width:55%;
                background:#F5F5FA;
                border-radius: 4px;
                max-height:430px;
                min-height:430px;
                background-size: cover;
            }
            .left-content img {
                height: 430px;
                width: 100%;
            }
            .right-content{

                width:45%;
                padding:30px;
                max-height:430px;
                min-height:430px;
                form{
                .form-label{
                    color:#303030;
                    font-size:14px;
                    font-family:PT Sans;
                    font-weight:bold;
                    margin-bottom:0;
                }
                .commandLabelSuffix{
                    color:#707070;
                }
            }
            }
        }
        .browse-tab-content{
            width:100%;
            background:#F5F5FA;
            max-height:430px;
            min-height:430px;
            padding: 24px;

            .tab-header-content{
                display: FLEX;
                justify-content: space-between;
                align-items: center;

                .fa-clone{

                }
            }
            .tab-list-content{
                display: FLEX;
                justify-content:flex-start;
                align-items: center;
                max-height: 320px;
                overflow: auto;
                margin-top: 10px !important;
                .list-col{
                    background:#ffffff;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
                    border-radius: 6px;
                    min-height:210px;
                    max-height:210px;
                    margin: 15px !important;
                    width: 28%;
                    position: relative;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);

                    .border-div{
                        border-left: 2px solid #45C7AD;
                        border-right: 2px solid #45C7AD;
                        width: 70px;
                        height: inherit;
                        position: absolute;
                        top: 20px;
                        left: 50px;
                    }
                    &.active{
                        border: 2px solid #157FFB;
                    }
                    .browse-img-details{
                        margin: 0 10px;
                        .browse-img-name{
                            color:#303030;
                            font-size:13px;
                            font-family:PT Sans;
                        }
                        .browse-img-size{
                            color:#707070;
                            font-size:8px;
                            font-family:PT Sans;
                        }
                }
                }
            }
        }
    }
    
    .button-wrapper{
        margin-top:40px;
        display: flex;
        justify-content: space-between;
    }

`;

const browseBg = styled.div`
    background-image: url(${props => props.bg});
    width: 70%;
    height: 130px;
    margin: 20px auto 10px auto;
    height: 130px;
    background-size: cover;
    border: 2px solid #45C7AD;
    top: 30px;
    left: 30px;
`;

const coverBg = styled.div`
    width: 70%;
    height: 130px;
    margin: 20px auto 10px auto;
    border: 2px solid #45C7AD;
    top: 30px;
    left: 30px;
`;
 
const moveBg = styled.div`
    position:relative;
    .left-content, .right-content{
        float:left;
        width:50%;
    }
    .left-content{
        height:430px;
        background:rgb(245, 245, 250);
        overflow: hidden;
    }
    .right-content{
        padding-left:30px !important;
    }
    .transitionTaxtBox{
        width:70px;
        font-size:14px;
        color: #707070 !important;
    }
    .label-title-text{
        font-size: 14px;
        font-weight:bold;
    }
    .secondsTextLabel{
        color: #707070 !important;
        font-size: 14px;
    }
    .transitionDuration {
        width: 45%;
    }
    .warningMessageBox{
        background: #f5f5f5;
        border-radius: 4px;
        padding:16px;
        margin-top: 40px;
    }
    .warningMessage{
        font-size:14px;
        color: #707070;
    }

    .warning-icon{
        margin-right:16px;
    }
    .input-group{
      width:65% !important;
    }
    .input-group-text{
      border:none !important;
      color: #707070 !important;

      background-color:#ffffff !important;
    }
    .spinner-class {
      text-align: center !important;
      vertical-align: middle !important;
      margin: 60% 45% !important;
    }
`;
export const PlaceBgStyle = {
    Tabdiv,
    browseBg,
    coverBg,
    moveBg
};

import * as ActionTypes from '../actions/game-interface-action-types';
import * as Constant from './../components/library/constant';

export const initialState = {
  interfaceList: [],
  loading: false,
  uploadModal: false,
  activeTab: Constant.FIRST_TAB_KEY,
  message: null,
  elementStatus: '',
  resetSuccess: false,
  gameFonts: {
    dialogueText: 'Raleway',
    characterNamePlate: 'Lato',
    buttonLabel: 'Lato'
  },
  successModal: false
};

export const GameInterfaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_GAME_INTERFACE:
      return {
        ...state,
        loading: action.loading
      };
      break;
    case ActionTypes.GET_GAME_INTERFACE_SUCCESS:
      return {
        ...state,
        interfaceList: action.gameInterfaceList,
        loading: action.loading
      };
      break;
    case ActionTypes.GET_GAME_INTERFACE_FAIL:
      return {
        ...state,
        interfaceList: [],
        loading: action.loading
      };
      break;
    case ActionTypes.SET_SHOW_MODAL_TRUE:
      return {
        ...state,
        uploadModal: true,
        elementStatus: ''
      };
      break;
    case ActionTypes.SET_SHOW_MODAL_FALSE:
      return {
        ...state,
        uploadModal: false,
        successModal: false
      };
      break;

    case ActionTypes.SAVE_ELEMENT:
      return {
        ...state,
        // uploadModal: false
      };
      break;

    case ActionTypes.SAVE_ELEMENT_FAIL:
      return {
        ...state,
        message: action.message,
        activeTab: Constant.FIFTH_TAB_KEY,
        uploadModal: false,
        elementStatus: 'fail'
      };
      break;
    case ActionTypes.SAVE_ELEMENT_SUCCESS:
      return {
        ...state,
        // activeTab: Constant.FIRST_TAB_KEY,
        elementStatus: '',
        uploadModal: false,
        successModal: true
      };
      break;
    case ActionTypes.SET_ACTIVE_TAB_KEY:
      return {
        ...state,
        activeTab: action.activeTabKey
      };
    case ActionTypes.SET_RESET_ELEMENT_SUCCESS:
      return {
        ...state,
        resetSuccess: true
      };
      break;
    case ActionTypes.HIDE_RESET_SUCCESS_MODAL:
      return {
        ...state,
        resetSuccess: false
      };
    case ActionTypes.GET_GAME_FONTS:
      return {
        ...state
      };
    case ActionTypes.GET_GAME_FONTS_SUCCESS:
      return {
        ...state,
        gameFonts: action.payload
      };
    case ActionTypes.SET_GAME_FONTS_SUCCESS:
      return {
        ...state,
        gameFonts: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export const INTRO_OPTIONS = {
  skipLabel: 'x',
  prevLabel: 'Previous',
  nextLabel: 'Next',
  exitOnEsc: true,
  exitOnOverlayClick: true,
  showStepNumbers: false,
  hidePrev: true,
  hideNext: true,
  overlayOpacity: 0.5,

  doneLabel: 'Finish'
};

export const stepVarConstant = [
  {
    element: '.stesp-var-main',
    intro:
      '<h3>VARIABLES</h3><p>You can use variables to control the flow of a story and save choices the player has made.</p>',
    position: 'bottom',
    tooltipClass: 'writetool_library_5'
  },
  {
    element: '.step-var',
    intro:
      '<h3>VARIABLE WINDOW</h3><p>This is where all defined variables will be displayed.</p>',
    position: 'bottom',
    tooltipClass: 'writetool-if_else_3'
  },
  {
    element: '.step-command',
    intro:
      '<h3>SET VARIABLES</h3><p>You can define variables here or directly in the script.</p>',
    position: 'left',
    tooltipClass: 'writetool-if_else_3'
  },

  {
    element: '.stesp-var',
    intro:
      '<h3>CROSS-CHAPTER VARIABLES</h3><p>These variables will carry across chapters and persist for the rest of the story.</p>',
    position: 'bottom',
    tooltipClass: 'writetool_library_5'
  },
  {
    element: '.stesp-var',
    intro:
      '<h3>VARIABLE VALUE</h3><p>You can always update the value of a variable in the script.</p>',
    position: 'bottom',
    tooltipClass: 'writetool-if_else_3'
  }
];

import React, { useState } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import * as translations from '../create-story/translations';
import { LibraryStyle } from '../style/style';
import '../style/style.css';
import * as Constant from '../create-story/constant';
import AddInfo from './AddInfo';
import TitleCover from './TitleCover';
import Tags from './Tags';
import AddFiles from './../story-info/AddFiles';

function StoryInfoTabs(props) {
  let firstTabClass =
    props.activeTabKey === Constant.FIRST_TAB_KEY
      ? 'circle-fill inactivelinks'
      : 'circle-fill inactivelinks';
  let secondTabClass =
    props.activeTabKey === Constant.SECOND_TAB_KEY ||
    props.activeTabKey === Constant.THIRD_TAB_KEY
      ? 'circle-fill inactivelinks'
      : 'circle-empty inactivelinks';
  let thirdTabClass =
    props.activeTabKey === Constant.THIRD_TAB_KEY
      ? 'circle-fill inactivelinks'
      : 'circle-empty inactivelinks';

  const [showAlbumIamge, setShowAlbumIamge] = useState(false);
  const [showSeriesIamge, setShowSeriesIamge] = useState(false);
  const [showTitleIamge, setShowTitle] = useState(false);

  const highlightALbum = () => {
    setShowAlbumIamge(true);
  };

  const resetAlbumBorder = () => {
    setShowAlbumIamge(false);
  };

  const highlightSeries = () => {
    setShowSeriesIamge(true);
  };

  const resetSeriesBorder = () => {
    setShowSeriesIamge(false);
  };

  const highlighttitle = () => {
    setShowTitle(true);
  };

  const resetTitleBorder = () => {
    setShowTitle(false);
  };

  return (
    <LibraryStyle.addInfoContainer>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={Constant.FIRST_TAB_KEY}
        activeKey={props.activeTabKey}
        onSelect={k => props.handleActiveKey(k)}
      >
        {(props.activeTabKey === Constant.FIRST_TAB_KEY ||
          props.activeTabKey === Constant.SECOND_TAB_KEY ||
          props.activeTabKey === Constant.THIRD_TAB_KEY) && (
          <Row>
            <Col>
              <LibraryStyle.tabLayout>
                <LibraryStyle.leftTabLabelLayout>
                  <LibraryStyle.leftTabLabelContent>
                    <Nav.Link
                      className={`${firstTabClass}`}
                      eventKey={Constant.FIRST_TAB_KEY}
                    >
                      {props.activeTabKey !== Constant.FIRST_TAB_KEY ? (
                        <i className="fa fa-check" />
                      ) : (
                        1
                      )}
                    </Nav.Link>
                    <LibraryStyle.firstCircleContent>
                      {translations.GENERAL_INFO}
                    </LibraryStyle.firstCircleContent>
                  </LibraryStyle.leftTabLabelContent>
                  <LibraryStyle.leftTabLine></LibraryStyle.leftTabLine>
                </LibraryStyle.leftTabLabelLayout>
                <LibraryStyle.rightTabLabelLayout>
                  <LibraryStyle.rightTabLabelContent>
                    <Nav.Link
                      className={secondTabClass}
                      eventKey={Constant.SECOND_TAB_KEY}
                    >
                      {props.activeTabKey === Constant.THIRD_TAB_KEY ? (
                        <i className="fa fa-check" />
                      ) : (
                        2
                      )}
                    </Nav.Link>
                    <LibraryStyle.secondCircleContent>
                      {translations.UPLOAD_FILE}
                    </LibraryStyle.secondCircleContent>
                  </LibraryStyle.rightTabLabelContent>
                  <LibraryStyle.leftTabLine></LibraryStyle.leftTabLine>
                </LibraryStyle.rightTabLabelLayout>
                <LibraryStyle.rightTabLabelLayout>
                  <LibraryStyle.rightTabLabelContent>
                    <Nav.Link
                      className={thirdTabClass}
                      eventKey={Constant.THIRD_TAB_KEY}
                    >
                      3
                    </Nav.Link>
                    <LibraryStyle.secondCircleContent>
                      {translations.ADDITIONAL_INFO}
                    </LibraryStyle.secondCircleContent>
                  </LibraryStyle.rightTabLabelContent>
                </LibraryStyle.rightTabLabelLayout>
              </LibraryStyle.tabLayout>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="p-0" sm={12}>
            <Tab.Content className="tabBody">
              <Tab.Pane eventKey={Constant.FIRST_TAB_KEY}>
                <AddInfo storyDetails={props.storyDetails} />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.SECOND_TAB_KEY}>
                {/* <Row>
                                    <Col>
                                        <div className="text-secondary mt-4">
                                            You can edit this story info and add these images later. Supported file formats: PNG, JPG, JPEG. File size limit: 15 Mb.
                                        </div>
                                    </Col>
                                </Row>
                                <div className="upload-files-wrapper d-flex mt-4">
                                    <TitleCover storyDetails={props.storyDetails} />
                                </div> */}
                <AddFiles
                  setShowClearFile={props.setShowClearFile}
                  seriesBackgroundFiles={[]}
                  artTitleFiles={[]}
                  asset={props.asset}
                  clearfile={false}
                  storyDetails={props.storyDetails}
                  showSeriesIamge={showSeriesIamge}
                  showTitleIamge={showTitleIamge}
                  generes={[]}
                  showAlbumIamge={showAlbumIamge}
                  highlightALbum={highlightALbum}
                  resetAlbumBorder={resetAlbumBorder}
                  highlightSeries={highlightSeries}
                  resetSeriesBorder={resetSeriesBorder}
                  highlighttitle={highlighttitle}
                  resetTitleBorder={resetTitleBorder}
                  description={props.storyDetails.description}
                  titleCoverFiles={[props.storyDetails.titleCover]}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={Constant.THIRD_TAB_KEY}>
                <Tags
                  genreList={props.genreList}
                  generes={
                    props.storyDetails.genres !== undefined
                      ? props.storyDetails.genres
                      : []
                  }
                  storyDetails={props.storyDetails}
                  tags={props.storyDetails.tags}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </LibraryStyle.addInfoContainer>
  );
}

export default StoryInfoTabs;

import React from 'react';
import { LibraryStyle } from './../style/style';
import { Table, Badge } from 'react-bootstrap';
import moment from 'moment';
import { LibraryServices } from './../service';

function GameInterfaceChangeLog(props) {
    return (
        <React.Fragment>
            <LibraryStyle.listTable >
                {
                    props.gameInterfaces.length > 0 &&
                    <Table responsive="md">
                        <thead className="bg-light">
                            <tr className="table-head-row">
                                <th className="text-left">ELEMENT</th>
                                <th className="text-left">SOURCE</th>
                                <th>FORMAT</th>
                                <th>
                                    LAST CHANGE ON
                                    <i className={`fa fa-sort-${props.gameInterfacesLogSort}`} 
                                        onClick={props.handleSortClick} 
                                        sort-name="gameInterfaces"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                props.gameInterfaces.map((gameInterface) => {
                                    return <tr className="table-body-row">
                                        <td className="text-left">{LibraryServices.makeCamelCaseString(gameInterface.element)}</td>
                                        <td className="text-left">{LibraryServices.makeCamelCaseString(gameInterface.source)}</td>
                                        <td><Badge variant="secondary" className="badges">{gameInterface.extension}</Badge></td>
                                        <td className="blur-text">{moment(new Date(gameInterface.updatedAt)).format('MM-DD-YYYY')}</td>
                                    </tr>
                                })
                            }
                        </tbody>     
                    </Table>
                }
                {
                    props.gameInterfaces.length === 0 &&
                    <div className="no-result">
                        <h3 className="text-center">No Game interface elements have been changed</h3>
                    </div>
                }
            </LibraryStyle.listTable>
        </React.Fragment>
    )
}

export default GameInterfaceChangeLog
import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from './../actions/library-action-types';
import * as Actions from './../actions/library-action';
import {
  GET_RESET_FLOWCHART_DETAILS,
  SET_API_ERROR,
  SET_LOGIN_DETAILS
} from '../actions/action-types';
import {
  postAsset,
  fetchAsset,
  rollbackAsset,
  getDeleteAsset,
  deleteAsset,
  deleteCover,
  getAssetById,
  getLockedAssetById,
  fetchLibraryChangeLog,
  uploadAsset,
  uploadCover,
  fetchCover,
  getCoverById,
  postCover,
  activeCover
} from './api/library-api';

export function* setAssetWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(postAsset, payloads);
      if (postData.result !== 'Success') {
        yield put(Actions.setAssetFail());
      } else {
        yield put(Actions.setAssetSuccess(postData));
        yield put(Actions.setAssetList(payloads.authToken, payloads.storyId));
      }
    } catch (e) {
      yield put(Actions.setAssetFail());
    }
  }
}

export function* setCoverWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(postCover, payloads);
      if (postData.result !== 'Success') {
        yield put(Actions.setCoverFail());
      } else {
        yield put(Actions.setCoverSuccess(postData));
        yield put(Actions.setCoverList(payloads.authToken, payloads.storyId));
      }
    } catch (e) {
      yield put(Actions.setCoverFail());
    }
  }
}

export function* setAssetUploadWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(uploadAsset, payloads);
      if (postData.result !== 'Success') {
        yield put(Actions.setAssetUploadFail());
      } else {
        yield put(Actions.setAssetUploadSuccess(postData));
        yield put(Actions.setAssetList(payloads.authToken, payloads.storyId));
      }
    } catch (e) {
      yield put(Actions.setAssetUploadFail());
    }
  }
}

export function* setCoverUploadWorker(payloads) {
  if (payloads.authToken) {
    try {
      const postData = yield call(uploadCover, payloads);
      if (postData.result !== 'Success') {
        yield put(Actions.setAssetUploadFail());
      } else {
        yield put(Actions.setAssetUploadSuccess(postData));
        yield put(Actions.setCoverList(payloads.authToken, payloads.storyId));
      }
    } catch (e) {
      yield put(Actions.setAssetUploadFail());
    }
  }
}

export function* fetchAssetListWorker(payload) {
  if (payload.authToken) {
    try {
      yield put(Actions.setLoadingTrue());
      const data = yield call(fetchAsset, payload);
      if (data.result !== 'Success') {
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put(Actions.setAssetListFail());
          yield put({
            type: SET_API_ERROR
          });
        }
      } else {
        yield put(Actions.setAssetListSuccess(data.body));
        yield put({
          type: GET_RESET_FLOWCHART_DETAILS
        });
      }
      yield put(Actions.setLoadingFalse());
    } catch (e) {
      yield put(Actions.setAssetListFail());
      yield put({
        type: SET_API_ERROR
      });
    }
  }
}

//Cover
export function* fetchCoverListWorker(payload) {
  if (payload.authToken) {
    try {
      yield put(Actions.setLoadingTrue());
      const data = yield call(fetchCover, payload);
      if (data.result !== 'Success') {
        if (
          data.message[0] ===
          'Full authentication is required to access this resource'
        ) {
          yield put({
            type: SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
        } else {
          yield put(Actions.setCoverListFail());
          yield put({
            type: SET_API_ERROR
          });
        }
      } else {
        yield put(Actions.setCoverListSuccess(data.body));
        yield put({
          type: GET_RESET_FLOWCHART_DETAILS
        });
      }
      yield put(Actions.setLoadingFalse());
    } catch (e) {
      yield put(Actions.setCoverListFail());
      yield put({
        type: SET_API_ERROR
      });
    }
  }
}

export function* setRollbackAssetWorkder(payload) {
  try {
    const data = yield call(rollbackAsset, payload);
    if (data.result === 'Success') {
      yield put(Actions.setAssetCancel());
    }
  } catch (e) {}
}

export function* getAssetEditInfoWorker(payload) {
  if (payload.authToken) {
    try {
      //change api call
      const data = yield call(getDeleteAsset, payload);
      if (data.result === 'Success') {
        let assetList = data.body;
        yield put(Actions.getAssetEditInfoSuccess(assetList));
      } else {
        yield put(Actions.getAssetEditInfoFail());
      }
    } catch (err) {
      yield put(Actions.getAssetEditInfoFail());
    }
  }
}

export function* getCoverEditInfoWorker(payload) {
  if (payload.authToken) {
    try {
      //change api call
      const data = yield call(getCoverById, payload);
      if (data.result === 'Success') {
        let assetList = data.body;
        yield put(Actions.getCoverEditInfoSuccess(assetList));
      } else {
        yield put(Actions.getCoverEditInfoFail());
      }
    } catch (err) {
      yield put(Actions.getCoverEditInfoFail());
    }
  }
}

export function* getAssetDeleteWorker(payload) {
  if (payload.authToken) {
    try {
      //change api call
      const data = yield call(getDeleteAsset, payload);
      if (data.result === 'Success') {
        let assetList = data.body;
        yield put(Actions.getAssetDeleteSuccess(assetList));
      } else {
        yield put(Actions.getAssetDeleteFail());
      }
    } catch (err) {
      yield put(Actions.getAssetDeleteFail());
    }
  }
}

export function* AssetDeleteWorker(payload) {
  if (payload.authToken) {
    try {
      //change api
      const data = yield call(deleteAsset, payload);

      if (data.result === 'Success') {
        let assetList = data.body;
        yield put(Actions.deleteAssetSuccess(assetList));
        yield put(Actions.setAssetList(payload.authToken, payload.storyId));
      } else {
        yield put(Actions.deleteAssetFail());
      }
    } catch (err) {
      yield put(Actions.deleteAssetFail());
    }
  }
}

export function* CoverDeleteWorker(payload) {
  if (payload.authToken) {
    try {
      //change api
      const data = yield call(deleteCover, payload);

      if (data.result === 'Success') {
        let assetList = data.body;
        yield put(Actions.deleteCoverSuccess(assetList));
        yield put(Actions.setCoverList(payload.authToken, payload.storyId));
      } else {
        yield put(Actions.deleteCoverFail());
      }
    } catch (e) {
      yield put(Actions.setCoverListFail());
      yield put({
        type: SET_API_ERROR
      });
    }
  }
}

export function* CoverActiveChange(payload){
  if (payload.authToken) {
    try {
      //change api
      const data = yield call(activeCover, payload);

      if (data.result === 'Success') {
        let assetList = data.body;
        // yield put(Actions.deleteCoverSuccess(assetList));
        yield put(Actions.setCoverList(payload.authToken, payload.storyId));
      } else {
        yield put(Actions.setCoverListFail());
        yield put({
          type: SET_API_ERROR
        });
      }
    } catch (e) {
      yield put(Actions.setCoverListFail());
      yield put({
        type: SET_API_ERROR
      });
    }
  }
}

export function* getAssetByIdWorker(payload) {
  if (payload.authToken) {
    try {
      const lockedAssetData = yield call(getLockedAssetById, payload);

      if (lockedAssetData.body.length === 0) {
        const data = yield call(getAssetById, payload);
        if (data.result === 'Success') {
          let assetData = data.body;
          yield put(Actions.getAssetByIdSuccess(assetData));
        } else {
          yield put(Actions.getAssetByIdFail([]));
        }
      } else {
        yield put(Actions.getAssetByIdFail(lockedAssetData.body));
      }
    } catch (err) {
      yield put(Actions.getAssetByIdFail());
    }
  }
}

export function* getCoverByIdWorker(payload) {
  if (payload.authToken) {
    try {
      const lockedAssetData = yield call(getCoverById, payload);
      // if (lockedAssetData.body.length === 0) {
      const data = yield call(getCoverById, payload);
      if (data.result === 'Success') {
        let assetData = data.body;
        yield put(Actions.getCoverByIdSuccess(assetData));
      } else {
        yield put(Actions.getCoverByIdFail([]));
      }
      // } else {
      //   yield put(Actions.getCoverByIdFail(lockedAssetData.body));
      // }
    } catch (err) {
      yield put(Actions.getCoverByIdFail());
    }
  }
}

export function* setLibraryChangeLogWorker(payload) {
  if (payload.authToken) {
    try {
      const changeLog = yield call(fetchLibraryChangeLog, payload);
      if (changeLog.result === 'Success') {
        yield put(Actions.setLibraryChangeLogSuccess(changeLog.body));
      } else {
        yield put(Actions.setLibraryChangeLogFail());
      }
    } catch (err) {
      yield put(Actions.setLibraryChangeLogFail());
    }
  }
}

export function* fetchAssetEditInfoWatcher() {
  yield takeLatest(ActionTypes.GET_ASSET_EDIT_INFO, getAssetEditInfoWorker);
}

export function* fetchCoverEditInfoWatcher() {
  yield takeLatest(ActionTypes.GET_COVER_EDIT_INFO, getCoverEditInfoWorker);
}

export function* fetchAssetDeleteDataWatcher() {
  yield takeLatest(ActionTypes.GET_ASSET_DELETE, getAssetDeleteWorker);
}

export function* fetchAssetByIdDataWatcher() {
  yield takeLatest(ActionTypes.GET_ASSET_BY_ID, getAssetByIdWorker);
}

export function* fetchCoverByIdDataWatcher() {
  yield takeLatest(ActionTypes.GET_COVER_BY_ID, getCoverByIdWorker);
}

export function* AssetDeleteDataWatcher() {
  yield takeLatest(ActionTypes.ASSET_DELETE, AssetDeleteWorker);
}

export function* CoverDeleteDataWatcher() {
  yield takeLatest(ActionTypes.COVER_DELETE, CoverDeleteWorker);
}

export function* coverActiveChangeWatcher (){
  yield takeLatest(ActionTypes.COVER_ACTIVE_CHANGE, CoverActiveChange)
}

export function* addAssetDataWatcher() {
  yield takeLatest(ActionTypes.SET_ASSET, setAssetWorker);
  yield takeLatest(ActionTypes.SET_ROLLBACK_ASSET, setRollbackAssetWorkder);
}

export function* addCoverDataWatcher() {
  yield takeLatest(ActionTypes.SET_COVER, setCoverWorker);
  // yield takeLatest(ActionTypes.SET_ROLLBACK_ASSET, setRollbackAssetWorkder);
}

export function* setAssetUploadDataWatcher() {
  yield takeLatest(ActionTypes.SET_ASSET_UPLOAD, setAssetUploadWorker);
}

export function* listAssetDataWatcher() {
  yield takeLatest(ActionTypes.SET_ASSET_LIST, fetchAssetListWorker);
}

export function* listCoverDataWatcher() {
  yield takeLatest(ActionTypes.SET_COVER_LIST, fetchCoverListWorker);
}

export function* setCoverUploadDataWatcher() {
  yield takeLatest(ActionTypes.SET_COVER_UPLOAD, setCoverUploadWorker);
}

export function* setLibraryChangeLogWatcher() {
  yield takeLatest(
    ActionTypes.SET_LIBRARY_CHANGELOG,
    setLibraryChangeLogWorker
  );
}

export default {
  addAssetDataWatcher,
  listAssetDataWatcher,
  fetchAssetDeleteDataWatcher,
  AssetDeleteDataWatcher,
  fetchAssetByIdDataWatcher,
  setLibraryChangeLogWatcher,
  fetchAssetEditInfoWatcher,
  setAssetUploadDataWatcher,
  listCoverDataWatcher,
  setCoverUploadDataWatcher,
  fetchCoverEditInfoWatcher,
  fetchCoverByIdDataWatcher,
  addCoverDataWatcher,
  CoverDeleteDataWatcher,
  coverActiveChangeWatcher
};

import { takeLatest, put, call } from 'redux-saga/effects';
import * as ActionTypes from './../actions/game-interface-action-types';
import * as MainActions from './../actions/action-types';
import * as Actions from './../actions/game-interface-action';
import {
    fetchGameInterfaceList,
    saveGameInterface,
    resetElement,
    fetchGameFonts,
    putGameFonts
} from './api/game-interface-api';

export function* fetchGameInterfaceListWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call(fetchGameInterfaceList, payload);
        if(data.result === 'Success') {
            yield put(Actions.getGameInterfaceSuccess(data.body));
        } else {
          if(data.message[0]==="Full authentication is required to access this resource"){
            yield put({
              type: MainActions.SET_LOGIN_DETAILS,
            payload: null,
            isLogOut: true
          });
    
          }else{
           
            yield put({
              type: MainActions.SET_API_ERROR,
              errorMsg:data.message[0]
            });
          }
        }
    } catch (err) {
        yield put(Actions.getGameInterfaceFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* saveGameInterfaceWorker(payload) {
  if(payload.authToken){

    try {
        const data = yield call(saveGameInterface, payload);

        if (data.result === 'Success') {
            yield put(Actions.saveElementSuccess());
            yield put(Actions.getGameInterface(payload.storyId, payload.authToken));
        }
        if (data.result === 'Failure') {
            yield put(Actions.saveElementFail(data.message));
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
      
            }else{
             
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            }
        }
    } catch (err) {
        yield put(Actions.saveElementFail(''));
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* resetGameInterfaceWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (resetElement, payload);
        if (data.result === 'Success') {
            yield put(Actions.resetElementSuccess());
            yield put(Actions.getGameInterface(payload.storyId, payload.authToken));
        } else {
            yield put(Actions.resetElementFail());
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
      
            }else{
             
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            }
        }
    } catch(err) {
        yield put(Actions.resetElementFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* getGameFontsWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (fetchGameFonts, payload);
        if (data.result === 'Success') {
            yield put(Actions.getGameFontsSuccess(data.body));
        } else {
            yield put(Actions.getGameFontsFail());   
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
      
            }else{
             
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            } 
        }
    } catch (err) {
        yield put(Actions.getGameFontsFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* setGameFontsWorker(payload) {
  if(payload.authToken){
    try {
        const data = yield call (putGameFonts, payload);
        if (data.result === 'Success') {
            yield put(Actions.setGameFontsSuccess(data.body));
        } else {
            yield put(Actions.setGameFontsFail()); 
            if(data.message[0]==="Full authentication is required to access this resource"){
              yield put({
                type: MainActions.SET_LOGIN_DETAILS,
              payload: null,
              isLogOut: true
            });
      
            }else{
             
              yield put({
                type: MainActions.SET_API_ERROR,
                errorMsg:data.message[0]
              });
            }  
        }
    } catch (err) {
        yield put(Actions.setGameFontsFail());
        yield put({
          type: MainActions.SET_API_ERROR,
          
        });
    }
  }
}

export function* listGameInterfaceDataWatcher() {
    yield takeLatest(ActionTypes.GET_GAME_INTERFACE, fetchGameInterfaceListWorker);
}

export function* saveGameInterfaceDataWatcher() {
    yield takeLatest(ActionTypes.SAVE_ELEMENT, saveGameInterfaceWorker);
}

export function* resetGameInterfaceDataWatcher() {
    yield takeLatest(ActionTypes.SET_RESET_ELEMENT, resetGameInterfaceWorker);
}

export function* getGameFontsDataWatcher() {
    yield takeLatest(ActionTypes.GET_GAME_FONTS, getGameFontsWorker);
}

export function* setGameFontsDataWatcher() {
    yield takeLatest(ActionTypes.SET_GAME_FONTS, setGameFontsWorker);
}

export default {
    listGameInterfaceDataWatcher,
    saveGameInterfaceDataWatcher,
    resetGameInterfaceDataWatcher,
    getGameFontsDataWatcher,
    setGameFontsDataWatcher
}
import React, { useEffect } from 'react';
import * as PlayerStyled from './../style';
import * as Constants from './../../../common/constants';
import * as Config from './../../../config/app.config';

function ChoiceCommands(props) {
  let normalImageKey = props.gameInterfaceList[Constants.interfaceList[4]];
  //let highlightedImageKey = props.gameInterfaceList[Constants.interfaceList[6]];
  let pressedImageKey = props.gameInterfaceList[Constants.interfaceList[5]];
  let currencyImageKey = props.gameInterfaceList[Constants.interfaceList[9]];

  let disabledImageKey = props.gameInterfaceList[Constants.interfaceList[6]];

  let defaultNormalImage = Constants.defaultInterfaceImageList[4];
  let defaultPressedImage = Constants.defaultInterfaceImageList[6];
  let defaultCurrencyImage = Constants.defaultInterfaceImageList[9];
  let defaultDisabledImage = Constants.defaultInterfaceImageList[5];

  let showPaidChoice =
    props.decisionParameters['Cost in game currency' + props.choiceCount] !=
      undefined &&
    props.decisionParameters['Cost in game currency' + props.choiceCount] > 0;

  if (showPaidChoice) {
    normalImageKey = props.gameInterfaceList[Constants.interfaceList[7]];
    defaultDisabledImage = Constants.defaultInterfaceImageList[7];
    //highlightedImageKey = props.gameInterfaceList[Constants.interfaceList[10]];
    pressedImageKey = props.gameInterfaceList[Constants.interfaceList[8]];

    defaultNormalImage = Constants.defaultInterfaceImageList[7];
    defaultPressedImage = Constants.defaultInterfaceImageList[8];
  }

  let normalImage =
    normalImageKey != null && props.blobImages.hasOwnProperty(normalImageKey)
      ? props.blobImages[normalImageKey]
      : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultNormalImage;
  //let highlightedImage = highlightedImageKey != null && props.blobImages.hasOwnProperty(highlightedImageKey) ? props.blobImages[highlightedImageKey] : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultHighlightedImage;
  let pressedImage =
    pressedImageKey != null && props.blobImages.hasOwnProperty(pressedImageKey)
      ? props.blobImages[pressedImageKey]
      : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultPressedImage;

  let currencyImage =
    currencyImageKey != null &&
    props.blobImages.hasOwnProperty(currencyImageKey)
      ? props.blobImages[currencyImageKey]
      : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultCurrencyImage;

  let disabledImage =
    disabledImageKey != null &&
    props.blobImages.hasOwnProperty(disabledImageKey)
      ? props.blobImages[disabledImageKey]
      : Config.GAME_INTERFACE_DEFAULT_IMAGE_PATH + defaultDisabledImage;

  let hideIfVisitedTrueFalseDest = 'false';
  let hideIfVisitedTrueFalse = [];
  if (
    props.hideIfVisitedArray &&
    props.hideIfVisitedArray.length > 0 &&
    props.hideIfVisited === 'true'
  ) {
    hideIfVisitedTrueFalse = props.hideIfVisitedArray.filter(
      choiceValue =>
        choiceValue.destination ==
          props.decisionParameters['Destination' + props.choiceCount] &&
        choiceValue.blockName ==
          props.decisionParameters['Button label' + props.choiceCount]
    );
    // console.log(
    //   'hideIfVisitedTrueFalse',
    //   hideIfVisitedTrueFalse,
    //   `props.decisionParameters['Destination' + props.choiceCount]`,
    //   props.decisionParameters['Destination' + props.choiceCount],
    //   `props.decisionParameters['Button label' + props.choiceCount]`,
    //   props.decisionParameters['Button label' + props.choiceCount]
    // );
    if (hideIfVisitedTrueFalse.length > 0) {
      hideIfVisitedTrueFalseDest = 'true';
    }
  }
  return (
    <PlayerStyled.choicePanel
      normalBackground={
        props.decisionParameters['Interactable' + props.choiceCount] == 'true'
          ? normalImage
          : disabledImage
      }
      //highlightedBackground = {highlightedImage}
      pressedBackground={
        props.decisionParameters['Interactable' + props.choiceCount] == 'true'
          ? pressedImage
          : disabledImage
      }
      currencyBackground={currencyImage}
      color={showPaidChoice ? '#000000' : '#FFFFFF'}
      gameFonts={props.gameFonts}
    >
      {hideIfVisitedTrueFalseDest != 'true' && (
        <div
          className="btn-img-wrapper"
          key={'Destination' + props.choiceCount}
          onClick={() =>
            props.decisionParameters['Interactable' + props.choiceCount] ==
            'true'
              ? props.handleDecisionClick(
                  props.decisionParameters['Destination' + props.choiceCount],
                  props.affinityType,
                  props.affinityWeight,
                  props.hideIfVisited,
                  props.decisionParameters['lineNumber'],
                  props.decisionParameters['Button label' + props.choiceCount]
                )
              : ''
          }
        >
          <span className="d-flex align-items-center btn-img-label">
            <span class="buttonLabel">
              {props.decisionParameters['Button label' + props.choiceCount]}
            </span>
            {props.decisionParameters[
              'Cost in game currency' + props.choiceCount
            ] > 0 && <span className="currencyIcon"></span>}
            <span className="buttonPrice">
              {props.decisionParameters[
                'Cost in game currency' + props.choiceCount
              ] > 0 &&
                props.decisionParameters[
                  'Cost in game currency' + props.choiceCount
                ]}
            </span>
          </span>
        </div>
      )}
    </PlayerStyled.choicePanel>
  );
}

export default ChoiceCommands;
